// eslint-disable-next-line arca/no-default-export
export default function (...chunks) {
    if (chunks.length === 0) {
        return '';
    }

    const isRoot = /^\//.exec(chunks[ 0 ]);
    const path = chunks
        .map((chunk) => chunk.replace(/^\/+|\/+$/g, ''))
        .filter((chunk) => chunk.length > 0)
        .join('/');
    return `${ isRoot ? '/' : '' }${ path }`;
}
