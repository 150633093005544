import styled          from 'styled-components';
import { LinkWrapper } from '@geenee/builder/src/components/Link/styles';
import { BoldCenter }  from '../common/styles';

export const PaymentWrapper = styled.div`
    width: 600px;
    padding: 32px 40px 40px;
`;

export const Disclaimer = styled.p`
    margin: 28px 0 0;
    text-align: center;
    font-size: 11px;
    line-height: 15px;
    width: 92%;

    span {
        /* display: block; */
    }

    ${ LinkWrapper } {
        display: inline-block;
        margin: 0;
    }
`;

export const Title = styled(BoldCenter)`
    margin-top: 0;
    margin-bottom: 35px;
`;

export const PricingDetails = styled.div`
    display: flex;
    justify-content: space-between;
    border-top: 1px solid
        ${ ({ theme: { secondaryColors } }) => secondaryColors.grey170 };
    border-bottom: 1px solid
        ${ ({ theme: { secondaryColors } }) => secondaryColors.grey170 };
    align-items: center;
    font-size: 12px;
    width: 100%;
    padding: 12px 4px;
    margin-bottom: 25px;

    p {
        margin: 0;
        color: black;
    }

    span {
        display: block;
        margin: 6px 0;
    }
`;

export const PricingDescription = styled.p`
    span:last-child {
        color: #747474;
    }
`;

export const PricingCost = styled.p`
    span:first-child {
        font-weight: 700;
    }
`;

export const MenuBar = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 12px;
`;
