import React, { FC }          from 'react';
import { useTakeSnapshot }    from '@geenee/shared/src/magellan/components/SnapShotComponent/hooks/useTakeSnapshot';
import { useVideoController } from "@geenee/shared/src/magellan/components/SnapShotComponent/hooks/useVideoController";
import { LoadingScreen }      from '@geenee/shared/src/magellan/components/SnapShotComponent/LoadingScreen';
import { SnapshotPreview }    from '@geenee/shared/src/magellan/components/SnapShotComponent/SnapshotPreview';
import { SnapshotToggle }     from '@geenee/shared/src/magellan/components/SnapShotComponent/SnapshotToggle';
import { SnapShotWrapper }    from '@geenee/shared/src/magellan/components/SnapShotComponent/SnapShotWrapper';
import { observer }           from 'mobx-react';
import { SceneModelType }     from '../../renderer/r3f-renderer/r3f.renderer';

type snapType = {
    sceneOutput: typeof ImageData
    cameraOutput: typeof ImageData
}
type PropsType = {
    takeSnapshot?: () => Promise<snapType>
    activeSceneModel: SceneModelType
}

export const SnapShotComponent: FC<PropsType> = observer((props) => {
    const {
        takeSnapshot, onSnapshotClose, snapShotTaken, onSnapshotTaken, snapShotPreviewImgRef
    } = useTakeSnapshot({ takeSnapshot: props.takeSnapshot, activeSceneModel: props.activeSceneModel });

    const {
        isVideoMode, videoBlob, showVideoLoading, onPhotoCaptureClick, onVideoCaptureClick,
        snapShotPreviewVideoRef, videoRecordingIndicatorContainer, isVideoRecordStarted
    } = useVideoController({ onSnapshotTaken, snapShotTaken, takeSnapshot, activeSceneModel: props.activeSceneModel });

    return (
        <SnapShotWrapper snapShotTaken={ snapShotTaken }>
            <SnapshotToggle
                isVideoMode={ isVideoMode }
                snapShotTaken={ snapShotTaken }
                videoRecordingIndicatorContainer={ videoRecordingIndicatorContainer }
                isVideoRecordStarted={ isVideoRecordStarted }
                onPhotoCaptureClick={ onPhotoCaptureClick }
                onVideoCaptureClick={ onVideoCaptureClick }
            />
            { showVideoLoading ? <LoadingScreen /> : <></> }
            <SnapshotPreview
                isVideoMode={ !!isVideoMode }
                snapShotPreviewImgRef={ snapShotPreviewImgRef }
                snapShotPreviewVideoRef={ snapShotPreviewVideoRef }
                snapShotTaken={ snapShotTaken }
                videoBlob={ videoBlob }
                onSnapshotClose={ onSnapshotClose }
            />
        </SnapShotWrapper>
    );
});
