import React, { useEffect, useRef, useState } from 'react';
import { useInject }                          from '@geenee/shared';
import { runInAction }                        from 'mobx';
import { observer }                           from 'mobx-react';
import RichTextEditor                         from '@geenee/builder/src/components/RichTextEditor/RichTextEditor';
import useOnClickOutside                      from '@geenee/builder/src/lib/useOnClickOutside';
import { AtomModel }                          from '@geenee/builder/src/magellan/model/atom.model';
import { MagellanState }                      from '@geenee/builder/src/magellan/state/app.state';
import { EditableBtn }                        from './styles';

export const BasicDrawerContentWrapper  = observer(() => {
    const { AppState } = useInject();
    const { firstAvailableSection } = AppState;
    const [ activeEditor, setActiveEditor ] = useState('');

    const wizardRef = useRef<HTMLElement | null>(null);
    useEffect(() => {
        wizardRef.current = document.getElementById('wizard_content');
    }, [ activeEditor ]);

    useOnClickOutside(wizardRef, (event) => {
        // @ts-ignore
        const targetClassName = event?.target?.className;
        let canClose = typeof targetClassName === 'string' ? !targetClassName.startsWith('ql-') : true;
        if (canClose) {
            const parentClassName = event?.target?.parentElement?.className;
            canClose = typeof parentClassName === 'string' ? !parentClassName.startsWith('ql-') : true;
        }
        canClose && activeEditor && setActiveEditor('');
        runInAction(() => {
            if (canClose) {
                (AppState as unknown as MagellanState).isSplashScreenEditing = false;
                (AppState as MagellanState).isBasicButtonFormOpened = false;
            }
        });
    });

    useEffect(() => () => {
        (
            runInAction(() => AppState as MagellanState).isSplashScreenEditing = false);
    }, []);

    if (!firstAvailableSection) return <></>;
    const { id } = firstAvailableSection || { id: '', button_title: '', text: '' };
    const { activeMolecule } = firstAvailableSection;
    const textAtom = activeMolecule?.getAtomByType('text') as AtomModel | null;
    const text = textAtom?.options?.atom_text_content;
    const buttonAtom = activeMolecule?.getAtomByType('button') as AtomModel | null;
    const {
        atom_button_title, atom_button_visible, atom_button_background_color, atom_button_border_color, atom_button_border_type
    } = buttonAtom?.options || {};
    const buttonId = `${ id }-button`;
    const disabled = firstAvailableSection?.nft_published;

    return (
        <>
            <div
                className="rte-container"
                onClick={ (e) => {
                    if (!disabled) {
                        e.stopPropagation();
                        e.preventDefault();
                        setActiveEditor(id);
                        runInAction(() => {
                            (AppState as MagellanState).isSplashScreenEditing = true;
                        });
                    }
                } }
            >
                <RichTextEditor
                    value={ text }
                    onEditorChange={ (data: string) => {
                        if (textAtom && (data !== text)) {
                            textAtom.options.atom_text_content = data;
                            textAtom.saveData();
                        }
                    } }
                    activeEditor={ activeEditor === id }
                    key={ firstAvailableSection.id }
                    id={ firstAvailableSection.id }
                />
            </div>
            { /* eslint-disable-next-line react/button-has-type */ }
            { atom_button_visible && (
                <EditableBtn
                    style={ {
                        backgroundColor: atom_button_background_color || '#000',
                        borderColor:     atom_button_border_color || '#000',
                        borderRadius:    atom_button_border_type === 'square' ? '0px' : '30px'
                    } }
                    onDoubleClick={ () => {
                        if (!disabled) {
                            runInAction(() => {
                                (AppState as MagellanState).isSplashScreenEditing = true;
                            });
                            setActiveEditor(buttonId);
                        }
                    } }
                    onClick={ () => {
                        if (!disabled) {
                            runInAction(() => {
                                (AppState as MagellanState).isBasicButtonFormOpened = true;
                            });
                        }
                    } }
                >
                    <RichTextEditor
                        hiddenKeys={ [ 'link' ] }
                        value={ atom_button_title }
                        onEditorChange={ (data: string) => {
                            if (buttonAtom && data !== atom_button_title) {
                                buttonAtom.options.atom_button_title = data;
                                buttonAtom.saveData();
                            }
                        } }
                        activeEditor={ activeEditor === buttonId }
                        disabled={ activeEditor !== buttonId }
                        key={ buttonId }
                        id={ buttonId }
                    />
                </EditableBtn>
            ) }
        </>
    );
});
