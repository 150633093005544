import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '41',
    height = '39',
    fill = 'black'
}: SvgIconProps) {
    return (
        <svg width={ width } height={ height } viewBox="0 0 41 39" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M1.45528 10.9685C3.12297 4.51189 8.94706 0.000976562 15.6156 0.000976562C21.856 0.000976562 27.357 3.95137 29.4042 9.74988H30.2483C35.2574 9.74484 39.4554 13.5363 39.9588 18.52C40.4622 23.5037 37.1073 28.0581 32.1983 29.0549C31.3188 29.2335 30.4611 28.6653 30.2825 27.7857C30.1039 26.9062 30.6721 26.0485 31.5516 25.8699C34.8242 25.2054 37.0608 22.1691 36.7252 18.8466C36.3896 15.5241 33.591 12.9965 30.25 12.9999H28.2025C27.4615 12.9999 26.8144 12.4987 26.6291 11.7813C25.332 6.75946 20.8022 3.25098 15.6156 3.25098C10.4289 3.25098 5.8991 6.75946 4.60201 11.7813C3.30492 16.8031 5.56908 22.0664 10.1069 24.5781C10.8921 25.0128 11.1763 26.0016 10.7417 26.7868C10.3071 27.572 9.31823 27.8562 8.53302 27.4216C2.69865 24.1922 -0.212406 17.4251 1.45528 10.9685ZM17.9736 38.7268C17.2268 38.229 17.0251 37.2201 17.5229 36.4734L22.3386 29.2498H15.625C14.3271 29.2498 13.553 27.8033 14.2729 26.7234L20.7729 16.9734C21.2707 16.2266 22.2796 16.0249 23.0264 16.5227C23.7731 17.0205 23.9749 18.0294 23.4771 18.7761L18.6613 25.9998H25.375C26.6728 25.9998 27.447 27.4462 26.7271 28.5261L20.227 38.2761C19.7292 39.0229 18.7203 39.2247 17.9736 38.7268Z"
                fill="black"
            />
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="39">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    // eslint-disable-next-line max-len
                    d="M1.45528 10.9685C3.12297 4.51189 8.94706 0.000976562 15.6156 0.000976562C21.856 0.000976562 27.357 3.95137 29.4042 9.74988H30.2483C35.2574 9.74484 39.4554 13.5363 39.9588 18.52C40.4622 23.5037 37.1073 28.0581 32.1983 29.0549C31.3188 29.2335 30.4611 28.6653 30.2825 27.7857C30.1039 26.9062 30.6721 26.0485 31.5516 25.8699C34.8242 25.2054 37.0608 22.1691 36.7252 18.8466C36.3896 15.5241 33.591 12.9965 30.25 12.9999H28.2025C27.4615 12.9999 26.8144 12.4987 26.6291 11.7813C25.332 6.75946 20.8022 3.25098 15.6156 3.25098C10.4289 3.25098 5.8991 6.75946 4.60201 11.7813C3.30492 16.8031 5.56908 22.0664 10.1069 24.5781C10.8921 25.0128 11.1763 26.0016 10.7417 26.7868C10.3071 27.572 9.31823 27.8562 8.53302 27.4216C2.69865 24.1922 -0.212406 17.4251 1.45528 10.9685ZM17.9736 38.7268C17.2268 38.229 17.0251 37.2201 17.5229 36.4734L22.3386 29.2498H15.625C14.3271 29.2498 13.553 27.8033 14.2729 26.7234L20.7729 16.9734C21.2707 16.2266 22.2796 16.0249 23.0264 16.5227C23.7731 17.0205 23.9749 18.0294 23.4771 18.7761L18.6613 25.9998H25.375C26.6728 25.9998 27.447 27.4462 26.7271 28.5261L20.227 38.2761C19.7292 39.0229 18.7203 39.2247 17.9736 38.7268Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0)">
                <rect x="1" width="39" height="39" fill={ fill } />
            </g>
        </svg>
    );
}
