import { Wrapper }        from '@geenee/ui';
import styled             from 'styled-components';
import { BodyStyles }     from '@geenee/builder/src/components/modals/DeleteAlert/styles';
import { ButtonsWrapper } from '../common/styles';

export const ContentWrapper = styled(Wrapper)`
       ${ BodyStyles };
      padding: 32px 48px;

       a {
           margin: 5px;
           font-size: 12px;
       }
`;

export const CustomButtonsWrapper = styled(ButtonsWrapper)`
    justify-content: space-between;
    padding: 8px 0 32px;
    * {
        width: 190px;
    }
`;
