import React, { useEffect } from 'react';
import { Scrollbars }       from 'react-custom-scrollbars';
import { useParams }        from 'react-router-dom';
import { HeaderTitle }      from "@geenee/builder/src/components/HeaderTitle";
import { useBuilderInject } from '@geenee/builder/src/core/hook/use-builder-inject';
import { stripeState }      from '@geenee/builder/src/core/state/stripe.state';
import { teamState }        from '@geenee/builder/src/core/state/team.state';
import { useHistory }       from "@geenee/common";
import { Divider, Wrapper } from '@geenee/ui';
import { observer }         from 'mobx-react';
import { MemberProfile }    from './MemberForm';

export const getEmailFromUrl = (email: string) => decodeURIComponent(email || '').replace(/[*]/mg, '.');

export const MemberPage = observer(() => {
    const team = Array.from(teamState.userStore.values());
    const { invites } = teamState;
    const { BuilderState } = useBuilderInject();
    // @ts-ignore
    const { role } = BuilderState.currentUser.profile;
    const history = useHistory();
    const { email } = useParams<{ email: string }>();
    const maxMembersPerPlan = stripeState.plan_params.seats - 1;

    useEffect(() => {
        // Get team size without owner
        const createdTeamMembers = team.length + invites.length;

        // Check if user has management permissions or exceeded members limit
        if (role !== 'owner') {
            history.push('/');
        } else if (createdTeamMembers >= maxMembersPerPlan && email === 'new') {
            history.push('/team');
        }
        const member = team.find((_member) => _member.email === decodeURIComponent(email || '').replace(/[*]/mg, '.'));
        if (!member && email !== 'new') {
            // Member not found - redirect to team
            history.push('/team');
        }
    }, [ ]);

    return (
        <Scrollbars
            autoHide
            autoHideTimeout={ 500 }
            autoHideDuration={ 200 }
        >
            <Wrapper
                fullHeight
                align="center"
                padding="xxl"
            >
                <Wrapper fullHeight maxWidth="775px">
                    <HeaderTitle
                        title={ email === 'new' ? 'Add New User' : "Team Member Details" }
                    />
                    <Divider transparent margin="lg" />
                    <MemberProfile />
                </Wrapper>
            </Wrapper>
        </Scrollbars>
    );
});
