import React                     from 'react';
import { Icon }                  from '@geenee/ui';
import { useModalDialogWrapper } from '../../../../components/ModalDialogWrapper';
import { ModalContent }          from './ModalContent';

export const useContactFormModal = ({ success }: {success: boolean}) => {
    const [ showModal, hideModal ] = useModalDialogWrapper(
        <ModalContent success={ success } />,
        { closeTriggerElement: <Icon color="shade-4" name="closeRound" size={ 24 } /> },
        '448px'
    );

    return { showModal, hideModal };
};
