import { ProjectModel }                                      from '@geenee/shared';
import { ViewType }                                          from '@geenee/shared/type/view.type';
import { inject, injectable }                                from 'inversify';
import { action, computed, observable, ObservableMap, toJS } from 'mobx';
import { HttpClient }                                        from '../../service/http.service';
import { ViewFactory }                                       from '../factory/view.factory';
import { BaseEntityModel }                                   from './base-entity.model';
import { ViewModel }                                         from './view.model';

@injectable()
export class ExperienceModel extends BaseEntityModel implements ViewType {
    @inject('<HttpClient>')
        httpClient!: HttpClient;
    @observable program_id: ViewType['program_id'] = '';

    type = 'experience';
    @observable title: ViewType['title'] = '';
    @observable cover_image_id: ViewType['cover_image_id'] = '';
    @observable cover_image: ViewType['cover_image'] = { url: '' };
    @observable splash_screen_message: ViewType['splash_screen_message'] = '';
    @observable menu_title: ViewType['menu_title'] = '';
    options!: ViewType['options'];
    @inject('<ViewFactory>')
        viewFactory!: ViewFactory;
    @observable viewsRegistry: ObservableMap<
        string,
        ViewModel
        > = new ObservableMap([]);
    $parent!: ProjectModel;

    @computed get activeView(): ViewModel | undefined {
        // @ts-ignore
        const view = this.viewsRegistry.get(this.parentProject.$parent.options.viewId);
        if (view) {
            return view;
        }
        return Array.from(this.viewsRegistry.values())
            .sort((a, b) => a.options.order - b.options.order)[ 0 ];
    }

    @computed get nft_published() {
        return this.$parent.nft_published;
    }

    @computed get toJsonObject(): ExperienceModel {
        const contents = Array.from(this.viewsRegistry.values()).map((el) => el.toJsonObject);
        return {
            ...toJS(this), $parent: undefined, contents, contentsRegistry: undefined, viewsRegistry: undefined, viewFactory: undefined
        };
    }

    @computed get children() {
        return this.viewsRegistry;
    }

    @computed get sortedChildren() {
        return Array.from(this.viewsRegistry.values()).sort((a, b) => a.options.order! - b.options.order!);
    }

    @computed
    get parentProject(): ProjectModel | null {
        return this.$parent;
    }

    @action
    async saveData() {
        alert('Saving Not Implemented');
    }
}
