import React                    from 'react';
import { DescriptionContainer } from '../styles';

type HeaderProps = {
  bothProjectAndMembers: boolean;
  maxMembers?: number | undefined;
  maxProjects?: number | undefined;
  planName?: string | undefined;
};

export function Header({ planName, bothProjectAndMembers, maxMembers, maxProjects }: HeaderProps) {
    return (
        <DescriptionContainer>
            <p>
                As a
                { ' ' }
                { planName }
                { ' ' }
                User, you can have
                { ' ' }
                {
                    // (maxProjects && `${ maxProjects } live project(s)` ||
                    maxMembers && `${ maxMembers } member seat(s)`
                }
                { ' ' }
                .
            </p>
            <p>
                Please choose the
                { ' ' }
                {
                    // bothProjectAndMembers
                    //   ? 'project(s) and person(s)'
                    // eslint-disable-next-line no-mixed-operators
                    //     : (maxProjects && 'project(s)' ||
                    maxMembers && 'person(s)'
                }
                { ' ' }
                you would like to keep.
            </p>
        </DescriptionContainer>
    );
}
