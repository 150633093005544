import React, { memo }              from 'react';
import { CallbackFunctionVariadic } from "@geenee/geeclient-kit/src/lib/type/type";
import { Description }              from "../../description/description.component";
import { Divider }                  from "../../divider/divider.component";
import { Icon }                     from "../../icon/icon.component";
import { Wrapper }                  from "../../wrapper/wrapper.component";
import { PopoverItemType }          from "../util";

type Props = {
    onClick: CallbackFunctionVariadic
    onMouseEnter: CallbackFunctionVariadic
    onMouseLeave?: CallbackFunctionVariadic
} & PopoverItemType

export const PopoverItem = memo((props: Props) => (
    <Wrapper
        key={ `container-${ props.id }` }
        fullWidth
    >
        <Wrapper
            className="popover-button"
            fullWidth
            frame="transparent-hover-light-grey"
            padding="xxs"
            row
            margin={ props.meta.hasDivider ? 'xxs' : undefined }
            align="space-between"
            color={ props.meta.color }
            onClick={ props.onClick }
            radius="sm"
            onMouseEnter={ props.onMouseEnter }
            onMouseLeave={ props.onMouseLeave }
        >
            <Description className="popover-button" size="sm" noWrap lineHeight="md">
                { props.label }
            </Description>
            { props.meta.triggerForInside ? <Icon name="extendIcon" color="shade-4" />
                : null }
        </Wrapper>

        { props.meta.hasDivider ? <Divider margin="xxs" /> : <></> }
    </Wrapper>
));
