import React                         from 'react';
import { Title as TitleUI, Wrapper } from '@geenee/ui';

export function Title() {
    return (
        <Wrapper fullWidth style={ { height: '83px' } }>
            <TitleUI styles={ { fontSize: '45px', fontWeight: 700 } }>
                Plan Options
            </TitleUI>
        </Wrapper>
    );
}
