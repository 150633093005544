import React       from 'react';
import { Wrapper } from '@geenee/ui';

interface IProps {
    style?: React.CSSProperties;
    children?: React.ReactNode | string;
}

export function MainContainer(props: IProps) {
    return (
        <Wrapper
            style={ props.style }
            fullHeight
            fullWidth
            align='center'
            overflow='auto'
            padding="xxl"
            maxWidth="fit-content"
        >
            { props.children }
        </Wrapper>
    );
}
