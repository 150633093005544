import * as React                                  from "react";
import type { AnimationGroup, TargetedAnimation }  from "@babylonjs/core/Animations/animationGroup";
import type { Sound }                              from "@babylonjs/core/Audio/sound";
import type { Bone }                               from "@babylonjs/core/Bones/bone";
import type { Skeleton }                           from "@babylonjs/core/Bones/skeleton";
import type { Camera }                             from "@babylonjs/core/Cameras/camera";
import type { IExplorerExtensibilityGroup }        from "@babylonjs/core/Debug/debugLayer";
import type { EffectLayer }                        from "@babylonjs/core/Layers/effectLayer";
import type { Light }                              from "@babylonjs/core/Lights/light";
import type { Material }                           from "@babylonjs/core/Materials/material";
import type { Texture }                            from "@babylonjs/core/Materials/Textures/texture";
import type { AbstractMesh }                       from "@babylonjs/core/Meshes/abstractMesh";
import type { TransformNode }                      from "@babylonjs/core/Meshes/transformNode";
import type { IParticleSystem }                    from "@babylonjs/core/Particles/IParticleSystem";
import type { PostProcess }                        from "@babylonjs/core/PostProcesses/postProcess";
import type { PostProcessRenderPipeline }          from "@babylonjs/core/PostProcesses/RenderPipeline/postProcessRenderPipeline";
import type { Sprite }                             from "@babylonjs/core/Sprites/sprite";
import type { SpriteManager }                      from "@babylonjs/core/Sprites/spriteManager";
import type { AdvancedDynamicTexture }             from "@babylonjs/gui/2D/advancedDynamicTexture";
import type { Control }                            from "@babylonjs/gui/2D/controls/control";
import { faProjectDiagram }                        from "@fortawesome/free-solid-svg-icons";
import type { GlobalState }                        from "../globalState";
import { AnimationGroupItemComponent }             from "./entities/animationGroupTreeItemComponent";
import { BoneTreeItemComponent }                   from "./entities/boneTreeItemComponent";
import { CameraTreeItemComponent }                 from "./entities/cameraTreeItemComponent";
import { EffectLayerItemComponent }                from "./entities/effectLayerPipelineTreeItemComponent";
import { AdvancedDynamicTextureTreeItemComponent } from "./entities/gui/advancedDynamicTextureTreeItemComponent";
import { ControlTreeItemComponent }                from "./entities/gui/controlTreeItemComponent";
import { LightTreeItemComponent }                  from "./entities/lightTreeItemComponent";
import { MaterialTreeItemComponent }               from "./entities/materialTreeItemComponent";
import { MeshTreeItemComponent }                   from "./entities/meshTreeItemComponent";
import { ParticleSystemTreeItemComponent }         from "./entities/particleSystemTreeItemComponent";
import { PostProcessItemComponent }                from "./entities/postProcessTreeItemComponent";
import { RenderingPipelineItemComponent }          from "./entities/renderingPipelineTreeItemComponent";
import { SkeletonTreeItemComponent }               from "./entities/skeletonTreeItemComponent";
import { SoundTreeItemComponent }                  from "./entities/soundTreeItemComponent";
import { SpriteManagerTreeItemComponent }          from "./entities/spriteManagerTreeItemComponent";
import { SpriteTreeItemComponent }                 from "./entities/spriteTreeItemComponent";
import { TargetedAnimationItemComponent }          from "./entities/targetedAnimationTreeItemComponent";
import { TextureTreeItemComponent }                from "./entities/textureTreeItemComponent";
import { TransformNodeItemComponent }              from "./entities/transformNodeTreeItemComponent";
import { TreeItemLabelComponent }                  from "./treeItemLabelComponent";

interface ITreeItemSpecializedComponentProps {
    label: string;
    entity?: any;
    extensibilityGroups?: IExplorerExtensibilityGroup[];
    globalState: GlobalState;
    onClick?: () => void;
}

export class TreeItemSpecializedComponent extends React.Component<ITreeItemSpecializedComponentProps> {
    constructor(props: ITreeItemSpecializedComponentProps) {
        super(props);
    }

    onClick() {
        if (!this.props.onClick) {
            return;
        }

        this.props.onClick();
    }

    render() {
        const { entity } = this.props;
        if (entity && entity.getClassName) {
            const className = entity.getClassName();

            if (className.indexOf("Mesh") !== -1) {
                const mesh = entity as AbstractMesh;
                return (
                    <MeshTreeItemComponent
                        globalState={ this.props.globalState }
                        extensibilityGroups={ this.props.extensibilityGroups }
                        mesh={ mesh }
                        onClick={ () => this.onClick() }
                    />
                );
            }

            if (className.indexOf("SpriteManager") !== -1) {
                return (
                    <SpriteManagerTreeItemComponent extensibilityGroups={ this.props.extensibilityGroups } spriteManager={ entity as SpriteManager } onClick={ () => this.onClick() } />
                );
            }

            if (className.indexOf("Sprite") !== -1) {
                return <SpriteTreeItemComponent extensibilityGroups={ this.props.extensibilityGroups } sprite={ entity as Sprite } onClick={ () => this.onClick() } />;
            }

            if (className.indexOf("Skeleton") !== -1) {
                return <SkeletonTreeItemComponent extensibilityGroups={ this.props.extensibilityGroups } skeleton={ entity as Skeleton } onClick={ () => this.onClick() } />;
            }

            if (className.indexOf("Bone") !== -1) {
                return <BoneTreeItemComponent extensibilityGroups={ this.props.extensibilityGroups } bone={ entity as Bone } onClick={ () => this.onClick() } />;
            }

            if (className.indexOf("TransformNode") !== -1) {
                return (
                    <TransformNodeItemComponent
                        extensibilityGroups={ this.props.extensibilityGroups }
                        transformNode={ entity as TransformNode }
                        onClick={ () => this.onClick() }
                    />
                );
            }

            if (className.indexOf("Camera") !== -1) {
                return (
                    <CameraTreeItemComponent
                        globalState={ this.props.globalState }
                        extensibilityGroups={ this.props.extensibilityGroups }
                        camera={ entity as Camera }
                        onClick={ () => this.onClick() }
                    />
                );
            }

            if (className.indexOf("Light", className.length - 5) !== -1) {
                return (
                    <LightTreeItemComponent
                        globalState={ this.props.globalState }
                        extensibilityGroups={ this.props.extensibilityGroups }
                        light={ entity as Light }
                        onClick={ () => this.onClick() }
                    />
                );
            }

            if (className.indexOf("Material") !== -1) {
                return <MaterialTreeItemComponent extensibilityGroups={ this.props.extensibilityGroups } material={ entity as Material } onClick={ () => this.onClick() } />;
            }

            if (className.indexOf("ParticleSystem") !== -1) {
                return <ParticleSystemTreeItemComponent extensibilityGroups={ this.props.extensibilityGroups } system={ entity as IParticleSystem } onClick={ () => this.onClick() } />;
            }

            if (className === "AdvancedDynamicTexture") {
                return (
                    <AdvancedDynamicTextureTreeItemComponent
                        onSelectionChangedObservable={ this.props.globalState.onSelectionChangedObservable }
                        extensibilityGroups={ this.props.extensibilityGroups }
                        texture={ entity as AdvancedDynamicTexture }
                        onClick={ () => this.onClick() }
                    />
                );
            }

            if (className === "AnimationGroup") {
                return (
                    <AnimationGroupItemComponent extensibilityGroups={ this.props.extensibilityGroups } animationGroup={ entity as AnimationGroup } onClick={ () => this.onClick() } />
                );
            }

            if (className === "TargetedAnimation") {
                return (
                    <TargetedAnimationItemComponent
                        extensibilityGroups={ this.props.extensibilityGroups }
                        targetedAnimation={ entity as TargetedAnimation }
                        onClick={ () => this.onClick() }
                    />
                );
            }

            if (className.indexOf("Texture") !== -1) {
                return <TextureTreeItemComponent extensibilityGroups={ this.props.extensibilityGroups } texture={ entity as Texture } onClick={ () => this.onClick() } />;
            }

            if (className.indexOf("RenderingPipeline") !== -1) {
                return (
                    <RenderingPipelineItemComponent
                        extensibilityGroups={ this.props.extensibilityGroups }
                        renderPipeline={ entity as PostProcessRenderPipeline }
                        onClick={ () => this.onClick() }
                    />
                );
            }

            if (className.indexOf("PostProcess") !== -1) {
                return <PostProcessItemComponent extensibilityGroups={ this.props.extensibilityGroups } postProcess={ entity as PostProcess } onClick={ () => this.onClick() } />;
            }

            if (className.indexOf("Layer") !== -1) {
                return <EffectLayerItemComponent extensibilityGroups={ this.props.extensibilityGroups } layer={ entity as EffectLayer } onClick={ () => this.onClick() } />;
            }

            if (className.indexOf("Sound") !== -1) {
                return <SoundTreeItemComponent extensibilityGroups={ this.props.extensibilityGroups } sound={ entity as Sound } onClick={ () => this.onClick() } />;
            }

            if (entity._host) {
                return <ControlTreeItemComponent extensibilityGroups={ this.props.extensibilityGroups } control={ entity as Control } onClick={ () => this.onClick() } />;
            }
        }

        return (
            <div className="meshTools">
                <TreeItemLabelComponent label={ entity.name } onClick={ () => this.onClick() } icon={ faProjectDiagram } color="cornflowerblue" />
            </div>
        );
    }
}
