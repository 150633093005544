import styled             from 'styled-components';
import { ActionsWrapper } from '@geenee/builder/src/components/Tree/NodeActions/styles';

export const ContentRoot = styled.div`
  overflow: hidden;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Root = styled.div<{opened: boolean}>`
  position: relative;
  transition: width .3s, min-width .3s, max-width .3s;
  // @ts-ignore
  ${ ({ opened }) => (opened ? 'width: 25%;' : 'width: 0;') }
  ${ ({ opened }) => (opened ? 'min-width: 290px;' : 'min-width: 0;') }
  height: 100%;
  max-width: 460px;
  touch-action: manipulation;
  background: ${ ({ theme }) => theme.tree.background };
 @keyframes slideInFromLeft {
    0% {
      min-width: 0;
      opacity: 0;
      width: 0;
    }
    100% {
      min-width: 290px;
      opacity: 1;
    }
  }

    @media (max-width: 1200px) {
      position: absolute;
      z-index: 5;
    }
  @media (max-width: 910px) {
    ${ ({ opened }) => (opened ? 'width: 60%;' : 'width: 0;') }
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 5px;
    // @ts-ignore
    background: ${ ({ theme }) => theme.tree.lineSeparatorBackground }
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
  }

  ${ ({ opened }) => opened && 'animation: .3s slideInFromLeft' };
`;

export const Container = styled.div`
  height: calc(100% - 100px);
  width: 100%;
  position: relative;
  overflow: hidden;
  font-size: 11px;
  // @ts-ignore
  overflow-y: auto;
`;

export const DashboardWrapper = styled.div`
  padding-top: 16px;
  padding-left: 24px;
  display: block;
`;

export const NodesWrapper = styled.ul`
  margin: 0;
  padding: 5px 0 0 24px;
  list-style: none;
  overflow-y: auto;
  transform: translate3d(0,0,0);

  ul {
    list-style: none;
  }
`;

export const Node = styled.li`
  position: relative;
  transform: translate3d(0, 0, 0);

  &:before {
    pointer-events: none;
    content: '';
    position: absolute;
    top: -5px;
    left: 0;
    width: 1px;
    height: 100%;
    // @ts-ignore
    background-color: ${ ({ theme: { secondaryColors } }) => secondaryColors.grey100 };
    z-index: 2;
  }

  &:after {
    pointer-events: none;
    content: '';
    position: absolute;
    top: 13px;
    left: 1px;
    width: 8px;
    height: 1px;
    // @ts-ignore
    background-color: ${ ({ theme: { secondaryColors } }) => secondaryColors.grey100 };
  }

  &.lastNode {
    &:before {
      pointer-events: none;
      content: '';
      position: absolute;
      top: -5px;
      left: 0;
      width: 1px;
      height: 19px;
      // @ts-ignore
      background-color: ${ ({ theme: { secondaryColors } }) => secondaryColors.grey100 };
    }
  }

  &.noParent {
    margin-bottom: 5px;

    &:before,
    &:after {
      content: none;
    }
  }
`;

export const NodeLine = styled.div<{paddingLeft: number}>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  cursor: default;
  height: 25px;

  &:hover {
    // @ts-ignore
    background: ${ ({ theme: { colors } }) => colors.white };
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);

    &:before {
      content: '';
      position: absolute;
      height: 25px;
      width: 100%;
      left:     ${ ({ paddingLeft }) => `-${ paddingLeft || 24 }px` };
      // @ts-ignore
      background: ${ ({ theme: { colors } }) => colors.white };
      box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.25);
      clip-path: inset(-5px 0px -5px -5px);
      &:hover {
        ${ ActionsWrapper } {
          display: block;
        }
      }
    }

    ${ ActionsWrapper } {
      display: block;
    }
  }
`;

export const NodeTitle = styled.div`
  cursor: pointer;
  width: 80%;
  z-index: 0;
  touch-action: manipulation;

  display: block;
  font-size: 13px;
  font-weight: 300;
  line-height: 16px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: black;
  font-family: 'Euclid Circular A', -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
  'Helvetica Neue', sans-serif;

  input {
    font-size: 13px;
    font-weight: 300;
    line-height: 16px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Euclid Circular A', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  }

  &:hover {
    //text-shadow: 0px 0px 1px black;
    font-weight: bold;
    color: black;
  }

  &.matchPath {
    font-weight: bold;

    &:hover {
      text-shadow: none;
    }

  }
`;

export const NodeControl = styled.div`
  margin-right: 5px;
  align-self: center;
  cursor: pointer;
  z-index: 0;

  &.activeAsset {
    circle {
      // @ts-ignore
      stroke: ${ ({ theme: { secondaryColors } }) => secondaryColors.pink40 };
      stroke-width: 0.5px;
    }
  }
`;

export const ChildrenWrapper = styled.ul`
  padding: 0 0 0 15px;
  max-height: 2000px;

  &.collapsed {
    padding: 0;
    overflow-y: hidden;
    max-height: 0;
  }
`;

export const AddNewWrapper = styled.div<{ active: boolean }>`
  overflow: hidden;
    #add-new-element {
        cursor: pointer;
    }

  #add-new-element > * {
    pointer-events: none;
  }

  &:hover {
    .plusCircleBg {
      fill: #57e7d4;
    }

    a {
      font-weight: bold;
    }
  }
  // @ts-ignore
  ${ ({ active }) => active
          && `.plusCircleBg {
            fill: #57e7d4;
        }

        a {
            font-weight: bold;
        }
        ` }

  position: relative;

  .one-ui-input-table-item__label {
    flex: 0 0 74px;
  }
`;

export const FooterWrapper = styled.div`
  padding: 0px 4px 24px 24px;
`;

export const TrialButton = styled.span`
  cursor: pointer;
  // @ts-ignore
  color: ${ ({ theme: { secondaryColors } }) => `${ secondaryColors.pink40 } !important` };
  font-weight: 500;
  font-size: 12px;
  &:hover {
    font-weight: bold;
  }
`;

export const ChevronWrapper = styled.span<{right?: boolean, mode: 'right' | 'left'}>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  // @ts-ignore
  ${ ({ right, mode }) => (right ? `${ mode === 'right' ? 'right: -15px;' : 'left: 15px;' }`
        : `${ mode === 'right' ? 'right: 25px;' : 'left: -15px;' };`) }
`;

export const PillWrapper = styled.span`
  margin-right: 23px;
`;
