import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { cn }                                                 from "../../util/bem";
import { useOnClickOutside }                                  from '../../util/useOnClickOutside';
import { Input }                                              from '../input/input.component';
import './styles.scss';

interface Props {
    value: string;
    onChange: (value: string) => void;
  // eslint-disable-next-line react/require-default-props
    doubleClickMode?: boolean;
    autoFocus?: boolean;
  // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
    onError?: () => void
    errorMessage?: string
    shorten?: boolean
}
const className = cn('editable-area');
export function EditableArea(props: Props) {
    const [ isEditing, setIsEditing ] = useState(false);
    const [ value, setValue ] = useState(props.value);
    const rootRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setValue(props.value);
    }, [ props.value ]);

    useEffect(() => {
        if (!props.disabled && props.autoFocus) {
            setIsEditing(true);
        }
    }, [ props.autoFocus, props.disabled ]);

    const onSave = () => {
        if (!value && props.onError) {
            props.onError();
        } else if (value !== props.value) {
            props.onChange(value);
        }
    };

    const closeAndSave = () => {
        if (!props.disabled && isEditing) {
            setIsEditing(false);
            onSave();
        }
    };

    // @ts-ignore
    useOnClickOutside(rootRef, closeAndSave);

    const handleKeyDown = (
        e: SyntheticEvent<HTMLInputElement, KeyboardEvent>
    ) => {
        if (e.nativeEvent.key === 'Enter') {
            closeAndSave();
        }
    };

    const enableEditing = (e: React.ChangeEvent<HTMLDivElement>) => {
        if (!props.disabled && !isEditing) {
            e.preventDefault();
            e.stopPropagation();
            setIsEditing(true);
        }
    };

    return (
        <div
            className={ className() }
            ref={ rootRef }
            onClick={ (e) => {
                if (isEditing) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            } }
            onDoubleClick={ props.doubleClickMode ? enableEditing : undefined }
        >
            { isEditing ? (
                <Input value={ value } onChange={ (e) => setValue(e.target.value) } onKeyDown={ handleKeyDown } autoFocus />
            ) : (
                <div className={ className('text', { shorten: props.shorten }) }>{ value }</div>
            ) }
        </div>
    );
}
