import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({ width = '27', height = '32', onClick, id }: SvgIconProps) {
    return (
        <svg
            id={ id }
            onClick={ onClick }
            width={ width }
            height={ height }
            viewBox="0 0 27 32"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_21487_103928)">
                { /* eslint-disable-next-line max-len */ }
                <path d="M2.00006 2C2.00006 0.895431 2.89549 0 4.00006 0H22.1539C23.2585 0 24.1539 0.895431 24.1539 2V26C24.1539 27.1046 23.2585 28 22.1539 28H4.00006C2.89549 28 2.00006 27.1046 2.00006 26V2Z" fill="#F2F2F2" />
            </g>
            { /* eslint-disable-next-line max-len */ }
            <path d="M18.1026 7H8.05134C7.25832 7 6.61545 7.69645 6.61545 8.55555V19.4444C6.61545 20.3035 7.25832 21 8.05134 21H18.1026C18.8956 21 19.5385 20.3035 19.5385 19.4444V8.55555C19.5385 7.69645 18.8956 7 18.1026 7Z" fill="white" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            { /* eslint-disable-next-line max-len */ }
            <path d="M10.564 12.4445C11.1588 12.4445 11.641 11.9222 11.641 11.2779C11.641 10.6335 11.1588 10.1112 10.564 10.1112C9.96928 10.1112 9.48712 10.6335 9.48712 11.2779C9.48712 11.9222 9.96928 12.4445 10.564 12.4445Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.5385 16.3333L15.9488 12.4445L8.05133 21" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <defs>
                { /* eslint-disable-next-line max-len */ }
                <filter id="filter0_d_21487_103928" x="6.10352e-05" y="0" width="26.1538" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.527938 0 0 0 0 0.527938 0 0 0 0 0.527938 0 0 0 0.5 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21487_103928" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_21487_103928" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}
