import * as React                        from 'react';
import { useState }                      from 'react';
import { GeeneeScene }                   from '@geenee/geeclient-kit/src/lib/component/scene/GeeneeScene.component';
import { useInject }                     from '@geenee/shared';
import { SnapshotToggle }                from '@geenee/shared/src/magellan/components/SnapShotComponent/SnapshotToggle';
import { SnapShotWrapper }               from '@geenee/shared/src/magellan/components/SnapShotComponent/SnapShotWrapper';
import { cn, Wrapper }                   from '@geenee/ui';
import { observer }                      from 'mobx-react';
import { SelectSceneSectionTypePopup }   from '@geenee/builder/src/components/ScreenElements/ContentTypeContainer/ContentTypes/SceneDrawer/SelectSceneSectionTypePopup';
import { useBuilderInject }              from '@geenee/builder/src/core/hook/use-builder-inject';
import { SCENE_TYPES, TYPE_SCENE_BUILD } from '@geenee/builder/src/lib/constants';
import { SceneOpenTrigger }              from './SceneOpenTrigger';
import './styles.scss';

const className = cn('scene-drawer');

export const SceneDrawer = observer((props: any) => {
    const { BuilderState } = useBuilderInject();
    const { AppState } = useInject();
    const { firstAvailableSection } = AppState;
    const showSceneOpenTriggerMenu = SCENE_TYPES.includes(firstAvailableSection?.type || '')
        && Array.from(AppState.firstAvailableSection?.moleculesRegistry.values() || []).length;
    const isSceneBuild = firstAvailableSection?.type === TYPE_SCENE_BUILD;
    const [ isVideoMode, setIsVideoMode ] = useState(false);
    // if (!firstAvailableSection) {
    //     return <></>;
    // }

    return (
        <>
            { props.children }
            <Wrapper fullWidth fullHeight className={ className('root') } id="container-popover-id">
                { !BuilderState.arSceneShown && <GeeneeScene type="viewer" mode="preview" blockingRender /> }
                <Wrapper
                    fullWidth={ false }
                    align="center"
                    className={ className('snap-shot-wrapper') }
                >

                    { isSceneBuild ? <SelectSceneSectionTypePopup /> : <></> }

                    <SnapShotWrapper>
                        <SnapshotToggle
                            isVideoMode={ isVideoMode }
                            videoRecordingIndicatorContainer={ null }
                            onPhotoCaptureClick={ () => setIsVideoMode(false) }
                            onVideoCaptureClick={ () => setIsVideoMode(true) }
                        />
                    </SnapShotWrapper>
                </Wrapper>
                { showSceneOpenTriggerMenu ? <SceneOpenTrigger /> : <></> }
            </Wrapper>
        </>
    );
});
