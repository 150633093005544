import { generateModelPreviewByUrl } from '~/lib/generateModelPreview';

export const generateTeamLibraryModelPreview = (file: File): Promise<Blob> => generateModelPreviewByUrl({
    url:        URL.createObjectURL(file),
    trackerUrl: '',
    done:       async (imageUrl) => {
        const response = await fetch(imageUrl);
        return response.blob();
    },
    lookAtObjectCenter: true,
    fileName:           file.name,
    rendererSize:       { width: 205, height: 205 }
});
