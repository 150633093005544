import React from 'react';

function SpotifyCallback() {
    const tokenFromUrl = window.location.search.split('code=')[ 1 ];

    if (tokenFromUrl) {
        window.opener.spotifyCallback(tokenFromUrl);
    }

    return <div />;
}

// eslint-disable-next-line arca/no-default-export
export default SpotifyCallback;
