import React              from 'react';
import Reorder            from '@geenee/builder/src/asset/icons/Reorder';
import Trash              from '@geenee/builder/src/asset/icons/Trash';
import { ActionsWrapper } from './styles';

interface Props {
  disabled: boolean;
  onChangeCanMove: (value: boolean) => void;
  depth: number;
  // eslint-disable-next-line react/require-default-props
  onDeleteClicked?: () => void | Promise<void>;
  // eslint-disable-next-line react/require-default-props
  hideMoveIcon?: boolean;
}

export function NodeActions({
    disabled, onChangeCanMove, depth, onDeleteClicked, hideMoveIcon
}: Props) {
    return disabled ? <></> : (
        <ActionsWrapper depth={ depth }>
            <span
                onMouseDown={ () => onChangeCanMove(true) }
                onMouseUp={ () => onChangeCanMove(false) }
            >
                { hideMoveIcon ? <></> : <Reorder /> }
            </span>
            <span onClick={ async (e) => {
                e.preventDefault();
                e.stopPropagation();
                onDeleteClicked && await onDeleteClicked();
            } }
            >
                <Trash width="12px" height="12px" />
            </span>
        </ActionsWrapper>
    );
}
