import { Analytics } from '@geenee/analytics';
import {
    ExperienceFactory, ExperienceModel, HttpClient,
    ProjectModel as ModelShared
} from '@geenee/shared';
import { injectable }                                            from 'inversify';
import { action, makeObservable, observable, runInAction, toJS } from 'mobx';
import {
    getMockOpenedProject,
    getPublishProject,
    publishProject,
    unpublishProject
} from '@geenee/builder/src/api/ProjectsAPI';
import { API_URL, EXPERIENCE_CREATED, EXPERIENCE_DELETED } from '@geenee/builder/src/lib/constants';
import envConfig                       from '@geenee/builder/src/lib/envConfig';
import Templates                       from '@geenee/builder/src/lib/nodeTemplates';
import { uploadAttachment }            from '@geenee/builder/src/lib/uploadAttachment';
import { container }                   from '@geenee/builder/src/magellan/di/di';
import { companyUrlConstructor }       from '@geenee/builder/src/pages/CompanyPage/components/CompanyPageContent';
import { MagellanState }               from '../state/app.state';

@injectable()
export class ProjectModel extends ModelShared {
    @observable expandedDataRequested = false;
    httpClient: HttpClient = container.get('<HttpClient>');
    analytics: Analytics = container.get('<Analytics>');

    constructor() {
        super();
        makeObservable(this);
    }

    @action
        updateState = (item: Partial<ProjectModel>) => {
            Object.keys(item).forEach((key) => {
            // @ts-ignore
                this[ key ] = item[ key ];
            });
        };

    @action
    async saveData(dirty = true) {
        try {
            // trigger recalculate the left panel tree
            (this.$parent as MagellanState).setLastUpdate();
            this.updateState({ ...this, options: { ...this.options, dirty } });

            await this.httpClient.put(
                `${ envConfig.API_URL }/api/v0/programs/${ this.id }`,
                { program: toJS(this.toServerData()) }
            );
        } catch (e) {
            console.error('Save program error: ', e);
        }
    }

    toServerData() {
        return {
            ...this,
            $parent:               undefined,
            experiencesRegistry:   undefined,
            httpClient:            undefined,
            attachmentFactory:     undefined,
            experienceFactory:     undefined,
            // @TODO: Delete after integrating real API
            expandedDataRequested: undefined
        };
    }

    @action
    async setLogo(file: File) {
        const newAttachment  = await this.createAttachmentModel({
            id: this?.header_logo_id || '',
            file
        }, uploadAttachment);

        if (newAttachment.id) {
            runInAction(() => {
                this.header_logo_id = newAttachment.id;
                this.header_logo = newAttachment;
            });
            await this.saveData();
        }
    }

    @action async publish(published = !this.published) {
        let response;
        if (published) {
            response = await publishProject(this.id, { published_name: companyUrlConstructor(this.title) });
        } else {
            response = await unpublishProject(this.id);
        }
        this.published = published;
        this.saveData(false);

        return response;
    }

    @action getPublishData() {
        return getPublishProject(this.id);
    }

    // TODO: refactor NFT generation
    // @computed get childArContentsWithChildren() {
    //     const experiences = Array.from(this.experiencesRegistry.values()).filter((el) => el) as ExperienceModel[];
    //     const arContents: SectionModel[] = [];
    //     experiences.forEach((exp) => {
    //         const contents = Array.from(exp.viewsRegistry.values()).filter((content) => AR_CONTENT_TYPES.includes(content.type))
    //             .sort((a, b) => (a.options.order || 0) - (b.options.order || 0));
    //         if (contents.length) {
    //             arContents.push(...contents);
    //         }
    //     });
    //     return arContents.reduce((acc, curr) => {
    //         // @ts-ignore
    //         const children = Array.from(curr.attachmentsRegistry.values());
    //         if (children.length) {
    //             acc.push({ content: curr, assets: children });
    //         }
    //         return acc;
    //     }, [] as any[]);
    // }

    @action openNode = async () => {
        this.expandedDataRequested = true;
        let expandedData = {};
        if (process.env.ENV_USE_MOCK_DATA) {
            expandedData.projects = await getMockOpenedProject(this.id);
        } else {
            const { data } = await this.httpClient.get(`${ API_URL }/api/v0/init/program/${ this.id }`);
            expandedData = data;
        }

        const experinceFactory: ExperienceFactory = container.get('<ExperienceFactory>');
        // @TODO: Double-check with the actual API endpoint response structure
        const project = expandedData;
        project?.experiences.forEach((el) => {
            el.cover_image_id = el?.cover_image?.id || '';
            const projectItem = experinceFactory.create(el, this);
            runInAction(() => {
                this.experiencesRegistry.set(el.id, projectItem);
            });
        });

        runInAction(() => {
            this.header_logo = project?.header_logo || { url: '' };
        });
        (this.$parent as MagellanState).setLastUpdate();
    };

    @action
    async createExperience(experienceSchema: Partial<ExperienceModel> = {}) {
        const experiences = Array.from(this.experiencesRegistry.values()).map((el) => el.options.order || 0);
        const order = experiences.length ? Math.max(...experiences) : 0;
        const composedSchema = {
            ...Templates.EXPERIENCE,
            ...experienceSchema,
            projectId:  this.id,
            program_id: this.id,
            options:    { ...Templates.EXPERIENCE.options, ...experienceSchema.options, order: order + 1 }
        };
        const { data: { data } } = await this.httpClient.post(`${ envConfig.API_URL }/api/v0/experiences`, { experience: composedSchema });
        const { id, program_id } = data;

        this.analytics.track(EXPERIENCE_CREATED, { id, program_id, order });

        composedSchema.id = id;
        const experienceModel = this.experienceFactory.create(composedSchema, this);
        this.experiencesRegistry.set(experienceModel.id, experienceModel);

        this.saveData();

        return experienceModel;
    }

    @action async deleteChild(childId:string) {
        try {
            await this.httpClient.delete(`${ envConfig.API_URL }/api/v0/experiences/${ childId }`);

            this.analytics.track(EXPERIENCE_DELETED, { id: childId });

            this.experiencesRegistry.delete(childId);

            this.saveData();
        } catch (e) {
            console.error('Error while deleting experience: ', e);
        }
    }
}
