import { useEffect } from 'react';
import { useInject } from '@geenee/shared';

export const useInitialScreenLoaded = (disabled?: boolean) => {
    const { AppState } = useInject();

    useEffect(() => {
        if (!disabled) {
            AppState.setInitialScreenLoaded();
        }
    }, [ disabled ]);
};
