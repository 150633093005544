import React, { memo, useState }  from 'react';
import { LoadingSpinnerCentered } from '@geenee/shared/src/magellan/components/LoadingSpinner';
import {
    Button, Card, Description, Divider, MediaContent, Wrapper
} from '@geenee/ui';
import { AnimatedCardProps } from '../CardList/useAnimatedCards';

export const AnimatedCard = memo((props: AnimatedCardProps) => {
    const [ startToPlay, setStartToPlay ] = useState(false);
    const [ lottieLoaded, setLottieLoaded ] = useState(false);
    return (
        <Card
            style={ { minWidth: 288 } }
            maxWidth="30%"
            mediaBefore
            onClick={ props.onClick }
            hoverShadow="child"
            media={ (
                <MediaContent
                    fallback={ <LoadingSpinnerCentered /> }
                    fit="contain"
                    type="animation"
                    speed={ 0.75 }
                    placeholder={ props.defaultImage }
                    loop
                    autoplay={ false }
                    play={ startToPlay }
                    playFrom={ props.playFrom }
                    animationData={ props.animationData }
                    ratio="ratio-1-1"
                    size="xxl"
                    align="center"
                    onLoad={ () => setLottieLoaded(true) }
                />
            ) }
            onMouseEnter={ () => {
                if (lottieLoaded) {
                    setStartToPlay(true);
                }
            } }
            onMouseLeave={ () => {
                if (lottieLoaded) {
                    setStartToPlay(false);
                }
            } }
        >
            <Divider transparent margin="xs" />
            <Wrapper style={ { paddingLeft: 16, paddingRight: 16 } } align="center" padding="md" frame="solid-default">
                <Description margin="xs" weight="bold" lineHeight="lg" size="lg" align="center">{ props.title }</Description>
                <Description margin="md" color="dark-5">{ props.description }</Description>
                <Button weight="bold" margin="xs" uppercase>Get Started</Button>
            </Wrapper>
        </Card>
    );
});
