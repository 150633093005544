import { action, makeAutoObservable, observable } from 'mobx';
import { AccountAPI }                             from '@geenee/builder/src/api/AccountAPI';
import { getCompanyLogoPreSignedUrl }             from '@geenee/builder/src/api/CompanyApi';
import { getMatomoSitesApi }                      from '@geenee/builder/src/api/MatomoApi';
import { createUploadFileChannel }                from '@geenee/builder/src/lib/uploadAttachment';

export class AccountState {
  @observable business_type = '';
  @observable ga_client_id = '';
  @observable matomo_id = '';
  @observable matomo_token_auth = '';
  @observable logo = '';
  @observable name = '';
  @observable size = '';
  @observable slug = '';
  @observable publishing_domain = '';
  @observable npm_key = '';

  constructor() {
      makeAutoObservable(this);
  }

  @action
      updateState = (item: Partial<AccountState>) => {
          Object.keys(item).forEach((key) => {
              // @ts-ignore
              this[ key ] = item[ key ];
          });
      };

  @action
      update = async (data: Partial<AccountState>, logoFile?: File) => {
          if (logoFile) {
              const { url, s3_url } = await getCompanyLogoPreSignedUrl(logoFile.name);
              await createUploadFileChannel(url, logoFile);
              // eslint-disable-next-line no-param-reassign
              data.logo = s3_url;
          } else if (data.logo === null) {
              // eslint-disable-next-line no-param-reassign
              data.logo = '';
          }
          const account = await AccountAPI.updateAccount(data);
          this.updateState(account);
      };

  @action fetchMatomoSites = () => getMatomoSitesApi(this.matomo_id, this.matomo_token_auth);
}

export const accountState = new AccountState();
