import React, { FC, useRef }   from 'react';
import { useGesture }          from 'react-use-gesture';
import { animated, useSpring } from '@react-spring/three';
import { observer }            from 'mobx-react';

type GesturePropsType = {
    gestureOn?: boolean;
    rotationOn?: boolean;
    translationOn?: boolean;
    scaleOn?: boolean;
    scaleLimit?: number;
    onPinchListener?: boolean;
    sceneModel: SceneRenderer;
};

export const GestureControls: FC<GesturePropsType> = observer(({ ...props }) => {
    const gestureOn =       props.gestureOn !== undefined ? props.gestureOn : props.sceneModel.gestureControl?.gestureOn;
    const rotationOn =      props.rotationOn !== undefined ? props.rotationOn : props.sceneModel.gestureControl?.rotateOn;
    const translationOn =   props.translationOn !== undefined ? props.translationOn : props.sceneModel.gestureControl?.dragOn;
    const scaleOn =         props.scaleOn !== undefined ? props.scaleOn : props.sceneModel.gestureControl?.scaleOn;
    const scaleLimit =      props.scaleLimit !== undefined ? props.scaleLimit : props.sceneModel.gestureControl?.minScaleLimit;
    const onPinchListener = props.onPinchListener !== undefined ? props.onPinchListener : props.sceneModel.gestureControl?.onPinchListener;

    const gestureControlsGroupRef = useRef();
    const pinchStarted =            useRef(false);

    const [ { gScale, gRotation, gPosition }, setGesture ] = useSpring(() => ({
        gScale:    [ 1, 1, 1 ],
        gRotation: [ 0, 0, 0 ],
        gPosition: [ 0, 0, 0 ],
        config:    { mass: 1, tension: 600, friction: 40 }
    }));

    const pinchStartedDispatcher = () => {
        props.sceneModel.emitter.emit('on-pinch-start-changed', { detail: { started: pinchStarted.current } });
    };

    useGesture({
        onPinch: ({ delta: [ d, y ] }) => {
            if (!gestureOn) return;
            const sc = d / 100;
            const rc = y / 10;
            const r = gestureControlsGroupRef?.current?.rotation;
            const s = Math.max(gestureControlsGroupRef?.current?.scale.x + sc, scaleLimit); // scale down not less then 0.1;

            rotationOn && setGesture({ gRotation: [ r.x, r.y - rc, r.z ] });
            scaleOn && setGesture({ gScale: [ s, s, s ] });
        },
        onPinchStart: () => {
            if (!gestureOn) return;
            pinchStarted.current = true;
            onPinchListener && pinchStartedDispatcher();
        },
        onPinchEnd: () => {
            if (!gestureOn) return;
            pinchStarted.current = false;
            onPinchListener && pinchStartedDispatcher();
        },
        onDrag: ({ delta: [ dx, dy ] }) => {
            if (!gestureOn) return;
            if (pinchStarted.current) return;

            const { x, z } = gestureControlsGroupRef?.current?.position;
            const newX = x + (dx) / 100;
            const newZ = z + (dy) / 50;

            translationOn && setGesture({ gPosition: [ newX, 0, newZ ] });
        }
    }, { domTarget: { current: props.sceneModel?.renderer?.domElement || document.body } });

    props.sceneModel.emitter.addListener('on-position-reset', () => {
        setGesture({ gPosition: [ 0, 0, 0 ] });
    });
    props.sceneModel.emitter.addListener('on-scale-reset', () => {
        setGesture({ gScale: [ 1, 1, 1 ] });
    });
    props.sceneModel.emitter.addListener('on-rotation-reset', () => {
        setGesture({ gRotation: [ 0, 0, 0 ] });
    });

    return (
        <animated.group
            name="geenee-3d-gesture-control-group"
            ref={ gestureControlsGroupRef }
            position={ gestureOn ? gPosition : [ 0, 0, 0 ] }
            scale={ gestureOn ? gScale : [ 1, 1, 1 ] }
            rotation={ gestureOn ? gRotation : [ 0, 0, 0 ] }
        >
            { props.children }
        </animated.group>
    );
});
