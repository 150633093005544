import React, { memo }                       from 'react';
import { SizesUnionType }                    from "../../util/global-props";
import { Description, DescriptionPropsType } from "../description/description.component";
import { InputTableItemPropsType }           from "../input-table/input-table-item.component";
import { Wrapper }                           from "../wrapper/wrapper.component";
import { InputMatrixRow }                    from "./input-matrix-row.component";

type Props = {
  labels: string[]
  ratio?: number
  viewType?: InputTableItemPropsType['viewType']
  margin?: SizesUnionType
  size?: DescriptionPropsType['size']
  title?: string
}
export const InputMatrixColumnLabels = memo((props: Props) => (
    <InputMatrixRow
        ratio={ props.ratio }
        viewType={ props.viewType }
        label={ props.title }
    >
        { props.labels.map((label, index) => (
            <Wrapper
                margin={ index !== (props.labels.length - 1) ? props.margin : 'off' }
                key={ `input-matrix-label-${ index }` }
                frame="solid-grey"
                radius="lg"
                padding="xs"
            >
                <Description
                    size={ props.size }
                    color="shade-3"
                    style={ { cursor: 'default' } }
                >
                    { label.toUpperCase() }
                </Description>
            </Wrapper>
        )) }
    </InputMatrixRow>
));
