import React, { useState } from 'react';
import { Input }           from '@geenee/ui';
import { observer }        from 'mobx-react';
import StyledButton        from '@geenee/builder/src/components/common/Button';
import { accountState }    from '@geenee/builder/src/core/state/account.state';
import { InputRowWrapper } from '../Analytics/styles';

export const InputRow = observer(() => {
    const [ domainValue, setDomainValue ] = useState(accountState.matomo_id);
    const [ tokenValue, setTokenValue ] = useState(accountState.matomo_id);
    const [ isLoading, setIsLoading ] = useState(false);
    // matomo_id: "https://geeneear.matomo.cloud/"
    // 07de208f0362b93348b5b49f583543f3
    return (
        <InputRowWrapper isMatomo>
            <Input
                placeholder="Your Matomo domain (ex. https://demo.matomo.cloud)"
                onChange={ (e) => setDomainValue(e.target.value) }
                value={ domainValue }
            />
            <Input
                className="matomo_auth_input"
                placeholder="Your Matomo token auth"
                onChange={ (e) => setTokenValue(e.target.value) }
                value={ tokenValue }
            />
            <StyledButton
                label="ADD"
                $width="152px"
                disabled={ isLoading || !domainValue || !tokenValue }
                onClick={ async () => {
                    setIsLoading(true);
                    await accountState.update({
                        matomo_id: domainValue.endsWith('/')
                            ? domainValue.slice(0, domainValue.length - 1) : domainValue,
                        matomo_token_auth: tokenValue
                    });
                    setIsLoading(false);
                } }
            />
        </InputRowWrapper>
    );
});
