import React                                       from 'react';
import { InputTableItem, InputTableItemPropsType } from "@geenee/ui/src/lib/component/input-table/input-table-item.component";
import { WrapperPropsType }                        from "@geenee/ui/src/lib/component/wrapper/wrapper.component";
import { cn }                                      from "@geenee/ui/src/lib/util/bem";
import { SizeType }                                from "@geenee/ui/src/lib/util/global-props";

require('./lineWrapper.scss');

interface Props {
  children: React.ReactNode
  wrapperProps?: WrapperPropsType
  border?: boolean
  className?: string
  title?: string | React.ReactNode
  padding?: SizeType
  overflow?: WrapperPropsType['overflow']
  ratio?: InputTableItemPropsType['ratio']
  valign?: InputTableItemPropsType['valign']
}

const className = cn('action-row-wrapper');

export function FormLineWrapperComponent({
    children, wrapperProps = {}, border = true, title, padding = "xs", ratio, ...props
}: Props) {
    return (
        <InputTableItem
            padding={ padding }
            ratio={ ratio || 0.7 }
            marginOff
            size="sm"
            valign={ props.valign }
            weight="medium"
            labelDescriptionProps={ { color: 'shade-1' } }
            label={ title }
            className={ `${ className({ border }) } ${ props.className }` }
            overflow={ props.overflow }
            contentWrapperProps={ { ...wrapperProps } }
        >
            { children }
        </InputTableItem>
    );
}
