import { Button }      from 'primereact/button';
import styled          from 'styled-components';
import { buttonStyle } from './styles';

const StyledButton = styled(Button)`
  ${ buttonStyle }
`;

// eslint-disable-next-line arca/no-default-export
export default StyledButton;
