import React, { useRef, useState }                from 'react';
import { Dropdown, FormItem, Select, SelectList } from '@geenee/ui';
import isEmpty                                    from 'lodash-es/isEmpty';
import useOnClickOutside                          from '@geenee/builder/src/lib/useOnClickOutside';
import {  SelectWrapper }                         from '../styles';

type Props = {
  disabledMemberSelection: boolean;
  membersList: { value: string; label: string }[] | undefined;
  selectedMembers: string[];
  onSelectMembers: (value: any) => void;
}

export function MembersSelectorRow({ disabledMemberSelection, membersList, selectedMembers, onSelectMembers }: Props) {
    const [ selectKey, setSelectKey ] = useState(0);
    const selectRef = useRef();
    // Click outside temp fix
    // @ts-ignore
    useOnClickOutside(selectRef, () => {
        setSelectKey(selectKey + 1);
    });
    return (
        <SelectWrapper
            disableSelect={ disabledMemberSelection }
            // @ts-ignore
            ref={ selectRef }
        >
            <Dropdown
                key={ selectKey }
                menu={ (
                    <SelectList
                        viewType="check"
                        onChange={ onSelectMembers }
                        items={ membersList }
                        value={ selectedMembers }
                        disabled
                    />
                ) }
                menuWidth="100%"
                style={ { width: 280 } }
            >
                <FormItem>
                    <Select
                        size='md'
                        placeholder={ isEmpty(selectedMembers) ? 'Choose Person' : `${ selectedMembers.length } person(s) selected` }
                        options={ [] }
                        isDisabled
                    />
                </FormItem>
            </Dropdown>
        </SelectWrapper>
    );
}
