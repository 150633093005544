import { isObject, transform } from "lodash-es";

export function mapDeepObject(
    object: Record<string, any>,
    replaceCallback: (params: {
    key: string;
    value: Record<string, any>;
  }) => Record<string, any> | string
) {
    return transform(
        object,
        (
            result: Record<string, any>,
            value,
            key
        ) => {
            if (isObject(value)) {
                result[ key ] = replaceCallback({ key, value }) || mapDeepObject(value, replaceCallback);
            } else {
                result[ key ] = replaceCallback({ key, value }) || value;
            }
        }
    );
}
