import * as React                               from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { TransitionGroup }                      from "react-transition-group";
import { DI }                                   from '@geenee/geeclient-kit/src/lib/context/di.context';
import {
    ISceneExplorerComponentProps,
    SceneExplorerWithDi
} from "@geenee/geespector/src/components/sceneExplorer/sceneExplorerComponent";
import { createDIContext }                           from "@geenee/geespector/src/createDIContext";
import { ModalProvider, ToastComponent, ToastProps } from "@geenee/ui";
import { observer, Provider }                        from "mobx-react";
import { ThemeProvider }                             from "styled-components";

export const SceneExplorerComponent = observer((props: ISceneExplorerComponentProps) => {
    const container = useMemo(() => createDIContext(), []);
    const [ toast, setToast ] = useState<ToastProps['toast']>({ severity: '', summary: '', detail: '' });

    const Modal = container.get('<Modal>');
    const WithModal = container.get('<WithModal>');
    const SceneCommander = container.get('<SceneCommander>');
    const BuilderState = container.get('<BuilderState>');
    useEffect(() => {
        BuilderState?.initProfile();
    }, []);

    useEffect(() => {
        props.globalState.SceneCommander = SceneCommander;
        props.globalState.showToast = (value: ToastProps['toast']) => {
            setToast(value);
        };
    }, [ SceneCommander ]);

    return (
        <DI.Provider value={ { container } }>
            <Provider { ...{ AppState: container.get('<AppState>'), BuilderState, container } }>
                <ThemeProvider theme={ container.get('<Theme>') }>
                    <WithModal>
                        <ModalProvider
                            rootComponent={ TransitionGroup }
                        >
                            <Modal />
                            <SceneExplorerWithDi { ...props } />
                            <ToastComponent
                                position="center"
                                toast={ toast }
                            />
                        </ModalProvider>
                    </WithModal>
                </ThemeProvider>
            </Provider>
        </DI.Provider>
    );
});
