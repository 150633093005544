import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '12',
    height = '12',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="6" cy="6" r="5.5" fill="#D8D8D8" stroke="#BBBBBB" />
            <path
                d="M8.39143 5.831H6.34543V3.818H5.65243V5.831H3.60643V6.48H5.65243V8.482H6.34543V6.48H8.39143V5.831Z"
                fill="black"
            />
        </svg>
    );
}
