import React, { FC, forwardRef, memo, Suspense, useMemo }                          from 'react';
import { createAsset }                                                             from 'use-asset';
import { cn }                                                                      from '../../util/bem';
import { MediaFitUnionType, MediaRatioUnionType, RadiusSizesType, SizesUnionType } from "../../util/global-props";
import { loadImage }                                                               from '../../util/loadImage';
import { Image }                                                                   from '../img/img.component';
import { Lottie }                                                                  from '../lottie/lottie.component';
import './media.component.scss';

export type MediaComponentPropsType = {
  className?: string;
  url?: string;
  alt?: string;
  animationData?: { [key: string]: any };
  autoplay?: boolean;
  loop?: boolean;
  play?: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
  // Only for animation
  speed?: number;
  playFrom?: number
  radius?: RadiusSizesType;
  onLoad?: ()=> void

  padding?: SizesUnionType;
  align?: 'start' | 'center' | 'end';
  ratio?: MediaRatioUnionType;
  size?: SizesUnionType;
  type: 'animation' | 'image' | 'video';
  fit?: MediaFitUnionType;
  // how it works fit and ratio ask Elton

  fallback?: React.ReactNode;
  styles?: React.CSSProperties;
  onClick?: React.EventHandler<React.MouseEvent<HTMLDivElement, PointerEvent>>;
  onMouseDown?: React.EventHandler<React.MouseEvent<HTMLDivElement, PointerEvent>>;
  onMouseLeave?: React.EventHandler<React.MouseEvent<HTMLDivElement>>;
  onMouseEnter?: React.EventHandler<React.MouseEvent<HTMLDivElement>>;
};

const loadedImage = createAsset(async (url) => loadImage(url));

const className = cn('media');

const Media = memo(forwardRef<HTMLDivElement, MediaComponentPropsType>((props, ref: React.Ref<HTMLDivElement>) => {
    const {
        url,
        alt = 'alt',
        autoplay,
        loop,
        play,
        placeholder,
        speed,
        playFrom,
        align,
        ratio,
        size,
        type,
        fit,
        styles,
        style,
        padding,
        onClick,
        onMouseDown,
        onMouseLeave,
        onMouseEnter,
        animationData

    } = props;

    const processedUrl = url;
    const content = useMemo(() => {
        switch (props.type) {
            case 'animation':
                return (
                    <Lottie
                        className={ placeholder ? className('hasPlaceholder', { hide: !play }) : '' }
                        speed={ speed }
                        autoplay={ autoplay }
                        loop={ loop }
                        start={ play }
                        src={ !animationData ? processedUrl : undefined }
                        animationData={ animationData }
                        playFrom={ playFrom }
                        onLoad={ props.onLoad }
                    />
                );
            case 'video':
            case 'image':
                return (
                    <>
                        { processedUrl && (
                            <Image
                                radius={ props.radius }
                                alt={ alt }
                                src={ processedUrl }
                            />
                        ) }
                        { !processedUrl && !!placeholder && (
                            <Image radius={ props.radius } alt={ alt } src={ placeholder } />
                        ) }
                    </>
                );
            default:
                return null;
        }
    }, [ processedUrl, autoplay, loop, play, placeholder, speed, playFrom, type ]);
    return (
        <div
            className={ className({
                fit, size, padding, hasPlaceholder: !!placeholder, align, ratio
            }, null, props.className) }
            ref={ ref }
            onClick={ onClick }
            onMouseEnter={ onMouseEnter }
            onMouseLeave={ onMouseLeave }
            onMouseDown={ onMouseDown }
            style={ { ...style, ...styles } }
        >
            { (type === 'animation' || type === 'video') && placeholder ? (
                <Image radius={ props.radius } className={ className('image-placeholder', { hide: play }) } src={ placeholder } alt={ alt } />
            ) : null }
            { content }
        </div>
    );
}));

Media.displayName = 'Media';

export const MediaContent: FC<MediaComponentPropsType> = memo(forwardRef<HTMLDivElement, MediaComponentPropsType>((props, ref) => (
    <Suspense fallback={ props.fallback || null }>
        <Media { ...props } ref={ ref } />
    </Suspense>
)));
