import React, { useEffect, useMemo, useState } from "react";
import { useModalWithIframe }                  from "@geenee/builder/src/components/ModalWithIframe/useModalWithIframe";
import envConfig                               from "@geenee/builder/src/lib/envConfig";
import { DeleteCommand }                       from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/commands/DeleteCommand";
import { OpenCustomCodeCommand }               from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/commands/OpenCustomCodeCommand";
import { PlayAnimationCommand }                from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/commands/PlayAnimationCommand";
import { PlayAudioCommand }                    from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/commands/PlayAudioCommand";
import { ShowHideCommand }                     from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/commands/ShowHideCommand";
import { DIContextType }                       from "@geenee/geeclient-kit/src/lib/context/di.context";
import { useInject }                           from "@geenee/shared/src/hook/use-inject.hook";
import { AtomModel }                           from "@geenee/shared/src/magellan/atom/model/atom.model";
import { withDIContext }                       from "@geenee/shared/src/magellan/hoc/withDIContext.hoc";
import { PopoverModel }                        from "@geenee/shared/src/magellan/model/popover.model";
import { MoleculeModel }                       from "@geenee/shared/src/magellan/molecule/model/molecule.model";
import {
    TREE_JS_SCENE_POPOVER_SCHEMA,
    TREE_JS_SCENE_POPOVER_SCHEMA_AUDIO,
    TREE_JS_SCENE_POPOVER_SCHEMA_ROOT,
    TREE_JS_SCENE_POPOVER_SCHEMA_ROOT_WITHOUT_ANIMATION,
    TREE_JS_SCENE_POPOVER_SCHEMA_SNIPPET,
    TREE_JS_SCENE_POPOVER_SCHEMA_TRACKER_IMAGE,
    TREE_JS_SCENE_POPOVER_SCHEMA_WITHOUT_ANIMATION
} from "@geenee/shared/src/util/popover-templates";
import { Button, PopoverContainer, Wrapper } from "@geenee/ui";
import { observer }                          from "mobx-react";

type Props = {
  popoverSchema?: "full" | "audio" | "rootNode" | "trackerImage";
  enableVisibleEye?: boolean;
  enableCustomCode?: boolean;
  enablePlayAudio?: boolean;
  enablePlayAnimation?: boolean;
  currentModel?: AtomModel | MoleculeModel;
  index: number;
  onDelete?: () => void;
  onClickAnimation?: () => void;
  isAnimationActive?: boolean | null;
}

const POPOVER_TEMPLATES = {
    full:                     TREE_JS_SCENE_POPOVER_SCHEMA,
    fullWithoutAnimation:     TREE_JS_SCENE_POPOVER_SCHEMA_WITHOUT_ANIMATION,
    audio:                    TREE_JS_SCENE_POPOVER_SCHEMA_AUDIO,
    rootNode:                 TREE_JS_SCENE_POPOVER_SCHEMA_ROOT,
    rootNodeWithoutAnimation: TREE_JS_SCENE_POPOVER_SCHEMA_ROOT_WITHOUT_ANIMATION,
    trackerImage:             TREE_JS_SCENE_POPOVER_SCHEMA_TRACKER_IMAGE,
    snippet:                  TREE_JS_SCENE_POPOVER_SCHEMA_SNIPPET
};
export const ActionsList = withDIContext(observer((props: Props & { diContext: DIContextType }) => {
    const { CustomCodeTransparentButtonComponent } = props.diContext;
    const {
        BuilderState,
        AppState,
        SceneState
    } = useInject();
    const [ iframeUrl, setIframeUrl ] = useState("");
    const [ showModal ] = useModalWithIframe({
        url:     iframeUrl,
        onClose: () => setIframeUrl("")
    });
    const {
        activeMolecule,
        commander
    } = AppState;
    const [ popover, setPopover ] = useState<PopoverModel | null>(null);

    useEffect(() => {
        if (iframeUrl) {
            showModal();
        }
    }, [ iframeUrl ]);
    const visibleIconParam = props.currentModel instanceof MoleculeModel
        ? !SceneState.allModelsHidden : props.currentModel?.sceneObjectVisible;

    const playAnimationParam = props.currentModel instanceof MoleculeModel
        ? SceneState.allAnimationsActive : props.currentModel?.options.scene_atom_selected_animation_index === props.index;
    const commands = useMemo(() => {
        if (!activeMolecule || !props.currentModel) {
            return null;
        }
        return ({
            delete:         new DeleteCommand(SceneState, props.currentModel as AtomModel),
            playAudio:      new PlayAudioCommand(SceneState, props.currentModel as AtomModel),
            showHide:       new ShowHideCommand(SceneState, props.currentModel),
            openCustomCode: new OpenCustomCodeCommand(SceneState, props.currentModel),
            playAnimation:  new PlayAnimationCommand(
                SceneState,
                props.currentModel,
                props.currentModel.options.scene_atom_selected_animation_index === props.index ? null : props.index
            )
        });
    }, [ props.currentModel, props.currentModel?.options?.scene_atom_selected_animation_index, props.index ]);
    return (
        <Wrapper row align="right">
            { props.enableCustomCode ? (
                <CustomCodeTransparentButtonComponent
                    currentModel={ props.currentModel as any }
                />
            ) : null }
            { props.enablePlayAnimation ? (
                <Button
                    icon={ playAnimationParam ? "stop" : "playCircle" }
                    size="xs"
                    radius="sm"
                    viewType="action"
                    onClick={ () => {
                        commands && commander.executeCommand(commands?.playAnimation);
                    } }
                />
            ) : null }
            { props.enablePlayAudio ? (
                <Button
                    icon={ playAnimationParam ? "stop" : "playCircle" }
                    size="xs"
                    radius="sm"
                    viewType="action"
                    onClick={ () => {
                        commands && commander.executeCommand(commands.playAudio);
                    } }
                />
            ) : null }
            { props.enableVisibleEye ? (
                <Button
                    icon={ visibleIconParam ? "eyeOn" : "eyeOff" }
                    size="xs"
                    radius="sm"
                    viewType="action"
                    onClick={ () => commands && commander.executeCommand(commands.showHide) }
                />
            ) : null }
            { props.popoverSchema && commands ? (
                <PopoverContainer
                    margin="md"
                    popoverSchema={
                        // @ts-ignore
                        popover?.toJSON()
                    }
                    onClose={ () => {
                        setPopover(BuilderState.closePopover());
                    } }
                    onSelected={ (item) => {
                        switch (item.label) {
                            case "Show/Hide":
                                commander.executeCommand(commands.showHide);
                                break;
                            case "Custom code":
                                commander.executeCommand(commands.openCustomCode);
                                break;
                            case "Delete":
                                commander.executeCommand(commands.delete);
                                break;
                            case "Play Animation":
                                commander.executeCommand(commands.playAnimation);
                                break;
                            case "Edit": {
                                SceneState.setCodeModuleId(props.currentModel?.id);
                                setIframeUrl(
                                    `${ envConfig.CUSTOM_CODE_EDITOR_URL }`
                                    //     /?json=${
                                    //         props.currentModel?.firstAsset?.url
                                    //     }&token=${ localStorage.getItem("authorization") }&referrer=${
                                    //         window.location.href }&storage=${ JSON.stringify({ ...localStorage }) }`
                                );
                                break;
                            }
                            case "Replace":
                                if (props.popoverSchema === "trackerImage") {
                                    popover?.eventEmitter.emit("replace-tracker-image", props.currentModel?.id);
                                }
                                break;
                            case "Play/Stop":
                                commander.executeCommand(commands.playAudio);
                                break;
                            case "Rename":
                                popover?.eventEmitter.emit("rename", props.currentModel?.id);
                                break;
                            default:
                                break;
                        }
                    } }
                >
                    <Button
                        icon="more"
                        size="xs"
                        radius="sm"
                        viewType="action"
                        onClick={ () => {
                            if (popover) {
                                setPopover(BuilderState.closePopover());
                            } else if (props.popoverSchema) {
                                if ((props.popoverSchema === "rootNode" || props.popoverSchema === "full") && !props.enablePlayAnimation) {
                                    setPopover(BuilderState.createPopover(POPOVER_TEMPLATES[ `${ props.popoverSchema }WithoutAnimation` ]));
                                } else {
                                    setPopover(BuilderState.createPopover(POPOVER_TEMPLATES[ props.popoverSchema ]));
                                }
                            }
                        } }
                    />
                </PopoverContainer>
            ) : null }
        </Wrapper>
    );
}));
