import React                                 from 'react';
import { cn, Description, Tooltip, Wrapper } from '@geenee/ui';
import { TransformModes }                    from '../../type';
import { TransformButton }                   from './TransformButton';
import './transformation-modes.styles.scss';

export const TOOLTIP_DELAY = 1500;

interface Props {
    rendererMode?: 'three' | 'babylon'
    transformMode: TransformModes
    setTransformMode: (value: TransformModes) => void
    disabled?: boolean
    styles?: React.CSSProperties
    className?: string
}

const className = cn('trans-mode-switcher');

function TooltipContent({ text, hotkey }: {text: string, hotkey: string}) {
    return (
        <Wrapper row>
            <Description margin="xxs">{ text }</Description>
            <Description color="dark">{ hotkey }</Description>
        </Wrapper>
    );
}

export function TransformationModesSwitcher({
    transformMode,
    rendererMode,
    setTransformMode,
    disabled,
    styles = {},
    className: propsClassName
}: Props) {
    const isThreeMode = rendererMode === 'three';
    const isBabylonMode = rendererMode === 'babylon';
    return (
        <Wrapper
            align="space-between"
            valign="center"
            radius="xxl"
            frame="solid-opacity-white"
            blur
            shadow="md"
            maxWidth="fit-content"
            row
            padding="xxs"
            className={ className('root') }
            styles={ styles }
        >
            <Tooltip
                shadow
                position='bottom'
                message={ <TooltipContent text="Move" hotkey="W" /> }
                margin="xxs"
                minWidth="70px"
                delay={ TOOLTIP_DELAY }
            >
                <TransformButton
                    disabled={ disabled }
                    icon="move"
                    iconSize={ 24 }
                    transformMode={ TransformModes.translate }
                    currentTransformMode={ transformMode }
                    onClick={ () => {
                        setTransformMode(TransformModes.translate);
                    } }
                />
            </Tooltip>
            <Tooltip
                shadow
                position='bottom'
                message={ <TooltipContent text="Rotate" hotkey="E" /> }
                margin="xxs"
                minWidth="70px"
                delay={ TOOLTIP_DELAY }
            >
                <TransformButton
                    disabled={ disabled }
                    icon="rotate"
                    iconSize={ 24 }
                    transformMode={ TransformModes.rotate }
                    currentTransformMode={ transformMode }
                    onClick={ () => {
                        setTransformMode(TransformModes.rotate);
                    } }
                />
            </Tooltip>
            <Tooltip
                shadow
                position='bottom'
                message={ <TooltipContent text="Scale" hotkey="R" /> }
                margin="xxs"
                delay={ TOOLTIP_DELAY }
                minWidth="65px"
            >
                <TransformButton
                    disabled={ disabled }
                    icon="scale"
                    iconSize={ 24 }
                    transformMode={ TransformModes.scale }
                    currentTransformMode={ transformMode }
                    onClick={ () => {
                        setTransformMode(TransformModes.scale);
                    } }
                />
            </Tooltip>
            { isBabylonMode
              && (
                  <Tooltip
                      shadow
                      position='bottom'
                      message={ <TooltipContent text="Transformation" hotkey="T" /> }
                      minWidth="120px"
                      delay={ TOOLTIP_DELAY }
                  >
                      <TransformButton
                          disabled={ disabled }
                          icon="boundingBox"
                          iconSize={ 21.5 }
                          transformMode={ TransformModes.boundingBox }
                          currentTransformMode={ transformMode }
                          onClick={ () => {
                              setTransformMode(TransformModes.boundingBox);
                          } }
                      />
                  </Tooltip>
              ) }
            { isThreeMode && (
                <Tooltip
                    shadow
                    position="bottom"
                    message="Lightning"
                    margin="off"
                    minWidth="50px"
                    delay={ TOOLTIP_DELAY }
                >
                    <TransformButton
                        disabled={ disabled }
                        icon="directLight"
                        iconSize={ 24 }
                        transformMode={ TransformModes.lightning }
                        currentTransformMode={ transformMode }
                        onClick={ () => {
                            setTransformMode(TransformModes.lightning);
                        } }
                    />
                </Tooltip>
            ) }
        </Wrapper>
    );
}
