import { useEffect }          from 'react';
import { BabylonRenderer }    from "@geenee/geespector/renderer/babylonjs.renderer";
import { FillSceneCommand }   from '@geenee/geespector/src/commands/FillSceneCommand';
import { PrefabCommand }      from '@geenee/geespector/src/commands/PrefabCommand';
import { useInject }          from '@geenee/shared';
import { SceneCommanderType } from '@geenee/shared/src/commander/scene-commander';
import { AtomModel }          from '~/magellan/model/atom.model';

export const useLoadedModels = (sceneActorAtoms: AtomModel[], sceneRenderer?: BabylonRenderer, type?: string) => {
    const { container, AppState } = useInject();
    const SceneCommander = container.get('<SceneCommander>') as SceneCommanderType;
    useEffect(() => {
        if (sceneRenderer) {
            const { activeSection } = AppState;
            const { activeMolecule, sceneManager } = activeSection || { activeMolecule: null, sceneManager: null };
            sceneRenderer.rendererType = type || '';

            if (!activeMolecule?.loading) {
                if (!sceneActorAtoms.length) {
                    const addPrefabCommand = new PrefabCommand(sceneRenderer, type || '');
                    SceneCommander.executeCommand(addPrefabCommand);
                } else {
                    const fillSceneCommand = new FillSceneCommand(sceneRenderer, sceneActorAtoms, type || '');
                    SceneCommander.executeCommand(fillSceneCommand);
                }
            }
            sceneManager?.createSceneGrid();
            SceneCommander.startRecordHistory();
        }
    }, [ sceneRenderer ]);
};
