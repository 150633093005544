import React, { memo, useEffect, useRef, useState } from 'react';
import StudioPanelsHDR                              from "@geenee/assets/lib/images/environments/2_Front_Panels_Soft_Backlight.env";
import StudioPanelsLight                            from "@geenee/assets/lib/images/environments/2_Front_Panels_Soft_Backlight_Thumb.png";
import StudioLowKeyHDR                              from "@geenee/assets/lib/images/environments/2_Point_Low_Key.env";
import StudioLowKeyLight                            from "@geenee/assets/lib/images/environments/2_Point_Low_Key_Thumb.png";
import StudioKeyAndFillHDR                          from "@geenee/assets/lib/images/environments/3_Point_Soft_Overhead.env";
import StudioKeyAndFillLight                        from "@geenee/assets/lib/images/environments/3_Point_Soft_Overhead_Thumb.png";
import StudioBacklightArchHDR                       from "@geenee/assets/lib/images/environments/Backlight_Arch_A.env";
import StudioBacklightArchLight                     from "@geenee/assets/lib/images/environments/Backlight_Arch_A_Thumb.png";
import StudioFrontKeyHDR                            from "@geenee/assets/lib/images/environments/Front_Key_Rear_Panels.env";
import StudioFrontKeyLight                          from "@geenee/assets/lib/images/environments/Front_Key_Rear_Panels_Thumb.png";
import IceLakeHDR                                   from "@geenee/assets/lib/images/environments/Ice_Lake.env";
import IceLakeLight                                 from "@geenee/assets/lib/images/environments/Ice_Lake_Thumb.jpg";
import StudioLightArchesAHDR                        from "@geenee/assets/lib/images/environments/Light_Arches_A.env";
import StudioLightArchesALight                      from "@geenee/assets/lib/images/environments/Light_Arches_A_Thumb.png";
import StudioLightArchesBHDR                        from "@geenee/assets/lib/images/environments/Light_Arches_B.env";
import StudioLightArchesBLight                      from "@geenee/assets/lib/images/environments/Light_Arches_B_Thumb.png";
import StudioLightArchesCHDR                        from "@geenee/assets/lib/images/environments/Light_Arches_C.env";
import StudioLightArchesCLight                      from "@geenee/assets/lib/images/environments/Light_Arches_C_Thumb.png";
import StudioLightPillarsDarkHDR                    from "@geenee/assets/lib/images/environments/Light_Pillars_Dark_A.env";
import StudioLightPillarsDarkLight                  from "@geenee/assets/lib/images/environments/Light_Pillars_Dark_A_Thumb.png";
import MallorcaRoadLight                            from "@geenee/assets/lib/images/environments/Mallorca_Road_D_thumb.jpg";
import MallorcaRoadHDR                              from "@geenee/assets/lib/images/environments/MallorcaRoad.env";
import StudioColorStageAHDR                         from "@geenee/assets/lib/images/environments/Studio_80S_Horror_Flick_A.env";
import StudioColorStageALight                       from "@geenee/assets/lib/images/environments/Studio_80S_Horror_Flick_A_Thumb.png";
import StudioColorStageBHDR                         from "@geenee/assets/lib/images/environments/Studio_80S_Horror_Flick_B.env";
import StudioColorStageBLight                       from "@geenee/assets/lib/images/environments/Studio_80S_Horror_Flick_B_Thumb.png";
import StudioSoftbox3PointHDR                       from "@geenee/assets/lib/images/environments/Studio_Softboxes_3_Point.env";
import StudioSoftbox3PointLight                     from "@geenee/assets/lib/images/environments/Studio_Softboxes_3_Point_Thumb.png";
import SunriseCampsiteLight                         from "@geenee/assets/lib/images/environments/Sunrise_Campsite_thumb.jpg";
import SunriseCampsiteHDR                           from "@geenee/assets/lib/images/environments/SunriseComposite.env";
import TopangaForestLight                           from "@geenee/assets/lib/images/environments/Topanga_Forest_B_thumb.jpg";
import TopangaForestHDR                             from "@geenee/assets/lib/images/environments/TopangaForest.env";
import StudioWarmKeyHDR                             from "@geenee/assets/lib/images/environments/Warm_Key_Cool_Fill.env";
import StudioWarmKeyLight                           from "@geenee/assets/lib/images/environments/Warm_Key_Cool_Fill_Thumb.png";
import StudioWoodWindowsHDR                         from "@geenee/assets/lib/images/environments/Wood_Studio_Windows.env";
import StudioWoodWindowsLight                       from "@geenee/assets/lib/images/environments/Wood_Studio_Windows_Thumb.png";
import { getFileFromUrl }                           from "@geenee/builder/src/lib/getFileFromUrl";
import { LoadingSpinnerCentered }                   from "@geenee/shared/src/magellan/components/LoadingSpinner";
import {
    Card, cn, Description, Divider, MediaContent, Wrapper
} from "@geenee/ui";
import GlobalState from "../../../globalState";
import './environmentSelectorComponent.scss';

const className = cn("environment-selector");

const data = [ {
    imgSrc:  StudioLowKeyLight,
    label:   "Studio Low Key Light",
    hdrFile: StudioLowKeyHDR
}, {
    imgSrc:  StudioPanelsLight,
    label:   "Studio Panels Light",
    hdrFile: StudioPanelsHDR
},
{
    imgSrc:  StudioFrontKeyLight,
    label:   "Studio Front Key Light",
    hdrFile: StudioFrontKeyHDR
},
{
    imgSrc:  StudioKeyAndFillLight,
    label:   "Studio Key and Fill Light",
    hdrFile: StudioKeyAndFillHDR
},
{
    imgSrc:  StudioWarmKeyLight,
    label:   "Studio Warm Key Light",
    hdrFile: StudioWarmKeyHDR
},
{
    imgSrc:  StudioWoodWindowsLight,
    label:   "Studio Wood Windows",
    hdrFile: StudioWoodWindowsHDR
},
{
    imgSrc:  StudioLightArchesALight,
    label:   "Studio Light Arches A",
    hdrFile: StudioLightArchesAHDR
},
{
    imgSrc:  StudioLightArchesBLight,
    label:   "Studio Light Arches B",
    hdrFile: StudioLightArchesBHDR
},
{
    imgSrc:  StudioLightArchesCLight,
    label:   "Studio Light Arches C",
    hdrFile: StudioLightArchesCHDR
},
{
    imgSrc:  StudioBacklightArchLight,
    label:   "Studio Backlight Arch",
    hdrFile: StudioBacklightArchHDR
},
{
    imgSrc:  StudioLightPillarsDarkLight,
    label:   "Studio Light Pillars Dark",
    hdrFile: StudioLightPillarsDarkHDR
},
{
    imgSrc:  StudioSoftbox3PointLight,
    label:   "Studio Softbox 3 Point",
    hdrFile: StudioSoftbox3PointHDR
}, {
    imgSrc:  StudioColorStageALight,
    label:   "Studio Color Stage A",
    hdrFile: StudioColorStageAHDR
}, {
    imgSrc:  StudioColorStageBLight,
    label:   "Studio Color Stage B",
    hdrFile: StudioColorStageBHDR
},
{
    imgSrc:  IceLakeLight,
    label:   "Ice Lake",
    hdrFile: IceLakeHDR
},
{
    imgSrc:  MallorcaRoadLight,
    label:   "Mallorca Road",
    hdrFile: MallorcaRoadHDR
}, {
    imgSrc:  SunriseCampsiteLight,
    label:   "Sunrise Campsite",
    hdrFile: SunriseCampsiteHDR
}, {
    imgSrc:  TopangaForestLight,
    label:   "Topanga Forest",
    hdrFile: TopangaForestHDR
}

];

const EnvCard = ({ imgSrc, label, onClick, isActive = false }: {imgSrc: string, label: string, onClick: () => void, isActive: boolean}) => (
    <Card
        styles={ { height: '104px' } }
        fullHeight
        hoverShadow="self"
        maxWidth="48%"
        padding="xxs"
        radius="sm"
        mediaBefore
        onClick={ async () => {
            onClick();
        } }
        frame="transparent-hover-white-violet"
        className={ className("image", { "is-active": isActive }) }
        media={ (
            <MediaContent
                fallback={ <LoadingSpinnerCentered /> }
                url={ imgSrc }
                fit="cover"
                type="image"
                ratio="auto"
                size="lg"
                radius="sm"
                align="center"
            />
        ) }
    >
        <Wrapper>
            <Divider transparent margin="xxs" />
            <Description
                noWrap
                align="center"
                size="xs"
                lineHeight="lg"
                weight="medium"
                color="shade-1"
            >
                { label }
            </Description>
        </Wrapper>
    </Card>
);

const filesCache: Record<string, File> = {};
export const EnvironmentSelectorComponent = memo(({
    onChange,
    defaultEnvironment = ""
}: { onChange: (file: File) => void, defaultEnvironment: string }) => {
    const [ selected, setSelected ] = useState(defaultEnvironment);
    const isLocalUpadaed = useRef(false);

    const getFilename = (url: string): string => url.split("/").pop() || '';
    const onClick = async (hdrFileUrl: string) => {
        const hdrFile = filesCache[ hdrFileUrl ] || await getFileFromUrl(hdrFileUrl, hdrFileUrl.split("/").pop());
        filesCache[ hdrFileUrl ] = hdrFile;
        onChange(hdrFile);
        setSelected(getFilename(hdrFileUrl));
    };

    useEffect(() => {
        GlobalState.onPropertyChangedObservable.add((e) => {
            if (isLocalUpadaed.current) {
                isLocalUpadaed.current = false;
                return;
            }
            if (e.property === "environmentTexture") {
                setSelected(e.value.metadata?.gltf?.extras?.fileName);
            }
        });
    }, [ ]);

    return (
        <Wrapper>
            <Divider transparent margin="xs" />
            <Wrapper row wrap className={ className('root') }>
                { data.map((el, index) => (
                    <EnvCard
                        key={ el.imgSrc }
                        onClick={ () => {
                            isLocalUpadaed.current = true;
                            onClick(el.hdrFile);
                        } }
                        imgSrc={ el.imgSrc }
                        label={ el.label }
                        isActive={ selected === getFilename(el.hdrFile) }
                    />
                )) }
            </Wrapper>
        </Wrapper>
    );
});
