import { SectionTypeUnion } from '@geenee/shared/type/section.type';
import isEmpty              from 'lodash-es/isEmpty';
import Templates            from '@geenee/builder/src/lib/nodeTemplates';
import { MagellanState }    from '@geenee/builder/src/magellan/state/app.state';

const urlResult = (fullPath: string[]) => (isEmpty(fullPath) ? undefined : `/${ fullPath.join('/') }`);

export type CreateHierarchyContentType = SectionTypeUnion<'slam-ar' | 'stamp-ar'> | 'program'
export const createHierarchy = async (
    AppState: MagellanState,
    contentType: CreateHierarchyContentType
) => {
    const fullPath: string[] = [];
    const project = await AppState.createProject();
    fullPath.push(project.id);
    const experience = await project.createExperience();
    fullPath.push(experience.id);
    if (contentType !== 'program') {
        const contentNames = {
            view:    '',
            section: ''
        };
        if (contentType === 'slam-ar') {
            contentNames.view = 'SLAM_AR_VIEW';
            contentNames.section = 'SLAM_AR';
        }
        if (contentType === 'stamp-ar') {
            contentNames.view = 'STAMP_AR_VIEW';
            contentNames.section = 'STAMP_AR';
        }
        const view = await experience.createView(Templates[ contentNames.view ]);
        const section = await view.createSection(Templates[ contentNames.section ]);
        await section.createMolecule({ type: 'scene-build' });
        fullPath.push(...[ view.id, section.id ]);
    }
    return urlResult(fullPath);
};
