import React, { ReactElement } from 'react';
import { Description }         from '@geenee/ui';
import {
    HintPlaceholder,
    HintWrapper
} from './styles';

type HintProps = {
  iconElement?: ReactElement;
  title?: string | ReactElement;
  text: string;
};

function Hint({ title, text, iconElement }: HintProps) {
    return (
        <HintPlaceholder>
            <HintWrapper>
                <div className="hint-content">
                    { iconElement && (
                        <div className="img-container">
                            { iconElement }
                        </div>
                    ) }
                    <div className="text-container">
                        { title && (
                            <Description size='sm' style={ { fontWeight: 700, fontStyle: 'italic' } }>
                                { title }
                            </Description>
                        ) }
                        <Description size='xs' style={ { fontStyle: 'italic' } }>
                            { text }
                        </Description>
                    </div>
                </div>
            </HintWrapper>
        </HintPlaceholder>
    );
}

// eslint-disable-next-line arca/no-default-export
export default Hint;
