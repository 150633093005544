import React                    from 'react';
import { useHistory }           from "@geenee/common";
import { Description, Wrapper } from '@geenee/ui';
import { Container, Link }      from './styles';

export function NoProjects() {
    const history = useHistory();
    return (
        <Container>
            <Wrapper fullWidth>
                <Description style={ { fontSize: '25px' } } margin='md'>
                    You do not have any NFTs.
                </Description>
                { /* <Description size='md' margin='sm'>
                Would you like to turn your WebAR Project into an NFT?
            </Description>
            <Wrapper margin='xl'>
                <RaisedButton
                    $gradient
                    $padding="0 23px"
                    $justify="space-between"
                    $width="144px"
                    $height="61px"
                    onClick={ () => { alert('open "Let’s do this." modal'); } }
                    text="YES"
                    iconElement={ (
                        <NextArrow width="30" height="30" />
                    ) }
                />
            </Wrapper> */ }
                <Description>
                    Want to learn more? Check out our blog post -
                    { ' ' }
                    <Link onClick={ () => history.push('/getting-started/13') }>Hold on, What are NFTs?</Link>
                </Description>
            </Wrapper>
        </Container>
    );
}
