import React, { useEffect, useState } from 'react';
import { LoadingSpinnerCentered }     from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { Wrapper }                    from '@geenee/ui';
import { observer }                   from 'mobx-react';
import { nftState }                   from '@geenee/builder/src/core/state/nft.state';
import { NoProjects }                 from '../NoProjects';
import { SingleProjectNFT }           from '../SingleProjectNFT';
import { ProjectsWrapper }            from './styles';

export const ProjectsList = observer(() => {
    const [ isLoading, setIsLoading ] = useState(true);
    useEffect(() => {
        nftState.getNftsList().then(() => setIsLoading(false));
    }, []);
    const { nftItems } = nftState;
    if (isLoading) {
        return <LoadingSpinnerCentered />;
    }

    const noProjects = !nftItems.length;

    return (
        <Wrapper fullWidth>
            { noProjects && <NoProjects /> }
            { !noProjects && (
                <ProjectsWrapper>
                    { nftItems.map((p) => (
                        <SingleProjectNFT key={ p.listing_id } item={ p } />
                    )) }
                </ProjectsWrapper>
            ) }
        </Wrapper>
    );
});
