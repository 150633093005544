import type { ProjectType }     from '@geenee/shared';
import { injectable }      from 'inversify';
import { FactoryAbstract } from '../../util/factoryContainer';
import { AppStateType }    from '../state/app.state';

@injectable()
export class ProjectFactory extends FactoryAbstract {
    async create(projectSchema: ProjectType, $parent?: AppStateType) {
        const projectModel = this.container.get('<ProjectModel>');
        const experienceFactory = this.container.get('<ExperienceFactory>');
        // programModel.experiences = programSchema.experiences;

        projectSchema?.experiences?.forEach((experience) => {
            const experienceModel = experienceFactory.create(experience, projectModel);
            projectModel.experiencesRegistry.set(experienceModel.id, experienceModel);
        });

        Object.assign(projectModel, { ...projectSchema });
        if ($parent) {
            projectModel.$parent = $parent;
        }
        // projectModel.initCode();
        return projectModel;
    }
}

export type ProjectFactoryType = ProjectFactory;
