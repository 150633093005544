import SensorSample from './SensorSample';
import Quaternion from '../math/Quaternion';
import Vector3 from '../math/Vector3';
import { isIOS, isTimestampDeltaValid } from './util';

/**
 *
 */
export default class ComplementaryFilter {
  private kFilter: number;
  private filterQ: Quaternion;
  private accelQ: Quaternion;
  private isOrientationInitialized: boolean;
  private estimatedGravity: Vector3;
  private measuredGravity: Vector3;
  private gyroIntegralQ: Quaternion;
  private previousFilterQ: Quaternion;
  private currentAccelMeasurement: SensorSample;
  private currentGyroMeasurement: SensorSample;
  private previousGyroMeasurement: SensorSample;

  constructor(kFilter: number) {
    this.kFilter = kFilter;
    this.filterQ = new Quaternion(isIOS() ? -1 : 1, 0, 0, 1);
    this.accelQ = new Quaternion();
    this.isOrientationInitialized = false;
    this.estimatedGravity = new Vector3();
    this.measuredGravity = new Vector3();
    this.gyroIntegralQ = new Quaternion();
    this.previousFilterQ = new Quaternion();
    this.currentAccelMeasurement = new SensorSample();
    this.currentGyroMeasurement = new SensorSample();
    this.previousGyroMeasurement = new SensorSample();
    this.previousFilterQ.copy(this.filterQ);
  }

  getOrientation(): Quaternion {
    return this.filterQ;
  }

  addAccelMeasurement(sample: Vector3, timestamp: number) {
    this.currentAccelMeasurement.set(sample, timestamp);
  }

  addGyroMeasurement(sample: Vector3, timestamp: number) {
    this.currentGyroMeasurement.set(sample, timestamp);

    const deltaT = timestamp - this.previousGyroMeasurement.timestamp;
    if (isTimestampDeltaValid(deltaT)) {
      this.run();
    }
    this.previousGyroMeasurement.copy(this.currentGyroMeasurement);
  }

  /**
   *
   */
  run() {
    if (!this.isOrientationInitialized) {
      this.accelQ = this.accelToQuaternion(this.currentAccelMeasurement.sample);
      this.previousFilterQ.copy(this.accelQ);
      this.isOrientationInitialized = true;
      return;
    }

    const deltaT = this.currentGyroMeasurement.timestamp - this.previousGyroMeasurement.timestamp;

    // Convert gyro rotation vector to a quaternion delta.
    const gyroDeltaQ = this.gyroToQuaternionDelta(this.currentGyroMeasurement.sample, deltaT);
    this.gyroIntegralQ.multiply(gyroDeltaQ);

    // filter_1 = K * (filter_0 + gyro * dT) + (1 - K) * accel.
    this.filterQ.copy(this.previousFilterQ);
    this.filterQ.multiply(gyroDeltaQ);

    // Calculate the delta between the current estimated gravity and the real
    // gravity vector from accelerometer.
    const invFilterQ = new Quaternion();
    invFilterQ.copy(this.filterQ);
    invFilterQ.inverse();

    this.estimatedGravity.set(0, 0, -1);
    this.estimatedGravity.applyQuaternion(invFilterQ);
    this.estimatedGravity.normalize();

    this.measuredGravity.copy(this.currentAccelMeasurement.sample);
    this.measuredGravity.normalize();

    // Compare estimated gravity with measured gravity, get the delta quaternion
    // between the two.
    const deltaQ = new Quaternion();
    deltaQ.setFromUnitVectors(this.estimatedGravity, this.measuredGravity);
    deltaQ.inverse();

    // Calculate the SLERP target: current orientation plus the measured-estimated
    // quaternion delta.
    const targetQ = new Quaternion();
    targetQ.copy(this.filterQ);
    targetQ.multiply(deltaQ);

    // SLERP factor: 0 is pure gyro, 1 is pure accel.
    this.filterQ.slerp(targetQ, 1 - this.kFilter);

    this.previousFilterQ.copy(this.filterQ);
  }

  accelToQuaternion(accel: Vector3): Quaternion {
    const normAccel = new Vector3();
    normAccel.copy(accel);
    normAccel.normalize();
    const quat = new Quaternion();
    quat.setFromUnitVectors(new Vector3(0, 0, -1), normAccel);
    quat.inverse();
    return quat;
  }

  gyroToQuaternionDelta(gyro: Vector3, dt: number): Quaternion {
    const quat = new Quaternion();
    const axis = new Vector3();
    axis.copy(gyro);
    axis.normalize();
    quat.setFromAxisAngle(axis, gyro.length() * dt);
    return quat;
  }
}
