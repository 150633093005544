export const generateSquaredQRcode = (url: string) => `https://generator.flowcode.com/v1/flowcode?client_id=${ window.env.FLOW_CODE_CLIENT_ID }&url=${
    url }&moduleShape=circle&containerShape=square&containerPadding=20&containerBorderSize=0
        &containerBorderColor=transparent&dataGridPercentageSize=100&containerBorderSize=5&gridBackgroundColor=transparent&opts={
          "containerText": [
              {
                  "font": "inter_medium",
                  "text": "",
                  "fontSize": 9,
                  "position": {
                      "property": 7,
                      "offsetPercentage": 3.1
                  },
                  "fontColor": "%23000000"
              }
          ],
          "positionElementBottomLeft": {
          "fillSvg": false,
          "innerShape": 2,
          "outerShape": 2,
          "backgroundColor": "%23ffffff",
          "innerShapeColor": "%23000000",
          "innerShapeWidth": 45,
          "outerShapeColor": "%23000000",
          "outerShapeWidth": 15,
          "innerShapeOutlineSize": 0,
          "outerShapeOutlineSize": 0,
          "innerShapeOutlineColor": "%23000000",
          "outerShapeOutlineColor": "%23000000"
          }
      }`;

export const generateCircleQRCode = (url: string) => `https://generator.flowcode.com/v1/flowcode?client_id=${ window.env.FLOW_CODE_CLIENT_ID }&url=${
    url }&opts={"gridBackgroundColor":"%23ffffff",
    "containerBackgroundColor":"%23ffffff",
    "containerBorderColor":"%23000000",
    "containerOuterPatternColor":"%23000000",
    "containerOuterPatternColorFillSvg":true,
    "containerOuterPatternShape":1,
    "containerOuterPatternRandomSizes":"80",
    "containerShape":1,
    "gridModuleColor":"%23000000",
    "gridModuleColorFillSvg":true,
    "gridModuleShape":1,
    "gridModuleRandomSizes":"80"
}`;
