import React, { FC, memo }  from 'react';
import { Tooltip, Wrapper } from '@geenee/ui';
import { cn }               from '../../util/bem';
import { TooltipPropsType } from "../tooltip/tooltip.component";
import { WrapperPropsType } from '../wrapper/wrapper.component';

export type TabsItemPropsType = WrapperPropsType & {
    className?: string;
    children?: React.ReactNode & {ref?: React.Ref<HTMLElement>};
    tabId: string;
    selected?: boolean;
    disabled?: boolean;
    tooltip?: Omit<TooltipPropsType, 'children' | 'position'> & {
        position?: 'top'| 'bottom' | 'right' | 'left';
    },
    onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement, PointerEvent>>;
    onChange?: (e: React.MouseEvent, newTabId: string) => void;
};
export type Ref = HTMLButtonElement;

const className = cn('tabs-item');

// type ElemType<T> = T extends FC<infer P> ? P : never;

export const TabsItem: FC<TabsItemPropsType> = memo<FC<TabsItemPropsType>>(React.forwardRef<Ref, TabsItemPropsType>((props, ref) => {
    const handleChange = (e: React.MouseEvent<HTMLButtonElement, PointerEvent>) => {
        if (props.onClick) {
            props.onClick(e);
        } else if (props.onChange) {
            props.onChange(e, props.tabId);
        }
    };

    const tabsItemChildren = React.Children.map((props.children), (child) => {
        if (React.isValidElement(child)) {
            // @ts-ignore
            return React.cloneElement(child, {
                ref,
                onClick: handleChange,
                ...child.props
            });
        }
        return child;
    });

    return (
        <Wrapper { ...props } className={ className() }>
            { props.tooltip ? (
                <Tooltip
                    position={ props.tooltip.position || 'bottom' }
                    menuPadding={ props.tooltip.menuPadding }
                    title={ props.tooltip.title }
                    delay={ props.tooltip.delay }
                    message={ props.tooltip.message }
                    minWidth="115px"
                    shadow
                >
                    { tabsItemChildren }
                </Tooltip>
            ) : tabsItemChildren }
        </Wrapper>
    );
}));

TabsItem.defaultProps = { disabled: false };
