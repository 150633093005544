// Usage example in atom/logo/util.ts
type addPrefix<TKey, TPrefix extends string> = TKey extends string
  // @ts-ignore
 ? `${TPrefix}${TKey}` : never;

type removePrefix<TPrefixedKey, TPrefix extends string> = TPrefixedKey extends addPrefix<infer TKey, TPrefix>
  ? TKey
  : '';

type prefixedValue<TObject extends object, TPrefixedKey extends string, TPrefix extends string> = TObject extends {[K in removePrefix<TPrefixedKey, TPrefix>]: infer TValue}
  ? TValue
  : never;

export type addPrefixToObject<TObject extends object, TPrefix extends string> = {
  [K in addPrefix<keyof TObject, TPrefix>]: prefixedValue<TObject, K, TPrefix>
}

export const getPrefixedKeysObject = (obj: Record<string, any>, prefix: string) => {
  return Object.keys(obj).reduce((acc, curr) => {
    acc[`${prefix}${curr}`] = obj[curr]
    return acc
  }, {} as Record<string, any>)
}

export const mapPrefixedPropsFromOwner = (prefixedObject: Record<string, any>, options: Record<string, any>) =>
  Object.keys(prefixedObject).reduce((acc, curr) => {
    acc[curr] = options[curr]
    return acc
  }, {} as Record<string, any>)

export const getUnprefixedObject = (prefix: string, props: Record<string, any>) => {
  const resultProps: Record<string, any> = {};

  Object.keys(props)
    .forEach((key) => {
      if (key.startsWith(prefix)) {
        // @ts-ignore
        resultProps[ key.replace(prefix, '') ] = props[ key ];
      }
    });
  return resultProps;
}
