import "@abraham/reflection";
import React                   from "react";
import type ReactDOM           from "react-dom";
import { createRoot }          from "react-dom/client";
import { AppModuleType, DI }   from "@geenee/common";
import { Container }           from "inversify";
import { MobXProviderContext } from "mobx-react";

type AppInstance = {
    render: (Component: React.ComponentType<{container: Container}>) => void;
    options: {
        elementId?: string
    };

}

export class AppFactory {
    public static bootstrap(appModule: AppModuleType, appOptions = { elementId: "app" }): AppInstance {
        const { di } = appModule as unknown as { di: DI };

        di.loadApplicationModule();

        const container = di.container as Container;

        const root = createRoot(document.getElementById(appOptions.elementId)) as ReturnType<createRoot>;
        const render = (Component: React.ComponentType<{ container: Container }>) => {
            root.render(
                <MobXProviderContext.Provider value={ { container } }>
                    <Component container={ container } />
                </MobXProviderContext.Provider>
            );
        };

        return {
            render,
            options: appOptions
        };
    }
}
