import * as React         from 'react';
import { TableRowProps }  from 'react-table';
import { tableClassName } from '../utils';

const className = tableClassName('tr');

type PropsType = {
    children?: React.ReactNode;
    rowProps: TableRowProps;
} ;

export const TableRow = React.memo((props: PropsType) => (
    <div { ...props.rowProps } className={ className }>
        { props.children }
    </div>
));
