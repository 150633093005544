import React from 'react';

interface Props {
  planNames: string[];
}

export function PlansTitles({ planNames }: Props) {
    return (
        <tr>
            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
            <th />
            <th className="basic-plan first-plan-cell">{ planNames[ 0 ] }</th>
            <td className="plan-filler" />
            <th className="studio-plan first-plan-cell">{ planNames[ 1 ] }</th>
            <td className="plan-filler" />
            <th className="pro-plan first-plan-cell">{ planNames[ 2 ] }</th>
        </tr>
    );
}
