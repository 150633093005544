import { useEffect, useState }   from 'react';
import { Analytics }             from '@geenee/analytics';
import { useInject }             from '@geenee/shared';
import useComposerLocationParser from '@geenee/shared/src/util/useComposerLocationParser';
import { usePublishProject }     from '@geenee/builder/src/components/ProjectsLinkDropdown/hooks/usePublishProject';
import { getDIComponent }        from '@geenee/builder/src/core/context/di.get-component';
import { accountState }          from '@geenee/builder/src/core/state/account.state';
import {
    PREVIEW_URL_GENERATED, PUBLISH_URL_GENERATED, PUBLISHING_DOMAIN_URL
} from '@geenee/builder/src/lib/constants';
import envConfig from '@geenee/builder/src/lib/envConfig';

export const useGenerateUrl = ({ publish, dropdownActive }:{publish, dropdownActive}) => {
    const { AppState, container } = useInject();
    const analytics: Analytics = container.get('<Analytics>');

    const [ fullUrl, setFullUrl ] = useState('');
    const useGenerateUrlHelper = getDIComponent('UseGenerateUrlHelper');
    const { generatePublishLink } = useGenerateUrlHelper();
    const { togglePublishProject } = usePublishProject({
        dropdownActive,
        updateFullUrl: (val: string) => setFullUrl(val)
    });
    const mainProgram = AppState.activeProject;
    const [ slug, setSlug ] = useState('');
    const { publishing_domain } = accountState;
    const { experienceId } = useComposerLocationParser();
    const previewLink = `${ envConfig.MAGELLAN_PREVIEW_URL }${ window.location.pathname }`;
    const publishLink = publishing_domain && slug
        ? `https://${ publishing_domain }${ PUBLISHING_DOMAIN_URL }/${ slug }${ experienceId && window.location.pathname }`
        : 'https://project-company.geenee.ar';
    const [ lastPathName, setLastPathName ] = useState('');

    const generateLink = async () => {
        try {
            let res = '';
            if (publish) {
                // @ts-ignore
                const { published_name } = await mainProgram?.getPublishData();
                setSlug(published_name);
                res = await generatePublishLink(published_name);
                analytics.track(PUBLISH_URL_GENERATED, { publishLink: res });
            } else {
                analytics.track(PREVIEW_URL_GENERATED, { previewLink });
                res = previewLink;
            }
            setFullUrl(res);
        } catch (e: any) {
            console.error(e);
            setFullUrl(publish ? publishLink : previewLink);
        }
    };

    useEffect(() => {
        if (dropdownActive && window.location.pathname !== lastPathName) {
            setLastPathName(window.location.pathname);
            generateLink();
        }
    }, [ dropdownActive ]);

    return { generateLink, fullUrl, togglePublishProject, publishLink };
};
