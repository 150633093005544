import React                                   from 'react';
import { MoleculeWrapper }                     from "@geenee/shared/src/magellan/molecule/molecule-wrapper.component";
import { COVER_IMAGE_ID }                      from '@geenee/shared/src/util/constants';
import { Description, Divider, Icon, Wrapper } from "@geenee/ui";
import { observer }                            from 'mobx-react';
import { CampaignWrapper }                     from './ExperiencesCarousel/styles';
import { AddImageText, UploadWrapper }         from './styles';

const openFileDialogById = (id: string, disabled?: boolean) => {
    if (!disabled) {
        const imageInput = document.querySelector(
            `#${ id }`
        ) as HTMLElement;
        imageInput && imageInput.click();
    }
};
export const ExperienceImage = observer(
    ({ image_src, disabled, onImageClick }: {image_src?: string
        disabled?: boolean, onImageClick?: ()=> void}) => {
        const isBuilder = process.env.ENV_GEENEE_APP === 'BUILDER';

        return (
            <MoleculeWrapper magellanMolecule="CampaignImageWrapper">
                <CampaignWrapper
                    className="campaign_image_wrapper campaign__image"
                    active
                    src={ image_src || '' }
                    onClick={ onImageClick }
                    hasHoverBorder={ !!image_src && isBuilder }
                    /*
                    // TODO: Double-check after new architecture release
                    onClick={ () => {
                        const { activeProject } = AppState;

                        if (activeProject) {
                            const [ firstExperience ] = Array.from(activeProject.experiencesRegistry.values());
                            if (firstExperience) {
                                history.push(
                                    `/${ programId }/${ firstExperience.id }`
                                );
                            }
                        }
                    } }

                */

                >
                    { isBuilder && !image_src && (
                        <Wrapper
                            radius="xs"
                            align="center"
                            valign="center"
                            fullHeight
                            onClick={ (e) => {
                                openFileDialogById(COVER_IMAGE_ID, disabled);
                                e.preventDefault();
                                e.stopPropagation();
                            } }
                        >
                            <Icon
                                name="upload"
                                color="shade-4"
                                size={ 65 }
                                margin="sm"
                            />
                            <Description
                                weight="bold"
                                size="sm"
                                // noWrap
                                align="center"
                            >
                                ADD COVER IMAGE
                            </Description>
                        </Wrapper>
                    ) }
                </CampaignWrapper>
            </MoleculeWrapper>
        );
    }
);
