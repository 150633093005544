import { Description } from '@geenee/ui';
import styled          from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    padding: 33px 40px 40px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-size: 13px;

    p {
        text-align: center;
    }

    p:nth-child(1) {
        margin-bottom: '46px';
    }

    a {
        margin: 5px;
    }
`;

export const CancelSubscriptionDescription = styled(Description)`
  padding: 0 30px
`;
