import { LinkAtomPropsType }      from "@geenee/geeclient-kit/src/lib/component/atom/link/link.view";
import { initProps as linkProps } from "@geenee/geeclient-kit/src/lib/component/atom/link/util";
import { LinkAtomOptType }        from "@geenee/geeclient-kit/src/lib/type/_atom-option-type";
import {
    BlurRadiusCustomExtensionOptType,
    BlurRadiusExtensionOptType, CompositionExtensionOptType
} from "@geenee/geeclient-kit/src/lib/type/_extension-type";

export type MuteButtonOptType = {
    mute_button_icon_muted_geenee: LinkAtomOptType['link_icon_geenee']
    mute_button_icon_muted_custom: string
    mute_button_icon_unmuted_geenee: LinkAtomOptType['link_icon_geenee']
    mute_button_icon_unmuted_custom: string
    mute_button_icon_width: string
    mute_button_icon_height: string
    mute_button_image_size: string
    style_type: string,
    style_color_bg: string,
    style_color_bg_alpha: number
    style_color_bg_type: string
  }
  & BlurRadiusExtensionOptType<'s' | 'm' | 'l' | 'xl' | 'custom'>
  & BlurRadiusCustomExtensionOptType
  & LinkAtomPropsType
  & CompositionExtensionOptType<'topLeft' | 'topRight' | 'left' | 'right'>

export const defaultOptions: Partial<MuteButtonOptType> = {
    ...linkProps,
    molecule_blur_radius_custom:     0,
    molecule_blur_radius:            's',
    style_color_bg:                  'inherit',
    style_color_bg_alpha:            1,
    style_color_bg_type:             'solid',
    style_type:                      'transparent',
    mute_button_icon_width:          "100%",
    mute_button_icon_height:         "100%",
    link_icon_size:                  's',
    link_icon_style:                 'blur',
    mute_button_icon_muted_geenee:   'muted',
    mute_button_icon_unmuted_geenee: 'unmuted',
    link_icon_switch:                true,
    link_hover:                      true,
    mute_button_icon_muted_custom:   '',
    mute_button_icon_unmuted_custom: '',
    mute_button_image_size:          '40px',
    composition_type:                "topLeft"
};
