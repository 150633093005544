import React, { memo }             from 'react';
import { BaseEntityModel }         from '@geenee/shared/src/magellan/model/base-entity.model';
import { Button, Wrapper }         from '@geenee/ui';
import { useAnalyticsModal }       from '~/module/custom-code/modal/analythics.modal';
import { useAssetCustomCodeModal } from '~/module/custom-code/modal/asset-custom-code.modal';
import { useCustomCodeModal }      from '~/module/custom-code/modal/custom-code.modal';

type PropsType = {
    model: BaseEntityModel;
    disabled?: boolean;
};

// eslint-disable-next-line arca/no-default-export
export default {
    useAnalyticsModal,
    useCustomCodeModal,
    useAssetCustomCodeModal
};

export const CustomCodeComponent = memo(({ model, disabled }: PropsType) => {
    // @ts-ignore
    const [ showModal ] = useCustomCodeModal(model);
    return (
        <Button
            size="qsm"
            viewType="secondary"
            onClick={ showModal }
            disabled={ disabled }
        >
            ADD
        </Button>
    );
});
