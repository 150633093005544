import React                                   from 'react';
import { useTheme }                            from 'styled-components';
import AudioIcon                               from '@geenee/builder/src/asset/icons/AudioIcon';
import CheckboxChecked                         from '@geenee/builder/src/asset/icons/CheckboxChecked';
import Hint                                    from '@geenee/builder/src/components/common/Hint';
import { UnderlinedTableRow, WithHintWrapper } from '@geenee/builder/src/pages/AllPlansPage/styles';

export function AddAudio() {
    const { secondaryColors } = useTheme();
    return (
        <UnderlinedTableRow>
            <td>
                <WithHintWrapper>
                    <span>Add Audio</span>
                    { ' ' }
                    <Hint
                        title='Upload audio files or embed Spotify clips'
                        text="Add music or audio files to your experience with just a few clicks."
                        iconElement={ <AudioIcon /> }
                    />
                </WithHintWrapper>

            </td>
            <td className='basic-plan'>
                { ' ' }
                <CheckboxChecked
                    beIcon
                    fill={ secondaryColors.green70 }
                />
            </td>
            <td className='plan-filler' />
            <td className='studio-plan'>
                { ' ' }
                <CheckboxChecked
                    beIcon
                    fill={ secondaryColors.green70 }
                />
            </td>
            <td className='plan-filler' />
            <td className='pro-plan'>
                { ' ' }
                <CheckboxChecked
                    beIcon
                    fill={ secondaryColors.green70 }
                />
            </td>
        </UnderlinedTableRow>
    );
}
