import React, { FC, memo } from 'react';
import { SizeType }        from '../../util/global-props';
import { Description }     from '../description/description.component';
import { Icon }            from '../icon/icon.component';
import { Wrapper }         from '../wrapper/wrapper.component';

export const FormError: FC<{ error?: string, margin?: SizeType }> = memo(
    ({ error, margin }) => (
        <Wrapper row valign="center" margin={ margin || 'off' }>
            <Icon
                size={ 16 }
                style={ { padding: '2px' } }
                stroke="fat"
                name="error"
                color="error"
                margin="xxs"
            />
            <Description size="xs" weight="medium" color="error">
                { error }
            </Description>
        </Wrapper>
    )
);
