import * as React                           from "react";
import type { HemisphericLight }            from "@babylonjs/core/Lights/hemisphericLight";
import type { Observable }                  from "@babylonjs/core/Misc/observable";
import { Color3LineComponent }              from "@geenee/geespector-ui-components/src/lines/color3LineComponent";
import { Vector3LineComponent }             from "@geenee/geespector-ui-components/src/lines/vector3LineComponent";
import type { LockObject }                  from "@geenee/geespector-ui-components/src/tabs/propertyGrids/lockObject";
import { Wrapper }                          from "@geenee/ui";
import type { GlobalState }                 from "../../../../globalState";
import type { PropertyChangedEvent }        from "../../../../propertyChangedEvent";
import { ActionTabSectionComponent }        from "../../../actionTabSectionComponent";
import { CommonLightPropertyGridComponent } from "./commonLightPropertyGridComponent";

interface IHemisphericLightPropertyGridComponentProps {
    globalState: GlobalState;
    light: HemisphericLight;
    lockObject: LockObject;
    onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
}

export class HemisphericLightPropertyGridComponent extends React.Component<IHemisphericLightPropertyGridComponentProps> {
    constructor(props: IHemisphericLightPropertyGridComponentProps) {
        super(props);
    }

    render() {
        const { light } = this.props;

        return (
            <Wrapper className="pane">
                <CommonLightPropertyGridComponent
                    globalState={ this.props.globalState }
                    lockObject={ this.props.lockObject }
                    light={ light }
                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                />
                <ActionTabSectionComponent title="SETUP" hasDivider={ false }>
                    <Color3LineComponent
                        label="Sky Color"
                        target={ light }
                        propertyName="diffuse"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                    <Color3LineComponent
                        label="Ground Color"
                        target={ light }
                        propertyName="groundColor"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                    <Vector3LineComponent
                        label="Direction"
                        target={ light }
                        propertyName="direction"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                </ActionTabSectionComponent>
            </Wrapper>
        );
    }
}
