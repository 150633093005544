import React from 'react';

export function ShowAll({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path className="fill" d="M6 3C6.55228 3 7 3.44772 7 4V17.5858L8.29289 16.2929C8.68342 15.9024 9.31658 15.9024 9.70711 16.2929C10.0976 16.6834 10.0976 17.3166 9.70711 17.7071L6.70711 20.7071C6.31658 21.0976 5.68342 21.0976 5.29289 20.7071L2.29289 17.7071C1.90237 17.3166 1.90237 16.6834 2.29289 16.2929C2.68342 15.9024 3.31658 15.9024 3.70711 16.2929L5 17.5858V4C5 3.44772 5.44772 3 6 3Z" fill="#3E3B46" />
            <path className="fill" d="M12 5C11.4477 5 11 5.44772 11 6C11 6.55228 11.4477 7 12 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H12Z" fill="#3E3B46" />
            <path className="fill" d="M13 12C13 11.4477 13.4477 11 14 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H14C13.4477 13 13 12.5523 13 12Z" fill="#3E3B46" />
            <path className="fill" d="M16 17C15.4477 17 15 17.4477 15 18C15 18.5523 15.4477 19 16 19H20C20.5523 19 21 18.5523 21 18C21 17.4477 20.5523 17 20 17H16Z" fill="#3E3B46" />
            { children }
        </svg>
    );
}

export function HideAll({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path className='fill' fill="#222225" d="M2.29289 7.70711C2.68342 8.09763 3.31658 8.09763 3.70711 7.70711L5 6.41421V20C5 20.5523 5.44772 21 6 21C6.55228 21 7 20.5523 7 20V6.41421L8.29289 7.70711C8.68342 8.09763 9.31658 8.09763 9.70711 7.70711C10.0976 7.31658 10.0976 6.68342 9.70711 6.29289L6.70711 3.29289C6.61123 3.19702 6.50073 3.12468 6.38278 3.07588C6.26575 3.02735 6.1375 3.0004 6.003 3L6 3L5.997 3C5.74208 3.00076 5.48739 3.09839 5.29289 3.29289L2.29289 6.29289C1.90237 6.68342 1.90237 7.31658 2.29289 7.70711Z" />
            <path className='fill' fill="#222225" d="M16 5C15.4477 5 15 5.44772 15 6C15 6.55228 15.4477 7 16 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H16Z" />
            <path className='fill' fill="#222225" d="M13 12C13 11.4477 13.4477 11 14 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H14C13.4477 13 13 12.5523 13 12Z" />
            <path className='fill' fill="#222225" d="M12 17C11.4477 17 11 17.4477 11 18C11 18.5523 11.4477 19 12 19H20C20.5523 19 21 18.5523 21 18C21 17.4477 20.5523 17 20 17H12Z" />
            { children }
        </svg>
    );
}

export function Extended({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path className='fill' fill="#222225" d="M19.25 8C19.25 8.41421 19.5858 8.75 20 8.75C20.4142 8.75 20.75 8.41421 20.75 8V4C20.75 3.58579 20.4142 3.25 20 3.25H16C15.5858 3.25 15.25 3.58579 15.25 4C15.25 4.41421 15.5858 4.75 16 4.75L18.1893 4.75L14.4697 8.46967C14.1768 8.76256 14.1768 9.23744 14.4697 9.53033C14.7626 9.82322 15.2374 9.82322 15.5303 9.53033L19.25 5.81066V8Z" />
            <path className='fill' fill="#222225" d="M4.75 16C4.75 15.5858 4.41421 15.25 4 15.25C3.58579 15.25 3.25 15.5858 3.25 16V20C3.25 20.4142 3.58579 20.75 4 20.75H8C8.41421 20.75 8.75 20.4142 8.75 20C8.75 19.5858 8.41421 19.25 8 19.25H5.81066L9.53033 15.5303C9.82322 15.2374 9.82322 14.7626 9.53033 14.4697C9.23744 14.1768 8.76256 14.1768 8.46967 14.4697L4.75 18.1893V16Z" />
            { children }
        </svg>
    );
}

export function Collapsed({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path className='fill' fill="#222225" d="M20.5303 4.53033C20.8232 4.23744 20.8232 3.76256 20.5303 3.46967C20.2374 3.17678 19.7626 3.17678 19.4697 3.46967L14.75 8.18934V6C14.75 5.58579 14.4142 5.25 14 5.25C13.5858 5.25 13.25 5.58579 13.25 6V10C13.25 10.4142 13.5858 10.75 14 10.75H18C18.4142 10.75 18.75 10.4142 18.75 10C18.75 9.58579 18.4142 9.25 18 9.25H15.8107L20.5303 4.53033Z" />
            <path className='fill' fill="#222225" d="M9.25 18C9.25 18.4142 9.58579 18.75 10 18.75C10.4142 18.75 10.75 18.4142 10.75 18L10.75 14C10.75 13.5858 10.4142 13.25 10 13.25H6C5.58579 13.25 5.25 13.5858 5.25 14C5.25 14.4142 5.58579 14.75 6 14.75H8.18934L3.46967 19.4697C3.17678 19.7626 3.17678 20.2374 3.46967 20.5303C3.76256 20.8232 4.23744 20.8232 4.53033 20.5303L9.25 15.8107L9.25 18Z" />
            { children }
        </svg>
    );
}
