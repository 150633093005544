import React, { FC, useMemo, useState }                          from 'react';
import Scrollbars                                                from "react-custom-scrollbars";
import { getPropsFromOwner }                                     from '@geenee/geeclient-kit/src/lib/component/atom/collapse/util';
import type { CollapseAtomOptType, LinkAtomOptType }             from '@geenee/geeclient-kit/src/lib/type/_atom-option-type';
import { withMoleculeContext }                                   from "@geenee/geeclient-kit/src/lib/hoc/withMoleculeContext";
import { SizeExtensionType }                                     from "@geenee/geeclient-kit/src/lib/type/_atom-option-type";
import { cnKitAtom }                                             from '@geenee/geeclient-kit/src/lib/util/bem';
import { compose }                                               from '@geenee/geeclient-kit/src/lib/util/compose';
import { withProps }                                             from '@geenee/geeclient-kit/src/lib/util/withProps';
import { observer }                                              from "mobx-react";
import { CombinationMoleculeSchemaType, SiteMoleculeSchemaType } from '../../../type/section-molecule';
import { CallbackFunctionVariadic }                              from '../../../type/type';
import { LinkAtomComponent }                                     from '../link/link.view';
import { initProps }                                             from './util';
import './collapse.view.scss';

export type CollapseAtomPropsType = {
  onExpand: CallbackFunctionVariadic;
  children: React.ReactNode
  moleculeContext: CombinationMoleculeSchemaType<CollapseAtomOptType>;
  size: SizeExtensionType<'s'| 'm' | 'l' | 'xl'>
} & CollapseAtomOptType & LinkAtomOptType;

const className = cnKitAtom('atom-collapse');

const ScrollMap = {
    s:   150,
    m:   200,
    l:   250,
    xl:  300,
    off: '100%'
};
export const CollapseView: FC<CollapseAtomPropsType> = observer((props: CollapseAtomPropsType) => {
    const [ expanded, setExpanded ] = useState(false);
    const childrenArray = React.Children.toArray(props.children);
    const hasCollapseButtons = useMemo(
        () => childrenArray.length > props.collapse_items_default_visible_count,
        [ childrenArray.length, props.collapse_items_default_visible_count ]
    );
    const displayChildren = useMemo(() => (expanded
        ? childrenArray
        : childrenArray.slice(0, props.collapse_items_default_visible_count)), [ childrenArray, expanded ]);

    const getCollapseButton = () => (
        hasCollapseButtons
            ? (
                <LinkAtomComponent
                    color_auto_variant="harmonic"
                    onClick={ () => setExpanded((prev) => !prev) }
                    link_text={ props.collapse_title_text }
                    link_icon_switch={ props.collapse_icon_switch }
                    link_icon_size={ props.collapse_icon_size }
                    link_icon_geenee={ expanded ? props.collapse_icon_geenee_expand : props.collapse_icon_geenee_collapsed }
                    link_icon_style={ props.collapse_icon_style }
                    link_icon_type={ props.link_icon_type }
                    link_icon_color_text={ props.link_icon_color_text }
                    link_icon_color_text_alpha={ props.link_icon_color_text_alpha }
                    link_icon_color_text_custom={ props.link_icon_color_text_custom }
                    link_icon_color_text_type={ props.link_icon_color_text_type }
                    link_icon_color_text_variation={ props.link_icon_color_text_variation }
                    link_icon_color_bg={ props.link_icon_color_bg }
                    link_icon_color_bg_alpha={ props.link_icon_color_bg_alpha }
                    link_icon_color_bg_custom={ props.link_icon_color_bg_custom }
                    link_icon_color_bg_type={ props.link_icon_color_bg_type }
                    link_icon_color_bg_variation={ props.link_icon_color_bg_variation }
                    link_icon_color_border={ props.link_icon_color_border }
                    link_icon_color_border_alpha={ props.link_icon_color_border_alpha }
                    link_icon_color_border_custom={ props.link_icon_color_border_custom }
                    link_icon_color_border_type={ props.link_icon_color_border_type }
                    link_icon_color_border_variation={ props.link_icon_color_border_variation }
                    link_icon_blur_radius={ props.link_icon_blur_radius }
                    link_icon_blur_radius_custom={ props.link_icon_blur_radius_custom }
                    link_icon_corner_radius={ props.link_icon_corner_radius }
                    link_icon_corner_radius_custom={ props.link_icon_corner_radius_custom }
                    link_padding="auto"
                />
            )
            : null
    );

    return (
        <div
            className={ className('', {
                space: props.collapse_items_space_between !== 'default' ? props.collapse_items_space_between : undefined,
                size:  props.size
            }) }
            data-atom="collapse"
        >
            { props.collapse_icon_position === 'top' ? getCollapseButton() : null }
            <div className={ className('collapse-items') }>
                <Scrollbars
                    autoHide
                    autoHideTimeout={ 500 }
                    autoHideDuration={ 200 }
                    autoHeight
                    autoHeightMin={ 0 }
                    autoHeightMax={ expanded ? ScrollMap[ props.collapse_scroll_height ] : '100%' }
                >
                    { displayChildren }
                </Scrollbars>
            </div>
            { props.collapse_icon_position === 'bottom' ? getCollapseButton() : null }
        </div>
    );
});

CollapseView.defaultProps = { ...initProps } as Partial<CollapseAtomPropsType>;

const enhance = compose(
    withMoleculeContext,
    withProps(
        ({
            moleculeContext,
            ...extendProps
        }: {
      moleculeContext: SiteMoleculeSchemaType<CollapseAtomOptType>;
    }) => getPropsFromOwner(moleculeContext.options, extendProps)
    )
);

export const CollapseAtomComponent = enhance(CollapseView);
