import React, { FC, memo }                 from 'react';
import { cn }                              from '../../util/bem';
import { externalClasses, MediaPropsType } from '../../util/externalClasses';
import { SizesUnionType }                  from '../../util/global-props';
import { withMedia }                       from '../../util/media-merge';
import './divider.component.scss';

export type DividerPropsType = {
    className?: string;
    transparent?: boolean;
    margin?: SizesUnionType;
    color?: 'grey'|'grey-2'|'grey-3' | 'shade-6' | 'white';
    // width?: string;
    flex?: number;
    style?: React.CSSProperties;
    outMargin?: string;

    hide?: MediaPropsType;
    xsm?: DividerPropsType;
    sm?: DividerPropsType;
    md?: DividerPropsType;
    lg?: DividerPropsType;
}

const className = cn('divider');

export const Divider: FC<DividerPropsType> = withMedia(memo((props) => (
    <div
        className={ className({
            color:       props.color,
            margin:      props.margin,
            transparent: props.transparent,
            flex:        props.flex
        }, null, props.className, externalClasses({ media: props.hide })) }
        style={ {
            width:      props.outMargin && `calc(100% + ${ props.outMargin } * 2)`,
            marginLeft: props.outMargin && `calc(${ props.outMargin } * -1)`,
            ...props.style
        } }
    />
)));
