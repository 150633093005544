import React                               from 'react';
import { cn, Description, Title, Wrapper } from '@geenee/ui';
import './styles.scss';

const className = cn('header-title');
export function HeaderTitle({
    title, onClickSDKDocs,
    subtitle, gradient, weight = 'medium'
}: { title: string, onClickSDKDocs?: () => void, subtitle?: React.ReactNode, gradient?: boolean, weight?: 'bold' | 'medium'}) {
    return (
        <Wrapper>
            <Title
                weight={ weight }
                className={ className(gradient ? 'gradient' : '') }
                size="xl"
                color='shade-2'
                margin={ subtitle ? 'xs' : 'off' }
            >
                { title }
            </Title>
            { subtitle ? (
                <Description lineHeight="lg" color='shade-3' size="lg">
                    { subtitle }
                    { onClickSDKDocs && (
                        <>
                            <br />
                            Find our
                            { ' ' }
                            <a onClick={ onClickSDKDocs } style={ { color: 'black', textDecoration: 'underline' } }>SDK documentation here</a>
                            { ' ' }
                            to learn more on how to use the token and prepare your assets.
                        </>
                    ) }
                </Description>
            ) : null }
        </Wrapper>
    );
}
