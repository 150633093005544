import React        from 'react';
import { Wrapper }  from '@geenee/ui';
import StyledButton from '@geenee/builder/src/components/common/Button';
import {
    DefaultWrapper,
    DescriptionContainer,
    RightsDescription,
    Title
} from './styles';

type DeleteMemberProps = {
    close: () => void;
    confirmCallback: () => void;
    title: string;
    textInsert: string;
};

function OwnerProfileAction({ title, textInsert, confirmCallback, close }: DeleteMemberProps) {
    const handleClick = () => {
        confirmCallback();
        close();
    };

    return (
        <DefaultWrapper>
            <Wrapper align="center">
                <Title>{ title }</Title>
                <DescriptionContainer>
                    <RightsDescription>
                        Before you
                        { ' ' }
                        { textInsert }
                        , please give Owner rights to someone else via the
                        { ' ' }
                        <span className='underline'>Team Management</span>
                        { ' ' }
                        page.
                    </RightsDescription>
                    <br />
                </DescriptionContainer>
                <StyledButton
                    label='GO TO TEAM MANAGEMENT'
                    $width="320px"
                    $fontSize="12px"
                    onClick={ handleClick }
                />
            </Wrapper>
        </DefaultWrapper>
    );
}

// eslint-disable-next-line arca/no-default-export
export default OwnerProfileAction;
