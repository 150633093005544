import React, { useState } from 'react';
import GeeneeLogo          from '@geenee/assets/lib/icons/resources/GeeneeLogoGradient';
import backgroundSrc       from '@geenee/assets/lib/images/bg-frame4.png';
import { useHistory }      from '@geenee/common';
import { useMobxForm }     from '@geenee/shared/src/magellan/hooks/use-mobx-form';
import {
    Button,
    Description,
    Form,
    FormError,
    FormItem,
    Input,
    Link,
    Title,
    Wrapper
} from '@geenee/ui';
import { observer } from 'mobx-react';

export const ForgotPage = observer(() => {
    const history = useHistory();
    const [ sentEmail, setSentEmail ] = useState(false);

    const formModel = useMobxForm('<ForgotPassword>');
    const { form: { formState: { errors } } } = formModel;

    const submitHandler = async (e) => {
        try {
            await formModel.submit(e);

            setSentEmail(true);
            setTimeout(() => {
                history.push('/login');
            }, 5000);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Wrapper
            fullHeight
            align="center"
            valign="center"
            backgroundImage={ backgroundSrc }
        >
            <Wrapper align="center" margin="md">
                <GeeneeLogo width="192" height="32" />
            </Wrapper>
            <Wrapper
                maxWidth="352px"
                frame="solid-opacity-white"
                padding="xl"
                radius="xxxl"
                margin="md"
                shadow="translucency-md"
            >
                <Wrapper align="center" valign="center">
                    { !sentEmail ? (
                        <Wrapper>
                            <Wrapper margin="md" align="center">
                                <Title
                                    size="sm"
                                    weight="medium"
                                    margin="xs"
                                    styles={ { lineHeight: 1.5 } }
                                >
                                    Forgot password?
                                </Title>
                                <Description size="md" color="shade-4">
                                    No problem. Let&apos;s reset your password
                                </Description>
                            </Wrapper>
                            <Form
                                onSubmit={ async (e) => {
                                    await submitHandler(e);
                                } }
                            >
                                <Wrapper>
                                    <FormItem
                                        errorComponent={ FormError as React.FC }
                                        error={ (!!errors.email && errors.email.message) || (!!errors.form && errors.form.message) }
                                        hasTooltipMessage={ false }
                                        autoFocusDetect
                                        margin="sm"
                                    >
                                        <Input
                                            size="md"
                                            fullWidth
                                            { ...formModel.bind('email', formModel.get('email').rules) }
                                            placeholder={ formModel.get('email').placeholder }
                                            error={ !!errors.email || !!errors.form }
                                            type="email"
                                        />
                                    </FormItem>
                                    <Button
                                        fullWidth
                                        onClick={ submitHandler }
                                        type="submit"
                                        radius="xxl"
                                        size="lg"
                                        loading={ formModel.isLoading }
                                    >
                                        RESET PASSWORD
                                    </Button>
                                </Wrapper>
                            </Form>
                        </Wrapper>
                    ) : (
                        <Wrapper align="center">
                            <Title
                                size="sm"
                                weight="medium"
                                margin="xs"
                                styles={ { lineHeight: 1.5 } }
                            >
                                All set!
                            </Title>
                            <Description size="md" margin="xs" color="shade-4">
                                An email with the link to restore password has
                                been sent
                            </Description>
                            <Description size="md">
                                Please check your email
                            </Description>
                        </Wrapper>
                    ) }
                </Wrapper>
            </Wrapper>
            <Description lineHeight="md">
                Not a member?
                { ' ' }
                <Link
                    onClick={ () => history.push('/sign-up') }
                    fontSize="14px"
                    weight="medium"
                    style={ { margin: 0 } }
                >
                    Sign Up
                </Link>
            </Description>
        </Wrapper>
    );
});
