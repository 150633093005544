import { initProps as iconProps }       from '@geenee/geeclient-kit/src/lib/component/atom/icon/util';
import { initProps as imageProps }      from '@geenee/geeclient-kit/src/lib/component/atom/image/util';
import { initProps as linkProps }       from '@geenee/geeclient-kit/src/lib/component/atom/link/util';
import { initProps as swiperItemProps } from '@geenee/geeclient-kit/src/lib/component/atom/swiper-item/util';
import {
    CollapseAtomOptType,
    IconAtomOptType, LinkAtomOptType,
    PlainTextAtomOptType,
    SizeExtensionType
} from "@geenee/geeclient-kit/src/lib/type/_atom-option-type";
import {
    BlurRadiusCustomExtensionOptType,
    BlurRadiusExtensionOptType,
    CompositionExtensionOptType,
    StyleExtensionOptType
} from "@geenee/geeclient-kit/src/lib/type/_extension-type";

export type ScenesListOptType = {
  [key: string]: any;
  style_size: SizeExtensionType<'s'| 'm' | 'l' | 'xl'>
} & IconAtomOptType
  & PlainTextAtomOptType
  & LinkAtomOptType
  & CollapseAtomOptType
  & StyleExtensionOptType<'transparent' | 'line' | 'solid' | 'blur'>
  & CompositionExtensionOptType<'topLeft' | 'topRight' | 'left' | 'right'>
  & BlurRadiusExtensionOptType<'s' | 'm' | 'l' | 'xl' | 'custom'>
  & BlurRadiusCustomExtensionOptType;

export const defaultOptions = {
    ...linkProps,
    ...imageProps,
    ...iconProps,
    ...swiperItemProps,
    molecule_blur_radius_custom:  0,
    molecule_blur_radius:         's',
    style_type:                   'transparent',
    style_color_text:             'inherit',
    style_color_text_alpha:       1,
    style_color_text_type:        'auto',
    style_color_text_variation:   'text',
    style_color_text_custom:      '',
    style_color_bg:               'inherit',
    style_color_bg_alpha:         1,
    style_color_bg_type:          'auto',
    style_color_bg_variation:     'contrast',
    style_color_bg_custom:        '',
    style_color_border:           'inherit',
    style_color_border_alpha:     1,
    style_color_border_type:      'auto',
    style_color_border_variation: 'contrast',
    style_color_border_custom:    '',
    style_corner_radius:          '0',
    style_corner_radius_custom:   0
};
