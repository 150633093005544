
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */

/* eslint-disable indent, max-len */
import * as React from 'react';
import {
    ColorExtendedType,
    ColorRootKeyType,
    ColorSchemaType,
    ColorVariantType
}                              from '@onepage-legacy/shared/lib/type/model/color';
import Color                   from 'color';
import { observer }            from 'mobx-react';
import { withColorKitContext } from '../hoc/with-color-kit-context';
import { compose }             from '../util/compose';

type PropsType = {
    colorKitContext: ColorSchemaType,
    // prefix?: string,
    prefixVars?: string,
}

class _ColorCSSVarsProvider extends React.Component<PropsType> {
    // static defaultProps = {
    //     prefix: '.gee-kit-organism'
    // };

    rootAttribute = '[data-color-root]';
    colors: ColorRootKeyType[] = [ 'white', 'black', 'light', 'dark', 'key1', 'key2', 'key3', 'key4' ];
    colorTypes: ColorVariantType[] = [ 'text', 'harmonic', 'contrast' ];
    fonts: ColorRootKeyType[] = [ 'Euclid Triangle', 'Euclid Circular A' ];
    alphaSteps = [ 1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1, 0 ];

    getExtendedColor(colorExtended: ColorExtendedType) {
        const baseColor = this.props.colorKitContext[ colorExtended.key ];
        const color     = Color(baseColor === '#000000' ? '#191919' : baseColor); // https://github.com/Qix-/color/issues/150
        if (colorExtended.shade === 0) {
            return {
                hex:   color.hex(),
                array: color.rgb().array()
            };
        }

        if (colorExtended.shade > 0) {
            return {
                hex:   color.lighten(colorExtended.shade / 100).hex(),
                array: color.lighten(colorExtended.shade / 100).rgb().array()
            };
        }

        return {
            hex:   color.darken((colorExtended.shade * -1) / 100).hex(),
            array: color.darken((colorExtended.shade * -1) / 100).rgb().array()
        };
    }

    spreedColor(colorRootKey: ColorRootKeyType) {
        const colorRoot = this.props.colorKitContext[ colorRootKey ];
        const color     = Color(colorRoot);

        return {
            hex:   color.hex(),
            array: color.rgb().array()
        };
    }

    genCSS() {
        let output = '';

        // Alpha global
      /* The above code is generating CSS variables and CSS rules for a color palette system. It takes
      in a set of colors and color variations, and generates CSS variables for each color and
      variation. It also generates CSS rules for applying these colors to various elements based on
      their data attributes. The generated CSS rules apply the appropriate color based on the data
      attributes of the element, such as data-color-bg and data-color-text. */
        this.alphaSteps.forEach((alpha) => {
            output += `
                ${ this.rootAttribute } [data-color-text-alpha="${ alpha }"] {
                    --color-text-alpha: ${ alpha };
                }


                ${ this.rootAttribute } [data-color-bg-alpha="${ alpha }"] {
                    --color-bg-alpha: ${ alpha };
                }

                ${ this.rootAttribute } [data-color-util-alpha="${ alpha }"] {
                    --color-util-alpha: ${ alpha };
                }

            `;
        });

        // Background: 1 Level


      /* The above code is generating CSS code for setting color variables based on a list of color
      keys. It is using template literals to concatenate the CSS code and a forEach loop to iterate
      over the list of color keys. The generated CSS code sets variables for background color, text
      color, and blur background color based on the color key. It also sets a variable for utility
      color based on the color key. The CSS code is using CSS variables and data attributes to apply
      the color styles to specific elements. */
        this.colors.forEach((colorKey) => {
            output += `
                ${ this.rootAttribute }[data-color-bg="${ colorKey }"],
                ${ this.rootAttribute } [data-color-bg="${ colorKey }"],
                ${ this.rootAttribute } [data-color-bg="${ colorKey }"] [data-color-bg="inherit"][data-color-bg-type="auto"],
                /* ${ this.rootAttribute } [data-color-bg="${ colorKey }"] [data-color-util="inherit"][data-color-util-type="auto"], */
                ${ this.rootAttribute } [data-color-bg="${ colorKey }"] [data-color-bg="inherit"][data-color-bg-type="auto"] [data-color-bg="inherit"][data-color-bg-type="auto"] {
                    --color-bg: var(--color-kit-${ colorKey }-array);
                    --color-blur-bg: var(--color-kit-${ colorKey }-array);
                    --color-text: var(--color-kit-${ colorKey }-text-array);
                }

                ${ this.rootAttribute } [data-color-util="${ colorKey }"] {
                    --color-util: var(--color-kit-${ colorKey }-array);
                }
            `;
        });

        // Background: 2 Level
        this.colors.forEach((colorKey) => {
            this.colorTypes.forEach((colorType2Level) => {
                // @ts-ignore
                const color1Level = this.props.colorKitContext[ `${ colorKey }_${ colorType2Level }` ];
                output += `
                ${ this.rootAttribute }[data-color-bg="${ colorKey }"] [data-color-bg="inherit"][data-color-bg-variation="${ colorType2Level }"] {
                    --color-bg: var(--color-kit-${ colorKey }-${ colorType2Level }-array);
                    --color-blur-bg: var(--color-kit-${ colorKey }-${ colorType2Level }-array);
                    --color-text: var(--color-kit-${ color1Level.key }-text-array);
                }
                `;
            });
        });
        this.colors.forEach((colorKey) => {
            this.colorTypes.forEach((colorType2Level) => {
                // @ts-ignore
                const color1Level = this.props.colorKitContext[ `${ colorKey }_${ colorType2Level }` ];
                output += `
                ${ this.rootAttribute } [data-color-bg="${ colorKey }"] [data-color-bg="inherit"][data-color-bg-variation="${ colorType2Level }"] {
                    --color-bg: var(--color-kit-${ colorKey }-${ colorType2Level }-array);
                    --color-text: var(--color-kit-${ color1Level.key }-text-array);
                    --color-blur-bg: var(--color-kit-${ colorKey }-${ colorType2Level }-array);
                }
                `;
            });
        });
        this.colors.forEach((colorKey) => {
            this.colorTypes.forEach((colorType2Level) => {
                output += `
                ${ this.rootAttribute }[data-color-bg="${ colorKey }"] [data-color-util="inherit"][data-color-util-variation="${ colorType2Level }"] {
                    --color-util: var(--color-kit-${ colorKey }-${ colorType2Level }-array);
                }
                `;
            });
        });
        this.colors.forEach((colorKey) => {
            this.colorTypes.forEach((colorType2Level) => {
                output += `
                ${ this.rootAttribute } [data-color-bg="${ colorKey }"] [data-color-util="inherit"][data-color-util-variation="${ colorType2Level }"] {
                    --color-util: var(--color-kit-${ colorKey }-${ colorType2Level }-array);
                }
                `;
            });
        });

        // Background: 3 Level
        this.colors.forEach((colorKey) => {
            this.colorTypes.forEach((colorType2Level) => {
                // @ts-ignore
                const color1Level = this.props.colorKitContext[ `${ colorKey }_${ colorType2Level }` ];
                this.colorTypes.filter((c) => c !== 'harmonic').forEach((colorType3Level) => {
                    // @ts-ignore
                    const color2Level = this.props.colorKitContext[ `${ color1Level.key }_${ colorType3Level }` ];
                    output += `
                    ${ this.rootAttribute }[data-color-bg="${ colorKey }"] [data-color-bg="inherit"][data-color-bg-variation="${ colorType2Level }"] [data-color-bg="inherit"][data-color-bg-variation="${ colorType3Level }"],
                    ${ this.rootAttribute } [data-color-bg="${ colorKey }"] [data-color-bg="inherit"][data-color-bg-variation="${ colorType2Level }"] [data-color-bg="inherit"][data-color-bg-variation="${ colorType3Level }"] {
                        --color-bg: var(--color-kit-${ color1Level.key }-${ colorType3Level }-array);
                        --color-blur-bg: var(--color-kit-${ color1Level.key }-${ colorType3Level }-array);
                        --color-text: var(--color-kit-${ color2Level.key }-text-array);
                    }
                    `;
                });
            });
        });
        this.colors.forEach((colorKey) => {
            this.colorTypes.forEach((colorType2Level) => {
                // @ts-ignore
                const color1Level = this.props.colorKitContext[ `${ colorKey }_${ colorType2Level }` ];
                this.colorTypes.filter((c) => c !== 'harmonic').forEach((colorType3Level) => {
                    output += `
                    ${ this.rootAttribute }[data-color-bg="${ colorKey }"] [data-color-bg="inherit"][data-color-bg-variation="${ colorType2Level }"] [data-color-util="inherit"][data-color-util-variation="${ colorType3Level }"],
                    ${ this.rootAttribute } [data-color-bg="${ colorKey }"] [data-color-bg="inherit"][data-color-bg-variation="${ colorType2Level }"] [data-color-util="inherit"][data-color-util-variation="${ colorType3Level }"] {
                        --color-util: var(--color-kit-${ color1Level.key }-${ colorType3Level }-array);
                    }
                    `;
                });
            });
        });
        this.colors.forEach((colorKey) => {
            this.colorTypes.forEach((colorType2Level) => {
                // @ts-ignore
                const color1Level = this.props.colorKitContext[ `${ colorKey }_${ colorType2Level }` ];
                this.colorTypes.filter((c) => c === 'harmonic').forEach((colorType3Level) => {
                    // @ts-ignore
                    const color2Level = this.props.colorKitContext[ `${ color1Level.key }_${ colorType3Level }` ];
                    output += `
                    ${ this.rootAttribute }[data-color-bg="${ colorKey }"] [data-color-bg="inherit"][data-color-bg-variation="${ colorType2Level }"] [data-color-bg="inherit"][data-color-bg-variation="${ colorType3Level }"],
                    ${ this.rootAttribute } [data-color-bg="${ colorKey }"] [data-color-bg="inherit"][data-color-bg-variation="${ colorType2Level }"] [data-color-bg="inherit"][data-color-bg-variation="${ colorType3Level }"] {
                        --color-bg: var(--color-kit-${ color1Level.key }-array);
                        --color-blur-bg: var(--color-kit-${ color1Level.key }-array);
                        --color-text: var(--color-kit-${ color2Level.key }-text-array);
                    }
                    `;
                });
            });
        });

        this.colors.forEach((colorKey) => {
            this.colorTypes.forEach((colorType2Level) => {
                // @ts-ignore
                const color1Level = this.props.colorKitContext[ `${ colorKey }_${ colorType2Level }` ];
                this.colorTypes.filter((c) => c === 'harmonic').forEach((colorType3Level) => {
                    output += `
                    ${ this.rootAttribute }[data-color-bg="${ colorKey }"] [data-color-bg="inherit"][data-color-bg-variation="${ colorType2Level }"] [data-color-util="inherit"][data-color-util-variation="${ colorType3Level }"],
                    ${ this.rootAttribute } [data-color-bg="${ colorKey }"] [data-color-bg="inherit"][data-color-bg-variation="${ colorType2Level }"] [data-color-util="inherit"][data-color-util-variation="${ colorType3Level }"] {
                        --color-util: var(--color-kit-${ color1Level.key }-array);
                    }
                    `;
                });
            });
        });

        // Text
        this.colors.forEach((colorKey) => {
            output += `
            ${ this.rootAttribute }[data-color-bg] [data-color-text="${ colorKey }"][data-color-text-type="palette"],
            ${ this.rootAttribute }[data-color-bg] [data-color-text="${ colorKey }"][data-color-text-type="palette"] [data-color-text="inherit"]:not([data-color-bg]) {
                --color-text: var(--color-kit-${ colorKey }-array)
            }
            `;
        });
        this.colors.forEach((colorKey) => {
            output += `
            ${ this.rootAttribute }[data-color-bg] [data-color-text="${ colorKey }"][data-color-text-type="palette"][data-color-bg][data-color-bg-variation] {
                --color-text: var(--color-kit-${ colorKey }-array);
            }
            `;
        });
        this.colors.forEach((colorKey) => {
            output += `
            ${ this.rootAttribute } [data-color-text="${ colorKey }"] {
                --color-text: var(--color-kit-${ colorKey }-array)
            }
            `;
        });

        return output;
    }

    /**
     * The function generates CSS variables for a color kit.
     *
     * @return a string of CSS variables and their values. The variables include color values and their
     * corresponding arrays, as well as alpha values for text, background, utility, and background
     * blur. The function uses a loop to iterate through the colors and color types, and generates CSS
     * variables for each combination.
     */
    genCSSVars() {
        let output = '';

        output += `
                --color-text-alpha: 1;
                --color-bg-alpha: 1;
                --color-util-alpha: 1;
                --color-bg-blur-alpha: .2;
        `;

        this.colors.forEach((colorKey) => {
            const colorSpreed = this.spreedColor(colorKey);
            output += `
                --color-kit-${ colorKey }: ${ colorSpreed.hex };
                --color-kit-${ colorKey }-array: ${ colorSpreed.array };
            `;

            this.colorTypes.forEach((colorType) => {
                // @ts-ignore
                const colorTypeSpreed = this.getExtendedColor(this.props.colorKitContext[ `${ colorKey }_${ colorType }` ]);
                output += `
                --color-kit-${ colorKey }-${ colorType }: ${ colorTypeSpreed.hex };
                --color-kit-${ colorKey }-${ colorType }-array: ${ colorTypeSpreed.array };
                `;
            });
        });

        return output;
    }

    genFontVars() {
        let output = '';

        this.fonts.forEach((fontKey) => {
            // @ts-ignore
            output += `
                --font-kit-family: ${ fontKey };
            `;
        });

        return output;
    }

     override render() {
         return (
             <style dangerouslySetInnerHTML={ {
                __html:
                    `
                    ${ this.props.prefixVars || this.rootAttribute } {
                        ${ this.genCSSVars() }
                        ${ this.genFontVars() }
                    }



                    ${ this.genCSS() }

                    `
            } }
             />
         );
     }
}

export const ColorCSSVarsProvider = compose(
    withColorKitContext,
    observer
)(_ColorCSSVarsProvider);

// export const ColorCSSVarsProvider = compose(
//     withColorKitContext,
//     observer
// )(_ColorCSSVarsProvider);
