/* eslint-disable max-len */

import React from 'react';

export default function Folder({ width = '12', height = '12', stroke = '#41F5EE' }: SvgIconProps) {
    return (
        <svg
            width={ width }
            height={ height }
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.8889 4.33332C4.33661 4.33332 3.8889 4.78104 3.8889 5.33332V10.6667C3.8889 11.2189 4.33661 11.6667 4.8889 11.6667H11.1111C11.6634 11.6667 12.1111 11.2189 12.1111 10.6667V7.1111C12.1111 6.55882 11.6634 6.1111 11.1111 6.1111H9.25707C8.9329 6.1111 8.62205 5.98233 8.39284 5.75312L7.13577 4.49604C7.03158 4.39186 6.89027 4.33332 6.74293 4.33332H4.8889ZM3.22223 5.33332C3.22223 4.41285 3.96842 3.66666 4.8889 3.66666H6.74293C7.06708 3.66666 7.37796 3.79543 7.60717 4.02464L8.86425 5.28171C8.96845 5.3859 9.10978 5.44443 9.25707 5.44443H11.1111C12.0316 5.44443 12.7778 6.19063 12.7778 7.1111V10.6667C12.7778 11.5871 12.0316 12.3333 11.1111 12.3333H4.8889C3.96842 12.3333 3.22223 11.5871 3.22223 10.6667V5.33332Z"
                fill="#3E3B46"
            />
            <path
                d="M8 0.25C12.2802 0.25 15.75 3.71979 15.75 8C15.75 12.2802 12.2802 15.75 8 15.75C3.71979 15.75 0.25 12.2802 0.25 8C0.25 3.71979 3.71979 0.25 8 0.25Z"
                stroke={ stroke }
                strokeWidth="0.5"
            />
        </svg>
    );
}
