import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: white;
  border-radius: 5px 5px 40px 40px;
  z-index: 2;
`;

export const Controls = styled.div`
  position: absolute;
  top: 5px;
  right: 3px;
`;

export const Content = styled.div`
  min-height: 30px;
  padding: 23px 32px 25px;
`;

export const SnapshotScreenWrapper = styled.div`
  position: absolute;
  bottom: 15px;
  width: auto;
  display: flex;
  left: 50%;
  z-index: 4;
  transform: translateX(-50%);
`;
