import * as React                    from "react";
import type { Observable }           from "@babylonjs/core/Misc/observable";
import { IndentedTextLineComponent } from "@geenee/geespector-ui-components/src/lines/indentedTextLineComponent";
import { LineContainerComponent }    from "@geenee/geespector-ui-components/src/lines/lineContainerComponent";
import { TextLineComponent }         from "@geenee/geespector-ui-components/src/lines/textLineComponent";
import type { LockObject }           from "@geenee/geespector-ui-components/src/tabs/propertyGrids/lockObject";
import type { GlobalState }          from "../../../globalState";
import type { PropertyChangedEvent } from "../../../propertyChangedEvent";

interface ICommonPropertyGridComponentProps {
    globalState: GlobalState;
    host: { metadata: any };
    lockObject: LockObject;
    onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
}

export class CommonPropertyGridComponent extends React.Component<ICommonPropertyGridComponentProps> {
    constructor(props: ICommonPropertyGridComponentProps) {
        super(props);
    }

    renderLevel(jsonObject: any) {
        const components = [];

        for (const data in jsonObject) {
            const value = jsonObject[ data ];
            const type = Object.prototype.toString.call(value);

            switch (type) {
                case "[object String]":
                    components.push(<TextLineComponent key={ data } label={ data } ignoreValue />);
                    components.push(<IndentedTextLineComponent key={ data + value } value={ value } />);
                    break;
                case "[object Array]":
                    components.push(<TextLineComponent key={ data } label={ data } ignoreValue />);
                    for (const entry of value) {
                        components.push(<IndentedTextLineComponent key={ data + entry } value={ entry } />);
                    }
                    break;
                case "[object Object]":
                    components.push(<TextLineComponent key={ data } label={ data } ignoreValue />);
                    for (const entryKey in value) {
                        components.push(<TextLineComponent key={ data + entryKey } label={ entryKey } value={ value[ entryKey ] } additionalClass="reduced-opacity" />);
                    }
                    break;
            }
        }

        return components;
    }

    render() {
        if (!this.props.host.metadata) {
            return null;
        }

        if (!this.props.host.metadata.xmp) {
            return null;
        }

        return (
            <div>
                <LineContainerComponent title="XMP METADATA" selection={ this.props.globalState }>
                    { this.renderLevel(this.props.host.metadata.xmp) }
                </LineContainerComponent>
            </div>
        );
    }
}
