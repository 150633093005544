import * as React                                from "react";
import type { InputBlock }                       from "@babylonjs/core/Materials/Node/Blocks/Input/inputBlock";
import { NodeMaterialBlockConnectionPointTypes } from "@babylonjs/core/Materials/Node/Enums/nodeMaterialBlockConnectionPointTypes";
import type { NodeMaterial }                     from "@babylonjs/core/Materials/Node/nodeMaterial";
import { Observable }                            from "@babylonjs/core/Misc/observable";
import { ButtonLineComponent }                   from "@geenee/geespector-ui-components/src/lines/buttonLineComponent";
import { CheckBoxLineComponent }                 from "@geenee/geespector-ui-components/src/lines/checkBoxLineComponent";
import { Color3LineComponent }                   from "@geenee/geespector-ui-components/src/lines/color3LineComponent";
import { Color4LineComponent }                   from "@geenee/geespector-ui-components/src/lines/color4LineComponent";
import { FloatLineComponent }                    from "@geenee/geespector-ui-components/src/lines/floatLineComponent";
import { LineContainerComponent }                from "@geenee/geespector-ui-components/src/lines/lineContainerComponent";
import { SliderLineComponent }                   from "@geenee/geespector-ui-components/src/lines/sliderLineComponent";
import { Vector2LineComponent }                  from "@geenee/geespector-ui-components/src/lines/vector2LineComponent";
import { Vector3LineComponent }                  from "@geenee/geespector-ui-components/src/lines/vector3LineComponent";
import { Vector4LineComponent }                  from "@geenee/geespector-ui-components/src/lines/vector4LineComponent";
import type { LockObject }                       from "@geenee/geespector-ui-components/src/tabs/propertyGrids/lockObject";
import { Wrapper }                               from '@geenee/ui';
import type { GlobalState }                      from "../../../../globalState";
import type { PropertyChangedEvent }             from "../../../../propertyChangedEvent";
import { ActionTabSectionComponent }             from '../../../actionTabSectionComponent';
import { TextureLinkLineComponent }              from "../../../lines/textureLinkLineComponent";
import { GradientPropertyTabComponent }          from "../../gradientNodePropertyComponent";
import { CommonMaterialPropertyGridComponent }   from "./commonMaterialPropertyGridComponent";

interface INodeMaterialPropertyGridComponentProps {
    globalState: GlobalState;
    material: NodeMaterial;
    lockObject: LockObject;
    onSelectionChangedObservable?: Observable<any>;
    onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
}

export class NodeMaterialPropertyGridComponent extends React.Component<INodeMaterialPropertyGridComponentProps> {
    private _onDebugSelectionChangeObservable = new Observable<TextureLinkLineComponent>();

    constructor(props: INodeMaterialPropertyGridComponentProps) {
        super(props);
    }

    edit() {
        this.props.material.edit();
    }

    renderTextures() {
        const { material } = this.props;

        const onDebugSelectionChangeObservable = this._onDebugSelectionChangeObservable;

        const textureBlocks = material.getTextureBlocks();

        if (!textureBlocks || textureBlocks.length === 0) {
            return null;
        }

        return (
            <ActionTabSectionComponent title="TEXTURES" collapsed>
                { textureBlocks.map((textureBlock, i) => (
                    <TextureLinkLineComponent
                        label={ textureBlock.name }
                        key={ `nodematText${ i }` }
                        texture={ textureBlock.texture }
                        material={ material }
                        onTextureCreated={ (texture) => (textureBlock.texture = texture) }
                        onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                        onDebugSelectionChangeObservable={ onDebugSelectionChangeObservable }
                    />
                )) }
            </ActionTabSectionComponent>
        );
    }

    renderInputBlock(block: InputBlock) {
        switch (block.type) {
            case NodeMaterialBlockConnectionPointTypes.Float: {
                const cantDisplaySlider = isNaN(block.min) || isNaN(block.max) || block.min === block.max;
                return (
                    <div key={ block.name }>
                        { block.isBoolean && (
                            <CheckBoxLineComponent
                                key={ block.name }
                                label={ block.name }
                                target={ block }
                                propertyName="value"
                                onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                            />
                        ) }
                        { !block.isBoolean && cantDisplaySlider && (
                            <FloatLineComponent
                                key={ block.name }
                                lockObject={ this.props.lockObject }
                                label={ block.name }
                                target={ block }
                                propertyName="value"
                                onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                            />
                        ) }
                        { !block.isBoolean && !cantDisplaySlider && (
                            <SliderLineComponent
                                key={ block.name }
                                label={ block.name }
                                target={ block }
                                propertyName="value"
                                step={ (block.max - block.min) / 100.0 }
                                minimum={ block.min }
                                maximum={ block.max }
                                onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                            />
                        ) }
                    </div>
                );
            }
            case NodeMaterialBlockConnectionPointTypes.Color3:
                return (
                    <Color3LineComponent
                        key={ block.name }
                        label={ block.name }
                        target={ block }
                        propertyName="value"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            case NodeMaterialBlockConnectionPointTypes.Color4:
                return (
                    <Color4LineComponent
                        key={ block.name }
                        label={ block.name }
                        target={ block }
                        propertyName="value"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            case NodeMaterialBlockConnectionPointTypes.Vector2:
                return (
                    <Vector2LineComponent
                        key={ block.name }
                        label={ block.name }
                        target={ block }
                        propertyName="value"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            case NodeMaterialBlockConnectionPointTypes.Vector3:
                return (
                    <Vector3LineComponent
                        key={ block.name }
                        label={ block.name }
                        target={ block }
                        propertyName="value"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            case NodeMaterialBlockConnectionPointTypes.Vector4:
                return (
                    <Vector4LineComponent
                        key={ block.name }
                        label={ block.name }
                        target={ block }
                        propertyName="value"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
        }

        return null;
    }

    renderInputValues() {
        const configurableInputBlocks = this.props.material
            .getInputBlocks()
            .filter((block) => block.visibleInInspector && block.isUniform && !block.isSystemValue)
            .sort((a, b) => a.name.localeCompare(b.name));

        const namedGroups: string[] = [];
        configurableInputBlocks.forEach((block) => {
            if (!block.groupInInspector) {
                return;
            }

            if (namedGroups.indexOf(block.groupInInspector) === -1) {
                namedGroups.push(block.groupInInspector);
            }
        });
        namedGroups.sort();

        const gradiantNodeMaterialBlocks = this.props.material.attachedBlocks
            .filter((block) => block.visibleInInspector && block.getClassName() === "GradientBlock")
            .sort((a, b) => a.name.localeCompare(b.name));

        const inputBlockContainer =            configurableInputBlocks.length > 0 ? (
            <LineContainerComponent title="INPUTS" selection={ this.props.globalState }>
                { " " }
                { configurableInputBlocks
                    .filter((block) => !block.groupInInspector)
                    .map((block) => this.renderInputBlock(block)) }
            </LineContainerComponent>
        ) : null;

        return (
            <>
                { inputBlockContainer }
                { namedGroups.map((name, i) => (
                    <LineContainerComponent key={ `inputValue${ i }` } title={ name.toUpperCase() } selection={ this.props.globalState }>
                        { configurableInputBlocks
                            .filter((block) => block.groupInInspector === name)
                            .map((block) => this.renderInputBlock(block)) }
                    </LineContainerComponent>
                )) }
                { gradiantNodeMaterialBlocks.map((block, i) => (
                    <LineContainerComponent key={ block.name + i } title={ block.name.toUpperCase() } selection={ this.props.globalState }>
                        <GradientPropertyTabComponent globalState={ this.props.globalState } block={ block } />
                    </LineContainerComponent>
                )) }
            </>
        );
    }

    render() {
        const { material } = this.props;

        return (
            <Wrapper className="pane">
                <CommonMaterialPropertyGridComponent
                    globalState={ this.props.globalState }
                    lockObject={ this.props.lockObject }
                    material={ material }
                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                />
                <ActionTabSectionComponent title="CONFIGURATION" hasDivider={ false }>
                    <CheckBoxLineComponent label="Ignore alpha" target={ material } propertyName="ignoreAlpha" onPropertyChangedObservable={ this.props.onPropertyChangedObservable } />
                    <ButtonLineComponent label="Node Material Editor" onClick={ () => this.edit() } />
                </ActionTabSectionComponent>
                { this.renderInputValues() }
                { this.renderTextures() }
            </Wrapper>
        );
    }
}
