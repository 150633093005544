import { Analytics }                                          from '@geenee/analytics';
import { HttpClient, SectionModel as SectionModelShared }     from '@geenee/shared';
import { MoleculeSchemaType }                                 from '@geenee/shared/type/molecule.type';
import { SceneSectionTypeUnionType, SectionSchemaType }       from '@geenee/shared/type/section.type';
import { Nullable }                                           from '@geenee/shared/type/shared.type';
import { injectable }                                         from 'inversify';
import { action, computed, makeObservable, observable, toJS } from 'mobx';
import {
    MOLECULE_CREATED,
    MOLECULE_DELETED,
    TYPE_BODY_TRACKING_OVERLAY,
    TYPE_BODY_TRACKING_TWIN,
    TYPE_HEAD_TRACKING,
    TYPE_NATIVE_AR,
    TYPE_SLAM_AR,
    TYPE_STAMP_AR
} from "@geenee/builder/src/lib/constants";
import envConfig         from '@geenee/builder/src/lib/envConfig';
import Templates         from '@geenee/builder/src/lib/nodeTemplates';
import { container }     from '@geenee/builder/src/magellan/di/di';
import { MoleculeModel } from '@geenee/builder/src/magellan/model/molecule.model';
import { ProjectModel }  from './project.model';

@injectable()
export class SectionModel extends SectionModelShared implements SectionSchemaType {
    httpClient: HttpClient = container.get('<HttpClient>');
    analytics: Analytics = container.get('<Analytics>');

    @computed
    get parentProjectEditable(): Nullable<ProjectModel> {
        return this.parentProject as Nullable<ProjectModel>;
    }

    constructor() {
        super();
        makeObservable(this);
    }

    @observable showMissingContentError = false;

    @action
        setShowMissingContentError = (value: boolean) => {
            this.showMissingContentError = value;
        };

    @action updateParentTitleType = async (type: SceneSectionTypeUnionType) => {
        if (!this.$parent) {
            return;
        }
        if (this.parentProjectEditable) {
            if (type === TYPE_SLAM_AR) {
                this.$parent.title = Templates.SLAM_AR_VIEW.title;
            } else if (type === TYPE_STAMP_AR) {
                this.$parent.title = Templates.STAMP_AR_VIEW.title;
            } else if (type === TYPE_NATIVE_AR) {
                this.$parent.title = Templates.NATIVE_AR_VIEW.title;
            } else if (type === TYPE_BODY_TRACKING_OVERLAY) {
                this.$parent.title = Templates.BODY_OVERLAY_AR_VIEW.title;
            } else if (type === TYPE_BODY_TRACKING_TWIN) {
                this.$parent.title = Templates.BODY_TWIN_AR_VIEW.title;
            } else if (type === TYPE_HEAD_TRACKING) {
                this.$parent.title = Templates.FACE_AR_VIEW.title;
            }
            await this.$parent.saveData();
        }
    };

    @action setTypeAndTitle = async (type: SceneSectionTypeUnionType) => {
        this.type = type;
        if (type === TYPE_SLAM_AR) {
            this.options.section_menu_title = Templates.SLAM_AR.options.section_menu_title;
        } else if (type === TYPE_STAMP_AR) {
            this.options.section_menu_title = Templates.STAMP_AR.options.section_menu_title;
        } else if (type === TYPE_NATIVE_AR) {
            this.options.section_menu_title = Templates.NATIVE_AR.options.section_menu_title;
        } else if (type === TYPE_BODY_TRACKING_OVERLAY) {
            this.options.section_menu_title = Templates.BODY_OVERLAY_AR.options.section_menu_title;
        } else if (type === TYPE_BODY_TRACKING_TWIN) {
            this.options.section_menu_title = Templates.BODY_TWIN_AR.options.section_menu_title;
        } else if (type === TYPE_HEAD_TRACKING) {
            this.options.section_menu_title = Templates.FACE_AR.options.section_menu_title;
        }
        this.options.section_is_visible_in_menu = true;
        await this.updateParentTitleType(type);
        return this.saveData();
    };

    @action
    async saveData(analyticData?: { eventName: string, properties: object }) {
        try {
            const { data: { data } } = await this.httpClient.put(
                `${ envConfig.API_URL }/api/v0/sections/${ this.id }`,
                { section: toJS(this.toServerData()) }
            );
            this.updateState(this);
            this.parentProjectEditable?.saveData();

            if (analyticData?.eventName && analyticData?.properties) {
                this.analytics.track(analyticData?.eventName, analyticData?.properties);
            }

            return data;
        } catch (e) {
            console.error('Error while updating section: ', e);

            return new Error('Error while updating section');
        }
    }

    toServerData() {
        return {
            ...this,
            $parent:                  undefined,
            attachmentsRegistry:      undefined,
            molecules:                undefined,
            moleculesRegistry:        undefined,
            parentProject:            undefined,
            parentProjectEditable:    undefined,
            httpClient:               undefined,
            moleculeFactory:          undefined,
            imageAttachmentsRegistry: undefined,
            sceneRenderer:            undefined,
            arProcessor:              undefined,
            attachmentFactory:        undefined,
            sceneManager:             undefined
        };
    }

    setNewMoleculeTitle(schema: Partial<MoleculeSchemaType>) {
        const existingTitle = schema.options?.molecule_menu_title;
        if (existingTitle) {
            let counter = 1;
            while (1) {
                schema.options!.molecule_menu_title = `${ existingTitle } ${ counter }`;
                if (Array.from(this.moleculesRegistry.values()).find((el) => el.title === schema.options?.molecule_menu_title)) {
                    counter += 1;
                } else {
                    break;
                }
            }
        }
    }

    @action
    async createMolecule(moleculeSchema: Partial<MoleculeSchemaType> = {}) {
        try {
            const molecules = Array.from(this.moleculesRegistry.values()).map((el) => el.options.order || 0);
            const order = molecules.length ? Math.max(...molecules) : 0;
            const composedSchema = {
                ...moleculeSchema,
                options: {
                    ...moleculeSchema.options,
                    order:               order + 1,
                    molecule_menu_title: moleculeSchema.type === 'scene-build' ? 'Scene' : undefined
                },
                section_id: this.id
            };
            this.setNewMoleculeTitle(composedSchema);

            const { data: { data } } = await this.httpClient.post(`${ envConfig.API_URL }/api/v0/molecules`, { molecule: composedSchema });

            const { id, section_id, type } = data;

            this.analytics.track(MOLECULE_CREATED, { id, section_id, type });

            composedSchema.id = id;
            const moleculeModel = this.moleculeFactory.create(composedSchema, this) as MoleculeModel;
            this.moleculesRegistry.set(moleculeModel.id, moleculeModel);
            await moleculeModel.createAtomsByMoleculeType();
            this.parentProjectEditable?.saveData();
            return moleculeModel;
        } catch (e) {
            console.error('Error while creating molecule: ', e);

            return new Error('Error while creating a molecule');
        }
    }

    @action async deleteChild(childId: string) {
        try {
            await this.httpClient.delete(`${ envConfig.API_URL }/api/v0/molecules/${ childId }`);
            this.moleculesRegistry.delete(childId);
            this.parentProjectEditable?.saveData();

            this.analytics.track(MOLECULE_DELETED, { id: childId });
        } catch (e) {
            console.error('Error while deleting molecule: ', e);
        }
    }
}
