import styled from 'styled-components';

export const DeletedSectionRoot = styled.li`
  height: 30px;
  background: #FFFDE1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
  z-index: 1;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 30px;
    width: 60%;
    left: -59%;
    background: #FFFDE1;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
    clip-path: inset(-5px 1px -5px -5px);
  }
`;
