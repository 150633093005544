import { Box3, Vector3 } from 'three';

const DISTANCE_COEF = 1;

export const fitCameraToObject = (camera: any, object: any, lookAtObjectCenter = false, customDistance?: number) => {
    const boundingBox = new Box3().setFromObject(object);
    const containerSize = boundingBox.getSize(new Vector3());
    const objCenter = boundingBox.getCenter(new Vector3());

    const maxSize = Math.max(containerSize.x, containerSize.y, containerSize.z);
    const fitHeightDistance =        maxSize / (2 * Math.atan((Math.PI * camera.fov) / 360));
    const fitWidthDistance = fitHeightDistance / camera.aspect;

    const distance =        DISTANCE_COEF * Math.max(fitHeightDistance, fitWidthDistance);

    // camera.position.set(0, 0.3, distance);
    camera.position.set(0, lookAtObjectCenter ? objCenter.y : 0.3, customDistance || distance);

    // camera.lookAt(new Vector3(0, 0, 0));
    camera.lookAt(lookAtObjectCenter ? objCenter : new Vector3(0, 0, 0));
};
