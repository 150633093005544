import React                from "react";
import { SectionComponent } from "@geenee/geeclient-kit/src/lib/component/section/section.component";
import { useInject }        from "@geenee/shared/src/hook/use-inject.hook";
import { SectionModel }     from "@geenee/shared/src/magellan/section/model/section.model";
import { observer }         from "mobx-react";

export const ViewViewComponent = observer(() => {
    const { AppState } = useInject();
    const { firstAvailableSection } = AppState;

    const experienceModel = AppState.activeView;

    if (!experienceModel || !firstAvailableSection) {
        return <></>;
    }

    const sections: SectionModel[] = process.env.ENV_GEENEE_APP === 'BUILDER'
        ? [ firstAvailableSection ] : Array.from(experienceModel.sectionsRegistry.values());
    return (
        <>
            { sections.map((sectionModel) => (
                <SectionComponent sectionModel={ sectionModel } key={ sectionModel.id } />
            )) }
        </>
    );
});
