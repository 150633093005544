import React, { useMemo }                          from 'react';
import Uploader                                    from "@geenee/geeclient-kit/src/lib/component/scene/components/Uploader";
import { cn, Description, Icon, iconMap, Wrapper } from '@geenee/ui';
import { observer }                                from "mobx-react";
import { useAssetSourceConfigBabylon }             from "@geenee/builder/src/components/AssetsSourcePickerBabylon/hooks/useAssetSourceConfigBabylon";
import { stripeState }                             from '@geenee/builder/src/core/state/stripe.state';
import './assetsSourcePickerBabylon.scss';

interface Props {
  mode?: 'add' | 'replace'
  onPanelClose: (panelId?: 'materials' | 'textures' | 'layers') => void
}

const className = cn('assets-source-picker-babylon');

export const AssetsSourcePickerBabylon = observer(({ mode = 'add', onPanelClose }: Props) => {
    const { itemsConfig, uploadHandler, uploaderId } = useAssetSourceConfigBabylon(
        (s) => s,
        onPanelClose,
        stripeState.isActiveTeamOrEnterprise,
        mode
    );
    const items = useMemo(() => itemsConfig.map((el, index) => (el.id === 'separator'
        ? <Wrapper key={ el.id + index } fullWidth className={ className('separator') } />
        : (
            <Wrapper
                padding="xs"
                key={ el.id + index }
                row
                frame="transparent-hover-opacity-white"
                radius="sm"
                fullWidth
                align="left"
                valign="center"
                onClick={ el.onClick }
            >
                <Icon stroke="fat" margin='xs' size={ 16 } name={ el.iconLabel as keyof typeof iconMap } color="shade-4" />
                <Description size="sm">
                    { el.label }
                </Description>
            </Wrapper>
        ))), [ itemsConfig ]);

    return (
        <Wrapper fullWidth fullHeight>
            <Wrapper>
                { items }
            </Wrapper>
            <Uploader
                hideUploadText
                name={ uploaderId }
                description=""
                // @ts-ignore
                handleUpload={ uploadHandler }
                accept=".glb,.gltf,.babylon"
                skipTypeValidation
            />
        </Wrapper>
    );
});
