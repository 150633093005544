import APIRequest from '@geenee/builder/src/lib/APIRequest';

export const USER_SERVER_URL =    'https://zlppglhwdd.execute-api.eu-central-1.amazonaws.com/dev';

// @ts-ignore
export const login = (email, password) => APIRequest.post(
    '/api/v0/public/auth',
    {
        email,
        password
    }
);

// @ts-ignore
export const signUpApi = (email, password) => APIRequest.post(
    '/api/v0/public/register',
    {
        email,
        password
    }
).then((data) => data.data);

// @ts-ignore
export const signUpWithInviteApi = (password: string, code: string) => APIRequest.post(
    '/api/v0/public/register',
    {
        password,
        code
    }
).then((data) => data.data);

// @ts-ignore
export const forgotApi = (email: string) => APIRequest.post('/api/v0/public/auth/password_reset', { email });

// @ts-ignore
export const resetPasswordApi = (code: string, password: string) => APIRequest.post(
    `/api/v0/public/auth/password_reset/${ code }`,
    { password }
);

// @ts-ignore
export const loadUserDataComposerApi = () => APIRequest.get(
    '/api/v0/account'
).then((res) => res.data);

export const loadMockUserDataComposerApi = () => APIRequest.request('GET', 'http://localhost:3000/init', { external: true }, { external: true });
