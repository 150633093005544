import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({ width = '24', height = '25', onClick, id }: SvgIconProps) {
    return (
        <svg
            id={ id }
            onClick={ onClick }
            width={ width }
            height={ height }
            viewBox="0 0 24 25"
            xmlns="http://www.w3.org/2000/svg"
        >
            { /* eslint-disable-next-line max-len */ }
            <path d="M13.3351 0.82995L21.3351 4.747C22.3546 5.24309 22.9995 6.26151 23.0001 7.37684V16.7097C22.9995 17.8245 22.3546 18.843 21.3373 19.338L13.3376 23.2549C12.4925 23.669 11.4976 23.669 10.6529 23.255L2.6501 19.3366C1.63159 18.8341 0.99216 17.8094 1.00007 16.6994L1.00007 7.37632C1.00068 6.26151 1.64559 5.24309 2.66286 4.74808L10.6651 0.82995C11.506 0.420738 12.4941 0.420738 13.3351 0.82995Z" fill="white" />
            { /* eslint-disable-next-line max-len */ }
            <path fillRule="evenodd" clipRule="evenodd" d="M21.3351 4.747L13.3351 0.82995C12.4941 0.420738 11.506 0.420738 10.6651 0.82995L2.66286 4.74808C1.64559 5.24309 1.00068 6.26151 1.00007 7.37632L1.00007 16.6994C0.99216 17.8094 1.63159 18.8341 2.6501 19.3366L10.6529 23.255C11.4976 23.669 12.4925 23.669 13.3376 23.2549L21.3373 19.338C22.3546 18.843 22.9995 17.8245 23.0001 16.7097V7.37684C22.9995 6.26151 22.3546 5.24309 21.3351 4.747ZM12.0001 9.96402L4.24114 6.165L11.5573 2.58279C11.8351 2.44761 12.164 2.44747 12.4442 2.58342L19.759 6.165L12.0001 9.96402ZM13.0001 11.6641L21.0001 7.74704L21.0001 16.7092C20.9999 17.0803 20.7849 17.4198 20.4451 17.5851L13.0001 21.2304V11.6641ZM11.0001 11.6641L3.00007 7.74704L3.00005 16.7062C2.9974 17.0785 3.21054 17.4201 3.54729 17.5862L11.0001 21.2353V11.6641Z" fill="black" />
        </svg>
    );
}
