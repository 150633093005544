import React                            from "react";
import { Button, Description, Wrapper } from "@geenee/ui";

type Props = {
    disabled: boolean;
    clickHandler: () => void;
    publish?: boolean
}

export function MintYourProject({
    disabled,
    clickHandler,
    publish
}: Props) {
    return (
        <Wrapper padding="xs">
            <Description
                size="sm"
                color={ publish ? "white" : "dark" }
                margin="xs"
            >
                Mint Your Project:
            </Description>
            <Button
                radius="sm"
                disabled={ disabled }
                onClick={ clickHandler }
                viewType="secondary"
            >
                MAKE NFT
            </Button>
        </Wrapper>
    );
}
