import React, { useMemo }                          from 'react';
import Uploader                                    from '@geenee/geeclient-kit/src/lib/component/scene/components/Uploader';
import { cn, Description, Icon, iconMap, Wrapper } from '@geenee/ui';
import { observer }                                from 'mobx-react';
import { useBuilderInject }                        from '@geenee/builder/src/core/hook/use-builder-inject';
import './assetsSourcePicker.styles.scss';

const className = cn('assets-source-picker');

export const AssetsSourcePicker = observer(() => {
    const { getDIComponent } = useBuilderInject();
    const useAssetSourceConfig = getDIComponent('useAssetSourceConfig');

    const { itemsConfig, uploadHandler, uploaderId, uploadTypes } = useAssetSourceConfig({});

    const items = useMemo(() => itemsConfig.map((el, index) => (el.id === 'separator'
        ? <Wrapper key={ el.id + index } fullWidth className={ className('separator') } />
        : (
            <Wrapper
                padding="xs"
                key={ el.id + index }
                row
                frame="transparent-hover-opacity-white"
                radius="sm"
                fullWidth
                align="left"
                onClick={ el.onClick }
            >
                <Icon stroke="fat" margin='xs' size={ 16 } name={ el.iconLabel as keyof typeof iconMap } color="shade-4" />
                <Description size="sm">
                    { el.label }
                </Description>
            </Wrapper>
        ))), [ itemsConfig ]);

    return (
        <Wrapper fullWidth fullHeight>
            <Wrapper>
                { items }
            </Wrapper>
            <Uploader
                hideUploadText
                name={ uploaderId }
                description=""
                handleUpload={ uploadHandler }
                accept={ uploadTypes }
                skipTypeValidation
            />
        </Wrapper>
    );
});
