import {
    AttachmentModel,
    ExperienceFactory,
    ExperienceModel, ProgramType
} from '@geenee/shared';
import { inject, injectable }                        from 'inversify';
import { computed, observable, ObservableMap, toJS } from 'mobx';
import { BaseEntityModel }                           from './base-entity.model';
import { ProjectModel }                              from './project.model';

@injectable()
export class ProgramModel extends BaseEntityModel implements ProgramType {
    @observable country: ProgramType['country'] = null;
    @observable description: ProgramType['description'] = null;
    favicon_id: ProgramType['favicon_id'] = null;
    $parent!: ProjectModel;
    experiences = [];

    @observable header_logo_id: ProgramType['header_logo_id'] = '';
    @observable header_logo: ProgramType['header_logo'] = { url: '' };
    internal_domain: ProgramType['internal_domain'] = null;
    @observable mapped_domain: ProgramType['mapped_domain'] = null;
    @observable nft_published: ProgramType['nft_published'] = false;
    @observable published: ProgramType['published'] = false;
    splash_screen: ProgramType['splash_screen'] = '';
    @observable title: ProgramType['title'] = '';
    type = 'program';
    @observable logoImage?: AttachmentModel;
    options: ProgramType['options'] = {
        branding_bar_color: '',
        background_color:   '',
        logo_image_url:     '',
        logo_image_id:      ''
    };

    @inject('<ExperienceFactory>')
        experienceFactory!: ExperienceFactory;

    @observable experiencesRegistry: ObservableMap<
      string,
      ExperienceModel
      > = new ObservableMap([]);
    @observable powered_by_screen: ProgramType['powered_by_screen'];

    @computed get activeExperience() {
        return this.experiencesRegistry.get(this.$parent.options.experienceId || '');
    }

    @computed get sortedChildren() {
        return Array.from(this.experiencesRegistry.values()).sort((a, b) => (a.options.order > b.options.order ? 1 : -1));
    }

    @computed get toJsonObject(): ProgramModel {
        const experiences = Array.from(this.experiencesRegistry.values()).map((el) => el.toJsonObject);
        return { ...toJS(this), $parent: undefined, experiences, experiencesRegistry: undefined };
    }

    @computed get children() {
        return this.experiencesRegistry;
    }
}

export type ProgramModelType = ProgramModel
