import * as React                              from "react";
import type { PBRMetallicRoughnessMaterial }   from "@babylonjs/core/Materials/PBR/pbrMetallicRoughnessMaterial";
import { Observable }                          from "@babylonjs/core/Misc/observable";
import { CheckBoxLineComponent }               from "@geenee/geespector-ui-components/src/lines/checkBoxLineComponent";
import { Color3LineComponent }                 from "@geenee/geespector-ui-components/src/lines/color3LineComponent";
import { LineContainerComponent }              from "@geenee/geespector-ui-components/src/lines/lineContainerComponent";
import { SliderLineComponent }                 from "@geenee/geespector-ui-components/src/lines/sliderLineComponent";
import type { LockObject }                     from "@geenee/geespector-ui-components/src/tabs/propertyGrids/lockObject";
import { Wrapper }                             from "@geenee/ui";
import type { GlobalState }                    from "../../../../globalState";
import type { PropertyChangedEvent }           from "../../../../propertyChangedEvent";
import { ActionTabSectionComponent }           from "../../../actionTabSectionComponent";
import { TextureLinkLineComponent }            from "../../../lines/textureLinkLineComponent";
import { CommonMaterialPropertyGridComponent } from "./commonMaterialPropertyGridComponent";

interface IPBRMetallicRoughnessMaterialPropertyGridComponentProps {
    globalState: GlobalState;
    material: PBRMetallicRoughnessMaterial;
    lockObject: LockObject;
    onSelectionChangedObservable?: Observable<any>;
    onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
}

export class PBRMetallicRoughnessMaterialPropertyGridComponent extends React.Component<IPBRMetallicRoughnessMaterialPropertyGridComponentProps> {
    private _onDebugSelectionChangeObservable = new Observable<TextureLinkLineComponent>();

    constructor(props: IPBRMetallicRoughnessMaterialPropertyGridComponentProps) {
        super(props);
    }

    renderTextures() {
        const { material } = this.props;
        const onDebugSelectionChangeObservable = this._onDebugSelectionChangeObservable;

        return (
            <ActionTabSectionComponent title="TEXTURES" collapsed>
                <TextureLinkLineComponent
                    label="Base"
                    texture={ material.baseTexture }
                    propertyName="baseTexture"
                    material={ material }
                    onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                    onDebugSelectionChangeObservable={ onDebugSelectionChangeObservable }
                />
                <TextureLinkLineComponent
                    label="Metallic roughness"
                    texture={ material.metallicRoughnessTexture }
                    propertyName="metallicRoughnessTexture"
                    material={ material }
                    onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                    onDebugSelectionChangeObservable={ onDebugSelectionChangeObservable }
                />
                <TextureLinkLineComponent
                    label="Normal"
                    texture={ material.normalTexture }
                    propertyName="normalTexture"
                    material={ material }
                    onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                    onDebugSelectionChangeObservable={ onDebugSelectionChangeObservable }
                />
                <TextureLinkLineComponent
                    label="Environment"
                    texture={ material.environmentTexture }
                    propertyName="environmentTexture"
                    material={ material }
                    onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                    onDebugSelectionChangeObservable={ onDebugSelectionChangeObservable }
                />
                <TextureLinkLineComponent
                    label="Emissive"
                    texture={ material.emissiveTexture }
                    propertyName="emissiveTexture"
                    material={ material }
                    onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                    onDebugSelectionChangeObservable={ onDebugSelectionChangeObservable }
                />
                <TextureLinkLineComponent
                    label="Lightmap"
                    texture={ material.lightmapTexture }
                    propertyName="lightmapTexture"
                    material={ material }
                    onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                    onDebugSelectionChangeObservable={ onDebugSelectionChangeObservable }
                />
            </ActionTabSectionComponent>
        );
    }

    render() {
        const { material } = this.props;

        return (
            <Wrapper className="pane">
                <CommonMaterialPropertyGridComponent
                    globalState={ this.props.globalState }
                    lockObject={ this.props.lockObject }
                    material={ material }
                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                />
                { this.renderTextures() }
                <ActionTabSectionComponent title="LIGHTING & COLORS" collapsed>
                    <Color3LineComponent
                        label="Base"
                        target={ material }
                        propertyName="baseColor"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                        isLinear
                    />
                    <Color3LineComponent
                        label="Emissive"
                        target={ material }
                        propertyName="emissiveColor"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                        isLinear
                    />
                </ActionTabSectionComponent>
                <ActionTabSectionComponent title="LEVELS" collapsed>
                    <SliderLineComponent
                        label="Metallic"
                        target={ material }
                        propertyName="metallic"
                        minimum={ 0 }
                        maximum={ 1 }
                        step={ 0.01 }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                    <SliderLineComponent
                        label="Roughness"
                        target={ material }
                        propertyName="roughness"
                        minimum={ 0 }
                        maximum={ 1 }
                        step={ 0.01 }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                </ActionTabSectionComponent>
                <ActionTabSectionComponent title="NORMAL MAP" collapsed hasDivider={ false }>
                    <CheckBoxLineComponent
                        label="Invert X axis"
                        target={ material }
                        propertyName="invertNormalMapX"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                    <CheckBoxLineComponent
                        label="Invert Y axis"
                        target={ material }
                        propertyName="invertNormalMapY"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                </ActionTabSectionComponent>
            </Wrapper>
        );
    }
}
