import React, { FC, forwardRef, memo, PropsWithChildren } from "react";
import { CallbackFunctionVariadic }                       from "@geenee/geeclient-kit/src/lib/type/type";
import { cn }                                             from "@geenee/ui";
import { StyledComponent }                                from "styled-components";

type Props = {
  rootContainer?: StyledComponent<any, any> | React.ComponentType<any>;
  onClick: CallbackFunctionVariadic
  fullHeight?: boolean
}

const className = cn('drop-wrapper');

export const DropRootContainer: FC<Props> = memo(forwardRef((props: PropsWithChildren<Props>, ref) => {
    if (props.rootContainer) {
        const Root = (() => props.rootContainer)();
        return (
            <Root
                ref={ ref }
                onClick={ props.onClick }
            >
                { props.children }
            </Root>
        );
    }
    return (
        <div
            className={ className('container', { fullHeight: props.fullHeight }) }
            ref={ ref }
            onClick={ props.onClick }
        >
            { props.children }
        </div>
    );
}));
