import React                        from "react";
import { Description, Icon }        from '@geenee/ui';
import { ModalConfirmationContent } from "~/components/ModalConfirmationContent";
import { useModalDialogWrapper }    from "~/components/ModalDialogWrapper";

export const useDeleteModal = ({ onDelete }:{onDelete: ()=> void}) => {
    const [ showModal ] = useModalDialogWrapper(
        <ModalConfirmationContent
            onConfirm={ async () => {
                onDelete();
                return true;
            } }
            title="Are you sure you want to delete asset (-s)?"
            description={ (
                <Description color="shade-4" align="center">
                    By pressing “Delete Assets” you erase asset (-s) from Team Library. They no longer be avaialble.
                </Description>
            ) }
            confirmButtonName="Delete assets"
            iconName="remove"
            iconColor="gradient-warm-5"
        />,
        { closeTriggerElement: <Icon color="shade-4" name="closeRound" size={ 24 } /> },
        '448px'
    );
    return [ showModal ];
};
