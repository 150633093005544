import React, { FC, memo }               from 'react';
import { cn }                            from '../../util/bem';
import { ChildrenOf }                    from '../../util/types';
import { Wrapper, WrapperPropsType }     from "../wrapper/wrapper.component";
import { TabsPanel, TabsPanelPropsType } from './tabs-panel.component';
import './tabs-panel-container.component.scss';

type TabsPanelContainerPropsType = {
    children: ChildrenOf<typeof TabsPanel> | ChildrenOf<typeof TabsPanel>[];
    wrapperProps?: WrapperPropsType
    activeTabId: string;
};

const className = cn('tabs-panel-container');

/**
 * Internal component for render needed tab by index & advancing tabs to animated tabs
 * */
export const TabsPanelContainer: FC<TabsPanelContainerPropsType> = memo((props) => (
    <Wrapper { ...(props.wrapperProps || {}) } className={ className() }>
        {
            React.Children
                .toArray(props.children)
                .find((child) => React.isValidElement<TabsPanelPropsType>(child) && props.activeTabId === child.props.tabPanelId)
        }
    </Wrapper>
));
