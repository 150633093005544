import { Material }                       from "@babylonjs/core/Materials/material";
import { GroundMesh }                     from "@babylonjs/core/Meshes/groundMesh";
import { TransformNode }                  from "@babylonjs/core/Meshes/transformNode";
import { BabylonRenderer, SceneNodeType } from "@geenee/geespector/renderer/babylonjs.renderer";
import { GridStrategy }                   from "@geenee/geespector/src/straregies/GridStrategy";
import { LightStrategy, LightType }       from "@geenee/geespector/src/straregies/LightStrategy";
import { MaterialStrategy }               from "@geenee/geespector/src/straregies/MaterialStrategy";
import { ModelFileStrategy }              from "@geenee/geespector/src/straregies/ModelFileStrategy";
import { TransformNodeStrategy }          from "@geenee/geespector/src/straregies/TransformNodeStrategy";
import { AbstractCommand, CommandType }   from '@geenee/shared/src/commander/types';

export class AddToSceneCommand extends AbstractCommand<CommandType> {
    receiver: BabylonRenderer;
    entity: SceneNodeType | LightType | GroundMesh | File;
    parent?: SceneNodeType;
    strategy: CommandType | null = null;

    constructor(receiver: BabylonRenderer, entity: SceneNodeType | LightType | GroundMesh | File, parent?: SceneNodeType) {
        super();
        this.receiver = receiver;
        this.entity = entity;
        this.parent = parent;
        if (entity instanceof Material) {
            this.setStrategy(new MaterialStrategy(receiver, entity));
        } else if (entity instanceof GroundMesh) {
            this.bypassHistory = true;
            this.setStrategy(new GridStrategy(receiver, entity));
        } else if (entity instanceof TransformNode) {
            this.setStrategy(new TransformNodeStrategy(receiver, entity));
        } else if (typeof entity === 'string' && entity.includes('light')) {
            this.setStrategy(new LightStrategy(receiver, entity, parent));
        } else if (entity instanceof File) {
            this.setStrategy(new ModelFileStrategy(receiver, entity));
        }
    }
    setStrategy = (strategy: CommandType) => {
        this.strategy = strategy;
    };
    execute = () => {
        this.strategy?.execute();
    };

    revert = () => {
        this.strategy?.revert();
    };
}
