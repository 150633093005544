import { AtomModel } from '@geenee/shared';
import { computed }  from 'mobx';

export class AtomToLegacyAttachmentAdapter {
    atomModel: AtomModel;

    constructor(atomModel: AtomModel) {
        this.atomModel = atomModel;
    }

    @computed
    get url() {
        return this.atomModel.assetUrl;
    }

    @computed get translation() {
        // @ts-ignore
        return this.atomModel.options.scene_atom_position?.length ? {
        // return false ? {
            // @ts-ignore
            x: this.atomModel.options.scene_atom_position[ 0 ],
            // @ts-ignore
            y: this.atomModel.options.scene_atom_position[ 1 ],
            // @ts-ignore
            z: this.atomModel.options.scene_atom_position[ 2 ]
        } : { x: 0, y: 0, z: 0 };
    }

    @computed get rotation() {
        return this.atomModel.options.scene_atom_rotation?.length ? {
        // return false ? {
            // @ts-ignore
            x: this.atomModel.options.scene_atom_rotation[ 0 ],
            // @ts-ignore
            y: this.atomModel.options.scene_atom_rotation[ 1 ],
            // @ts-ignore
            z: this.atomModel.options.scene_atom_rotation[ 2 ]
        } : { x: 0, y: 0, z: 0 };
    }

    @computed get scale() {
        return this.atomModel.options.scene_atom_scale?.length ? {
        // return false ? {
            // @ts-ignore
            x: this.atomModel.options.scene_atom_scale[ 0 ],
            // @ts-ignore
            y: this.atomModel.options.scene_atom_scale[ 1 ],
            // @ts-ignore
            z: this.atomModel.options.scene_atom_scale[ 2 ]
        } : { x: 1, y: 1, z: 1 };
    }

    @computed
    get deprecatedSceneStats() {
        return [ {
            type: 'Rotation',
            data: this.rotation
        },
        {
            type: 'Position',
            data: this.translation
        },
        {
            type: 'Scale',
            data: this.scale
        }
        ];
    }
}
