import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({ width = '47', height = '61', onClick, id }: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 47 61"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d)">
                <rect
                    x="4.5"
                    y="0.5"
                    width="38"
                    height="38"
                    rx="4.5"
                    fill="white"
                    stroke="#BDB8B8"
                />
                <path
                // eslint-disable-next-line max-len
                    d="M30.6667 10H14.3333C13.0447 10 12 10.9949 12 12.2222V27.7777C12 29.005 13.0447 29.9999 14.3333 29.9999H30.6667C31.9553 29.9999 33 29.005 33 27.7777V12.2222C33 10.9949 31.9553 10 30.6667 10Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                // eslint-disable-next-line max-len
                    d="M18.4165 17.7786C19.383 17.7786 20.1665 17.0324 20.1665 16.112C20.1665 15.1915 19.383 14.4453 18.4165 14.4453C17.45 14.4453 16.6665 15.1915 16.6665 16.112C16.6665 17.0324 17.45 17.7786 18.4165 17.7786Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M33 23.3329L27.1667 17.7773L14.3334 29.9995"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.8657 31.2728L29.3333 24.5195L41.2813 31.2728L41.8008 45.2987L29.3333 51.5325L16.8657 44.7793V31.2728Z"
                    fill="#ED87C3"
                    stroke="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    // eslint-disable-next-line max-len
                    d="M40.6486 29.2284L30.9516 24.3799C29.9323 23.8734 28.7346 23.8734 27.7152 24.3799L18.0156 29.2297C16.7825 29.8424 16.0008 31.103 16.0001 32.4829L16.0001 44.023C15.9905 45.3969 16.7656 46.6652 18.0001 47.2872L27.7004 52.1374C28.7244 52.6498 29.9303 52.6498 30.9546 52.1372L40.6513 47.2889C41.8843 46.6762 42.666 45.4156 42.6668 44.0357V32.4836C42.666 31.103 41.8843 29.8424 40.6486 29.2284ZM29.3333 35.6857L19.9286 30.9834L28.7966 26.5494C29.1333 26.382 29.5321 26.3819 29.8716 26.5501L38.738 30.9834L29.3333 35.6857ZM30.5455 37.79L40.2424 32.9415L40.2424 44.0349C40.2422 44.4942 39.9816 44.9144 39.5697 45.1191L30.5455 49.6312V37.79ZM28.1213 37.7901L18.4242 32.9416L18.4242 44.0312C18.421 44.492 18.6793 44.9148 19.0875 45.1204L28.1213 49.6373V37.7901Z"
                    fill="black"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d"
                    x="0"
                    y="0"
                    width="47"
                    height="60.5217"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}
