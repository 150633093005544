import React                                                  from "react";
import type { IExplorerExtensibilityGroup }                   from "@babylonjs/core/Debug/debugLayer";
import type { Nullable }                                      from "@babylonjs/core/types";
import { CollapsedTreeItem }                                  from "@geenee/geeclient-kit/src/lib/component/scene/components/CollapsedTreeItem";
import InjectedComponent                                      from "@geenee/geeclient-kit/src/packages/shared/lib/util/abstract/injected-component";
import { Description,  EditableArea, Icon, iconMap, Wrapper } from '@geenee/ui';
import type { Container }                                     from 'inversify';
import { inject }                                             from "mobx-react";
import { v4 }                                                 from "uuid";
import { Tools }                                              from "../../tools";
import type { GlobalState }                                   from "../globalState";
import { getTreeIcon }                                        from "./getTreeIcon";
import { TreeItemSpecializedComponent }                       from "./treeItemSpecializedComponent";

export type ITreeItemSelectableComponentProps ={
    entity: any;
    selectedEntity?: any;
    mustExpand?: boolean;
    offset: number;
    globalState: GlobalStateType;
    extensibilityGroups?: IExplorerExtensibilityGroup[];
    filter: Nullable<string>;
}

type ITreeItemSelectableComponentState = {
  isExpanded: boolean,
  isSelected: boolean,
  editNameMode: boolean,
  nameAreaKey: string,
  internalChanges: boolean
}
type InjectPropsType = {
  container: Container
};

@inject('container')
class _TreeItemSelectableComponent extends InjectedComponent<ITreeItemSelectableComponentProps, InjectPropsType, ITreeItemSelectableComponentState> {
    private _wasSelected = false;
    BuilderState = this.injected.container.get('<BuilderState>');
    AppState = this.injected.container.get('<AppState>');
    renderer = this.AppState?.activeSection?.sceneManager?.sceneRenderer;

    constructor(props: ITreeItemSelectableComponentProps) {
        super(props);

        this.state = {
            isSelected:      this.props.entity === this.props.selectedEntity,
            isExpanded:      this.props.mustExpand || Tools.LookForItem(this.props.entity, this.props.selectedEntity),
            editNameMode:    false,
            nameAreaKey:     v4(),
            internalChanges: false
        };
        this.props.globalState.onPropertyChangedObservable.add((e) => {
            if (e.property === 'name' && !this.state.internalChanges) {
                this.setState({ nameAreaKey: v4(), internalChanges: false });
            }
        });
    }

    switchExpandedState(): void {
        this.setState({ isExpanded: !this.state.isExpanded });
    }

    shouldComponentUpdate(nextProps: ITreeItemSelectableComponentProps, nextState: ITreeItemSelectableComponentState) {
        if (!nextState.isExpanded && this.state.isExpanded) {
            return true;
        }

        if (nextProps.selectedEntity) {
            if (nextProps.entity === nextProps.selectedEntity) {
                if (nextProps.entity._isLight) {
                    this.props.globalState.enableLightGizmo(nextProps.entity, true);
                }
                nextState.isSelected = true;
                return true;
            }
            // if (this.props.entity._isLight) {
            //     this.props.entity.getScene().reservedDataStore?.gizmoManager?.attachToMesh(null);
            //     this.props.globalState.enableLightGizmo(this.props.entity, false);
            // }
            nextState.isSelected = false;

            if (Tools.LookForItem(nextProps.entity, nextProps.selectedEntity)) {
                nextState.isExpanded = true;
                return true;
            }
        }

        return true;
    }

    scrollIntoView() {
        const element = document.getElementById(`node-item_${ this.props.entity.uniqueId }`);

        if (element) {
            element.scrollIntoView(false);
        }
    }

    componentDidMount() {
        if (this.state.isSelected) {
            this.scrollIntoView();
        }
    }

    componentDidUpdate() {
        if (this.state.isSelected && !this._wasSelected) {
            this.scrollIntoView();
        }
        this._wasSelected = false;
    }

    onSelect() {
        if (!this.props.globalState.onSelectionChangedObservable) {
            return;
        }
        this._wasSelected = true;
        const { entity } = this.props;
        this.setState({ isSelected: true });
        this.props.globalState.onSelectionChangedObservable.notifyObservers(entity);
    }

    renderChildren() {
        const { entity } = this.props;
        if ((!entity.getChildren && !entity.children)) {
            return null;
        }

        const children = Tools.SortAndFilter(entity, entity.getChildren ? entity.getChildren() : entity.children);
        return children.map((item, i) => (
            <TreeItemSelectableComponent
                globalState={ this.props.globalState }
                mustExpand={ this.props.mustExpand }
                extensibilityGroups={ this.props.extensibilityGroups }
                selectedEntity={ this.props.selectedEntity }
                key={ i }
                offset={ this.props.offset + 1 }
                entity={ item }
                filter={ this.props.filter }
            />
        ));
    }

    onChangeName(value: string) {
        this.renderer.renameMesh(this.props.entity, value);
        this.setState({ editNameMode: false, internalChanges: true });
    }

    render() {
        const { entity } = this.props;
        const popover = this.BuilderState?.activePopover;
        popover?.eventEmitter?.addListener('rename', (item: any) => {
            if (item === entity) {
                this.setState({ editNameMode: true });
            }
        });
        const children = entity.getClassName() === "MultiMaterial"
            ? []
            : Tools.SortAndFilter(entity, entity.getChildren ? entity.getChildren() : entity.children);
        const hasChildren = children.length > 0;

        if (!entity.reservedDataStore) {
            entity.reservedDataStore = {};
        }

        entity.reservedDataStore.setExpandedState = (value: boolean) => {
            this.setState({ isExpanded: value });
        };
        entity.reservedDataStore.isExpanded = this.state.isExpanded;

        if (this.props.filter) {
            const lowerCaseFilter = this.props.filter.toLowerCase();
            if (!entity.name || entity.name.toLowerCase().indexOf(lowerCaseFilter) === -1) {
                if (!hasChildren) {
                    return null;
                }

                if (entity.getDescendants) {
                    if (
                        entity.getDescendants(false, (n: any) => n.name && n.name.toLowerCase().indexOf(lowerCaseFilter) !== -1).length === 0
                    ) {
                        return null;
                    }
                }
            }
        }

        const iconConfig = getTreeIcon(entity);
        const icon = iconMap[ iconConfig.icon as keyof typeof iconMap ]
            ? <Icon margin="xxs" stroke="fat" color="shade-4" name={ iconConfig.icon as any } />
            : <Icon margin="xxs" stroke="fat" color="shade-4" name="sdk" />;

        const Item = (
            <>
                <Description shorten fullWidth size='sm'>
                    <EditableArea
                        key={ this.state.nameAreaKey }
                        shorten
                        value={ entity.name }
                        onChange={ (value) => {
                            this.onChangeName(value);
                        } }
                        doubleClickMode
                        autoFocus={ this.state.editNameMode }
                    />
                </Description>
                <div className="treeItemActions">
                    <TreeItemSpecializedComponent
                        globalState={ this.props.globalState }
                        extensibilityGroups={ this.props.extensibilityGroups }
                        label={ entity.name }
                        entity={ entity }
                    />
                </div>
            </>
        );

        if (this.props.entity?.metadata?._skinAuxilaryNode) {
            return <></>;
        }

        return (
            <Wrapper fullWidth key={ `${ this.state.isExpanded }` }>
                <CollapsedTreeItem
                    content={ Item }
                    isSelected={ this.state.isSelected }
                    icon={ icon }
                    id={ `node-item_${ this.props.entity.uniqueId }` }
                    offset={ this.props.offset }
                    isExpanded={ this.state.isExpanded }
                    onCollapseChange={ (value: boolean) => {
                        this.setState({ isExpanded: !value });
                    } }
                    onClick={ () => {
                        this.onSelect();
                    } }
                >
                    { hasChildren && this.renderChildren() }
                </CollapsedTreeItem>
            </Wrapper>
        );
    }
}

export const TreeItemSelectableComponent = _TreeItemSelectableComponent;
