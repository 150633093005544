import React, {useCallback, useEffect, useState} from 'react';

export const Media = React.createContext('');

export type MediaSizesType = 'xsm' | 'sm' | 'md' | 'lg';

export const mediaSizes: Record<MediaSizesType, any> =     {
    xsm: 450,
    sm:  768,
    md:  992,
    lg:  1200
};

const getMediaSize = () => {
    if (typeof window !== 'undefined') {
        const { innerWidth: width } = window;
        if (width < mediaSizes.xsm) { return 'xsm'; }
        if (width < mediaSizes.sm) { return 'sm'; }
        if (width < mediaSizes.md) { return 'md'; }
        if (width < mediaSizes.lg) { return 'lg'; }
        return 'xlg';
    }
    return 'lg';
};

export const MediaProvider = (props: any) => {
    const [ windowDimensions, setWindowDimensions ] = useState(getMediaSize());

    const handleResize = useCallback(() => setWindowDimensions(getMediaSize()), []);

    useEffect(() => {
        console.log('useEffect')
        handleResize();
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }

        return () => {};
    }, []);

    return (
        <Media.Provider value={ windowDimensions }>
            { props.children }
        </Media.Provider>
    );
};
