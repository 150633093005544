import React                        from 'react';
import { CallbackFunctionVariadic } from "@geenee/geeclient-kit/src/lib/type/type";
import { Icon }                     from '@geenee/ui';
import { useModalDialogWrapper }    from '../../../components/ModalDialogWrapper';
import { PasswordModalContent }     from './PasswordModalContent';

export const usePasswordModal = ({ onChange }: {onChange: CallbackFunctionVariadic}) => {
    const [ showModal, hideModal ] = useModalDialogWrapper(
        <PasswordModalContent onChange={ onChange } />,
        { closeTriggerElement: <Icon color="shade-4" name="closeRound" size={ 24 } /> },
        '448px'
    );

    return { showModal, hideModal };
};
