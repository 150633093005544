import { UserModel } from '@geenee/builder/src/core/model/user.model';
import APIRequest    from '@geenee/builder/src/lib/APIRequest';

// @ts-ignore
export const getTeam = () => APIRequest.get(
    '/api/v0/account/users'
).then((res) => res.data);

// @ts-ignore
export const getInvitesApi = () => APIRequest.get('/api/v0/account/users/invites').then((res) => res.data);

// @ts-ignore
export const inviteMember = async (data: Record<string, any>) => APIRequest.post('/api/v0/account/users/invites', data);

// @ts-ignore
export const deleteInviteApi = async (id: string) => APIRequest.del(`/api/v0/account/users/invite/${ id }`);

// @ts-ignore
export const updateMember = async (id: string, data: Partial<UserModel>) => id && APIRequest.put(`/api/v0/account/users/${ id }`, { user: data });

// @ts-ignore
export const deleteMember = async (id: string) => APIRequest.del(
    `/api/v0/account/users/${ id }`
);

/* export const changeMemberPassword = async (data: Record<string, any>) => APIRequest.post(
    `${ USER_SERVER_URL }/api/team/change-password`,
    { ...data },
    { external: true }
); */
