import { IconAtomOptType } from '../../../type/_atom-option-type';

export const initProps: IconAtomOptType = {
    icon_switch:                 true,
    icon_type:                   'geenee',
    icon_size:                   's',
    icon_color_text:             'inherit',
    icon_color_text_alpha:       1,
    icon_color_text_type:        'custom',
    icon_color_text_variation:   'harmonic',
    icon_color_text_custom:      '',
    icon_color_bg:               'inherit',
    icon_color_bg_type:          'auto',
    icon_color_bg_alpha:         1,
    icon_color_bg_variation:     'harmonic',
    icon_color_bg_custom:        '',
    icon_color_border:           'inherit',
    icon_color_border_type:      'auto',
    icon_color_border_alpha:     1,
    icon_color_border_variation: 'harmonic',
    icon_color_border_custom:    '',
    icon_style:                  'transparent',
    icon_geenee:                 'text',
    icon_corner_radius:          '0',
    icon_corner_radius_custom:   50,
    icon_blur_radius:            's',
    icon_blur_radius_custom:     0
};

export const getPropsFromOwner = (
    options: IconAtomOptType,
    extendProps: Object = {}
) => ({
    icon_size:                   options.icon_size,
    icon_switch:                 options.icon_switch,
    icon_geenee:                 options.icon_geenee,
    icon_style:                  options.icon_style,
    icon_type:                   options.icon_type,
    icon_corner_radius:          options.icon_corner_radius,
    icon_corner_radius_custom:   options.icon_corner_radius_custom,
    icon_color_text:             options.icon_color_text,
    icon_color_text_alpha:       options.icon_color_text_alpha,
    icon_color_text_type:        options.icon_color_text_type,
    icon_color_text_variation:   options.icon_color_text_variation,
    icon_color_text_custom:      options.icon_color_text_custom,
    icon_color_bg:               options.icon_color_bg,
    icon_color_bg_alpha:         options.icon_color_bg_alpha,
    icon_color_bg_type:          options.icon_color_bg_type,
    icon_color_bg_variation:     options.icon_color_bg_variation,
    icon_color_bg_custom:        options.icon_color_bg_custom,
    icon_color_border:           options.icon_color_border,
    icon_color_border_alpha:     options.icon_color_border_alpha,
    icon_color_border_type:      options.icon_color_border_type,
    icon_color_border_custom:    options.icon_color_border_custom,
    icon_color_border_variation: options.icon_color_border_variation,
    icon_blur_radius:            options.icon_blur_radius,
    icon_blur_radius_custom:     options.icon_blur_radius_custom,
    ...extendProps
});
