import React, { useMemo, useState } from 'react';
import {
    Button,
    Description,
    Divider,
    FormItem,
    Input,
    InputTableItem,
    Span,
    Wrapper
} from '@geenee/ui';
import { observer }         from 'mobx-react';
import { useBuilderInject } from "@geenee/builder/src/core/hook/use-builder-inject";
import { sdkTokenState }    from "@geenee/builder/src/core/state/sdkToken.state";
import { TOAST_ERROR }      from "@geenee/builder/src/lib/constants";

export const URL_REGEX = /http(s)*:\/\/((.{2,}\..{2,}|localhost)(:.{1,4})*)\/*.*/;

export function UrlInputBefore() {
    return (
        <Wrapper style={ { paddingLeft: 0 } } padding="sm">
            <Span color="shade-4" margin="sm">http(s)://</Span>
        </Wrapper>
    );
}

export const checkUrl = (url) => {
    if (!url) return null;

    try {
        const urlForTesting = `http://${ url }`;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const urlObj = new URL(urlForTesting);
        return URL_REGEX.test(urlForTesting);
    } catch (parseError) {
        return false;
    }
};

export const CreateToken = observer(({ onModeChange }:{ onModeChange: ()=> void }) => {
    const [ hostUrl, setHostUrl ] = useState<string>('');
    const { BuilderState } = useBuilderInject();

    const isUrl = useMemo(() => checkUrl(hostUrl), [ hostUrl ]);
    return (
        <Wrapper>
            <Wrapper style={ { paddingBottom: 0 } } margin="xs" padding="md">
                <Description color="grey-9" weight='bold' margin='xs' uppercase>New Token</Description>
            </Wrapper>
            <Wrapper frame="solid-default" maxWidth="640px" padding="md">
                <InputTableItem weight="bold" valign="base-line" viewType="secondary" size="lg" label="Host URL:">
                    <FormItem autoFocusDetect margin="off" error={ isUrl !== null && !isUrl ? 'Invalid URL' : false }>
                        <Input
                            before={ <UrlInputBefore /> }
                            size="sm"
                            value={ hostUrl }
                            onChange={ (e) => setHostUrl((e.target.value || '').replace(/http(s)*:\/\//, '')) }
                        />
                    </FormItem>
                </InputTableItem>
                <Divider color="grey-3" margin="sm" />
                <Wrapper row align="right">
                    <Button
                        margin="sm"
                        viewType="secondary"
                        uppercase
                        onClick={ () => onModeChange() }
                    >
                        Nevermind
                    </Button>
                    <Button
                        uppercase
                        onClick={ async () => {
                            if (!isUrl) {
                                BuilderState.toast = {
                                    severity: TOAST_ERROR,
                                    detail:   `Use URL format`,
                                    summary:  'Invalid format.'
                                };
                            } else {
                                try {
                                    const url = new URL(`http://${ hostUrl }`);
                                    let saveUrlString = url.hostname + url.pathname;
                                    // Note: on WA side there is a normalization and there is a need to do it on the FE too
                                    if (saveUrlString.endsWith('/')) {
                                        saveUrlString = saveUrlString.slice(0, -1);
                                    }
                                    await sdkTokenState.createToken(saveUrlString);
                                    setHostUrl('');
                                    onModeChange();
                                } catch (parseError) {
                                    BuilderState.toast = {
                                        severity: TOAST_ERROR,
                                        detail:   `Use URL format`,
                                        summary:  'Invalid format.'
                                    };
                                }
                            }
                        } }
                    >
                        Add new token
                    </Button>
                </Wrapper>
            </Wrapper>
        </Wrapper>
    );
});
