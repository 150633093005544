import React, { FC, memo }     from 'react';
import ReactDatepicker         from 'react-datepicker';
import { cn }                  from '../../util/bem';
import { SizesUnionType }      from '../../util/global-props';
import { Icon }                from '../icon/icon.component';
import { Input }               from '../input/input.component';
import { usePortal }           from '../portal/portal.hook';
import { useDatePickerHeader } from './date-picker-header.hook';
import 'react-datepicker/dist/react-datepicker.css';
import './date-picker.component.scss';

export type DatePickerPropsType = {
    disableHeader?: boolean;
    shouldCloseOnSelect?: boolean;
    customInput?: React.ReactNode;
    minDate?: Date;
    maxDate?: Date;
    dateFormat?: string;
    hideDisabled?: boolean;
    showTimeSelectOnly?: boolean;
    showTimeSelect?: boolean;

    // value?: Date | number;
    value?: Date;
    onChange: (value: Date) => any;
    placeholder?: string;

    margin?: SizesUnionType;
    className?: string;
    disabled?: boolean;
}

const className = cn('datepicker');

export const DatePicker: FC<DatePickerPropsType> = memo((props) => {
    const { Portal } = usePortal();
    const header = useDatePickerHeader(className('header'), props.disableHeader);
    const cnComp = className({
        margin:       props.margin,
        hideDisabled: props.hideDisabled
    }, null, props.className);

    return (
        <>
            { /* @ts-ignore */ }
            <ReactDatepicker
                wrapperClassName={ cnComp }
                calendarClassName={ cnComp }
                shouldCloseOnSelect={ props.shouldCloseOnSelect }
                selected={ props.value }
                onChange={ props.onChange }
                placeholderText={ props.placeholder }
                showTimeSelectOnly={ props.showTimeSelectOnly }
                showTimeSelect={ props.showTimeSelect }
                customInput={ props.customInput }
                renderCustomHeader={ header }
                minDate={ props.minDate }
                maxDate={ props.maxDate }
                dateFormat={ props.dateFormat }
                popperContainer={ Portal }
                disabled={ props.disabled }
            />
        </>

    );
});

DatePicker.defaultProps = {
    margin:              'xs',
    shouldCloseOnSelect: true,
    hideDisabled:        false,
    placeholder:         'Select date',
    dateFormat:          'MM/dd/yyyy',
    customInput:         <Input after={ <Icon name="calendar" /> } />,
    disabled:            false
};
