import { MoleculeModel }                 from '@geenee/shared/index';
import { AtomToLegacyAttachmentAdapter } from '@geenee/shared/src/magellan/atom/component/scene-atom/util';
import { SceneRenderer }                 from '@geenee/shared/src/magellan/renderer/r3f-renderer/r3f.renderer';
import {
    TYPE_BODY_TRACKING_OVERLAY, TYPE_BODY_TRACKING_TWIN,
    TYPE_HEAD_TRACKING,
    TYPE_NATIVE_AR, TYPE_SCENE_BUILD,
    TYPE_STAMP_AR
} from "@geenee/shared/src/util/constants";
import { getDynamicContent }    from '@geenee/shared/src/util/getDynamicContent';
import { SectionTypeUnionType } from '@geenee/shared/type/section.type';

export const setArAttachment = (
    _contentModel: any,
    sceneModel: SceneRenderer,
    attachId: string,
    callback?: (activeAttachment: any) => any
) => {
    const activeMolecule: MoleculeModel = _contentModel;

    if (activeMolecule) {
        const atoms = Array.from(activeMolecule.atomsRegistry.values());
        const parsedAtoms = atoms.map((at) => new AtomToLegacyAttachmentAdapter(activeMolecule.constructTagThatFormatLikeAttachment(at)));

        const getActiveAttachementWithDynamicContentCheck = () => {
            const dynamicContentConfig = activeMolecule?.options?.dynamic_content || {};

            if (dynamicContentConfig?.enabled) {
                return getDynamicContent(parsedAtoms, dynamicContentConfig, true);
            }

            return parsedAtoms[ 0 ];
        };

        const activeAttachment = attachId ? parsedAttachements.find((a) => a.id === attachId) : getActiveAttachementWithDynamicContentCheck();
        if (activeAttachment) {
            // @ts-ignore
            sceneModel.updateOption('arAssetId', activeAttachment.id);
        }

        if (activeAttachment && callback) {
            callback(activeAttachment);
        }
        // console.log(activeAtt);
    }
};

export const isArSceneType = (type: SectionTypeUnionType) => type === 'slam-ar'
    || type === TYPE_NATIVE_AR
    || type === TYPE_STAMP_AR
    || type === TYPE_HEAD_TRACKING
    || type === TYPE_BODY_TRACKING_OVERLAY
    || type === TYPE_BODY_TRACKING_TWIN
    || type === TYPE_SCENE_BUILD;
