import React                     from 'react';
import { cn, Collapse, Wrapper } from "@geenee/ui";
import './styles.scss';

type CollapsedTreeProps = {
  children?: React.ReactNode;
  isSelected: boolean
  isExpanded?: boolean
  onCollapseChange?: (val: boolean) => void
  icon: React.ReactNode
  content: React.ReactNode
  id?: string
  onClick?: ()=> void
  offset?: number
}

const className = cn('collapsed-tree-item');
export const CollapsedTreeItem = (props: CollapsedTreeProps) => (
    <Wrapper radius='sm'>
        { props.children ? (
            <Collapse
                clickMode="icon"
                icon={ props.icon }
                paddingLeft={ 20 }
                collapsed={ !props.isExpanded }
                onCollapseChange={ props.onCollapseChange }
                iconCollapsedProps={ {
                    name:   'arrowRightSmall',
                    color:  'shade-4',
                    margin: 'xxs',
                    size:   16
                } }
                panelContent={ (
                    <Wrapper
                        onClick={ props.onClick }
                        valign="center"
                        overflow="hidden"
                        row
                        id={ props.id }
                        color='shade-1'
                    >
                        { props.content }
                    </Wrapper>
                ) }
                panelWrapperProps={ {
                    valign:    'center',
                    padding:   'xxs',
                    radius:    'sm',
                    className: className({ isSelected: props.isSelected }),
                    style:     { '--treeItemOffset': `${ ((props.offset || 1) - 1) * -20 }px` } as React.CSSProperties
                } }
            >
                { props.children }
            </Collapse>
        ) : (
            <Wrapper
                className={ className({ isSelected: props.isSelected }) }
                id={ props.id }
                key={ props.id }
                padding="xxs"
                fullWidth
                row
                valign="center"
                color="shade-1"
                radius="sm"
                onClick={ props.onClick }
                style={ { '--treeItemOffset': `${ (props.offset - 1) * -20 }px` } as React.CSSProperties }
            >
                { props.icon }
                { props.content }
            </Wrapper>
        ) }
    </Wrapper>
);
