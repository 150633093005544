const IconDefs = `
            <linearGradient id="gradient-warm-1" xmlns="http://www.w3.org/2000/svg" x1="26" y1="0" x2="0" y2="26" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFE800"></stop>
                <stop offset="0.172134" stop-color="#FFB444"></stop>
                <stop offset="0.463678" stop-color="#FF5E86"></stop>
                <stop offset="0.715631" stop-color="#E24EFF"></stop>
                <stop offset="1" stop-color="#8E53FF"></stop>
            </linearGradient>
            <linearGradient id="gradient-warm-2" x1="13.9996" y1="0.666748" x2="0.0238807" y2="13.8427" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFE800"/>
                <stop offset="0.335979" stop-color="#FFB444"/>
                <stop offset="1" stop-color="#FF5D86"/>
            </linearGradient>
            <linearGradient id="gradient-warm-3" x1="34.125" y1="1.875" x2="3.375" y2="32.625" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFB444"></stop>
                <stop offset="1" stop-color="#FF1D97"></stop>
            </linearGradient>
            <linearGradient id="gradient-warm-4" x1="13.6663" y1="0.858643" x2="-0.582711" y2="14.1605" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFB444"/>
                <stop offset="0.527762" stop-color="#FF1D97"/>
                <stop offset="1" stop-color="#E24EFF"/>
            </linearGradient>
            <linearGradient id="gradient-warm-5" x1="45.5" y1="2.5" x2="2.5" y2="45.5" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF89C9"/>
                <stop offset="0.527762" stop-color="#FF008A"/>
                <stop offset="1" stop-color="#DB00FF"/>
            </linearGradient>
            <linearGradient id="gradient-cold-1" x1="15.3337" y1="0.666748" x2="0.666992" y2="15.3334" gradientUnits="userSpaceOnUse">
                <stop stop-color="#16D8F5"/>
                <stop offset="0.47432" stop-color="#007CFF"/>
                <stop offset="0.929614" stop-color="#8E53FF"/>
            </linearGradient>
            <linearGradient id="gradient-cold-3" x1="43.5" y1="0.5" x2="0.5" y2="43.5" gradientUnits="userSpaceOnUse">
                <stop stop-color="#5BFF7F"></stop>
                <stop offset="0.506588" stop-color="#00CDC1"></stop>
                <stop offset="1" stop-color="#007CFF"></stop>
            </linearGradient>
            <linearGradient id="gradient-key" x1="20.48" y1="99.85" x2="170.47"
            y2="99.85" gradientTransform="matrix(0.6, -0.8, -0.8, -0.6, 98.04, 211.22)" gradientUnits="userSpaceOnUse">
                <stop offset="0.02" stop-color="#F4C0A5"/>
                <stop offset="0.49" stop-color="#E491F1" />
                <stop offset="1" stop-color="#A9C9F9" />
            </linearGradient>
`;
export const addIconDefsToBody = () => {
    const exist = document.body.getElementsByTagName('svg-defs');
    if (!exist.length) {
        const el = document.createElement('svg-defs');
        el.innerHTML = `<svg height="0" width="0"><defs>${ IconDefs }</defs></svg>`;
        document.body.appendChild(el);
    }
};
