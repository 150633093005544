/* eslint-disable max-len */
import React from 'react';

export const None = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="stroke"
      d="M3.5 3.5L13 13"
      stroke="#303036"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      className="stroke"
      cx="8"
      cy="8"
      r="7.25"
      stroke="#303036"
      strokeWidth="1.5"
    />
  </svg>
);
