import React, { FC, useMemo, useRef, useState } from "react";
import MForm                                    from "react-jsonschema-form";
import ArrayFieldTemplate                       from "./generation-engine/ArrayFieldTemplate";
import BaseInput                                from "./generation-engine/BaseInput";
import CheckboxWidget                           from "./generation-engine/CheckboxWidget";
import FieldTemplate                            from "./generation-engine/FieldTemplate";
import ObjectFieldTemplate                      from "./generation-engine/ObjectFieldTemplate";
import RadioWidget                              from "./generation-engine/RadioWidget";
import SelectWidget                             from "./generation-engine/SelectWidget";
import { jsonSchemaPreprocess }                 from "./json-schema-preprocess";
import './json-shema-form.component.scss';

const widgets = {
    BaseInput,
    SelectWidget,
    CheckboxWidget,
    RadioWidget
};

type Props = {
  onChange?: (data: Record<string, any>) => void;
  uiSchema?: { [key: string]: any };
  schema: { [key: string]: any };
  liveValidate?: boolean;
  value?: Record<string, any>
}

// eslint-disable-next-line react/function-component-definition
const JsonSchemaForm: FC<Props> = ({
    schema, uiSchema, liveValidate, onChange, value = {}
}) => {
    const preprocessedSchema = useMemo(() => jsonSchemaPreprocess(schema), [ schema ]);
    const [ formData, setFormData ] = useState(value);
    const formRef = useRef(null);
    const handleChange = (newFormData: Record<string, any>) => {
        setFormData(newFormData);
        const form = formRef.current as any as MForm<any>;
        if (form) {
            const { errors } = form.validate(newFormData);
            if (errors && !errors.length) {
                onChange && onChange(newFormData as FormData);
            }
        }
    };

    return (
        <MForm
            ref={ formRef }
            noHtml5Validate
            FieldTemplate={ FieldTemplate }
            ArrayFieldTemplate={ (props) => (
                <ArrayFieldTemplate
                    { ...props }
                    formData={ formData }
                    onChange={ handleChange }
                />
            ) }
            ObjectFieldTemplate={ ObjectFieldTemplate }
            schema={ preprocessedSchema }
            formData={ formData }
            uiSchema={ uiSchema }
            widgets={ widgets }
            showErrorList={ false }
            liveValidate={ liveValidate }
            onChange={ (e) => handleChange(e.formData) }
        >
            <></>
        </MForm>
    );
};
JsonSchemaForm.defaultProps = { liveValidate: true };

export default JsonSchemaForm;
