import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '12',
    height = '12',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="6"
                cy="6"
                r="5.5"
                fill="url(#paint0_linear_fand)"
                stroke="#BBBBBB"
            />
            <path
            // eslint-disable-next-line max-len
                d="M8.1454 4.71542L7.69748 3.0437L3 4.30241L3.44813 5.97492L3.44827 5.97565C3.72922 6.03307 3.96808 6.24342 4.04733 6.53914C4.12659 6.83504 4.02484 7.13659 3.81022 7.3268L3.8105 7.32751L4.25872 9.00001L8.9562 7.7413L8.50842 6.06951C8.22581 6.01296 7.98572 5.80222 7.90603 5.50523C7.82648 5.20831 7.92931 4.90562 8.1454 4.71542ZM6.39297 4.77406L4.99489 5.14864L5.27584 6.19722L6.67393 5.82264L6.95486 6.87121L5.55678 7.24594L5.83773 8.29446L4.43965 8.66905L4.09977 7.40027C4.30385 7.14947 4.38904 6.80786 4.29899 6.47181C4.20889 6.13562 3.96424 5.88235 3.66212 5.76739L3.31587 4.47474L6.11203 3.72555L6.39297 4.77406Z"
                fill="#F26526"
            />
            <path
            // eslint-disable-next-line max-len
                d="M6.39302 4.77407L4.99494 5.14865L5.27589 6.19723L6.67397 5.82265L6.95491 6.87122L5.55683 7.24595L5.83778 8.29446L4.4397 8.66906L4.09982 7.40028C4.3039 7.14948 4.38909 6.80787 4.29904 6.47182C4.20894 6.13563 3.96429 5.88236 3.66217 5.7674L3.31592 4.47474L6.11208 3.72556L6.39302 4.77407Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_fand"
                    x1="6"
                    y1="0"
                    x2="6"
                    y2="12"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
}
