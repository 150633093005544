import { Light }                        from "@babylonjs/core/Lights/light";
import { AbstractMesh }                 from "@babylonjs/core/Meshes/abstractMesh";
import { TransformNode }                from "@babylonjs/core/Meshes/transformNode";
import { Scene }                        from "@babylonjs/core/scene";
import {  BuilderState }                from "@geenee/builder/src/core/state/builder.state";
import { MoleculeModel }                from "@geenee/builder/src/magellan/model/molecule.model";
import { history }                      from "@geenee/common";
import { BabylonRenderer }              from "@geenee/geespector/renderer/babylonjs.renderer";
import { SceneAssetCacheService }       from "@geenee/geespector/src/lib/SceneAssetCacheService";
import { bypassHistory }                from "@geenee/shared/src/commander/decorators";
import { AbstractCommand, CommandType } from '@geenee/shared/src/commander/types';
import { AtomModel }                    from "@geenee/shared/src/magellan/atom/model/atom.model";

@bypassHistory
export class SaveBabylonSceneCommand extends AbstractCommand<CommandType> {
    entity!: AbstractMesh | TransformNode | Light;
    unblock: any;
    constructor(public receiver: BuilderState, protected activeMolecule?: MoleculeModel, private hasExit = true) {
        super();
    }

    restrictWindowClose = () => {
        function confirmExit() {
            return "You have attempted to leave this page. Are you sure?";
        }
        window.onbeforeunload = confirmExit;
    };

    removeWindowCloseRestrictions = () => {
        window.onbeforeunload = null;
    };

    execute = async () => {
        const { activeSection } = this.receiver.$appState;
        const { sceneManager, activeMolecule } = activeSection;
        const renderer =  sceneManager.sceneRenderer as any as BabylonRenderer;
        try {
            activeMolecule.setLoading(true);
            this.restrictWindowClose();
            try {
                // @ts-ignore
                this.unblock = history.block((tx) => {
                    if (window.onbeforeunload) {
                        this.receiver.toast = {
                            severity: 'error',
                            detail:   `You can not leave this page until the scene is saved.`,
                            summary:  'Wait few seconds.'
                        };
                        return false;
                    }
                    this.unblock();
                    tx.retry();
                });

                const scene = sceneManager.sceneRenderer.scene || {} as Scene;
                const sceneParams = {
                    environmentIntensity: scene?.environmentIntensity,
                    fogMode:              scene?.fogMode,
                    fogColor:             scene?.fogColor,
                    fogDensity:           scene?.fogDensity,
                    fogStart:             scene?.fogStart,
                    fogEnd:               scene?.fogEnd,
                    metadata:             scene?.metadata
                };

                if (!activeMolecule.options.molecule_scene_params
                  || JSON.stringify(activeMolecule.options.molecule_scene_params || {}) !== JSON.stringify(sceneParams)) {
                    activeMolecule.updateOptions({ molecule_scene_params: sceneParams });
                }
                const glb = await renderer.getSceneGlbInWorker();
                const modelAtom = this.activeMolecule?.getAtomByType('scene-actor');
                if (!modelAtom) {
                    await (this.activeMolecule as MoleculeModel).addAtomWithAttachment(glb, { type: 'scene-actor' }, false);
                } else {
                    SceneAssetCacheService.remove(modelAtom.id);
                    const newAtom = await (this.activeMolecule as MoleculeModel).replaceAtomWithFile(
                        glb,
                        modelAtom.id,
                        { type: 'scene-actor' },
                        false
                    );
                    if (newAtom) {
                        SceneAssetCacheService.add((newAtom as AtomModel).id || '', glb);
                    }
                }
                if (this.hasExit) {
                    sceneManager.sceneRenderer.clearNodes();
                    await sceneManager.sceneRenderer.importModelFile(URL.createObjectURL(glb), 'scene.glb', undefined, false);
                    sceneManager.sceneRenderer.clearListeners();
                } else {
                    this.receiver.toast = {
                        severity: 'success',
                        detail:   `Scene successfully saved.`,
                        summary:  'Success Saving.'
                    };
                }
                activeMolecule.setLoading(false);
                this.removeWindowCloseRestrictions();
            } catch (e) {
                console.error(e);
                activeMolecule.setLoading(false);
                this.removeWindowCloseRestrictions();
            }
        } catch (e) {
            console.error(e);
            activeMolecule.setLoading(false);
            this.removeWindowCloseRestrictions();
        }
    };

    revert = () => {};
}
