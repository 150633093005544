/**/
// eslint-disable-next-line @typescript-eslint/no-var-requires
import Color from 'color';

const getTextColor = (hex, inputColors, blackTextColor) => {
    const color = Color(hex);
    const dark = Color(inputColors.dark);
    const hsl   = color.hsl().array();
    const hue   = hsl[ 0 ];

    let darkTextColor = { key: 'dark', shade: 0 };

    if (blackTextColor) {
        darkTextColor = { key: 'black', shade: 0 };
    } else if (dark.luminosity() > 0.2) {
        darkTextColor = { key: 'black', shade: 0 };
    }

    if (((hue >= 40) && (hue < 75))) {
        if (color.luminosity() > 0.5) {
            return darkTextColor;
        }

        return { key: 'white', shade: 0 };
    }
    if (color.luminosity() > 0.45) {
        return darkTextColor;
    }

    return { key: 'white', shade: 0 };
};

const getContrastColor = (key, hex, inputColors) => {
    const color = Color(hex);

    const contrastConst = (key === 'light') ? 1.5
        : (key === 'white') ? 1 : 3;

    const colorContrastKey1  = Math.round(color.contrast(Color(inputColors.key1)) * 100) / 100;
    const colorContrastKey2  = Math.round(color.contrast(Color(inputColors.key2)) * 100) / 100;
    const colorContrastLight = Math.round(color.contrast(Color(inputColors.light)) * 100) / 100;
    const colorContrastDark  = Math.round(color.contrast(Color(inputColors.dark)) * 100) / 100;
    let colorContrastKey;
    let colorContrastKeyColor;
    let colorContrastShade;
    let colorContrastShadeColor;

    if (colorContrastKey1 > contrastConst) {
        colorContrastKey      = colorContrastKey1;
        colorContrastKeyColor = 'key1';
    } else if (colorContrastKey2 > contrastConst) {
        colorContrastKey      = colorContrastKey2;
        colorContrastKeyColor = 'key2';
    }
    if (colorContrastLight > colorContrastDark) {
        colorContrastShade      = colorContrastLight;
        colorContrastShadeColor = 'light';
    } else {
        colorContrastShade      = colorContrastDark;
        colorContrastShadeColor = 'dark';
    }

    if (colorContrastKey > contrastConst) {
        return { key: colorContrastKeyColor, shade: 0 };
    }
    if (colorContrastShade > contrastConst) {
        return { key: colorContrastShadeColor, shade: 0 };
    }
    return { key: colorContrastShadeColor, shade: 0 };
};

const getHarmonicColor = (key, hex) => {
    const color      = Color(hex);
    const light      = color.luminosity();
    const lightConst = 0.1;

    if (light === 1) {
        return { key, shade: -5 };
    }
    if (light > lightConst) {
        return { key, shade: Math.round(-10 / light) };
    }
    if (light <= lightConst) {
        return { key, shade: 50 };
    }
    if (light < 0.02) {
        return { key, shade: 120 + 100 * light };
    }
    return { key, shade: 15 };
};

const getTags = (key, hex) => {
    const color = Color(hex);
    const hsl   = color.hsl().array();
    const hue   = hsl[ 0 ];
    let hueKey;

    if ((key === 'key1') || (key === 'key2')) {
        if ((hsl[ 1 ] > 20) && (hsl[ 2 ] > 10) && (hsl[ 2 ] < 95)) {
            // red
            if (((hue >= 0) && (hue < 30)) || ((hue >= 330) && (hue <= 360))) {
                if (hsl[ 2 ] < 80) {
                    hueKey = 'red';
                } else {
                    hueKey = 'pink';
                }
            }

            // orange
            if (((hue >= 30) && (hue < 45))) {
                hueKey = 'orange';
            }

            // yellow
            if (((hue >= 45) && (hue < 75))) {
                hueKey = 'yellow';
            }

            // green
            if (((hue >= 75) && (hue < 165))) {
                hueKey = 'green';
            }

            // cyan
            if (((hue >= 165) && (hue < 195))) {
                hueKey = 'cyan';
            }

            // blue
            if (((hue >= 195) && (hue < 240))) {
                hueKey = 'blue';
            }

            // violet
            if (((hue >= 240) && (hue < 300))) {
                hueKey = 'violet';
            }

            // pink
            if (((hue >= 300) && (hue < 330))) {
                hueKey = 'pink';
            }

            return hueKey;
        }
    }
};

const getColorSchema = (inputColors) => {
    const colorSchema = {
        white:      '',
        white_text: {
            key:   '',
            shade: ''
        },
        white_harmonic: {
            key:   '',
            shade: ''
        },
        white_contrast: {
            key:   '',
            shade: ''
        },

        light:      '',
        light_text: {
            key:   '',
            shade: ''
        },
        light_harmonic: {
            key:   '',
            shade: ''
        },
        light_contrast: {
            key:   '',
            shade: ''
        },

        key1:      '',
        key1_text: {
            key:   '',
            shade: ''
        },
        key1_harmonic: {
            key:   '',
            shade: ''
        },
        key1_contrast: {
            key:   '',
            shade: ''
        },

        key2:      '',
        key2_text: {
            key:   '',
            shade: ''
        },
        key2_harmonic: {
            key:   '',
            shade: ''
        },
        key2_contrast: {
            key:   '',
            shade: ''
        },

        key3:      '',
        key3_text: {
            key:   '',
            shade: ''
        },
        key3_harmonic: {
            key:   '',
            shade: ''
        },
        key3_contrast: {
            key:   '',
            shade: ''
        },

        key4:      '',
        key4_text: {
            key:   '',
            shade: ''
        },
        key4_harmonic: {
            key:   '',
            shade: ''
        },
        key4_contrast: {
            key:   '',
            shade: ''
        },

        dark:      '',
        dark_text: {
            key:   '',
            shade: ''
        },
        dark_harmonic: {
            key:   '',
            shade: ''
        },
        dark_contrast: {
            key:   '',
            shade: ''
        },

        black:      '',
        black_text: {
            key:   '',
            shade: ''
        },
        black_harmonic: {
            key:   '',
            shade: ''
        },
        black_contrast: {
            key:   '',
            shade: ''
        },
        blur:          '',
        blur_contrast: {
            key:   '',
            shade: ''
        },
        blur_harmonic: {
            key:   '',
            shade: ''
        }
        // tags: [ '' ]
        // tags: [ '' ]
    };
    // colorScheme.tags = [];
    Object.keys(inputColors).forEach((key) => {
        const hex = inputColors[ key ];

        colorSchema[ key ]                 = hex;
        colorSchema[ `${ key }_text` ]     = getTextColor(hex, inputColors);
        colorSchema[ `${ key }_contrast` ] = getContrastColor(key, hex, inputColors);
        colorSchema[ `${ key }_harmonic` ] = getHarmonicColor(key, hex);
        // colorScheme.tags.push(getTags(key, hex));
    });
    return colorSchema;
};

export {
    getColorSchema,
    getContrastColor,
    getHarmonicColor,
    getTags,
    getTextColor
};
