import React                          from 'react';
import { Description, Icon, Wrapper } from '@geenee/ui';

export function SuccessWindow() {
    return (
        <Wrapper align="center">
            <Icon name="contactProfile" size={ 48 } color="gradient-warm-3" margin="md" />
            <Description weight='bold' margin="xs" lineHeight="lg" size="lg">
                Your request has been sent!
            </Description>
            <Description color="dark-5" margin="xs">
                We have received your request.
                We will reach you after a propper investigation of your question. Our manager will contact you soon
            </Description>
        </Wrapper>
    );
}
