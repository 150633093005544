import React                                 from "react";
import { useBuilderInject }                  from "@geenee/builder/src/core/hook/use-builder-inject";
import { NOTION_SUPPORT_URL, TOOLTIP_DELAY } from "@geenee/builder/src/lib/constants";
import { MoleculeModel }                     from "@geenee/builder/src/magellan/model/molecule.model";
import { openInSmallWindow }                 from "@geenee/builder/src/utils/openInSmallWindow";
import { CallbackFunctionVariadic }          from "@geenee/geeclient-kit/src/lib/type/type";
import { SaveBabylonSceneCommand }           from "@geenee/geespector/src/commands/SaveBabylonScene";
import {  useInject }                        from '@geenee/shared';
import { Button, Divider, Tooltip, Wrapper } from '@geenee/ui';
import { observer }                          from "mobx-react";

export const SceneHeaderButtons = observer(({ onExit, isBabylon = false }: { onExit: CallbackFunctionVariadic, isBabylon?: boolean}) => {
    const { AppState } = useInject();
    const { BuilderState } = useBuilderInject();
    const { activeSection : sectionModel, activeMolecule } = AppState;
    const { sceneManager } = sectionModel || { sceneManager: undefined };
    const renderer = sceneManager?.sceneRenderer;
    return (
        <Wrapper id="scene-header-buttons" maxWidth="fit-content" row style={ { marginLeft: 'auto', zIndex: 5 } }>
            <Wrapper
                radius="xxl"
                frame="solid-opacity-white"
                shadow="md"
                blur
                maxWidth="84px"
                margin="xs"
                padding="xxs"
            >
                <Tooltip
                    delay={ TOOLTIP_DELAY }
                    position="bottom"
                    message="Help"
                    minWidth="80"
                >
                    <Button
                        icon="info"
                        iconMargin="xxs"
                        viewType="transparent"
                        onClick={ () => {
                            openInSmallWindow(NOTION_SUPPORT_URL);
                        } }
                        size="md"
                        radius="lg"
                        iconSize={ 24 }
                        weight="regular"
                        color="shade-3"
                    />
                </Tooltip>
            </Wrapper>
            { isBabylon ? (
                <Wrapper
                    radius="xxl"
                    frame="solid-opacity-white"
                    shadow="md"
                    padding="xxs"
                    blur
                    row
                    maxWidth="fit-content"
                    margin="xs"
                >
                    <Tooltip
                        position="bottom"
                        margin="xxs"
                        message="Undo"
                        minWidth="80"
                        delay={ TOOLTIP_DELAY }
                    >

                        <Button
                            style={ { padding: 8 } }
                            icon="undo"
                            iconMargin="xxs"
                            viewType="transparent"
                            size="md"
                            radius="lg"
                            disabled={ !sceneManager?.commander.hasRevert }
                            iconSize={ 24 }
                            weight="regular"
                            color="shade-3"
                            onClick={ () => sceneManager?.commander.revertCommand() }
                        />
                    </Tooltip>
                    <Tooltip
                        position="bottom"
                        message="Redo"
                        minWidth="80"
                        delay={ TOOLTIP_DELAY }
                    >
                        <Button
                            style={ { padding: 8 } }
                            icon="redo"
                            iconMargin="xxs"
                            viewType="transparent"
                            size="md"
                            disabled={ !sceneManager?.commander.hasForward }
                            radius="lg"
                            iconSize={ 24 }
                            weight="regular"
                            color="shade-3"
                            onClick={ () => sceneManager?.commander.forwardCommand() }

                        />
                    </Tooltip>
                </Wrapper>
            ) : null }
            <Wrapper
                radius="xxl"
                frame="solid-opacity-white"
                shadow="md"
                padding="xxs"
                blur
                maxWidth="fit-content"
                row
            >
                { isBabylon ? (
                    <>
                        <Tooltip
                            delay={ TOOLTIP_DELAY }
                            position="bottom"
                            message="Save & Continue"
                            minWidth="120"
                            margin="xxs"
                        >
                            <Button
                                style={ { padding: 8, paddingRight: 10 } }
                                icon="save"
                                disabled={ !renderer.wasSceneChanged }
                                iconMargin="xxs"
                                viewType="transparent"
                                onClick={ () => {
                                    if (renderer.wasSceneChanged) {
                                        const saveSceneCommand = new SaveBabylonSceneCommand(BuilderState, activeMolecule as MoleculeModel, false);
                                        sceneManager?.commander.executeCommand(saveSceneCommand);
                                        renderer.wasSceneChanged = false;
                                    }
                                } }
                                size="md"
                                radius="lg"
                                iconSize={ 24 }
                                weight="regular"
                                color="shade-3"
                            />
                        </Tooltip>
                        <Divider
                            style={ { zIndex: 1, width: 2 } }
                            margin="xxs"
                            color="shade-6"
                        />
                    </>
                ) : null }
                <Button
                    icon="exit"
                    iconMargin="xxs"
                    viewType="transparent"
                    size="qmd"
                    disabled={ activeMolecule?.loading }
                    radius="lg"
                    align="left"
                    iconSize={ 24 }
                    weight="regular"
                    color="shade-3"
                    onClick={ () => onExit() }
                >
                    Save & Exit
                </Button>
            </Wrapper>
        </Wrapper>
    );
});
