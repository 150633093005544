//ts-nocheck
/* eslint-disable implicit-arrow-linebreak */
import { NoStrictEntityMods, withNaming } from '@bem-react/classname';

const classNameInitializer = withNaming({
    n: 'one-ui-',
    e: '__',
    m: '--',
    v: '-'
});

export const cn = (blockName: string) => {
    const classFormatter = classNameInitializer(blockName);
    return (
        elemNameOrBlockMods?: NoStrictEntityMods | string | null,
        elemModsOrBlockMix?: NoStrictEntityMods | null,
        externalClassBefore = '',
        externalClassAfter = ''
    ) =>
        (externalClassBefore ? `${ externalClassBefore } ` : '')
      // @ts-ignore
        + classFormatter(elemNameOrBlockMods, elemModsOrBlockMix || undefined)
        + (externalClassAfter ? ` ${ externalClassAfter }` : '');
};
