import React, { useState } from 'react';
import { cn, Wrapper }     from "@geenee/ui";
import { SuccessWindow }   from './SuccessWindow';
import { WarningWindow }   from './WarningWindow';
import './styles.scss';

const className = cn('delete-account');

export function DeleteAccount({ onClose }:{ onClose: () => void }) {
    const [ isRemoved, setIsRemoved ] = useState(false);
    return (
        <Wrapper className={ className() }>
            { !isRemoved
                ? <WarningWindow onClose={ onClose } onRemove={ () => setIsRemoved(true) } />
                : <SuccessWindow onClose={ onClose } /> }
        </Wrapper>
    );
}
