/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable react/function-component-definition */
import React, { FC }              from 'react';
import { getPropsFromOwner }      from '@geenee/geeclient-kit/src/lib/component/atom/plain-text/util';
import { withMoleculeContext }    from '@geenee/geeclient-kit/src/lib/hoc/withMoleculeContext';
import { cnKitAtom }              from '@geenee/geeclient-kit/src/lib/util/bem';
import { compose }                from '@geenee/geeclient-kit/src/lib/util/compose';
import { withProps }              from '@geenee/geeclient-kit/src/lib/util/withProps';
import { observer }               from 'mobx-react';
import { FreeFont }               from '../../../quark/util/free-font/free-font';
import { PlainTextAtomOptType }   from '../../../type/_atom-option-type';
import { SiteMoleculeSchemaType } from '../../../type/section-molecule';
import { initProps }              from "./util";
import './plain-text.atom.component.scss';

const className = cnKitAtom('atom-plain-text');

export type PlainTextPropsType = {[key: string]: any} & PlainTextAtomOptType;
const PlainTextView: FC<PlainTextPropsType> = observer((props) => {
    if (!props.plain_text_switch) {
        return null;
    }
    return (
        <FreeFont
            font_type={ props.plain_text_font_type }
            font_size={ props.plain_text_font_size }
            font_weight={ props.plain_text_font_weight }
            font_line_height={ props.plain_text_font_line_height }
            font_uppercase={ props.plain_text_font_uppercase }
        >
            { (fontStyles) => (
                <div
                    id={ props.id }
                    className={ className('', { cursor: props.plain_text_cursor }) }
                    data-atom="plain-text"
                    data-font-kit-type={ props.plain_text_font_type }
                    data-color-text={ props.plain_text_color }
                    data-color-text-alpha={ props.plain_text_color_alpha }
                    data-color-text-type={ props.plain_text_color_type }
                    data-color-text-variation={
                        props.plain_text_color_type === 'auto'
                            ? 'text'
                            : props.plain_text_color_variation
                    }
                    style={
            {
                ...fontStyles,
                '--atom-plain-text__column_count': props.plain_text_column_count,
                '--color-text':
                props.plain_text_color_type === 'custom'
                    ? props.plain_text_color_custom
                    : undefined,
                '--font-kit-text-transform': props.plain_text_font_uppercase
                    ? 'uppercase'
                    : undefined,
                '--font-kit-size':          props.plain_text_font_size,
                '--atom-plain-text__align': props.plain_text_align
            } as React.CSSProperties
                    }
                >
                    { props.plain_text }
                </div>
            ) }
        </FreeFont>
    );
});

PlainTextView.defaultProps  = { ...initProps };

export { PlainTextView };

const enhance = compose(
    withMoleculeContext,
    withProps(
        ({
            moleculeContext,
            ...extendProps
        }: {
      moleculeContext: SiteMoleculeSchemaType<PlainTextAtomOptType>;
    }) => getPropsFromOwner(moleculeContext.options, extendProps)
    )
);

export const PlainTextAtomComponent = enhance(
    PlainTextView
);
