import React, { useEffect, useState } from 'react';
import {
    Input,
    InputTableItem
} from '@geenee/ui';
import {
    FlowCodeParams,
    generateTextSettings
} from '@geenee/builder/src/components/ProjectsLinkDropdown/components/FlowCode/components/FlowCodeHelper';

interface Props {
    initTextData?: {top: string, bottom: string}
    codeState: FlowCodeParams,
    onUpdateSettings: (val: FlowCodeParams, textInfo?: {top: string, bottom: string}) => void
    color: string
}

export function QrTextInputs({ codeState, onUpdateSettings, color, initTextData }: Props) {
    const [ localValue, setLocalValue ] = useState(initTextData || { top: '', bottom: '' });

    useEffect(() => {
        if (localValue.top || localValue.bottom) {
            const newOpts = { ...codeState.opts };
            newOpts.containerRimBorderColor = color;
            newOpts.containerRimText = Object.keys(localValue).map((item) => generateTextSettings(localValue[ item ], color)[ item ]);
            onUpdateSettings(newOpts, localValue);
        }
    }, [ color ]);

    const onChange = (field: string, value: string) => {
        const currentValue = { ...localValue, [ field ]: value };
        const newOpts = { ...codeState.opts };
        if (!currentValue.top && !currentValue.bottom) {
            newOpts.containerRimText = [];
        } else {
            newOpts.containerRimText  = Object.keys(currentValue).map((item) => generateTextSettings(currentValue[ item ], color)[ item ]);
        }
        setLocalValue(currentValue);
        onUpdateSettings(newOpts, currentValue);
    };

    return (
        <>
            <InputTableItem label="Top text:" viewType='secondary'>
                <Input
                    size="md"
                    fullWidth
                    onChange={ (e) => onChange('top', e.target.value) }
                    value={ localValue.top }

                />
            </InputTableItem>
            <InputTableItem label="Bottom text:" viewType='secondary'>
                <Input
                    size="md"
                    fullWidth
                    onChange={ (e) => onChange('bottom', e.target.value) }
                    value={ localValue.bottom }
                />
            </InputTableItem>
        </>
    );
}
