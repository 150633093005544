import { CubeTextureLoader, LinearFilter, Sphere } from 'three';
import envMapImgWhite                              from '../../assets/envWhite.jpg';

/*
 *Compute bounding box to avoid unexpected camera clipping
 *@param scene - THREE.Scene scene type
 */
export const computeClippingBox = (scene) => {
    scene.traverse((child) => {
        if (child.isSkinnedMesh) {
            child.geometry.computeBoundingSphere();
            const sphere = new Sphere();
            sphere.copy(child.geometry.boundingSphere);
            sphere.applyMatrix4(child.skeleton.bones[ 0 ].matrix);
            // eslint-disable-next-line no-param-reassign
            child.geometry.boundingSphere = sphere;
        }
    });
};

/*
 *Disable object's frustumCulled to avoid unexpected camera clipping
 *@param scene - THREE.Scene scene type
 */
export const disableFrustum = (scene) => {
    scene.traverse((child) => {
        if (child.isMesh) {
            // eslint-disable-next-line no-param-reassign
            child.frustumCulled = false;
        }
    });
};

/*
 *Set environment Map for every object
 *@param scene - THREE.Scene or react-three-fiber scene type
 *@param isFiber - boolean to detect scene type
 */
export const setEnvMap = (scene, isFiber) => {
    let resolve;
    let reject;
    const promise = new Promise((res, rej) => {
        resolve = res;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        reject = rej;
    });
    const loader = new CubeTextureLoader();
    const envMapTexture = loader.load(
        [
            envMapImgWhite,
            envMapImgWhite,
            envMapImgWhite,
            envMapImgWhite,
            envMapImgWhite,
            envMapImgWhite
        ],
        () => {
            if (isFiber) {
                // eslint-disable-next-line no-restricted-syntax
                for (const mat in scene.materials) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (scene.materials.hasOwnProperty(mat)) {
                        // eslint-disable-next-line no-param-reassign
                        scene.materials[ mat ].envMap = envMapTexture;
                        // eslint-disable-next-line no-param-reassign
                        scene.materials[ mat ].envMap.minFilter = LinearFilter;
                    }
                }
            } else {
                // use THREE.Scene traverse to access the materials
                scene.traverse((child) => {
                    if (child.isMesh) {
                        // eslint-disable-next-line no-param-reassign
                        child.material.envMap = envMapTexture;
                        // eslint-disable-next-line no-param-reassign
                        child.material.envMap.minFilter = LinearFilter;
                    }
                });
            }
            resolve();
        }
    );

    // use react-three-fiber structure to access all materials
    return promise;
};

export const setCastShadow = (object, isShadowsOn = true) => {
    if (object) {
        object.traverse((child) => {
            if (child.isMesh) {
                // eslint-disable-next-line no-param-reassign
                child.castShadow = isShadowsOn;
                // child.material.envMapIntensity  = 2;
                // child.receiveShadow = true
            }
            if (child.type === 'SkinnedMesh') {
                // eslint-disable-next-line no-param-reassign
                child.material.skinning = isShadowsOn;
            }
        });
    }
};

export const setFrustumCulled = (object, value) => {
    if (object) {
        object.traverse((child) => {
            child.frustumCulled = value;
        });
    }
};

export const setRecieveShadow = (object, isRecieveOn = true) => {
    if (object) {
        object.traverse((child) => {
            if (child.isMesh) {
                // eslint-disable-next-line no-param-reassign
                child.receiveShadow = isRecieveOn;
            }
        });
    }
};

export const removeAndDisposeObject = (obj, activeSceneModel) => {
    if (obj) {
        if (obj.material) {
            if (obj.material.map) {
                obj.material.map.dispose();
            }
            if (obj.material.lightMap) {
                obj.material.lightMap.dispose();
            }
            if (obj.material.aoMap) {
                obj.material.aoMap.dispose();
            }
            if (obj.material.emissiveMap) {
                obj.material.emissiveMap.dispose();
            }
            if (obj.material.bumpMap) {
                obj.material.bumpMap.dispose();
            }
            if (obj.material.normalMap) {
                obj.material.normalMap.dispose();
            }
            if (obj.material.displacementMap) {
                obj.material.displacementMap.dispose();
            }
            if (obj.material.roughnessMap) {
                obj.material.roughnessMap.dispose();
            }
            if (obj.material.metalnessMap) {
                obj.material.metalnessMap.dispose();
            }
            if (obj.material.alphaMap) {
                obj.material.alphaMap.dispose();
            }
            if (obj.material.envMaps) {
                obj.material.envMaps.dispose();
            }
            if (obj.material.envMap) {
                obj.material.envMap.dispose();
            }
            if (obj.material.specularMap) {
                obj.material.specularMap.dispose();
            }
            if (obj.material.gradientMap) {
                obj.material.gradientMap.dispose();
            }
            obj.material.dispose();
        }
        if (obj.geometry) {
            obj.geometry.dispose();
        }
        if (obj.texture) {
            obj.texture.dispose();
        }
        if (obj.bufferGeometry) {
            obj.bufferGeometry.dispose();
        }

        activeSceneModel.renderer.renderLists.dispose(); // ?????
        activeSceneModel.scene.remove(obj);
    }
};

export const preprocessObjectMaterialSettings = (object) => {
    if (object) {
        object.traverse((child) => {
            // Skinned Mesh fix
            if (child.type === 'SkinnedMesh') {
                // eslint-disable-next-line no-param-reassign
                child.material.skinning = true;
            }
            // morph animation fix
            if (child.isMesh && (child.morphTargetDictionary || child.morphTargetInfluences)) {
                // eslint-disable-next-line no-param-reassign
                child.material.morphTargets = true;
            }
            // if (child.material) {
            //     if (child.material.map) {
            //         // console.log('map', child);
            //         child.material.map.minFilter = LinearFilter;
            //     }
            //     if (child.material.lightMap) {
            //         // console.log('lightMap', child);
            //         child.material.lightMap.minFilter = LinearFilter;
            //     }
            //     if (child.material.aoMap) {
            //         // console.log('aoMap', child);
            //         child.material.aoMap.minFilter = LinearFilter;
            //     }
            //     if (child.material.emissiveMap) {
            //         // console.log('emissiveMap', child);
            //         child.material.emissiveMap.minFilter = LinearFilter;
            //     }
            //     if (child.material.bumpMap) {
            //         // console.log('bumpMap', child);
            //         child.material.bumpMap.minFilter = LinearFilter;
            //     }
            //     if (child.material.normalMap) {
            //         // console.log('normalMap', child);
            //         child.material.normalMap.minFilter = LinearFilter;
            //     }
            //     if (child.material.displacementMap) {
            //         // console.log('displacementMap', child);
            //         child.material.displacementMap.minFilter = LinearFilter;
            //     }
            //     if (child.material.roughnessMap) {
            //         // console.log('roughnessMap', child);
            //         child.material.roughnessMap.minFilter = LinearFilter;
            //     }
            //     if (child.material.metalnessMap) {
            //         // console.log('metalnessMap', child);
            //         child.material.metalnessMap.minFilter = LinearFilter;
            //     }
            //     if (child.material.alphaMap) {
            //         // console.log('alphaMap', child);
            //         child.material.alphaMap.minFilter = LinearFilter;
            //     }
            //     if (child.material.envMaps) {
            //         // console.log('envMaps', child);
            //         child.material.envMaps.minFilter = LinearFilter;
            //     }
            //     if (child.material.envMap) {
            //         // console.log('envMap', child);
            //         child.material.envMap.minFilter = LinearFilter;
            //     }
            //     if (child.material.specularMap) {
            //         // console.log('specularMap', child);
            //         child.material.specularMap.minFilter = LinearFilter;
            //     }
            //     if (child.material.gradientMap) {
            //         // console.log('gradientMap', child);
            //         child.material.gradientMap.minFilter = LinearFilter;
            //     }
            //     child.material.needsUpdate = true;
            // }
        });
    }
};

export const preprocessLoadedObject = async (object) => {
    // await setEnvMap(object);
    preprocessObjectMaterialSettings(object);
    return { ...object };
};

export const setEnvMapForObjectWhite = async () => {
    const loader = new CubeTextureLoader();
    return loader.load([
        envMapImgWhite,
        envMapImgWhite,
        envMapImgWhite,
        envMapImgWhite,
        envMapImgWhite,
        envMapImgWhite
    ]);
};

export const setEnvMapForObjectBridge = async () => {
    const loader = new CubeTextureLoader();
    return loader.load([
        envMapImgWhite,
        envMapImgWhite,
        envMapImgWhite,
        envMapImgWhite,
        envMapImgWhite,
        envMapImgWhite
    ]);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const setEnvMapBridge = (scene, isFiber) => {
    const loader = new CubeTextureLoader();
    return loader.load([
        envMapImgWhite,
        envMapImgWhite,
        envMapImgWhite,
        envMapImgWhite,
        envMapImgWhite,
        envMapImgWhite
    ]);

    /* // use react-three-fiber structure to access all materials
    if (isFiber) {
        for (const mat in scene.materials) {
            if (scene.materials.hasOwnProperty(mat)) {
                scene.materials[ mat ].envMap = envMapTexture;
                scene.materials[ mat ].envMap.minFilter = LinearFilter;
            }
        }
    } else {
        // use THREE.Scene traverse to access the materials
        scene.traverse((child) => {
            if (child.isMesh) {
                child.material.envMap = envMapTexture;
                child.material.envMap.minFilter = LinearFilter;
            }
        });
    } */
};
