//@ts-nocheck
import React, { FC, memo, useContext, useMemo } from 'react';
import { Media }                                from '../component/media-provider/media-provider.component';


export const withMedia = <T extends Record<any, any>>(Component: React.ComponentType<T>): FC<T> => memo<T>((props: T) => {
    const media = useContext(Media);

    const newProps = useMemo(() => {
        if (props.xsm || props.sm || props.md || props.lg) {
            let sizeProps = {};
            if (props.lg) {
                if ([ 'xsm', 'sm', 'md', 'lg' ].indexOf(media) !== -1) {
                    sizeProps = { ...props.lg };
                }
            }
            if (props.md) {
                if ([ 'xsm', 'sm', 'md' ].indexOf(media) !== -1) {
                    sizeProps = { ...props.md };
                }
            }
            if (props.sm) {
                if ([ 'xsm', 'sm' ].indexOf(media) !== -1) {
                    sizeProps = { ...props.sm };
                }
            }
            if (props.xsm && media === 'xsm') {
                sizeProps = { ...props.xsm };
            }
            return {
                ...props,
                ...sizeProps
            };
        }
        return props;
    }, [ props, media ]);

    return useMemo(() => <Component { ...newProps as T } />, [ newProps ]);
});
