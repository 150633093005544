import React, { useState }                          from 'react';
import { useHistory }                               from "@geenee/common";
import { Description, Divider, Title, Wrapper }     from '@geenee/ui';
import NextArrow                                    from '@geenee/builder/src/asset/icons/NextArrow';
import RaisedButton                                 from '@geenee/builder/src/components/common/Button/WithIcon';
import { ContentWrapper }                           from '../PostPublishingNFT/styles';
import { CopyButton, GradientWrapper, TextWrapper } from './styles';

type Props = {
    // eslint-disable-next-line react/no-unused-prop-types
  close: () => void;
    // eslint-disable-next-line no-empty-pattern
  setModal: ({ }) => void;
  id: string;
  link: string;
};

export function CongratsNFT(props: Props) {
    const [ copyIdSuccess, setCopyIdSuccess ] = useState(false);
    const [ copyLinkSuccess, setCopyLinkSuccess ] = useState(false);
    const history = useHistory();

    const copyToClipboard = (value: string, key: string) => {
        navigator.clipboard.writeText(value);
        if (key === 'id') {
            setCopyIdSuccess(true);
            setTimeout(() => {
                setCopyIdSuccess(false);
            }, 3000);
        } else {
            setCopyLinkSuccess(true);
            setTimeout(() => {
                setCopyLinkSuccess(false);
            }, 3000);
        }
    };

    const onClickNext = () => {
        props.setModal({ hideModal: true });
        history.push('/nft-dashboard');
    };

    return (
        <ContentWrapper>
            <Wrapper align='center'>
                <Title size='xxl' align='center' margin='lg'>
                    Congrats!
                </Title>
                <Wrapper maxWidth='490px' align='center'>
                    <Description size='sm' align='center' margin='lg'>
                        You have a newly minted NFT! Copy your Mintable ID & URL or go to your
                        NFT Dashboard to get the information, your wallet & NFT stats.
                    </Description>
                </Wrapper>
                <GradientWrapper maxWidth='362px' color='white'>
                    <Wrapper row valign='center' margin='sm' style={ { justifyContent: 'space-between' } }>
                        <TextWrapper>{ props.id }</TextWrapper>
                        <Wrapper row align='right' maxWidth='110'>
                            <Divider transparent style={ { width: 16 } } />
                            <CopyButton
                                $padding="0 23px"
                                $justify="center"
                                $width="92px"
                                $height="40px"
                                onClick={ () => copyToClipboard(props.id, 'id') }
                                label={ copyIdSuccess ? 'COPIED' : 'COPY' }
                            />
                        </Wrapper>
                    </Wrapper>
                    <Wrapper row valign='center' style={ { justifyContent: 'space-between' } }>
                        <TextWrapper>{ props.link }</TextWrapper>
                        <Wrapper row align='right' maxWidth='110'>
                            <Divider transparent style={ { width: 16 } } />
                            <CopyButton
                                $padding="0 23px"
                                $justify="center"
                                $width="92px"
                                $height="40px"
                                onClick={ () => copyToClipboard(props.link, 'link') }
                                label={ copyLinkSuccess ? 'COPIED' : 'COPY' }
                            />
                        </Wrapper>
                    </Wrapper>
                </GradientWrapper>
                <Divider transparent style={ { height: 37 } } />
                <Wrapper row fullWidth align='center'>
                    <RaisedButton
                        $gradient
                        $padding="0 23px"
                        $justify="space-between"
                        $width="159px"
                        $height="61px"
                        onClick={ () => onClickNext() }
                        text="NFT DASH"
                        iconElement={ (
                            <NextArrow width="30" height="30" />
                        ) }
                    />
                </Wrapper>
            </Wrapper>
        </ContentWrapper>
    );
}
