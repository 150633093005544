import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {  RgbColor, RgbColorPicker }                            from "react-colorful";
import { CallbackFunctionVariadic }                             from "@geenee/geeclient-kit/src/lib/type/type";
import { observer, useLocalObservable }                         from "mobx-react";
import { cn }                                                   from "../../util/bem";
import { FormItem }                                             from "../form-item/form-item.component";
import { FormError }                                            from "../form-validation-error/form-error";
import { Input }                                                from "../input/input.component";
import { InputMatrixCell }                                      from "../input-matrix/input-matrix-cell.component";
import { InputMatrixColumnLabels }                              from "../input-matrix/input-matrix-column-labels.component";
import { InputMatrixRow }                                       from "../input-matrix/input-matrix-row.component";
import { InputTable }                                           from "../input-table/input-table.component";
import { InputTableItem }                                       from "../input-table/input-table-item.component";
import { Wrapper }                                              from "../wrapper/wrapper.component";
import './rgb-color-picker.style.scss';

export type RGBColorPickerPropsType = {
  rgbColor?: { r: number, g: number, b: number };
  alpha?: boolean;
  onChange: CallbackFunctionVariadic
}

const className = cn('rgb-color-picker');
const HEX_REGEX = /[0-9A-Fa-f]{6}/g;
const RATIO = 0.1;

export const RGBColorPicker: FC<RGBColorPickerPropsType> = observer((props: RGBColorPickerPropsType) => {
    const [ timeout ] = useState({ value: 0 });

    const localHexColor = useLocalObservable(() => ({
        hexColor: '000000',
        styleProps() {
            return { '--colorful-color': `#${ this.hexColor }` } as React.CSSProperties;
        },
        error: '',
        setHexColor(hex: string) {
            if (HEX_REGEX.test(hex)) {
                const rgb = {
                    r: parseInt(hex.substring(0, 2) || '0', 16),
                    g: parseInt(hex.substring(2, 4) || '0', 16),
                    b: parseInt(hex.substring(4, 6) || '0', 16)
                };
                this.hexColor = hex;

                props.onChange(rgb, hex);
                this.error = '';
            } else {
                this.hexColor = hex;
                this.error = 'Invalid HEX value';
            }
        },
        setHexColorFromRGB(rgb: { r: number, g: number, b: number }) {
            const r = rgb.r.toString(16).padStart(2, '0');
            const g = rgb.g.toString(16).padStart(2, '0');
            const b = rgb.b.toString(16).padStart(2, '0');
            this.hexColor = `${ r }${ g }${ b }`;
        }
    }));

    const columnLabels = useMemo(() => (
        <InputMatrixColumnLabels
            labels={ [ 'R', 'G', 'B' ] }
            margin="xs"
            size="sm"
            viewType="secondary"
            ratio={ RATIO }
        />
    ), []);

    useEffect(() => {
        if (props.rgbColor) {
            localHexColor.setHexColorFromRGB(props.rgbColor);
        }
    }, []);

    const removeColorSlider = useCallback(() => {
        const elements = document.getElementsByClassName('react-colorful__hue-pointer');
        const container = elements?.length ? elements[ 0 ] : null;
        if (!container?.children[ 0 ]) {
            return;
        }
        container.removeChild(container.children[ 0 ]);
    }, []);

    useEffect(() => {
        removeColorSlider();
    }, []);

    const onChange = (color: RgbColor) => {
        localHexColor.setHexColorFromRGB(color);
        clearTimeout(timeout.value);
        timeout.value = setTimeout(() => {
            props.onChange(color, localHexColor.hexColor);
        }, 100);
        localHexColor.error = '';
    };

    if (!props.rgbColor) {
        return <></>;
    }
    return (
        <Wrapper className={ className() } style={ localHexColor.styleProps() }>
            <RgbColorPicker
                color={ props.rgbColor || { r: 255, g: 255, b: 255 } }
                onChange={ onChange }
            />
            <InputTable>
                <InputMatrixRow
                    ratio={ RATIO }
                    viewType="secondary"
                >
                    { Object.entries(props.rgbColor).map(([ key, value ], index) => (
                        <InputMatrixCell
                            margin={ index === (Object.keys(props.rgbColor!).length - 1) ? 'off' : "xs" }
                            key={ key }
                            value={ value.toString() }
                            onChange={ (e) => {
                                const newValue = { ...props.rgbColor, [ key ]: +e.target.value };
                                onChange(newValue);
                            } }
                            type="number"
                            size="sm"
                            min={ 0 }
                            max={ 255 }
                        />
                    )) }
                </InputMatrixRow>
                { columnLabels }
                <InputTableItem
                    ratio={ 0.5 }
                    label="HEX"
                    weight="medium"
                    valign="base-line"
                    viewType="secondary"
                    labelWrapperProps={ { align: 'center' } }
                >
                    <FormItem
                        margin="off"
                        autoFocusDetect
                        hasTooltipMessage={ false }
                        errorComponent={ FormError }
                        error={ !!localHexColor.error && localHexColor.error }
                    >
                        <Input
                            error={ !!localHexColor.error }
                            nativeProps={ { maxLength: 6 } }
                            size="sm"
                            value={ localHexColor.hexColor }
                            onChange={ (e) => {
                                localHexColor.setHexColor(e.target.value);
                            } }
                        />
                    </FormItem>
                </InputTableItem>
            </InputTable>
        </Wrapper>
    );
});

RGBColorPicker.defaultProps = { alpha: true };
