import React, { useState }          from 'react';
import { Button, Divider, Wrapper } from '@geenee/ui';
import { observer }                 from 'mobx-react';
import { NpmTable }                 from "@geenee/builder/src/pages/SDKAccessPage/components/NpmTable";
import { CreateToken }              from './CreateToken';
import { SDKAccessTable }           from './SDKAccessTable';

export const SDKAccessContent = observer(() => {
    const [ createTokenPage, setCreateTokenPage ] = useState(false);
    return (
        <Wrapper>
            <Divider transparent margin="md" />
            { !createTokenPage ? (
                <>
                    <Wrapper
                        maxWidth="190px"
                        margin="lg"
                    >
                        <Button
                            icon="add"
                            iconAfter
                            iconColor="white"
                            iconSize={ 12 }
                            uppercase
                            align="right"
                            size="qmd"
                            descriptionMargin="xs"
                            iconMargin="off"
                            radius="lg"
                            onClick={ () => setCreateTokenPage(true) }
                        >
                            Create new Token
                        </Button>
                    </Wrapper>
                    <SDKAccessTable />
                    <NpmTable />
                </>
            )
                : <CreateToken onModeChange={ () => setCreateTokenPage(false) } /> }

        </Wrapper>
    );
});
