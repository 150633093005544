import React                      from 'react';
import { AvatarRenderer }         from '@geenee/shared/src/magellan/renderer/babylon-renderer/avatar-renderer';
import { Wrapper }                from '@geenee/ui';
import { observer }               from 'mobx-react';
import { SceneHeaderButtons }     from "~/module/edit-asset-3d-scene/modal/SceneHeader/SceneHeaderButtons";
import { BabylonGizmosComponent } from "~/module/geeltf-inspector/component/babylon-gizmos.component";

interface Props {
  onHideModal: () => void
  hideAssetsOptionsList?: string[]
  renderer?: AvatarRenderer
}

export const SceneHeader = observer(({ onHideModal, renderer }: Props) => (
    <Wrapper
        valign="center"
        align="space-between"
        style={ { zIndex: 3, paddingBottom: '8px' } }
        row
        padding="sm"
    >
        { // @ts-ignore
            !!renderer?.scene && <BabylonGizmosComponent scene={ renderer.scene } />
        }
        <SceneHeaderButtons isBabylon onExit={ onHideModal } />
    </Wrapper>
));
