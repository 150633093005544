import { PoseResult }                 from '@geenee/bodyprocessors';
import { SceneRenderer }              from "@geenee/shared/src/magellan/renderer/r3f-renderer/r3f.renderer";
import { action, observable }         from "mobx";
import Stats                          from "stats.js";
import * as three                     from "three";
import { Group, Matrix4, Quaternion } from "three";
import { ThreeRenderer }              from '../three-renderer/three.renderer';

// Renderer
export class SlamRenderer extends ThreeRenderer {
  @observable matrixTargetGroup: Group;
  bufferCount = 1;
  bufferIndex = 0;
  buffer = [];
  fpsStats;

  initBuffer() {
      let i = 1;
      while (i <= this.bufferCount) {
          const bufferCanvas = document.createElement('canvas');
          const bufferCanvasContext = bufferCanvas.getContext('2d', {
              desynchronized:        true,
              preserveDrawingBuffer: true,
              alpha:                 true
          }) as CanvasRenderingContext2D;

          this.buffer.push({ canvas: bufferCanvas, context: bufferCanvasContext });

          i += 1;
      }
  }

  constructor(
      canvas: HTMLCanvasElement,
      context: CanvasRenderingContext2D,
      renderer: three.WebGLRenderer,
      scene: three.Scene,
      camera: three.Camera,
      activeSceneModel: SceneRenderer
  ) {
      super(canvas, context, renderer, scene, camera, activeSceneModel);
      this.initBuffer();

      const urlParams = new URLSearchParams(window.location.search);
      const fpsParam = urlParams.get('fps');
      if (fpsParam) {
          this.fpsStats = new Stats();
          this.fpsStats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
          document.body.appendChild(this.fpsStats.dom);
      }
  }

  @action
  updateRototranslationSlam(roto: number[]) {
      const m3jsRoto = new Matrix4();
      m3jsRoto.set(
          roto[ 0 ],
          roto[ 1 ],
          roto[ 2 ],
          roto[ 3 ],
          roto[ 4 ],
          roto[ 5 ],
          roto[ 6 ],
          roto[ 7 ],
          roto[ 8 ],
          roto[ 9 ],
          roto[ 10 ],
          roto[ 11 ],
          roto[ 12 ],
          roto[ 13 ],
          roto[ 14 ],
          roto[ 15 ]
      );

      this.updateSceneGroupFromRoto(m3jsRoto);
  }

  updateSceneGroupFromRoto(roto: Matrix4) {
      if (roto && this.matrixTargetGroup) {
          const targetGroup: Group = this.matrixTargetGroup;
          targetGroup.position.set(0, 0, 0);
          targetGroup.scale.set(1, 1, 1);
          targetGroup.updateMatrix();

          targetGroup.position.setFromMatrixPosition(roto);
          targetGroup.scale.setFromMatrixScale(roto);
          targetGroup.quaternion.slerp(new Quaternion().setFromRotationMatrix(roto), 0.35); // smooth rotation
          // targetGroup.position.lerp(new Vector3().setFromMatrixPosition(_matrix), 0.95); // smooth transform
          // targetGroup.scale.lerp(new Vector3().setFromMatrixScale(_matrix), 0.95); // smooth scale
          targetGroup.updateMatrix();
      }
  }

  // Update
  async update(result: PoseResult, stream: HTMLCanvasElement): Promise<void> {
      this.fpsStats?.begin();
      /*   const currResult = this.prevframe ? this.prevframe : result;
    this.prevframe = result; */
      /*    if (this.activeSceneModel.options.modelShown) {
            this.updateRototranslationSlam(result);
        }
        await super.update(result, stream); */
      /* if (this.activeSceneModel.options.modelShown) {
          this.updateRototranslationSlam(result);
      }
      await super.update(result, stream);
      return;
*/
      // BUFFER for the poseResult
      // TODO: update matrix here looks much faster then set it through the activeSceneModel
      // кольцевой буфер
      // this.activeSceneModel.updateRototranslationSlam(result)
      // this.context.invalidate();

      /* const currentBuffer = this.buffer[ this.bufferIndex ];
      if (this.activeSceneModel.options.modelShown) {
          this.updateRototranslationSlam(currentBuffer);
      }
      // отдать предыдущий кадр
      await super.update(currentBuffer, stream);

      this.buffer[ this.bufferIndex ] = result;
      this.bufferIndex += 1;
      this.bufferIndex %= this.bufferCount; */

      // Buffer for the stream
      if (this.activeSceneModel.options.modelShown) {
          this.updateRototranslationSlam(result);
      }
      // this.activeSceneModel.updateRototranslationSlam(result)
      // @ts-ignore
      this.canvasContext.invalidate();
      const currentCanvas = this.buffer[ this.bufferIndex ];
      // отдать предыдущий кадр
      await super.update(result, currentCanvas.canvas);

      // перезаписать самый старый кадр
      if (currentCanvas.canvas.width !== this.videoCanvas.width) {
          currentCanvas.canvas.width = this.videoCanvas.width;
          currentCanvas.canvas.height = this.videoCanvas.height;
      }
      const { width, height } = this.videoSize;
      currentCanvas.context.drawImage(stream, 0, 0, width, height);
      this.bufferIndex += 1;
      this.bufferIndex %= this.bufferCount;
      this.fpsStats?.end();
  }
}

//     this.activeSceneModel.updateRototranslationSlam(result)
//     this.context.invalidate();
//     const currentCanvas = this.buffer[this.bufferIndex]
//     // отдать предыдущий кадр
//     await super.update(result, currentCanvas.canvas)
//
//     // перезаписать самый старый кадр
//     currentCanvas.canvas.width = this.videoCanvas.width
//     currentCanvas.canvas.height = this.videoCanvas.height
//     const { width, height } = this.videoSize;
//     currentCanvas.context.drawImage(stream, 0,0, width, height)
//     this.bufferIndex += 1
//     this.bufferIndex %= this.bufferCount
