import axios          from 'axios';
import { FiltersSet } from '@geenee/builder/src/components/SketchFabImporterModal/FilterBtn';
import APIRequest     from '@geenee/builder/src/lib/APIRequest';

const SKETCHFAB_URL = 'https://api.sketchfab.com';
const PAGE_SIZE = 24;

export const downloadSketchFabModel = (url: string) => fetch(url).then((res) => res.arrayBuffer());

export const getSketchFabModelByQuery = (
    query = '',
    page = 0,
    sort = '',
    filters: FiltersSet = {}
    // @ts-ignore
) => APIRequest.get(
    `${ SKETCHFAB_URL }/v3/search?type=models&downloadable=true${
        query ? `&q=${ query }` : ''
    }&count=${ PAGE_SIZE }${ page ? `&cursor=${ page * PAGE_SIZE }` : '' }${
        sort ? `&sort_by=${ sort }` : ''
    }${
        filters?.animated === undefined
            ? ''
            : `&animated=${ filters.animated }`
    }`,
    { external: true }
);

export const getDownloadModelUrl = (id: string, type: 'gltf' | 'source' = 'gltf') => axios.get(
    `https://api.sketchfab.com/v3/models/${ id }/download`,
    { headers: { Authorization: `Bearer ${ localStorage.getItem('sketchfab_access_token') }` } }
).then((res) => res.data?.[ type ]?.url);
