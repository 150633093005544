import React, { FC }                       from 'react';
import { cn }                              from '../../util/bem';
import { externalClasses, MediaPropsType } from '../../util/externalClasses';
import { RadiusSizesType, SizesUnionType } from '../../util/global-props';
import {  withMedia }                      from '../../util/media-merge';
import './img.component.scss';

export type ImagePropsType = {
  className?: string;
  src: string;
  alt?: string;
  size?: 'contain' | 'cover';
  margin?: SizesUnionType;
  radius?: RadiusSizesType;
  style?: React.CSSProperties;

  hide?: MediaPropsType;
  xsm?: Partial<ImagePropsType>;
  sm?: Partial<ImagePropsType>;
  md?: Partial<ImagePropsType>;
  lg?: Partial<ImagePropsType>;
}

const className = cn('image');

export const Image: FC<ImagePropsType> = withMedia((props) => (
    <img
        className={ className({
            margin: props.margin,
            size:   props.size,
            radius: props.radius
        }, null, props.className, externalClasses({ media: props.hide })) }
        src={ props.src }
        alt={ props.alt || '' }
        style={ props.style }
    />
));
