import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({ width = '26', height = '27', onClick, id }: SvgIconProps) {
    return (
        <svg
            id={ id }
            onClick={ onClick }
            width={ width }
            height={ height }
            viewBox="0 0 26 27"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_22930_38367)">
                <path d="M16.5905 12.2726L10.7441 16.0503L10.7441 8.49501L16.5905 12.2726Z" fill="black" />
                { /* eslint-disable-next-line max-len */ }
                <path d="M24 12.3917C24 18.3098 19.095 23.1429 13 23.1429C6.90501 23.1429 2 18.3098 2 12.3917C2 6.47367 6.90501 1.64056 13 1.64056C19.095 1.64056 24 6.47367 24 12.3917Z" fill="white" stroke="black" strokeWidth="2" />
                { /* eslint-disable-next-line max-len */ }
                <path fillRule="evenodd" clipRule="evenodd" d="M17.6005 12.2089L10.6714 7.62878C10.342 7.41105 9.90881 7.65423 9.90881 8.05685V17.2171C9.90881 17.6197 10.342 17.8629 10.6714 17.6452L17.6005 13.0651C17.9035 12.8647 17.9035 12.4092 17.6005 12.2089ZM16.4176 12.637L10.8987 16.285V8.98899L16.4176 12.637Z" fill="black" />
            </g>
            <defs>
                { /* eslint-disable-next-line max-len */ }
                <filter id="filter0_d_22930_38367" x="0" y="0.640564" width="26" height="25.5023" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.516667 0 0 0 0 0.516667 0 0 0 0 0.516667 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_22930_38367" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_22930_38367" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}
