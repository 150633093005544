import React, { memo }     from 'react';
import { Button, Wrapper } from '@geenee/ui';
import { useDeleteModal }  from "~/module/team-library/hooks/useDeleteModal";

type Props = {
  onDelete: ()=> void
  onClearSelection: () => void
  onSelectAll: () => void
}

export const DeleteRow = memo((props: Props) => {
    const [ showModal ] = useDeleteModal({ onDelete: props.onDelete });
    return (
        <Wrapper row align="right" valign="center">
            <Button
                margin="xs"
                size='sm'
                icon='remove'
                viewType="white"
                iconSize={ 13 }
                align='center'
                iconMargin='sm'
                onClick={ () => showModal() }
                radius="lg"
            />
            <Button
                margin="xs"
                size='sm'
                viewType="white"
                align='center'
                onClick={ props.onClearSelection }
                radius="lg"
                uppercase
            >
                Clear Selection
            </Button>
            <Button
                margin="xs"
                size='sm'
                viewType="white"
                align='center'
                onClick={ props.onSelectAll }
                radius="lg"
                uppercase
            >
                Select All
            </Button>
            { /*
        @TODO: DISCUSSED
        <Button
            size='sm'
            align='center'
            onClick={ props.onClearSelection }
            radius="lg"
            uppercase
        >
            Import
        </Button> */ }
        </Wrapper>
    );
});
