import * as React         from 'react';
import { memo }           from 'react';
import { MagellanShared } from '@geenee/shared/src/magellan';
import { observer }       from 'mobx-react';

// force push
export const MagellanCMSDI = observer(() => (
    <MagellanShared />
));

export const MagellanCMS = observer(() => (
    <MagellanCMSDI />
));

// enableLogging();
