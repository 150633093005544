import { useCallback, useMemo, useState } from 'react';
import { Analytics }                      from '@geenee/analytics';
import { useBuilderInject }               from '@geenee/builder/src/core/hook/use-builder-inject';
import { CONTACT_US_SUBMIT }              from '@geenee/builder/src/lib/constants';
import { useLocalObservable }             from "mobx-react";
import { createTaskApi }                  from '../../../api/ZendeskApi';
import { useContactFormModal }            from './ContactFormModal/useContactFormModal';

export const categories = [
    { value: 'general', label: 'General help question' },
    { value: 'hirePro', label: 'Hire a Pro' },
    { value: 'refund', label: 'Request for refund' },
    { value: 'sales', label: 'Contact sales for pricing & custom contracts ' }
];

type Props = {
  category: {value: string, label: string};
  name: string;
  content: string;
}

export type SupportFile = {
  blob: Blob;
  fileName: string
  id: string
}
const defaultState = { category: { value: '', label: '' }, name: '', content: '' };

export const useSupportRequestForm = () => {
    const [ loading, setLoading ] = useState(false);
    const [ state, setState ] = useState<Props>({ ...defaultState });
    const [ filesList, setFilesList ] = useState<SupportFile[]>([]);
    const { container } = useBuilderInject();
    const analytics: Analytics = container.get('<Analytics>');

    const task = useLocalObservable(() => ({
        created: false,
        success() { this.created = true; },
        error() { this.created = false; }
    }));
    const { showModal } = useContactFormModal({ success: task.created });

    const onChange = useCallback((value: string | {value: string, label: string}, field: string) => {
        setState((prevState) => ({ ...prevState, [ field ]: value }));
    }, [ state ]);

    const isMainFieldsFilled = useMemo(() => state.name && state.category.value && state.content, [ state ]);

    const setFilesListCallback = useCallback((item: SupportFile) => {
        setFilesList((prev) => [ ...prev, item ]);
    }, [ filesList ]);

    const getBinaryImage = useCallback((file) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        const id = JSON.stringify(file);
        reader.onload = () => {
            setFilesListCallback({
                blob:     new Blob([ reader.result || '' ]),
                fileName: file.name,
                id
            });
        };
    }, []);

    const onSubmit = useCallback(async () => {
        const payload = {
            subject:     state.category.label,
            description: `From: ${ state.name };\n ${ state.content }`,
            attachments: filesList.length ? filesList.map((file) => ({
                blob:     file.blob,
                fileName: file.fileName
            })) : undefined
        };

        setLoading(true);
        createTaskApi(
            payload
        ).then(() => {
            analytics.track(CONTACT_US_SUBMIT, {
                subject:        payload.subject,
                description:    payload.description,
                hasAttachments: !!payload.attachments
            });
            setLoading(false);
            task.success();
            showModal();
            setState({ ...defaultState });
            setFilesList([]);
        }).catch(() => {
            setLoading(false);
            task.error();
            showModal();
        });
    }, [ state, filesList, task, showModal ]);

    const onRemoveFile = useCallback((id?: number) => {
        if (id === undefined) {
            setFilesList([]);
        } else {
            setFilesList((prev) => prev.filter((file, index) => index !== id));
        }
    }, [ filesList ]);

    return {
        state, filesList, isMainFieldsFilled, onChange, getBinaryImage, onSubmit, onRemoveFile, loading
    };
};
