import React, { useMemo }     from 'react';
import { useHistory }         from "@geenee/common";
import { iconMap }            from '@geenee/ui';
import { NOTION_SUPPORT_URL } from '../../../../lib/constants';

export type RedirectCardType = {
  id: string,
  title: string,
  description: string | JSX.Element,
  sdkDocs?: string,
  iconName: keyof typeof iconMap,
  onClick: () => void,
  onClickSDKDocs?: () => void,
  disabled?: boolean
  buttonName: string
}

export const useHomePageBottomMenu = () => {
    const history = useHistory();

    const menuSections: RedirectCardType[] = useMemo(() => [ {
        id:          'support',
        title:       'Support',
        description: 'Access our SDK documentation, code inject library, overall best practices and helpful tips',
        iconName:    'support',
        onClick:     () => window.open(NOTION_SUPPORT_URL),
        buttonName:  'Visit Support center'
    }, {
        id:             'access',
        title:          'SDK Token',
        description:    'Get an access key to our WebAR SDK for face & body tracking.',
        iconName:       'sdk',
        onClick:        () => history.push('/sdk'),
        onClickSDKDocs: () => window.open('https://lab.geen.ee/engeenee-doc/index.html'),
        buttonName:     'Get SDK Access'
    } ], []);
    return { menuSections };
};
