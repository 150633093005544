import React, {
    ChangeEvent, useEffect,
    useRef, useState
}   from 'react';
import Cog from '@geenee/builder/src/asset/icons/Cog';
import {
    FlowCodeParams,
    generateQRCode, getAttachmentUrl,
    getFromCache, lastOptsData,
    saveQRCodePng,
    setInCache
} from '@geenee/builder/src/components/ProjectsLinkDropdown/components/FlowCode/components/FlowCodeHelper';
import { QrSettingsPopup }          from '@geenee/builder/src/components/ProjectsLinkDropdown/components/FlowCode/components/QrSettingsPopup';
import { useBuilderInject }         from '@geenee/builder/src/core/hook/use-builder-inject';
import useOnClickOutside            from '@geenee/builder/src/lib/useOnClickOutside';
import DownArrowBox                 from '@geenee/shared/asset/icons/DownArrowBox';
import { useDebounce }              from '@geenee/shared/src/util/useDebounce';
import { cn, Description, Wrapper } from '@geenee/ui';
import jsQR                         from 'jsqr';
import { observer }                 from 'mobx-react';
import { useTheme }                 from 'styled-components';
import { QRCodeImage }              from './components/QrCodeImage';
import './styles.scss';

type Props = {
    publish?: boolean;
    url: string;
    onShortUrlUpdate: (val: string) => void
};
const className = cn('flow-code');

export const shortUrls: Record<string, string> = {};

export const FlowCode = observer(({ publish, url, onShortUrlUpdate }: Props) => {
    const { BuilderState } = useBuilderInject();
    const { currentUser } = BuilderState;
    const profile = currentUser?.profile;

    const dropdownRef = useRef<HTMLDivElement>(null);
    const { colors } = useTheme();
    const [ loading, setLoading ] = useState(true);
    const [ mainColor, setMainColor ] = useState(
        lastOptsData[ url ]?.gridModuleColor
            ? lastOptsData[ url ]?.gridModuleColor.split(',')[ 0 ]
            : colors.black
    );
    const [ codeState, setCodeState ] = useState<FlowCodeParams>({
        url,
        opts: lastOptsData[ url ] || {
            gridBackgroundColor:      colors.white,
            containerBackgroundColor: colors.white,
            containerBorderColor:     mainColor
        }
    });

    const [ imageBase64, setImageBase64 ] = useState('');

    const [ showSettings, setShowSettings ] = useState(false);
    const [ showDoneLabel, setShowDoneLabel ] = useState(false);
    const [ generatedData, setGeneratedData ] = useState<FlowCodeParams>(codeState);

    const qrCodeSettings: FlowCodeParams = useDebounce(generatedData, 500);

    const getCodeWithoutCache = async (settings: FlowCodeParams) => {
        const imageCode = await generateQRCode(settings, mainColor);
        setInCache(settings, imageCode);
        setImageBase64(imageCode);
    };
    const getShortUrlFromQRCode = () => {
        const image = new Image();
        image.src = `data:image/svg+xml;base64,${ imageBase64 }`;
        image.width = 180;
        image.height = 180;
        image.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = 180;
            canvas.height = 180;
            const context = canvas.getContext('2d');
            if (context) {
                context.drawImage(image, 0, 0, 180, 180);
                const imageData = context.getImageData(0, 0, 180, 180) as unknown as ImageData;
                const code = jsQR(imageData.data, 180, 180);
                if (code) {
                    onShortUrlUpdate(code.data);
                    shortUrls[ url ] = code.data;
                } else {
                    getCodeWithoutCache(qrCodeSettings);
                }
            }
        };
    };
    const getShortUrl = () => {
        if (profile?.options?.disableFlowcode) {
            onShortUrlUpdate(url);
            return;
        }
        if (!imageBase64) {
            return;
        }
        if (shortUrls[ url ]) {
            onShortUrlUpdate(shortUrls[ url ]);
        } else {
            getShortUrlFromQRCode();
        }
    };

    useEffect(() => {
        getShortUrl();
    }, [ imageBase64 ]);

    const updateQrCode = async (settings: FlowCodeParams) => {
        const cache = getFromCache(settings);
        if (!cache) {
            await getCodeWithoutCache(settings);
        } else {
            setImageBase64(cache);
        }
        setLoading(false);
    };

    useEffect(() => {
        updateQrCode(qrCodeSettings);
    }, [ qrCodeSettings ]);

    // @ts-ignore
    useOnClickOutside(dropdownRef, () => {
        setShowSettings(false);
    });

    const onChange = (data: {[key: string]: any}) => {
        !loading && setLoading(true);
        const newData = {
            ...codeState,
            opts: {
                ...codeState.opts,
                ...data
            }
        };

        const cache = getFromCache(newData);

        if (cache) {
            setImageBase64(cache);
            setLoading(false);
        } else {
            setGeneratedData(newData);
        }
        lastOptsData[ url ] = { ...(lastOptsData[ url ] || {}), ...newData.opts };
        setCodeState(newData);
    };

    const saveCodeImage = () => {
        saveQRCodePng(imageBase64);
        setShowDoneLabel(true);
        setTimeout(() => {
            setShowDoneLabel(false);
        }, 3000);
    };

    const onFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
        !loading && setLoading(true);
        const file = e.target.files![ 0 ];
        if (file.type.startsWith('image')) {
            const fileUrl = await getAttachmentUrl(file);
            onChange({ logoImageFile: fileUrl });
        }
    };
    return (
        <Wrapper padding="xs" style={ { rowGap: 10 } }>
            <Description
                size="sm"
                margin="xs"
                color={ publish ? 'white' : undefined }
            >
                Scan the QR code:
            </Description>
            <Wrapper row fullWidth valign="top">
                <Wrapper maxWidth="fit-content" align="left">
                    <div className={ className('icons_container') }>
                        <div className={ className('button') } onClick={ () => setShowSettings(!showSettings) }>
                            <Cog width="25" height="25" />
                        </div>
                        { showSettings ? (
                            <QrSettingsPopup
                                publish={ publish }
                                url={ url }
                                onChange={ onChange }
                                popupRef={ dropdownRef }
                                codeState={ codeState }
                                mainColor={ mainColor }
                                onFileSelect={ onFileSelect }
                                updateMainColor={ setMainColor }
                            />
                        ) : <></> }
                        { !showDoneLabel ? (
                            <div className={ className('button') }>
                                <a onClick={ saveCodeImage }>
                                    <DownArrowBox fill="black" />
                                </a>
                            </div>
                        ) : <div className={ className('done-label') }>done!</div> }
                    </div>
                </Wrapper>
                <Wrapper
                    maxWidth="fit-content"
                    frame="solid-white"
                    radius="md"
                    padding='xxs'
                >
                    <QRCodeImage imageCode={ imageBase64 } loading={ loading } />
                </Wrapper>
            </Wrapper>
        </Wrapper>
    );
});
