import queryString          from 'querystring';
import { History }          from 'history';
import { useBuilderInject } from '@geenee/builder/src/core/hook/use-builder-inject';
import { TOAST_ERROR }      from '@geenee/builder/src/lib/constants';

export const checkQueryToken = (history: History) => {
    const query = queryString.parse(window.location.search.slice(1));
    const { BuilderState } = useBuilderInject();
    const { token, error, is_new } = query;
    if (error) {
        BuilderState.update('toast', {
            severity: TOAST_ERROR,
            detail:   Array.isArray(error) ? error.join(' ') : error,
            summary:  ''
        });
        history.push(window.location.pathname);
        return error;
    }
    if (token) {
        const authHeaders = [ [ 'authorization', `Bearer ${ token }` ] ];
        BuilderState.setAuthHeaders(authHeaders);
        if (is_new) {
            history.push('/post-sign-up');
        } else {
            history.push('/');
        }
        return token;
    }
};
