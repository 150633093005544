import React, { FC, useEffect, useRef, useState }        from 'react';
import type { SceneRenderer }                            from '@geenee/shared/index';
// import { updateMatrixInNextFrame }                       from '@geenee/shared/index';
import { AppStateType }                                  from '@geenee/shared/src/magellan/state/app.state';
import { useFrame }                                      from '@react-three/fiber';
import { observer }                                      from 'mobx-react';
import { LinearFilter, Matrix4, Object3D, VideoTexture } from 'three';

type VideoOverlayPropsType = {
    activeSceneModel: SceneRenderer;
    appState: AppStateType;
    targets: unknown;
    videoElement: HTMLVideoElement;
    videoTextureMuted: boolean;
    visible: boolean;
    matrix?: Matrix4;
    onExpLoaded?: any;
    isExpPaused?: boolean;
};

const setCurrentVideoTime = (id: string, time: number) => localStorage.setItem(id, JSON.stringify(time));
const getCurrentVideoTime = (id: string) => JSON.parse(localStorage.getItem(id) || '0');

const VideoOverlay: FC<VideoOverlayPropsType> = observer(({
    activeSceneModel,
    videoTextureMuted,
    videoElement,
    targets,
    isExpPaused,
    visible,
    ...props
}) => {
    // This reference give us direct access to the mesh
    const mesh = useRef();

    // const [ videoEl, setVideoEl ] = useState(null);
    const videoElRef = useRef(null);
    const getVideoEl = () => videoElRef.current;
    // eslint-disable-next-line no-return-assign
    const setVideoEl = (videoEl: any) => videoElRef.current = videoEl;

    const videoTextureRef = useRef(null);
    const getVideoTexture = () => videoTextureRef.current;
    // eslint-disable-next-line no-return-assign
    const setVideoTexture = (videoTexture: any) => videoTextureRef.current = videoTexture;

    const [ localTarget, setLocalTarget ] = useState(null);
    const [ videoGeometryArgs, setVideoGeometryArgs ] = useState([ 0, 0, 0 ]);

    /*
    * Read and set Target Data
    */
    useEffect(() => {
        if (!targets) return;
        const target = targets.filter((t) => t.schema.arVideo)[ 0 ];
        setLocalTarget(target);
    }, [ targets ]);

    /*
    * Setup Video Textures and Overlay Map Textures + onLoad Callback
    */
    useEffect(() => {
        if (!videoElement) return;
        // global window video time setter/getter. // TODO: do we still need it here?
        window.GeeneeAR = {
            ...window.GeeneeAR,
            setCurrentVideoTime,
            getCurrentVideoTime
        };

        // Setup video Texture for Overlay Mesh
        const videoTexture = new VideoTexture(videoElement);
        videoTexture.minFilter = LinearFilter;
        setVideoTexture(videoTexture);

        // Callback after original video playback ended
        videoElement.addEventListener('ended', () => {
            props.appState.emit('geenee-arscene-video-ended');
        }, false);

        // And set video texture source element to local state
        setVideoEl(videoElement);

        // OnLoad Callback - needs to remove Application Loader
        props.onExpLoaded && props.onExpLoaded(true);

        // Unsubscribe from VideoEnded Event
        return () => {
            videoElement.removeEventListener('ended', () => {
                props.appState.emit('geenee-arscene-video-ended');
            });
        };
    }, [ videoElement ]);

    /*
    * Start video playback if Target in the CameraFeed
    */
    useEffect(() => {
        const videoEl: HTMLVideoElement = getVideoEl();
        if (!isExpPaused && videoEl && visible) {
            videoEl.play();
        }
    }, [ visible, videoElRef ]);

    /*
    * Stop/pause video if experience state changes
    */
    useEffect(() => {
        if (!getVideoEl()) return;
        isExpPaused ? getVideoEl().pause() : getVideoEl().play();
    }, [ isExpPaused ]);

    /*
    * Mute/Unmute video switcher
    */
    useEffect(() => {
        if (getVideoEl()) {
            getVideoEl().muted = videoTextureMuted;
            getVideoEl().play();
        }
    }, [ videoTextureMuted ]);

    /*
    * Set Overlay Geometry Size // TODO: need to be fixed
    */
    useEffect(() => {
        if (!localTarget) return;
        const { width, height, arDepth } = localTarget;

        setVideoGeometryArgs([
            2 * (width / width),
            2 * (height / width),
            arDepth
        ]);
    }, [ localTarget ]);

    /*
    * Matrix apply render loop
    */
    useFrame(() => {
        const _matrix: Matrix4 = activeSceneModel.options.overlayMatrix;
        // if (visible && mesh && mesh.current !== null && _matrix) {
        //     const container: Object3D = mesh.current;
        //     _matrix.decompose(container.position, container.quaternion, container.scale);
        // }

        if (visible && mesh && _matrix) {
            const container: Object3D = mesh.current;
            // updateMatrixInNextFrame(container, _matrix);
        }
    });

    return (
        <mesh visible={ visible } ref={ mesh }>
            <boxBufferGeometry attach="geometry" args={ videoGeometryArgs } />
            <meshLambertMaterial attach="material">
                { getVideoTexture() && <primitive attach="map" object={ getVideoTexture() } /> }
            </meshLambertMaterial>
        </mesh>
    );
});
// eslint-disable-next-line arca/no-default-export
export default VideoOverlay;
