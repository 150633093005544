import React, { useContext }          from 'react';
import { useHistory }                 from "@geenee/common";
import { Wrapper }                    from '@geenee/ui';
import findKey                        from 'lodash-es/findKey';
import { observer }                   from 'mobx-react';
import { useBuilderInject }           from '@geenee/builder/src/core/hook/use-builder-inject';
import { stripeState }                from '@geenee/builder/src/core/state/stripe.state';
import ModalUpdaterContext            from '@geenee/builder/src/lib/ModalUpdaterContext';
import { useHandlers }                from '@geenee/builder/src/pages/AllPlansPage/useHandlers';
import { FooterWrapper, TrialButton } from './styles';

interface Props {
  closeSideBar: () => void;
  hideNft?: boolean;
}

export const Footer = observer(({
    closeSideBar,
    hideNft
}: Props) => {
    const history = useHistory();
    const {
        BuilderState,
        getDIComponent
    } = useBuilderInject();
    const { role } = BuilderState.currentUser.profile || { role: "" };

    const NFTOptIn = getDIComponent("NftOptIn");
    const SettingsButtonCreator = getDIComponent("SettingsButtonCreator");
    const settingsButton = SettingsButtonCreator(closeSideBar);
    const currentInterval = stripeState.plan_period;
    const setModalOptions = useContext(ModalUpdaterContext);
    const { showPaymentModal } = useHandlers(currentInterval as "year" | "month", setModalOptions);

    const getPlanMessage = () => {
        if (stripeState.plan_price !== 0 && stripeState.status === "active") return null;

        let message = null;
        if (stripeState.plan_title.toLowerCase() !== "enterprise") {
            // @ts-ignore
            message = "UPGRADE - GO PRO";
        }

        const onButtonPush = () => {
            const properPlanForInterval = findKey(stripeState.prices, {
                name:   "Professional",
                period: currentInterval
            });
            // eslint-disable-next-line eqeqeq
            if (stripeState.daysLeft == 0 || !properPlanForInterval) {
                history.push("/all-plans");
                return;
            }
            properPlanForInterval && showPaymentModal(properPlanForInterval);
        };

        return (
            <TrialButton
                onClick={ onButtonPush }
            >
                { message }
            </TrialButton>
        );
    };
    const planMessage = (role || "").toLowerCase() === "owner" && stripeState.plan_title.toLowerCase() !== "enterprise" ? getPlanMessage() : <></>;
    return (
        <FooterWrapper>
            { /* { hideNft ? <></> : <NFTOptIn /> } */ }
            <Wrapper row valign="center">
                { settingsButton }
                { planMessage }
            </Wrapper>
        </FooterWrapper>
    );
});
