export const convertGltfToGlb = async (input_files) => {
    let files = [];
    let fileblobs = [];
    let gltf;
    let remainingfilestoprocess = 0;

    let outputBuffers;
    let bufferMap;
    let bufferOffset;

    const clearInitialData = () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        files = [];
        fileblobs = [];
        gltf = null;
        outputBuffers = null;
        bufferMap = null;
        bufferOffset = null;
    };

    // eslint-disable-next-line no-shadow
    const readFiles = async (files) => {
        try {
            clearInitialData();
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            remainingfilestoprocess = files.length;
            // eslint-disable-next-line no-restricted-syntax
            for (const item of files) {
                if (item.name) {
                    files.push(item.file);
                    if (item.ext === 'gltf') {
                        gltf = item.file;
                    } else {
                        fileblobs[ item.name.toLowerCase() ] = item.file;
                    }
                }
            }
            outputBuffers = [];
            bufferMap = new Map();
            bufferOffset = 0;
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            await processBuffers();
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            return fileSaveV2();
        } catch (e) {
            console.log({ e: e.message, stack: e.stack });
        }
    };

    function fileSaveV2() {
        const Binary = { Magic: 0x46546c67 };

        // eslint-disable-next-line no-plusplus
        for (let _i = 0, _a = gltf.bufferViews; _i < _a.length; _i++) {
            const bufferView = _a[ _i ];
            if (bufferView.byteOffset === undefined) {
                bufferView.byteOffset = 0;
            } else {
                bufferView.byteOffset += bufferMap.get(bufferView.buffer);
            }
            bufferView.buffer = 0;
        }

        const binBufferSize = bufferOffset;
        gltf.buffers = [
            { byteLength: binBufferSize }
        ];

        const enc = new TextEncoder();
        const jsonBuffer = enc.encode(JSON.stringify(gltf));
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const jsonAlignedLength = alignedLength(jsonBuffer.length);
        let padding;
        if (jsonAlignedLength !== jsonBuffer.length) {
            padding = jsonAlignedLength - jsonBuffer.length;
        }
        const totalSize =            12 // file header: magic + version + length
            + 8 // json chunk header: json length + type
            + jsonAlignedLength
            + 8 // bin chunk header: chunk length + type
            + binBufferSize;
        const finalBuffer = new ArrayBuffer(totalSize);
        const dataView = new DataView(finalBuffer);
        let bufIndex = 0;
        dataView.setUint32(bufIndex, Binary.Magic, true);
        bufIndex += 4;
        dataView.setUint32(bufIndex, 2, true);
        bufIndex += 4;
        dataView.setUint32(bufIndex, totalSize, true);
        bufIndex += 4;
        // JSON
        dataView.setUint32(bufIndex, jsonAlignedLength, true);
        bufIndex += 4;
        dataView.setUint32(bufIndex, 0x4e4f534a, true);
        bufIndex += 4;

        // eslint-disable-next-line no-var,block-scoped-var,no-plusplus,vars-on-top
        for (var j = 0; j < jsonBuffer.length; j++) {
            // eslint-disable-next-line block-scoped-var
            dataView.setUint8(bufIndex, jsonBuffer[ j ]);
            // eslint-disable-next-line no-plusplus
            bufIndex++;
        }
        if (padding !== undefined) {
            // eslint-disable-next-line no-var,block-scoped-var,no-plusplus,vars-on-top,no-redeclare
            for (var j = 0; j < padding; j++) {
                dataView.setUint8(bufIndex, 0x20);
                // eslint-disable-next-line no-plusplus
                bufIndex++;
            }
        }

        // BIN
        dataView.setUint32(bufIndex, binBufferSize, true);
        bufIndex += 4;
        dataView.setUint32(bufIndex, 0x004e4942, true);
        bufIndex += 4;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < outputBuffers.length; i++) {
            const bufoffset = bufIndex + bufferMap.get(i);
            const buf = new Uint8Array(outputBuffers[ i ]);
            let thisbufindex = bufoffset;
            // eslint-disable-next-line no-var,block-scoped-var,no-plusplus,vars-on-top,no-redeclare
            for (var j = 0; j < buf.byteLength; j++) {
                // eslint-disable-next-line block-scoped-var
                dataView.setUint8(thisbufindex, buf[ j ]);
                // eslint-disable-next-line no-plusplus
                thisbufindex++;
            }
        }
        return finalBuffer;
    }

    function processBuffers() {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const pendingBuffers = gltf.buffers.map((buffer, bufferIndex) => dataFromUri(buffer).then((data) => {
            if (data !== undefined) {
                outputBuffers.push(data);
            }
            // eslint-disable-next-line no-param-reassign
            delete buffer.uri;
            // eslint-disable-next-line no-param-reassign
            buffer.byteLength = data.byteLength;
            bufferMap.set(bufferIndex, bufferOffset);
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            bufferOffset += alignedLength(data.byteLength);
        }));

        return Promise.all(pendingBuffers).then(() => {
            let bufferIndex = gltf.buffers.length;
            const images = gltf.images || [];
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            const pendingImages = images.map((image) => dataFromUri(image).then((data) => {
                if (data === undefined) {
                    // eslint-disable-next-line no-param-reassign
                    delete image.uri;
                    return;
                }
                const bufferView = {
                    buffer:     0,
                    byteOffset: bufferOffset,
                    byteLength: data.byteLength
                };
                bufferMap.set(bufferIndex, bufferOffset);
                // eslint-disable-next-line no-plusplus
                bufferIndex++;
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                bufferOffset += alignedLength(data.byteLength);
                const bufferViewIndex = gltf.bufferViews.length;
                gltf.bufferViews.push(bufferView);
                outputBuffers.push(data);
                // eslint-disable-next-line no-param-reassign
                image.bufferView = bufferViewIndex;
                // eslint-disable-next-line no-param-reassign,@typescript-eslint/no-use-before-define
                image.mimeType = getMimeType(image.uri);
                // eslint-disable-next-line no-param-reassign
                delete image.uri;
            }));
            return Promise.all(pendingImages);
        });
    }

    function isBase64(uri) {
        return uri.length < 5 ? false : uri.substr(0, 5) === 'data:';
    }
    function decodeBase64(uri) {
        return fetch(uri).then((response) => response.arrayBuffer());
    }
    function dataFromUri(buffer) {
        if (buffer.uri === undefined) {
            return Promise.resolve(undefined);
        } if (isBase64(buffer.uri)) {
            return decodeBase64(buffer.uri);
        }
        const filename = buffer.uri.substr(buffer.uri.lastIndexOf('/') + 1);
        const file = Promise.resolve(fileblobs[ filename.toLowerCase() ]);
        return file;
    }
    function alignedLength(value) {
        const alignValue = 4;
        // eslint-disable-next-line eqeqeq
        if (value == 0) {
            return value;
        }
        const multiple = value % alignValue;
        if (multiple === 0) {
            return value;
        }
        return value + (alignValue - multiple);
    }
    function getMimeType(filename) {
        // eslint-disable-next-line guard-for-in,@typescript-eslint/no-use-before-define,no-restricted-syntax
        for (const mimeType in gltfMimeTypes) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define,guard-for-in,no-restricted-syntax
            for (const extensionIndex in gltfMimeTypes[ mimeType ]) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                const extension = gltfMimeTypes[ mimeType ][ extensionIndex ];
                if (filename.toLowerCase().endsWith(`.${ extension }`)) {
                    return mimeType;
                }
            }
        }
        return 'application/octet-stream';
    }

    // eslint-disable-next-line no-var,vars-on-top
    var gltfMimeTypes = {
        'image/png':        [ 'png' ],
        'image/jpeg':       [ 'jpg', 'jpeg' ],
        'text/plain':       [ 'glsl', 'vert', 'vs', 'frag', 'fs', 'txt' ],
        'image/vnd-ms.dds': [ 'dds' ]
    };

    return readFiles(input_files);
};
