import React from 'react';
import {
    LibraryIcon,
    SketchfabIcon,
    SpotifyLogo,
    Text3DIcon,
    UploadCloud
} from './resource/assetLibraryIcons';
import { CheckboxChecked, CheckboxUnchecked } from './resource/checkbox';
import {
    Add,
    AgainstArrows,
    AlignBottom,
    AlignCenter,
    AlignLeft,
    AlignMiddle,
    AlignRight,
    AlignTop,
    ArrowCircle,
    ArrowDown,
    ArrowDown2,
    ArrowLeft,
    ArrowRight,
    ArrowTop,
    AutoStar,
    AutoStars,
    Bell, BodyTrackingAvatar, BodyTrackingTwin,
    Button,
    Calendar,
    Card,
    CardComponent,
    Chat,
    Check,
    Clock,
    Close,
    Close2,
    Copy,
    Delete,
    DeviceAllM,
    DeviceAllS,
    DeviceDesktopL,
    DeviceDesktopM,
    DeviceDesktopS,
    DeviceMobileL,
    DeviceMobileM,
    DeviceMobileS,
    DeviceTabletL,
    DeviceTabletM,
    DeviceTabletS,
    Drag,
    Edit,
    Export,
    ExportLeads,
    EyeOff,
    EyeOn,
    Facebook,
    Faq,
    FaqBig,
    Filter,
    Form,
    Google,
    GoogleDrive,
    GoogleSheets,
    Grid, HeadTracking,
    Help,
    Home,
    Iframe, Image,
    ImageGallery,
    Instagram,
    Integrations,
    Key,
    KlickTipp,
    Lightning,
    LinkIcon,
    LockClose,
    LockOpen,
    LogoApple,
    LogoMessenger,
    LogoTelegram,
    Mail,
    Map,
    MapComponent,
    MarkerAr, Material,
    Move,
    MoveTo, Muted,
    NativeAr,
    NavBurger,
    NavBurger2,
    NavDots,
    NavDotsVertical,
    NewFeatures,
    None,
    Notification,
    Options,
    Overlay, PcBug,
    Phone,
    Play,
    Price,
    Profile,
    Restore,
    Rotate,
    Scale,
    SceneBuild, Search,
    Settings,
    Speaker,
    Star,
    StartFinish,
    Status,
    Styles,
    Submit,
    SubmitAction,
    Text,
    TextAlignCenter,
    TextAlignLeft,
    TextAlignRight,
    TextBold,
    TextColorPicker,
    TextFontSize,
    TextItalic,
    TextListArrow,
    TextListCheck,
    TextListCircle,
    TextListCross,
    TextListSquare,
    ToGround,
    Tutorials,
    Twitter, UnMuted,
    Unsplash,
    Upload, UploadImage,
    Video, Vimeo,
    WhatsUp,
    WorldAr,
    YouTube,
    Zapier
} from './resource';

export const iconLibrary = {
    System: {
        checkboxChecked:   <CheckboxChecked activated />,
        checkboxUnchecked: <CheckboxUnchecked />,
        check:             <Check />,
        lockClose:         <LockClose />,
        lockOpen:          <LockOpen />,
        key:               <Key />,
        eyeOn:             <EyeOn />,
        eyeOff:            <EyeOff />,
        play:              <Play />,
        grid:              <Grid />,
        settings:          <Settings />,
        options:           <Options />,
        submit:            <Submit />,
        link:              <LinkIcon />,
        styles:            <Styles />,
        upload:            <Upload />,
        drag:              <Drag />,
        faq:               <Faq />,
        autoStar:          <AutoStar />,
        autoStars:         <AutoStars />,
        search:            <Search />,
        pcBug:             <PcBug />
    },
    Settings:   { none: <None /> },
    Navigation: {
        home:         <Home />,
        burger:       <NavBurger />,
        burger2:      <NavBurger2 />,
        dots:         <NavDots />,
        dotsVertical: <NavDotsVertical />
    },
    Actions: {
        close:         <Close />,
        // @ts-ignore
        close2:        <Close2 />,
        add:           <Add />,
        copy:          <Copy />,
        delete:        <Delete />,
        edit:          <Edit />,
        restore:       <Restore />,
        moveTo:        <MoveTo />,
        toGround:      <ToGround />,
        export:        <Export />,
        star:          <Star />,
        move:          <Move />,
        rotate:        <Rotate />,
        scale:         <Scale />,
        material:      <Material />,
        lightning:     <Lightning />,
        againstArrows: <AgainstArrows />,
        muted:         <Muted />,
        unmuted:       <UnMuted />,
        uploadImage:   <UploadImage />
    },
    Arrows: {
        arrowDown:   <ArrowDown />,
        arrowLeft:   <ArrowLeft />,
        arrowRight:  <ArrowRight />,
        arrowTop:    <ArrowTop />,
        arrowDown2:  <ArrowDown2 />,
        arrowCircle: <ArrowCircle />
    },
    Align: {
        alignTop:    <AlignTop />,
        alignMiddle: <AlignMiddle />,
        alignBottom: <AlignBottom />,
        alignLeft:   <AlignLeft />,
        alignCenter: <AlignCenter />,
        alignRight:  <AlignRight />
    },
    Content: {
        image:         <Image />,
        clock:         <Clock />,
        form:          <Form />,
        mail:          <Mail />,
        phone:         <Phone />,
        text:          <Text />,
        calendar:      <Calendar />,
        submitAction:  <SubmitAction />,
        map:           <Map />,
        mapComponent:  <MapComponent />,
        button:        <Button />,
        cardComponent: <CardComponent />,
        start:         <StartFinish />
    },
    Profile: {
        profile:      <Profile />,
        card:         <Card />,
        bell:         <Bell />,
        speaker:      <Speaker />,
        price:        <Price />,
        notification: <Notification />
    },
    Crm: {
        status:       <Status />,
        exportLeads:  <ExportLeads />,
        integrations: <Integrations />,
        filter:       <Filter />
    },
    TextEdit: {
        textAlignCenter: <TextAlignCenter />,
        textAlignLeft:   <TextAlignLeft />,
        textAlignRight:  <TextAlignRight />,
        textBold:        <TextBold />,
        textColorPicker: <TextColorPicker />,
        textFontSize:    <TextFontSize />,
        textItalic:      <TextItalic />
    },
    TextList: {
        textListArrow:  <TextListArrow />,
        textListCheck:  <TextListCheck />,
        textListCircle: <TextListCircle />,
        textListCross:  <TextListCross />,
        textListSquare: <TextListSquare />
    },
    Devices: {
        deviceMobile: {
            16: <DeviceMobileS />,
            24: <DeviceMobileM />,
            56: <DeviceMobileL />
        },
        deviceTablet: {
            16: <DeviceTabletS />,
            24: <DeviceTabletM />,
            56: <DeviceTabletL />
        },
        deviceDesktop: {
            16: <DeviceDesktopS />,
            24: <DeviceDesktopM />,
            56: <DeviceDesktopL />
        },
        deviceAll: { 16: <DeviceAllS />, 24: <DeviceAllM /> }
    },
    Help: {
        help:        <Help />,
        chat:        <Chat />,
        faqBig:      <FaqBig />,
        tutorials:   <Tutorials />,
        newFeatures: <NewFeatures />
    },
    Logos: {
        apple:        <LogoApple />,
        google:       <Google />,
        facebook:     <Facebook />,
        messenger:    <LogoMessenger />,
        telegram:     <LogoTelegram />,
        klickTipp:    <KlickTipp />,
        googleSheets: <GoogleSheets />,
        unsplash:     <Unsplash />,
        youtube:      <YouTube />,
        vimeo:        <Vimeo />,
        instagram:    <Instagram />,
        googleDrive:  <GoogleDrive />,
        whatsUp:      <WhatsUp />,
        twitter:      <Twitter />,
        zapier:       <Zapier />
    },
    AssetLibraryIcons: {
        uploadCloud: <UploadCloud />,
        library:     <LibraryIcon />,
        text3d:      <Text3DIcon />,
        sketchfab:   <SketchfabIcon />,
        spotify:     <SpotifyLogo />
    },
    Sections: {
        'slam-ar':               <WorldAr />,
        'stamp-ar':              <MarkerAr />,
        'native-ar':             <NativeAr />,
        'scene-build':           <SceneBuild />,
        'fullscreen-video':      <Video />,
        'head-tracking':         <HeadTracking />,
        'body-tracking-overlay': <BodyTrackingAvatar />,
        'body-tracking-twin':    <BodyTrackingTwin />,
        'fullscreen-gallery':    <ImageGallery />,
        iframe:                  <Iframe />,
        overlay:                 <Overlay />
    }
};

export const iconMap = {
    ...iconLibrary.System,
    ...iconLibrary.Settings,
    ...iconLibrary.Navigation,
    ...iconLibrary.Actions,
    ...iconLibrary.Arrows,
    ...iconLibrary.Align,
    ...iconLibrary.Content,
    ...iconLibrary.Profile,
    ...iconLibrary.Crm,
    ...iconLibrary.Devices,
    ...iconLibrary.TextEdit,
    ...iconLibrary.TextList,
    ...iconLibrary.Help,
    ...iconLibrary.Logos,
    ...iconLibrary.AssetLibraryIcons,
    ...iconLibrary.Sections
};
