/* eslint-disable @nrwl/nx/enforce-module-boundaries */
// @flow
import * as React from 'react';
import {
  ColorDataSchemaType,
  ColorSchemaType,
  CustomColorSchemaType,
} from '../../packages/shared/lib/type/model/color';
import { createObservableContext } from '../../packages/shared/lib/util/context/_helpers/create-observable-context';


type ColorContextType =
  | ColorSchemaType
  | CustomColorSchemaType
  | ColorDataSchemaType;

export const ColorKitContext: React.Context<ColorContextType> =
  createObservableContext({} as ColorContextType);
