import * as React          from 'react';
import { observer }        from 'mobx-react';
import { $Diff }           from 'utility-types';
import { ColorSchemaType } from '@onepage-legacy/shared/lib/type/model/color';
import { ColorKitContext } from '../context/color-kit-context';

type DiffPropsType = {
    colorKitContext: ColorSchemaType;
}

export function withColorKitContext<T extends DiffPropsType>(Component: React.ComponentType<T>) {
    return React.memo(observer((props: $Diff<T, DiffPropsType>) => (
        <ColorKitContext.Consumer>
            { (context) => <Component { ...props as T } colorKitContext={ context } /> }
        </ColorKitContext.Consumer>
    )));
}
