// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Buffer }                from 'buffer';
import queryString               from 'querystring';
// @ts-ignore
import { useLocation }           from 'react-router';
import { useInject }             from '@geenee/shared';
// @ts-ignore
import crypto                    from 'crypto-browserify';
import { REDBULL_CRYPTO_SECRET } from './constants';

const algorithm = 'aes-256-ctr';

export const encrypt = (text: string) => {
    try {
        // @ts-ignore
        const cipher = crypto.createCipher(algorithm, REDBULL_CRYPTO_SECRET);
        // @ts-ignore
        const encrypted = Buffer.concat([ cipher.update(text), cipher.final() ]);
        return encrypted.toString('hex');
    } catch (e) {
        console.error(e);
        return '';
    }
};

export const decrypt = (hash: string) => {
    try {
        // @ts-ignore
        const decipher  = crypto.createDecipher(algorithm, REDBULL_CRYPTO_SECRET);
        // @ts-ignore
        const decrpyted = Buffer.concat([
            // @ts-ignore
            decipher.update(Buffer.from(hash, 'hex')),
            decipher.final()
        ]);
        return decrpyted.toString();
    } catch (e) {
        console.error(e);
        return '';
    }
};

export const getParsedLocationObject = (pathname: string, search?: string, publishedName?: string) => {
    // ON SSR the url has env variable which is not needed for computations of this method
    // eslint-disable-next-line no-param-reassign
    pathname = pathname.replace('/dev/', '/');
    if (publishedName) {
        // eslint-disable-next-line no-param-reassign
        pathname = pathname.replace(`/${ publishedName }`, '');
    }

    const initialResult = {
        projectId:    '',
        experienceId: '',
        viewId:       '',
        sectionId:    '',
        moleculeId:   '',
        currentUrl:   pathname,
        parentUrl:    '',
        config:       {}
    };

    const pathnameCollection = pathname.split('/').slice(1);
    const result = { ...initialResult };
    pathnameCollection.forEach((el, i) => {
        result.parentUrl = `/${ pathnameCollection.slice(0, i).join('/') }`;

        // eslint-disable-next-line default-case
        switch (i) {
            case 0:
                result.projectId = el;
                break;
            case 1:
                result.experienceId = el;
                break;
            case 2:
                result.viewId = el;
                break;
            case 3:
                result.sectionId = el;
                break;
            case 4:
                result.moleculeId = el;
                break;
        }
    });

    if (search) {
        try {
            const query = queryString.parse(window.location.search.slice(1));
            const config: {
                redirectUrl?: string
                scene_experience_type?: string
                sceneExperienceType?: string
                targetImages?: string[]
                customUI?: any
                buttonLabelHtml?: string
            } = query.config ? JSON.parse(decrypt(query.config as string)) : {};
            if (config.sceneExperienceType) {
                // Rename field locally in order to not break all custom projects after general field rename
                config.scene_experience_type = config.sceneExperienceType;
                delete config.sceneExperienceType;
            }
            result.config = config;
        } catch (e) {
            console.error('parse config error: ', e);
            result.config = {};
        }
    }

    return result;
};

// NOTE - build an object of hierarchy nodes from current URL location
const useComposerLocationParser = () => {
    const { pathname } = useLocation();
    const { AppState } = useInject();
    // Workaround with published name for the magellan publishing structure

    if (pathname) {
        return getParsedLocationObject(pathname, undefined, AppState.publishedName);
    }
    return {
        projectId:    '',
        experienceId: '',
        viewId:       '',
        sectionId:    '',
        moleculeId:   '',
        currentUrl:   '',
        parentUrl:    '',
        config:       {}
    };
};

// eslint-disable-next-line arca/no-default-export
export default useComposerLocationParser;
