import { useState }                            from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentMethod }                       from '@stripe/stripe-js';
import { ManageCards }                         from '@geenee/builder/src/components/modals/ManageCards';
import { useBuilderInject }                    from '@geenee/builder/src/core/hook/use-builder-inject';
import { stripeState }                         from '@geenee/builder/src/core/state/stripe.state';

export const usePaymentMethodHandlers = () => {
    const { BuilderState } = useBuilderInject();
    const email = BuilderState.currentUser.profile?.email;
    const elements = useElements();
    const stripe = useStripe();
    const [ waiting, setWaiting ] = useState<boolean>(false);

    const updateBillingDetails = async (
        paymentMethodId: string,
        cardName: string,
        // eslint-disable-next-line no-empty-pattern
        setModal: ({}) => void
    ) => {
        // const updatePaymentMethodDetails = fetchUpdatePaymentMethodDetails(
        //     dispatch
        // );

        // await updatePaymentMethodDetails(body);
        setModal({ component: ManageCards });
    };

    const handleCardUpdateSubmit = async (
        editingCard: boolean,
        paymentMethodId: string,
        cardName: string,
        // eslint-disable-next-line no-empty-pattern
        setModal: ({}) => void,
        showError: (message: string) => void
    ) => {
        setWaiting(true);
        /*
         * Only update billing details, like name,
         * paymentMethodId stays the same
         */
        if (!editingCard) {
            try {
                await updateBillingDetails(paymentMethodId, cardName, setModal);
                setWaiting(false);
            } catch (e: any) {
                setWaiting(false);
            }
            return;
        }

        /*
         * Create a new paymentMethod
         */
        const card = elements?.getElement(CardElement);
        if (!stripe || !card) {
            return;
        }
        const { error } = await stripe?.createPaymentMethod({
            type:            'card',
            card,
            billing_details: { name: cardName, email: email || '' }
        });

        if (error) {
            showError(error.message || '');
            setWaiting(false);
            throw error;
        }

        // const updatePaymentMethod = fetchUpdatePaymentMethod(dispatch);

        try {
            // await updatePaymentMethod(body);
            setModal({ component: ManageCards });
            showError('');
        } catch (err: any) {
            // @ts-ignore
            showError(err.message);
        }
        setWaiting(false);
    };

    const addNewCard = async (
        newCardName: string,
        showError: (message: string) => void
    ) => {
        setWaiting(true);
        const card = elements?.getElement(CardElement);
        if (!stripe || !card) {
            return;
        }
        const { error, paymentMethod } = (await stripe?.createPaymentMethod({
            type:            'card',
            card,
            billing_details: { name: newCardName, email: email || '' }
        })) || { error: undefined, paymentMethod: undefined };

        if (error) {
            setWaiting(false);
            showError(error.message || '');
            throw error;
        }
        setWaiting(false);
        showError('');
        return paymentMethod;
    };

    const makeCardDefault = async (paymentMethod?: PaymentMethod) => {
        try {
            setWaiting(true);
            await stripeState.addPaymentMethod(paymentMethod?.id);
            await stripeState.setDefaultPaymentMethod(paymentMethod?.id || '');
            setWaiting(false);
        } catch (err: any) {
            setWaiting(false);
            throw err;
        }
    };

    const addNewCardAndSetDefault = async (
        newCardName: string,
        showError: (message: string) => void
    ) => {
        const paymentMethod = await addNewCard(newCardName, showError);
        if (paymentMethod) {
            await makeCardDefault(paymentMethod);
        }
        return paymentMethod;
    };

    return {
        waiting,
        handleCardUpdateSubmit,
        addNewCardAndSetDefault,
        addNewCard
    };
};
