import React                       from 'react';
import { InputTableItem, Wrapper } from '@geenee/ui';
import { observer }                from 'mobx-react';
import { DropdownDisplayValue }    from "@geenee/builder/src/components/DropdownDisplayValue";

type Props = {
  value: string
  onSelect: (value: string) => void;
  filterData: {label: string, value: string }[]
  label: string
  disabled?: boolean
}

export const LibraryFilter = observer((props: Props) => (
    <InputTableItem
        marginOff
        labelWrapperProps={ { align: 'right' } }
        label={ props.label }
        weight="medium"
        viewType="secondary"
        size="sm"
        ratio={ 0.4 }
        style={ { width: 'fit-content' } }
        labelDescriptionProps={ { noWrap: true } }
    >
        <Wrapper style={ { width: '136px' } }>
            <DropdownDisplayValue
                disabled={ props.disabled }
                items={ props.filterData }
                value={ props.value }
                onSelect={ props.onSelect }
                itemsSize="xs"
                size="sm"
                defaultLabel="Default"
            />
        </Wrapper>
    </InputTableItem>
));
