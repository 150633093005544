import { isArSceneType } from "@geenee/geeclient-kit/src/lib/component/scene/util";
import {
    AttachmentModel,
    ProgramType,
    ProjectFactory,
    ProjectModel,
    SectionModel,
    ViewModel
} from '@geenee/shared';
import { SceneCommanderType }                from "@geenee/shared/src/commander/scene-commander";
import EventEmitter                          from "eventemitter3";
import { inject, injectable, postConstruct } from "inversify";
import {
    action,
    computed,
    extendObservable,
    IReactionDisposer,
    makeObservable,
    observable,
    ObservableMap,
    runInAction,
    set
} from "mobx";
import { AttachmentType }  from "../../../type/attachment.type";
import { BasicSchemaType } from "../../../type/shared.type";
import {
    BROWSER_MODE,
    CAMERA_ENVIRONMENT_MODE,
    CAMERA_FACE_MODE,
    PHONE_VIEW_CONTENT_TYPE,
    PHONE_VIEW_EXPERIENCE_TYPE,
    PHONE_VIEW_PROGRAM_TYPE
} from "../../util/constants";
import { getDynamicContent }       from "../../util/getDynamicContent";
import { getParsedLocationObject } from "../../util/useComposerLocationParser";
import { AttachmentFactory }       from "../factory/attachment.factory";
import { SceneFactory }            from "../factory/scene.factory";
import {
    SceneModelType,
    SceneSchemaType
} from "../renderer/r3f-renderer/r3f.renderer";
import { SectionFactory } from "../section/factory/section.factory";

export interface IRouteOptions {
    projectId?: string;
    experienceId?: string;
    viewId?: string;
    sectionId?: string;
    moleculeId?: string;
    location?: Location;
    config?: Record<string, any>;
    hostname?: string;
}

@injectable()
export class AppState {
    @observable projectId = "";
    @observable initialScreenLoaded = false;
    @inject('<SceneCommander>') commander: SceneCommanderType;

    @observable sceneRegistry: ObservableMap<string, SceneModelType> = new ObservableMap([]);
    // @ts-ignore
    @observable videoStreamType: CAMERA_ENVIRONMENT_MODE | CAMERA_FACE_MODE = CAMERA_ENVIRONMENT_MODE;

    @observable videoStream: HTMLVideoElement | null = null;

    @observable isWasmLoaded = false;

    @observable activeSceneId = "";

    @observable programsData!: ProgramType[] = [];

    @observable projectsRegistry: ObservableMap<string, ProjectModel> = new ObservableMap([]);

    @observable appState = "default";
    emitter = new EventEmitter();

    @observable rootProjectId = "";

    @observable options: IRouteOptions = {} as IRouteOptions;

    serverSidePath?: string;

    @observable magellanType: "full" | "experience" = "experience";

    @observable contentPreview: SectionModel | null = null;

    @observable publishedName = "";
    @observable publishingDomain = "";
    // Used to track current campaign in swiper on the programView
    @observable selectedCarouselCampaignId = "";

    @action
    setContentPreview(data: SectionModel | null) {
        this.contentPreview = data;
    }

    @inject("<ProjectFactory>")
        projectFactory!: ProjectFactory;
    @inject("<SectionFactory>")
        sectionFactory!: SectionFactory;
    @inject("<SceneFactory>")
        sceneFactory!: SceneFactory;
    @inject("<AttachmentFactory>")
        attachmentFactory!: AttachmentFactory;

    $autorun!: IReactionDisposer;

    constructor() {
        // makeObservable(this);
        const {
            projectId, experienceId, viewId, sectionId, moleculeId, config
        } = !BROWSER_MODE
            ? getParsedLocationObject(
                this.serverSidePath || "",
                "",
                this.publishedName
            )
            : getParsedLocationObject(
                window.location.pathname,
                window.location.search,
                this.publishedName
            );
        extendObservable(this.options, {
            location: BROWSER_MODE ? window.location : {},
            projectId,
            experienceId,
            viewId,
            moleculeId,
            sectionId,
            config
        });
    }

    @postConstruct()
    start() {
        /* const {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              programId,
              experienceId,
              sectionId,
              arAttachmentId
          } = !BROWSER_MODE
              ? getParsedLocationObject(this.serverSidePath || '', undefined, this.publishedName)
              : getParsedLocationObject(window.location.pathname, undefined, this.publishedName); */
    }

    @action setInitialScreenLoaded = () => {
        this.initialScreenLoaded = true;
    };

    @action onRouteChange = (location: Location) => {
        runInAction(() => {
            set(this.options, "location", location);
        });
        const {
            projectId, experienceId, viewId, sectionId, moleculeId, config
        } = !BROWSER_MODE
            ? getParsedLocationObject(
                this.serverSidePath || "",
                "",
                this.publishedName
            )
            : getParsedLocationObject(
                window.location.pathname,
                window.location.search,
                this.publishedName
            );

        this.updateOptions({
            projectId,
            experienceId,
            viewId,
            sectionId,
            moleculeId,
            config
        });
        return this.activeWorkingItem;
    };

    @computed get scenes(): SceneModelType[] {
        return Array.from(this.sceneRegistry.values()) || [];
    }

    @computed get activeSceneModel(): SceneModelType | undefined {
        return this.sceneRegistry.get(this.activeSceneId);
    }

    @action getOrientationPermission = (cb: () => any) => {
        // @ts-ignore
        if (DeviceOrientationEvent?.requestPermission !== undefined) {
            // @ts-ignore
            DeviceOrientationEvent.requestPermission().then((response) => {
                if (response === "granted") {
                    this.emit("geenee-slam-permission-granted");
                    cb();
                } else {
                    alert("Please Reload the page and ALLOW Device Orientation Control");
                    this.emit("geenee-camera-access-denied");
                    cb();
                }
            });
        } else {
            this.emit("geenee-slam-permission-granted");
        }
    };

    @action
    setActiveEnitites(entities: Record<string, any>) {
        Object.keys(entities).forEach((key) => {
            set(this.options, key, entities[ key ]);
        });
    }

    @action
    updateVideoStreamType(type: "environment" | "user") {
        this.videoStreamType = type;
    }

    @action
    setActiveScene(id: string) {
        this.activeSceneId = id;
    }

    @action
    setAppState(state: string) {
        this.appState = state;
    }

    @action setRootProjectId(value: string) {
        set(this.options, "projectId", value);
    }

    findSceneByType = (type: SceneSchemaType["scene_experience_type"]) => this.scenes.find((scene) => scene.scene_experience_type === type);

    @action
    setSlamSceneActive() {
        const scene = this.findSceneByType("slam-ar");
        if (scene) {
            this.setActiveScene(scene.id);
        }
    }

    @action
    setTrackingSceneActive() {
        const scene = this.findSceneByType("stamp-ar");
        if (scene) {
            this.setActiveScene(scene.id);
        }
    }

    @action
    setCustomSceneActive() {
        const scene = this.findSceneByType("custom");
        if (scene) {
            this.setActiveScene(scene.id);
        }
    }

    emit(eventName: string, data?: any) {
        console.log(eventName);
        this.emitter.emit(eventName, data || {});
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    @action init(scenesConfig: SceneSchemaType[], experienceOnly = true) {
        scenesConfig.forEach((sc, index) => {
            const model = this.sceneFactory.create(
                index === 0 ? { ...sc, ...this.options.config } : sc,
                this
            );

            this.sceneRegistry.set(model.id, model);
        });

        this.setActiveScene(scenesConfig[ 0 ].id);
    }

    @action
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async getProjects(rootProjectId: string) {
        throw new Error("Not Implemented");
    }

    @action
    setVideo(video: HTMLVideoElement) {
        if (video) {
            this.videoStream = video;
        }
    }

    @action
    updateOption<K extends keyof this["options"]>(
        key: K,
        value: this["options"]
    ) {
        runInAction(() => {
            set(this.options, key as string, value);
        });
    }

    @action
    updateOptions<K extends this["options"]>(options: K) {
        runInAction(() => {
            Object.keys(options).forEach((key) => {
                // @ts-ignore
                set(this.options, key, options[ key ]);
            });
        });
    }

    @action
    async setProject(projectSchema: any) {
        const project = await this.projectFactory.create(projectSchema, this);

        this.projectsRegistry.set(
            projectSchema.id,
            project
        );
    }

    @action
    async setProjects(
        projects: (BasicSchemaType & { type: string; parent_id: string })[]
    ) {
        const projectsSorted = projects.sort(
            (a, b) => a.options.order! - b.options.order!
        );

        // eslint-disable-next-line
        for (const p of projectsSorted) {
            // eslint-disable-next-line
            const project = await this.projectFactory.create(p, this);

            runInAction(() => {
                this.projectsRegistry.set(project.id, project);
            });
        }
    }

    getUrlParams() {
        if (BROWSER_MODE) {
            return getParsedLocationObject(
                window.location.pathname,
                undefined,
                this.publishedName
            );
        }
        return getParsedLocationObject(
            this.serverSidePath || "",
            undefined,
            this.publishedName
        );
    }

    @computed
    get activeExperience() {
        if (!this.activeProject) {
            return null;
        }
        if (this.options.experienceId) {
            return this.activeProject.experiencesRegistry.get(
                this.options.experienceId
            );
        }
        return Array.from(this.activeProject.experiencesRegistry.values()).sort(
            (a, b) => a.options.order - b.options.order
        )[ 0 ];
    }

    @computed
    get activeView(): ViewModel | undefined {
        if (!this.activeExperience || !this.activeProject) {
            return undefined;
        }
        if (this.options.viewId) {
            return this.activeExperience.viewsRegistry.get(this.options.viewId || "");
        }
        return Array.from(this.activeExperience.viewsRegistry.values()).sort(
            (a, b) => a.options.order - b.options.order
        )[ 0 ];
    }

    @computed
    get activeSection() {
        if (this.options.sectionId) {
            return this.activeView?.sectionsRegistry.get(
                this.options.sectionId || ""
            );
        }
        return Array.from(this.activeView?.sectionsRegistry?.values() || []).sort(
            (a, b) => a.options.order - b.options.order
        )[ 0 ];
    }

    @computed
    get arSection() {
        return Array.from(this.activeView?.sectionsRegistry?.values() || []).find(
            (section) => isArSceneType(section.type)
        );
    }

    @computed
    get firstAvailableSection() {
        const experience = this.activeExperience;
        if (experience) {
            const view = this.options.viewId
                ? experience.viewsRegistry.get(this.options.viewId)
                : Array.from(experience.viewsRegistry.values()).sort(
                    (a, b) => a.options.order! - b.options.order!
                )[ 0 ];
            if (view) {
                return this.options.sectionId
                    ? view.sectionsRegistry.get(this.options.sectionId)
                    : Array.from(view.sectionsRegistry.values()).sort(
                        (a, b) => a.options.order! - b.options.order!
                    )[ 0 ];
            }
        }
        return undefined;
    }

    @computed
    get activeMolecule() {
        const section = this.firstAvailableSection;
        if (this.options.moleculeId) {
            return section?.moleculesRegistry.get(this.options.moleculeId || "");
        }
        return section?.sortedChildren[ 0 ];
    }

    @computed
    get activeProject(): ProjectModel | undefined {
        return this.projectsRegistry.get(this.options.projectId || "");
    }

    @computed
    get activeAsset() {
        return this.getActiveAsset();
    }

    getActiveAsset(sectionModel?: SectionModel) {
        const _sectionModel = sectionModel
            || this.projectsRegistry
                .get(this.options.projectId || "")
                ?.experiencesRegistry.get(this.options.experienceId || "")
                ?.viewsRegistry.get(this.options.viewId || "")
                ?.sectionsRegistry.get(this.options.sectionId || "");
        if (!_sectionModel) return null;

        if (this.options.arAttachmentId) return _sectionModel.moleculesRegistry.get(this.options.arAttachmentId);

        const dynamicContentConfig = _sectionModel.options?.dynamic_content || {};
        if (dynamicContentConfig?.enabled) {
            const childAssets = Array.from(
                _sectionModel.moleculesRegistry.values() as IterableIterator<AttachmentModel>
            );

            return getDynamicContent(childAssets, dynamicContentConfig, true);
        }
        return Array.from(
            _sectionModel.moleculesRegistry.values() as IterableIterator<AttachmentModel>
        ).sort((a, b) => a.options.order! - b.options.order!)[ 0 ];
    }

    @computed
    get activeThumbnail() {
        const content = this.activeSection;
        const asset = this.activeAsset;
        if (content && asset && content.thumbnailAttachmentsRegistry) {
            return Array.from(content.thumbnailAttachmentsRegistry.values()).find(
                (el) => el.options.order === asset.options.order
            );
        }
        return null;
    }

    @computed
    get activeWorkingItem() {
        const { sectionId } = getParsedLocationObject(
            this.options?.location?.pathname || "",
            undefined,
            this.publishedName
        );
        if (this.options.arAttachmentId) {
            return this.activeAsset;
        }
        if (sectionId) {
            return this.activeSection;
        }
        if (this.options.viewId) {
            return this.activeView;
        }
        if (this.options.experienceId) {
            return this.activeExperience;
        }
        if (this.options.projectId) {
            return this.activeProject;
        }
    }

    @computed
    get childSections(): SectionModel[] {
        const project = this.activeProject;
        if (project) {
            const experience = project.activeExperience;
            if (experience) {
                const view = experience.activeView;
                if (view) {
                    return Array.from(
                        view.sectionsRegistry.values() as any as IterableIterator<SectionModel>
                    ).sort(
                        (a, b) => a.options.order! - b.options.order!
                    ) as SectionModel[];
                }
            }
        }
        return [];
    }

    // mobx router store
    @action updateActiveContent(newContent: Partial<SectionModel>) {
        // @ts-ignore
        if (newContent.type !== "program" && newContent.type !== "experience") {
            if (newContent.id) {
                set(this.options, "sectionId", newContent.id);
            }
            const activeSection = this.activeProject?.activeExperience?.activeSection;
            if (activeSection) {
                activeSection.updateOptions(newContent as any);
            }
        }
    }

    @action async updateCampaignsData(
        projects: (BasicSchemaType & { type: string; parent_id: string })[],
        annotations: ContentType[],
        attachments: AttachmentType[]
    ) {
        await this.setProjects(projects, annotations, attachments);
    }

    @action populateBatchChanges(composerWithAttachmentsState: any) {
        if (composerWithAttachmentsState) {
            this.updateActiveContent(composerWithAttachmentsState);
        }
    }

    @action setIsWasmLoaded = (loaded: boolean) => {
        this.isWasmLoaded = loaded;
    };

    setServerSidePath(path: string) {
        this.serverSidePath = path;
    }

    setHostname(value: string) {
        set(this.options, "hostname", value);
    }

    @computed
    get isPublishedMode() {
        return !this.options.hostname?.includes("preview.geenee");
    }

    getLocation() {
        return BROWSER_MODE
            ? window.location.pathname
            : this.setServerSidePath || "";
    }

    @computed
    get activeScreenType() {
        if (this.options.viewId) {
            return PHONE_VIEW_CONTENT_TYPE;
        }
        if (this.options.experienceId) {
            return PHONE_VIEW_EXPERIENCE_TYPE;
        }
        if (this.options.projectId) {
            return PHONE_VIEW_PROGRAM_TYPE;
        }
        return null;
    }

    @computed
    get treeToJson() {
        return Array.from(this.projectsRegistry.values()).map(
            (el) => el.toJsonObject
        );
    }

    @action
    setPublishedName(value = "") {
        this.publishedName = value;
    }

    @action
    setPublishingDomain(value = "") {
        this.publishingDomain = value;
    }
}

export type AppStateType = AppState;
