import * as React                from "react";
import { Navigate, useLocation } from "react-router";
import { useHistory }            from "@geenee/common";
import { observer }              from "mobx-react";
import { useBuilderInject }      from "@geenee/builder/src/core/hook/use-builder-inject";
import { checkSketchfabToken }   from "@geenee/builder/src/lib/checkSketchfabToken";

export const getNextRoute = (next: string) => {
    if (next === "/logout") {
    // eslint-disable-next-line no-param-reassign
        next = "/";
    }

    return `/login?next=${ encodeURIComponent(next) }`;
};

const withAuthorization = (TargetComponent: any) => observer((props: any) => {
    const { BuilderState } = useBuilderInject();
    const isAuth = BuilderState.isAuthenticated;

    const { pathname } = useLocation();
    const history = useHistory();
    // const queryData = checkQueryToken(dispatch, history);
    checkSketchfabToken(history);

    if (!isAuth) {
        const to = getNextRoute(pathname);

        return (
            <div>
                <Navigate to={ to } from={ pathname } />
            </div>
        );
    }
    return (<TargetComponent { ...props } />);
});

// eslint-disable-next-line arca/no-default-export
export default withAuthorization;
