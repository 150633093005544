import styled from 'styled-components';

export const LoadingWrapper = styled.div`
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    border-radius: 4px;
    pointer-events: none;
    z-index: 3;
    padding-bottom: 5px;
`;

export const UploadIconWrapper = styled.div`
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  &:hover {
    border: 1px solid${ ({ theme: { secondaryColors } }) => secondaryColors.pink10 };
    border-radius: 100%;
  }
  
  svg {
    cursor: pointer;
  }
`;

export const LogoLoadingWrapper = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
`;

export const CampaignImageWrapperContainer = styled.div`
  position: relative;
  height: 50%;
  margin-bottom: 2vw;
  flex-shrink: 0;
  .campaign_image_wrapper {
    height: 100%;
  }
`;
