// export type MediaPropsType ={
//     hideFrom?: 'xsm' | 'sm' | 'md' | 'lg' | 'xlg';
//     hideTo?: 'xsm' | 'sm' | 'md' | 'lg' | 'xlg';
//     columnTo?: 'xsm' | 'sm' | 'md' | 'lg' | 'xlg';
// }
export type MediaPropsType =[ '' | 'xsm' | 'sm' | 'md' | 'lg' | 'xlg', '' | 'xsm' | 'sm' | 'md' | 'lg' | 'xlg' | undefined]

export const externalClasses = (props: {
    className?: string;
    media?: MediaPropsType;
}) => {
    let cn = '';
    if (props.className) {
        cn += props.className;
    }
    // if (props.media) {
    //     const hideFrom = props.media.hideFrom ? props.media.hideFrom : 'zero';
    //     const hideTo = props.media.hideTo ? props.media.hideTo : 'infinite';
    //     const columnTo = props.media.columnTo ? props.media.columnTo : 'infinite';
    //     cn += ` one-ui-media--hide-${ hideFrom }-${ hideTo }`;
    //     cn += ` one-ui-media--column-${ columnTo }`;
    // }
    if (props.media) {
        const hideFrom = props.media[ 0 ] ? props.media[ 0 ] : 'zero';
        const hideTo = props.media[ 1 ] ? props.media[ 1 ] : 'infinite';
        cn += ` one-ui-media--hide-${ hideFrom }-${ hideTo }`;
    }
    return cn;
};
