import React, { PropsWithChildren,
  useCallback, useEffect, useState } from 'react';
import Scrollbars                                  from 'react-custom-scrollbars';
import { useMediaQuery }                           from 'react-responsive';
import { useHistory }                              from "@geenee/common";
import { Analytics }                               from '@geenee/analytics';
import {
    ExperienceModel,
    useInject,
    ViewModel
} from '@geenee/shared';
import useComposerLocationParser, { getParsedLocationObject } from '@geenee/shared/src/util/useComposerLocationParser';
import { Emptable, EntityOptions }                            from '@geenee/shared/type/shared.type';
import { Wrapper }                                            from '@geenee/ui';
import { observer }                                           from 'mobx-react';
import { v4 as uuid }                                         from 'uuid';
import { Logo }                                               from '@geenee/builder/src/components/Logo';
import { Footer }                                             from '@geenee/builder/src/components/Tree/Footer';
import { HomeNode }                                           from '@geenee/builder/src/components/Tree/HomeNode';
import { PanelToggler }                                       from '@geenee/builder/src/components/Tree/PanelToggler';
import { useBuilderInject }                                   from '@geenee/builder/src/core/hook/use-builder-inject';
import {
    CAROUSEL_CONTENT_TYPES,
    NODE_TYPE_EXPERIENCE,
    NODE_TYPE_FOLDER,
    NODE_TYPE_PROJECT,
    NODE_TYPE_VIEW,
    PROGRAM_RENAMED
} from '@geenee/builder/src/lib/constants';
import envConfig         from '@geenee/builder/src/lib/envConfig';
import { MoleculeModel } from '@geenee/builder/src/magellan/model/molecule.model';
import { ProjectModel }  from '@geenee/builder/src/magellan/model/project.model';
import { SectionModel }  from '@geenee/builder/src/magellan/model/section.model';
import { MagellanState } from '@geenee/builder/src/magellan/state/app.state';
import { ListItemProps } from '../DropWrapper';
import { DropZone, FolderNode }                       from "./FolderNode/index.component";
import { Adder }         from './Adder';
import {
    Container,
    ContentRoot,
    NodesWrapper,
    Root
}                                                       from './styles';
import { ElementType, TreeNode } from './TreeNode';

export const Tree = observer(() => {
    const { AppState, container } = useInject();
    const { BuilderState } = useBuilderInject();
    const analytics: Analytics = container.get('<Analytics>');

    const history = useHistory();
    const {
        projectId, experienceId, viewId, sectionId, moleculeId
    } =        useComposerLocationParser();

    const [ openedNodes, setOpenedNodes ] = useState<Set<string>>(
        new Set(
            [ projectId, experienceId, viewId, sectionId, moleculeId ].filter(
                (el) => el
            )
        )
    );
    const isMobile = useMediaQuery({ query: '(max-width: 1200px)' });
    const [ panelOpened, setPanelOpened ] = useState(!isMobile);
    const [ listItems, setListItems ] = useState<ElementType[]>([]);
    const [ foldersList, setFoldersList ] = useState<
        { id: string; title: string; children: string[] }[]
    >([]);
    const [ rootList, setRootList ] = useState<ElementType[]>([]);

    const [ willBeDeletedItems, setWillBeDeletedItems ] = useState<Set<string>>(
        new Set()
    );
    const [ willBeDeletedFolders, setWillBeDeletedFolders ] = useState<
        Set<string>
    >(new Set());
    const [ loadingItemsIds, setLoadingItemsIds ] = useState<Set<string>>(
        new Set()
    );

    useEffect(() => {
        setPanelOpened(!isMobile);
    }, [ isMobile ]);

    // Create right hierarchy model to build nodes from it
    useEffect(() => {
        const childReturner = (currentElement: any, parentPath?: string) => {
            const {
                id,
                type,
                title,
                published,
                nft_published,
                options,
                $parent
            } = currentElement;

            // NOTE - build propper path according to hierarchy of nesting
            const currentPath = parentPath ? `${ parentPath }/${ id }` : `/${ id }`;
            // NOTE - set propper object keys needed to iterate thru Tree hierarchy
            const rightTreeElementObject = {
                type,
                id,
                path:      currentPath,
                title,
                published,
                nft_published,
                parent_id: $parent.id,
                options:   { path: options.path },
                $parent
            };

            let children: (
                | ExperienceModel
                | SectionModel
                | {
                      type: string;
                      id: string;
                      path: string;
                      menu_title: string;
                      options: Emptable<EntityOptions>;
                  }
            )[] = [];

            if (type === NODE_TYPE_PROJECT) {
                const el = currentElement as ProjectModel;
                children = Array.from(
                    el.experiencesRegistry.values()
                ) as ExperienceModel[];
            } else if (type === NODE_TYPE_VIEW) {
                const el = currentElement as ViewModel;
                children = Array.from(
                    el.sectionsRegistry.values()
                ) as SectionModel[];
            } else if (type === NODE_TYPE_EXPERIENCE) {
                const el = currentElement as ExperienceModel;
                children = Array.from(el.viewsRegistry.values()) as ViewModel[];
            } else if (
                $parent.type === NODE_TYPE_VIEW
                && CAROUSEL_CONTENT_TYPES.includes(type)
            ) {
                const el = currentElement as SectionModel;
                children = Array.from(
                    el.moleculesRegistry.values()
                ) as MoleculeModel[];
            }

            children = children.sort(
                (a, b) => (a.options.order || 0) - (b.options.order || 0)
            );

            if (children.length === 0) {
                return rightTreeElementObject;
            }

            const treeChildren: ElementType[] = children.map((c) => childReturner(c, currentPath));
            return { ...rightTreeElementObject, ...{ children: treeChildren } };
        };

        const initTreeRoot = Array.from(
            // @ts-ignore
            AppState.projectsRegistry.values()
            // @ts-ignore
        ).sort((a, b) => a.options.order! - b.options.order!);

        // TODO: Not build the tree object. Use Mobx structure(Proxies) instead. Ask Alex, Kirill id anything
        const tree = initTreeRoot.map((t) => childReturner(t));

        setListItems(tree);

        // @ts-ignore
    }, [ AppState.lastUpdate ]);

    useEffect(() => {
        const list = BuilderState.foldersController.foldersTree.map(
            (folder) => ({
                ...folder,
                type: NODE_TYPE_FOLDER
            })
        );

        const sortedAlphabetically = list.sort((a, b) => a.title.localeCompare(b.title));
        const rootItems = listItems.filter(
            (p) => !p.options?.path || p.options?.path === '/'
        );

        setFoldersList(sortedAlphabetically);
        setRootList(rootItems);
    }, [ BuilderState.foldersController.foldersTree, listItems ]);

    // Show currently opened tree node hierarchy on delete or create action
    useEffect(() => {
        setOpenedNodes(
            new Set(
                [
                    ...openedNodes.values(),
                    projectId,
                    experienceId,
                    viewId,
                    sectionId,
                    moleculeId
                ].filter((el) => el)
            )
        );
    }, [ projectId, experienceId, viewId, sectionId, moleculeId ]);

    const moveItem = useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = rootList[ dragIndex ];
            const modifiedItemsSet = rootList;

            if (!dragCard) return;

            modifiedItemsSet.splice(dragIndex, 1);
            modifiedItemsSet.splice(hoverIndex, 0, dragCard);

            setRootList([ ...modifiedItemsSet ]);
        },
        [ rootList ]
    );

    const updateProgramsOrder = useCallback(() => {
        const newListItemsArray = rootList.map(
            (item: ListItemProps, i: number) => {
                if (!item.id) {
                    return {
                        order:    i,
                        oldOrder: item.order
                    };
                }
                return { id: item.id, order: i, oldOrder: item.order };
            }
        );
        newListItemsArray.forEach((el: { id: any; order: any }) => {
            // @ts-ignore
            const program = AppState.projectsRegistry.get(el.id);
            if (program) {
                program.options.order = el.order;
                // @ts-ignore
                program.saveData();
            }
        });
    }, [ rootList ]);
    const onDeleteItem = (id: string) => {
        // @ts-ignore
        const programItem = AppState.projectsRegistry.get(id);
        const actualUrlParams = getParsedLocationObject(
            history.location.pathname
        );

        if (programItem) {
            (AppState as MagellanState).deleteChild(programItem.id);
            willBeDeletedItems.delete(id);
            if (id === actualUrlParams.projectId) {
                history.push('/getting-started');
            }
        }
    };

    const onTitleChange = async (id: string, value: string) => {
        // @ts-ignore
        const programItem = AppState.projectsRegistry.get(id);
        if (programItem) {
            programItem.title = value;
            // @ts-ignore
            await programItem.saveData();
            analytics.track(PROGRAM_RENAMED, { title: value });
        }
    };

    const onFolderChange = async (project: ElementType, folderId = '', isRename = false) => {
        // @ts-ignore
        const projectModel = AppState.projectsRegistry.get(
            project.id
        ) as ProjectModel;

        try {
            !isRename && await BuilderState.foldersController.moveObjectToFolder(
                projectModel,
                folderId
            );

            projectModel.options.path = `/${ folderId }`;
            await projectModel.saveData();
        } catch (e) {
            console.log(e);
        }
    };

    const hideNft = envConfig.options && envConfig.options.hideNft;

    const onCollapsedChange = async (
        id: string,
        propvalue: boolean,
        type?: string,
        historyPath?: string
    ) => {
        const value = type === NODE_TYPE_VIEW ? openedNodes.has(id) : propvalue;
        if (!value) {
            if (type === NODE_TYPE_PROJECT) {
                const activeProject = AppState.projectsRegistry.get(
                    id
                ) as ProjectModel;
                if (activeProject && !activeProject.expandedDataRequested) {
                    loadingItemsIds.add(id);
                    setLoadingItemsIds(new Set(loadingItemsIds));
                    await activeProject.openNode();
                    loadingItemsIds.delete(id);
                    setLoadingItemsIds(new Set(loadingItemsIds));
                }
            }
            if (!openedNodes.has(id)) {
                openedNodes.add(id);
                setOpenedNodes(new Set(openedNodes));
            }
        } else {
            openedNodes.delete(id);
            setOpenedNodes(new Set(openedNodes));
        }
        if (historyPath && type !== NODE_TYPE_VIEW) {
            history.push(historyPath);
        }
    };

    const onCollapsedChangeHandler = async (
        id,
        value,
        type: string,
        historyPath?: string
    ) => {
        await onCollapsedChange(id, value, type, historyPath);
    };

    const onAddWillBeDeletedItem = (value) => {
        willBeDeletedItems.add(value);
        setWillBeDeletedItems(new Set(willBeDeletedItems));
    };

    const onAddWillBeDeletedFolder = (value) => {
        willBeDeletedFolders.add(value);
        setWillBeDeletedFolders(new Set(willBeDeletedFolders));
    };

    useEffect(() => {
        const item = AppState.activeProject;
        if (item) {
            onCollapsedChange(item.id, false, item.type);
        }
    }, [ AppState.activeProject ]);

    const renderTreeNode = (
        item: ElementType,
        i: number,
        isFolderChildren = false,
        lastNode = false,
        list: ElementType[] = listItems,
        setList: (value: ElementType[]) => void = setListItems
    ) => {
        const projectModel = AppState.projectsRegistry.get(item.id);

        let hiddenExperienceChildren;

        if (projectModel) {
            hiddenExperienceChildren = projectModel.children;
        }

        const treeChildren =            hiddenExperienceChildren
            || (projectModel || { children: undefined }).children;

        return (
            <TreeNode
                isLoading={ loadingItemsIds.has(item.id) }
                triggerTreeUpdate={ () => setList([ ...list ]) }
                moveItem={ moveItem }
                updateProgramsOrder={ updateProgramsOrder }
                index={ i }
                node={ item as ElementType }
                key={ item.id }
                /* eslint-disable-next-line no-shadow */
                lastNode={ lastNode }
                isFolderChildren={ isFolderChildren }
                openedNodes={ openedNodes }
                onCollapsedChange={ onCollapsedChangeHandler }
                depth={ 0 }
                // @ts-ignore
                treeChildren={ treeChildren }
                onAddWillBeDeletedItem={ onAddWillBeDeletedItem }
                willBeDeletedItems={ willBeDeletedItems }
                onDeleteItem={ onDeleteItem }
                onTitleChange={ onTitleChange }
                hideMoveIcon={ listItems.length < 2 }
            />
        );
    };

    return (
        <Root opened={ panelOpened }>
            <ContentRoot>
                <div style={ { overflow: 'hidden', height: '100%' } }>
                    <Wrapper row align="space-between">
                        <Logo />
                        <Adder
                            onAdd={ () => {
                                if (isMobile) {
                                    setPanelOpened(false);
                                }
                            } }
                        />
                    </Wrapper>
                    <Container>
                        <Scrollbars
                            autoHide
                            autoHideTimeout={ 500 }
                            autoHideDuration={ 200 }
                        >
                            <HomeNode />
                            <NodesWrapper id="nav_tree">
                                { foldersList.map((item, i: number) => {
                                    const folderItems = listItems.filter(
                                        (p) => p.options?.path === `/${ item.id }`
                                    );

                                    return (
                                        <FolderNode
                                            index={ i }
                                            key={ item.id }
                                            id={ item.id }
                                            title={ item.title }
                                            onFolderChange={ onFolderChange }
                                            onAddWillBeDeleted={
                                                onAddWillBeDeletedFolder
                                            }
                                            willBeDeleted={ willBeDeletedFolders }
                                            moveItem={ moveItem }
                                            updateProgramsOrder={
                                                updateProgramsOrder
                                            }
                                            openedNodes={ openedNodes }
                                        >
                                            { folderItems.map((el, it, l) => renderTreeNode(
                                                el,
                                                it,
                                                true,
                                                it === l.length - 1,
                                                l
                                            )) }
                                        </FolderNode>
                                    );
                                }) }
                                <DropZone onDrop={ (object) => {
                                    if (object.options.path && object.options.path !== '/') {
                                        onFolderChange(object);
                                    }
                                } }
                                >
                                    { rootList.map((item, i, l) => renderTreeNode(
                                        item,
                                        i,
                                        false,
                                        i === l.length - 1,
                                        l,
                                        setRootList
                                    )) }
                                </DropZone>
                            </NodesWrapper>
                        </Scrollbars>
                    </Container>
                </div>
                <Footer
                    closeSideBar={ () => setPanelOpened(false) }
                    hideNft={ hideNft }
                />
            </ContentRoot>
            <PanelToggler
                onTogglePanel={ () => setPanelOpened(!panelOpened) }
                toggle={ panelOpened }
            />
        </Root>
    );
});
