import React, { useContext, useState }   from 'react';
import { Analytics }                     from '@geenee/analytics';
import { Description, Table, Wrapper }   from '@geenee/ui';
import { observer }                      from 'mobx-react';
import { HeaderTitle }                   from "@geenee/builder/src/components/HeaderTitle";
import { useBuilderInject }              from '@geenee/builder/src/core/hook/use-builder-inject';
import { stripeState }                   from "@geenee/builder/src/core/state/stripe.state";
import { MONTHLY_YEARLY_TOGGLE_TOGGLED } from '@geenee/builder/src/lib/constants';
import ModalUpdaterContext               from "@geenee/builder/src/lib/ModalUpdaterContext";
import { useHandlers }                   from "@geenee/builder/src/pages/AllPlansPage/useHandlers";
import { useTableData }                  from './useTableData';

export const planNames = [ 'Basic', 'Premium', 'Enterprise' ];

export interface TableInfo {
    [key: string]: { [key: string]: { id: string; price: string; parent_plan_id?: string, originalPrice: number } };
}

export const AllPlansPage = observer(() => {
    const [ yearlyCycle, setYearlyCycle ] = useState(!!(stripeState.plan_period === 'year' && stripeState.plan_title !== 'Hobbyist'));

    const currentInterval = yearlyCycle ? 'year' : 'month';
    const setModalOptions = useContext(ModalUpdaterContext);

    const { showPaymentModal } = useHandlers(currentInterval, setModalOptions);
    const { container } = useBuilderInject();

    const analytics: Analytics = container.get('<Analytics>');

    const [ data, columns ] = useTableData(
        stripeState,
        yearlyCycle,
        currentInterval,
        (val) => {
            setYearlyCycle(val);
            analytics.track(MONTHLY_YEARLY_TOGGLE_TOGGLED, { isLookingYearly: val });
        },
        showPaymentModal,
        setModalOptions
    );

    return (
        <Wrapper fullWidth overflow="auto" padding='xxl' align="center">
            <Wrapper maxWidth='1022px' margin="sm">
                <HeaderTitle title="Billing options" />
            </Wrapper>
            <Table
                frame='solid-default'
                padding='sm'
                marginHeader='md'
                maxWidth='fit-content'
                data={ data }
                columns={ columns }
            />
            <Wrapper maxWidth='1022px' padding='sm'>
                <Description align='right'>* Coming soon to the Builder (available with SDK)</Description>
            </Wrapper>
        </Wrapper>
    );
});
