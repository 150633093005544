import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '40',
    height = '40',
    fill = 'black'
}: SvgIconProps) {
    return (
        <svg width={ width } height={ height } viewBox="0 0 40 40" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M28.3334 33.3335H33.3334C34.2538 33.3335 35 32.5873 35 31.6668V15.0002C35 14.0797 34.2538 13.3335 33.3334 13.3335H28.3334V33.3335ZM25 13.3335V33.3335H15V13.3335H25ZM28.3334 10.0002H33.3334C36.0948 10.0002 38.3334 12.2387 38.3334 15.0002V31.6668C38.3334 34.4283 36.0948 36.6668 33.3334 36.6668H26.6667H13.3334H6.66669C3.90526 36.6668 1.66669 34.4283 1.66669 31.6668V15.0002C1.66669 12.2387 3.90526 10.0002 6.66669 10.0002H11.6667V8.3335C11.6667 5.57207 13.9053 3.3335 16.6667 3.3335H23.3334C26.0948 3.3335 28.3334 5.57207 28.3334 8.3335V10.0002ZM15 10.0002V8.3335C15 7.41302 15.7462 6.66683 16.6667 6.66683H23.3334C24.2538 6.66683 25 7.41302 25 8.3335V10.0002H15ZM11.6667 13.3335H6.66669C5.74621 13.3335 5.00002 14.0797 5.00002 15.0002V31.6668C5.00002 32.5873 5.74621 33.3335 6.66669 33.3335H11.6667V13.3335Z"
                fill="black"
            />
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="3" width="38" height="34">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    // eslint-disable-next-line max-len
                    d="M28.3334 33.3335H33.3334C34.2538 33.3335 35 32.5873 35 31.6668V15.0002C35 14.0797 34.2538 13.3335 33.3334 13.3335H28.3334V33.3335ZM25 13.3335V33.3335H15V13.3335H25ZM28.3334 10.0002H33.3334C36.0948 10.0002 38.3334 12.2387 38.3334 15.0002V31.6668C38.3334 34.4283 36.0948 36.6668 33.3334 36.6668H26.6667H13.3334H6.66669C3.90526 36.6668 1.66669 34.4283 1.66669 31.6668V15.0002C1.66669 12.2387 3.90526 10.0002 6.66669 10.0002H11.6667V8.3335C11.6667 5.57207 13.9053 3.3335 16.6667 3.3335H23.3334C26.0948 3.3335 28.3334 5.57207 28.3334 8.3335V10.0002ZM15 10.0002V8.3335C15 7.41302 15.7462 6.66683 16.6667 6.66683H23.3334C24.2538 6.66683 25 7.41302 25 8.3335V10.0002H15ZM11.6667 13.3335H6.66669C5.74621 13.3335 5.00002 14.0797 5.00002 15.0002V31.6668C5.00002 32.5873 5.74621 33.3335 6.66669 33.3335H11.6667V13.3335Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0)">
                <rect width="40" height="40" fill={ fill } />
            </g>
        </svg>
    );
}
