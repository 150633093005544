import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '42',
    height = '42',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 42 42"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d)">
                <circle
                    cx="21"
                    cy="20"
                    r="19"
                    fill="#B1FF9D"
                    stroke="white"
                    strokeWidth="2"
                />
                <path
                // eslint-disable-next-line max-len
                    d="M20.4353 27.446C19.218 27.446 18.0667 27.292 16.9813 26.984C15.896 26.676 14.9427 26.2287 14.1213 25.642L15.4193 23.178C16.2113 23.7207 17.0253 24.124 17.8613 24.388C18.712 24.6374 19.614 24.762 20.5673 24.762C21.55 24.762 22.2907 24.6154 22.7893 24.322C23.3027 24.0287 23.5593 23.6034 23.5593 23.046C23.5593 22.5034 23.2953 22.0854 22.7673 21.792C22.2393 21.4987 21.4253 21.1907 20.3253 20.868C19.2253 20.5454 18.3307 20.23 17.6413 19.922C16.9667 19.614 16.38 19.1667 15.8813 18.58C15.3827 17.9787 15.1333 17.194 15.1333 16.226C15.1333 15.258 15.3973 14.4074 15.9253 13.674C16.468 12.9407 17.2233 12.376 18.1913 11.98C19.174 11.584 20.318 11.386 21.6233 11.386C22.7527 11.386 23.8233 11.5474 24.8353 11.87C25.8473 12.1927 26.6687 12.6327 27.2993 13.19L26.0233 15.654C25.3633 15.1407 24.6447 14.752 23.8673 14.488C23.09 14.2094 22.3127 14.07 21.5353 14.07C20.5967 14.07 19.8633 14.2314 19.3353 14.554C18.8073 14.8767 18.5433 15.3314 18.5433 15.918C18.5433 16.314 18.6827 16.6514 18.9613 16.93C19.2547 17.194 19.6213 17.414 20.0613 17.59C20.5013 17.7514 21.11 17.9494 21.8873 18.184C22.958 18.492 23.8307 18.8 24.5053 19.108C25.1947 19.4014 25.7813 19.8414 26.2653 20.428C26.7493 21 26.9913 21.748 26.9913 22.672C26.9913 24.1827 26.412 25.356 25.2533 26.192C24.1093 27.028 22.5033 27.446 20.4353 27.446Z"
                    fill="white"
                />
                <line
                    x1="20.5408"
                    y1="8.04102"
                    x2="20.5408"
                    y2="31.3673"
                    stroke="white"
                    strokeWidth="1.4"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d"
                    x="0"
                    y="0"
                    width="42"
                    height="42"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.516667 0 0 0 0 0.516667 0 0 0 0 0.516667 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}
