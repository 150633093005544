export function formatTime(num: number | string): string {
    return `0${ num }`.slice(-2);
}

export function isValidInput(element: HTMLInputElement) {
    return element.tagName === 'INPUT' && element.type === 'number';
}

export function focus(element?: HTMLInputElement) {
    if (element) {
        element.focus();
    }
}

export function findNextElement(element: HTMLInputElement, property: string) {
    let nextElement = element;
    do {
        // @ts-ignore
        nextElement = nextElement[ property ];
    } while (nextElement && !isValidInput(nextElement));
    return nextElement;
}
