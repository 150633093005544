import React, { useEffect }         from 'react';
import { Scrollbars }               from "react-custom-scrollbars";
import { useHistory }               from "@geenee/common";
import { Button, Divider, Wrapper } from '@geenee/ui';
import { observer }                 from 'mobx-react';
import { HeaderTitle }              from "@geenee/builder/src/components/HeaderTitle";
import { useBuilderInject }         from '@geenee/builder/src/core/hook/use-builder-inject';
import { stripeState }              from '@geenee/builder/src/core/state/stripe.state';
import { teamState }                from '@geenee/builder/src/core/state/team.state';
import { TeamPageContent }          from "@geenee/builder/src/pages/TeamPage/components/TeamPageContent";

export const TeamPage = observer(() => {
    const { BuilderState } = useBuilderInject();
    const history = useHistory();
    const { plan_title } = stripeState;

    useEffect(() => {
        if (stripeState.isPremium || stripeState.isHobbyist) {
            history.push('/');
        }
    }, [ plan_title ]);

    useEffect(() => {
        teamState.fetchInvites();
        teamState.fetchTeamMembers((BuilderState.currentUser.profile || { email: '' }).email || '');
    }, []);

    const maxMembersPerPlan = stripeState.plan_params.seats - 1;
    const team = Array.from(teamState.userStore.values());
    const { invites } = teamState;

    const canAddMember = team.length + invites.length < maxMembersPerPlan;
    return (
        <Scrollbars
            autoHide
            autoHideTimeout={ 500 }
            autoHideDuration={ 200 }
        >
            <Wrapper
                fullHeight
                overflow='scroll'
                align="center"
                padding="xxl"
            >
                <Wrapper fullHeight maxWidth="906px">
                    <HeaderTitle
                        title="Team Members"
                    />
                    <Wrapper fullHeight>
                        <Divider transparent margin="md" />
                        { canAddMember ? (
                            <Wrapper
                                maxWidth="120px"
                                margin="lg"
                            >
                                <Button
                                    icon="add"
                                    iconAfter
                                    iconColor="white"
                                    iconSize={ 12 }
                                    iconFloat="right"
                                    iconMargin="off"
                                    size="qmd"
                                    fullWidth
                                    fullWidthContent
                                    uppercase
                                    onClick={ () => history.push('/team/new') }
                                >
                                    Add user
                                </Button>
                            </Wrapper>
                        ) : <></> }
                        <TeamPageContent />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Scrollbars>

    );
});
