import React                                from 'react';
import { FormItem, InputTableItem, Select } from '@geenee/ui';

const typeOptions = [
    { value: 'web', label: 'Web Link' },
    { value: 'content', label: 'Content Link' }
];

interface Props {
  // eslint-disable-next-line react/require-default-props
  button_link_type?: string;
  handleSetLinkType: (value: string) => void;
}

export function ButtonTypeSelector({ button_link_type, handleSetLinkType }: Props) {
    return (
        <InputTableItem label='Link'>
            <FormItem
                autoFocusDetect
            >
                <Select
                    size='md'
                    placeholder={ (button_link_type && button_link_type === 'web' ? 'Web Link' : 'Content Link') || 'Choose Link Type' }
                    value={ typeOptions.find((item) => item.value === button_link_type) }
                    options={ typeOptions }
                    onChange={ ({ value }) => handleSetLinkType(value as string) }
                />
            </FormItem>
        </InputTableItem>
    );
}
