import React, { useEffect, useMemo }                      from 'react';
import { TOAST_ERROR, UPLOADER_ALLOWED_IMAGE_EXTENSIONS } from "@geenee/builder/src/lib/constants";
import { CollapsedTreeItem }                              from "@geenee/geeclient-kit/src/lib/component/scene/components/CollapsedTreeItem";
import Uploader                                           from "@geenee/geeclient-kit/src/lib/component/scene/components/Uploader";
import { AddTrackingImage }                               from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/commands/AddTrackingImage";
import { useInject }                                      from "@geenee/shared/src/hook/use-inject.hook";
import { Description, Icon }                              from '@geenee/ui';
import { observer }                                       from "mobx-react";
import { ActionsList }                                    from "./LayersChevronPanel/Actions";
import { AssetTitle }                                     from "./LayersChevronPanel/AssetTitle";

export const TrackerImageSectionComponent = observer(() => {
    const { AppState, BuilderState } = useInject();
    const trackerAtom = AppState.activeMolecule?.sceneTriggerAtom;
    const popover = BuilderState?.activePopover;

    useEffect(() => {
        popover?.eventEmitter?.addListener('replace-tracker-image', (id: string) => {
            if (id === trackerAtom?.id) {
                const uploader = document.getElementById("tracker-image-uploader");
                if (uploader) {
                    uploader.click();
                }
            }
        });
    }, [ popover ]);

    const icon = useMemo(() => <Icon margin="xxs" stroke="fat" color="shade-4" name="image" />, []);
    useEffect(() => {
        trackerAtom?.setSceneObjectVisible(true);
    }, []);
    return trackerAtom?.assetUrl ? (
        <>
            <CollapsedTreeItem
                isSelected={ false }
                icon={ icon }
                content={ (
                    <Description uppercase color="shade-2" size="sm">Tracking Image</Description>
                ) }
                offset={ 1 }
            >
                <CollapsedTreeItem
                    isSelected={ false }
                    icon={ icon }
                    content={ (
                        <AssetTitle
                            value={ trackerAtom?.assetUrl.split('/').pop() || '' }
                            after={ (
                                <ActionsList
                                    popoverSchema="trackerImage"
                                    enableVisibleEye
                                    currentModel={ trackerAtom }
                                />
                            ) }
                        />
                    ) }
                    offset={ 2 }
                />
            </CollapsedTreeItem>
            <Uploader
                hideUploadText
                name="tracker-image-uploader"
                handleUpload={ async (value) => {
                    const renameCommand = new AddTrackingImage(AppState.activeMolecule, Array.isArray(value) ? value[ 0 ] : value);
                    AppState.commander.executeCommand(renameCommand);
                } }
                errorCallback={ (type) => {
                    BuilderState.toast = {
                        severity: TOAST_ERROR,
                        detail:   type === 'size' ? `File size should be 2MB` : 'Only png, jpg, jpeg, gif files can be uploaded',
                        summary:  type === 'size' ? 'Invalid file size' : 'Invalid file format'
                    };
                } }
                accept={ UPLOADER_ALLOWED_IMAGE_EXTENSIONS }
                size={ 2 }
            />
        </>
    ) : (
        <></>
    );
});
