import * as React           from "react";
import { Button, Icon }     from '@geenee/ui';
import { v4 as uuid }       from "uuid";
import { useBuilderInject } from "~/core/hook/use-builder-inject";

export const AddFolder = () => {
    const { BuilderState } = useBuilderInject();

    const addFolder = async () => {
        const id = uuid();

        await BuilderState.foldersController.createFolder(`New folder_${ id }`);
    };

    return (
        <Button
            className="add-folder"
            viewType="white"
            radius="sm"
            size="xs"
            shadow="xs"
            onClick={ addFolder }
            style={ {
                padding:     2,
                marginRight: 24,
                width:       "20px",
                height:      "20px",
                minHeight:   "20px"
            } }
        >
            <Icon name="addFolder" size={ 16 } />
        </Button>
    );
};
