import { NavigationListAtomOptionsType } from '@geenee/shared/type/atom.type';
import { LinkAtomOptType }               from '../../../type/_atom-option-type';

export const initProps: NavigationListAtomOptionsType = {
    navigation_atom_text:     'menu',
    navigation_atom_color_bg: 'inherit',
    navigation_icon_geenee:   'arrowDown',
    navigation_text_switch:   false,
    navigation_icon_switch:   true
};

export const getPropsFromOwner = (
    options: NavigationListAtomOptionsType,
    extendProps: Object = {}
) => ({
    ...options,
    ...extendProps
});
