import { utils }          from '@geenee/shared';
import { cloneDeep, has } from "lodash-es";

function removeMeta(obj: Record<string, any>, key: string) {
    for (const prop in obj) {
        if (prop === key) delete obj[ prop ];
        else if (typeof obj[ prop ] === 'object') removeMeta(obj[ prop ], key);
    }
}

function withReplacedRef(object: Record<string, any>) {
    const replacePropKey = '$ref';
    const clonedObject = cloneDeep(object);

    return utils.mapDeepObject(
        clonedObject,
        ({ _key, value }) => {
            if (has(value, replacePropKey)) {
                const path = [ 'engeenee.com', value[ replacePropKey ] ].join('/');

                return clonedObject.definitions[ path ];
            }
        }
    );
}

export const jsonSchemaPreprocess = (schema: { [key: string]: any }) => {
    const resultSchema = withReplacedRef({ ...schema });

    delete resultSchema.anyOf;
    return resultSchema;
};
