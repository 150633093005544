import { Node }                         from "@babylonjs/core/node";
import { BabylonRenderer }              from "@geenee/geespector/renderer/babylonjs.renderer";
import { AbstractCommand, CommandType } from '@geenee/shared/src/commander/types';

export class ModelFileStrategy implements CommandType {
    receiver: BabylonRenderer;
    workingFile: File;
    transformNodes: Node[] = [];
    constructor(receiver: BabylonRenderer, workingFile: File) {
        this.receiver = receiver;
        this.workingFile = workingFile;
    }

    execute = () => {
        this.receiver.addNodeWithModel(this.workingFile).then((res) => {
            this.transformNodes = res;
        });
    };

    revert = () => {
        this.transformNodes.forEach((entity) => {
            this.receiver.deleteMesh(entity);
        });
    };
}
