import { container as builderContainer } from "@geenee/builder/src/magellan/di/di";
import { Container }                     from "inversify";

export const createDIContext = () => {
    const container = new Container();
    container.bind('<AppState>').toDynamicValue(() => builderContainer.get('<AppState>'));
    container.bind('<SceneCommander>').toDynamicValue(() => builderContainer.get('<SceneCommander>'));
    container.bind('<BuilderState>').toDynamicValue(() => builderContainer.get('<BuilderState>'));
    container.bind('<DIContextGetter>').toDynamicValue(() => builderContainer.get('<DIContextGetter>'));
    container.bind('<Modal>').toDynamicValue(() => builderContainer.get('<Modal>'));
    container.bind('<WithModal>').toDynamicValue(() => builderContainer.get('<WithModal>'));
    container.bind('<Theme>').toConstantValue(builderContainer.get('<Theme>'));
    container.bind('<ContextUI>').toDynamicValue(() => window.GeespectorDI);
    container.bind('<Analytics>').toDynamicValue(() => builderContainer.get('<Analytics>'));

    return container;
};
