import styled from 'styled-components';

export const CameraFeedContainer = styled.div`
    // TODO: Remove top position after brandingBar logic updates
    height: calc(100% + 48px);
    width: 100%;
    position: absolute;
    top: -48px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: linear-gradient(
            180deg,
            rgba(205, 191, 213, 0.16) 0%,
            rgba(169, 89, 215, 0.18) 43.34%,
            rgba(139, 172, 227, 0.308) 93.75%
        ),
        #ffffff;
    overflow-y: hidden;
`;

export const Wrapper = styled.div`
    position: relative;
`;
