import { useMemo }               from "react";
import { useForm, UseFormProps } from "react-hook-form";
import { useBuilderInject }      from "@geenee/builder/src/core/hook/use-builder-inject";
import { MobxForm }              from "@geenee/shared/src/magellan/form/mobx-form";

type formAccessKeysUnion = "Login" | "Register" | "ForgotPassword" | "ResetPassword" |
 "SignUp" | "InviteUser" | "CompanySettings" | "ProfileSettings" | "MemberSettings";
export const useMobxForm = (keyToForm: `<${ formAccessKeysUnion }>`, options: UseFormProps = {}) => {
    const formHookResult = useForm(options);
    const { container } = useBuilderInject();

    return useMemo(() => {
        const form = container.get(keyToForm) as MobxForm;
        form.setup(formHookResult);
        return form;
    }, []);
};
