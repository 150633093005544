import { TransformNode }   from "@babylonjs/core/Meshes/transformNode";
import { BabylonRenderer } from "@geenee/geespector/renderer/babylonjs.renderer";
import { addUuid }         from "@geenee/geespector/src/straregies/decorators";
import { CommandType }     from "@geenee/shared/src/commander/types";

@addUuid
export class TransformNodeStrategy implements CommandType {
    receiver: BabylonRenderer;
    entity: TransformNode;

    constructor(receiver: BabylonRenderer, entity: TransformNode) {
        this.receiver = receiver;
        this.entity = entity;
    }
    execute() {
        this.receiver.addTransformNode(this.entity);
    }
    revert() {
        this.receiver.deleteMesh(this.entity);
    }
}
