export type SwiperItemOptType = {
  [key: string]: any,
  style_color_text_variation?: any
  swiper_item_style_size?: any
  swiper_item_padding?: string
  swiper_item_border_radius?: string
  swiper_item_blur_radius?: string
  swiper_item_color_bg?: string
  swiper_item_background_type?: 'solid' | 'blur'
  swiper_item_border?: string
  swiper_item_width?: string
  swiper_item_height?: string

  swiper_item_style_type: string,
  swiper_item_style_color_text: string,
  swiper_item_style_color_text_alpha: number,
  swiper_item_style_color_text_type: string,
  swiper_item_style_color_text_variation: string,
  swiper_item_style_color_text_custom: string,
  swiper_item_style_color_bg: string,
  swiper_item_style_color_bg_alpha: number,
  swiper_item_style_color_bg_type: string,
  swiper_item_style_color_bg_variation: string,
  swiper_item_style_color_bg_custom: string,
  swiper_item_style_color_border: string,
  swiper_item_style_color_border_alpha: number,
  swiper_item_style_color_border_type: string,
  swiper_item_style_color_border_variation: string,
  swiper_item_style_color_border_custom: string,
  swiper_item_style_corner_radius: string,
  swiper_item_style_corner_radius_custom: number
}

export const initProps: SwiperItemOptType = {
    swiper_item_padding:         '4px',
    swiper_item_border_radius:   '50%',
    swiper_item_blur_radius:     's',
    swiper_item_color_bg:        'white',
    swiper_item_background_type: 'blur',
    swiper_item_border:          'none',
    swiper_item_width:           '100%',
    swiper_item_height:          '100%',

    swiper_item_style_type:                   'transparent',
    swiper_item_style_color_text:             'inherit',
    swiper_item_style_color_text_alpha:       1,
    swiper_item_style_color_text_type:        'auto',
    swiper_item_style_color_text_variation:   'text',
    swiper_item_style_color_text_custom:      '',
    swiper_item_style_color_bg:               'inherit',
    swiper_item_style_color_bg_alpha:         1,
    swiper_item_style_color_bg_type:          'auto',
    swiper_item_style_color_bg_variation:     'contrast',
    swiper_item_style_color_bg_custom:        '',
    swiper_item_style_color_border:           'inherit',
    swiper_item_style_color_border_alpha:     1,
    swiper_item_style_color_border_type:      'auto',
    swiper_item_style_color_border_variation: 'contrast',
    swiper_item_style_color_border_custom:    '',
    swiper_item_style_corner_radius:          '0',
    swiper_item_style_corner_radius_custom:   0
};
