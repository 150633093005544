import React                        from "react";
import { FormLineWrapperComponent } from "@geenee/geespector/src/components/formLineWrapper/formLineWrapperComponent";
import { FormItem }                 from "../../form-item/form-item.component";
import { Switch }                   from "../../switch/switch.component";
import { camelToText }              from "./getTitleFromCamelCase";
import { getError }                 from "./utils";

function CheckboxWidget(props) {
    const {
        id, required, schema, value, onChange, rawErrors, label
    } = props;
    return (
        <FormLineWrapperComponent title={ camelToText(label) } border>
            <FormItem margin="off" autoFocusDetect error={ !!(rawErrors && rawErrors.length) && getError(rawErrors) } contentAlign="right">
                <Switch
                    disabled={ schema.disabled }
                    size="lg"
                    label={ schema.title }
                    checked={ value }
                    onChange={ onChange }
                    default={ schema.default }
                    id={ id }
                />
            </FormItem>
        </FormLineWrapperComponent>
    );
}

export default CheckboxWidget;
