import React, { useState }   from 'react';
import { AddToSceneCommand } from "@geenee/geespector/src/commands/AddToSceneCommand";
import { DuplicateCommand }  from "@geenee/geespector/src/commands/DuplicateMeshCommand";
import { useInject }         from "@geenee/shared/src/hook/use-inject.hook";
import {
    SCENE_POPOVER_FULL,
    SCENE_POPOVER_LIGHT, SCENE_POPOVER_TRANSFORM_NODE
} from "@geenee/shared/src/util/popover-templates";
import { Icon, PopoverContainer, PopoverSchema } from "@geenee/ui";
import { observer }                              from "mobx-react";
import { DeleteCommand }                         from '../../../commands/DeleteCommand';
import { HideShowCommand }                       from '../../../commands/hideShowCommand';

const POPOVER_SCHEMAS = {
    'full-popover':           SCENE_POPOVER_FULL,
    'light-popover':          SCENE_POPOVER_LIGHT,
    'transform-node-popover': SCENE_POPOVER_TRANSFORM_NODE
};

type Props = {
  popoverSchemaType: keyof typeof POPOVER_SCHEMAS;
  item: any
  type: 'mesh' | 'light' | 'transformNode',
  disableDelete?: boolean
  disableDuplicate?: boolean
}

export const MoreAction = observer((props: Props) => {
    // @ts-ignore
    const { BuilderState, AppState } = useInject();
    const [ popover, setPopover ] = useState(null);

    const { activeSection : sectionModel } = AppState;
    const { sceneManager } = sectionModel || { sceneManager: undefined };
    const renderer = sceneManager?.sceneRenderer;

    const disableOptionByLabel = (schema: PopoverSchema, label: string) => {
        const disableIndex = schema.items.findIndex((el) => el.label.toLowerCase() === label.toLowerCase());
        if (disableIndex !== -1) {
            schema.items = schema.items.slice();
            schema.items[ disableIndex - 1 ].meta.hasDivider = false;
            schema.items.splice(disableIndex, 1);
        }
    };

    return (
        <PopoverContainer
            margin="md"
            popoverSchema={
                // @ts-ignore
                popover?.toJSON()
            }
            onClose={ () => setPopover(BuilderState.closePopover()) }
            onSelected={ (item) => {
                switch (item.label) {
                    case 'Delete':
                        sceneManager?.commander.executeCommand(new DeleteCommand(renderer, props.item));
                        break;
                    case 'Show / Hide':
                        sceneManager?.commander.executeCommand(new HideShowCommand(renderer, props.item, props.type));
                        break;
                    case 'Duplicate':
                        sceneManager?.commander.executeCommand(new DuplicateCommand(renderer, props.item));
                        break;
                    case 'Point Light':
                        sceneManager?.commander.executeCommand(new AddToSceneCommand(renderer, 'light-point', props.item));
                        break;
                    case 'Direct Light':
                        sceneManager?.commander.executeCommand(new AddToSceneCommand(renderer, 'light-direct', props.item));
                        break;
                    case 'Spot Light':
                        sceneManager?.commander.executeCommand(new AddToSceneCommand(renderer, 'light-spot', props.item));
                        break;
                    case 'Rename':
                        // @ts-ignore
                        popover?.eventEmitter.emit('rename', props.item);
                        break;
                    default:
                        break;
                }
            } }
        >
            <Icon
                size={ 16 }
                name="more"
                onClick={ () => {
                    if (popover) {
                        setPopover(BuilderState.closePopover());
                    } else {
                        const schema = { ...POPOVER_SCHEMAS[ props.popoverSchemaType ] };
                        if (schema) {
                            if (props.disableDelete) {
                                disableOptionByLabel(schema, 'delete');
                            }
                            if (props.disableDuplicate) {
                                disableOptionByLabel(schema, 'duplicate');
                            }
                        }
                        setPopover(BuilderState.createPopover(schema));
                    }
                } }
            />
        </PopoverContainer>
    );
});
