import React                          from 'react';
import { components, ContainerProps } from 'react-select';

import { cn }                         from '../../util/bem';
import { OptionType }                 from './select.component';

const className = cn('select');

export const CustomSelectContainer = (props: ContainerProps<OptionType>) => {
    const newProps = {
        ...props,
        className: className('select-container')
    };
    return (<components.SelectContainer { ...newProps } />);
};
