import React from "react";

export const Warning1 = ({ children }: { children?: React.ReactNode }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 6.25C11.5858 6.25 11.25 6.58579 11.25 7V14C11.25 14.4142 11.5858 14.75 12 14.75C12.4142 14.75 12.75 14.4142 12.75 14V7C12.75 6.58579 12.4142 6.25 12 6.25Z"
            fill="#3E3B46"
            className="fill"
        />
        <path
            d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"
            fill="#3E3B46"
            className="fill"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.25 8.16295V15.837C2.25 16.8394 2.79145 17.7629 3.66448 18.2497L10.6645 22.1527C11.495 22.6158 12.505 22.6158 13.3355 22.1527L20.3355 18.2497C21.2085 17.7629 21.75 16.8394 21.75 15.837V8.16295C21.75 7.16062 21.2085 6.23708 20.3355 5.7503L13.3355 1.84732C12.505 1.38423 11.495 1.38423 10.6645 1.84732L3.66448 5.7503C2.79145 6.23708 2.25 7.16062 2.25 8.16295ZM3.75 15.837L3.75 8.16295C3.75 7.70735 3.99611 7.28755 4.39295 7.06629L11.3929 3.16331C11.7705 2.95281 12.2295 2.95281 12.6071 3.16331L19.6071 7.06629C20.0039 7.28755 20.25 7.70735 20.25 8.16295V15.837C20.25 16.2927 20.0039 16.7124 19.6071 16.9337L12.6071 20.8367C12.2295 21.0472 11.7705 21.0472 11.3929 20.8367L4.39295 16.9337C3.99612 16.7124 3.75 16.2927 3.75 15.837Z"
            fill="#3E3B46"
            className="fill"
        />
        { children }
    </svg>
);

export const Warning1Fat = ({ children }: { children?: React.ReactNode }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 5.99997C11.4477 5.99997 11 6.44769 11 6.99997V12.5C11 13.0523 11.4477 13.5 12 13.5C12.5523 13.5 13 13.0523 13 12.5V6.99997C13 6.44769 12.5523 5.99997 12 5.99997Z"
            fill="#3E3B46"
            className="fill"
        />
        <path
            d="M13.5 16.5C13.5 17.3284 12.8284 18 12 18C11.1716 18 10.5 17.3284 10.5 16.5C10.5 15.6715 11.1716 15 12 15C12.8284 15 13.5 15.6715 13.5 16.5Z"
            fill="#3E3B46"
            className="fill"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 8.17673V15.8231C2 16.9126 2.59068 17.9165 3.54307 18.4456L10.5431 22.3345C11.4491 22.8378 12.5509 22.8378 13.4569 22.3345L20.4569 18.4456C21.4093 17.9165 22 16.9126 22 15.8231V8.17673C22 7.08723 21.4093 6.08337 20.4569 5.55426L13.4569 1.66537C12.5509 1.162 11.4491 1.162 10.5431 1.66537L3.54307 5.55426C2.59068 6.08337 2 7.08723 2 8.17673ZM4 15.8231L4 8.17673C4 7.81356 4.19689 7.47894 4.51436 7.30257L11.5144 3.41369C11.8164 3.24589 12.1836 3.24589 12.4856 3.41369L19.4856 7.30257C19.8031 7.47894 20 7.81356 20 8.17673V15.8231C20 16.1863 19.8031 16.5209 19.4856 16.6973L12.4856 20.5862C12.1836 20.754 11.8164 20.754 11.5144 20.5862L4.51436 16.6973C4.19689 16.5209 4 16.1863 4 15.8231Z"
            fill="#3E3B46"
            className="fill"
        />
        { children }
    </svg>
);
