import Quaternion from '../math/Quaternion';
import FusionPoseSensor from './FusionPoseSensor';
import BasePoseSensor from './BasePoseSensor';
import { BROWSER_MODE } from '../constants';

/**
 *
 */
export default class IOSPoseSensor extends BasePoseSensor {
  private fusionSensor: FusionPoseSensor;

  /**
   *
   */
  constructor() {
    super();

    this.fusionSensor = new FusionPoseSensor(0.98, 0.04, false, this.isDeviceMotionInRadians);
  }

  //-----------------------------------------------------------------
  //
  //  Public methods
  //
  //----------------------------------------------------------------

  public getOrientation(): Quaternion {
    if (!this.isStarted()) {
      return new Quaternion();
    }
    return new Quaternion().fromArray(this.fusionSensor.getOrientation());
  }

  public startWithRequestPermisson(): Promise<void> {
    return new Promise((resolve, reject) => {
      DeviceMotionEvent.requestPermission()
        .then(permissionState => {
          if (permissionState === 'granted') {
            setTimeout(() => {
              if (this.started) {
                resolve();
              } else {
                reject(new Error('not allowed'));
              }
            }, 500);
            this.started = true;
            this.addEventListeners();
            this.fusionSensor.start();
          }
        })
    });
  }

  public start(): Promise<void> {
    return new Promise((resolve, reject) => {
      DeviceMotionEvent.requestPermission().then(permissionState => {
        if (permissionState === 'granted') {
          this.started = true;
          this.addEventListeners();
        }
      });

      setTimeout(() => {
        if (this.started) {
          resolve();
        } else {
          this.fusionSensor.stop();
          reject(new Error('not allowed'));
        }
      }, 500);
    });
  }

  public stop() {
    this.started = false;
    this.fusionSensor.stop();
    this.removeEventListeners();
  }

  //-----------------------------------------------------------------
  //
  //  Private methods
  //
  //-----------------------------------------------------------------

  addEventListeners() {
    if (BROWSER_MODE) {
      window.addEventListener('devicemotion', (e) => this.onDeviceMotion(e));
      window.addEventListener('orientationchange', this.onOrientationChange);
    }
  }

  removeEventListeners() {
    if (BROWSER_MODE) {
      window.removeEventListener('devicemotion', this.onDeviceMotion);
      window.removeEventListener('orientationchange', this.onOrientationChange);
    }
  }

  //-----------------------------------------------------------------
  //
  //  Event handlers
  //
  //-----------------------------------------------------------------

  onOrientationChange = (e: Event) => {
    this.fusionSensor.setScreenTransform();
  }

  /**
   *
   * @param {DeviceMotionEvent} e
   * @private
   */
  onDeviceMotion(e: DeviceMotionEvent) {
    super.onDeviceMotion(e);
    this.fusionSensor.updateDeviceMotion(e);
  }
}
