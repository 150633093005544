import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '12',
    height = '20',
    onClick,
    id,
    fill = 'black',
    style
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            viewBox="0 0 12 20"
            xmlns="http://www.w3.org/2000/svg"
            onClick={ onClick }
            style={ style }
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M3.44888 10L11.0102 2.43872C11.568 1.88083 11.568 0.976311 11.0102 0.418419C10.4523 -0.139473 9.54774 -0.139473 8.98985 0.418419L0.418419 8.98985C-0.139473 9.54774 -0.139473 10.4523 0.418419 11.0102L8.98985 19.5816C9.54774 20.1395 10.4523 20.1395 11.0102 19.5816C11.568 19.0237 11.568 18.1192 11.0102 17.5613L3.44888 10Z"
                fill={ fill }
            />
        </svg>
    );
}
