import React, { FC, useEffect }     from 'react';
import { SceneUIElements }          from '@geenee/geeclient-kit/src/lib/component/scene/widget/scene-ui-elements.widget';
import { loadWasm, slam, stamp }    from '@geenee/geetracker';
import { SceneRenderer, useInject } from '@geenee/shared/index';
import { observer }                 from 'mobx-react';

type PropsType = {
  activeSceneModel: SceneRenderer;
};

export const WasmWrapperWidget: FC<PropsType> = observer((props) => {
    const { AppState } = useInject();

    useEffect(() => {
        if (!AppState.isWasmLoaded) {
            loadWasm().then(() => {
                AppState.setIsWasmLoaded(true);
                if (props.activeSceneModel.scene_experience_type === 'slam-ar' || props.activeSceneModel.scene_experience_type === 'scene-build') {
                    props.activeSceneModel.setWasmLoaded(true);
                }

                if (props.activeSceneModel.scene_experience_type === 'stamp-ar') {
                    props.activeSceneModel.setWasmLoaded(true);
                }

                // setWasmLoaded(true);
            });
        } else {
            window.location.reload();
        }
    }, [ AppState.videoStream ]);

    useEffect(() => {
        if (props.activeSceneModel?.id && AppState.isWasmLoaded) {
            try {
                if (props.activeSceneModel.scene_experience_type === 'stamp-ar') {
                    stamp.stop();
                    // props.activeSceneModel.setStampLoaded();
                }
            } catch (e) {
                console.log(e);
            }
        }
    }, [ props.activeSceneModel?.id ]);

    return (
        <>
            { /* @ts-ignore */ }
            <SceneUIElements sceneModel={ props.activeSceneModel }>
                { /* { !!props.activeSceneModel.wasmLoaded && !!AppState.videoStream && ( */ }
                { !!props.activeSceneModel.wasmLoaded && (
                    <>
                        { props.children }
                    </>
                ) }
            </SceneUIElements>
        </>
    );
});
