import * as React                from "react";
import { Collapse, Description } from "@geenee/ui";

interface Props {
  children: JSX.Element | JSX.Element[]
}

export function ShowMoreSectionComponent({ children }: Props) {
    return (
        <Collapse
            panelWrapperProps={ { padding: 'xs', radius: 'lg' } }
            hasIcon={ false }
            paddingLeft="off"
            // eslint-disable-next-line react/no-unstable-nested-components
            panelContent={ (collapsed) => (
                <Description
                    size="sm"
                    weight="medium"
                    color="violet"
                >
                    { collapsed ? 'Show more' : 'Show less' }
                </Description>
            ) }
        >
            { children }
        </Collapse>
    );
}
