import { ProjectModel }    from '@geenee/shared';
import { ViewType }        from '@geenee/shared/type/view.type';
import { injectable }      from 'inversify';
import { FactoryAbstract } from '../../util/factoryContainer';

@injectable()
export class ExperienceFactory extends FactoryAbstract {
    create(experienceSchema: Partial<ViewType>, $parent: ProjectModel) {
        const experienceModel = this.container.get('<ExperienceModel>');
        const viewFactory = this.container.get('<ViewFactory>');
        Object.assign(experienceModel, experienceSchema);
        experienceSchema?.views?.forEach((view) => {
            const viewModel = viewFactory.create(view, experienceModel);
            experienceModel.viewsRegistry.set(viewModel.id, viewModel);
        });
        if ($parent) {
            experienceModel.$parent = $parent;
        }
        experienceModel.initCode();
        return experienceModel;
    }
}

export type ExperienceFactoryType = ExperienceFactory
