import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '12',
    height = '12',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="6" cy="6" r="5.5" fill="white" stroke="#BBBBBB" />
            <rect
                x="3.35"
                y="3.35"
                width="5.3"
                height="5.3"
                rx="0.65"
                stroke="black"
                strokeWidth="0.7"
            />
        </svg>
    );
}
