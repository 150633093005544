import React, { useMemo } from 'react';
import { useHistory }     from "@geenee/common";
import {
    Button, Card, Description, Divider, Icon, Span, Wrapper
} from "@geenee/ui";
import { observer }    from "mobx-react";
import { stripeState } from "../../../core/state/stripe.state";

export const ChangePlanInfo = observer(() => {
    const history = useHistory();
    const viewCounter = useMemo(() => {
        if (stripeState.isHobbyist) {
            return "500";
        }
        if (stripeState.isPremium) {
            return "1000";
        }
        if (stripeState.isTeams) {
            return "10k";
        }
        if (stripeState.isEnterprise) {
            return "ထ";
        }
    }, []);
    return (
        <Card
            styles={ { height: "fit-content" } }
            row
            fullHeight
            maxWidth="40%"
            mediaBefore
            media={ (
                <Icon size={ 64 } rounded={ false } name="stats" frame="default" radius="lg" color="gradient-warm-3" />
            ) }
        >
            <Divider transparent margin="sm" />
            <Wrapper padding="xs">
                <Description margin="xs" weight="bold" lineHeight="lg" size="lg">SDK tokens usage stats</Description>
                <Wrapper row>
                    <Description noWrap>
                        <Span weight="bold">
                            { " " }
                            { viewCounter }
                            { " " }
                            views / month
                        </Span>
                        { " " }
                        { !stripeState.isEnterprise
                            ? (
                                <>
                                    <Span color="grey-9">Need more views? See the</Span>

                                    { " " }
                                    <Span>
                                        <Button
                                            style={ { height: 20, minHeight: 20 } }
                                            disableBorderRadius
                                            viewType="link"
                                            size="md"
                                            onClick={ () => {
                                                history.push("/all-plans");
                                            } }
                                            uppercase
                                        >
                                            upgrade plan
                                        </Button>
                                    </Span>
                                    { " " }
                                    <Span color="grey-9">options.</Span>
                                </>
                            ) : null }
                    </Description>
                </Wrapper>
            </Wrapper>
        </Card>
    );
});
