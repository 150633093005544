import React, { ChangeEvent, useMemo, useState }              from 'react';
import { useForm }                                            from 'react-hook-form';
import { DropdownDisplayValue }                               from "@geenee/builder/src/components/DropdownDisplayValue";
import { DropImageForm }                                      from "@geenee/builder/src/components/DropImageForm";
import { useBuilderInject }                                   from '@geenee/builder/src/core/hook/use-builder-inject';
import { accountState }                                       from '@geenee/builder/src/core/state/account.state';
import { COMPANY_SIZES, COMPANY_TYPES }                       from '@geenee/builder/src/lib/companyRelatedConstants';
import { COMPANY_UPDATE_SUCCESS, TOAST_ERROR, TOAST_SUCCESS } from '@geenee/builder/src/lib/constants';
import {
    Button, Description,
    Divider,
    FormItem,
    Input, InputTable, InputTableItem, Wrapper
} from '@geenee/ui';
import { runInAction }                  from 'mobx';
import { observer, useLocalObservable } from 'mobx-react';

const validate = (value: string) => {
    if (!value) {
        return 'required';
    }
    return '';
};

export const companyUrlConstructor = (companyName: string) => companyName.replace(/[^a-z0-9]/gi, '').toLowerCase();

export const CompanyPageContent = observer(() => {
    const company = accountState;
    const { BuilderState } = useBuilderInject();
    const [ isSaving, setIsSaving ] = useState(false);
    const [ error, setError ] = useState('');

    const { register, getValues, setValue, watch } = useForm();
    const watcher = watch();

    const dropdownLabels = useLocalObservable(() => ({
        size: (COMPANY_SIZES.find((
            item
        ) => item.value === (watcher.size || company.size)) || { label: '' }).label,
        business_type: (COMPANY_TYPES.find((
            item
        ) => item.value === (watcher.business_type || company.business_type)) || { label: '' }).label || '',
        setLabel(val: string, field: string) {
            this[ field ] = val;
        }
    }));
    const [ newLogo, setNewLogo ] = useState<any>();

    const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.currentTarget;

        const validatedError = validate(value);
        register(name);
        setValue(name, value);
        setError(validatedError);

        if (name === 'name') {
            register('publishing_domain');
            const newValue = companyUrlConstructor(value);
            setValue('publishing_domain', newValue);
        }
    };

    const handleDropdownChange = (item: {label: string, value: string }, name: string) => {
        register(name, { required: true });
        dropdownLabels.setLabel(item.label, name);
        setValue(name, item.value);
    };

    const handleImageUpload = (file: File) => {
        setNewLogo(file);
    };

    const logoUrl = useMemo(() => (newLogo ? URL.createObjectURL(newLogo) : company.logo), [ newLogo, company?.logo ]);

    const dropDescription = useMemo(() => (
        <>
            <Description
                size="sm"
                color="shade-3"
            >
                Upload your company logo
            </Description>
            <Description
                margin="xs"
                size="sm"
                color="shade-4"
            >
                File formats: gif, jpg, png.
            </Description>
        </>
    ), []);
    return (
        <Wrapper frame="solid-default" maxWidth="775">
            <Wrapper padding="md" align="center" valign="center">
                <InputTable margin="sm">
                    <InputTableItem ratio={ 0.65 } valign="start" label='Company logo' viewType="secondary" size="lg">
                        <DropImageForm
                            url={ logoUrl }
                            maxImageSize={ 2097152 } // 2MB
                            onDrop={ handleImageUpload }
                            description={ dropDescription }
                            dropRootProps={ { styles: { height: '96px', minWidth: '192px', maxWidth: '192px' } } }
                        />
                    </InputTableItem>
                    <InputTableItem ratio={ 0.65 } valign="base-line" label='Company name' viewType="secondary" size="lg">
                        <FormItem
                            margin="off"
                            autoFocusDetect
                            error={
                                (error === 'required' && (
                                    'This field is required'
                                ))
                            }
                        >
                            <Input
                                size="md"
                                fullWidth
                                name="name"
                                onChange={ handleTextChange }
                                value={ watcher.name !== undefined ? watcher.name : company.name }
                                placeholder="Enter your company name"
                            />
                        </FormItem>
                    </InputTableItem>

                    <InputTableItem ratio={ 0.65 } valign="base-line" label='Company size' viewType="secondary" size="lg">
                        <DropdownDisplayValue
                            items={ COMPANY_SIZES }
                            value={ watcher.size || company.size }
                            onSelect={ (_val, itemId) => {
                                handleDropdownChange(COMPANY_SIZES[ itemId ], 'size');
                            } }
                            defaultLabel="Select company size"
                        />
                    </InputTableItem>
                    <InputTableItem ratio={ 0.65 } valign="base-line" label='Business type' viewType="secondary" size="lg">
                        <DropdownDisplayValue
                            items={ COMPANY_TYPES }
                            value={ watcher.business_type || company.business_type }
                            onSelect={ (_val, itemId) => {
                                handleDropdownChange(COMPANY_TYPES[ itemId ], 'business_type');
                            } }
                            defaultLabel="Select business type"
                        />
                    </InputTableItem>
                </InputTable>
                <Divider color="grey-3" margin="sm" />

                <Wrapper row align="right">
                    <Button
                        uppercase
                        disabled={ isSaving || !!error }
                        onClick={ async () => {
                            try {
                                setIsSaving(true);
                                await accountState.update({
                                    ...company,
                                    ...getValues()
                                }, newLogo);
                                runInAction(() => {
                                    BuilderState.toast = {
                                        severity: TOAST_SUCCESS,
                                        detail:   COMPANY_UPDATE_SUCCESS,
                                        summary:  ''
                                    };
                                });
                            } catch (e) {
                                console.error(e);
                                runInAction(() => {
                                    BuilderState.toast = {
                                        severity: TOAST_ERROR,
                                        detail:   'Server error or company name is already taken',
                                        summary:  ''
                                    };
                                });
                            }
                            setIsSaving(false);
                        } }
                    >
                        Save settings
                    </Button>
                </Wrapper>
            </Wrapper>
        </Wrapper>
    );
});
