import styled from 'styled-components';

const PHONE_PREVIEW_WIDTH = '375px';
export const Container = styled.div<{ builder?: boolean }>`
    height: 100%;
    .ql-container {
        font-family: 'Gordita', sans-serif;;
    }

    .ql-container.ql-snow {
        border: none;
    }

  /* Quill RTE 'snow' theme editor style overwrights */
  /* Set the font-family content used for the HTML content. */
  .ql-font-euclid-circular-a {
    font-family: 'Euclid Circular A', sans-serif !important;
  }

  .ql-font-montserrat {
    font-family: 'Montserrat', sans-serif !important;
  }

  .ql-font-open-sans {
    font-family: 'Open Sans', sans-serif !important;
  }

  .ql-font-lato {
    font-family: 'Lato', sans-serif !important;
  }

  .ql-font-arvo {
    font-family: 'Arvo', sans-serif !important;
  }

  .ql-font-karla {
    font-family: 'Karla', sans-serif !important;
  }

    /* Set the font-size content used for the HTML content. */
    .ql-size-8 {
        font-size: 8px;
    }

    .ql-size-9 {
        font-size: 9px;
    }

    .ql-size-10 {
        font-size: 10px;
    }

    .ql-size-11 {
        font-size: 11px;
    }

    .ql-size-12 {
        font-size: 12px;
    }

    .ql-size-14 {
        font-size: 14px;
    }

    .ql-size-16 {
        font-size: 16px;
    }

    .ql-size-18 {
        font-size: 18px;
    }

    .ql-size-1vw {
        font-size: 1vw;
    }

    .ql-size-1_5vw {
        font-size: 1.5vw;
    }

    .ql-size-2vw {
        font-size: 2vw;
    }

    .ql-size-2_5vw {
        font-size: 2.5vw;
    }

    .ql-size-3vw {
        font-size: 3vw;
    }

    .ql-size-3_5vw {
        font-size: 3.5vw;
    }

    .ql-size-4vw {
        font-size: 4vw;
    }

    .ql-size-4_5vw {
        font-size: 4.5vw;
    }

    .ql-size-5vw {
        font-size: 5vw;
    }

    .ql-size-5_5vw {
        font-size: 5.5vw;
    }

    .ql-size-6vw {
        font-size: 6vw;
    }

    ${ ({ builder }) => builder
        && `
     .ql-size-1vw {
        font-size: calc(${ PHONE_PREVIEW_WIDTH } / 100);
    }

    .ql-size-1_5vw {
        font-size: calc(${ PHONE_PREVIEW_WIDTH } * 1.5 / 100);
    }

    .ql-size-2vw {
        font-size: calc(${ PHONE_PREVIEW_WIDTH } * 2 / 100);
    }

    .ql-size-2_5vw {
        font-size: calc(${ PHONE_PREVIEW_WIDTH } * 2.5 / 100);
    }

    .ql-size-3_5vw {
        font-size: calc(${ PHONE_PREVIEW_WIDTH } * 3.5 / 100);
    }

    .ql-size-3vw {
        font-size: calc(${ PHONE_PREVIEW_WIDTH } * 3 / 100);
    }

    .ql-size-4vw {
        font-size: calc(${ PHONE_PREVIEW_WIDTH } * 4 / 100);
    }

    .ql-size-4_5vw {
        font-size: calc(${ PHONE_PREVIEW_WIDTH } * 4.5 / 100);
    }

    .ql-size-5vw {
        font-size: calc(${ PHONE_PREVIEW_WIDTH } * 5 / 100);
    }

    .ql-size-5_5vw {
        font-size: calc(${ PHONE_PREVIEW_WIDTH } * 5.5 / 100);
    }

    .ql-size-6vw {
        font-size: calc(${ PHONE_PREVIEW_WIDTH } * 6 / 100);
    }
  ` }
`;
