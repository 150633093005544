import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({ width = '44', height = '34', onClick, id }: SvgIconProps) {
    return (
        <svg
            id={ id }
            onClick={ onClick }
            width={ width }
            height={ height }
            viewBox="0 0 44 34"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_22930_38389)">
                { /* eslint-disable-next-line max-len */ }
                <path d="M2 6.66129C2 5.55672 2.89543 4.66129 4 4.66129H36C37.1046 4.66129 38 5.55672 38 6.66129V27.1429C38 28.2474 37.1046 29.1429 36 29.1429H4C2.89543 29.1429 2 28.2474 2 27.1429V6.66129Z" fill="white" />
            </g>
            <rect x="13" y="6.61981" width="13" height="1.95851" rx="0.979256" fill="#C5C5C5" />
            <rect x="6" y="12.4954" width="28" height="13.7097" rx="2" fill="black" />
            { /* eslint-disable-next-line max-len */ }
            <path d="M17.2871 20.1768C17.2871 19.5557 16.9824 19.2422 16.4258 19.0313V19.0225C16.833 18.8116 17.085 18.4864 17.085 18.0176C17.085 17.1768 16.3408 16.7755 15.585 16.7755H13.6367V21.4747H15.6143C16.4434 21.4747 17.2871 21.1319 17.2871 20.1768ZM16.1328 18.17C16.1328 18.5919 15.7314 18.6827 15.3857 18.6827H14.583V17.6719H15.4502C15.7783 17.6719 16.1328 17.7803 16.1328 18.17ZM16.3379 20.0508C16.3379 20.4727 15.9512 20.5723 15.6025 20.5723H14.583V19.5264H15.5703C15.9307 19.5264 16.3379 19.6172 16.3379 20.0508ZM21.8281 16.7784H20.8643V19.6143C20.8643 20.209 20.5332 20.6075 19.9385 20.6075C19.4258 20.6075 19.0244 20.2852 19.0244 19.5909L19.0273 16.7784H18.0547V19.6143C18.0547 20.751 18.6289 21.5391 19.9326 21.5391C21.2041 21.5391 21.8252 20.7774 21.8252 19.5792L21.8281 16.7784ZM25.1094 21.4747V19.5733L26.8291 16.7755H25.7188L24.6201 18.6563L23.5361 16.7755H22.4111L24.1367 19.6172V21.4747H25.1094Z" fill="white" />
            <defs>
                { /* eslint-disable-next-line max-len */ }
                <filter id="filter0_d_22930_38389" x="0" y="0.661285" width="44" height="32.4816" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.527938 0 0 0 0 0.527938 0 0 0 0 0.527938 0 0 0 0.5 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_22930_38389" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_22930_38389" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}
