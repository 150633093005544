import { useHistory } from "@geenee/common";

export const useCloseAndRedirect = (close: () => void) => {
    const history = useHistory();

    const goToContact = () => {
        window.scrollTo(0, 0);
        history.push('/contact');
        close();
    };

    const goToQuestions = (id = '1') => {
        window.scrollTo(0, 0);
        history.push(`/getting-started/${ id }`);
        close();
    };

    const goToPlans = () => {
        window.scrollTo(0, 0);
        history.push('/all-plans');
        close();
    };

    const goToSignup = () => {
        window.scrollTo(0, 0);
        history.push('/sign-up');
        close();
    };

    return {
        goToContact,
        goToQuestions,
        goToPlans,
        goToSignup
    };
};
