import React                       from 'react';
import { useAnalyticsModal }       from '~/module/custom-code/modal/analythics.modal';
import { useAssetCustomCodeModal } from '~/module/custom-code/modal/asset-custom-code.modal';
import { useCustomCodeModal }      from '~/module/custom-code/modal/custom-code.modal';

export type CustomCodeContextType = {
    useAssetCustomCodeModal: typeof useAssetCustomCodeModal;
    useCustomCodeModal: typeof useCustomCodeModal;
    useAnalyticsModal: typeof useAnalyticsModal;
};

export const customCodeContext: CustomCodeContextType = {
    useAssetCustomCodeModal,
    useCustomCodeModal,
    useAnalyticsModal
};

export const CustomCodeContext: React.Context<CustomCodeContextType> = React.createContext({ ...customCodeContext });
