import React   from 'react';
import Amplify from '@geenee/builder/src/asset/icons/Amplify';

export function LinkIcon() {
    return (
        <>
            <span />
            <Amplify style={ { flexShrink: '0', marginLeft: '-5px' } } />
        </>
    );
}
