import React, { useEffect, useState } from 'react';
import { observer }                   from "mobx-react";
import { cn }                         from "../../util/bem";
import { Card }                       from "../card/card.component";
import { Description }                from "../description/description.component";
import { Icon }                       from "../icon/icon.component";
import { iconMap }                    from "../icon/icon.library";
import { Wrapper }                    from "../wrapper/wrapper.component";
import './toast.component.styles.scss';

export type ToastProps = {
  toast: {
    severity: 'error' | 'warning' | 'info' | 'success' | '';
    summary?: string;
    detail?: string;
  }
  position?: 'right' | 'center';
}

type TemplateProps = {
  [ key: string ]: {
    icon: keyof typeof iconMap;
    color: 'gradient-warm-4' | 'gradient-cold-3' | 'gradient-warm-2' | 'gradient-cold-1';
    summary: string;
  };
}
const NOTIFICATION_TEMPLATE: TemplateProps = {
    error:   { icon: 'error', color: 'gradient-warm-4', summary: 'Error' },
    info:    { icon: 'info', color: 'gradient-cold-1', summary: 'Info' },
    warning: { icon: 'warning', color: 'gradient-warm-2', summary: 'Warning' },
    success: { icon: 'checkBig', color: 'gradient-cold-3', summary: 'Success' }
};

const className = cn('toast');

export const ToastComponent = observer((props: ToastProps) => {
    const [ toastStack, setToastStack ] = useState<ToastProps['toast'][]>([]);

    useEffect(() => {
        if (props.toast.severity && !toastStack.find((item: ToastProps['toast']) => item === props.toast)) {
            setToastStack((prev) => [ props.toast, ...prev ]);
            setTimeout(() => {
                setToastStack((prev) => prev.slice(0, -1));
            }, 7000);
        }
    }, [ props.toast ]);

    useEffect(() => {
        setToastStack([]);
    }, [ props.position ]);

    return (
        <Wrapper className={ className({ position: props.position || 'right' }) }>
            { toastStack.slice(0, 3).map((toast, index) => (
                <Card
                    className={ className('card') }
                    margin="xs"
                    key={ index }
                    row
                    fullWidth
                    mediaBefore
                    shadow="smooth-md"
                    radius="lg"
                    padding="xs"
                    frame="solid-white"
                    media={ (
                        <Wrapper padding="xxs" maxWidth="fit-content">
                            <Icon
                                stroke="fat"
                                name={ NOTIFICATION_TEMPLATE[ toast.severity ].icon }
                                size={ 16 }
                                color={ NOTIFICATION_TEMPLATE[ toast.severity ].color }
                            />
                        </Wrapper>
                    ) }
                >
                    <Wrapper padding="xxs">
                        <Description
                            lineHeight="sm"
                            margin="xxs"
                            size="sm"
                            weight="medium"
                            color={ NOTIFICATION_TEMPLATE[ toast.severity ].color }
                        >
                            { toast.summary || NOTIFICATION_TEMPLATE[ toast.severity ].summary }
                        </Description>
                        <Description
                            size="sm"
                            color="shade-4"
                        >
                            { toast.detail }
                        </Description>
                    </Wrapper>
                </Card>
            )) }
        </Wrapper>
    );
});
