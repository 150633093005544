import React                               from 'react';
import { components, ValueContainerProps } from 'react-select';
import { cn }                              from '../../util/bem';
import { OptionType }                      from './select.component';

const className = cn('select');

export const CustomValueContainer = (props: ValueContainerProps<OptionType>) => {
    const newProps = {
        ...props,
        className: className('value-container')
    };
    return (<components.ValueContainer { ...newProps } />);
};
