import React, { FC, useMemo }                            from "react";
import { Button, Description, Dropdown, Menu, MenuItem } from "@geenee/ui";
import { FormSizesType }                                 from "@geenee/ui/src/lib/util/global-props";

type Props = {
    items: { value: string, label: string }[]
    onSelect: (val: string, id: string) => void
    margin?: 'sm' | 'md' | 'lg' | 'off' | 'xxs' | 'xs' | 'xl' | 'xxl';
    value: string
    disabled?: boolean
    size?: FormSizesType
    itemsSize?: "xs" | "sm" | "md" | "lg"
    maxCount?: number
    defaultLabel?: string
}
export const DropdownDisplayValue: FC<Props> = (props: Props) => {
    const menuItems = useMemo(() => (
        (onClose) => (
            <Menu
                viewType="light"
                activeItemId={ `${ props.items.findIndex((el) => el.value === props.value) }` }
                radius="lg"
                size={ props.itemsSize }
                maxCount={ props.maxCount }
                padding="xxs"
            >
                { props.items.map((item, index) => (
                    <MenuItem
                        id={ `${ index }` }
                        key={ item.value }
                        onSelect={ (e, itemId) => {
                            props.onSelect(props.items[ itemId ].value, itemId);
                            onClose();
                        } }
                    >
                        { item.label }
                    </MenuItem>
                )) }
            </Menu>
        )
    ), [ props.value, props.items ]);

    const activeItem = useMemo(() => props.items.find((filter) => filter.value === props.value), [ props.value, props.items ]);
    return (
        <Dropdown
            maxCount={ props.maxCount }
            disabled={ props.disabled }
            clickOutside
            menu={ menuItems }
            closeOnSelect
            menuWidth="100%"
            fullWidth
            margin={ props.margin }
        >
            { (dropdownOpen) => (
                <Button
                    style={ { padding: 8 } }
                    icon={ dropdownOpen ? "arrowTop" : "arrowDown" }
                    iconAfter
                    viewType="white"
                    border="shade-5"
                    shadow="none"
                    iconFloat="right"
                    fullWidthContent
                    radius="lg"
                    size={ props.size }
                    iconMargin="off"
                    fullWidth
                    weight="medium"
                >
                    <Description
                        size={ props.size }
                        weight="regular"
                        noWrap
                        shorten
                        color={ !activeItem?.label ? "shade-4" : undefined }
                    >
                        { activeItem?.label || props.defaultLabel }
                    </Description>
                </Button>
            ) }

        </Dropdown>
    );
};

DropdownDisplayValue.defaultProps = { size: "md", maxCount: 6, defaultLabel: "Default" };
