import React                 from 'react';
import { generateQRCodeApi } from '@geenee/builder/src/api/FlowCodeApi';
import CircleTemplate        from '@geenee/builder/src/asset/images/FCCircleTemplate.png';
import DiamondTemplate       from '@geenee/builder/src/asset/images/FCDiamondTemplate.png';
import HeartTemplate         from '@geenee/builder/src/asset/images/FCHeartTemplate.png';
import SquareTemplate        from '@geenee/builder/src/asset/images/FCSquareTemplate.png';
import StandardTemplate      from '@geenee/builder/src/asset/images/FCStandardTemplate.png';
import StarTemplate          from '@geenee/builder/src/asset/images/FCStarTemplate.png';
import TriangleTemplate      from '@geenee/builder/src/asset/images/FCTriangleTemplate.png';
import { uploadAttachment }  from '@geenee/builder/src/lib/uploadAttachment';
import { container }         from '@geenee/builder/src/magellan/di/di';

export const lastOptsData: Record<string, any> = {};

const cacheObject: Record<string, string> = {};

export const getAttachmentUrl = async (file?: File) => {
    if (file) {
        const { attachment: uploadedAttachmentSchema } = await uploadAttachment({ file });
        // @ts-ignore
        const attachmentModel = container.get('<AttachmentFactory>').create(uploadedAttachmentSchema, this);
        return attachmentModel.url;
    }
    return '';
};

export const getFromCache = (data: FlowCodeParams): string => {
    try {
        const key = JSON.stringify(data);
        return cacheObject[ key ] || '';
    } catch (e) {
        console.error(e);
    }
    return '';
};

export const setInCache = (data: FlowCodeParams, code: string) => {
    try {
        const key = JSON.stringify(data);
        cacheObject[ key ] = code;
    } catch (e) {
        console.error(e);
    }
};

const getOpts = (id: string | number, color: string, isShape = false) => ({
    containerOuterPatternColor:        color,
    containerOuterPatternColorFillSvg: true,
    containerOuterPatternImageUrl:     isShape ? undefined : id,
    containerOuterPatternShape:        isShape ? id : undefined,
    containerOuterPatternRandomSizes:  '80',
    containerShape:                    1,
    gridModuleColor:                   color,
    gridModuleColorFillSvg:            true,
    gridModuleImageUrl:                isShape ? undefined : id,
    gridModuleShape:                   isShape ? id : undefined,
    gridModuleRandomSizes:             '80'

});
const size = 20;
// eslint-disable-next-line no-shadow
enum TemplatesEnum {
    Circle = 1,
    Square = 2,
    Diamond = 3,
    Standard =4,
    Triangle ='https://cdn.flowcode.com/prodassets/Asset_13_6IrDInz.svg?ts=1624978566926416304',
    Heart = 'https://cdn.flowcode.com/prodassets/Asset_2_T0NJ0Is.svg?ts=1624978647643562449',
    Star = 'https://cdn.flowcode.com/prodassets/Star_1_paabDgX.svg?ts=1624978620875933822',
}

export type FlowCodeParams = {
    url: string
    logoImageFile?: string
    logoWidth?: number
    logoHeight?: number
    opts?: any
    moduleShape?: string
    rimTextTop?: string
    rimTextBottom?: string
    containerRimWidth?: number
    containerRimBorderSize?: number
}

export const QRTemplates = (color: string) => [
    {
        label:    'Standard',
        opts:     getOpts(TemplatesEnum.Standard, color, true),
        template: <img src={ StandardTemplate } width={ size } height={ size } alt="qr template" />
    }, {
        label:    'Star',
        opts:     getOpts(TemplatesEnum.Star, color),
        template: <img src={ StarTemplate } width={ size } height={ size } alt="qr template" />
    }, {
        label:    'Heart',
        opts:     getOpts(TemplatesEnum.Heart, color),
        template: <img src={ HeartTemplate } width={ size } height={ size } alt="qr template" />
    },
    {
        label:    'Triangle',
        opts:     getOpts(TemplatesEnum.Triangle, color),
        template: <img src={ TriangleTemplate } width={ size } height={ size } alt="qr template" />
    },
    {
        label:    'Square',
        opts:     getOpts(TemplatesEnum.Square, color, true),
        template: <img src={ SquareTemplate } width={ size } height={ size } alt="qr template" />
    },
    {
        label:    'Diamond',
        opts:     getOpts(TemplatesEnum.Diamond, color, true),
        template: <img src={ DiamondTemplate } width={ size } height={ size } alt="qr template" />
    },
    {
        label:    'Circle',
        opts:     getOpts(TemplatesEnum.Circle, color, true),
        template: <img src={ CircleTemplate } width={ size } height={ size } alt="qr template" />
    }
];

export const generateQRCode = async (settings: FlowCodeParams, mainColor: string) => {
    const { data } = await generateQRCodeApi(settings, mainColor);
    return btoa(unescape(encodeURIComponent(data)));
};

const generateRimParams = (settings: any, color: string) => {
    settings.containerBorderColor = color;

    if (settings.containerRimText && settings.containerRimText.length) {
        settings.containerRimWidth = 10;
        settings.containerRimBorderSize = 5;
        settings.containerRimBorderColor = color;
    } else {
        settings.containerRimWidth = undefined;
        settings.containerRimBorderSize = undefined;
        settings.containerRimBorderColor = undefined;
    }
};
export const generateParameters = (params: FlowCodeParams, color: string) => {
    // const logoUrl = await getAttachmentUrl(params.opts.logoImageFile);
    const settings = { ...params };
    generateRimParams(settings.opts, color);

    if (params.opts.logoImageFile) {
        settings.opts = {
            ...settings.opts,
            ...{
                logoImageUrl:  params.opts.logoImageFile,
                logoWidth:     40,
                logoHeight:    40,
                logoImageFile: undefined
            }
        };
    }
    return {
        shorten:             1,
        client_id:           process.env.ENV_FLOW_CODE_CLIENT_ID,
        containerBorderSize: 5,
        ...settings

    };
};

export const saveQRCodePng = (imageCode: string) => {
    const img = new Image();
    img.src = `data:image/svg+xml;base64,${ imageCode }`;
    const canvas = document.createElement('canvas');
    const imageSize = 800;
    canvas.width = imageSize;
    canvas.height = imageSize;
    // @ts-ignore
    canvas.getContext('2d').drawImage(img, 0, 0, imageSize, imageSize);
    const imgURL = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.download = 'qr_code.png';
    link.href = imgURL;
    link.dataset.downloadurl = [ 'image/png', link.download, link.href ].join(':');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const generateTextSettings = (text: string, color: string) => ({
    top: {
        font:             'centra_light',
        fontColor:        color,
        fontOutlineColor: color,
        fontOutlineSize:  2,
        fontSize:         12,
        position:         { property: 6, offsetPercentage: 0 },
        text
    },
    bottom: {
        font:             'centra_light',
        fontColor:        color,
        fontOutlineColor: color,
        fontOutlineSize:  2,
        fontSize:         12,
        position:         { property: 7, offsetPercentage: 0 },
        text
    }
});
