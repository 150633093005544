import * as React from 'react';
import {TableCellProps} from 'react-table';
import {Wrapper} from '../../wrapper/wrapper.component';
import {tableClassName} from '../utils';

const className = tableClassName('td');

type PropsType = {
    children?: React.ReactNode;
    cellProps: TableCellProps;
};

export const TableCell = React.memo((props: PropsType) => (
    <Wrapper
        className={ className }
        { ...props.cellProps }
    >
        { props.children }
    </Wrapper>
));
