import React         from 'react';
import { AtomModel } from '@geenee/builder/src/magellan/model/atom.model';

export const useHandlers = (buttonAtom?: AtomModel) => {
    const handleSetLinkType = (value: string) => {
        if (buttonAtom) {
            buttonAtom.options.atom_button_link_type = value;
            buttonAtom.saveData();
        }
    };

    const handleSetLinkPath = (value: string) => {
        if (buttonAtom) {
            buttonAtom.options.atom_button_url = value;
            buttonAtom.saveData();
        }
    };

    const handleSetLinkPathInput = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (buttonAtom) {
            const { value } = e.target;
            let newValue    = value;
            if (newValue.startsWith('http://')) {
                newValue = newValue.replace('http://', 'https://');
            }
            if (
                newValue.startsWith('https:/')
          && !newValue.startsWith('https://')
            ) {
                newValue = newValue.replace('https:/', 'https://');
            }
            if (!newValue.startsWith('https://')) {
                newValue = `https://${ newValue }`;
            }
            buttonAtom.options.atom_button_url = newValue;
            buttonAtom.saveData();
        }
    };

    return { handleSetLinkType, handleSetLinkPath, handleSetLinkPathInput };
};
