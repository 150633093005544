import { SceneStats }                         from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/type/type";
import EventEmitter                           from 'eventemitter3';
import { injectable }                         from 'inversify';
import { action, computed, observable, toJS } from 'mobx';
// @ts-ignore
import * as THREE                             from 'three';
import { AttachmentType }                     from '../../../type/attachment.type';
import type { SectionModel }                  from '../section/model/section.model';
import { BaseEntityModel }                    from './base-entity.model';
import { ProjectModel }                       from './project.model';

@injectable()
export class AttachmentModel extends BaseEntityModel implements AttachmentType {
    @observable filename!: AttachmentType['filename'] = '';
    @observable file_size!: AttachmentType['file_size'] = '';
    @observable file_type!: AttachmentType['file_type'] = '';
    @observable url!: AttachmentType['url'] = '';
    @observable properties: AttachmentType['properties'] = {};
    @observable $parent!: SectionModel = null;
    @observable isLoaded = false;
    @observable customCodeExecuted = false;
    options!: AttachmentType['options'];
    custom_code: AttachmentType['custom_code'] = null;
    customCodeContext: any = null;
    @observable thumbnail_attachment: AttachmentModel | null = null;
    emitter: EventEmitter = new EventEmitter();
    @observable title: AttachmentType['title'] = null;

    @computed
    get experience() {
        return this?.$parent?.$parent;
    }

    get position() {
        return this.properties.stats.find((stat: SceneStats) => stat.type === 'Position')?.data;
    }

    get rotation() {
        return this.properties.stats.find((stat: SceneStats) => stat.type === 'Rotation')?.data;
    }

    get scale() {
        return this.properties.stats.find((stat: SceneStats) => stat.type === 'Scale')?.data;
    }

    @action
    runCustomCode(object: THREE.Object3D) {
        // @ts-ignore
        if (process.env.ENV_GEENEE_APP === 'BUILDER') {
            return;
        }
        if (this.custom_code?.code && !this.customCodeExecuted) {
            this.customCodeExecuted = true;
            // @TODO: double-check the $parent nesting
            const activeScene = this.$parent.$parent.$parent.$parent.$parent.activeSceneModel;
            this.customCodeContext = {
                activeSceneModel: activeScene,
                textureLoader:    new THREE.TextureLoader(),
                THREE,
                on:               (event: any, callback: any) => {
                    this.emitter.on(event, (intersections) => {
                        if (intersections[ 0 ].object.uuid === object.uuid) callback(intersections[ 0 ]);
                    });
                },
                object3D: object
            };
            // @ts-ignore
            window.THREE = THREE;
            // eslint-disable-next-line no-new-func
            const wrapperFunc = new Function(this.custom_code.code);
            wrapperFunc.call(this.customCodeContext);
        }
    }

    @action
    onModelClick(intersections: any) {
        this.emitter.emit('on-model-click', intersections);
    }

    @computed get nft_published() {
        return this.$parent.nft_published;
    }

    @computed get toJsonObject(): AttachmentModel {
        return { ...toJS(this), $parent: undefined, thumbnail_attachment: this.thumbnail_attachment?.toJsonObject };
    }

    @computed
    get parentProject(): ProjectModel | null {
        // @TODO: double-check the parent nesting
        return this.$parent?.$parent?.$parent?.$parent;
    }

    @computed
    get audioUrl() {
        return this.options.audio_spotify_url || this.url;
    }
}
