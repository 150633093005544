import React, { memo }                      from 'react';
import { Description, Icon, Span, Wrapper } from '@geenee/ui';

export const EmptyPage = memo(() => (
    <Wrapper frame="solid-default" margin="xl">
        <Wrapper padding='xl' align="center" valign="center">
            <Icon size={ 64 } radius="lg" margin="md" name="team" color="gradient-cold-3" frame="default" />
            <Description size="lg">
                Your Team Members will show up here.
            </Description>
            <Description size="lg" margin="xs">
                Press
                { ' ' }
                <Span weight="bold">Add User</Span>
                { ' ' }
                button to add new team member
            </Description>
        </Wrapper>
    </Wrapper>
));
