import { Renderer }      from '@geenee/armature';
import { SceneRenderer } from "@geenee/shared/src/magellan/renderer/r3f-renderer/r3f.renderer";

export class BuilderThreeRenderer extends Renderer<any> {
    constructor(protected canvasContext: CanvasRenderingContext2D, protected activeSceneModel: SceneRenderer) {
        super();
    }

    async update(result: any, stream: HTMLCanvasElement) {
        this.canvasContext.invalidate();
        this.activeSceneModel.onRender();
        return super.update(result, stream);
    }
}
