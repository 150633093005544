import React from 'react';

// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '12',
    height = '12',
    onClick,
    id,
    fill = 'none'
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
            // eslint-disable-next-line max-len
                d="M0.5 3.8556C0.5 3.68807 0.583897 3.53168 0.723469 3.43903L4.72347 0.78371C4.89104 0.672469 5.10896 0.672469 5.27653 0.783709L9.27653 3.43903C9.4161 3.53168 9.5 3.68807 9.5 3.8556V11C9.5 11.2761 9.27614 11.5 9 11.5H1C0.723858 11.5 0.5 11.2761 0.5 11V3.8556Z"
                fill={ fill }
                stroke="#9821D0"
            />
        </svg>
    );
}
