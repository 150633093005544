import React, { useMemo }     from 'react';
import { observer }           from "mobx-react";
import { Color }              from 'three';
import { InfiniteGridHelper } from '../util/infinite-grid.helper';

const distanceFn = (x: number) => -0.0003 * x ** 2 + 14 * x + 7;

export const GridScene = observer(() => {
    const grid = useMemo(() => {
        const Grid = new InfiniteGridHelper(
            0.05,
            1,
            new Color('#727A8F'),
            distanceFn(1)
        ).mesh;
        Grid.material.depthWrite = false;
        return Grid;
    }, []);

    return (
        <>
            <primitive object={ grid } position={ [ 0, 0, 0 ] } />
        </>
    );
});
