import React, { ChangeEvent, SyntheticEvent, useState } from 'react';
import { Input }                                        from '@geenee/ui';
import { useTheme }                                     from 'styled-components';
import Magnifier                                        from '@geenee/builder/src/asset/icons/Magnifier';
import { InputRow }                                     from './styles';

type Props = {
  handleQueryChange: (value: string) => void;
  searchString: string | undefined;
}

function SearchBar(props: Props) {
    const { secondaryColors } = useTheme();

    const [ inputValue, setInputValue ] = useState(props.searchString || '');

    const handleKeyDown = (
        e: SyntheticEvent<HTMLInputElement, KeyboardEvent>
    ) => {
        if (e.nativeEvent.key === 'Enter') {
            props.handleQueryChange((e.target as HTMLInputElement).value);
        }
    };

    return (
        <InputRow>
            <Magnifier fill={ secondaryColors.grey30 } />
            <Input
                fullWidth
                size='md'
                onChange={ (e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value) }
                value={ inputValue }
                onKeyDown={ handleKeyDown }
                placeholder='Search for a song title, album or artist'
            />
        </InputRow>
    );
}

// eslint-disable-next-line arca/no-default-export
export default SearchBar;
