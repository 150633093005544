import React, { useEffect, useState } from 'react';
import { useParams }                  from 'react-router';
import { useInject }                  from '@geenee/shared/index';
import {
    CAMERA_ENVIRONMENT_MODE,
    CAMERA_FACE_MODE,
    TYPE_BODY_TRACKING_OVERLAY, TYPE_BODY_TRACKING_TWIN,
    TYPE_HEAD_TRACKING,
    TYPE_SLAM_AR,
    TYPE_STAMP_AR
} from '@geenee/shared/src/util/constants';
import { observer } from 'mobx-react';
import PropTypes    from 'prop-types';
import WebRTCHelper from './WebRTCHelper';

const typesToEnvironmentMode = [ TYPE_STAMP_AR, TYPE_SLAM_AR ];
const typesToFaceMode = [ TYPE_BODY_TRACKING_OVERLAY, TYPE_BODY_TRACKING_TWIN, TYPE_HEAD_TRACKING ];

const VideoStream = observer(({ onSuccess }) => {
    const { AppState } = useInject();
    const { activeSceneModel, firstAvailableSection } = AppState;
    const { projectId } = useParams();
    const rtcHelper = new WebRTCHelper();
    const isIOS = rtcHelper.isIOS();

    const [ error, setError ] = useState(null);

    const getConstraints = () => {
        const videoWidth = rtcHelper.isPortrait()
            ? window.innerHeight
            : window.innerWidth;
        const videoHeight = rtcHelper.isPortrait()
            ? window.innerWidth
            : window.innerHeight;

        return {
            video: { facingMode: AppState.videoStreamType, frameRate: { max: 30, ideal: 30, min: 30 } },
            audio: false
        };
    };

    const onRtcHelperSuccess = (video) => {
        video.id = 'rtcVideo';
        AppState.emit('geenee-camera-access-granted');
        onSuccess(video);
    };

    const onRtcHelperFail = (e) => {
        AppState.emit('geenee-camera-access-denied');
        setError(e.stack || 'Could not initialize video stream.');
    };

    const requestStream = () => {
        rtcHelper
            .getVideoStream(null, getConstraints())
            .then(onRtcHelperSuccess)
            .catch(onRtcHelperFail);
    };

    const requestVideo = () => {
        if (window.env && projectId) {
            const id = window?.env?.isPreview ? projectId : window?.env?.projectSchema?.id || projectId;
            console.log('rtc', id, projectId);
            if (!window.env.isPreview) {
                if (id === projectId) {
                    requestStream();
                }
            } else {
                requestStream();
            }
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const stopStreamedVideo = (video) => {
        const stream = video.srcObject;
        const tracks = stream.getTracks();

        tracks.forEach((track) => {
            track.stop();
        });

        rtcHelper
            .getVideoStream(video, getConstraints())
            .then(() => {
                // onSuccess(video);
            })
            .catch(onRtcHelperFail);
    };

    useEffect(() => {
        window.GeeneeAR = {
            ...window.GeeneeAR,
            requestVideoPermission: requestVideo // TODO: This is not right way to use actions. Use AppState instead. Need to be removed
        };

        if (typesToEnvironmentMode.includes(firstAvailableSection?.type)) {
            AppState.updateVideoStreamType(CAMERA_ENVIRONMENT_MODE);
        } else if (typesToFaceMode.includes((firstAvailableSection?.type))) {
            AppState.updateVideoStreamType(CAMERA_FACE_MODE);
        }

        AppState.emitter.addListener(
            'geenee-request-video-permission',
            requestVideo
        );

        if (!activeSceneModel?.geeneeUI?.videoPermission) {
            requestVideo();
        }

        return () => {
            rtcHelper
                .getVideoStream(null, getConstraints())
                .then(stopStreamedVideo)
                .catch(onRtcHelperFail);
        };
    }, []);

    if (error) {
        return <span>{ error.message }</span>;
    }

    return (
        <div>
            { /* <div style={ { */ }

            { /* // position: 'fixed', */ }
            { /* // top:      0, */ }
            { /* // right:    0, */ }
            { /* // bottom:   0, */ }
            { /* // left:     0, */ }
            { /* // zIndex:   3 */ }
            { /* // backdropFilter:       'blur(50px)', */ }
            { /* // '-webkit-backdrop-filter': ' blur(20px)', */ }
            { /* // WebkitBackdropFilter: ' blur(20px)' */ }
            { /* } } */ }
            { /* /> */ }
            { /* <div style={ { ...style, opacity: 1 } } ref={ rtcContainerRef } /> */ }
        </div>
    );
});

VideoStream.propType = { onSuccess: PropTypes.any.isRequired };
// eslint-disable-next-line arca/no-default-export
export default VideoStream;
