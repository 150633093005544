import { HeaderAtomOptType, initProps as headerTextProps } from "@geenee/geeclient-kit/src/lib/component/atom/header/util";
import { initProps as plainTextProps }                     from "@geenee/geeclient-kit/src/lib/component/atom/plain-text/util";
import { PlainTextAtomOptType }                            from "@geenee/geeclient-kit/src/lib/type/_atom-option-type";
import {
    addPrefixToObject,
    getPrefixedKeysObject,
    mapPrefixedPropsFromOwner
} from '../../../util/nestingProps';

export const prefix = 'logo_subtitle_';

export type LogoAtomOptType = {
    logo_width: string,
    logo_height: string,
    logo_image_src: string,
    logo_image_width: string,
    logo_image_height: string,
    logo_image_object_fit: 'cover' | 'contain',
  }
  & addPrefixToObject<PlainTextAtomOptType, 'logo_subtitle_'>
  & HeaderAtomOptType

const logoSubtitleProps = getPrefixedKeysObject(plainTextProps, prefix);

export const initProps : LogoAtomOptType = {
    ...headerTextProps,
    ...logoSubtitleProps,
    logo_subtitle_plain_text_align: 'center',
    logo_width:                     '50%',
    logo_height:                    'auto',
    header_text_font_uppercase:     true,
    logo_image_src:                 '',
    logo_image_width:               '100%',
    logo_image_height:              'auto',
    logo_image_object_fit:          'contain'
};

export const getPropsFromOwner = (options: LogoAtomOptType, extendProps: Record<string, any> = {}) => ({
    logo_width:                   options.logo_width,
    logo_height:                  options.logo_height,
    logo_image_src:               options.logo_image_src,
    logo_image_width:             options.logo_image_width,
    logo_image_height:            options.logo_image_height,
    header_text:                  options.header_text,
    header_text_font_size:        options.header_text_font_size,
    header_text_font_weight:      options.header_text_font_weight,
    header_text_font_line_height: options.header_text_font_line_height,
    header_text_font_type:        options.header_text_font_type,
    header_text_font_uppercase:   options.header_text_font_uppercase,
    header_text_color:            options.header_text_color,
    header_text_color_alpha:      options.header_text_color_alpha,
    header_text_color_type:       options.header_text_color_type,
    header_text_color_variation:  options.header_text_color_variation,
    header_text_color_custom:     options.header_text_color_custom,
    header_text_switch:           options.header_text_switch,
    ...mapPrefixedPropsFromOwner(logoSubtitleProps, options),
    ...extendProps
});
