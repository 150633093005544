import styled from 'styled-components';

// are we need this component? will use link from ui-kit ◔ᴗ◔

export const LinkWrapper = styled.a<{$fontSize: string}>`
    margin-right: 1em;
    display: inline-block;
    color: black;
    font-family: 'Gordita', Arial, Helvetica, sans-serif;
    font-weight: normal;
    cursor: pointer;

    &:hover{
        color: black;
        & > p {
            text-shadow: 1px 0 ${ ({ theme: { colors } }) => colors.black20 };
        }
    }
  
    p {
        margin: 3px;
        font-size: ${ ({ $fontSize }) => $fontSize };
        text-decoration: underline;
        transition: text-shadow .2s;
    }
`;
