import React                      from "react";
import { useInject }              from "@geenee/shared";
import { LoadingSpinnerCentered } from "@geenee/shared/src/magellan/components/LoadingSpinner";
import { Wrapper }                from "@geenee/ui";
import { observer }               from "mobx-react";
import { Tree }                   from "@geenee/builder/src/components/Tree";
import { MagellanState }          from "@geenee/builder/src/magellan/state/app.state";
import { MainContent }            from "@geenee/builder/src/pages/MainPage/MainContent";

export const MainPage = observer((props: any) => {
    const { AppState } = useInject();
    const { isInitialised } = AppState as unknown as MagellanState;

    return (
        <>
            { isInitialised ? (
                <Wrapper row fullHeight fullWidth>
                    <Tree />
                    <MainContent routes={ props.routes } />
                </Wrapper>
            ) : (
                <LoadingSpinnerCentered />
            ) }
        </>
    );
});
