import React                    from 'react';
import { Description, Wrapper } from '@geenee/ui';
// @ts-ignore
import nftPlaceholder           from '@geenee/builder/src/asset/images/NFT_placeholder.png';
import { PreviewCarousel }      from '@geenee/builder/src/components/modals/DoubleCheckNFT/PreviewCarousel';
import { NftItemModel }         from '@geenee/builder/src/core/model/nftItem.model';
// import {DescriptionColumn } from './components/DescriptionColumn'
import { InfoBlock }            from './styles';

type DataProps = {
  item: NftItemModel;
}

export function DescriptionColumn({ item }: DataProps) {
    return (
        <Wrapper fullWidth maxWidth='380px'>
            <Wrapper margin='md'>
                <PreviewCarousel items={ item.preview_images.length ? item.preview_images : [ nftPlaceholder ] } />
            </Wrapper>
            <InfoBlock>
                <Wrapper fullWidth>
                    <Description margin='sm' style={ { fontWeight: 'bold' } }>
                        { item.title }
                    </Description>
                    { /* <Description margin='sm'>
                    Category:
                    { ' ' }
                    { data.category }
                </Description> */ }
                    <Description margin='sm'>
                        Description:
                        { ' ' }
                        { item.description }
                    </Description>
                    <Description margin='sm'>
                        Price: ETH
                        { ' ' }
                        { item.price }
                        { /* { ' ' } */ }
                        { /* or 1.569471 ETH */ }
                    </Description>
                    { /* <HighlightContainer>
                    <Description>
                        Current Price: $400 or 2.569471 ETH
                    </Description>
                </HighlightContainer> */ }
                </Wrapper>
            </InfoBlock>
        </Wrapper>
    );
}
