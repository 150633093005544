/* eslint-disable max-len */
import React, { SyntheticEvent } from 'react';

export function Close({ children }: { children?: React.ReactNode }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.53034 4.46967C4.82323 4.17678 5.29811 4.17678 5.591 4.46967L10.5303 9.40901C10.8232 9.7019 10.8232 10.1768 10.5303 10.4697C10.2374 10.7626 9.76257 10.7626 9.46968 10.4697L4.53034 5.53033C4.23745 5.23744 4.23745 4.76256 4.53034 4.46967Z"
                fill="#222225"
            />
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.4697 4.46967C10.1768 4.17678 9.70189 4.17678 9.409 4.46967L4.46966 9.40901C4.17677 9.7019 4.17677 10.1768 4.46966 10.4697C4.76255 10.7626 5.23743 10.7626 5.53032 10.4697L10.4697 5.53033C10.7626 5.23744 10.7626 4.76256 10.4697 4.46967Z"
                fill="#222225"
            />
            { children }
        </svg>
    );
}
export function Close2({ onClick, children }: {onClick?: (e?: SyntheticEvent) => void, children?: React.ReactNode}) {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={ onClick }>
            <rect x="8.81543" y="0.0172729" width="1.24568" height="12.4568" rx="0.622839" transform="rotate(45 8.81543 0.0172729)" fill="#4E4E4E" className="fill" />
            <rect width="1.24568" height="12.4568" rx="0.622839" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 8.81543 9.98273)" fill="#4E4E4E" className="fill" />
            { children }
        </svg>
    );
}

export function CloseRound({ onClick, children }: {onClick?: (e?: SyntheticEvent) => void, children?: React.ReactNode}) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={ onClick }>
            <path
                className="fill"
                fill="#222225"
                d="M14.5303 9.46967C14.2374 9.17678 13.7626 9.17678 13.4697 9.46967L12 10.9393L10.5303 9.46967C10.2374 9.17678 9.76256 9.17678 9.46967 9.46967C9.17678 9.76256 9.17678 10.2374 9.46967 10.5303L10.9393 12L9.46967 13.4697C9.17678 13.7626 9.17678 14.2374 9.46967 14.5303C9.76256 14.8232 10.2374 14.8232 10.5303 14.5303L12 13.0607L13.4697 14.5303C13.7626 14.8232 14.2374 14.8232 14.5303 14.5303C14.8232 14.2374 14.8232 13.7626 14.5303 13.4697L13.0607 12L14.5303 10.5303C14.8232 10.2374 14.8232 9.76256 14.5303 9.46967Z"
            />
            <path
                className="fill"
                fill="#222225"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.25 12C3.25 7.16751 7.16751 3.25 12 3.25C16.8325 3.25 20.75 7.16751 20.75 12C20.75 16.8325 16.8325 20.75 12 20.75C7.16751 20.75 3.25 16.8325 3.25 12ZM12 4.75C7.99594 4.75 4.75 7.99594 4.75 12C4.75 16.0041 7.99594 19.25 12 19.25C16.0041 19.25 19.25 16.0041 19.25 12C19.25 7.99594 16.0041 4.75 12 4.75Z"
            />
            { children }
        </svg>
    );
}

export function Copy({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0.25C7.92893 0.25 6.25 1.92893 6.25 4V10C6.25 12.0711 7.92893 13.75 10 13.75H16C18.0711 13.75 19.75 12.0711 19.75 10V4C19.75 1.92893 18.0711 0.25 16 0.25H10ZM7.75 4C7.75 2.75736 8.75736 1.75 10 1.75H16C17.2426 1.75 18.25 2.75736 18.25 4V10C18.25 11.2426 17.2426 12.25 16 12.25H10C8.75736 12.25 7.75 11.2426 7.75 10V4Z"
                fill="#3E3B46"
            />
            <path
                className="fill"
                d="M0.25 16C0.25 18.0711 1.92893 19.75 4 19.75H10C12.0711 19.75 13.75 18.0711 13.75 16V15.5C13.75 15.0858 13.4142 14.75 13 14.75C12.5858 14.75 12.25 15.0858 12.25 15.5V16C12.25 17.2426 11.2426 18.25 10 18.25H4C2.75736 18.25 1.75 17.2426 1.75 16L1.75 10C1.75 8.75736 2.75736 7.75 4 7.75H4.5C4.91421 7.75 5.25 7.41421 5.25 7C5.25 6.58579 4.91421 6.25 4.5 6.25H4C1.92893 6.25 0.25 7.92893 0.25 10V16Z"
                fill="#3E3B46"
            />
            { children }
        </svg>
    );
}
export function Delete({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2V4H14C14.5523 4 15 4.44772 15 5C15 5.55228 14.5523 6 14 6H2C1.44772 6 1 5.55228 1 5C1 4.44772 1.44772 4 2 4H4V2C4 1.4 4.4 1 5 1H11C11.6 1 12 1.4 12 2ZM3 13V8H13V13C13 14.1 12.1 15 11 15H5C3.9 15 3 14.1 3 13Z"
                fill="#222225"
            />
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2V4H14C14.5523 4 15 4.44772 15 5C15 5.55228 14.5523 6 14 6H2C1.44772 6 1 5.55228 1 5C1 4.44772 1.44772 4 2 4H4V2C4 1.4 4.4 1 5 1H11C11.6 1 12 1.4 12 2ZM3 13V8H13V13C13 14.1 12.1 15 11 15H5C3.9 15 3 14.1 3 13Z"
                fill="#222225"
            />
            { children }
        </svg>
    );
}
export function Add({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="fill" fill="#222225" d="M16.912 7.91091H10.0912V1.09016C10.0912 0.490787 9.60045 0 9.00107 0C8.40168 0 7.91091 0.490771 7.91091 1.09016V7.91091H1.09016C0.490787 7.91091 0 8.40168 0 9.00107C0 9.60046 0.490771 10.0912 1.09016 10.0912H7.91091V16.9098C7.91091 17.5092 8.40168 18 9.00107 18C9.60045 18 10.0912 17.5092 10.0912 16.9098V10.0912H16.9098C17.5092 10.0912 18 9.60046 18 9.00107C18.0021 8.4017 17.5113 7.91091 16.9119 7.91091H16.912Z" />
            { children }
        </svg>
    );
}
export function Restore({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00003 1.25L8.03745 1.2501C9.61467 1.25867 11.0638 1.80819 12.2088 2.72256C12.629 3.05803 13.0081 3.44261 13.3377 3.86768C14.11 4.86392 14.6097 6.08223 14.7246 7.41061C14.7414 7.60496 14.75 7.80155 14.75 8C14.75 8.29521 14.731 8.58631 14.6941 8.87206C14.6818 8.96731 14.6675 9.06196 14.6513 9.15598C14.4877 10.1032 14.1267 10.9829 13.6126 11.7509L13.6126 11.7509C13.1201 12.4867 12.4867 13.12 11.751 13.6126L10.9165 12.3662C11.1114 12.2357 11.2971 12.0925 11.4724 11.9377L11.4724 11.9377C11.6236 11.8043 11.767 11.6623 11.9019 11.5126C11.9554 11.4532 12.0076 11.3926 12.0584 11.3307C12.1676 11.1979 12.2703 11.0597 12.3662 10.9165L12.3662 10.9165C12.9248 10.0821 13.25 9.07924 13.25 8C13.25 5.13485 10.9548 2.80575 8.10277 2.75098C8.06861 2.75033 8.03436 2.75 8.00003 2.75H7.99998C6.41954 2.75 5.00156 3.44809 4.03841 4.55468L5.65116 6.17486C5.96502 6.49018 5.74169 7.0276 5.29679 7.0276H0.5L0.500017 2.21102C0.500019 1.76502 1.03973 1.54219 1.35438 1.85829L2.9782 3.48959C4.21325 2.11548 6.00526 1.25 7.99998 1.25H8.00003ZM2.32535 11.6566C2.15194 11.3879 1.99702 11.1061 1.86243 10.8128L3.2257 10.1872C3.33024 10.4149 3.45066 10.634 3.58558 10.843L2.32535 11.6566ZM5.19792 14.1425C4.6092 13.8736 4.06625 13.5227 3.58353 13.1047L4.56539 11.9707C4.94146 12.2964 5.36392 12.5692 5.82121 12.7781L5.19792 14.1425ZM9.31655 14.6215C8.89015 14.7059 8.44987 14.75 8.00003 14.75C7.67489 14.75 7.3547 14.727 7.04109 14.6823L7.25251 13.1973C7.49629 13.232 7.74584 13.25 8.00003 13.25C8.35161 13.25 8.6944 13.2155 9.02545 13.1501L9.31655 14.6215Z"
                fill="#222225"
            />
            { children }
        </svg>
    );
}
export function Measure({ children }: {children?: React.ReactNode}) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 512.025 512.025"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M226.333,0.013h-160c-8.837,0-16,7.163-16,16v480c0,8.837,7.163,16,16,16h160c8.837,0,16-7.163,16-16v-480
				C242.333,7.176,235.169,0.013,226.333,0.013z M210.333,480.013h-128v-48h80v-32h-80v-48h48v-32h-48v-48h80v-32h-80v-48h48v-32
				h-48v-48h80v-32h-80v-48h128V480.013z"
                className='fill'
            />
            <path
                d="M439.133,404.813l-52.8,52.64V54.573l52.64,52.64l22.56-22.56l-80-80c-6.241-6.204-16.319-6.204-22.56,0l-80,80
				l22.56,22.56l52.8-52.64v402.88l-52.64-52.64l-22.56,22.56l80,80c6.241,6.204,16.319,6.204,22.56,0l80-80L439.133,404.813z"
                className='fill'
            />
            { children }
        </svg>
    );
}
export function Edit({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path
                d="M10.761 0.431078C10.1862 -0.143693 9.25776 -0.143693 8.68291 0.431078L1.12239 7.9766C1.0931 8.00589 1.07818 8.02082 1.06326 8.05011C1.06326 8.05011 1.06326 8.06503 1.04834 8.06503C1.03341 8.09432 1.01904 8.10924 1.01904 8.13853V8.15345L0.0170686 11.4252C-0.0271445 11.5872 0.0170686 11.7496 0.120417 11.8674C0.208843 11.9558 0.312196 12 0.429909 12C0.474122 12 0.518335 12 0.562549 11.9851L3.81947 10.9831H3.83439C3.86368 10.9682 3.89352 10.9682 3.90789 10.9538C3.90789 10.9538 3.92282 10.9538 3.92282 10.9389C3.95211 10.924 3.98195 10.9096 3.99632 10.8798L11.5418 3.33423C12.1166 2.75946 12.1166 1.83098 11.5418 1.25614L10.761 0.431078ZM1.10768 10.8798L1.65316 9.12614L2.87621 10.3492L1.10768 10.8798ZM3.7019 9.92205L2.06608 8.30109L7.7845 2.58267L9.42032 4.21848L3.7019 9.92205ZM10.923 2.68595L10.0387 3.57021L8.41778 1.94869L9.30204 1.06442C9.53803 0.828431 9.9061 0.828431 10.1421 1.06442L10.9379 1.86026C11.159 2.08133 11.159 2.46487 10.923 2.68594L10.923 2.68595Z"
                fill="black"
                className='fill'
            />
            { children }
        </svg>
    );
}
export function ToGround({ children }: {children?: React.ReactNode}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="18"
            fill="none"
            viewBox="0 0 17 18"
        >
            <path
                fill="#000"
                className='fill'
                d="M7.41 15.53a.75.75 0 001.061 0l4.774-4.773a.75.75 0 00-1.061-1.06L7.94 13.939 3.7 9.697a.75.75 0 10-1.061 1.06L7.41 15.53zM7.192 0v15h1.5V0h-1.5z"
            />
            <rect width="16.875" height="2" y="16" fill="#000" rx="1" className='fill' />
            { children }
        </svg>
    );
}
export function MoveTo({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.29289 3.70711C8.90237 3.31658 8.90237 2.68342 9.29289 2.29289C9.68342 1.90237 10.3166 1.90237 10.7071 2.29289L15.7071 7.29289C16.0976 7.68342 16.0976 8.31658 15.7071 8.70711L10.7071 13.7071C10.3166 14.0976 9.68342 14.0976 9.29289 13.7071C8.90237 13.3166 8.90237 12.6834 9.29289 12.2929L12.5858 9L9 9C8.44771 9 8 8.55228 8 8C8 7.44771 8.44772 7 9 7L12.5858 7L9.29289 3.70711ZM1 7H1.5C2.05228 7 2.5 7.44771 2.5 8C2.5 8.55228 2.05228 9 1.5 9H1C0.447715 9 0 8.55228 0 8C0 7.44771 0.447715 7 1 7ZM6 9C6.55228 9 7 8.55228 7 8C7 7.44772 6.55228 7 6 7H5C4.44772 7 4 7.44772 4 8C4 8.55228 4.44772 9 5 9H6Z"
                fill="#222225"
            />
            { children }
        </svg>
    );
}
export function Export({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.16663 0.75C8.26186 0.75 8.35295 0.76775 8.43676 0.800123C8.5316 0.836661 8.6205 0.893177 8.697 0.96967L11.8637 4.13634C12.1566 4.42923 12.1566 4.9041 11.8637 5.197C11.5708 5.48989 11.0959 5.48989 10.803 5.197L8.91663 3.31062V11C8.91663 11.4142 8.58084 11.75 8.16663 11.75C7.75241 11.75 7.41663 11.4142 7.41663 11V3.3107L5.53033 5.197C5.23744 5.48989 4.76256 5.48989 4.46967 5.197C4.17678 4.9041 4.17678 4.42923 4.46967 4.13634L7.63167 0.974337C7.64843 0.957286 7.66599 0.941033 7.6843 0.925639C7.7386 0.879934 7.79785 0.843148 7.86013 0.815283C7.9537 0.773332 8.05744 0.75 8.16663 0.75ZM1.75 11C1.75 10.5858 1.41421 10.25 1 10.25C0.585786 10.25 0.25 10.5858 0.25 11V14.2C0.25 15.1601 1.11655 15.75 1.93333 15.75H14.0667C14.8834 15.75 15.75 15.1601 15.75 14.2V11C15.75 10.5858 15.4142 10.25 15 10.25C14.5858 10.25 14.25 10.5858 14.25 11V14.185C14.2476 14.1877 14.2439 14.1915 14.2384 14.1962C14.2105 14.2201 14.152 14.25 14.0667 14.25H1.93333C1.84796 14.25 1.78948 14.2201 1.76156 14.1962C1.75612 14.1915 1.75243 14.1877 1.75 14.185V11ZM14.2541 14.1793C14.2542 14.1793 14.254 14.1798 14.2532 14.1809C14.2537 14.1798 14.2541 14.1792 14.2541 14.1793ZM1.74679 14.1809C1.74629 14.1798 1.74592 14.1792 1.74585 14.1793C1.74583 14.1793 1.74586 14.1794 1.74599 14.1796C1.74613 14.1799 1.74639 14.1803 1.74679 14.1809Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}

export function Star({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                d="M14.2499 5.4238L10.4734 4.87442L8.78761 1.45303C8.7075 1.31521 8.59262 1.20084 8.45446 1.12134C8.31629 1.04184 8.15968 1 8.00028 1C7.84087 1 7.68426 1.04184 7.5461 1.12134C7.40793 1.20084 7.29305 1.31521 7.21295 1.45303L5.52718 4.87529L1.75062 5.4238C1.5889 5.44699 1.4369 5.51501 1.31185 5.62015C1.1868 5.72529 1.09368 5.86334 1.04306 6.01868C0.992433 6.17401 0.986319 6.34042 1.02541 6.49905C1.06449 6.65768 1.14722 6.8022 1.26422 6.91623L3.99626 9.58003L3.35152 13.3417C3.32396 13.5026 3.34195 13.668 3.40347 13.8192C3.46499 13.9704 3.56758 14.1013 3.69964 14.1973C3.8317 14.2932 3.98796 14.3503 4.15076 14.362C4.31356 14.3738 4.4764 14.3397 4.62088 14.2638L8.00028 12.487L11.3779 14.262C11.5224 14.338 11.6852 14.372 11.848 14.3603C12.0108 14.3485 12.1671 14.2914 12.2992 14.1955C12.4312 14.0996 12.5338 13.9686 12.5953 13.8174C12.6569 13.6662 12.6748 13.5009 12.6473 13.34L12.0025 9.57828L14.7363 6.91623C14.853 6.80228 14.9356 6.65797 14.9746 6.49959C15.0136 6.34122 15.0076 6.17508 14.9572 6.01996C14.9068 5.86483 14.814 5.72688 14.6893 5.62169C14.5647 5.5165 14.4131 5.44826 14.2517 5.42467L14.2499 5.4238Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}

export function Move({
    width = '24',
    height = '24',
    onClick,
    id,
    children
}: React.ReactNode & {width?: string; height?: string; onClick?: () => void; id?: string, children?: React.ReactNode}) {
    return (
        <svg
            id={ id }
            width={ width }
            height={ height }
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={ onClick }
        >
            <path className="fill" d="M12.5302 2.46966C12.2373 2.17677 11.7625 2.17678 11.4696 2.46968L8.46966 5.46967C8.17677 5.76257 8.17678 6.23744 8.46968 6.53033C8.76257 6.82322 9.23745 6.82322 9.53034 6.53032L11.25 4.81061V11.25H4.81066L6.53033 9.53033C6.82322 9.23744 6.82322 8.76256 6.53033 8.46967C6.23744 8.17678 5.76256 8.17678 5.46967 8.46967L2.46967 11.4697C2.17678 11.7626 2.17678 12.2374 2.46967 12.5303L5.46967 15.5303C5.76256 15.8232 6.23744 15.8232 6.53033 15.5303C6.82322 15.2374 6.82322 14.7626 6.53033 14.4697L4.81066 12.75H11.25V19.1893L9.53033 17.4697C9.23744 17.1768 8.76256 17.1768 8.46967 17.4697C8.17678 17.7626 8.17678 18.2374 8.46967 18.5303L11.4697 21.5303C11.7626 21.8232 12.2374 21.8232 12.5303 21.5303L15.5303 18.5303C15.8232 18.2374 15.8232 17.7626 15.5303 17.4697C15.2374 17.1768 14.7626 17.1768 14.4697 17.4697L12.75 19.1893V12.75H19.1893L17.4697 14.4697C17.1768 14.7626 17.1768 15.2374 17.4697 15.5303C17.7626 15.8232 18.2375 15.8232 18.5303 15.5303L21.5303 12.5302C21.8232 12.2373 21.8232 11.7625 21.5303 11.4696L18.5303 8.46966C18.2374 8.17677 17.7626 8.17678 17.4697 8.46968C17.1768 8.76257 17.1768 9.23745 17.4697 9.53034L19.1894 11.25H12.75V4.8107L14.4697 6.53033C14.7626 6.82322 15.2374 6.82321 15.5303 6.53032C15.8232 6.23742 15.8232 5.76255 15.5303 5.46966L12.5302 2.46966Z" fill="#3E3B46" />
            { children }
        </svg>
    );
}

export function Rotate({ width = '24', height = '24', onClick, children }: React.ReactNode & {width?: string; height?: string; onClick?: () => void, children?: React.ReactNode}) {
    return (
        <svg width={ width } height={ height } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={ onClick }>
            <path className="fill" d="M5.85053 6.50019C7.36224 4.81118 9.5567 3.75 12 3.75C16.5563 3.75 20.25 7.44365 20.25 12V13.1893L19.5303 12.4697C19.2374 12.1768 18.7626 12.1768 18.4697 12.4697C18.1768 12.7626 18.1768 13.2374 18.4697 13.5303L20.4697 15.5303C20.7626 15.8232 21.2374 15.8232 21.5303 15.5303L23.5303 13.5303C23.8232 13.2374 23.8232 12.7626 23.5303 12.4697C23.2374 12.1768 22.7626 12.1768 22.4697 12.4697L21.75 13.1893V12C21.75 6.61522 17.3848 2.25 12 2.25C9.1123 2.25 6.51705 3.50633 4.73283 5.49981C4.45658 5.80846 4.48285 6.28261 4.79149 6.55885C5.10014 6.8351 5.57429 6.80883 5.85053 6.50019Z" fill="#3E3B46" />
            <path className="fill" d="M2.25 10.8107L1.53033 11.5303C1.23744 11.8232 0.762563 11.8232 0.46967 11.5303C0.176777 11.2374 0.176777 10.7626 0.46967 10.4697L2.46967 8.46967C2.76256 8.17678 3.23744 8.17678 3.53033 8.46967L5.53033 10.4697C5.82322 10.7626 5.82322 11.2374 5.53033 11.5303C5.23744 11.8232 4.76256 11.8232 4.46967 11.5303L3.75 10.8107V12C3.75 16.5563 7.44365 20.25 12 20.25C14.4433 20.25 16.6378 19.1888 18.1495 17.4998C18.4257 17.1912 18.8999 17.1649 19.2085 17.4411C19.5172 17.7174 19.5434 18.1915 19.2672 18.5002C17.483 20.4937 14.8877 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12V10.8107Z" fill="#3E3B46" />
            { children }
        </svg>
    );
}

export function Pick({ width = '24', height = '24', onClick, children }: React.ReactNode & {width?: string; height?: string; onClick?: () => void, children?: React.ReactNode}) {
    return (
        <svg width={ width } height={ height } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={ onClick }>
            <path className="fill" d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="#3E3B46" />
            <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M12 1.25C12.4142 1.25 12.75 1.58579 12.75 2V3.28169C16.9842 3.64113 20.3589 7.01581 20.7183 11.25H22C22.4142 11.25 22.75 11.5858 22.75 12C22.75 12.4142 22.4142 12.75 22 12.75H20.7183C20.3589 16.9842 16.9842 20.3589 12.75 20.7183V22C12.75 22.4142 12.4142 22.75 12 22.75C11.5858 22.75 11.25 22.4142 11.25 22V20.7183C7.01581 20.3589 3.64113 16.9842 3.28169 12.75H2C1.58579 12.75 1.25 12.4142 1.25 12C1.25 11.5858 1.58579 11.25 2 11.25H3.28169C3.64113 7.01581 7.01581 3.64113 11.25 3.28169V2C11.25 1.58579 11.5858 1.25 12 1.25ZM11.25 4.78832C7.84488 5.13828 5.13828 7.84488 4.78832 11.25H6C6.41421 11.25 6.75 11.5858 6.75 12C6.75 12.4142 6.41421 12.75 6 12.75H4.78832C5.13828 16.1551 7.84488 18.8617 11.25 19.2117V18C11.25 17.5858 11.5858 17.25 12 17.25C12.4142 17.25 12.75 17.5858 12.75 18V19.2117C16.1551 18.8617 18.8617 16.1551 19.2117 12.75H18C17.5858 12.75 17.25 12.4142 17.25 12C17.25 11.5858 17.5858 11.25 18 11.25H19.2117C18.8617 7.84488 16.1551 5.13828 12.75 4.78832V6C12.75 6.41421 12.4142 6.75 12 6.75C11.5858 6.75 11.25 6.41421 11.25 6V4.78832Z" fill="#3E3B46" />
            { children }
        </svg>
    );
}

export function BoundingBox({ width = '24', height = '24', onClick, children }: React.ReactNode & {width?: string; height?: string; onClick?: () => void, children?: React.ReactNode}) {
    return (
        <svg width={ width } height={ height } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={ onClick }>
            <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M2.25 3C2.25 2.58579 2.58579 2.25 3 2.25H7C7.41421 2.25 7.75 2.58579 7.75 3V4.25H16.25V3C16.25 2.58579 16.5858 2.25 17 2.25H21C21.4142 2.25 21.75 2.58579 21.75 3V7C21.75 7.41421 21.4142 7.75 21 7.75H19.75V16.25H21C21.4142 16.25 21.75 16.5858 21.75 17V21C21.75 21.4142 21.4142 21.75 21 21.75H17C16.5858 21.75 16.25 21.4142 16.25 21V19.75H7.75V21C7.75 21.4142 7.41421 21.75 7 21.75H3C2.58579 21.75 2.25 21.4142 2.25 21V17C2.25 16.5858 2.58579 16.25 3 16.25H4.25V7.75H3C2.58579 7.75 2.25 7.41421 2.25 7V3ZM17.75 20.25H20.25V17.75H17.75V20.25ZM18.25 16.25H17C16.5858 16.25 16.25 16.5858 16.25 17V18.25H7.75V17C7.75 16.5858 7.41421 16.25 7 16.25H5.75V7.75H7C7.41421 7.75 7.75 7.41421 7.75 7V5.75H16.25V7C16.25 7.41421 16.5858 7.75 17 7.75H18.25V16.25ZM6.25 17.75V20.25H3.75V17.75H6.25ZM20.25 6.25V3.75H17.75V6.25H20.25ZM6.25 3.75V6.25H3.75V3.75H6.25Z" fill="#3E3B46" />
            { children }
        </svg>
    );
}

export function Scale({ width = '24', height = '24', onClick, children }: React.ReactNode & {width?: string; height?: string; onClick?: () => void, children?: React.ReactNode}) {
    return (
        <svg
            width={ width }
            height={ height }
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={ onClick }
        >
            <path className="fill" d="M5 2.25C2.92893 2.25 1.25 3.92893 1.25 6V7.5C1.25 7.91421 1.58579 8.25 2 8.25C2.41421 8.25 2.75 7.91421 2.75 7.5V6C2.75 4.75736 3.75736 3.75 5 3.75H19C20.2426 3.75 21.25 4.75736 21.25 6V18C21.25 19.2426 20.2426 20.25 19 20.25H17.5C17.0858 20.25 16.75 20.5858 16.75 21C16.75 21.4142 17.0858 21.75 17.5 21.75H19C21.0711 21.75 22.75 20.0711 22.75 18V6C22.75 3.92893 21.0711 2.25 19 2.25H5Z" fill="#3E3B46" />
            <path className="fill" d="M12 16.75C12.4142 16.75 12.75 16.4142 12.75 16C12.75 15.5858 12.4142 15.25 12 15.25H10.8107L13.0303 13.0303C13.3232 12.7374 13.3232 12.2626 13.0303 11.9697C12.7374 11.6768 12.2626 11.6768 11.9697 11.9697L9.75 14.1893V13C9.75 12.5858 9.41421 12.25 9 12.25C8.58579 12.25 8.25 12.5858 8.25 13L8.25 16C8.25 16.4142 8.58579 16.75 9 16.75H12Z" fill="#3E3B46" />
            <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M4 9.25C2.48122 9.25 1.25 10.4812 1.25 12V19C1.25 20.5188 2.48122 21.75 4 21.75H13C14.5188 21.75 15.75 20.5188 15.75 19V12C15.75 10.4812 14.5188 9.25 13 9.25H4ZM2.75 12C2.75 11.3096 3.30964 10.75 4 10.75H13C13.6904 10.75 14.25 11.3096 14.25 12V19C14.25 19.6904 13.6904 20.25 13 20.25H4C3.30964 20.25 2.75 19.6904 2.75 19V12Z" fill="#3E3B46" />
            <path className="fill" d="M15.25 6C15.25 5.58579 15.5858 5.25 16 5.25H18.9997C19.0126 5.25 19.0258 5.25033 19.0386 5.25098C19.1262 5.25542 19.2099 5.27491 19.2871 5.30691C19.3755 5.34351 19.4584 5.39776 19.5303 5.46967C19.6768 5.61612 19.75 5.80806 19.75 6V9C19.75 9.41421 19.4142 9.75 19 9.75C18.5858 9.75 18.25 9.41421 18.25 9V7.81066L16.5303 9.53033C16.2374 9.82322 15.7626 9.82322 15.4697 9.53033C15.1768 9.23744 15.1768 8.76256 15.4697 8.46967L17.1893 6.75H16C15.5858 6.75 15.25 6.41421 15.25 6Z" fill="#3E3B46" />
            { children }
        </svg>
    );
}

export function AgainstArrows({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.5303 4.53033C20.8232 4.23744 20.8232 3.76256 20.5303 3.46967C20.2374 3.17678 19.7626 3.17678 19.4697 3.46967L14.75 8.18934V6C14.75 5.58579 14.4142 5.25 14 5.25C13.5858 5.25 13.25 5.58579 13.25 6V10C13.25 10.4142 13.5858 10.75 14 10.75H18C18.4142 10.75 18.75 10.4142 18.75 10C18.75 9.58579 18.4142 9.25 18 9.25H15.8107L20.5303 4.53033Z" fill="#3E3B46" className='fill' />
            <path d="M9.25 18C9.25 18.4142 9.58579 18.75 10 18.75C10.4142 18.75 10.75 18.4142 10.75 18L10.75 14C10.75 13.5858 10.4142 13.25 10 13.25H6C5.58579 13.25 5.25 13.5858 5.25 14C5.25 14.4142 5.58579 14.75 6 14.75H8.18934L3.46967 19.4697C3.17678 19.7626 3.17678 20.2374 3.46967 20.5303C3.76256 20.8232 4.23744 20.8232 4.53033 20.5303L9.25 15.8107L9.25 18Z" fill="#3E3B46" className='fill' />
            { children }
        </svg>
    );
}

export function Trash({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path
                className="fill"
                fill="#222225"
                d="M24 14.5C24.8284 14.5 25.5 15.1716 25.5 16V28C25.5 28.8284 24.8284 29.5 24 29.5C23.1716 29.5 22.5 28.8284 22.5 28V16C22.5 15.1716 23.1716 14.5 24 14.5Z"
            />
            <path
                className="fill"
                fill="#222225"
                d="M17.5 16C17.5 15.1716 16.8284 14.5 16 14.5C15.1716 14.5 14.5 15.1716 14.5 16V28C14.5 28.8284 15.1716 29.5 16 29.5C16.8284 29.5 17.5 28.8284 17.5 28V16Z"
            />
            <path
                className="fill"
                fill="#222225"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5 0.5C12.6716 0.5 12 1.17157 12 2V4.5H2C1.17157 4.5 0.5 5.17157 0.5 6C0.5 6.82843 1.17157 7.5 2 7.5H4.5V34C4.5 37.0376 6.96243 39.5 10 39.5H30C33.0376 39.5 35.5 37.0376 35.5 34V7.5H38C38.8284 7.5 39.5 6.82843 39.5 6C39.5 5.17157 38.8284 4.5 38 4.5H27V2C27 1.17157 26.3284 0.5 25.5 0.5H13.5ZM7.5 7.5V34C7.5 35.3807 8.61929 36.5 10 36.5H30C31.3807 36.5 32.5 35.3807 32.5 34V7.5H7.5Z"
            />
            { children }
        </svg>
    );
}

export function Edit2({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path
                className="fill"
                fill="#222225"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.5251 1.3535C31.892 -0.0133371 34.108 -0.0133421 35.4749 1.35349L38.6464 4.52507C40.0133 5.8919 40.0133 8.10798 38.6464 9.47481L35.0687 13.0525L35.0607 13.0607L35.0525 13.0687L18.818 29.3032C17.4115 30.7098 15.5038 31.4999 13.5147 31.4999H10C9.17157 31.4999 8.5 30.8284 8.5 29.9999V26.4852C8.5 24.4961 9.29018 22.5884 10.6967 21.1819L30.5251 1.3535ZM34 9.87865L36.5251 7.35349C36.7204 7.15823 36.7204 6.84165 36.5251 6.64639L33.3536 3.47481C33.1583 3.27955 32.8417 3.27955 32.6464 3.47482L30.1213 5.99997L34 9.87865ZM28 8.12129L31.8787 12L16.6967 27.1819C15.8528 28.0258 14.7082 28.4999 13.5147 28.4999H11.5V26.4852C11.5 25.2917 11.9741 24.1472 12.818 23.3032L28 8.12129Z"
            />
            <path
                className="fill"
                fill="#222225"
                d="M3.5 32C3.5 34.4853 5.51472 36.5 8 36.5H32C34.4853 36.5 36.5 34.4853 36.5 32V18C36.5 17.1716 37.1716 16.5 38 16.5C38.8284 16.5 39.5 17.1716 39.5 18V32C39.5 36.1421 36.1421 39.5 32 39.5H8C3.85787 39.5 0.5 36.1421 0.5 32V8C0.5 3.85786 3.85786 0.5 8 0.5L22 0.499999C22.8284 0.499999 23.5 1.17157 23.5 2C23.5 2.82843 22.8284 3.5 22 3.5L8 3.5C5.51472 3.5 3.5 5.51472 3.5 8L3.5 32Z"
            />
            { children }
        </svg>
    );
}

export function Cancel({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path
                className="fill"
                d="M7.80474 4.19526C7.54439 3.93491 7.12228 3.93491 6.86193 4.19526L6 5.05719L5.13807 4.19526C4.87772 3.93491 4.45561 3.93491 4.19526 4.19526C3.93491 4.45561 3.93491 4.87772 4.19526 5.13807L5.05719 6L4.19526 6.86193C3.93491 7.12228 3.93491 7.54439 4.19526 7.80474C4.45561 8.06509 4.87772 8.06509 5.13807 7.80474L6 6.94281L6.86193 7.80474C7.12228 8.06509 7.54439 8.06509 7.80474 7.80474C8.06509 7.54439 8.06509 7.12228 7.80474 6.86193L6.94281 6L7.80474 5.13807C8.06509 4.87772 8.06509 4.45561 7.80474 4.19526Z"
                fill="#3E394B"
            />
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6ZM6 1.33333C3.42267 1.33333 1.33333 3.42267 1.33333 6C1.33333 8.57733 3.42267 10.6667 6 10.6667C8.57733 10.6667 10.6667 8.57733 10.6667 6C10.6667 3.42267 8.57733 1.33333 6 1.33333Z"
                fill="#3E394B"
            />
            { children }
        </svg>
    );
}

export function Refresh({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
            <path
                className="fill"
                d="M7.33329 0.666667C7.33329 0.298477 7.63177 0 7.99996 0C11.3137 0 14 2.68629 14 6V6.39052L14.1952 6.19526C14.4556 5.93491 14.8777 5.93491 15.138 6.19526C15.3984 6.45561 15.3984 6.87772 15.138 7.13807L13.8047 8.47141C13.5443 8.73175 13.1222 8.73175 12.8619 8.47141L11.5286 7.13807C11.2682 6.87772 11.2682 6.45561 11.5286 6.19526C11.7889 5.93491 12.211 5.93491 12.4714 6.19526L12.6666 6.39052V6C12.6666 3.42267 10.5773 1.33333 7.99996 1.33333C7.63177 1.33333 7.33329 1.03486 7.33329 0.666667Z"
                fill="#3E394B"
            />
            <path
                className="fill"
                d="M3.34183 6.28468L3.52855 6.4714C3.7889 6.73175 4.21101 6.73175 4.47136 6.4714C4.73171 6.21106 4.73171 5.78895 4.47136 5.5286L3.13803 4.19526C2.87768 3.93491 2.45557 3.93491 2.19522 4.19526L0.861888 5.5286C0.601539 5.78895 0.601539 6.21106 0.861888 6.4714C1.12224 6.73175 1.54435 6.73175 1.8047 6.4714L2.00593 6.27017C2.14722 9.4585 4.77679 12 7.99996 12C8.36815 12 8.66663 11.7015 8.66663 11.3333C8.66663 10.9651 8.36815 10.6667 7.99996 10.6667C5.51822 10.6667 3.48895 8.72944 3.34183 6.28468Z"
                fill="#3E394B"
            />
            { children }
        </svg>
    );
}

export function Info({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                className='fill'
                fill="#222225"
                d="M8.66675 5.99996C8.66675 6.36815 8.36827 6.66663 8.00008 6.66663C7.63189 6.66663 7.33341 6.36815 7.33341 5.99996C7.33341 5.63177 7.63189 5.33329 8.00008 5.33329C8.36827 5.33329 8.66675 5.63177 8.66675 5.99996Z"
            />
            <path
                className='fill'
                fill="#222225"
                d="M7.50008 7.99996C7.50008 7.72382 7.72394 7.49996 8.00008 7.49996C8.27622 7.49996 8.50008 7.72382 8.50008 7.99996V10.6666C8.50008 10.9428 8.27622 11.1666 8.00008 11.1666C7.72394 11.1666 7.50008 10.9428 7.50008 10.6666V7.99996Z"
            />
            <path
                className='fill'
                fill="#222225"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00008 2.16663C4.77842 2.16663 2.16675 4.7783 2.16675 7.99996C2.16675 11.2216 4.77842 13.8333 8.00008 13.8333C11.2217 13.8333 13.8334 11.2216 13.8334 7.99996C13.8334 4.7783 11.2217 2.16663 8.00008 2.16663ZM3.16675 7.99996C3.16675 5.33058 5.3307 3.16663 8.00008 3.16663C10.6695 3.16663 12.8334 5.33058 12.8334 7.99996C12.8334 10.6693 10.6695 12.8333 8.00008 12.8333C5.3307 12.8333 3.16675 10.6693 3.16675 7.99996Z"
            />
            { children }
        </svg>
    );
}

export function Error({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                className="fill"
                d="M7.99967 4.00006C7.63148 4.00006 7.33301 4.29854 7.33301 4.66673V8.3334C7.33301 8.70159 7.63148 9.00006 7.99967 9.00006C8.36786 9.00006 8.66634 8.70159 8.66634 8.3334V4.66673C8.66634 4.29854 8.36786 4.00006 7.99967 4.00006Z"
                fill="#222225"
            />
            <path
                className="fill"
                d="M8.99967 11.0001C8.99967 11.5523 8.55196 12.0001 7.99967 12.0001C7.44739 12.0001 6.99967 11.5523 6.99967 11.0001C6.99967 10.4478 7.44739 10.0001 7.99967 10.0001C8.55196 10.0001 8.99967 10.4478 8.99967 11.0001Z"
                fill="#222225"
            />
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.33301 5.45124V10.5488C1.33301 11.2752 1.72679 11.9444 2.36172 12.2971L7.02839 14.8897C7.63243 15.2253 8.36692 15.2253 8.97096 14.8897L13.6376 12.2971C14.2726 11.9444 14.6663 11.2752 14.6663 10.5488V5.45123C14.6663 4.7249 14.2726 4.05566 13.6376 3.70292L8.97096 1.11033C8.36691 0.774747 7.63243 0.774747 7.02839 1.11033L2.36172 3.70292C1.72679 4.05566 1.33301 4.7249 1.33301 5.45124ZM2.66634 10.5488L2.66634 5.45124C2.66634 5.20912 2.7976 4.98604 3.00925 4.86846L7.67591 2.27587C7.87726 2.16401 8.12209 2.16401 8.32344 2.27587L12.9901 4.86846C13.2017 4.98604 13.333 5.20912 13.333 5.45123V10.5488C13.333 10.7909 13.2017 11.014 12.9901 11.1316L8.32344 13.7242C8.12209 13.836 7.87726 13.836 7.67591 13.7242L3.00925 11.1316C2.7976 11.014 2.66634 10.7909 2.66634 10.5488Z"
                fill="#222225"
            />
            { children }
        </svg>
    );
}

export function Warning({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.4936 2.48074C10.8653 1.75033 11.6242 1.25 12.5 1.25C13.3757 1.25 14.1346 1.75032 14.5064 2.48071C14.8061 2.33299 15.1433 2.25 15.5 2.25C16.7426 2.25 17.75 3.25736 17.75 4.5V5.87799C17.9846 5.7951 18.237 5.75 18.4999 5.75C19.7425 5.75 20.7499 6.75736 20.7499 8V16C20.7499 19.1756 18.1755 21.75 14.9999 21.75H11.1072C9.23624 21.75 7.44919 20.9734 6.17259 19.6056L2.137 15.2818C1.20006 14.2779 1.22704 12.7122 2.19802 11.7412C3.19317 10.7461 4.80662 10.7461 5.80177 11.7412L7.2499 13.1893V4.5C7.2499 3.25736 8.25726 2.25 9.4999 2.25C9.85659 2.25 10.1939 2.333 10.4936 2.48074ZM11.7499 12C11.7499 12.4142 11.4141 12.75 10.9999 12.75C10.5857 12.75 10.2499 12.4142 10.2499 12V4.5C10.2499 4.08579 9.91411 3.75 9.4999 3.75C9.08569 3.75 8.7499 4.08579 8.7499 4.5V13.7929C8.7499 14.9065 7.40347 15.4642 6.61602 14.6768L4.74111 12.8019C4.33175 12.3925 3.66805 12.3925 3.25868 12.8019C2.85926 13.2013 2.84816 13.8454 3.23358 14.2583L7.26917 18.5822C8.26209 19.646 9.65201 20.25 11.1072 20.25H14.9999C17.3471 20.25 19.2499 18.3472 19.2499 16V8C19.2499 7.58579 18.9141 7.25 18.4999 7.25C18.0898 7.25 17.7566 7.57908 17.75 7.98756V12C17.75 12.4142 17.4142 12.75 17 12.75C16.5858 12.75 16.25 12.4142 16.25 12C16.25 9.49996 16.25 7.00006 16.25 4.5C16.25 4.08579 15.9142 3.75 15.5 3.75C15.0858 3.75 14.75 4.08579 14.75 4.5V12C14.75 12.4142 14.4142 12.75 14 12.75C13.5858 12.75 13.25 12.4142 13.25 12V3.5C13.25 3.08579 12.9142 2.75 12.5 2.75C12.0858 2.75 11.75 3.08579 11.75 3.5C11.75 6.33333 11.7499 9.16667 11.7499 12Z"
                fill="#3E3B46"
            />
            { children }
        </svg>
    );
}

export function Lock({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="40" viewBox="0 0 36 40" fill="none">
            <path
                className="fill"
                fill="#222225"
                d="M18 28C19.1046 28 20 27.1046 20 26C20 24.8954 19.1046 24 18 24C16.8954 24 16 24.8954 16 26C16 27.1046 16.8954 28 18 28Z"
            />
            <path
                className="fill"
                fill="#222225"
                d="M28 26C28 27.1046 27.1046 28 26 28C24.8954 28 24 27.1046 24 26C24 24.8954 24.8954 24 26 24C27.1046 24 28 24.8954 28 26Z"
            />
            <path
                className="fill"
                fill="#222225"
                d="M10 28C11.1046 28 12 27.1046 12 26C12 24.8954 11.1046 24 10 24C8.89543 24 8 24.8954 8 26C8 27.1046 8.89543 28 10 28Z"
            />
            <path
                className="fill"
                fill="#222225"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.5 10V12.5H8C3.85786 12.5 0.5 15.8579 0.5 20V32C0.5 36.1421 3.85786 39.5 8 39.5H28C32.1421 39.5 35.5 36.1421 35.5 32V20C35.5 15.8579 32.1421 12.5 28 12.5H27.5V10C27.5 4.75329 23.2467 0.5 18 0.5C12.7533 0.5 8.5 4.75329 8.5 10ZM18 3.5C14.4101 3.5 11.5 6.41015 11.5 10V12.5H24.5V10C24.5 6.41015 21.5899 3.5 18 3.5ZM8 15.5C5.51472 15.5 3.5 17.5147 3.5 20V32C3.5 34.4853 5.51472 36.5 8 36.5H28C30.4853 36.5 32.5 34.4853 32.5 32V20C32.5 17.5147 30.4853 15.5 28 15.5H8Z"
            />
            { children }
        </svg>
    );
}

export const ColorPicker = ({ children }: {children?: React.ReactNode}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            className="fill"
            d="M2.66671 11.9999C2.66671 12.7363 3.26366 13.3333 4.00004 13.3333H12C12.7364 13.3333 13.3334 12.7363 13.3334 11.9999V9.99992C13.3334 9.63173 13.6319 9.33325 14 9.33325C14.3682 9.33325 14.6667 9.63173 14.6667 9.99992V11.9999C14.6667 13.4727 13.4728 14.6666 12 14.6666H4.00004C2.52728 14.6666 1.33337 13.4727 1.33337 11.9999V3.99992C1.33337 2.52716 2.52728 1.33325 4.00004 1.33325H6.00004C6.36823 1.33325 6.66671 1.63173 6.66671 1.99992C6.66671 2.36811 6.36823 2.66658 6.00004 2.66658L4.00004 2.66659C3.26366 2.66659 2.66671 3.26354 2.66671 3.99992L2.66671 11.9999Z"
            fill="#3E3B46"
        />
        <path
            className="fill"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.86197 4.47131C6.60162 4.21096 6.60162 3.78885 6.86197 3.5285C7.12232 3.26815 7.54443 3.26815 7.80478 3.5285L8.1953 3.91903C8.45565 4.17938 8.87776 4.17938 9.13811 3.91903L10.862 2.19517C11.6746 1.38254 12.9921 1.38253 13.8048 2.19517C14.6174 3.0078 14.6174 4.32534 13.8048 5.13798L12.0809 6.86183C11.8206 7.12218 11.8206 7.5443 12.0809 7.80465L12.4714 8.19517C12.7318 8.45552 12.7318 8.87763 12.4714 9.13798C12.2111 9.39833 11.789 9.39833 11.5286 9.13798L10.6667 8.27607L7.7239 11.2189C7.2238 11.719 6.54552 11.9999 5.83828 11.9999H4.66671C4.29852 11.9999 4.00004 11.7015 4.00004 11.3333V10.1617C4.00004 9.45445 4.28099 8.77618 4.78109 8.27608L7.72391 5.33326L6.86197 4.47131ZM11.8048 3.13798C12.0967 2.84604 12.57 2.84604 12.862 3.13798C13.1539 3.42991 13.1539 3.90323 12.862 4.19517L11.1381 5.91903C10.9728 6.08432 10.8425 6.27135 10.7472 6.47091L9.52904 5.25277C9.7286 5.15744 9.91563 5.02713 10.0809 4.86184L11.8048 3.13798ZM9.72391 7.33326L6.78109 10.2761C6.53104 10.5261 6.1919 10.6666 5.83828 10.6666H5.33337V10.1617C5.33337 9.80808 5.47385 9.46894 5.7239 9.21889L8.66672 6.27607L9.72391 7.33326Z"
            fill="#3E3B46"
        />
        { children }
    </svg>
);

export function AddImage({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
            <path
                className="fill"
                d="M40.5 10C40.5 10.8284 41.1716 11.5 42 11.5C42.8284 11.5 43.5 10.8284 43.5 10V7.5L46 7.5C46.8284 7.5 47.5 6.82843 47.5 6C47.5 5.17157 46.8284 4.5 46 4.5L43.5 4.5V2C43.5 1.17157 42.8284 0.5 42 0.5C41.1716 0.5 40.5 1.17157 40.5 2V4.5L38 4.5C37.1716 4.5 36.5 5.17157 36.5 6C36.5 6.82843 37.1716 7.5 38 7.5L40.5 7.5V10Z"
                fill="url(#gradient-warm-1)"
            />
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.93852 37.94C7.65742 37.3526 7.5 36.6947 7.5 36L7.5 12C7.5 9.51472 9.51472 7.5 12 7.5L32 7.5C32.8284 7.5 33.5 6.82842 33.5 6C33.5 5.17157 32.8284 4.5 32 4.5L12 4.5C7.85786 4.5 4.5 7.85786 4.5 12V36C4.5 40.1421 7.85787 43.5 12 43.5H36C40.1421 43.5 43.5 40.1421 43.5 36V16C43.5 15.1716 42.8284 14.5 42 14.5C41.1716 14.5 40.5 15.1716 40.5 16V28.3786L36.8891 24.7677C34.7412 22.6198 31.2588 22.6198 29.1109 24.7677L25 28.8786L24.8891 28.7677C22.7412 26.6198 19.2588 26.6198 17.1109 28.7677L7.93852 37.94ZM10.0598 40.0614C10.6473 40.3426 11.3052 40.5 12 40.5H32.3788L22.7678 30.889C21.7915 29.9127 20.2085 29.9127 19.2322 30.889L10.0598 40.0614ZM34.7678 26.889L40.5 32.6212V36C40.5 38.2874 38.7933 40.1762 36.5839 40.4625L27.1213 30.9999L31.2322 26.889C32.2085 25.9127 33.7915 25.9127 34.7678 26.889Z"
                fill="url(#gradient-warm-1)"
            />
            <path
                className="fill"
                d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z"
                fill="url(#gradient-warm-1)"
            />
            { children }
        </svg>
    );
}

export const ViewInYourSpace = ({ children }: {children?: React.ReactNode}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            className="fill"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.3354 7.32918C12.1243 7.22361 11.8757 7.22361 11.6646 7.32918L7.67913 9.32191C7.42365 9.44301 7.25 9.70145 7.25 10V15C7.25 15.2841 7.4105 15.5438 7.66459 15.6708L11.6617 17.6694C11.875 17.7772 12.1304 17.7762 12.3432 17.6669L16.3354 15.6708C16.5895 15.5438 16.75 15.2841 16.75 15V10C16.75 9.7006 16.5746 9.44217 16.3209 9.32191L12.3354 7.32918ZM8.75 14.5365V11.2135L11.25 12.4635V15.7865L8.75 14.5365ZM12.75 15.7865V12.4635L15.25 11.2135V14.5365L12.75 15.7865ZM14.3229 10L12 11.1615L9.67705 10L12 8.83853L14.3229 10Z"
            fill="#3E3B46"
        />
        <path
            className="fill"
            clipRule="evenodd"
            d="M4.25 5C4.25 2.92893 5.92893 1.25 8 1.25H16C18.0711 1.25 19.75 2.92893 19.75 5V19C19.75 21.0711 18.0711 22.75 16 22.75H8C5.92893 22.75 4.25 21.0711 4.25 19V5ZM8 2.75C6.75736 2.75 5.75 3.75736 5.75 5V19C5.75 20.2426 6.75736 21.25 8 21.25H16C17.2426 21.25 18.25 20.2426 18.25 19V5C18.25 3.75736 17.2426 2.75 16 2.75H14.25V3.5C14.25 3.91421 13.9142 4.25 13.5 4.25H10.5C10.0858 4.25 9.75 3.91421 9.75 3.5V2.75H8Z"
            fill="#3E3B46"
        />
        { children }
    </svg>
);
