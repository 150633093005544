import React                           from 'react';
import { ContentTabTreejs }            from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control';
import { ParametersTabTreejs }         from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/ParametersTabTreejs";
import { TransformationModesSwitcher } from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/transformation-modes-switcher';
import { AtomModel, useInject }        from '@geenee/shared';
import { Wrapper }                     from '@geenee/ui';
import { observer }                    from 'mobx-react';

interface Props {
    sceneGroupRef: any
    children: React.ReactNode
}

export const SceneControls2d = observer(({
    sceneGroupRef,
    children
}: Props) => {
    const { SceneState } = useInject();
    const { activeMolecule, disabled, mode } = SceneState;

    const atomModels = activeMolecule?.sceneActorAtoms || [] as AtomModel[];

    const hideTransformPanel = mode === 'preview' || !atomModels.length;

    if (!activeMolecule) {
        return <></>;
    }

    return (
        <Wrapper
            fullWidth
            fullHeight
            style={ {
                zIndex:   2,
                position: 'absolute'
            } }
        >
            { mode !== 'preview' && (
                <ContentTabTreejs />
            ) }
            { children }
            { !hideTransformPanel
                ? (
                    <TransformationModesSwitcher
                        rendererMode="three"
                        transformMode={ SceneState.transformControlsMode }
                        setTransformMode={ (val) => SceneState.setTransformControlsMode(val) }
                        disabled={ disabled }
                    />
                )
                : <></> }
            { mode !== 'preview' ? (
                <ParametersTabTreejs
                    sceneWrapperObject={ sceneGroupRef.current }
                />
            ) : <></> }
        </Wrapper>
    );
});
