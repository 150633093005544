import styled from 'styled-components';

export const WidgetToolbar = styled.div`
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    overflow: hidden;

    @keyframes pulse {
        /* Looks better if we transition the color instead of the width of the border */
        0% {
            box-shadow: 0 0 0 1.5px
                ${ ({ theme }) => theme.secondaryColors.pink10 };
        }
        100% {
            box-shadow: 0 0 0 1.5px rgba(0, 0, 0, 0);
        }
    }
    &.got-focus {
        /* animation: 2s linear pulse; */
    }
`;

export const ModalButtonContainer = styled.div`
    margin-left: auto;
    padding-right: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const Separator = styled.div`
    margin: 12px 1px;
    border-left: 1px solid #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
`;

export const ToolbarItem = styled.div`
    flex: 1 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: grab;

    .ti-wrapper {
        width: 44px;
        height: 44px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        border-radius: 5px;

        svg,
        span {
            display: inline-block;
        }

        span {
            padding: 4px 0 5px;
            font-size: 8px;
        }

        &:hover {
            background-color: ${ ({ theme: { secondaryColors } }) => secondaryColors.grey60 };
            box-shadow: inset 0px 1px 1px rgba(172, 172, 172, 0.25);

            span {
                font-weight: 700;
            }
        }
    }
`;

export const Container = styled.div`
    width:  100%;
    @keyframes pulse {
        /* Looks better if we transition the color instead of the width of the border */
        0% {
            box-shadow: inset 0 0 0 1.5px
                ${ ({ theme }) => theme.secondaryColors.pink10 };
        }
        100% {
            box-shadow: inset 0 0 0 1.5px rgba(0, 0, 0, 0);
        }
    }
  
    /* Quill RTE 'snow' theme toolbar style overwrights */
    .ql-toolbar.ql-snow {
        z-index: 3;
        border: 0;
        /* border-bottom: 1px solid; */
        /* border-color: ${ ({ theme }) => theme.secondaryColors.blue30 }; */
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        padding: 0px;
        /* animation: 2s linear pulse; */
    }
    
    #toolbar-color-picker-button {
        position: relative;
    }

    .ql-first-row, .ql-second-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .ql-first-row {
      justify-content: space-between; 
    }
  
    .ql-second-row {
      margin-top: 15px;
    }
  
    .ql-separator {
        margin: 3px;
        height: 26px;
        border-right: 1px solid ${ ({ theme }) => theme.colors.white };
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    }

    .ql-toolbar.ql-snow .ql-formats {
        margin-right: 1px;
    }

    .ql-toolbar.ql-snow .text-style-block div:last-of-type {
        margin-right: 0;
    }

    .ql-snow .ql-picker.ql-font {
        width: 121px;
        margin-right: 8px;
        height: 32px;
    }
    
    .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
      right: 10px;
    }
  
    .ql-snow .ql-picker.ql-size .ql-picker-label, .ql-snow .ql-picker.ql-font .ql-picker-label {
      padding: 5px 12px;
      display: flex;
      align-items: center;
    }
  
    .ql-snow .ql-picker.ql-size {
      width: 95px;
      margin-left: 8px;
      height: 32px;
    }

    .ql-snow .ql-icon-picker {
        width: 26px;
    }

    .ql-snow .ql-picker-label {
        padding-left: 2px;
    }

    .ql-snow.ql-toolbar button {
        width: 26px;
    }

    /* Set font droplist names - -item is the currently selected font, -label is the appearance in the droplist*/
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='gordita']::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='gordita']::before {
        content: 'Gordita';
        font-family: 'Gordita', sans-serif;
    }

    .ql-snow
        .ql-picker.ql-font
        .ql-picker-label[data-value='euclid-circular-a']::before,
    .ql-snow
        .ql-picker.ql-font
        .ql-picker-item[data-value='euclid-circular-a']::before {
        content: 'Euclid Circular A';
        font-family: 'Euclid Circular A', sans-serif;
        white-space: nowrap;
        width: 82px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .ql-snow
        .ql-picker.ql-font
        .ql-picker-label[data-value='montserrat']::before,
    .ql-snow
        .ql-picker.ql-font
        .ql-picker-item[data-value='montserrat']::before {
        content: 'Montserrat';
        font-family: 'Montserrat', sans-serif;
    }

    .ql-snow
        .ql-picker.ql-font
        .ql-picker-label[data-value='open-sans']::before,
    .ql-snow
        .ql-picker.ql-font
        .ql-picker-item[data-value='open-sans']::before {
        content: 'Open Sans';
        font-family: 'Open Sans', sans-serif;
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='arvo']::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='arvo']::before {
        content: 'Arvo';
        font-family: 'Arvo', sans-serif;
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='karla']::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='karla']::before {
        content: 'Karla';
        font-family: 'Karla', sans-serif;
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='lato']::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='lato']::before {
        content: 'Lato';
        font-family: 'Lato', sans-serif;
    }

    /* Set size droplist names - -item is the currently selected font, -label is the appearance in the droplist*/
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='8']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='8']::before {
        content: '8';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='9']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='9']::before {
        content: '9';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='10']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10']::before {
        content: '10';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='11']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='11']::before {
        content: '11';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='12']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12']::before {
        content: '12';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='13']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='13']::before {
        content: '13';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='14']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14']::before {
        content: '14';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16']::before {
        content: '16';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='18']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='18']::before {
        content: '18';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='1vw']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='1vw']::before {
        content: '1';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='1_5vw']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='1_5vw']::before {
        content: '1.5';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='2vw']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='2vw']::before {
        content: '2';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='2_5vw']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='2_5vw']::before {
        content: '2.5';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='3vw']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='3vw']::before {
        content: '3';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='3_5vw']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='3_5vw']::before {
        content: '3.5';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='4vw']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='4vw']::before {
        content: '4';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='4_5vw']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='4_5vw']::before {
        content: '4.5';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='5vw']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='5vw']::before {
        content: '5';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='5_5vw']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='5_5vw']::before {
        content: '5.5';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='6vw']::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='6vw']::before {
        content: '6';
    }

    /* Hover or selection state overwrite */
    .ql-snow .ql-picker.ql-expanded .ql-picker-label {
        border-radius: 5px;
    }

    .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
      stroke: ${ ({ theme }) => theme.colors.black20 };
    }
    
    .ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ql-snow .ql-icon-picker .ql-picker-label {
        padding: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .ql-color svg {
      margin-top: 5px;
    }
    
    .ql-underline svg {
      margin-top: 2px;
    }
  
    .ql-toolbar.ql-snow .ql-picker-label:focus {
        outline: none;
    }

    .ql-snow .ql-picker-item .ql-stroke {
      stroke: ${ ({ theme }) => theme.colors.black20 };
    }
  
    .ql-snow .ql-stroke {
        stroke: ${ ({ theme }) => theme.colors.white };
    }

    .ql-picker-label .ql-stroke, .ql-picker-label.ql-active .ql-stroke {
      stroke: ${ ({ theme }) => theme.colors.black20 };
    }

    .ql-align.ql-picker.ql-icon-picker .ql-picker-label .ql-stroke {
      stroke: ${ ({ theme }) => theme.colors.white };
    }
  
    .ql-snow .ql-fill,
    .ql-snow .ql-stroke.ql-fill {
        fill: ${ ({ theme }) => theme.colors.white };
    }

    .ql-snow.ql-toolbar button:hover,
    .ql-snow .ql-toolbar button:hover,
    .ql-snow.ql-toolbar button:focus,
    .ql-snow .ql-toolbar button:focus,
    .ql-snow.ql-toolbar button.ql-active,
    .ql-snow .ql-toolbar button.ql-active,
    .ql-snow .ql-picker.ql-size,
    .ql-snow .ql-picker.ql-font,
    .ql-snow.ql-toolbar .ql-picker-label:hover,
    .ql-snow .ql-toolbar .ql-picker-label:hover,
    .ql-snow.ql-toolbar .ql-picker-label.ql-active,
    .ql-snow .ql-toolbar .ql-picker-label.ql-active,
    .ql-snow .ql-toolbar .ql-picker-item:hover,
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
        border-radius: 5px;
        background: ${ ({ theme }) => theme.secondaryColors.grey60 };
        color: ${ ({ theme }) => theme.colors.black20 };
        box-shadow: inset 0px 1px 1px rgba(172, 172, 172, 0.25);
    }

    .ql-snow .ql-picker {
      color: ${ ({ theme }) => theme.colors.black20 };
      font-weight: 300;
      font-size: 12px;
    }
  
    .ql-snow .ql-picker-options {
        padding: 4px 0;
        background: ${ ({ theme }) => theme.secondaryColors.grey60 };
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
    }

    .ql-snow.ql-toolbar .ql-picker-item {
        padding-left: 4px;
        padding-right: 4px;
    }

    .ql-snow.ql-toolbar .ql-picker-item:hover {
        color: black;
        background: ${ ({ theme }) => theme.colors.white };

        .ql-stroke {
            stroke: ${ ({ theme }) => theme.colors.black };
        }
    }

    .ql-snow .ql-color-label.ql-stroke {
        stroke: ${ ({ theme }) => theme.colors.black };
        opacity: 1;
    }

    &&& {
        .ql-snow.ql-toolbar .ql-size .ql-picker-label.ql-active,
        .ql-snow.ql-toolbar .ql-font .ql-picker-label.ql-active {
            border-radius: 0;
            background: unset;
            box-shadow: unset;
        }
    }

    .ql-snow.ql-toolbar button:hover .ql-stroke,
    .ql-snow .ql-toolbar button:hover .ql-stroke,
    .ql-snow.ql-toolbar button:focus .ql-stroke,
    .ql-snow .ql-toolbar button:focus .ql-stroke,
    .ql-snow.ql-toolbar button.ql-active .ql-stroke,
    .ql-snow .ql-toolbar button.ql-active .ql-stroke,
    .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
    .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
    .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
    .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
    .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
    .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
    .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
    .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
    .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
    .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
    .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
    .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
    .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
    .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
    .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
    .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
    .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
    .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
        stroke: ${ ({ theme }) => theme.colors.black20 };
    }

    .ql-snow.ql-toolbar button:hover .ql-fill,
    .ql-snow .ql-toolbar button:hover .ql-fill,
    .ql-snow.ql-toolbar button:focus .ql-fill,
    .ql-snow .ql-toolbar button:focus .ql-fill,
    .ql-snow.ql-toolbar button.ql-active .ql-fill,
    .ql-snow .ql-toolbar button.ql-active .ql-fill,
    .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
    .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
    .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
    .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
    .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
    .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
    .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
        fill: ${ ({ theme }) => theme.colors.black20 };
    }
`;
