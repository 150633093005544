import React, { useEffect, useState }                   from 'react';
import BodyTrackingLottie                               from '@geenee/assets/lib/lottie/ar-scene-lottie/bodyTracking.json';
import HeadTrackingLottie                               from '@geenee/assets/lib/lottie/ar-scene-lottie/headTracking.json';
import LookAroundLottie                                 from '@geenee/assets/lib/lottie/ar-scene-lottie/lookAround.json';
import PinchLottie                                      from '@geenee/assets/lib/lottie/ar-scene-lottie/pinch.json';
import ScanLottie                                       from '@geenee/assets/lib/lottie/ar-scene-lottie/scan.json';
import TapToPlaceLottie                                 from '@geenee/assets/lib/lottie/ar-scene-lottie/tapToPlace.json';
import { useInject }                                    from "@geenee/shared/src/hook/use-inject.hook";
import { SceneExperienceUnionType }                     from "@geenee/shared/src/magellan/renderer/r3f-renderer/r3f.renderer";
import { Card, cn, Description, MediaContent, Wrapper } from "@geenee/ui";
import { observer }                                     from "mobx-react";
import './scene-ui-elements.styles.scss';

type PropsType = {
  type: SceneExperienceUnionType
  isLoaderVisible: boolean
  onLoad: () => void,
  lottiesLoaded: boolean,
  setLookAroundVisible: (val: boolean) => void,
  worldArLottiesState: {
    pinch: { visible: boolean, shown: boolean },
    tap: { visible: boolean, shown: boolean },
    permissions: { visible: boolean, shown: boolean }
    lookAround: { visible: boolean }
  }
  markerArImageUrl?: string
  sceneModel: any
}
const className = cn('scene-ui-elements');
export const SceneLotties = observer((props: PropsType) => {
    const { AppState } = useInject();

    const [ showLottie, setShowLottie ] = useState(true);

    useEffect(() => {
        let timeout: NodeJS.Timeout | null = null;
        const onShowLottie = () => {
            timeout && clearTimeout(timeout);
            timeout = setTimeout(() => {
                console.log('mouse in');
                setShowLottie(true);
                timeout && clearTimeout(timeout);
            }, 500);
        };
        const onHideLottie = () => {
            if (timeout) {
                clearTimeout(timeout);
            }
            console.log('mouse out');

            setShowLottie(false);
        };
        props.sceneModel.emitter.addListener('geenee-marker-in', onHideLottie);
        props.sceneModel.emitter.addListener('geenee-marker-out', onShowLottie);
        return () => {
            props.sceneModel.emitter.removeListener('geenee-marker-in', onHideLottie);
            props.sceneModel.emitter.removeListener('geenee-marker-out', onShowLottie);
        };
    }, []);

    useEffect(() => {
        if (AppState.initialScreenLoaded && (props.type === 'head-tracking' || props.type.includes('body-tracking') || props.type === 'stamp-ar')) {
            setTimeout(() => setShowLottie(false), 3000);
        }
    }, [ AppState.initialScreenLoaded ]);

    useEffect(() => {
        if (props.type === 'slam-ar') {
            setTimeout(() => props.setLookAroundVisible(true), 1500);
        }
    }, []);

    const LottieCard = (_props: { animationData: { [key: string]: any }, description: string, isVisible: boolean, placeholder?: React.ReactNode}) => (
        <Card
            styles={ { display: _props.isVisible ? 'block' : 'none' } }
            mediaBefore
            media={ (
                <Wrapper align="center" valign="center">
                    <MediaContent
                        className={ className('lottie-icon') }
                        type="animation"
                        animationData={ _props.animationData }
                        play
                        loop
                        autoplay
                    />
                    { _props.placeholder }
                </Wrapper>
            ) }
        >
            <Description size="lg" color="white" align="center">{ _props.description }</Description>
        </Card>

    );
    const Content = () => {
        if (props.type === 'slam-ar') {
            return (
                <>
                    <LottieCard
                        animationData={ TapToPlaceLottie }
                        description="Tap ground to place"
                        isVisible={ !props.isLoaderVisible && props.worldArLottiesState.tap.visible }
                    />
                    <LottieCard
                        animationData={ PinchLottie }
                        description="Pinch to resize"
                        isVisible={ !props.isLoaderVisible && props.worldArLottiesState.pinch.visible }
                    />
                    <LottieCard
                        animationData={ LookAroundLottie }
                        description="Look around"
                        isVisible={ props.isLoaderVisible && !props.worldArLottiesState.permissions.visible
                          && props.worldArLottiesState.lookAround.visible }
                    />
                </>
            );
        } if (props.type === 'head-tracking') {
            return (
                <LottieCard
                    animationData={ HeadTrackingLottie }
                    description="Scan face"
                    isVisible={ showLottie }
                />
            );
        } if (props.type.includes('body-tracking')) {
            return (
                <LottieCard
                    animationData={ BodyTrackingLottie }
                    description="Scan full body"
                    isVisible={ showLottie }
                />
            );
        }
        if (props.type === 'stamp-ar') {
            return (
                <LottieCard
                    animationData={ ScanLottie }
                    description="Scan tracking image"
                    isVisible={ showLottie }
                    placeholder={ (
                        <MediaContent
                            size="xxl"
                            className={ className('tracking-image') }
                            type="image"
                            fit="cover"
                            url={ props.markerArImageUrl }
                            ratio="ratio-1-1"
                        />
                    ) }
                />
            );
        }
        return null;
    };
    return (
        <div
            style={ {
                width:          '100%',
                height:         '100%',
                display:        'flex',
                alignItems:     'center',
                justifyContent: 'center'
            } }
            id="lotties"
        >
            { Content() }
        </div>
    );
});
