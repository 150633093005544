import styled from 'styled-components';

export const CampaignWrapper = styled.div<{
    src: string
    active: boolean
    isNewCampaign?: boolean
    hasHoverBorder: boolean
}>`
    flex-shrink: 0;
    margin-bottom: 2vw;
    flex-shrink: 0;
    pointer-events: all;
    cursor: pointer;
    background: ${ ({ src }) => (src
        ? `url('${ src }')`
        : 'radial-gradient(70.96% 70.96% at 50% 50%, #B5FFF5 0%, rgba(255, 255, 255, 0) 75%), #F5F5F5;') };
    background-color: ${ ({ theme: { secondaryColors }, src }) => (src ? 'transparent' : secondaryColors.grey50) };
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: ${ ({ active }) => (active ? '50%' : '30%') };
    margin-top: ${ ({ active }) => (active ? '0px' : '26%') };
    min-width: 195px;
    width: 100%;
    ${ ({ src }) => (!src ? 'box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);' : '') };

    &:hover {
      ${ ({ theme: { secondaryColors }, hasHoverBorder }) => hasHoverBorder && `
          box-shadow: 0 0 0 1.2px
                ${ secondaryColors.pink10 };
          ` };

    }

    span {
        margin-top: 22px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        font-family: 'Euclid Circular A', Helvetica, sans-serif;
    }

    ${ ({ active, isNewCampaign }) => !active
        && isNewCampaign
        && `
    align-items: flex-start;

    span {
      display: none;
    }

  ` };
`;
export const IntroBoxStyled = styled.div`
    width: 100%;
    max-height: 30%;
    min-height: 30%;
    height: fit-content;
    text-align: center;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    min-width: 208px;
    div:first-child {
      width: 100%;
    }

    .ql-editor {
      overflow-y: auto;
    }
`;
