import React, { useCallback, useEffect, useMemo } from "react";
import { EnterExperienceButton }                  from "@geenee/geeclient-kit/src/lib/component/project/EnterExperienceButton";
import { ExperienceImage }                        from "@geenee/geeclient-kit/src/lib/component/project/ExperienceImage";
import { ExperiencesCarousel }                    from "@geenee/geeclient-kit/src/lib/component/project/ExperiencesCarousel";
import { useProjectData }                         from "@geenee/geeclient-kit/src/lib/component/project/useProjectData";
import { DIContextType }                          from "@geenee/geeclient-kit/src/lib/context/di.context";
import { useInject }                              from "@geenee/shared/src/hook/use-inject.hook";
import { useSharedHistory }                       from "@geenee/shared/src/hook/use-shared-history";
import { useInitialScreenLoaded }                 from "@geenee/shared/src/magellan/hooks/use-loaded-initial-screen";
import { ViewModel }                              from "@geenee/shared/src/magellan/model/view.model";
import {  cn, Wrapper }                           from "@geenee/ui";
import html2canvas                                from "html2canvas";
import { runInAction }                            from "mobx";
import { observer }                               from "mobx-react";
import { SwiperSlide }                            from "swiper/react";
import { ExperiencesSwiperWrapper }               from "./ExperiencesCarousel/ExperiencesSwiperWrapper";
import './project-view.styles.scss';

const className = cn('project-view');

export const createLoaderWithBlurBackground = async (preloader: HTMLElement) => {
    const canvas = await html2canvas(document.body, { });
    canvas.style.filter = 'blur(20px)';
    const imgSrc = canvas.toDataURL();

    const progressLine = preloader.querySelector('.preloader--progress_line') as HTMLDivElement;
    if (progressLine) {
        progressLine.classList.add('anim');
        progressLine.style.width = '5%';
    }
    preloader.style.background = `none`;
    const divElement = document.createElement('div');
    divElement.style.backgroundImage = `url(${ imgSrc })`;
    divElement.style.backgroundSize = 'cover';
    divElement.style.filter = 'blur(16px) brightness(0.7);';
    // @ts-ignore
    divElement.style[ '-webkit-filter' ] = 'blur(16px) brightness(0.7)';
    divElement.style.position = 'fixed';
    divElement.style.height = '106vh';
    divElement.style.width = '106vw';
    divElement.style.zIndex = '-1';
    preloader.appendChild(divElement);

    document.body.appendChild(preloader);
};

export const ProjectViewComponent = observer((props: {diContext: DIContextType}) => {
    const history = useSharedHistory();
    const { AppState: { activeProject, activeExperience }, AppState } = useInject();

    const _preloader = useMemo(() => document.getElementById('preloader'), []);

    useInitialScreenLoaded();

    const redirectToSection = useCallback(async (url: string) => {
        runInAction(() => {
            AppState.initialScreenLoaded = false;
        });
        if (process.env.ENV_GEENEE_APP !== 'BUILDER' && _preloader) {
            await createLoaderWithBlurBackground(_preloader);
        }
        history.push(url, { pushToScene: true });
    }, [ _preloader ]);

    const {
        projectId,
        experiencesData,
        experienceId
    } = useProjectData(redirectToSection);

    useEffect(() => {
    // @ts-ignore
        if (process.env.ENV_GEENEE_APP !== 'BUILDER') {
            activeProject?.initCode();
        }
    }, []);

    const disabled = activeProject?.nft_published;

    const { IntroBox } = props.diContext as unknown as JSX.Element;

    const openAvailableView = async () => {
        if (activeExperience) {
            const [ view ]: ViewModel[] = Array.from(activeExperience.viewsRegistry.values()).sort((a, b) => a.options.order - b.options.order);
            await redirectToSection(`/${ projectId }/${ experienceId }/${ view ? view.id : '' }`);
        }
    };

    if (!experienceId && !experiencesData?.length) {
        return (
            <Wrapper className={ className() } fullHeight style={ { backgroundColor: activeProject?.options.background_color } }>
                <Wrapper overflow="hidden" fullHeight />
            </Wrapper>
        );
    }

    if (!experienceId && experiencesData?.length) {
        return (
            <Wrapper className={ className() } fullHeight style={ { backgroundColor: activeProject?.options.background_color } }>
                <Wrapper overflow="hidden" fullHeight>
                    <ExperiencesCarousel
                        experiences={ experiencesData }
                    />
                </Wrapper>
            </Wrapper>
        );
    }
    return (
        <Wrapper className={ className() } fullHeight style={ { backgroundColor: activeProject?.options.background_color } }>
            <Wrapper overflow="hidden" fullHeight>
                <ExperiencesSwiperWrapper simulateTouch={ false }>
                    <SwiperSlide>
                        <Wrapper
                            className="campaign"
                            align="center"
                            style={ {
                                position: 'absolute',
                                top:      '0',
                                height:   '100%',
                                padding:  '50px 0 65px'
                            } }
                        >
                            <ExperienceImage
                                disabled={ disabled }
                                image_src={ activeExperience?.cover_image?.url }
                                onImageClick={ openAvailableView }
                            />
                            <IntroBox
                                splash_screen_message={ activeExperience?.splash_screen_message }
                            />
                            <EnterExperienceButton
                                onClick={ openAvailableView }
                            />
                        </Wrapper>
                    </SwiperSlide>
                </ExperiencesSwiperWrapper>
            </Wrapper>
        </Wrapper>
    );
});
