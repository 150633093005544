import React                from 'react';
import { TYPE_CUSTOM_CODE } from '@geenee/shared/src/magellan/components/contentView/constants';
import { observer }         from 'mobx-react';
import { AtomModel }        from '@geenee/builder/src/magellan/model/atom.model';
import { MoleculeModel }    from '@geenee/builder/src/magellan/model/molecule.model';
import { CodeEditor }       from '@geenee/builder/src/module/custom-code/component/editor.component';

interface Props {
    disabled: boolean
    customCodeMolecule?: MoleculeModel
    width?: string
}

export const CustomCodeForm = observer(({ disabled, customCodeMolecule, width }: Props) => {
    const customCodeAtom = customCodeMolecule?.getAtomByType('custom-code') as AtomModel | undefined;
    if (customCodeMolecule?.type !== TYPE_CUSTOM_CODE || !customCodeMolecule || !customCodeAtom) {
        return <></>;
    }
    const { atom_custom_code_value } = customCodeAtom.options;

    return (
        <CodeEditor
            disabled={ disabled }
            style={ {
                width: width || '80%', height: '100%', minHeight: 300, maxHeight: '40%', marginTop: '10px'
            } }
            value={ atom_custom_code_value || '' }
            onUpdate={ (val) => {
                customCodeAtom.options.atom_custom_code_value = val;
                customCodeAtom?.saveData();
            } }
        />
    );
});
