import React                from 'react';
import { Scrollbars }       from 'react-custom-scrollbars';
import { Wrapper }          from '@geenee/ui';
import { observer }         from 'mobx-react';
import { HeaderTitle }      from '../../components/HeaderTitle';
import { SDKAccessContent } from './components/SDKAccessContent';

export const SDKAccessPage = observer(() => (
    <Scrollbars
        autoHide
        autoHideTimeout={ 500 }
        autoHideDuration={ 200 }
    >
        <Wrapper
            align="center"
            padding="xxl"
        >
            <Wrapper maxWidth="832px">
                <HeaderTitle
                    title="SDK Access"
                    subtitle="Get an access key (per project) to our WebAR SDK for face & body tracking."
                    onClickSDKDocs={ () => window.open('https://lab.geen.ee/engeenee-doc/index.html') }
                />
                <SDKAccessContent />
            </Wrapper>
        </Wrapper>
    </Scrollbars>
));
