import React             from 'react';
import { Bold, Wrapper } from './styles';

function PaymentSuccessful() {
    return (
        <Wrapper>
            <Bold>PAYMENT SUCCESSFUL</Bold>
            <p>Your payment has gone through.</p>
            <p>Enjoy building and publishing your WebAR experiences.</p>
        </Wrapper>
    );
}

// eslint-disable-next-line arca/no-default-export
export default PaymentSuccessful;
