import React, { useEffect, useMemo, useState } from "react";
import {
    Button,
    Checkbox,
    Description,
    Divider,
    Icon,
    Link,
    Title,
    Wrapper
} from "@geenee/ui";
import { createTaskApi }    from "~/api/ZendeskApi";
import { useBuilderInject } from "~/core/hook/use-builder-inject";

enum RadioValues {
    pending = 1,
    delete = 2,
    all = 3,
    subscription = 4
}

const contactEmail = "contact@geenee.me";

export function WarningWindow({ onRemove, onClose }: { onRemove: () => void, onClose: () => void }) {
    const { BuilderState } = useBuilderInject();
    const { profile } = BuilderState.currentUser;
    const email = profile?.email || "";
    const isAdminOrEditor = profile?.role === "admin" || profile?.role === "editor";
    const [ selectedCheckboxes, setSelectedCheckboxes ] = useState<string[]>([]);

    useEffect(() => {
        isAdminOrEditor && setSelectedCheckboxes([ RadioValues[ 1 ] ]);
    }, []);

    const items = useMemo(() => {
        const result = [ {
            label: "Yes, I want to permanently delete this Geenee Account and all its data *",
            value: "delete"
        }, {
            label: "I want to unsubscribe from any Email messages",
            value: "subscription"
        } ];
        if (!isAdminOrEditor) {
            result.unshift({
                label: "Yes, I acknowledge that I am responsible for any pending charges *",
                value: "pending"
            });
        }
        return result;
    }, []);

    return (
        <Wrapper align="center" fullWidth maxWidth="448px">
            <Icon color="gradient-warm-5" size={ 48 } margin="sm" name="faceSad" />
            <Title align="center" size="lg" weight="medium" margin="xs">Account deletion</Title>
            <Description size="md" margin="sm" lineHeight="lg" align="center" color="shade-3">
                You’re trying to delete your Geenee account which provides access to all of your WebAR Projects, assets,
                and data.
                { " " }
                { !isAdminOrEditor
                    ? "You, your team and your customers will no longer have any type of access as this request will delete everything."
                    : null }
                <br />
                <br />
                Prior to proceeding, please email
                { " " }
                <Link
                    style={ { margin: 0 } }
                    onClick={ () => {
                        window.open(
                            `mailto:${ contactEmail }`
                        );
                    } }
                >
                    { contactEmail }
                </Link>
                { " " }
                if you would like an export of your data before submitting.
                { !isAdminOrEditor ? (
                    <>
                        <br />
                        <br />
                        If you have any pending financial transactions, you will still be responsible.
                    </>
                ) : null }
            </Description>
            <Divider margin="xs" />
            <Wrapper padding="xs" align="stretch">
                { items.map((checkbox) => {
                    const checked = !!selectedCheckboxes.find((i) => i === checkbox.value);
                    const index = selectedCheckboxes.findIndex((i) => i === checkbox.value);

                    return (
                        <Checkbox
                            size="md"
                            margin="xs"
                            key={ checkbox.value }
                            label={ checkbox.label }
                            color="shade-3"
                            iconColor="shade-3"
                            checked={ checked }
                            onChange={ () => {
                                if (index >= 0) {
                                    selectedCheckboxes.splice(index, 1);
                                } else {
                                    selectedCheckboxes.push(checkbox.value);
                                }

                                setSelectedCheckboxes([ ...selectedCheckboxes ]);
                            } }
                        />
                    );
                }) }
            </Wrapper>
            <Wrapper fullWidth align="space-between" padding="xs" row>
                <Button
                    onClick={ onClose }
                    fullWidth
                    viewType="secondary"
                    uppercase
                    margin="sm"
                >
                    Nevermind
                </Button>
                <Button
                    fullWidth
                    uppercase
                    disabled={ (!isAdminOrEditor && !selectedCheckboxes.includes("pending")) || !selectedCheckboxes.includes("delete") }
                    viewType="error"
                    onClick={ async () => {
                        if (email) {
                            await createTaskApi({
                                subject:        "Delete account",
                                description:    `Delete account for ${ email }. ${ selectedCheckboxes.includes("subscription") ? "Cancel subscription." : "Subscription active." }`,
                                assignee_email: !selectedCheckboxes.includes("subscription") && email
                            });

                            onRemove();
                        }
                    } }
                >
                    Delete account
                </Button>
            </Wrapper>
        </Wrapper>
    );
}
