import React from 'react';
import './styles.scss';

export function PulseLoadingIndicator() {
    return (
        <div id="dots" className="dots full-screen">
            <div className="pulse1" />
            <div className="pulse2" />
            <div className="pulse3" />
        </div>
    );
}
