import React, { useEffect, useState }             from "react";
import { RenameCommand }                          from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/commands/RenameCommand";
import { useInject }                              from "@geenee/geeclient-kit/src/packages/shared/lib/hook/use-inject.hook";
import { AtomModel }                              from "@geenee/shared/src/magellan/atom/model/atom.model";
import { MoleculeModel }                          from "@geenee/shared/src/magellan/molecule/model/molecule.model";
import { cn, Description, EditableArea, Wrapper } from "@geenee/ui";
import { observer }                               from "mobx-react";
import './styles.scss';

const className = cn('layers-panel');

type Props = {
  value: string,
  disabled?: boolean,
  after?: React.ReactNode,
  onClick?: ()=> void,
  currentModel?: AtomModel | MoleculeModel
}

export const AssetTitle = observer((props:Props) => {
    const [ autoFocus, setAutoFocus ] = useState(0);
    const { BuilderState, AppState, SceneState } = useInject();
    const popover = BuilderState?.activePopover;
    useEffect(() => {
        popover?.eventEmitter?.addListener('rename', (id: string) => {
            if (id === props.currentModel?.id) {
                setAutoFocus((prev) => prev + 1);
            }
        });
    }, [ popover ]);

    const onChange = (value: string) => {
        if (props.currentModel) {
            const renameCommand = new RenameCommand(SceneState, props.currentModel, value);
            AppState.commander.executeCommand(renameCommand);
            setAutoFocus((prev) => prev + 1);
        }
    };

    return (
        <Wrapper className={ className('editable-title') } row align="space-between" valign="center" overflow="hidden">
            <Description shorten fullWidth size='sm' onClick={ props.onClick } lineHeight="xxl">
                <EditableArea
                    key={ `${ autoFocus }` }
                    shorten
                    value={ props.value }
                    onChange={ onChange }
                    disabled={ props.disabled }
                    doubleClickMode={ !!props.currentModel }
                    autoFocus={ !!autoFocus }
                />
            </Description>
            <div className="treeItemActions">
                { props.after }
            </div>
        </Wrapper>
    );
});
