import React, { memo }                     from 'react';
import { Description, Wrapper }            from '@geenee/ui';
import { SUPPORT_FORM_ATTACHMENT_FORMATS } from '../../../../lib/constants';
import { FilesUploadBar }                  from '../FilesUploadBar';
import { SupportFile }                     from '../useSupportRequestForm';

export const DropContent = memo(({ filesList, onRemoveFile }: { filesList: SupportFile[], onRemoveFile: (id?: number)=> void }) => (
    <Wrapper fullHeight>
        <Description
            color="shade-4"
        >
            Drag & Drop up to 5 files
        </Description>
        <Description color="shade-5" margin={ filesList.length ? 'sm' : 'off' }>
            Supported file formats:
            { ' ' }
            { SUPPORT_FORM_ATTACHMENT_FORMATS.join(', ') }
        </Description>

        <FilesUploadBar onRemoveFile={ onRemoveFile } filesList={ filesList } />

    </Wrapper>
));
