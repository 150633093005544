import React, { ChangeEvent, useState } from 'react';
import { FormItem, Input, Wrapper }     from '@geenee/ui';
import StyledButton                     from '@geenee/builder/src/components/common/Button';
import { NEW_OWNER }                    from '@geenee/builder/src/lib/constants';
import {
    DefaultWrapper,
    DeleteDescription,
    DescriptionContainer,
    Title
} from './styles';

type DeleteMemberProps = {
    // eslint-disable-next-line react/no-unused-prop-types
    close: () => void;
    confirmCallback: () => void;
    textInsert: string;
};

const PassOwnerRoleConfirmation = function ({ confirmCallback, textInsert }: DeleteMemberProps) {
    const [ text, setText ] = useState('');

    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    const handleClick = () => {
        if (text === NEW_OWNER) {
            confirmCallback();
            // Close modal from saga
            // close();
        }
    };

    return (
        <DefaultWrapper>
            <Wrapper align="center">
                <Title>RIGHTS CHANGE CONFIRMATION</Title>
                <DescriptionContainer>
                    <DeleteDescription>
                        Are you sure you want to give
                        { ' ' }
                        { textInsert }
                        { ' ' }
                        Owner rights?
                    </DeleteDescription>
                    <br />
                    <DeleteDescription>
                        Once you do, you will no longer have Owner priviledges to manage users, change plans and update payment.
                    </DeleteDescription>
                    <br />
                    <DeleteDescription>Confirm by typing “NEW OWNER” below.</DeleteDescription>
                </DescriptionContainer>
                <Wrapper maxWidth='308px'>
                    <FormItem
                        autoFocusDetect
                    >
                        <Input
                            className='ta-center'
                            size="md"
                            fullWidth
                            onChange={ handleTextChange }
                            value={ text }
                            placeholder={ NEW_OWNER }
                        />
                    </FormItem>
                </Wrapper>
                <StyledButton
                    disabled={ text !== NEW_OWNER }
                    label='CHANGE TO OWNER'
                    $width="310px"
                    $fontSize="12px"
                    onClick={ handleClick }
                />
            </Wrapper>
        </DefaultWrapper>
    );
};

// eslint-disable-next-line arca/no-default-export
export default PassOwnerRoleConfirmation;
