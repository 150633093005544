// side effects
import "@babylonjs/core/Sprites/spriteSceneComponent";
import "@babylonjs/core/Audio/audioSceneComponent";
import "@babylonjs/core/PostProcesses/RenderPipeline/postProcessRenderPipelineManagerSceneComponent";
import * as React                           from "react";
import { FreeCamera }                       from "@babylonjs/core/Cameras/freeCamera";
import type { TargetCamera }                from "@babylonjs/core/Cameras/targetCamera";
import type { IExplorerExtensibilityGroup } from "@babylonjs/core/Debug/debugLayer";
import { EngineStore }                      from "@babylonjs/core/Engines/engineStore";
import { DirectionalLight }                 from "@babylonjs/core/Lights/directionalLight";
import { Light }                            from "@babylonjs/core/Lights/light";
import { PointLight }                       from "@babylonjs/core/Lights/pointLight";
import { Material }                         from "@babylonjs/core/Materials/material";
import { NodeMaterial }                     from "@babylonjs/core/Materials/Node/nodeMaterial";
import { PBRMaterial }                      from "@babylonjs/core/Materials/PBR/pbrMaterial";
import { StandardMaterial }                 from "@babylonjs/core/Materials/standardMaterial";
import { Texture }                          from "@babylonjs/core/Materials/Textures/texture";
import { Vector3 }                          from "@babylonjs/core/Maths/math";
import { Mesh }                             from "@babylonjs/core/Meshes/mesh";
import { TransformNode }                    from "@babylonjs/core/Meshes/transformNode";
import type { Observer }                    from "@babylonjs/core/Misc/observable";
import { GPUParticleSystem }                from "@babylonjs/core/Particles/gpuParticleSystem";
import { ParticleHelper }                   from "@babylonjs/core/Particles/particleHelper";
import { DefaultRenderingPipeline }         from "@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/defaultRenderingPipeline";
import { SSAO2RenderingPipeline }           from "@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/ssao2RenderingPipeline";
import { SSAORenderingPipeline }            from "@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/ssaoRenderingPipeline";
import type { Scene }                       from "@babylonjs/core/scene";
import { SpriteManager }                    from "@babylonjs/core/Sprites/spriteManager";
import type { Nullable }                    from "@babylonjs/core/types";
import { ResizeWrapper }                    from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/ResizeWrapper";
import { TOOLTIP_DELAY }                    from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/transformation-modes-switcher";
import InjectedComponent                    from "@geenee/geeclient-kit/src/packages/shared/lib/util/abstract/injected-component";
import { BabylonRenderer }                  from "@geenee/geespector/renderer/babylonjs.renderer";
import { DeleteCommand }                    from "@geenee/geespector/src/commands/DeleteCommand";
import { EmptyTab }                         from "@geenee/geespector/src/components/sceneExplorer/components/EmptyTab";
import { ScenePanelComponent }              from "@geenee/geespector/src/components/scenePanel/scenePanelComponent";
import { withDIContext }                    from "@geenee/shared/src/magellan/hoc/withDIContext.hoc";
import { TOAST_ERROR }                      from "@geenee/shared/src/util/constants";
import { Button, Collapse, Tabs, Wrapper }  from '@geenee/ui';
import { TabsItem }                         from "@geenee/ui/src/lib/component/tabs/tabs-item.component";
import { TabsPanel }                        from "@geenee/ui/src/lib/component/tabs/tabs-panel.component";
import { TabsPanelContainer }               from '@geenee/ui/src/lib/component/tabs/tabs-panel-container.component';
import { Container }                        from 'inversify';
import { inject, observer }                 from "mobx-react";
import { Tools }                            from "../../tools";
import type { GlobalState }                 from "../globalState";
import { HeaderComponent }                  from "../headerComponent";
import { SceneExplorerFilterComponent }     from "./components/scene-explorer-filter.component";
import { SceneTreeItemComponent }           from "./entities/sceneTreeItemComponent";
import { TreeItemComponent }                from "./treeItemComponent";
import { TreeItemSelectableComponent }      from "./treeItemSelectableComponent";
import './sceneExplorer.scss';

export interface ISceneExplorerComponentProps {
  scene: Scene;
  noCommands?: boolean;
  noHeader?: boolean;
  noExpand?: boolean;
  noClose?: boolean;
  extensibilityGroups?: IExplorerExtensibilityGroup[];
  globalState: GlobalState;
  popupMode?: boolean;
  onPopup?: () => void;
  onClose?: () => void;
  diContext: {container: Container}
}
type SceneExplorerComponentState ={
  filter: Nullable<string>;
  selectedTexture: Texture | null;
  selectedNavigator: TransformNode | Mesh | Light | Scene | null;
  selectedMaterial: Material | null;
  selectedEntity: Texture | Material | TransformNode | Mesh | Light | Scene | null;
  scene: Scene,
  collapsed?: boolean,
  activeTab: string
}
type InjectProps = {
  container: Container
  diContext: any
}

enum KEY_CODES {
  LEFT = 37,
  RIGHT =39,
  UP = 38,
  DOWN = 40,
  ENTER = 13,
  DELETE = 46
}
const HIDE_SCENE_OBJECTS_ID = [ 'Camera', 'grid', "BackgroundHelper", 'BackgroundPlane' ];

@inject('container', 'diContext')
@observer
class _SceneExplorerComponent extends InjectedComponent<ISceneExplorerComponentProps, InjectProps, SceneExplorerComponentState> {
    private _onSelectionChangeObserver: Nullable<Observer<any>> = null;
    private _onSelectionRenamedObserver: Nullable<Observer<void>> = null;
    private _onNewSceneAddedObserver: Nullable<Observer<Scene>> = null;
    private _onNodeTabsChangedObservable: Nullable<Observer<string>> = null;
    private _onNewSceneObserver: Nullable<Observer<Scene>>;
    private _mutationTimeout: Nullable<number> = null;
    private lastOpenedTab = 'layers';
    ContextUI = this.injected.container.get('<ContextUI>');
    // @ts-ignore
    AssetsSourcePickerBabylon = this.ContextUI.AssetsSourcePickerBabylon;
    private renderer: BabylonRenderer;
    private sceneManager: any;
    private _once = true;
    private _hooked = false;

    private _sceneMutationFunc: () => void;

    constructor(props: ISceneExplorerComponentProps) {
        super(props);
        const appState = this.injected.container.get('<AppState>');
        // @ts-ignore
        const atom = appState?.activeMolecule?.getAtomByType('scene-actor');
        this.state = {
            filter:            null,
            selectedEntity:    null,
            selectedNavigator: null,
            selectedTexture:   null,
            selectedMaterial:  null,
            scene:             this.props.scene,
            activeTab:         atom ? 'layers' : 'addAsset',
            collapsed:         false
        };
        this._sceneMutationFunc = this.processMutation.bind(this);
        this.onChangeActiveTab = this.onChangeActiveTab.bind(this);

        const { activeSection : sectionModel } = appState;
        const { sceneManager } = sectionModel || { sceneManager: undefined };
        this.sceneManager = sceneManager;
        this.renderer = sceneManager?.sceneRenderer;

        this._onNewSceneObserver = this.props.globalState.onNewSceneObservable.add((scene: Scene) => {
            this.setState({ scene });
        });
    }

    processMutation() {
        if (this.props.globalState.blockMutationUpdates) {
            return;
        }

        // To avoid perf hits we want to make sure that we are not rebuilding the entire tree on each call
        if (this._mutationTimeout !== null) {
            window.clearTimeout(this._mutationTimeout);
        }

        this._mutationTimeout = window.setTimeout(() => {
            this._mutationTimeout = null;
            this.forceUpdate();
        }, 32);
    }

    componentDidMount() {
        this._onSelectionChangeObserver = this.props.globalState.onSelectionChangedObservable.add((
            entity: SceneExplorerComponentState['selectedEntity']
        ) => {
            if (entity === null) {
                const newState = {
                    selectedEntity:    null,
                    selectedNavigator: null,
                    selectedMaterial:  null,
                    selectedTexture:   null
                };
                this.props.globalState.onRightPanelChangeObservable.notifyObservers(newState.selectedEntity);
                this.setState(newState);
            } else if (this.state.selectedEntity !== entity) {
                const newState = {
                    selectedEntity:    entity,
                    selectedNavigator: this.state.selectedNavigator,
                    selectedMaterial:  this.state.selectedMaterial,
                    selectedTexture:   this.state.selectedTexture
                };
                if (entity instanceof Material) {
                    newState.selectedMaterial = entity;
                    // @ts-ignore
                    newState.selectedTexture = entity._albedoTexture || this.state.selectedTexture;
                } else if (entity instanceof Texture) {
                    newState.selectedTexture = entity;
                } else {
                    newState.selectedNavigator = entity;
                }
                // Select material or texture for mesh
                if (entity instanceof Mesh) {
                    const { material } = entity;
                    // @ts-ignore
                    const texture = entity.material?._albedoTexture || this.state.selectedTexture;

                    newState.selectedMaterial = material;
                    newState.selectedTexture = texture;
                    // Needs when you click when you on materials or textures tab
                    if (this.state.activeTab === 'materials') {
                        newState.selectedEntity = material;
                    } else if (this.state.activeTab === 'textures') {
                        newState.selectedEntity = texture;
                    }
                }

                this.props.globalState.onRightPanelChangeObservable.notifyObservers(newState.selectedEntity);
                this.setState(newState);
            }
        });

        this._onSelectionRenamedObserver = this.props.globalState.onSelectionRenamedObservable.add(() => {
            this.forceUpdate();
        });

        this._onNodeTabsChangedObservable = this.props.globalState.onNodeTabsChangedObservable.add((value) => {
            this.onChangeActiveTab(null, value);
        });
        const { scene } = this.state;
        setTimeout(() => {
            this.props.globalState.onSelectionChangedObservable.notifyObservers(scene);
        }, 0);
        this.props.globalState.onEmptySceneObserver.add((value: boolean) => {
            if (!value && this.state.activeTab === 'addAsset') {
                this.onChangeActiveTab(null, this.lastOpenedTab || 'layers');
            }
        });
    }

    componentWillUnmount() {
        if (this._onSelectionChangeObserver) {
            this.props.globalState.onSelectionChangedObservable.remove(this._onSelectionChangeObserver);
        }

        if (this._onNodeTabsChangedObservable) {
            this.props.globalState.onNodeTabsChangedObservable.remove(this._onNodeTabsChangedObservable);
        }

        if (this._onSelectionRenamedObserver) {
            this.props.globalState.onSelectionRenamedObservable.remove(this._onSelectionRenamedObserver);
        }

        if (this._onNewSceneAddedObserver) {
          EngineStore.LastCreatedEngine!.onNewSceneAddedObservable.remove(this._onNewSceneAddedObserver);
        }

        if (this._onNewSceneObserver) {
            this.props.globalState.onNewSceneObservable.remove(this._onNewSceneObserver);
        }

        const { scene } = this.state;

        scene.onNewSkeletonAddedObservable.removeCallback(this._sceneMutationFunc);
        scene.onNewCameraAddedObservable.removeCallback(this._sceneMutationFunc);
        scene.onNewLightAddedObservable.removeCallback(this._sceneMutationFunc);
        scene.onNewMaterialAddedObservable.removeCallback(this._sceneMutationFunc);
        scene.onNewMeshAddedObservable.removeCallback(this._sceneMutationFunc);
        scene.onNewTextureAddedObservable.removeCallback(this._sceneMutationFunc);
        scene.onNewTransformNodeAddedObservable.removeCallback(this._sceneMutationFunc);

        scene.onSkeletonRemovedObservable.removeCallback(this._sceneMutationFunc);
        scene.onMeshRemovedObservable.removeCallback(this._sceneMutationFunc);
        scene.onCameraRemovedObservable.removeCallback(this._sceneMutationFunc);
        scene.onLightRemovedObservable.removeCallback(this._sceneMutationFunc);
        scene.onMaterialRemovedObservable.removeCallback(this._sceneMutationFunc);
        scene.onTransformNodeRemovedObservable.removeCallback(this._sceneMutationFunc);
        scene.onTextureRemovedObservable.removeCallback(this._sceneMutationFunc);
    }

    filterContent(filter: string) {
        this.setState({ filter });
    }

    findSiblings(parent: any, items: any[], target: any, goNext: boolean, data: { previousOne?: any; found?: boolean }): boolean {
        if (!items) {
            return false;
        }

        const sortedItems = Tools.SortAndFilter(parent, items.filter((el) => !HIDE_SCENE_OBJECTS_ID.includes(el.id)));

        if (!items || sortedItems.length === 0) {
            return false;
        }

        for (const item of sortedItems) {
            if (item === target) {
                // found the current selection!
                data.found = true;
                if (!goNext) {
                    if (data.previousOne) {
                        this.props.globalState.onSelectionChangedObservable.notifyObservers(data.previousOne);
                    }
                    return true;
                }
            } else {
                if (data.found) {
                    this.props.globalState.onSelectionChangedObservable.notifyObservers(item);
                    return true;
                }
                data.previousOne = item;
            }

            if (item.getChildren && item.reservedDataStore && item.reservedDataStore.isExpanded) {
                if (this.findSiblings(item, item.getChildren(), target, goNext, data)) {
                    return true;
                }
            }
        }

        return false;
    }

    processKeys(keyEvent: React.KeyboardEvent<HTMLDivElement>) {
        if (!this.state.selectedEntity || keyEvent.target.className.includes("one-ui-input")) {
            return;
        }

        const { scene } = this.state;
        let search = false;
        let goNext = false;

        if (keyEvent.keyCode === KEY_CODES.UP) {
            search = true;
        } else if (keyEvent.keyCode === KEY_CODES.DOWN) {
            goNext = true;
            search = true;
        } else if (keyEvent.keyCode === KEY_CODES.ENTER || keyEvent.keyCode === KEY_CODES.RIGHT) {
            const { reservedDataStore } = this.state.selectedEntity;
            if (reservedDataStore && reservedDataStore.setExpandedState) {
                reservedDataStore.setExpandedState(true);
            }
            keyEvent.preventDefault();
            return;
        } else if (keyEvent.keyCode === KEY_CODES.LEFT) {
            const { reservedDataStore } = this.state.selectedEntity;
            if (reservedDataStore && reservedDataStore.setExpandedState) {
                reservedDataStore.setExpandedState(false);
            }
            keyEvent.preventDefault();
            return;
        } else if (keyEvent.keyCode === KEY_CODES.DELETE) {
            const className = this.state.selectedEntity.getClassName();
            if (className === 'Scene') {
                this.props.globalState.showToast({
                    severity: TOAST_ERROR,
                    detail:   `You can't delete the whole scene`,
                    summary:  'Delete error'
                });
            } else if (this.state.selectedEntity instanceof Texture) {
                this.props.globalState.showToast({
                    severity: TOAST_ERROR,
                    detail:   `You can't delete texture`,
                    summary:  'Delete error'
                });
            } else {
                const deleteCommand = new DeleteCommand(
                    this.renderer,
                    this.state.selectedEntity as Texture | TransformNode | Mesh | Light | Material
                );
                this.sceneManager?.commander.executeCommand(deleteCommand);
            }
        }

        if (!search) {
            return;
        }

        keyEvent.preventDefault();

        const data = {};
        if (this.state.selectedEntity instanceof Material) {
            this.findSiblings(null, scene.materials, this.state.selectedEntity, goNext, data);
        } else if (this.state.selectedEntity instanceof Texture) {
            this.findSiblings(null, scene.textures, this.state.selectedEntity, goNext, data);
        } else {
            this.findSiblings(null, scene.rootNodes, this.state.selectedEntity, goNext, data);
        }
    }

    initPanel() {
        const { scene } = this.state;

        if (!scene) {
            this._onNewSceneAddedObserver = EngineStore.LastCreatedEngine!.onNewSceneAddedObservable.addOnce((_scene) => this.setState({ _scene }));
            return false;
        }

        if (!this._hooked) {
            this._hooked = true;
            scene.onNewSkeletonAddedObservable.add(this._sceneMutationFunc);
            scene.onNewCameraAddedObservable.add(this._sceneMutationFunc);
            scene.onNewLightAddedObservable.add(this._sceneMutationFunc);
            scene.onNewMaterialAddedObservable.add(this._sceneMutationFunc);
            scene.onNewMeshAddedObservable.add(this._sceneMutationFunc);
            scene.onNewTextureAddedObservable.add(this._sceneMutationFunc);
            scene.onNewTransformNodeAddedObservable.add(this._sceneMutationFunc);

            scene.onSkeletonRemovedObservable.add(this._sceneMutationFunc);
            scene.onMeshRemovedObservable.add(this._sceneMutationFunc);
            scene.onCameraRemovedObservable.add(this._sceneMutationFunc);
            scene.onLightRemovedObservable.add(this._sceneMutationFunc);
            scene.onMaterialRemovedObservable.add(this._sceneMutationFunc);
            scene.onTransformNodeRemovedObservable.add(this._sceneMutationFunc);
            scene.onTextureRemovedObservable.add(this._sceneMutationFunc);
        }
        return true;
    }

    onCollapseChange() {
        const parent = document.getElementById('scene-explorer-host');
        if (parent) {
            parent.style.height = this.state.collapsed ? '100%' : 'fit-content';
        }
        this.setState((prevState) => ({ collapsed: !prevState.collapsed }));
    }

    renderTextures() {
        if (!this.initPanel()) {
            return null;
        }
        const { scene } = this.state;
        const textures = scene.textures.filter((t) => t.getClassName() !== "AdvancedDynamicTexture" && t.name
          && !t?.url?.includes('https://assets.babylonjs.com/environments'));
        const sortedItems = Tools.SortAndFilter(null, textures);
        const activeTexture = this.state.selectedTexture || this.state.selectedEntity;
        return (
            <ScenePanelComponent
                title="Textures"
                changeTitleBackgroundOnHover={ false }
                clickMode="icon"
                stickyToHeaderComponent={ (
                    <SceneExplorerFilterComponent
                        value={ this.state.filter || '' }
                        disabled={ !sortedItems.length }
                        onFilter={ (filter) => this.filterContent(filter) }
                    />
                ) }
                onCollapseChange={ () => this.onCollapseChange() }
                collapsed={ this.state.collapsed }
            >
                { sortedItems.map((item) => (
                    <TreeItemSelectableComponent
                        extensibilityGroups={ this.props.extensibilityGroups }
                        key={ item.uniqueId !== undefined && item.uniqueId !== null ? item.uniqueId : item.name }
                        offset={ 1 }
                        selectedEntity={ activeTexture }
                        entity={ item }
                        globalState={ this.props.globalState }
                        filter={ this.state.filter }
                    />
                )) }
                { !sortedItems.length && <EmptyTab label="texture" /> }
            </ScenePanelComponent>
        );
    }

    renderMaterials() {
        if (!this.initPanel()) {
            return null;
        }
        const { scene } = this.state;
        const getUniqueName = (name: string): string => {
            let idSubscript = 1;
            let newName = name;
            while (scene.getMaterialById(name)) {
                newName = `${ name } ${ idSubscript += 1 }`;
            }
            return newName;
        };
        // Materials
        const materialsContextMenus: { label: string; action: () => void }[] = [];
        materialsContextMenus.push({
            label:  "Add new standard material",
            action: () => {
                const newStdMaterial = new StandardMaterial(getUniqueName("Standard material"), scene);
                this.props.globalState.onSelectionChangedObservable.notifyObservers(newStdMaterial);
            }
        });
        materialsContextMenus.push({
            label:  "Add new PBR material",
            action: () => {
                const newPBRMaterial = new PBRMaterial(getUniqueName("PBR material"), scene);
                this.props.globalState.onSelectionChangedObservable.notifyObservers(newPBRMaterial);
            }
        });
        materialsContextMenus.push({
            label:  "Add new node material",
            action: () => {
                const newNodeMaterial = new NodeMaterial(getUniqueName("node material"), scene);
                newNodeMaterial.setToDefault();
                newNodeMaterial.build();
                this.props.globalState.onSelectionChangedObservable.notifyObservers(newNodeMaterial);
            }
        });

        let materials = [];

        materials.push(...scene.materials);

        if (scene.multiMaterials && scene.multiMaterials.length) {
            materials.push(...scene.multiMaterials);
        }

        const HIDE_MATERIALS_ID = [ 'BackgroundPlaneMaterial', 'GridMaterial' ];
        if (!this.props.globalState.isDevMode) {
            materials = materials.filter((el) => !HIDE_MATERIALS_ID.includes(el.id));
        }

        const activeItem = this.state.selectedMaterial || this.state.selectedEntity;
        const sortedItems = Tools.SortAndFilter(null, materials);
        return (
            <ScenePanelComponent
                title="Materials"
                clickMode="icon"
                changeTitleBackgroundOnHover={ false }
                stickyToHeaderComponent={ (
                    <SceneExplorerFilterComponent
                        disabled={ !sortedItems.length }
                        value={ this.state.filter || '' }
                        onFilter={ (filter) => this.filterContent(filter) }
                    />
                ) }
                onCollapseChange={ () => this.onCollapseChange() }
                collapsed={ this.state.collapsed }
            >
                { sortedItems.map((item) => (
                    <TreeItemSelectableComponent
                        extensibilityGroups={ this.props.extensibilityGroups }
                        key={ item.uniqueId !== undefined && item.uniqueId !== null ? item.uniqueId : item.name }
                        offset={ 1 }
                        selectedEntity={ activeItem }
                        entity={ item }
                        globalState={ this.props.globalState }
                        filter={ this.state.filter }
                    />
                )) }
                { !sortedItems.length && <EmptyTab label="material" /> }
            </ScenePanelComponent>
        );
    }

    renderContent() {
        if (!this.initPanel()) {
            return null;
        }
        const { scene } = this.state;

        const guiElements = scene.textures.filter((t) => t.getClassName() === "AdvancedDynamicTexture");
        const { postProcesses } = scene;
        const pipelines = scene.postProcessRenderPipelineManager.supportedPipelines;

        // Context menus
        const pipelineContextMenus: { label: string; action: () => void }[] = [];

        if (scene.activeCamera) {
            if (!pipelines.some((p) => p.getClassName() === "DefaultRenderingPipeline")) {
                pipelineContextMenus.push({
                    label:  "Add new Default Rendering Pipeline",
                    action: () => {
                        const newPipeline = new DefaultRenderingPipeline("Default rendering pipeline", true, scene, scene.cameras);
                        this.props.globalState.onSelectionChangedObservable.notifyObservers(newPipeline);
                    }
                });
            }

            if (!pipelines.some((p) => p.getClassName() === "SSAORenderingPipeline")) {
                pipelineContextMenus.push({
                    label:  "Add new SSAO Rendering Pipeline",
                    action: () => {
                        const newPipeline = new SSAORenderingPipeline("SSAO rendering pipeline", scene, 1, scene.cameras);
                        this.props.globalState.onSelectionChangedObservable.notifyObservers(newPipeline);
                    }
                });
            }

            if (scene.getEngine().getCaps().drawBuffersExtension && !pipelines.some((p) => p.getClassName() === "SSAORenderingPipeline")) {
                pipelineContextMenus.push({
                    label:  "Add new SSAO2 Rendering Pipeline",
                    action: () => {
                        const newPipeline = new SSAO2RenderingPipeline("SSAO2 rendering pipeline", scene, 1, scene.cameras);
                        this.props.globalState.onSelectionChangedObservable.notifyObservers(newPipeline);
                    }
                });
            }
        }

        const nodeContextMenus: { label: string; action: () => void }[] = [];
        nodeContextMenus.push({
            label:  "Add new point light",
            action: () => {
                const newPointLight = new PointLight("point light", Vector3.Zero(), scene);
                this.props.globalState.onSelectionChangedObservable.notifyObservers(newPointLight);
            }
        });
        nodeContextMenus.push({
            label:  "Add new directional light",
            action: () => {
                const newDirectionalLight = new DirectionalLight("directional light", new Vector3(-1, -1, -0.5), scene);
                this.props.globalState.onSelectionChangedObservable.notifyObservers(newDirectionalLight);
            }
        });
        nodeContextMenus.push({
            label:  "Add new free camera",
            action: () => {
                const newFreeCamera = new FreeCamera("free camera", scene.activeCamera ? scene.activeCamera.globalPosition : new Vector3(0, 0, -5), scene);

                if (scene.activeCamera) {
                    newFreeCamera.minZ = scene.activeCamera.minZ;
                    newFreeCamera.maxZ = scene.activeCamera.maxZ;
                    if ((scene.activeCamera as any).getTarget) {
                        newFreeCamera.setTarget((scene.activeCamera as TargetCamera).getTarget());
                    }
                }

                this.props.globalState.onSelectionChangedObservable.notifyObservers(newFreeCamera);
            }
        });

        // Sprite Managers
        const spriteManagersContextMenus: { label: string; action: () => void }[] = [];
        spriteManagersContextMenus.push({
            label:  "Add new sprite manager",
            action: () => {
                const newSpriteManager = new SpriteManager(
                    "Default sprite manager",
                    "//playground.babylonjs.com/textures/player.png",
                    500,
                    64,
                    scene
                );
                this.props.globalState.onSelectionChangedObservable.notifyObservers(newSpriteManager);
            }
        });

        // Particle systems
        const particleSystemsContextMenus: { label: string; action: () => void }[] = [];
        particleSystemsContextMenus.push({
            label:  "Add new CPU particle system",
            action: () => {
                const newSystem = ParticleHelper.CreateDefault(Vector3.Zero(), 10000, scene);
                newSystem.name = "CPU particle system";
                newSystem.start();
                this.props.globalState.onSelectionChangedObservable.notifyObservers(newSystem);
            }
        });

        if (GPUParticleSystem.IsSupported) {
            particleSystemsContextMenus.push({
                label:  "Add new GPU particle system",
                action: () => {
                    const newSystem = ParticleHelper.CreateDefault(Vector3.Zero(), 10000, scene, true);
                    newSystem.name = "GPU particle system";
                    newSystem.start();
                    this.props.globalState.onSelectionChangedObservable.notifyObservers(newSystem);
                }
            });
        }

        const rootNodes = this.props.globalState.isDevMode ? scene.rootNodes.slice(0)
            : scene.rootNodes.slice(0).filter((el) => !HIDE_SCENE_OBJECTS_ID.includes(el.id));

        // Adding nodes parented to a bone
        scene.meshes.forEach((mesh) => {
            if (mesh.parent && mesh.parent.getClassName() === "Bone") {
                rootNodes.push(mesh);
            }
        });

        const sortedRootNodes = Tools.SortAndFilter(null, rootNodes);
        const selectedItem = this.state.selectedNavigator || this.state.selectedEntity;
        return (
            <div id="tree" style={ { width: '100%', height: '100%' } } onContextMenu={ (e) => e.preventDefault() }>
                <ScenePanelComponent
                    title="Navigator"
                    clickMode="icon"
                    changeTitleBackgroundOnHover={ false }
                    stickyToHeaderComponent={
                        <SceneExplorerFilterComponent value={ this.state.filter || '' } onFilter={ (filter) => this.filterContent(filter) } />
                    }
                    onCollapseChange={ () => this.onCollapseChange() }
                    collapsed={ this.state.collapsed }
                >
                    <Collapse
                        clickMode="icon"
                        paddingLeft="off"
                        collapsed={ false }
                        iconCollapsedProps={ {
                            name:   'arrowRight',
                            color:  'shade-4',
                            margin: 'xxs',
                            size:   16
                        } }
                        panelContent={ (
                            <SceneTreeItemComponent
                                sceneName="Scene"
                                sceneNameDescriptionProps={ { size: 'sm' } }
                                selectedEntity={ selectedItem }
                                scene={ scene }
                            />
                        ) }
                        panelWrapperProps={ {
                            valign:    'center',
                            padding:   'xs',
                            className: 'sceneTreeRootWrapper',
                            radius:    'sm'
                        } }
                    >
                        <>
                            {
                                sortedRootNodes.map((item) => (
                                    <TreeItemSelectableComponent
                                        extensibilityGroups={ this.props.extensibilityGroups }
                                        key={ item.uniqueId !== undefined && item.uniqueId !== null ? item.uniqueId : item.name }
                                        offset={ 1 }
                                        selectedEntity={ selectedItem }
                                        entity={ item }
                                        globalState={ this.props.globalState }
                                        filter={ this.state.filter }
                                    />
                                ))
                            }
                            { this.props.globalState.isDevMode ? (
                                <>
                                    { scene.skeletons.length > 0 && (
                                        <TreeItemComponent
                                            globalState={ this.props.globalState }
                                            extensibilityGroups={ this.props.extensibilityGroups }
                                            selectedEntity={ selectedItem }
                                            items={ scene.skeletons }
                                            label="Skeletons"
                                            offset={ 1 }
                                            filter={ this.state.filter }
                                        />
                                    ) }
                                    { postProcesses.length > 0 && (
                                        <TreeItemComponent
                                            globalState={ this.props.globalState }
                                            extensibilityGroups={ this.props.extensibilityGroups }
                                            selectedEntity={ selectedItem }
                                            items={ postProcesses }
                                            label="Post-processes"
                                            offset={ 1 }
                                            filter={ this.state.filter }
                                        />
                                    ) }
                                    <TreeItemComponent
                                        globalState={ this.props.globalState }
                                        extensibilityGroups={ this.props.extensibilityGroups }
                                        contextMenuItems={ pipelineContextMenus }
                                        selectedEntity={ selectedItem }
                                        items={ pipelines }
                                        label="Rendering pipelines"
                                        offset={ 1 }
                                        filter={ this.state.filter }
                                    />
                                    { scene.effectLayers && scene.effectLayers.length > 0 && (
                                        <TreeItemComponent
                                            globalState={ this.props.globalState }
                                            extensibilityGroups={ this.props.extensibilityGroups }
                                            selectedEntity={ selectedItem }
                                            items={ scene.effectLayers }
                                            label="Effect layers"
                                            offset={ 1 }
                                            filter={ this.state.filter }
                                        />
                                    ) }
                                    <TreeItemComponent
                                        globalState={ this.props.globalState }
                                        contextMenuItems={ particleSystemsContextMenus }
                                        extensibilityGroups={ this.props.extensibilityGroups }
                                        selectedEntity={ selectedItem }
                                        items={ scene.particleSystems }
                                        label="Particle systems"
                                        offset={ 1 }
                                        filter={ this.state.filter }
                                    />
                                    <TreeItemComponent
                                        globalState={ this.props.globalState }
                                        contextMenuItems={ spriteManagersContextMenus }
                                        forceSubitems
                                        extensibilityGroups={ this.props.extensibilityGroups }
                                        selectedEntity={ selectedItem }
                                        items={ scene.spriteManagers }
                                        label="Sprite managers"
                                        offset={ 1 }
                                        filter={ this.state.filter }
                                    />
                                    { guiElements && guiElements.length > 0 && (
                                        <TreeItemComponent
                                            globalState={ this.props.globalState }
                                            extensibilityGroups={ this.props.extensibilityGroups }
                                            selectedEntity={ selectedItem }
                                            items={ guiElements }
                                            label="GUI"
                                            offset={ 1 }
                                            filter={ this.state.filter }
                                        />
                                    ) }
                                    { scene.animationGroups.length > 0 && (
                                        <TreeItemComponent
                                            globalState={ this.props.globalState }
                                            extensibilityGroups={ this.props.extensibilityGroups }
                                            selectedEntity={ selectedItem }
                                            items={ scene.animationGroups }
                                            label="Animation groups"
                                            offset={ 1 }
                                            filter={ this.state.filter }
                                        />
                                    ) }
                                    { scene.mainSoundTrack && scene.mainSoundTrack.soundCollection.length > 0 && (
                                        <TreeItemComponent
                                            globalState={ this.props.globalState }
                                            extensibilityGroups={ this.props.extensibilityGroups }
                                            selectedEntity={ selectedItem }
                                            items={ scene.mainSoundTrack.soundCollection }
                                            label="Sounds"
                                            offset={ 1 }
                                            filter={ this.state.filter }
                                        />
                                    ) }
                                </>
                            ) : <></> }
                        </>
                    </Collapse>
                </ScenePanelComponent>
            </div>
        );
    }

    onClose() {
        if (!this.props.onClose) {
            return;
        }

        this.props.onClose();
    }

    onPopup() {
        if (!this.props.onPopup) {
            return;
        }

        this.props.onPopup();
    }

    updateRightPanelContent = (entity?: Material | Texture | TransformNode | Mesh | Scene | null) => {
        if (!entity) {
            return;
        }
        this.props.globalState.onRightPanelChangeObservable.notifyObservers(entity);
        this.setState({ selectedEntity: entity });
    };

    onChangeActiveTab(_event: React.MouseEvent, newTabId: string) {
        if (newTabId === this.state.activeTab) {
            this.closeAddPanel();
            return;
        }
        if (newTabId === 'addAsset') {
            if (this.state.collapsed) {
                this.onCollapseChange();
            }
            this.lastOpenedTab = this.state.activeTab;
        }
        if (newTabId === 'materials') {
            this.updateRightPanelContent(this.state.selectedMaterial);
        }
        if (newTabId === 'layers') {
            this.updateRightPanelContent(this.state.selectedNavigator as Mesh | TransformNode);
        }
        if (newTabId === 'textures') {
            this.updateRightPanelContent(this.state.selectedTexture);
        }

        this.setState({ activeTab: newTabId });
    }

    closeAddPanel = (panelId?: 'materials' | 'textures' | 'layers') => {
        this.setState({ activeTab: panelId || this.lastOpenedTab });
    };

    render() {
        if (this.props.popupMode) {
            return (
                <div id="sceneExplorer" tabIndex={ 0 } onKeyDown={ (keyEvent) => this.processKeys(keyEvent) }>
                    { !this.props.noHeader && (
                        <HeaderComponent
                            title="SCENE EXPLORER"
                            noClose={ this.props.noClose }
                            noExpand={ this.props.noExpand }
                            noCommands={ this.props.noCommands }
                            onClose={ () => this.onClose() }
                            onPopup={ () => this.onPopup() }
                        />
                    ) }
                    { this.renderContent() }
                </div>
            );
        }

        if (this._once) {
            this._once = false;
            // A bit hacky but no other way to force the initial width to 300px and not auto
            setTimeout(() => {
                const element = document.getElementById("sceneExplorer");
                if (!element) {
                    return;
                }
                element.style.width = "300px";
            }, 150);
        }

        return (
            <Wrapper fullHeight>
                <Wrapper style={ { zIndex: 1 } }>
                    <Tabs
                        activeTab={ this.state.activeTab }
                        orientation="horizontal"
                        tabsContainerStyle={ { marginBottom: 8 } }
                    >
                        {
                            [
                                <TabsItem
                                    key="first-tab-item"
                                    onChange={ this.onChangeActiveTab }
                                    tabId="addAsset"
                                    style={ { marginRight: 8 } }
                                    tooltip={ { message: "Add new item", position: 'right', delay: TOOLTIP_DELAY } }
                                >
                                    <Wrapper
                                        frame="solid-opacity-white"
                                        blur
                                        shadow="md"
                                        radius="xxl"
                                        padding="xxs"
                                    >
                                        <Button
                                            viewType="transparent"
                                            align="center"
                                            size="md"
                                            radius="lg"
                                            iconSize={ 17.5 }
                                            icon="add"
                                            id="addAsset"
                                            iconColor={ (this.state.activeTab === 'addAsset') ? 'white' : 'shade-1' }
                                            active={ this.state.activeTab === 'addAsset' }
                                        />
                                    </Wrapper>
                                </TabsItem>,
                                <Wrapper
                                    key="tab-item-second-container"
                                    frame="solid-opacity-white"
                                    blur
                                    shadow="md"
                                    row
                                    radius="xxl"
                                    padding="xxs"
                                >
                                    <TabsItem
                                        onChange={ this.onChangeActiveTab }
                                        tabId="layers"
                                        tooltip={ { message: "Layers", minWidth: '60px', delay: TOOLTIP_DELAY } }
                                        margin="xxs"
                                    >
                                        <Button
                                            viewType="transparent"
                                            align="center"
                                            size="md"
                                            radius="lg"
                                            iconSize={ 24 }
                                            icon="layers"
                                            iconColor={ (this.state.activeTab === 'layers') ? 'white' : 'shade-1' }
                                            active={ this.state.activeTab === 'layers' }
                                        />
                                    </TabsItem>
                                    <TabsItem
                                        onChange={ this.onChangeActiveTab }
                                        tabId="materials"
                                        tooltip={ { message: "Materials", minWidth: '60px', delay: TOOLTIP_DELAY } }
                                        margin="xxs"
                                    >
                                        <Button
                                            viewType="transparent"
                                            align="center"
                                            size="md"
                                            radius="lg"
                                            iconSize={ 24 }
                                            icon="materials"
                                            iconColor={ (this.state.activeTab === 'materials') ? 'white' : 'shade-1' }
                                            active={ this.state.activeTab === 'materials' }
                                        />
                                    </TabsItem>
                                    <TabsItem
                                        onChange={ this.onChangeActiveTab }
                                        tabId="textures"
                                        tooltip={ { message: "Textures", minWidth: '60px', delay: TOOLTIP_DELAY } }
                                    >
                                        <Button
                                            viewType="transparent"
                                            align="center"
                                            size="md"
                                            radius="lg"
                                            iconSize={ 24 }
                                            icon="textures"
                                            iconColor={ (this.state.activeTab === 'textures') ? 'white' : 'shade-1' }
                                            active={ this.state.activeTab === 'textures' }
                                        />
                                    </TabsItem>
                                </Wrapper>
                            ]
                        }
                    </Tabs>
                </Wrapper>
                <ResizeWrapper
                    id="sceneExplorer"
                    enable={ { right: true } }
                    onKeyDown={ (keyEvent) => this.processKeys(keyEvent) }
                >
                    <TabsPanelContainer activeTabId={ this.state.activeTab } wrapperProps={ { fullHeight: true } }>
                        <TabsPanel tabPanelId="addAsset">
                            <ScenePanelComponent
                                clickMode="none"
                                title="Add new item"
                                hideCollapseIcon
                                changeTitleBackgroundOnHover={ false }
                            >
                                <this.AssetsSourcePickerBabylon onPanelClose={ this.closeAddPanel } />
                            </ScenePanelComponent>
                        </TabsPanel>
                        <TabsPanel tabPanelId="layers">
                            { this.renderContent() }
                        </TabsPanel>
                        <TabsPanel tabPanelId="materials">
                            { this.renderMaterials() }
                        </TabsPanel>
                        <TabsPanel tabPanelId="textures">
                            { this.renderTextures() }
                        </TabsPanel>
                    </TabsPanelContainer>
                </ResizeWrapper>

            </Wrapper>
        );
    }
}
// @ts-ignore
export const SceneExplorerWithDi = withDIContext(_SceneExplorerComponent);
