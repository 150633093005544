import React, { memo }       from "react";
import { cn, Icon, Wrapper } from "@geenee/ui";
import { IconPropsType }     from "../../icon/icon.component";
import { WrapperPropsType }  from "../../wrapper/wrapper.component";

export type CollapsePanelProps = {
    icon: React.ReactNode;
    panelContent: React.ReactNode | ((collapsed: boolean) => React.ReactNode);
    iconCollapsedAfter?: boolean;
    iconCollapsedProps?: IconPropsType;
    iconCollapsedRotationAngle?: string;
    iconAfter?: boolean;
    useDefaultCollapseIcon?: boolean;
    panelWrapperProps?: WrapperPropsType;
    collapsed: boolean;
    onCollapseChange?: (collapsed: boolean) => void;
    clickMode?: 'icon' | 'container' | 'none'
    changeTitleBackgroundOnHover?: boolean
    hasIcon?: boolean;
}

const className = cn('collapse-panel');

export const CollapsePanel = memo(({
    iconCollapsedAfter,
    iconCollapsedRotationAngle = '90deg',
    iconCollapsedProps, icon,
    panelContent,
    panelWrapperProps,
    collapsed,
    onCollapseChange,
    clickMode = 'container',
    changeTitleBackgroundOnHover = false,
    hasIcon = true
}: CollapsePanelProps) => {
    const onClick = (event: React.MouseEvent<HTMLDivElement, PointerEvent>) => {
        onCollapseChange && onCollapseChange(!collapsed);
        panelWrapperProps?.onClick && panelWrapperProps?.onClick(event);
    };

    const collapsedIconLayout = hasIcon ? (
        <Icon
            className={ className('icon', { collapsed, after: iconCollapsedAfter }) }
            margin={ iconCollapsedAfter ? "off" : "md" }
            name="arrowRight"
            color="shade-3"
            { ...iconCollapsedProps }
            style={ { '--collapse-icon-rotation-angle': iconCollapsedRotationAngle } as React.CSSProperties }
            onClick={ clickMode === 'icon' ? onClick : undefined }
        />
    ) : null;

    return (
        <Wrapper
            row
            className={ panelWrapperProps?.className ? panelWrapperProps?.className : className({ collapsed }) }
            valign="center"
            frame={ changeTitleBackgroundOnHover ? 'transparent-hover-opacity-white' : undefined }
            { ...panelWrapperProps }
            onClick={ clickMode === 'container' ? onClick : undefined }
        >
            { !iconCollapsedAfter && collapsedIconLayout }
            { icon }
            { typeof panelContent === 'function'
                ? panelContent(collapsed) : panelContent }
            { iconCollapsedAfter && collapsedIconLayout }
        </Wrapper>
    );
});
