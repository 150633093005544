import { isIOS, getChromeVersion, isInsideCrossOriginIFrame, isR7 } from "./util";

/**
 *
 */
export default class BasePoseSensor {
  protected started: boolean;
  protected isDeviceMotionInRadians: boolean;

  private isIOS: boolean;
  private isR7: boolean;
  private isInsideCrossOriginIFrame: boolean;
  private timestamp: number;
  private rotationRate: Array<number>;

  /**
   *
   */
  constructor() {
    this.isIOS = isIOS();
    this.isR7 = isR7();
    this.isDeviceMotionInRadians = (!this.isIOS && getChromeVersion() && (getChromeVersion() as number < 66)) as boolean;
    this.isInsideCrossOriginIFrame = isInsideCrossOriginIFrame();
    this.started = false;
    this.timestamp = 0;
    this.rotationRate = [0, 0, 0];
  }

  //-----------------------------------------------------------------
  //
  //  Public methods
  //
  //-----------------------------------------------------------------

  public getTimestamp(): number {
    return this.timestamp;
  }

  public getRotationRate(): Array<number> {
    return this.rotationRate;
  }

  public isStarted(): boolean {
    return this.started;
  }

  public startWithRequestPermisson(): Promise<void> {
    return new Promise(resolve => resolve());
  }

  //-----------------------------------------------------------------
  //
  //  Private methods
  //
  //-----------------------------------------------------------------

  /**
   *
   * @param {DeviceMotionEvent} e
   * @private
   */
  updateTimestamp(e: DeviceMotionEvent) {
    const timestamp = e.timeStamp / 1000;
    this.timestamp = timestamp;
  }

  /**
   *
   * @param {DeviceMotionEvent} e
   * @private
   */
  updateRotationRate(e: DeviceMotionEvent) {
    const alpha = e.rotationRate?.alpha as number;
    const beta = e.rotationRate?.beta as number;
    const gamma = e.rotationRate?.gamma as number;

    if (this.isR7) {
      this.rotationRate = [-beta, alpha, gamma];
    } else {
      this.rotationRate = [alpha, beta, gamma];
    }
    if (!this.isDeviceMotionInRadians) {
      const grad_to_rad = Math.PI / 180;
      this.rotationRate[0] *= grad_to_rad;
      this.rotationRate[1] *= grad_to_rad;
      this.rotationRate[2] *= grad_to_rad;
    }
  }

  //-----------------------------------------------------------------
  //
  //  Event handlers
  //
  //-----------------------------------------------------------------

  /**
   *
   * @param {DeviceMotionEvent} e
   * @private
   */
  onDeviceMotion(e: DeviceMotionEvent) {
    this.updateTimestamp(e);
    this.updateRotationRate(e);
  }
}