import { Processor }                                   from "@geenee/armature";
import { FaceProcessor, PoseProcessor }                from "@geenee/bodyprocessors";
import { isArSceneType }                               from '@geenee/geeclient-kit/src/lib/component/scene/util';
import * as Geetracker from '@geenee/geetracker';
const  { slam, stamp  }                                = Geetracker;
import { ExperienceModel, SceneFactory, SectionModel } from '@geenee/shared';
import { MoleculeFactory }                             from '@geenee/shared/src/magellan/molecule/factory/molecule.factory';
import { GeetrackerSlamProcessor }                     from '@geenee/shared/src/magellan/processor/geetracker/geetracker-slam.processor';
import { GeetrackerStampProcessor }                    from '@geenee/shared/src/magellan/processor/geetracker/geetracker-stamp.processor';
import { generateEmptySceneRenderer }                  from '@geenee/shared/src/magellan/section/factory/util';
import { AppStateType }                                from '@geenee/shared/src/magellan/state/app.state';
import {
    TYPE_BODY_TRACKING_OVERLAY,
    TYPE_BODY_TRACKING_TWIN,
    TYPE_HEAD_TRACKING, TYPE_SLAM_AR, TYPE_STAMP_AR
} from "@geenee/shared/src/util/constants";
import { injectable }        from 'inversify';
import { SectionSchemaType } from '../../../../type/section.type';
import { FactoryAbstract }   from '../../../util/factoryContainer';

const getProcessor = (type: string) => {
    if (type === TYPE_BODY_TRACKING_OVERLAY || type === TYPE_BODY_TRACKING_TWIN) {
        return PoseProcessor;
    }
    if (type === TYPE_HEAD_TRACKING) {
        return FaceProcessor;
    }
    if (process.env.ENV_GEENEE_APP === 'BUILDER') {
        return Processor;
    }
    if (type === TYPE_STAMP_AR) {
        return stamp;
    }
    if (type === TYPE_SLAM_AR) {
        return slam;
    }
    return Processor;
};

@injectable()
export class SectionFactory extends FactoryAbstract {
    create(sectionSchema: Partial<SectionSchemaType>, $parent?: ExperienceModel) {
        const sectionModel: SectionModel = this.container.get('<SectionModel>');
        const appState: AppStateType = this.container.get('<AppState>');
        const moleculeFactory: MoleculeFactory = this.container.get('<MoleculeFactory>');
        const sceneManagerFactory = this.container.get('<SceneManagerFactory>');
        Object.assign(sectionModel, sectionSchema);

        sectionModel.molecules.forEach((schema) => {
            const molecule = moleculeFactory.create(schema, sectionModel);
            sectionModel.moleculesRegistry.set(molecule.id, molecule);
        });

        if ($parent) {
            sectionModel.$parent = $parent;
        }

        // @ts-ignore
        if (sectionSchema.type && isArSceneType(sectionSchema.type) && sectionSchema.type && process.env.ENV_GEENEE_APP !== 'BUILDER') {
            const sceneFactory: SceneFactory = this.container.get('<SceneFactory>');
            const emptySceneSchema = generateEmptySceneRenderer(sectionSchema.type);
            sectionModel.sceneRenderer = sceneFactory.create(emptySceneSchema, appState);

            if ((sectionSchema.type === 'slam-ar'
                || sectionSchema.type === 'stamp-ar')
            ) {
                // @ts-ignore
                sectionModel.arProcessor = sectionSchema.type === 'slam-ar' ? new GeetrackerSlamProcessor() : new GeetrackerStampProcessor();
            }
        }

        if (sectionSchema.type && isArSceneType(sectionSchema.type)) {
            sectionModel.sceneManager = sceneManagerFactory.create({}, getProcessor(sectionModel.type), appState);
            if ([ TYPE_STAMP_AR, TYPE_SLAM_AR ].includes(sectionModel.type)) {
                sectionModel.sceneManager.rear = true;
            }
        }

        if (!sectionModel.custom_code?.code) {
            sectionModel.custom_code = {
                code:    'console.log("Here we go")',
                options: {}
            };
        }

        sectionModel.initCode();
        return sectionModel;
    }
}

export type SectionFactoryType = SectionFactory
