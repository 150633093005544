import {
    CAMERA_PLANE_HEIGHT,     CAMERA_PLANE_WIDTH,
    HEIGHT_POSITION_OFFSET
}                                        from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/camera-viewport.component3d';
import { BASIC_WIDTH, DELIMETER  } from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/tracker-image-plane.component3d';
import { fitCameraToObject }       from '@geenee/shared/src/util/fitCameraToObject';
import { generatePlaneMesh }       from '@geenee/shared/src/util/generatePlaneMesh';
import { setObjectProperties }     from '@geenee/shared/src/util/setObjectProperties';
import {
    AmbientLight,
    DirectionalLight,
    DoubleSide, Group,
    LinearFilter, LinearToneMapping,
    Mesh, MeshBasicMaterial,
    Object3D, PerspectiveCamera,
    PlaneGeometry, Scene, sRGBEncoding,
    TextureLoader,
    VideoTexture,
    WebGLRenderer
} from 'three';
import { GLTFLoader }    from 'three/examples/jsm/loaders/GLTFLoader';
import { setEnvMap }     from '@geenee/builder/src/lib/SceneObjectUtils';
import { AtomModel }     from '@geenee/builder/src/magellan/model/atom.model';
import { MoleculeModel } from '@geenee/builder/src/magellan/model/molecule.model';

const builderPreviewSceneStats = {
    renderer: new WebGLRenderer({
        preserveDrawingBuffer: true,
        alpha:                 true
    }),
    scene:            new Scene(),
    camera:           new PerspectiveCamera(60, 1, 0.1, 2000),
    ambientLight:     new AmbientLight(0xFFFFFF, 0.3),
    directionalLight: new DirectionalLight(0xFFFFFF, 0.95 * Math.PI),
    planeGeometry:    new PlaneGeometry(
        CAMERA_PLANE_WIDTH,
        CAMERA_PLANE_HEIGHT
    ),
    planeMesh: new Mesh(),
    created:   false
};

const loadTargetImageObject = async (url: string) => {
    const loader = new TextureLoader();
    const tex = await loader.loadAsync(url);
    const geometry = new PlaneGeometry();
    const material = new MeshBasicMaterial({
        map:         tex,
        side:        DoubleSide,
        transparent: true
    });
    // As the DELIMETER saved for only BASIC_WIDTH image, we need to calculate the new value,
    // but for the new image sizes
    const newDelimeter = (DELIMETER * tex.image.width) / BASIC_WIDTH;
    const planeHeight = tex.image.height / newDelimeter;
    const planeWidth = tex.image.width / newDelimeter;
    const imgMesh = new Mesh(geometry, material);
    imgMesh.scale.set(planeWidth, planeHeight, 1);
    return imgMesh;
};

const loadImageObject = async (url: string) => {
    const loader = new TextureLoader();
    const tex = await loader.loadAsync(url);
    const ratio = tex.image.height / tex.image.width;
    const geometry = new PlaneGeometry(1, ratio);
    const material = new MeshBasicMaterial({
        map:         tex,
        side:        DoubleSide,
        transparent: true
    });
    return new Mesh(geometry, material);
};

const loadVideoPreview = async (url: string) => {
    const video = document.createElement('video');

    video.muted = true;
    video.loop = false;
    video.preload = 'auto';
    video.autoplay = false;
    // @ts-ignore
    video.playsinline = true;
    // @ts-ignore
    video.crossorigin = 'anonymous';
    video.setAttribute('muted', 'true');
    video.setAttribute('loop', 'false');
    video.setAttribute('preload', 'auto');
    video.setAttribute('playsinline', 'true');
    video.setAttribute('crossorigin', 'anonymous');
    video.setAttribute('id', 'builderPreviewVideoElement');

    video.src = url;

    // set styles so works with iOS 13 but basically not visible
    video.style.display = 'block';
    video.style.position = 'absolute';
    video.style.top = '0px';
    video.style.left = '0px';
    video.style.opacity = '0.01';
    video.style.zIndex = '-100';

    const documentBody = document.querySelector('body');
    // @ts-ignore
    documentBody.appendChild(video);

    return new Promise((res) => {
        video.addEventListener('loadeddata', async () => {
            const videoTexture = new VideoTexture(video);
            videoTexture.flipY = false;
            videoTexture.minFilter = LinearFilter;
            const ratio = video.videoHeight / video.videoWidth;
            const videoTex = new VideoTexture(video);
            const geometry = new PlaneGeometry(1, ratio);
            const material = new MeshBasicMaterial({
                map:         videoTex,
                side:        DoubleSide,
                transparent: true
            });
            videoTex.needsUpdate = true;
            res(new Mesh(geometry, material));
            // @ts-ignore
            document.getElementById('builderPreviewVideoElement').remove();
        }, false);
    });
};

const removeAndDisposeObject = (object: Mesh) => {
    if (object) {
        // @ts-ignore
        object.material?.map?.dispose();
        // @ts-ignore
        object.material?.envMap?.dispose();
        // @ts-ignore
        object.material?.dispose();
        object.geometry?.dispose();
        builderPreviewSceneStats.renderer.renderLists.dispose(); // ?????
        builderPreviewSceneStats.scene.remove(object);
    }
};

const removeAndDisposeAllScene = () => {
    const thisScene = builderPreviewSceneStats.scene.children;

    // eslint-disable-next-line no-plusplus
    for (let i = thisScene.length - 1; i >= 0; i--) {
        if (thisScene[ i ] instanceof Mesh || thisScene[ i ] instanceof Group) {
            // @ts-ignore
            removeAndDisposeObject(thisScene[ i ]);
        }
    }
};

const loadAssets = async (children: AtomModel[], scene: Scene | Group) => children && Promise.all(children.map(async (child) => {
    if (child) {
        let object;
        const imgArr = [ 'png', 'jpg', 'jpeg' ];
        const gifArr = [ 'gif' ];
        const gltfArr = [ 'glb', 'gltf' ];
        const mp4Arr = [ 'mp4' ];
        // @ts-ignore
        const fileType = child.firstAsset.filename.split('.').pop();

        if (fileType) {
            if (child.firstObject) {
                object = child.firstObject;
            } else if (imgArr.includes(fileType)) {
                object = await loadImageObject(child.firstAsset.url);
            } else if (gifArr.includes(fileType)) {
                object = await loadImageObject(child.firstAsset.url);
            } else if (gltfArr.includes(fileType)) {
                const gltf = await (new GLTFLoader().loadAsync(child.firstAsset.url));
                object     = gltf.scene;
                object.animations = gltf.animations;
            } else if (mp4Arr.includes(fileType)) {
                object = await loadVideoPreview(child.firstAsset.url);
                // TODO: Make a preview for video
            }
        }

        setObjectProperties(object, child);
        scene.add(object);
        child.objectsRegistry.set(object.id, object);
    }
}));

const loadAssetByUrl = async (url: string, scene: Scene, fileName?: string) => {
    if (!url || url === '') return;
    let object;
    const imgArr = [ 'png', 'jpg', 'jpeg' ];
    const gifArr = [ 'gif' ];
    const gltfArr = [ 'glb', 'gltf' ];
    const mp4Arr = [ 'mp4' ];
    const fileType = ((fileName || url).split('.').pop() as string)?.toLowerCase();
    // const sceneStats = url.properties?.stats || null;

    if (imgArr.includes(fileType)) {
        object = await loadImageObject(url);
    } else if (gifArr.includes(fileType)) {
        object = await loadImageObject(url);
    } else if (gltfArr.includes(fileType)) {
        const gltf = await (new GLTFLoader().loadAsync(url));
        object = gltf.scene;
    } else if (mp4Arr.includes(fileType)) {
        object = await loadVideoPreview(url);
        // TODO: Make a preview for video
    }
    scene.add(object);
    return object;
};

const init3DScene = (
    rendererSize: {width: number; height: number}
) => {
    builderPreviewSceneStats.camera.aspect = rendererSize.width / rendererSize.height;
    builderPreviewSceneStats.camera.updateProjectionMatrix();

    if (!builderPreviewSceneStats.created) {
        builderPreviewSceneStats.renderer.outputColorSpace = sRGBEncoding;
        builderPreviewSceneStats.renderer.physicallyCorrectLights = true;
        builderPreviewSceneStats.renderer.toneMapping = LinearToneMapping;
        builderPreviewSceneStats.directionalLight.position.set(-0.5, 0.866, 0.866);

        builderPreviewSceneStats.scene.add(builderPreviewSceneStats.ambientLight);
        builderPreviewSceneStats.scene.add(builderPreviewSceneStats.directionalLight);
    }

    builderPreviewSceneStats.renderer.setSize(rendererSize.width, rendererSize.height);
};

const createSceneWrapper = (moleculeWithSceneStats?: MoleculeModel) => {
    const wrapper = new Group();
    setObjectProperties(wrapper, moleculeWithSceneStats);
    builderPreviewSceneStats.scene.add(wrapper);
    return wrapper;
};

const generateTrackerPlane = async (trackerUrl: string, customDistance?: number) => {
    const trackerMesh = await loadTargetImageObject(trackerUrl);
    builderPreviewSceneStats.scene.add(trackerMesh);
    fitCameraToObject(builderPreviewSceneStats.camera, trackerMesh, undefined, customDistance);
};

const generateImageData = (done: (value: string) => Promise<Blob>) => {
    builderPreviewSceneStats.renderer.render(builderPreviewSceneStats.scene, builderPreviewSceneStats.camera);
    const imgData = builderPreviewSceneStats.renderer.domElement.toDataURL('image/png');
    removeAndDisposeAllScene();
    builderPreviewSceneStats.created = true;
    return done(imgData);
};

const generateViewportPlane = async (lookAtObjectCenter: boolean, mainObject?: Object3D | Group, customDistance?: number) => {
    const planeMesh = generatePlaneMesh();
    builderPreviewSceneStats.scene.add(planeMesh);
    fitCameraToObject(builderPreviewSceneStats.camera, lookAtObjectCenter ? mainObject : planeMesh, lookAtObjectCenter, customDistance);
    // builderPreviewSceneStats.camera.position.set(builderPreviewSceneStats.camera.position.x, 0.6, builderPreviewSceneStats.camera.position.z);
    if (!lookAtObjectCenter) {
        builderPreviewSceneStats.camera.position.set(builderPreviewSceneStats.camera.position.x, 0.6, builderPreviewSceneStats.camera.position.z);
    }
    planeMesh.visible = false;
};

const generatePlane = async (trackerUrl: string, lookAtObjectCenter: boolean, mainObject?: Object3D | Group, customDistance?: number) => {
    if (trackerUrl) {
        await generateTrackerPlane(trackerUrl, customDistance);
    } else {
        await generateViewportPlane(lookAtObjectCenter, mainObject, customDistance);
    }
};

export const generateModelPreviewByUrl = async ({
    url = '',
    trackerUrl = '',
    done,
    rendererSize = { width: 1000, height: 1000 },
    lookAtObjectCenter = false,
    customDistance,
    fileName
} : {
    url: string,
    trackerUrl?: string,
    done: (value: any) => Promise<any>,
    rendererSize: {width: number; height: number},
    lookAtObjectCenter?: boolean,
    customDistance?: number,
    fileName?: string,
    }): Promise<Blob> => {
    init3DScene(rendererSize);
    const wrapper = createSceneWrapper();
    const children = [];
    // @ts-ignore
    const mainObject = await loadAssetByUrl(url, wrapper, fileName);
    await loadAssets(children, wrapper);
    await setEnvMap(builderPreviewSceneStats.scene);
    await generatePlane(trackerUrl, lookAtObjectCenter, mainObject, customDistance);
    return generateImageData(done);
};

const generateModelPreview = async ({
    trackerUrl = '',
    done,
    modelsToBeRendered = [],
    rendererSize = { width: 1000, height: 1000 },
    lookAtObjectCenter = false,
    customDistance,
    moleculeWithSceneStats
}: {
    trackerUrl?: string,
    done: (value: any) => Promise<Blob | any>,
    modelsToBeRendered: AtomModel[],
    rendererSize?: { width: number; height: number },
    lookAtObjectCenter?: boolean,
    customDistance?: number,
    moleculeWithSceneStats?: MoleculeModel
}): Promise<Blob> => {
    init3DScene(rendererSize);
    const wrapper = createSceneWrapper(moleculeWithSceneStats);
    const mainObjectAtom = modelsToBeRendered[ 0 ];
    await loadAssets(modelsToBeRendered, wrapper);
    await setEnvMap(builderPreviewSceneStats.scene);
    await generatePlane(trackerUrl, lookAtObjectCenter, mainObjectAtom ? mainObjectAtom.firstObject : undefined, customDistance);
    return generateImageData(done);
};

// eslint-disable-next-line arca/no-default-export
export default generateModelPreview;
