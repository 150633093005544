import styled from 'styled-components';

export const InputRow = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    background: ${ ({ theme: { colors } }) => colors.white };
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 0 16px;
    align-items: center;

    .one-ui-input {
        border: none !important;
        background: none !important;
    }
`;
