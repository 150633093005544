import { action, makeAutoObservable, observable, ObservableMap } from 'mobx';
// import { tourProgram }                                           from '@geenee/builder/src/components/Tour/TourData';
import { ProgramModel }                                          from '@geenee/builder/src/magellan/model/program.model';
import { MagellanState }                                         from '@geenee/builder/src/magellan/state/app.state';

class TourState {
    @observable isStarted = false;
    @observable step = 1;
    constructor() {
        makeAutoObservable(this);
    }

    @action startTour(AppState: MagellanState) {
        this.isStarted = true;
        // eslint-disable-next-line no-param-reassign
        AppState.reservedPrograms = new ObservableMap(AppState.programsRegistry as unknown as ObservableMap<string, ProgramModel>);
        AppState.programsRegistry.clear();
        // const program = new ProgramModel();
        // program.updateState({ ...tourProgram, type: 'program' });
        // program.$parent = AppState;
        const attachedElement = document.getElementById('root') as HTMLElement;
        attachedElement.style.pointerEvents = 'none';
        // AppState.programsRegistry.set(program.id, program);
    }

    @action closeTour(AppState: MagellanState) {
        this.isStarted = false;
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        AppState.programsRegistry = new ObservableMap(AppState.reservedPrograms);
        const attachedElement = document.getElementById('root') as HTMLElement;
        attachedElement.style.pointerEvents = 'auto';
        this.step = 1;
        AppState.reservedPrograms.clear();
    }
}

// eslint-disable-next-line arca/no-default-export
export default new TourState();
