import { MathUtils } from 'three';

export const ROUND_DELIMITER = 10000;

export const getSceneInputId = (type: string, axis: string) => `scene_input_${ type.toLocaleLowerCase() }_${ axis }`;

const round = (value: number) => Math.round(value * ROUND_DELIMITER) / ROUND_DELIMITER;
const { radToDeg } = MathUtils;

export const valueFormat = (
    type: string,
    value: string | number
) => {
    if (typeof value === 'string' && (value.endsWith('.') || value.startsWith('-'))) {
        return value;
    }
    if (type.toLocaleLowerCase() === 'rotation' && typeof value !== 'string') {
        return round(radToDeg(value));
    }
    const newValue = round(value as number || 0);
    if (newValue === 0) return value;
    return round(value as number || 0);
};
