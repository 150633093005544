import React from 'react';

// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '12',
    height = '12',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="6" cy="6" r="5.5" fill="white" stroke="#BBBBBB" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M9.11173 3.4378L6.44506 2.10447C6.16475 1.96518 5.83538 1.96518 5.55506 2.10447L2.88766 3.43817C2.54857 3.60667 2.3336 3.95333 2.3334 4.3328L2.3334 7.50631C2.33076 7.88415 2.5439 8.23293 2.88341 8.40399L5.55099 9.73779C5.83258 9.8787 6.16421 9.8787 6.4459 9.73774L9.11247 8.40446C9.45156 8.23596 9.66653 7.8893 9.66673 7.50982V4.33298C9.66653 3.95333 9.45156 3.60667 9.11173 3.4378ZM6.00008 5.21364L3.41377 3.92048L5.85249 2.70112C5.94509 2.65511 6.05474 2.65506 6.14811 2.70134L8.5864 3.92048L6.00008 5.21364ZM6.33341 5.79233L9.00008 4.45899L9.00008 7.50965C9.00001 7.63596 8.92836 7.75151 8.81508 7.8078L6.33341 9.04864V5.79233ZM5.66674 5.79232L3.00008 4.45899L3.00007 7.50863C2.99919 7.63535 3.07024 7.75161 3.18249 7.80817L5.66674 9.0503V5.79232Z"
                fill="black"
            />
        </svg>
    );
}
