import { Light }                        from "@babylonjs/core/Lights/light";
import { AbstractMesh }                 from "@babylonjs/core/Meshes/abstractMesh";
import { TransformNode }                from "@babylonjs/core/Meshes/transformNode";
import { BabylonRenderer }              from "@geenee/geespector/renderer/babylonjs.renderer";
import { bypassHistory }                from "@geenee/shared/src/commander/decorators";
import { AbstractCommand, CommandType } from '@geenee/shared/src/commander/types';

@bypassHistory
export class HideShowCommand extends AbstractCommand<CommandType> {
    receiver: BabylonRenderer;
    entity: AbstractMesh | TransformNode | Light;
    type: 'mesh' | 'light' | 'transformNode';

    constructor(receiver: BabylonRenderer, entity: AbstractMesh | TransformNode | Light, type: 'mesh' | 'light' | 'transformNode') {
        super();
        this.receiver = receiver;
        this.entity = entity;
        this.type = type;
    }

    execute = () => {
        this.receiver.showHideMesh(this.entity.uniqueId, this.type);
    };

    revert = () => {
        this.receiver.showHideMesh(this.entity.uniqueId, this.type);
    };
}
