import React, { FC, useEffect, useState } from 'react';
import { LoadingSpinnerCentered }         from "@geenee/shared/src/magellan/components/LoadingSpinner";
import { generateSquaredQRcode }          from '@geenee/shared/src/util/generateSquaredQRcode';
import {
    Button, Card, cn, Description, Divider, Icon, Input, MediaContent, Wrapper
} from "@geenee/ui";
import { SceneRenderer } from '../../renderer/r3f-renderer/r3f.renderer';
import './styles.scss';

type PropsType = {
    activeSceneModel: SceneRenderer,
    dQRBlockStyle?: Record<string, unknown>;
};
const className = cn('fallback');
const TRACKING_TYPES = [ 'body-tracking-overlay', 'head-tracking', 'body-tracking-twin' ];
export const FallbackPlaceholder: FC<PropsType> = (props) => {
    const [ isMobileDevice, setIsMobileDevice ] = useState(true);
    const [ copyText, setCopyText ] = useState('Copy');
    const [ isSupportedBrowser, setIsSupportedBrowser ] = useState(true);
    const [ isSafari, setIsSafari ] = useState(true);
    const [ isChromeIOS, setIsChromeIOS ] = useState(true);
    const [ isCopied, setIsCopied ] = useState(false);

    const showDesktop = !TRACKING_TYPES.includes(props.activeSceneModel.scene_experience_type)
    && props.activeSceneModel.fallbackPlaceholder?.ifDesktop;

    const [ qrImage ] = useState(generateSquaredQRcode(window.location.href));

    const onCopyBtnClick  = () => {
        navigator.clipboard.writeText(window.location.href).then(() => {
            setIsCopied(true);
        }, (err) => {
            console.error('Async: Could not copy text: ', err);
        });
    };

    useEffect(() => {
        // Check if is Mobile device
        const iOSiPadOS = /^iP/.test(navigator.platform)
          // eslint-disable-next-line no-mixed-operators
          || /^Mac/.test(navigator.platform) && navigator.maxTouchPoints > 4;
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || iOSiPadOS;
        setIsMobileDevice(isMobile);

        // Check if it's Safari
        const userAgent = window.navigator.userAgent.toLowerCase();
        const is_safari = /safari/.test(userAgent) || /iphone|ipod|ipad/.test(userAgent);
        setIsSafari(is_safari);

        // If WKWebView
        if (navigator.platform.substr(0, 2) === 'iP') {
            // iOS (iPhone, iPod or iPad)
            const lte9 = /constructor/i.test(window.HTMLElement);
            const nav = window.navigator; const ua = nav.userAgent; const
                idb = !!window.indexedDB;
            if (ua.indexOf('Safari') !== -1 && ua.indexOf('Version') !== -1 && !nav.standalone) {
                // Safari (WKWebView/Nitro since 6+)
            } else if ((!idb && lte9) || !window.statusbar.visible) {
                // UIWebView
            } else if ((window.webkit && window.webkit.messageHandlers) || !lte9 || idb) {
                // WKWebView
                setIsSupportedBrowser(false);
                // Remove preloader
                const elem = document.getElementById('preloader');
                elem?.parentNode.removeChild(elem);
            }
        }

        // If it's Chrome on iOS
        if (navigator.userAgent.match('CriOS')) {
            setIsChromeIOS(true);
        }
    }, [ ]);
    return (
        <Wrapper
            className={ className('root') }
            style={ { backgroundImage: `url('/asset/fallbackBackground.png')` } }
        >
            { showDesktop
            && (
                <Wrapper
                    className={ className({ hide: isMobileDevice }) }
                    valign="center"
                    id="geenee-ui-fallbackPlaceholder-isDesktop--wrapper"
                >
                    <Wrapper className={ className('content') }>
                        <Description margin="sm" weight="bold" color="gradient-warm-1" className={ className('header') }>
                            Pull out that mobile phone
                        </Description>

                        <Wrapper align="center" margin="md">
                            <Description size="lg" align="center">
                                The experience is best viewed on your mobile device using Safari / Chrome.
                            </Description>
                            <Description size="lg" align="center">Either scan the QR below or copy URL and send to your phone.</Description>
                        </Wrapper>
                        <Card
                            maxWidth="30%"
                            mediaBefore
                            media={ (
                                <Wrapper frame="solid-default" padding="md">
                                    <MediaContent
                                        fallback={ <LoadingSpinnerCentered /> }
                                        fit="contain"
                                        type="image"
                                        size="xxl"
                                        url={ qrImage }
                                        align="center"
                                    />
                                </Wrapper>
                            ) }
                        >
                            <Divider transparent margin="xs" />
                            <Wrapper row align="center" padding="sm" frame="solid-default">
                                <Input
                                    margin="xs"
                                    size="sm"
                                    fullWidth
                                    value={ window.location.href }
                                />
                                <Button
                                    viewType="secondary"
                                    radius="lg"
                                    size="sm"
                                    uppercase
                                    weight="bold"
                                    onClick={ () => {
                                        navigator.clipboard.writeText(window.location.href);
                                        setCopyText('Copied');
                                        setTimeout(() => {
                                            setCopyText('Copy');
                                        }, 3000);
                                    } }
                                >
                                    { copyText }
                                </Button>
                            </Wrapper>
                        </Card>
                    </Wrapper>
                    <Icon className={ className('geenee-icon') } color="white" name="poweredByGeeneeHorizontal" />
                </Wrapper>
            ) }

            { props.activeSceneModel.fallbackPlaceholder?.unsupportedBrowser
            && (
                <Wrapper
                    fullHeight
                    valign="center"
                    className={ className({ hide: !isMobileDevice || isSupportedBrowser || isChromeIOS }) }
                    id="geenee-ui-fallbackPlaceholder-unsupportedBrowser--wrapper"
                >
                    <Wrapper className={ className('content') }>
                        <Description size='xxxl' margin="sm" weight="bold" color="shade-3" className={ className('header') }>
                            Try this   on
                            { ' ' }
                            { isSafari ? 'Safari' : 'Chrome' }
                        </Description>
                        <div className={ className('text', { isMobile: true }) }>
                            This experience is best viewed on your mobile device using
                            { ' ' }
                            { isSafari ? 'Safari' : 'Chrome' }
                            . Press
                            <span className={ className('meatball-menu-span') }>
                                <div className={ className('menu__item--meatball') }>
                                    <div className={ className('circle') } />
                                    <div className={ className('circle') } />
                                    <div className={ className('circle') } />
                                </div>
                            </span>
                            icon or
                            { ' ' }
                            <br />
                            Copy and paste the URL.
                        </div>

                        <div className={ className('link-block') }>
                            <div className={ className('copied-popup', { active: isCopied }) }>
                                Copied!
                            </div>
                            <div className={ className(`link`) }>
                                { window.location.href }
                            </div>
                            <div className={ className('copy-btn') } onClick={ onCopyBtnClick }>
                                COPY
                            </div>
                        </div>
                    </Wrapper>
                </Wrapper>
            ) }
        </Wrapper>
    );
};
