export type ImageAtomOptType = {
  image_src?: string
  image_width?: string
  image_height?: string
  image_object_fit?: string
  image_border_radius?: string
}

export const initProps : ImageAtomOptType = {
    image_width:         '100%',
    image_height:        '100%',
    image_object_fit:    'cover',
    image_border_radius: '0'
};

export const getPropsFromOwner = (options: ImageAtomOptType, extendProps: Record<string, any> = {}) => ({
    image_src:           options.image_src,
    image_width:         options.image_width,
    image_height:        options.image_height,
    image_object_fit:    options.image_object_fit,
    image_border_radius: options.image_border_radius,
    ...extendProps
});
