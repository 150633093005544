import React                        from 'react';
import { DIContextType }            from '@geenee/geeclient-kit/src/lib/context/di.context';
import { withDIContext }            from '../hoc/withDIContext.hoc';
import { MoleculeWrapperUnionType } from '../type/molecule.type';

type PropsType = {
    magellanMolecule: MoleculeWrapperUnionType;
    diContext: DIContextType;
}

export const MoleculeWrapper = withDIContext<PropsType>(
    (props: PropsType) => {
        const Component = (props.diContext as any)[ props.magellanMolecule ];
        if (Component) {
            return (<Component { ...props } />);
        }

        return <>{ props.children }</>;
    }
);
