import React            from 'react';
import { Wrapper }      from '@geenee/ui/src';
import { SnapShotRoot } from './styles';

const className = 'snapshot-block';

type Props = {
    children: JSX.Element | JSX.Element[]
    snapShotTaken?: boolean | null
}
export function SnapShotWrapper(props: Props) {
    return (
        <Wrapper
            padding="off"
            className="snapshot-wrapper"
            style={ {
                height:        '100%',
                position:      'fixed',
                top:           0,
                right:         0,
                bottom:        0,
                left:          0,
                zIndex:        props.snapShotTaken ? 4 : 3,
                pointerEvents: 'none'
            } }
            align="center"
            valign="center"
            id="geenee-ui-snapshot--wrapper"
        >
            <SnapShotRoot id="record-root">
                <div className={ `${ className }` }>
                    { props.children }
                </div>
            </SnapShotRoot>
        </Wrapper>
    );
}
