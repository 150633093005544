import React, { memo }                                from "react";
import { CallbackFunctionVariadic }                   from "@geenee/geeclient-kit/src/lib/type/type";
import { FormError, FormItem, Input, InputTableItem } from '@geenee/ui';

type TemplateProps = {
  label: string
  errors?: any
  name?: string
  value?: string
  onChange?: CallbackFunctionVariadic
  disabled?: boolean
  placeholder?: string
}
export const InputTableItemInput = memo(React.forwardRef(({
    label, errors, name, onChange, value, disabled, placeholder, ...rest
}: TemplateProps, ref) => (
    <InputTableItem
        size="lg"
        ratio={ 0.65 }
        label={ label }
        viewType="secondary"
        valign="base-line"
        weight="medium"
    >
        <FormItem
            errorComponent={ FormError as React.FC }
            margin="off"
            autoFocusDetect
            hasTooltipMessage={ false }
            error={
                errors?.[ name || '' ]?.message || (
                    // TODO: remove this when MemberPage will be replaced to mobx form
                    (errors?.type === 'required' && (
                        'This field is required'
                    )) || (
                        errors?.type === 'minLength' && (field === 'email' ? (
                            'Please provide more than 6 symbols'
                        ) : ('Please provide more than 2 symbols')
                        )) || (
                        errors?.type === 'pattern' && (
                            'Please provide valid data'
                        )
                    ))
            }
        >
            <Input
                ref={ ref }
                size="md"
                fullWidth
                name={ name }
                placeholder={ placeholder }
                onChange={ onChange }
                value={ value || '' }
                error={ !!errors?.[ name || '' ]?.message }
                disabled={ disabled }
                { ...rest }
            />
        </FormItem>
    </InputTableItem>
)));
