import defaultTexture                            from '../../assets/textures/candy.png';
import { BevelOptionsType, MaterialOptionsType } from './types';

export const TEXT3D_TYPE = 'text3d';

export const DEFAULT_TEXT3D_BODY = 'Hello AR!';

export const TEXT3D_HEIGHT_MULTIPLIER = 0.1;
export const TEXT3D_BEVEL_SIZE_OPTIMIZER = 0.05;
export const MAX_TEXT3D_LENGTH = 36;
export const TEXT3D_Z_INDEX_GAP = 6;

export const DEFAULT_TEXT3D_CURVE_SEGMENTS = 16;
export const DEFAULT_TEXT3D_COLOR = '#bcbcbc';
export const DEFAULT_TEXT3D_OPACITY = '1.00';
export const DEFAULT_TEXT3D_ROUGHNESS = 0.6;
export const DEFAULT_TEXT3D_METALNESS = 0.8;
export const DEFAULT_TEXT3D_OFFSET = 0;

export const RAD_TO_DEG = 180 / Math.PI;
export const DEG_TO_RAD = Math.PI / 180;
export const DEFAULT_TEXT_SIZE = 0.2;

export const DEFAULT_BEVEL_OPTIONS: BevelOptionsType = {
    enabled:   false,
    thickness: DEFAULT_TEXT_SIZE * TEXT3D_BEVEL_SIZE_OPTIMIZER,
    size:      DEFAULT_TEXT_SIZE * TEXT3D_BEVEL_SIZE_OPTIMIZER,
    offset:    DEFAULT_TEXT3D_OFFSET,
    segments:  DEFAULT_TEXT3D_CURVE_SEGMENTS
};

export const DEFAULT_MATERIAL_OPTIONS: MaterialOptionsType = {
    color:     DEFAULT_TEXT3D_COLOR,
    // TODO: Change to default texture, comes with custom textures update
    map:       undefined,
    opacity:   DEFAULT_TEXT3D_OPACITY,
    roughness: DEFAULT_TEXT3D_ROUGHNESS,
    metalness: DEFAULT_TEXT3D_METALNESS
};
