import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '40',
    height = '29',
    fill = 'black'
}: SvgIconProps) {
    return (
        <svg width={ width } height={ height } viewBox="0 0 40 29" fill="none">
            <path
            // eslint-disable-next-line max-len
                d="M3.41116 15.9065L10.8897 8.95681C11.4415 8.44404 11.4415 7.61268 10.8897 7.09991C10.338 6.58714 9.44332 6.58714 8.89153 7.09991L0.413843 14.9781C-0.137948 15.4908 -0.137948 16.3222 0.413843 16.835L8.89153 24.7131C9.44332 25.2259 10.338 25.2259 10.8897 24.7131C11.4415 24.2003 11.4415 23.369 10.8897 22.8562L3.41116 15.9065Z"
                fill={ fill }
            />
            <line
                x1="0.75"
                y1="-0.75"
                x2="29.4393"
                y2="-0.75"
                // eslint-disable-next-line max-len
                transform="matrix(-0.302719 0.95308 -0.964381 -0.264517 23.4872 0)"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
            // eslint-disable-next-line max-len
                d="M36.5888 15.8577L29.1103 22.8074C28.5585 23.3201 28.5585 24.1515 29.1103 24.6643C29.662 25.177 30.5567 25.177 31.1085 24.6643L39.5862 16.7861C40.1379 16.2733 40.1379 15.442 39.5862 14.9292L31.1085 7.05105C30.5567 6.53828 29.662 6.53828 29.1103 7.05105C28.5585 7.56382 28.5585 8.39518 29.1103 8.90795L36.5888 15.8577Z"
                fill={ fill }
            />
        </svg>
    );
}
