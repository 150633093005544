import { useEffect }                  from "react";
import { useLocalObservable }         from "mobx-react";
import { sdkTokenState }              from "@geenee/builder/src/core/state/sdkToken.state";
import { SDKModalProps, useSDKModal } from "@geenee/builder/src/pages/SDKAccessPage/components/useSDKModal";

export const useInitState = () => {
    const modalState = useLocalObservable(() => ({
        item: {} as SDKModalProps['item'],
        mode: 'remove',
        v:    0,
        onChange(item, mode) {
            this.item = { ...item };
            this.mode = mode;
            this.v    += 1;
        }
    }));

    const [ showModal ] = useSDKModal(modalState);
    const { fetchTokenList } = sdkTokenState;

    useEffect(() => {
        fetchTokenList();
    }, []);

    useEffect(() => {
        if (modalState.v) {
            showModal();
        }
    }, [ modalState.v ]);

    const onRemove = (item: SDKModalProps['item']) => {
        modalState.onChange(item, 'remove');
    };
    const onUpdate = (item: SDKModalProps['item']) => {
        modalState.onChange(item, 'update');
    };
    return { onRemove, onUpdate };
};
