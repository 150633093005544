import React, { memo, useMemo, useState }    from 'react';
import Uploader                              from '@geenee/geeclient-kit/src/lib/component/scene/components/Uploader';
import { Button, cn, MediaContent, Wrapper } from '@geenee/ui';
import { v4 }                                from "uuid";

type Props = {
    acceptedFormats: string[]
    onFilesUpload?: (file: File) => void
    bind?: any
    url?: string
    mediaSize?: 'xl' | 'xxl';
}
const className = cn('drop-image-form');
export const DropContent = memo((props: Props) => {
    const [ id ] = useState(props.bind?.name || v4());
    const accept = useMemo(() => props.acceptedFormats.map((type) => `.${ type }`).join(', '), [ props.acceptedFormats ]);

    return (
        <Wrapper align="center" valign="center" fullHeight>
            { props.url && (
                <MediaContent
                    // TODO: investigate if need to replace it in media component style
                    style={ { maxWidth: 'fit-content', maxHeight: 'fit-content' } }
                    url={ props.url }
                    size={ props.mediaSize }
                    type="image"
                    fit='contain'
                    radius="xl"
                    ratio="ratio-2-1"
                />
            ) }
            <div className={ className('actions') }>
                { props.url ? (
                    <Button
                        viewType="action"
                        icon="edit2"
                        shadow="hover"
                        radius="sm"
                        size="xs"
                        iconSize={ 16 }
                        onClick={ () => document.getElementById(id)?.click() }
                    />
                ) : (
                    <Button
                        viewType="action"
                        shadow="hover"
                        icon="add"
                        radius="sm"
                        size="xs"
                        iconSize={ 12 }
                        onClick={ () => document.getElementById(id)?.click() }
                    />
                ) }
                <Uploader
                    hideUploadText
                    name={ id }
                    description=""
                    handleUpload={ (file) => props.onFilesUpload && props.onFilesUpload(file) }
                    accept={ accept }
                    skipTypeValidation
                    { ...props.bind }
                />
            </div>
        </Wrapper>

    );
});
