import { USER_SERVER_URL } from '@geenee/builder/src/api/AuthAPI';
import APIRequest          from '@geenee/builder/src/lib/APIRequest';
import { querySerialize }  from '@geenee/builder/src/lib/querySerialize';

// @ts-ignore
export const getCompany = () => APIRequest.get(
    `${ USER_SERVER_URL }/api/company`,
    { external: true },
    { external: true }
).then((data) => data);

// @ts-ignore
export const updateCompany = async (data: Record<string, any>) => APIRequest.put(
    `/api/v0/account?${ querySerialize(data) }`
);

// @ts-ignore
export const updateCompanyLogoToS3 = async (data: Record<string, any>) => APIRequest.post(
    `${ USER_SERVER_URL }/api/company/upload-s3-logo`,
    { ...data },
    { external: true }
).then((res) => res.data);

export const getCompanyLogoPreSignedUrl = (fileName = 'logo.png') => APIRequest.request(
    'get',
    `/api/v0/account/s3_path/${ fileName }`
);
