import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;

    padding: 32px 40px 40px;

    button {
        margin-top: 20px;
    }

    form {
        width: 100%;
    }
`;

export const Card = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    border-bottom: 1px solid #ccc;
`;

export const Trash = styled.div`
    :hover {
        opacity: 0.5;
    }

    :active {
        position: relative;
        top: 1px;
    }
`;

export const CardsWrapper = styled.div`
    padding: 4px;
    width: 100%;
    font-size: 12px;

    > ${ Card } {
        padding: 13px 1px;
        cursor: pointer;

        p {
            margin: 0;
            margin-top: 13px;
        }

        img {
            margin-top: 4px;
            margin-right: 13px;
        }
    }
`;

export const ManageCardsWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-top: 17px;
    margin-bottom: 36px;
    font-size: 12px;
`;

export const CardInformation = styled.div`
    border: 1px solid
        ${ ({ theme: { secondaryColors } }) => secondaryColors.grey20 };
    border-radius: 8px;
    padding: 12px;
    width: 100%;
`;

export const InfoText = styled.p`
    margin: 28px 0 0;
    text-align: center;
    font-size: 11px;
    line-height: 15px;
    width: 92%;
`;
