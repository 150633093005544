import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { Color3, Color4 }                                    from "@babylonjs/core/Maths/math.color";
import type { Observable }                                   from "@babylonjs/core/Misc/observable";
import { FormLineWrapperComponent }                          from "@geenee/geespector/src/components/formLineWrapper/formLineWrapperComponent";
import GlobalState                                           from "@geenee/geespector/src/components/globalState";
import {
    Button, cn, Description, Popover, RGBColorPicker, Tooltip, Wrapper
} from "@geenee/ui";
import type { PropertyChangedEvent }    from "../propertyChangedEvent";
import type { LockObject }              from "../tabs/propertyGrids/lockObject";
import { conflictingValuesPlaceholder } from "./targetsProxy";
import './colorLineComponent.style.scss';

const emptyColor = new Color4(0, 0, 0, 0);
const COLOR_PICKER_CONST = {
    width:            230,
    marginTop:        12,
    heightWithMargin: 296
};

const className = cn('color-line');

export interface ColorLineComponentProps {
  label: string;
  target?: any;
  propertyName: string;
  onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
  onChange?: () => void;
  isLinear?: boolean;
  icon?: string;
  iconLabel?: string;
  lockObject?: LockObject;
  disableAlpha?: boolean;
  border?: boolean
}

export const ColorLineComponent = memo((props: ColorLineComponentProps) => {
    const [ color, setColor ] = useState<Color4>();
    const [ copyText, setCopyText ] = useState('Copy');
    const [ popoverCoordinates, setPopoverCoordinates ] = useState<{top?: number, bottom?: number, left: number}>();
    const internalChanges = useRef(false);

    useEffect(() => {
        const _value = getValue();
        setColor(_value);
        const { target } = props;
        // so that replayRecorder can append toLinearSpace() as appropriate
        target._isLinearColor = props.isLinear;
        GlobalState.onPropertyChangedObservable.add((event) => {
            if (internalChanges.current) {
                internalChanges.current = false;
                return;
            }
            if (event.property === props.propertyName && !internalChanges.current) {
                setColor(event.value);
            }
        });
    }, []);

    useEffect(() => {
        if (copyText === 'Copied') {
            setTimeout(() => setCopyText('Copy'), 2000);
        }
    }, [ copyText ]);
    const _convertToColor = (_color: string): Color4 => {
        if (_color === "" || _color === "transparent") {
            return emptyColor;
        }

        if (_color.substring(0, 1) !== "#" || (_color.length !== 7 && _color.length !== 9)) {
            const d = document.createElement("div");
            d.style.color = _color;
            document.body.append(d);
            const rgb = window.getComputedStyle(d).color;
            document.body.removeChild(d);

            const rgbArray = rgb
                .substring(4, rgb.length - 1)
                .replace(/ /g, "")
                .split(",");

            const alpha = rgbArray.length > 3 ? parseInt(rgbArray[ 3 ]) / 255 : 1.0;

            return new Color4(parseInt(rgbArray[ 0 ]) / 255, parseInt(rgbArray[ 1 ]) / 255, parseInt(rgbArray[ 2 ]) / 255, alpha);
        }

        if (props.disableAlpha) {
            const color3 = Color3.FromHexString(_color);
            return new Color4(color3.r, color3.g, color3.b, 1.0);
        }

        return Color4.FromHexString(_color);
    };

    function getValue(): Color4 {
        const { target } = props;
        const property = target[ props.propertyName ];
        if (!property) return emptyColor;
        if (typeof property === "string") {
            if (property === conflictingValuesPlaceholder) {
                return emptyColor;
            }
            return _convertToColor(property);
        }
        if (props.isLinear) {
            return property.toGammaSpace();
        }
        return property.clone();
    }

    const _toColor3 = (_color: Color4) => new Color3(_color.r, _color.g, _color.b);

    const updateColor = (newColor: Color4) => {
        setColor(newColor.clone());
        if (props.isLinear) {
            newColor.toLinearSpaceToRef(newColor);
        }
        // whether to set properties to color3 or color4
        const _color = props.disableAlpha ? _toColor3(newColor) : newColor;
        onColorChange(_color);
    };

    const onColorChange = (newColor: Color4 | Color3) => {
        const { target } = props;
        const initialValue = target[ props.propertyName ];
        const value = typeof target[ props.propertyName ] === "string" ? newColor.toHexString() : newColor;
        // make the change
        target[ props.propertyName ] = value;
        // notify observers
        if (props.onPropertyChangedObservable) {
            props.onPropertyChangedObservable.notifyObservers({
                object:   target,
                property: props.propertyName,
                value,
                initialValue
            });
        }

        if (props.onChange) {
            props.onChange();
        }
    };

    const copyToClipboard = () => {
        const element = document.createElement("div");
        element.textContent = color?.toHexString() || '';
        document.body.appendChild(element);

        if (window.getSelection) {
            const range = document.createRange();
            range.selectNode(element);
            window.getSelection()!.removeAllRanges();
            window.getSelection()!.addRange(range);
        }

        document.execCommand("copy");
        element.remove();
        setCopyText('Copied');
    };

    const switchExpandState = (e: React.MouseEvent) => {
        const boundingClientRect = e.currentTarget.getBoundingClientRect();
        if ((window.innerHeight - boundingClientRect.bottom) > COLOR_PICKER_CONST.heightWithMargin) {
            setPopoverCoordinates({
                top:  boundingClientRect.bottom + COLOR_PICKER_CONST.marginTop,
                left: boundingClientRect.right - COLOR_PICKER_CONST.width
            });
        } else {
            setPopoverCoordinates({
                top:  boundingClientRect.top - COLOR_PICKER_CONST.heightWithMargin,
                left: boundingClientRect.right - COLOR_PICKER_CONST.width
            });
        }
    };

    const rgbColor =  useMemo(() => {
        if (!color) {
            return { r: 0, g: 0, b: 0 };
        }
        return (
            {
                r: Math.floor(color.r * 255),
                g: Math.floor(color.g * 255),
                b: Math.floor(color.b * 255)
            }
        );
    }, [ color ]);

    if (!color) {
        return null;
    }
    return (
        <FormLineWrapperComponent valign="start" title={ props.label } className="color3Line" border={ props.border }>
            <Wrapper
                className={ className('color-preview') }
                margin="xs"
                border="shade-5"
                style={ {
                    background: props.disableAlpha
                        ? _toColor3(color).toHexString() : color.toHexString()
                } }
                radius="lg"
                onClick={ (e) => switchExpandState(e) }
            >
                <Button
                    radius="lg"
                    size="xs"
                    viewType="white"
                    className={ className('picker-icon') }
                    icon="colorPicker"
                />
            </Wrapper>
            <Tooltip
                position="top"
                menuWidth="80px"
                minWidth="80px"
                shadow
                message={ copyText }
            >
                <Wrapper frame='solid-white' radius='lg' maxWidth="fit-content" margin="xs">
                    <Button
                        onClick={ () => copyToClipboard() }
                        size="sm"
                        icon="copy"
                        viewType="secondary"
                        radius="lg"
                    />
                </Wrapper>
            </Tooltip>

            { popoverCoordinates && (
                <Popover
                    closePopover={ () => {
                        setPopoverCoordinates(undefined);
                    } }
                    coordinates={ popoverCoordinates }
                >
                    <Wrapper frame="solid-white" padding="xs" radius="xxxl">
                        <RGBColorPicker
                            alpha
                            rgbColor={ props.disableAlpha ? rgbColor : color }
                            onChange={ (v) => updateColor(new Color4(v.r / 255, v.g / 255, v.b / 255, 1)) }
                        />
                    </Wrapper>
                </Popover>
            ) }
        </FormLineWrapperComponent>
    );
});
