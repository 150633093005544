import React, { ChangeEvent, useRef, useState }             from 'react';
import { FormItem, Input, Wrapper }                         from '@geenee/ui';
import StyledButton                                         from '@geenee/builder/src/components/common/Button';
import { DELETE, NODE_TYPE_PROGRAM, PROGRAM_ALIAS_IN_TEXT } from '@geenee/builder/src/lib/constants';
import useOnClickOutside                                    from '@geenee/builder/src/lib/useOnClickOutside';
import {
    DefaultWrapper,
    DeleteDescription,
    DeleteTitle,
    DescriptionContainer,
    Underline
} from './styles';

export type DeleteAlertProps = {
  location?: string;
  nodeName?: string;
  confirmCallback?: () => void;
  warningText?: JSX.Element
  onClose?: () => void
}

export function DeleteAlert({
    location, nodeName, confirmCallback, warningText, onClose
}: DeleteAlertProps) {
    const [ text, setText ] = useState('');
    const ref = useRef<HTMLDivElement>(null);

    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    const handleClick = () => {
        if (text === DELETE) {
      confirmCallback!();
        }
    };

    useOnClickOutside(ref, () => {
        onClose && onClose();
    });

    return (
        <DefaultWrapper ref={ ref }>
            <Wrapper align="center">
                <DeleteTitle>DELETION CONFIRMATION</DeleteTitle>
                <DescriptionContainer>
                    <DeleteDescription>
                        Are you sure you want to delete the
                        { ' ' }
                        <Underline>{ nodeName }</Underline>
                        { ' ' }
                        { location === NODE_TYPE_PROGRAM ? PROGRAM_ALIAS_IN_TEXT : location }
                        ?
                    </DeleteDescription>
                    <br />
                    <DeleteDescription>
                        { warningText || (
                            <>
                                If so, it will be
                                { ' ' }
                                <strong>permanently</strong>
                                { ' ' }
                                deleted.
                            </>
                        ) }
                    </DeleteDescription>
                    <br />
                    <DeleteDescription>Confirm by typing “DELETE” below.</DeleteDescription>
                </DescriptionContainer>
                <Wrapper maxWidth='308px'>
                    <FormItem
                        autoFocusDetect
                    >
                        <Input
                            className='ta-center'
                            size="md"
                            fullWidth
                            onChange={ handleTextChange }
                            value={ text }
                            placeholder={ DELETE }
                        />
                    </FormItem>
                </Wrapper>
                <StyledButton
                    disabled={ text !== DELETE }
                    label="DELETE FOREVER"
                    $width="310px"
                    $fontSize="12px"
                    onClick={ handleClick }
                />
            </Wrapper>
        </DefaultWrapper>
    );
}
