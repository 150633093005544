import React from 'react';

// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '20',
    height = '20',
    onClick,
    id,
    fill = 'black'
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            onClick={ onClick }
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M3.33325 11.6673H4.16659C4.62682 11.6673 4.99992 12.0404 4.99992 12.5007C4.99992 12.9609 4.62682 13.334 4.16659 13.334H3.33325C1.95254 13.334 0.833252 12.2147 0.833252 10.834V3.33398C0.833252 1.95327 1.95254 0.833984 3.33325 0.833984H10.8333C12.214 0.833984 13.3333 1.95327 13.3333 3.33398V4.16732C13.3333 4.62756 12.9602 5.00065 12.4999 5.00065C12.0397 5.00065 11.6666 4.62756 11.6666 4.16732V3.33398C11.6666 2.87375 11.2935 2.50065 10.8333 2.50065H3.33325C2.87301 2.50065 2.49992 2.87375 2.49992 3.33398V10.834C2.49992 11.2942 2.87301 11.6673 3.33325 11.6673ZM9.16658 6.66732H16.6666C18.0473 6.66732 19.1666 7.78661 19.1666 9.16732V16.6673C19.1666 18.048 18.0473 19.1673 16.6666 19.1673H9.16658C7.78587 19.1673 6.66659 18.048 6.66659 16.6673V9.16732C6.66659 7.78661 7.78587 6.66732 9.16658 6.66732ZM9.16658 8.33398C8.70635 8.33398 8.33325 8.70708 8.33325 9.16732V16.6673C8.33325 17.1276 8.70635 17.5007 9.16658 17.5007H16.6666C17.1268 17.5007 17.4999 17.1276 17.4999 16.6673V9.16732C17.4999 8.70708 17.1268 8.33398 16.6666 8.33398H9.16658Z"
                fill={ fill }
            />
        </svg>
    );
}
