import { Buffer } from 'buffer';
import AdmZip     from 'adm-zip';

export const extractFilesFromZip = (data: ArrayBuffer) => {
    const zip = new AdmZip(Buffer.from(data));
    const zipEntries = zip.getEntries();
    const files: {
    file: File | Buffer | null;
    ext: string;
    name: string;
  }[] = [];
    zipEntries.forEach((entry) => {
        if (entry.name) {
            const ext = entry.name.substr(entry.name.lastIndexOf('.') + 1);
            if (ext === 'gltf') {
                files.push({
                    file: JSON.parse(zip.readAsText(entry)),
                    ext,
                    name: entry.name
                });
            } else {
                files.push({
                    file: zip.readFile(entry),
                    ext,
                    name: entry.name
                });
            }
        }
    });
    return files;
};
