import React                     from "react";
import { Icon }                  from "@geenee/ui";
import { useModalDialogWrapper } from "~/components/ModalDialogWrapper";
import { DeleteAccount }         from "./DeleteAccount";

export const useDeleteAccount = () => {
    const [ showModal, hideModal ] = useModalDialogWrapper(
        <DeleteAccount onClose={ () => hideModal() } />,
        { closeTriggerElement: <Icon color="shade-4" name="closeRound" size={ 24 } /> }
    );

    return { showModal, hideModal };
};
