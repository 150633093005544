import React                       from 'react';
import { ExperienceViewComponent } from "@geenee/geeclient-kit/src/lib/component/experience/experience-view.component";
import { DIContextType }           from "@geenee/geeclient-kit/src/lib/context/di.context";
import { withDIContext }           from "@geenee/geeclient-kit/src/lib/hoc/with-di-context";

export const ExperienceComponent = withDIContext((props: {diContext: DIContextType}) => {
    const { ExperienceWrapperComponent } = props.diContext;
    return (
        <ExperienceWrapperComponent>
            <ExperienceViewComponent />
        </ExperienceWrapperComponent>
    );
});
