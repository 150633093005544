import React from 'react';
import {
    AbstractMesh, BoundingBoxGizmo,
    Light, Mesh, PositionGizmo,
    RotationGizmo, ScaleGizmo,
    TransformNode
} from "@babylonjs/core";
import { PointerEventTypes, PointerInfo } from "@babylonjs/core/Events/pointerEvents";
import { LightGizmo }                     from "@babylonjs/core/Gizmos/lightGizmo";
import { TmpVectors, Vector3 }            from "@babylonjs/core/Maths/math";
import { Color3 }                         from '@babylonjs/core/Maths/math.color';
import { Quaternion }                     from "@babylonjs/core/Maths/math.vector.js";
import { Observer }                       from "@babylonjs/core/Misc/observable";
import { UtilityLayerRenderer }           from "@babylonjs/core/Rendering/utilityLayerRenderer";
import { Scene }                          from "@babylonjs/core/scene";
import { Nullable }                       from "@babylonjs/core/types";
import { TransformationModesSwitcher }    from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/transformation-modes-switcher';
import { TransformModes }                 from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/type';
import GlobalState                        from '@geenee/geespector/src/components/globalState';
import { PropertyChangedEvent }           from '@geenee/geespector/src/components/propertyChangedEvent';

interface Props {
  scene: Scene;
}
// eslint-disable-next-line no-shadow
enum KeyCodes {
  KeyR = 82,
  KeyT = 84,
  KeyE = 69,
  KeyW = 87,
  KeyY = 89,
}

enum GizmoMode {
  None = 0,
  Position = 1,
  Rotation = 2,
  Scale = 3,
  BoundingBox = 4
}

export class BabylonGizmosComponent extends React.Component<Props, { gizmoMode: number, transformMode: TransformModes | null }> {
    private _gizmoLayerOnPointerObserver: Nullable<Observer<PointerInfo>> = null;
    private _onPointerObserver: Nullable<Observer<PointerInfo>> = null;
    private _onSelectionChangeObserver: Nullable<Observer<any>> = null;
    private _selectedEntity: any;
    private _activeGizmo: Nullable<PositionGizmo | RotationGizmo | ScaleGizmo | BoundingBoxGizmo> = null;

    constructor(props: Props) {
        super(props);

        let gizmoMode = GizmoMode.None;

        if (this._activeGizmo) {
            if (this._activeGizmo instanceof PositionGizmo) {
                gizmoMode = GizmoMode.Position;
            } else if (this._activeGizmo instanceof RotationGizmo) {
                gizmoMode = GizmoMode.Rotation;
            } else if (this._activeGizmo instanceof ScaleGizmo) {
                gizmoMode = GizmoMode.Scale;
            } else if (this._activeGizmo instanceof BoundingBoxGizmo) {
                gizmoMode = GizmoMode.BoundingBox;
            }
        }
        this.onPickingMode();
        this.state = { gizmoMode, transformMode: null };
    }

    attachEntity(entity: TransformNode & Mesh & TransformNode[] | null) {
        // !entity - pick to empty place, entity.length - scene level. We should block gizmo for both of cases
        if (!entity || entity.length) {
            this.setState({ transformMode: null });
            this.setGizmoMode(0);
            return;
        }

        if (entity && this._activeGizmo) {
            const className = entity.getClassName();

            if (className === "TransformNode" || className.indexOf("Mesh") !== -1 || entity instanceof Light) {
                // If entity has skeleton - it is a skinned mesh. They are dont attached to gizmo
                if (!entity.skeleton) {
                    this._activeGizmo.attachedMesh = entity;
                } else {
                    this._activeGizmo.attachedMesh = null;
                }
            } else if (className.indexOf("Light") !== -1) {
                if (!entity.reservedDataStore || !entity.reservedDataStore.lightGizmo) {
                    GlobalState.enableLightGizmo(entity, true);
                    this.forceUpdate();
                }
                // Avoid changing the direction of the light, better for glb to change transformations on the parent node
                if (className === 'DirectionalLight' && entity.parent?.id === "Directional light") {
                    this._activeGizmo.attachedNode = entity.parent;
                } else {
                    this._activeGizmo.attachedNode = entity.reservedDataStore.lightGizmo.attachedNode;
                }
            } else if (className.indexOf("Bone") !== -1) {
                // For bones we use transform node, attached to bone
                this._activeGizmo.attachedNode = entity._linkedTransformNode
                    ? entity._linkedTransformNode
                    : entity;
            } else {
                this._activeGizmo.attachedMesh = null;
            }
        }
    }

    componentDidMount() {
        if (!GlobalState.onSelectionChangedObservable) {
            return;
        }
        window.addEventListener('keydown', this.handleKeyDown);

        this._onSelectionChangeObserver = GlobalState.onSelectionChangedObservable.add((entity) => {
            this._selectedEntity = entity;
            this.attachEntity(entity);
        });
    }

    componentWillUnmount() {
        const { scene } = this.props;

        if (this._onPointerObserver) {
            scene.onPointerObservable.remove(this._onPointerObserver);
            this._onPointerObserver = null;
        }

        if (this._gizmoLayerOnPointerObserver) {
            scene.onPointerObservable.remove(this._gizmoLayerOnPointerObserver);
            this._gizmoLayerOnPointerObserver = null;
        }

        if (this._onSelectionChangeObserver && GlobalState.onSelectionChangedObservable) {
            GlobalState.onSelectionChangedObservable.remove(this._onSelectionChangeObserver);
        }
        this._activeGizmo?.dispose();
        window.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (event: KeyboardEvent) => {
        // @ts-ignore
        if (event.target.className.includes("one-ui-input")) {
            return;
        }
        // if (disabled) return;
        switch (event.keyCode) {
            case KeyCodes.KeyR: {
                this.setTransformMode(TransformModes.scale);
                break;
            }
            case KeyCodes.KeyE: {
                this.setTransformMode(TransformModes.rotate);
                break;
            }
            case KeyCodes.KeyW: {
                this.setTransformMode(TransformModes.translate);
                break;
            }
            case KeyCodes.KeyT: {
                this.setTransformMode(TransformModes.boundingBox);
                break;
            }
            default: {
                break;
            }
        }
    };

    getGizmoItem() {
        const lightGizmo: Nullable<LightGizmo> = this._activeGizmo?.attachedNode?.reservedDataStore
            ? this._activeGizmo?.attachedNode?.reservedDataStore.lightGizmo
            : null;
        const objLight: any = lightGizmo?.light || this._activeGizmo?.attachedNode;
        return objLight;
    }
    setGizmoMode(mode: number) {
        const { scene } = this.props;
        const initialValues: { [key: string]: Vector3 | Quaternion } = {
            position: new Vector3(),
            rotation: new Vector3(),
            scale:    new Vector3()
        };

        if (this._gizmoLayerOnPointerObserver) {
            scene.onPointerObservable.remove(this._gizmoLayerOnPointerObserver);
            this._gizmoLayerOnPointerObserver = null;
        }

        // Allow picking of light gizmo when a gizmo mode is selected
        this._gizmoLayerOnPointerObserver = scene.onPointerObservable.add((pointerInfo) => {
            if (pointerInfo.type == PointerEventTypes.POINTERDOWN) {
                if (pointerInfo.pickInfo && pointerInfo.pickInfo.pickedMesh) {
                    let node: Nullable<any> = pointerInfo.pickInfo.pickedMesh;
                    // Attach to the most parent node
                    while (node && node.parent != null) {
                        node = node.parent;
                    }
                    for (const gizmo of GlobalState.lightGizmos) {
                        if (gizmo._rootMesh == node) {
                            manager.attachToNode(gizmo.attachedNode);
                        }
                    }
                }
            }
        });

        const updateRotation = (modifiedNode: any) => {
            if (modifiedNode.rotationQuaternion) {
                const e = new PropertyChangedEvent();
                e.object = modifiedNode;
                e.property = "rotationQuaternion";
                e.value = modifiedNode.rotationQuaternion?.clone();
                e.initialValue = initialValues.rotation?.clone();
                GlobalState.onPropertyChangedObservable.notifyObservers(e);
            } else if (modifiedNode.rotation) {
                const e = new PropertyChangedEvent();
                e.object = modifiedNode;
                e.property = "rotation";
                e.value = modifiedNode.rotation?.clone();
                e.initialValue = initialValues.rotation?.clone();
                GlobalState.onPropertyChangedObservable.notifyObservers(e);
            } else if (modifiedNode.direction) {
                const e = new PropertyChangedEvent();
                e.object = modifiedNode;
                e.property = "direction";
                e.value = modifiedNode.direction?.clone();
                e.initialValue = initialValues.rotation?.clone();
                GlobalState.onPropertyChangedObservable.notifyObservers(e);
            }
        };

        // The user pressed the same button or the gizmo mode was not selected
        if (this.state.gizmoMode === mode || mode === GizmoMode.None) {
            mode = GizmoMode.None;
            this._activeGizmo?.dispose();
            this._activeGizmo = null;
        } else {
            const utilLayer = new UtilityLayerRenderer(scene);
            this._activeGizmo?.dispose();
            switch (mode) {
                case GizmoMode.Position:
                    this._activeGizmo = new PositionGizmo(utilLayer);
                    this._activeGizmo.yPlaneGizmo.isEnabled = true;
                    this._activeGizmo.xPlaneGizmo.isEnabled = true;
                    this._activeGizmo.zPlaneGizmo.isEnabled = true;

                    this._activeGizmo.onDragStartObservable.add(() => {
                        const objectBeforeChange = this.getGizmoItem();
                        if (objectBeforeChange?.position) {
                            initialValues.position = objectBeforeChange.position.clone();
                        } else {
                            initialValues.position = new Vector3(0, 0, 0);
                        }
                    });
                    this._activeGizmo.onDragEndObservable.add(() => {
                        const changedObj = this.getGizmoItem();
                        if (changedObj?.position) {
                            const e = new PropertyChangedEvent();
                            e.object = changedObj;
                            e.property = "position";
                            e.value = changedObj.position?.clone();
                            e.initialValue = initialValues.position?.clone();
                            GlobalState.onPropertyChangedObservable.notifyObservers(e);
                        }
                    });
                    break;
                case GizmoMode.Rotation:
                    this._activeGizmo = new RotationGizmo(utilLayer);
                    this._activeGizmo.onDragStartObservable.add(() => {
                        const objectBeforeChange = this.getGizmoItem();
                        const initVal = objectBeforeChange?.rotationQuaternion || objectBeforeChange?.rotation || objectBeforeChange?.direction;
                        initialValues.rotation = initVal ? initVal.clone() : new Vector3(0, 0, 0);
                    });
                    this._activeGizmo.onDragEndObservable.add(() => {
                        if (this._activeGizmo?.attachedNode) {
                            updateRotation(this.getGizmoItem());
                        }
                    });
                    break;
                case GizmoMode.Scale:
                    this._activeGizmo = new ScaleGizmo(utilLayer);
                    this._activeGizmo.onDragStartObservable.add(() => {
                        const { scaling = null } = this.getGizmoItem();
                        initialValues.scale = scaling ? scaling.clone() : new Vector3(0, 0, 0);
                    });
                    // Record movement for generating replay code
                    this._activeGizmo.onDragEndObservable.add(() => {
                        if (this._activeGizmo?.attachedMesh) {
                            const changedObj = this.getGizmoItem();

                            if (changedObj?.scaling) {
                                const e = new PropertyChangedEvent();
                                e.object = changedObj;
                                e.property = "scaling";
                                e.value = changedObj.scaling?.clone();
                                e.initialValue = initialValues.scale?.clone();
                                GlobalState.onPropertyChangedObservable.notifyObservers(e);
                            }
                        }
                    });

                    break;
                case GizmoMode.BoundingBox:
                    if (this._selectedEntity && (this._selectedEntity instanceof AbstractMesh || this._selectedEntity instanceof TransformNode)) {
                        this._activeGizmo = new BoundingBoxGizmo(Color3.FromHexString("#0984e3"), utilLayer);
                        this._activeGizmo.setEnabledScaling(false);
                        this._activeGizmo.setEnabledRotationAxis("none");
                    }
                    break;
                default:
                    break;
            }

            this.attachEntity(this._selectedEntity);
        }

        this.setState({ gizmoMode: mode });
    }

    onPickingMode() {
        const { scene } = this.props;

        if (this._onPointerObserver) {
            scene.onPointerObservable.remove(this._onPointerObserver);
            this._onPointerObserver = null;
        }

        this._onPointerObserver = scene.onPointerObservable.add(() => {
            const pickPosition = scene.unTranslatedPointer;
            const pickInfo = scene.pick(
                pickPosition.x,
                pickPosition.y,
                (mesh) => mesh.isEnabled() && mesh.isVisible && mesh.getTotalVertices() > 0 && mesh.id !== 'BackgroundPlane' && mesh.id !== 'grid',
                false,
                undefined,
                (p0, p1, p2, ray) => {
                    if (!GlobalState.ignoreBackfacesForPicking) {
                        return true;
                    }

                    const p0p1 = TmpVectors.Vector3[ 0 ];
                    const p1p2 = TmpVectors.Vector3[ 1 ];
                    let normal = TmpVectors.Vector3[ 2 ];

                    p1.subtractToRef(p0, p0p1);
                    p2.subtractToRef(p1, p1p2);

                    normal = Vector3.Cross(p0p1, p1p2);

                    return Vector3.Dot(normal, ray.direction) < 0;
                }
            );
            // Pick light gizmos first
            if (GlobalState.lightGizmos.length > 0) {
                const gizmoScene = GlobalState.lightGizmos[ 0 ].gizmoLayer.utilityLayerScene;
                const pickInfo = gizmoScene.pick(pickPosition.x, pickPosition.y, (m: any) => {
                    for (const g of GlobalState.lightGizmos as any) {
                        if (g.attachedNode == m) {
                            return true;
                        }
                    }
                    return false;
                });
                if (pickInfo && pickInfo.hit && GlobalState.onSelectionChangedObservable) {
                    GlobalState.onSelectionChangedObservable.notifyObservers(pickInfo.pickedMesh);
                    return;
                }
            }
            // Pick camera gizmos
            if (GlobalState.cameraGizmos.length > 0) {
                const gizmoScene = GlobalState.cameraGizmos[ 0 ].gizmoLayer.utilityLayerScene;
                const pickInfo = gizmoScene.pick(pickPosition.x, pickPosition.y, (m: any) => {
                    for (const g of GlobalState.cameraGizmos as any) {
                        if (g.attachedNode == m) {
                            return true;
                        }
                    }
                    return false;
                });
                if (pickInfo && pickInfo.hit && GlobalState.onSelectionChangedObservable) {
                    GlobalState.onSelectionChangedObservable.notifyObservers(pickInfo.pickedMesh);
                    return;
                }
            }
            if (GlobalState.onSelectionChangedObservable) {
                GlobalState.onSelectionChangedObservable.notifyObservers(pickInfo?.pickedMesh);
            }
        }, PointerEventTypes.POINTERTAP);
    }

    setTransformMode = (transformMode: TransformModes) => {
        const value = this.state.transformMode === transformMode ? null : transformMode;
        switch (transformMode) {
            case TransformModes.translate:
                // @ts-ignore
                this.setGizmoMode(1);
                this.setState({ transformMode: value });
                break;
            case TransformModes.rotate:
                // @ts-ignore
                this.setGizmoMode(2);
                this.setState({ transformMode: value });
                break;
            case TransformModes.scale:
                // @ts-ignore
                this.setGizmoMode(3);
                this.setState({ transformMode: value });
                break;
            case TransformModes.boundingBox:
                // @ts-ignore
                this.setGizmoMode(4);
                this.setState({ transformMode: value });
                break;
            default:
                break;
        }
    };

    render() {
        return (
            <TransformationModesSwitcher
                rendererMode="babylon"
                transformMode={ this.state.transformMode }
                styles={ { position: 'absolute', left: '50%', transform: 'translateX(-50%)' } }
                setTransformMode={ this.setTransformMode }
            />
        );
    }
}
