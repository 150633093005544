import React                   from 'react';
import { useInject }           from '@geenee/shared';
import { InputTable, Wrapper } from '@geenee/ui';
import { observer }            from 'mobx-react';
// import { ButtonAlign }         from '@geenee/builder/src/components/WizardForm/FormType/ContentBasicButton/components/ButtonAlign';
import { ButtonBorderType }    from '@geenee/builder/src/components/WizardForm/FormType/ContentBasicButton/components/ButtonBorderType';
import { ButtonColors }        from '@geenee/builder/src/components/WizardForm/FormType/ContentBasicButton/components/ButtonColors';
import { ButtonTypeSelector }  from '@geenee/builder/src/components/WizardForm/FormType/ContentBasicButton/components/ButtonTypeSelector';
import { UrlField }            from '@geenee/builder/src/components/WizardForm/FormType/ContentBasicButton/components/UrlField';
import { useHandlers }         from '@geenee/builder/src/components/WizardForm/FormType/ContentBasicButton/hooks/useHandlers';
import { AtomModel }           from '@geenee/builder/src/magellan/model/atom.model';
import { SectionModel }        from '@geenee/builder/src/magellan/model/section.model';

interface Props {
    disabled: boolean
}

export const ContentBasicButton = observer(({ disabled }:Props) => {
    const { AppState } = useInject();
    const { activeSection } = (AppState as unknown) as {
        activeSection?: SectionModel;
    };
    if (!activeSection) return <></>;
    const { activeMolecule } = activeSection;
    const buttonAtom = activeMolecule.getAtomByType('button') as AtomModel | undefined;
    const { atom_button_link_type, atom_button_url } = buttonAtom?.options || {};
    const { handleSetLinkPathInput, handleSetLinkType, handleSetLinkPath } = useHandlers(buttonAtom);

    if (!buttonAtom) {
        return <></>;
    }
    return (
        <Wrapper>
            <InputTable>
                { /* <ButtonAlign disabled={ disabled } buttonAtom={ buttonAtom } /> */ }
                <ButtonBorderType disabled={ disabled } buttonAtom={ buttonAtom } />
                <ButtonColors disabled={ disabled } buttonAtom={ buttonAtom } />
                <ButtonTypeSelector
                    button_link_type={ atom_button_link_type }
                    handleSetLinkType={ handleSetLinkType }
                />
                <UrlField
                    handleSetLinkPathInput={ handleSetLinkPathInput }
                    handleSetLinkPath={ handleSetLinkPath }
                    button_url={ atom_button_url }
                    button_link_type={ atom_button_link_type }
                />
            </InputTable>
        </Wrapper>
    );
});
