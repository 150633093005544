import { useCallback, useMemo, useState } from 'react';
import { Analytics }                      from '@geenee/analytics';
import { useBuilderInject }               from '@geenee/builder/src/core/hook/use-builder-inject';
import { useShowErrorMessage }            from '@geenee/builder/src/hooks/useShowErrorMessage';
import {
    EXPERIENCE_MAX_SIZE_MB,
    INVALID_FILE_FORMAT,
    TOAST_ERROR,
    TYPE_BODY_TRACKING_OVERLAY,
    TYPE_BODY_TRACKING_TWIN,
    TYPE_HEAD_TRACKING,
    TYPE_NATIVE_AR
} from '@geenee/builder/src/lib/constants';
import { getFileFromUrl }       from '@geenee/builder/src/lib/getFileFromUrl';
import { TeamLibraryItemModel } from '@geenee/builder/src/module/team-library/state/team-library-item.model';
import { useInject }            from '@geenee/shared';

enum SectionName {
  "native-ar" = "True Size AR",
  "slam-ar" = "World AR",
  "stamp-ar" = "Marker AR"
}

export type SourceTypes = "scene" | "team-library" | "tenor" | "spotify" | "sketchfab" | "readyPlayerMe" | "text3d";
export const getIconFromSource = (file: File, source: SourceTypes) => {
    const fileType = file.name.split(".")
        .pop();
    switch (source) {
        case "scene":
            if (fileType === "mp4" || fileType === "mp3") {
                return "media";
            }
            if (fileType === "gltf" || fileType === "glb") {
                return "mesh";
            }
            return "image";
        case "team-library":
            if (fileType === "mp4" || fileType === "mp3") {
                return "media";
            }
            if (fileType === "gltf" || fileType === "glb") {
                return "mesh";
            }
            return "image";
        case "sketchfab":
            return "sketchfab";
        case "spotify":
            return "spotify";
        case "tenor":
            return "tenor";
        case "readyPlayerMe":
            return "readyPlayerMe";
        case "text3d":
            return "text3d";
        default:
            return "mesh";
    }
};

const SECTION_3D_TYPES = [ TYPE_NATIVE_AR, TYPE_BODY_TRACKING_TWIN, TYPE_HEAD_TRACKING, TYPE_BODY_TRACKING_OVERLAY ];
export const useSceneAssetsActions = (_accessibleTypes?: string, loaderFromState?: boolean) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const { BuilderState } = useBuilderInject();
    const {
        AppState,
        container
    } = useInject();
    const { firstAvailableSection } = AppState;
    const SceneState = BuilderState.$sceneState;
    const { activeMolecule } = firstAvailableSection || {};
    const atomModels = activeMolecule?.sceneActorAtoms || [];
    const onCustomAudioUploaded = activeMolecule?.setSceneAudioFromFile || (() => {
    });
    const { showErrorNotification } = useShowErrorMessage();
    const analytics: Analytics = container.get("<Analytics>");

    const setLoadingStateChange = (loading: boolean) => {
        if (loaderFromState) {
            SceneState.setIsLoading(loading);
        }
        setIsLoading(loading);
    };

    const accessibleTypes = useMemo(() => {
        if (_accessibleTypes) {
            return _accessibleTypes;
        }
        if (SECTION_3D_TYPES
            .includes(firstAvailableSection?.type || "")) {
            return '.glb, .gltf';
        }
        return ".glb, .png, .jpg, .gif, .mp3, .mp4, .json";
    }, [ firstAvailableSection?.type, _accessibleTypes ]);

    const getCustomErrorMessage = useCallback(
        () => `Only ${ accessibleTypes } files \nwill be viewable in ${ SectionName[ firstAvailableSection?.type || "" ] }`,
        [ accessibleTypes, firstAvailableSection?.type ]
    );

    const getAtomSchema = (isGlb: boolean, sourceIcon: string, isSnippet?: boolean) => ({
        type:    isSnippet ? "scene-code-module" : "scene-actor",
        options:
          {
              scene_atom_source_icon: sourceIcon,
              ...isGlb ? { scene_atom_selected_animation_index: 0 } : undefined
          }

    });

    const calculateExperienceSize = () => {
        let sizeSum = 0;
        atomModels?.forEach((el) => {
            sizeSum += Array.from(el.assetsRegistry.values())[ 0 ]?.file_size || 0;
        });
        return sizeSum;
    };

    const isValidOverallAssetsSize = (fileSize: number) => {
        if (!fileSize) {
            BuilderState.toast = { severity: TOAST_ERROR, summary: 'File download error', detail: 'Failed to download file because it is empty.' };
            return false;
        }
        const maxAllowableSize = EXPERIENCE_MAX_SIZE_MB * 1048576;

        let sizeSum = calculateExperienceSize();
        sizeSum += fileSize || 0;
        if (sizeSum > maxAllowableSize) {
            const diff = (maxAllowableSize - sizeSum) / -1048576;
            showErrorNotification({
                message: diff.toFixed(2),
                summary: "File size exceeded"
            });
            return false;
        }
        return true;
    };

    const isCorrectFileType = (workingFile: File) => {
        if (!accessibleTypes) {
            return true;
        }
        const fileType = workingFile.name.split(".")
            .pop();
        if (!accessibleTypes.includes(`.${ fileType }`)) {
            BuilderState.toast = {
                severity: TOAST_ERROR,
                detail:   getCustomErrorMessage(),
                summary:  "Invalid file format"
            };
            const {
                name,
                type,
                size
            } = workingFile;

            analytics.track(INVALID_FILE_FORMAT, {
                name,
                type,
                size
            });
            return false;
        }
        return true;
    };

    const addText3DAsset = () => {
        SceneState.onTabChange("navigator");
        activeMolecule?.addText3DAsset();
    };

    const createAndCheckWorkingFile = async (file?: File, url?: string) => {
        if (file || url) {
            setLoadingStateChange(true);
            const workingFile = file || (url ? await getFileFromUrl(url, url.split("/")
                .pop()) : null);
            if (!workingFile) {
                BuilderState.toast = {
                    severity: TOAST_ERROR,
                    detail:   "Error when fetching file",
                    summary:  ""
                };
                setLoadingStateChange(false);
                return null;
            }
            if (!isCorrectFileType(workingFile) || !isValidOverallAssetsSize(workingFile.size)) {
                setLoadingStateChange(false);
                return null;
            }
            return workingFile;
        }
        return null;
    };
    const addSceneAsset = async ({
        file,
        url,
        source = "scene"
    }: { file?: File, url?: string, source: SourceTypes }) => {
        console.log({ file });
        SceneState.onTabChange("navigator");
        const workingFile = await createAndCheckWorkingFile(file, url);
        if (!workingFile) {
            setLoadingStateChange(false);
            return;
        }
        const fileType = workingFile.name.split(".")
            .pop();
        workingFile.type === "audio/mpeg"
            ? await onCustomAudioUploaded(workingFile, getIconFromSource(workingFile, source))
            : await activeMolecule?.addAtomWithAttachment(
                workingFile,

                getAtomSchema(fileType === "glb", getIconFromSource(workingFile, source), fileType === "json"),
                activeMolecule?.$parent?.type !== "native-ar"
            );
        setLoadingStateChange(false);
    };

    const replaceSceneAsset = async ({
        atomId,
        file,
        url,
        source
    }: { atomId: string, file?: File, url?: string, source: SourceTypes }) => {
        SceneState.onTabChange("navigator");
        if (!atomId) {
            return;
        }
        const workingFile = await createAndCheckWorkingFile(file, url);
        if (!workingFile) {
            return;
        }
        if (
            workingFile.type === "audio/mpeg" || workingFile.name.toLowerCase()
                .endsWith(".mp3")
        ) {
            await onCustomAudioUploaded(workingFile, getIconFromSource(workingFile, source));
        } else if (atomId && workingFile) {
            const fileType = workingFile.name.split(".")
                .pop();

            await activeMolecule?.replaceAtomWithFile(
                workingFile,
                atomId,
                getAtomSchema(fileType === "glb", getIconFromSource(workingFile, source), fileType === "json"),
                activeMolecule?.$parent?.type !== "native-ar"
            );
        }
        setLoadingStateChange(false);
    };

    const onImportFromLibrary = async (
        asset: TeamLibraryItemModel,
        mode: "replace" | "add" = "add",
        atomId = ""
    ) => {
        if (asset.attachment?.url) {
            // @ts-ignore
            const fileName = asset.attachment.file_name || asset.attachment.filename
        || asset.attachment.url.split("/")
            .pop();
            const file = await getFileFromUrl(
                asset.attachment.url,
                fileName
            );
            if (fileName?.toLowerCase()
                ?.endsWith(".mp3")) {
                onCustomAudioUploaded(file);
            } else if (mode === "replace") {
                await replaceSceneAsset({
                    atomId,
                    file,
                    source: "team-library"
                });
            } else {
                await addSceneAsset({
                    file,
                    source: "team-library"
                });
            }
        }
    };

    const onDrop = (files: File[]) => {
        if (files.length > 0) {
            const firstAcceptedFile = files[ 0 ];
            return addSceneAsset({
                file:   firstAcceptedFile,
                source: "scene"
            });
        }
        return null;
    };

    return {
        isLoading,
        addSceneAsset,
        addText3DAsset,
        replaceSceneAsset,
        onCustomAudioUploaded,
        calculateExperienceSize,
        atomModels,
        deleteSceneAsset: activeMolecule?.deleteAtom,
        onImportFromLibrary,
        isValidOverallAssetsSize,
        onDrop,
        getCustomErrorMessage,
        accessibleTypes
    };
};
