import React                                 from 'react';
import { BuilderState }                      from '@geenee/builder/src/core/state/builder.state';
import { handleError }                       from '@geenee/shared/src/util/errorHandler';
import { inject, injectable, postConstruct } from "inversify";
import { action, makeObservable }            from "mobx";
import { MobxForm }                          from './mobx-form';

export interface SubmitData {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
}

@injectable()
export class ProfileSettings extends MobxForm {
  @inject('<BuilderState>') builderState: BuilderState;

  @postConstruct()
  initialize() {
      makeObservable(this);
  }

  setup(form) {
      this.form = form;

      this.fields = [
          {
              name:        'first_name',
              type:        'text',
              placeholder: 'First name',
              rules:       { required: 'Please, fill first name' }
          },
          {
              name:        'last_name',
              type:        'text',
              placeholder: 'Last name',
              rules:       { required: 'Please, fill second name' }
          }
      ];

      this.hooks.onSubmit = this.onSubmit.bind(this);
  }

  @action
  async onSubmit(data: SubmitData, _e: React.SyntheticEvent) {
      this.setIsLoading(true);

      try {
          await this.builderState.currentUser?.profile?.update(data);
      } catch (error) {
          this.setIsLoading(false);

          handleError(error, this.setError);

          this.errorHandler(error);
      }

      this.setIsLoading(false);
  }
}
