/* eslint-disable max-len */
import React from 'react';

export function Status({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                opacity="0.3"
                d="M12 13C12.5304 13 13.0391 12.7893 13.4142 12.4142C13.7893 12.0391 14 11.5304 14 11C14 10.4696 13.7893 9.96086 13.4142 9.58579C13.0391 9.21071 12.5304 9 12 9H4C2.93913 9 1.92172 8.57857 1.17157 7.82843C0.421427 7.07828 0 6.06087 0 5C0 3.93913 0.421427 2.92172 1.17157 2.17157C1.92172 1.42143 2.93913 1 4 1H10V3H4C3.46957 3 2.96086 3.21071 2.58579 3.58579C2.21071 3.96086 2 4.46957 2 5C2 5.53043 2.21071 6.03914 2.58579 6.41421C2.96086 6.78929 3.46957 7 4 7H12C13.0609 7 14.0783 7.42143 14.8284 8.17157C15.5786 8.92172 16 9.93913 16 11C16 12.0609 15.5786 13.0783 14.8284 13.8284C14.0783 14.5786 13.0609 15 12 15H6V13H12Z"
                fill="#303036"
            />
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 16C3.10457 16 4 15.1046 4 14C4 12.8954 3.10457 12 2 12C0.89543 12 0 12.8954 0 14C0 15.1046 0.89543 16 2 16ZM14 4C15.1046 4 16 3.10457 16 2C16 0.895431 15.1046 0 14 0C12.8954 0 12 0.895431 12 2C12 3.10457 12.8954 4 14 4Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
export function ExportLeads({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                opacity="0.3"
                d="M15 16H1C0.4 16 0 15.6 0 15V3C0 2.4 0.4 2 1 2H3.5C3.77614 2 4 2.22386 4 2.5V3.5C4 3.77614 3.77614 4 3.5 4H2.5C2.22386 4 2 4.22386 2 4.5V13.5C2 13.7761 2.22386 14 2.5 14H13.5C13.7761 14 14 13.7761 14 13.5V9.5C14 9.22386 14.2239 9 14.5 9H15.5C15.7761 9 16 9.22386 16 9.5V15C16 15.6 15.6 16 15 16Z"
                fill="#303036"
            />
            <path
                className="fill"
                d="M10 3C6.8 3 4 5.5 4 10C5.1 8.3 6.4 7 10 7V10L16 5L10 0V3Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
export function Integrations({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                opacity="0.3"
                d="M13.649 9.89999L14.862 8.62999C15.4639 8.0247 15.849 7.23758 15.9575 6.39092C16.066 5.54427 15.8918 4.68548 15.462 3.94799L16.703 2.70699C16.8046 2.61647 16.8866 2.50619 16.944 2.38289C17.0015 2.25959 17.0332 2.12586 17.0372 1.98989C17.0412 1.85392 17.0174 1.71856 16.9673 1.5921C16.9172 1.46564 16.8418 1.35073 16.7457 1.25441C16.6497 1.15808 16.535 1.08237 16.4087 1.03189C16.2823 0.981421 16.1471 0.957251 16.0111 0.960863C15.8751 0.964474 15.7413 0.995792 15.6178 1.0529C15.4943 1.11001 15.3838 1.1917 15.293 1.29299L14.052 2.53399C13.3145 2.10422 12.4557 1.93006 11.6091 2.03856C10.7624 2.14706 9.97532 2.53215 9.37003 3.13399L8.12903 4.37899L13.649 9.89999Z"
                fill="#303036"
            />
            <path
                className="fill"
                d="M10.53 10.53L9.47002 9.46997L7.60002 11.345L6.66002 10.405L8.53002 8.52997L7.47002 7.46997L5.60002 9.34497L4.35102 8.09997L3.13802 9.36997C2.53618 9.97526 2.15109 10.7624 2.04259 11.609C1.93409 12.4557 2.10826 13.3145 2.53802 14.052L1.29302 15.293C1.19751 15.3852 1.12133 15.4956 1.06892 15.6176C1.01651 15.7396 0.988924 15.8708 0.98777 16.0036C0.986616 16.1363 1.01192 16.268 1.0622 16.3909C1.11248 16.5138 1.18673 16.6255 1.28063 16.7194C1.37452 16.8133 1.48617 16.8875 1.60907 16.9378C1.73196 16.9881 1.86364 17.0134 1.99642 17.0122C2.1292 17.0111 2.26042 16.9835 2.38242 16.9311C2.50443 16.8787 2.61477 16.8025 2.70702 16.707L3.94802 15.466C4.68551 15.8957 5.5443 16.0699 6.39095 15.9614C7.23761 15.8529 8.02473 15.4678 8.63002 14.866L9.87102 13.625L8.65502 12.405L10.53 10.53Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
export function Filter({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                ill-rule="evenodd"
                clipRule="evenodd"
                d="M1 3H15C15.552 3 16 2.552 16 2C16 1.448 15.552 1 15 1H1C0.448 1 0 1.448 0 2C0 2.552 0.448 3 1 3ZM13 7H3C2.448 7 2 6.552 2 6C2 5.448 2.448 5 3 5H13C13.552 5 14 5.448 14 6C14 6.552 13.552 7 13 7ZM5 11H11C11.552 11 12 10.552 12 10C12 9.448 11.552 9 11 9H5C4.448 9 4 9.448 4 10C4 10.552 4.448 11 5 11ZM7 15H9C9.552 15 10 14.552 10 14C10 13.448 9.552 13 9 13H7C6.448 13 6 13.448 6 14C6 14.552 6.448 15 7 15Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
