import { PlainTextAtomOptType } from '../../../type/_atom-option-type';

export const initProps: PlainTextAtomOptType = {
    plain_text:                  '',
    plain_text_font_type:        'm',
    plain_text_font_size:        14,
    plain_text_font_weight:      400,
    plain_text_font_line_height: 1.5,
    plain_text_font_uppercase:   false,
    plain_text_color:            'inherit',
    plain_text_color_alpha:      1,
    plain_text_color_type:       'auto',
    plain_text_color_variation:  'text',
    plain_text_color_custom:     '',
    plain_text_column_count:     1,
    plain_text_switch:           true,
    plain_text_cursor:           'text',
    plain_text_align:            'left'
};

export const getPropsFromOwner = (
    options: PlainTextAtomOptType,
    extendProps: Object = {}
) => ({
    plain_text_switch:           options.plain_text_switch,
    plain_text_font_type:        options.plain_text_font_type,
    plain_text_font_size:        options.plain_text_font_size,
    plain_text_font_weight:      options.plain_text_font_weight,
    plain_text_font_line_height: options.plain_text_font_line_height,
    plain_text_font_uppercase:   options.plain_text_font_uppercase,
    plain_text_color:            options.plain_text_color,
    plain_text_color_alpha:      options.plain_text_color_alpha,
    plain_text_color_custom:     options.plain_text_color_custom,
    plain_text_color_variation:  options.plain_text_color_variation,
    plain_text_color_type:       options.plain_text_color_type,
    plain_text_column_count:     options.plain_text_column_count,
    plain_text_cursor:           options.plain_text_cursor,
    plain_text:                  options.plain_text,
    plain_text_align:            options.plain_text_align,
    ...extendProps
});
