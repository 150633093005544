import styled from 'styled-components';
import theme  from './theme';
// Composer part
/* export const ContentContainerStyled = styled.div`
    flex-grow: 1;
`; */

// Mobile component styles

export const NavbarStyled = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    background-color: white;
    padding: 10px 12px 14px;

    div {
        box-sizing: border-box;
        /* box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25); */
        border-radius: 10px;
        width: 100%;
        background: #e8e8e8;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const PhoneScreenStyled = styled.div<{ color?: string }>`
    width: 100%;
    height: calc(100% - 200px);
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    background-color: ${ (props) => props.color || props.theme.colors.white };
    overflow: hidden;

    svg:hover {
        #hover-rollover {
            fill: ${ ({ theme: { secondaryColors } }) => secondaryColors.grey60 };
        }
    }
`;

export const BrandingBarStyled = styled.div<{ color?: string }>`
    width: 100%;
    height: 49px;
    padding: 12px 10px;
    display: flex;
    align-items: center;
    background-color: ${ ({ color }) => color || theme.colors.brandingBarBackground };
    z-index: 1;
    cursor: pointer;

    img {
        height: 29px;
    }

    .exit-btn svg {
        cursor: pointer;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    &:hover {
        border: 1.2px solid
            ${ ({ theme: { secondaryColors } }) => secondaryColors.pink10 };
    }
`;

export const CampaignWrapperStyled = styled.div`
    margin-top: 60px;
    width: 100%;
    height: 243px;
    position: relative;
`;

export const IntroBoxStyled = styled.div<{ hoverEffect: boolean }>`
  width: 100%;
  min-height: 30%;
  max-height: 30%;
  text-align: center;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  min-width: 208px;
  div:first-child {
    height: 100%;
    width: 100%;
  }

  &:hover {
    border-radius: 5px;
    box-shadow: 0 0 0 1.2px ${ ({ theme: { secondaryColors } }) => secondaryColors.pink10 };
  }

  ${ ({ theme: { secondaryColors }, hoverEffect }) => hoverEffect
          && `
        border-radius: 5px;
        box-shadow: 0 0 0 1.2px
            ${ secondaryColors.pink10 };
    ` }
`;

// Wizard component styles
export const TopBarStyled = styled.div`
    /* padding: 14px 25px; */
    height: 54px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px 10px 0px 0px;


    /* div {
        display: flex;
        align-items: center;
    } */
`;

export const WizardRowStyled = styled.div<{ noMargin?: boolean }>`
    padding: 12.5px 0;
    display: flex;
    align-items: center;
`;

export const WizardLabelColStyled = styled.div`
    flex-basis: 118px;
    flex-shrink: 0;
    font-size: 12px;
`;

export const WizardControlColStyled = styled.div`
    padding-left: 20px;
    flex-basis: 54%;
    flex-shrink: 0;
    display: flex;
    align-items: center;

    select,
    textarea,
    input:not([type='checkbox']) {
        width: 100%;
    }

    @keyframes pulse {
        /* Looks better if we transition the color instead of the width of the border */
        0% {
            box-shadow: 0 0 0 1.5px
                ${ ({ theme }) => theme.secondaryColors.pink10 };
        }
        100% {
            box-shadow: 0 0 0 1.5px rgba(0, 0, 0, 0);
        }
    }
    .got-focus {
        animation: 2s linear pulse;
    }
`;

export const WizardErrorColStyled = styled.div<{ minWidth?: string }>`
    padding-left: 20px;
    flex-basis: auto;

    b {
        font-weight: bold;
    }

    span {
        min-width: ${ (props) => (props.minWidth ? props.minWidth : 'unset') };
        padding: 8px;
        display: block;
        font-size: 11px;
        font-style: italic;
        font-family: 'Mulish', sans-serif;
        line-height: 14px;
        border-radius: 5px;
        background-color: ${ ({ theme: { colors } }) => colors.errorBackground };
        color: ${ ({ theme: { colors } }) => colors.black10 };
        border: 1px solid
            ${ ({ theme: { secondaryColors } }) => secondaryColors.pink30 };
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
    }
`;

export const DrawerShareStyled = styled.div`
    .shr-title {
        text-align: center;
    }

    .shr-wrapper {
        display: flex;
    }
`;

export const ShareBlockStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 25%;

    &:hover {
        background-color: lightgray;
        cursor: pointer;
    }
`;

export const DrawerSignupStyled = styled.div`
    .signup-wrapper {
        display: flex;
        flex-direction: column;

        input {
            margin-bottom: 6px;
        }
    }
`;

export const DrawerSpotifyStyled = styled.div``;

export const DrawerFandangoStyled = styled.div``;

export const ClickOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;

    :hover {
        // box-shadow: 0px 0px 0px 2px ${ ({ theme: { secondaryColors } }) => secondaryColors.pink10 };
    }
`;
