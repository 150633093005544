import React, { FC, memo } from 'react';
import { CSSTransition }   from 'react-transition-group';
import { cn }              from '../../util/bem';
import { usePortal }       from '../portal/portal.hook';
import './modal.component.scss';

export type ModalPropsType = {
    children?: React.ReactNode;
    backDrop?: boolean;
    backDropTransparent?: boolean;

    position?: 'center' | 'top' | 'bottom-right';
    size?: 'xs' | 'lg' | 'xl';

    style?: React.CSSProperties;
    actions?: React.ReactNode;
    onHideModal?: React.EventHandler<React.MouseEvent<HTMLElement>>;
    // onClick?: React.EventHandler<React.MouseEvent<HTMLElement>>;
    className?: string;
    in?: boolean;
    closeTriggerElement?: React.ReactNode | undefined
}

const className = cn('modal');

export const Modal: FC<ModalPropsType> = memo((props) => {
    const { Portal } = usePortal();

    return (
        <Portal>
            <CSSTransition
                in={ props.in }
                timeout={ 200 }
                classNames={ className() }
                unmountOnExit
            >
                <div
                    className={ className({
                        position: props.position,
                        size:     props.size
                    }, null, props.className) }
                    onClick={ (event) => {
                        event.stopPropagation();
                    } }
                >
                    { props.backDrop && (
                        <div
                            className={ className('back-drop', { transparent: props.backDropTransparent }) }
                            onClick={ props.onHideModal }
                        />
                    ) }

                    <div
                        className={ className('content') }
                        style={ props.style }
                    >
                        { props.children }
                        { !!props.closeTriggerElement && (
                            <div onClick={ props.onHideModal } className={ className('close-btn') }>
                                { props.closeTriggerElement }
                            </div>
                        ) }
                    </div>
                </div>
            </CSSTransition>
        </Portal>
    );
});

Modal.defaultProps = {
    in:       true,
    backDrop: true,
    position: 'center'
};
