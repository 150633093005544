import Quaternion from '../math/Quaternion';
import Euler from '../math/Euler';
import Vector3 from '../math/Vector3';
import ComplementaryFilter from './ComplementaryFilter';
import PosePredictor from './PosePredictor';
import {
  isLandscapeMode,
  MIN_TIMESTEP,
  MAX_TIMESTEP,
  isIOS,
  isFirefoxAndroid,
  getChromeVersion,
  isChromeWithoutDeviceMotion,
  isInsideCrossOriginIFrame,
  isR7,
} from './util';

import { BROWSER_MODE } from '../constants';

type FusionSettings = {
  isFirefoxAndroid: boolean,
  isIOS: boolean,
  chromeVersion: number | null,
  isDeviceMotionInRadians: boolean,
  isWithoutDeviceMotion: boolean,
  isInsideCrossOriginIFrame: boolean,
  isR7: boolean
};

/**
 *
 */
export default class FusionPoseSensor {
  private yawOnly: boolean;
  private accelerometer: Vector3;
  private gyroscope: Vector3;
  private filter: ComplementaryFilter;
  private posePredictor: PosePredictor;
  private settings: FusionSettings;
  private filterToWorldQ: Quaternion;
  private inverseWorldToScreenQ: Quaternion;
  private worldToScreenQ: Quaternion;
  private originalPoseAdjustQ: Quaternion;
  private resetQ: Quaternion;
  private orientationOut: Array<number>;
  private _deviceOrientationQ?: Quaternion;
  private deviceOrientationFixQ?: Quaternion;
  private deviceOrientationFilterToWorldQ?: Quaternion;
  private previousTimestampS: number;

  constructor(kFilter: number, predictionTime: number, yawOnly: boolean, isDeviceMotionInRadians: boolean) {
    this.yawOnly = yawOnly;
    this.accelerometer = new Vector3();
    this.gyroscope = new Vector3();
    this.filter = new ComplementaryFilter(kFilter);
    this.posePredictor = new PosePredictor(predictionTime);

    this.previousTimestampS = 0;

    this.settings = {
      isFirefoxAndroid: isFirefoxAndroid(),
      isIOS: isIOS(),
      chromeVersion: getChromeVersion(),
      isDeviceMotionInRadians: isDeviceMotionInRadians,
      isWithoutDeviceMotion: isChromeWithoutDeviceMotion(),
      isInsideCrossOriginIFrame: isInsideCrossOriginIFrame(),
      isR7: isR7(),
    };

    this.filterToWorldQ = new Quaternion();
    if (this.settings.isIOS) {
      this.filterToWorldQ.setFromAxisAngle(new Vector3(1, 0, 0), Math.PI / 2);
    } else {
      this.filterToWorldQ.setFromAxisAngle(new Vector3(1, 0, 0), -Math.PI / 2);
    }
    this.inverseWorldToScreenQ = new Quaternion();
    this.worldToScreenQ = new Quaternion();
    this.originalPoseAdjustQ = new Quaternion();
    const windowOrientation = BROWSER_MODE ? window.orientation : 0;
    this.originalPoseAdjustQ.setFromAxisAngle(new Vector3(0, 0, 1), -windowOrientation * Math.PI / 180);
    this.setScreenTransform();
    if (isLandscapeMode()) {
      this.filterToWorldQ.multiply(this.inverseWorldToScreenQ);
    }
    this.resetQ = new Quaternion();
    this.orientationOut = [0, 0, 0, 1];
    this.start();
  }

  /**
   *
   * @return {Array<number>}
   */
  getOrientation() {
    let orientation = new Quaternion();
    if (this.settings.isWithoutDeviceMotion && this._deviceOrientationQ) {
      this.deviceOrientationFixQ = this.deviceOrientationFixQ || function () {
        let z = new Quaternion().setFromAxisAngle(new Vector3(0, 0, -1), 0);
        let y = new Quaternion();
        const windowOrientation = BROWSER_MODE ? window.orientation : 0;

        if (windowOrientation === -90) {
          y.setFromAxisAngle(new Vector3(0, 1, 0), Math.PI / -2);
        } else {
          y.setFromAxisAngle(new Vector3(0, 1, 0), Math.PI / 2);
        }
        return z.multiply(y);
      }();
      this.deviceOrientationFilterToWorldQ = this.deviceOrientationFilterToWorldQ || function () {
        let q = new Quaternion();
        q.setFromAxisAngle(new Vector3(1, 0, 0), -Math.PI / 2);
        return q;
      }();
      orientation = this._deviceOrientationQ;
      let out = new Quaternion();
      out.copy(orientation);
      out.multiply(this.deviceOrientationFilterToWorldQ);
      out.multiply(this.resetQ);
      out.multiply(this.worldToScreenQ);
      out.multiplyQuaternions(this.deviceOrientationFixQ, out);
      if (this.yawOnly) {
        out.x = 0;
        out.z = 0;
        out.normalize();
      }
      this.orientationOut[0] = out.x;
      this.orientationOut[1] = out.y;
      this.orientationOut[2] = out.z;
      this.orientationOut[3] = out.w;
      return this.orientationOut;
    } else {
      let filterOrientation = this.filter.getOrientation();
      orientation = this.posePredictor.getPrediction(filterOrientation, this.gyroscope, this.previousTimestampS);
    }
    let out = new Quaternion();
    out.copy(this.filterToWorldQ);
    out.multiply(this.resetQ);
    out.multiply(orientation);
    out.multiply(this.worldToScreenQ);
    if (this.yawOnly) {
      out.x = 0;
      out.z = 0;
      out.normalize();
    }
    this.orientationOut[0] = out.x;
    this.orientationOut[1] = out.y;
    this.orientationOut[2] = out.z;
    this.orientationOut[3] = out.w;
    return this.orientationOut;
  }

  /**
   *
   */
  resetPose() {
    this.resetQ.copy(this.filter.getOrientation());
    this.resetQ.x = 0;
    this.resetQ.y = 0;
    this.resetQ.z *= -1;
    this.resetQ.normalize();
    if (isLandscapeMode()) {
      this.resetQ.multiply(this.inverseWorldToScreenQ);
    }
    this.resetQ.multiply(this.originalPoseAdjustQ);
  }

  /**
   *
   * @param {DeviceMotionEvent} e
   */
  updateDeviceMotion(deviceMotion: DeviceMotionEvent) {
    const accX = deviceMotion.accelerationIncludingGravity?.x as number;
    const accY = deviceMotion.accelerationIncludingGravity?.y as number;
    const accZ = deviceMotion.accelerationIncludingGravity?.z as number;

    const alpha = deviceMotion.rotationRate?.alpha as number;
    const beta = deviceMotion.rotationRate?.beta as number;
    const gamma = deviceMotion.rotationRate?.gamma as number;

    const timestampS = deviceMotion.timeStamp / 1000;
    const deltaS = timestampS - this.previousTimestampS;
    if (deltaS < 0) {
      this.previousTimestampS = timestampS;
      return;
    } else if (deltaS <= MIN_TIMESTEP || deltaS > MAX_TIMESTEP) {
      this.previousTimestampS = timestampS;
      return;
    }
    this.accelerometer.set(-accX, -accY, -accZ);
    if (this.settings.isR7) {
      this.gyroscope.set(-beta, alpha, gamma);
    } else {
      this.gyroscope.set(alpha, beta, gamma);
    }
    if (!this.settings.isDeviceMotionInRadians) {
      this.gyroscope.multiplyScalar(Math.PI / 180);
    }
    this.filter.addAccelMeasurement(this.accelerometer, timestampS);
    this.filter.addGyroMeasurement(this.gyroscope, timestampS);
    this.previousTimestampS = timestampS;
  }

  /**
   *
   */
  setScreenTransform() {
    this.worldToScreenQ.set(0, 0, 0, 1);
    const windowOrientation = BROWSER_MODE ? window.orientation : 0;
    
    switch (windowOrientation) {
      case 90:
        this.worldToScreenQ.setFromAxisAngle(new Vector3(0, 0, 1), -Math.PI / 2);
      case -90:
        this.worldToScreenQ.setFromAxisAngle(new Vector3(0, 0, 1), Math.PI / 2);
      default:
    }
    this.inverseWorldToScreenQ.copy(this.worldToScreenQ);
    this.inverseWorldToScreenQ.inverse();
  }

  onOrientationChange = (e: Event) => {
    this.setScreenTransform();
  }

  onDeviceOrientation = (e: DeviceOrientationEvent) => {
    this._deviceOrientationQ = this._deviceOrientationQ || new Quaternion();
    let alpha = e.alpha,
      beta = e.beta,
      gamma = e.gamma;
    alpha = (alpha || 0) * Math.PI / 180;
    beta = (beta || 0) * Math.PI / 180;
    gamma = (gamma || 0) * Math.PI / 180;
    const euler = new Euler(beta, alpha, -gamma, 'XYZ');
    this._deviceOrientationQ.setFromEuler(euler);
  }

  /**
   *
   * @param e
   * @private
   */
  onDeviceMotion = (e: DeviceMotionEvent) => {
    this.updateDeviceMotion(e);
  }

  onMessage = (event: MessageEvent) => {
    const message = event.data;
    if (!message || !message.type) {
      return;
    }
    const type = message.type.toLowerCase();
    if (type !== 'devicemotion') {
      return;
    }
    this.updateDeviceMotion(message.deviceMotionEvent);
  }

  start() {
    if (this.settings.isIOS && this.settings.isInsideCrossOriginIFrame) {
      BROWSER_MODE && window.addEventListener('message', this.onMessage);
    }
    BROWSER_MODE && window.addEventListener('orientationchange', this.onOrientationChange);
    if (this.settings.isWithoutDeviceMotion) {
      BROWSER_MODE && window.addEventListener('deviceorientation', this.onDeviceOrientation);
    } else {
      if (this.settings.isIOS) {
        DeviceMotionEvent.requestPermission()
          .then(response => {
            if (response === 'granted') {
              BROWSER_MODE && window.addEventListener('devicemotion', this.onDeviceMotion);
            }
          });
      } else {
        BROWSER_MODE && window.addEventListener('devicemotion', this.onDeviceMotion);
      }
    }
  }

  stop() {
    if (BROWSER_MODE) {
      window.removeEventListener('devicemotion', this.onDeviceMotion);
      window.removeEventListener('deviceorientation', this.onDeviceOrientation);
      window.removeEventListener('orientationchange', this.onOrientationChange);
      window.removeEventListener('message', this.onMessage);
    }
  }
}
