export const COMPANY_SIZES = [
    {
        value: '0-500',
        label: 'Less than 500 people'
    },
    {
        value: '500-1000',
        label: '500-1000 people'
    },
    {
        value: '1000-5000',
        label: '1000-5000 people'
    },
    {
        value: '6000-10000',
        label: '6000-10000 people'
    },
    {
        value: '10000+',
        label: 'More than 10000 people'
    }
];

export const COMPANY_TYPES = [
    {
        value: 'ad-agency',
        label: 'Ad Agency'
    },
    {
        value: 'design-studio',
        label: 'Design Studio'
    },
    {
        value: 'consumer-goods',
        label: 'Consumer Goods'
    },
    {
        value: 'installations',
        label: 'Installations'
    },
    {
        value: 'operating-supplies',
        label: 'Operating Supplies'
    },
    {
        value: 'business-services',
        label: 'Business Services'
    },
    {
        value: 'automotive',
        label: 'Automotive'
    },
    {
        value: 'beauty',
        label: 'Beauty'
    },
    {
        value: 'education',
        label: 'Education'
    },
    {
        value: 'e-commerce',
        label: 'E-commerce'
    },
    {
        value: 'healthcare',
        label: 'Healthcare'
    },
    {
        value: 'hospitality',
        label: 'Hospitality'
    },
    {
        value: 'fashion',
        label: 'Fashion'
    },
    {
        value: 'fitness',
        label: 'Fitness'
    },
    {
        value: 'food-beverage',
        label: 'Food & Beverage'
    },
    {
        value: 'gaming',
        label: 'Gaming'
    },
    {
        value: 'media-entertainment',
        label: 'Media & Entertainment'
    },
    {
        value: 'real-estate',
        label: 'Real Estate'
    },
    {
        value: 'retail',
        label: 'Retail'
    },
    {
        value: 'software-apps',
        label: 'Software & Apps'
    },
    {
        value: 'travel',
        label: 'Travel'
    },
    {
        value: 'wearables',
        label: 'Wearables'
    },
    {
        value: 'workplace-productivity',
        label: 'Workplace Productivity'
    },
    {
        value: 'other',
        label: 'Other'
    }
];
