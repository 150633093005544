import React, { useState } from 'react';
import { Input }           from '@geenee/ui';
import { observer }        from 'mobx-react';
import StyledButton        from '@geenee/builder/src/components/common/Button';
import { accountState }    from '@geenee/builder/src/core/state/account.state';
import { InputRowWrapper } from './styles';

export const AnalyticsInputRow = observer(() => {
    const [ value, setValue ] = useState(accountState.ga_client_id);
    const [ isLoading, setIsLoading ] = useState(false);
    return (
        <InputRowWrapper>
            <Input placeholder="Paste your Google Analytics Code Here" onChange={ (e) => setValue(e.target.value) } value={ value } />
            <StyledButton
                label="ADD CODE"
                $width="152px"
                disabled={ isLoading }
                onClick={ async () => {
                    setIsLoading(true);
                    await accountState.update({ ga_client_id: value });
                    setIsLoading(false);
                } }
            />
        </InputRowWrapper>
    );
});
