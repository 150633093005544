import React               from 'react';
import { DIContextType }   from '@geenee/geeclient-kit/src/lib/context/di.context';
import { SectionModel }    from '@geenee/shared';
import { withDIContext }   from '@geenee/shared/src/magellan/hoc/withDIContext.hoc';
import { MoleculeWrapper } from '@geenee/shared/src/magellan/molecule/molecule-wrapper.component';

interface Props {
    sectionModel: SectionModel
    diContext: DIContextType
}

export const SceneBuildSectionComponent = withDIContext((props: Props) => {
    const { sectionModel, diContext } = props;
    const { SlamAR } = diContext;
    return (
        <MoleculeWrapper magellanMolecule="SceneBuildWrapper">
            <MoleculeWrapper magellanMolecule="ContentWrapper">
                { /* <SlamAR /> */ }
            </MoleculeWrapper>
        </MoleculeWrapper>
    );
});

// eslint-disable-next-line arca/no-default-export
export default SceneBuildSectionComponent;
