import React, { useState }                   from 'react';
import { useInject }                         from '@geenee/shared';
import { LoadingSpinnerCentered }            from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { BRANDING_IMAGE_ID }                 from '@geenee/shared/src/util/constants';
import { cn }                                from '@geenee/ui';
import { observer }                          from 'mobx-react';
import UploadIcon                            from '@geenee/builder/src/asset/icons/Upload';
import { DropWrapper }                       from '@geenee/builder/src/components/DropWrapper';
import { DIComponentType }                   from '@geenee/builder/src/core/context/di.context';
import { UPLOADER_ALLOWED_IMAGE_EXTENSIONS } from '@geenee/builder/src/lib/constants';
import { ImageUpdatePencil }                 from '@geenee/builder/src/magellan/components/programView/ImageUpdatePencil';
import {
    LogoLoadingWrapper,
    UploadIconWrapper
}                                 from '@geenee/builder/src/magellan/components/programView/styles';
import { ProjectModel } from '@geenee/builder/src/magellan/model/project.model';

interface Props {
    children: JSX.Element | JSX.Element[];
}
const className = cn('campaign-image-wrapper');

export const BrandingBarWrapper = observer(({ children }: Props & DIComponentType) => {
    const [ isLoading, setLoading ] = useState(false);
    const { AppState } = useInject();
    const { activeProject } = (AppState as unknown) as {
            activeProject: ProjectModel;
        };
    if (!activeProject) return <>No active program</>;
    const disabled = activeProject.nft_published;

    const uploadFile = async (file: File) => {
        setLoading(true);
        await activeProject.setLogo(file);
        setLoading(false);
    };

    return (
        <DropWrapper
            dimension={ [ 300, 100 ] }
            disabled={ disabled }
            onDrop={ async ([ file ]) => {
                await uploadFile(file);
            } }
        >
            { isLoading ? (
                <LogoLoadingWrapper>
                    <LoadingSpinnerCentered size={ 20 } />
                </LogoLoadingWrapper>
            ) : (
                <></>
            ) }
            <div
                className={ className('root') }
            >
                <ImageUpdatePencil
                    uploadContainerId={ BRANDING_IMAGE_ID }
                    className={ className('pencil-branding-container') }
                    disabled={ disabled }
                    handleUpload={ uploadFile }
                    hide={ !activeProject.header_logo?.url }
                />
                { children }
            </div>
            { !activeProject.header_logo?.url ? (
                <UploadIconWrapper>
                    <UploadIcon
                        fill="#fff"
                        onClick={ () => {
                            if (!disabled) {
                                const imageInput = document.querySelector(
                                    '#header_logo_id'
                                ) as HTMLElement;
                                imageInput && imageInput.click();
                            }
                        } }
                    />
                    <input
                        accept={ UPLOADER_ALLOWED_IMAGE_EXTENSIONS }
                        onChange={ async (event) => {
                            const file =  event?.target?.files![ 0 ];
                            if (!file) return;
                            await uploadFile(file);
                        } }
                        type="file"
                        id="header_logo_id"
                        hidden
                    />
                </UploadIconWrapper>
            ) : <></> }
        </DropWrapper>
    );
});
