import React                      from 'react';
import { InputTableItem, Switch } from '@geenee/ui';

interface Props {
    hidden_in_tree?: boolean;
    onUpdate: (value: boolean) => void;
    disabled?: boolean;
}

export function ShowIntroRow({ hidden_in_tree = false, onUpdate, disabled }: Props) {
    return (
        <InputTableItem label='Show Experience Intro'>
            <Switch
                descriptionSize="xs"
                color="white"
                size='lg'
                checked={ !hidden_in_tree }
                onChange={ onUpdate }
                disabled={ disabled }
            />
        </InputTableItem>
    );
}
