import React, { useState }              from 'react';
import { Description, Switch, Wrapper } from '@geenee/ui';
import { observer }                     from 'mobx-react';

type SubscriptionPeriodSwitcherProps = {
    key: number
    yearlyCycle: boolean;
    onChange: (yearlyCycle: boolean) => void;
}

export const SubscriptionPeriodSwitcher = observer(({ key, yearlyCycle, ...props }: SubscriptionPeriodSwitcherProps) => {
    const [ checked, setChecked ] = useState(yearlyCycle);

    return (
        <Wrapper key={ key } padding='xxs'>
            <Description color='shade-1' margin='xs' size='xxl'>Save 10–15% annually</Description>
            <Wrapper row margin='xl' valign='center'>
                <Description color={ !checked ? 'shade-1' : 'shade-4' } margin='xs'>Monthly</Description>
                { ' ' }
                <Switch
                    size='lg'
                    margin='off'
                    checked={ checked }
                    onChange={ (_checked) => {
                        setChecked(_checked);
                        props.onChange(_checked);
                    } }
                />
                { ' ' }
                <Description color={ checked ? 'shade-1' : 'shade-4' }>Yearly</Description>
            </Wrapper>
            <Wrapper align='left' valign='bottom' maxWidth='160px'>
                <Description color='shade-4'>Roll over the features list to see the details</Description>
            </Wrapper>
        </Wrapper>
    );
});
