import React, { useEffect, useState }             from 'react';
import { AtomModel }                              from '@geenee/shared/index';
import { observer }                               from 'mobx-react';
import { Box3, Object3D, PlaneGeometry, Vector3 } from 'three';

interface Props {
    object: Object3D | null;
    atomModel: AtomModel;
}

export const ModelBoundingFrame = observer(({ object, atomModel }: Props) => {
    const [ center, setCenter ] = useState<Vector3>(new Vector3());
    const [ plane, setPlane ] = useState<PlaneGeometry | null>();

    useEffect(() => {
        // setTimeout is used for waiting until sceneStats applied to the model
        setTimeout(() => {
            if (object) {
                try {
                    const obj = object.clone();
                    const boundingBox = new Box3().setFromObject(obj);
                    const containerSize = boundingBox.getSize(new Vector3());
                    const maxSize = containerSize && Math.max(...Object.values(containerSize));
                    const newPlane = new PlaneGeometry(maxSize || 0, maxSize || 0);
                    setPlane(newPlane);
                    setCenter(boundingBox.getCenter(new Vector3()));
                } catch (e) {
                    console.log(e);
                    setPlane(null);
                }
            } else {
                setPlane(null);
            }
        });
    }, [ object, JSON.stringify([ atomModel.position, atomModel.rotation, atomModel.scale, atomModel.options.atom_text3d_body ]) ]);

    if (!plane) return <></>;

    return (
        <lineSegments
            name='LineSegment'
            rotation={ [ Math.PI * 1.5, 0, 0 ] }
            position={ [ center.x, 0.001, center.z ] }
        >
            <edgesGeometry attach="geometry" args={ [ plane ] } />
            <lineBasicMaterial color="#CC46CF" attach="material" />
        </lineSegments>
    );
});
