import React              from 'react';
import { Button }         from '@geenee/ui';
import { TransformModes } from '../../type';

type TransformButtonProps = {
    disabled: boolean | undefined
    icon: string
    iconSize?: number
    transformMode: TransformModes
    currentTransformMode: TransformModes
    onClick: () => void
}

export function TransformButton({
    disabled,
    icon,
    iconSize,
    transformMode,
    currentTransformMode,
    onClick
}: TransformButtonProps) {
    return (
        <Button
            disabled={ disabled }
            size="md"
            iconSize={ iconSize }
            icon={ icon }
            viewType="transparent"
            radius="lg"
            active={ transformMode === currentTransformMode }
            onClick={ onClick }
            iconColor={ transformMode === currentTransformMode ? 'white' : 'shade-1' }
        />
    );
}
