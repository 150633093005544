import { AttachmentModel, SceneRenderer } from '@geenee/shared';
import { MoleculeFactory }                from '@geenee/shared/src/magellan/molecule/factory/molecule.factory';
import { MoleculeModel }                  from '@geenee/shared/src/magellan/molecule/model/molecule.model';
import { ArSceneProcessorType }           from '@geenee/shared/src/magellan/processor/processor';
import { SceneManager }                   from '@geenee/shared/src/magellan/renderer/babylon-renderer/client-scene-manager';
import { inject, injectable }             from 'inversify';
import {
    action,
    computed,
    extendObservable,
    isObservableProp,
    makeObservable,
    observable,
    ObservableMap,
    set,
    toJS
}                                                from 'mobx';
import { AssetSchemaType } from '../../../../type/asset.type';
import {  SectionType }    from '../../../../type/section.type';
import {  Nullable }       from '../../../../type/shared.type';
import { BaseEntityModel } from '../../model/base-entity.model';
import { ExperienceModel } from '../../model/experience.model';
import { ProjectModel }    from '../../model/project.model';

@injectable()
export class SectionModel extends BaseEntityModel implements SectionType {
  @observable experience_id = '';
  @observable type: SectionType['type'] = 'scene-build';
  @observable attachmentsRegistry = new ObservableMap<string, AttachmentModel>([]);
  @observable sceneManager!: SceneManager | null = null;
  @observable imageAttachmentsRegistry: ObservableMap<string, AssetSchemaType> = new ObservableMap<string, AssetSchemaType>();
  @observable $parent!: ExperienceModel | null = null;
  options: SectionType['options'] = {
      section_is_visible_in_menu:        false,
      section_scene_carousel_visibility: true,
      section_thumbnail_img_url:         '',
      dynamic_content:                   {},
      image_attachment_ids:              [],
      order:                             0,
      section_menu_title:                ''
  };
  @observable order: SectionType['order'] = 0;
  @observable molecules: SectionType['molecules'] = [];
  @observable moleculesRegistry: ObservableMap<string, MoleculeModel> = new ObservableMap<string, MoleculeModel>();

    @inject('<MoleculeFactory>')
        moleculeFactory!: MoleculeFactory;

    constructor() {
        super();
        makeObservable(this);
    }

    @action updateState = (item: Partial<SectionModel>) => {
        Object.keys(item).forEach((key) => {
            // @ts-ignore
            this[ key ] = item[ key ];
        });
    };

  @action updateOption = (name: string, value: any) => {
      set(this.options, name, value);
      // @ts-ignore
      this[ name ] = value;
  };

  @action updateOptions = (opts: typeof SectionModel) => {
      Object.keys(opts).forEach((key) => {
          if (isObservableProp(this, key)) {
              // @ts-ignore
              this.updateOption(key, opts[ key ]);
          } else {
              // @ts-ignore
              extendObservable(this, { [ key ]: opts[ key ] });
          }
      });
  };

  @computed
  get activeMolecule() {
      const { moleculeId } = this.parentProject?.$parent.options || {};
      return this.moleculesRegistry.get(moleculeId || '') || this.sortedChildren[ 0 ];
  }

  @computed get nft_published() {
      // @TODO: double-check if additional $parent needed
      return this.$parent?.nft_published;
  }

  @computed get toJsonObject(): SectionModel {
      const ar_attachments = Array.from(this.attachmentsRegistry.values()).map((el) => el.toJsonObject);
      const image_attachments = Array.from(this.imageAttachmentsRegistry.values()).map((el) => el.toJsonObject);
      // const tracking_image = this.trackingImage?.toJsonObject;
      // @ts-ignore
      return {
          // ...toJS(this), $parent: undefined, ar_attachments, image_attachments, tracking_image
          ...toJS(this), $parent: undefined, ar_attachments, image_attachments
      };
  }

  @computed get children() {
      return this.moleculesRegistry;
  }

  @computed get sortedChildren() {
      return Array.from(this.moleculesRegistry.values()).sort((a, b) => a.options.order! - b.options.order!);
  }

  // TODO: Refactor:
  // naming
  @computed
  get parentProject(): Nullable<ProjectModel> {
      // @ts-ignore
      return this.$parent?.parentProject;
  }

  @computed
  get parent_id() {
      return this.experience_id;
  }

  @computed
  get title(): string | undefined {
      return this.options.section_menu_title;
  }

  set title(value: string | undefined) {
      this.options.section_menu_title = value;
  }
}
