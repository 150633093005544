/* eslint-disable max-len */
import React from 'react';

export function ArrowDown({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M18.5303 9.46967C18.8232 9.76256 18.8232 10.2374 18.5303 10.5303L12.5303 16.5303C12.2374 16.8232 11.7626 16.8232 11.4697 16.5303L5.46967 10.5303C5.17678 10.2374 5.17678 9.76256 5.46967 9.46967C5.76256 9.17678 6.23744 9.17678 6.53033 9.46967L12 14.9393L17.4697 9.46967C17.7626 9.17678 18.2374 9.17678 18.5303 9.46967Z" fill="#3E3B46" />
            { children }
        </svg>
    );
}

export function ArrowTop({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.46967 14.5303C5.17678 14.2374 5.17678 13.7626 5.46967 13.4697L11.4697 7.46967C11.7626 7.17678 12.2374 7.17678 12.5303 7.46967L18.5303 13.4697C18.8232 13.7626 18.8232 14.2374 18.5303 14.5303C18.2374 14.8232 17.7626 14.8232 17.4697 14.5303L12 9.06066L6.53033 14.5303C6.23744 14.8232 5.76256 14.8232 5.46967 14.5303Z"
                fill="#3E3B46"
            />
            { children }
        </svg>
    );
}

export function ArrowLeft({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5303 18.5303C14.2374 18.8232 13.7626 18.8232 13.4697 18.5303L7.46967 12.5303C7.17678 12.2374 7.17678 11.7626 7.46967 11.4697L13.4697 5.46967C13.7626 5.17678 14.2374 5.17678 14.5303 5.46967C14.8232 5.76256 14.8232 6.23744 14.5303 6.53033L9.06066 12L14.5303 17.4697C14.8232 17.7626 14.8232 18.2374 14.5303 18.5303Z"
                fill="#3E3B46"
            />
            { children }
        </svg>
    );
}
export function ArrowRight({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967L16.5303 11.4697C16.671 11.6103 16.75 11.8011 16.75 12C16.75 12.1989 16.671 12.3897 16.5303 12.5303L10.5303 18.5303C10.2374 18.8232 9.76256 18.8232 9.46967 18.5303C9.17677 18.2374 9.17677 17.7626 9.46967 17.4697L14.9393 12L9.46967 6.53033C9.17678 6.23744 9.17678 5.76256 9.46967 5.46967Z" fill="#3E3B46" />
            { children }
        </svg>
    );
}

export function ArrowRightSmall({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.29289 7.29289C9.68342 6.90237 10.3166 6.90237 10.7071 7.29289L14.7071 11.2929C14.8946 11.4804 15 11.7348 15 12C15 12.2652 14.8946 12.5196 14.7071 12.7071L10.7071 16.7071C10.3166 17.0976 9.68342 17.0976 9.29289 16.7071C8.90237 16.3166 8.90237 15.6834 9.29289 15.2929L12.5858 12L9.29289 8.70711C8.90237 8.31658 8.90237 7.68342 9.29289 7.29289Z"
                fill="#3E3B46"
            />
            { children }
        </svg>
    );
}

export function ArrowCircle({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                className="fill"
                d="M11.0303 7.46967C10.7374 7.17678 10.2626 7.17678 9.96967 7.46967C9.67678 7.76256 9.67678 8.23744 9.96967 8.53033L13.4393 12L9.96967 15.4697C9.67678 15.7626 9.67678 16.2374 9.96967 16.5303C10.2626 16.8232 10.7374 16.8232 11.0303 16.5303L15.0303 12.5303C15.3232 12.2374 15.3232 11.7626 15.0303 11.4697L11.0303 7.46967Z"
                fill="#3E3B46"
            />
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z"
                fill="#3E3B46"
            />
            { children }
        </svg>
    );
}

export function ExtendIcon({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.86177 5.52851C7.12212 5.26816 7.54423 5.26816 7.80458 5.52851L9.80458 7.52851C9.9296 7.65354 9.99984 7.82311 9.99984 7.99992C9.99984 8.17673 9.9296 8.3463 9.80458 8.47132L7.80457 10.4713C7.54422 10.7317 7.12211 10.7317 6.86177 10.4713C6.60142 10.211 6.60142 9.78886 6.86177 9.52851L8.39036 7.99992L6.86177 6.47132C6.60142 6.21097 6.60142 5.78886 6.86177 5.52851Z"
                fill="#858293"
            />
            { children }
        </svg>
    );
}

export function UndoIcon({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                className="fill"
                d="M4.46967 10.5303L9.46967 15.5303C9.76256 15.8232 10.2374 15.8232 10.5303 15.5303C10.8232 15.2374 10.8232 14.7626 10.5303 14.4697L6.81066 10.75H10C15.1086 10.75 19.25 14.8914 19.25 20C19.25 20.4142 19.5858 20.75 20 20.75C20.4142 20.75 20.75 20.4142 20.75 20C20.75 14.0629 15.9371 9.25 10 9.25H6.81066L10.5303 5.53033C10.8232 5.23744 10.8232 4.76256 10.5303 4.46967C10.2374 4.17678 9.76256 4.17678 9.46967 4.46967L4.46967 9.46967C4.17678 9.76256 4.17678 10.2374 4.46967 10.5303Z"
                fill="#3E3B46"
            />
            { children }
        </svg>
    );
}

export function RedoIcon({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                className="fill"
                d="M19.5303 10.5303L14.5303 15.5303C14.2374 15.8232 13.7626 15.8232 13.4697 15.5303C13.1768 15.2374 13.1768 14.7626 13.4697 14.4697L17.1893 10.75H14C8.89137 10.75 4.75 14.8914 4.75 20C4.75 20.4142 4.41421 20.75 4 20.75C3.58579 20.75 3.25 20.4142 3.25 20C3.25 14.0629 8.06294 9.25 14 9.25H17.1893L13.4697 5.53033C13.1768 5.23744 13.1768 4.76256 13.4697 4.46967C13.7626 4.17678 14.2374 4.17678 14.5303 4.46967L19.5303 9.46967C19.8232 9.76256 19.8232 10.2374 19.5303 10.5303Z"
                fill="#3E3B46"
            />
            { children }
        </svg>
    );
}
