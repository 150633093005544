import React, { useEffect }        from 'react';
import { Button }                  from '@geenee/ui';
import { SizeType }                from '@geenee/ui/src/lib/util/global-props';
import { observer }                from 'mobx-react';
import { AtomModel }               from "~/magellan/model/atom.model";
import { MoleculeModel }           from "~/magellan/model/molecule.model";
import { useAssetCustomCodeModal } from '~/module/custom-code/modal/asset-custom-code.modal';

export const CustomCodeTransparentButtonComponent = observer(({
    currentModel,
    margin = 'off'
}: {currentModel?: AtomModel | MoleculeModel; margin?: SizeType}) => {
    const [ showModal ] = useAssetCustomCodeModal(currentModel);

    useEffect(() => {
        if (currentModel?.custom_code_opened) {
            showModal();
        }
    }, [ currentModel?.custom_code_opened ]);

    if (!currentModel) {
        return <></>;
    }
    return (
        <Button
            icon="customCode"
            size="xs"
            iconStroke="fat"
            radius="sm"
            margin={ margin }
            viewType="action"
            onClick={ showModal }
        />
    );
});
