import styled from 'styled-components';

export const UploadBox = styled.div<{
    width?: string;
    height?: string;
    imgSrc?: string;
}>`
    border-radius: 5px;
    width: ${ ({ width }) => width || '150px' };
    height: ${ ({ height }) => height || '50px' };
    ${ ({ imgSrc }) => imgSrc && `background: url('${ imgSrc }');` };

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    img {
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
    }
`;

export const ArAssetUploadBox = styled(UploadBox)`
  overflow: hidden;
  min-width: 30px;
  max-width: 30px;
    border-radius: 100%;
    background: ${ ({ theme: { secondaryColors } }) => secondaryColors.grey60 };
    border: 1px solid ${ ({ theme: { colors } }) => colors.white };
    height: 41px;
    width: 41px;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

export const UploaderText = styled.a`
    color: #595959;
`;

export const DescriptionText = styled.p<{viewType?: 'primary' | 'secondary'}>`
    margin: 6px 0 0 0;
    max-width: 200px;
    font-size: 10px;
    font-style: italic;
    color: ${ ({ theme: { colors }, viewType }) => (viewType === 'primary' ? colors.white : colors.black) };
`;

export const ErrorRoot = styled.div`
    padding: 5px 8px;
    margin-top: -6px;
    background: ${ ({ theme: { secondaryColors } }) => secondaryColors.pink20 };
    border: 1px solid
        ${ ({ theme: { secondaryColors } }) => secondaryColors.pink30 };
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
        margin-left: 8px;
`;
