import { Description } from '@geenee/ui';
import styled          from 'styled-components';

export const BodyStyles = () => `
  width: 460px;
  height: auto;
  max-height: 100vh;
  padding: 33px 25px 40px;
  background: linear-gradient(
          180deg,
          rgba(205, 191, 213, 0.16) 0%,
          rgba(169, 89, 215, 0.18) 43.34%,
          rgba(139, 172, 227, 0.308) 93.75%
      ),
      #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;

export const DefaultWrapper = styled.div`
    ${ BodyStyles };
`;

export const Title = styled.span`
    font-family: 'Gordita', Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: inherit;
    font-size: 13px;
    margin-bottom: 24px;
`;

export const DescriptionContainer = styled.div`
    width: 310px;
    margin-bottom: 26px;
`;

export const DeleteDescription = styled(Description)`
    font-family: 'Gordita', Arial, Helvetica, sans-serif;
    font-size: 13px;
    text-align: center;
`;
