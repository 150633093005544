import React, { useEffect, useRef, useState } from 'react';
import { useDebounce }                        from '@geenee/shared/src/util/useDebounce';
import { useOnClickOutside, Wrapper }         from '@geenee/ui';
import Editor                                 from '@monaco-editor/react';
import { observer }                           from 'mobx-react';
import './editor.component.scss';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const options = {
    acceptSuggestionOnCommitCharacter: true,
    acceptSuggestionOnEnter:           'on',
    accessibilitySupport:              'auto',
    autoIndent:                        true,
    automaticLayout:                   true,
    codeLens:                          true,
    colorDecorators:                   true,
    contextmenu:                       true,
    cursorBlinking:                    'blink',
    cursorSmoothCaretAnimation:        true,
    cursorStyle:                       'line',
    disableLayerHinting:               false,
    disableMonospaceOptimizations:     false,
    dragAndDrop:                       true,
    fixedOverflowWidgets:              false,
    folding:                           true,
    foldingStrategy:                   'auto',
    fontLigatures:                     true,
    formatOnPaste:                     true,
    formatOnType:                      false,
    hideCursorInOverviewRuler:         false,
    highlightActiveIndentGuide:        true,
    links:                             true,
    mouseWheelZoom:                    true,
    multiCursorMergeOverlapping:       true,
    multiCursorModifier:               'alt',
    overviewRulerBorder:               true,
    overviewRulerLanes:                2,
    quickSuggestions:                  true,
    quickSuggestionsDelay:             100,
    readOnly:                          false,
    renderControlCharacters:           false,
    renderFinalNewline:                true,
    renderIndentGuides:                true,
    renderLineHighlight:               'all',
    renderWhitespace:                  'none',
    revealHorizontalRightPadding:      30,
    roundedSelection:                  true,
    rulers:                            [],
    scrollBeyondLastColumn:            5,
    scrollBeyondLastLine:              true,
    selectOnLineNumbers:               true,
    selectionClipboard:                true,
    selectionHighlight:                true,
    showFoldingControls:               'mouseover',
    smoothScrolling:                   true,
    suggestOnTriggerCharacters:        true,
    wordBasedSuggestions:              true,
    wordSeparators:                    "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
    wordWrap:                          'off',
    wordWrapBreakAfterCharacters:      '\t})]?|&,;',
    wordWrapBreakBeforeCharacters:     '{([+',
    wordWrapBreakObtrusiveCharacters:  '.',
    wordWrapColumn:                    80,
    wordWrapMinified:                  true,
    wrappingIndent:                    'none'
};

type PropsType = {
    value: string;
    onUpdate: (val: string) => any;
    onClose?: () => any;
    style?: React.CSSProperties
    disabled?: boolean
}

export const CodeEditor = observer(({
    value, onUpdate, style = {}, disabled = false, onClose
}: PropsType) => {
    const ref = useRef(null);
    const [ localValue, setLocalValue ] = useState(value);

    const debouncedSearchTerm = useDebounce(localValue, 500);
    useEffect(() => {
        if (debouncedSearchTerm !== value) {
            onUpdate(debouncedSearchTerm);
        }
    }, [ debouncedSearchTerm ]);
    useOnClickOutside(ref, () => {
        onClose && onClose();
    });

    return (
        <Wrapper innerRef={ ref } fullWidth style={ { height: '90vh', width: '90vw', ...style } }>
            { /* <CodeMirror */ }
            { /*    value={ value } */ }
            { /*    options={ { */ }
            { /*        mode:        'javascript', */ }
            { /*        theme:       'material', */ }
            { /*        lineNumbers: true, */ }
            { /*        gutters:     { className: 'CodeMirror-linenumbers', style: { width: '20px' } } */ }

            { /*    } } */ }
            { /*    onChange={ (editor, value) => { */ }
            { /*        console.log('uncontrolled', { val: editor.getValue() }); */ }
            { /*        onUpdate(editor.getValue()); */ }
            { /*    } } */ }
            { /* /> */ }
            <Editor
                options={ { readOnly: disabled } }
                height="100%" // By default, it fully fits with its parent
                theme="vs-dark"
                language="javascript"
                loading={ <></> }
                value={ value }
                onChange={ (_value) => {
                    setLocalValue(_value || '');
                } }
            />

        </Wrapper>
    );
});

// @ts-ignore
CodeEditor.defaultProps = { value: '' };
