import { useEffect, useState }         from 'react';
import { TYPE_SLAM_AR, TYPE_STAMP_AR } from '@geenee/builder/src/lib/constants';
import generateModelPreview            from '@geenee/builder/src/lib/generateModelPreview';
import { AtomModel }                   from '@geenee/builder/src/magellan/model/atom.model';
import { SectionModel }                from '@geenee/builder/src/magellan/model/section.model';

export const useFirstAssetPreview = (firstAvailableSection?: SectionModel) => {
    const [ previewUrl, setPreviewUrl ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);
    useEffect(() => {
        if (firstAvailableSection?.type === TYPE_SLAM_AR || firstAvailableSection?.type === TYPE_STAMP_AR) {
            const { activeMolecule } = firstAvailableSection;
            const atoms = activeMolecule.sceneActorAtoms;
            if (atoms.length) {
                setIsLoading(true);
                generateModelPreview({
                    done:
                        (async (imageUrl) => {
                            setPreviewUrl(imageUrl);
                        }),
                    modelsToBeRendered: atoms as AtomModel[],
                    rendererSize:       { width: 2000, height: 1000 },
                    customDistance:     1.3
                }).then(() => {
                    setIsLoading(false);
                });
            }
        }
    }, [ firstAvailableSection?.$parent?.sequences ]);

    return { previewUrl, isLoading };
};
