import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '39',
    height = '65',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 39 65"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.75" y="0.75" width="37.5" height="63.5" rx="2.25" fill="#4C4B4B" stroke="black" strokeWidth="1.5" />
            <g filter="url(#filter0_d)">
                <circle cx="19.3616" cy="32.8616" r="13.8616" stroke="white" strokeWidth="2" />
                <path
                // eslint-disable-next-line max-len
                    d="M22.1603 30.9474C22.4726 31.2597 22.9789 31.2597 23.2912 30.9474C23.6035 30.6352 23.6035 30.1289 23.2912 29.8166L20.0927 26.6181C19.7804 26.3058 19.2741 26.3058 18.9618 26.6181L15.7633 29.8166C15.451 30.1289 15.451 30.6352 15.7633 30.9474C16.0756 31.2597 16.5819 31.2597 16.8941 30.9474L19.5272 28.3143L22.1603 30.9474Z"
                    fill="white"
                />
                <path
                // eslint-disable-next-line max-len
                    d="M20.3271 38.3781L20.3271 27.1832C20.3271 26.7416 19.9691 26.3836 19.5275 26.3836C19.0859 26.3836 18.7279 26.7416 18.7279 27.1832L18.7279 38.3781C18.7279 38.8197 19.0859 39.1777 19.5275 39.1777C19.9691 39.1777 20.3271 38.8197 20.3271 38.3781Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter id="filter0_d" x="3.5" y="18" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.516667 0 0 0 0 0.516667 0 0 0 0 0.516667 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}
