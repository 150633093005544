import React, { memo }                                 from 'react';
import { Button, Description, Icon, iconMap, Wrapper } from '@geenee/ui';
import { IconPropsType }                               from '@geenee/ui/src/lib/component/icon/icon.component';

export type ModalProps = {
  title: string,
  description: React.ReactNode,
  confirmButtonName: string
  onConfirm: () => Promise<boolean>
  onClose?: () => void
  iconName: keyof typeof iconMap,
  iconColor?: IconPropsType['color'],
  disabled?: boolean
};

export const ModalConfirmationContent = memo((props: ModalProps) => (
    <Wrapper align="center" style={ { width: 400 } }>
        <Icon size={ 48 } name={ props.iconName } color={ props.iconColor || 'none' } margin="sm" />
        <Description weight='medium' margin="xs" size="lg" lineHeight="lg">
            { props.title }
        </Description>
        <Wrapper margin="sm">
            { props.description }
        </Wrapper>
        <Wrapper style={ { paddingBottom: 0 } } row margin="xs" align="center">
            <Button fullWidth margin="sm" viewType="secondary" uppercase onClick={ props.onClose }>Nevermind</Button>
            <Button
                fullWidth
                uppercase
                onClick={ async () => {
                    const result = await props.onConfirm();
                    if (result) {
                        props.onClose && props.onClose();
                    }
                } }
                margin="xs"
            >
                { props.confirmButtonName }
            </Button>
        </Wrapper>
    </Wrapper>
));
