import { Analytics }                                from '@geenee/analytics';
import { SECTION_CREATED, SECTION_DELETED }         from '@geenee/builder/src/lib/constants';
import envConfig                                    from '@geenee/builder/src/lib/envConfig';
import { container }                                from '@geenee/builder/src/magellan/di/di';
import { HttpClient, ViewModel as ViewModelShared } from '@geenee/shared';
import { SectionSchemaType }                        from '@geenee/shared/type/section.type';
import { Nullable }                                 from '@geenee/shared/type/shared.type';
import { ViewType }                                 from '@geenee/shared/type/view.type';
import { action, computed, makeObservable, toJS }   from 'mobx';
import { ExperienceModel }                          from './experience.model';
import { ProjectModel }                             from './project.model';

export class ViewModel extends ViewModelShared implements ViewType {
    httpClient: HttpClient = container.get('<HttpClient>');
    analytics: Analytics = container.get('<Analytics>');

    constructor() {
        super();
        makeObservable(this);
    }

    @computed
    get parentProjectEditable(): Nullable<ProjectModel> {
        return this.parentProject as Nullable<ProjectModel>;
    }

    @action
        updateState = (item: Partial<ExperienceModel>) => {
            Object.keys(item).forEach((key) => {
            // @ts-ignore
                this[ key ] = item[ key ];
            });
        };

    @action
    async updateOptions(options: Partial<ViewType['options']>) {
        Object.assign(this.options, options);
        await this.saveData();
    }

    async saveData(analyticData?: { eventName: string, properties: object }) {
        try {
            const view = toJS(this.toServerData());
            this.parentProjectEditable?.saveData();

            await this.httpClient.put(
                `${ envConfig.API_URL }/api/v0/views/${ this.id }`,
                { view }
            );
            this.updateState(this);

            if (analyticData?.eventName && analyticData?.properties) {
                this.analytics.track(analyticData?.eventName, analyticData?.properties);
            }
        } catch (e) {
            console.error('Error while updating experience: ', e);
        }
    }

    toServerData() {
        return {
            ...this,
            $parent:               undefined,
            sectionsRegistry:      undefined,
            httpClient:            undefined,
            sections:              undefined,
            parentProject:         undefined,
            parentProjectEditable: undefined,
            attachmentFactory:     undefined,
            sectionFactory:        undefined
        };
    }

    @action
    async createSection(sectionSchema: Partial<SectionSchemaType>) {
        try {
            const sections = Array.from(this.sectionsRegistry.values()).map((el) => el.options.order || 0);
            const order = sections.length ? Math.max(...sections) : 0;
            const composedSchema = {
                ...sectionSchema,
                options: {
                    ...sectionSchema.options,
                    order: order + 1
                },
                molecules: [],
                view_id:   this.id,
                viewId:    this.id
            };

            const { data: { data } } = await this.httpClient.post(`${ envConfig.API_URL }/api/v0/sections`, { section: composedSchema });
            const { id, view_id, type } = data;

            this.analytics.track(SECTION_CREATED, { id, view_id, type, order });

            composedSchema.id = id;
            composedSchema.viewId = view_id;
            const sectionModel = this.sectionFactory.create(composedSchema, this);
            this.sectionsRegistry.set(sectionModel.id, sectionModel);
            (this.parentProject as ProjectModel).saveData();
            return sectionModel;
        } catch (e) {
            console.error('Error while creating a section: ', e);

            return new Error('Error while creating a section');
        }
    }

    @action
    async deleteChild(childId: string) {
        try {
            await this.httpClient.delete(`${ envConfig.API_URL }/api/v0/sections/${ childId }`);
            this.sectionsRegistry.delete(childId);
            this.parentProjectEditable?.saveData();

            this.analytics.track(SECTION_DELETED, { id: childId });
        } catch (e) {
            console.error('Error while deleting section: ', e);
        }
    }
}
