import { BuilderState } from '@geenee/builder/src/core/state/builder.state';
import {
    TeamState,
    teamState
} from '@geenee/builder/src/core/state/team.state';
import { inject, injectable, postConstruct }   from "inversify";
import { action, makeObservable, runInAction } from 'mobx';
import { MobxForm }                            from './mobx-form';

@injectable()
export class InviteUser extends MobxForm {
  @inject('<BuilderState>') builderState: BuilderState;
  @inject('<TeamState>') teamState: TeamState;

  @postConstruct()
  initialize() {
      makeObservable(this);
  }

  setup(form) {
      this.form = form;

      this.fields = [
          {
              name:        'password',
              type:        'password',
              placeholder: 'Create Password',
              rules:       {
                  required:  'This field is required',
                  minLength: {
                      value:   8,
                      message: 'Password must have at least 8 characters'
                  }
              }
          }
      ];

      this.hooks.onSubmit = this.onSubmit.bind(this);
      this.teamState = teamState;
  }

  @action
  async onSubmit(
      data: any,
      _e: React.SyntheticEvent,
      options: { token: string }
  ) {
      this.setIsLoading(true);

      try {
          await this.teamState.acceptInvite(data.password, options.token);

          runInAction(() => {
              this.builderState.authHeaders = [];
              this.builderState.currentUser.profile = null;
              this.builderState.$appState.projectsRegistry.clear();
          });
      } catch (error) {
          this.setIsLoading(false);

          this.setError('form', {
              type:    'custom',
              message: 'The credentials you’ve entered are incorrect.'
          });

          this.errorHandler(error);
      }

      this.setIsLoading(false);
  }
}
