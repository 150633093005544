import React                              from 'react';
import { cn, Description, Icon, Wrapper } from "@geenee/ui";
import './styles.scss';

const className = cn('empty-scene-tab');

interface Props {
  label?: string
  customDescription?: string
  customLabel?: string
}

export function EmptyTab({ label = '', customDescription = '', customLabel = '' }: Props) {
    return (
        <Wrapper
            align="center"
            className={ className('root') }
            padding="md"
        >
            <Icon size={ 48 } name="searchQuestion" margin="sm" color="shade-4" />
            <Description margin="xs">
                { customLabel || `No ${ label }s yet` }
            </Description>
            <Description color="shade-4">
                { customDescription || `They will show up here once you add a new node containing ${ label }` }
            </Description>
        </Wrapper>
    );
}
