import React, { FC, memo } from 'react';
import { cn }              from '../../util/bem';
import { TextColorType }   from '../../util/global-props';
import './span.component.scss';

export type SpanPropsType = {
    className?: string;
    children: React.ReactNode;
    color?: TextColorType;
    weight?: 'bold' | 'normal' | 'regular' | 'medium';
    textAlign?: 'left' | 'center' | 'right';
    uppercase?: boolean;
    flex?: number;
    role?: string;
    underline?: boolean;
    style?: React.CSSProperties;
    tabularNumbers?: boolean;
}

const className = cn('span');

export const Span: FC<SpanPropsType> = memo((props) => (
    <span
        className={ className({
            weight:         props.weight,
            color:          props.color,
            uppercase:      props.uppercase,
            tabularNumbers: props.tabularNumbers,
            underline:      props.underline,
            textAlign:      props.textAlign
        }, null, props.className) }
        style={ { ...props.style, flex: props.flex } }
        role={ props.role }
    >
        { props.children }
    </span>
));
