import React, { useEffect, useMemo, useRef, useState } from 'react';
import { LoadingSpinnerCentered }                      from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { Description, Divider, Wrapper }               from '@geenee/ui';
import isEqual                                         from 'lodash-es/isEqual';
import { observer }                                    from 'mobx-react';
import { spotifyState }                                from '@geenee/builder/src/core/state/spotify.state';
import { SPOTIFY_PAGE_LIMIT }                          from '@geenee/builder/src/lib/constants';
import TrackRow                                        from '../TrackRow';
import { TracksContainer }                             from './styles';

type Props = {
    collection: any;
    choosedTrack: any;
}

const FoundTracks = observer(({ collection, choosedTrack }: Props) => {
    const { page, searchString, isLoading } = spotifyState;

    const [ currentAudioPreviewUrl, setCurrentAudioPreviewUrl ] = useState<null | string>(null);
    const [ audioPaused, setAudioPaused ] = useState(false);
    const [ audioEnded, setAaudioEnded ] = useState(false);

    const activeCollection = useRef(collection || []);
    const audioInstanceRef = useRef<null | HTMLAudioElement>(null);
    const scrollRef = useRef();

    const audioInstance: (null | HTMLAudioElement) = useMemo(() => (audioInstanceRef.current
        ? audioInstanceRef.current : null), [ audioInstanceRef.current ]);

    const playPreview = (instance: HTMLAudioElement | null, play: boolean) => {
        setAudioPaused(!play);
        instance && (play ? instance.play() : instance.pause());
    };

    // Stop playing music on new search results
    useEffect(() => {
        if (!isEqual(collection, activeCollection.current)) {
            audioInstance && playPreview(audioInstance, false);
            activeCollection.current = collection;
        }
    }, [ activeCollection.current, collection, audioInstance ]);

    // Stop active song on modal close
    useEffect(() => () => {
        if (audioInstanceRef.current && !audioPaused && !audioEnded) {
            playPreview(audioInstanceRef.current, false);
        }
    }, []);

    const scrolled = () => {
        const myDiv = scrollRef.current;
        if (!myDiv) return;
        if (
        // @ts-ignore
            myDiv.offsetHeight + myDiv.scrollTop >= myDiv.scrollHeight && !isLoading
        ) {
            spotifyState.search({
                query:  searchString,
                offset: (page + 1) * SPOTIFY_PAGE_LIMIT,
                limit:  SPOTIFY_PAGE_LIMIT,
                page:   page + 1
            });
        }
    };

    const onTrackClickHandler = (track) => {
        spotifyState.setChoosedTrack(track);
    };

    const trackClickHandler = (trackPreviewUrl: string) => {
        setAaudioEnded(false);
        if ((trackPreviewUrl === currentAudioPreviewUrl) && audioInstance) {
            if (audioInstance.paused || audioInstance.ended) {
                playPreview(audioInstance, true);
            } else {
                playPreview(audioInstance, false);
            }
        } else {
            audioInstance && playPreview(audioInstance, false);
            const localAudioInstance = new Audio(trackPreviewUrl);
            localAudioInstance.onended = function () {
                setAaudioEnded(true);
            };
            audioInstanceRef.current = localAudioInstance;
            setCurrentAudioPreviewUrl(trackPreviewUrl);
            playPreview(localAudioInstance, true);
        }
    };

    return (
        <Wrapper fullWidth>
            <Divider transparent margin='xs' />
            <Wrapper fullWidth row margin='xs' valign='center'>
                <Wrapper padding='sm' style={ { flex: '1 0 316px' } }>
                    <Description size='sm'>
                        ALBUM
                    </Description>
                </Wrapper>
                <Wrapper padding='sm' style={ { flex: '1 0 216px' } }>
                    <Description size='sm'>
                        ARTIST
                    </Description>
                </Wrapper>
                <Wrapper padding='sm' style={ { flex: '1 1 auto' } }>
                    <Description size='sm'>
                        SONG
                    </Description>
                </Wrapper>
            </Wrapper>
            { /* <Scrollbars
        // style={{ width: 916 }}
        autoHeight
        autoHeightMin={150}
        autoHeightMax={500}
    > */ }
            <Wrapper fullWidth>
                <TracksContainer
                    onScroll={ scrolled }
                    // @ts-ignore
                    ref={ scrollRef }
                >
                    { activeCollection.current.map((track) => {
                        const showStopIcon = audioInstance && track.preview_url === currentAudioPreviewUrl && !audioPaused && !audioEnded;
                        return (
                            <TrackRow
                                key={ track.id }
                                track={ track }
                                // @ts-ignore
                                onTrackClickHandler={ onTrackClickHandler }
                                // @ts-ignore
                                trackClickHandler={ trackClickHandler }
                                // @ts-ignore
                                showStopIcon={ showStopIcon }
                                choosedTrack={ choosedTrack }
                            />
                        );
                    }) }
                    { isLoading && (
                        <Wrapper style={ { height: '120px' } } fullWidth align='center' valign='center'>
                            <LoadingSpinnerCentered />
                        </Wrapper>
                    ) }
                </TracksContainer>
            </Wrapper>
            { /* </Scrollbars> */ }
        </Wrapper>
    );
});
// eslint-disable-next-line arca/no-default-export
export default FoundTracks;
