// @ts-ignore
import { AtomModel, MoleculeModel } from '@geenee/shared/index';
import { Euler, Vector3 }           from 'three';

export interface ModelServerValue {
    type: string;
    data: { x: number; y: number; z: number };
}

export interface ModelObjectValue {
    type: string;
    data: Vector3 | Euler;
}

export const transformServerValuesToObjects = (
    stats: ModelServerValue[],
    containerSize?: Vector3
) => stats.map((el) => ({
    ...el,
    data:
    // eslint-disable-next-line no-nested-ternary
            el.type === 'Rotation'
                ? new Euler(...Object.values(el.data))
                : el.type === 'Size'
                    ? containerSize || new Vector3()
                    : new Vector3(...Object.values(el.data))
}));

export const transformObjectsToServerValues = (stats: ModelObjectValue[]) => stats.map((el) => ({
    ...el,
    data: { x: el.data.x, y: el.data.y, z: el.data.z }
}));

export const saveShadowOption = (value: boolean, type: 'receive' | 'cast', currentModel?: AtomModel | null) => {
    if (currentModel) {
        if (type === 'receive') {
            // eslint-disable-next-line no-param-reassign
            currentModel.options.scene_atom_disable_receive_shadow = value;
        } else {
            // eslint-disable-next-line no-param-reassign
            currentModel.options.scene_atom_disable_cast_shadow = value;
        }
        // @ts-ignore
        currentModel.saveData();
    } else {
        console.error('Data wasn`t saved');
    }
};
