import React                  from 'react';
import { CollapsedTreeItem }  from "@geenee/geeclient-kit/src/lib/component/scene/components/CollapsedTreeItem";
import { ActionsList }        from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/LayersChevronPanel/Actions";
import { AtomModel }          from '@geenee/shared/index';
import { Icon }               from "@geenee/ui";
import { observer }           from "mobx-react";
import { AssetTitle }         from './LayersChevronPanel/AssetTitle';
import { LayersChevronPanel } from './LayersChevronPanel';

interface Props {
    active?: boolean
    atomModel: AtomModel
    fileName: string
    onClick: () => void
    disabled?: boolean
    title?: string
    animationObjectId?: string
    onReplaceAsset?: (v: {assetId: string, file?: File, url?: string}) => Promise<void>
}

export const ObjectListItem = observer(({
    active = false,
    fileName,
    onClick,
    atomModel,
    disabled,
    title,
    onReplaceAsset
}: Props) => {
    const sceneAnimations =  atomModel.firstObject?.animations;
    if (!atomModel) {
        return null;
    }

    return (
        <LayersChevronPanel
            active={ active }
            title={ title || fileName }
            disabled={ disabled }
            currentModel={ atomModel }
            onTitleClick={ onClick }
            noAnimations={ !sceneAnimations.length }
            initialExpanded={ false }
            onReplaceAsset={ onReplaceAsset }
            offset={ 2 }
        >
            { sceneAnimations.length ? sceneAnimations.map((a, index) => (
                <CollapsedTreeItem
                    id={ `node-item_${ a.uuid }` }
                    key={ a.uuid }
                    isSelected={ false }
                    icon={ <Icon margin="xxs" stroke="fat" color="shade-4" name="media" /> }
                    content={ (
                        <AssetTitle
                            key={ index }
                            value={ a.name }
                            after={ (
                                <ActionsList
                                    currentModel={ atomModel }
                                    index={ index }
                                    enablePlayAnimation
                                />
                            ) }
                        />
                    ) }
                    offset={ 3 }
                />
            )) : null }
        </LayersChevronPanel>
    );
});
