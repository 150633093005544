import React, { ForwardedRef, forwardRef, useMemo } from 'react';
import { DndProvider }                              from 'react-dnd';
import { HTML5Backend }                             from 'react-dnd-html5-backend';
import { MagellanViewerSceneShared, useInject }     from '@geenee/shared';
import LoadingSpinner                               from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { cn, Wrapper }                              from '@geenee/ui';
import { observer }                                 from 'mobx-react';
import { DropWrapper }                              from '~/components/DropWrapper';
import { useBuilderInject }                         from '~/core/hook/use-builder-inject';
import { stripeState }                              from "~/core/state/stripe.state";
import { useSceneAssetsActions }                    from '~/magellan/hooks/useSceneAssetsActions';
import { SceneBottomBar }                           from "~/module/edit-asset-3d-scene/modal/SceneBottomBar";
import { SceneDropPlaceholder }                     from "~/module/edit-asset-3d-scene/modal/SceneDropPlaceholder";
import { SceneHeaderButtons }                       from "~/module/edit-asset-3d-scene/modal/SceneHeader/SceneHeaderButtons";
import './ar-scene.scss';

const className = cn('ar-scene');

const SceneDropWrapper = forwardRef((props: { children: JSX.Element }, ref: ForwardedRef<HTMLDivElement>) => (
    <div className={ className('scene-drop-wrapper') } { ...props } ref={ ref } />

));
const ArScene = observer((props) => {
    const { AppState } = useInject();
    const { activeMolecule, activeProject, firstAvailableSection } = AppState;
    const { BuilderState } = useBuilderInject();
    const disabled = activeProject?.nft_published;
    const assetsOnScene = Array.from(activeMolecule?.sceneActorAtoms.values() || []);

    const accessibleTypes = useMemo(() => {
        if (firstAvailableSection?.type === 'native-ar') {
            return '.glb, .gltf';
        }
        if (stripeState.isActiveTeamOrEnterprise) {
            return ".glb, .png, .jpg, .gif, .mp3, .mp4, .json";
        }
        return ".glb, .png, .jpg, .gif, .mp3, .mp4";
    }, [ firstAvailableSection?.type ]);

    const { onDrop, isLoading: _isLoading, getCustomErrorMessage } = useSceneAssetsActions(accessibleTypes);

    const isLoading = _isLoading || BuilderState.$sceneState.isLoading;

    const customDropErrorMessage = useMemo(() => getCustomErrorMessage(), [ getCustomErrorMessage ]);

    return (
        <div className={ className('scene-wrapper') }>
            { (BuilderState.arSceneBlocked || isLoading) && (
                <div className={ className('scene-overlay') }>
                    <LoadingSpinner />
                </div>
            ) }
            { /* @ts-ignore */ }
            <DndProvider backend={ HTML5Backend }>
                <DropWrapper
                    onDrop={ onDrop }
                    rootContainer={ SceneDropWrapper }
                    disabled={ disabled }
                    fileFormats={ accessibleTypes.split(',') }
                    customErrorMessage={ customDropErrorMessage }
                    overlayComponent={ <SceneDropPlaceholder active formats={ accessibleTypes.split(',') } /> }
                >

                    { /* <SceneContent onHideModal={ props.onHideModal } /> */ }
                    <Wrapper
                        style={ {
                            height:   '100%',
                            width:    '100%',
                            position: 'relative'
                        } }
                        padding="sm"
                    >
                        <SceneHeaderButtons onExit={ () => props.onHideModal() } />

                        { BuilderState.arSceneShown && <MagellanViewerSceneShared /> }

                        { !assetsOnScene.length && <SceneDropPlaceholder active={ isLoading } formats={ accessibleTypes.split(', ') } /> }
                        <SceneBottomBar />

                    </Wrapper>
                </DropWrapper>

            </DndProvider>
        </div>
    );
});

// eslint-disable-next-line arca/no-default-export
export default ArScene;
