import { injectable }      from 'inversify';
import { observable }      from 'mobx';
import { AssetSchemaType } from '../../../type/asset.type';
import { AtomModel }       from '../atom/model/atom.model';
import { BaseEntityModel } from './base-entity.model';

@injectable()
export class AssetModel extends BaseEntityModel implements AssetSchemaType {
  @observable file_size: AssetSchemaType['file_size'] = 0;
  @observable file_name: AssetSchemaType['file_name'] = '';
  @observable file_type: AssetSchemaType['file_type'] = '';
  @observable title: AssetSchemaType['title'] = null;
  @observable url: AssetSchemaType['url'] = '';

  $parent?: AtomModel;
}
