/* eslint-disable jsx-a11y/no-autofocus */
import React, { FC, forwardRef, memo, useMemo, useState } from "react";
import { cn }                                             from "../../util/bem";
import { FormSizesType, SizesUnionType, TextColorType }         from "../../util/global-props";
import { Icon, IconPropsType }                            from "../icon/icon.component";
import { iconMap }                                        from "../icon/icon.library";
import { FileInputComponent }                             from './components/input-file.component';
import "./input.component.scss";

export type InputPropsType = {
    className?: string;
    id?: string;
    value?: string | typeof undefined;
    defaultValue?: string | typeof undefined;
    name?: string;
    placeholder?: string;
    color?: TextColorType;
    // tod: import type
    style?: React.CSSProperties;
    icon?: keyof typeof iconMap;
    iconColor?: IconPropsType["color"];
    iconSize?: IconPropsType["size"];
    after?: React.ReactNode;
    before?: React.ReactNode;
    step?: number;
    type?: "text" | "number" | "integer" | "password" | "email" | 'file';
    withShowPassword?: boolean;
    size?: FormSizesType;
    flex?: number;
    margin?: SizeType;
    min?: number;
    max?: number;
    autocompleteAttr?: string;
    clearable?: boolean;
    autoFocus?: boolean;
    autoComplete?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    transparent?: boolean;
    hideBorder?: boolean;
    readOnly?: boolean;
    shorten?: boolean;
    required?: boolean;
    nativeProps?: React.InputHTMLAttributes<HTMLInputElement>;
    textCentered?: boolean;
    error?: boolean;
    ref?: React.Ref<HTMLInputElement>;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onClick?: React.MouseEventHandler<HTMLInputElement>;
    onClear?: React.MouseEventHandler<HTMLButtonElement>;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
    onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
    onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
}

const className = cn("input");

export const Input: FC<InputPropsType> = memo(forwardRef((props: InputPropsType, ref: React.Ref<HTMLInputElement>) => {
    const [ focus, setFocus ] = useState(false);
    const [ showPassword, setShowPassword ] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.type === "integer") {
            e.target.value = e.target.value.replace(/\D/, "");
        }

        if (props.onChange) {
            props.onChange(e);
        }
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setFocus(true);
        if (props.onFocus) {
            props.onFocus(event);
        }
    };
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setFocus(false);
        if (props.onBlur) {
            props.onBlur(event);
        }
    };

    const type = useMemo(() => {
        if (props.type === "password" && props.withShowPassword) {
            return showPassword ? "text" : "password";
        }
        return props.type;
    }, [ props.withShowPassword, showPassword, props.type, setShowPassword ]);

    const inputProps = useMemo(() => ({
        id:           props.id,
        className:    className("input", { color: props.color }),
        min:          props.type === "number" ? props.min : undefined,
        max:          props.type === "number" ? props.max : undefined,
        defaultValue: props.defaultValue,
        type,
        name:         props.name,
        placeholder:  props.placeholder,
        step:         props.step,
        ref,
        autoFocus:    props.autoFocus,
        disabled:     props.disabled,
        readOnly:     props.readOnly,
        autoComplete: props.autoComplete ? "On" : "Off",
        required:     props.required,
        style:        props.style,
        onClick:      props.onClick,
        onChange:     handleChange,
        onFocus:      handleFocus,
        onBlur:       handleBlur,
        onKeyDown:    props.onKeyDown,
        onKeyPress:   props.onKeyPress,
        onKeyUp:      props.onKeyUp,
        ...props.value ? ({ value: props.value }) : undefined
    }), [ props, type ]);

    return (
        <div
            className={ className({
                size:         props.size,
                fullWidth:    props.fullWidth,
                disabled:     props.disabled,
                transparent:  props.transparent,
                hideBorder:   props.hideBorder,
                margin:       props.margin,
                focus,
                readOnly:     props.readOnly,
                textCentered: props.textCentered,
                error:        props.error,
                shorten:      props.shorten
            }, null, props.className) }
            style={ props.style }
        >
            { props.icon && (
                <Icon name={ props.icon } color={ props.iconColor } size={ props.iconSize } />
            ) }

            { props.before && (
                <span className={ className("before") }>{ props.before }</span>
            ) }
            { /* @ts-ignore */ }
            { props.type === "file"
                ? <FileInputComponent { ...inputProps } /> : <input { ...inputProps } { ...props.nativeProps } /> }

            { props.clearable && (
                <span
                    className={ className("clear", { hidden: !props.value }) }
                    onClick={ props.onClear }
                >
                    <Icon name="close" color={ props.iconColor } />
                </span>
            ) }

            { props.type === 'password' && props.withShowPassword && (
                <span
                    className={ className("show-password") }
                    onClick={ () => setShowPassword(!showPassword) }
                >
                    <Icon name={ showPassword ? "eyeOn" : "eyeOff" } color="shade-2" size={ 24 } />
                </span>
            ) }

            { props.after && (
                <span className={ className("after") } style={ { paddingRight: "4px" } }>{ props.after }</span>
            ) }
        </div>
    );
}));

Input.defaultProps = {
    size:      "lg",
    type:      "text",
    iconColor: "grey",
    color:     "dark"
};
