// @flow
import React, { FC, useMemo }       from 'react';
import { Link as LinkRouter }       from 'react-router-dom';
import { CallbackFunctionVariadic } from "@geenee/geeclient-kit/src/lib/type/type";
import { observer }                 from 'mobx-react';
import { LinkTypesExtensionType }   from '../../type/_atom-option-type';

type PropsType = {
  to: string;
  tel?: string;
  mailTo?: string;
  scrollSectionId?: number;
  modalSectionId?: number;
  animateScroll?: boolean;
  type?: LinkTypesExtensionType;
  blank?: boolean;
  onClick?: CallbackFunctionVariadic;
  children: React.ReactNode;
  [key: string]: any;
};

function isExternalLink(link: string) {
    return /(^(http|https)?:\/\/)|(\.)/i.test(link);
}

export const Link: FC<PropsType> = observer((props: PropsType) => {
    const handleClick = (event: React.SyntheticEvent<HTMLElement>) => {
    // if (process.env.ENV_CON_PACKAGE !== 'client') {
    //   event.preventDefault();
    //   return;
    // }

        if (props.onClick) {
            props.onClick();
            event.preventDefault();
            return;
        }

        switch (props.type) {
            // case 'scroll':
            //     event.preventDefault();
            //     props.modalsStateContext.onClose();
            //     scrollToSection(props.animateScroll, props.scrollSectionId);
            //     break;
            //
            // case 'modal':
            //     event.preventDefault();
            //     if (props.modalSectionId) {
            //         props.modalsStateContext.onOpen(props.modalSectionId);
            //     }
            //     break;
            //
            // case 'close_modal':
            //     event.preventDefault();
            //     props.modalsStateContext.onClose();
            //     break;

            default:
                break;
        }
    };

    switch (props.type) {
        case 'mail_to':
            return <a { ...props } href={ `mailto:${ props.mailTo }` } />;
        case 'tel':
            return (
                <a { ...props } href={ `tel:+${ (props.tel || '').replace(/\D+/g, '') }` } />
            );
        case 'link':
        default:
            if (isExternalLink(props.to)) {
                return (
                    <a
                        { ...props }
                        href={
                            /^(http|https)?:\/\//i.test(props.to)
                                ? props.to
                                : `http://${ props.to }`
                        }
                        target={ props.blank ? '_blank' : undefined }
                        onClick={ handleClick }
                    />
                );
            }
    }
    const linkProps = useMemo(() => {
        const _props = { ...props };
        delete _props.mailTo;
        delete _props.animateScroll;
        delete _props.scrollSectionId;
        delete _props.modalSectionId;
        delete _props.blank;
        return _props;
    }, [ props ]);

    return (
        <LinkRouter
            { ...linkProps }
            to={ props.to }
            target={ props.blank ? '_blank' : undefined }
            onClick={ handleClick }
        />
    );
});

Link.defaultProps = {
    scrollSectionId: 0,
    modalSectionId:  0,
    animateScroll:   true,
    type:            'link'
};
