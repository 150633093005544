import React, {
    FC, memo, useEffect, useMemo, useRef, useState
} from 'react';
import { useFrame }                          from '@react-three/fiber';
import { Clock, DoubleSide, Euler, Vector3 } from 'three';
import GifLoader                             from './gif-loader';

type GIFPlanePropsType = {
  url: string
  width?: number,
  height?: number,
  position: Vector3 | [ number, number, number ] | undefined;
  rotation: Vector3 | Euler | [ number, number, number ] | undefined;
  scale: Vector3 | [ number, number, number ] | undefined;
  loadedCallback: any;
  visible: boolean
};

const GIFPlane: FC<GIFPlanePropsType> = memo(({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    width,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    height,
    url,
    ...props
}) => {
    const gifMeshRef = useRef();

    const [ gifRatio, setGifRatio ] = useState(1);
    const [ gifTexture, setGifTexture ] = useState(null);
    const clock = useMemo(() => new Clock(), []);
    const gifLoader = useMemo(() => new GifLoader(null), []);

    useEffect(() => {
        const texture = gifLoader.load(url || '', (reader) => {
            setGifRatio(reader.height / reader.width);
            props.loadedCallback(true);
            setGifTexture(texture);
        });
    }, [ gifLoader ]);

    const framesStamps = useMemo(() => {
        if (!gifTexture?.reader) {
            return [];
        }

        const _framesStamps: number[] = [];
        let currTimeStamp = 0;
        for (let i = 0; i < gifTexture.reader.numFrames(); i++) {
            currTimeStamp += gifTexture.reader.frameInfo(i).delay;
            _framesStamps.push(currTimeStamp);
        }
        return _framesStamps;
    }, [ gifTexture?.reader ]);

    useFrame(() => {
        if (clock && gifTexture && framesStamps.length) {
            const moduluses = framesStamps?.map((time) => {
                const elapsedTime = +clock.getElapsedTime().toFixed(2) * 100;
                return elapsedTime > time ? elapsedTime - time : time - elapsedTime;
            });
            const minIndex = moduluses.indexOf(Math.min(...moduluses));

            gifTexture.draw(minIndex);

            if (minIndex === framesStamps.length - 1) {
                clock.startTime = 0;
                clock.start();
            }
        }
    });

    return (
        <mesh
            ref={ gifMeshRef }
            position={ props.position }
            rotation={ props.rotation }
            scale={ props.scale }
            dispose={ null }
            castShadow={ false }
            name="geenee-3d-gif-mesh"
            visible={ props.visible }
        >
            { gifTexture && (
                <>
                    <planeGeometry attach="geometry" args={ [ 1, (gifRatio || 1) ] } />
                    <meshBasicMaterial attach="material" color="#ffffff" transparent side={ DoubleSide } depthWrite={ false } map={ gifTexture } />
                </>
            ) }
        </mesh>
    );
});
// eslint-disable-next-line arca/no-default-export
export default GIFPlane;
