import * as TWEEN from '@tweenjs/tween.js';
import {
    AdditiveBlending,
    BufferGeometry,
    Float32BufferAttribute,
    MathUtils,
    Object3D,
    Points,
    PointsMaterial,
    Sprite,
    SpriteMaterial,
    Texture
    // @ts-ignore
} from 'three';

export interface SpriteTextOptions {
  fontsize?: number
  fontface?: string
  borderThickness?: number
  backgroundColor?: {r: number, g: number, b: number, a: number}
  textColor?: {r: number, g: number, b: number, a: number}
  canvasSize?: {width: number, height: number}
}

const NICKNAME_SPRITE_NAME = 'nickname_sprite';

export class EffectsLibrary {
    // If you need dynamic animated particles insert object.particlesSystem.rotation.y += 0.01; in the render loop
    generateSprite(colorStart = 'rgb(123, 0, 255)', colorEnd = 'rgba(50, 0, 64, 0.7)') {
        const canvas = document.createElement('canvas');
        canvas.width = 16;
        canvas.height = 16;

        const context = canvas.getContext('2d');
        if (context) {
            const gradient = context.createRadialGradient(
                canvas.width / 2,
                canvas.height / 2,
                0,
                canvas.width / 2,
                canvas.height / 2,
                canvas.width / 2
            );
            gradient.addColorStop(0, 'rgba(255,255,255,1)');
            gradient.addColorStop(0.2, colorStart);
            gradient.addColorStop(0.4, colorEnd);
            gradient.addColorStop(1, 'rgba(0,0,0,0)');

            context.fillStyle = gradient;
            context.fillRect(0, 0, canvas.width, canvas.height);

            const texture = new Texture(canvas);
            texture.needsUpdate = true;
            return texture;
        }
    }

    createParticlesForObject = ({
        object,
        pointsDistance = 4, particleCount = 10, materialSize = 0.08,
        colorStart, colorEnd
    }: {
        object: Object3D,
        pointsDistance?: number,
        particleCount?: number,
        materialSize?: number,
        colorStart?: string,
        colorEnd?: string
    }) => {
        const geometry = new BufferGeometry();
        const vertices = [];
        // eslint-disable-next-line no-plusplus
        for (let p = 0; p < particleCount; p++) {
            const x = MathUtils.randFloatSpread(pointsDistance);
            const y = MathUtils.randFloatSpread(pointsDistance);
            const z = MathUtils.randFloatSpread(pointsDistance);

            vertices.push(x, y, z);
        }
        geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));
        const material = new PointsMaterial({
            color:    0xFFFFFF,
            size:     materialSize,
            map:      this.generateSprite(colorStart, colorEnd),
            blending: AdditiveBlending
        });
        const points = new Points(geometry, material);
        object.add(points);
        return points;
    };

    // if use one of these animation pls be sure you've inserted this.TWEEN.update into the custom code render loop
    runAppearAnimation = (config: {
        object: Object3D,
        delay: number,
        duration: number
    }) => {
        this.runScaleAnimation(config);
    };

    runDisappearAnimation = ({ object, delay, duration }: { object: Object3D, duration?: number, delay?: number }) => {
        this.runScaleAnimation({
            object,
            delay,
            duration,
            from: { x: object.scale.x, y: object.scale.y, z: object.scale.z },
            to:   { x: 0, y: 0, z: 0 }
        });
    };

    runScaleAnimation = (
        {
            object,
            from = { x: 0, y: 0, z: 0 },
            to = { x: object.scale.x, y: object.scale.y, z: object.scale.z },
            delay = 0,
            duration = 1000
        }: {
            object: Object3D,
            from?: { x: number, y: number, z: number },
            to?: { x: number, y: number, z: number },
            delay?: number,
            duration?: number
        }
    ) => {
        const scale = { ...from };
        const tween = new TWEEN.Tween(scale).to(to, duration);

        object.scale.x = 0;
        object.scale.y = 0;
        object.scale.z = 0;

        tween.onUpdate(() => {
            object.scale.x = scale.x;
            object.scale.y = scale.y;
            object.scale.z = scale.z;
        });

        setTimeout(() => {
            tween.start();
        }, delay);
    };

    createSpriteText = async (message: string, parameters: SpriteTextOptions = {}) => {
        if (parameters === undefined) parameters = {};
        // eslint-disable-next-line no-prototype-builtins
        const fontface = 'Euclid Circular A';
        const font = new FontFace(fontface, 'url(\'/fonts/EuclidCircularA-Bold.woff2\') format(\'woff2\')');

        return font.load().then((font) => {
            document.fonts.add(font);
            const fontsize = parameters.hasOwnProperty('fontsize') ? parameters.fontsize : 30;
            // eslint-disable-next-line no-prototype-builtins
            const borderThickness = parameters.hasOwnProperty('borderThickness') ? parameters.borderThickness : 0;
            // eslint-disable-next-line no-prototype-builtins
            const backgroundColor = (parameters.hasOwnProperty('backgroundColor') && parameters.backgroundColor) || { r: 255, g: 255, b: 255, a: 1.0 };
            // eslint-disable-next-line no-prototype-builtins
            const textColor = (parameters.hasOwnProperty('textColor') && parameters.textColor) || { r: 255, g: 255, b: 255, a: 1.0 };

            const canvas = document.createElement('canvas');
            canvas.width = parameters?.canvasSize?.width || 200;
            canvas.height = parameters?.canvasSize?.height || 70;
            const context = canvas.getContext('2d') as CanvasRenderingContext2D;
            context.font = `${ fontsize }px ${ fontface }`;
            const metrics = context.measureText(message);
            const textWidth = metrics.width;

            context.fillStyle = `rgba(${ backgroundColor.r },${ backgroundColor.g },${ backgroundColor.b },${ backgroundColor.a })`;
            // context.fillRect(0, 0, canvas.width, canvas.height);
            context.lineWidth = borderThickness || 0;

            context.fillStyle = `rgba(${ textColor.r }, ${ textColor.g }, ${ textColor.b }, 1.0)`;
            context.textAlign = 'left';
            context.fillText(message, canvas.width / 2 - (textWidth / 2), 50);

            const texture = new Texture(canvas);
            texture.needsUpdate = true;

            const spriteMaterial = new SpriteMaterial({ map: texture, useScreenCoordinates: false });
            const sprite = new Sprite(spriteMaterial);
            sprite.scale.set(0.003 * canvas.width, 0.0025 * canvas.height);
            sprite.name = NICKNAME_SPRITE_NAME;
            return sprite;
        });
    };

    addNicknameToObject = async (nickname: string, object: Object3D, textOptions: SpriteTextOptions) => {
        const nicknameSprite = object.getObjectByName(NICKNAME_SPRITE_NAME);
        if (nicknameSprite) {
            nicknameSprite.removeFromParent();
        }
        const sprite = await this.createSpriteText(nickname, textOptions);
        sprite.position.y = 1.05;
        object.nicknameSprite = sprite;
        object.add(object.nicknameSprite);
    };
}
