import React, { useMemo, useState } from 'react';
import {
    Column, DataType, Description, Divider,
    Wrapper
} from '@geenee/ui';
import { sdkTokenState }   from "@geenee/builder/src/core/state/sdkToken.state";
import { SDKTableActions } from "@geenee/builder/src/pages/SDKAccessPage/components/SDKTableActions";
import { useInitState }    from "@geenee/builder/src/pages/SDKAccessPage/components/useInitState";

export const encodeKey = (key: string) => {
    if (!key) {
        return;
    }
    const firstPart = key.substring(0, 8);
    const secondPart = key.substring(8, key.length - 8).replace(/./g, '•');
    const thirdPart = key.substring(key.length - 8);
    return firstPart + secondPart + thirdPart;
};

export const useTableData = () => {
    const { tokens, isLoaded } = sdkTokenState;
    const [ openEyes, setOpenEyes ] = useState<Set<string>>(new Set());
    const { onRemove, onUpdate } = useInitState();

    const dividerRow = useMemo(() => ({
        origin:  <Divider color='grey-2' />,
        key:     <Divider color='grey-2' />,
        actions: <Divider color='grey-2' />
    }), []);

    const getTableCells = () => {
        const cells: Array<{ [key: string]: React.ReactNode }> = [];
        Array.from(tokens).forEach((cellData, index, array) => {
            cells.push({
                origin: (
                    <Wrapper key={ cellData.key } valign='center' style={ { height: '56px' } }>
                        <Description style={ { width: 'calc(100% - 16px)', height: '20px' } } shorten>{ `${ cellData.origin }` }</Description>
                    </Wrapper>
                ),
                key: (
                    <Wrapper key={ cellData.key } valign='center' style={ { height: '56px' } }>
                        <Description weight="bold">{ openEyes.has(cellData.key) ? cellData.key : encodeKey(cellData.key) }</Description>
                    </Wrapper>
                ),
                actions: (
                    <SDKTableActions
                        key={ cellData.key }
                        onShow={ (show: boolean) => {
                            setOpenEyes((prev) => {
                                if (show) {
                                    prev.add(cellData.key);
                                } else {
                                    prev.delete(cellData.key);
                                }

                                return new Set(prev);
                            });
                        } }
                        onUpdate={ () => onUpdate(cellData) }
                        onRemove={ () => onRemove(cellData) }
                        onCopy={ () => navigator.clipboard.writeText(cellData.key).catch((e) => console.log(e)) }
                    />
                )
            });
            if (index + 1 !== array.length) {
                cells.push(dividerRow);
            }
        });

        return cells;
    };

    const columns: Column<DataType>[] = useMemo(() => [
        {
            accessor: 'origin',
            minWidth: 240,
            Header:   () => <Description uppercase color="grey-9" weight='bold'>Host URL</Description>,
            Cell:     ({ cell: { value } }) => <Wrapper key={ value }>{ value }</Wrapper>
        },
        {
            accessor: 'key',
            minWidth: 344,
            Header:   () => <Description uppercase color="grey-9" weight='bold'>Access Token</Description>,
            Cell:     ({ cell: { value } }) => <Wrapper key={ value }>{ value }</Wrapper>
        },
        {
            accessor: 'actions',
            minWidth: 216,
            Header:   () => <Description style={ { width: '100%' } } uppercase color="grey-9" align="right" weight='bold'>Actions</Description>,
            Cell:     ({ cell: { value } }) => <Wrapper key={ value }>{ value }</Wrapper>
        }
    ], []);

    const data = useMemo(() => getTableCells(), [ tokens, openEyes ]);

    return [ data, columns, isLoaded ];
};
