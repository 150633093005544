import React                             from 'react';
import Toggle                            from '@geenee/builder/src/components/Toggle';
import { ToggleContainer, UpperBarArea } from '@geenee/builder/src/pages/AllPlansPage/styles';

interface Props {
  yearlyCycle: boolean;
  onChangeBillingCycle: () => void;
  showPaymentModal: (priceId: string) => void;
}

const billingCycles = [ 'Monthly', 'Yearly' ];

export function UpperBar({ yearlyCycle, onChangeBillingCycle, showPaymentModal }: Props) {
    return (
        <UpperBarArea>
            <div />
            <ToggleContainer>
                <Toggle
                    options={ billingCycles }
                    onToggle={ onChangeBillingCycle }
                    preToggle={ yearlyCycle }
                />
            </ToggleContainer>
        </UpperBarArea>
    );
}
