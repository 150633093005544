import envConfig from "./envConfig";
// APIRequest
// export const API_URL = 'https://eu-central-1-staging-monolith.geenee.io';
export const { API_URL } = envConfig;
export const APIREQUEST_AUTH_SUFFIX = "-auth";
export const APIREQUEST_HOSTNAME_MATCH_SLUG = `${ API_URL }${ APIREQUEST_AUTH_SUFFIX }`;
export const MARKER_AR_INSTRUCTIONS = "https://docs.google.com/document/d/1c0HBg0c9QP7OtaLx_J0f2q3L6VKlhUH_dMQonfJy5xQ/edit?pli=1";
export const NOTION_SUPPORT_URL = "https://geeneeinc.notion.site/geeneeinc/Welcome-4458bce66ea441438aad43c3919c9af0";
export const TERMS_CONDITION_LINK = 'https://geenee.ar/terms-and-conditions/';
/**
 * Common constants
 */
export const AUTHORIZATION = "authorization";
export const GRINGOTTS_AUTH = "gringotts_auth";
export const DELETE = "DELETE";
export const NEW_OWNER = "NEW OWNER";
export const DELETE_ACCOUNT = "DELETE ACCOUNT";
export const NEW = "new";
export const PROJECT_TYPES = [ "project", "program", "experience" ];

export const TREE_NODE_TYPES = [ "program", "experience", "content" ];

export const UPLOADER_ALLOWED_IMAGE_EXTENSIONS = ".gif, .jpeg, .png, .jpg, .GIF, .JPEG, .PNG, .JPG";

export const CONTENT_IMAGE_GALLERY_PROPERTY_NAME = "image_attachment_ids";
export const CONTENT_AR_PROPERTY_NAME = "ar_attachment_ids";
// eslint-disable-next-line max-len
export const VALID_EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const DEBOUNCE_DELAY_TIME_FOR_INPUT = 300;

export const WIDGET_TYPE_IMAGE = "image";
export const WIDGET_TYPE_VIDEO = "video";
export const WIDGET_TYPE_TEXT = "text";
export const WIDGET_TYPE_BUTTON = "button";
export const WIDGET_TYPE_FORM = "form";
export const WIDGET_TYPE_SPOTIFY = "spotify";
export const WIDGET_TYPE_FANDANGO = "fandango";

export const PROGRAM_ALIAS_IN_TEXT = "project";

export const NODE_TYPE_PROJECT = "project";
export const NODE_TYPE_PROGRAM = "program";
export const NODE_TYPE_VIEW = "view";
export const NODE_TYPE_EXPERIENCE = "experience";
export const NODE_TYPE_CONTENT = "content";
export const NODE_TYPE_TRIGGERS = "triggers";
export const NODE_TYPE_CONTENT_GALLERY = "content_gallery";
export const NODE_TYPE_CONTENT_AR = "content_ar";
export const NODE_TYPE_DASHBOARD = "dashboard";
export const NODE_TYPE_PROFILE = "profile";
export const NODE_TYPE_COMPANY = "company";
export const NODE_TYPE_PLANS = "plans";
export const NODE_TYPE_CONTACT = "contact";
export const NODE_TYPE_TEAM = "team";
export const NODE_TYPE_TEAM_MEMBER = "team_member";

export const NODE_TYPE_FOLDER = "folder";

export const NODE_TYPE_IMAGE_GALLERY_ASSETs = "image_gallery_assets";
export const NODE_TYPE_AR_ASSET = "ar_asset";

/**
 * Content types
 */
export const TYPE_DRAWER_OVERLAY = "overlay";
export const TYPE_DRAWER_SHARE = "drawer_share";
export const TYPE_DRAWER_SIGNUP = "drawer_signup";
export const TYPE_DRAWER_SPOTIFY = "drawer_spotify";
export const TYPE_DRAWER_FANDANGO = "drawer_fandango";
export const TYPE_FULLSCREEN_GALLERY = "fullscreen-gallery";
export const TYPE_FULLSCREEN_VIDEO = "fullscreen-video";
export const TYPE_IFRAME = "iframe";
export const TYPE_SLAM_AR = "slam-ar";
export const TYPE_NATIVE_AR = "native-ar";
export const TYPE_STAMP_AR = "stamp-ar";
export const TYPE_HEAD_TRACKING = "head-tracking";
export const TYPE_BODY_TRACKING_OVERLAY = "body-tracking-overlay";
export const TYPE_BODY_TRACKING_TWIN = "body-tracking-twin";
export const TYPE_SCENE_BUILD = "scene-build";
export const TYPE_WYSIWYG = "wysiwyg";
export const SCENE_TYPES = [ TYPE_SLAM_AR, TYPE_STAMP_AR, TYPE_HEAD_TRACKING, TYPE_BODY_TRACKING_OVERLAY, TYPE_BODY_TRACKING_TWIN, TYPE_NATIVE_AR ];
export const HIDE_AR_SCENES_SWITCHER_TYPES = [ TYPE_SCENE_BUILD, TYPE_NATIVE_AR ];

export const FLAT_CONTENT_TYPES = [ TYPE_FULLSCREEN_VIDEO, TYPE_FULLSCREEN_GALLERY, TYPE_IFRAME ];
// Content related wizard states
export const WIZARD_VIEW_CONTENT_GALLERY = "content_gallery";
export const WIZARD_VIEW_CONTENT_MAIN = "content_settings";
export const WIZARD_VIEW_CONTENT_SET_AR_ASSET = "content_set_ar_asset";
export const WIZARD_VIEW_CONTENT_SET_IMAGE_GALLERY_ASSET = "content_set_image_gallery_asset";
export const NEW_EXPERIENCE_ID = "new_experience";
export const NEW_CONTENT_ID = "new_content";
export const NEW_PROGRAM_ID = "new_program";
/**
 * Drag and Drop item types
 */
export const DND_BLOCK = "dnd_block";
export const DND_TOOLBAR_ITEM = "dnd_toolbar_item";
export const REORDER_ITEM = "reorder-item";

// Toast Severity
export const TOAST_ERROR = "error";
export const TOAST_INFO = "info";
export const TOAST_SUCCESS = "success";

// Toast Message
export const MEMBER_CREATE_SUCCESS = "Member created successfully";
export const MEMBER_DELETE_SUCCESS = "Member deleted successfully";
export const MEMBER_UPDATE_SUCCESS = "Member updated successfully";
export const MEMBER_CREATE_FAILURE = "Error while creating a Member";
export const OWNER_RIGHTS_PASSES_SUCCESS = "Rights have been passed successfully";
export const PASSWORD_UPDATE_SUCCESS = "Password updated successfully";
export const COMPANY_UPDATE_SUCCESS = "Company updated successfully";
export const LINK_COPIED_SUCCESS = "Link copied to clipboard";
export const ACCOUNT_DELETE_SUCCESS = "Account deleted successfully";
export const HIERARCHY_CREATE_SUCCESS = "Created successfully";
export const PROJECT_PUBLISH_SUCCESS = "Published successfully";
export const PROJECT_UNPUBLISH_SUCCESS = "Unpublished successfully";

export const ATTACHMENT_PROPERTYNAMES_UPLOAD_UPDATE_OK = [
    CONTENT_IMAGE_GALLERY_PROPERTY_NAME
];

// Shared content S3 bucket root url
export const SHARED_CONTENT_S3_BUCKET_PATH = "https://cms-content-files.s3.amazonaws.com";

// URL used as a main domain on project publishing
export const { PUBLISHING_DOMAIN_URL } = envConfig;

export const OWNER_ROLE_ID = 4;
export const EDITOR_ROLE_ID = 5;

export const LOAD_IMAGE_WITH_CORS = "LOAD_IMAGE_WITH_CORS";

// Spotify
export const SPOTIFY_CLIENT_ID = "a1455ae30a1c48c6acc9c8e4d8bbf8a0";
// eslint-disable-next-line max-len,no-nested-ternary
export const SPOTIFY_REDIRECT_URL = process.env.ENV_NODE_ENV === "development" ? "https://localhost:1234/spotify" : typeof window !== undefined ? `${ window.location.origin }/spotify/` : "https://builder.geenee.ar/spotify/";
export const SPOTIFY_SCOPE = "user-read-email";
export const SPOTIFY_PAGE_LIMIT = 50;

export const EXPERIENCE_MAX_SIZE_MB = 40;

export const QR_CODE_SIZE = 173;

export const SUPPORT_FORM_ATTACHMENT_FORMATS = [ "png", "jpg", "gif", "glb", "gltf" ] as const;
export const MODELS_FORMATS = [ ".glb" ];
export const IMG_FORMATS = [ "gif", "jpeg", "png", "jpg" ];
export const AVAILABLE_DROP_FORMATS = [ ...MODELS_FORMATS, "mp4", ...IMG_FORMATS ] as const;

export type AttachmentFormatTypeUnion = typeof SUPPORT_FORM_ATTACHMENT_FORMATS[number]
    | typeof AVAILABLE_DROP_FORMATS[number]
    | typeof IMG_FORMATS[number];
export type AttachmentFormatType<T extends AttachmentFormatTypeUnion> = T;

export const CLOSE_SIZE_EXCEED_ID = "close-size-exceed";
export const SHARED_AUDIO_ICON_URL = "https://cms-content-files.s3.amazonaws.com/audio.png";

export const AR_CONTENT_TYPES = [ TYPE_SLAM_AR, TYPE_STAMP_AR, TYPE_NATIVE_AR ];
export const CAROUSEL_CONTENT_TYPES = [ TYPE_SLAM_AR, TYPE_STAMP_AR, TYPE_BODY_TRACKING_OVERLAY, TYPE_BODY_TRACKING_TWIN, TYPE_HEAD_TRACKING ];

export const UPDATE_BTN_COUNTER_KEY = "update_btn_click_count";

// Analytics
export const PROGRAM_CREATED = "builder:program_created";
export const PROGRAM_DELETED = "builder:program_deleted";
export const PROGRAM_RENAMED = "builder:program_renamed";
export const PROGRAM_PUBLISHED = "builder:program_published";
export const PROGRAM_REPUBLISHED = "builder:program_republished";
export const PROGRAM_UNPUBLISHED = "builder:program_unpublished";
export const PROGRAM_HEADER_COLOR_CHANGED = "builder:program_headerColorChanged";
export const PROGRAM_INTRO_BACKGROUND_COLOR_CHANGED = "builder:program_introBackgroundColorChanged";
export const PROGRAM_CUSTOM_CODE_UPDATED = "builder:program_customCodeUpdated";
export const EXPERIENCE_CREATED = "builder:experience_created";
export const EXPERIENCE_DELETED = "builder:experience_deleted";
export const EXPERIENCE_RENAMED = "builder:experience_renamed";
export const EXPERIENCE_INTRO_TOGGLED = "builder:experience_introToggled";
export const VIEW_CREATED = "builder:view_created";
export const VIEW_DELETED = "builder:view_deleted";
export const VIEW_RENAMED = "builder:view_renamed";
export const SECTION_CREATED = "builder:section_created";
export const SECTION_DELETED = "builder:section_deleted";
export const SECTION_RENAMED = "builder:section_renamed";
export const SECTION_VISIBLE_IN_MENU_TOGGLED = "builder:section_visibleInMenuToggled";
export const MOLECULE_CREATED = "builder:molecule_created";
export const MOLECULE_DELETED = "builder:molecule_deleted";
export const MOLECULE_RENAMED = "builder:molecule_renamed";
export const ATOM_CREATED = "builder:atom_created";
export const ATOM_DELETED = "builder:atom_deleted";
export const INVALID_FILE_FORMAT = "builder:atom_formatInvalid";
export const MODEL_DROPPED = "MODEL_DROPPED";
export const ACCOUNT_SETUPED = "builder:account_setuped";
export const TEAM_MEMBER_INVITED = "builder:account_teamMemberInvited";
export const DEFAULT_CARD_CHANGED = "builder:account_paymentCardChanged";
export const CARD_DELETED = "builder:account_paymentCardDeleted";
export const CARD_CREATED = "builder:account_paymentCardCreated";
export const SUBSCRIPTION_BOUGHT = "builder:account_subscriptionBought";
export const SUBSCRIPTION_CANCELED = "builder:account_subscriptionCanceled";
export const VIEW_PLAN_PAGE_CLICKED = "builder:user_viewPlanPageClicked";
export const MONTHLY_YEARLY_TOGGLE_TOGGLED = "builder:user_monthlyYearlyToggleToggled";
export const CONTACT_US_FROM_PLANS_PAGE_CLICKED = "builder:user_contactUsFromPlansPageClicked";
export const CONTACT_US_SUBMIT = "builder:user_contactUsSubmit";
export const SDK_TOKEN_CREATED = "builder:user_sdkTokenCreated";
export const SDK_TOKEN_DELETED = "builder:user_sdkTokenDeleted";
export const SDK_TOKEN_UPDATED = "builder:user_sdkTokenUpdated";
export const USER_LOGIN = "builder:user_login";
export const USER_LOGOUT = "builder:user_logout";
export const PREVIEW_URL_GENERATED = "builder:program_previewUrlGenerated";
export const PUBLISH_URL_GENERATED = "builder:program_publishUrlGenerated";
export const REQUEST_BUG_OPEN = "builder:user_reportBugButtonClicked";

export const ACCOUNT_DELETION_REASON_SENT = "builder:account_deleteionResasonSent";
export const TOOLTIP_DELAY = 1500;

// Error messages
export const ERROR_PASSWORD_LENGTH = 'Your password has to be at least 8 characters';
export const ERROR_PASSWORD_MATCH = 'Passwords do not match';
