import { PopoverSchema }                            from '@geenee/ui';
import EventEmitter                                 from 'eventemitter3';
import { injectable }                               from "inversify";
import { action, makeObservable, observable, toJS } from "mobx";

@injectable()
export class PopoverModel implements PopoverSchema {
    constructor() {
        makeObservable(this);
    }
  @observable insides: PopoverModel[] = [];
  @observable items = [];
  @observable isOpen = true;
  @observable id = '';
  eventEmitter = new EventEmitter();
  @action
  update(props: Partial<this>) {
      Object.keys(props).forEach((key) => {
          this[ key ] = props[ key ];
      });
  }

  toJSON(): PopoverSchema {
      const popoverModelJSON = toJS(this);
      return {
          id:      popoverModelJSON.id,
          items:   popoverModelJSON.items,
          insides: popoverModelJSON.insides,
          meta:    {}
      };
  }
}

export type PopoverModelType = PopoverModel;
