import React, { FC, memo, useMemo }        from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { v4 as uuid }                      from 'uuid';

type TransitionType = {
    children: React.ReactNode;
    manual?: boolean;
    className?: string;
}

export const AnimatedWrapperTransition: FC<TransitionType> = memo((props) => {
    const isManualMode = useMemo(() => typeof props.manual !== 'undefined', [ props.manual ]);

    const key = useMemo(() => (isManualMode ? '__manual' : uuid()), [ props.children, isManualMode ]);
    const DynamicWrapper = useMemo(() => (isManualMode ? React.Fragment : SwitchTransition), [ isManualMode ]);

    return (
        <DynamicWrapper>
            <CSSTransition
                key={ key }
                timeout={ 200 }
                in={ props.manual }
                classNames={ props.className }
            >
                <>
                    {
                    // @ts-ignore
                        props.children
                    }

                </>
            </CSSTransition>
        </DynamicWrapper>
    );
});
