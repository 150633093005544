import { Wrapper } from '@geenee/ui';
import styled      from 'styled-components';

export const Label = styled.span`
  font-family: 'Euclid Circular A', sans-serif;
  font-weight: 600;
  font-size: 12px;
`;

export const AddSectionPanel = styled(Wrapper)<{isMobile: boolean, isNotOneItem: boolean, opened: boolean, maxWidthSize: number }>`
  margin-left: 3px;
  max-width: ${ ({ maxWidthSize }) => `${ maxWidthSize }px` };
  width: ${ ({ isMobile }) => (isMobile ? '98.6% !important' : '210% !important') };
  position: absolute;
  top: 64px;
  left: -3px;
  z-index: 1;
  border-radius: 10px;
  background: linear-gradient(0, rgba(209, 236, 250, 0.87) 5.05%, #B9C5DB 16.13%, #C0B6D2 27.96%, #ECC5CD 46.16%, #EFCBB6 64.04%, #F0E9C6 80.73%, #C1DBC4 98.64%);
  overflow: hidden;
  padding-bottom: 10px;
  flex-wrap: wrap;
  visibility: ${ ({ opened }) => (opened ? 'visible' : 'hidden') };
  opacity: ${ ({ opened }) => (opened ? 1 : 0) };
  transition: visibility 0.25s, opacity 0.25s;

  ${ ({ isNotOneItem }) => (isNotOneItem ? 'min-width: 400px;' : '') };

  @media only screen and (max-width: 1510px) and (min-width: 1200px){
    max-width: ${ ({ maxWidthSize }) => (maxWidthSize > 400 ? '400px' : `${ maxWidthSize }px`) };;
  }

  @media only screen and (max-width: 1120px){
    max-width: ${ ({ maxWidthSize }) => (maxWidthSize > 400 ? '400px' : `${ maxWidthSize }px`) };;
  }

  &:before {
    content: '';
    background: rgba(255, 255, 255, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
