import React                                from 'react';
import { InputTableItem, Tooltip, Wrapper } from '@geenee/ui';
import { observer }                         from 'mobx-react';
import { ColorPicker }                      from '@geenee/builder/src/components/ColorPicker/ColorPicker';
import { AtomModel }                        from '@geenee/builder/src/magellan/model/atom.model';

interface Props {
    disabled: boolean
    buttonAtom: AtomModel
}

export const ButtonColors = observer(({ disabled, buttonAtom }: Props) => (
    <InputTableItem label='' style={ { marginBottom: 37 } }>
        <Wrapper row>
            <Wrapper margin="sm" maxWidth="fit-content">
                <Tooltip
                    title="Outline Color"
                    message="Change the stroke color of your button"
                    menuWidth="135px"
                    position="top"
                    shadow
                    messageAlign="center"
                >
                    <ColorPicker
                        disabled={ disabled }
                        color={ buttonAtom.options.atom_button_border_color || '#000' }
                        onColorChange={ (value) => {
                            buttonAtom.options.atom_button_border_color = value;
                            buttonAtom.saveData();
                        } }
                    />
                </Tooltip>
            </Wrapper>
            <Wrapper>
                <Tooltip
                    title="Fill Color"
                    message="Change the fill color of your button"
                    menuWidth="135px"
                    position="top"
                    shadow
                    messageAlign="center"
                >
                    <ColorPicker
                        disabled={ disabled }
                        color={ buttonAtom.options.atom_button_background_color || '#000' }
                        onColorChange={ (value) => {
                            buttonAtom.options.atom_button_background_color = value;
                            buttonAtom.saveData();
                        } }
                    />
                </Tooltip>
            </Wrapper>
        </Wrapper>
    </InputTableItem>
));
