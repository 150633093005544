import styled from 'styled-components';

export const SongRow = styled.div`
    padding-left: 15px;
    border-bottom: 1px solid #ffffff;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
`;

export const SongContainer = styled.div`
    padding: 10px 0;
`;

export const SongAlbumWrapper = styled.div`
    img {
        max-width: 80px;
        max-height: 80px;
    }
`;

export const ButtonWrapper = styled.div`
    cursor: pointer;

    svg {
        cursor: pointer;

        &:hover #hover-rollover,
        &:hover #add-item {
            fill: ${ ({ theme: { secondaryColors } }) => secondaryColors.grey60 };
        }
    }
`;
