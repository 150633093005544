import 'swiper/css/bundle';
import React, { useEffect, useState }       from 'react';
import { useParams }                        from "react-router-dom";
import { useHistory }                       from "@geenee/common";
import { EnterExperienceButton }            from "@geenee/geeclient-kit/src/lib/component/project/EnterExperienceButton";
import { DIContextType }                    from "@geenee/geeclient-kit/src/lib/context/di.context";
import { withDIContext }                    from "@geenee/geeclient-kit/src/lib/hoc/with-di-context";
import { RouteParams, useInject, ViewType } from '@geenee/shared';
import { NEW_EXPERIENCE_ID }                from '@geenee/shared/src/util/constants';
import { Icon, Wrapper }                    from '@geenee/ui';
import SwiperCore, { Navigation }           from 'swiper';
import { ExperiencesSwiperWrapper }         from './ExperiencesSwiperWrapper';
import { RteContent }                       from "./rteContent";
import { CampaignWrapper, IntroBoxStyled }  from './styles';

type ExperienceCarouselProps = {
    experiences: {
        id: string,
        title: string,
        onClick: any,
        splash_screen_message?: string
        options: ViewType['options']
        cover_image: { url: string }
    }[]
}

SwiperCore.use([ Navigation ]);

export const ExperiencesCarousel = withDIContext(({ experiences, diContext }: ExperienceCarouselProps & {diContext: DIContextType}) => {
    const history = useHistory();
    const { AppState } = useInject();
    const { projectId } = useParams<RouteParams>();
    const [ page, setPage ] = useState(0);
    const { IntroBox } = diContext;
    useEffect(() => {
        AppState.selectedCarouselCampaignId = experiences[ 0 ]?.id;
    }, []);

    const items = experiences.filter((item) => !item.options.hidden_in_tree).map(
        ({ onClick, id, cover_image, ...experience }, index) => {
            const isNewExperience = id === NEW_EXPERIENCE_ID;
            const isFocusedExperience = index === page;
            // @ts-ignore
            const isBuilder = process.env.ENV_GEENEE_APP === 'BUILDER';

            return (
                <Wrapper
                    key={ id }
                    align="center"
                    style={ {
                        position: 'absolute',
                        top:      '0',
                        height:   '100%',
                        padding:  '50px 0 65px'
                    } }
                >
                    <CampaignWrapper
                        active={ isFocusedExperience }
                        isNewCampaign
                        src={ cover_image?.url || '' }
                        onClick={ isFocusedExperience ? onClick : undefined }
                        hasHoverBorder={ !!cover_image?.url && isBuilder }
                    >
                        { isNewExperience && (
                            <Wrapper align="center">
                                { /* { isFocusedExperience ? ( */ }
                                <Icon stroke="normal" name="arrowCircle" color="shade-3" size={ 65 } />
                                { /* ) : (
                                // TODO: check icon
                                    <Icon name="arrowCircle" color="shade-3" size={ 25 } />
                                ) } */ }
                                <span>ADD EXPERIENCE</span>
                            </Wrapper>
                        ) }
                    </CampaignWrapper>
                    { isFocusedExperience && (
                        <>
                            { isBuilder ? (
                                <IntroBoxStyled onClick={ () => {
                                    history.push(`/${ projectId }/${ id }`);
                                } }
                                >
                                    <RteContent
                                        htmlContent={
                                            experience.splash_screen_message || ''
                                        }
                                    />
                                </IntroBoxStyled>
                            ) : (
                                <IntroBox
                                    splash_screen_message={ experience.splash_screen_message }
                                />
                            ) }
                            { !isNewExperience && (
                                <EnterExperienceButton onClick={ onClick } />
                            ) }
                        </>
                    ) }
                </Wrapper>
            );
        }
    );

    return (
        <ExperiencesSwiperWrapper
            onSlideChange={ (swiper) => {
                setPage(swiper.activeIndex);
                AppState.selectedCarouselCampaignId = experiences[ swiper.activeIndex ]?.id;
            } }
        >
            { items.map((item) => item) }
        </ExperiencesSwiperWrapper>
    );
});
