import React, { FC, memo } from 'react';
import { Range }           from 'react-range';
import { cn }              from '../../util/bem';
import { SizesUnionType }  from '../../util/global-props';
import { Icon }            from "../icon/icon.component";
import { Tooltip }         from "../tooltip/tooltip.component";
import { Wrapper }         from '../wrapper/wrapper.component';
import './range-slider.component.scss';

export type RangeSliderPropsType = {
    className?: string;

    step?: number;
    min?: number;
    max: number;
    values: number[];

    margin?: SizesUnionType;
    flex?: number | string;
    style?: React.CSSProperties;

    tooltip?: boolean;
    tooltipBefore?: React.ReactNode;
    tooltipAfter?: React.ReactNode;

    toFixed?: number | undefined;
    onChange?: (values: number[]) => void;
    onFinalChange?: (values: number[]) => void;

}

// https://www.npmjs.com/package/react-range

const className = cn('range-slider');

export const RangeSlider: FC<RangeSliderPropsType> = memo((props) => {
    const renderTrack = (p: any) => (
        <div
            className={ className({}, null, props.className) }
            onMouseDown={ p.props.onMouseDown }
            onTouchStart={ p.props.onTouchStart }
            style={ {
                ...p.props.style,
                ...props.style
            } }
        >
            <div
                className={ className('track') }
                ref={ p.props.ref }
            >
                { p.children }
            </div>

        </div>
    );
    const renderThumb = (p: any) => (
        <div
            key="render-thumb"
            { ...p.props }
        >
            { /*  <Tooltip
                shadow
                position="top"
                disabled={ !props.tooltip && !props.tooltipAfter && !props.tooltipBefore }
                minWidth="60px"
                message={ (
                    <>
                        { props.tooltipBefore && `${ props.tooltipBefore } ` }
                        { props.toFixed !== undefined ? props.values[ p.index ].toFixed(props.toFixed)
                            : props.values[ p.index ] }
                        { props.tooltipAfter && ` ${ props.tooltipAfter }` }
                    </>
                ) }
            > */ }
            <Wrapper shadow="md" frame="solid-white" radius="sm">
                <Icon name="cursor" size={ 16 } />
            </Wrapper>
            { /* </Tooltip> */ }
        </div>
    );

    return (
        <Wrapper
            className={ className('root', null, props.className) }
            valign="center"
            row
            radius="lg"
            frame="solid-opacity-white"
            padding="xs"
            margin={ props.margin }
            flex={ props.flex }
        >
            { /* @ts-ignore */ }
            <Range
                onChange={ (values) => props.onChange && props.onChange(values) }
                onFinalChange={ (values) => props.onFinalChange && props.onFinalChange(values) }
                values={ props.values }
                step={ props.step }
                min={ props.min }
                max={ props.max }
                renderThumb={ (p) => renderThumb(p) }
                renderTrack={ (p) => renderTrack(p) }
            />
        </Wrapper>

    );
});
RangeSlider.defaultProps = {
    step: 1,
    min:  0
};
