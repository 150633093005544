import React, { useState } from "react";
import { useParams }       from "react-router-dom";
import GeeneeLogo          from "@geenee/assets/lib/icons/resources/GeeneeLogoGradient";
import backgroundSrc       from "@geenee/assets/lib/images/bg-frame4.png";
import { API_URL }         from "@geenee/builder/src/lib/constants";
import { useHistory }      from "@geenee/common";
import { useMobxForm }     from '@geenee/shared/src/magellan/hooks/use-mobx-form';
import {
    Button,
    Description,
    Form,
    FormError,
    FormItem,
    Input,
    Link,
    Title,
    Wrapper
} from '@geenee/ui';
import { observer } from 'mobx-react';

export const InvitePage = observer(() => {
    const history = useHistory();
    const { token } = useParams<{ id: string; token: string }>();
    const [ activated, setActivated ] = useState(false);

    const formModel = useMobxForm('<InviteUser>');
    const { form: { formState: { errors } } } = formModel;

    const submitHandler = async (event) => {
        try {
            await formModel.submit(event, { token });
            setActivated(true);

            setTimeout(() => {
                history.push("/login");
            }, 5000);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Wrapper fullHeight align="center" valign="center" backgroundImage={ backgroundSrc }>
            <Wrapper align="center" margin="md">
                <GeeneeLogo width="192" height="32" />
            </Wrapper>
            <Wrapper
                maxWidth="352px"
                frame="solid-opacity-white"
                padding="xl"
                radius="xxxl"
                margin="md"
                shadow="translucency-md"
            >
                <Wrapper align="center" valign="center">
                    { !activated ? (
                        <Wrapper>
                            <Wrapper margin="md" align="center">
                                <Title size="sm" weight="medium" styles={ { lineHeight: 1.5 } }>
                                    Complete Sign Up
                                </Title>
                            </Wrapper>
                            <Wrapper align="center" valign="center">
                                <Button
                                    margin="sm"
                                    size="lg"
                                    radius="xxl"
                                    icon="google"
                                    iconSize={ 24 }
                                    fullWidth
                                    viewType="secondary"
                                    onClick={ () => {
                                        window.open(
                                            `${ API_URL }/api/v0/public/oauth/google/?scope=email%20profile&state=${ token }`,
                                            "_self"
                                        );
                                    } }
                                >
                                    <Description size="md" lineHeight="md" color="shade-2" weight="medium">
                                        LOGIN WITH GOOGLE
                                    </Description>
                                </Button>
                                <Button
                                    margin="sm"
                                    size="lg"
                                    radius="xxl"
                                    icon="facebook"
                                    iconSize={ 24 }
                                    fullWidth
                                    viewType="secondary"
                                    onClick={ () => {
                                        window.open(
                                            `${ API_URL }/api/v0/public/oauth/facebook?scope=email%20public_profile&state=${ token }`,
                                            "_self"
                                        );
                                    } }
                                >
                                    <Description size="md" lineHeight="md" color="shade-2" weight="medium">
                                        LOGIN WITH FACEBOOK
                                    </Description>
                                </Button>
                                <Description weight="medium" size="sm" margin="sm" color="shade-4">or</Description>
                            </Wrapper>
                            <Form
                                onSubmit={ async (e) => {
                                    await submitHandler(e);
                                } }
                            >
                                <Wrapper>
                                    <FormItem
                                        errorComponent={ FormError as React.FC }
                                        error={ (!!errors.password && errors.password.message) || (!!errors.form && errors.form.message) }
                                        hasTooltipMessage={ false }
                                        autoFocusDetect
                                        margin="sm"
                                    >
                                        <Input
                                            size="md"
                                            fullWidth
                                            withShowPassword
                                            { ...formModel.bind('password', formModel.get('password').rules) }
                                            error={ !!errors.password || !!errors.form }
                                            placeholder={ formModel.get('password').placeholder }
                                            type="password"
                                        />
                                    </FormItem>
                                    <Button
                                        onClick={ submitHandler }
                                        type="submit"
                                        loading={ formModel.isLoading }
                                        disabled={ formModel.isLoading }
                                        fullWidth
                                        radius="xxl"
                                        size="lg"
                                    >
                                        SIGN UP
                                    </Button>
                                </Wrapper>
                            </Form>
                        </Wrapper>
                    ) : (
                        <Description size="lg">
                            Password has been successfully created.
                        </Description>
                    ) }
                </Wrapper>
            </Wrapper>
            <Description lineHeight="md">
                Already a member?
                { " " }
                <Link
                    onClick={ () => history.push("/login") }
                    fontSize="14px"
                    weight="medium"
                    style={ { margin: 0 } }
                >
                    Login
                </Link>
            </Description>
        </Wrapper>
    );
});
