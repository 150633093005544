import React, { memo, useMemo }          from 'react';
import { CallbackFunctionVariadic }      from "@geenee/geeclient-kit/src/lib/type/type";
import { FormSizesType, SizesUnionType } from "../../util/global-props";
import { FormItem }                      from "../form-item/form-item.component";
import { Input }                         from "../input/input.component";

type InputMatrixRowPropsType = {
  margin?: SizesUnionType
  min?: number
  max?: number
  step?: number
  type?: 'text' | 'number' | 'password' | 'email'
  size?: FormSizesType
  onChange?: CallbackFunctionVariadic
  value: string
  defaultValue?: string
  disabled?: boolean
}
export const InputMatrixCell = memo((props: InputMatrixRowPropsType) => {
    const fixedSize = useMemo(() => {
        if (props.type === 'number' && props.step) {
            return Math.log10(1 / props.step);
        }
        return null;
    }, [ props.type, props.step ]);

    const value = useMemo(
        () => {
            if (fixedSize && `${ props.value || 0 }`.split(/\.|,/g)[ 1 ]?.length > fixedSize) {
                return Number(props.value || 0).toFixed(fixedSize);
            }
            return props.value;
        },
        [ props.value, fixedSize ]
    );
    return (
        <FormItem
            margin={ props.margin }
        >
            <Input
                color="shade-4"
                min={ props.min }
                max={ props.max }
                step={ props.step }
                size={ props.size }
                type={ props.type }
                onChange={ props.onChange }
                value={ value }
                defaultValue={ props.defaultValue }
                disabled={ props.disabled }
            />
        </FormItem>
    );
});
