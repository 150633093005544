import React, { useEffect, useMemo, useState } from 'react';
import { DndProvider }                         from 'react-dnd';
import { HTML5Backend }                        from 'react-dnd-html5-backend';
import { Scene }                               from '@babylonjs/core';
import { BabylonRenderer }                     from '@geenee/geespector/renderer/babylonjs.renderer';
import GlobalState                             from '@geenee/geespector/src/components/globalState';
import { useInject }                           from '@geenee/shared';
import { useBabylonAssetsActions }    from "~/components/AssetsSourcePickerBabylon/hooks/useBabylonAssetsActions";
import { DropWrapper }                from "~/components/DropWrapper";
import { MODELS_FORMATS }             from "~/lib/constants";
import { SceneDropPlaceholder }       from "~/module/edit-asset-3d-scene/modal/SceneDropPlaceholder";

interface Props {
  children: React.ReactNode
  scene?: Scene
  renderer?: BabylonRenderer
}

export function SceneDropWrapperComponent({ scene, renderer, children }: Props) {
    const [ isSceneEmpty, setIsSceneEmpty ] = useState(false);
    const { AppState} = useInject();
    const { activeSection } = AppState;
    const { addSceneAsset, getCustomErrorMessage } = useBabylonAssetsActions(renderer, undefined, activeSection?.type);
    const drop = async (files) => {
        if (scene) {
            await Promise.all(files.map((file) => addSceneAsset({ file, fileName: file.name })));
        }
    }; ``;

    const customDropErrorMessage = useMemo(() => getCustomErrorMessage(), [ getCustomErrorMessage ]);

    useEffect(() => {
        if (renderer) {
            GlobalState.onEmptySceneObserver.add(() => {
                setIsSceneEmpty(renderer.checkIfSceneEmpty());
            });
        }
    }, [ renderer ]);

    return (
    // @ts-ignore
        <DndProvider backend={ HTML5Backend }>
            <DropWrapper
                fileFormats={ MODELS_FORMATS }
                onDrop={ drop }
                customErrorMessage={ customDropErrorMessage }
                overlayComponent={ <SceneDropPlaceholder active formats={ MODELS_FORMATS } /> }
            >
                { children }
                { isSceneEmpty && <SceneDropPlaceholder formats={ MODELS_FORMATS } /> }
            </DropWrapper>
        </DndProvider>
    );
}
