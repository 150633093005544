import * as React                                 from "react";
import { useEffect, useRef, useState }            from "react";
import { useDrop }                                from "react-dnd";
import { DeletedSection }                         from "@geenee/builder/src/components/Tree/DeletedSection";
import { useTreeNodeUtils }                       from "@geenee/builder/src/components/Tree/hooks/useTreeNodeUtils";
import { useBuilderInject }                       from "@geenee/builder/src/core/hook/use-builder-inject";
import { NODE_TYPE_FOLDER }                       from "@geenee/builder/src/lib/constants";
import { EditableArea }                           from "@geenee/ui";
import classnames                                 from "classnames";
import { observer }                               from "mobx-react";
import { NodeActions }                            from "../NodeActions";
import { Node, NodeControl, NodeLine, NodeTitle } from "../styles";
import "./index.component.scss";

export type FolderNodePropsType = {
    onFolderChange: (object: any, value: string) => void;
    id: string;
    index: number;
    title: string;
    onAddWillBeDeleted: (id: string) => void;
    willBeDeleted: Set<string>;
    moveItem: (dragIndex: number, hoverIndex: number) => void;
    openedNodes: Set<string>;
    updateProgramsOrder: () => void;
    children?: React.ReactNode[];
};

export const DropZone = (props: {
    onDrop?: (object: any) => void | Promise<void>;
    style?: React.CSSProperties;
    children?: React.ReactNode | React.ReactNode[];
}) => {
    const [ { isOver }, drop ] = useDrop({
        accept: "reorder-item",
        drop:   async (object: any) => {
            props.onDrop && await props.onDrop(object);
        },
        collect: (monitor) => ({ isOver: monitor.isOver() })
    });

    return (
        <div className={ `drop-zone ${ isOver ? 'drop-zone-is-over' : '' }` } ref={ drop } style={ props.style || {} }>
            { props.children }
        </div>
    );
};

const className = classnames("folder-node", { noParent: true });

export const FolderNode = observer((props: FolderNodePropsType) => {
    const containerRef = useRef(null);
    const { BuilderState } = useBuilderInject();

    const [ canMove, setCanMove ] = useState(false);
    const [ isOpened, setIsOpened ] = useState(false);
    const [ isDisabled, setIsDisabled ] = useState(false);
    const [ showDeleteRow, setShowDeleteRow ] = useState(false);
    const [ deleteTimeout, setDeleteTimeout ] = useState();
    const [ childNodes, setChildNodes ] = useState([]);

    const { getIcon } = useTreeNodeUtils();

    const icon = getIcon(NODE_TYPE_FOLDER, isOpened, [], "16");

    useEffect(() => {
        const nodes = props.children && props.children.length
            ? props.children.map((child) => {
                const { node } = child.props;

                if (node) {
                    return node;
                }
            })
            : [];

        setChildNodes(nodes);
    }, [ props.children ]);

    const onTitleChange = async (value: string) => {
        const newFolderId = await BuilderState.foldersController.changeFolderTitle(
            props.id,
            value
        );

        childNodes.forEach((child) => {
            props.onFolderChange(child, newFolderId, true);
        });
    };

    const onDelete = async () => {
        childNodes.forEach((child) => {
            props.onFolderChange(child, "");
        });

        await BuilderState.foldersController.removeFolder(props.id);
    };

    const onDeleteHandler = async () => {
        setShowDeleteRow(true);
        props.onAddWillBeDeleted(props.id);
        const removeTime = setTimeout(async () => {
            await onDelete();
            setShowDeleteRow(false);
        }, 5000);
        // @ts-ignore
        setDeleteTimeout(removeTime);
    };

    const onDropHandler = async (object) => {
        await props.onFolderChange(object, props.id);
    };

    if (showDeleteRow) {
        return (
            <DeletedSection
                onCloseDelete={ () => setShowDeleteRow(false) }
                onUndoClick={ () => {
                    if (deleteTimeout) {
                        clearTimeout(deleteTimeout);
                        setDeleteTimeout(undefined);
                        setShowDeleteRow(false);
                        props.willBeDeleted.delete(props.id);
                    }
                } }
            />
        );
    }

    // const { childMove } = useTreeNodeDrag(
    //     props.node,
    //     props.index,
    //     canMove,
    //     props.updateProgramsOrder,
    //     props.node.children,
    //     containerRef,
    //     props.onCollapsedChange,
    //     props.moveItem,
    //     props.triggerTreeUpdate
    // );

    return (
        <Node className={ className }>
            <NodeLine onClick={ () => setIsOpened(!isOpened) }>
                <DropZone onDrop={ onDropHandler } style={ { display: "flex" } }>
                    <NodeControl onClick={ () => setIsOpened(!isOpened) }>
                        { icon }
                    </NodeControl>
                    <NodeTitle>
                        <EditableArea
                            shorten
                            value={ props.title }
                            onChange={ onTitleChange }
                            onError={ () => {
                                console.error("Error");
                            } }
                            disabled={ isDisabled }
                            doubleClickMode
                        />
                    </NodeTitle>
                    <NodeActions
                        disabled={ isDisabled }
                        onChangeCanMove={ (v) => {
                            setCanMove(v);
                        } }
                        depth={ 0 }
                        onDeleteClicked={ onDeleteHandler }
                    />
                </DropZone>
            </NodeLine>
            { props.children && isOpened && (
                <ul style={ { paddingLeft: "16px" } }>{ props.children }</ul>
            ) }
        </Node>
    );
});
