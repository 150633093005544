import React, { memo }    from 'react';
import type { ProjectType }    from "@geenee/shared/type/project.type";
import { observer }       from 'mobx-react';
import { $Diff }          from 'utility-types';
import { ProjectContext } from '../context/project-context';

type DiffPropsType = {
  projectContext: ProjectType;
};

export function withProjectContext<T extends DiffPropsType>(
    Component: React.ComponentType<T>
) {
    return memo(
        observer((props: $Diff<T, DiffPropsType>) => (
            <ProjectContext.Consumer>
                { (context) => <Component { ...(props as T) } projectContext={ context } /> }
            </ProjectContext.Consumer>
        ))
    );
}
