interface EnvOptions {
    hideNft: boolean;
    showCountryRestrictions: boolean;
  }
  
  interface EnvConfig {
    [name: string]: {
      "API_URL": string;
      "NFT_API_URL": string;
      "PUBLISHING_DOMAIN_URL": string;
      "MAGELLAN_PREVIEW_URL": string;
      "STRIPE_KEY": string;
      "SENTRY_DSN": string;
      "TENOR_KEY": string;
      "SEGMENT_KEY": string;
      "ZENDESK_KEY": string;
      options?: EnvOptions;
      CUSTOM_CODE_EDITOR_URL?: string;
    };
  }
  
  export enum EnvironmentHostnames {
    Prod = "builder.geenee.ar",
    Staging = "builder.geenee.it",
    Sandbox = "builder.geenee.io",
    RedBullSandbox = "builder.redbull.geenee.io",
    Redesign = "builder.redesign.geenee.it",
    RedBull = "builder.redbull.geenee.ar",
    Feature1 = "builder-fb1.geenee.it",
    Feature2 = "builder-fb2.geenee.it",
    Feature3 = "builder-fb3.geenee.it",
    Local = "localhost",
  
  }
  
  const baseConfig: Partial<EnvConfig[EnvironmentHostnames]> = { CUSTOM_CODE_EDITOR_URL: "https://custom-code-builder-frame.geen.ee/" };
  
  const config: EnvConfig = {
      [ EnvironmentHostnames.Prod ]: {
          API_URL:               "https://monolith.geenee.ar",
          NFT_API_URL:           "https://gringotts.geenee.ar",
          PUBLISHING_DOMAIN_URL: ".geenee.ar",
          MAGELLAN_PREVIEW_URL:  "https://preview.geenee.ar",
          STRIPE_KEY:            "pk_live_51HfHUSHqDogCH2yoTV38cvyKXEYke5dxc7bHlH1X3aNyoijkrZuXkFDZb4Eyn5papee5n81w34q9pXQeg2T9yozy001TJacuZp",
          SENTRY_DSN:            "https://45589eee42f044579af6cb6d21c024dd@o548614.ingest.sentry.io/5671422",
          TENOR_KEY:             "465D5OBFSMEA",
          SEGMENT_KEY:           "1vuiv1yhWEIXYMQDI0H5zZOkIZjSXV1e",
          ZENDESK_KEY:           "d29bffe2-2186-4f2a-8b1f-6e6e7c08a82f"
      },
      [ EnvironmentHostnames.Staging ]: {
          API_URL:               "https://monolith.geenee.it",
          NFT_API_URL:           "https://gringotts.geenee.it",
          PUBLISHING_DOMAIN_URL: ".geenee.it",
          MAGELLAN_PREVIEW_URL:  "https://preview.geenee.it",
          STRIPE_KEY:            "pk_test_51HfHUSHqDogCH2yo19XhD8F61QoEI7QmKolzV6zdfvct7EpJl5TBqbNIQTHtGlZtRynCbcRaNnnq52zauYP56aWX00aPKe6F7Q",
          SENTRY_DSN:            "https://45589eee42f044579af6cb6d21c024dd@o548614.ingest.sentry.io/5671422",
          TENOR_KEY:             "465D5OBFSMEA",
          SEGMENT_KEY:           "NHIvBtyAzmE8P3DDxezauh1r6iS38XlF",
          ZENDESK_KEY:           "d29bffe2-2186-4f2a-8b1f-6e6e7c08a82f"
      },
      [ EnvironmentHostnames.Feature1 ]: {
          API_URL:               "https://monolith.geenee.it",
          NFT_API_URL:           "https://gringotts.geenee.it",
          PUBLISHING_DOMAIN_URL: "-fb1.geenee.it",
          MAGELLAN_PREVIEW_URL:  "https://preview-fb1.geenee.it",
          STRIPE_KEY:            "pk_test_51HfHUSHqDogCH2yo19XhD8F61QoEI7QmKolzV6zdfvct7EpJl5TBqbNIQTHtGlZtRynCbcRaNnnq52zauYP56aWX00aPKe6F7Q",
          SENTRY_DSN:            "https://45589eee42f044579af6cb6d21c024dd@o548614.ingest.sentry.io/5671422",
          TENOR_KEY:             "465D5OBFSMEA",
          SEGMENT_KEY:           "NHIvBtyAzmE8P3DDxezauh1r6iS38XlF",
          ZENDESK_KEY:           "d29bffe2-2186-4f2a-8b1f-6e6e7c08a82f"
      },
      [ EnvironmentHostnames.Feature2 ]: {
          API_URL:               "https://monolith.geenee.it",
          NFT_API_URL:           "https://gringotts.geenee.it",
          PUBLISHING_DOMAIN_URL: "-fb2.geenee.it",
          MAGELLAN_PREVIEW_URL:  "https://preview-fb2.geenee.it",
          STRIPE_KEY:            "pk_test_51HfHUSHqDogCH2yo19XhD8F61QoEI7QmKolzV6zdfvct7EpJl5TBqbNIQTHtGlZtRynCbcRaNnnq52zauYP56aWX00aPKe6F7Q",
          SENTRY_DSN:            "https://45589eee42f044579af6cb6d21c024dd@o548614.ingest.sentry.io/5671422",
          TENOR_KEY:             "465D5OBFSMEA",
          SEGMENT_KEY:           "NHIvBtyAzmE8P3DDxezauh1r6iS38XlF",
          ZENDESK_KEY:           "d29bffe2-2186-4f2a-8b1f-6e6e7c08a82f"
      },
      [ EnvironmentHostnames.Feature3 ]: {
          API_URL:               "https://monolith.geenee.it",
          NFT_API_URL:           "https://gringotts.geenee.it",
          PUBLISHING_DOMAIN_URL: "-fb3.geenee.it",
          MAGELLAN_PREVIEW_URL:  "https://preview-fb3.geenee.it",
          STRIPE_KEY:            "pk_test_51HfHUSHqDogCH2yo19XhD8F61QoEI7QmKolzV6zdfvct7EpJl5TBqbNIQTHtGlZtRynCbcRaNnnq52zauYP56aWX00aPKe6F7Q",
          SENTRY_DSN:            "https://45589eee42f044579af6cb6d21c024dd@o548614.ingest.sentry.io/5671422",
          TENOR_KEY:             "465D5OBFSMEA",
          SEGMENT_KEY:           "NHIvBtyAzmE8P3DDxezauh1r6iS38XlF",
          ZENDESK_KEY:           "d29bffe2-2186-4f2a-8b1f-6e6e7c08a82f"
      },
      [ EnvironmentHostnames.Sandbox ]: {
          API_URL:               "https://monolith.geenee.io",
          NFT_API_URL:           "https://gringotts.geenee.io",
          PUBLISHING_DOMAIN_URL: ".geenee.io",
          MAGELLAN_PREVIEW_URL:  "https://preview.geenee.io",
          STRIPE_KEY:            "pk_live_51HfHUSHqDogCH2yoTV38cvyKXEYke5dxc7bHlH1X3aNyoijkrZuXkFDZb4Eyn5papee5n81w34q9pXQeg2T9yozy001TJacuZp",
          SENTRY_DSN:            "https://45589eee42f044579af6cb6d21c024dd@o548614.ingest.sentry.io/5671422",
          TENOR_KEY:             "465D5OBFSMEA",
          SEGMENT_KEY:           "NHIvBtyAzmE8P3DDxezauh1r6iS38XlF",
          ZENDESK_KEY:           "d29bffe2-2186-4f2a-8b1f-6e6e7c08a82f"
      },
      [ EnvironmentHostnames.RedBullSandbox ]: {
          API_URL:               "https://monolith.geenee.io",
          NFT_API_URL:           "https://gringotts.geenee.io",
          PUBLISHING_DOMAIN_URL: ".redbull.geenee.io",
          MAGELLAN_PREVIEW_URL:  "https://preview.redbull.geenee.io",
          STRIPE_KEY:            "pk_live_51HfHUSHqDogCH2yoTV38cvyKXEYke5dxc7bHlH1X3aNyoijkrZuXkFDZb4Eyn5papee5n81w34q9pXQeg2T9yozy001TJacuZp",
          SENTRY_DSN:            "https://45589eee42f044579af6cb6d21c024dd@o548614.ingest.sentry.io/5671422",
          TENOR_KEY:             "465D5OBFSMEA",
          SEGMENT_KEY:           "NHIvBtyAzmE8P3DDxezauh1r6iS38XlF",
          ZENDESK_KEY:           "d29bffe2-2186-4f2a-8b1f-6e6e7c08a82f"
      },
      [ EnvironmentHostnames.Redesign ]: {
          API_URL:               "https://monolith.geenee.it",
          NFT_API_URL:           "https://gringotts.geenee.it",
          PUBLISHING_DOMAIN_URL: ".geenee.it",
          MAGELLAN_PREVIEW_URL:  "https://preview.geenee.it",
          STRIPE_KEY:            "pk_test_51HfHUSHqDogCH2yo19XhD8F61QoEI7QmKolzV6zdfvct7EpJl5TBqbNIQTHtGlZtRynCbcRaNnnq52zauYP56aWX00aPKe6F7Q",
          SENTRY_DSN:            "https://45589eee42f044579af6cb6d21c024dd@o548614.ingest.sentry.io/5671422",
          TENOR_KEY:             "465D5OBFSMEA",
          SEGMENT_KEY:           "NHIvBtyAzmE8P3DDxezauh1r6iS38XlF",
          ZENDESK_KEY:           "d29bffe2-2186-4f2a-8b1f-6e6e7c08a82f"
      },
      [ EnvironmentHostnames.RedBull ]: {
          API_URL:               "https://monolith.geenee.ar",
          NFT_API_URL:           "https://gringotts.geenee.ar",
          // Publishing domain for RedBull environments is different due to the custom domain structure
          // which is different from our default platform one
          PUBLISHING_DOMAIN_URL: "ar-rb.redbull.com",
          MAGELLAN_PREVIEW_URL:  "https://preview.redbull.geenee.ar",
          STRIPE_KEY:            "pk_live_51HfHUSHqDogCH2yoTV38cvyKXEYke5dxc7bHlH1X3aNyoijkrZuXkFDZb4Eyn5papee5n81w34q9pXQeg2T9yozy001TJacuZp",
          SENTRY_DSN:            "https://45589eee42f044579af6cb6d21c024dd@o548614.ingest.sentry.io/5671422",
          TENOR_KEY:             "465D5OBFSMEA",
          SEGMENT_KEY:           "NHIvBtyAzmE8P3DDxezauh1r6iS38XlF",
          ZENDESK_KEY:           "d29bffe2-2186-4f2a-8b1f-6e6e7c08a82f",
          options:               {
              hideNft:                 true,
              showCountryRestrictions: true
          }
      },
      [ EnvironmentHostnames.Local ]: {
          API_URL:                "https://monolith.geenee.it",
          NFT_API_URL:            "https://gringotts.geenee.it",
          PUBLISHING_DOMAIN_URL:  ".geenee.it",
          MAGELLAN_PREVIEW_URL:   "https://preview.geenee.it",
          STRIPE_KEY:             "pk_test_51HfHUSHqDogCH2yo19XhD8F61QoEI7QmKolzV6zdfvct7EpJl5TBqbNIQTHtGlZtRynCbcRaNnnq52zauYP56aWX00aPKe6F7Q",
          SENTRY_DSN:             "https://45589eee42f044579af6cb6d21c024dd@o548614.ingest.sentry.it/5671422",
          TENOR_KEY:              "465D5OBFSMEA",
          SEGMENT_KEY:            "NHIvBtyAzmE8P3DDxezauh1r6iS38XlF",
          ZENDESK_KEY:            "d29bffe2-2186-4f2a-8b1f-6e6e7c08a82f"
      }
  };
  
  // eslint-disable-next-line arca/no-default-export
  const domainConfig = config[ window.location.hostname ] || config[ EnvironmentHostnames.Local ];
  export const exportConfig = {
      ...baseConfig,
      ...domainConfig
  };
  export default exportConfig;
  