// @flow
import * as React from 'react';
import { Observer } from 'mobx-react';

export function createObservableContext<T extends object>(
  defaultValue: T
): React.Context<T> {
  const Context = React.createContext(defaultValue);
  return {
    Provider: Context.Provider,
    // @ts-ignore
    Consumer: (props) => (
      <Context.Consumer>
        {(value: T) => (
          // @ts-ignore:
          <Observer>{() => props.children(value)}</Observer>
        )}
      </Context.Consumer>
    ),
  };
}
