import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { CallbackFunctionVariadic }                      from "@geenee/geeclient-kit/src/lib/type/type";
import { debounce }                                      from "lodash-es";
import { FormSizesType, WeightType }                     from "../../util/global-props";
import { DescriptionPropsType }                          from "../description/description.component";
import { InputTable }                                    from "../input-table/input-table.component";
import { InputMatrixCell }                               from "./input-matrix-cell.component";
import { InputMatrixColumnLabels }                       from "./input-matrix-column-labels.component";
import { InputMatrixRow }                                from './input-matrix-row.component';

type InputMatrixRowType = {
  label: string,
  rowGetter: string,
  valueGetter?: (values: ValuesType, column: string) => string | number
  displayRule?: CallbackFunctionVariadic,
  step?: number,
  disabled?: boolean,
  before?: React.ReactNode
  min?: number
  max?: number
}

type InputMatrixProps = {
  rows: InputMatrixRowType[];
  columns: string[]
  displayColumns?: string[]
  getOnChange: (row: string, column: string) => CallbackFunctionVariadic
  inputSize?: FormSizesType
  type?: 'text' | 'number'
  labelSize?: DescriptionPropsType['size']
  labelsTitle?: string
  labelWeight?: WeightType
  target: Record<string, any>
  lockedRows?: string[]
  hasColumnLabels?: boolean
  ratio?: number
  className?: string
}

type ValuesType = {
  [key: string]: {
    [key: string]: string
  }
}

const sizeMap: {[key: number]: FormSizesType } = {
    1: 'md',
    2: 'sm',
    3: 'sm',
    4: 'xs'
};

export const InputMatrix: FC<InputMatrixProps> = memo((props: InputMatrixProps) => {
    const getDefaultValues = () => props.rows.reduce((rowVals, row) => {
        const rowValues = props.columns.reduce((colVals, col) => ({
            ...colVals,
            [ col ]:
        row.valueGetter ? `${ row.valueGetter(props.target, col) }` : props.target[ row.rowGetter ][ col ]
        }), {});
        return { ...rowVals, [ row.rowGetter ]: rowValues };
    }, {});

    const defaultValues: ValuesType  = useMemo(() => getDefaultValues(), []);

    const [ values, setValues ] = useState<ValuesType>(defaultValues);

    useEffect(() => {
        if (JSON.stringify(defaultValues) !== JSON.stringify(values)) {
            setValues(defaultValues);
        }
    }, [ defaultValues ]);

    const _getOnChange = (row: string, column: string) => (e: any) => {
        const { value } = e.target;
        if (props.lockedRows?.includes(row)) {
            setValues({
                ...values,
                [ row ]: {
                    ...values[ row ],
                    ...props.columns.reduce((acc, curr) => {
                        // @ts-ignore
                        acc[ curr ] = value;
                        return acc;
                    }, {})
                }
            });
        } else {
            setValues({ ...values, [ row ]: { ...values[ row ], [ column ]: value } });
        }
        const debounced = debounce(() => props.getOnChange(row, column)(+value), 0);
        debounced();
    };

    const columnLabels = useMemo(() => (props.hasColumnLabels ? (
        <InputMatrixColumnLabels
            labels={ props.displayColumns || props.columns }
            margin="xxs"
            title={ props.labelsTitle }
            size={ props.labelSize }
            viewType="secondary"
            ratio={ props.ratio }
        />
    )
        : <></>), [ props.hasColumnLabels, props.columns ]);
    return (
        <InputTable className={ props.className }>
            { props.rows.map((row, index) => (
                <InputMatrixRow
                    key={ `input-matrix-row-${ index }` }
                    ratio={ props.ratio }
                    marginOff={ false }
                    viewType="secondary"
                    label={ row.label }
                    before={ row.before }
                    size={ props.labelSize }
                    weight={ props.labelWeight }
                >
                    { props.columns.map((column, columnIndex) => (
                        <InputMatrixCell
                            key={ `input-matrix-${ index }-${ columnIndex }` }
                            min={ row.min }
                            max={ row.max }
                            step={ row.step || 0.01 }
                            size={ props.inputSize || sizeMap[ props.columns.length ] }
                            type={ props.type }
                            onChange={ _getOnChange(row.rowGetter, column) }
                            value={ values[ row.rowGetter ]?.[ column ] }
                            defaultValue={ defaultValues[ row.rowGetter ]?.[ column ] }
                            disabled={ row.disabled }
                            margin={ (columnIndex === props.columns.length - 1) ? 'off' : "xxs" }
                        />
                    )) }
                </InputMatrixRow>
            )) }
            { columnLabels }
        </InputTable>
    );
});

InputMatrix.defaultProps = { type: "number" };
