import React                                 from 'react';
import { Button, Wrapper }                   from '@geenee/ui';
import { UPLOADER_ALLOWED_IMAGE_EXTENSIONS } from '@geenee/builder/src/lib/constants';

type Props = {
    disabled?: boolean
    className?: string
    handleUpload: (file: File) => Promise<void>
    uploadContainerId: string
    hide: boolean
}

const openFileDialogById = (id: string, disabled?: boolean) => {
    if (!disabled) {
        const imageInput = document.querySelector(
            `#${ id }`
        ) as HTMLElement;
        imageInput && imageInput.click();
    }
};

export function ImageUpdatePencil({
    disabled, className, handleUpload, uploadContainerId, hide
}: Props) {
    return (
        <>
            <input
                accept={ UPLOADER_ALLOWED_IMAGE_EXTENSIONS }
                onChange={ async (event) => {
                    const file =  event?.target?.files![ 0 ];
                    if (!file) return;
                    await handleUpload(file);
                } }
                type="file"
                id={ uploadContainerId }
                hidden
            />
            { !hide
                ? (
                    <Wrapper
                        frame="solid-white"
                        fullWidth={ false }
                        maxWidth="38"
                        className={ className }
                    >
                        <Button
                            disabled={ disabled }
                            onClick={ () => openFileDialogById(uploadContainerId, disabled) }
                            viewType="transparent"
                            radius="sm"
                            size="xs"
                            icon="edit"
                            iconSize={ 18 }
                        />
                    </Wrapper>
                )
                : <></> }
        </>
    );
}
