import { Color3 }                                  from "@babylonjs/core";
import * as babylon                                from "@babylonjs/core/Legacy/legacy";
import { Engine, Processor }                       from '@geenee/armature';
import { TYPE_HEAD_TRACKING }                      from "@geenee/builder/src/lib/constants";
import { SceneManagerCommander }                   from "@geenee/builder/src/magellan/factory/scene-manager.commander";
import { BabylonRenderer, BabylonRendererOptions } from '@geenee/geespector/renderer/babylonjs.renderer';
import { SceneManagerAbstractFactory }             from '@geenee/shared/src/magellan/factory/scene-manager-abstract.factory';
import { CombinationSceneManagerModel }            from '@geenee/shared/src/magellan/scene-manager/type';
import { AppState }                                from '@geenee/shared/src/magellan/state/app.state';
import { injectable }                              from 'inversify';

@injectable()
export class SceneManagerFactory extends SceneManagerAbstractFactory {
    public create(schema: CombinationSceneManagerModel, processor: typeof Processor, $parent?: AppState) {
        const sceneManager = super.create(schema, processor, $parent);

        sceneManager.commander = this.container.get('<SceneCommander>');

        sceneManager.initialise = async (canvasWrapper: HTMLDivElement, options: BabylonRendererOptions) => {
            sceneManager.sceneRenderer = new BabylonRenderer(
                canvasWrapper,
                options,
                $parent?.activeMolecule?.id,
                $parent?.activeSection?.type,
                sceneManager.commander
            );

            if ($parent?.activeMolecule?.options?.molecule_scene_params) {
                Object.entries($parent?.activeMolecule?.options?.molecule_scene_params).forEach(([ key, value ]) => {
                    if (key === 'fogColor' && value) {
                        sceneManager.sceneRenderer.scene[ key ] = new Color3(value.r, value.g, value.b);
                    } else {
                        sceneManager.sceneRenderer.scene[ key ] = value;
                    }
                });
            }

            if ($parent?.activeSection?.type === TYPE_HEAD_TRACKING) {
                sceneManager.sceneRenderer.camera.setPosition(new babylon.Vector3(0, 0.25, 0.5));
            } else {
                sceneManager.sceneRenderer.camera.target.y = 0.8;
            }
            const commanderPrototype = new SceneManagerCommander();
            Object.setPrototypeOf(sceneManager, commanderPrototype);
        };

        return sceneManager;
    }
}
