import { useEffect, useRef } from 'react';
import { ZendeskAPI }        from 'react-zendesk';

export const useZenDeskSettings = (url: string) => {
    const urlRef = useRef(url);

    // Zendesk Web Widget customization settings
    // Refer to docs: https://developer.zendesk.com/embeddables/docs/widget/introduction
    const zendeskOptions = {
        color:       { theme: '#000' },
        launcher:    { label: { '*': 'Report' } },
        contactForm: {
            title:       { 'en-US': 'Report a bug' },
            ticketForms: [
                {
                    id:     360001794097,
                    fields: [
                        {
                            id:      'subject',
                            prefill: { '*': 'Bug Report' }
                        },
                        {
                            id:      'description',
                            prefill: { '*': `---------------------\nBug occured on:\n${ url }\n\n---------------------\nHow to reproduce:\n\n` }
                        }
                    ]
                }
            ]
        }
    };

    useEffect(() => {
        if (urlRef.current !== url) {
            // Apply settings with updated url path
            ZendeskAPI('webWidget', 'updateSettings', zendeskOptions);
            urlRef.current = url;
        }
    }, [ url, zendeskOptions ]);

    return zendeskOptions;
};
