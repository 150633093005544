import styled       from 'styled-components';
import StyledButton from '@geenee/builder/src/components/common/Button';

export const BackButton = styled(StyledButton)`
    &&& {
        background: rgba(196, 196, 196, 0.19);
        border: 1px solid
            ${ ({ theme: { secondaryColors } }) => secondaryColors.grey200 };
        box-shadow: none;
        color: black;

        &:hover {
            background: ${ ({ theme: { colors } }) => colors.white };
            box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
            color: black;
        }
    }
`;

export const GenerateButton = styled(StyledButton)`
    &&& {
        background: #000;
        border: 1px solid #000;
        box-shadow: none;
        color: white;

        &:hover {
            background: #5A5A5A;
            box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
            color: white;
        }
    }
`;

export const FormWrapper = styled.div`
    width: 489px;
    background: ${ ({ theme: { colors } }) => colors.panelBackground };
    border-radius: 10px;
    padding: 26px;

    .p-dropdown {
        width: 268px;
    }

    .one-ui-input-table-item__label {
        flex: 0 0 150px;
    }
`;

export const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MediaItemContainer = styled.div`
  width: 136px;
  height: 74px;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: radial-gradient(72.9% 72.9% at 50% 50%, rgba(255, 214, 0, 0.4) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.2);

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
`;

export const MediaWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-end;

    &:nth-child(n + 3) {
        margin-top: 5px;
    }

    &:nth-child(2n) {
        margin-left: 5px;
    }

    svg {
        margin-left: auto;
        cursor: pointer;
    }
`;
