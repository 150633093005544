import React, { FC, useMemo } from "react";
import { Button, Wrapper }    from "@geenee/ui";
import { observer }           from "mobx-react";

type Props = {
    key: number
    description: React.ReactNode
    onClick: () => void
    buttonText: string,
    isCurrentPlan: boolean
    currentPlanIsActive?: boolean
}

export const TableFooterComponent:FC<Props> = observer((props) => {
    const label = useMemo(() => {
        if (props.isCurrentPlan) {
            return props.currentPlanIsActive ? "Current Plan" : "Payment Needed";
        }
        return props.buttonText;
    }, [ props.currentPlanIsActive, props.isCurrentPlan, props.buttonText ]);
    return (
        <Wrapper key={ props.key } padding='xs' align='center'>
            { props.description }
            <Button
                fullWidth
                uppercase
                disabled={ props.isCurrentPlan && props.currentPlanIsActive }
                size='md'
                onClick={ props.onClick }
            >
                { label }
            </Button>
        </Wrapper>
    );
});
