import queryString         from 'querystring';
import React, { useState } from 'react';
import GeeneeLogo          from '@geenee/assets/lib/icons/resources/GeeneeLogoGradient';
import backgroundSrc       from '@geenee/assets/lib/images/bg-frame4.png';
import { useHistory }      from "@geenee/common";
import { useMobxForm }     from '@geenee/shared/src/magellan/hooks/use-mobx-form';
import {
    Button,
    Description, Form,
    FormError,
    FormItem,
    Input,
    Link,
    Title,
    Wrapper
} from "@geenee/ui";
import { observer } from 'mobx-react';

export const PasswordPage = observer(() => {
    const history = useHistory();
    const [ isSent, setIsSent ] = useState(false);

    const formModel = useMobxForm('<ResetPassword>');
    const { form: { formState: { errors } } } = formModel;

    const submitHandler = async (e) => {
        try {
            const query: { code?: string } = queryString.parse(
                window.location.search.slice(1)
            );

            await formModel.submit(e, { code: query.code });

            setIsSent(true);
            setTimeout(() => {
                history.push('/login');
                setIsSent(false);
            }, 5000);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Wrapper
            fullHeight
            align="center"
            valign="center"
            backgroundImage={ backgroundSrc }
        >
            <Wrapper align="center" margin="md">
                <GeeneeLogo width="192" height="32" />
            </Wrapper>
            <Wrapper
                maxWidth="352px"
                frame="solid-opacity-white"
                padding="xl"
                radius="xxxl"
                margin="md"
                shadow="translucency-md"
            >
                <Wrapper align="center" valign="center">
                    { !isSent ? (
                        <Wrapper>
                            <Wrapper margin="md" align="center">
                                <Title
                                    size="sm"
                                    weight="medium"
                                    styles={ { lineHeight: 1.5 } }
                                >
                                    Set up a new password
                                </Title>
                            </Wrapper>
                            <Form
                                onSubmit={ async (e) => {
                                    await submitHandler(e);
                                } }
                            >
                                <Wrapper>
                                    <FormItem
                                        errorComponent={ FormError as React.FC }
                                        error={ (!!errors.password && errors.password.message) || (!!errors.form && errors.form.message) }
                                        hasTooltipMessage={ false }
                                        autoFocusDetect
                                    >
                                        <Input
                                            size="md"
                                            fullWidth
                                            withShowPassword
                                            { ...formModel.bind('password', formModel.get('password').rules) }
                                            error={ !!errors.password || !!errors.form }
                                            placeholder={ formModel.get('password').placeholder }
                                            type="password"
                                        />
                                    </FormItem>
                                    <Button
                                        fullWidth
                                        onClick={ submitHandler }
                                        loading={ formModel.isLoading }
                                        radius="xxl"
                                        size="lg"
                                    >
                                        DONE
                                    </Button>
                                </Wrapper>
                            </Form>
                        </Wrapper>
                    ) : (
                        <Description size="xl">
                            Password has been successfully changed.
                        </Description>
                    ) }
                </Wrapper>
            </Wrapper>
            <Description lineHeight="md">
                Not a member?
                { ' ' }
                <Link
                    onClick={ () => history.push('/login') }
                    fontSize="14px"
                    weight="medium"
                    style={ { margin: 0 } }
                >
                    Free Account
                </Link>
            </Description>
        </Wrapper>
    );
});
