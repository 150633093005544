import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({ width = '35', height = '14', onClick, id }: SvgIconProps) {
    return (
        <svg
            id={ id }
            onClick={ onClick }
            width={ width }
            height={ height }
            viewBox="0 0 35 14"
            xmlns="http://www.w3.org/2000/svg"
        >
            { /* eslint-disable-next-line max-len */ }
            <path d="M1.90194 5.86925L6.07175 1.7859C6.37941 1.48462 6.37941 0.996144 6.07175 0.694863C5.76409 0.393582 5.26527 0.393582 4.95761 0.694863L0.230745 5.32373C-0.0769149 5.62501 -0.0769149 6.11348 0.230745 6.41477L4.95761 11.0436C5.26527 11.3449 5.76409 11.3449 6.07175 11.0436C6.37941 10.7424 6.37941 10.2539 6.07175 9.9526L1.90194 5.86925Z" fill="black" />
            { /* eslint-disable-next-line max-len */ }
            <path d="M32.4008 5.84044L28.231 9.92379C27.9233 10.2251 27.9233 10.7135 28.231 11.0148C28.5386 11.3161 29.0375 11.3161 29.3451 11.0148L34.072 6.38595C34.3796 6.08467 34.3796 5.5962 34.072 5.29492L29.3451 0.666053C29.0375 0.364772 28.5386 0.364772 28.231 0.666053C27.9233 0.967334 27.9233 1.45581 28.231 1.75709L32.4008 5.84044Z" fill="black" />
            { /* eslint-disable-next-line max-len */ }
            <path d="M11.3145 7.62173V4.0358H9.88477V7.9733C9.88477 9.42642 10.6816 10.5749 12.2871 10.5749C12.791 10.5749 13.6289 10.4049 14.2441 9.57877V10.4401H15.6387V4.0358H14.209V7.5983C14.209 8.56509 13.6348 9.22134 12.7266 9.22134C11.7422 9.22134 11.3145 8.52994 11.3145 7.62173ZM21.0586 5.4772V4.01236C20.9531 3.98892 20.7598 3.9772 20.707 3.9772C20.0332 3.9772 19.3359 4.32291 18.9492 5.02017V4.0358H17.5488V10.4401H18.9785V7.18228C18.9785 6.08658 19.5586 5.44205 20.6543 5.44205C20.6719 5.44205 20.9414 5.45377 21.0586 5.4772ZM23.7012 10.4401V0.666656H22.2715V10.4401H23.7012Z" fill="black" />
        </svg>
    );
}
