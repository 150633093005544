/* eslint-disable max-len */
import React from 'react';

export function DeviceMobileS({ children }: { children?: React.ReactNode }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.25 1.5C3.25 0.809643 3.80964 0.25 4.5 0.25H11.5C12.1904 0.25 12.75 0.809644 12.75 1.5V14.5C12.75 15.1904 12.1904 15.75 11.5 15.75H4.5C3.80964 15.75 3.25 15.1904 3.25 14.5V1.5ZM4.75 12.9999H11.25V1.75H9.00002V1.9999C9.00002 2.27604 8.77617 2.4999 8.50002 2.4999H7.50002C7.22388 2.4999 7.00002 2.27604 7.00002 1.9999V1.75H4.75V12.9999Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
export function DeviceMobileM({ children }: { children?: React.ReactNode }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 3.15C5 2.51487 5.51487 2 6.15 2H17.15C17.7851 2 18.3 2.51487 18.3 3.15V21.211C18.3 21.8462 17.7851 22.361 17.15 22.361H6.15C5.51487 22.361 5 21.8462 5 21.211V3.15ZM6.3 19.8049H17V3.3H12.65V4.0561C12.65 4.33225 12.4261 4.5561 12.15 4.5561H11.15C10.8739 4.5561 10.65 4.33225 10.65 4.0561V3.3H6.3V19.8049Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
export function DeviceMobileL({ children }: { children?: React.ReactNode }) {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 8.5C15 7.67157 15.6716 7 16.5 7H39.5C40.3284 7 41 7.67157 41 8.5V47.5C41 48.3284 40.3284 49 39.5 49H16.5C15.6716 49 15 48.3284 15 47.5V8.5ZM17 44H39V9H30.4001V10.7001C30.4001 10.9762 30.1762 11.2001 29.9001 11.2001H26.1001C25.824 11.2001 25.6001 10.9762 25.6001 10.7001V9H17V44Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
export function DeviceTabletS({ children }: { children?: React.ReactNode }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.5 0.25C1.80964 0.25 1.25 0.809643 1.25 1.5V14.5C1.25 15.1904 1.80964 15.75 2.5 15.75H13.5C14.1904 15.75 14.75 15.1904 14.75 14.5V1.5C14.75 0.809644 14.1904 0.25 13.5 0.25H2.5ZM2.75 12.9999V1.75H13.25V12.9999H2.75Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
export function DeviceTabletM({ children }: { children?: React.ReactNode }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.50004 1.34998C3.86491 1.34998 3.35004 1.86485 3.35004 2.49998V20.5C3.35004 21.1351 3.86491 21.65 4.50004 21.65H18.5C19.1352 21.65 19.65 21.1351 19.65 20.5V2.49998C19.65 1.86485 19.1352 1.34998 18.5 1.34998H4.50004ZM4.65004 19V2.64998H18.35V19H4.65004Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
export function DeviceTabletL({ children }: { children?: React.ReactNode }) {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 7C11.6716 7 11 7.67157 11 8.5V47.5C11 48.3284 11.6716 49 12.5 49H43.5C44.3284 49 45 48.3284 45 47.5V8.5C45 7.67157 44.3284 7 43.5 7H12.5ZM13 10.0001V44H43V10.0001H13Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
export function DeviceDesktopS({ children }: { children?: React.ReactNode }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.25 2.25C0.25 1.55964 0.809644 1 1.5 1H14.5C15.1904 1 15.75 1.55964 15.75 2.25V11.25C15.75 11.9404 15.1904 12.5 14.5 12.5H1.5C0.809643 12.5 0.25 11.9404 0.25 11.25V2.25ZM1.75 10H14.25V2.5H1.75V10ZM4.99996 14C4.99996 13.7239 5.22382 13.5 5.49996 13.5H10.5C10.7761 13.5 11 13.7239 11 14V15H4.99996V14Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
export function DeviceDesktopM({ children }: { children?: React.ReactNode }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.25 2C0.559644 2 0 2.55964 0 3.25V18.25C0 18.9404 0.559643 19.5 1.25 19.5H22.25C22.9404 19.5 23.5 18.9404 23.5 18.25V3.25C23.5 2.55964 22.9404 2 22.25 2H1.25ZM1.5 18V3.5H22V18H1.5ZM9.25 20.75C8.97386 20.75 8.75 20.9739 8.75 21.25V22.75H14.75V21.25C14.75 20.9739 14.5261 20.75 14.25 20.75H9.25Z"
                fill="#303030"
            />
            { children }
        </svg>
    );
}
export function DeviceDesktopL({ children }: { children?: React.ReactNode }) {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5 7C5.67157 7 5 7.67157 5 8.5V39.5C5 40.3284 5.67157 41 6.5 41H49.5C50.3284 41 51 40.3284 51 39.5V8.5C51 7.67157 50.3284 7 49.5 7H6.5ZM7 39V9H49V39H7ZM24 44C22.8954 44 22 44.8954 22 46V48H34V46C34 44.8954 33.1046 44 32 44H24Z"
                fill="#303030"
            />
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5 7C5.67157 7 5 7.67157 5 8.5V39.5C5 40.3284 5.67157 41 6.5 41H49.5C50.3284 41 51 40.3284 51 39.5V8.5C51 7.67157 50.3284 7 49.5 7H6.5ZM7 39V9H49V39H7ZM24 44C22.8954 44 22 44.8954 22 46V48H34V46C34 44.8954 33.1046 44 32 44H24Z"
                fill="#303030"
            />
            { children }
        </svg>
    );
}
export function DeviceAllS({ children }: { children?: React.ReactNode }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.50004 0.349976C0.864909 0.349976 0.350037 0.864848 0.350037 1.49998V10.5C0.350037 11.1351 0.864909 11.65 1.50004 11.65H8V10.35H1.65004V1.64998H14.35V4H15.65V1.49998C15.65 0.864849 15.1352 0.349976 14.5 0.349976H1.50004ZM5.5 12.75H8V14.25H5V13.25C5 12.9739 5.22386 12.75 5.5 12.75ZM9.34999 6.50005C9.34999 5.86492 9.86486 5.35005 10.5 5.35005H14.5C15.1351 5.35005 15.65 5.86492 15.65 6.50005V13.5C15.65 14.1352 15.1351 14.65 14.5 14.65H10.5C9.86486 14.65 9.34999 14.1352 9.34999 13.5V6.50005ZM10.65 6.65005V13H14.35V6.65005H10.65Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
export function DeviceAllM({ children }: { children?: React.ReactNode }) {
    return (
        <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.25 2C0.559644 2 0 2.55964 0 3.25V18.25C0 18.9404 0.559643 19.5 1.25 19.5H14.75V18H1.5V3.5H22V6.75H23.5V3.25C23.5 2.55964 22.9404 2 22.25 2H1.25ZM14.25 20.75C14.5261 20.75 14.75 20.9739 14.75 21.25V22.75H8.75V21.25C8.75 20.9739 8.97386 20.75 9.25 20.75H14.25ZM16.75 10.0571C16.75 9.36671 17.3096 8.80707 18 8.80707H24C24.6904 8.80707 25.25 9.36671 25.25 10.0571V21.4524C25.25 22.1428 24.6904 22.7024 24 22.7024H23.75V22.7499H17.75V22.6774C17.1794 22.5616 16.75 22.0572 16.75 21.4524V10.0571ZM23.75 10.3071V20.7499H18.25V10.3071H23.75Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
