import * as React                             from "react";
import { Scrollbars }                         from 'react-custom-scrollbars';
import { CallbackFunctionVariadic }           from "@geenee/geeclient-kit/src/lib/type/type";
import { cn, Collapse, Description, Wrapper } from "@geenee/ui";

require('./scenePanel.scss');

interface Props {
  title: string,
  children: React.ReactNode,
  hideCollapseIcon?: boolean,
  changeTitleBackgroundOnHover?: boolean
  clickMode?: 'icon' | 'container' | 'none'
  stickyToHeaderComponent?: React.ReactNode
  onCollapseChange?: CallbackFunctionVariadic
  collapsed?: boolean

}

const className = cn('scene-panel');
export function ScenePanelComponent({
    title, children, hideCollapseIcon,
    changeTitleBackgroundOnHover, clickMode, stickyToHeaderComponent,
    onCollapseChange, collapsed = false
}: Props) {
    return (
        <Collapse
            collapseWrapperProps={ {
                fullHeight: true,
                overflow:   'hidden',
                frame:      "solid-opacity-white-25",
                className:  className(),
                radius:     "xxxl",
                shadow:     'smooth-md',
                style:      { backdropFilter: 'blur(32px)' }
            } }
            paddingLeft="off"
            collapsed={ collapsed }
            iconCollapsedAfter
            clickMode={ clickMode }
            onCollapseChange={ onCollapseChange }
            hasIcon={ !hideCollapseIcon }
            iconCollapsedProps={ { name: 'showAll', color: 'shade-2' } }
            iconCollapsedRotationAngle="180deg"
            changeTitleBackgroundOnHover={ changeTitleBackgroundOnHover }
            panelWrapperProps={ { className: 'scenePanelHeader' } }
            panelContent={ (
                <Description color="shade-3">
                    { title }
                </Description>
            ) }
            contentWrapperProps={ { className: 'scenePanelContentWrapper' } }
        >
            <Wrapper
                padding="xs"
                fullHeight
                overflow="hidden"
            >
                { stickyToHeaderComponent }
                <Scrollbars
                    autoHide
                    autoHideTimeout={ 500 }
                    autoHideDuration={ 200 }
                >
                    { /* @ts-ignore */ }
                    { children }
                </Scrollbars>
            </Wrapper>
        </Collapse>
    );
}
