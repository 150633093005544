import React, { RefObject, useEffect, useRef, useState } from 'react';
import { GeeneeSceneType }                               from '@geenee/geeclient-kit/src/lib/component/scene/GeeneeScene.component';
import { DIContextType }                                 from '@geenee/geeclient-kit/src/lib/context/di.context';
import { AtomModel, SceneRenderer }                      from '@geenee/shared/index';
import { withDIContext }                                 from '@geenee/shared/src/magellan/hoc/withDIContext.hoc';
import { setObjectProperties }                           from '@geenee/shared/src/util/setObjectProperties';
import { useThree }                                      from '@react-three/fiber';
import { ObservableMap, runInAction }                                 from 'mobx';
import { Object3D }                                      from 'three';
import { GLTF }                                          from 'three/examples/jsm/loaders/GLTFLoader';
// import { preprocessLoadedObject }                        from '../../utils/sdk/SceneObjectsUtils';
import { ModelBoundingFrame }                            from './model-bounding-frame.component3d';

type LoadedAssetsProps = {
    loadingCallback?: (value: boolean) => void
    atomModel: AtomModel
    loadedAsset: Object3D | null
    activeSceneModel?: SceneRenderer
    diContext: DIContextType
    mode: GeeneeSceneType
    onModelClick?: () => void
}

// TODO: AtomComponent
const LoadedAssets = withDIContext<LoadedAssetsProps>(({
    loadingCallback,
    atomModel,
    loadedAsset,
    activeSceneModel,
    mode,
    onModelClick,
    diContext
}) => {
    const groupRef = useRef();
    const boxHelperRef = useRef();
    const meshRef = useRef();
    // Get default scene camera
    const { camera } = useThree();
    const [ asset, setAsset ] = useState<GLTF | Object3D>();
    const [ assetLoaded, setAssetLoaded ] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    useBoundingCubeUpdate(atomModel, boxHelperRef);

    const loadAssets = () => {
        if (!assetLoaded) return;
        // @ts-ignore
        const object = meshRef.current.children[ 0 ].children[ 0 ];
        if (!object) {
            return;
        }
        setAsset(object);
        setObjectProperties(object, atomModel);

        const map = new ObservableMap();
        map.set(object.id, object);
        runInAction(() => {
            atomModel.objectsRegistry = map;
            loadingCallback && loadingCallback(true);
        });

    };

    useEffect(() => {
        loadAssets();
    }, [ assetLoaded ]);

    useEffect(() => {
        if (!asset) return;
        return () => {
            loadingCallback && loadingCallback(false);
        };
    }, [ asset ]);

    if (!atomModel) {
        return <></>;
    }

    const { SceneAtomComponent: SCENE_ATOM_COMPONENT_DI } = diContext;

    return (
        <>
            <group ref={ groupRef } dispose={ null }>
                <mesh
                    ref={ meshRef }
                    onClick={ (e) => {
                        e.stopPropagation();
                        // onClick event acting like onMouse up. That's whe we have to check the length of the click
                        if (!e.delta) {
                            onModelClick && onModelClick();
                        }
                    } }
                >
                    <SCENE_ATOM_COMPONENT_DI
                        setAssetLoaded={ (loaded: boolean) => {
                            setAssetLoaded(loaded);
                        } }
                        activeSceneModel={ activeSceneModel }
                        atomModel={ atomModel }
                    />
                </mesh>
            </group>
            { mode !== 'preview' && (
                <ModelBoundingFrame
                    object={ loadedAsset || null }
                    atomModel={ atomModel }
                />
            ) }
        </>
    );
});
// eslint-disable-next-line arca/no-default-export
export default LoadedAssets;

const useBoundingCubeUpdate = (
    atomModel: AtomModel,
    boxHelperRef: RefObject<any>
) => {
    useEffect(() => {
        if (boxHelperRef.current) {
            boxHelperRef.current.update();
        }
    }, [ JSON.stringify([ atomModel.position, atomModel.scale, atomModel.rotation ]) ]);
};
