import React, { FC, memo, useMemo } from "react";
import { CallbackFunctionVariadic } from "@geenee/geeclient-kit/src/lib/type/type";
import { cn }                       from "@geenee/ui";
import Resizable                    from "re-resizable";
import './styles.scss';

type ResizeProps = {
    children: React.ReactNode
    enable?: {
      left?: boolean
      right?: boolean
      top?: boolean
      bottom?: boolean
      topRight?: boolean
      bottomRight?: boolean
      bottomLeft?: boolean
      topLeft?: boolean
    }
    onKeyDown?: CallbackFunctionVariadic
    id?: string
    minWidth?: number
    maxWidth?: number
}
const className = cn('resize-wrapper');

export const ResizeWrapper: FC<ResizeProps> = memo((props: ResizeProps) => {
    const enable = useMemo(() => ({
        top:         !!props.enable?.top,
        right:       !!props.enable?.right,
        bottom:      !!props.enable?.bottom,
        left:        !!props.enable?.left,
        topRight:    !!props.enable?.topRight,
        bottomRight: !!props.enable?.bottomRight,
        bottomLeft:  !!props.enable?.bottomLeft,
        topLeft:     !!props.enable?.topLeft
    }), [
        props.enable
    ]);
    return (
        <Resizable
            className={ className() }
            id={ props.id }
            style={ { display: 'flex' } }
            tabIndex={ -1 }
            size={ { height: "100%" } }
            minWidth={ props.minWidth }
            maxWidth={ props.maxWidth }
            minHeight="100%"
            enable={ enable }
            onKeyDown={ props.onKeyDown }
        >
            { props.children }
        </Resizable>
    );
});

ResizeWrapper.defaultProps = {
    minWidth: 300,
    maxWidth: 600
};
