import React                 from 'react';
import { Wrapper }           from '@geenee/ui';
import { NftItemModel }      from '@geenee/builder/src/core/model/nftItem.model';
import { DescriptionColumn } from './components/DescriptionColumn';
import { StatisticsColumn }  from './components/StatisticsColumn';
import { Container }         from './styles';

type Props = {
  item: NftItemModel;
}

export function SingleProjectNFT({ item }: Props) {
    return (
        <Container>
            <Wrapper row fullWidth styles={ { justifyContent: 'space-between' } }>
                <DescriptionColumn item={ item } />
                <StatisticsColumn url={ item.url } />
            </Wrapper>
        </Container>
    );
}
