import React, { useEffect, useState } from 'react';
import cn                             from 'classnames';
import CloseIcon                      from '../../../../asset/icons/Close';
import Download                       from '../../../../asset/icons/Download';
import { CloseConfirmation }          from './CloseConfirmation';
import { DownloadInstructions }       from './DownloadInstructions';
import { DownloadIconWrapper }        from './styles';

const className = 'snapshot-block';

interface Props {
    snapShotTaken: boolean | null
    isVideoMode: boolean
    snapShotPreviewImgRef: any
    snapShotPreviewVideoRef: any
    videoBlob?: Blob | null
    onSnapshotClose: any
}

export function SnapshotPreview({
    snapShotTaken, isVideoMode, snapShotPreviewImgRef, snapShotPreviewVideoRef, videoBlob, onSnapshotClose
}: Props) {
    const [ showCloseConfirmation, setShowCloseConfirmation ] = useState(false);
    const [ showDownloadInstructions, setShowDownloadInstructions ] = useState(false);

    const createFile = async () => {
        if (isVideoMode && videoBlob) {
            return new File([ videoBlob ], 'gee-video.mp4', { type: 'video/mp4' });
        }
        if (snapShotPreviewImgRef?.current) {
            const res = await fetch(snapShotPreviewImgRef.current.src);
            const blob = await res.blob();
            return new File([ blob ], 'gee-screen.jpeg', { type: 'image/jpeg' });
        }
        return null;
    };

    const fileDownload = (file: File) => {
        const url = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = file.name;
        a.click();
    };

    const onOpenShareClick = async () => {
        const file = await createFile();
        try {
            if (!navigator.share) {
                throw new Error('Device did not support share');
            }

            if (file) {
                const data = { files: [ file ] };
                // @ts-ignore
                if (navigator.canShare(data)) {
                    await navigator.share(data);
                } else {
                    throw new Error('Could not share');
                }
            }
        } catch (e) {
            console.log(e);
            // Not an abort error
            if (e.code !== 20) {
                fileDownload(file);
                setShowDownloadInstructions(true);
            }
        }
    };

    useEffect(() => {
        if (snapShotTaken) {
            setShowDownloadInstructions(false);
        }
    }, [ snapShotTaken ]);

    return (
        <>
            <div
                className={ cn(`${ className }__snapshot-preview`, { active: snapShotTaken }) }
            >
                { isVideoMode
                    ? (
                        <video
                            preload="auto"
                            playsInline
                            autoPlay
                            ref={ snapShotPreviewVideoRef }
                            src="/"
                            loop
                            controlsList="nodownload nofullscreen noremoteplayback"
                        />
                    )
                    : <img ref={ snapShotPreviewImgRef } src="/" alt="" /> }
                <DownloadIconWrapper onClick={ onOpenShareClick }>
                    <Download />
                </DownloadIconWrapper>
                <div
                    className={ `${ className }__close-download-icon` }
                    id="geenee-ui-snapshot-close--icon"
                    onClick={ () => setShowCloseConfirmation(true) }
                >
                    <CloseIcon width="20" height="20" />
                </div>
                { !showCloseConfirmation && showDownloadInstructions && <DownloadInstructions /> }
                { showCloseConfirmation && (
                    <CloseConfirmation
                        onConfirm={ () => {
                            onSnapshotClose();
                            setShowCloseConfirmation(false);
                        } }
                        onCancel={ () => setShowCloseConfirmation(false) }
                    />
                ) }
            </div>
            <div
                className={ cn(`${ className }__snapshot-flash`, { active: snapShotTaken }) }
            />
        </>
    );
}
