import React          from "react";
import { InputTable } from "../../input-table/input-table.component";

function ObjectFieldTemplate(props) {
    return (
        <InputTable>
            { props.properties.map((el, index) => (<React.Fragment key={ index }>{ el.content }</React.Fragment>)) }
        </InputTable>
    );
}

export default ObjectFieldTemplate;
