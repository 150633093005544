import IOSPoseSensor from "./IOSPoseSensor";
import ROSPoseSensor from "./ROSPoseSensor";
import DMPoseSensor from "./DMPoseSensor";
import NoIMUPoseSensor from "./NoIMUPoseSensor";

import { isIOS, isAndroid } from "./util";
import IPoseSensor from "./IPoseSensor";

const tryInitRelativeOrientationSensor = (): RelativeOrientationSensor | null => {
  let sensor: RelativeOrientationSensor | null = null;

  try {
    sensor = new RelativeOrientationSensor({
      frequency: 30
    });
  } catch (error) {
    if (error.name === 'SENSOR_FREQUENCY') {
      console.log('Cannot construct sensors due to the Feature Policy');
      sensor = null;
    } else if (error.name === 'ReferenceError') {
      sensor = null;
    } else {
      console.error(error);
      sensor = null;
    }
  }

  return sensor;
}

export const createPoseSensor = (): IPoseSensor => {
  const ios = isIOS() as boolean;
  const android = isAndroid() as boolean;
  const supprtsIMU = (ios || android);

  if (!supprtsIMU)
    return new NoIMUPoseSensor();

  if (ios)
    return new IOSPoseSensor();

  const roSensor = tryInitRelativeOrientationSensor();
  if (roSensor)
    return new ROSPoseSensor(roSensor);

  return new DMPoseSensor();
}
