import React, { useMemo }                                        from 'react';
import { useParams }                                             from 'react-router';
import { ExperienceModel, ProjectModel, RouteParams, useInject } from '@geenee/shared';
import isEmpty                                                   from 'lodash-es/isEmpty';
import { observer }                                              from 'mobx-react';
import { Toolbar }                                               from '@geenee/builder/src/components/MobileWrapper/Toolbar';
import { CustomCodeForm }                                        from '@geenee/builder/src/components/WizardForm/FormType/ContentMainForm/components/CustomCodeForm';
import {
    getRightHint,
    getRightTitle
} from '@geenee/builder/src/components/WizardForm/FormType/ContentMainForm/lib/TextSwitcherFunctions';
import { ExperienceDesignForm }                                      from '@geenee/builder/src/components/WizardForm/FormType/ExperienceDesignForm';
import { NEW_CONTENT_ID, TYPE_FULLSCREEN_GALLERY, TYPE_SCENE_BUILD } from '@geenee/builder/src/lib/constants';
import { SectionModel }                                              from '@geenee/builder/src/magellan/model/section.model';
import { ContentMainForm }                                           from './FormType/ContentMainForm';
import { ContentSetImageGalleryAsset }                               from './FormType/ContentSetImageGalleryAsset';
import { ProgramDesignForm }                                         from './FormType/ProgramDesignForm';
import { ViewDesignForm }                                            from './FormType/ViewDesignForm';
import { WizardForm }                                                from '.';

export const WizardFormSwitcher = observer(() => {
    const { projectId, experienceId, sectionId, viewId } = useParams<RouteParams>();
    const { AppState } = useInject();

    const {
        isSplashScreenEditing,
        activeSection,
        activeProject
    } = AppState as unknown as { isSplashScreenEditing: boolean, activeSection: SectionModel, activeProject: ProjectModel };
    // @ts-ignore
    const { activeWorkingItem } = AppState;
    const isSceneBuild = activeWorkingItem?.type === TYPE_SCENE_BUILD;
    const experienceIndex = experienceId
        ? Array.from(activeProject?.experiencesRegistry?.values() || [])
            .sort((a, b) => a.options.order - b.options.order)
            .findIndex((item: ExperienceModel) => item.id === experienceId)
        : -1;

    return useMemo(() => {
        if (isEmpty(activeWorkingItem) && sectionId !== NEW_CONTENT_ID) {
            return <></>;
        }

        if (sectionId) {
            const imageContent = activeWorkingItem.type === TYPE_FULLSCREEN_GALLERY;
            const disabled = activeSection?.nft_published;
            return (
                <>
                    <WizardForm
                        width={ isSplashScreenEditing ? 300 : undefined }
                        title={ getRightTitle(activeWorkingItem.type) }
                        description={ getRightHint(activeWorkingItem.type) }
                        withoutContent={ isSceneBuild }
                    >
                        { isSplashScreenEditing ? <></> : <ContentMainForm disabled={ disabled } /> }
                        <Toolbar />
                    </WizardForm>
                    <CustomCodeForm
                        width={ isSplashScreenEditing ? '300px' : undefined }
                        customCodeMolecule={ activeSection?.activeMolecule }
                        disabled={ disabled }
                    />
                    { imageContent ? (
                        <ContentSetImageGalleryAsset
                            disabled={ disabled }
                            activeSection={ activeSection }
                            type={ activeSection?.type }
                        />
                    ) : <></> }
                </>
            );
        }
        if (viewId) {
            // @TODO: Double-check
            const disabled = false;
            // const disabled = activeSection?.nft_published;

            return (
                <WizardForm
                    width={ isSplashScreenEditing ? 300 : undefined }
                    title="View"
                    withoutContent
                    description={ (
                        <>
                            View text
                        </>
                    ) }
                >
                    { isSplashScreenEditing ? <Toolbar /> : <ViewDesignForm disabled={ disabled } /> }
                </WizardForm>
            );
        }
        if (experienceId) {
            return (
                <WizardForm
                    width={ isSplashScreenEditing ? 300 : undefined }
                    title="Experience"
                    description={ (
                        <>
                            Your Experience will begin with a Splash page. Or you can turn it off and your Experience will begin with the Loader.
                            { experienceIndex > 0
                                ? (
                                    <>
                                        <br />
                                        <br />
                                        Please note if you turn the Splash off, your additional
                                        Experience will not appear at the Project-Level carousel.
                                    </>
                                )
                                : <></> }
                        </>
                    ) }
                >
                    { isSplashScreenEditing ? <Toolbar /> : <ExperienceDesignForm /> }
                </WizardForm>
            );
        }
        if (projectId) {
            return (
                <WizardForm
                    title="Design Your Project"
                    description={ (
                        <>
                            Use the left navigation to retitle, drag to reorganize, view, edit and sort your Project’s sections.
                            <br />
                            <br />
                            Use the green + icon to add sections.
                            <br />
                            <br />
                            Tap on any element within the phone to customize. Drag & drop media.
                            <br />
                            <br />
                            Brand your interface with a logo, header color & background color.
                            <br />
                            <br />
                            Advanced user? Drop in your own project-level code.
                        </>
                    ) }
                >
                    <ProgramDesignForm />
                </WizardForm>
            );
        }
        return <h3>Something wrong :(</h3>;
    }, [ sectionId, experienceId, projectId, viewId, activeWorkingItem, isSplashScreenEditing, isSceneBuild ]);
});
