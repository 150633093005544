// @TODO: use this config to all page redirections like history.push('/sdk')
const Pages = {
    Home: {
        path: '/getting-started',
        name: 'Home'
    },
    HomeBasic: {
        path: '/',
        name: 'Home'
    },
    CompanySettings: {
        path: '/company',
        name: 'Company Settings'
    },
    YourPlan: {
        path: '/your-plan',
        name: 'Your Plan'
    },
    AllPlans: {
        path: '/all-plans',
        name: 'All plans'
    },
    NFTDashboard: {
        path: '/nft-dashboard',
        name: 'NFT Dashboard'
    },
    ContactUs: {
        path: '/contact',
        name: 'Contact Us'
    },
    YourProfile: {
        path: '/profile',
        name: 'Your Profile'
    },
    SDKAccess: {
        path: '/sdk',
        name: 'SDK Access'
    },
    GoogleAnalytics: {
        path: '/analytics-google',
        name: 'Google Analytics'
    },
    MatomoAnalytics: {
        path: '/analytics-matomo',
        name: 'Matomo Analytics'
    },
    SingIn: {
        path: '/sign-in',
        name: 'Sing In'
    },
    SingUp: {
        path: '/sign-up',
        name: 'Sing Up'
    },
    TeamMembers: {
        path: '/team',
        name: 'Team Members'
    },
    AddNewTeamMembers: {
        path: '/team/new',
        name: 'Add New Team Members'
    }
};

export default Pages;
