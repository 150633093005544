import React                  from 'react';
import { useInject }          from '@geenee/shared';
import { observer }           from 'mobx-react';
import DrawerVideoContent     from '@geenee/builder/src/asset/videos/drawer.mp4';
import ExperienceVideoContent from '@geenee/builder/src/asset/videos/experience.mp4';
import GalleryVideoContent    from '@geenee/builder/src/asset/videos/gallery.mp4';
import MarkerARVideoContent   from '@geenee/builder/src/asset/videos/markerAR.mp4';
import PageEmbedVideoContent  from '@geenee/builder/src/asset/videos/page_embed.mp4';
import VideoContent           from '@geenee/builder/src/asset/videos/video_embed.mp4';
import WorldARVideoContent    from '@geenee/builder/src/asset/videos/worldAR.mp4';
import {
    NODE_TYPE_EXPERIENCE,
    TYPE_DRAWER_OVERLAY,
    TYPE_FULLSCREEN_GALLERY,
    TYPE_FULLSCREEN_VIDEO,
    TYPE_IFRAME,
    TYPE_SLAM_AR,
    TYPE_STAMP_AR
} from '@geenee/builder/src/lib/constants';
import { GalleryPreviewContainer } from './styles';

export const PhoneContentWrapper = observer((props) => {
    const { AppState } = useInject();
    const { contentPreview } = AppState;

    const getProperPreview = () => {
        const { type } = contentPreview || { type: '' };
        switch (type) {
            case TYPE_DRAWER_OVERLAY:
                return (
                    <video src={ DrawerVideoContent } loop muted autoPlay />
                );
            case TYPE_FULLSCREEN_GALLERY:
                return (
                    <video src={ GalleryVideoContent } loop muted autoPlay />
                );
            case TYPE_FULLSCREEN_VIDEO:
                return (
                    <video src={ VideoContent } loop muted autoPlay />
                );
            case TYPE_IFRAME:
                return (
                    <video src={ PageEmbedVideoContent } loop muted autoPlay />
                );
            case TYPE_SLAM_AR:
                return (
                    <video src={ WorldARVideoContent } loop muted autoPlay />
                );
            case TYPE_STAMP_AR:
                return (
                    <video src={ MarkerARVideoContent } loop muted autoPlay />
                );
            /* case TYPE_WYSIWYG:
                return (
                    <img src={ WYSIWYGContentImage } alt="preview_img" />
                ); */
            case NODE_TYPE_EXPERIENCE:
                return (
                    <video src={ ExperienceVideoContent } loop muted autoPlay />
                );
            default:
                return <></>;
        }
    };

    return (
        <>
            { contentPreview ? (
                <GalleryPreviewContainer foreground={ !!contentPreview }>
                    { getProperPreview() }
                </GalleryPreviewContainer>
            ) : <></> }
            { props.children }
        </>

    );
});
