import { AnimationClip }      from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control';
import { action, observable } from 'mobx';
import {
    AnimationAction,
    AnimationMixer,
    Clock,
    Object3D
    // @ts-ignore
} from 'three';

export class AnimationController {
    @observable animations: {object: Object3D, animation: AnimationClip, action: AnimationAction}[] = [];
    mixer = new AnimationMixer();
    clock = new Clock();

    update = () => {
        this.mixer.update(this.clock.getDelta());
    };

    @action
    add(animation: AnimationClip, object: Object3D) {
        const actionClip = this.mixer.clipAction(animation, object);
        this.animations.push({ object, animation, action: actionClip });
    }

    // Play specific Animation Clip by Name
    playClip = (name: string, object?: Object3D) => {
        this.animations.forEach((animation) => {
            if (animation.action.getClip().name === name) {
                if (object && animation.object !== object) {
                    return;
                }
                animation.action.play();
                animation.action.paused = false;
            }
        });
    };

    playAll = () => {
        this.animations.forEach((el) => {
            el.action.play();
            el.action.paused = false;
        });
    };

    // Stop specific Animation Clip by Name
    stopClip = (name: string, object?: Object3D) => {
        this.animations.forEach((animation) => {
            if (animation.action.getClip().name === name) {
                console.log('found', name, object, animation.object);
                if (object && animation.object !== object) {
                    return;
                }
                animation.action.stop();
            }
        });
    };

    stopAllObjectAnims = (object: Object3D) => {
        this.animations.forEach((animation) => {
            if (animation.object === object) {
                animation.action.stop();
            }
        });
    };

    stopAll = () => {
        this.mixer.stopAllAction();
    };

    // Pause specific Animation Clip by Name
    pauseClip = (name: string, object?: Object3D) => {
        this.animations.forEach((animation) => {
            if (animation.action.getClip().name === name) {
                if (object && animation.object !== object) {
                    return;
                }
                animation.action.paused = true;
            }
        });
    };

    pauseAll = () => {
        // @ts-ignore
        // eslint-disable-next-line no-shadow
        this.mixer._actions.forEach((action) => {
            action.paused = true;
        });
    };

    // Get Specific Animation Clip
    getClipByName = (name: string, object?: Object3D) => {
        let animationClip;
        this.animations.forEach((animation) => {
            if (animation.action.getClip().name === name) {
                if (object && animation.object !== object) {
                    return;
                }
                animationClip = animation.action.getClip();
            }
        });
        return animationClip;
    };

    // Get Specific Animation Action
    getActionByName = (name: string, object?: Object3D) => {
        let animationAction;
        this.animations.forEach((animation) => {
            if (animation.action.getClip().name === name) {
                if (object && animation.object !== object) {
                    return;
                }
                animationAction = animation.action;
            }
        });
        return animationAction;
    };

    crossFade = (from: string, to: string, time: number, object: Object3D) => {
        this.stopAllObjectAnims(object);

        const fromAction = this.getActionByName(from, object);
        const toAction = this.getActionByName(to, object);

        if (fromAction && toAction) {
            // @ts-ignore
            fromAction.play();
            // @ts-ignore
            fromAction.crossFadeTo(toAction, time);
            // @ts-ignore
            toAction.play();
        }
    };
}
