export function addUuid<T extends {new(...args:any[]):{}}>(constructor:T) {
    return class extends constructor {
        metadata : object | null = null;
        execute = () => {
            if (!this.metadata) {
                this.receiver.addNewNodesToNodesMap([ this.entity ]);
                this.metadata = { ...this.entity.metadata || {} };
            }
            this.entity.metadata = this.metadata;
            this.__proto__.__proto__.execute.bind(this)();
        };
    };
}
