import React, { useRef } from 'react';

export function Lights() {
    const ref = useRef();
    return (
        <group name="geenee-lightning-group">
            <ambientLight
                intensity={ 0.3 }
                color={ 0xFFFFFF }
            />

            { /* camera helper for debug shadows and etc */ }
            { /* { ref.current && <cameraHelper camera={ ref?.current?.shadow.camera } args={ [ ref?.current?.shadow.camera ] } visible /> } */ }
            <directionalLight
                ref={ ref }
                castShadow
                receiveShadow
                color={ 0xFFFFFF }
                shadow-bias={ -0.0001 }
                shadow-mapSize-height={ 2048 }
                shadow-mapSize-width={ 2048 }
                shadow-darkness={ 0.1 }
                shadow-camera-far={ 500 }
                shadow-camera-near={ -50 }
                shadow-camera-left={ -10 }
                shadow-camera-right={ 10 }
                shadow-camera-top={ 10 }
                shadow-camera-bottom={ -10 }
                position={ [ -50, 86, 86 ] }
                intensity={ 0.5 * Math.PI }
            />
            { /* <directionalLight position={ [ -10, 2, 50 ] } intensity={ 0.3 } /> */ }
            { /* /!*<pointLight position={POINT_LIGHT_POSITION} />*!/ */ }
            { /* <pointLight position={ POINT_LIGHT_2_POSITION } intensity={ POINT_LIGHT_2_INT } /> */ }
            { /* <directionalLight position={ DIR_LIGHT_POSITION } intensity={ DIR_LIGHT_INT } /> */ }

        </group>
    );
}

// eslint-disable-next-line arca/no-default-export
export default Lights;
