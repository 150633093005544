import React, { useEffect, useRef, useState } from 'react';
import CameraToggleLottie                     from '@geenee/assets/lib/lottie/camera-toggle.json';
import MorphLottie                            from '@geenee/assets/lib/lottie/morph.json';
import CameraPhoto                            from '@geenee/shared/asset/icons/CameraPhoto';
import {
    CameraActionsContainer, CameraAnimContainer, CameraIconWrapper,
    ToggleAnimWrapper, VideoRecordingIndicatorContainer
} from '@geenee/shared/src/magellan/components/SnapShotComponent/styles';
import { VideoRecordingTimer } from '@geenee/shared/src/magellan/components/SnapShotComponent/VideoRecordingTimer';

const className = 'snapshot-block';

type Props = {
  snapShotTaken?: boolean | null
  isVideoRecordStarted?: boolean
  isVideoMode: boolean | null
  videoRecordingIndicatorContainer: any
  onPhotoCaptureClick: () => void
  onVideoCaptureClick: () => void
}
export function SnapshotToggle({
    snapShotTaken = false,
    isVideoRecordStarted = false,
    isVideoMode,
    onPhotoCaptureClick,
    onVideoCaptureClick,
    videoRecordingIndicatorContainer
}: Props) {
    const [ toggleAnimation, setToggleAnimation ] = useState<any>();
    const [ cameraAnimation, setCameraAnimation ] = useState<any>();
    const cameraContainer = useRef(null);
    const toggleContainer = useRef(null);
    // @ts-ignore
    const isBuilder = process.env.ENV_GEENEE_APP === 'BUILDER';

    const loadLottie = async () => {
    // @ts-ignore
        setCameraAnimation(await lottie.loadAnimation({
            container:     cameraContainer.current,
            renderer:      'svg',
            loop:          false,
            autoplay:      false,
            animationData: MorphLottie
        }));

        // @ts-ignore
        setToggleAnimation(await lottie.loadAnimation({
            container:     toggleContainer.current,
            renderer:      'svg',
            loop:          false,
            autoplay:      false,
            animationData: CameraToggleLottie
        }));
    };

    useEffect(() => {
        loadLottie();
    }, []);

    return (
        <div className={ `${ className }__snapshot-ui` } style={ { display: snapShotTaken ? 'none' : 'flex' } }>
            <CameraActionsContainer right={ !!isVideoMode } left={ isVideoMode === false } isBuilder={ isBuilder }>
                <ToggleAnimWrapper ref={ toggleContainer } hide={ isVideoRecordStarted } />
                <CameraIconWrapper
                    active={ !isVideoMode }
                    onClick={ () => {
                        if (!isVideoRecordStarted && isVideoMode) {
                            toggleAnimation?.setDirection(-1);
                            toggleAnimation?.play();
                        }
                        onPhotoCaptureClick();
                    } }
                >
                    <CameraPhoto />
                </CameraIconWrapper>
                <CameraAnimContainer
                    active={ !!isVideoMode }
                    ref={ cameraContainer }
                />
                <VideoRecordingIndicatorContainer ref={ videoRecordingIndicatorContainer } />
                <CameraIconWrapper onClick={ () => {
                    if (isVideoMode) {
                        if (!isBuilder) {
                            cameraAnimation?.setDirection(isVideoRecordStarted ? -1 : 1);
                            cameraAnimation?.play();
                        }
                    } else {
                        toggleAnimation?.setDirection(1);
                        toggleAnimation?.play();
                    }
                    onVideoCaptureClick();
                } }
                />
            </CameraActionsContainer>
            <VideoRecordingTimer isVideoRecordStarted={ isVideoRecordStarted } />
        </div>
    );
}
