type RgbaProps = {
    r: number;
    g: number;
    b: number;
    a?: number | undefined;
};

// Returns string value of hex color with alpha
export const rgbaObjectToHexA = ({ r, g, b, a = 1 }: RgbaProps) => {
    let rHex = r.toString(16);
    let gHex = g.toString(16);
    let bHex = b.toString(16);
    let aHex = Math.round(a * 255).toString(16);

    if (rHex.length === 1) rHex = `0${ rHex }`;
    if (gHex.length === 1) gHex = `0${ gHex }`;
    if (bHex.length === 1) bHex = `0${ bHex }`;
    if (aHex.length === 1) aHex = `0${ aHex }`;

    return `#${ rHex }${ gHex }${ bHex }${ aHex }`;
};

const parseHexA = (hexA: string) => {
    let r: number | string = 0;
    let g: number | string = 0;
    let b: number | string = 0;
    let a: number | string = 1;

    if (hexA.length === 5) {
        r = `0x${ hexA[ 1 ] }${ hexA[ 1 ] }`;
        g = `0x${ hexA[ 2 ] }${ hexA[ 2 ] }`;
        b = `0x${ hexA[ 3 ] }${ hexA[ 3 ] }`;
        a = `0x${ hexA[ 4 ] }${ hexA[ 4 ] }`;
    } else if (hexA.length === 9) {
        r = `0x${ hexA[ 1 ] }${ hexA[ 2 ] }`;
        g = `0x${ hexA[ 3 ] }${ hexA[ 4 ] }`;
        b = `0x${ hexA[ 5 ] }${ hexA[ 6 ] }`;
        a = `0x${ hexA[ 7 ] }${ hexA[ 8 ] }`;
    }
    r = Number(r);
    g = Number(g);
    b = Number(b);
    a = +(Number(a) / 255).toFixed(3);
    return { r, g, b, a };
};

// Returns string value of RGBa color built from hex color with alpha
export const hexAToRGBA = (hexA = '#ffffffff') => {
    const { r, g, b, a } = parseHexA(hexA);
    return `rgba(${ +r },${ +g },${ +b },${ a })`;
};

// Returns object of values (needed for react-color lib) built from hex color with alpha
export const hexAToRGBAObject = (hexA = '#ffffffff') => {
    const { r, g, b, a } = parseHexA(hexA);
    return { r, g, b, a };
};
