/* eslint-disable max-len */
import React from 'react';

export function AutoStars({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 0L15.0804 2.91964L18 4L15.0804 5.08036L14 8L12.9196 5.08036L10 4L12.9196 2.91964L14 0ZM7 3L8.89064 8.10936L14 10L8.89064 11.8906L7 17L5.10936 11.8906L0 10L5.10936 8.10936L7 3ZM15.0804 14.9196L14 12L12.9196 14.9196L10 16L12.9196 17.0804L14 20L15.0804 17.0804L18 16L15.0804 14.9196Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
export function AutoStar({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                d="M3.5 0L4.44532 2.55468L7 3.5L4.44532 4.44532L3.5 7L2.55468 4.44532L0 3.5L2.55468 2.55468L3.5 0Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
