import React                                      from 'react';
import { action, makeAutoObservable, observable } from 'mobx';

export class PaymentModalState {
    @observable component: React.ReactNode = (<></>);
    @observable modalProps: {[key: string]: any} = {};
    @observable errorMessage = '';

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setComponent(component: React.ReactNode) {
        this.component = component;
    }

    @action
    setErrorMessage(message: string) {
        this.errorMessage = message;
    }

    @action
    setModalProps(props: any) {
        this.modalProps = { ...props };
    }
}

export const paymentModalState = new PaymentModalState();
export type PaymentModalStateType = PaymentModalState;
