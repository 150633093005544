import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '36',
    height = '35',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 36 35"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M0.418283 9.84264C1.91493 4.04825 7.14167 0 13.1262 0C18.7266 0 23.6634 3.54522 25.5006 8.74901H26.2582C30.7535 8.74449 34.5209 12.1471 34.9727 16.6196C35.4245 21.0922 32.4136 25.1795 28.0082 26.074C27.2189 26.2343 26.4491 25.7243 26.2888 24.935C26.1286 24.1457 26.6385 23.3759 27.4278 23.2157C30.3648 22.6193 32.372 19.8945 32.0708 16.9128C31.7696 13.931 29.258 11.6627 26.2597 11.6657H24.4222C23.7572 11.6657 23.1765 11.2159 23.0102 10.5721C21.8461 6.06531 17.7809 2.91667 13.1262 2.91667C8.47157 2.91667 4.40633 6.06531 3.24227 10.5721C2.07821 15.0788 4.11015 19.8023 8.18257 22.0564C8.88724 22.4465 9.1423 23.3339 8.75225 24.0386C8.36221 24.7432 7.47477 24.9983 6.7701 24.6083C1.53413 21.7101 -1.07836 15.637 0.418283 9.84264ZM15.2424 34.754C14.5722 34.3072 14.3911 33.4018 14.8379 32.7316L19.1597 26.2489H13.1346C11.9699 26.2489 11.2751 24.9508 11.9212 23.9816L17.7546 15.2317C18.2013 14.5615 19.1068 14.3804 19.7769 14.8272C20.4471 15.2739 20.6281 16.1794 20.1814 16.8495L15.8596 23.3323H21.8846C23.0494 23.3323 23.7441 24.6304 23.098 25.5995L17.2647 34.3495C16.8179 35.0197 15.9125 35.2008 15.2424 34.754Z"
                fill="black"
            />
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="35">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    // eslint-disable-next-line max-len
                    d="M0.418283 9.84264C1.91493 4.04825 7.14167 0 13.1262 0C18.7266 0 23.6634 3.54522 25.5006 8.74901H26.2582C30.7535 8.74449 34.5209 12.1471 34.9727 16.6196C35.4245 21.0922 32.4136 25.1795 28.0082 26.074C27.2189 26.2343 26.4491 25.7243 26.2888 24.935C26.1286 24.1457 26.6385 23.3759 27.4278 23.2157C30.3648 22.6193 32.372 19.8945 32.0708 16.9128C31.7696 13.931 29.258 11.6627 26.2597 11.6657H24.4222C23.7572 11.6657 23.1765 11.2159 23.0102 10.5721C21.8461 6.06531 17.7809 2.91667 13.1262 2.91667C8.47157 2.91667 4.40633 6.06531 3.24227 10.5721C2.07821 15.0788 4.11015 19.8023 8.18257 22.0564C8.88724 22.4465 9.1423 23.3339 8.75225 24.0386C8.36221 24.7432 7.47477 24.9983 6.7701 24.6083C1.53413 21.7101 -1.07836 15.637 0.418283 9.84264ZM15.2424 34.754C14.5722 34.3072 14.3911 33.4018 14.8379 32.7316L19.1597 26.2489H13.1346C11.9699 26.2489 11.2751 24.9508 11.9212 23.9816L17.7546 15.2317C18.2013 14.5615 19.1068 14.3804 19.7769 14.8272C20.4471 15.2739 20.6281 16.1794 20.1814 16.8495L15.8596 23.3323H21.8846C23.0494 23.3323 23.7441 24.6304 23.098 25.5995L17.2647 34.3495C16.8179 35.0197 15.9125 35.2008 15.2424 34.754Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0)">
                <rect x="0.00976562" width="35" height="35" fill="black" />
            </g>
        </svg>
    );
}
