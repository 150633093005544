import React, { memo }                      from 'react';
import { Description, Icon, Span, Wrapper } from '@geenee/ui';

export const EmptyPage = memo(() => (
    <Wrapper frame="solid-default" maxWidth='fit-content' margin="xl">
        <Wrapper padding='xl' align="center" valign="center" style={ { width: '832px' } }>
            <Icon size={ 64 } radius="lg" margin="md" name="lock" color="gradient-cold-3" frame="default" />
            <Description size="lg">
                Your SDK access keys will show up here.
            </Description>
            <Description size="lg" margin="xs">
                Press on
                { ' ' }
                <Span weight="bold">Create New Token</Span>
                { ' ' }
                button to add your first token
            </Description>
        </Wrapper>
    </Wrapper>
));
