import React, { memo }           from "react";
import { Collapse, Description } from "@geenee/ui";
import { WrapperPropsType }      from "@geenee/ui/src/lib/component/wrapper/wrapper.component";
import { SizeType }              from "@geenee/ui/src/lib/util/global-props";

type Props = {
  children: JSX.Element | JSX.Element[]
  title: string
  collapsed?: boolean
  contentWrapperProps?: WrapperPropsType
  paddingLeft?: SizeType
}
export const CollapseWrapper = memo(({
    children, title, collapsed = false, contentWrapperProps, paddingLeft = 'off'
} : Props) => (
    <Collapse
        collapsed={ collapsed }
        panelContent={ <Description lineHeight="lg" weight="medium">{ title }</Description> }
        iconCollapsedAfter
        iconCollapsedProps={ { name: 'arrowDown' } }
        iconCollapsedRotationAngle="180deg"
        paddingLeft={ paddingLeft }
        panelWrapperProps={ { padding: "xs", radius: 'lg' } }
        collapseWrapperProps={ { fullWidth: true } }
        contentWrapperProps={ contentWrapperProps }
    >
        { children }
    </Collapse>
));
