import React               from 'react';
import { CollapseWrapper } from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/ParametersTabTreejs/CollapseWrapper";
import {
    Divider,
    InputTable, InputTableItem,
    Switch, Wrapper
} from '@geenee/ui';
import { observer } from "mobx-react";

interface Props {
    disableReceiveShadow?: boolean
    disableCastShadow?: boolean
    onDisableShadowChanged: (value: boolean, type: 'receive' | 'cast') => void
}

export const LightningPanel = observer(({ disableReceiveShadow, disableCastShadow, onDisableShadowChanged }:Props) => (
    <CollapseWrapper
        collapsed={ false }
        title="LIGHTING"
        contentWrapperProps={ { padding: "xs" } }
        paddingLeft="xs"
    >
        <InputTable>
            <InputTableItem viewType="secondary" size="sm" weight="medium" label="Receive Shadow">
                <Wrapper fullWidth fullHeight align="right" valign="center">
                    <Switch
                        size="lg"
                        descriptionSize="sm"
                        checked={ !disableReceiveShadow }
                        onChange={ (value: boolean) => onDisableShadowChanged(!value, 'receive') }
                    />
                </Wrapper>
            </InputTableItem>
            <Divider style={ { height: 1, marginTop: 12, marginBottom: 12 } } color="white" />
            <InputTableItem viewType="secondary" size="sm" weight="medium" label="Cast Shadow">
                <Wrapper fullWidth fullHeight align="right" valign="center">
                    <Switch
                        size="lg"
                        descriptionSize="sm"
                        checked={ !disableCastShadow }
                        onChange={ (value: boolean) => onDisableShadowChanged(!value, 'cast') }
                    />
                </Wrapper>
            </InputTableItem>
        </InputTable>
    </CollapseWrapper>
));
