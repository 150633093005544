import React, { useMemo } from "react";
import { useInject }      from '@geenee/shared';
import {
    Button, Dropdown, FormItem, InputTableItem, SelectList, Span, Wrapper
} from '@geenee/ui';
import { observer } from "mobx-react";

type Props = {
  value: string[]
  onSelect: (value?: string) => void
  hasRestrictedProjects: boolean
}
export const ProjectsDropdown = observer(({ value, onSelect, hasRestrictedProjects }: Props) => {
    const { AppState } = useInject();
    const projectsItems = Array.from(AppState.projectsRegistry.values())
        .sort((a, b) => (a?.options.order || 0) - (b?.options.order || 0))
        .map((el) => ({ value: el.id, label: el.title }));
    projectsItems.unshift({ value: 'all', label: 'All' });

    const projectsList = useMemo(() => (
        <SelectList
            onChange={ onSelect }
            value={ value }
            items={ projectsItems }
        />
    ), [ projectsItems.length, hasRestrictedProjects, value ]);

    const label = useMemo(() => {
        if (value && value.includes('all')) {
            return 'All';
        }
        if (value && value.length) {
            return `${ value.length } project(s) selected`;
        }
        return 'Select at least one project';
    }, [ value ]);
    return (
        <InputTableItem
            size="lg"
            label="Projects access"
            viewType="secondary"
            weight="medium"
            ratio={ 0.65 }
        >
            <FormItem
                margin="off"
            >
                <Dropdown
                    menu={ projectsList }
                    menuWidth="100%"
                    fullWidth
                    maxCount={ 6 }
                >
                    { (dropdownOpen) => (
                        <Button
                            style={ { paddingLeft: 12, paddingRight: 15, borderRadius: 8, fontSize: 14 } }
                            icon={ dropdownOpen ? 'arrowTop' : 'arrowDown' }
                            iconAfter
                            border="shade-5"
                            shadow="none"
                            viewType="white"
                            iconFloat="right"
                            fullWidthContent
                            iconMargin="off"
                            fullWidth
                        >
                            <Span color="shade-2" weight="regular">{ label }</Span>
                        </Button>
                    ) }
                </Dropdown>
            </FormItem>
        </InputTableItem>
    );
});
