import React, {
    FC, forwardRef, memo, useEffect, useMemo,
    useRef, useState
} from 'react';
import { createPortal } from "react-dom";
import { cn }           from '../../util/bem';
import {
    FrameExtensionUnionType,
    SizesUnionType, TextColorType, TooltipPositionUnionType
} from "../../util/global-props";
import { Card }               from "../card/card.component";
import { Description }        from "../description/description.component";
import { Divider }            from "../divider/divider.component";
import { Title }              from "../title/title.component";
import { Wrapper }            from '../wrapper/wrapper.component';
import { Content }            from './components/tooltip.content.component';
import {  useAutoPosition }   from './autoposition.hook';
import { usePortalCalculate } from "./usePortalCalculate";
import './tooltip.component.scss';

export type TooltipPropsType = {
    className?: string;
    children: React.ReactNode;
    message?: React.ReactNode;
    title?: string
    disabled?: boolean;
    timeout?: number;

    row?: boolean;
    align?: 'center' | 'right' | 'left' | 'stretch' | 'space-between' | 'space-around';
    valign?: 'top'|'center'|'bottom' | 'stretch';

    media?: React.ReactNode;
    mediaBefore?: boolean;
    mediaRow?: boolean;

    manual?: boolean;
    shadow?: boolean;
    isOpen?: boolean;

    titleColor?: TextColorType
    messageColor?: TextColorType
    messageAlign?: 'left' | 'center' | 'right';

    frame?: FrameExtensionUnionType;
    position: TooltipPositionUnionType;
    margin?: SizesUnionType;
    minWidth?: string;
    menuWidth?: string;
    menuPadding?: SizesUnionType;
    style?: React.CSSProperties;
    delay?: number
}
const className = cn('tooltip');

export const Tooltip: FC<TooltipPropsType> = memo(forwardRef((props: TooltipPropsType, ref: React.Ref<HTMLDivElement>) => {
    const [ timeout ] = useState({ value: 0 });
    const triggerEl  = useRef<HTMLDivElement>(null);
    const dropdownEl = useRef<HTMLDivElement>(null);
    const [ open, setOpen ] = useState(props.isOpen || false);
    const [ portalContainer, setPortalContainer ] = useState<HTMLDivElement | null>(null);

    const [ autoPosition ] = useAutoPosition({
        dropdownEl: dropdownEl.current,
        triggerEl:  triggerEl.current,
        openState:  props.manual || open,
        position:   props.position
    });
    const position = useMemo(() => autoPosition || props.position, [ autoPosition, props.position ]);

    const createPortalContainer = () => {
        const _portalContainer = document.createElement('div');
        _portalContainer.style.position = 'fixed';
        _portalContainer.style.zIndex = '999999';
        document.body.appendChild(_portalContainer);
        return _portalContainer;
    };
    useEffect(() => {
        if (open && !portalContainer && !props.disabled) {
            const _portalContainer = createPortalContainer();
            setPortalContainer(_portalContainer);
        }
    }, [ open, props.disabled ]);

    useEffect(() => () => {
        if (portalContainer) {
            document.body.removeChild(portalContainer);
        }
    }, [ portalContainer ]);

    usePortalCalculate({
        position,
        triggerEl:        triggerEl.current,
        portalTarget:     portalContainer,
        triggerCalculate: open
    });

    const onMouseEnter = () => {
        if (props.delay !== undefined) {
            // @ts-ignore
            timeout.value = setTimeout(() => {
                setOpen(true);
            }, props.delay);
        } else {
            setOpen(true);
        }
    };

    const onMouseLeave = () => {
        if (timeout) {
            clearTimeout(timeout.value);
        }
        setOpen(false);
    };

    const PortalContent = () => (
        <Content
            className={ className }
            open={ open }
            position={ position }
            shadow={ props.shadow }
            menuWidth={ props.menuWidth }
            minWidth={ props.minWidth }
            largeSize={ !!props.media }
        >
            <Card
                className={ className('content') }
                innerRef={ dropdownEl }
                align={ props.align }
                valign={ props.valign }
                padding={ props.menuPadding }
                frame={ props.frame }
                row={ props.mediaRow }
                media={ props.media }
                mediaBefore={ props.mediaBefore }
                onClick={ (e) => e.stopPropagation() }
            >
                <Wrapper
                    row={ props.row }
                >
                    { props.title
                && (
                    <Title
                        weight="bold"
                        color={ props.titleColor }
                        size="sm"
                        align={ props.messageAlign }
                    >
                        { props.title }
                    </Title>
                ) }
                    { props.message && props.title ? <Divider transparent margin="xxs" /> : null }
                    { props.message
                && (
                    <Description
                        fullWidth
                        align={ props.messageAlign }
                        color={ props.messageColor }
                        size="sm"
                    >
                        { props.message }
                    </Description>
                ) }
                </Wrapper>
            </Card>
        </Content>
    );

    if ((!props.message && !props.title && !props.media) || props.disabled) {
        return <>{ props.children }</>;
    }
    return (
        <div
            className={
                className({
                    disabled: props.disabled,
                    margin:   props.margin
                })
            }
            ref={ ref }
            style={ props.style }
            onMouseEnter={ () => onMouseEnter() }
            onMouseLeave={ () => onMouseLeave() }
        >
            <Wrapper
                className={ className(
                    'trigger',
                    { disabled: !props.message }
                ) }
                innerRef={ triggerEl }
            >
                { props.children }
            </Wrapper>
            { portalContainer ? createPortal(PortalContent(), portalContainer) : <></> }
        </div>
    );
}));

Tooltip.defaultProps = {
    menuPadding:  'xxs',
    frame:        'solid-white',
    titleColor:   'shade-2',
    messageColor: 'shade-4',
    messageAlign: 'center',
    align:        'center',
    valign:       'center',
    minWidth:     '160px'
};
