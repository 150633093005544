import * as React                    from "react";
import type { Vector3 }              from "@babylonjs/core/Maths/math.vector";
import type { Observable }           from "@babylonjs/core/Misc/observable";
import { Tools }                     from "@babylonjs/core/Misc/tools";
import {  InputMatrix, Wrapper }     from "@geenee/ui";
import { v4 }                        from "uuid";
import type { PropertyChangedEvent } from "../propertyChangedEvent";

interface IVector3LineComponentProps {
    label: string;
    target: any;
    propertyName: string;
    step?: number;
    onChange?: (newvalue: Vector3) => void;
    useEuler?: boolean;
    onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
    noSlider?: boolean;
    icon?: string;
    iconLabel?: string;
}

export class Vector3LineComponent extends React.Component<IVector3LineComponentProps, { value: Vector3 }> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    static defaultProps = { step: 0.001 // cm
    };
    private inputKey = v4();
    private _localChange = false;

    constructor(props: IVector3LineComponentProps) {
        super(props);
        this.state = { value: this.getCurrentValue().clone() };
    }

    getCurrentValue() {
        return this.props.target[ this.props.propertyName ];
    }

    shouldComponentUpdate(nextProps: IVector3LineComponentProps, nextState: { value: Vector3 }) {
        const nextPropsValue = this.getCurrentValue();

        if (!nextPropsValue.equals(nextState.value) || this._localChange) {
            nextState.value = nextPropsValue.clone();
            if (!this._localChange) {
                this.inputKey = v4();
            }
            this._localChange = false;
            return true;
        }
        return false;
    }

    raiseOnPropertyChanged(previousValue: Vector3) {
        if (this.props.onChange) {
            this.props.onChange(this.state.value);
        }

        if (!this.props.onPropertyChangedObservable) {
            return;
        }
        this.props.onPropertyChangedObservable.notifyObservers({
            object:       this.props.target,
            property:     this.props.propertyName,
            value:        this.state.value,
            initialValue: previousValue
        });
    }

    updateVector3() {
        const store = this.props.target[ this.props.propertyName ].clone();
        this.props.target[ this.props.propertyName ] = this.state.value;

        this.setState({ value: store });

        this.raiseOnPropertyChanged(store);
    }

    updateAxisState(value: number, axis: 'x' | 'y' | 'z') {
        this._localChange = true;
        this.state.value[ axis ] = value;
        this.updateVector3();
    }

    transformMatrix = {
        rows: [ {
            label:     this.props.label,
            rowGetter: this.props.propertyName
        } ],
        columns:
      [ 'x', 'y', 'z' ]
    };

    getOnChange = (field: string, axis: string) => (value: number) => {
        const _value = this.props.useEuler ? Tools.ToRadians(value) : value;
        this.updateAxisState(_value, axis as 'x' | 'y' | 'z');
    };

    render() {
        return (
            <Wrapper padding="xs">
                <InputMatrix
                    key={ this.props.target.id + this.inputKey }
                    rows={ this.transformMatrix.rows }
                    columns={ this.transformMatrix.columns }
                    getOnChange={ this.getOnChange }
                    target={ this.props.target }
                    inputSize="sm"
                    labelWeight="medium"
                    labelSize="sm"
                />
            </Wrapper>
        );
    }
}
