import APIRequest from '@geenee/builder/src/lib/APIRequest';

export const getAttachments = () => APIRequest.request('GET', '/api/v0/attachments/', { auth: true });

export const deleteAttachment = (id: string) => APIRequest.request('delete', `/api/v0/attachments/${ id }`).then(
    (response) => response.status
);

export const updateAttachment = (
    id: string,
    attachmentData: Record<string, any>
) => APIRequest.request('put', `/api/v0/attachments/${ id }`, { attachment: attachmentData });

export const getAttachmentPreSignedUrl = (payload: Record<string, any>) => APIRequest.request(
    'post',
    '/api/v0/attachments',
    payload
).then(({ data, presigned_url }) => ({
    attachment:   data,
    presignedUrl: presigned_url
}));
