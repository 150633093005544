import React, { ChangeEvent, SyntheticEvent } from "react";
import { Icon, Input }                        from '@geenee/ui';
import { SizeType }                           from "@geenee/ui/src/lib/util/global-props";

type Props = {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  onKeyDown?: (
    e: SyntheticEvent<HTMLInputElement, KeyboardEvent>
  ) => void
  margin?: SizeType
}
export function InputSearch(props: Props) {
    return (
        <Input
            disabled={ props.disabled }
            transparent
            hideBorder
            onKeyDown={ props.onKeyDown }
            size="sm"
            type="text"
            placeholder="Type in a search word"
            value={ props.value }
            icon="search"
            margin={ props.margin }
            after={ props.value ? <Icon color="shade-4" name="closeRound" onClick={ () => !props.disabled && props.onChange('') } /> : undefined }
            iconSize={ 16 }
            iconColor="grey-9"
            onChange={ (evt: ChangeEvent<HTMLInputElement>) => {
                props.onChange(evt.target.value);
            } }
        />
    );
}
