export const querySerialize = function (obj) {
    const str = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const p in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(p)) {
            // @ts-ignore
            str.push(`${ encodeURIComponent(p) }=${ encodeURIComponent(obj[ p ]) }`);
        }
    }
    return str.join('&');
};
