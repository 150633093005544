import React, { useEffect, useMemo }   from 'react';
import { SceneManager }                from '@geenee/shared/src/manager/scene.manager';
import { Modal, useModal, Wrapper }    from '@geenee/ui';
import { LoadingStatus }               from "~/components/LoadingStatus/loading-status.component";
import { useBuilderInject }            from '~/core/hook/use-builder-inject';
import { GltfInspectorSceneComponent } from "~/module/geeltf-inspector/component/inspector-scene.component";

export const useGLTFInspectorModal = (sceneManager?: SceneManager, onHideModal?: () => void) => {
    const { BuilderState } = useBuilderInject();
    const [ layoutShowModal, layoutHideModal ] = useModal((transformGroupProps: JSX.IntrinsicAttributes) => (
        <Modal
            className='global-modal'
            onHideModal={ async () => {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                await BuilderState.onArModalLeave(layoutHideModal);
            } }
            // eslint-disable-next-line react/jsx-props-no-multi-spaces
            { ...transformGroupProps }
        >

            <Wrapper style={ { width: '100vw', height: '100vh' } }>
                { /* eslint-disable-next-line @typescript-eslint/no-use-before-define */ }
                <GltfInspectorSceneComponent onHideModal={ hideModal } />
                <LoadingStatus id="scene-loader" />
            </Wrapper>
        </Modal>
    ), [ ]);

    const hideModal = useMemo(() => async () => {
        if (sceneManager) {
            sceneManager?.sceneRenderer?.scene?.debugLayer.hide();
        }
        await BuilderState.onArModalLeave(layoutHideModal);
        // @ts-ignore
        sceneManager?.sceneRenderer.clearListeners();
        sceneManager?.sceneRenderer.dispose();
        onHideModal && onHideModal();
    }, [ sceneManager, onHideModal ]);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                hideModal();
            }
        };
        window.addEventListener('keydown', close);
        return () => {
            window.removeEventListener('keydown', close);
            hideModal();
        };
    }, []);

    const showModal = () => {
        // @ts-ignore
        sceneManager?.sceneRenderer.clearListeners();
        layoutShowModal();
    };
    return [ showModal, hideModal ];
};
