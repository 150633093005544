import React                           from 'react';
import { useInject }                   from '@geenee/shared';
import { Description, Title, Wrapper } from '@geenee/ui';
import { observer }                    from 'mobx-react';

export const Header = observer(({ publish }:{publish?: boolean}) => {
    const { AppState } = useInject();

    const mainProgram = AppState.activeProject;

    let title = 'PREVIEW:';

    if (mainProgram?.nft_published) {
        title = 'YOUR NFT IS LIVE';
    } else if (publish) {
        title = 'IT’S LIVE. CLICK TO UNPUBLISH OR:';
    }
    return (
        <Wrapper padding="xs">
            <Title margin="sm" size="xxs">
                { title }
            </Title>
            <Description
                size="sm"
                color={
                    publish ? 'white' : undefined
                }
            >
                Copy the URL:
            </Description>
        </Wrapper>
    );
});
