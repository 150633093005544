import { Engine, Processor }            from '@geenee/armature';
import { AppState }                     from '@geenee/shared';
import { CombinationSceneManagerModel } from '@geenee/shared/src/magellan/scene-manager/type';
import { FactoryAbstract }              from '@geenee/shared/src/util/factoryContainer';
import { injectable }                   from "inversify";

// TODO: SceneManagerAbstractFactory
// THREE JS SceneManager, Babylon Factory
@injectable()
export class SceneManagerAbstractFactory extends FactoryAbstract {
    public create(schema: CombinationSceneManagerModel, processor: typeof Processor, $parent?: AppState) {
        const sceneManagerModel = new Engine(processor, {});
        Object.assign(sceneManagerModel, schema);
        if ($parent) {
            sceneManagerModel.$parent = $parent;
        }

        return sceneManagerModel;
    }
}
export const SceneManagerFactory = SceneManagerAbstractFactory;
