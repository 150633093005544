import React, { FC, useEffect, useRef }   from 'react';
import { createPortal }                   from "react-dom";
import { observer }                       from "mobx-react";
import { useOnClickOutside }              from "../../util/useOnClickOutside";
import { Card }                           from "../card/card.component";
import { PopoverItem }                    from "./component/popover-item.component";
import { PopoverStyleProps }              from "./popover-container.component";
import { PopoverItemType, PopoverSchema } from "./util";

interface CoordinatesType {
  left?: number,
  top?: number,
  right?: number,
  bottom?: number
}
export interface PopoverPropsType extends PopoverStyleProps {
  popoverSchema?: PopoverSchema;
  children?: React.ReactNode;
  showAndHideInside?: (id: string, parentId: string) => void;
  closePopover: CallableFunction;
  coordinates: CoordinatesType;
  id?: string;
  onSelected?: (item: PopoverItemType, e: any) => void
}

const marginSizes = {
    off:  0,
    xxxl: 56,
    xxl:  48,
    xl:   40,
    lg:   32,
    md:   24,
    sm:   16,
    xs:   8,
    xxs:  4
};

export const Popover: FC<PopoverPropsType> = observer((
    props: PopoverPropsType
) => {
    const [ portalTarget, setPortalTarget ] = React.useState<HTMLElement | null>(null);
    const portalTargetRef = useRef<HTMLElement | null>(
        portalTarget
    );

    const createDomElement = () => {
        const portalContainer = document.createElement('div');
        portalContainer.style.position = 'fixed';
        portalContainer.style.opacity = '0';
        portalContainer.style.zIndex = '999999';
        // @ts-ignore
        Object.keys(props.coordinates).forEach((key: keyof CoordinatesType) => {
            let value = props.coordinates[ key ] || 0;
            if (key === 'left') {
                value +=  marginSizes[ props.margin || 'off' ];
            }
            portalContainer.style[ key ] = `${ value }px`;
        });
        document.body.appendChild(portalContainer);
        return portalContainer;
    };

    useEffect(() => {
        if (portalTarget) {
            const windowHeight = window.innerHeight;
            if ((props.coordinates.top || 0) + portalTarget.clientHeight > windowHeight) {
                portalTarget.style.top = `${ windowHeight - portalTarget.clientHeight - 16 }px`;
            }
            portalTarget.style.opacity = '1';
        }
    }, [ portalTarget ]);
    useEffect(() => {
        if (props.popoverSchema || props.children) {
            const el = createDomElement();
            setPortalTarget(el);
            portalTargetRef.current = el;
        }
        return () => {
            if (portalTargetRef.current) {
                document.body.removeChild(portalTargetRef.current);
            }
        };
    }, []);
    useOnClickOutside(portalTargetRef, (e) => {
        if (!(e.target as HTMLElement).classList.contains('popover-button')) {
            props.closePopover(e);
        }
    });
    const Content = (
        <Card
            id={ `main-popover-${ props.id || props.popoverSchema?.id || '' }` }
            align={ props.align }
            valign={ props.valign }
            radius={ props.radius }
            frame={ props.frame }
            shadow={ props.shadow }
            color={ props.color }
            padding={ props.padding }
            styles={ {
                ...props.style,
                minWidth: props.minWidth,
                width:    props.menuWidth || 'auto'
            } }
        >
            { props.children || props.popoverSchema?.items.map((item) => (
                <PopoverItem
                    key={ `container-${ item.id }` }
                    { ...item }
                    onMouseEnter={ () => {
                        props.showAndHideInside
                        && props.showAndHideInside(item.meta.triggerForInside || '', props.popoverSchema?.id || '');
                    } }
                    onClick={ (e) => {
                        if (props.onSelected) {
                            props.onSelected(item, e);
                            props.closePopover(e);
                        }
                    } }
                />
            )) }
        </Card>
    );
    if (portalTarget) {
        return createPortal(Content, portalTarget);
    }
    return null;
});

Popover.defaultProps = { margin: 'off' };
