// @flow
import * as React                 from 'react';
import {
    FontSizeExtensionType,
    FontWeightExtensionType,
    FontLineHeightExtensionType,
    FontUppercaseExtensionType,
    FontTypeExtensionUnionType
} from 'con-shared/lib/type/model/_extension-type';

type StyleType = { [key: string]: any };

type PropsType = {
    children: (style: StyleType) => React.ReactNode,
    font_type?: FontTypeExtensionUnionType,
    font_size?: FontSizeExtensionType,
    font_weight?: FontWeightExtensionType,
    font_line_height?: FontLineHeightExtensionType,
    font_uppercase?: FontUppercaseExtensionType
}

export const FreeFont: React.FC<PropsType> = props => {
    const style: StyleType = {};

    // TODO: Add logic for responsive
    if (props.font_type === 'custom') {
        style[ '--font-kit-size' ]        = props.font_size ? `${ props.font_size }` : undefined;
        style[ '--font-kit-size-max' ]    = props.font_size  ? `${ +props.font_size < 40 ? props.font_size : 40 }` : undefined;
        style[ '--font-kit-weight' ]      = props.font_weight ? `${ props.font_weight }` : undefined;
        style[ '--font-kit-line-height' ] = props.font_line_height ? `${ props.font_line_height }` : undefined;

        if (props.font_uppercase !== undefined) {
            style[ '--font-kit-text-transform' ] = props.font_uppercase ? 'uppercase' : 'none';
        }
    }

    return (
        <React.Fragment>
            { props.children(style) }
        </React.Fragment>
    );
};
