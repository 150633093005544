import React                                                  from 'react';
import Trash                                                  from '@geenee/builder/src/asset/icons/Trash';
import { CountryItem }                                        from '@geenee/builder/src/components/CountriesSelector/index';
import { CloseIconWrapper, FlagWrapper, SelectedItemWrapper } from './styles';

export function SelectedTemplate(item: CountryItem) {
    return (
        <SelectedItemWrapper>
            <FlagWrapper>{ item.flag }</FlagWrapper>
            <span>{ item.name }</span>
            <CloseIconWrapper><Trash width="15" height="15" /></CloseIconWrapper>
        </SelectedItemWrapper>
    );
}
