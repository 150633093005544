import React                      from 'react';
import { InputTableItem, Switch } from '@geenee/ui';

interface Props {
  menu_visibility: boolean;
  onUpdate: (value: boolean) => void;
  disabled?: boolean;
}

export function ContentVisibilityRow({ menu_visibility, onUpdate, disabled }: Props) {
    return (
        <InputTableItem label='Visible in Menu'>
            <Switch
                descriptionSize="xs"
                color="white"
                size='lg'
                checked={ menu_visibility }
                onChange={ onUpdate }
                disabled={ disabled }
            />
        </InputTableItem>
    );
}
