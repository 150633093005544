import React                               from 'react';
import { Button, InputTableItem, Wrapper } from '@geenee/ui';
import { ProjectModel }                    from '@geenee/builder/src/magellan/model/project.model';
import { useAnalyticsModal }               from '@geenee/builder/src/module/custom-code/modal/analythics.modal';

interface IProps {
  model: ProjectModel;
  disabled: boolean;
}

export function AnalyticsRow({ model, disabled }: IProps) {
    // @ts-ignore
    const [ showAnaliticsModal ] = useAnalyticsModal(model);
    return (
        <InputTableItem label='GA Code'>
            <Wrapper fullWidth row>
                <Wrapper row valign="center">
                    <Button
                        size="qsm"
                        viewType="secondary"
                        margin="md"
                        onClick={ showAnaliticsModal }
                        disabled={ disabled }
                    >
                        ADD
                    </Button>
                </Wrapper>
            </Wrapper>
        </InputTableItem>
    );
}
