import styled from 'styled-components';

export const SpotifyWrapper = styled.div`
    padding: 24px 70px 0;
    height: auto;
    min-height: 232px;
    width: 1020px;

    background: linear-gradient(
            180deg,
            rgba(205, 191, 213, 0.16) 0%,
            rgba(169, 89, 215, 0.18) 43.34%,
            rgba(139, 172, 227, 0.308) 93.75%
        ),
        #ffffff;
    border-radius: 10px;
`;

export const HeaderTitle = styled.span`
    color: ${ ({ theme: { secondaryColors } }) => secondaryColors.green110 } !important;
    font-size: 15px;
    font-weight: bold;
    margin-left: 6px;
`;
