import React, { useMemo, useRef, useState }        from 'react';
import { useMediaQuery }                           from 'react-responsive';
import { useParams }                               from 'react-router';
import { useHistory }                              from "@geenee/common";
import { RouteParams, useInject }                  from '@geenee/shared';
import { MoleculeSchemaType }                      from '@geenee/shared/type/molecule.type';
import { SectionTypeUnionType }                    from '@geenee/shared/type/section.type';
import { Button, Wrapper }                         from '@geenee/ui';
import { observer }                                from 'mobx-react';
import ExperienceIntroIcon                         from '@geenee/builder/src/asset/icons/ExperienceIntroIcon';
import FullPageIcon                                from '@geenee/builder/src/asset/icons/FullPageIcon';
import ImageGalleryIcon                            from '@geenee/builder/src/asset/icons/ImageGalleryIcon';
import OverlayIcon                                 from '@geenee/builder/src/asset/icons/OverlayIcon';
import SceneBuildIcon                              from '@geenee/builder/src/asset/icons/SceneBuildIcon';
import VideoEmbedIcon                              from '@geenee/builder/src/asset/icons/VideoEmbedIcon';
import { AddSectionItem }                          from '@geenee/builder/src/components/Composer/HeaderButtons/AddSection/AddSectionItem';
import { AddSectionPanel, Label }                  from '@geenee/builder/src/components/Composer/HeaderButtons/AddSection/styles';
import { NODE_TYPE_EXPERIENCE, NODE_TYPE_PROJECT } from '@geenee/builder/src/lib/constants';
import Templates                                   from '@geenee/builder/src/lib/nodeTemplates';
import useOnClickOutside                           from '@geenee/builder/src/lib/useOnClickOutside';
import { ExperienceModel }                         from '@geenee/builder/src/magellan/model/experience.model';
import { ProjectModel }                            from '@geenee/builder/src/magellan/model/project.model';
import { SectionModel }                            from '@geenee/builder/src/magellan/model/section.model';

const SECTION_WIDTH = 200;
export const antagonisticSections: SectionTypeUnionType[] = [
    'scene-build', 'slam-ar', 'stamp-ar', 'fullscreen-video', 'fullscreen-gallery', 'iframe'
];

export const AddSection = observer(() => {
    const [ isPanelShown, setIsPanelShown ] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 1250px)' });
    const { AppState } = useInject();
    const { activeExperience, activeProject, activeWorkingItem, activeView } = AppState;
    const history = useHistory();
    const { projectId } = useParams<RouteParams>();
    const panelRef = useRef<HTMLDivElement | null>(null);
    const disabled = activeProject?.nft_published;
    const showContentType = (type: string) => {
        if (type === (AppState.contentPreview && AppState.contentPreview.type) || !isPanelShown) return;
        const properTypeForConst = type.includes('-')
            ? type.replace(/-/g, '_')
            : type;
        const workingData = Templates[ properTypeForConst.toUpperCase() ];
        const content = new SectionModel();
        if (activeExperience) {
            // @ts-ignore
            content.$parent = activeExperience;
        }
        // @ts-ignore
        content.updateState(workingData);
        // @ts-ignore
        AppState.setContentPreview(content);
    };

    const onClose = () => {
        if (isPanelShown) {
            setIsPanelShown(false);
        }

        if (AppState.contentPreview) {
            AppState.setContentPreview(null);
        }
    };
    // @ts-ignore
    useOnClickOutside(panelRef, onClose);
    const createNewExperience = async () => {
        const newExperience = await (activeProject as ProjectModel)
            .createExperience();
        history.push(`/${ projectId }/${ newExperience.id }`);
        return newExperience;
    };

    const onCreateSection = async (viewSchema: Record<string, any>, sectionSchema: Record<string, any>, moleculeType: MoleculeSchemaType['type']) => {
        onClose();
        const experience = (activeExperience || Array.from(activeProject?.experiencesRegistry.values() || [])[ 0 ]) as ExperienceModel | undefined;
        const isNewSectionIsAntagonist = antagonisticSections.includes(sectionSchema.type);
        if (experience) {
            let viewModel;
            if (isNewSectionIsAntagonist) {
                viewModel = await experience.createView(viewSchema);
            } else {
                viewModel = activeView;
            }
            const newSectionModel = await viewModel.createSection(sectionSchema);
            await newSectionModel.createMolecule({ type: moleculeType });
            history.push(`/${ projectId }/${ experience.id }/${ viewModel.id }/${ newSectionModel.id }`);
        }
    };

    const availableItemsList: JSX.Element[] = useMemo(() => {
        if (activeWorkingItem) {
            if (activeWorkingItem.type === NODE_TYPE_PROJECT) {
                return [
                    <AddSectionItem
                        key="experience"
                        icon={ <ExperienceIntroIcon /> }
                        title="EXPERIENCE"
                        description="Add 1 or more Experiences. As you add more, an Experience Carousel will appear at the Project-level."
                        onClick={ async () => {
                            if (activeProject) {
                                await createNewExperience();
                                onClose();
                            } else {
                                console.error('There is no active program');
                            }
                        } }
                        onMouseEnter={ () => {
                            showContentType(Templates.EXPERIENCE.type);
                        } }
                    />
                ];
            }

            if (activeWorkingItem.type === NODE_TYPE_EXPERIENCE) {
                return [
                    <AddSectionItem
                        key="ar-build"
                        icon={ <SceneBuildIcon /> }
                        title="AR BUILD"
                        description="Build out scenes by adding AR assets, sound and interactivity for world or marker AR."
                        onClick={ async () => {
                            // @ts-ignore
                            await onCreateSection(Templates.SCENE_BUILD_VIEW, Templates.SCENE_BUILD, Templates.SCENE_BUILD.type);
                        } }
                        onMouseEnter={ () => {
                            showContentType(Templates.SLAM_AR.type);
                        } }
                    />,
                    <AddSectionItem
                        key="full-page"
                        icon={ <FullPageIcon /> }
                        title="FULL PAGE"
                        description="Add a full page embed within the Experience."
                        onClick={ async () => {
                            // @ts-ignore
                            await onCreateSection(Templates.IFRAME_VIEW, Templates.IFRAME, Templates.IFRAME.type);
                        } }
                        onMouseEnter={ () => {
                            showContentType(Templates.IFRAME.type);
                        } }
                    />,
                    <AddSectionItem
                        key="image-gallery"
                        icon={ <ImageGalleryIcon /> }
                        title="IMAGE GALLERY"
                        onClick={ async () => {
                            await onCreateSection(
                                Templates.FULLSCREEN_GALLERY_VIEW,
                                Templates.FULLSCREEN_GALLERY,
                                // @ts-ignore
                                Templates.FULLSCREEN_GALLERY.type
                            );
                        } }
                        description="Add a gallery of images to go deeper into your product, brand or story."
                        onMouseEnter={ () => {
                            showContentType(Templates.FULLSCREEN_GALLERY.type);
                        } }
                    />,
                    <AddSectionItem
                        key="video"
                        icon={ <VideoEmbedIcon /> }
                        title="VIDEO EMBED"
                        description="Embed a video into your Experience to go deeper into your product, brand or story."
                        onClick={ async () => {
                            await onCreateSection(
                                Templates.FULLSCREEN_VIDEO_VIEW,
                                Templates.FULLSCREEN_VIDEO,
                                // @ts-ignore
                                Templates.FULLSCREEN_VIDEO_ATOMS[ 0 ].type
                            );
                        } }
                        onMouseEnter={ () => {
                            showContentType(Templates.FULLSCREEN_VIDEO.type);
                        } }
                    />
                ];
            }

            return [
                <AddSectionItem
                    key="overlay"
                    icon={ <OverlayIcon /> }
                    title="OVERLAY"
                    description="Add quick info with or without a button within a slide-up quarter page."
                    onClick={ async () => {
                        // @ts-ignore
                        await onCreateSection(Templates.OVERLAY_VIEW, Templates.OVERLAY, Templates.OVERLAY.type);
                    } }
                    onMouseEnter={ () => {
                        showContentType(Templates.OVERLAY.type);
                    } }
                />
                /* <AddSectionItem
                            icon={ <CustomCodeIcon /> }
                            title="CUSTOM CODE"
                            description="Add a custom html / css page."
                            onClick={ async () => {
                            // @ts-ignore
                                await onCreateSection(Templates.CUSTOM_CODE_VIEW, Templates.CUSTOM_CODE, Templates.CUSTOM_CODE.type);
                            } }
                            onMouseEnter={ () => {
                                showContentType(Templates.FULLSCREEN_VIDEO.type);
                            } }
                        /> */
            ];
        }
        return [];
    }, [ activeWorkingItem?.type, isPanelShown ]);

    return (
        <Wrapper
            style={ { marginBottom: 8 } }
            maxWidth="fit-content"
            innerRef={ panelRef }
        >
            <Wrapper row valign="center">
                <Button
                    active={ isPanelShown }
                    viewType="green"
                    size="lg"
                    icon="add"
                    iconColor="dark"
                    margin="sm"
                    iconSize={ 18 }
                    rounded
                    disabled={ disabled }
                    onClick={ () => {
                        if (isPanelShown) {
                            onClose();
                        } else {
                            setIsPanelShown(true);
                        }
                    } }
                />
                <Label>ADD SECTION</Label>
            </Wrapper>
            <div>
                <AddSectionPanel
                    isMobile={ isMobile }
                    opened={ isPanelShown }
                    isNotOneItem={ availableItemsList.length > 1 }
                    maxWidthSize={ availableItemsList.length < 3 ? availableItemsList.length * SECTION_WIDTH : SECTION_WIDTH * 3 }
                    frame="solid-white"
                    shadow="md"
                    row
                >
                    { [ ...availableItemsList ] }
                </AddSectionPanel>
            </div>
        </Wrapper>
    );
});
