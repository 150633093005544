import React                                             from 'react';
import { GeeneeScene }                                   from '@geenee/geeclient-kit/src/lib/component/scene/GeeneeScene.component';
import { createComponentContext, DIContextType, initDI } from '@geenee/geeclient-kit/src/lib/context/di.context';
import { observer }                                      from 'mobx-react';
import { AssetsSourcePicker }                            from '@geenee/builder/src/components/AssetsSourcePicker';
import { useDeleteModal }                                from '@geenee/builder/src/components/AssetsSourcePicker/modal/useDeleteAsset.modal';
import { IntroBox }                                      from '@geenee/builder/src/components/ScreenElements/IntroBox/IntroBox';
import { PhoneContentWrapper }                           from '@geenee/builder/src/components/ScreenElements/PhoneContentWrapper';
import { BasicDrawerContentWrapper }                     from '@geenee/builder/src/magellan/components/drawers/basic';
import { CustomCodeWrapper }                             from '@geenee/builder/src/magellan/components/drawers/customCode/CustomCodeWrapper';
import { ExperienceWrapper }                             from '@geenee/builder/src/magellan/components/experienceView/ExperienceWrapper';
import { BrandingBarWrapper }                            from '@geenee/builder/src/magellan/components/programView/BrandingBarWrapper';
import { CampaignImageWrapper }                          from '@geenee/builder/src/magellan/components/programView/CampaignImageWrapper';
import { SceneControls }                                 from '@geenee/builder/src/magellan/components/scene/scene-controls.component';
import { ExperienceWrapperComponent }                    from "@geenee/builder/src/magellan/experience/experience-wrapper.component";
import { useSceneAssetsActions }                         from '@geenee/builder/src/magellan/hooks/useSceneAssetsActions';
import { CustomCodeTransparentButtonComponent }          from '@geenee/builder/src/module/custom-code/component/button/custom-code-transparent-button.component';
import { GeeLTFSceneComponent }                          from "@geenee/builder/src/module/geeltf-inspector";
import {
    AssetSwitcherItemWrapper,
    ContentWrapper,
    ImageGalleryWrapper,
    SceneBuildWrapper,
    SlamWrapper,
    StampWrapper,
    VideoCanvasWrapper,
    VideoStreamWrapper
} from '../components/contentView';
import { EmptyGallerySlideWrapper } from '../components/EmptyGallerySlideWrapper';
import { SceneControls2d }          from '../components/scene/scene-controls-2d.component';
import { SectionWrapperComponent }  from '../section/section-wrapper.component';

const Viewer3DComponent = observer((props: any) => (
    <GeeneeScene { ...props } type="viewer" mode="cms" />
));

export const cmsDI: DIContextType = {
    ...initDI,
    VideoCanvasWrapper,
    VideoStreamWrapper,
    SlamWrapper,
    StampWrapper,
    SceneBuildWrapper,
    Viewer3D: Viewer3DComponent,
    IntroBox,
    CampaignImageWrapper,
    BrandingBarWrapper,
    ExperienceWrapper,
    ContentWrapper,
    ImageGalleryWrapper,
    BasicDrawerContentWrapper,
    AssetSwitcherItemWrapper,
    CustomCodeTransparentButtonComponent,
    useSceneAssetsActions,
    AssetsSourcePicker,
    CustomCodeWrapper,
    PhoneContentWrapper,
    SectionWrapperComponent,
    ExperienceWrapperComponent,
    EmptyGallerySlideWrapper,
    SceneControls,
    SceneControls2d,
    useDeleteModal,
    GeeLTFSceneComponent
    // SceneAtomComponent:     LoadedAssets,
    // SceneMoleculeComponent: <></> // Grid, Controls, Trackers,
};

export const DI: React.ContextType<DIContextType> = createComponentContext(cmsDI);
