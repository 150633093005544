import React                     from "react";
import { DefaultLoadingManager } from "three/src/loaders/LoadingManager";

export const useLoadingProgress = () => {
    DefaultLoadingManager.onProgress = (
        url: string,
        itemsLoaded: number,
        itemsTotal: number
    ) => {
        const textElement = document.querySelector("#preloader-loaded-percent");
        const progressLine = document.querySelector("#preloader-progress-line");
        const statusText = document.querySelector("#preloader-status-text");

        const percentAmount = ((itemsLoaded / itemsTotal) * 100).toFixed();
        if (textElement) {
            textElement.innerHTML = `${ percentAmount }%`;
        }
        if (progressLine) {
            const numberValue = parseInt(percentAmount, 10);
            if (numberValue > 30) {
                progressLine.classList.remove("anim");
                progressLine.style.width = `${ parseInt(percentAmount, 10) + 1 }%`;

                if (statusText) {
                    if (numberValue > 50) {
                        statusText.style.setProperty(
                            "--preloader-status-text",
                            "\"Sprinkling the pixie dust\""
                        );
                    } else {
                        statusText.style.setProperty(
                            "--preloader-status-text",
                            "\"Entering new dimensions\""
                        );
                    }
                }
            } else {
                progressLine.style.width = "33%";
            }
        }
    };

    const closePreloader = () => {
        const progressLine = document.querySelector(".preloader--progress_line");
        if (progressLine) {
            progressLine.style.width = "101%";
        }

        const elem = document.getElementById("preloader");
        // elem?.parentNode.removeChild(elem);
        if (elem) elem.style.display = "none";
    };

    DefaultLoadingManager.onLoad = () => {
        closePreloader();
    };
};
