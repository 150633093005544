import { HttpClient } from '@geenee/shared';
import envConfig      from '@geenee/builder/src/lib/envConfig';
import { container }  from '@geenee/builder/src/magellan/di/di';

const baseUrl = `${ envConfig.API_URL }/api/v0/sdk_keys`;

export const getSDKAccessKeys = async () => {
    const httpClient: HttpClient = container.get('<HttpClient>');
    return httpClient.get(baseUrl);
};

export const createSDKAccessKey = async (params: { origin: string }) => {
    const httpClient: HttpClient = container.get('<HttpClient>');
    return httpClient.post(
        baseUrl,
        { ...params },
        { headers: { 'content-type': 'application/json', Accept: '*/*' } }
    );
};
export const updateSDKAccessKey = async (
    id: string,
    params: { origin: string }
) => {
    const httpClient: HttpClient = container.get('<HttpClient>');
    return httpClient.put(`${ baseUrl }/${ id }`, { ...params });
};

export const deleteSDKAccessKey = async (id: string) => {
    const httpClient: HttpClient = container.get('<HttpClient>');
    return httpClient.delete(`${ baseUrl }/${ id }`);
};
