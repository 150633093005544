import "@babylonjs/core/Physics/physicsEngineComponent";
import React, { useCallback, useMemo, useState }                      from 'react';
import { Camera }                                                     from "@babylonjs/core/Cameras";
import { Light }                                                      from "@babylonjs/core/Lights";
import { BackgroundMaterial, PBRMaterial, StandardMaterial, Texture } from "@babylonjs/core/Materials";
import { ImageProcessingConfiguration }                               from "@babylonjs/core/Materials/imageProcessingConfiguration";
import { CubeTexture }                                                from "@babylonjs/core/Materials/Textures/cubeTexture";
import { HDRCubeTexture }                                             from "@babylonjs/core/Materials/Textures/hdrCubeTexture";
import type { Vector3 }                                               from "@babylonjs/core/Maths/math.vector";
import { Mesh }                                                       from "@babylonjs/core/Meshes";
import { Observable }                                                 from "@babylonjs/core/Misc/observable";
import { Tools }                                                      from "@babylonjs/core/Misc/tools";
import { Node }                                                       from "@babylonjs/core/node";
import type { Scene }                                                 from "@babylonjs/core/scene";
import type { Nullable }                                              from "@babylonjs/core/types";
import { GLTF2Export, GLTFData }                                      from "@babylonjs/serializers/glTF";
import { EnvironmentSelectorComponent }                               from "@geenee/geespector/src/components/actionTabs/tabs/propertyGrids/environmentSelectorComponent";
import { ButtonLineComponent }                                        from "@geenee/geespector-ui-components/src/lines/buttonLineComponent";
import { CheckBoxLineComponent }                                      from "@geenee/geespector-ui-components/src/lines/checkBoxLineComponent";
import { Color3LineComponent }                                        from "@geenee/geespector-ui-components/src/lines/color3LineComponent";
import { FileButtonLineComponent }                                    from "@geenee/geespector-ui-components/src/lines/fileButtonLineComponent";
import { FloatLineComponent }                                         from "@geenee/geespector-ui-components/src/lines/floatLineComponent";
import { LineContainerComponent }                                     from "@geenee/geespector-ui-components/src/lines/lineContainerComponent";
import { OptionsLineComponent }                                       from "@geenee/geespector-ui-components/src/lines/optionsLineComponent";
import { RadioButtonLineComponent }                                   from "@geenee/geespector-ui-components/src/lines/radioLineComponent";
import { SliderLineComponent }                                        from "@geenee/geespector-ui-components/src/lines/sliderLineComponent";
import { Vector3LineComponent }                                       from "@geenee/geespector-ui-components/src/lines/vector3LineComponent";
import type { LockObject }                                            from "@geenee/geespector-ui-components/src/tabs/propertyGrids/lockObject";
import { Description, Wrapper }                                       from "@geenee/ui";
import GlobalState                                                    from "../../../globalState";
import { PropertyChangedEvent }                                       from "../../../propertyChangedEvent";
import { ActionTabSectionComponent }                                  from "../../actionTabSectionComponent";
import { TextureLinkLineComponent }                                   from "../../lines/textureLinkLineComponent";
import { AnimationGridComponent }                                     from "./animations/animationPropertyGridComponent";
import { FogPropertyGridComponent }                                   from "./fogPropertyGridComponent";

interface IScenePropertyGridComponentProps {
    globalState: GlobalState;
    scene: Scene;
    lockObject: LockObject;
    onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
    onSelectionChangedObservable?: Observable<any>;
}

interface IGlbExportOptions {
    exportDisabledNodes: boolean;
    exportSkyboxes: boolean;
    exportCameras: boolean;
    exportLights: boolean;
}

export const ScenePropertyGridComponent: React.FC<IScenePropertyGridComponentProps> = (props) => {
    const {
        scene, globalState, lockObject, onPropertyChangedObservable, onSelectionChangedObservable
    } = props;
    const [ storedEnvironmentTexture, setStoredEnvironmentTexture ] = useState<any>(null);
    const renderingModeGroupObservable = useMemo(() => new Observable<RadioButtonLineComponent>(), []);

    const gltfExportOptions = useMemo(
        () => ({
            exportDisabledNodes: false,
            exportSkyboxes:      false,
            exportCameras:       false,
            exportLights:        false
        }),
        []
    );

    const [ isExportingGltf, setIsExportingGltf ] = useState(false);

    const setRenderingModes = (point: boolean, wireframe: boolean) => {
        scene.forcePointsCloud = point;
        scene.forceWireframe = wireframe;
    };

    const switchIBL = () => {
        if (scene.environmentTexture) {
            setStoredEnvironmentTexture(scene.environmentTexture);
            scene.environmentTexture = null;
        } else {
            scene.environmentTexture = storedEnvironmentTexture;
            setStoredEnvironmentTexture(null);
        }
    };

    const updateEnvironmentTexture = useCallback((file: File) => {
        const isFileDDS = file.name.toLowerCase().indexOf('.dds') > 0;
        const isFileEnv = file.name.toLowerCase().indexOf('.env') > 0;
        const isFileHdr = file.name.toLowerCase().indexOf('.hdr') > 0;
        if (!isFileDDS && !isFileEnv && !isFileHdr) {
            console.error('Unable to update environment texture. Please select a dds or env file.');
            return;
        }

        Tools.ReadFile(
            file,
            (data) => {
                const blob = new Blob([ data ], { type: 'octet/stream' });
                const url = URL.createObjectURL(blob);
                const prevTexture = scene.environmentTexture?.clone();
                // @ts-ignore
                prevTexture.metadata = { gltf: { extras: { fileName: scene.metadata?.gltf?.extras?.fileName } } };

                if (isFileHdr) {
                    scene.environmentTexture = new HDRCubeTexture(url, scene, 256, false, true, false, true);
                } else if (isFileDDS) {
                    scene.environmentTexture = CubeTexture.CreateFromPrefilteredData(url, scene, '.dds');
                } else {
                    scene.environmentTexture = new CubeTexture(
                        url,
                        scene,
                        undefined,
                        undefined,
                        undefined,
                        () => {},
                        (message) => {
                            if (message) {
                                console.error(message);
                            }
                        },
                        undefined,
                        undefined,
                        '.env'
                    );
                }

                scene.metadata = {
                    ...scene.metadata || {},
                    gltf: {
                        ...scene.metadata?.gltf || {},
                        extras:
                     { ...scene.metadata?.gltf?.extras, fileName: file.name }
                    }
                };
                const value = scene.environmentTexture.clone();
                // @ts-ignore
                value.metadata = { gltf: { extras: { fileName: file.name } } };
                const e = new PropertyChangedEvent();
                e.object = scene;
                e.property = 'environmentTexture';
                e.value = value;
                e.initialValue = prevTexture;
                GlobalState.onPropertyChangedObservable.notifyObservers(e);
            },
            undefined,
            true
        );
    }, [ scene?.environmentTexture ]);

    const updateGravity = (newValue: Vector3) => {
        const physicsEngine = scene.getPhysicsEngine()!;

        physicsEngine.setGravity(newValue);
    };

    const updateTimeStep = (newValue: number) => {
        const physicsEngine = scene.getPhysicsEngine()!;

        physicsEngine.setTimeStep(newValue);
    };

    const normalizeScene = () => {
        scene.meshes.forEach((mesh: any) => {
            mesh.normalizeToUnitCube(true);
            mesh.computeWorldMatrix(true);
        });
    };

    const exportGLTF = () => {
        setIsExportingGltf(true);

        const shouldExport = (node: Node): boolean => {
            if (!gltfExportOptions.exportDisabledNodes) {
                if (!node.isEnabled()) {
                    return false;
                }
            }

            if (!gltfExportOptions.exportSkyboxes) {
                if (node instanceof Mesh) {
                    if (node.material) {
                        const material = node.material as PBRMaterial | StandardMaterial | BackgroundMaterial;
                        const { reflectionTexture } = material;
                        if (reflectionTexture && reflectionTexture.coordinatesMode === Texture.SKYBOX_MODE) {
                            return false;
                        }
                    }
                }
            }

            if (!gltfExportOptions.exportCameras) {
                if (node instanceof Camera) {
                    return false;
                }
            }

            if (!gltfExportOptions.exportLights) {
                if (node instanceof Light) {
                    return false;
                }
            }

            return true;
        };

        GLTF2Export.GLBAsync(scene, 'scene', { shouldExportNode: (node) => shouldExport(node) }).then(
            (glb: GLTFData) => {
                setIsExportingGltf(false);
                glb.downloadFiles();
            },
            () => {
                setIsExportingGltf(false);
            }
        );
    };

    const physicsEngine = scene.getPhysicsEngine();
    let dummy: Nullable<{ gravity: Vector3; timeStep: number }> = null;

    if (physicsEngine) {
        dummy = {
            gravity:  physicsEngine.gravity,
            timeStep: physicsEngine.getTimeStep()
        };
    }

    const imageProcessing = scene.imageProcessingConfiguration;

    const toneMappingOptions = useMemo(
        () => [
            { label: 'Standard', value: ImageProcessingConfiguration.TONEMAPPING_STANDARD },
            { label: 'ACES', value: ImageProcessingConfiguration.TONEMAPPING_ACES }
        ],
        []
    );

    const vignetteModeOptions = useMemo(
        () => [
            { label: 'Multiply', value: ImageProcessingConfiguration.VIGNETTEMODE_MULTIPLY },
            { label: 'Opaque', value: ImageProcessingConfiguration.VIGNETTEMODE_OPAQUE }
        ],
        []
    );
    return (
        <>
            { globalState.isDevMode ? (
                <LineContainerComponent title="RENDERING MODE" selection={ globalState }>
                    <RadioButtonLineComponent
                        onSelectionChangedObservable={ renderingModeGroupObservable }
                        label="Point"
                        isSelected={ () => scene.forcePointsCloud }
                        onSelect={ () => setRenderingModes(true, false) }
                    />
                    <RadioButtonLineComponent
                        onSelectionChangedObservable={ renderingModeGroupObservable }
                        label="Wireframe"
                        isSelected={ () => scene.forceWireframe }
                        onSelect={ () => setRenderingModes(false, true) }
                    />
                    <RadioButtonLineComponent
                        onSelectionChangedObservable={ renderingModeGroupObservable }
                        label="Solid"
                        isSelected={ () => !scene.forcePointsCloud && !scene.forceWireframe }
                        onSelect={ () => setRenderingModes(false, false) }
                    />
                </LineContainerComponent>
            ) : null }
            <ActionTabSectionComponent title="ENVIRONMENT" hasDivider={ false }>
                { globalState.isDevMode && (
                    <>
                        <Color3LineComponent
                            label="Clear color"
                            target={ scene }
                            propertyName="clearColor"
                            onPropertyChangedObservable={ onPropertyChangedObservable }
                        />
                        <CheckBoxLineComponent
                            label="Clear color enabled"
                            target={ scene }
                            propertyName="autoClear"
                            onPropertyChangedObservable={ onPropertyChangedObservable }
                        />
                        <Color3LineComponent
                            label="Ambient color"
                            target={ scene }
                            propertyName="ambientColor"
                            onPropertyChangedObservable={ onPropertyChangedObservable }
                        />
                        <CheckBoxLineComponent
                            label="Environment texture (IBL)"
                            isSelected={ () => scene.environmentTexture != null }
                            onSelect={ () => switchIBL() }
                        />
                    </>
                ) }
                { scene.environmentTexture && (
                    <TextureLinkLineComponent
                        label="Texture"
                        border={ false }
                        ratio={ 1.1 }
                        texture={ scene.environmentTexture }
                        onSelectionChangedObservable={ onSelectionChangedObservable }
                    />
                ) }
                <FileButtonLineComponent
                    icon="add"
                    ratio={ 1.2 }
                    label={ (
                        <Wrapper>
                            <Description size="sm" weight="medium">Upload texture</Description>
                            <Description size="sm" color="shade-3" weight="medium">File formats: dds, env, hdr</Description>
                        </Wrapper>
                    ) }
                    onClick={ (file) => updateEnvironmentTexture(file) }
                    accept=".dds, .env, .hdr"
                />
                <EnvironmentSelectorComponent
                    defaultEnvironment={ scene.metadata?.gltf?.extras?.fileName }
                    onChange={ updateEnvironmentTexture }
                />
                <SliderLineComponent
                    minimum={ 0 }
                    maximum={ 2 }
                    step={ 0.01 }
                    label="IBL Intensity"
                    target={ scene }
                    propertyName="environmentIntensity"
                    onPropertyChangedObservable={ onPropertyChangedObservable }
                />
                <FogPropertyGridComponent
                    lockObject={ lockObject }
                    scene={ scene }
                    onPropertyChangedObservable={ onPropertyChangedObservable }
                />
            </ActionTabSectionComponent>
            <AnimationGridComponent
                globalState={ globalState }
                animatable={ scene }
                scene={ scene }
                lockObject={ lockObject }
            />
            { globalState.isDevMode ? (
                <LineContainerComponent title="MATERIAL IMAGE PROCESSING" selection={ globalState }>
                    <SliderLineComponent
                        minimum={ 0 }
                        maximum={ 4 }
                        step={ 0.1 }
                        label="Contrast"
                        target={ imageProcessing }
                        propertyName="contrast"
                        onPropertyChangedObservable={ onPropertyChangedObservable }
                    />
                    <SliderLineComponent
                        minimum={ 0 }
                        maximum={ 4 }
                        step={ 0.1 }
                        label="Exposure"
                        target={ imageProcessing }
                        propertyName="exposure"
                        onPropertyChangedObservable={ onPropertyChangedObservable }
                    />
                    <CheckBoxLineComponent
                        label="Tone mapping"
                        target={ imageProcessing }
                        propertyName="toneMappingEnabled"
                        onPropertyChangedObservable={ onPropertyChangedObservable }
                    />
                    <OptionsLineComponent
                        label="Tone mapping type"
                        options={ toneMappingOptions }
                        target={ imageProcessing }
                        propertyName="toneMappingType"
                        onPropertyChangedObservable={ onPropertyChangedObservable }
                        onSelect={ (value) => setState({ mode: value }) }
                    />
                    <CheckBoxLineComponent
                        label="Vignette"
                        target={ imageProcessing }
                        propertyName="vignetteEnabled"
                        onPropertyChangedObservable={ onPropertyChangedObservable }
                    />
                    <SliderLineComponent
                        minimum={ 0 }
                        maximum={ 4 }
                        step={ 0.1 }
                        label="Vignette weight"
                        target={ imageProcessing }
                        propertyName="vignetteWeight"
                        onPropertyChangedObservable={ onPropertyChangedObservable }
                    />
                    <SliderLineComponent
                        minimum={ 0 }
                        maximum={ 1 }
                        step={ 0.1 }
                        label="Vignette stretch"
                        target={ imageProcessing }
                        propertyName="vignetteStretch"
                        onPropertyChangedObservable={ onPropertyChangedObservable }
                    />
                    <SliderLineComponent
                        minimum={ 0 }
                        maximum={ Math.PI }
                        step={ 0.1 }
                        label="Vignette FOV"
                        target={ imageProcessing }
                        propertyName="vignetteCameraFov"
                        onPropertyChangedObservable={ onPropertyChangedObservable }
                    />
                    <SliderLineComponent
                        minimum={ 0 }
                        maximum={ 1 }
                        step={ 0.1 }
                        label="Vignette center X"
                        target={ imageProcessing }
                        propertyName="vignetteCentreX"
                        onPropertyChangedObservable={ onPropertyChangedObservable }
                    />
                    <SliderLineComponent
                        minimum={ 0 }
                        maximum={ 1 }
                        step={ 0.1 }
                        label="Vignette center Y"
                        target={ imageProcessing }
                        propertyName="vignetteCentreY"
                        onPropertyChangedObservable={ onPropertyChangedObservable }
                    />
                    <Color3LineComponent
                        label="Vignette color"
                        target={ imageProcessing }
                        propertyName="vignetteColor"
                        onPropertyChangedObservable={ onPropertyChangedObservable }
                    />
                    <OptionsLineComponent
                        label="Vignette blend mode"
                        options={ vignetteModeOptions }
                        target={ imageProcessing }
                        propertyName="vignetteBlendMode"
                        onPropertyChangedObservable={ onPropertyChangedObservable }
                        onSelect={ (value) => setState({ mode: value }) }
                    />
                </LineContainerComponent>
            ) : null }
            { dummy !== null && (
                <LineContainerComponent title="PHYSICS" closed selection={ globalState }>
                    <FloatLineComponent
                        lockObject={ lockObject }
                        label="Time step"
                        target={ dummy }
                        propertyName="timeStep"
                        onChange={ (newValue) => updateTimeStep(newValue) }
                        onPropertyChangedObservable={ onPropertyChangedObservable }
                    />
                    <Vector3LineComponent
                        label="Gravity"
                        target={ dummy }
                        propertyName="gravity"
                        onChange={ (newValue) => updateGravity(newValue) }
                        onPropertyChangedObservable={ onPropertyChangedObservable }
                    />
                </LineContainerComponent>
            ) }
            { globalState.isDevMode ? (
                <LineContainerComponent title="COLLISIONS" closed selection={ globalState }>
                    <Vector3LineComponent
                        label="Gravity"
                        target={ scene }
                        propertyName="gravity"
                        onPropertyChangedObservable={ onPropertyChangedObservable }
                    />
                </LineContainerComponent>
            ) : null }
            { globalState.isDevMode ? (
                <>
                    <LineContainerComponent title="SHADOWS" closed selection={ globalState }>
                        <ButtonLineComponent label="Normalize scene" onClick={ () => normalizeScene() } />
                    </LineContainerComponent>
                    <LineContainerComponent title="EXPORT" closed={ false } selection={ globalState }>
                        <ButtonLineComponent label="Export to GLB" onClick={ () => exportGLTF() } />
                    </LineContainerComponent>
                </>
            ) : null }
        </>
    );
};
