import * as React                              from "react";
import type { BackgroundMaterial }             from "@babylonjs/core/Materials/Background/backgroundMaterial";
import { Observable }                          from "@babylonjs/core/Misc/observable";
import { CheckBoxLineComponent }               from "@geenee/geespector-ui-components/src/lines/checkBoxLineComponent";
import { Color3LineComponent }                 from "@geenee/geespector-ui-components/src/lines/color3LineComponent";
import { SliderLineComponent }                 from "@geenee/geespector-ui-components/src/lines/sliderLineComponent";
import type { LockObject }                     from "@geenee/geespector-ui-components/src/tabs/propertyGrids/lockObject";
import { Wrapper }                             from '@geenee/ui';
import type { GlobalState }                    from "../../../../globalState";
import type { PropertyChangedEvent }           from "../../../../propertyChangedEvent";
import { ActionTabSectionComponent }           from '../../../actionTabSectionComponent';
import { TextureLinkLineComponent }            from "../../../lines/textureLinkLineComponent";
import { CommonMaterialPropertyGridComponent } from "./commonMaterialPropertyGridComponent";

interface IBackgroundMaterialPropertyGridComponentProps {
    globalState: GlobalState;
    material: BackgroundMaterial;
    lockObject: LockObject;
    onSelectionChangedObservable?: Observable<any>;
    onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
}

export class BackgroundMaterialPropertyGridComponent extends React.Component<IBackgroundMaterialPropertyGridComponentProps> {
    private _onDebugSelectionChangeObservable = new Observable<TextureLinkLineComponent>();

    constructor(props: IBackgroundMaterialPropertyGridComponentProps) {
        super(props);
    }

    renderTextures() {
        const { material } = this.props;

        const onDebugSelectionChangeObservable = this._onDebugSelectionChangeObservable;

        return (
            <ActionTabSectionComponent title="TEXTURES" collapsed>
                <TextureLinkLineComponent
                    label="Diffuse"
                    texture={ material.diffuseTexture }
                    material={ material }
                    onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                    onDebugSelectionChangeObservable={ onDebugSelectionChangeObservable }
                />
                <TextureLinkLineComponent
                    label="Reflection"
                    texture={ material.reflectionTexture }
                    material={ material }
                    onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                    onDebugSelectionChangeObservable={ onDebugSelectionChangeObservable }
                />
                { material.reflectionTexture && (
                    <SliderLineComponent
                        label="Reflection blur"
                        target={ material }
                        propertyName="reflectionBlur"
                        minimum={ 0 }
                        maximum={ 1 }
                        step={ 0.01 }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                ) }
            </ActionTabSectionComponent>
        );
    }

    render() {
        const { material } = this.props;

        return (
            <Wrapper className="pane">
                <CommonMaterialPropertyGridComponent
                    globalState={ this.props.globalState }
                    lockObject={ this.props.lockObject }
                    material={ material }
                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                />
                <ActionTabSectionComponent title="LIGHTING & COLORS" collapsed>
                    <Color3LineComponent label="Primary" target={ material } propertyName="primaryColor" onPropertyChangedObservable={ this.props.onPropertyChangedObservable } />
                    <SliderLineComponent
                        label="Shadow level"
                        target={ material }
                        propertyName="primaryColorShadowLevel"
                        minimum={ 0 }
                        maximum={ 1 }
                        step={ 0.01 }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                    <SliderLineComponent
                        label="Highlight level"
                        target={ material }
                        propertyName="primaryColorHighlightLevel"
                        minimum={ 0 }
                        maximum={ 1 }
                        step={ 0.01 }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                </ActionTabSectionComponent>
                { this.props.globalState.isDevMode && this.renderTextures() }
                <ActionTabSectionComponent title="RENDERING" collapsed hasDivider={ false }>
                    { this.props.globalState.isDevMode && (
                        <CheckBoxLineComponent
                            label="Enable noise"
                            target={ material }
                            propertyName="enableNoise"
                            onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                        />
                    ) }
                    <CheckBoxLineComponent
                        label="Opacity fresnel"
                        target={ material }
                        propertyName="opacityFresnel"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                    <CheckBoxLineComponent
                        label="Reflection fresnel"
                        target={ material }
                        propertyName="reflectionFresnel"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                    <SliderLineComponent
                        label="Reflection amount"
                        target={ material }
                        propertyName="reflectionAmount"
                        minimum={ 0 }
                        maximum={ 1 }
                        step={ 0.01 }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                </ActionTabSectionComponent>
            </Wrapper>
        );
    }
}
