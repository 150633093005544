import React, { memo } from 'react';
import { Button, cn }  from '@geenee/ui';

type Props = {
  checked: boolean;
  onClick: () => void;
}

const className = cn('asset-library');

export const DeleteCheckbox = memo((props: Props) => (
    <div className={ className('action', { position: 'left', active: props.checked }) }>
        <Button
            viewType="action"
            size="xs"
            radius="sm"
            icon={ props.checked ? "checkboxCheckedNew" : "checkboxUncheckedNew" }
            onClick={ (e) => {
                e.stopPropagation();
                e.preventDefault();
                props.onClick();
            } }
        />
    </div>
));
