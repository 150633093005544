// @ts-ignore
import { Vector3 } from 'three';

// eslint-disable-next-line no-shadow
export enum TransformModes {
    translate = 'translate',
    rotate = 'rotate',
    scale = 'scale',
    lightning = 'lightning',
    boundingBox = 'boundingBox',
}

export type SceneStats = {
    type: string;
    data: Vector3 | [ x: number, y: number, z: number ] | undefined;
    hidden?: boolean;
};
