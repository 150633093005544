import React, { FC, useEffect, useRef } from 'react';
import { GestureControls }              from "@geenee/geeclient-kit/src/lib/component/scene/components/GestureControls";
import ShadowReceiverPlane              from "@geenee/geeclient-kit/src/lib/component/scene/utils/sdk/ShadowReceiverPlane";
import { CallbackFunctionVariadic }     from "@geenee/geeclient-kit/src/lib/type/type";
import { AtomModel }                    from '@geenee/shared';
import { useLoadingProgress }           from '@geenee/shared/src/magellan/molecule/component/SceneMolecule/useLoadingProgress';
import { SceneModelType }               from '@geenee/shared/src/magellan/renderer/r3f-renderer/r3f.renderer';
import { useFrame }                     from '@react-three/fiber';
import { observer }                     from 'mobx-react';
import { Group, Quaternion }            from 'three';
import { AppStateType }                 from '../../../state/app.state';

// Define some constants here, export if we have more of them
export const RAD_TO_DEG = 180 / Math.PI;
export const DEG_TO_RAD = Math.PI / 180;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fps = 1 / 24;

export type GLTFComponentPropsType = {
    url?: string,
    activeSceneModel: SceneModelType;
    appState: AppStateType;
    arModel: any;
    onLoad: CallbackFunctionVariadic;
    setIsModelLoaded?: (value: boolean) => void
    appRef: React.RefObject<any>;
    atoms: React.ReactNode;
    audioAtom?: AtomModel
    isActive: boolean
};

export const GLTFTemplate: FC<GLTFComponentPropsType> = observer(({
    activeSceneModel,
    arModel,
    setIsModelLoaded,
    audioAtom,
    isActive,
    ...props
}) => {
    // This reference will give us direct access to the mesh

    const gltfWrap = useRef();
    const matrixTargetGroup = useRef();

    const loopCount = useRef(0);

    const clearAudioData = () => {
        if (isActive && activeSceneModel.audio) {
            activeSceneModel.audioInit(undefined);
        }
    };

    useEffect(() => {
        if (isActive) {
            activeSceneModel.audioInit(audioAtom);
        }
        return () => {
            clearAudioData();
        };
    }, []);

    // Run animation only after first time scan
    useEffect(() => {
        if (props.visible && !gltfWrap.current.visible) {
            const { mixer } = activeSceneModel.animationController;
            if (mixer) {
                mixer.addEventListener('loop', () => {
                    loopCount.current += 1;
                    activeSceneModel.emitter.emit('after-animation-loop', loopCount.current);
                });
            }
        }
        gltfWrap.current.visible = true;
    }, [ props.visible, arModel ]);

    const isSlamType = activeSceneModel.scene_experience_type === 'slam-ar';
    useFrame(() => {
        const _matrix = activeSceneModel.options.overlayMatrix;

        if (_matrix && matrixTargetGroup.current !== null) {
            const targetGroup: Group = matrixTargetGroup.current;
            if (isSlamType) {
                // NEW WAY TO APPLY THE MATRIX (FOR SLAM):
                targetGroup.position.set(0, 0, 0);
                targetGroup.scale.set(1, 1, 1);
                targetGroup.updateMatrix();

                targetGroup.position.setFromMatrixPosition(_matrix);
                targetGroup.scale.setFromMatrixScale(_matrix);
                targetGroup.quaternion.slerp(new Quaternion().setFromRotationMatrix(_matrix), 0.35); // smooth rotation
                // targetGroup.position.lerp(new Vector3().setFromMatrixPosition(_matrix), 0.95); // smooth transform
                // targetGroup.scale.lerp(new Vector3().setFromMatrixScale(_matrix), 0.95); // smooth scale

                targetGroup.updateMatrix();
            } else {
                // OLD MATRIX APPLY METHOD (FOR TRACKING)
                _matrix.decompose(targetGroup.position, targetGroup.quaternion, targetGroup.scale);
                targetGroup.updateMatrix();
            }
        }
    });

    // NOTE Define exported methods here
    window.GeeneeAR = {
        ...window.GeeneeAR,
        playAnimations:           activeSceneModel.animationController.playAll,
        playAllAnimations:        activeSceneModel.animationController.playAll,
        stopAllAnimations:        activeSceneModel.animationController.stopAll,
        pauseAllAnimations:       activeSceneModel.animationController.pauseAll,
        playAnimationClip:        activeSceneModel.animationController.playClip,
        stopAnimationClip:        activeSceneModel.animationController.stopClip,
        pauseAnimationClip:       activeSceneModel.animationController.pauseClip,
        getAnimationClipByName:   activeSceneModel.animationController.getClipByName,
        getAnimationActionByName: activeSceneModel.animationController.getActionByName
    };

    useLoadingProgress();

    const LightsComponent = activeSceneModel._children.lights_component.component;
    return (
        <group ref={ matrixTargetGroup } name="geenee-3d-matrix-target-group">

            <LightsComponent />

            <GestureControls sceneModel={ activeSceneModel }>

                <group
                    name="geenee-3d-object-wrapper-1"
                    ref={ gltfWrap }
                    onClick={ (e) => {
                        e.stopPropagation();
                    } }
                    visible={ false }
                >

                    { React.Children.map(props.atoms, (atomComponent, index) => {
                        if (React.isValidElement(atomComponent)) {
                            return React.cloneElement(atomComponent, {
                                ...atomComponent.props,
                                activeSceneModel
                            });
                        }
                    }) }

                </group>

            </GestureControls>

            { activeSceneModel.shadows && activeSceneModel.scene_experience_type === 'slam-ar'
                && <ShadowReceiverPlane activeSceneModel={ activeSceneModel } /> }

        </group>

    );
});
// eslint-disable-next-line arca/no-default-export
export default GLTFTemplate;
