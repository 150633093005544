import React                                                from 'react';
import { DndProvider }                                      from 'react-dnd';
import { HTML5Backend }                                     from 'react-dnd-html5-backend';
import { cn, Wrapper }                                      from '@geenee/ui';
import { observer }                                         from 'mobx-react';
import { ImageGallery }                                     from '@geenee/builder/src/components/ImageGallery/ImageGallery';
import { experienceSizeErrorTemplate, useShowErrorMessage } from '@geenee/builder/src/hooks/useShowErrorMessage';
import { EXPERIENCE_MAX_SIZE_MB, TYPE_FULLSCREEN_GALLERY }  from '@geenee/builder/src/lib/constants';
import { AtomModel }                                        from '@geenee/builder/src/magellan/model/atom.model';
import { MoleculeModel }                                    from '@geenee/builder/src/magellan/model/molecule.model';
import { SectionModel }                                     from '@geenee/builder/src/magellan/model/section.model';
import './styles.scss';

type ComponentProps = {
    activeSection: SectionModel;
    type: string;
    disabled?: boolean;
};

const className = cn('image-gallery-asset');

export const ContentSetImageGalleryAsset = observer(({ activeSection, type, disabled }: ComponentProps) => {
    const { activeMolecule } = activeSection as SectionModel & {activeMolecule: MoleculeModel};
    // TODO: Check if we need to filter the atoms
    const childImages = Array.from(activeMolecule?.atomsRegistry.values() || []).filter((el) => el.type === 'gallery-image')
        .sort((a, b) => a.options.order! - b.options.order!);

    const handleImageDelete = (id: string) => {
        activeMolecule?.deleteAtom(id);
    };
    const { showErrorNotification } = useShowErrorMessage();

    const isValidOverallAssetsSize = (files: File[]) => {
        let sizeSum = childImages.reduce((acc, curr) => acc + (curr?.options?.atom_image_file_size || 0), 0);
        const filesSizeSum = files.reduce((acc, curr) => acc + curr.size, 0);
        sizeSum += filesSizeSum;
        if (sizeSum > EXPERIENCE_MAX_SIZE_MB * 1048576) {
            const diff = (EXPERIENCE_MAX_SIZE_MB * 1048576 - sizeSum) / -1048576;
            // @ts-ignore
            showErrorNotification({ message: diff.toFixed(2), summary: 'File size exceeded' });
            return false;
        }
        return true;
    };

    const onDrop = async (files: File[]) => {
        if (isValidOverallAssetsSize(files)) {
            await Promise.all(files.map((
                file
            ) => activeMolecule?.addAtomWithAttachment(file, { type: 'gallery-image', options: { atom_image_file_size: file.size } })));
        }
    };

    if (type !== TYPE_FULLSCREEN_GALLERY || !childImages?.length) {
        return <></>;
    }

    return (
        <Wrapper
            className={ className('') }
            radius="xl"
            padding="md"
            frame="solid-grey-border-grey"
        >
            <DndProvider backend={ HTML5Backend }>
                <ImageGallery
                    dragAndDrop={ !disabled }
                    onDrop={ onDrop }
                    images={ childImages as AtomModel[] }
                    handleDelete={ handleImageDelete }
                    activeSection={ activeSection }
                />
            </DndProvider>
        </Wrapper>
    );
});
