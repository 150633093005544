import queryString          from 'querystring';
import React, { useEffect } from 'react';
import { useHistory }       from "@geenee/common";
import { observer }         from 'mobx-react';
import { useBuilderInject } from '@geenee/builder/src/core/hook/use-builder-inject';

export const AuthRedirectPage = observer(() => {
    const { BuilderState } = useBuilderInject();
    const history = useHistory();
    useEffect(() => {
        const query = queryString.parse(window.location.search.slice(1));
        const {
            token,
            gringotts_token
        } = query;
        if (token && !Array.isArray(token) && !Array.isArray(gringotts_token)) {
            BuilderState.authByToken(token, gringotts_token);
            history.push("/");
        }
    }, []);

    return <></>;
});
