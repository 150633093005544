import React from 'react';

// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '40',
    height = '40',
    onClick,
    id,
    fill = 'black'
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
            onClick={ onClick }
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M33.3333 8.3335H19.2253L16.3867 4.07566C16.0776 3.612 15.5572 3.3335 15 3.3335H6.66663C3.9052 3.3335 1.66663 5.57207 1.66663 8.3335V31.6668C1.66663 34.4283 3.9052 36.6668 6.66663 36.6668H33.3333C36.0947 36.6668 38.3333 34.4283 38.3333 31.6668V13.3335C38.3333 10.5721 36.0947 8.3335 33.3333 8.3335ZM6.66663 6.66686H14.108L16.9465 10.9247C17.2557 11.3884 17.776 11.6669 18.3333 11.6669H33.3333C34.2538 11.6669 35 12.413 35 13.3335V31.6669C35 32.5873 34.2538 33.3335 33.3333 33.3335H6.66663C5.74616 33.3335 4.99997 32.5873 4.99997 31.6669V8.33352C4.99997 7.41305 5.74616 6.66686 6.66663 6.66686Z"
                fill="black"
            />
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="3" width="38" height="34">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    // eslint-disable-next-line max-len
                    d="M33.3333 8.3335H19.2253L16.3867 4.07566C16.0776 3.612 15.5572 3.3335 15 3.3335H6.66663C3.9052 3.3335 1.66663 5.57207 1.66663 8.3335V31.6668C1.66663 34.4283 3.9052 36.6668 6.66663 36.6668H33.3333C36.0947 36.6668 38.3333 34.4283 38.3333 31.6668V13.3335C38.3333 10.5721 36.0947 8.3335 33.3333 8.3335ZM6.66663 6.66686H14.108L16.9465 10.9247C17.2557 11.3884 17.776 11.6669 18.3333 11.6669H33.3333C34.2538 11.6669 35 12.413 35 13.3335V31.6669C35 32.5873 34.2538 33.3335 33.3333 33.3335H6.66663C5.74616 33.3335 4.99997 32.5873 4.99997 31.6669V8.33352C4.99997 7.41305 5.74616 6.66686 6.66663 6.66686Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0)">
                <rect width="40" height="40" fill={ fill } />
            </g>
        </svg>
    );
}
