import queryString                from 'querystring';
import React, { useEffect }       from 'react';
import { useHistory }             from "@geenee/common";
import { LoadingSpinnerCentered } from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { useBuilderInject }       from '@geenee/builder/src/core/hook/use-builder-inject';
import { sketchfabState }         from '@geenee/builder/src/core/state/sketchfab.state';
import { TOAST_ERROR }            from '@geenee/builder/src/lib/constants';

export function SketchfabAuthPage() {
    const { BuilderState } = useBuilderInject();
    const history = useHistory();
    const query = queryString.parse(window.location.href.split('#')[ 1 ]);

    useEffect(() => {
        const { state, access_token } = query as {access_token?: string; state?: string};
        if (!access_token) {
            history.push('/');
        } else {
            try {
                sketchfabState.setAccessToken(access_token);
                history.push(state ? `${ state.split(window.location.origin)[ 1 ] }` : '/');
            } catch (e: any) {
                BuilderState.update('toast', {
                    severity: TOAST_ERROR,
                    detail:   e.message
                });
                history.push('/');
            }
        }
    }, [ ]);

    return <LoadingSpinnerCentered />;
}
