/* eslint-disable max-len */
import React, { SyntheticEvent } from 'react';

export function Close() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.53034 4.46967C4.82323 4.17678 5.29811 4.17678 5.591 4.46967L10.5303 9.40901C10.8232 9.7019 10.8232 10.1768 10.5303 10.4697C10.2374 10.7626 9.76257 10.7626 9.46968 10.4697L4.53034 5.53033C4.23745 5.23744 4.23745 4.76256 4.53034 4.46967Z"
                fill="#222225"
            />
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.4697 4.46967C10.1768 4.17678 9.70189 4.17678 9.409 4.46967L4.46966 9.40901C4.17677 9.7019 4.17677 10.1768 4.46966 10.4697C4.76255 10.7626 5.23743 10.7626 5.53032 10.4697L10.4697 5.53033C10.7626 5.23744 10.7626 4.76256 10.4697 4.46967Z"
                fill="#222225"
            />
        </svg>
    );
}
export function Close2({ onClick }: {
  onClick: (e?: SyntheticEvent) => void;
}) {
    return (
        <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={ onClick }
        >
            <rect
                className='fill'
                x="8.81543"
                y="0.0172729"
                width="1.24568"
                height="12.4568"
                rx="0.622839"
                transform="rotate(45 8.81543 0.0172729)"
                fill="#4E4E4E"
            />
            <rect
                className='fill'
                width="1.24568"
                height="12.4568"
                rx="0.622839"
                transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 8.81543 9.98273)"
                fill="#4E4E4E"
            />
        </svg>
    );
}
export function Copy() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                className='fill'
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.66675 9.33329H3.33341C3.7016 9.33329 4.00008 9.63177 4.00008 9.99996C4.00008 10.3681 3.7016 10.6666 3.33341 10.6666H2.66675C1.56218 10.6666 0.666748 9.7712 0.666748 8.66663V2.66663C0.666748 1.56206 1.56218 0.666626 2.66675 0.666626H8.66675C9.77132 0.666626 10.6667 1.56206 10.6667 2.66663V3.33329C10.6667 3.70148 10.3683 3.99996 10.0001 3.99996C9.63189 3.99996 9.33342 3.70148 9.33342 3.33329V2.66663C9.33342 2.29844 9.03494 1.99996 8.66675 1.99996H2.66675C2.29856 1.99996 2.00008 2.29844 2.00008 2.66663V8.66663C2.00008 9.03482 2.29856 9.33329 2.66675 9.33329ZM7.33342 5.33329H13.3334C14.438 5.33329 15.3334 6.22872 15.3334 7.33329V13.3333C15.3334 14.4379 14.438 15.3333 13.3334 15.3333H7.33342C6.22885 15.3333 5.33342 14.4379 5.33342 13.3333V7.33329C5.33342 6.22872 6.22885 5.33329 7.33342 5.33329ZM7.33342 6.66663C6.96523 6.66663 6.66675 6.9651 6.66675 7.33329V13.3333C6.66675 13.7015 6.96523 14 7.33342 14H13.3334C13.7016 14 14.0001 13.7015 14.0001 13.3333V7.33329C14.0001 6.9651 13.7016 6.66663 13.3334 6.66663H7.33342Z"
                fill="black"
            />
            <mask
                id="mask0_25216_200503"
                style={ { maskType: 'alpha' } }
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="16"
                height="16"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.66675 9.33329H3.33341C3.7016 9.33329 4.00008 9.63177 4.00008 9.99996C4.00008 10.3681 3.7016 10.6666 3.33341 10.6666H2.66675C1.56218 10.6666 0.666748 9.7712 0.666748 8.66663V2.66663C0.666748 1.56206 1.56218 0.666626 2.66675 0.666626H8.66675C9.77132 0.666626 10.6667 1.56206 10.6667 2.66663V3.33329C10.6667 3.70148 10.3683 3.99996 10.0001 3.99996C9.63189 3.99996 9.33342 3.70148 9.33342 3.33329V2.66663C9.33342 2.29844 9.03494 1.99996 8.66675 1.99996H2.66675C2.29856 1.99996 2.00008 2.29844 2.00008 2.66663V8.66663C2.00008 9.03482 2.29856 9.33329 2.66675 9.33329ZM7.33342 5.33329H13.3334C14.438 5.33329 15.3334 6.22872 15.3334 7.33329V13.3333C15.3334 14.4379 14.438 15.3333 13.3334 15.3333H7.33342C6.22885 15.3333 5.33342 14.4379 5.33342 13.3333V7.33329C5.33342 6.22872 6.22885 5.33329 7.33342 5.33329ZM7.33342 6.66663C6.96523 6.66663 6.66675 6.9651 6.66675 7.33329V13.3333C6.66675 13.7015 6.96523 14 7.33342 14H13.3334C13.7016 14 14.0001 13.7015 14.0001 13.3333V7.33329C14.0001 6.9651 13.7016 6.66663 13.3334 6.66663H7.33342Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_25216_200503)">
                <rect className='fill' width="16" height="16" fill="black" />
            </g>
        </svg>
    );
}
export function Delete() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2V4H14C14.5523 4 15 4.44772 15 5C15 5.55228 14.5523 6 14 6H2C1.44772 6 1 5.55228 1 5C1 4.44772 1.44772 4 2 4H4V2C4 1.4 4.4 1 5 1H11C11.6 1 12 1.4 12 2ZM3 13V8H13V13C13 14.1 12.1 15 11 15H5C3.9 15 3 14.1 3 13Z"
                fill="#222225"
            />
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2V4H14C14.5523 4 15 4.44772 15 5C15 5.55228 14.5523 6 14 6H2C1.44772 6 1 5.55228 1 5C1 4.44772 1.44772 4 2 4H4V2C4 1.4 4.4 1 5 1H11C11.6 1 12 1.4 12 2ZM3 13V8H13V13C13 14.1 12.1 15 11 15H5C3.9 15 3 14.1 3 13Z"
                fill="#222225"
            />
        </svg>
    );
}
export function Add() {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_i_22696_9154)">
                <path
                    className="fill"
                    d="M16.912 7.91091H10.0912V1.09016C10.0912 0.490787 9.60045 0 9.00107 0C8.40168 0 7.91091 0.490771 7.91091 1.09016V7.91091H1.09016C0.490787 7.91091 0 8.40168 0 9.00107C0 9.60046 0.490771 10.0912 1.09016 10.0912H7.91091V16.9098C7.91091 17.5092 8.40168 18 9.00107 18C9.60045 18 10.0912 17.5092 10.0912 16.9098V10.0912H16.9098C17.5092 10.0912 18 9.60046 18 9.00107C18.0021 8.4017 17.5113 7.91091 16.9119 7.91091H16.912Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_i_22696_9154"
                    x="0"
                    y="0"
                    width="18"
                    height="20"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_22696_9154"
                    />
                </filter>
            </defs>
        </svg>
    );
}
export function Restore() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00003 1.25L8.03745 1.2501C9.61467 1.25867 11.0638 1.80819 12.2088 2.72256C12.629 3.05803 13.0081 3.44261 13.3377 3.86768C14.11 4.86392 14.6097 6.08223 14.7246 7.41061C14.7414 7.60496 14.75 7.80155 14.75 8C14.75 8.29521 14.731 8.58631 14.6941 8.87206C14.6818 8.96731 14.6675 9.06196 14.6513 9.15598C14.4877 10.1032 14.1267 10.9829 13.6126 11.7509L13.6126 11.7509C13.1201 12.4867 12.4867 13.12 11.751 13.6126L10.9165 12.3662C11.1114 12.2357 11.2971 12.0925 11.4724 11.9377L11.4724 11.9377C11.6236 11.8043 11.767 11.6623 11.9019 11.5126C11.9554 11.4532 12.0076 11.3926 12.0584 11.3307C12.1676 11.1979 12.2703 11.0597 12.3662 10.9165L12.3662 10.9165C12.9248 10.0821 13.25 9.07924 13.25 8C13.25 5.13485 10.9548 2.80575 8.10277 2.75098C8.06861 2.75033 8.03436 2.75 8.00003 2.75H7.99998C6.41954 2.75 5.00156 3.44809 4.03841 4.55468L5.65116 6.17486C5.96502 6.49018 5.74169 7.0276 5.29679 7.0276H0.5L0.500017 2.21102C0.500019 1.76502 1.03973 1.54219 1.35438 1.85829L2.9782 3.48959C4.21325 2.11548 6.00526 1.25 7.99998 1.25H8.00003ZM2.32535 11.6566C2.15194 11.3879 1.99702 11.1061 1.86243 10.8128L3.2257 10.1872C3.33024 10.4149 3.45066 10.634 3.58558 10.843L2.32535 11.6566ZM5.19792 14.1425C4.6092 13.8736 4.06625 13.5227 3.58353 13.1047L4.56539 11.9707C4.94146 12.2964 5.36392 12.5692 5.82121 12.7781L5.19792 14.1425ZM9.31655 14.6215C8.89015 14.7059 8.44987 14.75 8.00003 14.75C7.67489 14.75 7.3547 14.727 7.04109 14.6823L7.25251 13.1973C7.49629 13.232 7.74584 13.25 8.00003 13.25C8.35161 13.25 8.6944 13.2155 9.02545 13.1501L9.31655 14.6215Z"
                fill="#222225"
            />
        </svg>
    );
}
export function Edit() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
        >
            <path
                className='fill'
                d="M10.761 0.431078C10.1862 -0.143693 9.25776 -0.143693 8.68291 0.431078L1.12239 7.9766C1.0931 8.00589 1.07818 8.02082 1.06326 8.05011C1.06326 8.05011 1.06326 8.06503 1.04834 8.06503C1.03341 8.09432 1.01904 8.10924 1.01904 8.13853V8.15345L0.0170686 11.4252C-0.0271445 11.5872 0.0170686 11.7496 0.120417 11.8674C0.208843 11.9558 0.312196 12 0.429909 12C0.474122 12 0.518335 12 0.562549 11.9851L3.81947 10.9831H3.83439C3.86368 10.9682 3.89352 10.9682 3.90789 10.9538C3.90789 10.9538 3.92282 10.9538 3.92282 10.9389C3.95211 10.924 3.98195 10.9096 3.99632 10.8798L11.5418 3.33423C12.1166 2.75946 12.1166 1.83098 11.5418 1.25614L10.761 0.431078ZM1.10768 10.8798L1.65316 9.12614L2.87621 10.3492L1.10768 10.8798ZM3.7019 9.92205L2.06608 8.30109L7.7845 2.58267L9.42032 4.21848L3.7019 9.92205ZM10.923 2.68595L10.0387 3.57021L8.41778 1.94869L9.30204 1.06442C9.53803 0.828431 9.9061 0.828431 10.1421 1.06442L10.9379 1.86026C11.159 2.08133 11.159 2.46487 10.923 2.68594L10.923 2.68595Z"
                fill="black"
            />
        </svg>
    );
}
export function ToGround() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="18"
            fill="none"
            viewBox="0 0 17 18"
        >
            <path
                fill="#000"
                d="M7.41 15.53a.75.75 0 001.061 0l4.774-4.773a.75.75 0 00-1.061-1.06L7.94 13.939 3.7 9.697a.75.75 0 10-1.061 1.06L7.41 15.53zM7.192 0v15h1.5V0h-1.5z"
            />
            <rect width="16.875" height="2" y="16" fill="#000" rx="1" />
        </svg>
    );
}
export function MoveTo() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.29289 3.70711C8.90237 3.31658 8.90237 2.68342 9.29289 2.29289C9.68342 1.90237 10.3166 1.90237 10.7071 2.29289L15.7071 7.29289C16.0976 7.68342 16.0976 8.31658 15.7071 8.70711L10.7071 13.7071C10.3166 14.0976 9.68342 14.0976 9.29289 13.7071C8.90237 13.3166 8.90237 12.6834 9.29289 12.2929L12.5858 9L9 9C8.44771 9 8 8.55228 8 8C8 7.44771 8.44772 7 9 7L12.5858 7L9.29289 3.70711ZM1 7H1.5C2.05228 7 2.5 7.44771 2.5 8C2.5 8.55228 2.05228 9 1.5 9H1C0.447715 9 0 8.55228 0 8C0 7.44771 0.447715 7 1 7ZM6 9C6.55228 9 7 8.55228 7 8C7 7.44772 6.55228 7 6 7H5C4.44772 7 4 7.44772 4 8C4 8.55228 4.44772 9 5 9H6Z"
                fill="#222225"
            />
        </svg>
    );
}
export function Export() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.16663 0.75C8.26186 0.75 8.35295 0.76775 8.43676 0.800123C8.5316 0.836661 8.6205 0.893177 8.697 0.96967L11.8637 4.13634C12.1566 4.42923 12.1566 4.9041 11.8637 5.197C11.5708 5.48989 11.0959 5.48989 10.803 5.197L8.91663 3.31062V11C8.91663 11.4142 8.58084 11.75 8.16663 11.75C7.75241 11.75 7.41663 11.4142 7.41663 11V3.3107L5.53033 5.197C5.23744 5.48989 4.76256 5.48989 4.46967 5.197C4.17678 4.9041 4.17678 4.42923 4.46967 4.13634L7.63167 0.974337C7.64843 0.957286 7.66599 0.941033 7.6843 0.925639C7.7386 0.879934 7.79785 0.843148 7.86013 0.815283C7.9537 0.773332 8.05744 0.75 8.16663 0.75ZM1.75 11C1.75 10.5858 1.41421 10.25 1 10.25C0.585786 10.25 0.25 10.5858 0.25 11V14.2C0.25 15.1601 1.11655 15.75 1.93333 15.75H14.0667C14.8834 15.75 15.75 15.1601 15.75 14.2V11C15.75 10.5858 15.4142 10.25 15 10.25C14.5858 10.25 14.25 10.5858 14.25 11V14.185C14.2476 14.1877 14.2439 14.1915 14.2384 14.1962C14.2105 14.2201 14.152 14.25 14.0667 14.25H1.93333C1.84796 14.25 1.78948 14.2201 1.76156 14.1962C1.75612 14.1915 1.75243 14.1877 1.75 14.185V11ZM14.2541 14.1793C14.2542 14.1793 14.254 14.1798 14.2532 14.1809C14.2537 14.1798 14.2541 14.1792 14.2541 14.1793ZM1.74679 14.1809C1.74629 14.1798 1.74592 14.1792 1.74585 14.1793C1.74583 14.1793 1.74586 14.1794 1.74599 14.1796C1.74613 14.1799 1.74639 14.1803 1.74679 14.1809Z"
                fill="#303036"
            />
        </svg>
    );
}

export function Star() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="fill"
                d="M14.2499 5.4238L10.4734 4.87442L8.78761 1.45303C8.7075 1.31521 8.59262 1.20084 8.45446 1.12134C8.31629 1.04184 8.15968 1 8.00028 1C7.84087 1 7.68426 1.04184 7.5461 1.12134C7.40793 1.20084 7.29305 1.31521 7.21295 1.45303L5.52718 4.87529L1.75062 5.4238C1.5889 5.44699 1.4369 5.51501 1.31185 5.62015C1.1868 5.72529 1.09368 5.86334 1.04306 6.01868C0.992433 6.17401 0.986319 6.34042 1.02541 6.49905C1.06449 6.65768 1.14722 6.8022 1.26422 6.91623L3.99626 9.58003L3.35152 13.3417C3.32396 13.5026 3.34195 13.668 3.40347 13.8192C3.46499 13.9704 3.56758 14.1013 3.69964 14.1973C3.8317 14.2932 3.98796 14.3503 4.15076 14.362C4.31356 14.3738 4.4764 14.3397 4.62088 14.2638L8.00028 12.487L11.3779 14.262C11.5224 14.338 11.6852 14.372 11.848 14.3603C12.0108 14.3485 12.1671 14.2914 12.2992 14.1955C12.4312 14.0996 12.5338 13.9686 12.5953 13.8174C12.6569 13.6662 12.6748 13.5009 12.6473 13.34L12.0025 9.57828L14.7363 6.91623C14.853 6.80228 14.9356 6.65797 14.9746 6.49959C15.0136 6.34122 15.0076 6.17508 14.9572 6.01996C14.9068 5.86483 14.814 5.72688 14.6893 5.62169C14.5647 5.5165 14.4131 5.44826 14.2517 5.42467L14.2499 5.4238Z"
                fill="#303036"
            />
        </svg>
    );
}

export function Move({
    width = '22',
    height = '22',
    onClick,
    id,
    fill = 'black'
}: any) {
    return (
        <svg
            fill="none"
            id={ id }
            width="22"
            height="22"
            viewBox="0 0 22 22"
            xmlns="http://www.w3.org/2000/svg"
            onClick={ onClick }
        >
            <path
                d="M4.70711 8.70711C5.09763 8.31658 5.09763 7.68342 4.70711 7.29289C4.31658 6.90237 3.68342 6.90237 3.29289 7.29289L0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071L3.29289 14.7071C3.68342 15.0976 4.31658 15.0976 4.70711 14.7071C5.09763 14.3166 5.09763 13.6834 4.70711 13.2929L2.41421 11L4.70711 8.70711Z"
                fill={ fill }
            />
            <path
                d="M13.2929 4.70711C13.6834 5.09763 14.3166 5.09763 14.7071 4.70711C15.0976 4.31658 15.0976 3.68342 14.7071 3.29289L11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893L7.29289 3.29289C6.90237 3.68342 6.90237 4.31658 7.29289 4.70711C7.68342 5.09763 8.31658 5.09763 8.70711 4.70711L11 2.41421L13.2929 4.70711Z"
                fill={ fill }
            />
            <path
                d="M8.70711 17.2929C8.31658 16.9024 7.68342 16.9024 7.29289 17.2929C6.90237 17.6834 6.90237 18.3166 7.29289 18.7071L10.2929 21.7071C10.6834 22.0976 11.3166 22.0976 11.7071 21.7071L14.7071 18.7071C15.0976 18.3166 15.0976 17.6834 14.7071 17.2929C14.3166 16.9024 13.6834 16.9024 13.2929 17.2929L11 19.5858L8.70711 17.2929Z"
                fill={ fill }
            />
            <path
                d="M17.2929 13.2929C16.9024 13.6834 16.9024 14.3166 17.2929 14.7071C17.6834 15.0976 18.3166 15.0976 18.7071 14.7071L21.7071 11.7071C22.0976 11.3166 22.0976 10.6834 21.7071 10.2929L18.7071 7.29289C18.3166 6.90237 17.6834 6.90237 17.2929 7.29289C16.9024 7.68342 16.9024 8.31658 17.2929 8.70711L19.5858 11L17.2929 13.2929Z"
                fill={ fill }
            />
            <path
                d="M1 12H21C21.5523 12 22 11.5523 22 11C22 10.4477 21.5523 10 21 10H1C0.447715 10 0 10.4477 0 11C0 11.5523 0.447715 12 1 12Z"
                fill={ fill }
            />
            <path
                d="M10 1V21C10 21.5523 10.4477 22 11 22C11.5523 22 12 21.5523 12 21V1C12 0.447715 11.5523 0 11 0C10.4477 0 10 0.447715 10 1Z"
                fill={ fill }
            />
        </svg>
    );
}

export function Rotate({ width = '16', height = '16', onClick }: any) {
    return (
        <svg
            width={ width }
            height={ height }
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={ onClick }
        >
            <path
                d="M1.86678 10.6584C2.94315 14.0189 5.91029 16.1914 9.14413 15.9867C12.378 15.7819 15.0989 13.2494 15.8177 9.77514C16.5364 6.30092 15.0797 2.7226 12.2448 0.998778C9.40997 -0.725045 5.88039 -0.178802 3.588 2.33852L0.229256 5.80961C-0.0634566 6.11216 -0.0777758 6.61844 0.197273 6.94042C0.472321 7.26239 0.932582 7.27815 1.22529 6.9756L4.59984 3.48766C6.44956 1.45697 9.27323 1.01997 11.5411 2.39903C13.809 3.77809 14.9744 6.64075 14.3994 9.42013C13.8244 12.1995 11.6476 14.2256 9.06055 14.3894C6.47348 14.5531 4.09977 12.8152 3.23868 10.1267C3.1052 9.70999 2.6899 9.49119 2.31106 9.63801C1.93222 9.78482 1.73331 10.2416 1.86678 10.6584Z"
                fill="black"
            />
            <path
                d="M1.45455 5.59288V1.59295C1.45455 1.15113 1.12893 0.792969 0.727273 0.792969C0.325611 0.792969 0 1.15113 0 1.59295V6.39286C0 6.83468 0.325611 7.19285 0.727273 7.19285H5.09091C5.49257 7.19285 5.81818 6.83468 5.81818 6.39286C5.81818 5.95104 5.49257 5.59288 5.09091 5.59288H1.45455Z"
                fill="black"
            />
        </svg>
    );
}

export function Scale({ width = '13', height = '16', onClick }: any) {
    return (
        <svg
            width={ width }
            height={ height }
            viewBox="0 0 13 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={ onClick }
        >
            <rect x="5.5" width="1.7" height="13" rx="0.85" fill="black" />
            <rect
                y="7.5"
                width="1.7"
                height="13"
                rx="0.85"
                transform="rotate(-90 0 7.5)"
                fill="black"
            />
            <rect
                y="15.6992"
                width="1.7"
                height="13"
                rx="0.85"
                transform="rotate(-90 0 15.6992)"
                fill="black"
            />
        </svg>
    );
}

export function Material() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="16"
            fill="none"
            viewBox="0 0 12 16"
        >
            <path
                stroke="#000"
                strokeWidth="1.2"
                d="M0.849 5.626H7.649V12.426H0.849z"
                transform="rotate(-44.641 .849 5.626)"
            />
            <path
                fill="#C4C4C4"
                stroke="#000"
                strokeWidth="1.2"
                d="M0.849 9.863H7.649V16.663H0.849z"
                transform="rotate(-44.641 .849 9.863)"
            />
        </svg>
    );
}

export function Lightning() {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="5.09784"
                cy="5.09784"
                r="4.34784"
                stroke="black"
                strokeWidth="1.5"
            />
            <line
                x1="5.23277"
                y1="11.9688"
                x2="5.93564"
                y2="15.2331"
                stroke="black"
                strokeWidth="1.3"
                strokeLinecap="round"
            />
            <line
                x1="10.4728"
                y1="9.46383"
                x2="14.1563"
                y2="12.4555"
                stroke="black"
                strokeWidth="1.3"
                strokeLinecap="round"
            />
            <line
                x1="12.8049"
                y1="4.55604"
                x2="16.0127"
                y2="5.4832"
                stroke="black"
                strokeWidth="1.3"
                strokeLinecap="round"
            />
        </svg>
    );
}

export function AgainstArrows() {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.20624 0.420274C0.877556 0.452465 0.58275 0.691358 0.474317 1.00313C0.365883 1.3149 0.452291 1.68253 0.691184 1.91128L13.9609 15.181H5.63836C5.40625 15.1776 5.1826 15.2674 5.01656 15.43C4.85222 15.5944 4.75903 15.8163 4.75903 16.0484C4.75903 16.2806 4.85222 16.5025 5.01656 16.6669C5.1826 16.8295 5.40625 16.9193 5.63836 16.9159H16.048C16.5275 16.9159 16.9155 16.5279 16.9155 16.0485V5.63882C16.9206 5.38976 16.8189 5.14917 16.6342 4.97976C16.4495 4.81202 16.2022 4.7307 15.9531 4.7578C15.5059 4.80694 15.1704 5.18984 15.1806 5.63881V13.9474L1.91087 0.677747C1.72958 0.493072 1.46361 0.394805 1.20605 0.420217L1.20624 0.420274ZM20.304 19.5181C19.8568 19.5604 19.5162 19.9365 19.5179 20.3855V30.7952C19.5145 31.0273 19.6043 31.2509 19.7669 31.417C19.9313 31.5813 20.1532 31.6745 20.3854 31.6745C20.6175 31.6745 20.8394 31.5813 21.0038 31.417C21.1664 31.2509 21.2562 31.0273 21.2528 30.7952V22.4727L34.5225 35.7423C34.8427 36.0727 35.4324 36.0829 35.756 35.7559C36.0796 35.4289 36.0762 34.8258 35.7424 34.5089L22.4866 21.2531H30.7952C31.0274 21.2565 31.251 21.1667 31.4171 21.004C31.5814 20.8397 31.6746 20.6178 31.6746 20.3856C31.6746 20.1535 31.5814 19.9315 31.4171 19.7672C31.251 19.6046 31.0274 19.5148 30.7952 19.5181H20.3856C20.3585 19.5165 20.3314 19.5165 20.3043 19.5181L20.304 19.5181Z"
                fill="black"
            />
        </svg>
    );
}

export function Muted() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M12 5.27915C12 4.13923 10.6213 3.56834 9.81491 4.3744L5.93934 8.24849H4.25C2.73122 8.24849 1.5 9.47924 1.5 10.9974V12.9967C1.5 14.5149 2.73122 15.7456 4.25 15.7456H5.93934L9.8149 19.6197C10.6213 20.4258 12 19.8549 12 18.715V5.27915ZM6.78033 9.52834L10.5 5.81008V18.1841L6.78033 14.4658C6.63968 14.3252 6.44891 14.2462 6.25 14.2462H4.25C3.55964 14.2462 3 13.6868 3 12.9967V10.9974C3 10.3074 3.55964 9.74792 4.25 9.74792H6.25C6.44891 9.74792 6.63968 9.66894 6.78033 9.52834Z" fill="#3E3B46" />
            <path className="fill" d="M22.2803 15.5303C21.9874 15.8232 21.5126 15.8232 21.2197 15.5303L18.25 12.5607L15.2803 15.5303C14.9874 15.8232 14.5126 15.8232 14.2197 15.5303C13.9268 15.2374 13.9268 14.7626 14.2197 14.4697L17.1893 11.5L14.2197 8.53033C13.9268 8.23744 13.9268 7.76256 14.2197 7.46967C14.5126 7.17678 14.9874 7.17678 15.2803 7.46967L18.25 10.4393L21.2197 7.46967C21.5126 7.17678 21.9874 7.17678 22.2803 7.46967C22.5732 7.76256 22.5732 8.23744 22.2803 8.53033L19.3107 11.5L22.2803 14.4697C22.5732 14.7626 22.5732 15.2374 22.2803 15.5303Z" fill="#3E3B46" />
        </svg>
    );
}

export function UnMuted() {
    return (

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M12 5.27915C12 4.13923 10.6213 3.56834 9.81491 4.3744L5.93934 8.24849H4.25C2.73122 8.24849 1.5 9.47924 1.5 10.9974V12.9967C1.5 14.5149 2.73122 15.7456 4.25 15.7456H5.93934L9.8149 19.6197C10.6213 20.4258 12 19.8549 12 18.715V5.27915ZM6.78033 9.52834L10.5 5.81008V18.1841L6.78033 14.4658C6.63968 14.3252 6.44891 14.2462 6.25 14.2462H4.25C3.55964 14.2462 3 13.6868 3 12.9967V10.9974C3 10.3074 3.55964 9.74792 4.25 9.74792H6.25C6.44891 9.74792 6.63968 9.66894 6.78033 9.52834Z" fill="#3E3B46" />
            <path className="fill" d="M18.272 4.39838C18.5662 4.10549 19.0431 4.10549 19.3373 4.39838C23.5542 8.59652 23.5542 15.403 19.3373 19.6012C19.0431 19.8941 18.5662 19.8941 18.272 19.6012C17.9778 19.3083 17.9778 18.8334 18.272 18.5405C21.9004 14.9282 21.9004 9.07139 18.272 5.45904C17.9778 5.16615 17.9778 4.69127 18.272 4.39838Z" fill="#3E3B46" />
            <path className="fill" d="M14.7207 7.93403C15.0149 7.64113 15.4918 7.64113 15.786 7.93403C18.0416 10.1795 18.0416 13.8202 15.786 16.0658C15.4918 16.3586 15.0149 16.3586 14.7207 16.0658C14.4264 15.7729 14.4264 15.298 14.7207 15.0051C16.3878 13.3454 16.3878 10.6544 14.7207 8.99469C14.4264 8.70179 14.4264 8.22692 14.7207 7.93403Z" fill="#3E3B46" />
        </svg>
    );
}

export function UploadImage() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="fill" d="M20.25 5C20.25 5.41421 20.5858 5.75 21 5.75C21.4142 5.75 21.75 5.41421 21.75 5V3.75L23 3.75C23.4142 3.75 23.75 3.41421 23.75 3C23.75 2.58579 23.4142 2.25 23 2.25L21.75 2.25V1C21.75 0.585787 21.4142 0.25 21 0.25C20.5858 0.25 20.25 0.585786 20.25 1V2.25L19 2.25C18.5858 2.25 18.25 2.58579 18.25 3C18.25 3.41421 18.5858 3.75 19 3.75L20.25 3.75V5Z" fill="#3E3B46" />
            <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M3.96926 18.97C3.82871 18.6763 3.75 18.3473 3.75 18L3.75 6C3.75 4.75736 4.75736 3.75 6 3.75L16 3.75C16.4142 3.75 16.75 3.41421 16.75 3C16.75 2.58579 16.4142 2.25 16 2.25L6 2.25C3.92893 2.25 2.25 3.92893 2.25 6V18C2.25 20.0711 3.92893 21.75 6 21.75H18C20.0711 21.75 21.75 20.0711 21.75 18V8C21.75 7.58579 21.4142 7.25 21 7.25C20.5858 7.25 20.25 7.58579 20.25 8V14.1893L18.4445 12.3838C17.3706 11.3099 15.6294 11.3099 14.5555 12.3838L12.5 14.4393L12.4445 14.3838C11.3706 13.3099 9.6294 13.3099 8.55546 14.3838L3.96926 18.97ZM5.0299 20.0307C5.32363 20.1713 5.65262 20.25 6 20.25H16.1894L11.3839 15.4445C10.8957 14.9563 10.1043 14.9563 9.61612 15.4445L5.0299 20.0307ZM17.3839 13.4445L20.25 16.3106V18C20.25 19.1437 19.3967 20.0881 18.292 20.2312L13.5607 15.4999L15.6161 13.4445C16.1043 12.9563 16.8957 12.9563 17.3839 13.4445Z" fill="#3E3B46" />
            <path className="fill" d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" fill="#3E3B46" />
        </svg>
    );
}
