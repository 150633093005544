import React                                   from 'react';
import { useTheme }                            from 'styled-components';
import CheckboxChecked                         from '@geenee/builder/src/asset/icons/CheckboxChecked';
import CloudBuilder                            from '@geenee/builder/src/asset/icons/CloudBuilder';
import Hint                                    from '@geenee/builder/src/components/common/Hint';
import { UnderlinedTableRow, WithHintWrapper } from '@geenee/builder/src/pages/AllPlansPage/styles';

export function CloudCms() {
    const { secondaryColors } = useTheme();
    return (
        <UnderlinedTableRow>
            <td>
                <WithHintWrapper>
                    <span>Cloud-based drag & drop Builder</span>

                    <Hint
                        title="Login From Anywhere"
                        text="Create, edit, and deploy your AR experieneces no matter where you are."
                        iconElement={ <CloudBuilder /> }
                    />
                </WithHintWrapper>
            </td>
            <td className="basic-plan">
                <CheckboxChecked beIcon fill={ secondaryColors.green70 } />
            </td>
            <td className="plan-filler" />
            <td className="studio-plan">
                <CheckboxChecked beIcon fill={ secondaryColors.green70 } />
            </td>
            <td className="plan-filler" />
            <td className="pro-plan">
                { ' ' }
                <CheckboxChecked beIcon fill={ secondaryColors.green70 } />
            </td>
        </UnderlinedTableRow>
    );
}
