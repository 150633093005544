import React, { useCallback, useState } from 'react';
import { useModal as useModalHook }     from 'react-modal-hook';

export const useModal = (c: React.FunctionComponent<any>, deps: any[] = []) => {
    const [ show, hide ] = useModalHook(c, deps);
    const [isOpened, setIsOpened] = useState(false)

    const showModal = useCallback((event?: React.BaseSyntheticEvent) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        show();
        setIsOpened(true)
    }, [ show ]);

    const hideModal = useCallback((event?: React.BaseSyntheticEvent) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        hide();
        setIsOpened(false)
    }, [ hide ]);

    return [ showModal, hideModal, isOpened ] as any[];
};
