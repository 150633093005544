const colors = {
    white:          '#ffffff',
    black:          '#000000',
    black10:        '#010A08',
    black20:        '#3C3B3B',
    bodyBackground:
        'radial-gradient(50% 50% at 50% 50%, #EAF8EB 23.53%, #E1F4F9 45.41%, #F0EAFB 70.41%, #F2F2F3 81.87%, #FBFCFC 98.01%)',
    errorBackground:       '#FFDDD6',
    warnBackground:        '#FFFDD6',
    successBackground:     '#DAFFD0',
    brandingBarBackground: '#A7A7A7',
    panelBackground:       'linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));'
};

const secondaryColors = {
    blue10:   '#ade7ff',
    blue20:   '#61bcff',
    blue30:   '#EBEDF0',
    blue40:   '#00D7E4',
    blue50:   '#CDFCFF',
    blue60:   '#4FC9B8',
    blue70:   '#5BE9D5',
    green10:  '#b5ffcb',
    green20:  '#8BFF6C',
    green30:  '#2EE5CB',
    green40:  '#33FF00',
    green50:  '#EBF6E8',
    green60:  '#DAFFD0',
    green70:  '#56E4D0',
    green80:  '#57E7D4',
    green90:  '#F1FFED',
    green100: '#5CEBD6',
    green110: '#1ED760',
    grey10:   '#9a9a9a',
    grey20:   '#F0EFEF',
    grey30:   '#d0d0d0',
    grey40:   '#f2f2f2',
    grey50:   '#fafafa',
    grey60:   '#f5f5f5',
    grey70:   '#F0F1F7',
    grey90:   '#fbfafa',
    grey100:  '#a9a9a9',
    grey110:  '#f7f7f7',
    grey120:  '#7B7373',
    grey130:  '#555555',
    grey140:  '#686868',
    grey150:  '#B5B8C4',
    grey160:  '#C5C7CD',
    grey170:  '#D9D9D9',
    grey180:  '#2A2A2A',
    grey190:  '#efefef',
    grey200:  '#e5e5e5',
    grey210:  '#E6E6E6',
    pink10:   '#ed87c3',
    pink20:   '#ffddd6',
    pink30:   '#fbd9d2',
    pink40:   '#FF008A',
    pink50:   '#FAC8E3',
    pink60:   '#CD81F0',
    pink70:   '#CC46CF',
    pink80:   '#9821D0'
};

const theme = {
    colors,
    secondaryColors
};

// eslint-disable-next-line arca/no-default-export
export default theme;
