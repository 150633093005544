import * as React                   from "react";
import type { IInspectableOptions } from "@babylonjs/core/Misc/iInspectable";
import type { Observable }          from "@babylonjs/core/Misc/observable";
import { FormLineWrapperComponent } from "@geenee/geespector/src/components/formLineWrapper/formLineWrapperComponent";
import {
    Button, Description, Dropdown, Icon, iconMap, Input, Menu, MenuItem, Span,
    Wrapper
} from "@geenee/ui";
import type { PropertyChangedEvent } from "../propertyChangedEvent";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Null_Value = Number.MAX_SAFE_INTEGER;

type OptionsType = IInspectableOptions & { iconConfig?: { icon: keyof typeof iconMap | any, color?: string } };

export interface IOptionsLineComponentProps {
    label: string;
    target: any;
    propertyName: string;
    options: OptionsType[];
    noDirectUpdate?: boolean;
    onSelect?: (value: number) => void;
    extractValue?: () => number;
    onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
    allowNullValue?: boolean;
    icon?: string;
    iconLabel?: string;
    border?: boolean;
}

export class OptionsLineComponent extends React.Component<IOptionsLineComponentProps, { value: number, searchValue: string }> {
    private _localChange = false;

    private _remapValueIn(value: number | null): number {
        return this.props.allowNullValue && value === null ? Null_Value : value!;
    }

    private _remapValueOut(value: number): number | null {
        return this.props.allowNullValue && value === Null_Value ? null : value;
    }

    constructor(props: IOptionsLineComponentProps) {
        super(props);
        this.state = {
            value:       this._remapValueIn(this.props.extractValue ? this.props.extractValue() : props.target[ props.propertyName ]),
            searchValue: ""
        };
    }

    shouldComponentUpdate(nextProps: IOptionsLineComponentProps, nextState: { value: number }) {
        if (JSON.stringify(nextProps.options) !== JSON.stringify(this.props.options)) {
            return true;
        }

        if (this._localChange) {
            this._localChange = false;
            return true;
        }

        const newValue = this._remapValueIn(nextProps.extractValue ? nextProps.extractValue() : nextProps.target[ nextProps.propertyName ]);
        if (newValue != null && newValue !== nextState.value) {
            nextState.value = newValue;
            return true;
        }
        return false;
    }

    raiseOnPropertyChanged(newValue: number, previousValue: number) {
        if (!this.props.onPropertyChangedObservable) {
            return;
        }

        this.props.onPropertyChangedObservable.notifyObservers({
            object:         this.props.target,
            property:       this.props.propertyName,
            value:          newValue,
            initialValue:   previousValue,
            allowNullValue: this.props.allowNullValue
        });
    }

    updateValue(value: string | number) {
        const _value = +value;
        this._localChange = true;

        const store = this.props.extractValue ? this.props.extractValue() : this.props.target[ this.props.propertyName ];

        if (!this.props.noDirectUpdate) {
            this.props.target[ this.props.propertyName ] = this._remapValueOut(_value);
        }
        this.setState({ value: _value });

        if (this.props.onSelect) {
            this.props.onSelect(_value);
        }

        const newValue = this.props.extractValue ? this.props.extractValue() : this.props.target[ this.props.propertyName ];

        this.raiseOnPropertyChanged(newValue, store);
    }

    updateSearchValue(val: string) {
        this._localChange = true;
        this.setState({ searchValue: val });
    }

    get searchMatches() {
        return this.props.options.filter((item) => item.label.toLowerCase().includes(this.state.searchValue.toLowerCase()));
    }

    formattedSearchLabel(label: string) {
        return label.replace(
            new RegExp(this.state.searchValue, "gi"),
            (match) => `|${ match }|`
        )
            .split("|")
            .map((chunk, i) => (chunk.toLowerCase() === this.state.searchValue.toLowerCase() ? (
                <Span weight="medium" color="violet-3" style={ { margin: 0 } } key={ i }>
                    { chunk }
                </Span>
            ) : chunk));
    }

    render() {
        return (
            <FormLineWrapperComponent
                wrapperProps={ { style: { width: 0 } } }
                border={ this.props.border }
                title={ this.props.label }
            >
                <Wrapper className="options" fullWidth style={ { minWidth: "80px" } }>
                    <Dropdown
                        clickOutside
                        prepend={ this.props.options.length > 10 ? (
                            <Wrapper>
                                <Input
                                    size="xs"
                                    margin="xxs"
                                    fullWidth
                                    placeholder="Search"
                                    value={ this.state.searchValue }
                                    onChange={ (e) => {
                                        this.updateSearchValue(e.target.value);
                                    } }
                                />
                            </Wrapper>
                        ) : null }
                        prependSticky
                        menu={ (onClose: () => void) => (
                            <Menu
                                maxCount={ 10 }
                                viewType="light"
                                activeItemId={ `${ this.state.value }` }
                                radius="lg"
                                size="xs"
                                padding="xxs"
                            >
                                { this.searchMatches
                                    .map((item, index) => {
                                        const key = typeof item.iconConfig?.icon === "string" ? item.iconConfig?.icon : item.iconConfig?.icon.iconName;
                                        const icon = iconMap[ key as keyof typeof iconMap ]
                                            ? (
                                                <Icon
                                                    margin="xxs"
                                                    stroke="fat"
                                                    color="shade-3"
                                                    name={ key as keyof typeof iconMap }
                                                />
                                            )
                                            : undefined;

                                        return (
                                            <MenuItem
                                                hasTooltip
                                                id={ `${ item.value }` }
                                                key={ item.value }
                                                iconBefore={ icon }
                                                onSelect={ (e, value) => {
                                                    this.updateValue(value);
                                                    onClose();
                                                } }
                                            >
                                                { this.state.searchValue ? this.formattedSearchLabel(item.label) : item.label }
                                            </MenuItem>
                                        );
                                    }) }
                            </Menu>
                        ) }
                        menuWidth="100%"
                        fullWidth
                    >
                        { (dropdownOpen) => (
                            <Button
                                style={ { paddingLeft: 8, paddingRight: 8 } }
                                icon={ dropdownOpen ? "arrowTop" : "arrowDown" }
                                iconAfter
                                border="shade-5"
                                shadow="none"
                                weight="medium"
                                size="sm"
                                radius="lg"
                                viewType="white"
                                iconFloat="right"
                                fullWidthContent
                                iconMargin="off"
                                fullWidth
                            >
                                <Description noWrap shorten size="sm">
                                    { this.props.options.find((item) => item.value === this.state.value)?.label }
                                </Description>
                            </Button>
                        ) }
                    </Dropdown>
                </Wrapper>
            </FormLineWrapperComponent>
        );
    }
}
