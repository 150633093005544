import React           from 'react';
import { stripeState } from '@geenee/builder/src/core/state/stripe.state';
import { TableInfo }   from '@geenee/builder/src/pages/AllPlansPage';
import { YourPlan }    from '@geenee/builder/src/pages/AllPlansPage/styles';

interface Props {
    planNames: string[];
    tableInfo: TableInfo;
    currentInterval: 'year' | 'month';
}

export function YourPlanRow({
    planNames,
    tableInfo,
    currentInterval
}: Props) {
    // @ts-ignore
    const isBasic = stripeState.plan_price_id === tableInfo[ planNames[ 0 ] ][ currentInterval ].id;
    const isStudio = stripeState.plan_price_id === tableInfo[ planNames[ 1 ] ][ currentInterval ].id;
    const isPro = stripeState.plan_title.toLowerCase() === 'enterprise';
    return (
        <tr>
            <td />
            <YourPlan className="basic-plan">
                <>{ isBasic && <>YOUR PLAN</> }</>
            </YourPlan>
            <td className="plan-filler" />
            <YourPlan className="studio-plan">
                { isStudio && <>YOUR PLAN</> }
            </YourPlan>
            <td className="plan-filler" />
            <YourPlan className="pro-plan">{ isPro && <>YOUR PLAN</> }</YourPlan>
        </tr>
    );
}
