import { AttachmentFactoryType } from '@geenee/shared/src/magellan/factory/attachment.factory';
import { injectable }            from 'inversify';
import { MoleculeSchemaType }    from '../../../../type/molecule.type';
import { FactoryAbstract }       from '../../../util/factoryContainer';
import { AtomFactoryType }       from '../../atom/factory/atom.factory';
import { SectionModel }          from '../../model/section.model';
import { MoleculeModel }         from '../model/molecule.model';

type ParentType = SectionModel

@injectable()
export class MoleculeFactory extends FactoryAbstract {
    create(
        moleculeSchema: MoleculeSchemaType,
        $parent?: ParentType
    ) {
        const moleculeModel: MoleculeModel = this.container.get('<MoleculeModel>');
        const atomFactory: AtomFactoryType = this.container.get('<AtomFactory>');
        const attachmentFactory: AttachmentFactoryType = this.container.get('<AttachmentFactory>');

        Object.assign(moleculeModel, moleculeSchema);
        moleculeModel.moleculeSchema = moleculeSchema;
        moleculeSchema?.atoms?.forEach((atomSchema) => {
            const atom = atomFactory.create(atomSchema, moleculeModel);
            moleculeModel.atomsRegistry.set(atom.id, atom);
        });
        if (moleculeSchema.thumbnail) {
            const attachment = attachmentFactory.create(moleculeSchema.thumbnail, moleculeModel);
            moleculeModel.thumbnail = attachment;
            moleculeModel.thumbnail_id = attachment.id;
        }
        if ($parent) {
            moleculeModel.$parent = $parent;
        }

        return moleculeModel;
    }
}

export type MoleculeFactoryType = MoleculeFactory
