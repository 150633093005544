import React, { useRef, useState } from 'react';
import { useMediaQuery }           from 'react-responsive';
import { useInject }               from '@geenee/shared';
import { observer }                from 'mobx-react';
import { HeaderButtons }           from '@geenee/builder/src/components/ProjectsLinkDropdown/components/HeaderButtons';
import { ProjectLinkPopup }        from '@geenee/builder/src/components/ProjectsLinkDropdown/components/ProjectLinkPopup';
import { useGenerateUrl }          from '@geenee/builder/src/components/ProjectsLinkDropdown/hooks/useGenerateUrl';
import useOnClickOutside           from '@geenee/builder/src/lib/useOnClickOutside';
import {
    ComponentWrapper,
    Root
}                                from './styles';

export const ProjectLinksDropdown = observer(
    ({ publish, disabled }: { publish?: boolean; disabled?: boolean }) => {
        const { AppState } = useInject();
        const isMobile = useMediaQuery({ query: '(max-width: 1200px)' });

        const mainProgram = AppState.activeProject;

        const projectPublished = mainProgram?.published;

        const [ dropdownActive, setDropdownActive ] = useState(false);
        const { fullUrl, togglePublishProject, publishLink } = useGenerateUrl({ publish, dropdownActive });

        const togglePreviewCard = async (published?: boolean) => {
            if (publish) {
                await togglePublishProject(published);
                return;
            }
            if (!publish) {
                setDropdownActive(!dropdownActive);
            }
        };

        const menuRef = useRef<HTMLDivElement | null>(null);

        useOnClickOutside(menuRef, () => {
            if (dropdownActive) {
                setDropdownActive(false);
            }
        });

        return (
            <Root>
                <ComponentWrapper
                    ref={ menuRef }
                    disabled={ disabled }
                    active={ dropdownActive }
                    publish={ publish }
                    published={ projectPublished }
                    style={
                        publish
                            ? {
                                display:       'flex',
                                flexDirection: isMobile ? 'column-reverse' : 'row'
                            }
                            : {}
                    }
                >
                    <HeaderButtons
                        disabled={ !!disabled }
                        publish={ !!publish }
                        isMobile={ false }
                        onPreviewPublishClick={ (published) => togglePreviewCard(published) }
                        onLinkButtonClick={ () => setDropdownActive(!dropdownActive) }
                    />
                    { dropdownActive && (
                        <ProjectLinkPopup
                            dropdownActive={ dropdownActive }
                            fullUrl={ fullUrl }
                            publish={ publish }
                            disabled={ disabled }
                            publishLink={ publishLink }
                        />
                    ) }
                </ComponentWrapper>
            </Root>
        );
    }
);
