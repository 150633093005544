import React                              from 'react';
import { DIContextType }                  from '@geenee/geeclient-kit/src/lib/context/di.context';
import { AtomModel }                      from '@geenee/shared';
import { sceneAtomComponentDefaultProps } from '@geenee/shared/src/magellan/atom/component/scene-atom/default-props';
import { withDIContext }                  from '@geenee/shared/src/magellan/hoc/withDIContext.hoc';
import { observer }                       from 'mobx-react';

export type AtomComponentPropsType = {
  atomModel: AtomModel;
  diContext: DIContextType
} & {[key: string]: any};

export const SceneAtomComponent = withDIContext(observer((props: AtomComponentPropsType) => {
    const { atomModel } = props;
    const { SceneAtomComponent: SCENE_ATOM_COMPONENT_DI } = props.diContext;
    if (!atomModel) {
        return null;
    }

    return (
        <>
            <SCENE_ATOM_COMPONENT_DI { ...{ ...sceneAtomComponentDefaultProps, ...props } } />
        </>

    );
}));
