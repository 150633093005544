import { PBRMaterial }       from "@babylonjs/core/Materials/PBR/pbrMaterial";
import { StandardMaterial }  from "@babylonjs/core/Materials/standardMaterial";
import { CreateGround }      from "@babylonjs/core/Meshes/Builders/groundBuilder";
import { TransformNode }     from "@babylonjs/core/Meshes/transformNode";
import { OccluderMaterial }  from "@geenee/geespector/materials/occluder.material";
import { SceneNodeType }     from "@geenee/geespector/renderer/babylonjs.renderer";
import { AddToSceneCommand } from '@geenee/geespector/src/commands/AddToSceneCommand';
import {
    BODY_TRACKING_OVERLAY_NODE_ID,
    BODY_TRACKING_TWIN_NODE_ID,
    HEAD_TRACKING_NODE_ID
} from "@geenee/shared/src/util/constants";

export class SceneManagerCommander {
    sceneRenderer: any;
    constructor() {
    }
    uniqueMaterialName(name: string) {
        if (this.sceneRenderer.scene) {
            let uniqueName = `${ name }`;
            let idSubscript = 1;
            while (this.sceneRenderer.scene.getMaterialById(uniqueName)) {
                uniqueName = `${ name } ${ idSubscript }`;
                idSubscript += 1;
            }
            return uniqueName;
        }
        return name;
    }
    createSceneMaterial(type: 'pbr' | 'standard' | 'occluder' = 'standard') {
        let material: StandardMaterial | PBRMaterial | OccluderMaterial | null = null;
        switch (type) {
            case 'standard':
                material = new StandardMaterial(this.uniqueMaterialName("Standard Material"), this.sceneRenderer.scene);
                break;
            case 'pbr':
                material = new PBRMaterial(this.uniqueMaterialName("PBR Material"), this.sceneRenderer.scene);
                break;
            case 'occluder':
                material = new OccluderMaterial(this.uniqueMaterialName("Occluder Material"), this.sceneRenderer.scene);
                break;
            default:
                return;
        }

        if (!material) {
            return;
        }
        const command = new AddToSceneCommand(this.sceneRenderer, material);
        this.sceneRenderer.commander.executeCommand(command);
    }

    createSceneLight(type: 'light-point' | 'light-direct' | 'light-spot', parent?: SceneNodeType) {
        const command = new AddToSceneCommand(this.sceneRenderer, type, parent);
        this.sceneRenderer.commander.executeCommand(command);
    }

    createTrackingNode(type: 'head' | 'twin' | 'avatar') {
        let node: TransformNode | null = null;
        if (type === 'head') {
            node = new TransformNode(HEAD_TRACKING_NODE_ID, this.sceneRenderer.scene);
            node.metadata = { gltf: { extras: { engeenee: { type: 'head', headTrack: {} } } } };
        } else if (type === 'twin') {
            node = new TransformNode(BODY_TRACKING_TWIN_NODE_ID, this.sceneRenderer.scene);
            node.metadata = { gltf: { extras: { engeenee: { type: 'twin', poseTwin: {} } } } };
        } else {
            node = new TransformNode(BODY_TRACKING_OVERLAY_NODE_ID, this.sceneRenderer.scene);
            node.metadata = { gltf: { extras: { engeenee: { type: 'avatar', poseAlign: {} } } } };
        }
        const command = new AddToSceneCommand(this.sceneRenderer, node);
        this.sceneRenderer.commander.executeCommand(command);
    }

    createEmptyNode() {
        const emptyNode = new TransformNode("Empty Node", this.sceneRenderer.scene);
        emptyNode.metadata = { ...(emptyNode.metadata || {}), type: 'emptyNode' };
        const command = new AddToSceneCommand(this.sceneRenderer, emptyNode);
        this.sceneRenderer.commander.executeCommand(command);
    }

    createSceneGrid() {
        const grid = CreateGround(
            'grid',
            { width: 1.0, height: 1.0, subdivisions: 1 },
            this.sceneRenderer.scene
        );
        const command = new AddToSceneCommand(this.sceneRenderer, grid);
        this.sceneRenderer.commander.executeCommand(command);
    }

    createModelFromFile(file: File) {
        const command = new AddToSceneCommand(this.sceneRenderer, file);
        this.sceneRenderer.commander.executeCommand(command);
    }
}
