import React, { FC, memo }          from 'react';
import { cn }                       from '../../util/bem';
import { CallbackFunctionVariadic } from '@geenee/geeclient-kit/src/lib/type/type';


export type DropdownItemPropsType = {
    onSelect?: CallbackFunctionVariadic;
    id: any;
    children?: React.ReactNode;
}


const className = cn('dropdown-item');

export const DropdownItem: FC<DropdownItemPropsType> = memo((props) => {
    const handleOnSelect = (e: React.MouseEvent) => {
        if (props.onSelect) {
            props.onSelect(e, props.id);
        }
    };

    return (
        <>
            { React.Children.map(props.children, (child) => (
                React.isValidElement(child)
                    ? React.cloneElement(child, {
                        ...child.props,
                        className: `${ child.props.className } ${ className() }`,
                        id:        child.props.id,
                        onClick:   (e: React.MouseEvent) => { e.stopPropagation(); handleOnSelect(e); }
                    })
                    : null)) }
        </>
    );
});
