import React, { FC, useEffect, useMemo, useState }   from 'react';
import { DoubleSide, Euler, TextureLoader, Vector3 } from 'three';
import { DefaultLoadingManager }                     from 'three/src/loaders/LoadingManager';

type ImagePlanePropsType = {
    width: number,
    height: number,
    url: string,
    position: Vector3 | [ number, number, number ] | undefined;
    rotation: Vector3 | Euler | [ number, number, number ] | undefined;
    scale: Vector3 | [ number, number, number ] | undefined;
    visible: boolean;
    loadedCallback?: any
};

const ImagePlane: FC<ImagePlanePropsType> = ({ width, height, url, ...props }) => {
    const textureLoader = useMemo(() => new TextureLoader(DefaultLoadingManager), []);
    const [ texture, setTexture ] = useState(null);
    useEffect(() => {
        textureLoader.load(url || '', (loadedTexture) => {
            setTexture(loadedTexture);
            props.loadedCallback(true);
        });
    }, [ textureLoader ]);

    return (
        <mesh
            position={ props.position }
            rotation={ props.rotation }
            scale={ props.scale }
            dispose={ null }
            visible={ props.visible }
            castShadow={ false }
            name="geenee-3d-image-mesh"
        >
            { texture?.image && (
                <>
                    <planeGeometry
                        attach="geometry"
                        args={ [ width || 1, (height || 1) * (texture.image.height / texture.image.width) ] }
                    />
                    <meshBasicMaterial attach="material" color="#ffffff" transparent depthWrite={ false } side={ DoubleSide } map={ texture } />
                </>
            ) }
        </mesh>
    );
};

// eslint-disable-next-line arca/no-default-export
export default ImagePlane;
