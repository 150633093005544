import React, { useEffect }                 from 'react';
import { useHistory }                       from "@geenee/common";
import { AnimatedWrapper, Modal, useModal } from '@geenee/ui';
import SpotifyApi                           from '@geenee/builder/src/api/SpotifyAPI';
import SpotifyContainer                     from '@geenee/builder/src/components/Spotify';
import { spotifyState }                     from '@geenee/builder/src/core/state/spotify.state';
import {
    SPOTIFY_CLIENT_ID,
    SPOTIFY_REDIRECT_URL,
    SPOTIFY_SCOPE
}                          from '@geenee/builder/src/lib/constants';
import {
    generateCodeChallengeFromVerifier,
    generateCodeVerifier
}                          from '@geenee/builder/src/lib/generateVerifiers';
import { MoleculeModel } from '@geenee/builder/src/magellan/model/molecule.model';

export const useSpotifyHandlers = (activeMolecule?: MoleculeModel, choosedTrack?: SpotifyApi.TrackObjectFull) => {
    const history = useHistory();
    const { token } = spotifyState;

    const onChangeTrack = () => {
        if (choosedTrack?.preview_url && activeMolecule?.audioAtom?.firstAsset?.url !== choosedTrack?.preview_url) {
            activeMolecule?.setSceneAudioOptions({
                url:     choosedTrack.preview_url,
                name:    choosedTrack.name,
                artists: choosedTrack.artists.map((el) => el.name).join(','),
                id:      choosedTrack.id
            });
        }
    };

    useEffect(() => {
        onChangeTrack();
    }, [ choosedTrack ]);
    const [ layoutShowModal, layoutHideModal ] = useModal((transformGroupProps: JSX.IntrinsicAttributes) => (
        <Modal
            className='global-modal'
            onHideModal={ () => {
                spotifyState.clearData();
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                layoutHideModal();
            } }
            // eslint-disable-next-line react/jsx-props-no-multi-spaces
            { ...transformGroupProps }
        >
            <AnimatedWrapper manual={ false }>
                { activeMolecule && (
                    <SpotifyContainer
                        activeMolecule={ activeMolecule }
                    />
                ) }
            </AnimatedWrapper>
        </Modal>
    ), [ activeMolecule ]);

    const getToken = async () => {
        const verifier = generateCodeVerifier();
        const challenge = await generateCodeChallengeFromVerifier(verifier);

        const w = 600;
        const h = 800;
        // eslint-disable-next-line no-restricted-globals
        const left = (screen.width - w) / 2;
        // eslint-disable-next-line no-restricted-globals
        const top = (screen.height - h) / 4;
        // @ts-ignore
        window.spotifyCallback = (code: string) => {
            history.push(
                // @ts-ignore
                localStorage
                    .getItem('spotify-redirect-url')
                    .split('https://builder-dev.geen.ee')[ 1 ]
            );
            localStorage.removeItem('spotify-redirect-url');

            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            popup.close();

            spotifyState.getCredsByToken({ code, verifier });

            layoutShowModal();
        };

        const popup = window.open(
            `https://accounts.spotify.com/en/authorize?response_type=code&client_id=${ SPOTIFY_CLIENT_ID }&scope=${ encodeURIComponent(
                SPOTIFY_SCOPE
            ) }&redirect_uri=${ encodeURIComponent(
                SPOTIFY_REDIRECT_URL
            ) }&code_challenge=${ challenge }&code_challenge_method=S256`,
            'Login with Spotify',
            `width=${ w },height=${ h },top=${ top },left=${ left }`
        );
        localStorage.setItem('spotify-redirect-url', window.location.href);
    };

    const onSpotifyButtonClick = () => {
        if (activeMolecule) {
            if (!token) {
                getToken();
            } else {
                SpotifyApi.setAccessToken(token);
                layoutShowModal();
            }
        }
    };

    return { onSpotifyButtonClick };
};
