import { BabylonRenderer }                       from "@geenee/geespector/renderer/babylonjs.renderer";
import GlobalState, { PropertyChangedEventType } from "@geenee/geespector/src/components/globalState";
import { AbstractCommand, CommandType }          from '@geenee/shared/src/commander/types';

export class RecordPropertyChangeCommand extends AbstractCommand<CommandType> {
    receiver: BabylonRenderer;
    entity: PropertyChangedEventType;
    initialValue: any;
    value: any;
    reverted = false;
    metadata: object;

    constructor(receiver: BabylonRenderer, entity: PropertyChangedEventType) {
        super();
        this.receiver = receiver;
        this.entity = entity;
        this.metadata = entity.object.metadata;
        this.initialValue = entity.initialValue;
        this.value = entity.value;
    }

    execute = () => {
        if (this.reverted) {
            let node = this.receiver.getNodeByMetaData(this.metadata);
            if (!node) {
                node = this.entity.object;
            }
            node[ this.entity.property ] = this.value;
            GlobalState.onPropertyChangedObservable.notifyObservers({
                property:            this.entity.property,
                value:               this.value,
                initialValue:        this.initialValue,
                object:              node,
                skipCommandCreation: true
            });
            this.reverted = false;
        }
    };

    revert = () => {
        let node = this.receiver.getNodeByMetaData(this.metadata);
        if (!node) {
            node = this.entity.object;
        }
        node[ this.entity.property ] = this.initialValue;
        GlobalState.onPropertyChangedObservable.notifyObservers({
            property:            this.entity.property,
            value:               this.initialValue,
            initialValue:        '',
            object:              node,
            skipCommandCreation: true
        });
        this.reverted = true;
    };
}
