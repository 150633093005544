import { useEffect }  from 'react';
import { useInject }  from '@geenee/shared/index';
// @ts-ignore
import { v4 as uuid } from 'uuid';

export const useWatermark = () => {
    const { AppState } = useInject();
    const { activeProject } = AppState;

    const addRemoveListener = (id: string) => {
        const watermarkRemovedListener = () => {
            if (!document.getElementById(id)) {
                document.body.removeEventListener('DOMSubtreeModified', watermarkRemovedListener);
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                createDomWatermark();
            }
        };
        document.body.addEventListener('DOMSubtreeModified', watermarkRemovedListener);
        return () => document.body.removeEventListener('DOMSubtreeModified', watermarkRemovedListener);
    };

    const addStyleListener = (target: HTMLElement) => {
        const observer = new MutationObserver(((mutations) => {
            if (mutations.length) {
                document.body.removeChild(target);
                observer.disconnect();
            }
        }));
        observer.observe(target, { attributes: true, attributeFilter: [ 'style' ] });
        return () => observer.disconnect();
    };

    const createDomWatermark = () => {
        const svg = document.createElement('svg-watermark');

        svg.id = uuid();
        svg.innerHTML = WaterMarkSvgHtml;
        svg.style.position = 'absolute';
        svg.style.left = '0px';
        svg.style.bottom = '11px';
        svg.style.pointerEvents = 'none';
        document.body.appendChild(svg);

        const styleUnsubscribe = addStyleListener(svg);
        const deleteUnsubscribe = addRemoveListener(svg.id);
        return () => {
            styleUnsubscribe();
            deleteUnsubscribe();
            document.body.removeChild(svg);
        };
    };

    useEffect(() => {
        // @ts-ignore
        if (activeProject?.powered_by_screen && process.env.ENV_GEENEE_APP !== 'BUILDER') {
            createDomWatermark();
        }
    }, [ activeProject?.powered_by_screen ]);
};

const WaterMarkSvgHtml = `<svg width="110" height="56" viewBox="0 0 110 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_3224_18830)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.0829 32.6048C21.3627 32.4164 21.6924 32.3147 22.0304 32.3127C22.4823 32.3099 22.917 32.4855 23.239 32.8008C23.561 33.1162 23.7441 33.5456 23.7482 33.9949C23.7512 34.3308 23.6539 34.6601 23.4686 34.9411C23.2833 35.222 23.0183 35.4419 22.7071 35.5731C22.396 35.7042 22.0527 35.7406 21.7208 35.6776C21.3888 35.6147 21.0831 35.4552 20.8423 35.2195C20.6016 34.9837 20.4366 34.6822 20.3683 34.3532C20.3 34.0242 20.3315 33.6824 20.4587 33.3712C20.5859 33.0599 20.8031 32.7932 21.0829 32.6048ZM21.535 34.7401C21.6818 34.8369 21.8542 34.888 22.0304 34.887V34.8972C22.2647 34.8958 22.4891 34.8023 22.6543 34.6371C22.8196 34.4718 22.9123 34.2483 22.9123 34.0153C22.9144 33.8402 22.8639 33.6685 22.7675 33.522C22.671 33.3756 22.5329 33.261 22.3707 33.1928C22.2085 33.1246 22.0295 33.106 21.8566 33.1393C21.6837 33.1726 21.5247 33.2563 21.3998 33.3797C21.2749 33.5031 21.1898 33.6607 21.1553 33.8324C21.1209 34.0041 21.1385 34.1821 21.2062 34.3437C21.2738 34.5054 21.3883 34.6434 21.535 34.7401Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.0355 31.3645C21.5147 31.3665 21.0062 31.5216 20.5738 31.8101C20.1415 32.0987 19.8046 32.5079 19.6056 32.9864C19.4065 33.4648 19.3542 33.991 19.4552 34.4989C19.5562 35.0068 19.806 35.4736 20.1731 35.8408C20.5403 36.2079 21.0085 36.4589 21.5188 36.5623C22.0291 36.6656 22.5587 36.6166 23.0411 36.4215C23.5235 36.2264 23.9371 35.8939 24.2299 35.4657C24.5227 35.0376 24.6816 34.533 24.6866 34.0153C24.6853 33.3194 24.4066 32.6524 23.9116 32.1604C23.4167 31.6683 22.7458 31.3913 22.0458 31.39L22.0355 31.3645ZM22.0355 36.2226C21.5912 36.2236 21.1567 36.0932 20.787 35.8482C20.4174 35.6031 20.1294 35.2543 19.9597 34.8461C19.7899 34.438 19.746 33.9889 19.8336 33.5559C19.9211 33.1228 20.1362 32.7255 20.4514 32.4142C20.7667 32.103 21.1679 31.892 21.604 31.8079C22.0402 31.7238 22.4917 31.7706 22.9011 31.9422C23.3105 32.1138 23.6593 32.4025 23.9033 32.7716C24.1472 33.1407 24.2753 33.5736 24.2713 34.0153C24.2713 34.6052 24.0359 35.1711 23.6167 35.5887C23.1976 36.0063 22.629 36.2416 22.0355 36.243V36.2226Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.6839 29.0101C19.6761 28.3515 20.8424 28 22.0355 28C22.8294 28 23.6155 28.1557 24.3487 28.4582C25.082 28.7607 25.748 29.204 26.3087 29.7628C26.8693 30.3215 27.3136 30.9848 27.616 31.7145C27.9184 32.4442 28.073 33.2261 28.071 34.0153C28.068 35.2013 27.7114 36.3599 27.0464 37.3445C26.3813 38.3292 25.4376 39.0959 24.3345 39.5477C23.2314 39.9994 22.0184 40.1161 20.8486 39.8828C19.6789 39.6496 18.6049 39.077 17.7623 38.2372C16.9198 37.3975 16.3465 36.3284 16.1149 35.1649C15.8832 34.0015 16.0036 32.7958 16.4609 31.7004C16.9182 30.6049 17.6918 29.6687 18.6839 29.0101ZM20.1759 36.757C20.7261 37.1231 21.3734 37.3186 22.0355 37.3186L22.0406 37.3441C22.4799 37.3441 22.9148 37.2579 23.3205 37.0905C23.7262 36.9231 24.0946 36.6777 24.4047 36.3685C24.7149 36.0593 24.9605 35.6922 25.1277 35.2884C25.2949 34.8846 25.3802 34.452 25.3789 34.0153C25.3829 33.3571 25.1903 32.7125 24.8254 32.1632C24.4605 31.614 23.9398 31.1848 23.3292 30.9301C22.7187 30.6754 22.0458 30.6067 21.3959 30.7326C20.746 30.8585 20.1483 31.1735 19.6787 31.6375C19.2091 32.1015 18.8886 32.6937 18.7579 33.3389C18.6273 33.9842 18.6923 34.6536 18.9447 35.2621C19.1971 35.8706 19.6256 36.3909 20.1759 36.757Z" fill="white"/>
<path d="M65.3506 39.1538V28.2192L72.8886 36.0544V28.9176H74.4269V39.7808L66.9043 31.9762V39.1538H65.3506Z" fill="white"/>
<path d="M37.5524 33.8114H41.8086V34.153C41.8175 34.8422 41.726 35.529 41.5368 36.1921C41.3456 36.7584 41.0383 37.2792 40.6343 37.7214C40.1859 38.2422 39.6266 38.6574 38.9969 38.9369C38.3672 39.2164 37.6829 39.3532 36.9935 39.3373C36.3039 39.3487 35.6192 39.2187 34.9823 38.9556C34.3453 38.6925 33.7697 38.3019 33.2912 37.808C32.7924 37.3189 32.3988 36.7341 32.1343 36.0892C31.8699 35.4442 31.7401 34.7525 31.7528 34.0561C31.7427 33.3558 31.8766 32.6609 32.1465 32.0139C32.4163 31.367 32.8163 30.7817 33.322 30.294C34.1048 29.5236 35.1054 29.0088 36.1902 28.8185C37.2751 28.6281 38.3925 28.7712 39.3933 29.2286C40.1508 29.628 40.8204 30.174 41.3625 30.8344L40.2548 31.8896C39.921 31.3783 39.4657 30.9564 38.9291 30.6613C38.3926 30.3662 37.7913 30.2068 37.1781 30.1972C36.6682 30.1808 36.1604 30.2702 35.6871 30.4598C35.2139 30.6494 34.7856 30.935 34.4296 31.2983C34.0646 31.6592 33.7776 32.0904 33.5863 32.5655C33.3949 33.0407 33.3032 33.5496 33.3168 34.0612C33.3034 34.5923 33.4071 35.1199 33.6206 35.6069C33.8341 36.0939 34.1522 36.5285 34.5526 36.8803C35.2268 37.5229 36.1211 37.8873 37.055 37.8998C37.8101 37.8995 38.5393 37.6258 39.1062 37.13C39.3946 36.9014 39.634 36.6176 39.8102 36.2954C39.9863 35.9731 40.0957 35.6191 40.1318 35.2541H37.5678L37.5524 33.8114Z" fill="white"/>
<path d="M47.2492 30.3654H51.3515V28.9176H45.6647V39.1538H51.3515V37.701H47.2492V34.2804H51.2592V32.8276H47.2492V30.3654Z" fill="white"/>
<path d="M57.0896 30.3654H61.2227V28.9176H55.5359V39.1538H61.2124V37.701H57.0793V34.2804H61.0945V32.8276H57.0896V30.3654Z" fill="white"/>
<path d="M84.0878 30.3654H79.9856V32.8276H83.9956V34.2804H79.9856V37.701H84.1186V39.1538H78.401V28.9176H84.0878V30.3654Z" fill="white"/>
<path d="M94 30.3654H89.8721V32.8276H93.8821V34.2804H89.8721V37.701H94V39.1538H88.3132V28.9176H94V30.3654Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M26.99 23.9997C26.4154 24.0055 25.8453 23.9006 25.3139 23.6914C24.8235 23.4966 24.3783 23.2107 24.0043 22.8503C23.6381 22.4828 23.3494 22.0513 23.154 21.5793C22.9418 21.0777 22.8355 20.541 22.8413 19.9999C22.8355 19.4589 22.9418 18.9221 23.154 18.4206C23.3483 17.9494 23.6371 17.5192 24.0043 17.1543C24.3768 16.7908 24.8222 16.5031 25.3139 16.3085C26.3899 15.8972 27.5901 15.8972 28.6661 16.3085C29.1578 16.5031 29.6033 16.7908 29.9757 17.1543C30.3428 17.5202 30.6331 17.9501 30.8309 18.4206C31.0387 18.9232 31.1432 19.4594 31.1388 19.9999C31.1432 20.5404 31.0387 21.0766 30.8309 21.5793C30.632 22.0506 30.3418 22.4818 29.9757 22.8503C29.6017 23.2107 29.1566 23.4966 28.6661 23.6914C28.1348 23.9009 27.5647 24.0058 26.99 23.9997ZM26.99 23.0325C27.3986 23.0391 27.804 22.963 28.1796 22.8091C28.5552 22.6553 28.8925 22.4273 29.1695 22.14C29.4367 21.8603 29.6442 21.5333 29.7803 21.1774C30.0559 20.421 30.0559 19.5976 29.7803 18.8411C29.6431 18.4857 29.4357 18.159 29.1695 17.8785C28.7349 17.4522 28.1753 17.1619 27.5646 17.046C26.9539 16.93 26.3208 16.9938 25.7488 17.229C25.39 17.3835 25.0676 17.606 24.8014 17.8831C24.5351 18.1602 24.3304 18.4861 24.1998 18.8411C23.9179 19.5966 23.9179 20.422 24.1998 21.1774C24.3318 21.5341 24.5379 21.8615 24.8057 22.14C25.0729 22.4131 25.3933 22.6338 25.7488 22.7895C26.1395 22.9548 26.5629 23.0376 26.99 23.0325Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16 16.1964H18.7951C19.2257 16.1871 19.654 16.2585 20.0559 16.4066C20.3529 16.5187 20.62 16.6927 20.8377 16.916C21.0249 17.1123 21.162 17.3474 21.2384 17.6028C21.3102 17.8414 21.348 18.0882 21.3508 18.3365C21.3479 18.5862 21.3101 18.8345 21.2384 19.0747C21.1598 19.3293 21.0229 19.5639 20.8377 19.7616C20.6211 19.985 20.3535 20.1576 20.0559 20.2663C19.654 20.4136 19.2252 20.482 18.7951 20.4672H17.0799V23.8175H16V16.1964ZM17.0799 19.5514H18.4922C18.7018 19.5507 18.911 19.5335 19.1177 19.5C19.308 19.4717 19.4916 19.4116 19.6601 19.3224C19.8217 19.2355 19.9552 19.1078 20.0461 18.9532C20.1514 18.7587 20.2019 18.5413 20.1927 18.3224C20.2027 18.105 20.1521 17.8889 20.0461 17.6963C19.9528 17.5472 19.8195 17.4246 19.6601 17.3412C19.4877 17.2529 19.301 17.1929 19.1079 17.1636C18.9008 17.1339 18.6918 17.1183 18.4824 17.1169H17.0799V19.5514Z" fill="white"/>
<path d="M33.4891 16.1963H32.3652L34.6766 23.8035H35.8249L37.7405 17.5935L39.6951 23.8035H40.8239L43.1353 16.1963H42.0114L40.2522 22.2708L38.3806 16.1963H37.1443L35.2727 22.2708H35.2483L33.4891 16.1963Z" fill="white"/>
<path d="M44.8505 16.1963H49.9864V17.1636H45.9305V19.4298H49.7029V20.3971H45.9305V22.8362H50.1867V23.8035H44.8505V16.1963Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M55.2003 16.1963H52.4003V23.8222H53.4705V20.4719H54.6726L56.6956 23.8222H58.0443L55.7867 20.3504C56.0706 20.3277 56.3466 20.2498 56.5979 20.1214C56.8316 20.0014 57.0417 19.8433 57.2185 19.6541C57.3845 19.4739 57.5152 19.2665 57.6045 19.042C57.6963 18.8172 57.7428 18.5778 57.7414 18.3364C57.7386 18.0882 57.7007 17.8414 57.629 17.6028C57.5546 17.3466 57.4172 17.1111 57.2283 16.9159C57.0105 16.6926 56.7434 16.5186 56.4464 16.4066C56.0492 16.2601 55.626 16.1886 55.2003 16.1963ZM54.8974 19.5513H53.4802V17.0982H54.8974C55.1051 17.0996 55.3125 17.1152 55.518 17.1449C55.7111 17.1742 55.8978 17.2342 56.0701 17.3225C56.2321 17.411 56.3656 17.5403 56.4562 17.6963C56.5622 17.8889 56.6128 18.105 56.6028 18.3224C56.612 18.5413 56.5615 18.7587 56.4562 18.9532C56.3653 19.1078 56.2318 19.2355 56.0701 19.3224C55.8987 19.4127 55.7116 19.4728 55.518 19.4999C55.3129 19.5335 55.1054 19.5507 54.8974 19.5513Z" fill="white"/>
<path d="M59.9306 16.1963H65.0665V17.1636H61.0106V19.4298H64.7879V20.3971H61.0106V22.8362H65.2717V23.8035H59.9306V16.1963Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M70.0703 16.1964H67.2947L67.2801 23.7895H69.8749C70.5181 23.8015 71.1574 23.6919 71.7562 23.4671C72.2682 23.2738 72.7338 22.9828 73.1245 22.612C73.4862 22.2681 73.7691 21.8559 73.9552 21.4017C74.1393 20.9552 74.2354 20.4799 74.2386 19.9999C74.2354 19.7411 74.2076 19.483 74.1555 19.2289C74.1027 18.9407 74.0175 18.6587 73.9014 18.3879C73.7752 18.0967 73.6111 17.8219 73.4128 17.5701C73.1994 17.2893 72.9384 17.0445 72.6407 16.8459C72.3001 16.6364 71.9275 16.4787 71.5363 16.3786C71.0594 16.2504 70.5656 16.1891 70.0703 16.1964ZM69.7332 22.8363H68.3747V17.1636H69.9921C70.5275 17.1456 71.0597 17.248 71.5461 17.4627C71.9166 17.6409 72.2406 17.8964 72.4941 18.2103C72.7225 18.4863 72.8889 18.8045 72.9827 19.1448C73.056 19.4247 73.097 19.7115 73.1049 19.9999C73.1159 20.4284 73.0239 20.8537 72.8361 21.2429C72.6637 21.5871 72.4133 21.8904 72.1032 22.1307C71.7848 22.3702 71.4216 22.5495 71.033 22.6587C70.6114 22.7797 70.1733 22.8396 69.7332 22.8363Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M80.254 16.1964H83.0198C83.3497 16.1918 83.6786 16.2311 83.9971 16.3132C84.2721 16.383 84.5307 16.5019 84.7594 16.6636C84.9672 16.8217 85.1343 17.0231 85.2481 17.2524C85.3762 17.5172 85.4397 17.8064 85.4338 18.0982C85.4521 18.4888 85.3141 18.8714 85.0478 19.1682C84.784 19.4498 84.4467 19.6595 84.0704 19.7756C84.3053 19.7916 84.5345 19.8519 84.7448 19.9532C84.9515 20.0579 85.1368 20.1971 85.2921 20.3644C85.447 20.5377 85.5694 20.7353 85.6537 20.9485C85.7469 21.1778 85.7933 21.4221 85.7905 21.6681C85.7977 21.9846 85.7221 22.2978 85.5706 22.5793C85.4252 22.8397 85.2196 23.065 84.9696 23.2381C84.7003 23.4223 84.3993 23.5599 84.0802 23.6446C83.7257 23.7402 83.3588 23.7874 82.9905 23.7848H80.254V16.1964ZM81.3339 19.3551H82.7999C82.994 19.3568 83.1875 19.3364 83.3765 19.2944C83.5526 19.2644 83.7195 19.1973 83.8652 19.0981C84.0011 19.0063 84.1117 18.8845 84.1877 18.743C84.2708 18.5786 84.311 18.3974 84.305 18.215C84.3084 17.9266 84.2005 17.6471 84.002 17.43C83.8749 17.309 83.7214 17.2164 83.5524 17.1591C83.3833 17.1017 83.2031 17.0809 83.0247 17.0982H81.329L81.3339 19.3551ZM81.3339 22.9017H82.9172C83.1007 22.901 83.2838 22.8853 83.4645 22.855C83.6614 22.8235 83.8504 22.7569 84.0216 22.6587C84.1972 22.5612 84.3475 22.427 84.4614 22.2662C84.5878 22.0701 84.6491 21.8421 84.6373 21.612C84.652 21.4201 84.618 21.2276 84.538 21.051C84.458 20.8745 84.3344 20.719 84.1779 20.598C83.8152 20.3521 83.3757 20.2318 82.9319 20.2569H81.3339V22.9017Z" fill="white"/>
<path d="M86.8949 16.1963L89.905 20.5606L89.9246 23.8035H91.0045V20.5606L94 16.1963H92.6855L90.4474 19.5373L88.2778 16.1963H86.8949Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_3224_18830" x="0" y="0" width="110" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="8"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3224_18830"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3224_18830" result="shape"/>
</filter>
</defs>
</svg>`;
