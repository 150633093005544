import React, { FC, forwardRef, memo }     from 'react';
import { Scrollbars }                      from "react-custom-scrollbars";
import { cn }                              from '../../util/bem';
import { RadiusSizesType, SizesUnionType } from "../../util/global-props";
import { ChildrenOf }                      from '../../util/types';
import { MenuItem }                        from './menu-item.component';
import './menu.component.scss';

export type MenuPropsType = {
    className?: string;
    children?: ChildrenOf<typeof MenuItem> | ChildrenOf<typeof MenuItem>[];
    prepend?: React.ReactNode;
    prependSticky?: boolean
    activeItemId?: string | null;
    viewType?: 'light' | 'dark';
    onSelect?: (e: React.SyntheticEvent, id: string) => void;
    role?: string;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    style?: React.CSSProperties;
    radius?: RadiusSizesType;
    padding?: SizesUnionType;
    fullWidth?: boolean;
    maxCount?: number
}
export type Ref = HTMLUListElement;

const className = cn('menu');

const ITEM_SIZES = {
    xs: 24,
    sm: 32,
    md: 40,
    lg: 48
};
export const Menu: FC<MenuPropsType> = memo(forwardRef<Ref, MenuPropsType>((props, ref) => {
    const style = { ...props.style };

    return (
        <ul
            className={ className({
                viewType:  props.viewType,
                radius:    props.radius,
                padding:   props.padding,
                fullWidth: props.fullWidth
            }, null, props.className) }
            role={ props.role }
            ref={ ref }
            style={ style }
        >
            { props.prependSticky && props.prepend ? props.prepend : null }
            <Scrollbars
                style={ { overflowX: 'hidden' } }
                autoHide
                autoHideTimeout={ 500 }
                autoHideDuration={ 200 }
                autoHeight
                // each item 40px + padding 8px
                autoHeightMax={ props.maxCount ? `${ ITEM_SIZES[ props.size || 'sm' ] * props.maxCount }px` : '100%' }
            >
                { !props.prependSticky && props.prepend ? props.prepend : null }
                {
                    React.Children.map(props.children, (child) => (
                        React.isValidElement(child)
                            ? React.cloneElement(child, {
                            // @ts-ignore
                                ...child.props,
                                selected: child.props.id === props.activeItemId,
                                id:       child.props.id,
                                size:     props.size,
                                ...props.onSelect && { onSelect: props.onSelect }
                            })
                            : null))
                }
            </Scrollbars>
        </ul>
    );
}));

Menu.defaultProps = {
    role:      'menu',
    fullWidth: true,
    size:      'sm'
};
