import React, { useRef, useState } from 'react';
import { observer }                from 'mobx-react';
import Trash                       from '@geenee/builder/src/asset/icons/Trash';
import { cardToString }            from '@geenee/builder/src/components/modals/common/CardToString';
import { stripeState }             from '@geenee/builder/src/core/state/stripe.state';
import { BoldCenter, Link }        from '../common/styles';
import { CreditCardEditor }        from '../CreditCardEditor';
import { ManageBilling }           from '../ManageBilling';
import {
    ButtonsWrapper,
    Card,
    CardBorder,
    CardUndo,
    CardWrapper,
    StyledButton,
    Wrapper
}                        from './styles';

type Props = {
    close: () => void;
    // eslint-disable-next-line no-empty-pattern
    setModal: ({ }) => void;
    showError: (message: string) => void;
};

export const ManageCards = observer((props: Props) => {
    const [ detachingId, setDetachingId ] = useState('');
    const undoRef = useRef();
    const [ waiting, setWaiting ] = useState(false);

    const disableDelete = Object.keys(stripeState.paymentMethods).length <= 1;

    const undo = () => {
        const button = undoRef.current as any;
        if (button) {
            button.setAttribute('SlideRight', true);

            button.onanimationend = (event: any) => {
                if (event.animationName === 'SlideRight') {
                    setDetachingId('');
                }
            };
        }
    };

    const handleButton = async () => {
        /* Add new card */
        if (detachingId === '') {
            props.setModal({
                component: CreditCardEditor,
                props:     {
                    isNewCard:               true,
                    onAddingNewCardFinished: () => props.setModal({ component: ManageBilling })
                }
            });
            return;
        }

        setWaiting(true);
        try {
            props.showError('');
            await stripeState.deletePaymentMethod(detachingId);
            setWaiting(false);
            setDetachingId('');
        } catch (e: any) {
            // @ts-ignore
            props.showError(e.message);
            setWaiting(false);
        }
    };

    // eslint-disable-next-line react/no-unused-prop-types,no-empty-pattern,no-shadow
    function RenderCard(props: { paymentMethodId: string; setModal: ({ }) => void }) {
        const { paymentMethodId } = props;
        const card = stripeState.paymentMethods[ paymentMethodId ];
        return (
            <CardWrapper key={ paymentMethodId }>
                <Card>
                    <span>
                        <p>{ card.name }</p>
                        <p>{ cardToString(card) }</p>
                    </span>
                    { !disableDelete ? (
                        <ButtonsWrapper>
                            { /* <Pencil
                            onClick={ () => setModal({
                                component: CreditCardEditor,
                                props:     {
                                    paymentMethodId,
                                    onAddingNewCardFinished: () => setModal({ component: ManageCards })
                                }
                            }) }
                        /> */ }
                            <Trash
                                onClick={ () => setDetachingId(paymentMethodId) }
                            />
                        </ButtonsWrapper>
                    ) : <></> }
                </Card>
                <CardBorder />
                { detachingId === paymentMethodId ? (
                    <CardUndo
                    // @ts-ignore
                        ref={ undoRef }
                    >
                        <p>
                            Are you sure you want to delete this card?
                            { ' ' }
                            <Link onClick={ undo }>Undo</Link>
                        </p>
                    </CardUndo>
                ) : null }
            </CardWrapper>
        );
    }

    const RenderManage = (
        <>
            { Object.keys(stripeState.paymentMethods).map(
                (paymentMethodId, i) => (
                    <RenderCard key={ i } paymentMethodId={ paymentMethodId } setModal={ props.setModal } />
                )
            ) }
            <StyledButton
                $width={ 172 }
                $waiting={ waiting }
                onClick={ handleButton }
                disabled={ waiting }
                label={ detachingId === '' ? 'ADD NEW CARD' : 'CONFIRM' }
            />
        </>
    );

    return (
        <Wrapper>
            <BoldCenter>manage cards</BoldCenter>
            { RenderManage }
        </Wrapper>
    );
});
