import React, { useMemo, useRef, useState }    from 'react';
import { useHistory }                 from "@geenee/common";
import { LoadingSpinnerCentered }     from '@geenee/shared/src/magellan/components/LoadingSpinner';
import useComposerLocationParser      from '@geenee/shared/src/util/useComposerLocationParser';
import { Emptable, EntityOptions }    from '@geenee/shared/type/shared.type';
import { EditableArea }               from '@geenee/ui';
import classnames                     from 'classnames';
import { ObservableMap, runInAction } from 'mobx';
import { antagonisticSections }       from '@geenee/builder/src/components/Composer/HeaderButtons/AddSection/index';
import { Pill, PillType }             from '@geenee/builder/src/components/Pill';
import { DeletedSection }             from '@geenee/builder/src/components/Tree/DeletedSection';
import { useNodeChildren }            from '@geenee/builder/src/components/Tree/hooks/useNodeChildren';
import { useTreeNodeDrag }            from '@geenee/builder/src/components/Tree/hooks/useTreeNodeDrag';
import { useTreeNodeUtils }           from '@geenee/builder/src/components/Tree/hooks/useTreeNodeUtils';
import { useBuilderInject }           from '@geenee/builder/src/core/hook/use-builder-inject';
import {
    CONTENT_AR_PROPERTY_NAME,
    EXPERIENCE_RENAMED,
    MOLECULE_RENAMED,
    NODE_TYPE_EXPERIENCE,
    NODE_TYPE_PROJECT,
    SECTION_RENAMED,
    TOAST_ERROR,
    TYPE_DRAWER_OVERLAY,
    TYPE_SLAM_AR,
    TYPE_STAMP_AR,
    VIEW_RENAMED
} from '@geenee/builder/src/lib/constants';
import { NodeActions } from './NodeActions';
import {
    Node,
    NodeControl,
    NodeLine,
    NodeTitle,
    PillWrapper
}                                 from './styles';

export type ElementType = {
    type: string | undefined;
    id: string;
    path: string;
    title?: string;
    children?: ElementType[];
    published?: boolean;
    nft_published?: boolean;
    parent_id?: string;
    $parent?: any;
    options?: Emptable<EntityOptions>
};

export type TreeNodeProps = {
    node: ElementType;
    lastNode: boolean;
    onCollapsedChange: (id: string, value: boolean, type: string, historyPath?: string) => Promise<void>;
    openedNodes: Set<string>;
    index: number;
    moveItem: (dragIndex: number, hoverIndex: number) => void;
    triggerTreeUpdate: () => void;
    depth: number;
    updateProgramsOrder: () => void;
    // eslint-disable-next-line react/require-default-props
    treeChildren?: ObservableMap<string, any>;
    onAddWillBeDeletedItem: (id: string) => void;
    willBeDeletedItems: Set<string>;
    onDeleteItem: (id: string) => void;
    onTitleChange: (id: string, value: string) => void;
    // eslint-disable-next-line react/require-default-props
    hideMoveIcon?: boolean;
    isLoading?: boolean
    isFolderChildren?: boolean
};

export function TreeNode({
    node,
    lastNode,
    onCollapsedChange,
    openedNodes,
    index,
    moveItem,
    triggerTreeUpdate,
    depth,
    updateProgramsOrder,
    treeChildren,
    onAddWillBeDeletedItem,
    willBeDeletedItems,
    onDeleteItem,
    onTitleChange,
    hideMoveIcon,
    isLoading,
    isFolderChildren
}: TreeNodeProps) {
    const history = useHistory();
    const [ canMove, setCanMove ] = useState(false);
    const { id, title, type, path } = node;
    const paddingLeft = useMemo(() => {
        if (type === 'project') {
            return 45;
        }
        if (type === 'experience') {
            return 60;
        }
        return 100;
    }, [ type ]);
    const { currentUrl } = useComposerLocationParser();
    const collapsed = !openedNodes.has(id);
    const containerRef = useRef(null);
    const [ showDeleteRow, setShowDeleteRow ] = useState(false);
    const [ deleteTimeout, setDeleteTimeout ] = useState();
    const { BuilderState } = useBuilderInject();

    const { getIcon } = useTreeNodeUtils();

    const { childMove } = useTreeNodeDrag(
        node,
        index,
        canMove,
        updateProgramsOrder,
        treeChildren,
        containerRef,
        onCollapsedChange,
        moveItem,
        triggerTreeUpdate
    );

    // eslint-disable-next-line no-shadow
    const onDeleteChildItem = (id: string) => {
        if (treeChildren) {
            const item = treeChildren.get(id);
            if (item) {
                item.$parent.deleteChild(item.id);
                willBeDeletedItems.delete(id);
            }
        }
    };

    // eslint-disable-next-line no-shadow
    const getAnalyticDataForTitleChangingEvent = (entity) => {
        if (entity?.type === 'experience') {
            // eslint-disable-next-line no-shadow
            const { id, program_id, title } = entity;

            return { eventName: EXPERIENCE_RENAMED, properties: { id, program_id, title } };
        }
        if (entity?.type === 'view') {
            // eslint-disable-next-line no-shadow
            const { id, experience_id, title } = entity;

            return { eventName: VIEW_RENAMED, properties: { id, experience_id, title } };
        }
        if (antagonisticSections.includes(entity?.type)) {
            // eslint-disable-next-line default-case
            switch (true) {
                case !!entity.view_id: {
                    // eslint-disable-next-line no-shadow
                    const { id, view_id, title } = entity;
                    return { eventName: SECTION_RENAMED, properties: { id, view_id, title } };
                }
                case !!entity.section_id: {
                    // eslint-disable-next-line no-shadow
                    const { id, section_id, title } = entity;
                    return { eventName: MOLECULE_RENAMED, properties: { id, section_id, title } };
                }
            }
        }

        return null;
    };

    // eslint-disable-next-line no-shadow
    const onTitleChildChange = (id: string, value: string) => {
        if (treeChildren) {
            const item = treeChildren.get(id);
            if (item) {
                item.title = value;
                item.saveData(getAnalyticDataForTitleChangingEvent(item));
            }
        }
    };

    const { nodeChildren } = useNodeChildren(
        node,
        openedNodes,
        childMove,
        onCollapsedChange,
        triggerTreeUpdate,
        depth,
        updateProgramsOrder,
        onAddWillBeDeletedItem,
        willBeDeletedItems,
        onDeleteChildItem,
        onTitleChildChange,
        treeChildren
    );

    const handleLineClick = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        e.stopPropagation();
        // history.push(path);
        onCollapsedChange(id, false, type, path);
    };

    const handleControlClick = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        e.stopPropagation();
        if (type === CONTENT_AR_PROPERTY_NAME) {
            // history.push(path);
            onCollapsedChange(id, !collapsed, type, path);
        } else {
            onCollapsedChange(id, !collapsed, type);
        }
    };

    const icon = getIcon(type, collapsed, node.children);
    const children = nodeChildren();
    const preprocessedPath = path && path.endsWith('/')
        ? path.slice(0, path.length - 1)
        : path;
    const preprocessedUrl = currentUrl.endsWith('/')
        ? currentUrl.slice(0, currentUrl.length - 1)
        : currentUrl;
    const isProject = type === NODE_TYPE_PROJECT;
    const disabled = !!(node && node.nft_published);
    if (!showDeleteRow && willBeDeletedItems.has(id)) {
        return <></>;
    }
    if (showDeleteRow) {
        return (
            <DeletedSection
                onCloseDelete={ () => setShowDeleteRow(false) }
                onUndoClick={ () => {
                    if (deleteTimeout) {
                        clearTimeout(deleteTimeout);
                        setDeleteTimeout(undefined);
                        setShowDeleteRow(false);
                        willBeDeletedItems.delete(id);
                    }
                } }
            />
        );
    }

    const nodeClass = classnames('tree-node', isFolderChildren ? { lastNode } : {
        lastNode,
        noParent: isProject
    });

    // TODO: fix last node
    return (
        <Node
            ref={ containerRef }
            key={ id }
            className={ nodeClass }
        >
            <NodeLine
                onClick={ handleLineClick }
                paddingLeft={ paddingLeft }
            >
                { isLoading ? (
                    <LoadingSpinnerCentered
                        style={ { width: 30, height: 15, alignItems: 'flex-start' } }
                        size={ 15 }
                    />
                ) : (
                    <NodeControl
                        className={
                            (preprocessedPath === preprocessedUrl
                            && 'activeAsset')
                        || ''
                        }
                        onClick={ handleControlClick }
                    >
                        { icon }
                    </NodeControl>
                ) }
                <NodeTitle
                    className={ classnames({
                        matchPath:
                            preprocessedPath === preprocessedUrl
                            || (type === TYPE_SLAM_AR
                                && currentUrl.includes(path))
                            || (type === TYPE_STAMP_AR
                                && currentUrl.includes(path))
                            || (type === TYPE_DRAWER_OVERLAY
                                && currentUrl.includes(path))
                    }) }
                    onClick={ () => {
                        // history.push(path);
                    } }
                >
                    <EditableArea
                        shorten
                        value={ title }
                        onChange={ (value) => onTitleChange(id, value) }
                        onError={ () => {
                            runInAction(() => {
                                BuilderState.toast = { severity: TOAST_ERROR, detail: 'Title could not be empty', summary: '' };
                            });
                        } }
                        disabled={ disabled }
                        doubleClickMode
                    />
                </NodeTitle>
                { isProject ? (
                    <PillWrapper>
                        { /* eslint-disable-next-line no-nested-ternary */ }
                        <Pill
                            type={
                                // eslint-disable-next-line no-nested-ternary
                                node.nft_published
                                    ? PillType.NFT_LIVE
                                    : node.published
                                        ? PillType.LIVE
                                        : PillType.DRAFT
                            }
                        />
                        { ' ' }
                    </PillWrapper>
                ) : (
                    <></>
                ) }
                <NodeActions
                    disabled={ disabled }
                    hideMoveIcon={ hideMoveIcon }
                    onChangeCanMove={ (v) => {
                        setCanMove(v);
                    } }
                    depth={ depth }
                    onDeleteClicked={ () => {
                        // redirect to parent node
                        if (currentUrl.includes(id) && !isProject) {
                            let newUrl = currentUrl.split(id).shift();
                            if (newUrl) {
                                if (node?.$parent?.type === NODE_TYPE_EXPERIENCE) {
                                    // @ts-ignore
                                    newUrl = newUrl.split(node.parent_id).shift();
                                }
                                // @ts-ignore
                                newUrl = newUrl.slice(0, newUrl.length - 1);
                                history.push(newUrl);
                            }
                        }
                        setShowDeleteRow(true);
                        onAddWillBeDeletedItem(id);
                        const removeTime = setTimeout(() => {
                            onDeleteItem(id);
                        }, 5000);
                        // @ts-ignore
                        setDeleteTimeout(removeTime);
                    } }
                />
            </NodeLine>
            { children }
        </Node>
    );
}
