/**
 * Content types
 */
export const TYPE_DRAWER_OVERLAY = 'overlay';
export const TYPE_DRAWER_SHARE = 'drawer_share';
export const TYPE_DRAWER_SIGNUP = 'drawer_signup';
export const TYPE_DRAWER_SPOTIFY = 'drawer_spotify';
export const TYPE_DRAWER_FANDANGO = 'drawer_fandango';
export const TYPE_FULLSCREEN_GALLERY = 'fullscreen-gallery';
export const TYPE_FULLSCREEN_VIDEO = 'fullscreen-video';
export const TYPE_IFRAME = 'iframe';
export const TYPE_SLAM_AR = 'slam-ar';
export const TYPE_STAMP_AR = 'stamp-ar';
export const TYPE_WYSIWYG = 'wysiwyg';
export const TYPE_CUSTOM_CODE = 'custom-code';
