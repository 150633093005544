import React, { memo, useState }                     from 'react';
import { useBuilderInject }                          from "@geenee/builder/src/core/hook/use-builder-inject";
import { InviteModel }                               from '@geenee/builder/src/core/model/invite.model';
import { UserModel }                                 from '@geenee/builder/src/core/model/user.model';
import { MEMBER_DELETE_SUCCESS, TOAST_SUCCESS }      from "@geenee/builder/src/lib/constants";
import { useHistory }                                from "@geenee/common";
import { CallbackFunctionVariadic }                  from "@geenee/geeclient-kit/src/lib/type/type";
import { Button, Description, Icon, Input, Wrapper } from "@geenee/ui";

export const DeleteMemberModalContent = memo((props: { member: InviteModel | UserModel, onClose: CallbackFunctionVariadic }) => {
    const [ message, setMessage ] = useState("");
    const history = useHistory();
    const { BuilderState } = useBuilderInject();

    return (
        <Wrapper align="center" style={ { width: 400 } }>
            <Icon size={ 48 } name="error" color="gradient-warm-4" margin="md" />
            <Description weight="bold" margin="xs" size="lg" lineHeight="lg">
                Delete account confirmation
            </Description>
            <Description margin="xxs" align="center" color="shade-4">
                Are you sure you want to delete the user?
                If so, it will be permanently deleted.
            </Description>
            <Description margin="md" align="center" color="shade-4">
                Confirm by typing “DELETE” below.
            </Description>
            <Input
                size="md"
                fullWidth
                value={ message }
                placeholder="DELETE"
                onChange={ (e) => setMessage(e.target.value) }
            />
            <Wrapper maxWidth="200px" style={ { paddingBottom: 0 } } padding="sm" row margin="xs" align="center">
                <Button
                    fullWidth
                    uppercase
                    disabled={ message !== "DELETE" }
                    viewType="alert"
                    onClick={ async () => {
                        if (props.member instanceof UserModel) {
                            await BuilderState.$teamState.deleteMember(props.member?.email || '');
                        } else {
                            await BuilderState.$teamState.deleteInvite(props.member?.id || '');
                        }
                        BuilderState.update("toast", {
                            severity: TOAST_SUCCESS,
                            detail:   MEMBER_DELETE_SUCCESS,
                            summary:  ""
                        });
                        props.onClose();
                        history.push("/team");
                    } }
                    margin="xs"
                >
                    Delete Account
                </Button>
            </Wrapper>
        </Wrapper>
    );
});
