import React from 'react';

function GeeneeLogo({ width = '152.12', height = '23.55', fill = 'black' }) {
    return (
        <svg
            width={ width }
            height={ height }
            viewBox="0 0 152.12 23.55"
            fill={ fill }
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
            // eslint-disable-next-line max-len
                d="M19.72,6.12A11.78,11.78,0,1,0,31.49,17.89,11.77,11.77,0,0,0,19.72,6.12Zm0,18.29a6.52,6.52,0,1,1,6.51-6.52h0a6.51,6.51,0,0,1-6.51,6.52Z"
                transform="translate(-7.94 -6.12)"
            />
            <path
            // eslint-disable-next-line max-len
                d="M19.72,12.72a5.15,5.15,0,1,0,5.15,5.15,5.16,5.16,0,0,0-5.15-5.15Zm0,9.53a4.37,4.37,0,1,1,4.36-4.36,4.37,4.37,0,0,1-4.36,4.36Z"
                transform="translate(-7.94 -6.12)"
            />
            <path
            // eslint-disable-next-line max-len
                d="M19.7,14.58a3.33,3.33,0,1,0,3.36,3.31,3.34,3.34,0,0,0-3.36-3.31Zm0,5a1.73,1.73,0,1,1,1.73-1.73,1.73,1.73,0,0,1-1.73,1.73Z"
                transform="translate(-7.94 -6.12)"
            />
            <path
            // eslint-disable-next-line max-len
                d="M50,17.53h8.3v.66a13.6,13.6,0,0,1-.53,4,8.51,8.51,0,0,1-1.76,3,9.13,9.13,0,0,1-7.11,3.15,9.81,9.81,0,0,1-7.23-3.05,10.16,10.16,0,0,1-3-7.36,10.08,10.08,0,0,1,3.06-7.38A10.58,10.58,0,0,1,53.53,8.49a12.72,12.72,0,0,1,3.84,3.15l-2.16,2.07a7.3,7.3,0,0,0-6-3.32,7.16,7.16,0,0,0-5.35,2.21A7.36,7.36,0,0,0,41.68,18a7.11,7.11,0,0,0,2.41,5.54,7.2,7.2,0,0,0,4.87,2,6,6,0,0,0,4-1.51,5.34,5.34,0,0,0,2-3.68H50Z"
                transform="translate(-7.94 -6.12)"
            />
            <path
            // eslint-disable-next-line max-len
                d="M76.89,10.76H68.83v4.83h7.82v2.85H68.83v6.72h8.06V28H65.8V7.92H76.89Z"
                transform="translate(-7.94 -6.12)"
            />
            <path
            // eslint-disable-next-line max-len
                d="M96.13,10.76H88.07v4.83H95.9v2.85H88.07v6.72h8.06V28H85V7.92H96.13Z"
                transform="translate(-7.94 -6.12)"
            />
            <path
            // eslint-disable-next-line max-len
                d="M104.19,28V6.55l14.65,15.33v-14h3V29.23L107.22,13.94V28Z"
                transform="translate(-7.94 -6.12)"
            />
            <path
            // eslint-disable-next-line max-len
                d="M140.72,10.76h-8v4.83h7.82v2.85h-7.82v6.72h8.06V28H129.63V7.92h11.09Z"
                transform="translate(-7.94 -6.12)"
            />
            <path
            // eslint-disable-next-line max-len
                d="M160.05,10.76H152v4.83h7.82v2.85H152v6.72h8.06V28H149V7.92h11.09Z"
                transform="translate(-7.94 -6.12)"
            />
        </svg>
    );
}

// eslint-disable-next-line arca/no-default-export
export default GeeneeLogo;
