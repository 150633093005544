import "@babylonjs/core/Rendering/boundingBoxRenderer";
import * as React                           from "react";
import { useState }                         from "react";
import type { IExplorerExtensibilityGroup } from "@babylonjs/core/Debug/debugLayer";
import type { AbstractMesh }                from "@babylonjs/core/Meshes/abstractMesh";
import { HEAD_OCCLUDER_NODE_ID }            from "@geenee/shared/src/util/constants";
import { Icon, Wrapper }                    from "@geenee/ui";
import { observer }                         from "mobx-react";
import type { GlobalStateClass }            from "../../globalState";
import { MoreAction }                       from "../components/moreAction";
import { ExtensionsComponent }              from "../extensionsComponent";

interface IMeshTreeItemComponentProps {
    mesh: AbstractMesh;
    extensibilityGroups?: IExplorerExtensibilityGroup[];
    onClick: () => void;
    globalState: GlobalStateClass;
}

export const MeshTreeItemComponent = observer((props: IMeshTreeItemComponentProps) => {
    const [ isVisible, setIsVisible ] = useState(props.mesh.isVisible);
    // const [ isBoundingBoxEnabled, setIsBoundingBoxEnabled ] = useState(props.mesh.showBoundingBox);

    /* const showBoundingBox = () => {
        props.mesh.showBoundingBox = !isBoundingBoxEnabled;
        setIsBoundingBoxEnabled(!isBoundingBoxEnabled);
    }; */

    const switchVisibility = () => {
        const newState = !isVisible;
        setIsVisible(newState);
        props.mesh.isVisible = newState;
    };

    const disableDelete = [ ].includes(props.mesh.id);

    return (
        <Wrapper row>
            { /* <Icon
                name="checkboxUncheckedNew"
                color={ isBoundingBoxEnabled ? "shade-2" : "shade-4" }
                onClick={ (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    showBoundingBox();
                } }
            /> */ }
            <Icon
                name={ props.mesh.isVisible ? 'eyeOn' : 'eyeOff' }
                onClick={ (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    switchVisibility();
                } }
                margin="xs"
            />
            <MoreAction
                type="mesh"
                popoverSchemaType="full-popover"
                item={ props.mesh }

            />
            <ExtensionsComponent target={ props.mesh } extensibilityGroups={ props.extensibilityGroups } />
        </Wrapper>
    );
});
