import { Wrapper }  from '@geenee/ui';
import styled       from 'styled-components';
import StyledButton from '@geenee/builder/src/components/common/Button';

export const GradientWrapper = styled(Wrapper)`
  padding: 18px 23px;
  background: #000;
  border-radius: 10px;
`;

export const CopyButton = styled(StyledButton)`
    &&& {
        background: ${ ({ theme: { colors } }) => colors.white };
        border: 1px solid
            ${ ({ theme: { secondaryColors } }) => secondaryColors.grey200 };
        box-shadow: none;
        color: black;

        &:hover {
            background: rgba(196, 196, 196, 0.5);
            box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
            color: black;
        }
    }
`;
