
//@ts-nocheck

/* eslint-disable implicit-arrow-linebreak */
import { NoStrictEntityMods, withNaming } from '@bem-react/classname';

const classNameInitializer = withNaming({
  n: 'gee-kit-',
  e: '__',
  m: '--',
  v: '-',
});

export const cn = (blockName: string) => {
  const classFormatter = classNameInitializer(blockName);
  return (
    elemNameOrBlockMods?: NoStrictEntityMods | string | null,
    elemModsOrBlockMix?: NoStrictEntityMods | null,
    externalClassBefore = '',
    externalClassAfter = ''
  ) =>
    (externalClassBefore ? `${externalClassBefore} ` : '') +
    classFormatter(elemNameOrBlockMods, elemModsOrBlockMix || undefined) +
    (externalClassAfter ? ` ${externalClassAfter}` : '');
};



const classNameKitGeneric = (kitElemName:string) => (blockname:string) => (
  elemNameOrBlockMods?: NoStrictEntityMods | string | null,
    elemModsOrBlockMix?: NoStrictEntityMods | null,
  ) => cn(blockname)(elemNameOrBlockMods, elemModsOrBlockMix,  kitElemName, 'light');
export const cnKitAtom = (blockName: string) => classNameKitGeneric("gee-kit-atom")(blockName);
export const cnKitMolecule = (blockName: string) => classNameKitGeneric("gee-kit-molecule")(blockName);
export const cnKitSection = (blockName: string) => classNameKitGeneric("gee-kit-section")(blockName);
export const cnKitPage = (blockName: string) => classNameKitGeneric("gee-kit-page")(blockName);



// export const classNameMolecule = (blockName: string) =>  (
//   elemNameOrBlockMods?: NoStrictEntityMods | string | null,
//   elemModsOrBlockMix?: NoStrictEntityMods | null,
//   externalClassBefore = '',
//   externalClassAfter = ''
// )=> (`gee-kit-molecule ${ cn(blockName)(elemNameOrBlockMods, elemModsOrBlockMix, externalClassBefore, externalClassAfter)}`)


