import { Wrapper } from '@geenee/ui';
import styled      from 'styled-components';

export const GradientWrapper = styled(Wrapper)`
  padding: 30px 25px;
  background: rgba(196, 196, 196, 0.19);
  border-radius: 10px;

  .one-ui-form-item:first-child {
    margin-bottom: 14px;
  }
`;
