import React                    from 'react';
import { Description, Wrapper } from '@geenee/ui';
import AddContent               from '@geenee/builder/src/asset/icons/AddContent';
import GreenCheckmark           from '@geenee/builder/src/asset/icons/GreenCheckmark';
import PauseVideoIcon           from '@geenee/builder/src/asset/icons/PauseVideoIcon';
import PlayVideoIcon            from '@geenee/builder/src/asset/icons/PlayVideoIcon';
import {
    ButtonWrapper,
    SongAlbumWrapper,
    SongContainer,
    SongRow
} from './styles';

type Props = {
    track: any;
    onTrackClickHandler: () => void;
    trackClickHandler: () => void;
    showStopIcon: boolean;
    choosedTrack: any;
}

function TrackRow({
    track, onTrackClickHandler, trackClickHandler, showStopIcon, choosedTrack
}: Props) {
    const selected = track.preview_url === choosedTrack.preview_url;

    return (
        <SongRow key={ track.id }>
            <SongContainer>
                <Wrapper row fullWidth valign='center'>
                    <Wrapper style={ { flex: '0 0 301px' } }>
                        <SongAlbumWrapper>
                            <Wrapper row valign='center'>
                                <img src={ track.album.images[ 0 ]?.url } alt="album cover" />
                                <Wrapper padding='sm'>
                                    <Description size='sm'>{ track.album.name }</Description>
                                </Wrapper>
                            </Wrapper>
                        </SongAlbumWrapper>
                    </Wrapper>
                    <Wrapper padding='sm' style={ { flex: '0 0 216px' } }>
                        <Description size='sm'>{ track.artists.map((artist) => artist.name).join(', ') }</Description>
                    </Wrapper>
                    <Wrapper row valign='center' style={ { flex: '1 0 auto', width: 'auto' } } padding='sm'>
                        <Wrapper style={ { flex: '0 0 52px' } }>
                            <ButtonWrapper
                                // @ts-ignore
                                onClick={ () => trackClickHandler(track.preview_url) }
                            >
                                {
                                    showStopIcon
                                        ? <PauseVideoIcon width="50" height="50" />
                                        : <PlayVideoIcon width="50" height="50" />
                                }
                            </ButtonWrapper>
                        </Wrapper>
                        <Wrapper style={ { flex: ' 0 0 190px' } } padding='sm'>
                            <Description size='sm'>{ track.name }</Description>
                        </Wrapper>
                        <Wrapper style={ { flex: '0 0 50px' } }>

                            { selected ? (
                                <GreenCheckmark width='50px' height='50px' />
                            )
                                : (
                                    <ButtonWrapper>
                                        <AddContent
                                            width='50'
                                            height='50'
                                            // @ts-ignore
                                            onClick={ () => onTrackClickHandler(track) }
                                        />
                                    </ButtonWrapper>
                                ) }
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </SongContainer>
        </SongRow>
    );
}

// eslint-disable-next-line arca/no-default-export
export default TrackRow;
