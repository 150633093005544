import { useCallback, useEffect, useState } from 'react';
import cloneDeep                            from 'lodash-es/cloneDeep';
import { TeamLibraryItemModel }             from '~/module/team-library/state/team-library-item.model';

export const useReorder = (storage: TeamLibraryItemModel[]) => {
    const [ listItems, setListItems ] = useState(storage);
    useEffect(
        () => {
            setListItems(storage);
        },
        [ storage ]
    );
    const moveItem = useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = listItems[ dragIndex ];
            const modifiedItemsSet = cloneDeep(listItems);
            modifiedItemsSet.splice(dragIndex, 1);
            modifiedItemsSet.splice(hoverIndex, 0, dragCard);
            setListItems([ ...modifiedItemsSet ]);
        },
        [ listItems ]
    );

    const calculateOrderValue = (prev = -1, next = 0) => {
        const step = (next - prev) / 2;
        return next - step;
    };

    const calculateOrder = (newOrder: number) => {
        if (newOrder === storage.length - 1) {
            return Math.floor(storage[ storage.length - 1 ].attachment.options.order || storage.length) + 1;
        }
        const prevItemOrder = storage[ newOrder - 1 ]?.attachment.options.order;
        const nextItemOrder = storage[ newOrder ]?.attachment.options.order;
        return calculateOrderValue(prevItemOrder, nextItemOrder);
    };

    const updateOrder = (item: TeamLibraryItemModel, newOrder: number) => {
        if (item?.attachment) {
            item.attachment.options.order = newOrder;
            item.attachment?.saveData();
        }
        if (item?.thumbnail) {
            item.thumbnail.options.order = newOrder;
            item.thumbnail?.saveData();
        }
    };

    const updateListItemsOrder = useCallback((newOrder, id) => {
        const replacedItemIndex = storage.findIndex((item) => item.id === id);
        if (replacedItemIndex === -1 || replacedItemIndex === newOrder) {
            return;
        }
        const replacedItem = storage[ replacedItemIndex ];
        const newCalculatedOrder = calculateOrder(newOrder);
        updateOrder(replacedItem, newCalculatedOrder);
    }, [ listItems ]);

    return { moveItem, updateListItemsOrder, listItems };
};
