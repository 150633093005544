import styled       from 'styled-components';
import StyledButton from '@geenee/builder/src/components/common/Button';

export const ContentWrapper = styled.div`
    width: 800px;
    padding: 43px 50px 55px;
    position: relative;
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(205, 191, 213, 0.16) 0%, rgba(169, 89, 215, 0.18) 43.34%, rgba(139, 172, 227, 0.308) 93.75%), #FFFFFF;

    .do-not-show {
      margin-top: 40px;
      margin-bottom: -30px;
      font-size: 10px;
    }
`;

export const BackButton = styled(StyledButton)`
    &&& {
        background: rgba(196, 196, 196, 0.19);
        border: 1px solid
            ${ ({ theme: { secondaryColors } }) => secondaryColors.grey200 };
        box-shadow: none;
        color: black;

        &:hover {
            background: ${ ({ theme: { colors } }) => colors.white };
            box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
            color: black;
        }
    }
`;

export const Link = styled.a`
    font-size: 12px;
    color: #000;
    text-decoration: underline;

    &:hover,
    &:visited {
        color: #000;
    }
`;

export const ImgWrapper = styled.div`
  background: radial-gradient(72.9% 72.9% at 50% 50%, rgba(255, 214, 0, 0.4) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.2);
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: none;
  }
`;
