import React          from 'react';
import { ModalError } from './styles';

type Props = {
  message?: string | undefined;
}

const ErrorMessage = function ({ message }: Props) {
    return (
        <ModalError>
            <b>Oops.</b>
            { message }
        </ModalError>
    );
};

// eslint-disable-next-line arca/no-default-export
export default ErrorMessage;
