import * as React       from 'react';
import { cn, Wrapper }  from '@geenee/ui';
// @ts-ignore
import safariBar        from '@geenee/builder/src/asset/images/safari-bottom-bar.jpg';
import { PhoneScreen }  from '../PhoneScreen/PhoneScreen';
import { Navbar }       from './Navbar/Navbar';
import { PhoneButtons } from './PhoneButtons';
import './mobile.styles.scss';

const className = cn('mobile');

export function MobileWrapper() {
    return (
        <Wrapper
            className={ className('root') }
        >
            <Wrapper fullWidth fullHeight id="phone_container" className={ className('wrapper') } shadow="thin">
                <Wrapper className={ className('mobile-toolbar') }>
                    <Wrapper className={ className('phoneNotch') } maxWidth="50%" />
                </Wrapper>
                <Navbar />
                <PhoneScreen />
                <img className={ className('bottomBar') } alt="safari-bar" src={ safariBar } />
            </Wrapper>
            <PhoneButtons />
        </Wrapper>
    );
}
