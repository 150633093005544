import { stripeState } from '@geenee/builder/src/core/state/stripe.state';
import { TableInfo }   from '@geenee/builder/src/pages/AllPlansPage/index';

export const usd = new Intl.NumberFormat('en-US', {
    style:                 'currency',
    currency:              'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
});

export const useTableInfo = () => {
    const generateTableInfo = () => {
        const prices: TableInfo = {};

        Object.keys(stripeState.prices).forEach((priceId) => {
            const price = stripeState.prices[ priceId ];

            if (price.price === 0 && price.name !== 'Hobbyist') return;

            if (!prices[ price.name ]) {
                prices[ price.name ] = {};
            }

            prices[ price.name ][ price.period ] = {
                ...price,
                id:            priceId,
                price:         usd.format(price.price),
                originalPrice: price.price
            };
        });

        return prices;
    };
    const tableInfo = generateTableInfo();
    return { tableInfo };
};
