import * as React                           from "react";
import { useEffect }                        from "react";
import type { IExplorerExtensibilityGroup } from "@babylonjs/core/Debug/debugLayer";
import type { Light }                       from "@babylonjs/core/Lights/light";
import { MoreAction }                       from "@geenee/geespector/src/components/sceneExplorer/components/moreAction";
import { Icon, Wrapper }                    from "@geenee/ui";
import { observer }                         from "mobx-react";
import type { GlobalState }                 from "../../globalState";
import { ExtensionsComponent }              from "../extensionsComponent";

interface ILightTreeItemComponentProps {
    light: Light;
    extensibilityGroups?: IExplorerExtensibilityGroup[];
    onClick: () => void;
    globalState: GlobalState;
}

export const LightTreeItemComponent = observer((props: ILightTreeItemComponentProps) => {
    const switchIsEnabled = () => {
        props.light.setEnabled(!props.light.isEnabled());
    };

    useEffect(() => {
        props.globalState.enableLightGizmo(props.light, true);
    }, []);

    return (
        <Wrapper row>
            <Icon
                name={ props.light.isEnabled() ? 'eyeOn' : 'eyeOff' }
                onClick={ () => switchIsEnabled() }
                margin="xs"
            />
            <MoreAction
                type="light"
                popoverSchemaType="light-popover"
                item={ props.light }
            />
            <ExtensionsComponent target={ props.light } extensibilityGroups={ props.extensibilityGroups } />
        </Wrapper>
    );
});
