import * as React                                    from "react";
import { FormLineWrapperComponent }                  from "@geenee/geespector/src/components/formLineWrapper/formLineWrapperComponent";
import { FormError, FormItem, Input, Link, Wrapper } from '@geenee/ui';

interface ITextLineComponentProps {
    label?: string;
    value?: string;
    color?: string;
    underline?: boolean;
    onLink?: () => void;
    url?: string;
    ignoreValue?: boolean;
    additionalClass?: string;
    icon?: string;
    iconLabel?: string;
    tooltip?: string;
    border?: boolean;
    actions?: React.ReactNode
    error?: string
    ratio?: number
}

export class TextLineComponent extends React.Component<ITextLineComponentProps> {
    constructor(props: ITextLineComponentProps) {
        super(props);
    }

    onLink() {
        if (this.props.url) {
            window.open(this.props.url, "_blank");
            return;
        }
        if (!this.props.onLink) {
            return;
        }

        this.props.onLink();
    }

    renderContent() {
        if (this.props.ignoreValue) {
            return null;
        }

        if (this.props.onLink || this.props.url) {
            const hasError = !this.props.url && this.props.error;
            return (
                <Wrapper
                    padding="xxs"
                    frame="solid-opacity-white"
                    radius="lg"
                    border={ hasError ? 'error' : undefined }
                    fullHeight
                    fullWidth
                    valign="center"
                    overflow="hidden"
                >
                    <Link
                        color="violet"
                        ellipsis
                        fontSize="12px"
                        style={ { width: '100%', ...hasError && { opacity: 0 } } }
                        onClick={ () => !hasError && this.onLink() }
                    >
                        { this.props.url ? "doc" : this.props.value || "no name" }
                    </Link>
                </Wrapper>

            );
        }

        return (
            <Input disabled size="sm" value={ this.props.value || "no name" } />
        );
    }

    render() {
        return (
            <FormLineWrapperComponent
                title={ this.props.label }
                border={ this.props.border }
                ratio={ this.props.ratio }
                overflow="hidden"
                className={ this.props.underline
                    ? "textLine underline" : `textLine${ this.props.additionalClass ? ` ${ this.props.additionalClass }` : "" }` }
            >
                <FormItem
                    margin="off"
                    hasTooltipMessage={ false }
                    errorComponent={ FormError }
                    error={ this.props.error }
                    style={ { fontSize: 'inherit ' } }
                >
                    <Wrapper
                        row
                        style={ { fontSize: 'inherit ' } }
                    >
                        { this.renderContent() }
                        { this.props.actions }
                    </Wrapper>
                </FormItem>

            </FormLineWrapperComponent>
        );
    }
}
