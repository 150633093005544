import React, { useContext }   from 'react';
import { InputTableItemInput } from "@geenee/builder/src/components/InputTableItemInput";
import OwnerProfileAction
    from '@geenee/builder/src/components/modals/OwnerProfileAction';
import { PasswordRow }      from "@geenee/builder/src/components/PasswordRow";
import { useBuilderInject } from '@geenee/builder/src/core/hook/use-builder-inject';
import { teamState }        from '@geenee/builder/src/core/state/team.state';
import { TOAST_SUCCESS }    from '@geenee/builder/src/lib/constants';
import ModalUpdaterContext
    from '@geenee/builder/src/lib/ModalUpdaterContext';
import { useHistory }  from  "@geenee/common";
import { useMobxForm } from '@geenee/shared/src/magellan/hooks/use-mobx-form';
import {
    Button,
    Description, Divider, Form, InputTable, InputTableItem, Switch, Wrapper
} from '@geenee/ui';
import { observer }         from 'mobx-react';
import { useDeleteAccount } from "./DeleteAccountModalDialog/useDeleteAccount.modal";

export const ProfileForm = observer(() => {
    const history = useHistory();
    const { BuilderState } = useBuilderInject();
    const { profile } = BuilderState.currentUser;
    const setModalOptions = useContext(ModalUpdaterContext);
    const { showModal: showDeleteModal } = useDeleteAccount();
    const formModel = useMobxForm('<ProfileSettings>', { values: profile || {} });
    const { form: { formState: { errors } } } = formModel;
    const isOwner = profile?.role === 'owner';

    const changeActiveStatus = () => {
        setModalOptions({
            component: OwnerProfileAction,
            props:     {
                title:           'DEACTIVATION',
                textInsert:      'deactivate yourself',
                confirmCallback: () => {
                    // dispatch(teamChangeOwnerTrigger('deactivate'));
                    history.push('/team');
                }
            }
        });
    };

    const onEdit = async (data: any) => {
        await formModel.submit(data);
        BuilderState.update('toast', {
            severity: TOAST_SUCCESS,
            detail:   'Profile updated successfully',
            summary:  ''
        });
    };

    const hasOtherMembers = teamState.userStore.size > 0;

    return (
        <Wrapper frame="solid-default" maxWidth="775">
            <Form
                onSubmit={ onEdit }
            >
                <Wrapper padding="md" valign="center">
                    <InputTable margin="sm">
                        <InputTableItemInput
                            { ...formModel.bind(
                                'first_name',
                                formModel.get('first_name').rules
                            ) }
                            placeholder={ formModel.get('first_name').placeholder }
                            errors={ errors }
                            label="First name"
                        />
                        <InputTableItemInput
                            label="Last name"
                            { ...formModel.bind(
                                'last_name',
                                formModel.get('last_name').rules
                            ) }
                            placeholder={ formModel.get('last_name').placeholder }
                            errors={ errors }
                        />
                        <InputTableItemInput
                            value={ profile?.email || '' }
                            label="Email"
                            disabled
                        />
                        <InputTableItem ratio={ 0.65 } label="Rights" viewType="secondary" weight="medium">
                            <Wrapper valign="center" frame="solid-white" radius="xl" style={ { height: '40px' } }>
                                <Description style={ { paddingLeft: 13 } } capitalize>
                                    { profile?.role }
                                </Description>
                            </Wrapper>
                        </InputTableItem>

                        { isOwner && hasOtherMembers && (
                            <InputTableItem
                                ratio={ 0.65 }
                                size="lg"
                                label="Status"
                                viewType="secondary"
                                weight="medium"
                            >
                                <Wrapper valign='bottom' row>
                                    <Description margin="sm">Active:</Description>
                                    <Switch
                                        size='lg'
                                        checked={ profile?.is_active }
                                        onChange={ changeActiveStatus }
                                    />
                                </Wrapper>
                            </InputTableItem>
                        ) }

                        <PasswordRow
                            canEdit={ profile?.login_type === 'credentials' }
                            onEdit={ onEdit }
                        />
                    </InputTable>
                    <Wrapper
                        style={ { height: '40px' } }
                        valign="center"
                        margin="sm"
                    >
                        <Description
                            weight="medium"
                            onClick={ () => showDeleteModal() }
                            align="left"
                            color="error"
                        >
                            Delete account
                        </Description>
                    </Wrapper>
                    <Divider color="grey-3" margin="sm" />

                    <Wrapper row align="right">
                        <Button
                            margin="sm"
                            uppercase
                            viewType="secondary"
                            onClick={ () => {
                                history.push('/');
                            } }
                        >
                            Nevermind
                        </Button>

                        <Button
                            type="submit"
                            uppercase
                            disabled={ formModel.isLoading }
                        >
                            Update Details
                        </Button>
                    </Wrapper>
                </Wrapper>
            </Form>

        </Wrapper>
    );
});
