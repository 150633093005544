import React, { useContext, useState } from 'react';
import { Scrollbars }                  from 'react-custom-scrollbars';
import { Description }                 from '@geenee/ui';
import { observer }                    from 'mobx-react';
import StyledButton                    from "@geenee/builder/src/components/common/Button";
import { useBuilderInject }            from '@geenee/builder/src/core/hook/use-builder-inject';
import { stripeState }                 from '@geenee/builder/src/core/state/stripe.state';
import ModalUpdaterContext             from '@geenee/builder/src/lib/ModalUpdaterContext';
import { AddAudio }                    from '@geenee/builder/src/pages/AllPlansPage/rows/AddAudio';
import { AnalyticsDashboard }          from '@geenee/builder/src/pages/AllPlansPage/rows/AnalyticsDashboard';
import { ButtonsRow }                  from '@geenee/builder/src/pages/AllPlansPage/rows/ButtonsRow';
import { CloudCms }                    from '@geenee/builder/src/pages/AllPlansPage/rows/CloudCms';
import { CodeInjection }               from '@geenee/builder/src/pages/AllPlansPage/rows/CodeInjection';
import { CommericalLicense }           from '@geenee/builder/src/pages/AllPlansPage/rows/CommericalLicense';
import { ContentTemplates }            from '@geenee/builder/src/pages/AllPlansPage/rows/ContentTemplates';
import { Hosting }                     from '@geenee/builder/src/pages/AllPlansPage/rows/Hosting';
import { LiveProjects }                from '@geenee/builder/src/pages/AllPlansPage/rows/LiveProjects';
import { MarkerAr }                    from '@geenee/builder/src/pages/AllPlansPage/rows/MarkerAr';
import { NoWatermark }                 from '@geenee/builder/src/pages/AllPlansPage/rows/NoWatermark';
import { PlansTitles }                 from '@geenee/builder/src/pages/AllPlansPage/rows/PlansTitles';
import { Pricing }                     from '@geenee/builder/src/pages/AllPlansPage/rows/Pricing';
import { PriorityTechnicalSupport }    from '@geenee/builder/src/pages/AllPlansPage/rows/PriorityTechnicalSupport';
import { SdkAccess }                   from '@geenee/builder/src/pages/AllPlansPage/rows/SdkAccess';
import { Seats }                       from '@geenee/builder/src/pages/AllPlansPage/rows/Seats';
import { TeamAssetLibrary }            from '@geenee/builder/src/pages/AllPlansPage/rows/TeamAssetLibrary';
import { WorldAr }                     from '@geenee/builder/src/pages/AllPlansPage/rows/WorldAr';
import { YourPlanRow }                 from '@geenee/builder/src/pages/AllPlansPage/rows/YourPlanRow';
import { UpperBar }                    from '@geenee/builder/src/pages/AllPlansPage/UpperBar';
import { useHandlers }                 from '@geenee/builder/src/pages/AllPlansPage/useHandlers';
import { useTableInfo }                from '@geenee/builder/src/pages/AllPlansPage/useTableInfo';
import { MainContainer }               from '@geenee/builder/src/pages/MainContainer';
import {
    AllPlansContainer,
    PlansTable,
    PlansTableArea,
    TipContainer
}                             from './styles';
import { Title } from './Title';

export const planNames = [ 'Basic', 'Premium', 'Enterprise' ];

export interface TableInfo {
    [key: string]: { [key: string]: { id: string; price: string; parent_plan_id?: string, originalPrice: number } };
}

// TODO: Delete old Table and related code
export const AllPlansPageOld = observer(() => {
    const [ yearlyCycle, setYearlyCycle ] = useState(stripeState.plan_period === 'year');
    const currentInterval = yearlyCycle ? 'year' : 'month';
    const setModalOptions = useContext(ModalUpdaterContext);
    const { getDIComponent } = useBuilderInject();
    const NFTConnect = getDIComponent('NFTConnect');

    const { tableInfo } = useTableInfo();
    const { showPaymentModal } = useHandlers(currentInterval, setModalOptions);

    return (
        <MainContainer style={ { overflow: 'hidden' } }>
            <Title />
            <AllPlansContainer>
                <TipContainer>
                    <Description style={ { fontWeight: 700 } }>
                        Save 10-15% Yearly
                    </Description>
                </TipContainer>
                <UpperBar
                    onChangeBillingCycle={ () => setYearlyCycle(!yearlyCycle) }
                    yearlyCycle={ yearlyCycle }
                    showPaymentModal={ showPaymentModal }
                />
                <PlansTableArea>
                    <Scrollbars
                        autoHeight
                        autoHide
                        autoHideTimeout={ 500 }
                        autoHideDuration={ 200 }
                        autoHeightMax="95%"
                    >
                        <PlansTable currentPlan={ stripeState.plan_title }>

                            <tbody>
                                <PlansTitles planNames={ planNames } />
                                <YourPlanRow
                                    currentInterval={ currentInterval }
                                    planNames={ planNames }
                                    tableInfo={ tableInfo }
                                />
                                <CloudCms />
                                <WorldAr />
                                <MarkerAr />
                                <NoWatermark />
                                <AddAudio />
                                <NFTConnect />
                                <CodeInjection />
                                <LiveProjects />
                                <ContentTemplates />
                                <Seats />
                                <Hosting />
                                <TeamAssetLibrary />
                                { /* <DynamicContent /> */ }
                                { /* <CustomUrl /> */ }
                                <CommericalLicense />
                                { /* <DomainMapping /> */ }
                                <SdkAccess />
                                <PriorityTechnicalSupport />
                                <AnalyticsDashboard />
                                <Pricing
                                    planNames={ planNames }
                                    currentInterval={ currentInterval }
                                    tableInfo={ tableInfo }
                                />
                                <tr className="buttons-row" style={ { display: 'flex' } }>
                                    <StyledButton
                                        $width="180px"
                                        $height="40px"
                                        label="HOBBYIST"
                                        onClick={ () => {
                                            const tablePlan = tableInfo.Hobbyist[ yearlyCycle ? 'year' : 'month' ];
                                            if (tablePlan) {
                                                showPaymentModal(tablePlan.id);
                                            }
                                        } }
                                    />
                                    <StyledButton
                                        $width="180px"
                                        $height="40px"
                                        label="PREMIUM 2.0"
                                        onClick={ () => {
                                            const tablePlan = tableInfo[ 'Premium2.0' ][ yearlyCycle ? 'year' : 'month' ];
                                            if (tablePlan) {
                                                showPaymentModal(tablePlan.id);
                                            }
                                        } }
                                    />
                                    <StyledButton
                                        $width="180px"
                                        $height="40px"
                                        label="TEAMS"
                                        onClick={ () => {
                                            const tablePlan = tableInfo.Teams[ yearlyCycle ? 'year' : 'month' ];
                                            if (tablePlan) {
                                                showPaymentModal(tablePlan.id);
                                            }
                                        } }
                                    />
                                    <StyledButton
                                        $width="180px"
                                        $height="40px"
                                        label="ENTERPRISE"
                                        onClick={ () => {
                                            const tablePlan = tableInfo.Enterprise[ yearlyCycle ? 'year' : 'month' ];
                                            if (tablePlan) {
                                                showPaymentModal(tablePlan.id);
                                            }
                                        } }
                                    />
                                </tr>
                                <ButtonsRow
                                    showPaymentModal={ showPaymentModal }
                                    tableInfo={ tableInfo }
                                    planNames={ planNames }
                                    yearlyCycle={ yearlyCycle }
                                />
                            </tbody>
                        </PlansTable>
                    </Scrollbars>
                </PlansTableArea>
                { /* <Enterprice /> */ }
            </AllPlansContainer>
        </MainContainer>
    );
});
