import React, { useEffect, useState }          from 'react';
import { LoadingSpinnerCentered }              from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { observer }                            from 'mobx-react';
import { stripeState }                         from '@geenee/builder/src/core/state/stripe.state';
import { TransactionType }                     from '../../../../type/model/stripe/transaction.type';
import { BoldCenter }                          from '../common/styles';
import { Root, TableBody, TableHead, Wrapper } from './styles';

export const ViewTransactions = observer(() => {
    const [ transactions, setTransactions ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(true);

    const fetch = async () => {
        try {
            setIsLoading(true);
            setTransactions(await stripeState.fetchTransactions());
            setIsLoading(false);
        } catch (e: any) {
            console.error(e);
            setTransactions([]);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    const RenderTransactions = function () {
        const list = transactions;

        const toShortDate = (date: string) => new Date(date).toLocaleDateString('en-US', {
            day:   '2-digit',
            month: '2-digit',
            year:  'numeric'
        });

        const usd = new Intl.NumberFormat('en-US', {
            style:    'currency',
            currency: 'USD'
        });

        return (
            <>
                { list.map((trans: TransactionType, index: number) => (
                    <tr key={ index }>
                        <td>{ toShortDate(trans.date) }</td>
                        <td>{ trans.description }</td>
                        <td>
                            { `
                                ${ toShortDate(trans.period_start) }
                                -
                                ${ toShortDate(trans.period_end) }
                            ` }
                        </td>
                        <td>
                            ************
                            { trans.card }
                        </td>
                        { /* transaction price is in cents */ }
                        <td>{ usd.format(trans.price) }</td>
                    </tr>
                )) }
            </>
        );
    };

    if (isLoading) {
        return <LoadingSpinnerCentered />;
    }

    return (
        <Root>
            <BoldCenter>transactions</BoldCenter>
            <Wrapper>
                <table>
                    <TableHead>
                        <tr>
                            <th>DATE</th>
                            <th>DESCRIPTION</th>
                            <th>PERIOD</th>
                            <th>CARD</th>
                            <th>PRICE</th>
                        </tr>
                    </TableHead>
                    <TableBody>
                        <RenderTransactions />
                    </TableBody>
                </table>
            </Wrapper>
        </Root>
    );
});
