import React, { useEffect, useState }          from 'react';
import { useHistory }                          from "@geenee/common";
import { LoadingSpinnerCentered }              from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { Divider, Select, Wrapper }            from '@geenee/ui';
import { observer }                            from 'mobx-react';
import StyledButton                            from '@geenee/builder/src/components/common/Button';
import { HeaderTitle }                         from "@geenee/builder/src/components/HeaderTitle";
import { Link }                                from '@geenee/builder/src/components/Link';
import { accountState }                        from '@geenee/builder/src/core/state/account.state';
import { stripeState }                         from '@geenee/builder/src/core/state/stripe.state';
import { NOTION_SUPPORT_URL }                  from "@geenee/builder/src/lib/constants";
import { InputRow }                            from '@geenee/builder/src/pages/MatomoAnalytics/InputRow';
import { AnalyticsDescription, AnalyticsRoot } from '../Analytics/styles';
import { IframeWrapper, SelectWrapper }        from './styles';

export const MatomoAnalytics = observer(() => {
    const [ isSaving, setIsSaving ] = useState(false);
    const { matomo_id, matomo_token_auth } = accountState;
    const history = useHistory();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ sites, setSites ] = useState([]);
    const [ currentSiteId, setCurrentSiteId ] = useState(-1);

    const fetch = async () => {
        if (matomo_id && matomo_token_auth) {
            setIsLoading(true);
            const resultSites = await accountState.fetchMatomoSites();
            setSites(
                resultSites.map((el: any) => ({ value: el.idsite, label: el.name }))
            );
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        if (!stripeState.isEnterprise && !stripeState.isTeams) {
            history.replace('/');
        }
    }, []);

    if (isLoading) {
        return <LoadingSpinnerCentered />;
    }

    return (
        <AnalyticsRoot>
            <HeaderTitle
                title="Matomo Analytics"
                subtitle={ (
                    !matomo_id ? (
                        <>
                            You can connect your Matomo Analytics account to 1 or more
                            projects. For help, please check out the
                            { ' ' }
                            { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
                            <Link
                                onClick={ () => window.open(NOTION_SUPPORT_URL) }
                            >
                                FAQ’s.
                            </Link>
                        </>
                    ) : null
                ) }
            />
            <Divider transparent margin="md" />
            { matomo_id ? (
                <Wrapper row style={ { alignItems: 'center' } }>
                    <AnalyticsDescription>
                        You connected your Matomo Analytics.
                    </AnalyticsDescription>
                    <SelectWrapper>
                        <Select
                            size="md"
                            placeholder="Choose site to show"
                            options={ sites }
                            onChange={ ({ value }) => setCurrentSiteId(parseInt(value as string, 10)) }
                        />
                    </SelectWrapper>
                    <StyledButton
                        disabled={ isSaving }
                        label="REMOVE"
                        $width="152px"
                        onClick={ async () => {
                            setIsSaving(true);
                            await accountState.update({
                                matomo_id:         '',
                                matomo_token_auth: ''
                            });
                            setIsSaving(false);
                        } }
                    />
                </Wrapper>
            ) : (
                <InputRow />
            ) }
            { matomo_id ? (
                <>
                    { currentSiteId !== -1 && (
                        <IframeWrapper>
                            { /* eslint-disable-next-line jsx-a11y/iframe-has-title */ }
                            <iframe
                                // eslint-disable-next-line max-len
                                src={ `${ matomo_id }/index.php?module=Widgetize&action=iframe&moduleToWidgetize=Dashboard&actionToWidgetize=index&idSite=${ currentSiteId }&period=week&date=yesterday&token_auth=anonymous` }
                                frameBorder="0"
                                // @ts-ignore
                                marginHeight="0"
                                // @ts-ignore
                                marginWidth="0"
                                width="100%"
                                height="100%"
                            />
                        </IframeWrapper>
                    ) }
                </>
            ) : (
                <></>
            ) }
        </AnalyticsRoot>
    );
});
