import styled from 'styled-components';

export const Root = styled.div`
  width: 486px;
  height: 524px;
  background: linear-gradient(180deg, rgba(205, 191, 213, 0.16) 0%, rgba(169, 89, 215, 0.18) 43.34%, rgba(139, 172, 227, 0.308) 93.75%), #FFFFFF;
  border-radius: 10px;
  position: fixed;
`;

export const Title = styled.div`
  font-size: 40px;
  margin: 40px 0;
  width: 100%;
  text-align: center;
  font-weight: bold;
`;

export const Subtitle = styled.div`
  font-size: 13px;
  margin: 0 24px;
  text-align: center;
`;

export const QRContainer = styled.div`
  background: rgba(196, 196, 196, 0.19);
  margin: 34px 54px 50px;
  border-radius: 10px;
  height: 254px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoWrapper = styled.div`
  position: absolute;
`;

export const ZippedQrWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
`;
