import React                      from 'react';
import { Tooltip }                from '@geenee/ui';
import Reload                     from '@geenee/builder/src/asset/icons/Reload';
import { ReloadButtonIcon }       from '@geenee/builder/src/components/ProjectsLinkDropdown/styles';
import { UPDATE_BTN_COUNTER_KEY } from '@geenee/builder/src/lib/constants';

const TOOLTIP_HIDE_AFTER_TIMES = 2;

export function ReloadButton({ onClick, disabled }:{ onClick: ()=> void, disabled: boolean }) {
    const onReload = () => {
        onClick();
        const currentValue = parseInt(localStorage.getItem(UPDATE_BTN_COUNTER_KEY) || '0', 10);
        if (currentValue < TOOLTIP_HIDE_AFTER_TIMES) {
            localStorage.setItem(UPDATE_BTN_COUNTER_KEY, `${ currentValue + 1 }`);
        }
    };
    return (
        <Tooltip
            message="Publish your changes."
            title="Refresh needed"
            shadow
            position="bottom"
            disabled={ parseInt(localStorage.getItem(UPDATE_BTN_COUNTER_KEY) || '0', 10) >= TOOLTIP_HIDE_AFTER_TIMES }
        >
            <ReloadButtonIcon
                disabled={ disabled }
                pulseAnimation
                id="republish-button"
                onClick={ onReload }
                iconElement={ <Reload style={ { flexShrink: '0' } } /> }
            />
        </Tooltip>
    );
}
