import * as React                                   from 'react';
import { observer }                                 from 'mobx-react';
import { $Diff }                                    from 'utility-types';
import { CustomCodeContext, CustomCodeContextType } from '~/module/custom-code/context/customCode.context';

export function withCustomCode<T extends CustomCodeContextType>(Component: React.ComponentType<T>) {
    return observer((props: $Diff<T, CustomCodeContextType>) => (
        <CustomCodeContext.Consumer>
            { (context) => <Component { ...props as T } { ...context } /> }
        </CustomCodeContext.Consumer>
    ));
}
