import React, { useCallback, useContext, useEffect, useMemo, useState }                     from 'react';
import { useParams }                                                                        from 'react-router-dom';
import { Analytics }                                                                        from '@geenee/analytics';
import { InputTableItemInput }                                                              from "@geenee/builder/src/components/InputTableItemInput";
import PassOwnerRoleConfirmation                                                            from '@geenee/builder/src/components/modals/PassOwnerRoleConfirmation';
import { PasswordRow }                                                                      from "@geenee/builder/src/components/PasswordRow";
import { useBuilderInject }                                                                 from '@geenee/builder/src/core/hook/use-builder-inject';
import { UserModel }                                                                        from '@geenee/builder/src/core/model/user.model';
import { teamState }                                                                        from '@geenee/builder/src/core/state/team.state';
import { MEMBER_CREATE_SUCCESS, MEMBER_UPDATE_SUCCESS, TEAM_MEMBER_INVITED, TOAST_SUCCESS } from '@geenee/builder/src/lib/constants';
import ModalUpdaterContext                                                                  from '@geenee/builder/src/lib/ModalUpdaterContext';
import { ProjectsDropdown }                                                                 from "@geenee/builder/src/pages/MemberPage/components/ProjectsDropdown";
import { RightsDropdown }                                                                   from "@geenee/builder/src/pages/MemberPage/components/RightsDropdown";
import { useDeleteMemberModal }                                                             from "@geenee/builder/src/pages/MemberPage/components/useDeleteMemberModal";
import { useHistory }                                                                       from "@geenee/common";
import { SubmitData }                                                                       from '@geenee/shared/src/magellan/form/member-settings';
import { useMobxForm }                                                                      from '@geenee/shared/src/magellan/hooks/use-mobx-form';
import {
    Button,
    Description, Divider,
    Form,
    InputTable, InputTableItem,
    Switch, Wrapper
} from '@geenee/ui';
import { observer }        from 'mobx-react';
import { getEmailFromUrl } from '.';

export const MemberProfile = observer(() => {
    const { BuilderState, container } = useBuilderInject();

    const history = useHistory();
    const { email } = useParams<{ email: string }>();
    const [ isNewMember ] = useState(email === 'new');
    const member = teamState.getMemberByEmail(getEmailFromUrl(email || ''));

    const setModalOptions = useContext(ModalUpdaterContext);
    const formModel = useMobxForm('<MemberSettings>', {
        defaultValues: member || {
            first_name: '',
            last_name:  '',
            email:      '',
            role:       ''
        }
    });
    const { form: { formState: { errors } } } = formModel;

    const currentProfile = BuilderState.currentUser.profile;
    const { showModal } = useDeleteMemberModal(member || {} as UserModel);

    useEffect(() => {
        if (isNewMember) {
            formModel.setValue('is_active', false);
            formModel.setValue('role', 'admin');
            formModel.setValue('has_restricted_projects', false);
        } else {
            formModel.setValue('is_active', member?.is_active);
        }
    }, [ isNewMember ]);

    const handleDropdownChange = useCallback((value: string) => {
        formModel.setValue('role', value);
    }, []);

    const hasRestrictedProjects = useMemo(
        () => formModel.values?.has_restricted_projects
    || member?.has_restricted_projects
    || false,
        [ formModel.values?.has_restricted_projects, member?.has_restricted_projects ]
    );

    const dropdownValues = useMemo(
        () => (hasRestrictedProjects
            ? formModel.values.restricted_projects_ids || member?.restricted_projects_ids : [ 'all' ]),
        [ hasRestrictedProjects, JSON.stringify(formModel.values.restricted_projects_ids || []) ]
    );

    const handleProjectSelect = (value?: string) => {
        if (value === 'all') {
            formModel.setValue('has_restricted_projects', !hasRestrictedProjects);
            formModel.setValue('restricted_projects_ids', []);
            return;
        }
        formModel.setValue('has_restricted_projects', true);

        const oldValues = formModel.values?.restricted_projects_ids || member?.restricted_projects_ids || [];

        if (oldValues.includes(value)) {
            formModel.setValue('restricted_projects_ids', [ ...oldValues.filter((o) => o !== value) ]);
        } else {
            formModel.setValue('restricted_projects_ids', [ ...oldValues, value ]);
        }
    };

    const createNewMember = async (data: SubmitData) => {
        try {
            const analytics: Analytics = container.get('<Analytics>');
            analytics.track(TEAM_MEMBER_INVITED, {
                inviteeFirstName: data?.first_name,
                inviteeLastName:  data?.last_name,
                inviteeEmail:     data?.email,
                inviteeRole:      data?.role,
                // @TODO: Find and set the accountId field
                inviteeAccount:   undefined
            });
            await formModel.submit(data);
            BuilderState.update('toast', { severity: TOAST_SUCCESS, detail: MEMBER_CREATE_SUCCESS, summary: '' });
            history.push('/team');
        } catch (e) {
            console.error('Error while inviting the team member: ', e);
        }
    };

    const editMember = async (data: SubmitData) => {
        if (data.role === 'owner') {
            // Show confirmation modal for passing an owner role
            setModalOptions({
                component: PassOwnerRoleConfirmation,
                props:     {
                    textInsert:      `${ formModel.values.first_name } ${ formModel.values.last_name }`,
                    confirmCallback: async () => {
                        if (currentProfile && !isNewMember) {
                            try {
                                await formModel.submit(data);
                                setModalOptions({ hideModal: true });
                                BuilderState.update('toast', {
                                    severity: TOAST_SUCCESS,
                                    detail:   MEMBER_UPDATE_SUCCESS,
                                    summary:  ''
                                });
                            } catch (e) {
                                console.error('Error while passed owner role: ', e);
                            }
                        }
                    }
                }
            });
        } else {
            try {
                await formModel.submit(data);
                BuilderState.update('toast', {
                    severity: TOAST_SUCCESS,
                    detail:   MEMBER_UPDATE_SUCCESS,
                    summary:  ''
                });
            } catch (e) {
                console.error('Error while edit member: ', e);
            }
        }
    };
    const onSubmit = useCallback(async (data: any) => {
        if (isNewMember) {
            await createNewMember(data);
        } else {
            await editMember(data);
        }
    }, [ isNewMember ]);

    const changeActiveStatus = () => {
        formModel.setValue('is_active', !formModel.values?.is_active);
    };

    const onEditPassword = async (data: UserModel) => {
        await member?.updateTeamMember(data);
    };

    return (
        <Wrapper frame="solid-default" maxWidth="775">
            <Form onSubmit={ onSubmit }>
                <Wrapper padding="md" valign="center">
                    <InputTable margin="sm">
                        <InputTableItemInput
                            { ...formModel.bind(
                                'first_name',
                                formModel.get('first_name').rules
                            ) }
                            placeholder={ formModel.get('first_name').placeholder }
                            errors={ errors }
                            label="First name"
                        />
                        <InputTableItemInput
                            label="Last name"
                            { ...formModel.bind(
                                'last_name',
                                formModel.get('last_name').rules
                            ) }
                            placeholder={ formModel.get('last_name').placeholder }
                            errors={ errors }
                        />
                        <InputTableItemInput
                            label="Email"
                            { ...formModel.bind(
                                'email',
                                formModel.get('email').rules
                            ) }
                            disabled={ !isNewMember }
                            placeholder={ formModel.get('email').placeholder }
                            errors={ errors }
                        />
                        <RightsDropdown
                            errors={ errors?.role }
                            isNewMember={ isNewMember }
                            onSelect={ handleDropdownChange }
                            value={ formModel.values.role || member?.role || '' }
                        />

                        { !isNewMember && (
                            <InputTableItem
                                size="lg"
                                label="Status"
                                viewType="secondary"
                                weight="medium"
                                ratio={ 0.65 }
                            >
                                <Wrapper valign='bottom' row>
                                    <Description margin="sm">Active:</Description>
                                    <Switch
                                        size='lg'
                                        checked={ formModel.values.is_active }
                                        onChange={ changeActiveStatus }
                                    />
                                </Wrapper>
                            </InputTableItem>
                        ) }
                        <ProjectsDropdown
                            value={ dropdownValues }
                            onSelect={ handleProjectSelect }
                            hasRestrictedProjects={ hasRestrictedProjects }
                        />
                        { !isNewMember && (
                            <PasswordRow
                                label="Password"
                                onEdit={ onEditPassword }
                                canEdit={ formModel.values.login_type === 'credentials' && formModel.values.is_active }
                            />
                        ) }
                    </InputTable>
                    { !isNewMember && (
                        <Wrapper
                            style={ { height: '40px' } }
                            valign="center"
                            margin="sm"
                        >
                            <Description
                                weight="medium"
                                onClick={ () => showModal() }
                                align="left"
                                color="error"
                            >
                                Delete account
                            </Description>
                        </Wrapper>
                    ) }
                    <Divider color="grey-3" margin="sm" />

                    <Wrapper row align="right">
                        <Button
                            margin="sm"
                            uppercase
                            viewType="secondary"
                            onClick={ () => {
                                history.push('/team');
                            } }
                        >
                            Nevermind
                        </Button>
                        <Button
                            uppercase
                            disabled={ formModel.isLoading }
                            type="submit"
                        >
                            { isNewMember ? 'Add new user' : 'Update Details' }
                        </Button>
                    </Wrapper>
                </Wrapper>
            </Form>
        </Wrapper>
    );
});
