import React, { memo, ReactElement, useState } from "react";
import { cn }                                  from "../../util/bem";
import { SizesUnionType }                      from "../../util/global-props";
import { AnimatedWrapper }                     from "../animated-wrapper/animated-wrapper.component";
import { IconPropsType }                       from "../icon/icon.component";
import { Wrapper, WrapperPropsType }           from "../wrapper/wrapper.component";
import { CollapseContent }                     from "./components/collapse-content.component";
import { CollapsePanel, CollapsePanelProps }   from "./components/collapse-panel.component";
import "./collapse.component.scss";

export type CollapseProps = {
    onCollapseChange?: (collapsed: boolean) => void;
    collapsed?: boolean;
    panelContent: CollapsePanelProps["panelContent"];
    icon?: React.ReactNode;
    withTransitionHeight?: boolean;
    iconCollapsedAfter?: boolean;
    iconCollapsedRotationAngle?: string;
    iconCollapsedProps?: IconPropsType;
    iconAfter?: boolean;
    collapseWrapperProps?: WrapperPropsType;
    panelWrapperProps?: CollapsePanelProps["panelWrapperProps"];
    contentWrapperProps?: WrapperPropsType;
    paddingLeft?: SizesUnionType | number
    children: React.ReactNode
    clickMode?: "icon" | "container" | "none"
    changeTitleBackgroundOnHover?: CollapsePanelProps["changeTitleBackgroundOnHover"]
    hasIcon?: boolean
}

const className = cn("collapse-component");

const ConditionalWrapper = ({
    condition,
    wrapper,
    children
}: {
    condition: boolean | undefined,
    wrapper: (c: JSX.Element) => JSX.Element,
    children: JSX.Element
}): JSX.Element => (condition ? wrapper(children) : children);

export const Collapse = memo((props: CollapseProps) => {
    const [ collapsed, setCollapsed ] = useState<boolean>((props.collapsed !== undefined) ? props.collapsed : true);
    const onCollapseChange = (value: boolean) => {
        setCollapsed(value);
        props.onCollapseChange && props.onCollapseChange(value);
    };

    return (
        <Wrapper
            { ...props.collapseWrapperProps }
            className={ `${ className({ collapsed }) } ${ props.collapseWrapperProps?.className }` }
        >
            <CollapsePanel
                hasIcon={ props.hasIcon }
                iconCollapsedProps={ props.iconCollapsedProps }
                iconCollapsedAfter={ props.iconCollapsedAfter }
                iconCollapsedRotationAngle={ props.iconCollapsedRotationAngle }
                iconAfter={ props.iconAfter }
                icon={ props.icon }
                panelContent={ props.panelContent }
                panelWrapperProps={ props.panelWrapperProps }
                collapsed={ collapsed }
                onCollapseChange={ onCollapseChange }
                clickMode={ props.clickMode }
                changeTitleBackgroundOnHover={ props.changeTitleBackgroundOnHover }
            />
            <ConditionalWrapper
                condition={ props.withTransitionHeight }
                wrapper={ (children) => (
                    <AnimatedWrapper
                        manual={ false }
                        transitionFade
                        transitionHeight
                        initialHeight="0px"
                    >
                        { children }
                    </AnimatedWrapper>
                ) }
            >

                <CollapseContent
                    collapsed={ collapsed }
                    contentWrapperProps={ props.contentWrapperProps }
                    paddingLeft={ props.paddingLeft }
                >
                    { props.children }
                </CollapseContent>
            </ConditionalWrapper>
        </Wrapper>
    );
});
