import * as React                from 'react';
import { TableHeaderGroupProps } from 'react-table';
import { tableClassName }        from '../../utils';

const className = tableClassName('h__tr');

type PropsType = {
    children?: React.ReactNode;
    headerRowProps: TableHeaderGroupProps;
} ;

export const TableHeaderRow = React.memo((props: PropsType) => (
    <div { ...props.headerRowProps } className={ className }>
        { props.children }
    </div>
));
