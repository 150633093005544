import styled       from 'styled-components';
import { PillType } from '@geenee/builder/src/components/Pill/index';

export const PillRoot = styled.div<{ type: PillType }>`
    height: 20px;
    margin-left: 16px;
    padding: 3px 10px;
    align-self: start;
    background: ${ ({ theme: { secondaryColors }, type }) =>
    // eslint-disable-next-line no-nested-ternary,implicit-arrow-linebreak
        (type === PillType.LIVE
            ? secondaryColors.green100
            : type === PillType.NFT_LIVE
                ? secondaryColors.pink80
                : secondaryColors.grey110) };
    border-radius: 40px;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));

    .one-ui-description {
        font-family: 'Euclid Circular A', serif;
        font-weight: 500;
        color: ${ ({ theme: { colors }, type }) => (type === PillType.NFT_LIVE ? colors.white : colors.black) };
        font-size: 11px !important;
        white-space: nowrap;
    }
`;
