import React                from 'react';
import { useLocation }      from 'react-router';
import GeeneeLogo           from '@geenee/assets/lib/icons/resources/GeeneeLogoGradient';
import backgroundSrc        from '@geenee/assets/lib/images/bg-frame4.png';
import { useBuilderInject } from '@geenee/builder/src/core/hook/use-builder-inject';
import { API_URL }          from '@geenee/builder/src/lib/constants';
import { useHistory }       from "@geenee/common";
import { useMobxForm }      from '@geenee/shared/src/magellan/hooks/use-mobx-form';
import {
    Button,
    Description,
    Form,
    FormError,
    FormItem,
    Input,
    Link,
    Wrapper
} from '@geenee/ui';
import { observer } from 'mobx-react';

export const LoginPage = observer(({ hasSocialAuth = true }: {hasSocialAuth?: boolean; }) => {
    const { BuilderState } = useBuilderInject();
    const location = useLocation();
    const history = useHistory();

    const formModel = useMobxForm('<Login>');
    const { form: { formState: { errors } } } = formModel;

    const searchQuery = new URLSearchParams(
        decodeURIComponent(location.search)
    ).get('next');
    const nextPath = searchQuery || '/';

    const submitHandler = async (event) => {
        try {
            await formModel.submit(event, { nextPath });

            history.push(nextPath);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Wrapper
            fullHeight
            align="center"
            valign="center"
            backgroundImage={ backgroundSrc }
        >
            <Wrapper align="center" margin="md">
                <GeeneeLogo width="192" height="32" />
            </Wrapper>
            <Wrapper
                maxWidth="352px"
                frame="solid-opacity-white"
                padding="xl"
                radius="xxxl"
                margin="md"
                shadow="translucency-md"
            >
                <Wrapper align="center" valign="center">
                    { hasSocialAuth ? (
                        <>
                            <Button
                                margin="sm"
                                size="lg"
                                radius="xxl"
                                icon="google"
                                iconSize={ 24 }
                                fullWidth
                                viewType="secondary"
                                onClick={ () => {
                                    window.open(
                                        `${ API_URL }/api/v0/public/oauth/google/?scope=email%20profile`,
                                        '_self'
                                    );
                                } }
                            >
                                <Description
                                    size="md"
                                    lineHeight="md"
                                    color="shade-2"
                                    weight="medium"
                                >
                                    LOGIN WITH GOOGLE
                                </Description>
                            </Button>
                            <Button
                                margin="sm"
                                size="lg"
                                radius="xxl"
                                icon="facebook"
                                iconSize={ 24 }
                                fullWidth
                                viewType="secondary"
                                onClick={ () => {
                                    window.open(
                                        `${ API_URL }/api/v0/public/oauth/facebook?scope=email%20public_profile`,
                                        '_self'
                                    );
                                } }
                            >
                                <Description
                                    size="md"
                                    lineHeight="md"
                                    color="shade-2"
                                    weight="medium"
                                >
                                    LOGIN WITH FACEBOOK
                                </Description>
                            </Button>
                            <Description
                                weight="medium"
                                size="sm"
                                margin="sm"
                                color="shade-4"
                            >
                                or
                            </Description>
                        </>
                    ) : (
                        <></>
                    ) }
                    <Form
                        onSubmit={ async (e) => {
                            await submitHandler(e);
                        } }
                    >
                        <Wrapper>
                            <FormItem
                                errorComponent={ FormError as React.FC }
                                error={
                                    !!errors.email && errors.email.message
                                }
                                hasTooltipMessage={ false }
                                autoFocusDetect
                                margin="sm"
                            >
                                <Input
                                    size="md"
                                    fullWidth
                                    { ...formModel.bind(
                                        'email',
                                        formModel.get('email').rules
                                    ) }
                                    placeholder={
                                        formModel.get('email').placeholder
                                    }
                                    error={ !!errors.email || !!errors.form }
                                    type="email"
                                />
                            </FormItem>
                            <FormItem
                                errorComponent={ FormError as React.FC }
                                error={
                                    (!!errors.password
                                            && errors.password.message)
                                        || (!!errors.form && errors.form.message)
                                }
                                hasTooltipMessage={ false }
                                autoFocusDetect
                                margin="xs"
                            >
                                <Input
                                    size="md"
                                    fullWidth
                                    withShowPassword
                                    { ...formModel.bind(
                                        'password',
                                        formModel.get('password').rules
                                    ) }
                                    error={
                                        !!errors.password || !!errors.form
                                    }
                                    placeholder={
                                        formModel.get('password')
                                            .placeholder
                                    }
                                    type="password"
                                />
                            </FormItem>
                            <Wrapper
                                margin="sm"
                                style={ { lineHeight: '16px' } }
                            >
                                <Link
                                    onClick={ () => {
                                        history.push('/forgot');
                                    } }
                                    fontSize="10px"
                                    weight="medium"
                                    style={ { marginLeft: 14 } }
                                >
                                    Forgot password?
                                </Link>
                            </Wrapper>
                            <Button
                                fullWidth
                                onClick={ submitHandler }
                                type="submit"
                                disabled={ BuilderState.isAuthenticating }
                                radius="xxl"
                                size="lg"
                                loading={ formModel.isLoading }
                            >
                                LOG IN
                            </Button>
                        </Wrapper>
                    </Form>
                </Wrapper>
            </Wrapper>
            <Description lineHeight="md">
                Not a member?
                { ' ' }
                <Link
                    onClick={ () => history.push('/sign-up') }
                    fontSize="14px"
                    weight="medium"
                    style={ { margin: 0 } }
                >
                    Create free account
                </Link>
            </Description>
        </Wrapper>
    );
});
