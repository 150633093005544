import * as React                     from "react";
import type { Scene }                 from "@babylonjs/core/scene";
import { Description, Icon, Wrapper } from "@geenee/ui";
import { DescriptionPropsType }       from "@geenee/ui/src/lib/component/description/description.component";
import GlobalState                    from '../../globalState';

interface ISceneTreeItemComponentProps {
    scene: Scene;
    sceneName?: string;
    sceneNameDescriptionProps?: Omit<DescriptionPropsType, 'children'>;
    selectedEntity?: any;
    children?: any
}

export class SceneTreeItemComponent extends React.Component<ISceneTreeItemComponentProps, { isSelected: boolean;}> {
    constructor(props: ISceneTreeItemComponentProps) {
        super(props);
        this.state = { isSelected: false };
    }

    onSelect = () => {
        if (!GlobalState.onSelectionChangedObservable) {
            return;
        }
        const { scene } = this.props;
        GlobalState.onSelectionChangedObservable.notifyObservers(scene);
    };

    shouldComponentUpdate(nextProps: ISceneTreeItemComponentProps, nextState: { isSelected: boolean;}) {
        if (nextProps.selectedEntity) {
            if (nextProps.scene === nextProps.selectedEntity) {
                nextState.isSelected = true;
                return true;
            }
            nextState.isSelected = false;
        }
        return true;
    }

    render() {
        return (
            <Wrapper
                className={ this.state.isSelected ? "itemContainer selected" : "itemContainer" }
                color='shade-1'
                row
                valign="center"
                onClick={ this.onSelect }
            >
                <Description size="sm">
                    { this.props.sceneName }
                </Description>
                <Icon
                    className="scene-settings-icon"
                    color="shade-4"
                    name="settings"
                    style={ { marginLeft: 'auto' } }
                    { ...this.props.sceneNameDescriptionProps }
                    size={ 15 }
                />
                { this.props.children }
            </Wrapper>
        );
    }
}
