import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '30',
    height = '30',
    onClick,
    id,
    fill = 'black'
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 30 30"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
            // eslint-disable-next-line max-len
                d="M18.2929 12.7071C18.6834 13.0976 19.3166 13.0976 19.7071 12.7071C20.0976 12.3166 20.0976 11.6834 19.7071 11.2929L15.7071 7.29289C15.3166 6.90237 14.6834 6.90237 14.2929 7.29289L10.2929 11.2929C9.90237 11.6834 9.90237 12.3166 10.2929 12.7071C10.6834 13.0976 11.3166 13.0976 11.7071 12.7071L15 9.41421L18.2929 12.7071Z"
                fill={ fill }
            />
            <path
                d="M16 22L16 8C16 7.44771 15.5523 7 15 7C14.4477 7 14 7.44771 14 8L14 22C14 22.5523 14.4477 23 15 23C15.5523 23 16 22.5523 16 22Z"
                fill={ fill }
            />
            <circle cx="15" cy="15" r="14" stroke={ fill } strokeWidth="2" />
        </svg>
    );
}
