const validateExternalUrl = (url: string) => {
    if (!url) return false;

    const regexp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
    const validatedUrl = regexp.test(url);

    // Dirty hack to check if URL is resolved properly and prevent using relative paths
    const urlParser = document.createElement('a');
    urlParser.href = url;

    const remoteHostname = urlParser.hostname;
    const currentResource = window.location.hostname;

    if (
        validatedUrl
        && remoteHostname
        && currentResource !== urlParser.hostname
    ) return true;
    return false;
};

// eslint-disable-next-line arca/no-default-export
export default validateExternalUrl;
