import React                     from 'react';
import { observer }              from 'mobx-react';
import { LandscapeSwitcher }     from '@geenee/builder/src/components/WizardForm/FormType/ContentMainForm/components/VideoEmbedForm/LandscapeSwitcher';
import { VideoUrlForm }          from '@geenee/builder/src/components/WizardForm/FormType/ContentMainForm/components/VideoEmbedForm/VideoUrlForm';
import { TYPE_FULLSCREEN_VIDEO } from '@geenee/builder/src/lib/constants';
import { AtomModel }             from '@geenee/builder/src/magellan/model/atom.model';
import { MoleculeModel }         from '@geenee/builder/src/magellan/model/molecule.model';

interface Props {
    type: string;
    disabled?: boolean;
    videoMolecule?: MoleculeModel
}

export const VideoEmbedForm = observer(({
    type,
    disabled,
    videoMolecule
}: Props) => {
    const isEmbedVideoContent = type === TYPE_FULLSCREEN_VIDEO;
    const videoAtom = videoMolecule?.getAtomByType('video') as AtomModel | undefined;
    if (!isEmbedVideoContent || !videoMolecule || !videoAtom) return <></>;
    const { atom_video_url, atom_video_allow_landscape_mode } = videoAtom?.options || {};
    const handleFieldChange = (value: string | boolean, field: string) => {
        videoAtom.options[ field ] = value;
        videoAtom.saveData();
    };
    return (
        <>
            <VideoUrlForm
                url={ atom_video_url || '' }
                onUpdate={ (value) => handleFieldChange(value, 'atom_video_url') }
                disabled={ disabled }
            />
            <LandscapeSwitcher
                allow_landscape_mode={ atom_video_allow_landscape_mode || false }
                onUpdate={ (value) => handleFieldChange(value, 'atom_video_allow_landscape_mode') }
                disabled={ disabled }
            />
        </>
    );
});
