import Quaternion from '../math/Quaternion';
import ISlam from './ISlam';

export default class CVSlam {
  private cv: any;
  private slam: ISlam;

  constructor(cv: any, maxPoints: number, fov: number, maxRotationRate: number, useImageFilter: number) {
    this.cv = cv;
    this.slam = new cv.Slam(maxPoints, fov, maxRotationRate, useImageFilter);
  }

  compute(image: ImageData, Q: Quaternion, rotationRate: Array<number>, scale: number, timestamp: number): Array<number> {
    const { cv } = this;
    const mImage = cv.matFromImageData(image);
    let mQ = cv.matFromArray(4, 1, cv.CV_64F, Q.toArray());
    let mRotationRate = cv.matFromArray(3, 1, cv.CV_64F, rotationRate);
    let mTranslation = new cv.Mat();
    this.slam.compute(mImage, mQ, mRotationRate, scale, timestamp, mTranslation);

    mImage.delete();

    return mTranslation.data64F;
  }

  start(u: number, v: number): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (this.slam.start(u, v)) {
          resolve();
        } else {
          reject(new Error('Wrong location!!!'));
        }
      }, 100);
    })
  }

  drag(u: number, v: number) {
    this.slam.drag(u, v);
  }

  clear() {
    this.slam.clear();
  }
}
