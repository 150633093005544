import APIRequest from '@geenee/builder/src/lib/APIRequest';

export const getProjects = () => APIRequest.request('GET', '/api/v0/init', { auth: true });

export const getProject = (id: string, projectType: string) => APIRequest.request('GET', `/api/v0/${ projectType }s/${ id }`);

export const createProject = (
    project: Record<string, any>,
    projectType: string
) => APIRequest.request('post', `/api/v0/${ projectType }s`, { [ projectType ]: project });

export const updateProject = (
    id: string,
    projectType: string,
    projectData: Record<string, any>
) => APIRequest.request('put', `/api/v0/${ projectType }s/${ id }`, { [ projectType ]: projectData });

export const deleteProject = (id: string, projectType: string) => APIRequest.request('delete', `/api/v0/${ projectType }s/${ id }`);

export const publishProject = (
    id: string,
    params: Record<string, any> = {}
) => APIRequest.request('post', `/api/v0/programs/${ id }/publish`, { params });
export const unpublishProject = (id: string) => APIRequest.request('delete', `/api/v0/programs/${ id }/publish`);
export const getPublishProject = (id: string) => APIRequest.request('get', `/api/v0/programs/${ id }/publish`);

export const getMockProjects = () => APIRequest.request('GET', 'http://localhost:3000/projects', { external: true }, { external: true });

export const getMockOpenedProject = async (id: string) => {
    const project = await APIRequest.request('GET', `http://localhost:3000/projects/${ id }/?_embed=experiences`, { external: true }, { external: true });
    for (const [ experienceIdx, experience ] of project.experiences.entries()) {
        const fetchedExperience = await APIRequest.request('GET', `http://localhost:3000/experiences/${ experience.id }/?_embed=views`, { external: true }, { external: true });
        for (const [ viewIdx, view ] of fetchedExperience.views.entries()) {
            const fetchedView = await APIRequest.request('GET', `http://localhost:3000/views/${ view.id }/?_embed=sections`, { external: true }, { external: true });
            for (const [ sectionIdx, section ] of fetchedView.sections.entries()) {
                const fetchedSection = await APIRequest.request('GET', `http://localhost:3000/sections/${ section.id }/?_embed=molecules`, { external: true }, { external: true });
                for (const molecule of fetchedSection.molecules) {
                    molecule.atoms = await APIRequest.request('GET', `http://localhost:3000/molecules/${ molecule.id }/atoms`, { external: true }, { external: true });
                }
                fetchedView.sections[ sectionIdx ] = fetchedSection;
            }
            fetchedExperience.views[ viewIdx ] = fetchedView;
        }
        project.experiences[ experienceIdx ] = fetchedExperience;
    }
    return [ project ];
};
