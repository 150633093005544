import React               from "react";
import GeeneeLogo          from '@geenee/assets/lib/icons/resources/GeeneeLogoGradient';
import backgroundSrc       from '@geenee/assets/lib/images/bg-frame4.png';
import { checkQueryToken } from '@geenee/builder/src/lib/checkQueryToken';
import { API_URL }         from '@geenee/builder/src/lib/constants';
import { useHistory }      from "@geenee/common";
import { useMobxForm }     from '@geenee/shared/src/magellan/hooks/use-mobx-form';
import {
    Button,
    Description,
    Form,
    FormError,
    FormItem,
    Input,
    Link,     Wrapper
} from "@geenee/ui";
import { observer } from 'mobx-react';

export const SignUpPage = observer(
    ({ hasSocialSignUp = true }: {
            hasSocialSignUp?: boolean;
        }) => {
        const history = useHistory();
        checkQueryToken(history);

        const formModel = useMobxForm("<SignUp>");
        const { form: { formState: { errors } } } = formModel;

        const submitHandler = async (event) => {
            try {
                await formModel.submit(event);

                history.push('/post-sign-up');
            } catch (error) {
                console.error(error);
            }
        };

        return (
            <Wrapper
                fullHeight
                align="center"
                valign="center"
                backgroundImage={ backgroundSrc }
            >
                <Wrapper align="center" margin="md">
                    <GeeneeLogo width="192" height="32" style={ { marginBottom: '18px' } } />
                    <Description size='lg'>
                        The all-in-one WebAR creation platform
                    </Description>
                </Wrapper>
                <Wrapper
                    maxWidth="352px"
                    frame="solid-opacity-white"
                    padding="xl"
                    radius="xxxl"
                    margin="md"
                    shadow="translucency-md"
                >
                    <Wrapper align="center" valign="center">
                        { hasSocialSignUp ? (
                            <>
                                <Button
                                    margin="sm"
                                    size="lg"
                                    radius="xxl"
                                    icon="google"
                                    iconSize={ 24 }
                                    fullWidth
                                    viewType="secondary"
                                    onClick={ () => {
                                        window.open(
                                            `${ API_URL }/api/v0/public/oauth/google/?scope=email%20profile`,
                                            '_self'
                                        );
                                    } }
                                >
                                    <Description
                                        size="md"
                                        lineHeight="md"
                                        color="shade-2"
                                        weight="medium"
                                    >
                                        SIGN UP WITH GOOGLE
                                    </Description>
                                </Button>
                                <Button
                                    margin="sm"
                                    size="lg"
                                    radius="xxl"
                                    icon="facebook"
                                    iconSize={ 24 }
                                    fullWidth
                                    viewType="secondary"
                                    onClick={ () => {
                                        window.open(
                                            `${ API_URL }/api/v0/public/oauth/facebook?scope=email%20public_profile`,
                                            '_self'
                                        );
                                    } }
                                >
                                    <Description
                                        size="md"
                                        lineHeight="md"
                                        color="shade-2"
                                        weight="medium"
                                    >
                                        SIGN UP WITH FACEBOOK
                                    </Description>
                                </Button>
                                <Description
                                    weight="medium"
                                    size="sm"
                                    margin="sm"
                                    color="shade-4"
                                >
                                    or
                                </Description>
                            </>
                        ) : (
                            <></>
                        ) }
                        <Form
                            onSubmit={ async (e) => {
                                await submitHandler(e);
                            } }
                        >
                            <Wrapper>
                                <FormItem
                                    errorComponent={ FormError as React.FC }
                                    error={
                                        !!errors.email
                                      && errors.email.message
                                    }
                                    hasTooltipMessage={ false }
                                    margin="sm"
                                    autoFocusDetect
                                >
                                    <Input
                                        size="md"
                                        fullWidth
                                        { ...formModel.bind('email', formModel.get('email').rules) }
                                        placeholder={ formModel.get('email').placeholder }
                                        error={ !!errors.email || !!errors.form }
                                        type="email"
                                    />
                                </FormItem>
                                <FormItem
                                    errorComponent={ FormError as React.FC }
                                    error={ (!!errors.password && errors.password.message) || (!!errors.form && errors.form.message) }
                                    hasTooltipMessage={ false }
                                    autoFocusDetect
                                >
                                    <Input
                                        size="md"
                                        type="password"
                                        fullWidth
                                        withShowPassword
                                        { ...formModel.bind('password', formModel.get('password').rules) }
                                        error={ !!errors.password || !!errors.form }
                                        placeholder={ formModel.get('password').placeholder }
                                    />
                                </FormItem>
                                <Button
                                    fullWidth
                                    onClick={ submitHandler }
                                    type="submit"
                                    radius="xxl"
                                    size="lg"
                                    margin="xs"
                                    loading={ formModel.isLoading }
                                >
                                    CREATE FREE ACCOUNT
                                </Button>
                                <Wrapper align="center">
                                    <Description size="sm" color="shade-4">No credit card required</Description>
                                </Wrapper>
                            </Wrapper>
                        </Form>
                    </Wrapper>
                </Wrapper>
                <Description lineHeight="md">
                    Already a member?
                    { ' ' }
                    <Link
                        onClick={ () => history.push('/login') }
                        fontSize="14px"
                        weight="medium"
                        style={ { margin: 0 } }
                    >
                        Login
                    </Link>
                </Description>
            </Wrapper>
        );
    }
);
