/* eslint-disable max-len */
import React from 'react';

export const Help = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      d="M8.878 10.144V10.036C8.878 9.298 9.22 8.74 9.85 8.29L10.588 7.768C11.65 7.012 12.37 6.004 12.37 4.582C12.37 2.764 10.912 1 8.122 1C5.458 1 4 2.872 4 4.816C4 5.086 4.018 5.374 4.09 5.626L6.358 5.716C6.304 5.554 6.286 5.356 6.286 5.14C6.286 4.132 6.844 3.106 8.122 3.106C9.328 3.106 9.922 3.898 9.922 4.726C9.922 5.266 9.688 5.752 9.148 6.148L8.284 6.796C7.258 7.57 6.862 8.452 6.862 9.406C6.862 9.676 6.898 9.91 6.934 10.144H8.878ZM6.52 12.754C6.52 13.546 7.15 14.176 7.942 14.176C8.734 14.176 9.382 13.546 9.382 12.754C9.382 11.962 8.734 11.314 7.942 11.314C7.15 11.314 6.52 11.962 6.52 12.754Z"
      fill="#303036"
    />
  </svg>
);
export const Chat = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      opacity="0.2"
      d="M9.375 0C5.86875 0 3 2.47031 3 5.57812C3 8.68594 5.86875 11.1562 9.375 11.1562C9.69375 11.1562 10.0125 11.1562 10.2516 11.0766L14.1562 12.75V9.24375C15.1125 8.2875 15.75 7.0125 15.75 5.57812C15.75 2.47031 12.8813 0 9.375 0Z"
      fill="#222225"
    />
    <path
      className="fill"
      d="M4.5 6C6.975 6 9 7.74375 9 9.9375C9 12.1313 6.975 13.875 4.5 13.875C4.275 13.875 4.05 13.875 3.88125 13.8187L1.125 15V12.525C0.45 11.85 0 10.95 0 9.9375C0 7.74375 2.025 6 4.5 6Z"
      fill="#222225"
    />
  </svg>
);
export const FaqBig = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      opacity="0.2"
      d="M8 0C3.58867 0 0 3.58867 0 8C0 12.4113 3.58867 16 8 16C12.4113 16 16 12.4113 16 8C16 3.58867 12.4113 0 8 0Z"
      fill="#222225"
    />
    <path
      className="fill"
      d="M8.49677 9.7459V9.67213C8.49677 9.16803 8.74194 8.78689 9.19355 8.47951L9.72258 8.12295C10.4839 7.60656 11 6.91803 11 5.94672C11 4.70492 9.95484 3.5 7.95484 3.5C6.04516 3.5 5 4.77869 5 6.10656C5 6.29098 5.0129 6.4877 5.06452 6.65984L6.69032 6.72131C6.65161 6.61066 6.63871 6.47541 6.63871 6.32787C6.63871 5.63934 7.03871 4.93852 7.95484 4.93852C8.81935 4.93852 9.24516 5.47951 9.24516 6.04508C9.24516 6.41393 9.07742 6.7459 8.69032 7.01639L8.07097 7.45902C7.33548 7.9877 7.05161 8.59016 7.05161 9.2418C7.05161 9.42623 7.07742 9.58607 7.10323 9.7459H8.49677ZM6.80645 11.5287C6.80645 12.0697 7.25806 12.5 7.82581 12.5C8.39355 12.5 8.85806 12.0697 8.85806 11.5287C8.85806 10.9877 8.39355 10.5451 7.82581 10.5451C7.25806 10.5451 6.80645 10.9877 6.80645 11.5287Z"
      fill="#303036"
    />
  </svg>
);
export const Tutorials = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      opacity="0.2"
      d="M8 0C3.58867 0 0 3.58867 0 8C0 12.4113 3.58867 16 8 16C12.4113 16 16 12.4113 16 8C16 3.58867 12.4113 0 8 0Z"
      fill="#222225"
    />
    <path
      className="fill"
      d="M11.25 7.56699C11.5833 7.75944 11.5833 8.24056 11.25 8.43301L6.75 11.0311C6.41667 11.2235 6 10.983 6 10.5981L6 5.40192C6 5.01702 6.41667 4.77646 6.75 4.96891L11.25 7.56699Z"
      fill="#303036"
    />
  </svg>
);
export const NewFeatures = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      className="fill"
      opacity="0.2"
      y="3"
      width="14"
      height="14"
      rx="2"
      fill="#303036"
    />
    <circle
      className="fill"
      cx="13.7106"
      cy="3.78947"
      r="3.78947"
      fill="#303036"
    />
  </svg>
);
