import { useMemo }   from "react";
import { useParams } from "react-router";
import {
    MoleculeModel, RouteParams,
    SectionModel,
    useInject, ViewModel
} from '@geenee/shared';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
// eslint-disable-next-line no-shadow
export enum MoleculeTypes {
  Redbull = 'redbull'
}

export const useContentNavHelper = () => {
    const { AppState } = useInject();
    const {
        activeProject,
        activeExperience,
        activeView
    } = AppState;
    const {
        viewId,
        sectionId
    } = useParams<RouteParams>();

    const moleculeSchemaType: MoleculeTypes | '' = useMemo(() => {
        let type: MoleculeTypes | '' = '';
        Object.values(MoleculeTypes).forEach((item: MoleculeTypes) => {
            if (window.location.href.includes(item)) {
                type = item;
            }
        });
        return type;
    }, []);

    let showContentNav = false;
    let items: any[] = [];
    let views: ViewModel[] = [];
    if (activeExperience && activeProject && activeView) {
        const sectionsArr = Array.from(activeView.sectionsRegistry.values());

        views = activeExperience.sortedChildren || [];
        const sections: SectionModel[] = [];
        views.forEach((item) => {
            const experienceSections = Array.from(item.sectionsRegistry.values())
                .sort((a, b) => a.options.order - b.options.order);
            experienceSections.forEach((section) => {
                if (section.options.section_is_visible_in_menu) {
                    sections.push(section);
                }
            });
        });
        showContentNav = !!views.length;

        const linkOverlaysSections = sectionsArr.filter((sectionModel) => sectionModel.type === 'overlay')
            .filter((sectionModel) => {
            // TODO: GOSH... MOVE THIS OPTION TO SECTION!!!
            // TODO: WTF!
                const moleculeModel: MoleculeModel = Array.from(sectionModel.moleculesRegistry.values())[ 0 ] as MoleculeModel;
                return moleculeModel?.options.molecule_overlay_open_at === 'link';
            });

        items = [ ...sections, ...linkOverlaysSections ];
    }
    let childViewId = '';
    views.find((view) => Array.from(view.sectionsRegistry.values())
        .sort((a, b) => a.options.order - b.options.order).find((sec) => {
            // @ts-ignore
            if (sec.options.section_is_visible_in_menu && (viewId === view.id)) {
                childViewId = sec.id;
                return true;
            }
            return false;
        }));

    const activeId = sectionId || childViewId;

    const atoms = items.map((item) => ({
        id:            item.id,
        experience_id: item.$parent.$parent.id,
        view_id:       item.$parent.id,
        molecule_id:   item.activeMolecule?.id || uuidv4(),
        options:       {
            navigation_atom_text:   item.options.section_menu_title,
            navigation_icon_geenee: item.type,
            ...item.id === activeId && {
                navigation_atom_text_font_weight: 800,
                navigation_atom_text_font_type:   'custom'
            }
        },
        inserted_at: 'asdasd',
        updated_at:  'asdasd',
        type:        'navigation-link',
        assets:      [],
        custom_code: {
            code:    '',
            options: {}
        }
    }));

    return { atoms, showContentNav, activeId, moleculeSchemaType };
};

export const getMoleculeSettings = (type: MoleculeTypes | '') => {
    switch (type) {
        case MoleculeTypes.Redbull:
            return {
                style_type:                             'transparent',
                style_corner_radius:                    'custom',
                style_corner_radius_custom:             48,
                style_border_radius:                    'l',
                composition_type:                       'topRight',
                link_size:                              's',
                link_icon_indent:                       's',
                link_icon_size:                         's',
                link_color_text:                        'white',
                link_color_text_type:                   'palette',
                link_icon_switch:                       true,
                link_text_switch:                       true,
                link_icon_style:                        'blur',
                link_icon_color_text:                   'auto',
                link_icon_color_text_type:              'text',
                link_icon_color_text_variation:         'contrast',
                link_icon_color_bg_alpha:               0.1,
                collapse_icon_style:                    'blur',
                collapse_icon_style_color_bg:           'black',
                collapse_icon_style_color_bg_type:      'palette',
                collapse_icon_style_color_bg_variation: 'contrast',
                collapse_icon_color_bg_alpha:           0.1,
                collapse_icon_color_text_type:          'auto',
                collapse_icon_color_text_variation:     'contrast',
                collapse_icon_color_text:               'text',
                collapse_scroll_height:                 's',
                collapse_items_default_visible_count:   2,
                link_padding:                           'auto'
            };
        default:
            return {
                composition_type:                       'topRight',
                link_size:                              's',
                link_color_text:                        'white',
                link_color_text_type:                   'palette',
                link_icon_switch:                       false,
                link_text_switch:                       true,
                link_icon_color_text:                   'auto',
                link_icon_color_text_type:              'text',
                link_icon_color_text_variation:         'contrast',
                collapse_icon_style:                    'blur',
                collapse_icon_style_color_bg:           'inherit',
                collapse_icon_style_color_bg_type:      'palette',
                collapse_icon_style_color_bg_variation: 'contrast',
                collapse_icon_color_bg_alpha:           0.1,
                collapse_icon_color_text_type:          'auto',
                collapse_icon_color_text_variation:     'contrast',
                collapse_icon_color_text:               'text',
                link_padding:                           'auto'
            };
    }
};
