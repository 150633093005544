import React            from 'react';
import CloseCircle      from '@geenee/builder/src/asset/icons/CloseCircle';
import { CloseElement } from './styles';

type Props = {
  close: () => void;
  isScene: boolean | undefined;
}

function CloseButton({ close, isScene }: Props) {
    return (
        <CloseElement>
            { isScene
            // (
            //     <Minimize onClick={ () => close() } />
            // )
                ? null : (
                    <CloseCircle onClick={ () => close() } />
                ) }
        </CloseElement>
    );
}

// eslint-disable-next-line arca/no-default-export
export default CloseButton;
