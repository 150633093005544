import React                        from 'react';
import { Analytics }                from '@geenee/analytics';
import { useInject }                from '@geenee/shared';
import { InputTable }               from '@geenee/ui';
import { observer }                 from 'mobx-react';
import { ShowIntroRow }             from '@geenee/builder/src/components/WizardForm/FormType/ExperienceDesignForm/components/ShowIntroRow';
import { EXPERIENCE_INTRO_TOGGLED } from '@geenee/builder/src/lib/constants';
import { ExperienceModel }          from '@geenee/builder/src/magellan/model/experience.model';

export const ExperienceDesignForm = observer(() => {
    const { AppState, container } = useInject();
    const analytics: Analytics = container.get('<Analytics>');
    const { activeExperience } = AppState;

    if (!activeExperience) {
        return <></>;
    }

    const disabled = activeExperience.nft_published;

    const onUpdateHandler = async (value) => {
        try {
            await (activeExperience as ExperienceModel).updateOptions({ hidden_in_tree: !value });
            const { id, program_id, title } = activeExperience;

            analytics.track(EXPERIENCE_INTRO_TOGGLED, { id, program_id, title, hidden_in_tree: !value });
        } catch (e) {
            console.log('Error while updating experience intro: ', e);
        }
    };

    return (
        <InputTable>
            <ShowIntroRow
                hidden_in_tree={ activeExperience.options.hidden_in_tree }
                disabled={ disabled }
                onUpdate={ onUpdateHandler }
            />
        </InputTable>
    );
});
