import React, { useState }                                from 'react';
import { observer }                                       from 'mobx-react';
import HireAProArrow                                      from '@geenee/builder/src/asset/icons/HireAProArrow';
import { cardToString }                                   from '@geenee/builder/src/components/modals/common/CardToString';
import { CreditCardEditor }                               from '@geenee/builder/src/components/modals/CreditCardEditor';
import { stripeState }                                    from '@geenee/builder/src/core/state/stripe.state';
import { ManageBilling }                                  from '../ManageBilling';
import { AddNewCard, Card, CardListWrapper, DefaultCard } from './styles';

// eslint-disable-next-line no-shadow
export enum CardListState {
    SHOW_CARD_LIST,
    SHOW_DEFAULT_PAYMENT_METHOD
}

type CardListProps = {
    currentId: string;
    onChange: (id: string) => any;
    // eslint-disable-next-line react/require-default-props
    onAddNewCardOverrideHandler?: () => void;
    // eslint-disable-next-line no-empty-pattern
    setModal: ({ }) => void;
};

export const CardList = observer(({
    currentId,
    onChange,
    onAddNewCardOverrideHandler,
    setModal
}: CardListProps) => {
    const defaultState = CardListState.SHOW_DEFAULT_PAYMENT_METHOD;
    const [ state, setState ] = useState(defaultState);

    // eslint-disable-next-line no-shadow,no-empty-pattern
    function CardListComponent({ setModal }: { setModal: ({ }) => void }) {
        // eslint-disable-next-line react/no-unused-prop-types
        function RenderCard(props: { id: string; tempId: string }) {
            const card = stripeState.paymentMethods[ props.id ];
            if (!card) {
                return <></>;
            }
            return (
                <Card
                    onClick={ () => {
                        setState(CardListState.SHOW_DEFAULT_PAYMENT_METHOD);
                        onChange(props.id);
                    } }
                    key={ props.id }
                >
                    { cardToString(card) }
                </Card>
            );
        }

        return (
            <CardListWrapper>
                { Object.keys(stripeState.paymentMethods).map(
                    (paymentMethodId, i) => (
                        <RenderCard
                            tempId={ currentId }
                            id={ paymentMethodId }
                            key={ i }
                        />
                    )
                ) }
                <AddNewCard
                    onClick={ () => {
                        onAddNewCardOverrideHandler
                            ? onAddNewCardOverrideHandler()
                            : setModal({
                                component: CreditCardEditor,
                                props:     {
                                    isNewCard:               true,
                                    onAddingNewCardFinished: () => setModal({ component: ManageBilling })
                                }
                            });
                    } }
                >
                    Add New Credit Card
                </AddNewCard>
            </CardListWrapper>
        );
    }

    const ShowDefaultPaymentMethodComponent = function () {
        const card = stripeState.paymentMethods[ currentId ];
        if (!card) {
            return <></>;
        }

        return (
            <DefaultCard onClick={ () => setState(CardListState.SHOW_CARD_LIST) }>
                <p style={ { margin: 0 } }>{ cardToString(card) }</p>
                <HireAProArrow />
            </DefaultCard>
        );
    };

    const getContent = () => {
        switch (state) {
            case CardListState.SHOW_CARD_LIST:
                return <CardListComponent setModal={ setModal } />;
            case CardListState.SHOW_DEFAULT_PAYMENT_METHOD:
                return <ShowDefaultPaymentMethodComponent />;
            default:
                return <p>Unknown CardList state</p>;
        }
    };

    return <>{ getContent() }</>;
});
