import APIRequest          from '@geenee/builder/src/lib/APIRequest';
import { querySerialize }  from '@geenee/builder/src/lib/querySerialize';
import { USER_SERVER_URL } from './AuthAPI';

const STRIPE_URL = `${ USER_SERVER_URL }/api/stripe`;

export type PurchaseSubscriptionBody = {
    paymentMethodId: string;
    priceId: string;
};

export type PaymentMethodBody = {
    paymentMethodId: string;
};

export type UpdatePaymentMethodBody = {
    stripe_id: string;
    newPaymentMethodId: string;
};

export type UpdatePaymentMethodDetailsBody = {
    paymentMethodId: string;
    name: string;
};

// @ts-ignore
export const fetchData = () => APIRequest.get(`${ STRIPE_URL }`, { external: true }).then((data) => data);

// @ts-ignore
export const purchaseSubscription = (body: PurchaseSubscriptionBody) => APIRequest.post(`/api/v0/account/subscribe?${ querySerialize(body) }`)
    .then((res) => res.data);
// @ts-ignore
export const cancelSubscription = () => APIRequest.del('/api/v0/account/subscribe').then(
    (data) => data
);

// @ts-ignore
export const attachPaymentMethod = (stripe_id: string) => APIRequest.post(`/api/v0/payment_methods/${ stripe_id }/create`);

// @ts-ignore
export const detachPaymentMethod = (stripe_id: string) => APIRequest.del(`/api/v0/payment_methods/${ stripe_id }/delete`);

// @ts-ignore
export const setDefaultPaymentMethod = (stripe_id: string) => APIRequest.patch(`/api/v0/payment_methods/${ stripe_id }/set_default`);

// @ts-ignore
export const updatePaymentMethod = (body: UpdatePaymentMethodBody) => APIRequest.patch(
    `${ STRIPE_URL }/paymentMethod`,
    body,
    { external: true }
).then((data) => data);

export const updatePaymentMethodDetails = (
    body: UpdatePaymentMethodDetailsBody
    // @ts-ignore
) => APIRequest.put(`${ STRIPE_URL }/paymentMethod/details`, body, { external: true }).then((data) => data);

// @ts-ignore
export const confirmPayment = () => APIRequest.post(
    `${ STRIPE_URL }/subscription/confirm-payment`,
    {},
    { external: true }
).then((data) => data);

// @ts-ignore
export const fetchPlans = () => APIRequest.get('/api/v0/public/plans').then((data) => data.data);
// @ts-ignore
export const fetchPaymentMethods = () => APIRequest.get('/api/v0/payment_methods').then((data) => data.data);
// @ts-ignore
export const fetchTransactions = () => APIRequest.get('/api/v0/account/transactions').then((res) => res.data);
