import React, { ChangeEvent }     from 'react';
import { Input, Wrapper }         from '@geenee/ui';
import { CardElement }            from '@stripe/react-stripe-js';
import { Bold,  CardInformation } from '../styles';

type CreditCardEditorProps = {
    cardName: string;
    onCardNameChange: (value: string) => void;
};

export function CreditCardForm(props: CreditCardEditorProps) {
    return (
        <Wrapper fullWidth maxWidth='600px' style={ { padding: '0 40px' } }>
            <Bold>name on card</Bold>
            <Input
                size="md"
                fullWidth
                name='cardName'
                value={ props.cardName }
                onChange={ (e: ChangeEvent<HTMLInputElement>) => props.onCardNameChange(e.target.value) }
            />
            <Bold>card information</Bold>
            <CardInformation>
                <CardElement />
            </CardInformation>
        </Wrapper>
    );
}
