import styled from 'styled-components';

export const LogoImageWrapper = styled.div`
    height: 28px;
    img, svg {
        height: 100%;
        object-fit: scale-down;
        object-position: center;
        max-width: 160px;
    }
`;
