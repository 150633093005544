import React, { ReactChild } from 'react';
import ModalStateContext     from '@geenee/builder/src/lib/ModalStateContext';
import ModalUpdaterContext   from '@geenee/builder/src/lib/ModalUpdaterContext';
import { useModalState }     from '@geenee/builder/src/lib/useModalState';

function WithModal({ children }: { children: ReactChild }) {
    const [ modalOptions, setModalOptions ] = useModalState({});

    return (
        <ModalUpdaterContext.Provider
        // @ts-ignore
            value={ setModalOptions }
        >
            <ModalStateContext.Provider value={ modalOptions }>
                { children }
            </ModalStateContext.Provider>
        </ModalUpdaterContext.Provider>
    );
}

// eslint-disable-next-line arca/no-default-export
export default WithModal;
