import React, { memo, useMemo }              from 'react';
import { DropdownDisplayValue }              from "@geenee/builder/src/components/DropdownDisplayValue";
import getRoleHintText                       from "@geenee/builder/src/lib/getRoleHintText";
import { FormItem, InputTableItem, Tooltip } from '@geenee/ui';

type Props = {
  errors: any
  value: string
  isNewMember: boolean
  onSelect: (value: string) => void
}
export const RightsDropdown = memo(({ errors, onSelect, value, isNewMember }: Props) => {
    const hintText = useMemo(() => getRoleHintText(value || ''), [ value ]);
    const roleOptions = useMemo(() => {
        const roles = [
            {
                value: 'admin',
                label: 'Admin'
            },
            {
                value: 'editor',
                label: 'Editor'
            }
        ];

        if (!isNewMember) {
            roles.push({
                value: 'owner',
                label: 'Owner'
            });
        }

        return roles;
    }, [ isNewMember ]);

    return (
        <InputTableItem
            weight="medium"
            label="Rights"
            viewType="secondary"
            valign="base-line"
            size="lg"
            ratio={ 0.65 }
        >
            <FormItem
                margin="off"
                autoFocusDetect
                error={
                    errors?.type === 'required' && (
                        'This field is required'
                    )
                }
            >
                <Tooltip style={ { width: '100%' } } message={ hintText } delay={ 500 } position="top" shadow arrow>
                    <DropdownDisplayValue
                        items={ roleOptions }
                        value={ value }
                        onSelect={ (_value) => {
                            onSelect(_value);
                        } }
                        defaultLabel="Select rights"
                    />
                </Tooltip>
            </FormItem>
        </InputTableItem>
    );
});
