export const handleError = (error: any, onSuccess: (key: string, value: { type: string, message: string })=> void) => {
    if (error.cause.invalid_params) {
        const { invalid_params } = error.cause;

        Object.entries(invalid_params).forEach(([ key, value ]) => {
            onSuccess(key === 'publishing_domain' ? 'name' : key, {
                type:    'text',
                message: (value as string[] || [])?.join(',')
            });
        });
    } else {
        onSuccess("form", { type: "custom", message: 'Incorrect request' });
    }
};
