/* eslint-disable max-len */
import React from 'react';

export const AlignTop = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6C12 5.44772 11.5523 5 11 5L10 5C9.44772 5 9 5.44771 9 6L9 15C9 15.5523 9.44771 16 10 16L11 16C11.5523 16 12 15.5523 12 15L12 6ZM7 6C7 5.44772 6.55228 5 6 5L5 5C4.44772 5 4 5.44771 4 6L4 11C4 11.5523 4.44771 12 5 12L6 12C6.55228 12 7 11.5523 7 11L7 6Z"
      fill="#303036"
    />
    <rect
      className="fill"
      opacity="0.3"
      x="1"
      width="14"
      height="2"
      rx="1"
      fill="#303036"
    />
  </svg>
);
export const AlignMiddle = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4C12 3.44772 11.5523 3 11 3H10C9.44772 3 9 3.44771 9 4L9 13C9 13.5523 9.44771 14 10 14H11C11.5523 14 12 13.5523 12 13L12 4ZM7 6C7 5.44772 6.55228 5 6 5L5 5C4.44772 5 4 5.44772 4 6V11C4 11.5523 4.44772 12 5 12H6C6.55228 12 7 11.5523 7 11L7 6Z"
      fill="#303036"
    />
    <rect
      className="fill"
      opacity="0.3"
      x="1"
      y="7.5"
      width="14"
      height="2"
      rx="1"
      fill="#303036"
    />
  </svg>
);
export const AlignBottom = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1C12 0.447715 11.5523 -1.95703e-08 11 -4.37114e-08L10 -8.74228e-08C9.44772 -1.11564e-07 9 0.447715 9 1L9 10C9 10.5523 9.44771 11 10 11L11 11C11.5523 11 12 10.5523 12 10L12 1ZM7 5C7 4.44772 6.55228 4 6 4L5 4C4.44772 4 4 4.44771 4 5L4 10C4 10.5523 4.44771 11 5 11L6 11C6.55228 11 7 10.5523 7 10L7 5Z"
      fill="#303036"
    />
    <rect
      className="fill"
      opacity="0.3"
      x="1"
      y="14"
      width="14"
      height="2"
      rx="1"
      fill="#303036"
    />
  </svg>
);
export const AlignLeft = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4C5.44772 4 5 4.44772 5 5V6C5 6.55228 5.44771 7 6 7H15C15.5523 7 16 6.55228 16 6V5C16 4.44772 15.5523 4 15 4H6ZM6 9C5.44772 9 5 9.44772 5 10V11C5 11.5523 5.44772 12 6 12H11C11.5523 12 12 11.5523 12 11V10C12 9.44772 11.5523 9 11 9H6Z"
      fill="#303036"
    />
    <rect
      className="fill"
      opacity="0.3"
      y="15"
      width="14"
      height="2"
      rx="1"
      transform="rotate(-90 0 15)"
      fill="#303036"
    />
  </svg>
);
export const AlignCenter = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      className="fill"
      opacity="0.3"
      x="7"
      y="15"
      width="14"
      height="2"
      rx="1"
      transform="rotate(-90 7 15)"
      fill="#303036"
    />
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 4C2.44772 4 2 4.44772 2 5V6C2 6.55228 2.44772 7 3 7H13C13.5523 7 14 6.55228 14 6V5C14 4.44772 13.5523 4 13 4H3ZM5 9C4.44772 9 4 9.44772 4 10V11C4 11.5523 4.44772 12 5 12H11C11.5523 12 12 11.5523 12 11V10C12 9.44772 11.5523 9 11 9H5Z"
      fill="#303036"
    />
  </svg>
);
export const AlignRight = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 4C2.44772 4 2 4.44772 2 5V6C2 6.55228 2.44772 7 3 7H10C10.5523 7 11 6.55228 11 6V5C11 4.44772 10.5523 4 10 4H3ZM5 9C4.44772 9 4 9.44772 4 10V11C4 11.5523 4.44772 12 5 12H10C10.5523 12 11 11.5523 11 11V10C11 9.44772 10.5523 9 10 9H5Z"
      fill="#303036"
    />
    <rect
      className="fill"
      opacity="0.3"
      x="14"
      y="15"
      width="14"
      height="2"
      rx="1"
      transform="rotate(-90 14 15)"
      fill="#303036"
    />
  </svg>
);
