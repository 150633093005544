import React                                                  from 'react';
import type { AnimationGroup, TargetedAnimation }             from "@babylonjs/core/Animations/animationGroup";
import type { Sound }                                         from "@babylonjs/core/Audio/sound";
import type { Bone }                                          from "@babylonjs/core/Bones/bone";
import type { Skeleton }                                      from "@babylonjs/core/Bones/skeleton";
import type { ArcRotateCamera }                               from "@babylonjs/core/Cameras/arcRotateCamera";
import type { FollowCamera }                                  from "@babylonjs/core/Cameras/followCamera";
import type { FreeCamera }                                    from "@babylonjs/core/Cameras/freeCamera";
import type { EffectLayer }                                   from "@babylonjs/core/Layers/effectLayer";
import type { DirectionalLight }                              from "@babylonjs/core/Lights/directionalLight";
import type { HemisphericLight }                              from "@babylonjs/core/Lights/hemisphericLight";
import type { PointLight }                                    from "@babylonjs/core/Lights/pointLight";
import type { SpotLight }                                     from "@babylonjs/core/Lights/spotLight";
import type { BackgroundMaterial }                            from "@babylonjs/core/Materials/Background/backgroundMaterial";
import type { Material }                                      from "@babylonjs/core/Materials/material";
import type { MultiMaterial }                                 from "@babylonjs/core/Materials/multiMaterial";
import type { NodeMaterial }                                  from "@babylonjs/core/Materials/Node/nodeMaterial";
import type { PBRMaterial }                                   from "@babylonjs/core/Materials/PBR/pbrMaterial";
import type { PBRMetallicRoughnessMaterial }                  from "@babylonjs/core/Materials/PBR/pbrMetallicRoughnessMaterial";
import type { PBRSpecularGlossinessMaterial }                 from "@babylonjs/core/Materials/PBR/pbrSpecularGlossinessMaterial";
import type { StandardMaterial }                              from "@babylonjs/core/Materials/standardMaterial";
import type { Texture }                                       from "@babylonjs/core/Materials/Textures/texture";
import type { Mesh }                                          from "@babylonjs/core/Meshes/mesh";
import type { TransformNode }                                 from "@babylonjs/core/Meshes/transformNode";
import type { IParticleSystem }                               from "@babylonjs/core/Particles/IParticleSystem";
import type { PostProcess }                                   from "@babylonjs/core/PostProcesses/postProcess";
import type { DefaultRenderingPipeline }                      from "@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/defaultRenderingPipeline";
import type { LensRenderingPipeline }                         from "@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/lensRenderingPipeline";
import type { SSAO2RenderingPipeline }                        from "@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/ssao2RenderingPipeline";
import type { SSAORenderingPipeline }                         from "@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/ssaoRenderingPipeline";
import type { PostProcessRenderPipeline }                     from "@babylonjs/core/PostProcesses/RenderPipeline/postProcessRenderPipeline";
import type { Scene }                                         from "@babylonjs/core/scene";
import type { Sprite }                                        from "@babylonjs/core/Sprites/sprite";
import type { SpriteManager }                                 from "@babylonjs/core/Sprites/spriteManager";
import type { Checkbox }                                      from "@babylonjs/gui/2D/controls/checkbox";
import type { ColorPicker }                                   from "@babylonjs/gui/2D/controls/colorpicker";
import type { Control }                                       from "@babylonjs/gui/2D/controls/control";
import type { Ellipse }                                       from "@babylonjs/gui/2D/controls/ellipse";
import type { Grid }                                          from "@babylonjs/gui/2D/controls/grid";
import type { Image }                                         from "@babylonjs/gui/2D/controls/image";
import type { InputText }                                     from "@babylonjs/gui/2D/controls/inputText";
import type { Line }                                          from "@babylonjs/gui/2D/controls/line";
import type { RadioButton }                                   from "@babylonjs/gui/2D/controls/radioButton";
import type { Rectangle }                                     from "@babylonjs/gui/2D/controls/rectangle";
import type { ScrollViewer }                                  from "@babylonjs/gui/2D/controls/scrollViewers/scrollViewer";
import type { ImageBasedSlider }                              from "@babylonjs/gui/2D/controls/sliders/imageBasedSlider";
import type { Slider }                                        from "@babylonjs/gui/2D/controls/sliders/slider";
import type { StackPanel }                                    from "@babylonjs/gui/2D/controls/stackPanel";
import type { TextBlock }                                     from "@babylonjs/gui/2D/controls/textBlock";
import { EmptyTab }                                           from "@geenee/geespector/src/components/sceneExplorer/components/EmptyTab";
import { CheckboxPropertyGridComponent }                      from "@geenee/geespector-ui-components/src/tabs/propertyGrids/gui/checkboxPropertyGridComponent";
import { ColorPickerPropertyGridComponent }                   from "@geenee/geespector-ui-components/src/tabs/propertyGrids/gui/colorPickerPropertyGridComponent";
import { ControlPropertyGridComponent }                       from "@geenee/geespector-ui-components/src/tabs/propertyGrids/gui/controlPropertyGridComponent";
import { EllipsePropertyGridComponent }                       from "@geenee/geespector-ui-components/src/tabs/propertyGrids/gui/ellipsePropertyGridComponent";
import { GridPropertyGridComponent }                          from "@geenee/geespector-ui-components/src/tabs/propertyGrids/gui/gridPropertyGridComponent";
import { ImageBasedSliderPropertyGridComponent }              from "@geenee/geespector-ui-components/src/tabs/propertyGrids/gui/imageBasedSliderPropertyGridComponent";
import { ImagePropertyGridComponent }                         from "@geenee/geespector-ui-components/src/tabs/propertyGrids/gui/imagePropertyGridComponent";
import { InputTextPropertyGridComponent }                     from "@geenee/geespector-ui-components/src/tabs/propertyGrids/gui/inputTextPropertyGridComponent";
import { LinePropertyGridComponent }                          from "@geenee/geespector-ui-components/src/tabs/propertyGrids/gui/linePropertyGridComponent";
import { RadioButtonPropertyGridComponent }                   from "@geenee/geespector-ui-components/src/tabs/propertyGrids/gui/radioButtonPropertyGridComponent";
import { RectanglePropertyGridComponent }                     from "@geenee/geespector-ui-components/src/tabs/propertyGrids/gui/rectanglePropertyGridComponent";
import { ScrollViewerPropertyGridComponent }                  from "@geenee/geespector-ui-components/src/tabs/propertyGrids/gui/scrollViewerPropertyGridComponent";
import { SliderPropertyGridComponent }                        from "@geenee/geespector-ui-components/src/tabs/propertyGrids/gui/sliderPropertyGridComponent";
import { StackPanelPropertyGridComponent }                    from "@geenee/geespector-ui-components/src/tabs/propertyGrids/gui/stackPanelPropertyGridComponent";
import { TextBlockPropertyGridComponent }                     from "@geenee/geespector-ui-components/src/tabs/propertyGrids/gui/textBlockPropertyGridComponent";
import { LockObject }                                         from "@geenee/geespector-ui-components/src/tabs/propertyGrids/lockObject";
import type { IPaneComponentProps }                           from "../paneComponent";
import { PaneComponent }                                      from "../paneComponent";
import { AnimationGroupGridComponent }                        from "./propertyGrids/animations/animationGroupPropertyGridComponent";
import { TargetedAnimationGridComponent }                     from "./propertyGrids/animations/targetedAnimationPropertyGridComponent";
import { ArcRotateCameraPropertyGridComponent }               from "./propertyGrids/cameras/arcRotateCameraPropertyGridComponent";
import { FollowCameraPropertyGridComponent }                  from "./propertyGrids/cameras/followCameraPropertyGridComponent";
import { FreeCameraPropertyGridComponent }                    from "./propertyGrids/cameras/freeCameraPropertyGridComponent";
import { LayerPropertyGridComponent }                         from "./propertyGrids/layers/layerPropertyGridComponent";
import { DirectionalLightPropertyGridComponent }              from "./propertyGrids/lights/directionalLightPropertyGridComponent";
import { HemisphericLightPropertyGridComponent }              from "./propertyGrids/lights/hemisphericLightPropertyGridComponent";
import { PointLightPropertyGridComponent }                    from "./propertyGrids/lights/pointLightPropertyGridComponent";
import { SpotLightPropertyGridComponent }                     from "./propertyGrids/lights/spotLightPropertyGridComponent";
import { BackgroundMaterialPropertyGridComponent }            from "./propertyGrids/materials/backgroundMaterialPropertyGridComponent";
import { MaterialPropertyGridComponent }                      from "./propertyGrids/materials/materialPropertyGridComponent";
import { MultiMaterialPropertyGridComponent }                 from "./propertyGrids/materials/multiMaterialPropertyGridComponent";
import { NodeMaterialPropertyGridComponent }                  from "./propertyGrids/materials/nodeMaterialPropertyGridComponent";
import { PBRMaterialPropertyGridComponent }                   from "./propertyGrids/materials/pbrMaterialPropertyGridComponent";
import { PBRMetallicRoughnessMaterialPropertyGridComponent }  from "./propertyGrids/materials/pbrMetallicRoughnessMaterialPropertyGridComponent";
import { PBRSpecularGlossinessMaterialPropertyGridComponent } from "./propertyGrids/materials/pbrSpecularGlossinessMaterialPropertyGridComponent";
import { StandardMaterialPropertyGridComponent }              from "./propertyGrids/materials/standardMaterialPropertyGridComponent";
import { TexturePropertyGridComponent }                       from "./propertyGrids/materials/texturePropertyGridComponent";
import { BonePropertyGridComponent }                          from "./propertyGrids/meshes/bonePropertyGridComponent";
import { MeshPropertyGridComponent }                          from "./propertyGrids/meshes/meshPropertyGridComponent";
import { SkeletonPropertyGridComponent }                      from "./propertyGrids/meshes/skeletonPropertyGridComponent";
import { TransformNodePropertyGridComponent }                 from "./propertyGrids/meshes/transformNodePropertyGridComponent";
import { ParticleSystemPropertyGridComponent }                from "./propertyGrids/particleSystems/particleSystemPropertyGridComponent";
import { DefaultRenderingPipelinePropertyGridComponent }      from "./propertyGrids/postProcesses/defaultRenderingPipelinePropertyGridComponent";
import { LensRenderingPipelinePropertyGridComponent }         from "./propertyGrids/postProcesses/lensRenderingPipelinePropertyGridComponent";
import { PostProcessPropertyGridComponent }                   from "./propertyGrids/postProcesses/postProcessPropertyGridComponent";
import { RenderingPipelinePropertyGridComponent }             from "./propertyGrids/postProcesses/renderingPipelinePropertyGridComponent";
import { SSAO2RenderingPipelinePropertyGridComponent }        from "./propertyGrids/postProcesses/ssao2RenderingPipelinePropertyGridComponent";
import { SSAORenderingPipelinePropertyGridComponent }         from "./propertyGrids/postProcesses/ssaoRenderingPipelinePropertyGridComponent";
import { ScenePropertyGridComponent }                         from "./propertyGrids/scenePropertyGridComponent";
import { SoundPropertyGridComponent }                         from "./propertyGrids/sounds/soundPropertyGridComponent";
import { SpriteManagerPropertyGridComponent }                 from "./propertyGrids/sprites/spriteManagerPropertyGridComponent";
import { SpritePropertyGridComponent }                        from "./propertyGrids/sprites/spritePropertyGridComponent";

export class PropertyGridTabComponent extends PaneComponent {
    private _timerIntervalId: number;
    private _lockObject = new LockObject();

    constructor(props: IPaneComponentProps) {
        super(props);
    }

    timerRefresh() {
        if (!this._lockObject.lock) {
            this.forceUpdate();
        }
    }

    componentDidMount() {
        this._timerIntervalId = window.setInterval(() => this.timerRefresh(), 500);
    }

    componentWillUnmount() {
        window.clearInterval(this._timerIntervalId);
    }

    render() {
        const entity = this.props.selectedEntity;

        if (!entity || entity.length) {
            return <EmptyTab customLabel="No selected nodes" customDescription="Select any node to display their parameters" />;
        }
        const key = entity?.uniqueId || entity?.id;
        if (entity.getClassName) {
            const className = entity.getClassName();

            if (className === "Scene") {
                const scene = entity as Scene;
                return (
                    <ScenePropertyGridComponent
                        key={ key }
                        scene={ scene }
                        globalState={ this.props.globalState }
                        lockObject={ this._lockObject }
                        onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "Sound") {
                const sound = entity as Sound;
                return (
                    <SoundPropertyGridComponent
                        key={ key }
                        sound={ sound }
                        globalState={ this.props.globalState }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "Sprite") {
                const sprite = entity as Sprite;
                return (
                    <SpritePropertyGridComponent
                        key={ key }
                        sprite={ sprite }
                        globalState={ this.props.globalState }
                        lockObject={ this._lockObject }
                        onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "SpriteManager") {
                const spriteManager = entity as SpriteManager;
                return (
                    <SpriteManagerPropertyGridComponent
                        spriteManager={ spriteManager }
                        key={ key }
                        globalState={ this.props.globalState }
                        lockObject={ this._lockObject }
                        onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className.indexOf("Mesh") !== -1) {
                const mesh = entity as Mesh;
                if (mesh.getTotalVertices() > 0) {
                    return (
                        <MeshPropertyGridComponent
                            key={ key }
                            globalState={ this.props.globalState }
                            mesh={ mesh }
                            lockObject={ this._lockObject }
                            onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                            onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                        />
                    );
                }
            }

            if (className.indexOf("ParticleSystem") !== -1) {
                const particleSystem = entity as IParticleSystem;
                return (
                    <ParticleSystemPropertyGridComponent
                        key={ key }
                        globalState={ this.props.globalState }
                        system={ particleSystem }
                        lockObject={ this._lockObject }
                        onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (
                className.indexOf("FreeCamera") !== -1
        || className.indexOf("UniversalCamera") !== -1
        || className.indexOf("WebXRCamera") !== -1
        || className.indexOf("DeviceOrientationCamera") !== -1
            ) {
                const freeCamera = entity as FreeCamera;
                return (
                    <FreeCameraPropertyGridComponent
                        key={ key }
                        globalState={ this.props.globalState }
                        camera={ freeCamera }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className.indexOf("ArcRotateCamera") !== -1) {
                const arcRotateCamera = entity as ArcRotateCamera;
                return (
                    <ArcRotateCameraPropertyGridComponent
                        key={ key }
                        globalState={ this.props.globalState }
                        camera={ arcRotateCamera }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className.indexOf("FollowCamera") !== -1) {
                const followCamera = entity as FollowCamera;
                return (
                    <FollowCameraPropertyGridComponent
                        key={ key }
                        globalState={ this.props.globalState }
                        camera={ followCamera }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "HemisphericLight") {
                const hemisphericLight = entity as HemisphericLight;
                return (
                    <HemisphericLightPropertyGridComponent
                        key={ key }
                        globalState={ this.props.globalState }
                        light={ hemisphericLight }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "PointLight") {
                const pointLight = entity as PointLight;
                return (
                    <PointLightPropertyGridComponent
                        key={ key }
                        globalState={ this.props.globalState }
                        light={ pointLight }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "DirectionalLight") {
                const pointLight = entity as DirectionalLight;
                return (
                    <DirectionalLightPropertyGridComponent
                        key={ key }
                        globalState={ this.props.globalState }
                        light={ pointLight }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "SpotLight") {
                const pointLight = entity as SpotLight;
                return (
                    <SpotLightPropertyGridComponent
                        key={ key }
                        globalState={ this.props.globalState }
                        light={ pointLight }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className.indexOf("TransformNode") !== -1 || className.indexOf("Mesh") !== -1) {
                const transformNode = entity as TransformNode;
                return (
                    <TransformNodePropertyGridComponent
                        key={ key }
                        transformNode={ transformNode }
                        globalState={ this.props.globalState }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "MultiMaterial") {
                const material = entity as MultiMaterial;
                return (
                    <MultiMaterialPropertyGridComponent
                        key={ key }
                        globalState={ this.props.globalState }
                        material={ material }
                        lockObject={ this._lockObject }
                        onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "StandardMaterial") {
                const material = entity as StandardMaterial;
                return (
                    <StandardMaterialPropertyGridComponent
                        key={ key }
                        globalState={ this.props.globalState }
                        material={ material }
                        lockObject={ this._lockObject }
                        onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "NodeMaterial") {
                const material = entity as NodeMaterial;
                return (
                    <NodeMaterialPropertyGridComponent
                        key={ key }
                        globalState={ this.props.globalState }
                        material={ material }
                        lockObject={ this._lockObject }
                        onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "PBRMaterial") {
                const material = entity as PBRMaterial;
                return (
                    <PBRMaterialPropertyGridComponent
                        key={ key }
                        globalState={ this.props.globalState }
                        material={ material }
                        lockObject={ this._lockObject }
                        onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "PBRMetallicRoughnessMaterial") {
                const material = entity as PBRMetallicRoughnessMaterial;
                return (
                    <PBRMetallicRoughnessMaterialPropertyGridComponent
                        key={ key }
                        globalState={ this.props.globalState }
                        material={ material }
                        lockObject={ this._lockObject }
                        onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "PBRSpecularGlossinessMaterial") {
                const material = entity as PBRSpecularGlossinessMaterial;
                return (
                    <PBRSpecularGlossinessMaterialPropertyGridComponent
                        key={ key }
                        globalState={ this.props.globalState }
                        material={ material }
                        lockObject={ this._lockObject }
                        onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "BackgroundMaterial") {
                const material = entity as BackgroundMaterial;
                return (
                    <BackgroundMaterialPropertyGridComponent
                        key={ key }
                        globalState={ this.props.globalState }
                        material={ material }
                        lockObject={ this._lockObject }
                        onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "AnimationGroup") {
                const animationGroup = entity as AnimationGroup;
                return (
                    <AnimationGroupGridComponent
                        key={ key }
                        globalState={ this.props.globalState }
                        animationGroup={ animationGroup }
                        scene={ this.props.scene }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "TargetedAnimation") {
                const targetedAnimation = entity as TargetedAnimation;
                return (
                    <TargetedAnimationGridComponent
                        key={ key }
                        globalState={ this.props.globalState }
                        targetedAnimation={ targetedAnimation }
                        scene={ this.props.scene }
                        lockObject={ this._lockObject }
                        onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className.indexOf("Material") !== -1) {
                const material = entity as Material;
                return (
                    <MaterialPropertyGridComponent
                        key={ key }
                        material={ material }
                        globalState={ this.props.globalState }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className.indexOf("DefaultRenderingPipeline") !== -1) {
                const renderPipeline = entity as DefaultRenderingPipeline;
                return (
                    <DefaultRenderingPipelinePropertyGridComponent
                        key={ key }
                        renderPipeline={ renderPipeline }
                        globalState={ this.props.globalState }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className.indexOf("LensRenderingPipeline") !== -1) {
                const renderPipeline = entity as LensRenderingPipeline;
                return (
                    <LensRenderingPipelinePropertyGridComponent
                        key={ key }
                        renderPipeline={ renderPipeline }
                        globalState={ this.props.globalState }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className.indexOf("SSAORenderingPipeline") !== -1) {
                const renderPipeline = entity as SSAORenderingPipeline;
                return (
                    <SSAORenderingPipelinePropertyGridComponent
                        key={ key }
                        renderPipeline={ renderPipeline }
                        globalState={ this.props.globalState }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className.indexOf("SSAO2RenderingPipeline") !== -1) {
                const renderPipeline = entity as SSAO2RenderingPipeline;
                return (
                    <SSAO2RenderingPipelinePropertyGridComponent
                        renderPipeline={ renderPipeline }
                        key={ key }
                        globalState={ this.props.globalState }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className.indexOf("RenderingPipeline") !== -1) {
                const renderPipeline = entity as PostProcessRenderPipeline;
                return (
                    <RenderingPipelinePropertyGridComponent
                        renderPipeline={ renderPipeline }
                        key={ key }
                        globalState={ this.props.globalState }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className.indexOf("PostProcess") !== -1) {
                const postProcess = entity as PostProcess;
                return (
                    <PostProcessPropertyGridComponent
                        postProcess={ postProcess }
                        key={ key }
                        globalState={ this.props.globalState }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className.indexOf("Layer") !== -1) {
                const layer = entity as EffectLayer;
                return (
                    <LayerPropertyGridComponent
                        key={ key }
                        layer={ layer }
                        globalState={ this.props.globalState }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className.indexOf("Texture") !== -1) {
                const texture = entity as Texture;
                return (
                    <TexturePropertyGridComponent
                        key={ key }
                        texture={ texture }
                        globalState={ this.props.globalState }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className.indexOf("Skeleton") !== -1) {
                const skeleton = entity as Skeleton;
                return (
                    <SkeletonPropertyGridComponent
                        key={ key }
                        skeleton={ skeleton }
                        globalState={ this.props.globalState }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className.indexOf("Bone") !== -1) {
                const bone = entity as Bone;
                return (
                    <BonePropertyGridComponent
                        key={ key }
                        bone={ bone }
                        globalState={ this.props.globalState }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "TextBlock") {
                const textBlock = entity as TextBlock;
                return (
                    <TextBlockPropertyGridComponent
                        key={ key }
                        textBlock={ textBlock }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "InputText") {
                const inputText = entity as InputText;
                return (
                    <InputTextPropertyGridComponent
                        key={ key }
                        inputText={ inputText }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "ColorPicker") {
                const colorPicker = entity as ColorPicker;
                return (
                    <ColorPickerPropertyGridComponent
                        key={ key }
                        colorPicker={ colorPicker }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "Image") {
                const image = entity as Image;
                return (
                    <ImagePropertyGridComponent
                        key={ key }
                        image={ image }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "Slider") {
                const slider = entity as Slider;
                return (
                    <SliderPropertyGridComponent
                        key={ key }
                        slider={ slider }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "ImageBasedSlider") {
                const imageBasedSlider = entity as ImageBasedSlider;
                return (
                    <ImageBasedSliderPropertyGridComponent
                        key={ key }
                        imageBasedSlider={ imageBasedSlider }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "Rectangle") {
                const rectangle = entity as Rectangle;
                return (
                    <RectanglePropertyGridComponent
                        key={ key }
                        rectangle={ rectangle }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "StackPanel") {
                const stackPanel = entity as StackPanel;
                return (
                    <StackPanelPropertyGridComponent
                        key={ key }
                        stackPanel={ stackPanel }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "Grid") {
                const grid = entity as Grid;
                return (
                    <GridPropertyGridComponent
                        key={ key }
                        grid={ grid }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "ScrollViewer") {
                const scrollViewer = entity as ScrollViewer;
                return (
                    <ScrollViewerPropertyGridComponent
                        key={ key }
                        scrollViewer={ scrollViewer }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "Ellipse") {
                const ellipse = entity as Ellipse;
                return (
                    <EllipsePropertyGridComponent
                        key={ key }
                        ellipse={ ellipse }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "Checkbox") {
                const checkbox = entity as Checkbox;
                return (
                    <CheckboxPropertyGridComponent
                        key={ key }
                        checkbox={ checkbox }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "RadioButton") {
                const radioButton = entity as RadioButton;
                return (
                    <RadioButtonPropertyGridComponent
                        key={ key }
                        radioButtons={ [ radioButton ] }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (className === "Line") {
                const line = entity as Line;
                return (
                    <LinePropertyGridComponent
                        key={ key }
                        line={ line }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }

            if (entity._host) {
                const control = entity as Control;
                return (
                    <ControlPropertyGridComponent
                        key={ key }
                        control={ control }
                        lockObject={ this._lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                );
            }
        }

        return null;
    }
}
