import { useRef, useState } from "react";
import useResizeObserver    from "use-resize-observer";

export const useDynamicHeight = (init?: string, on = false) => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(typeof navigator !== "undefined" ? navigator.userAgent : "");
    const [ height, setHeight ] = useState(init);

    // @ts-ignore
    const { ref } = !isSafari && on
        ? useResizeObserver({
            // @ts-ignore
            onResize: ({ height: elemHeight = 0 }: { height: number }) => {
                setHeight(`${ elemHeight }px`);
            }
        })
        : useRef(null);

    return [ ref, height ];
};
