import { ExperienceModel, ProjectModel }                     from '@geenee/shared';
import { SectionFactory }                                    from '@geenee/shared/src/magellan/section/factory/section.factory';
import { SectionModel }                                      from '@geenee/shared/src/magellan/section/model/section.model';
import { ViewType }                                          from '@geenee/shared/type/view.type';
import { inject, injectable }                                from 'inversify';
import { action, computed, observable, ObservableMap, toJS } from 'mobx';
import { HttpClient }                                        from '../../service/http.service';
import { BaseEntityModel }                                   from './base-entity.model';

@injectable()
export class ViewModel extends BaseEntityModel implements ViewType {
    @inject('<HttpClient>')
        httpClient!: HttpClient;
    @observable program_id: ViewType['program_id'] = '';

    type = 'view';
    @observable title: ViewType['title'] = '';
    @observable cover_image_id: ViewType['cover_image_id'] = '';
    @observable cover_image: ViewType['cover_image'] = { url: '' };
    @observable splash_screen_message: ViewType['splash_screen_message'] = '';
    @observable menu_title: ViewType['menu_title'] = '';
    @observable sections: ViewType['sections'] = [];
    options!: ViewType['options'];
    @inject('<SectionFactory>')
        sectionFactory!: SectionFactory;
    @observable sectionsRegistry: ObservableMap<
      string,
      SectionModel
      > = new ObservableMap([]);
    $parent!: ExperienceModel;

    @computed get activeSection(): SectionModel | undefined {
        // @ts-ignore
        return this.sectionsRegistry.get(this.$parent.options.sectionId);
    }

    @computed get nft_published() {
        return this.$parent.nft_published;
    }

    @computed get toJsonObject(): ViewModel {
        const contents = Array.from(this.sectionsRegistry.values()).map((el) => el.toJsonObject());
        return {
            ...toJS(this), $parent: undefined, contents, contentsRegistry: undefined, sectionsRegistry: undefined
        };
    }

    @computed get children() {
        return this.sectionsRegistry;
    }

    @computed
    get parentProject(): ProjectModel | null {
        return this.$parent.parentProject;
    }

    @action
    async saveData() {
        alert('Saving Not Implemented');
    }
}
