import getEmitter from '../emitter/getEmitter';
import PoseSensor from '../imu/IPoseSensor';
import { createPoseSensor } from '../imu/PoseSensorFactory';
import { Processor } from '@geenee/armature'

import {
  MAX_CNT,
  USE_IMAGE_FILTER,
  MAX_ROT_RATE,
  MAX_SIZE
} from './parameters';

import CVSlam from './CVSlam';
import { getImageFromCanvas, captureFrame, scaleFromSize } from '../image/ImageUtil';
import { createCanvasRenderingContext2D } from '../utils';
import { Size } from "@geenee/armature";
import { SlamResult } from "~/index";

export type ImageInput = ImageData | ImageBuffer | HTMLCanvasElement;
export interface ImageBuffer {
  /** Pixel buffer */
  data: Uint8Array;
  /** Width in pixels */
  width: number;
  /** Height in pixels */
  height: number;
}

/**
 *
 */
export default class Slam extends Processor<SlamResult> {
  // @ts-ignore
  public on: Function;
  // @ts-ignore
  public off: Function;
  private captureTime = 0;

  private video?: HTMLVideoElement;
  private fov?: number;
  // @ts-ignore
  private emit: Function;
  private poseSensor: PoseSensor;
  private prevImageFrame?: ImageData;
  private frameContext: CanvasRenderingContext2D;
  private videoContext: CanvasRenderingContext2D;
  private slamContext: CanvasRenderingContext2D;
  private maxSize: number;
  private lastSize: Size;
  private scale: number;
  private translation: Array<number>;
  private isStarted: boolean;
  private isReady: boolean;
  private slam?: CVSlam;


  constructor() {
    super();
    // Emitter
    const emitter = getEmitter(this) as any;
    this.emit = emitter.emit;
    this.on = emitter.on;
    this.off = emitter.off;

    this.frameContext = createCanvasRenderingContext2D();
    this.videoContext = createCanvasRenderingContext2D();
    this.slamContext = createCanvasRenderingContext2D();

    this.maxSize = MAX_SIZE;
    this.lastSize = { width: 0, height: 0 };
    this.scale = 1.0;
    this.translation = [];

    this.isStarted = false;
    this.isReady = false;

    this.poseSensor = createPoseSensor();
  }

  //------------------------------------------------
  //------------- Start Initialization -------------

  public async init(params?: any, size?: Size, ratio?: number) {
    this.fov = 60;

    this.params = params || {};
    if (!ratio && size)
      ratio = size?.width / size?.height;

    this.setupVideo(
        size || this.videoSize,
        ratio || this.videoRatio);

    // @ts-ignore
    this.slam = new CVSlam(window.cv, MAX_CNT, this.fov, MAX_ROT_RATE, USE_IMAGE_FILTER);
    return true
  }

  private initializeIMU() {
    const promise = this.poseSensor.start() as Promise<void>;
    promise.then(() => {
      return this.poseSensor.start();
    }).then(() => {
      this.isReady = true;
      this.emit('geenee-slam-ready');
    }, (error) => {
      this.emit('geenee-slam-permission-request');
    });
  }

  //------------- End Initialization -------------
  //----------------------------------------------

  public startImu() {
    this.poseSensor.startWithRequestPermisson()
      .then(() => {
        this.isReady = true;
        this.emit('geenee-slam-ready');
      });

  }

  public async process(input: HTMLCanvasElement, timestamp?: number): Promise<SlamResult> {
    const context = input.getContext("2d", { alpha: false });
    if(!context) {
      return []
    }
    if (!this.isReady) return [];
      const Q = this.poseSensor.getOrientation();
      const rotation_rate = this.isStarted ? this.poseSensor.getRotationRate() : [8, 8, 8];

      if (this.lastSize.width !== this.videoSize.width || this.lastSize.height !== this.videoSize.height) {
        this.lastSize.width = this.videoSize.width;
        this.lastSize.height = this.videoSize.height;
        this.scale = this.computeScale(this.videoSize.width, this.videoSize.height, this.maxSize);
      }
      const slamImageWidth = this.videoSize.width * this.scale;
      const slamImageHeight = this.videoSize.height * this.scale;
      const slamFrame = getImageFromCanvas(context, this.slamContext, slamImageWidth, slamImageHeight);
      // const currImageFrame = this.prevImageFrame ? this.prevImageFrame : slamFrame;
      // this.prevImageFrame = slamFrame;

      const slam = this.slam as CVSlam;
      // @ts-ignore
      this.translation = slam.compute(slamFrame, Q, rotation_rate, this.scale, timestamp);
      return this.translation as SlamResult;
  }

  public start() {}

  public startExperience(u: number, v: number, sceneWidth: number, sceneHeight: number): Promise<void> {
    const scale = scaleFromSize(this.videoSize, sceneWidth, sceneHeight);
    const { width: videoWidth, height: videoHeight } = this.videoSize;

    const dx = (videoWidth - sceneWidth / scale) / 2;
    const dy = (videoHeight - sceneHeight / scale) / 2;
    const vid_u = u / scale + dx;
    const vid_v = v / scale + dy;


    return new Promise((resolve, reject) => {
      if (this.poseSensor.isStarted()) {
        this.isStarted = true;
        const slam = this.slam as CVSlam;
        slam.start(vid_u, vid_v).then(
          () => {
            resolve();
          }, () => {
            this.isStarted = false;
            reject(new Error('Wrong position'));
          }
        )
      } else {
        reject(new Error('IMU is not ready'));
      }
    });
  }

  public drag(u: number, v: number) {
    if (this.isStarted) {
      this.slam?.drag(u, v);
    }
  }

  public stop() {
    this.isStarted = false;
    this.emit('geenee-slam-stopped');
  }

  public clear() {
    this.translation = [];
    const slam = this.slam as CVSlam;
    slam.clear();
  }

  private computeScale(image_width: number, image_height: number, max_size: number): number {
    if (image_width * image_height > max_size) {
      const scale = Math.sqrt(max_size / (image_width * image_height));
      const bigger_side = Math.max(image_width, image_height);
      const scaled_side = bigger_side * scale;
      return scaled_side / bigger_side;
    } else {
      return 1.0;
    }
  }
}
