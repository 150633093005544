import React, { useState } from 'react';
import { useInject }       from '@geenee/shared';
import {
    Description,
    FormItem,
    Input,
    InputTable,
    Title,
    Wrapper
} from '@geenee/ui';
import isEmpty            from 'lodash-es/isEmpty';
import { runInAction }    from 'mobx';
import { observer }       from 'mobx-react';
import NextArrow          from '@geenee/builder/src/asset/icons/NextArrow';
import RaisedButton       from '@geenee/builder/src/components/common/Button/WithIcon';
import { DoubleCheckNFT } from '@geenee/builder/src/components/modals/DoubleCheckNFT';
import { BackButton }     from '@geenee/builder/src/components/modals/DoubleCheckNFT/styles';
import {
    generateQrImage,
    generateQrWithTermsImage
}                           from '@geenee/builder/src/components/NftWithTermsImage';
import { useBuilderInject }                   from '@geenee/builder/src/core/hook/use-builder-inject';
import { accountState }                       from '@geenee/builder/src/core/state/account.state';
import { nftState }                           from '@geenee/builder/src/core/state/nft.state';
import { PUBLISHING_DOMAIN_URL, TOAST_ERROR } from '@geenee/builder/src/lib/constants';
import { ProjectModel }                       from '@geenee/builder/src/magellan/model/project.model';
import { CongratsNFT }                        from '../CongratsNFT';
import { ContentWrapper }                     from '../PostPublishingNFT/styles';
import { GradientWrapper }                    from './styles';

type Props = {
    close: () => void;
    // eslint-disable-next-line no-empty-pattern
    setModal: ({}) => void;
    descriptionData: {
        title: string | undefined;
        subtitle: string | undefined;
        category: number | undefined;
        description: string | undefined;
        price: string | undefined;
    };
    previewUrl: string;
    mintProjectId: string;
    projectUrl: string;
};

const validate = (value: string) => {
    if (isEmpty(value)) {
        return 'required';
    }
    if (value.length < 6) {
        return 'minLength';
    }
    return '';
};

export const ReenterNFTWalletPassword = observer((props: Props) => {
    const [ value, setValue ] = useState('');
    const [ error, setError ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);
    const { AppState: { activeProject } } = useInject();
    const { BuilderState } = useBuilderInject();

    const { address } = nftState.walletStore[ 0 ];
    const { publishing_domain } = accountState;

    const onGoBack = () => {
        props.setModal({
            component: DoubleCheckNFT,
            props:     { ...props }
        });
    };

    const onClickEnter = async () => {
        try {
            if (error) {
                return;
            }
            setIsLoading(true);
            const { projectUrl } = props;
            if (!projectUrl) {
                return;
            }
            if (address && activeProject) {
                const projectMintUrl = `https://${ publishing_domain }${ PUBLISHING_DOMAIN_URL }/${ activeProject.id }`;
                const qrExperienceWithTermsUrl = await generateQrWithTermsImage(projectMintUrl, 'experience');
                const { published_name } = await (activeProject as ProjectModel).getPublishData();
                console.log('Published with', { publishing_domain, published_name, url: projectMintUrl });
                const qrZippedUrl = await generateQrImage(projectMintUrl);
                const mintedProject = await nftState.mintProject({
                    ...props.descriptionData,
                    password:       value,
                    address,
                    price:          Number(props.descriptionData.price),
                    image:          qrZippedUrl,
                    preview_images: [
                        ...(props.descriptionData as any).preview_images,
                        qrExperienceWithTermsUrl
                    ]
                });
                if (activeProject) {
                    activeProject.nft_published = true;
                    await (activeProject as ProjectModel).saveData();
                }
                const { listing_id, url } = mintedProject;
                setIsLoading(false);
                props.setModal({
                    component: CongratsNFT,
                    props:     {
                        id:   listing_id,
                        link: url
                    }
                });
            }
        } catch (e: any) {
            console.error(e);
            if (e.response.status === 401) {
                runInAction(() => {
                    BuilderState.toast = {
                        severity: TOAST_ERROR,
                        detail:   'Password is incorrect'
                    };
                });
            } else {
                runInAction(() => {
                    BuilderState.toast = {
                        severity: TOAST_ERROR,
                        detail:   e.message
                    };
                });
            }
            onGoBack();
        }
    };

    return (
        <ContentWrapper>
            <Wrapper align="center">
                <Title size="xxl" align="center" margin="lg">
                    Secure it and go live.
                </Title>
                <Wrapper maxWidth="490px" align="center">
                    <Description size="sm" align="center" margin="md">
                        Enter your wallet password.
                    </Description>
                </Wrapper>
                <GradientWrapper maxWidth="378px" align="center" margin="xl">
                    <InputTable>
                        <FormItem
                            autoFocusDetect
                            error={
                                (error === 'required'
                                    && 'This field is required')
                                || (error === 'minLength'
                                    && 'Password should be at least 6 characters long')
                            }
                        >
                            <Input
                                disabled={ isLoading }
                                size="md"
                                fullWidth
                                name="password"
                                type="password"
                                value={ value }
                                placeholder="Password"
                                onChange={ (e) => {
                                    setValue(e.target.value);
                                    const validatedError = validate(
                                        e.target.value
                                    );
                                    setError(validatedError);
                                } }
                            />
                        </FormItem>
                    </InputTable>
                </GradientWrapper>
                <Wrapper maxWidth="378px" row fullWidth align="center" style={ { justifyContent: 'space-between' } }>
                    <BackButton
                        $padding="0 23px"
                        $justify="center"
                        $width="141px"
                        $height="61px"
                        onClick={ () => onGoBack() }
                        label="BACK"
                    />
                    <RaisedButton
                        disabled={ isLoading }
                        $gradient
                        $padding="0 23px"
                        $justify="space-between"
                        $width="141px"
                        $height="61px"
                        onClick={ () => onClickEnter() }
                        text="NEXT"
                        iconElement={ <NextArrow width="30" height="30" /> }
                    />
                </Wrapper>
            </Wrapper>
        </ContentWrapper>
    );
});
