import React, { useState } from 'react';
import {
    Description, FormItem, Input, InputTable, Title, Wrapper
}                          from '@geenee/ui';
import isEmpty             from 'lodash-es/isEmpty';
import NextArrow           from '@geenee/builder/src/asset/icons/NextArrow';
import RaisedButton        from '@geenee/builder/src/components/common/Button/WithIcon';
import { nftState }        from '@geenee/builder/src/core/state/nft.state';
import { ContentWrapper }  from '../PostPublishingNFT/styles';
import { SaveNFTPhrase }   from '../SaveNFTPhrase';
import { GradientWrapper } from './styles';

type Props = {
  // eslint-disable-next-line no-empty-pattern
  setModal: ({ }) => void;
  projectUrl: string;
};

const validate = (value: string, name: string, siblingValue: string) => {
    if (isEmpty(value)) {
        return 'required';
    }
    if (value.length < 6) {
        return 'minLength';
    }
    if (name === 'confirmPassword' && !isEmpty(value) && !isEmpty(siblingValue) && value !== siblingValue) {
        return 'notSame';
    }
    return '';
};

export function CreateNFTWallet(props: Props) {
    const [ value, setValue ] = useState('');
    const [ confirmValue, setConfirmValue ] = useState('');
    const [ error, setError ] = useState('');
    const [ confirmError, setConfirmError ] = useState('');

    const onClickCreate = async () => {
        const { mnemonic, address } = await nftState.createWallet(value, confirmValue);
        console.log({ mnemonic, address });
        props.setModal({
            component: SaveNFTPhrase,
            props:     { secretPhrase: mnemonic, id: address, projectUrl: props.projectUrl }
        });
    };

    return (
        <ContentWrapper>
            <Wrapper align='center'>
                <Title size='xxl' align='center' margin='lg'>
                    Sweet, let’s get encrypted.
                </Title>
                <Wrapper maxWidth='490px' align='center'>
                    <Description size='sm' align='center' margin='md'>
                        Please add a super secret wallet password.
                    </Description>
                </Wrapper>
                <GradientWrapper maxWidth='378px' align='center' margin='lg'>
                    <InputTable>
                        <FormItem
                            autoFocusDetect
                            error={
                                (error === 'required' && (
                                    'This field is required'
                                )) || (
                                    error === 'minLength' && (
                                        'Password should be at least 6 characters long'
                                    )
                                )
                            }
                        >
                            <Input
                                size="md"
                                fullWidth
                                name="password"
                                type='password'
                                value={ value }
                                placeholder='Password'
                                onChange={ (e) => {
                                    setValue(e.target.value);
                                    const validatedError = validate(e.target.value, e.target.name, confirmValue);
                                    setError(validatedError);
                                } }
                            />
                        </FormItem>
                        <FormItem
                            autoFocusDetect
                            error={
                                (confirmError === 'required' && (
                                    'This field is required'
                                )) || (
                                    confirmError === 'minLength' && (
                                        'Password should be at least 6 characters long'
                                    )
                                ) || (confirmError === 'notSame' && "Passwords doesn't match")
                            }
                        >
                            <Input
                                size="md"
                                fullWidth
                                name="confirmPassword"
                                type='password'
                                value={ confirmValue }
                                placeholder='Confirm Password'
                                onChange={ (e) => {
                                    setConfirmValue(e.target.value);
                                    const validatedError = validate(e.target.value, e.target.name, value);
                                    setConfirmError(validatedError);
                                } }
                            />
                        </FormItem>
                    </InputTable>
                </GradientWrapper>
                <Wrapper row fullWidth align='center'>
                    <RaisedButton
                        disabled={ isEmpty(value) || isEmpty(confirmValue) || value !== confirmValue }
                        $gradient
                        $padding="0 23px"
                        $justify="space-between"
                        $width="141px"
                        $height="61px"
                        onClick={ () => onClickCreate() }
                        text="NEXT"
                        iconElement={ (
                            <NextArrow width="30" height="30" />
                        ) }
                    />
                </Wrapper>
            </Wrapper>
        </ContentWrapper>
    );
}
