/* eslint-disable max-len */
import React from 'react';

export const NavBurger = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 1H1C0.4 1 0 1.4 0 2C0 2.6 0.4 3 1 3H15C15.6 3 16 2.6 16 2C16 1.4 15.6 1 15 1ZM15 7H1C0.4 7 0 7.4 0 8C0 8.6 0.4 9 1 9H15C15.6 9 16 8.6 16 8C16 7.4 15.6 7 15 7ZM1 13H15C15.6 13 16 13.4 16 14C16 14.6 15.6 15 15 15H1C0.4 15 0 14.6 0 14C0 13.4 0.4 13 1 13Z"
      fill="#303036"
    />
  </svg>
);
export const NavBurger2 = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 3C1 2.44772 1.44772 2 2 2H14C14.5523 2 15 2.44772 15 3C15 3.55228 14.5523 4 14 4H2C1.44772 4 1 3.55228 1 3ZM1 8C1 7.44772 1.44772 7 2 7H14C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9H2C1.44772 9 1 8.55228 1 8ZM2 12C1.44772 12 1 12.4477 1 13C1 13.5523 1.44772 14 2 14H14C14.5523 14 15 13.5523 15 13C15 12.4477 14.5523 12 14 12H2Z"
      fill="#303036"
    />
  </svg>
);

export const NavDots = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.72144 8C1.72144 7.17157 2.40793 6.5 3.23636 6.5C4.06479 6.5 4.75129 7.17157 4.75129 8C4.75129 8.82843 4.06479 9.5 3.23636 9.5C2.40793 9.5 1.72144 8.82843 1.72144 8ZM7.27616 8C7.27616 7.17157 7.96266 6.5 8.79109 6.5C9.61951 6.5 10.306 7.17157 10.306 8C10.306 8.82843 9.61951 9.5 8.79109 9.5C7.96266 9.5 7.27616 8.82843 7.27616 8ZM14.3458 6.5C13.5174 6.5 12.8309 7.17157 12.8309 8C12.8309 8.82843 13.5174 9.5 14.3458 9.5C15.1742 9.5 15.8607 8.82843 15.8607 8C15.8607 7.17157 15.1742 6.5 14.3458 6.5Z"
      fill="#303036"
    />
  </svg>
);
export const NavDotsVertical = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12C9 12.5523 8.55228 13 8 13ZM8 9C7.44772 9 7 8.55228 7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8C9 8.55228 8.55228 9 8 9ZM7 4C7 4.55229 7.44772 5 8 5C8.55228 5 9 4.55229 9 4C9 3.44771 8.55228 3 8 3C7.44772 3 7 3.44771 7 4Z"
      fill="#303036"
    />
  </svg>
);
export const Home = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      d="M12.2857 14C12.7143 14 13 13.7005 13 13.2512V6.5117C13 6.28705 12.9286 6.0624 12.7143 5.91264L8.42857 2.16849C8.14286 1.94384 7.78571 1.94384 7.5 2.16849L3.21429 5.91264C3.07143 6.0624 3 6.28705 3 6.5117V13.2512C3 13.7005 3.28571 14 3.71429 14H6.57143V10.2559H9.42857V14H12.2857Z"
      fill="#303036"
    />
  </svg>
);
