import { Dependencies, DI } from "../di";

type Class = { new(...args: any[]): any; };

// @Module decorator that takes MetaModule as argument and calls  di.loadApplicationModule();

export function DependencyModule(metaModule: MetaModule<Dependencies>) {
    return function (target: Class) {
        class Derived extends DI {
            constructor(...args: any[]) {
                super(...args);

                this.addApplicationDependencies(...args);
            }
        }

        class M extends target {
            di = new Derived(metaModule);
        }

        return M;
    };
}

export type AppModuleType = ReturnType<typeof DependencyModule>

// interface of the AppModule class that is decorated with Module decorator
