export type StripePlanParamsType = {
  ar_assets_per_experience: number
  asset_size_limit: number
  commercial_license: boolean
  content_templates: number
  custom_domains: number
  experience_size_limit: number
  experiences: number
  multi_ar_assets_per_scene: number
  nfts: number
  projects: number
  sdk: boolean
  seats: number
  views: number
}

export type StripePriceType = {
  id: string
  period: string
  price: number
  name: string
}

export type StripePlanType = {
  description: string,
  id: string,
  params: StripePlanParamsType
  prices: StripePriceType[]
  title: string
}

export type PlanUnionType = 'hobbyist' | 'premium' | 'teams' | 'enterprise'
export const stripePlanTitles: {[key in PlanUnionType]: string} = {
  'hobbyist': 'hobbyist',
  'premium': 'premium2.0',
  'teams': 'teams',
  'enterprise': 'enterprise'
}
