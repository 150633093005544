/**
 * Common constants
 */
export const AUTHORIZATION = 'authorization';
export const DELETE = 'DELETE';
export const NEW = 'new';
export const TRIGGER = 'Trigger';
export const ATTACHMENT = 'Attachment';
export const IMAGE = 'Image';
export const PROJECT_TYPES = [ 'program', 'experience' ];
export const TREE_NODE_TYPES = [ 'program', 'experience', 'content' ];

export const COVER_IMAGE_ID = 'cover_image_id';
export const BRANDING_IMAGE_ID = 'branding_image_id';

export const CONTENT_IMAGE_GALLERY_PROPERTY_NAME = 'image_attachment_ids';
export const CONTENT_AR_PROPERTY_NAME = 'ar_attachment_ids';
export const CONTENT_THUMBNAIL_PROPERTY_NAME = 'thumbnail_attachment_ids';
// eslint-disable-next-line max-len
export const VALID_EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const TOOLBAR_WIDGET_ITEMS = [
    'image',
    'video',
    'text',
    'button',
    'form',
    'spotify'
    // 'fandango'
];

// Content container width divided by height
export const CONTENT_CONTAINER_ASPECT_RATIO_C = 0.653;
export const DEBOUNCE_DELAY_TIME = 1000;
export const DEBOUNCE_DELAY_TIME_FOR_INPUT = 300;

export const WIDGET_TYPE_IMAGE = 'image';
export const WIDGET_TYPE_VIDEO = 'video';
export const WIDGET_TYPE_TEXT = 'text';
export const WIDGET_TYPE_BUTTON = 'button';
export const WIDGET_TYPE_FORM = 'form';
export const WIDGET_TYPE_SPOTIFY = 'spotify';
export const WIDGET_TYPE_FANDANGO = 'fandango';

export const NODE_TYPE_PROGRAM = 'program';
export const NODE_TYPE_EXPERIENCE = 'experience';
export const NODE_TYPE_CONTENT = 'content';
export const NODE_TYPE_TRIGGERS = 'triggers';
export const NODE_TYPE_DASHBOARD = 'dashboard';
export const NODE_TYPE_PROFILE = 'profile';
export const NODE_TYPE_COMPANY = 'company';
export const NODE_TYPE_PLANS = 'plans';

export const NODE_TYPE_IMAGE_GALLERY_ASSETs = 'image_gallery_assets';
export const NODE_TYPE_AR_ASSET = 'ar_asset';

export const TRIGGERS_SORT_NEWEST = 'Newest';
export const TRIGGERS_SORT_OLDEST = 'Oldest';
export const TRIGGERS_SORT_VIDEOS = 'Videos';
export const TRIGGERS_SORT_IMAGES = 'Images';
export const TRIGGERS_SORT_POPULAR = 'Popular';

export const CAMERA_FACE_MODE = 'user';
export const CAMERA_ENVIRONMENT_MODE = 'environment';
/**
 * Content types
 */
export const TYPE_DRAWER_OVERLAY = 'basic';
export const TYPE_DRAWER_SHARE = 'drawer_share';
export const TYPE_DRAWER_SIGNUP = 'drawer_signup';
export const TYPE_DRAWER_SPOTIFY = 'drawer_spotify';
export const TYPE_DRAWER_FANDANGO = 'drawer_fandango';
export const TYPE_FULLSCREEN_GALLERY = 'fullscreen-gallery';
export const TYPE_FULLSCREEN_VIDEO = 'fullscreen-video';
export const TYPE_IFRAME = 'iframe';
export const TYPE_SLAM_AR = 'slam-ar';
export const TYPE_NATIVE_AR = 'native-ar';
export const TYPE_STAMP_AR = 'stamp-ar';
export const TYPE_HEAD_TRACKING = 'head-tracking';
export const TYPE_BODY_TRACKING_OVERLAY = 'body-tracking-overlay';
export const TYPE_BODY_TRACKING_TWIN = 'body-tracking-twin';
export const TYPE_WYSIWYG = 'wysiwyg';
export const TYPE_SCENE_BUILD = 'scene-build';
export const BABYLON_SECTION_TYPES = [ TYPE_HEAD_TRACKING, TYPE_BODY_TRACKING_TWIN, TYPE_BODY_TRACKING_OVERLAY ];

export const PHONE_VIEW_PROGRAM = 'program';
export const PHONE_VIEW_EXPERIENCE = 'experience';
export const PHONE_VIEW_CONTENT = 'content';

/**
 * Phone view states
 */
export const PHONE_VIEW_PROGRAM_TYPE = 'program_type';
export const PHONE_VIEW_EXPERIENCE_TYPE = 'experience_type';
export const PHONE_VIEW_CONTENT_TYPE = 'content_type';

export const PHONE_VIEW_CONTENT_GALLERY = 'content_gallery';
export const PHONE_VIEW_TRIGGERS_GALLERY = 'triggers_gallery';

// Content related wizard states
export const WIZARD_VIEW_CONTENT_GALLERY = 'content_gallery';
export const WIZARD_VIEW_CONTENT_MAIN = 'content_settings';
export const WIZARD_VIEW_CONTENT_SET_AR_ASSET = 'content_set_ar_asset';
export const WIZARD_VIEW_CONTENT_SET_IMAGE_GALLERY_ASSET =    'content_set_image_gallery_asset';
export const NEW_CAMPAIGN_ID = 'new_campaign';
export const NEW_EXPERIENCE_ID = 'new_experience';
export const NEW_CONTENT_ID = 'new_content';
export const NEW_PROGRAM_ID = 'new_program';

/**
 * Drag and Drop item types
 */
export const DND_BLOCK = 'dnd_block';
export const DND_TOOLBAR_ITEM = 'dnd_toolbar_item';
export const REORDER_ITEM = 'reorder-item';

// APIRequest

export const APIREQUEST_HOSTNAME_MATCH_SLUG = '-cms.';
export const APIREQUEST_AUTH_SUFFIX = '-auth';

// Modal State
export const MODAL_EDIT_TRIGGER = 'MODAL_EDIT_TRIGGER';
export const MODAL_TAG_TRIGGER = 'MODAL_TAG_TRIGGER';
export const MODAL_AR_SCENE = 'MODAL_AR_SCENE';
export const MODAL_CONTENT_GALLERY = 'MODAL_CONTENT_GALLERY';
export const MODAL_UNSAVED_ALERT = 'MODAL_UNSAVED_ALERT';
export const MODAL_DELETE_WARNING = 'MODAL_DELETE_WARNING';
export const MODAL_SKETCHFAB_IMPORTER = 'MODAL_SKETCHFAB_IMPORTER';

// Drawer State
export const DRAWER_SHOW_TRIGGER_UPLOADS = 'DRAWER_SHOW_TRIGGER_UPLOADS';
export const DRAWER_SHOW_ATTACHMENT_UPLOADS = 'DRAWER_SHOW_ATTACHMENT_UPLOADS';

// Toast Severity
export const TOAST_ERROR = 'error';
export const TOAST_INFO = 'info';
export const TOAST_SUCCESS = 'success';

// Toast Message
export const PROJECT_DELETE_SUCCESS = 'Project deleted successfully';
export const PROJECT_CREATE_SUCCESS = 'Project created successfully';
export const CONTENT_DELETE_SUCCESS = 'Content deleted successfully';
export const CONTENT_CREATE_SUCCESS = 'Content created successfully';

export const ATTACHMENT_PROPERTYNAMES_UPLOAD_UPDATE_OK = [
    CONTENT_IMAGE_GALLERY_PROPERTY_NAME
];

// Slide Modal state
export const SLIDE_MODAL_SET = 'SLIDE_MODAL_SET';
export const SLIDE_MODAL_SET_WITH_PROPS = 'SLIDE_MODAL_SET_WITH_PROPS';
export const SLIDE_MODAL_HIDE = 'SLIDE_MODAL_HIDE';
export const SLIDE_MODAL_SET_ERROR = 'SLIDE_MODAL_SET_ERROR';

export const BROWSER_MODE =    typeof window !== 'undefined' && typeof document !== 'undefined';
export const IS_SSR = false;
export const IS_IOS = typeof window !== 'undefined'
    && (/safari/.test(window.navigator.userAgent.toLowerCase()) || /iphone|ipod|ipad/.test(window.navigator.userAgent.toLowerCase()))
    && !window.navigator.userAgent.match(/Chrome/i);
export const IS_ANDROID = typeof window !== 'undefined' && !!(/(android)/i.test(window.navigator.userAgent));
export const IS_IOS_SAFARI = window.navigator.userAgent.indexOf('Safari') !== -1 && window.navigator.userAgent.indexOf('Chrome') === -1;
export const IS_ANDROID_CHROME = typeof window !== 'undefined' && IS_ANDROID && !!window.navigator.userAgent.match(/Chrome/i);

// @TODO: Rethink the way of taking the secrets. Need to keep in mind the
// possibility to have several keys for different clients
export const REDBULL_CRYPTO_SECRET = 'geenee<3redbull';

export const BODY_TRACKING_OVERLAY_NODE_ID = 'Body Tracking Avatar';
export const BODY_TRACKING_TWIN_NODE_ID = 'Body Tracking Twin';
export const BODY_OCCLUDER_NODE_ID = 'Body Occluder';
export const HEAD_TRACKING_NODE_ID = 'Head Tracking';
export const HEAD_OCCLUDER_NODE_ID = 'Head Occluder';

export const GTTI_END_EVENT_NAME = 'geenee-time-to-interactive-end';
export const GTTI_START_EVENT_NAME = 'geenee-time-to-interactive-start';
export const GTTI_THRESHOLD_FIRED = 'geenee-time-to-interactive-threshold-fired';
export const CHECK_TIME_TO_INTERACTIVE_EVENT_NAME = 'geenee-check-if-interactive';

export const TIME_TO_INTERACTIVE_THRESHOLDS = {
    [ TYPE_SLAM_AR ]:               14000,
    [ TYPE_STAMP_AR ]:              12000,
    [ TYPE_BODY_TRACKING_OVERLAY ]: 32000,
    [ TYPE_BODY_TRACKING_TWIN ]:    40000,
    [ TYPE_HEAD_TRACKING ]:         20000,
    [ TYPE_NATIVE_AR ]:             11000,
    undefined:                      0
};
