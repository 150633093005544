import React from 'react';

// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '12',
    height = '12',
    onClick,
    id,
    fill = 'none',
    stroke = 'black'
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="6" cy="6" r="5.5" fill={ fill } stroke={ stroke } strokeWidth={ 0.5 } />
        </svg>
    );
}
