import React                                 from 'react';
import { MoleculeComponent }                 from "@geenee/geeclient-kit/src";
import { defaultOptions, MuteButtonOptType } from "@geenee/geeclient-kit/src/lib/default-options/molecule/MuteButton";
import { SceneRenderer }                     from '@geenee/shared/index';
import { observer }                          from 'mobx-react';

interface Props {
    sceneModel: SceneRenderer
}

export const MuteButton = observer(({ sceneModel } : Props) => sceneModel.audio && (
    <section
        id="mute-button"
        data-color-root="true"
        data-color-bg="black"
    >
        <MoleculeComponent<MuteButtonOptType>
            kitMolecule="MuteButton"
            moleculeSchema={ { options: { ...defaultOptions, link_padding: 'auto' } as MuteButtonOptType } }
            onClick={ () => sceneModel.toggleAudioMuted() }
            muted={ sceneModel.audioMuted }
        />
    </section>
));
