import React, { memo }                from 'react';
import { Icon, Wrapper as WrapperUi } from '@geenee/ui';
import { Wrapper }                    from './styles';

const LoadingSpinner = memo(({ size = 100 }: { size?: number }) => (
    <Wrapper>
        <Icon name="loaderCircle" size={ size } color="gradient-key" />
    </Wrapper>
));

interface Props {
    size?: number
    style?: React.CSSProperties
}

export function LoadingSpinnerCentered({ size, style = {} }: Props) {
    return (
        <WrapperUi fullWidth fullHeight valign="center" align="center" style={ style }>
            <LoadingSpinner size={ size } />
        </WrapperUi>
    );
}

// eslint-disable-next-line arca/no-default-export
export default LoadingSpinner;
