import Quaternion from '../math/Quaternion';
import Vector3 from '../math/Vector3';

/**
 *
 */
export default class PosePredictor {
  /**
   *
   * @param predictionTime
   */
  constructor(predictionTimeS) {
    this.predictionTimeS = predictionTimeS;
    this.previousQ = new Quaternion();
    this.deltaQ = new Quaternion();
    this.outQ = new Quaternion();
    this.previousTimestampS = null;
  }

  /**
   *
   * @param {Quaternion} currentQ
   * @param {Vector3} gyro
   * @param timestamp
   * @return {Quaternion}
   */
  getPrediction(currentQ, gyro, timestampS) {
    if (this.previousTimestampS === null) {
      this.previousQ.copy(currentQ);
      this.previousTimestampS = timestampS;
      return currentQ;
    }

    // Calculate axis and angle based on gyroscope rotation rate data.
    const axis = new Vector3();
    axis.copy(gyro);
    axis.normalize();

    const angularSpeed = gyro.length();

    // If we're rotating slowly, don't do prediction.
    if (angularSpeed < 20 * Math.PI / 180.0) {
      this.outQ.copy(currentQ);
      this.previousQ.copy(currentQ);
      return this.outQ;
    }

    // Get the predicted angle based on the time delta and latency.
    const deltaT = timestampS - this.previousTimestampS;
    const predictAngle = angularSpeed * this.predictionTimeS;

    this.deltaQ.setFromAxisAngle(axis, predictAngle);
    this.outQ.copy(this.previousQ);
    this.outQ.multiply(this.deltaQ);

    this.previousQ.copy(currentQ);
    this.previousTimestampS = timestampS;

    return this.outQ;
  }
}
