import React                  from 'react';
import { ViewViewComponent }  from "@geenee/geeclient-kit/src/lib/component/view/view-view.component";
import { DIContextType }      from "@geenee/geeclient-kit/src/lib/context/di.context";
import { withDIContext }      from "@geenee/geeclient-kit/src/lib/hoc/with-di-context";
import { withProjectContext } from "@geenee/geeclient-kit/src/lib/hoc/withProjectContext";
import { observer }           from "mobx-react";

type PropsType = {
  diContext: DIContextType
}

export const ViewComponent =  withProjectContext(withDIContext<PropsType>(observer((props) => {
    const { ViewWrapperComponent } = props.diContext;

    return (
        <ViewWrapperComponent { ...props }>
            <ViewViewComponent { ...props } />
        </ViewWrapperComponent>
    );
})));
