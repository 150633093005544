import React                          from 'react';
import { withMoleculeContext }        from '@geenee/geeclient-kit/src';
import { isArSceneType }              from '@geenee/geeclient-kit/src/lib/component/scene/util';
import { withSectionContext }         from '@geenee/geeclient-kit/src/lib/hoc/withSectionContext';
import { useInject }                  from '@geenee/shared';
// @ts-ignore
import  { observer }                  from 'mobx-react';
import { useBuilderInject }           from '@geenee/builder/src/core/hook/use-builder-inject';
import { SceneBuildSectionComponent } from './scene/scene-build-section.component';

export const SectionWrapperComponent = (
    withSectionContext(
        withMoleculeContext(
            observer(
                (props) => {
                    const { AppState } = useInject();

                    const { BuilderState } = useBuilderInject();
                    const { arSceneShown } = BuilderState;
                    const { activeSection, firstAvailableSection } =  AppState;
                    const sectionModel = props.sectionModel || activeSection || firstAvailableSection;
                    return (

                        <>
                            { sectionModel && (
                                <>
                                    { sectionModel.type === 'scene-build' && <SceneBuildSectionComponent /> }
                                    { sectionModel.type !== 'scene-build' && React.Children.map(props.children, (child) => {
                                        if (React.isValidElement(child)) {
                                        // @ts-ignore
                                            if (isArSceneType(sectionModel.type)) {
                                                return React.cloneElement(child, { ...child.props, ...{ disableScene: !arSceneShown, sectionModel } });
                                            }
                                            if (!isArSceneType(sectionModel.type)) {
                                                return React.cloneElement(child, { ...child.props, ...{ disableScene: !arSceneShown, sectionModel } });
                                            }
                                        }
                                    }) }
                                </>
                            ) }

                        </>

                    );
                }
            )
        )
    )
);
