import { Analytics }                              from '@geenee/analytics';
import { action, makeAutoObservable, observable } from 'mobx';
import {
    SDK_TOKEN_CREATED, SDK_TOKEN_DELETED, SDK_TOKEN_UPDATED
} from '@geenee/builder/src/lib/constants';
import { container } from '@geenee/builder/src/magellan/di/di';
import {
    createSDKAccessKey, deleteSDKAccessKey, getSDKAccessKeys, updateSDKAccessKey
} from '../../api/SDKAccessApi';
import { SDKTokenModel } from '../model/sdktoken.model';

export class SDKTokenState {
  @observable tokens: SDKTokenModel[] = [];
  @observable isLoaded = false;
  constructor() {
      makeAutoObservable(this);
  }

  @action
      addToken = (token: SDKTokenModel) => {
          const model = new SDKTokenModel();
          model.init(token);
          this.tokens.push(model);
      };

  @action
      createToken = async (origin: string) => {
          const { data: token } = await createSDKAccessKey({ origin });
          const { id, key, origin: responseOrigin } = token;

          const analytics: Analytics = container.get('<Analytics>');
          analytics.track(SDK_TOKEN_CREATED, { id, key, origin: responseOrigin });

          this.addToken(token);
          this.tokens = this.tokens.filter((item) => item.id);
      };

  @action
      deleteToken = async (id: string) => {
          await deleteSDKAccessKey(id);
          const token = this.tokens.find((t) => t.id === id);
          const { key, origin } = token!;

          const analytics: Analytics = container.get('<Analytics>');
          analytics.track(SDK_TOKEN_DELETED, { id, key, origin });

          this.tokens = this.tokens.filter((item) => item.id !== id);
      };

  @action
      updateToken = async (token: SDKTokenModel, origin: string = token.origin) => {
          const { data: result } = await updateSDKAccessKey(token.id, { origin });
          const { key, origin: responseOrigin, id } = result!;

          const analytics: Analytics = container.get('<Analytics>');
          analytics.track(SDK_TOKEN_UPDATED, { id, key, origin: responseOrigin });

          const index = this.tokens.findIndex((item) => item.id === token.id);
          if (index !== -1) {
              this.tokens.splice(index, 1, result);
          }
          this.tokens = this.tokens.filter((item) => item.id);
      };

  @action
      fetchTokenList = async () => {
          if (!this.isLoaded) {
              const { data: result } = await getSDKAccessKeys();
              result.data.forEach((el) => {
                  this.addToken(el);
              });
              this.tokens = this.tokens.filter((item) => item.origin);
              this.isLoaded = true;
          }
      };
}

export const sdkTokenState = new SDKTokenState();
