import React                                      from 'react';
import { Analytics }                              from '@geenee/analytics';
import { InputTableItem }                         from '@geenee/ui';
import { ColorPicker }                            from '@geenee/builder/src/components/ColorPicker/ColorPicker';
import { useBuilderInject }                       from '@geenee/builder/src/core/hook/use-builder-inject';
import { PROGRAM_INTRO_BACKGROUND_COLOR_CHANGED } from '@geenee/builder/src/lib/constants';
import { ProjectModel }                           from '@geenee/builder/src/magellan/model/project.model';

interface Props {
    background_color: string;
    disabled?: boolean;
    activeProject: ProjectModel
}

export function ProgramBackgroundColorRow({
    background_color,
    disabled,
    activeProject
}: Props) {
    const { container } = useBuilderInject();
    const analytics: Analytics = container.get('<Analytics>');

    const colorChangeHandler = (color) => {
        try {
            console.log('are we here?');
            activeProject.options.background_color = color;
            activeProject.saveData();
            const { id } = activeProject;
            analytics.track(PROGRAM_INTRO_BACKGROUND_COLOR_CHANGED, { id, color });
        } catch (e) {
            console.error('Error while updating intro background color');
        }
    };

    return (
        <InputTableItem label="Background Color">
            <div className="btn-container">
                <ColorPicker
                    disabled={ disabled }
                    color={ background_color || '#ffffffff' }
                    onColorChange={ colorChangeHandler }
                />
            </div>
        </InputTableItem>
    );
}
