export const animValues = [
    {
        value: '',
        label: 'All'
    },
    {
        value: '-',
        label: 'No Animation'
    },
    {
        value: '+',
        label: 'Animated'
    }
];
