import React  from 'react';
import { cn } from '@geenee/ui';
import './mobile.styles.scss';

const className = cn('mobile');

export function PhoneButtons() {
    return (
        <>
            <span className={ className('btn btn-first') } />
            <span className={ className('btn btn-second') } />
            <span className={ className('btn btn-third') } />
            <span className={ className('btn btn-right') } />
        </>
    );
}
