import React                                   from 'react';
import { useTheme }                            from 'styled-components';
import CheckboxChecked                         from '@geenee/builder/src/asset/icons/CheckboxChecked';
import Library                                 from '@geenee/builder/src/asset/icons/Library';
import Hint                                    from '@geenee/builder/src/components/common/Hint';
import { UnderlinedTableRow, WithHintWrapper } from '@geenee/builder/src/pages/AllPlansPage/styles';

export function TeamAssetLibrary() {
    const { secondaryColors } = useTheme();
    return (
        <UnderlinedTableRow>
            <td>
                <WithHintWrapper>
                    <span>Team Asset Library</span>
                    { ' ' }
                    <Hint
                        title='Custom Asset Libraries'
                        text="Save and share collections of visual assets
                        that can be accessed & utilized by your team for easy, real-time collaboration."
                        iconElement={ <Library /> }
                    />
                </WithHintWrapper>

            </td>
            <td className='basic-plan' />
            <td className='plan-filler' />
            <td className='studio-plan'>
                { ' ' }
                <CheckboxChecked
                    beIcon
                    fill={ secondaryColors.green70 }
                />
            </td>
            <td className='plan-filler' />
            <td className='pro-plan'>
                { ' ' }
                <CheckboxChecked
                    beIcon
                    fill={ secondaryColors.green70 }
                />
            </td>
        </UnderlinedTableRow>
    );
}
