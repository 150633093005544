import React                    from 'react';
import { SectionViewComponent } from "@geenee/geeclient-kit/src/lib/component/section/section-view.component";
import { DIContextType }        from '@geenee/geeclient-kit/src/lib/context/di.context';
import { SectionContext }       from "@geenee/geeclient-kit/src/lib/context/section-context";
import { withDIContext }        from "@geenee/geeclient-kit/src/lib/hoc/with-di-context";
import { withSectionContext }   from "@geenee/geeclient-kit/src/lib/hoc/withSectionContext";
import { compose }              from "@geenee/geeclient-kit/src/lib/util/compose";
import { SectionModel }         from '@geenee/shared/index';
import { observer }             from "mobx-react";

type PropsType = {
  diContext: DIContextType;
  shouldNotRenderInteractiveScene?: boolean;
  sectionModel: SectionModel
};

function _SectionComponent(props: PropsType) {
    const { SectionWrapperComponent } = props.diContext;

    return (
        <SectionContext.Provider value={ props.sectionModel }>
            <SectionWrapperComponent { ...props }>
                <SectionViewComponent { ...props } />
            </SectionWrapperComponent>
        </SectionContext.Provider>
    );
}

const enhance = compose(
    withSectionContext,
    withDIContext,
    observer
);

export const SectionComponent = enhance(_SectionComponent);
