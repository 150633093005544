import React, { useMemo }                            from "react";
import { BaseEntityModel }                           from '@geenee/shared/src/magellan/model/base-entity.model';
import { AnimatedWrapper, Modal, useModal, Wrapper } from '@geenee/ui';
import { runInAction }                               from "mobx";
import { CodeEditor }                                from '~/module/custom-code/component/editor.component';

export const useAssetCustomCodeModal = (entityModel?: BaseEntityModel) => {
    if (!entityModel) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return [ () => {}, () => {} ];
    }

    const [ layoutShowModal, layoutHideModal ] = useModal((transformGroupProps: JSX.IntrinsicAttributes) => (

        <Modal
            className='global-modal'
            onHideModal={ () => {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                hideModal();
            } }

            // eslint-disable-next-line react/jsx-props-no-multi-spaces
            { ...transformGroupProps }
        >
            <AnimatedWrapper manual={ false } fullWidth>
                <Wrapper fullWidth>
                    <Wrapper flex={ 2 }>
                        <CodeEditor
                            // eslint-disable-next-line @typescript-eslint/no-use-before-define
                            onClose={ onClose }
                            value={ entityModel?.custom_code?.code || '' }
                            onUpdate={ (val) => {
                                if (entityModel) {
                                    // @ts-ignore
                                    entityModel.updateCustomCode({ custom_code: { code: val } });
                                    // @ts-ignore
                                    entityModel?.saveData();
                                }
                            } }
                        />
                    </Wrapper>
                </Wrapper>

            </AnimatedWrapper>
        </Modal>
    ), [ entityModel ]);

    const onClose = () => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        hideModal();
        runInAction(() => {
            if (entityModel?.custom_code_opened) {
                entityModel.custom_code_opened = false;
            }
        });
    };
    const closeHandler = useMemo(() => (e: KeyboardEvent) => {
        if (e.keyCode === 27) {
            onClose();
            e.preventDefault();
            e.stopPropagation();
        }
    }, []);

    const showModal = () => {
        layoutShowModal();
        window.addEventListener('keydown', closeHandler, true);
    };

    const hideModal = () => {
        layoutHideModal();
        window.removeEventListener('keydown', closeHandler, true);
    };

    return [ showModal, hideModal ];
};
