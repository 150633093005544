import React, { ChangeEvent }                      from 'react';
import { cn, InputTable, InputTableItem, Wrapper } from '@geenee/ui';
import { BackgroundColorRow, ImageRow }            from '@geenee/builder/src/components/ProjectsLinkDropdown/components';
import { FgColorsPicker }                          from '@geenee/builder/src/components/ProjectsLinkDropdown/components/FlowCode/components/FgColorsPicker';
import {
    FlowCodeParams,
    lastOptsData
} from '@geenee/builder/src/components/ProjectsLinkDropdown/components/FlowCode/components/FlowCodeHelper';
import { FlowCodeTemplates } from '@geenee/builder/src/components/ProjectsLinkDropdown/components/FlowCode/components/FlowCodeTemplates';
import { QrTextInputs }      from '@geenee/builder/src/components/ProjectsLinkDropdown/components/FlowCode/components/QrTextInputs';

const className = cn('flow-code');

interface Props {
  codeState: FlowCodeParams
  url: string
  popupRef: React.Ref<HTMLDivElement>
  onChange: (data: {[key: string]: any}) => void
  updateMainColor: (val: string) => void
  publish?: boolean
  onFileSelect: (e: ChangeEvent<HTMLInputElement>) => void;
  mainColor: string
}
export function QrSettingsPopup({
    codeState, url, popupRef, onChange, updateMainColor, publish, onFileSelect, mainColor
}: Props) {
    return (
        <Wrapper
            style={ { width: 'fit-content' } }
            frame="solid-white"
            padding="sm"
            radius="md"
            fullWidth={ false }
            maxWidth="300px"
            className={ className('code-settings') }
            innerRef={ popupRef }
        >
            <InputTable>
                <BackgroundColorRow
                    bgColor={ codeState.opts.gridBackgroundColor }
                    onColorChange={ (val: string) => onChange({ gridBackgroundColor: val, containerBackgroundColor: val }) }
                />
                <FgColorsPicker
                    initColorsList={
                        lastOptsData[ url ]?.gridModuleColor
                            ? lastOptsData[ url ]?.gridModuleColor.split(',')
                            : undefined
                    }
                    updateMainColor={ updateMainColor }
                    onChange={ (colorsList) => {
                        const colorsSettings = {};
                        [ 'gridModuleColor', 'containerOuterPatternColor' ].forEach((field) => {
                            colorsSettings[ field ] = colorsList.join(',');
                        });
                        onChange(colorsSettings);
                    } }
                />
                <InputTableItem label="Logo icon" viewType="secondary">
                    <ImageRow
                        withoutLabel
                        qrImageUrl={ codeState.opts.logoImageFile }
                        onFileSelect={ onFileSelect }
                        onDelete={ () => {
                            onChange({ logoImageFile: undefined });
                        } }
                    />
                </InputTableItem>
                <FlowCodeTemplates onSelect={ onChange } publish={ publish } color={ mainColor } />
                <QrTextInputs
                    initTextData={ lastOptsData[ url ]?.textData }
                    codeState={ codeState }
                    onUpdateSettings={ (settings, textData) => {
                        onChange(settings);
                        lastOptsData[ url ] = { ...(lastOptsData[ url ] || {}), textData };
                    } }
                    color={ mainColor }
                />
            </InputTable>
        </Wrapper>
    );
}
