import Quaternion from '../math/Quaternion';
import FusionPoseSensor from './FusionPoseSensor';
import BasePoseSensor from './BasePoseSensor';
import IPoseSensor from './IPoseSensor';
import { BROWSER_MODE } from '../constants';

/**
 *
 */
export default class DMPoseSensor extends BasePoseSensor implements IPoseSensor {
  private fusionSensor: FusionPoseSensor;

  constructor() {
    super();

    this.fusionSensor = new FusionPoseSensor(0.98, 0.04, false, this.isDeviceMotionInRadians);
  }

  //-----------------------------------------------------------------
  //
  //  Public methods
  //
  //-----------------------------------------------------------------

  public getOrientation(): Quaternion {
    if (!this.started) {
      return new Quaternion();
    }
    return new Quaternion().fromArray(this.fusionSensor.getOrientation());
  }

  /**
   *
   */
  public start(): Promise<void> {
    return new Promise(resolve => {
      if (this.started) {
        resolve();
        return;
      }

      this.started = true;
      this.addEventListeners();
      resolve();
    });
  }

  /**
   *
   */
  stop() {
    this.started = false;
    this.fusionSensor.stop();
    this.removeEventListeners();
  }

  //-----------------------------------------------------------------
  //
  //  Private methods
  //
  //-----------------------------------------------------------------

  addEventListeners() {
    if (BROWSER_MODE) {
      window.addEventListener('devicemotion', (e) => this.onDeviceMotion(e));
      window.addEventListener('orientationchange', this.onOrientationChange);
    }
  }

  removeEventListeners() {
    if (BROWSER_MODE) {
      window.removeEventListener('devicemotion', this.onDeviceMotion);
      window.removeEventListener('orientationchange', this.onOrientationChange);
    }
  }

  //-----------------------------------------------------------------
  //
  //  Event handlers
  //
  //-----------------------------------------------------------------

  onOrientationChange = (e: Event) => {
    this.fusionSensor.setScreenTransform();
  }

  onDeviceMotion(e: DeviceMotionEvent) {
    super.onDeviceMotion(e);
    this.fusionSensor.updateDeviceMotion(e);
  }
}
