import { Bone }                         from "@babylonjs/core/Bones/bone";
import { Light }                        from "@babylonjs/core/Lights/light";
import { AbstractMesh }                 from "@babylonjs/core/Meshes/abstractMesh";
import { TransformNode }                from "@babylonjs/core/Meshes/transformNode";
import type { Node }                    from "@babylonjs/core/node.js";
import { BabylonRenderer }              from "@geenee/geespector/renderer/babylonjs.renderer";
import { getTexturesForMesh }           from "@geenee/geespector/renderer/utils";
import GlobalState                      from "@geenee/geespector/src/components/globalState";
import { PropertyChangedEvent }         from "@geenee/geespector/src/components/propertyChangedEvent";
import { AbstractCommand, CommandType } from '@geenee/shared/src/commander/types';
import { isArray }                      from "lodash-es";

export class DuplicateCommand extends AbstractCommand<CommandType> {
    receiver: BabylonRenderer;
    entity: AbstractMesh | TransformNode | Light;
    metadata: any;

    constructor(receiver: BabylonRenderer, entity: AbstractMesh | TransformNode | Light) {
        super();
        this.receiver = receiver;
        this.entity = entity;
    }

    setMeshMetadata = (mesh: AbstractMesh) => {
        this.receiver.setNodeToMap(mesh, 'meshes');
        mesh.material && this.receiver.setNodeToMap(mesh.material, 'materials');
        const textures = getTexturesForMesh(mesh);
        textures.forEach((texture) => {
            this.receiver.setNodeToMap(texture, 'textures');
        });
    };
    setMetadataToChild = (item: TransformNode | AbstractMesh | Light | Node) => {
        if (item instanceof AbstractMesh) {
            this.setMeshMetadata(item);
        } else if (item instanceof Light) {
            this.receiver.setNodeToMap(item, 'lights');
        } else if (item instanceof TransformNode) {
            this.receiver.setNodeToMap(item, 'transformNodes');
        }
        this.setMetadataToDuplicate(item);
    };

    setMetadataToDuplicate = (clone: TransformNode | TransformNode[] | Node) => {
        if (isArray(clone)) {
            clone.forEach((item) => {
                this.setMetadataToDuplicate(item);
            });
        } else {
            clone.getChildren().forEach((item) => {
                this.setMetadataToChild(item);
            });
        }
    };

    findUniqueName = (searchName: string) => {
        let index = 0;
        let name = `${ searchName }_${ index }`;
        while (this.receiver.getNodeByName(name)) {
            index += 1;
            name = `${ searchName }_${ index }`;
        }
        return name;
    };

    getTransformNodesMap = (entity: TransformNode, clone: TransformNode) => {
        const className = entity.getClassName();
        if ([ 'Mesh', 'TransformNode' ].includes(className)) {
            const entityNodes = entity.getChildTransformNodes();
            const cloneNodes = clone.getChildTransformNodes();
            if (entityNodes.length === cloneNodes.length) {
                return entityNodes.reduce((acc, curr, index) => {
                    acc.set(curr, cloneNodes[ index ]);
                    return acc;
                }, new Map());
            }
        }
        return new Map();
    };

    execute = () => {
        const name = this.findUniqueName(this.entity.name);
        // @ts-ignore
        const isRootNode = this.receiver.scene?.rootNodes.includes(this.entity);
        const isTrackableParent = this.receiver.getTrackingNode() === this.entity;
        const clone = this.entity.clone(name, this.entity.parent);

        if (clone) {
            const transformMap = this.getTransformNodesMap(this.entity as TransformNode, clone as TransformNode);
            const cloneAsMesh = clone as AbstractMesh;
            if (cloneAsMesh.material) {
                cloneAsMesh.material = cloneAsMesh.material?.clone(`${ cloneAsMesh.material?.name }`) || null;
            }
            clone.getChildMeshes().forEach((mesh, i) => {
                mesh.material = mesh.material?.clone(`${ mesh.material?.name }`) || null;
                // Cloning Skeleton
                if (mesh.skeleton && (isRootNode || isTrackableParent)) {
                    mesh.skeleton = mesh.skeleton.clone(mesh.skeleton.name);
                    mesh.skeleton.bones.forEach((bone: Bone) => {
                        const transformNode = bone.getTransformNode();
                        const clonedNode = transformMap.get(transformNode);
                        if (transformNode && clonedNode) {
                            bone.linkTransformNode(clonedNode);
                        }
                    });
                }
            });
            this.setMetadataToChild(clone);

            this.receiver.addMesh(clone);
            if (!this.metadata) {
                this.metadata = clone?.metadata;
            } else {
                clone.metadata = this.metadata;
            }
            this.clone = clone;
            const e = new PropertyChangedEvent();
            e.object = clone;
            GlobalState.onPropertyChangedObservable.notifyObservers({ ...e, skipCommandCreation: true });
        }
    };

    revert = () => {
        if (this.metadata) {
            const node = this.receiver.getNodeByMetaData(this.metadata);
            node && this.receiver.deleteMesh(node);
        }
    };
}
