import findKey                     from 'lodash-es/findKey';
import { Payment as PaymentModal } from '@geenee/builder/src/components/modals/Payment';
import { stripeState }             from '@geenee/builder/src/core/state/stripe.state';

export const useHandlers = (currentInterval: 'year' | 'month', setModalOptions: (v: any) => void) => {
    const showPaymentModal = (priceId?: string) => {
        const properPlanForInterval = findKey(stripeState.prices, { name: 'Premium', period: 'month' });
        setModalOptions({
            component: PaymentModal,
            props:     {
                priceId: priceId || properPlanForInterval,
                type:    'PAYMENT'
            }
        });
    };
    return { showPaymentModal };
};
