import React                                      from 'react';
import { DIContextType }                          from '@geenee/geeclient-kit/src/lib/context/di.context';
import { CallbackFunctionVariadic }               from "@geenee/geeclient-kit/src/lib/type/type";
import { MoleculeModel }                          from '@geenee/shared';
import { SceneAtomComponent }                     from '@geenee/shared/src/magellan/atom/component/scene-atom/scene-atom.component';
import { withDIContext }                          from '@geenee/shared/src/magellan/hoc/withDIContext.hoc';
import { sceneMoleculeToGltfTemplatePropsBridge } from '@geenee/shared/src/magellan/molecule/component/SceneMolecule/util';
import { SceneModelType }                         from '@geenee/shared/src/magellan/renderer/r3f-renderer/r3f.renderer';
import { observer }                               from 'mobx-react';
import { DefaultLoadingManager }                  from 'three/src/loaders/LoadingManager';
import { AttachmentModel }                        from '../../../model/attachment.model';
import { AppStateType }                           from '../../../state/app.state';
import { GLTFComponentPropsType }                 from './GLTF_TEMPLATE';

type PropsType = {
    moleculeModel: MoleculeModel;
    isCurrentlyActive: boolean
    url?: string,
    activeSceneModel: SceneModelType;
    appState: AppStateType;
    arModel: any;
    onLoad: CallbackFunctionVariadic;
    setIsModelLoaded?: (value: boolean) => void
    attachmentModel: AttachmentModel;
    diContext: DIContextType
    isActive?: boolean
} & GLTFComponentPropsType;

export const SceneMoleculeComponent = withDIContext<PropsType>(observer(({ moleculeModel, diContext, isActive }) => {
    const atomModels = Array.from(moleculeModel.sceneActorAtoms);
    const legacyProps = sceneMoleculeToGltfTemplatePropsBridge(moleculeModel);
    const { SceneMoleculeComponent: SCENE_MOLECULE_COMPONENT_DI } = diContext;

    if (!atomModels.length && isActive) {
        DefaultLoadingManager.onLoad && DefaultLoadingManager.onLoad();
        legacyProps.activeSceneModel.setLoaded(true);
    }

    return (
        <>
            { moleculeModel && (
                <SCENE_MOLECULE_COMPONENT_DI
                    { ...legacyProps }
                    isActive={ isActive }
                    atoms={ atomModels.map((atomModel) => (
                        <SceneAtomComponent atomModel={ atomModel } key={ atomModel.id } />
                    )) }
                />
            ) }
        </>
    );
}));
