import React            from 'react';
import { render }       from 'react-dom';
import html2canvas      from 'html2canvas';
import QRCode           from 'qrcode.react';
import { v4 as uuidv4 } from 'uuid';
// @ts-ignore
import logo             from '@geenee/builder/src/asset/images/qr-geenee-logo.png';
import { nftState }     from '@geenee/builder/src/core/state/nft.state';
import { QR_CODE_SIZE } from '@geenee/builder/src/lib/constants';
import {
    LogoWrapper, QRContainer, Root, Subtitle, Title, ZippedQrWrapper
} from './styles';

export function QRImage({ url }: {url: string}) {
    return (
        <>
            <QRCode
                value={ url }
                size={ QR_CODE_SIZE }
                bgColor="transparent"
                fgColor="black"
                level="H"
                includeMargin={ false }
                renderAs="canvas"
            />
            <LogoWrapper>
                { /* eslint-disable-next-line jsx-a11y/alt-text */ }
                <img src={ logo } width="131px" height="34px" />
            </LogoWrapper>
        </>
    );
}

export function NftWithTermsImage({ url, mode = 'experience' }: {url: string; mode?: 'zip' | 'experience'}) {
    return (
        <Root>
            <Title>What You’ll Own</Title>
            <Subtitle>
                {
                    mode === 'experience' ? (
                        <>
                            Once purchased, you will own this artist’s Augmented Reality NFT source files,
                            { ' ' }
                            located in the image source link below, as well as a year of the NFT hosted at Geenee.ar.
                            <br />
                            <br />
                            Use the QR Code to experience and share the AR NFT.
                        </>
                    ) : (
                        <>
                            Source files
                            <br />
                            <br />
                            Use the QR code to download an archive with the experience files, which you can host on your service
                        </>
                    )
                }
            </Subtitle>
            <QRContainer>
                <QRImage url={ url } />
            </QRContainer>
        </Root>
    );
}

export const generateQrWithTermsImage = async (url: string, mode: 'zip' | 'experience' = 'experience') => {
    const element = document.createElement('div');
    render(<NftWithTermsImage url={ url } mode={ mode } />, element);
    const [ child ] = element.children;
    document.body.appendChild(child);

    const canvas = await html2canvas(child as any, { backgroundColor: null, scale: 2 });
    document.body.removeChild(child);
    const imgSrc = canvas.toDataURL();
    const blob = await fetch(imgSrc)
        .then((response) => response.blob());
    const thumbnail = new File([ blob ], `${ uuidv4() }.png`, { type: 'image/png' });
    return nftState.uploadQrCode(thumbnail);
};

export const generateQrImage = async (url: string) => {
    const element = document.createElement('div');
    render(<ZippedQrWrapper><QRImage url={ url } /></ZippedQrWrapper>, element);
    const [ child ] = element.children;
    document.body.appendChild(child);

    const canvas = await html2canvas(child as any, { backgroundColor: null, scale: 2 });
    document.body.removeChild(child);
    const imgSrc = canvas.toDataURL();
    const blob = await fetch(imgSrc)
        .then((response) => response.blob());
    const thumbnail = new File([ blob ], `${ uuidv4() }.png`, { type: 'image/png' });
    return nftState.uploadQrCode(thumbnail);
};
