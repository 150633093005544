import React, { memo, useMemo, useRef } from 'react';
import { useDrag, useDrop }             from "react-dnd";
import { LoadingSpinnerCentered }       from '@geenee/shared/src/magellan/components/LoadingSpinner';
import {
    Card, cn, Description, Divider,
    InputTable, InputTableItem, MediaContent, Wrapper
} from '@geenee/ui';
import { SizeType }     from '@geenee/ui/src/lib/util/global-props';
import { REORDER_ITEM } from "@geenee/builder/src/lib/constants";
import './styles.scss';

type Props = {
  descriptionData: { [key: string]: string | number }
  onClick: () => void
  url: string
  margin?: SizeType
  // Drag props
  moveItem?: (dragIndex: number, hoverIndex: number) => void
  updateListItemsOrder?: (index: number, id: string) => void
  id?: string,
  index?: number
  order?: number

  actions?: React.ReactNode
  mediaClassName?: string
}

type DropItem = {
  type: string;
  id: string;
  index: number;
}

const className = cn('asset-card');

export const AssetCard = memo((props: Props) => {
    const imageRef = useRef<HTMLDivElement | null>(null);
    const [ {}, drag ] = useDrag({
        item: {
            type:  REORDER_ITEM,
            id:    props.id,
            order: props.order,
            index: props.index
        },
        canDrag: true,
        collect: (monitor) => ({ isDragging: monitor.isDragging() })
    });

    const [ {}, drop ] = useDrop({
        accept: [ REORDER_ITEM ],
        drop:   () => {
            props.updateListItemsOrder && props.updateListItemsOrder(props.index || 0, props.id || '');
        },
        hover: (item: DropItem) => {
            if (!imageRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = props.index || 0;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            props.moveItem && props.moveItem(dragIndex, hoverIndex);
            // eslint-disable-next-line no-param-reassign
            item.index = hoverIndex;
        },
        collect: (monitor) => ({ isOver: monitor.isOver() })
    });

    drag(drop(imageRef));

    const media = useMemo(() => (
        <Wrapper className={ props.mediaClassName }>
            { props.actions }
            <MediaContent
                className={ className('media') }
                fallback={ <LoadingSpinnerCentered /> }
                url={ props.url }
                fit="cover"
                type="image"
                ratio="ratio-2-1"
                size="xl"
                radius="xxl"
                align="center"
            />
        </Wrapper>
    ), [ props.url, props.actions, props.mediaClassName ]);
    return (
        <Card
            styles={ { height: 'fit-content' } }
            innerRef={ imageRef }
            fullHeight
            hoverShadow="self"
            maxWidth="192px"
            margin={ props.margin }
            mediaBefore
            frame="solid-default"
            onClick={ props.onClick }
            media={ media }
        >
            <Wrapper className={ className('card-wrapper') } frame='solid-white' radius="xxl" fullWidth padding="xs">
                <Divider transparent margin="xs" />
                <InputTable>
                    { Object.keys(props.descriptionData).map((item) => (
                        <InputTableItem
                            ratio={ 0.8 }
                            size="sm"
                            label={ item }
                            key={ item }
                            labelDescriptionProps={ { noWrap: true } }
                            viewType="shade-4"
                        >
                            <Description style={ { width: '96px' } } shorten noWrap color="shade-2" size="sm">
                                { props.descriptionData[ item ] }
                            </Description>
                        </InputTableItem>
                    )) }
                </InputTable>
                <Divider transparent margin="xs" />
            </Wrapper>
        </Card>
    );
});
