import { ElementType }       from 'react';
import { Composer }          from '@geenee/builder/src/components/Composer/Composer';
import { DashboardNFT }      from '@geenee/builder/src/components/DashboardNFT';
import { HomePage }          from "@geenee/builder/src/components/HomePage";
import SpotifyCallback       from '@geenee/builder/src/components/Spotify/SpotifyCallback';
import { DIComponentNames }  from '@geenee/builder/src/core/context/di.get-component';
import withAuthorization     from '@geenee/builder/src/lib/withAuthorization';
import { MainPage }          from '@geenee/builder/src/pages';
import { AllPlansPage }      from '@geenee/builder/src/pages/AllPlansPage';
import { AllPlansPageOld }   from '@geenee/builder/src/pages/AllPlansPage/index-old';
import { AnalyticsPage }     from '@geenee/builder/src/pages/Analytics';
import { AuthRedirectPage }  from '@geenee/builder/src/pages/AuthRedirectPage';
import { CompanyPage }       from '@geenee/builder/src/pages/CompanyPage';
import { ContactUs }         from '@geenee/builder/src/pages/ContactForm';
import { MatomoAnalytics }   from '@geenee/builder/src/pages/MatomoAnalytics';
import { MemberPage }        from '@geenee/builder/src/pages/MemberPage';
import { ForgotPage }        from '@geenee/builder/src/pages/NonAuth/ForgotPage/ForgotPage';
import { InvitePage }        from '@geenee/builder/src/pages/NonAuth/InvitePage/InvitePage';
import { PasswordPage }      from '@geenee/builder/src/pages/NonAuth/PasswordPage/PasswordPage';
import { SignUpCompanyInfo } from '@geenee/builder/src/pages/NonAuth/SignUpCompanyInfo';
import { ProfilePage }       from '@geenee/builder/src/pages/ProfilePage/ProfilePage';
import { SketchfabAuthPage } from '@geenee/builder/src/pages/SketchfabAuthPage';
import { TeamPage }          from '@geenee/builder/src/pages/TeamPage';
import { YourPlanPage }      from '@geenee/builder/src/pages/YourPlanPage';
import { SDKAccessPage }     from './SDKAccessPage';

// NOTE - All routes for app should be here

type Route = {
  path: string;
  exact?: boolean;
  // eslint-disable-next-line no-use-before-define
  routes?: Routes;
  render: ElementType;
};

export const periodEndAvailableRoutes = [
    {
        path:   '/getting-started',
        exact:  true,
        render: HomePage
    },
    {
        path:   '/your-plan',
        exact:  true,
        render: YourPlanPage
    },
    {
        path:   '/all-plans',
        exact:  true,
        render: AllPlansPage
    },
    {
        path:   '/all-plans-old',
        exact:  true,
        render: AllPlansPageOld
    },
    {
        path:   '/contact',
        exact:  true,
        render: ContactUs
    },
    {
        path:   '/profile',
        exact:  true,
        render: ProfilePage
    },
    {
        path:   '/nft-dashboard',
        exact:  true,
        render: DashboardNFT
    },
    {
        path:   '/analytics-google',
        exact:  true,
        render: AnalyticsPage
    },
    {
        path:   '/analytics-matomo',
        exact:  true,
        render: MatomoAnalytics
    },
    {
        path:   '/company',
        exact:  true,
        render: CompanyPage
    },
    {
        path:   '/sdk',
        exact:  true,
        render: SDKAccessPage
    },
    {
        path:   '/:projectId/:experienceId?/:viewId?/:sectionId?/:moleculeId?',
        render: Composer
    }
];

const getAvailableMainRoutes = (isPeriodLeft?: boolean) => {
    const res = periodEndAvailableRoutes.slice();

    if (!isPeriodLeft) {
        return [
            {
                path:   '/team',
                exact:  true,
                render: TeamPage
            },
            {
                path:   '/team/:email?',
                exact:  true,
                render: MemberPage
            },
            ...res
        ];
    }
    return res;
};

type Routes = Route[];
// FIXME - turn withAuthorization(component) on when there will be working backend
export const getRoutes = (getDIPage: (value: DIComponentNames) => any, isPeriodLeft?: boolean): Routes => [
    {
        path:   '/login',
        exact:  true,
        render: getDIPage(DIComponentNames.LoginPage)
    },
    {
        path:   '/spotify',
        exact:  true,
        render: SpotifyCallback
    },
    {
        path:   '/sign-up',
        exact:  true,
        render: getDIPage(DIComponentNames.SignUpPage)
    },
    {
        path:   '/post-sign-up',
        exact:  true,
        render: SignUpCompanyInfo
    },
    {
        path:   '/forgot',
        exact:  true,
        render: ForgotPage
    },
    {
        path:   '/reset-password',
        exact:  true,
        render: PasswordPage
    },
    {
        path:   '/invite/:token',
        exact:  true,
        render: InvitePage
    },
    {
        path:   '/oauth',
        exact:  true,
        render: AuthRedirectPage
    },
    {
        path:   '/auth/sketchfab/callback',
        exact:  true,
        render: SketchfabAuthPage
    },
    {
        path:   '*',
        render: withAuthorization(MainPage),
        routes: getAvailableMainRoutes(isPeriodLeft)
    }
];

export const prohibitedRedirectsByOwner = [
    {
        from: '/your-plan',
        to:   '/getting-started'
    },
    {
        from: '/all-plans',
        to:   '/getting-started'
    },
    {
        from: '/team',
        to:   '/getting-started'
    },
    {
        from: '/company',
        to:   '/getting-started'
    }
];
