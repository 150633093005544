import React                                    from 'react';
import { Description, InputTableItem, Wrapper } from '@geenee/ui';
import { observer }                             from 'mobx-react';
import { stripeState }                          from '@geenee/builder/src/core/state/stripe.state';

export const BillingCycle = observer(() => (
    <InputTableItem
        size="lg"
        label="Billing cycle"
        viewType="secondary"
        weight="medium"
    >
        <Wrapper row valign='center'>
            <Wrapper valign="center" frame="solid-white" radius="xl" margin="xs" style={ { height: '40px' } }>
                <Description style={ { paddingLeft: 13 } } capitalize>
                    { stripeState.plan_period ? `${ stripeState.plan_period }ly` : 'None' }
                </Description>
            </Wrapper>
        </Wrapper>
    </InputTableItem>
));
