import { AttachmentModel as AttachmentModelShared } from '@geenee/shared';
import { AtomSchemaType }                           from '@geenee/shared/type/atom.type';
import { AttachmentType }                           from '@geenee/shared/type/attachment.type';
import { injectable }                               from 'inversify';
import { action, makeObservable, observable, toJS } from 'mobx';
import { deleteAttachment, updateAttachment }       from '@geenee/builder/src/api/AttachmentsAPI';
import generateModelPreview                         from '@geenee/builder/src/lib/generateModelPreview';
import { uploadAttachment }                         from '@geenee/builder/src/lib/uploadAttachment';
import { AtomModel }                                from '@geenee/builder/src/magellan/model/atom.model';
import { SectionModel }                             from '@geenee/builder/src/magellan/model/section.model';

@injectable()
export class AttachmentModel extends AttachmentModelShared implements AttachmentType {
    @observable
        uploadProgress = 100;

    constructor() {
        super();
        makeObservable(this);
    }

    @action
        updateState = (item: Partial<AttachmentModel>) => {
            Object.keys(item).forEach((key) => {
            // @ts-ignore
                this[ key ] = item[ key ];
            });
        };

    async createThumbnail(blob: Blob, order?: number) {
        const thumbnail = new File([ blob ], 'Preview', { type: 'image/png' });
        const thumbnailAttachment = new AttachmentModel();
        await thumbnailAttachment.create({
            id:   null,
            file: thumbnail,
            order
        });
        return thumbnailAttachment;
    }

    @action
    async createWithPreview(initialData: {[key: string]: any} = {}, parent?: SectionModel) {
        if (initialData.file) {
            // TODO: create by factory or another way
            const atom = new AtomModel();
            const attachment = new AttachmentModel();
            Object.assign(attachment, { ...initialData, filename: initialData.file.name, url: URL.createObjectURL(initialData.file) });
            atom.assetsRegistry.set('upload', attachment);
            return generateModelPreview(
                {
                    done: (imageUrl) => fetch(imageUrl).then((response) => response.blob()).then(async (blob) => {
                        const thumbnailAttachment = await this.createThumbnail(blob);
                        return this.create({
                            ...initialData,
                            properties:
                                { ...(initialData?.properties || {}), thumbnail_attachment_id: thumbnailAttachment.id },
                            thumbnail_attachment: thumbnailAttachment
                        }, parent);
                    }),
                    rendererSize:       { width: 205, height: 205 },
                    lookAtObjectCenter: true,
                    modelsToBeRendered: [ atom ]
                }
            );
        }
    }

    @action
    async create(initalData: {[key: string]: any} = {}, parent?: SectionModel) {
        this.uploadProgress = 0;
        this.updateState({ ...initalData });
        const { attachment } = await uploadAttachment(
            toJS(this),
            (value) => { this.uploadProgress = value; }
        ) || { attachment: null };
        if (attachment) {
            this.updateState(attachment);
            if (parent) {
                this.$parent = parent;
            }
            // @ts-ignore
            this.parentProject?.saveData();
        }
        return this;
    }

    @action
    async saveData() {
        this.updateState && this.updateState(this);
        // @ts-ignore
        this.parentProject?.saveData();
        return updateAttachment(this.id, toJS(this.toServerData()));
    }

    toServerData() {
        return { ...this, $parent: undefined, parentProject: undefined, attachmentFactory: undefined };
    }

    @action
    async updateOptions(options: Partial<AtomSchemaType['options']>) {
        Object.assign(this.options, options);
        await this.saveData();
    }

    @action
    async delete() {
        if (this.$parent) {
            // @ts-ignore
            const index = this.$parent.ar_attachment_ids?.findIndex((el) => el === this.id);
            if (index !== undefined && index !== -1) {
                // @ts-ignore
                this.$parent.ar_attachment_ids?.splice(index, 1);
                this.$parent.attachmentsRegistry.delete(this.id);
                await deleteAttachment(this.id);
                // @ts-ignore
                await this.$parent.saveData();
                // @ts-ignore
                this.parentProject?.saveData();
            }
        } else {
            return deleteAttachment(this.id);
        }
    }
}
