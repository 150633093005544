import { Class } from '@babel/types';

export class FactoryAbstract {
    container: any;

    $key: any;
    $value: any;

    constructor(container: any) {
        this.container = container;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    create(...params: any) {
        // eslint-disable-line
        throw new Error('This method is abstract');
    }
}

export function factoryContainer(Factory: Class) {
    // @ts-ignore
    return ({ container }: any) => new Factory(container);
}
