import React, { ChangeEvent, useState } from 'react';
import Uploader                         from '@geenee/geeclient-kit/src/lib/component/scene/components/Uploader';
import { useInject }                    from '@geenee/shared';
import LoadingSpinner                   from '@geenee/shared/src/magellan/components/LoadingSpinner';
import {
    Description,
    Divider,
    FormItem,
    Input,
    InputTable,
    InputTableItem,
    Select,
    TextArea,
    Title,
    Wrapper
} from '@geenee/ui';
import forIn                from 'lodash-es/forIn';
import isEmpty              from 'lodash-es/isEmpty';
import omit                 from 'lodash-es/omit';
import pick                 from 'lodash-es/pick';
import { runInAction }      from 'mobx';
import NextArrow            from '@geenee/builder/src/asset/icons/NextArrow';
import RaisedButton         from '@geenee/builder/src/components/common/Button/WithIcon';
import { ListItemProps }    from '@geenee/builder/src/components/DropWrapper';
import { MediaFiles }       from '@geenee/builder/src/components/modals/AddNFTDetails/MediaFiles';
import { useBuilderInject } from '@geenee/builder/src/core/hook/use-builder-inject';
import { nftState }         from '@geenee/builder/src/core/state/nft.state';
import {
    TOAST_ERROR,
    UPLOADER_ALLOWED_IMAGE_EXTENSIONS
} from '@geenee/builder/src/lib/constants';
import { DoubleCheckNFT }                         from '../DoubleCheckNFT';
import { ContentWrapper }                         from '../PostPublishingNFT/styles';
import { ButtonRow, FormWrapper, GenerateButton } from './styles';

type Props = {
    // eslint-disable-next-line react/no-unused-prop-types
  close: () => void;
    // eslint-disable-next-line no-empty-pattern
  setModal: ({ }) => void;
  descriptionData: {
    title: string | undefined;
    subtitle: string | undefined;
    category: number | undefined;
    description: string | undefined;
    price: string | undefined;
    preview_images: string[] | undefined;
  };
  mintProjectId: string;
  projectUrl: string;
};

type ErrorsProps = {
  title?: any;
  subtitle?: any;
  category?: any;
  description?: any;
  price?: any;
}

// I have no idea what should be here
const CATEGORY_OPTIONS = [
    {
        value: 'Art',
        label: 'Art'
    },
    {
        value: 'Collectibles',
        label: 'Collectibles'
    }
];

export function AddNFTDetails(props: Props) {
    const { AppState } = useInject();
    const { BuilderState } = useBuilderInject();
    const { descriptionData } = props;
    const initData = {
        title:          descriptionData?.title || '',
        subtitle:       descriptionData?.subtitle || '',
        category:       descriptionData?.category || null,
        description:    descriptionData?.description || '',
        price:          descriptionData?.price || '',
        preview_images: descriptionData?.preview_images || ([] as string[])
    };
    const [ formData, setFormData ] = useState(initData);
    const [ errors, setErrors ] = useState<ErrorsProps>({});
    const [ isGenerating, setIsGenerating ] = useState(false);

    const validate = (
        inputValue: string | number,
        name: string,
        validation: { required?: boolean; minLength?: number }
    ) => {
        let error: any = {};
        forIn(validation, (value, key) => {
            if (
                typeof inputValue !== 'number'
                && key === 'required'
                && isEmpty(inputValue)
            ) {
                error = { [ name ]: { type: 'required' } };
                return;
            }
            if (
                typeof inputValue !== 'number'
                && key === 'minLength'
                && inputValue.length < value!
            ) {
                error = { [ name ]: { type: 'minLength' } };
            }
        });

        if (!isEmpty(error)) {
            setErrors({ ...errors, ...error });
        } else if (errors[ name ]) {
            // @ts-ignore
            const prunedErrors = omit(errors, [ [ name ] ]);
            setErrors(prunedErrors);
        }
    };

    const handleDropdownChange = (value: string) => {
        const validation: any = { required: true };
        // @ts-ignore
        setFormData({ ...formData, category: value });
        validate(value, 'category', validation);
    };

    const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.currentTarget;
        const validation: any = {
            required:  true,
            minLength: 2
        };
        setFormData({ ...formData, description: value });
        validate(value, 'description', validation);
    };

    const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.currentTarget;
        setFormData({ ...formData, [ name ]: value });

        const validation: any = {};
        switch (name) {
            case 'price':
                validation.required = true;
                break;
            default:
                validation.minLength = 2;
                validation.required = true;
                break;
        }
        validate(value, name, validation);
    };

    const validateForm = (inputs: any) => {
        let formErrors: any = {};

        forIn(inputs, (value, key) => {
            if (typeof value !== 'number' && isEmpty(value)) {
                formErrors = { ...formErrors, [ key ]: { type: 'required' } };
                return;
            }
            if (key !== 'price' && key !== 'category' && value.length < 2) {
                formErrors = { ...formErrors, [ key ]: { type: 'minLength' } };
                return;
            }
            if (key === 'category' && !value) {
                formErrors = { ...formErrors, [ key ]: { type: 'required' } };
            }
        });

        setErrors(formErrors);
        return formErrors;
    };
    const onClickPreview = () => {
        const inputs = pick(formData, [ 'title', 'subtitle', 'category', 'description', 'price' ]);
        const formErrors = validateForm(inputs);

        if (isEmpty(formErrors)) {
            // Pass props as mockup data to test Back action in modals
            props.setModal({
                component: DoubleCheckNFT,
                props:     {
                    descriptionData: formData,
                    mintProjectId:   props.mintProjectId,
                    projectUrl:      props.projectUrl
                }
            });
        }
    };

    const handleReorder = (items: ListItemProps[]) => {
        const preview_images: string[] = [];
        items.forEach((el) => {
            preview_images.push(formData.preview_images[ el.oldOrder as number ]);
        });
        setFormData({ ...formData, preview_images });
    };

    const handleDelete = (id: string) => {
        const index = formData.preview_images.findIndex((el) => el === id);
        if (index !== -1) {
            formData.preview_images.splice(index, 1);
            setFormData({ ...formData });
        }
    };

    const handleUpload = async (files: File[]) => {
        setIsGenerating(true);
        const preview_images = await nftState.uploadPreviewImages(
            files
        );
        setFormData({
            ...formData,
            preview_images: [
                ...formData.preview_images,
                ...preview_images
            ]
        });
        setIsGenerating(false);
    };

    // const onClickSave = () => {
    //     console.log(formData, props.mintProjectId);
    // };

    return (
        <ContentWrapper>
            <Wrapper align="center">
                <Title size="xxl" align="center" margin="lg">
                    Now just add the details.
                </Title>
                <Wrapper maxWidth="490px" align="center">
                    <Description size="sm" align="center" margin="md">
                        Your NFT will be sold on the Mintable marketplace.
                        <br />
                        Add your marketing details so it gets optimal
                        visibility.
                    </Description>
                </Wrapper>
                <FormWrapper>
                    <Wrapper>
                        <InputTable>
                            <InputTableItem label="Title" viewType="secondary">
                                <FormItem
                                    autoFocusDetect
                                    error={
                                        (errors?.title?.type === 'required'
                                            && 'This field is required')
                                        || (errors?.title?.type === 'minLength'
                                            && 'Please provide more than 2 symbols')
                                    }
                                >
                                    <Input
                                        size="md"
                                        fullWidth
                                        name="title"
                                        placeholder="NFT Title"
                                        onChange={ handleTextChange }
                                        value={ formData.title }
                                    />
                                </FormItem>
                            </InputTableItem>
                            <InputTableItem label="Subtitle" viewType="secondary">
                                <FormItem
                                    autoFocusDetect
                                    error={
                                        (errors?.subtitle?.type === 'required' && (
                                            'This field is required'
                                        )) || (
                                            errors?.subtitle?.type === 'minLength' && (
                                                'Please provide more than 2 symbols'
                                            )
                                        )
                                    }
                                >
                                    <Input
                                        size="md"
                                        fullWidth
                                        name="subtitle"
                                        placeholder='NFT Subtitle'
                                        onChange={ handleTextChange }
                                        value={ formData.subtitle }
                                    />
                                </FormItem>
                            </InputTableItem>
                            <InputTableItem label="Category" viewType="secondary">
                                <FormItem
                                    autoFocusDetect
                                    error={
                                        errors?.category?.type === 'required'
                                        && 'This field is required'
                                    }
                                >
                                    <Select
                                        size="md"
                                        placeholder="Select item category"
                                        value={ CATEGORY_OPTIONS.find(
                                        // @ts-ignore
                                            (item) => item.value === formData.category
                                        ) }
                                        options={ CATEGORY_OPTIONS }
                                        // @ts-ignore
                                        onChange={ ({ value }) => handleDropdownChange(value) }
                                    />
                                </FormItem>
                            </InputTableItem>
                            <InputTableItem label="Description" viewType="secondary">
                                <FormItem
                                    autoFocusDetect
                                    error={
                                        (errors?.description?.type
                                            === 'required'
                                            && 'This field is required')
                                        || (errors?.description?.type
                                            === 'minLength'
                                            && 'Please provide more than 2 symbols')
                                    }
                                >
                                    <TextArea
                                        size="md"
                                        autoSize
                                        onChange={ handleTextAreaChange }
                                        value={ formData.description }
                                        placeholder="NFT description"
                                    />
                                </FormItem>
                            </InputTableItem>
                            <InputTableItem label="Price" viewType="secondary">
                                <FormItem
                                    autoFocusDetect
                                    error={
                                        errors?.price?.type === 'required'
                                        && 'This field is required'
                                    }
                                >
                                    <Input
                                        size="md"
                                        fullWidth
                                        name="price"
                                        type="number"
                                        placeholder="1"
                                        before="eth"
                                        onChange={ handleTextChange }
                                        value={ formData.price }
                                    />
                                </FormItem>
                            </InputTableItem>
                            <InputTableItem label="Media Upload" viewType="secondary">
                                <FormItem>
                                    <ButtonRow>
                                        { /* <Wrapper row valign='center' style={ { justifyContent: 'space-between' } }> */ }
                                        <Uploader
                                            label="Upload"
                                            viewType="secondary"
                                            name="nft-media"
                                            description=""
                                            // @ts-ignore
                                            handleUpload={ handleUpload }
                                            multiple
                                            accept={
                                                UPLOADER_ALLOWED_IMAGE_EXTENSIONS
                                            }
                                        />
                                        { isGenerating ? (
                                            <LoadingSpinner
                                                size={ 20 }
                                            />
                                        ) : (
                                            <GenerateButton
                                                $padding="0 13px"
                                                $justify="center"
                                                style={ {
                                                    width:      '180px',
                                                    height:     '40px',
                                                    flexShrink: 0
                                                } }
                                                onClick={ async () => {
                                                    setIsGenerating(true);
                                                    try {
                                                        const project = AppState.projectsRegistry.get(
                                                            props.mintProjectId
                                                        );
                                                        const preview_images = await nftState.generateAndUploadPreviewImages(
                                                            // @ts-ignore
                                                            project
                                                        );
                                                        setFormData({
                                                            ...formData,
                                                            preview_images: [
                                                                ...formData.preview_images,
                                                                ...preview_images
                                                            ]
                                                        });
                                                    } catch (e: any) {
                                                        console.error(e);
                                                        runInAction(() => {
                                                            BuilderState.toast = {
                                                                severity: TOAST_ERROR,
                                                                detail:
                                                                    'Error while generating preview',
                                                                summary: ''
                                                            };
                                                        });
                                                    }
                                                    setIsGenerating(false);
                                                } }
                                                label="GENERATE IMAGES"
                                            />
                                        ) }
                                    </ButtonRow>
                                    { formData.preview_images.length ? (
                                        <MediaFiles
                                            images={
                                                formData.preview_images.map(
                                                    (el, index) => ({
                                                        url:   el,
                                                        order: index,
                                                        id:    el
                                                    })
                                                ) || []
                                            }
                                            handleDelete={ handleDelete }
                                            onDrop={ handleUpload }
                                            // @ts-ignore
                                            handleReorder={ handleReorder }
                                        />
                                    ) : (
                                        <></>
                                    ) }
                                    { /* </Wrapper> */ }
                                </FormItem>
                            </InputTableItem>
                        </InputTable>
                    </Wrapper>
                </FormWrapper>
                <Divider transparent style={ { height: 32 } } />
                <Wrapper
                    row
                    fullWidth
                    maxWidth="489px"
                    style={ { justifyContent: 'space-between' } }
                >
                    { /* <BackButton
                        $padding="0 23px"
                        $justify="center"
                        $width="141px"
                        $height="61px"
                        onClick={ () => onClickSave() }
                        label="SAVE"
                    /> */ }
                    <Wrapper align="right">
                        <RaisedButton
                            $gradient
                            $padding="0 23px"
                            $justify="space-between"
                            $width="141px"
                            $height="61px"
                            onClick={ () => onClickPreview() }
                            text="PREVIEW"
                            iconElement={ <NextArrow width="30" height="30" /> }
                        />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </ContentWrapper>
    );
}
