import { initProps as logoProps, LogoAtomOptType } from "@geenee/geeclient-kit/src/lib/component/atom/logo/util";
import {
    BlurRadiusCustomExtensionOptType,
    BlurRadiusExtensionOptType, StyleExtensionOptType
} from "@geenee/geeclient-kit/src/lib/type/_extension-type";

export type HeaderOptType = {
  style_color_bg: string,
  style_color_bg_alpha: number,
  style_color_bg_type: string,
  style_color_bg_custom?: string,
  style_type: StyleExtensionOptType<'transparent' | 'line' | 'solid' | 'blur'>['style_type'],
  header_height: string
  header_padding: string
  header_content_align: 'left' | 'right' | 'center'
}
  & BlurRadiusExtensionOptType<'s' | 'm' | 'l' | 'xl' | 'custom'>
  & BlurRadiusCustomExtensionOptType
  & LogoAtomOptType

export const defaultOptions: HeaderOptType = {
    ...logoProps,
    molecule_blur_radius_custom: 0,
    molecule_blur_radius:        's',
    style_color_bg:              'key1',
    style_color_bg_custom:       '',
    style_color_bg_alpha:        1,
    style_color_bg_type:         'solid',
    style_type:                  'solid',
    header_padding:              '2px',
    header_text_font_size:       23,
    header_content_align:        'center',
    logo_width:                  'fit-content',
    header_height:               'fit-content'
};
