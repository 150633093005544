import React, { useEffect, useMemo } from 'react';
import ShadowReceiverPlane           from '@geenee/geeclient-kit/src/lib/component/scene/utils/sdk/ShadowReceiverPlane';
import { CameraViewPort }            from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/camera-viewport.component3d';
import Scene3DControls               from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-3d-controls';
import { GridScene }                 from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-grid.component3d';
import { TrackerImagePlane }         from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/tracker-image-plane.component3d';
import { AtomModel, useInject }      from '@geenee/shared';
import { TYPE_SLAM_AR }              from '@geenee/shared/src/util/constants';
import { useThree }                  from '@react-three/fiber';
import { observer }                  from 'mobx-react';

interface Props {
    sceneGroupRef: any
}

export const SceneControls = observer(({ sceneGroupRef } : Props) => {
    const { AppState, SceneState } = useInject();
    const { activeSceneModel } = AppState;
    if (!activeSceneModel) {
        return null;
    }
    const sectionModel = AppState.firstAvailableSection;

    const { activeMolecule, selectedAtomId, mode } = SceneState;
    const atomModels = activeMolecule?.sceneActorAtoms || [] as AtomModel[];
    const { camera } = useThree();

    const targetLoadedAsset = useMemo(
        () => {
            if (!selectedAtomId) {
                return sceneGroupRef.current;
            } if (atomModels.length && selectedAtomId && activeMolecule.atomsRegistry) {
                return activeMolecule.atomsRegistry.get(selectedAtomId)?.firstObject;
            }
            return null;
        },
        [ selectedAtomId, atomModels, sceneGroupRef, activeMolecule?.atomsRegistry?.get(selectedAtomId)?.firstObject ]
    );

    const disabled = (atomModels.length && !atomModels[ 0 ].sceneObjectVisible) || SceneState.disabled;

    useEffect(() => {
        if (!atomModels.length && activeSceneModel.camera) {
            // FIX FOR: Not rendered grid if there is no model on the scene
            camera.position.copy(activeSceneModel.camera.position);
        }
    }, [ atomModels.length, camera, activeSceneModel.camera?.position ]);

    if (mode === 'preview') {
        return (
            <>
                <TrackerImagePlane
                    currentModel={ activeMolecule?.sceneTriggerAtom }
                />
                <GridScene />
                { activeSceneModel.shadows && (
                    <ShadowReceiverPlane
                        activeSceneModel={ activeSceneModel }
                    />
                ) }
            </>
        );
    }

    return (
        <>
            <TrackerImagePlane
                currentModel={ activeMolecule?.sceneTriggerAtom }
            />
            { sectionModel?.type === TYPE_SLAM_AR && (
                <CameraViewPort />
            ) }
            <GridScene />
            <color
                attach='background'
                args={ [ '#fff' ] }
            />
            <Scene3DControls
                targetAsset={ targetLoadedAsset }
                disabled={ disabled }
            />
            { activeSceneModel.shadows && (
                <ShadowReceiverPlane
                    activeSceneModel={ activeSceneModel }
                />
            ) }
        </>
    );
});
