import React, { useEffect }     from 'react';
import { Html }                 from '@react-three/drei';
import { ArAssetComponentType } from '../../type/scene';

type PropsType = {
    htmlContent: string;
} & ArAssetComponentType;

export function HtmlComponent3d(props: PropsType) {
    useEffect(() => {
        props.loadedCallback();
    }, []);

    return (
        // <mesh
        //     ref={ htmlMeshRef }
        //     dispose={ null }
        //     castShadow={ true }
        // >
        <Html
            as="div"
            position={ props.position }
            rotation={ props.rotation }
            scale={ props.scale }
            name="geenee-3d-html-mesh"
            transform
            // sprite
            distanceFactor={ 100 } // If set (default: undefined), children will be scaled by this factor,
            // and also by distance to a PerspectiveCamera / zoom by a OrthographicCamera.
            // eslint-disable-next-line react/jsx-props-no-multi-spaces
            zIndexRange={ [ 100, 0 ] }
            // occlude
        >
            { /* <iframe title={'asdas'} srcDoc={ */ }
            { /*    props.htmlContent */ }
            { /* } */ }
            { /* /> */ }
            { /* eslint-disable-next-line react/no-danger */ }
            <div
                dangerouslySetInnerHTML={ { __html: props.htmlContent } }
            />
        </Html>
        // </mesh>

    );
}
