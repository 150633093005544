import React, { FC }                          from 'react';
import { DIContextType, DICustomContextType } from '@geenee/geeclient-kit/src/lib/context/di.context';
import { useInject }                          from '@geenee/shared';
import { observer }                           from 'mobx-react';

type DiffPropsType = {
    diContext: DIContextType & DICustomContextType
}

const InjectionConsumer: FC<any> = observer((props) => {
    const { container } = useInject();
    const DI: React.ContextType<any> = container.get('<DIContextGetter>');
    return (
        <DI.Consumer>
            { (context) => {
                const Component = props.component;
                return (
                    <Component { ...props.componentProps } diContext={ context } />
                );
            } }
        </DI.Consumer>
    );
});

export function withDIContext<T extends DiffPropsType>(
    Component: React.ComponentType<T>
) {
    return observer((props) => (
        <InjectionConsumer component={ Component } componentProps={ props } />
    ));
}
