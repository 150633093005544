import React from 'react';
import { getCameraScaledVector }
    from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/GetCameraScaledVector';
import { AtomModel, useInject }                                 from '@geenee/shared/index';
import { Button, Divider, InputTable, InputTableItem, Wrapper } from '@geenee/ui';
import { observer }                                             from 'mobx-react';

export const TransformToolsPanel = observer(() => {
    const { SceneState } = useInject();
    const { selectedAtomId, activeMolecule, disabled } = SceneState;
    const atomModels = activeMolecule?.sceneActorAtoms || [] as AtomModel[];

    const fitToViewport = () => {
        if (selectedAtomId) {
            const child = atomModels.find((el) => el.id === selectedAtomId);
            if (!child) return;
            const object = child.firstObject;
            const scale = getCameraScaledVector(object, child.scale);
            if (object && scale) {
                child.setSceneStats({ scale: [ scale.x, scale.y, scale.z ] });
                object.scale.set(scale.x, scale.y, scale.z);
            }
        } else {
            const child = atomModels[ 0 ];
            if (!child) return;
            const object = child.firstObject;
            const scale = getCameraScaledVector(object, activeMolecule.scale);
            if (object && scale) {
                activeMolecule.setSceneStats({ scale: [ scale.x, scale.y, scale.z ] });
            }
        }
        SceneState.updateParametersMatrixKey();
    };

    return (
        <Wrapper>
            <Divider style={ { height: 1, marginTop: 12, marginBottom: 12 } } color="white" />
            <InputTable>
                <InputTableItem
                    size="sm"
                    weight="medium"
                    viewType="secondary"
                    label="Fit to viewport"
                >
                    <Button
                        disabled={ disabled }
                        size="qsm"
                        fullWidth
                        viewType="secondary"
                        radius="lg"
                        onClick={ fitToViewport }
                    >
                        Fit
                    </Button>
                </InputTableItem>
                <InputTableItem
                    size="sm"
                    weight="medium"
                    viewType="secondary"
                    label="Move to ground"
                >
                    <Button
                        disabled={ disabled }
                        size="qsm"
                        iconSize={ 14 }
                        fullWidth
                        viewType="secondary"
                        radius="lg"
                        onClick={ () => SceneState.moveToGround() }
                    >
                        Move
                    </Button>
                </InputTableItem>
            </InputTable>
        </Wrapper>

    );
});
