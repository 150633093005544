import { useRef, useState }      from 'react';
import { Snapshoter }            from '@geenee/armature';
import { useInject }             from '@geenee/shared';
import { SceneModelType }        from '@geenee/shared/src/magellan/renderer/r3f-renderer/r3f.renderer';
import { BABYLON_SECTION_TYPES } from "@geenee/shared/src/util/constants";
import { drawCanvasCoverImage }  from '@geenee/shared/src/util/drawCanvasCoverImage';

const ITERABLE_IDS = [
    // 'content-nav',
    'ar-assets-switcher',
    'record-root',
    'brandingBar',
    'lotties',
    'mute-button'
];

type snapType = {
    sceneOutput: typeof ImageData
    cameraOutput: typeof ImageData
}
type Props = {
    takeSnapshot?: () => Promise<snapType>
    activeSceneModel: SceneModelType
}

const getUiElements = () => {
    const result: HTMLElement[] = [];
    ITERABLE_IDS.forEach((id) => {
        const el = document.getElementById(id);
        if (el) {
            result.push(el);
        }
    });
    return result;
};

const hideUiElements = () => {
    const elements = getUiElements();
    // eslint-disable-next-line no-return-assign
    elements.forEach((el) => (el.style.opacity = '0'));
    // TODO: should be removed
    const contentNav = document.getElementById('content-nav');
    contentNav.style.opacity = '0';
};

const showUiElements = () => {
    const elements = getUiElements();
    // eslint-disable-next-line no-return-assign
    elements.forEach((el) => (el.style.opacity = '1'));
};

export const useTakeSnapshot = (props: Props) => {
    const { AppState } = useInject();
    const { arSection } = AppState;
    const [ snapShotTaken, setSnapShotTaken ] = useState<boolean | null>(null);
    const { sceneManager } = arSection;

    const snapShotPreviewImgRef = useRef<HTMLImageElement>(null);

    const takeThreeSnapshot = () => {
        const { width } = sceneManager.sceneRenderer.canvas;
        const { height } = sceneManager.sceneRenderer.canvas;
        const canvas = document.createElement("canvas");
        canvas.id = "engeenee.snapshot";
        canvas.hidden = true;
        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext("2d", { alpha: true });
        if (!context) return;
        if (sceneManager.sceneRenderer.mirror) {
            context.translate(width, 0);
            context.scale(-1, 1);
        }
        drawCanvasCoverImage(context, sceneManager.sceneRenderer.videoCanvas, 0, 0, width, height);
        context.drawImage(
            sceneManager.sceneRenderer.canvas,
            0,
            0,
            width,
            height
        );

        // const snapshotImg = props.activeSceneModel.renderer.domElement.toDataURL();
        return canvas.toDataURL();
    };

    const takeBabylonSnapshot = async () => {
        const snapshoter = new Snapshoter(sceneManager.sceneRenderer, true, 'max', window.innerWidth > window.innerHeight ?  window.innerWidth : window.innerHeight);
        const image = await snapshoter.snapshot();
        if (!image) return;
        const canvas = document.createElement("canvas");
        canvas.id = "engeenee.snapshot";
        canvas.hidden = true;

        const context = canvas.getContext('2d');
        if (!context) {
            return;
        }
      const cropRatio = window.innerWidth / window.innerHeight;
      const imageRatio = image.width / image.height;

      let  xOffset, yOffset;
      if (cropRatio > imageRatio) {
        xOffset = (window.innerWidth - image.width) / 2;
        yOffset = 0;
      } else {
        xOffset = 0;
        yOffset = (window.innerHeight - image.height) / 2;
      }

      canvas.width = image.width;
      canvas.height = image.height;





        context.putImageData(image, 0, 0);
        return canvas.toDataURL();
    };


    const takeSnapshot = async () => {
        if (props.takeSnapshot) {
            return props.takeSnapshot();
        }
        // @ts-ignore
        if (process.env.ENV_GEENEE_APP === 'BUILDER') return;
        if (AppState !== undefined) {
            // remove ui elements for the moment
            hideUiElements();
            let snapshotImg = null;
            if (BABYLON_SECTION_TYPES.includes(arSection.type)) {
                snapshotImg = await takeBabylonSnapshot();
            } else {
                snapshotImg = takeThreeSnapshot();
            }
            // const snapshotImg = sceneManager.sceneRenderer.canvas.toDataURL();
            const preview: HTMLImageElement | null = snapShotPreviewImgRef.current;
            if (preview) {
                preview.src = snapshotImg;
            }
            setSnapShotTaken(true);
            // show ui elements
            showUiElements();
        }
    };

    const onSnapshotClose = () => {
        setSnapShotTaken(false);
        // TODO: should be removed
        const contentNav = document.getElementById('content-nav');
        contentNav.style.opacity = '1';
    };

    const onSnapshotTaken = () => {
        setSnapShotTaken(true);
    };

    return {
        takeSnapshot, onSnapshotClose, snapShotTaken, onSnapshotTaken, snapShotPreviewImgRef
    };
};
