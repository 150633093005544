import * as React             from "react";
import { useEffect, useMemo } from "react";
import { TransitionGroup }    from "react-transition-group";
import { DebugLayerTab }      from "@babylonjs/core/Debug/debugLayer";
import type { Observer }      from "@babylonjs/core/Misc/observable";
import type { Scene }         from "@babylonjs/core/scene";
import type { Nullable }      from "@babylonjs/core/types";
import {
    faBug,
    faChartBar, faCog, faFileAlt, faWrench
} from "@fortawesome/free-solid-svg-icons";
import { ResizeWrapper }            from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/ResizeWrapper";
import { DI }                       from "@geenee/geeclient-kit/src/lib/context/di.context";
import { createDIContext }                                    from "@geenee/geespector/src/createDIContext";
import { ModalProvider, Wrapper }   from '@geenee/ui';
import { observer, Provider }                         from "mobx-react";
import { ThemeProvider }                                      from "styled-components";
import   GlobalState                                          from "../globalState";
import { HeaderComponent }                                    from "../headerComponent";
import { ScenePanelComponent }                                from "../scenePanel/scenePanelComponent";
import { DebugTabComponent }                                  from "./tabs/debugTabComponent";
import { PropertyGridTabComponent }                           from "./tabs/propertyGridTabComponent";
import { SettingsTabComponent }                               from "./tabs/settingsTabComponent";
import { StatisticsTabComponent }                             from "./tabs/statisticsTabComponent";
import { ToolsTabComponent }                                  from "./tabs/toolsTabComponent";
import { TabsComponent }                                      from "./tabsComponent";

require("./actionTabs.scss");

interface IActionTabsComponentProps {
    scene?: Scene;
    noCommands?: boolean;
    noHeader?: boolean;
    noExpand?: boolean;
    noClose?: boolean;
    popupMode?: boolean;
    onPopup?: () => void;
    onClose?: () => void;
    globalState?: GlobalState;
    initialTab?: DebugLayerTab;
}

export class ActionTabsComponent_ extends React.Component<IActionTabsComponentProps, { selectedEntity: any; selectedIndex: number, collapsed: boolean }> {
    private _onRightPanelChangeObserver: Nullable<Observer<any>>;
    private _onTabChangedObserver: Nullable<Observer<any>>;
    private _once = true;
    private resizableWrapperMinWidth = 342;
    constructor(props: IActionTabsComponentProps) {
        super(props);

        let initialIndex = props.initialTab === undefined ? DebugLayerTab.Properties : props.initialTab;

        if (this.props.globalState) {
            const { validationResults } = this.props.globalState;
            if (validationResults) {
                if (validationResults.issues.numErrors || validationResults.issues.numWarnings) {
                    initialIndex = DebugLayerTab.Tools;
                }
            }
        }

        this.state = { selectedEntity: null, selectedIndex: initialIndex, collapsed: false };
    }

    componentDidMount() {
        const sceneHeadersButton = document.getElementById("scene-header-buttons");
        this.resizableWrapperMinWidth = sceneHeadersButton?.offsetWidth || 342;
        if (this.props.globalState) {
            this._onRightPanelChangeObserver = this.props.globalState.onRightPanelChangeObservable.add((entity) => {
                this.setState({ selectedEntity: entity, selectedIndex: DebugLayerTab.Properties });
            });

            this._onTabChangedObserver = this.props.globalState.onTabChangedObservable.add((index) => {
                this.setState({ selectedIndex: index });
            });
        }
    }

    componentWillUnmount() {
        if (this.props.globalState) {
            if (this._onRightPanelChangeObserver) {
                this.props.globalState.onRightPanelChangeObservable.remove(this._onRightPanelChangeObserver);
            }
            if (this._onTabChangedObserver) {
                this.props.globalState.onTabChangedObservable.remove(this._onTabChangedObserver);
            }
        }
    }

    changeSelectedTab(index: number) {
        if (this.props.globalState) {
            this.props.globalState.onTabChangedObservable.notifyObservers(index);
        }
    }

    renderContent() {
        if (this.props.globalState && this.props.scene) {
            if (!this.props.globalState.isDevMode) {
                return (
                    <PropertyGridTabComponent
                        title="Properties"
                        icon={ faFileAlt }
                        scene={ this.props.scene }
                        selectedEntity={ this.state.selectedEntity }
                        globalState={ this.props.globalState }
                        onSelectionChangedObservable={ this.props.globalState.onSelectionChangedObservable }
                        onPropertyChangedObservable={ this.props.globalState.onPropertyChangedObservable }
                    />
                );
            }
            return (
                <TabsComponent selectedIndex={ this.state.selectedIndex } onSelectedIndexChange={ (value) => this.changeSelectedTab(value) }>
                    <PropertyGridTabComponent
                        title="Properties"
                        icon={ faFileAlt }
                        scene={ this.props.scene }
                        selectedEntity={ this.state.selectedEntity }
                        globalState={ this.props.globalState }
                        onSelectionChangedObservable={ this.props.globalState.onSelectionChangedObservable }
                        onPropertyChangedObservable={ this.props.globalState.onPropertyChangedObservable }
                    />
                    <DebugTabComponent title="Debug" icon={ faBug } scene={ this.props.scene } globalState={ this.props.globalState } />
                    <StatisticsTabComponent title="Statistics" icon={ faChartBar } scene={ this.props.scene } globalState={ this.props.globalState } />
                    <ToolsTabComponent title="Tools" icon={ faWrench } scene={ this.props.scene } globalState={ this.props.globalState } />
                    <SettingsTabComponent title="Settings" icon={ faCog } scene={ this.props.scene } globalState={ this.props.globalState } />
                </TabsComponent>
            );
        }
        return null;
    }

    onClose() {
        if (!this.props.onClose) {
            return;
        }
        this.props.onClose();
    }

    onPopup() {
        if (!this.props.onPopup) {
            return;
        }
        this.props.onPopup();
    }

    onCollapseChange() {
        const parent = document.getElementById('inspector-host');
        if (parent) {
            parent.style.height = this.state.collapsed ? '100%' : 'fit-content';
        }
        this.setState((prevState) => ({ collapsed: !prevState.collapsed }));
    }

    render() {
        if (this.props.popupMode) {
            return (
                <div id="actionTabs">
                    { !this.props.noHeader && (
                        <HeaderComponent
                            title="INSPECTOR"
                            handleBack
                            noClose={ this.props.noClose }
                            noExpand={ this.props.noExpand }
                            noCommands={ this.props.noCommands }
                            onClose={ () => this.onClose() }
                            onPopup={ () => this.onPopup() }
                            onSelectionChangedObservable={ this.props.globalState ? this.props.globalState.onSelectionChangedObservable : undefined }
                        />
                    ) }
                    { this.renderContent() }
                </div>
            );
        }

        if (this._once) {
            this._once = false;
            // A bit hacky but no other way to force the initial width to 300px and not auto
            setTimeout(() => {
                const element = document.getElementById("actionTabs");
                if (!element) {
                    return;
                }
                element.style.width = "200px";
            }, 150);
        }

        return (
            <Wrapper fullHeight>
                <ResizeWrapper
                    id="actionTabs"
                    enable={ { left: true } }
                    minWidth={ this.resizableWrapperMinWidth }
                >
                    <ScenePanelComponent
                        title="Parameters"
                        changeTitleBackgroundOnHover={ false }
                        clickMode="icon"
                        onCollapseChange={ () => this.onCollapseChange() }

                    >
                        { this.renderContent() }
                    </ScenePanelComponent>
                </ResizeWrapper>
            </Wrapper>
        );
    }
}

export const ActionTabsComponent = observer((props: IActionTabsComponentProps) => {
    const container = useMemo(() => createDIContext(), []);

    const Modal = container.get('<Modal>');
    const WithModal = container.get('<WithModal>');
    const SceneCommander = container.get('<SceneCommander>');

    useEffect(() => {
        props.globalState.SceneCommander = SceneCommander;
    }, [ SceneCommander ]);

    return (
    // @ts-ignore
    // eslint-disable-next-line react/jsx-no-constructed-context-values
        <DI.Provider value={ { container } }>
            <Provider { ...{ AppState: container.get('<AppState>'), BuilderState: container.get('<BuilderState>'), container } }>
                <ThemeProvider theme={ container.get('<Theme>') }>
                    { /* @ts-ignore */ }
                    <WithModal>
                        <ModalProvider
                            rootComponent={ TransitionGroup }
                        >
                            { /* @ts-ignore */ }
                            <Modal />
                            <ActionTabsComponent_ { ...props } />
                        </ModalProvider>
                    </WithModal>
                </ThemeProvider>
            </Provider>
        </DI.Provider>
    );
});
