import React                     from 'react';
import { useHistory }            from "@geenee/common";
import { MediaContent, Wrapper } from '@geenee/ui';
import { observer }              from 'mobx-react';
import GeeneeLogo                from '@geenee/builder/src/asset/images/svg/geenee_logo_black';
import { accountState }          from '@geenee/builder/src/core/state/account.state';
import { LogoImageWrapper }      from './styles';

export const Logo = observer(() => {
    const history = useHistory();
    const company = accountState;
    return (
        <Wrapper
            style={ { marginLeft: 32, marginTop: 16 } }
            maxWidth="fit-content"
            onClick={ () => history.push('/getting-started') }
        >
            { company.logo ? (
                <MediaContent
                    url={ company.logo }
                    fit="contain"
                    type="image"
                    align="start"
                    size="sm"
                    ratio="auto"
                    padding="off"
                />
            ) : (
                <LogoImageWrapper>
                    <GeeneeLogo />
                </LogoImageWrapper>

            ) }
        </Wrapper>
    );
});
