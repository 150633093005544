import React, { forwardRef, memo } from "react";
import { Wrapper }                 from "../../wrapper/wrapper.component";
import '../tooltip.component.scss';

type ContentProps = {
    className: any
    position: any
    open: any
    shadow: any
    children: any
    menuWidth?: string
    minWidth?: string
    largeSize?: boolean
}

export const Content = memo(forwardRef(({
    className,
    position,
    open,
    shadow,
    children,
    menuWidth,
    minWidth,
    largeSize
}: ContentProps, _ref) => (
    <div className={ className('shadow-wrapper', { shadow }) }>
        <Wrapper
            className={ className(
                'content-wrapper',
                {
                    position,
                    open,
                    arrowPosition: position,
                    isLarge:       largeSize

                }
            ) }
            styles={ { width: menuWidth || 'auto', minWidth: minWidth || 0 } }
            frame="solid-white"
        >
            { children }
        </Wrapper>
    </div>
)));
