import styled from 'styled-components';

export const DowngradeWrapper = styled.div`
    width: 600px;
    padding: 32px 72px 56px 72px;

    p {
        font-size: 13px;
        width: 100%;
        text-align: center;
    }
`;
