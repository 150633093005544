import * as React               from 'react';
import { useInject }            from '@geenee/shared';
import { cn, iconMap, Wrapper } from '@geenee/ui';
import { observer }             from "mobx-react";
import {
    TYPE_BODY_TRACKING_OVERLAY,
    TYPE_BODY_TRACKING_TWIN,
    TYPE_HEAD_TRACKING,
    TYPE_NATIVE_AR,
    TYPE_SLAM_AR,
    TYPE_STAMP_AR
} from '@geenee/builder/src/lib/constants';
import { SectionRowTemplate } from './SectionRowTemplate';
import './styles.scss';

const className = cn('scene-drawer');
const sceneTypes: {
  title: string,
  iconName: keyof typeof iconMap,
  sceneType: string,
  description: string}[] = [ {
      title:       'Body Overlay AR',
      iconName:    'bodyTrackingAR',
      sceneType:   TYPE_BODY_TRACKING_OVERLAY,
      description: 'add rigged garments or avatars'
  }, {
      title:       'Body Twin AR',
      iconName:    'twin',
      sceneType:   TYPE_BODY_TRACKING_TWIN,
      description: 'add a rigged avatar to mirror the user’s movements'
  },
  {
      title:       'Face AR',
      iconName:    'faceTrackingAR',
      sceneType:   TYPE_HEAD_TRACKING,
      description: 'add accessories to the face & head'
  }, {
      title:       'World AR',
      iconName:    'worldAR',
      sceneType:   TYPE_SLAM_AR,
      description: 'create interactive AR scenes placed in world view'
  },
  {
      title:       'True Size AR',
      iconName:    'nativeAR',
      sceneType:   TYPE_NATIVE_AR,
      description: 'add assets placed in world view with accurate dimensions'
  },
  {
      title:       'Marker',
      iconName:    'markerAR',
      sceneType:   TYPE_STAMP_AR,
      description: 'add interactive AR scenes overlayed on real-world images'
  }
  ];

export const SelectSceneSectionTypePopup = observer(() => {
    const { AppState } = useInject();
    const { firstAvailableSection } = AppState;

    return (
        <Wrapper
            padding="xs"
            radius="xxxl"
            frame="solid-white"
            shadow="md"
            className={ className('popup-root') }
        >
            { sceneTypes.map((item) => (
                <SectionRowTemplate
                    className={ className('row') }
                    key={ item.sceneType }
                    label={ item.title }
                    description={ item.description }
                    icon={ item.iconName }
                    onClick={ () => {
                        if (firstAvailableSection) {
                            // @ts-ignore
                            firstAvailableSection.setTypeAndTitle(item.sceneType);
                        }
                    } }
                />
            )) }
        </Wrapper>
    );
});
