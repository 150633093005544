import React                          from 'react';
import { Navigate, Route, Routes }    from 'react-router';
import { Wrapper }                    from '@geenee/ui';
import { observer }                   from 'mobx-react';
import { useBuilderInject }           from '@geenee/builder/src/core/hook/use-builder-inject';
import { prohibitedRedirectsByOwner } from '@geenee/builder/src/pages/routes';

interface Props {
  routes: { path: string; exact: boolean; render: React.ComponentType }[];
}

export const MainContent = observer(({ routes }: Props) => {
    const { BuilderState } = useBuilderInject();
    const { role } = BuilderState.currentUser.profile || { role: '' };
    const isOwner = (role || '').toLowerCase() === 'owner';

    return (
        <Wrapper
            style={ { overflowY: 'auto' } }
            fullHeight
            fullWidth
            row
            align="center"
        >
            <Routes>
                { !isOwner
          && prohibitedRedirectsByOwner.map((el, i) => (
              <Route
                  key={ i }
                  element={ (
                      <Navigate
                          from={ el.from }
                          to={ el.to }
                          key={ el.from + el.to }
                      />
                  ) }
              />
          )) }
                { routes.map(
                    (
                        { path, render: Component },
                        index
                    ) => (
                        <Route
                            key={ index }
                            path={ path }
                            element={ <Component /> }
                        />
                    )
                ) }
            </Routes>
        </Wrapper>
    );
});
