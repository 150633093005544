import React from 'react';

// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '10',
    height = '9',
    onClick,
    id,
    fill = '#A9A9A9'
}: SvgIconProps) {
    return (
        <svg
            id={ id }
            onClick={ onClick }
            width={ width }
            height={ height }
            viewBox="0 0 10 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line x1="4.37114e-08" y1="8.5" x2="10" y2="8.5" stroke={ fill } />
            <line x1="4.37114e-08" y1="4.5" x2="10" y2="4.5" stroke={ fill } />
            <line x1="4.37114e-08" y1="0.5" x2="10" y2="0.500001" stroke={ fill } />
        </svg>
    );
}
