import React               from 'react';
import DownArrowBox        from '@geenee/shared/asset/icons/DownArrowBox';
import { DragDropOverlay } from './DragAndDropOverlayStyles';

export function DragAndDropOverlay({ style }: {style?: React.CSSProperties}) {
    return (
        <DragDropOverlay style={ style }>
            <>
                <DownArrowBox id="trigger-drop-icon" />
                <div>Drop file</div>
            </>
        </DragDropOverlay>
    );
}
