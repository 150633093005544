import { TEXT3D_TYPE }                                      from "@geenee/geeclient-kit/src/lib/component/scene/components/Text3D/constants";
import { getCameraScaledVector }                            from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/GetCameraScaledVector";
import { AttachmentModel, ProjectModel }                    from "@geenee/shared";
import { Nullable }                                         from "@geenee/shared/type/shared.type";
import * as TWEEN                                           from "@tweenjs/tween.js";
import * as ethers                                          from "ethers";
import EventEmitter                                         from "eventemitter3";
import { injectable }                                       from "inversify";
import { action, computed, observable, ObservableMap, set } from "mobx";
import * as THREE                                           from "three";
import { Object3D }                                         from "three";
import { TextGeometry }                                     from "three/examples/jsm/geometries/TextGeometry";
import { FontLoader }                                       from "three/examples/jsm/loaders/FontLoader";
import { GLTFLoader }                                       from "three/examples/jsm/loaders/GLTFLoader";
import { AtomSchemaType, Vector3SchemaType }                from "../../../../type/atom.type";
import { AssetModel }                                       from "../../model/asset.model";
import { BaseEntityModel }                                  from "../../model/base-entity.model";
import { MoleculeModel }                                    from "../../molecule/model/molecule.model";

@injectable()
export class AtomModel extends BaseEntityModel implements AtomSchemaType {
  @observable atomSchema!: AtomSchemaType;
  @observable assets: AtomSchemaType["assets"] = [];
  @observable molecule_id: AtomSchemaType["molecule_id"] = "";
  @observable assetsRegistry: ObservableMap<string, AttachmentModel> = new ObservableMap<string, AttachmentModel>();
  @observable imageAsset: AssetModel | null = null;
  @observable selectedAnimation: number | null = null;
  @observable objectsRegistry: ObservableMap<string, Object3D> = new ObservableMap<string, Object3D>();
  @observable sceneObjectVisible = true;
  @observable is_audio_playing = false;
  @observable custom_code_opened = false;
  @observable customCodeExecuted = false;
  @observable view_id = "";
  @observable experience_id = "";
  customCodeContext: any = null;
  emitter: EventEmitter = new EventEmitter();
  type: AtomSchemaType["type"] = "";
  options: AtomSchemaType["options"] = {
      scene_atom_scale:                  [ 1, 1, 1 ],
      scene_atom_bounding_box:           [ 1, 1, 1 ],
      scene_atom_position:               [ 0, 0, 0 ],
      scene_atom_rotation:               [ 0, 0, 0 ],
      scene_atom_thumbnail_url:          "",
      atom_title:                        "",
      scene_atom_disable_cast_shadow:    false,
      scene_atom_disable_receive_shadow: false,
      scene_atom_source_icon:            "mesh"
  };
  $parent?: MoleculeModel;

  get parent_id() {
      return this.molecule_id;
  }

  @computed
  get firstAsset() {
      return Array.from(this.assetsRegistry.values())[ 0 ];
  }

  @computed
  get assetUrl() {
      return this.firstAsset?.url || "";
  }

  @computed
  get isText3D() {
      return this.options.type === TEXT3D_TYPE;
  }

  @computed
  get firstObject() {
      return Array.from(this.objectsRegistry.values())[ 0 ];
  }

  @computed
  get parentProject(): Nullable<ProjectModel> {
      return this.$parent?.parentProject || null;
  }

  @computed
  get position() {
      return this.options.scene_atom_position || [ 0, 0, 0 ];
  }

  @computed
  get rotation() {
      return this.options.scene_atom_rotation || [ 0, 0, 0 ];
  }

  @computed
  get scale() {
      return this.options.scene_atom_scale || [ 1, 1, 1 ];
  }

  @action
  async update(value: Partial<AtomModel>) {
      Object.assign(value);
      // put request
      // @ts-ignore
      this.saveData();
  }

  @action
      updateState = (item: Partial<AtomModel>) => {
          Object.keys(item)
              .forEach((key) => {
                  // @ts-ignore
                  this[ key ] = item[ key ];
              });
      };

  @action
  async updateOptions(options: Partial<AtomSchemaType["options"]>) {
      // Object.assign(this.options, options);
      for (const key in options) {
          set(this.options, key, options[ key ]);
      }
      await this.update(this);
  }

  @action
  async updateOption<T = string>(key: string, value: T) {
      this.options[ key ] = value;
      await this.update(this);
  }

  @action
      setSceneObjectVisible = (value: boolean) => {
          this.sceneObjectVisible = value;
      };

  @action
      setSceneStats = async (value: { position?: Vector3SchemaType, scale?: Vector3SchemaType, rotation?: Vector3SchemaType }) => {
          if (value.position && value.position.length === 3) {
              this.options.scene_atom_position = [ ...value.position ];
          }
          if (value.scale && value.scale.length === 3) {
              this.options.scene_atom_scale = [ ...value.scale ];
          }
          if (value.rotation && value.rotation.length === 3) {
              this.options.scene_atom_rotation = [ ...value.rotation ];
          }
          await this.update(this);
      };

  @action
  onModelClick() {
      this.emitter.emit("on-model-click");
  }

  @action
  runCustomCode(object: THREE.Object3D, activeScene: any) {
      // @ts-ignore
      if (process.env.ENV_GEENEE_APP === "BUILDER") {
          return;
      }
      if (this.custom_code?.code && !this.customCodeExecuted) {
          this.customCodeExecuted = true;
          // const activeScene = this.parentProject?.$parent?.activeSceneModel;
          this.customCodeContext = {
              activeSceneModel: activeScene,
              textureLoader:    new THREE.TextureLoader(),
              THREE:            {
                  ...THREE,
                  FontLoader,
                  TextGeometry,
                  GLTFLoader
              },
              TWEEN,
              on: (event: any, callback: any) => {
                  this.emitter.on(event, () => {
                      callback(object);
                  });
              },
              object3D: object,
              ethers,
              getCameraScaledVector
          };
          // @ts-ignore
          window.THREE = THREE;
          // eslint-disable-next-line no-new-func
          const wrapperFunc = new Function(this.custom_code.code);
          wrapperFunc.call(this.customCodeContext);
      }
  }
}
