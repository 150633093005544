import { UtilityLayerRenderer }   from "@babylonjs/core/Rendering/utilityLayerRenderer";
import { Analytics }              from '@geenee/analytics';
import { Auth }                   from '@geenee/builder/src/api';
import { CompanyModel }           from '@geenee/builder/src/core/model/company.model';
import { UserModel }              from '@geenee/builder/src/core/model/user.model';
import { accountState }           from "@geenee/builder/src/core/state/account.state";
import { nftState }               from '@geenee/builder/src/core/state/nft.state';
import { SceneState, sceneState } from "@geenee/builder/src/core/state/scene.state";
import { spotifyState }           from '@geenee/builder/src/core/state/spotify.state';
import { stripeState }            from '@geenee/builder/src/core/state/stripe.state';
import APIRequest                 from '@geenee/builder/src/lib/APIRequest';
import {
    AUTHORIZATION,
    GRINGOTTS_AUTH,
    TOAST_ERROR, TYPE_BODY_TRACKING_OVERLAY, TYPE_BODY_TRACKING_TWIN,
    TYPE_DRAWER_OVERLAY, TYPE_FULLSCREEN_GALLERY,
    TYPE_FULLSCREEN_VIDEO,
    TYPE_HEAD_TRACKING,
    TYPE_IFRAME,
    TYPE_SLAM_AR,
    TYPE_STAMP_AR,
    USER_LOGIN,
    USER_LOGOUT
} from "@geenee/builder/src/lib/constants";
import dealLocalStorage                               from "@geenee/builder/src/lib/dealLocalStorage";
import envConfig                                      from "@geenee/builder/src/lib/envConfig";
import { InitializationService }                      from "@geenee/builder/src/lib/InitializationService";
import { uploadAttachment }                           from "@geenee/builder/src/lib/uploadAttachment";
import { container }                                  from "@geenee/builder/src/magellan/di/di";
import { MoleculeModel }                              from "@geenee/builder/src/magellan/model/molecule.model";
import { MagellanState }                              from "@geenee/builder/src/magellan/state/app.state";
import AssetLibraryState                              from "@geenee/builder/src/module/team-library/state/team-library.state";
import { BabylonRenderer }                            from "@geenee/geespector/renderer/babylonjs.renderer";
import { SaveBabylonSceneCommand }                    from "@geenee/geespector/src/commands/SaveBabylonScene";
import { ErrorCodes, FoldersController, HttpClient  } from '@geenee/shared';
import { PopoverFactory }                             from '@geenee/shared/src/general/factory/popover.factory';
import { PopoverModel }                               from "@geenee/shared/src/magellan/model/popover.model";
import { inject, injectable }                         from 'inversify';
import {
    action, computed, makeAutoObservable, observable, ObservableMap, runInAction, set
} from 'mobx';
import { Scene }                        from "three";
// @ts-ignore
import { GLTFExporter }                 from "three/addons/exporters/GLTFExporter";
import { paymentModalState }            from "./payment-modal.state";
import { SDKTokenState, sdkTokenState } from "./sdkToken.state";
import { SpotifyState }                 from "./spotify.state";
import { teamState }                    from "./team.state";
import { UrlShortener }                 from "./url-shortener.state";

const { API_URL } = envConfig;
const {
    forgotApi,
    resetPasswordApi
} = Auth;

export const annotationTypes = [
    TYPE_DRAWER_OVERLAY,
    TYPE_SLAM_AR,
    TYPE_STAMP_AR,
    TYPE_IFRAME,
    TYPE_FULLSCREEN_VIDEO,
    TYPE_FULLSCREEN_GALLERY
];

type HeadersType = string[][]

const exporter = new GLTFExporter();

@injectable()
export class BuilderState {
    @observable isLoading = false;
    @observable isInitialized = false;
    @observable isAuthenticating = false;
    @observable authHeaders: HeadersType = [];
    @observable gringottsHeaders: HeadersType = [];
    @observable pushRoute = null;
    @observable arSceneShown = false;
    @observable arSceneBlocked = false;
    @observable isSceneSaving = false;
    @observable sketchfabModelMode: 'geenee' | 'default' = 'default';
    @observable teamLibraryMode: 'snippet' | 'default' = 'default';
    //
    httpClient: HttpClient = container.get("<HttpClient>");
    analytics: Analytics = container.get("<Analytics>");

    // eslint-disable-next-line max-len
    @observable brandLogo = "https://eu-central-1-staging-st-01-medium-upload.s3.eu-central-1.amazonaws.com/media/a683273c-d569-4d25-8ffa-d98a9069e95d/image.original.jpg";
    @observable forgotPasswordSuccessful = false;
    @observable resetPasswordSuccessful = false;
    @observable inviteSetPasswordSuccessful = false;
    @observable toast: {severity: 'error' | 'warning' | 'info' | 'success' | '', detail: string, summary?: string } = {
        severity: "",
        detail:   "",
        summary:  ""
    };
    $appState: MagellanState = container.get("<AppState>");
    @observable toastPosition: 'right' | 'center' = 'right';
    $sdkTokenState: SDKTokenState = sdkTokenState;
    $spotifyState: SpotifyState = spotifyState;
    $sceneState: SceneState = sceneState;
    $stripeState: typeof stripeState = stripeState;
    $teamState: typeof teamState = teamState;
    $accountState: typeof accountState = accountState;
    $paymentModalState: typeof paymentModalState = paymentModalState;
    $urlShortenerState: typeof UrlShortener = UrlShortener;
    @inject("<PopoverFactory>")
        popoverFactory: PopoverFactory = container.get("<PopoverFactory>");

    @observable popoverRegistry: ObservableMap<
        string,
        PopoverModel
    > = new ObservableMap([]);
    @observable activePopoverId = "";

    @observable currentUser: {
        profile: UserModel | null;
        company: CompanyModel | null;
        spotify: null;
    } = {
            profile: null,
            company: null,
            spotify: null
        };

    initializationService = new InitializationService();

    @observable foldersController: FoldersController = new FoldersController();

    constructor() {
        makeAutoObservable(this);

        this.$appState.$parent = this;

        this.loadAuthHeaders();
        this.loadGringottsHeaders();
        window.addEventListener("unauth", () => {
            this.logout();
        });
        window.addEventListener("httpError", (e) => {
            const { detail } = e as any as { detail: { message: string, quietCatch?: boolean } };

            if (!detail.quietCatch) {
                this.toast = {
                    detail:   detail.message,
                    severity: TOAST_ERROR,
                    summary:  ""
                };
            }
        });
    }

    @action
        setToastPosition = (position: 'right' | 'center') => {
            this.toastPosition = position;
        };

    @action
    createPopover(popoverSchema: any) {
        const popover = this.popoverFactory.create(popoverSchema);
        this.popoverRegistry.set(popoverSchema.id, popover);
        this.activePopoverId = popoverSchema.id;
        return popover;
    }

    @computed
    get activePopover() {
        return this.popoverRegistry.get(this.activePopoverId);
    }

    @action
    closePopover() {
        this.popoverRegistry.clear();
        return null;
    }

    @computed
    get isAuthenticated() {
        return this.authHeaders && this.authHeaders.length > 0;
    }

    @computed
    get isNftAvailable() {
        return this.gringottsHeaders.length > 0 && !stripeState.isHobbyist;
    }

    @action onArModalEnter = () => {
        this.arSceneShown = true;
    };

    @action setIsSceneSaving = (value: boolean) => {
        this.isSceneSaving = value;
    };

    unmountBabylonScene = () => {
        UtilityLayerRenderer._DefaultUtilityLayer = null;
        UtilityLayerRenderer._DefaultKeepDepthUtilityLayer = null;
    };

    @action changeSketchfabMode(mode: "geenee" | "default") {
        this.sketchfabModelMode = mode;
    }
    @action changeTeamLibraryMode(mode: 'snippet' | 'default') {
        this.teamLibraryMode = mode;
    }

    @action onArModalLeave = async (closeModal: any) => {
        const { activeSection } = this.$appState;
        if (activeSection?.type === "native-ar") {
            this.arSceneBlocked = true;

            this.arSceneShown = false;
            await this.saveNativeArGLTF(closeModal);
        } else if (activeSection?.type === TYPE_BODY_TRACKING_OVERLAY
            || activeSection?.type === TYPE_BODY_TRACKING_TWIN
            || activeSection?.type === TYPE_HEAD_TRACKING) {
            this.unmountBabylonScene();
            const { sceneManager } = activeSection;
            const { activeMolecule } = activeSection;
            this.arSceneShown = false;
            closeModal();
            if (sceneManager && sceneManager.sceneRenderer && activeMolecule) {
                const renderer = sceneManager.sceneRenderer as any as BabylonRenderer;
                if (renderer.wasSceneChanged) {
                    const saveSceneCommand = new SaveBabylonSceneCommand(this, activeMolecule as MoleculeModel);
                    sceneManager.commander.executeCommand(saveSceneCommand);
                }
            }
        } else {
            this.arSceneShown = false;
            closeModal();
        }
    };

    @action saveNativeArGLTF = async (closeModal: any) => {
        if (this.$appState.activeSceneModel) {
            this.$appState.activeSceneModel.camera.position.set(0, 0, 0);
            const scene = new Scene();
            scene.add(this.$appState.activeSceneModel.scene.children[ 0 ].getObjectByName("main-assets-group"));
            const lineSegment = scene.children[ 0 ].getObjectByName('LineSegment');
            if (lineSegment) {
                scene.children[ 0 ].remove(lineSegment);
            }
            exporter.parse(
                scene,
                async (gltf) => {
                    const blob = new Blob([ gltf ], { type: "application/octet-stream" });
                    const file = new File([ blob ], "model.glb");

                    const res = await uploadAttachment({ file });
                    this.$appState.activeSection?.updateOption("section_native_ar_merged_model_url", res.attachment.url);
                    // @ts-ignore
                    await this.$appState.activeSection?.saveData();
                    runInAction(() => {
                        this.arSceneBlocked = false;

                        closeModal();
                    });
                },
                // Error handler
                () => {
                    runInAction(() => {
                        this.arSceneBlocked = false;
                    });
                },
                { binary: true }
            );
        }
    };

    isSubscriptionExpired = () => {
        const { isExpired } = stripeState;
        if (isExpired) {
            const error = new Error('Subscription is expired');
            // @ts-ignore
            error.code = ErrorCodes.SUBSCRIPTION_ERROR;
            throw error;
        }
    };

    @action init = async (): Promise<void> => {
        this.isLoading = true;
        const data = await this.initializationService.initProfile() || {};
        const { profile, company } = data;
        const userDescription = {
            email:                 profile.email,
            first_name:            profile.first_name,
            id:                    profile.id,
            isSketchFabAuthorized: profile.isSketchFabAuthorized,
            last_name:             profile.last_name,
            login_type:            profile.login_type,
            role:                  profile.role,
            companyName:           company.name,
            publishing_domain:     company.publishing_domain,
            npm_key:               company.npm_key,
            plan_period:           this.$stripeState?.plan_period,
            plan_price:            this.$stripeState?.plan_price,
            plan_price_id:         this.$stripeState?.plan_price_id,
            plan_title:            this.$stripeState?.plan_title
        };
        this.analytics.identify(userDescription.id, userDescription);

        spotifyState.init(this);

        // @TODO: Rewrite error handling. Hotfix to able to login to the platform
        try {
            if (stripeState.isActiveTeamOrEnterprise) {
                await AssetLibraryState.fetchItems();
            } else {
                await AssetLibraryState.genarateDefaultItems();
            }
        } catch (e) {
            console.log("AssetLibraryState fetchItems failed: ", e);
        }

        await this.setCurrentUserProperties(company, profile);
        this.$appState.setLastUpdate();
        this.isSubscriptionExpired();
        this.isLoading = false;
    };

    @action initProfile = async () => {
        const {
            profile,
            company
        } = await this.initializationService.initProfile() || {};
        this.currentUser.profile = profile;
        this.currentUser.company = company;
    };
    @action
        setCurrentUserProperties = async (company: CompanyModel, profile?: UserModel) => {
            this.isInitialized = true;
            this.$appState.isInitialised = true;
            if (profile) {
                this.currentUser.profile = profile;
                this.currentUser.company = company;
                // di context - remove from params
                const successes = await this.initializationService.loadUserContentData();
                const [ projectsData ] = successes;
                if (this.$appState) {
                    const restrictedIds = new Set(profile.restricted_projects_ids);
                    await this.$appState.setProjects(profile.has_restricted_projects
                    // @ts-ignore
                        ? projectsData.filter((project) => restrictedIds.has(project.id))
                        : projectsData);
                    await this.foldersController.init();
                    runInAction(() => {
                        this.$appState.isInitialised = true;
                    });
                }
                runInAction(() => {
                    this.isInitialized = [ ...successes ].every((result) => result);
                });
            }
            runInAction(() => {
                this.isLoading = false;
            });
        };

    @action
    runAfterUpdate() {
        console.log("after update");
    }

    @action
    update<T extends keyof BuilderState>(property: T, val: BuilderStateType[T]) {
        set(this, property, val);
        this.runAfterUpdate();
    }

    @action
    async logout() {
        try {
            await this.analytics.track(USER_LOGOUT, {
                authorizationToken: dealLocalStorage("get", AUTHORIZATION),
                gingottsToken:      dealLocalStorage("get", GRINGOTTS_AUTH)
            });
        } catch (e) {
            console.error("Logout tracking error: ", e);
        }

        this.authHeaders = [];
        APIRequest.setAuthHeaders(this.authHeaders);
        dealLocalStorage("remove", AUTHORIZATION);
        dealLocalStorage("remove", GRINGOTTS_AUTH);
        this.isInitialized = false;
    }

  @action
    loadAuthHeaders() {
        this.isAuthenticating = true;
        const authHeaderData = dealLocalStorage("get", AUTHORIZATION);
        if (authHeaderData) {
            this.setAuthHeaders(authHeaderData);
        }
        this.isAuthenticating = false;
    }

    @action
  loadGringottsHeaders() {
      const token = dealLocalStorage("get", GRINGOTTS_AUTH);
      if (token) {
          this.setGringottsToken(token);
      }
  }

    @action
    setAuthHeaders(token) {
        set(this, 'authHeaders', [ [ "authorization", `Bearer ${ token }` ] ]);
        dealLocalStorage("set", AUTHORIZATION, token);

        APIRequest.setAuthHeaders(this.authHeaders);
    }

    @action
    setGringottsToken(token: string) {
        set(this, 'gringottsHeaders', [ [ "authorization", `Bearer ${ token }` ] ]);
        dealLocalStorage("set", GRINGOTTS_AUTH, token);

        nftState.setToken(token);
    }

    @action
    async downloadGringottsToken() {
        try {
            const { data } = await this.httpClient.get(
                `${ API_URL }/api/v0/accounts/tokens`
            );
            this.setGringottsToken(data.gringotts_token);
        } catch (e) {
            console.log(e);
        }
    }

  @action
    async login(email: string, password: string, nextPath: string) {
        // @ts-ignore
        this.isAuthenticating = true;
        let token;
        let gringotts_token;
        try {
            const { data } = await this.httpClient.post(
                `${ API_URL }/api/v0/public/auth`,
                {
                    email,
                    password
                }
            );
            this.analytics.track(USER_LOGIN, {
                email,
                nextPath
            });

            token = data.data;
            gringotts_token = data.gringotts_token;
            this.setGringottsToken(gringotts_token);
            this.setAuthHeaders(token);
        } catch (e: any) {
            this.isAuthenticating = false;
            throw new Error(e);
        }

        this.isAuthenticating = false;
    }

  @action authByToken(token: string, gringotts?: string) {
      this.isAuthenticating = true;
      if (gringotts) {
          this.setGringottsToken(gringotts);
      }
      this.setAuthHeaders(token);
      dealLocalStorage("set", AUTHORIZATION, token);
      runInAction(() => {
          this.isAuthenticating = false;
      });
  }

  @action
  async signUp(email: string, password: string) {
      const response = await this.httpClient.post(
          `${ API_URL }/api/v0/public/register`,
          {
              email,
              password
          },
          { params: { quietCatch: true } } // for hiding toast error message
      )
          .catch((e) => {
              throw new Error(e);
          });

      if (response) {
          const { data: token } = response.data;
          this.setAuthHeaders(token);
      }
  }

    @action
  async forgotPass(email: string) {
      if (email) {
          return forgotApi(email);
      }
  }

    @action
    async resetPass(code: string, password: string) {
        return resetPasswordApi(code, password);
    }
}

export type BuilderStateType = BuilderState
