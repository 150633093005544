import { UploadBox }      from '@geenee/geeclient-kit/src/lib/component/scene/components/Uploader/styles';
import { Wrapper }        from '@geenee/ui';
import styled             from 'styled-components';
import { ButtonWithIcon } from '@geenee/builder/src/components/common/Button/styles';
import WithIcon           from '@geenee/builder/src/components/common/Button/WithIcon';

export const ComponentWrapper = styled.div<{
    active: boolean;
    publish?: boolean;
    published: boolean | undefined;
    disabled?: boolean;
}>`
    position: relative;

    ${ ButtonWithIcon } {
        & > * {
            pointer-events: none;
        }

        background-color: ${ ({ theme: { secondaryColors, colors }, publish }) => (publish ? colors.black : secondaryColors.grey60) } !important;

        &:hover {
            background-color: ${ ({
        theme: { secondaryColors, colors },
        publish
    }) => (publish ? secondaryColors.grey130 : colors.white) } !important;
        }

        ${ ({ active, theme: { colors }, publish }) => active
            && `background-color: ${
                publish ? colors.black : colors.white
            } !important;
            box-shadow: 0px 2px 2px rgb(0 0 0 / 25%), inset 0px 2px 2px rgb(0 0 0 / 25%) !important;
            ` }

    .one-ui-description {
            color: ${ ({
        theme: { colors, secondaryColors },
        publish,
        published
        // eslint-disable-next-line no-nested-ternary
    }) => (publish
        ? published
            ? secondaryColors.green80
            : colors.white
        : colors.black) };
        }
    }
  .one-ui-tooltip__message {
    white-space: nowrap;
    left: -65px;
  }
  #republish-button {
    border-left: 1px solid ${ ({ theme: { secondaryColors } }) => secondaryColors.grey180 } !important;
  }
`;

export const DropdownWrapper = styled.div<{ publish?: boolean }>`
    padding: 10px 10px;
    width: 295px;
    background: ${ ({ theme: { colors, secondaryColors }, publish }) => (publish ? colors.black : secondaryColors.grey60) };
    border: 1px solid
        ${ ({ theme: { colors, secondaryColors }, publish }) => (publish ? colors.black : secondaryColors.grey190) };
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    .one-ui-title {
        ${ ({ theme: { colors }, publish }) => publish
            && `
            color: ${ colors.white };
        ` }
    }
`;

export const CardDropdownContainer = styled.div<{publish?: boolean}>`
    position: absolute;
    padding-top: 16px;
    top: 50px;
    z-index: 1;
    ${ ({ publish }) => (publish ? 'right: 0;' : 'left: -20px;') };
`;

export const CopyActionWrapper = styled.div<{
    active: boolean;
    publish?: boolean;
}>`
    height: 46px;
    padding: 11px 7px;
    border-radius: 5px;
    transition: background-color ease 0.3s;
    background: ${ ({ theme: { colors } }) => (colors.white) };
    display: flex;
    align-items: center;

    span {
        white-space: pre-wrap;
        word-break: break-all;
        font-size: 12px;
        color: ${ ({ theme: { colors } }) => colors.black };
    }

    .button {
        position: relative;
        cursor: pointer;
    }
`;

// ${ PanelContent } {
// overflow: unset;
// };

export const PanelContainer = styled.div`

    input[type="color"] {
        width: 100%;
        border: none;
        height: 30px;
        padding: 0;
        cursor: pointer;

        &::-webkit-color-swatch {
            border-radius: 6px;
            padding: 0;
        }
    }

    .one-ui-input-table-item {
        min-height: 48px;
    }

    .one-ui-input-table-item__label {
        padding-right: 16px;
    }

    .one-ui-input-table-item__content {
        flex-grow: 2;
    }

    .one-ui-range-slider__tooltip {
        pointer-events: none;
    }
`;

export const DeleteButton = styled.div`
    width: 24px;
    height: 24px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
        background: ${ ({ theme: { secondaryColors } }) => secondaryColors.grey100 };
    }
`;

export const ErrorLevelWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
`;

export const ErrorLevelButton = styled.button<{$active: boolean}>`
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: none;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    background-color: ${ ({ theme: { secondaryColors } }) => secondaryColors.grey20 };
    cursor: pointer;
    transition: background ease-in-out .2s;

    &:hover {
        background: ${ ({ theme: { secondaryColors } }) => secondaryColors.grey130 }
    }

    ${ ({ $active, theme: { secondaryColors } }) => $active
        && `
        background: ${ secondaryColors.grey100 };
        box-shadow: none;
        `
}
`;

export const LabelHintWrapper = styled.div<{ hintText: string }>`
    .one-ui-input-table-item__label {
        position: relative;

        ${ ({ hintText }) => hintText && `
            &:hover {
                font-weight: bold;
                cursor: pointer;

                label {
                    cursor: pointer;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: -16px;
                    transform: translateY(-50%);
                    border-top: 13px solid transparent;
                    border-left: 6px solid #cacaca;
                    border-bottom: 13px solid transparent;
                }

                &:after {
                    content: '${ hintText }';
                    position: absolute;
                    top: 50%;
                    left: -140px;
                    width: 124px;
                    padding: 10px;
                    background: #f5f5f5;
                    border: 1px solid #cacaca;
                    box-sizing: border-box;
                    box-shadow: 2px 2px 4px rgb(0 0 0 / 4%);
                    border-radius: 5px;
                    font-family: 'Mulish', sans-serif;
                    font-style: italic;
                    font-weight: normal;
                    font-size: 11px;
                    line-height: 14px;
                    transform: translateY(-50%);
                }
            }
        ` }
    }
`;

export const ButtonStyled = styled.div`
  cursor: pointer;
  padding: 6px;
  &:hover {
    border-radius: 100%;
    box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.25);
  }
`;

export const IconsContainer = styled.div`
  background: white;
  border-radius: 8px;
  padding: 10px;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  ${ ButtonStyled }:last-child {
    margin-top: 10px
  }
`;

export const DropdownCodeSettings = styled.div`
  background: white;
  width: 169px;
  height: 136px;
  border-radius: 5px;
  position: absolute;
  top: 24px;
  left: 24px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  display: flex;
  flex-direction: column;
  padding: 11px 18px;
`;

export const ColorPickerButton = styled.div<{color: string}>`
  height: 30px;
  width: 30px;
  border: 1px solid #A6A6A6;
  border-radius: 100%;
  background: ${ ({ color }) => color };
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
`;

export const QrRowWrapper = styled.div`
  display: flex;
  font-size: 10px;
  position: relative;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  input[type=color] {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    cursor: pointer;
  }
  &:last-child {
    margin-bottom: 0;
  }
  ${ UploadBox } {
    width: 30px;
    height: 30px;
    background: white;
  }
`;

export const DoneLabel = styled.div<{withoutMargin?: boolean}>`
  font-size: 10px;
  font-style: italic;
  margin-top: 18px;
  margin-bottom: 5px;
  ${ ({ withoutMargin }) => withoutMargin && 'margin: 0;' }
`;

export const Root = styled.div`
  width: 100%;

  @media (max-width: 1200px) {
    margin-top: 0;
    #link-button {
      margin-bottom: 16px;
    }
  }
  @media (max-width: 910px) {
    margin-top: 0;
    #link-button {
      margin-bottom: 16px;
    }
  }
`;

export const QrImageLoaderWrapper = styled.div`
  height: 180px;
  width: 180px;
`;

export const ShortUrlRowRoot = styled.div`
  padding: 0 8px 8px 8px;
`;

export const ReloadButtonIcon = styled(WithIcon)`
  padding:                16px !important;
  height:                 48px !important;
  width:                  48px !important;
  cursor:                 pointer !important;
  border-top-left-radius:    0 !important;
  border-bottom-left-radius: 0 !important;
`;

export const PreviewPublishButtonIcon = styled(WithIcon)<{withBorder?: boolean}>`
  width: fit-content !important;
  padding:       16px 16px 16px 23px !important;
  height:       48px !important;
  cursor: pointer !important;
  ${ ({ withBorder }) => (withBorder ? `border-top-right-radius: 0 !important;
                              border-bottom-right-radius: 0 !important;` : '') }
`;
