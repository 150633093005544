import React, { FC }     from 'react';
import { Scrollbars }    from "react-custom-scrollbars";
import { useMediaQuery } from "react-responsive";
import {
    Cell,
    Column,
    ColumnInstance,
    HeaderGroup,
    Row,
    useFlexLayout,
    useResizeColumns,
    useRowSelect,
    useSortBy,
    useTable
} from 'react-table';
import { FrameExtensionUnionType, SizesUnionType } from '../../util/global-props';
import { Swiper }                                  from "../swiper/swiper.component";
import { Wrapper }                                 from '../wrapper/wrapper.component';
import { TableFooter }                             from './components/Footer/table-footer.component';
import { TableFooterRow }                          from './components/Footer/table-footer-row.component';
import { TableHeader }                             from './components/Header/table-header.component';
import { TableHeaderRow }                          from './components/Header/table-header-row.component';
import { TableBody }                               from './components/table-body.component';
import { TableCell }                               from './components/table-cell.component';
import { TableRow }                                from './components/table-row.component';
import { tableClassName }                          from './utils';
import './table.components.scss';

const className = tableClassName;

export interface DataType {
  [key: string]: any;
}

type TablePropsType = {
    columns: Column<DataType>[];
    data: DataType[];
    className?: string;

    minWidth?: number;
    width?: number;
    maxWidth?: string | undefined;

    frame?: FrameExtensionUnionType;
    bodyFrame?: FrameExtensionUnionType;

    margin?: SizesUnionType;
    padding?: SizesUnionType;
    overflow?: 'hidden' | 'scroll' | 'visible';

    marginHeader?: SizesUnionType;
    paddingHeader?: SizesUnionType;
    marginBody?: SizesUnionType;
    paddingBody?: SizesUnionType;
    marginFooter?: SizesUnionType;
    paddingFooter?: SizesUnionType;
    style?: React.CSSProperties;

    usePagination?: boolean;
    useSelection?: boolean;
    useSorting?: boolean;
    noHeader?: boolean;
    noFooter?: boolean;
    stickyHeader?: boolean;

    // mobile mode
    hasMobileMode?: boolean;
    arrowPosition?: 'top' | 'default' | 'none';
    swiperFrame?: FrameExtensionUnionType;
    ignoreFirstColumn?: boolean;

    loadNext?: () => void;
    getTableHeight?: () => void;
    onHeaderClick?: (column: Column) => void;
};

export const Table: FC<TablePropsType> = React.memo(
    ({
        columns,
        data,
        style,
        // useSelection,
        // usePagination,
        // width,
        // minWidth,
        maxWidth,
        frame,
        bodyFrame,

        margin,
        padding,
        overflow,

        marginHeader,
        paddingHeader,
        marginBody,
        paddingBody,
        marginFooter,
        paddingFooter,

        hasMobileMode,
        arrowPosition,
        swiperFrame,
        ignoreFirstColumn,

        useSorting,
        noHeader,
        noFooter,
        stickyHeader
    }) => {
        const isMobile = useMediaQuery({ query: '(max-width: 1100px)' });

        const getTableHooks = () => {
            const hooks = [ useFlexLayout, useResizeColumns, useRowSelect ];

            if (useSorting) {
                return [ useSortBy, ...hooks ];
            }

            return hooks;
        };

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            footerGroups,
            rows,
            prepareRow
        } = useTable<DataType>(
            {
                columns,
                data
            },
            ...getTableHooks()

            // (hooks: Hooks<DataType>) => {
            //     if (useSelection) {
            //         hooks.flatColumns.push((_columns: Column[]) => [
            //             {
            //                 id:       'selection',
            //                 minWidth: 48,
            //                 width:    0,
            //                 Header:   ({ getToggleAllRowsSelectedProps }: {
            //                     getToggleAllRowsSelectedProps: Function;
            //                 }) => (
            //                     <div
            //                         className={ className('checkbox', { selected: getToggleAllRowsSelectedProps().isSelected }) }
            //                     >
            //                         <Checkbox
            //                             onChange={ (val) => getToggleAllRowsSelectedProps().onChange({ target: { value: val } }) }
            //                             checked={ getToggleAllRowsSelectedProps().checked }
            //                         />
            //                     </div>
            //                 ),
            //                 // @ts-ignore
            //                 Cell: ({ row }): { [key: string]: any } => (
            //                     <div
            //                         className={ className('checkbox', { selected: row.isSelected }) }
            //                     >
            //                         <Checkbox
            //                             onChange={ (val) => row
            //                                 .getToggleRowSelectedProps()
            //                                 .onChange({ target: { value: val } }) }
            //                             checked={ row.getToggleRowSelectedProps().checked }
            //                         />
            //                     </div>
            //                 )
            //             },
            //             ..._columns
            //         ]);
            //     }
            // }
        );

        const desktopContent = () => (
            <>
                { !noHeader && (
                    <TableHeader
                        margin={ marginHeader }
                        padding={ paddingHeader }
                        stickyHeader={ stickyHeader }
                    >
                        { headerGroups.map((headerGroup: HeaderGroup, headerIndex) => (
                            <TableHeaderRow
                                key={ `header_${ headerGroup.id }${ headerIndex }` }
                                headerRowProps={ headerGroup.getHeaderGroupProps() }
                            >
                                { (headerGroup.headers as ColumnInstance[]).map((column) => (
                                    <TableCell
                                        key={ column.id }
                                        cellProps={ column.getHeaderProps() }
                                    >
                                        { column.render('Header') }
                                    </TableCell>
                                )) }
                            </TableHeaderRow>
                        )) }
                    </TableHeader>
                ) }

                { rows.length ? (
                    <TableBody
                        frame={ bodyFrame }
                        margin={ marginBody }
                        padding={ paddingBody }
                        tBodyProps={ getTableBodyProps() }
                    >
                        { /* { usePagination && !!tableHeight && (
                        <FixedSizeList
                            style={ { minWidth: '100%' } }
                            height={ tableHeight }
                            itemData={ rowsData }
                            overscanCount={ 10 }
                            width="auto"
                            itemCount={ rows.length }
                            itemSize={ 61 }
                        >
                            { RenderRow }
                        </FixedSizeList>
                    ) } */ }

                        { /* { !usePagination */ }
                        { rows.map((row: Row<DataType>) => {
                            prepareRow(row);
                            return (
                                <TableRow key={ row.id } rowProps={ row.getRowProps() }>
                                    { row.cells.map((cell: Cell<DataType>) => (
                                        <TableCell
                                            key={ cell.getCellProps().key }
                                            cellProps={ cell.getCellProps() }
                                        >
                                            { cell.render('Cell') }
                                        </TableCell>
                                    )) }
                                </TableRow>
                            );
                        }) }
                    </TableBody>
                ) : <></> }

                { !noFooter && (
                    <TableFooter
                        margin={ marginFooter }
                        padding={ paddingFooter }
                    >
                        {
                            footerGroups.map((footerGroup) => (
                                <TableFooterRow
                                    key={ footerGroup.id }
                                    footerRowProps={ footerGroup.getFooterGroupProps() }
                                >
                                    { (footerGroup.headers as ColumnInstance[]).map((column) => (
                                        <TableCell
                                            key={ column.id }
                                            cellProps={ column.getFooterProps() }
                                        >
                                            { column.render('Footer') }
                                        </TableCell>
                                    )) }
                                </TableFooterRow>
                            ))
                        }
                    </TableFooter>
                ) }
            </>
        );

        function Item({ header, column, footer }: { header: React.ReactNode, column: Column<DataType>[], footer: React.ReactNode }) {
            return (
                <Wrapper
                    align="center"
                    fullWidth
                    fullHeight
                    padding="md"
                    style={ { minWidth: '400px', minHeight: "80vh" } }
                >
                    <Wrapper
                        align="center"
                        valign="center"
                        flex="03"
                        maxWidth="224px"
                        radius="xl"
                        style={ { height: 40 } }
                        shadow="xl"
                        padding="sm"
                    >
                        { header }
                    </Wrapper>
                    <Wrapper padding="sm" maxWidth="330px">
                        <Scrollbars
                            autoHide
                            autoHideTimeout={ 500 }
                            autoHideDuration={ 200 }
                            autoHeight
                        >
                            <Table noFooter noHeader padding='off' data={ data } columns={ column } />
                        </Scrollbars>
                        { footer }
                    </Wrapper>
                </Wrapper>
            );
        }

        const mobileContent = () => {
            const headers = ignoreFirstColumn ? headerGroups[ 0 ].headers.slice(1) : headerGroups[ 0 ].headers;
            return (
                <Swiper
                    size="lg"
                    fullHeight
                    fullWidth
                    align="center"
                    overflowY="auto"
                    direction="horizontal"
                    loop={ false }
                    navigation
                    centeredSlides
                    slidesPerView={ 2 }
                    spaceBetween={ 130 }
                >
                    { headers.map((header, headerIndex) => (
                        <Item
                            key={ `header_${ header.id }${ headerIndex }` }
                            header={ header.render('Header') }
                            column={ ignoreFirstColumn ? [ columns[ 0 ], columns[ headerIndex + 1 ] ] : [ columns[ headerIndex ] ] }
                            footer={ header.render('Footer') }
                        />
                    )) }
                </Swiper>
            );
        };

        if (isMobile && hasMobileMode) {
            return (
                <>
                    { ' ' }
                    <style dangerouslySetInnerHTML={ {
                        __html: `
            .swiper-slide-next .one-ui-table, .swiper-slide-prev .one-ui-table {
              display: none;
          }`
                    } }
                    />
                    <Wrapper frame={ frame } style={ { width: 320 } }>
                        { mobileContent() }
                    </Wrapper>
                </>
            );
        }
        return (
            <Wrapper
                frame={ frame }
                margin={ margin }
                padding={ padding }
                maxWidth={ maxWidth }
                overflow={ overflow }
                { ...getTableProps() }
                className={ className() }
                style={ style }
            >
                { isMobile && hasMobileMode ? mobileContent() : desktopContent() }
            </Wrapper>
        );
    }
);
