import React, { useState }                      from 'react';
import { useInject }                            from '@geenee/shared';
import { Description, Divider, Title, Wrapper } from '@geenee/ui';
import { observer }                             from 'mobx-react';
import NextArrow                                from '@geenee/builder/src/asset/icons/NextArrow';
import RaisedButton                             from '@geenee/builder/src/components/common/Button/WithIcon';
import { AddNFTDetails }                        from '@geenee/builder/src/components/modals/AddNFTDetails';
import { nftState }                             from '@geenee/builder/src/core/state/nft.state';
import { ContentWrapper }                       from '../PostPublishingNFT/styles';
import { CopyButton, GradientWrapper }          from './styles';

type Props = {
  close: () => void;
    // eslint-disable-next-line no-empty-pattern
  setModal: ({ }) => void;
  secretPhrase: string;
  id: string;
  projectUrl: string;
};

export const SaveNFTPhrase = observer((props: Props) => {
    const [ copySuccess, setCopySuccess ] = useState(false);
    const { AppState } = useInject();
    const copyToClipboard = () => {
        navigator.clipboard.writeText(props.secretPhrase);
        setCopySuccess(true);

        setTimeout(() => {
            setCopySuccess(false);
        }, 3000);
    };

    const onClickNext = async () => {
        if (props.id) {
            await nftState.confirmWallet(props.id);
            // props.setModal({ component: SelectNFTToMint });
            if (AppState?.activeProject?.id) {
                props.setModal({ component: AddNFTDetails, props: { mintProjectId: AppState?.activeProject.id, projectUrl: props.projectUrl } });
            }
        }
    };

    return (
        <ContentWrapper>
            <Wrapper align='center'>
                <Title size='xxl' align='center' margin='lg'>
                    Save this phrase.
                </Title>
                <Wrapper maxWidth='490px' align='center'>
                    <Description size='sm' align='center' margin='md'>
                        You’re encrypted.
                        <br />
                        Please copy & paste this mnemonic security phrase somewhere safe.
                        <br />
                        You will use it if you need to recover your wallet.
                    </Description>
                </Wrapper>
                <GradientWrapper row maxWidth='362px' valign='center' color='white' style={ { justifyContent: 'space-between' } }>
                    <Description>{ props.secretPhrase }</Description>
                    <Wrapper row align='right' maxWidth='110'>
                        <Divider transparent style={ { width: 16 } } />
                        <CopyButton
                            $padding="0 23px"
                            $justify="center"
                            $width="92px"
                            $height="40px"
                            onClick={ copyToClipboard }
                            label={ copySuccess ? 'COPIED' : 'COPY' }
                        />
                    </Wrapper>
                </GradientWrapper>
                <Divider transparent style={ { height: 60 } } />
                <Wrapper row fullWidth align='center'>
                    <RaisedButton
                        $gradient
                        $padding="0 23px"
                        $justify="space-between"
                        $width="141px"
                        $height="61px"
                        onClick={ () => onClickNext() }
                        text="NEXT"
                        iconElement={ (
                            <NextArrow width="30" height="30" />
                        ) }
                    />
                </Wrapper>
            </Wrapper>
        </ContentWrapper>
    );
});
