import * as React                           from "react";
import { Constants }                        from "@babylonjs/core/Engines/constants";
import { Engine }                           from "@babylonjs/core/Engines/engine";
import { Material }                         from "@babylonjs/core/Materials/material";
import { PBRMaterial }                      from "@babylonjs/core/Materials/PBR/pbrMaterial";
import type { Observable }                  from "@babylonjs/core/Misc/observable";
import { ButtonLineComponent }              from "@geenee/geespector-ui-components/src/lines/buttonLineComponent";
import { CheckBoxLineComponent }            from "@geenee/geespector-ui-components/src/lines/checkBoxLineComponent";
import { FloatLineComponent }               from "@geenee/geespector-ui-components/src/lines/floatLineComponent";
import { HexLineComponent }                 from "@geenee/geespector-ui-components/src/lines/hexLineComponent";
import { LineContainerComponent }           from "@geenee/geespector-ui-components/src/lines/lineContainerComponent";
import { Null_Value, OptionsLineComponent } from "@geenee/geespector-ui-components/src/lines/optionsLineComponent";
import { SliderLineComponent }              from "@geenee/geespector-ui-components/src/lines/sliderLineComponent";
import { TextInputLineComponent }           from "@geenee/geespector-ui-components/src/lines/textInputLineComponent";
import { TextLineComponent }                from "@geenee/geespector-ui-components/src/lines/textLineComponent";
import type { LockObject }                  from "@geenee/geespector-ui-components/src/tabs/propertyGrids/lockObject";
import type { GlobalState }                 from "../../../../globalState";
import type { PropertyChangedEvent }        from "../../../../propertyChangedEvent";
import { ActionTabSectionComponent }        from '../../../actionTabSectionComponent';
import { ShowMoreSectionComponent }         from "../../../showMoreSectionComponent";
import { AnimationGridComponent }           from "../animations/animationPropertyGridComponent";
import { CustomPropertyGridComponent }      from "../customPropertyGridComponent";

interface ICommonMaterialPropertyGridComponentProps {
    globalState: GlobalState;
    material: Material;
    lockObject: LockObject;
    onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
}

export class CommonMaterialPropertyGridComponent extends React.Component<ICommonMaterialPropertyGridComponentProps> {
    constructor(props: ICommonMaterialPropertyGridComponentProps) {
        super(props);
    }

    render() {
        const { material } = this.props;

        material.depthFunction = material.depthFunction ?? 0;

        const orientationOptions = [
            { label: "Clockwise", value: Material.ClockWiseSideOrientation },
            { label: "Counterclockwise", value: Material.CounterClockWiseSideOrientation }
        ];

        const transparencyModeOptions = [
            { label: "<Not Defined>", value: Null_Value },
            { label: "Opaque", value: PBRMaterial.PBRMATERIAL_OPAQUE },
            { label: "Alpha test", value: PBRMaterial.PBRMATERIAL_ALPHATEST },
            { label: "Alpha blend", value: PBRMaterial.PBRMATERIAL_ALPHABLEND },
            { label: "Alpha blend and test", value: PBRMaterial.PBRMATERIAL_ALPHATESTANDBLEND }
        ];

        const alphaModeOptions = [
            { label: "Combine", value: Constants.ALPHA_COMBINE },
            { label: "One one", value: Constants.ALPHA_ONEONE },
            { label: "Add", value: Constants.ALPHA_ADD },
            { label: "Subtract", value: Constants.ALPHA_SUBTRACT },
            { label: "Multiply", value: Constants.ALPHA_MULTIPLY },
            { label: "Maximized", value: Constants.ALPHA_MAXIMIZED },
            { label: "Pre-multiplied", value: Constants.ALPHA_PREMULTIPLIED }
        ];

        const depthfunctionOptions = [
            { label: "<Engine Default>", value: 0 },
            { label: "Never", value: Engine.NEVER },
            { label: "Always", value: Engine.ALWAYS },
            { label: "Equal", value: Engine.EQUAL },
            { label: "Less", value: Engine.LESS },
            { label: "Less or equal", value: Engine.LEQUAL },
            { label: "Greater", value: Engine.GREATER },
            { label: "Greater or equal", value: Engine.GEQUAL },
            { label: "Not equal", value: Engine.NOTEQUAL }
        ];

        const stencilFunctionOptions = [
            { label: "Never", value: Constants.NEVER },
            { label: "Always", value: Constants.ALWAYS },
            { label: "Equal", value: Constants.EQUAL },
            { label: "Less", value: Constants.LESS },
            { label: "Less or equal", value: Constants.LEQUAL },
            { label: "Greater", value: Constants.GREATER },
            { label: "Greater or equal", value: Constants.GEQUAL },
            { label: "Not equal", value: Constants.NOTEQUAL }
        ];

        const stencilOperationOptions = [
            { label: "Keep", value: Constants.KEEP },
            { label: "Zero", value: Constants.ZERO },
            { label: "Replace", value: Constants.REPLACE },
            { label: "Incr", value: Constants.INCR },
            { label: "Decr", value: Constants.DECR },
            { label: "Invert", value: Constants.INVERT },
            { label: "Incr wrap", value: Constants.INCR_WRAP },
            { label: "Decr wrap", value: Constants.DECR_WRAP }
        ];

        return (
            <>
                <CustomPropertyGridComponent
                    globalState={ this.props.globalState }
                    target={ material }
                    lockObject={ this.props.lockObject }
                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                />
                <ActionTabSectionComponent title="GENERAL">
                    { this.props.globalState.isDevMode && <TextLineComponent label="ID" value={ material.id } /> }
                    <TextInputLineComponent
                        lockObject={ this.props.lockObject }
                        label="Name"
                        target={ material }
                        propertyName="name"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                    <SliderLineComponent
                        label="Alpha"
                        target={ material }
                        propertyName="alpha"
                        minimum={ 0 }
                        maximum={ 1 }
                        step={ 0.01 }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                    <ShowMoreSectionComponent>
                        { this.props.globalState.isDevMode && <TextLineComponent label="Unique ID" value={ material.uniqueId.toString() } /> }
                        <TextLineComponent label="Class" value={ material.getClassName() } />
                        <CheckBoxLineComponent
                            label="Backface culling"
                            target={ material }
                            propertyName="backFaceCulling"
                            onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                        />
                        <OptionsLineComponent
                            label="Orientation"
                            options={ orientationOptions }
                            target={ material }
                            propertyName="sideOrientation"
                            onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                            onSelect={ (value) => this.setState({ mode: value }) }
                        />
                        <CheckBoxLineComponent
                            label="Disable lighting"
                            target={ material }
                            propertyName="disableLighting"
                            onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                        />
                        <CheckBoxLineComponent
                            label="Disable color write"
                            target={ material }
                            propertyName="disableColorWrite"
                            onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                        />
                        <CheckBoxLineComponent
                            label="Disable depth write"
                            target={ material }
                            propertyName="disableDepthWrite"
                            onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                        />
                        <OptionsLineComponent
                            label="Depth function"
                            options={ depthfunctionOptions }
                            target={ material }
                            propertyName="depthFunction"
                            onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                            onSelect={ (value) => this.setState({ depthFunction: value }) }
                        />
                        { this.props.globalState.isDevMode
                            ? (
                                <>
                                    <CheckBoxLineComponent
                                        label="Need depth pre-pass"
                                        target={ material }
                                        propertyName="needDepthPrePass"
                                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                                    />
                                    <CheckBoxLineComponent label="Wireframe" target={ material } propertyName="wireframe" onPropertyChangedObservable={ this.props.onPropertyChangedObservable } />
                                    <CheckBoxLineComponent label="Point cloud" target={ material } propertyName="pointsCloud" onPropertyChangedObservable={ this.props.onPropertyChangedObservable } />
                                    <SliderLineComponent
                                        label="Point size"
                                        target={ material }
                                        propertyName="pointSize"
                                        minimum={ 0 }
                                        maximum={ 100 }
                                        step={ 0.1 }
                                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                                    />
                                    <SliderLineComponent
                                        label="Z-offset Factor"
                                        target={ material }
                                        propertyName="zOffset"
                                        minimum={ -10 }
                                        maximum={ 10 }
                                        step={ 0.1 }
                                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                                    />
                                    <SliderLineComponent
                                        label="Z-offset Units"
                                        target={ material }
                                        propertyName="zOffsetUnits"
                                        minimum={ -10 }
                                        maximum={ 10 }
                                        step={ 0.1 }
                                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                                    />
                                    <ButtonLineComponent
                                        label="Dispose"
                                        onClick={ () => {
                                            material.dispose();
                                            this.props.globalState.onSelectionChangedObservable.notifyObservers(null);
                                        } }
                                    />
                                </>
                            ) : <></> }
                    </ShowMoreSectionComponent>
                </ActionTabSectionComponent>
                <ActionTabSectionComponent collapsed title="TRANSPARENCY">
                    { this.props.globalState.isDevMode && (material as any).transparencyMode !== undefined && (
                        <OptionsLineComponent
                            allowNullValue
                            label="Transparency mode"
                            options={ transparencyModeOptions }
                            target={ material }
                            propertyName="transparencyMode"
                            onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                            onSelect={ (value) => this.setState({ transparencyMode: value }) }
                        />
                    ) }
                    { this.props.globalState.isDevMode && (
                        <OptionsLineComponent
                            label="Alpha mode"
                            options={ alphaModeOptions }
                            target={ material }
                            propertyName="alphaMode"
                            onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                            onSelect={ (value) => this.setState({ alphaMode: value }) }
                        />
                    ) }
                    { (material as any).diffuseTexture && (
                        <CheckBoxLineComponent
                            label="Diffuse texture alpha"
                            target={ (material as any).diffuseTexture }
                            propertyName="hasAlpha"
                            onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                        />
                    ) }
                    { (material as any).useAlphaFromDiffuseTexture !== undefined && (
                        <CheckBoxLineComponent
                            label="Use alpha from diffuse texture"
                            target={ material }
                            propertyName="useAlphaFromDiffuseTexture"
                            onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                        />
                    ) }
                    { (material as any).albedoTexture && (
                        <CheckBoxLineComponent
                            label="Albedo texture alpha"
                            target={ (material as any).albedoTexture }
                            propertyName="hasAlpha"
                            onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                        />
                    ) }
                    { (material as any).useAlphaFromAlbedoTexture !== undefined && (
                        <CheckBoxLineComponent
                            label="Use alpha from albedo texture"
                            target={ material }
                            propertyName="useAlphaFromAlbedoTexture"
                            onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                        />
                    ) }
                    <ShowMoreSectionComponent>
                        <CheckBoxLineComponent
                            label="Separate culling pass"
                            target={ material }
                            propertyName="separateCullingPass"
                            onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                        />
                    </ShowMoreSectionComponent>
                </ActionTabSectionComponent>
                { material.stencil && this.props.globalState.isDevMode && (
                    <>
                        <LineContainerComponent title="STENCIL" selection={ this.props.globalState }>
                            <CheckBoxLineComponent
                                label="Enabled"
                                target={ material.stencil }
                                propertyName="enabled"
                                onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                            />
                            <HexLineComponent
                                isInteger
                                lockObject={ this.props.lockObject }
                                label="Mask"
                                target={ material.stencil }
                                propertyName="mask"
                                onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                            />
                            <OptionsLineComponent
                                label="Function"
                                options={ stencilFunctionOptions }
                                target={ material.stencil }
                                propertyName="func"
                                onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                                onSelect={ (value) => this.setState({ stencilFunction: value }) }
                            />
                            <FloatLineComponent
                                isInteger
                                lockObject={ this.props.lockObject }
                                label="Function reference"
                                target={ material.stencil }
                                propertyName="funcRef"
                                onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                            />
                            <HexLineComponent
                                isInteger
                                lockObject={ this.props.lockObject }
                                label="Function mask"
                                target={ material.stencil }
                                propertyName="funcMask"
                                onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                            />
                            <OptionsLineComponent
                                label="Op stencil fail"
                                options={ stencilOperationOptions }
                                target={ material.stencil }
                                propertyName="opStencilFail"
                                onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                                onSelect={ (value) => this.setState({ opStencilFail: value }) }
                            />
                            <OptionsLineComponent
                                label="Op depth fail"
                                options={ stencilOperationOptions }
                                target={ material.stencil }
                                propertyName="opDepthFail"
                                onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                                onSelect={ (value) => this.setState({ opDepthFail: value }) }
                            />
                            <OptionsLineComponent
                                label="Op stencil+depth pass"
                                options={ stencilOperationOptions }
                                target={ material.stencil }
                                propertyName="opStencilDepthPass"
                                onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                                onSelect={ (value) => this.setState({ opStencilDepthPass: value }) }
                            />
                        </LineContainerComponent>
                    </>
                ) }
                <AnimationGridComponent globalState={ this.props.globalState } animatable={ material } scene={ material.getScene() } lockObject={ this.props.lockObject } />
            </>
        );
    }
}
