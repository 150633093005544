import { BypassHistoryCommandType, CommandType } from "@geenee/shared/src/commander/types";
import { injectable }                            from "inversify";
import { computed, makeAutoObservable, toJS }    from "mobx";

@injectable()
export class SceneCommander {
    history: CommandType[];
    currentIdx = -1;
    constructor() {
        this.history = [];
        window.addEventListener('keydown', (e) => this.selectActionByHotkey(e));
        makeAutoObservable(this);
    }

    startRecordHistory() {
        this.history = [];
        this.currentIdx = -1;
    }
    executeCommand = (command: CommandType & BypassHistoryCommandType) => {
        command.execute();
        if (command.bypassHistory) {
            return;
        }
        this.history.splice(this.currentIdx + 1);
        this.history.push(command);
        this.currentIdx = this.history.length - 1;
    };

    selectActionByHotkey(keyEvent: KeyboardEvent) {
        if (!keyEvent || keyEvent?.target?.className?.includes("one-ui-input")) {
            return;
        }
        if (keyEvent.code === 'KeyZ' && (keyEvent.ctrlKey || keyEvent.metaKey) && keyEvent.shiftKey) {
            keyEvent.preventDefault();
            this.forwardCommand();
        } else if (keyEvent.code === 'KeyZ' && (keyEvent.ctrlKey || keyEvent.metaKey)) {
            keyEvent.preventDefault();
            this.revertCommand();
        }
    }

    revertCommand = () => {
        if (this.currentIdx >= 0) {
            const command = this.history[ this.currentIdx ];
            command.revert();
            this.currentIdx -= 1;
        }
    };

    forwardCommand = () => {
        if (this.currentIdx < this.history.length - 1) {
            const command = this.history[ this.currentIdx + 1 ];
            command.execute();
            this.currentIdx += 1;
        }
    };

    @computed get hasRevert() {
        return this.currentIdx >= 0;
    }

    @computed get hasForward() {
        return this.currentIdx < this.history.length - 1;
    }

    getCommandsStack = () => toJS(this.history);
}
export type SceneCommanderType = SceneCommander;
