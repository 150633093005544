import { action, makeAutoObservable, observable, set } from 'mobx';

export class InviteModel {
  @observable code = '';
  @observable email = '';
  @observable first_name = '';
  @observable last_name = '';
  @observable has_restricted_projects = false;
  @observable restricted_projects_ids = [];
  @observable role = '';
  @observable id = '';

  constructor() {
      makeAutoObservable(this);
  }

  @action
  init(data: Partial<InviteModel>) {
      Object.keys(data).forEach((key) => {
          set(this, key, data[ key as keyof InviteModel ]);
      });
  }
}
