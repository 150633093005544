import { TranspiledModule }                        from "@codesandbox/sandpack-client/dist/types/types";
import { Engine }                                  from "@geenee/armature";
import { CustomCodeAttachmentModel }               from "@geenee/shared/src/magellan/atom/custom-module-atom/model/custom-code-attachment.model";
import { AtomModel }                               from "@geenee/shared/src/magellan/atom/model/atom.model";
import { SlamRenderer }                            from "@geenee/shared/src/magellan/renderer/r3f-renderer/slam.renderer";
import { injectable, postConstruct }               from "inversify";
import { computed, observable, runInAction, when } from "mobx";
import * as THREE                                  from "three";
import { GLTFLoader }                              from "three/examples/jsm/loaders/GLTFLoader";

@injectable()
export class CustomModuleAtomModel extends AtomModel {
  @observable transpiledModules!: Record<string, TranspiledModule> | undefined;
  @observable type = "scene-code-module";
  @observable shouldRun = false;

  @computed
  get customCodeAttachment(): CustomCodeAttachmentModel | undefined {
      return Array.from(this.assetsRegistry.values())[ 0 ] as CustomCodeAttachmentModel;
  }

  @postConstruct()
  async init() {
      when(() => this.customCodeAttachment?.module?.exports && this.customCodeAttachment?.module?.exports?.getRendererClass && this.shouldRun, () => {
          this.replaceCurrentRenderer();
      });
      this.emitter.on(
          "runCustomCode",
          async () => {
              runInAction(() => {
                  this.shouldRun = true;
              });
          }
      );
  }

  replaceCurrentRenderer = async () => {
      const { customCodeAttachment } = this;
      if (!customCodeAttachment) {
          return;
      }
      const { module } = customCodeAttachment;
      if (!module) {
          return;
      }

      const sceneManager = this.$parent.$parent.sceneManager as unknown as Engine<any, any, any>;

      const currentlyRendererRendererToInheritAndReplace = sceneManager.sceneRenderer as SlamRenderer;

      if (!currentlyRendererRendererToInheritAndReplace) {
          return;
      }

      const { getRendererClass } = module.exports;

      // todo: reffactoer
      const { customRenderer, redbullRenderer } = getRendererClass(
          currentlyRendererRendererToInheritAndReplace.canvas,
          currentlyRendererRendererToInheritAndReplace.canvasContext,
          currentlyRendererRendererToInheritAndReplace.renderer,
          currentlyRendererRendererToInheritAndReplace.scene,
          currentlyRendererRendererToInheritAndReplace.camera,
          currentlyRendererRendererToInheritAndReplace.activeSceneModel,
          SlamRenderer,
          THREE,
          GLTFLoader,
          this.customCodeAttachment.options
      );
      sceneManager.removeRenderer(currentlyRendererRendererToInheritAndReplace);

      console.log({ customRenderer, redbullRenderer });

      await sceneManager.addRenderer(customRenderer || redbullRenderer);
      // todo: reffactoer
      document.getElementById("preloader").style.display = "none";
      console.log({ sceneManager });
  };

  runCustomCode = async (...args) => {
      if (process.env.ENV_GEENEE_APP !== "BUILDER") {
          console.log("runCustomCode");
          this.emitter.emit("runCustomCode");
      }
      // @ts-ignore
      super.runCustomCode(
          ...args
      );
  };
}

export type CustomModuleAtomModelType = CustomModuleAtomModel;
