import React from 'react';

export function UploadCloud({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="40" viewBox="0 0 44 40" fill="none">
            <path
                className="fill"
                fill="#222225"
                d="M11.5 14C11.5 8.20101 16.201 3.5 22 3.5C27.799 3.5 32.5 8.20101 32.5 14C32.5 14.0585 32.4995 14.1169 32.4986 14.1753C32.4868 14.8979 32.9919 15.5261 33.7002 15.6698C37.5801 16.4567 40.5 19.889 40.5 24C40.5 28.6944 36.6944 32.5 32 32.5H29C28.1716 32.5 27.5 33.1716 27.5 34C27.5 34.8284 28.1716 35.5 29 35.5H32C38.3513 35.5 43.5 30.3513 43.5 24C43.5 18.8555 40.1232 14.5025 35.4658 13.0319C34.9695 6.0279 29.1303 0.5 22 0.5C14.8697 0.5 9.03047 6.0279 8.53418 13.0319C3.8768 14.5025 0.5 18.8555 0.5 24C0.5 30.3513 5.64873 35.5 12 35.5H15C15.8284 35.5 16.5 34.8284 16.5 34C16.5 33.1716 15.8284 32.5 15 32.5H12C7.30558 32.5 3.5 28.6944 3.5 24C3.5 19.889 6.41991 16.4567 10.2998 15.6698C11.0081 15.5261 11.5132 14.8978 11.5014 14.1752C11.5005 14.117 11.5 14.0585 11.5 14Z"
            />
            <path
                className="fill"
                fill="#222225"
                d="M16.9393 27.0607C16.3536 26.4749 16.3536 25.5251 16.9393 24.9393L20.9393 20.9393C21.5251 20.3536 22.4749 20.3536 23.0607 20.9393L27.0607 24.9393C27.6464 25.5251 27.6464 26.4749 27.0607 27.0607C26.4749 27.6464 25.5251 27.6464 24.9393 27.0607L23.5 25.6213V38C23.5 38.8284 22.8284 39.5 22 39.5C21.1716 39.5 20.5 38.8284 20.5 38V25.6213L19.0607 27.0607C18.4749 27.6464 17.5251 27.6464 16.9393 27.0607Z"
            />
            { children }
        </svg>

    );
}

export function LibraryIcon({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                className="fill"
                d="M9 11.75L5 11.75C4.58579 11.75 4.25 11.4142 4.25 11C4.25 10.5858 4.58579 10.25 5 10.25L9 10.25C9.41421 10.25 9.75 10.5858 9.75 11C9.75 11.4142 9.41422 11.75 9 11.75Z"
                fill="#222225"
            />
            <path
                className="fill"
                d="M9 15.75L5 15.75C4.58579 15.75 4.25 15.4142 4.25 15C4.25 14.5858 4.58579 14.25 5 14.25L9 14.25C9.41421 14.25 9.75 14.5858 9.75 15C9.75 15.4142 9.41422 15.75 9 15.75Z"
                fill="#222225"
            />
            <path
                className="fill"
                d="M15 11.75L19 11.75C19.4142 11.75 19.75 11.4142 19.75 11C19.75 10.5858 19.4142 10.25 19 10.25L15 10.25C14.5858 10.25 14.25 10.5858 14.25 11C14.25 11.4142 14.5858 11.75 15 11.75Z"
                fill="#222225"
            />
            <path
                className="fill"
                d="M15 15.75L19 15.75C19.4142 15.75 19.75 15.4142 19.75 15C19.75 14.5858 19.4142 14.25 19 14.25L15 14.25C14.5858 14.25 14.25 14.5858 14.25 15C14.25 15.4142 14.5858 15.75 15 15.75Z"
                fill="#222225"
            />
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 4.25C1.58579 4.25 1.25 4.58579 1.25 5V20C1.25 20.4142 1.58579 20.75 2 20.75H9.1459C9.99813 20.75 10.7772 21.2315 11.1584 21.9938L11.3255 22.328C11.3354 22.3483 11.3461 22.3681 11.3577 22.3873C11.405 22.4658 11.4652 22.5333 11.5345 22.5881C11.6038 22.643 11.6834 22.6861 11.7706 22.7141C11.8385 22.7359 11.9096 22.7481 11.9821 22.7499C11.998 22.7502 12.014 22.7501 12.03 22.7495C12.0983 22.7467 12.1653 22.7347 12.2294 22.7141C12.3166 22.6861 12.3962 22.643 12.4655 22.5881C12.5348 22.5333 12.595 22.4658 12.6423 22.3873C12.6539 22.3681 12.6646 22.3483 12.6745 22.328L12.8416 21.9938C13.2228 21.2315 14.0019 20.75 14.8541 20.75H22C22.4142 20.75 22.75 20.4142 22.75 20V5C22.75 4.58579 22.4142 4.25 22 4.25H16C14.3205 4.25 12.8446 5.12162 12 6.43718C11.1554 5.12162 9.67947 4.25 8 4.25H2ZM11.25 9C11.25 7.20507 9.79493 5.75 8 5.75H2.75V19.25H9.1459C9.9108 19.25 10.6403 19.4827 11.25 19.8959V9ZM12.75 19.8959C13.3597 19.4827 14.0892 19.25 14.8541 19.25H21.25V5.75H16C14.2051 5.75 12.75 7.20507 12.75 9V19.8959Z"
                fill="#222225"
            />
            { children }
        </svg>
    );
}

export function PointLight({ children }:{children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 1.25C7.71979 1.25 4.25 4.71979 4.25 9C4.25 11.2185 5.18315 13.2203 6.67624 14.6321C7.04694 14.9826 7.25 15.3963 7.25 15.7887V16C7.25 16.854 7.63925 17.617 8.25 18.1214V19C8.25 20.5188 9.48122 21.75 11 21.75H13C14.5188 21.75 15.75 20.5188 15.75 19V18.1214C16.3608 17.617 16.75 16.854 16.75 16V15.7887C16.75 15.3963 16.9531 14.9826 17.3238 14.6321C18.8169 13.2203 19.75 11.2185 19.75 9C19.75 4.71979 16.2802 1.25 12 1.25ZM5.75 9C5.75 5.54822 8.54822 2.75 12 2.75C15.4518 2.75 18.25 5.54822 18.25 9C18.25 10.7892 17.4991 12.4019 16.2932 13.5422C15.7138 14.09 15.25 14.8736 15.25 15.7887V16C15.25 16.6904 14.6904 17.25 14 17.25H10C9.30964 17.25 8.75 16.6904 8.75 16V15.7887C8.75 14.8736 8.28624 14.09 7.70682 13.5422C6.50088 12.4019 5.75 10.7892 5.75 9ZM14.25 18.7388C14.1677 18.7462 14.0843 18.75 14 18.75H10C9.91573 18.75 9.83234 18.7462 9.75 18.7388V19C9.75 19.6904 10.3096 20.25 11 20.25H13C13.6904 20.25 14.25 19.6904 14.25 19V18.7388Z"
                fill="#3E3B46"
            />
            { children }
        </svg>

    );
}

export function DirectLight({ children }:{children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M10 2.25C5.71979 2.25 2.25 5.71979 2.25 10C2.25 14.2802 5.71979 17.75 10 17.75C14.2802 17.75 17.75 14.2802 17.75 10C17.75 5.71979 14.2802 2.25 10 2.25ZM3.75 10C3.75 6.54822 6.54822 3.75 10 3.75C13.4518 3.75 16.25 6.54822 16.25 10C16.25 13.4518 13.4518 16.25 10 16.25C6.54822 16.25 3.75 13.4518 3.75 10Z" fill="#3E3B46" />
            <path className="fill" d="M17.4697 18.5303C17.1768 18.2374 17.1768 17.7626 17.4697 17.4697C17.7626 17.1768 18.2374 17.1768 18.5303 17.4697L21.5303 20.4697C21.8232 20.7626 21.8232 21.2374 21.5303 21.5303C21.2374 21.8232 20.7626 21.8232 20.4697 21.5303L17.4697 18.5303Z" fill="#3E3B46" />
            <path className="fill" d="M18.9697 13.9697C18.6768 14.2626 18.6768 14.7374 18.9697 15.0303L21.4697 17.5303C21.7626 17.8232 22.2374 17.8232 22.5303 17.5303C22.8232 17.2374 22.8232 16.7626 22.5303 16.4697L20.0303 13.9697C19.7374 13.6768 19.2626 13.6768 18.9697 13.9697Z" fill="#3E3B46" />
            <path className="fill" d="M13.9697 20.0303C13.6768 19.7374 13.6768 19.2626 13.9697 18.9697C14.2626 18.6768 14.7374 18.6768 15.0303 18.9697L17.5303 21.4697C17.8232 21.7626 17.8232 22.2374 17.5303 22.5303C17.2374 22.8232 16.7626 22.8232 16.4697 22.5303L13.9697 20.0303Z" fill="#3E3B46" />
            { children }
        </svg>

    );
}

export function SpotLight({ children }:{children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M7 2.25C4.37665 2.25 2.25 4.37665 2.25 7C2.25 9.62335 4.37665 11.75 7 11.75C9.62335 11.75 11.75 9.62335 11.75 7C11.75 4.37665 9.62335 2.25 7 2.25ZM3.75 7C3.75 5.20507 5.20507 3.75 7 3.75C8.79493 3.75 10.25 5.20507 10.25 7C10.25 8.79493 8.79493 10.25 7 10.25C5.20507 10.25 3.75 8.79493 3.75 7Z" fill="#3E3B46" />
            <path className="fill" d="M13.8515 9.83741C13.4855 9.64337 13.0316 9.78273 12.8375 10.1487C12.6435 10.5146 12.7829 10.9686 13.1488 11.1626L19.8756 14.7294C20.5113 15.0664 20.6778 15.7985 20.3362 16.288C19.2364 17.864 17.8643 19.2361 16.2883 20.3359C15.7988 20.6775 15.0668 20.5109 14.7297 19.8753L11.1628 13.1487C10.9687 12.7827 10.5147 12.6434 10.1488 12.8374C9.78284 13.0315 9.64349 13.4854 9.83755 13.8514L13.4045 20.578C14.105 21.8991 15.8199 22.4919 17.1467 21.566C18.8675 20.3651 20.3655 18.8672 21.5663 17.1464C22.4922 15.8195 21.8994 14.1047 20.5783 13.4042L13.8515 9.83741Z" fill="#3E3B46" />
            { children }
        </svg>
    );
}

export function StandardMaterial({ children }:{children?: React.ReactNode}) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M10.5577 3.36991C11.4562 2.8767 12.5442 2.8767 13.4427 3.36991L20.481 7.23331C20.8054 7.41135 21.0051 7.75408 21.0001 8.12434C20.9952 8.49458 20.7864 8.83185 20.4574 9.00115L12.9147 12.8827C12.3407 13.1781 11.6597 13.1781 11.0857 12.8827L3.54293 9.00115C3.21394 8.83185 3.00519 8.49458 3.00025 8.12434C2.99531 7.75408 3.19497 7.41135 3.51933 7.23331L10.5577 3.36991ZM12.481 5.1252C12.1815 4.96079 11.8189 4.96079 11.5194 5.1252L6.13184 8.08244L12.0002 11.1023L17.8685 8.08244L12.481 5.1252Z" fill="#3E394B" />
            <path className="fill" d="M3.10579 11.7801C3.35278 11.2857 3.95345 11.0853 4.44743 11.3324L12.0002 15.1124L19.553 11.3324C20.0469 11.0853 20.6476 11.2857 20.8946 11.7801C21.1416 12.2745 20.9414 12.8758 20.4474 13.123L12.8946 16.903C12.3315 17.1848 11.6688 17.1848 11.1057 16.903L3.553 13.123C3.05902 12.8758 2.8588 12.2745 3.10579 11.7801Z" fill="#3E394B" />
            <path className="fill" d="M3.10579 15.7839C3.35278 15.2895 3.95345 15.0891 4.44743 15.3362L11.553 18.8924C11.8345 19.0333 12.1659 19.0333 12.4474 18.8924L19.553 15.3362C20.0469 15.0891 20.6476 15.2895 20.8946 15.7839C21.1416 16.2783 20.9414 16.8796 20.4474 17.1268L13.3418 20.683C12.4972 21.1057 11.5031 21.1057 10.6585 20.683L3.553 17.1268C3.05902 16.8796 2.8588 16.2783 3.10579 15.7839Z" fill="#3E394B" />
            { children }
        </svg>
    );
}

export function PBRMaterial({ children }:{children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path className="fill" d="M11.6319 4.65369C11.2709 4.45062 11.1429 3.99333 11.346 3.63232C11.549 3.2713 12.0063 3.14326 12.3673 3.34633L20.3673 7.84633C20.7284 8.0494 20.8564 8.50669 20.6533 8.86771C20.4503 9.22873 19.993 9.35677 19.632 9.15369L11.6319 4.65369Z" fill="#3E3B46" />
            <path className="fill" d="M7.34596 5.63231C7.14289 5.99333 7.27093 6.45062 7.63195 6.65369L15.6319 11.1537C15.993 11.3568 16.4503 11.2287 16.6533 10.8677C16.8564 10.5067 16.7284 10.0494 16.3673 9.84633L8.36734 5.34633C8.00632 5.14326 7.54904 5.2713 7.34596 5.63231Z" fill="#3E3B46" />
            <path className="fill" d="M3.34596 7.63232C3.14289 7.99333 3.27093 8.45062 3.63195 8.65369L11.6319 13.1537C11.993 13.3568 12.4503 13.2287 12.6533 12.8677C12.8564 12.5067 12.7284 12.0494 12.3673 11.8463L4.36734 7.34633C4.00632 7.14326 3.54904 7.2713 3.34596 7.63232Z" fill="#3E3B46" />
            <path className="fill" d="M20.6533 11.6323C20.4503 11.2713 19.993 11.1433 19.632 11.3463L13.1027 15.019C12.4178 15.4043 11.5815 15.4043 10.8966 15.019L4.36734 11.3463C4.00632 11.1433 3.54904 11.2713 3.34596 11.6323C3.14289 11.9933 3.27093 12.4506 3.63195 12.6537L10.1612 16.3264C11.3027 16.9685 12.6966 16.9685 13.8381 16.3264L20.3673 12.6537C20.7284 12.4506 20.8564 11.9933 20.6533 11.6323Z" fill="#3E3B46" />
            <path className="fill" d="M19.632 15.3463C19.993 15.1433 20.4503 15.2713 20.6533 15.6323C20.8564 15.9933 20.7284 16.4506 20.3673 16.6537L13.8381 20.3264C12.6966 20.9685 11.3027 20.9685 10.1612 20.3264L3.63195 16.6537C3.27093 16.4506 3.14289 15.9933 3.34596 15.6323C3.54904 15.2713 4.00632 15.1433 4.36734 15.3463L10.8966 19.019C11.5815 19.4043 12.4178 19.4043 13.1027 19.019L19.632 15.3463Z" fill="#3E3B46" />
            { children }
        </svg>
    );
}

export function EmptyNode({ children }:{children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path className="fill" d="M6.53033 4.53033C6.82322 4.23744 6.82322 3.76256 6.53033 3.46967L4.53033 1.46967C4.45842 1.39776 4.37555 1.34351 4.28709 1.30691C4.19931 1.27051 4.10312 1.2503 4.00225 1.25L4 1.25L3.99775 1.25C3.80656 1.25057 3.61554 1.3238 3.46967 1.46967L1.46967 3.46967C1.17678 3.76256 1.17678 4.23744 1.46967 4.53033C1.76256 4.82322 2.23744 4.82322 2.53033 4.53033L3.25 3.81066V20C3.25 20.4142 3.58579 20.75 4 20.75H20.1893L19.4697 21.4697C19.1768 21.7626 19.1768 22.2374 19.4697 22.5303C19.7626 22.8232 20.2374 22.8232 20.5303 22.5303L22.5303 20.5303C22.6022 20.4584 22.6565 20.3755 22.6931 20.2871C22.7251 20.2099 22.7446 20.1262 22.749 20.0386C22.7497 20.0258 22.75 20.0129 22.75 20C22.75 19.8983 22.7298 19.8013 22.6931 19.7129C22.6565 19.6245 22.6022 19.5416 22.5303 19.4697L20.5303 17.4697C20.2374 17.1768 19.7626 17.1768 19.4697 17.4697C19.1768 17.7626 19.1768 18.2374 19.4697 18.5303L20.1893 19.25H4.75V3.81066L5.46967 4.53033C5.76256 4.82322 6.23744 4.82322 6.53033 4.53033Z" fill="#3E3B46" />
            <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M14 3.25C10.2721 3.25 7.25 6.27208 7.25 10C7.25 13.7279 10.2721 16.75 14 16.75C17.7279 16.75 20.75 13.7279 20.75 10C20.75 6.27208 17.7279 3.25 14 3.25ZM8.75 10C8.75 7.1005 11.1005 4.75 14 4.75C16.8995 4.75 19.25 7.1005 19.25 10C19.25 12.8995 16.8995 15.25 14 15.25C11.1005 15.25 8.75 12.8995 8.75 10Z" fill="#3E3B46" />
            { children }
        </svg>
    );
}

export function BodyTrackingAvatar({ children }:{children?: React.ReactNode}) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M12 2.25C9.37665 2.25 7.25 4.37665 7.25 7C7.25 9.0245 8.51654 10.7532 10.3005 11.4369C6.83777 12.2117 4.25 15.3036 4.25 19C4.25 20.5188 5.48122 21.75 7 21.75H17C18.5188 21.75 19.75 20.5188 19.75 19C19.75 15.3036 17.1622 12.2117 13.6995 11.4369C15.4835 10.7532 16.75 9.0245 16.75 7C16.75 4.37665 14.6234 2.25 12 2.25ZM8.75 7C8.75 5.20507 10.2051 3.75 12 3.75C13.7949 3.75 15.25 5.20507 15.25 7C15.25 8.79493 13.7949 10.25 12 10.25C10.2051 10.25 8.75 8.79493 8.75 7ZM5.75 19C5.75 15.5482 8.54822 12.75 12 12.75C15.4518 12.75 18.25 15.5482 18.25 19C18.25 19.6904 17.6904 20.25 17 20.25H7C6.30964 20.25 5.75 19.6904 5.75 19Z" fill="#3E3B46" />
            { children }
        </svg>
    );
}

export function BodyTrackingTwin({ children }:{children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M5.66667 9.33333C5.66667 5.83553 8.5022 3 12 3C15.4978 3 18.3333 5.83553 18.3333 9.33333C18.3333 12.0556 16.6158 14.3767 14.2053 15.2722C18.1097 16.2556 21 19.7903 21 24V25.3333C21 27.3584 19.3584 29 17.3333 29H6.66667C4.64162 29 3 27.3584 3 25.3333V24C3 19.7903 5.89028 16.2556 9.79471 15.2722C7.38418 14.3767 5.66667 12.0556 5.66667 9.33333ZM12 5C9.60677 5 7.66667 6.9401 7.66667 9.33333C7.66667 11.7266 9.60677 13.6667 12 13.6667C14.3932 13.6667 16.3333 11.7266 16.3333 9.33333C16.3333 6.9401 14.3932 5 12 5ZM5 24C5 20.134 8.13401 17 12 17C15.866 17 19 20.134 19 24V25.3333C19 26.2538 18.2538 27 17.3333 27H6.66667C5.74619 27 5 26.2538 5 25.3333V24Z" fill="#615B6F" />
            <path className="fill" d="M19 5.33333C19 4.78105 19.4477 4.33333 20 4.33333C23.4978 4.33333 26.3333 7.16886 26.3333 10.6667C26.3333 12.7346 25.3427 14.5699 23.8106 15.7256C27.6318 17.2424 30.3333 20.9718 30.3333 25.3333C30.3333 27.3584 28.6917 29 26.6667 29H24C23.4477 29 23 28.5523 23 28C23 27.4477 23.4477 27 24 27H26.6667C27.5871 27 28.3333 26.2538 28.3333 25.3333C28.3333 21.136 25.2292 17.662 21.1917 17.0844C20.6992 17.014 20.3333 16.5921 20.3333 16.0945V15.832C20.3333 15.3757 20.6422 14.9773 21.0841 14.8635C22.9533 14.3824 24.3333 12.6845 24.3333 10.6667C24.3333 8.27343 22.3932 6.33333 20 6.33333C19.4477 6.33333 19 5.88562 19 5.33333Z" fill="#615B6F" />
            { children }
        </svg>
    );
}

export const GeeneeLibraryIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <rect width="24" height="24" rx="4" fill="white" />
        <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M12 1C5.93226 1 1 5.93226 1 12C1 18.0677 5.93226 23 12 23C18.0677 23 23 18.0677 23 12C23 5.93226 18.0677 1 12 1ZM12 18.0677C8.62903 18.0677 5.93226 15.3355 5.93226 11.9645C5.93226 8.59355 8.66452 5.86129 12 5.86129C15.3355 5.86129 18.0677 8.62903 18.0677 12C18.0677 15.371 15.3355 18.1032 12 18.0677ZM12.0006 7.17407C9.33936 7.17407 7.21033 9.33859 7.21033 11.9999C7.21033 14.6612 9.33936 16.7902 12.0006 16.7902C14.6619 16.7902 16.791 14.6257 16.791 11.9644C16.791 9.3031 14.6265 7.17407 12.0006 7.17407ZM12.0006 16.0805C9.76517 16.0805 7.92 14.2708 7.92 11.9999C7.92 9.72891 9.72968 7.91923 12.0006 7.91923C14.2361 7.91923 16.0813 9.72891 16.0813 11.9999C16.0813 14.2708 14.2361 16.0805 12.0006 16.0805ZM8.87805 12.0355C8.87805 10.3323 10.2619 8.91296 11.9651 8.91296C13.7039 8.87748 15.0877 10.2613 15.0877 12.0001C15.0877 13.7033 13.7039 15.1226 12.0006 15.1226C10.2974 15.1226 8.87805 13.7388 8.87805 12.0355ZM10.3684 12.0001C10.3684 12.9226 11.0781 13.6323 11.9651 13.6323C12.8522 13.5968 13.5619 12.8872 13.5619 12.0001C13.5619 11.0775 12.8522 10.3678 11.9651 10.3678C11.0781 10.3678 10.3684 11.0775 10.3684 12.0001Z" fill="#3E3B46" />
    </svg>
);
