import React, { useEffect, useState }                   from 'react';
import { Description, FormItem, Input, InputTableItem } from '@geenee/ui';
import validateExternalUrl                              from '@geenee/builder/src/lib/validateExternalUrl';

interface Props {
  url: string;
  onUpdate: (value: string) => void;
  disabled?: boolean;
}

const validate = (value: string) => {
    if (!value) return 'required';
    if (!validateExternalUrl(value)) return 'notValid';
    return '';
};

export function IFrameUrlRow({ url, onUpdate, disabled }: Props) {
    const [ value, setValue ] = useState(url || '');
    const [ error, setError ] = useState('');
    useEffect(() => {
        setValue(url);
    }, [ url ]);
    return (
        <InputTableItem label='Page URL'>
            <FormItem
                autoFocusDetect
                error={
                    (error === 'required' && <Description color="shade-2">This field is required</Description>) || (
                        error === 'notValid' && <Description color="shade-2">Please add a valid Page URL</Description>)
                }
            >
                <Input
                    size="md"
                    fullWidth
                    onChange={ (e) => {
                        setValue(e.target.value);
                        const validatedError = validate(e.target.value);
                        if (!validatedError) {
                            onUpdate(e.target.value);
                        }
                        setError(validatedError);
                    } }
                    value={ value }
                    disabled={ disabled }
                />
            </FormItem>
        </InputTableItem>
    );
}
