import React                 from 'react';
import { observer }          from 'mobx-react';
import { IFrameUrlRow }      from '@geenee/builder/src/components/WizardForm/FormType/ContentMainForm/components/IFrameForm/IFrameUrlRow';
import { OpenInNewSwitcher } from '@geenee/builder/src/components/WizardForm/FormType/ContentMainForm/components/IFrameForm/OpenInNewSwitcher';
import { TYPE_IFRAME }       from '@geenee/builder/src/lib/constants';
import { AtomModel }         from '@geenee/builder/src/magellan/model/atom.model';
import { MoleculeModel }     from '@geenee/builder/src/magellan/model/molecule.model';

interface Props {
  type: string;
    disabled?: boolean;
    iframeMolecule?: MoleculeModel
}

export const IFrameForm = observer(({
    type,
    disabled,
    iframeMolecule
}: Props) => {
    const isIframeContent = type === TYPE_IFRAME;
    const iframeAtom = iframeMolecule?.getAtomByType('iframe') as AtomModel | undefined;
    if (!isIframeContent || !iframeMolecule || !iframeAtom) return <></>;
    const { atom_iframe_remote_url, atom_iframe_open_in_new } = iframeAtom?.options || {};

    const handleFieldChange = (value: string | boolean, field: string) => {
        iframeAtom.options[ field ] = value;
        iframeAtom.saveData();
    };

    return (
        <>
            <IFrameUrlRow
                url={ atom_iframe_remote_url || '' }
                onUpdate={ (value) => handleFieldChange(value, 'atom_iframe_remote_url') }
                disabled={ disabled }
            />
            <OpenInNewSwitcher
                open_in_new={ atom_iframe_open_in_new || false }
                onUpdate={ ((value) => handleFieldChange(value, 'atom_iframe_open_in_new')) }
                disabled={ disabled }
            />
        </>
    );
});
