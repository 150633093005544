import React, { useEffect }   from 'react';
import { useLocation }        from 'react-router';
import Zendesk                from 'react-zendesk';
import { Analytics }          from '@geenee/analytics';
import { useInject }          from '@geenee/shared';
import { Button, cn }         from '@geenee/ui';
import { REQUEST_BUG_OPEN }   from '@geenee/builder/src/lib/constants';
import envConfig              from '@geenee/builder/src/lib/envConfig';
import { useZenDeskSettings } from './hooks/useZenDeskSettings';
import './bugReport.styles.scss';

export function BugReportComponent() {
    const settings = useZenDeskSettings(window.location.href);
    const location = useLocation();
    const { container } = useInject();
    const analytics: Analytics = container.get('<Analytics>');

    useEffect(() => {
        // @ts-ignore
        if (window.zE) {
            // @ts-ignore
            window.zE(() => {
                // @ts-ignore
                window.zE.hide();
            });
        }
        // @ts-ignore
    }, [ window.zE ]);

    const className = cn('bug-report');

    const isLogin = location.pathname.includes('login')
      || location.pathname.includes('sign-up')
      || location.pathname.includes('forgot')
      || location.pathname.includes('reset-password')
      || location.pathname.includes('oauth');

    // @ts-ignore
    const clickHandler = () => {
        window.zE.activate({ hideOnClose: true });
        analytics.track(REQUEST_BUG_OPEN, { isOpen: true });
    };

    return (
        <>
            <Zendesk defer zendeskKey={ envConfig.ZENDESK_KEY } { ...settings } />
            { isLogin ? <></> : (
                <Button
                    className={ className('button') }
                    icon="pcBug"
                    iconAfter
                    iconMargin="off"
                    iconSize={ 21 }
                    // @ts-ignore
                    onClick={ clickHandler }
                >
                    REPORT A BUG
                </Button>
            ) }
        </>
    );
}
