import React, { FC, useRef }        from 'react';
import { preprocessLoadedObject }   from '@geenee/geeclient-kit/src/lib/component/scene/utils/sdk/SceneObjectsUtils';
import { AtomModel, SceneRenderer } from '@geenee/shared/index';
import { observer }                 from 'mobx-react';
import { SkeletonUtils }            from 'three-stdlib';
import GIFPlane                     from '../GIFPlane';
import SimpleGLTF                   from '../GLTF/SimpleGLTF';
import { HtmlComponent3d }          from '../HTML/html.component3d';
import ImagePlane                   from '../ImagePlane';
import { Text3D }                   from '../Text3D';
import VideoPlane                   from '../VideoPlane';

export type Object3DAssetsParserPropsType = {
    atomModel: AtomModel
    url?: string
    activeSceneModel: SceneRenderer
    setAssetLoaded?: (v: boolean) => void
}

window.SkeletonUtils = SkeletonUtils;

export const Object3DAssetsParser: FC<Object3DAssetsParserPropsType> = observer(({ atomModel, activeSceneModel, ...props }) => {
    const { assetUrl, isText3D } = atomModel;
    const fileType = assetUrl?.split('.')?.pop() || '';
    const fileTypeImgEnum = [ 'png', 'jpg', 'jpeg' ];
    const fileTypeGLBEnum = [ 'glb', 'gltf' ];
    const fileTypeGifEnum = [ 'gif' ];
    const fileTypeMP4Enum = [ 'mp4' ];
    const fileTypeHtmlEnum = [ 'html' ];

    const objectRef = useRef<any>();
    const visible = atomModel.sceneObjectVisible;
    const selectedAnimation = atomModel.options.scene_atom_selected_animation_index;

    const position = atomModel.options.scene_atom_position;
    const scale = atomModel.options.scene_atom_scale;
    const rotation = atomModel.options.scene_atom_rotation;

    const assetCallBack = () => {
        const object = objectRef.current;
        atomModel.runCustomCode(object, activeSceneModel);
        preprocessLoadedObject(object);
        props.setAssetLoaded && props.setAssetLoaded(true);
        setTimeout(() => {
            if (!activeSceneModel.isLoaded) {
                requestAnimationFrame(() => activeSceneModel.setLoaded(true));
            }
        }, 0);
    };

    return (
        <group
            ref={ objectRef }
            onClick={ () => {
            // @ts-ignore
                if (process.env.ENV_GEENEE_APP !== 'BUILDER') {
                    atomModel.onModelClick();
                }
            } }
        >
            { fileTypeGLBEnum.includes(fileType) && (
                <SimpleGLTF
                    atomModel={ atomModel }
                    onAssetLoad={ assetCallBack }
                    selectedAnimation={ selectedAnimation }
                    disableCastShadow={ atomModel.options.scene_atom_disable_cast_shadow }
                    disableReceiveShadow={ atomModel.options.scene_atom_disable_receive_shadow }
                    position={ position }
                    rotation={ rotation }
                    scale={ scale }
                    activeSceneModel={ activeSceneModel }
                />
            ) }
            { fileTypeImgEnum.includes(fileType) && (
                <ImagePlane
                    url={ assetUrl }
                    position={ position }
                    scale={ scale }
                    rotation={ rotation }
                    loadedCallback={ assetCallBack }
                    width={ 1 }
                    height={ 1 }
                    visible={ visible }
                />
            ) }
            { fileTypeGifEnum.includes(fileType) && (
                <GIFPlane
                    url={ assetUrl }
                    position={ position }
                    scale={ scale }
                    rotation={ rotation }
                    loadedCallback={ assetCallBack }
                    width={ 1 }
                    height={ 1 }
                    visible={ visible }
                />
            ) }
            { fileTypeMP4Enum.includes(fileType) && (
                <VideoPlane
                    url={ assetUrl }
                    position={ position }
                    scale={ scale }
                    rotation={ rotation }
                    loadedCallback={ assetCallBack }
                    visible={ visible }
                    width={ 1 }
                    height={ 1 }
                />
            ) }
            { isText3D && (
                <Text3D
                    atomModel={ atomModel }
                    position={ position }
                    scale={ scale }
                    visible={ visible }
                    rotation={ rotation }
                    loadedCallback={ assetCallBack }
                />
            ) }
            { fileTypeHtmlEnum.includes(fileType) && (
                <HtmlComponent3d
                    htmlContent={
                        `
                            <video style="width: 50px" className="player__video" playsInline="" autoplay preload="none" loop="loop">
		                        <source src="https://cs14.pikabu.ru/video/2021/08/10/1628568009210268076_460x258.mp4" type="video/mp4">
	                        </video>
                            `
                    }
                    url={ assetUrl }
                    position={ position }
                    scale={ scale }
                    rotation={ rotation }
                    loadedCallback={ assetCallBack }
                    selectedAnimation={ selectedAnimation }
                />
            ) }
        </group>
    );
});
