/* eslint-disable max-len */
import React from 'react';

export const Form = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g className="fill" opacity="0.3">
      <path
        className="fill"
        d="M12.2426 3.75736L13.6569 2.34315C12.2091 0.89543 10.2091 -1.93129e-07 8 0C3.58172 -5.67416e-07 5.67416e-07 3.58172 0 8C3.86258e-07 12.4183 3.58172 16 8 16C9.31762 16 10.5608 15.6815 11.6569 15.1172C12.3984 14.7354 13.0726 14.2411 13.6569 13.6569L12.2426 12.2426C11.1569 13.3284 9.65685 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C9.65685 2 11.1569 2.67157 12.2426 3.75736Z"
        fill="#303036"
      />
      <path
        className="fill"
        d="M10.4749 5.52513C9.8415 4.89175 8.9665 4.5 8 4.5C6.067 4.5 4.5 6.067 4.5 8C4.5 9.933 6.067 11.5 8 11.5C8.9665 11.5 9.8415 11.1082 10.4749 10.4749L9.41421 9.41421C9.29179 9.29179 9.18856 9.15745 9.10452 9.01492C8.83036 9.31312 8.43701 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.43701 6.5 8.83036 6.68688 9.10452 6.98508C9.18856 6.84255 9.29179 6.70821 9.41421 6.58579L10.4749 5.52513Z"
        fill="#303036"
      />
    </g>
    <path
      className="fill"
      d="M15.1464 2.85355L10.3536 7.64645C10.1583 7.84171 10.1583 8.15829 10.3536 8.35355L15.1464 13.1464C15.4614 13.4614 16 13.2383 16 12.7929V3.20711C16 2.76165 15.4614 2.53857 15.1464 2.85355Z"
      fill="#303036"
    />
  </svg>
);
export const Image = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      d="M15.8947 12.553L11.8947 4.55304C11.7417 4.24804 11.4447 4.04104 11.1057 4.00604C10.7677 3.96704 10.4327 4.11004 10.2197 4.37504L6.79168 8.65901L4.55469 7.16802C4.32669 7.01702 4.04569 6.96402 3.78069 7.02402C3.51369 7.08402 3.28269 7.25102 3.14269 7.48502L0.142699 12.485C-0.0433002 12.794 -0.0473002 13.178 0.129699 13.493C0.306699 13.807 0.639698 14 0.999697 14H14.9997C15.3467 14 15.6677 13.82 15.8507 13.525C16.0337 13.23 16.0487 12.862 15.8947 12.553Z"
      fill="#303036"
    />
    <path
      className="fill"
      opacity="0.3"
      d="M5.99963 3.99998C7.1042 3.99998 7.99962 3.10455 7.99962 1.99999C7.99962 0.895425 7.1042 0 5.99963 0C4.89506 0 3.99963 0.895425 3.99963 1.99999C3.99963 3.10455 4.89506 3.99998 5.99963 3.99998Z"
      fill="#303036"
    />
  </svg>
);
export const Clock = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      className="stroke"
      cx="8"
      cy="8"
      r="7"
      stroke="#303036"
      strokeWidth="2"
    />
    <path
      className="stroke"
      d="M8 4V8L9.5 9.5"
      stroke="#303036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      className="stroke"
      cx="8"
      cy="8"
      r="7.25"
      stroke="#303036"
      strokeWidth="1.5"
    />
  </svg>
);
export const Mail = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.75 3H13.25C13.7 3 14 3.28571 14 3.71429V4.48318C14 4.66917 13.8968 4.83979 13.732 4.92609L8.46927 7.68277C8.17574 7.83652 7.82512 7.83489 7.53304 7.67841L2.26389 4.85566C2.10141 4.76861 2 4.59924 2 4.41492V3.71429C2 3.28571 2.3 3 2.75 3ZM2 6.35714L7.625 9.35714C7.835 9.45714 8.165 9.45714 8.375 9.35714L14 6.35714V12.2857C14 12.7143 13.7 13 13.25 13H2.75C2.3 13 2 12.7143 2 12.2857V6.35714Z"
      fill="#303036"
    />
  </svg>
);
export const Phone = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      d="M13.7757 11.155L11.7896 9.15773C11.4891 8.85514 10.9999 8.85514 10.6987 9.15619L9.64059 10.2137C9.25011 10.6039 8.61727 10.6039 8.22679 10.2137L5.78937 7.77768C5.39857 7.38711 5.39857 6.75363 5.78937 6.36306L6.84896 5.30409C7.14942 5.0038 7.15019 4.51642 6.84973 4.21536L4.86207 2.22579C4.56084 1.92474 4.07317 1.92474 3.77194 2.22579L2.00391 3.99202C2.00343 3.9925 2.00268 3.99255 2.00215 3.99215C2.00126 3.99148 2 3.99213 2 3.99324C2.00142 9.38157 6.26245 13.7745 11.6009 13.9916C11.8658 14.0024 12.1207 13.8947 12.3082 13.7073L13.7742 12.2422C14.0746 11.9419 14.0754 11.4561 13.7757 11.155Z"
      fill="#303036"
    />
  </svg>
);
export const SubmitAction = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      className="fill"
      d="M9.60284 0.519304C9.80296 0.257634 10.197 0.257634 10.3972 0.519304L11.403 1.83451C11.5528 2.03035 11.823 2.08778 12.0395 1.96979L13.4933 1.17741C13.7826 1.01976 14.1426 1.18005 14.219 1.50049L14.6029 3.11112C14.6601 3.35095 14.8836 3.51331 15.1293 3.49357L16.7798 3.36103C17.1081 3.33466 17.3718 3.62751 17.3113 3.95132L17.0069 5.57888C16.9616 5.82123 17.0997 6.06044 17.3323 6.14237L18.8939 6.69258C19.2047 6.80205 19.3264 7.17684 19.1394 7.44803L18.1994 8.81109C18.0594 9.01405 18.0883 9.28875 18.2674 9.45818L19.4703 10.596C19.7096 10.8224 19.6684 11.2143 19.3872 11.386L17.9741 12.2489C17.7637 12.3774 17.6783 12.6401 17.773 12.8677L18.4091 14.3964C18.5357 14.7005 18.3386 15.0418 18.0119 15.0843L16.37 15.2978C16.1255 15.3296 15.9407 15.5349 15.9346 15.7813L15.8939 17.4366C15.8858 17.7659 15.567 17.9976 15.2513 17.9035L13.6645 17.4307C13.4282 17.3603 13.1759 17.4726 13.0701 17.6953L12.3596 19.1909C12.2183 19.4885 11.8328 19.5704 11.5827 19.3561L10.3253 18.2788C10.1381 18.1183 9.86189 18.1183 9.67467 18.2788L8.41733 19.3561C8.16718 19.5704 7.78172 19.4885 7.64037 19.1909L6.92993 17.6953C6.82414 17.4726 6.57181 17.3603 6.33552 17.4307L4.7487 17.9035C4.43299 17.9976 4.11417 17.7659 4.10608 17.4366L4.06537 15.7813C4.05931 15.5349 3.87449 15.3296 3.63 15.2978L1.98806 15.0843C1.66138 15.0418 1.46434 14.7005 1.5909 14.3964L2.22697 12.8677C2.32168 12.6401 2.23633 12.3774 2.0259 12.2489L0.612755 11.386C0.3316 11.2143 0.290408 10.8224 0.529725 10.596L1.73259 9.45818C1.9117 9.28875 1.94057 9.01405 1.8006 8.81109L0.860593 7.44803C0.673572 7.17684 0.795348 6.80205 1.10605 6.69258L2.66772 6.14237C2.90026 6.06044 3.03837 5.82123 2.99305 5.57888L2.68872 3.95132C2.62817 3.62751 2.89185 3.33466 3.22022 3.36103L4.87067 3.49357C5.11643 3.51331 5.33989 3.35095 5.39707 3.11112L5.78103 1.50049C5.85742 1.18005 6.21743 1.01976 6.50668 1.17741L7.96053 1.96979C8.17701 2.08778 8.44719 2.03035 8.59697 1.83451L9.60284 0.519304Z"
      fill="#C1C1C8"
    />
    <path
      className="fill"
      d="M14.54 8.35864L13.1814 7L9.29955 10.8818L7.35864 8.94091L6 10.2995L9.29955 13.5991L14.54 8.35864Z"
      fill="#454545"
    />
  </svg>
);
export const Text = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      d="M7 2C6.44772 2 6 2.44772 6 3V3C6 3.55228 6.44772 4 7 4H10V13C10 13.5523 10.4477 14 11 14V14C11.5523 14 12 13.5523 12 13V4H15C15.5523 4 16 3.55228 16 3V3C16 2.44772 15.5523 2 15 2H7Z"
      fill="#303036"
    />
    <path
      className="fill"
      opacity="0.3"
      d="M1 6C0.447715 6 0 6.44772 0 7V7C0 7.55228 0.447715 8 1 8H3V13C3 13.5523 3.44772 14 4 14V14C4.55228 14 5 13.5523 5 13V8H7C7.55228 8 8 7.55228 8 7V7C8 6.44772 7.55228 6 7 6H1Z"
      fill="#303036"
    />
  </svg>
);
export const Calendar = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.99994 6L-6.10352e-05 6V15C-6.10352e-05 15.552 0.447939 16 0.999939 16L14.9999 16C15.5519 16 15.9999 15.552 15.9999 15V6H13.9999L13.9999 13.2C13.9999 13.6416 13.6639 14 13.2499 14L2.74994 14C2.33594 14 1.99994 13.6416 1.99994 13.2L1.99994 6Z"
      fill="#303036"
    />
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2C2 1.44772 2.44772 1 3 1C3.55228 1 4 1.44772 4 2H12C12 1.44772 12.4477 1 13 1C13.5523 1 14 1.44772 14 2H15C15.552 2 16 2.448 16 3V6H0V3C0 2.448 0.448 2 1 2H2Z"
      fill="#303036"
    />
  </svg>
);
export const Map = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7.11544C2 3.70827 4.67581 1 8.04214 1C11.4085 1 14.0843 3.70827 13.998 7.11544C13.998 8.77535 13.3938 10.3479 12.1853 11.4836C12.1853 11.549 10.2062 13.3243 9.17194 14.252C8.82315 14.5649 8.58181 14.7814 8.56004 14.8034C8.30109 15.0655 7.78319 15.0655 7.43793 14.8034C7.39477 14.7598 6.51002 13.9516 5.62527 13.1435C4.74053 12.3354 3.8558 11.5273 3.81264 11.4836C2.60421 10.3479 2 8.77535 2 7.11544ZM8 10C6.35 10 5 8.65 5 7C5 5.35 6.35 4 8 4C9.65 4 11 5.35 11 7C11 8.65 9.65 10 8 10Z"
      fill="#303036"
    />
  </svg>
);
export const MapComponent = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      className="fill"
      opacity="0.3"
      y="9"
      width="14"
      height="5"
      rx="1"
      fill="#303036"
    />
    <path
      className="fill"
      d="M12 4.72653C12 4.07417 11.8698 3.45874 11.6094 2.88023C11.349 2.30172 10.9909 1.80014 10.5352 1.37549C10.0794 0.950843 9.54883 0.615435 8.94336 0.36926C8.33789 0.123085 7.69011 0 7 0C6.30989 0 5.66211 0.123085 5.05664 0.36926C4.45117 0.615435 3.92058 0.950843 3.46484 1.37549C3.00911 1.80014 2.65104 2.30172 2.39063 2.88023C2.13021 3.45874 2 4.07417 2 4.72653C2 5.61276 2.26041 6.56052 2.78125 7.56983C3.30209 8.57915 3.875 9.5146 4.5 10.3762C5.125 11.2378 5.69791 11.9548 6.21875 12.5271C6.73959 13.0995 7 12.992 7 12.992C7 12.992 7.26041 13.0995 7.78125 12.5271C8.30209 11.9548 8.875 11.2378 9.5 10.3762C10.125 9.5146 10.6979 8.57915 11.2188 7.56983C11.7396 6.56052 12 5.61276 12 4.72653ZM5.33984 4.72653C5.33984 4.28341 5.5026 3.908 5.82812 3.60029C6.15365 3.29257 6.54427 3.13871 7 3.13871C7.45573 3.13871 7.84635 3.29257 8.17188 3.60029C8.4974 3.908 8.66016 4.28341 8.66016 4.72653C8.66016 5.15733 8.50065 5.52659 8.18164 5.83431C7.86263 6.14203 7.46875 6.29588 7 6.29588C6.54427 6.29588 6.15365 6.14203 5.82812 5.83431C5.5026 5.52659 5.33984 5.15733 5.33984 4.72653Z"
      fill="#303036"
    />
  </svg>
);

export const Button = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      opacity="0.3"
      d="M8 16C3.58887 16 0 12.4111 0 8C0 3.58887 3.58887 0 8 0C12.4111 0 16 3.58887 16 8C16 12.4111 12.4111 16 8 16ZM8 2C4.69141 2 2 4.69141 2 8C2 11.3086 4.69141 14 8 14C11.3086 14 14 11.3086 14 8C14 4.69141 11.3086 2 8 2Z"
      fill="#303036"
    />
    <path
      className="fill"
      d="M8 12C5.79443 12 4 10.2056 4 8C4 5.79443 5.79443 4 8 4C10.2056 4 12 5.79443 12 8C12 10.2056 10.2056 12 8 12ZM8 6C6.89697 6 6 6.89697 6 8C6 9.10303 6.89697 10 8 10C9.10303 10 10 9.10303 10 8C10 6.89697 9.10303 6 8 6Z"
      fill="#303036"
    />
  </svg>
);

export const CardComponent = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      opacity="0.2"
      d="M0 10H16V13.875C16 13.875 13.5621 15 12 15C10.4379 15 9.5 14.4375 8 13.875C6.5 13.3125 5.5621 12.75 4 12.75C2.4379 12.75 0 13.875 0 13.875L0 10Z"
      fill="#303036"
    />
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 1C0.447715 1 0 1.44772 0 2V10H16V2C16 1.44772 15.5523 1 15 1H1ZM9.94733 3.27666L11.9473 7.27653C12.0243 7.43102 12.0168 7.61501 11.9253 7.76251C11.8338 7.91 11.6733 8 11.4998 8H4.49985C4.31985 8 4.15335 7.9035 4.06485 7.74651C3.97635 7.58901 3.97835 7.39702 4.07135 7.24253L5.57135 4.74261C5.64135 4.62562 5.75684 4.54212 5.89034 4.51212C6.02284 4.48212 6.16334 4.50862 6.27734 4.58412L7.39584 5.32959L9.10984 3.18767C9.21634 3.05517 9.38383 2.98367 9.55283 3.00317C9.72233 3.02067 9.87083 3.12417 9.94733 3.27666Z"
      fill="#303036"
    />
  </svg>
);
export const StartFinish = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      d="M0 0V16H2V12H15V0H0ZM4 10H2V8H4V10ZM4 4H2V2H4V4ZM7 7H5V5H7V7ZM10 10H8V8H10V10ZM10 4H8V2H10V4ZM13 7H11V5H13V7Z"
      fill="#303036"
    />
  </svg>
);
