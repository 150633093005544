const checkIfTimeIsInRange = (
    d: Date,
    startHours: number,
    startMinutes: number,
    endHours: number,
    endMinutes: number
) => {
    if (startHours < d.getHours() && d.getHours() < endHours) {
        return true;
    }
    if (startHours === d.getHours()) {
        if (d.getHours() === endHours) {
            if (
                startMinutes <= d.getMinutes()
                && d.getMinutes() <= endMinutes
            ) {
                return true;
            }
            return false;
        }
        if (startMinutes <= d.getMinutes()) {
            return true;
        }
        return false;
    }
    if (d.getHours() === endHours) {
        if (d.getMinutes() <= endMinutes) {
            return true;
        }
        return false;
    }
    return false;
};

export const getDynamicContent = (childAssets: any[], dynamicContentConfig: any, singleMode = false) => {
    if (childAssets.length > 1) {
        const defaultAssetId = dynamicContentConfig?.default;
        const sceneValues = dynamicContentConfig?.sceneValues || {};
        const todayDate = new Date();

        let defaultAsset = childAssets[ 0 ];
        let defaultAssetIndex = 0;
        let foundAsset = null;
        let foundAssetIndex = null;

        childAssets.forEach((asset, index) => {
            if (asset.id === defaultAssetId) {
                defaultAsset = asset;
                defaultAssetIndex = index;
            }
            const { start, end } = sceneValues[ asset.id ] || {};

            if (start && end) {
                if (dynamicContentConfig?.type === 'date') {
                    const starDate = start ? new Date(start) : null;
                    const endDate = end ? new Date(end) : null;

                    starDate && (starDate.setHours(0, 0, 0, 0));
                    endDate && (endDate.setHours(23, 59, 59, 59));

                    if (starDate && endDate && todayDate >= starDate && todayDate <= endDate) {
                        foundAsset = asset;
                        foundAssetIndex = index;
                    }
                } else {
                    const transformedToChoosedTimeZoneDate = new Date(todayDate.toLocaleString(
                        'en-US',
                        { timeZone: dynamicContentConfig?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone }
                    ));

                    if (checkIfTimeIsInRange(
                        transformedToChoosedTimeZoneDate,
                        (start || {}).hours,
                        (start || {}).minutes,
                        (end || {}).hours,
                        (end || {}).minutes
                    )) {
                        foundAsset = asset;
                        foundAssetIndex = index;
                    }
                }
            }
        });

        if (singleMode) {
            return foundAsset || defaultAsset;
        }
        // @ts-ignore
        childAssets.splice(foundAsset ? foundAssetIndex : defaultAssetIndex, 1);

        return [ foundAsset || defaultAsset, ...childAssets ];
    }

    if (singleMode) {
        return childAssets[ 0 ];
    }
    return childAssets;
};
