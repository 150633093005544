import React, { useEffect }                 from 'react';
import { ExperienceModel, ProjectModel }    from '@geenee/shared';
import { AnimatedWrapper, Modal, useModal } from '@geenee/ui';
import { CodeEditor }                       from '~/module/custom-code/component/editor.component';

export const useAnalyticsModal = (
    entityModel: ProjectModel | ExperienceModel | undefined
) => {
    if (!entityModel) {
        return null;
    }

    const [ layoutShowModal, layoutHideModal ] = useModal(
        (transformGroupProps: JSX.IntrinsicAttributes) => (
            <Modal
                className="global-modal"
                onHideModal={ () => {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    hideModal();
                } }
                { ...transformGroupProps }
            >
                <AnimatedWrapper manual={ false }>
                    <CodeEditor
                        value={
                            entityModel?.custom_code?.options?.analytics || ''
                        }
                        onUpdate={ (val) => {
                            if (entityModel) {
                                // @ts-ignore
                                entityModel.updateCustomCode({ custom_code: { options: { analytics: val } } });
                                // @ts-ignore
                                entityModel?.saveData();
                            }
                        } }
                    />
                </AnimatedWrapper>
            </Modal>
        ),
        [ entityModel.custom_code ]
    );

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                layoutHideModal();
            }
        };
        window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close);
    }, []);

    const showModal = () => {
        layoutShowModal();
    };

    const hideModal = () => {
        layoutHideModal();
    };

    return [ showModal, hideModal ];
};
