import React             from 'react';
import { useInject }     from '@geenee/shared';
import SwapCameraIcon    from '@geenee/shared/asset/icons/SwapCamera';
import { TYPE_STAMP_AR } from "@geenee/shared/src/util/constants";
import { cn, Wrapper }   from '@geenee/ui';
import { observer }      from 'mobx-react';
import './styles.scss';

const className = cn('swap-camera');
export const SwapCamera = observer(() => {
    const { AppState } = useInject();
    const { arSection } = AppState;
    const { sceneManager } = arSection;

    return (
        <Wrapper
            blur
            radius="h"
            align="center"
            valign="center"
            maxWidth="40px"
            className={ className() }
        >
            <SwapCameraIcon
                style={ { zIndex: 1, pointerEvents: 'all' } }
                onClick={ async () => {
                    sceneManager.rear = !sceneManager.rear;
                    const params = arSection.type === TYPE_STAMP_AR
                        ? { video: { frameRate: { max: 30, ideal: 30, min: 30 }, facingMode: sceneManager.rear ? 'environment' : 'user' } }
                        : { size: { width: 1920, height: 1080 }, rear: sceneManager.rear };
                    await sceneManager.setup(params);
                    await sceneManager.start();
                    sceneManager.sceneRenderer.setMirror(!sceneManager.rear);
                } }
            />
        </Wrapper>
    );
});
