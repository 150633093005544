import React, { useState }                                 from 'react';
import { DEFAULT_TEXT3D_BODY, MAX_TEXT3D_LENGTH }          from '@geenee/geeclient-kit/src/lib/component/scene/components/Text3D/constants';
import {  useInject }                                      from '@geenee/shared/index';
import { Button, Divider, Input, InputTableItem, Wrapper } from '@geenee/ui';
import { observer }                                        from 'mobx-react';

export const Text3DPanel = observer(() => {
    const { SceneState } = useInject();
    const { currentAsset, disabled } = SceneState;

    const [ text3DBody, setText3DBody ] = useState(currentAsset?.options.atom_text3d_body);

    const onChange = (event: React.KeyboardEvent) => {
        if (event.target.value.length > MAX_TEXT3D_LENGTH) return;
        currentAsset?.updateOption('atom_text3d_body', event.target.value);
        setText3DBody(event.target.value);
    };

    return (
        <Wrapper>
            <Divider style={ { height: 1, marginTop: 12, marginBottom: 12 } } color="white" />
            <InputTableItem marginOff weight="medium" viewType="secondary" label="3D text" size="sm">
                <Input
                    size="sm"
                    fullWidth
                    placeholder={ DEFAULT_TEXT3D_BODY }
                    value={ text3DBody }
                    onChange={ onChange }
                />
            </InputTableItem>
            <Divider style={ { height: 1, marginTop: 12, marginBottom: 12 } } color="white" />
            <InputTableItem
                size="sm"
                weight="medium"
                viewType="secondary"
                marginOff
                label="Move to ground"
            >
                <Button
                    disabled={ disabled }
                    size="qsm"
                    iconSize={ 14 }
                    fullWidth
                    viewType="secondary"
                    radius="lg"
                    onClick={ () => SceneState.moveToGround() }
                >
                    Move
                </Button>
            </InputTableItem>

        </Wrapper>
    );
});
