import React, { memo }     from 'react';
import { SectionType }     from "@geenee/shared/type/section.type";
import { observer }        from 'mobx-react';
import { $Diff }           from 'utility-types';
import { SectionsContext } from "../context/sections-context";

type DiffPropsType = {
  sectionContext: SectionType;
};

export function withSectionContext<T extends DiffPropsType>(
    Component: React.ComponentType<T>
) {
    return memo(
        observer((props: $Diff<T, DiffPropsType>) => (
            <SectionsContext.Consumer>
                { (context) => <Component { ...(props as T) } sectionContext={ context } /> }
            </SectionsContext.Consumer>
        ))
    );
}
