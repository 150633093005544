import React                    from 'react';
import { Scrollbars }           from 'react-custom-scrollbars';
import { Description, Wrapper } from '@geenee/ui';
import { QRTemplates }          from './FlowCodeHelper';
import '../styles.scss';

interface Props {
    publish?: boolean
    onSelect: (data: {[key: string]: any}) => void
    color: string
}

export function FlowCodeTemplates({ publish, onSelect, color }: Props) {
    return (
        <>
            <Description
                size="sm"
                margin="xxs"
                color={ publish ? 'white' : undefined }
            >
                Templates:
            </Description>
            <Scrollbars
                universal
                style={ { display: 'flex' } }
                autoHide
                autoHideTimeout={ 500 }
                autoHideDuration={ 200 }
                autoHeight
            >
                <Wrapper row>
                    { QRTemplates(color).map((item) => (
                        <Wrapper
                            style={ { gap: 8 } }
                            onClick={ () => onSelect(item.opts) }
                            key={ item.label }
                            maxWidth="fit-content"
                            frame="solid-white"
                            radius="md"
                            padding='xs'
                            align="center"
                        >
                            { item.template }
                            <Description
                                style={ { width: '50px' } }
                                size="xs"
                                color={ publish ? 'white' : undefined }
                            >
                                { item.label }
                            </Description>
                        </Wrapper>
                    )) }
                </Wrapper>
            </Scrollbars>
        </>
    );
}
