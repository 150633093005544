import  React, { FC, memo }       from 'react';
import { observer }               from 'mobx-react';
import { $Diff }                  from 'utility-types';
import { MoleculeContext }        from "../context/molecule-context";
import { SiteMoleculeSchemaType } from '../type/section-molecule';

type DiffPropsType = {
  moleculeContext: SiteMoleculeSchemaType;
};

export function withMoleculeContext<T extends DiffPropsType>(Component: FC<T>) {
    return memo(
        observer((props: $Diff<T, DiffPropsType>) => {
            const Elem: FC<T> = Component;
            return (
                <MoleculeContext.Consumer>
                    { (context) => (<Elem { ...(props as T) } moleculeContext={ context } />) }
                </MoleculeContext.Consumer>
            );
        })
    );
}
