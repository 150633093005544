import React from 'react';

export function ExperienceWrapperComponent(props) {
    return (
    // TODO: Remove absolute flow after brandingBar logic updates
        <div style={ { position: 'relative', width: '100%', height: `calc(100% - 48px)`, top: '48px' } }>
            { props.children }
        </div>
    );
}
