import React, { useEffect }              from 'react';
import { MoleculeComponent }             from "@geenee/geeclient-kit/src";
import { ExperienceComponent }           from "@geenee/geeclient-kit/src/lib/component/experience/experience.component";
import { ProjectComponent }              from "@geenee/geeclient-kit/src/lib/component/project/project.component";
import VideoStream                       from "@geenee/geeclient-kit/src/lib/component/scene/components/stream";
import { ColorKitContext }               from '@geenee/geeclient-kit/src/lib/context/color-kit-context';
import { defaultOptions, HeaderOptType } from "@geenee/geeclient-kit/src/lib/default-options/molecule/Header";
import { ColorCSSVarsProvider }          from '@geenee/geeclient-kit/src/lib/service/style-provider.service';
import { ColorDataSchemaType }           from "@geenee/geeclient-kit/src/lib/type/color";
import { getColorSchema }                from "@geenee/geeclient-kit/src/lib/util/color";
import { observer }                      from 'mobx-react';
import { useInject }                     from '../hook/use-inject.hook';
import {
    PHONE_VIEW_CONTENT_TYPE,
    PHONE_VIEW_EXPERIENCE_TYPE,
    PHONE_VIEW_PROGRAM_TYPE
} from '../util/constants';
import { withDIContext }   from './hoc/withDIContext.hoc';
import { MoleculeWrapper } from './molecule/molecule-wrapper.component';

const colorPalette = {
    dark:  '#000000',
    key1:  '#E21B4D',
    key2:  '#0014fd',
    key3:  '#3DE4CB',
    key4:  '#247657',
    light: '#55555533',
    white: '#FFFFFF',
    black: '#000000',
    blur:  '#FFFFFF'
};

const colorSchema: ColorDataSchemaType = getColorSchema(
    colorPalette
) as unknown as ColorDataSchemaType;

const ContentRenderer = withDIContext(observer(() => {
    const { AppState } = useInject();
    const { activeScreenType, activeExperience, activeProject } = AppState;
    // TODO: rename hidden in tree naming to something more meaningful like "isHidden"
    const { hidden_in_tree } = activeExperience?.options || { hidden_in_tree: true };

    const isExperienceView = hidden_in_tree || (activeScreenType === PHONE_VIEW_CONTENT_TYPE);

    const isProgramView = !hidden_in_tree && (activeScreenType === PHONE_VIEW_PROGRAM_TYPE
        || activeScreenType === PHONE_VIEW_EXPERIENCE_TYPE);

    return (
        <>
            <MoleculeWrapper magellanMolecule="PhoneContentWrapper">
                <>
                    <section
                        id="branding-bar"
                        data-color-root="true"
                        data-color-bg="black"
                        // TODO: Remove absolute flow after brandingBar logic updates
                        style={ { position: 'absolute', width: '100%', zIndex: 3 } }
                    >
                        <MoleculeComponent<HeaderOptType>
                            kitMolecule="Header"
                            moleculeSchema={ {
                                options: {
                                    ...defaultOptions,
                                    logo_image_src:        activeProject?.header_logo?.url,
                                    style_color_bg_custom: activeProject?.options?.branding_bar_color,
                                    logo_image_height:     '100%',
                                    logo_image_width:      'auto',
                                    logo_height:           '100%',
                                    header_height:         '48px'
                                }
                            } }
                        />
                    </section>

                    { isProgramView && <ProjectComponent /> }
                    { isExperienceView
                      && <ExperienceComponent /> }
                </>
            </MoleculeWrapper>
        </>
    );
}));

export const MagellanShared = observer((props: any) => {
    const { AppState } = useInject();

    if (!AppState) {
        return null;
    }
    useEffect(() => {
        if (AppState.initialScreenLoaded) {
            const progressLine = document.querySelector('.preloader--progress_line') as HTMLDivElement;
            if (progressLine) {
                progressLine.classList.remove('anim');
                progressLine.style.width = '101%';
            }
            setTimeout(() => {
                const elem = document.getElementById('preloader');
                elem?.parentNode.removeChild(elem);
            }, 200);
        }
    }, [ AppState.initialScreenLoaded ]);

    if (!AppState || !AppState.projectsRegistry.size) {
        return null;
    }

    return (
        <>
            <ColorKitContext.Provider value={ colorSchema }>
                <ColorCSSVarsProvider />
                <ContentRenderer
                    activeType={ AppState.activeScreenType }
                    appState={ AppState }
                    { ...props }
                />
            </ColorKitContext.Provider>
        </>

    );
});

export const MagellanSceneShared = withDIContext(observer((props: any) => {
    const { AppState } = useInject();
    const { activeSceneModel } = AppState;

    const onVideoStreamSuccess = (video: HTMLVideoElement) => {
        AppState.setVideo(video);
    };

    const { SlamAR, StampAR, NativeAR } = props.diContext;

    return (
        <>
            <MoleculeWrapper magellanMolecule="VideoStreamWrapper">
                <VideoStream
                    style={ {
                        opacity:  0,
                        position: 'absolute',
                        width:    window.innerWidth,
                        height:   window.innerHeight
                    } }
                    onSuccess={ onVideoStreamSuccess }
                />
            </MoleculeWrapper>
            { AppState.videoStream && (

                <>
                    { activeSceneModel?.scene_experience_type === 'bodytracking' && (

                        <SlamAR />

                    ) }
                    { activeSceneModel?.scene_experience_type === 'slam-ar' && (
                        <>
                            <SlamAR />
                        </>
                    ) }
                    { activeSceneModel?.scene_experience_type === 'native-ar' && (
                        <>
                            <NativeAR />
                        </>
                    ) }
                    { activeSceneModel?.scene_experience_type === 'stamp-ar' && (
                        <>
                            <StampAR />
                        </>
                    ) }
                </>

            ) }

        </>
    );
}));

export const MagellanViewerSceneShared = withDIContext(observer((props: any) => {
    const { Viewer3D } = props.diContext;
    return (
        <Viewer3D props={ props } />
    );
}));
