import IStamp from "./IStamp";

export default class CVStamp {
  private cv: any;
  private stamp: IStamp;

  constructor(cv: any) {
    this.cv = cv;
    this.stamp = new cv.Stamp();
  }

  addTarget(image: ImageData) {
    const { cv } = this;
    const mImage = cv.matFromImageData(image);
    this.stamp.addTarget(mImage);
    mImage.delete();
  }

  train() {
    this.stamp.train();
  }

  recognize(image: ImageData, intrinsic: Float64Array, translation: Float64Array): number {
    const { cv } = this;
    const mImage = cv.matFromImageData(image);
    const mIntrinsic = new cv.Mat();
    const mTranslation = new cv.Mat();
    const nFoundTarget = this.stamp.recognize(mImage, mIntrinsic, mTranslation);
    for (let i = 0; i < mIntrinsic.data64F.length; i++) {
      intrinsic[i] = mIntrinsic.data64F[i];
    }
    for (let i = 0; i < mTranslation.data64F.length; i++) {
      translation[i] = mTranslation.data64F[i];
    }

    mImage.delete();

    return nFoundTarget;
  }


}