import React                    from 'react';
import { Wrapper }              from '@geenee/ui';
import { observer }             from "mobx-react";
import { DeleteRow }            from "~/module/team-library/components/DeleteRow";
import { FiltersRow }           from "~/module/team-library/components/FiltersRow";
import { LibraryTitle }         from "~/module/team-library/components/LibraryTitle";
import { TeamLibraryItemModel } from "~/module/team-library/state/team-library-item.model";

interface Props {
  onClose: () => void;
  onUploadModel: (files: File[]) => void;
  fileTypes?: string;
  showUploadButton?: boolean;
  checkedList: Map<string, TeamLibraryItemModel>;
  onDelete: () => void;
  onClearSelection: () => void;
  onSelectAll: () => void;
}

export const TeamLibraryDialogHeader = observer((props: Props) => (
    <Wrapper padding="md">
        <LibraryTitle />
        <Wrapper
            radius="xxl"
            padding="xs"
            frame="solid-bg"
            row
            valign="center"
            style={ { fontSize: '12px' } }
        >
            { props.checkedList.size ? (
                <DeleteRow
                    onDelete={ props.onDelete }
                    onClearSelection={ props.onClearSelection }
                    onSelectAll={ props.onSelectAll }
                />
            ) : <FiltersRow onUploadModel={ props.onUploadModel } /> }
        </Wrapper>
    </Wrapper>
));
