// ts-nocheck
import React, { FC, forwardRef, memo } from 'react';
import { cn }                          from '../../util/bem';
import { SizesUnionType }              from "../../util/global-props";
import { Title }                       from '../title/title.component';
import './input-table.component.scss';

export type InputTableProps = {
    className?: string;
    children?: React.ReactNode;
    style?: React.CSSProperties;
    title?: React.ReactNode;
    margin?: SizesUnionType;
}

const className = cn('input-table');

export const InputTable: FC<InputTableProps> = memo(forwardRef<HTMLDivElement, InputTableProps>((props, ref) => (
    <div
        className={ `${ className({ margin: props.margin }) } ${ props.className || '' }` }
        style={ props.style }
        ref={ ref }
    >
        { props.title && (
            <div className={ className('title') }>
                <Title size="sm" margin="sm">
                    { props.title }
                </Title>
            </div>
        ) }

        <div className={ className('content') }>
            { props.children }
        </div>
    </div>
)));
