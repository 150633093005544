import React, { useMemo }           from 'react';
import { observer }                 from 'mobx-react';
import { useTheme }                 from 'styled-components';
import OrionIcon                    from '@geenee/builder/src/asset/icons/OrionIcon';
import PublishIcon                  from '@geenee/builder/src/asset/icons/PublishIcon';
import { PreviewPublishButtonIcon } from '@geenee/builder/src/components/ProjectsLinkDropdown/styles';
import { GetIconElementWrapper }    from './styles';

export const PreviewPublishButton = observer(({
    publish,
    onClick, projectPublished, isChangeDetected,
    disabled
} : {publish: boolean, onClick: () => void, projectPublished: boolean, isChangeDetected: boolean, disabled: boolean}) => {
    const { secondaryColors } = useTheme();
    const idName = useMemo(() => (publish ? 'publish' : 'preview'), [ publish ]);

    const getIconElement = useMemo(() => (
        <GetIconElementWrapper>
            { publish ? (
                <PublishIcon
                    fill={
                        projectPublished ? secondaryColors.green80 : ''
                    }
                />
            ) : (
                <OrionIcon />
            ) }
        </GetIconElementWrapper>
    ), [ publish, projectPublished ]);

    const text = useMemo(() => {
        if (publish) {
            if (projectPublished) {
                return 'Unpublish';
            }
            return 'Publish';
        }
        return 'Preview';
    }, [ publish, projectPublished ]);

    return (
        <PreviewPublishButtonIcon
            id={ `${ idName }-btn` }
            onClick={ onClick }
            disabled={ disabled }
            $width={ publish ? '151px' : '133px' }
            iconElement={ getIconElement }
            text={ text }
            withBorder={ publish && isChangeDetected && projectPublished }
            $justify="center"
            $light
        />
    );
});
