import { AttachmentFactory }                      from '@geenee/shared/src/magellan/factory/attachment.factory';
import { action, makeAutoObservable, observable } from 'mobx';
import { container }                              from '~/magellan/di/di';
import { AttachmentModel }                        from '~/magellan/model/attachment.model';
import { AssetLibraryApi }                        from '~/module/team-library/api';
import { TeamLibraryState }                       from '~/module/team-library/state/team-library.state';

export class TeamLibraryItemModel {
    @observable id = '';
    @observable attachment_id = '';
    @observable thumbnail_id = '';
    @observable title = '';
    @observable description = '';
    @observable attachment!: AttachmentModel;
    @observable thumbnail?: AttachmentModel;
    inserted_at = '';
    @observable posted_by = '';
    $parent?: TeamLibraryState;
    attachmentFactory: AttachmentFactory = container.get('<AttachmentFactory>');

    constructor() {
        makeAutoObservable(this);
    }

    init(schema?: Partial<TeamLibraryItemModel>) {
        Object.assign(this, schema);
        if (schema?.attachment) {
            const attachmentModel = this.attachmentFactory.create();
            attachmentModel.updateState(schema?.attachment);
            this.attachment_id = attachmentModel.id;
            this.attachment = attachmentModel;
        }
        if (schema?.thumbnail) {
            const thumbnailModel = this.attachmentFactory.create();
            thumbnailModel.updateState(schema?.thumbnail);
            this.thumbnail_id = thumbnailModel.id;
            this.thumbnail = thumbnailModel;
        }
    }

    @action async update(newItem: {title: string; description?: string; file?: File; thumbnailFile?: File; posted_by?: string}) {
        Object.assign(this, newItem);
        if (newItem.thumbnailFile && this.$parent) {
            const thumbnail = await this.$parent.createThumbnail(newItem.thumbnailFile, this.attachment?.options.order);
            this.thumbnail = thumbnail;
            this.thumbnail_id = thumbnail.id;
            if (newItem.file) {
                const fileAttachment = await this.$parent.createAttachment(newItem.file, this.attachment?.options.order);
                this.attachment_id = fileAttachment.id;
                this.attachment = fileAttachment;
            }
        } else if (newItem.file && this.$parent) {
            const { fileAttachment, thumbnailAttachment } = await this.$parent.createModelAndThumbnailAttachments(
                newItem.file,
                this.attachment?.options.order
            );
            this.attachment_id = fileAttachment.id;
            this.thumbnail_id  = thumbnailAttachment.id;
            this.attachment    = fileAttachment;
            this.thumbnail     = thumbnailAttachment;
        }
        const data = await AssetLibraryApi.update({ ...this, $parent: undefined });

        return data;
    }

    @action async delete() {
        const index = this.$parent?.storage.findIndex((el) => el.id === this.id);
        if (index !== -1) {
            // @ts-ignore
            this.$parent?.storage.splice(index, 1);
        }
        await AssetLibraryApi.deleteLibraryItem(this.id);
        this.attachment?.delete();
        this.thumbnail?.delete();
    }
}
