import * as React                      from "react";
import type { Light }                  from "@babylonjs/core/Lights/light";
import type { Observable }             from "@babylonjs/core/Misc/observable";
import { ButtonLineComponent }         from "@geenee/geespector-ui-components/src/lines/buttonLineComponent";
import { FloatLineComponent }          from "@geenee/geespector-ui-components/src/lines/floatLineComponent";
import { LineContainerComponent }      from "@geenee/geespector-ui-components/src/lines/lineContainerComponent";
import { TextInputLineComponent }      from "@geenee/geespector-ui-components/src/lines/textInputLineComponent";
import { TextLineComponent }           from "@geenee/geespector-ui-components/src/lines/textLineComponent";
import type { LockObject }             from "@geenee/geespector-ui-components/src/tabs/propertyGrids/lockObject";
import type { GlobalState }            from "../../../../globalState";
import type { PropertyChangedEvent }   from "../../../../propertyChangedEvent";
import { ActionTabSectionComponent }   from '../../../actionTabSectionComponent';
import { ShowMoreSectionComponent }    from "../../../showMoreSectionComponent";
import { AnimationGridComponent }      from "../animations/animationPropertyGridComponent";
import { CustomPropertyGridComponent } from "../customPropertyGridComponent";
import { ParentPropertyGridComponent } from "../parentPropertyGridComponent";

interface ICommonLightPropertyGridComponentProps {
    globalState: GlobalState;
    light: Light;
    lockObject: LockObject;
    onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
}

export class CommonLightPropertyGridComponent extends React.Component<ICommonLightPropertyGridComponentProps> {
    constructor(props: ICommonLightPropertyGridComponentProps) {
        super(props);
    }

    render() {
        const { light } = this.props;

        return (
            <>
                <CustomPropertyGridComponent
                    globalState={ this.props.globalState }
                    target={ light }
                    lockObject={ this.props.lockObject }
                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                />
                <ActionTabSectionComponent title="GENERAL">
                    { this.props.globalState.isDevMode && <TextLineComponent label="ID" value={ light.id } /> }
                    <TextInputLineComponent
                        lockObject={ this.props.lockObject }
                        label="Name"
                        target={ light }
                        propertyName="name"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                    { this.props.globalState.isDevMode && <TextLineComponent label="Unique ID" value={ light.uniqueId.toString() } /> }
                    <ParentPropertyGridComponent
                        globalState={ this.props.globalState }
                        node={ light }
                        lockObject={ this.props.lockObject }
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                    <FloatLineComponent
                        lockObject={ this.props.lockObject }
                        label="Intensity"
                        target={ light }
                        propertyName="intensity"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                    { this.props.globalState.isDevMode && (
                        <ButtonLineComponent
                            label="Dispose"
                            onClick={ () => {
                                light.dispose();
                                this.props.globalState.onSelectionChangedObservable.notifyObservers(null);
                            } }
                        />
                    ) }
                    <ShowMoreSectionComponent>
                        <TextLineComponent label="Class" value={ light.getClassName() } />
                    </ShowMoreSectionComponent>
                </ActionTabSectionComponent>
                <AnimationGridComponent globalState={ this.props.globalState } animatable={ light } scene={ light.getScene() } lockObject={ this.props.lockObject } />
            </>
        );
    }
}
