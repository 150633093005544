import React from 'react';
import {
    AccountState,
    accountState
} from '@geenee/builder/src/core/state/account.state';
import { BuilderState }                      from '@geenee/builder/src/core/state/builder.state';
import { handleError }                       from '@geenee/shared/src/util/errorHandler';
import { inject, injectable, postConstruct } from "inversify";
import { action, makeObservable }            from "mobx";
import { MobxForm }                          from './mobx-form';

export interface SubmitData {
  name: string;
  logo?: File;
  size?: string;
  business_type?: string;
}

@injectable()
export class CompanySettings extends MobxForm {
  @inject('<BuilderState>') builderState: BuilderState;

  accountState!: AccountState;

  @postConstruct()
  initialize() {
      makeObservable(this);
  }

  setup(form) {
      this.form = form;

      this.fields = [
          {
              name:        'name',
              type:        'text',
              placeholder: 'Company name',
              rules:       { required: 'Please, fill company name' }
          },
          {
              name:        'logo',
              type:        'file',
              placeholder: 'Upload your company logo'
          },
          {
              name:        'size',
              type:        'select',
              placeholder: 'Enter your company size',
              rules:       { required: true }
          },
          {
              name:        'business_type',
              type:        'select',
              placeholder: 'Enter your company business type',
              rules:       { required: true }
          }
      ];
      this.accountState = accountState;

      this.hooks.onSubmit = this.onSubmit.bind(this);
  }

  @action
  async onSubmit(data: SubmitData, _e: React.SyntheticEvent) {
      this.setIsLoading(true);

      try {
          await this.accountState.update(
              { publishing_domain: data.name, name: data.name },
              data.logo instanceof FileList ? data.logo[ 0 ] : data.logo
          );
      } catch (error) {
          this.setIsLoading(false);

          handleError(error, (key, value) => this.setError(key, {
              type:    value.type,
              message: (value.message.split(',') || []).map((item) => {
                  if (item === 'has already been taken') {
                      return 'This company name already exists';
                  }
                  return item;
              }).join(',')
          }));

          this.errorHandler(error);
      }

      this.setIsLoading(false);
  }
}
