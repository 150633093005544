import { injectable }      from 'inversify';
import { AssetSchemaType } from '../../../type/asset.type';
import { FactoryAbstract } from '../../util/factoryContainer';
import { AtomModel }       from '../atom/model/atom.model';
import { AssetModel }      from '../model/asset.model';

type ParentType = AtomModel

@injectable()
export class AssetFactory extends FactoryAbstract {
    create(
        assetSchema: AssetSchemaType,
        $parent?: ParentType
    ) {
        const assetModel: AssetModel = this.container.get('<AssetModel>');
        Object.assign(assetModel, assetSchema);
        if ($parent) {
            assetModel.$parent = $parent;
        }
        return assetModel;
    }
}

export type AssetFactoryType = AssetFactory
