import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '12',
    height = '12',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.5 6C11.5 9.03757 9.03757 11.5 6 11.5C2.96243 11.5 0.5 9.03757 0.5 6C0.5 2.96243 2.96243 0.5 6 0.5C9.03757 0.5 11.5 2.96243 11.5 6Z"
                fill="white"
                stroke="#BBBBBB"
            />
            <path
            // eslint-disable-next-line max-len
                d="M5.99985 3.00989C4.3481 3.00989 3.00903 4.34892 3.00903 6.00067C3.00903 7.65249 4.3481 8.99142 5.99985 8.99142C7.65178 8.99142 8.99071 7.65249 8.99071 6.00067C8.99071 4.34903 7.65178 3.01003 5.99982 3.01003L5.99985 3.00989ZM7.37143 7.32346C7.31785 7.41132 7.20285 7.43917 7.115 7.38525C6.41278 6.95632 5.52878 6.85917 4.48771 7.09703C4.38739 7.11989 4.28739 7.05703 4.26453 6.95667C4.24157 6.85632 4.30418 6.75632 4.40475 6.73346C5.54403 6.47317 6.52128 6.58524 7.30964 7.06703C7.3975 7.12096 7.42535 7.2356 7.37143 7.32346ZM7.7375 6.5091C7.67 6.61882 7.52643 6.65346 7.41678 6.58596C6.61285 6.09182 5.38739 5.94871 4.4365 6.23735C4.31318 6.2746 4.18293 6.2051 4.1455 6.08199C4.10835 5.95867 4.17789 5.82867 4.301 5.79117C5.38718 5.4616 6.7375 5.62124 7.66071 6.18857C7.77035 6.25607 7.805 6.3996 7.7375 6.5091ZM7.76893 5.6611C6.805 5.08857 5.21464 5.03592 4.29432 5.31525C4.14653 5.36007 3.99025 5.27664 3.94546 5.12885C3.90068 4.98099 3.98403 4.82482 4.13193 4.77989C5.18839 4.45917 6.94464 4.52114 8.05443 5.17996C8.18764 5.25885 8.23121 5.43053 8.15228 5.56328C8.07371 5.69621 7.90157 5.74003 7.76907 5.6611H7.76893Z"
                fill="#1ED760"
            />
        </svg>
    );
}
