import styled from 'styled-components';
import '@geenee/ui/src/lib/styles/vars.scss';

export const SnapShotRoot = styled.div`
  width: 100%;
  height: 100%;

  .snapshot-block {
    opacity: 1;
    width: 100%;
    height: 100%;
    z-index: 4;

    &__snapshot-ui {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      position: absolute;
      bottom: 23px;
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      left: 50%;
      z-index: 4;
      transform: translateX(-50%);
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      pointer-events: all;
    }

    &__swap-camera--block {
      position: absolute;
      bottom: 20px;
      left: -85px;
      margin: 0 auto;
      color: #fff;
      width: 50px;
      text-align: center;
    }

    @keyframes snap-pulse {
      0% {
        transform: scale(1, 1);
      }

      40% {
        transform: scale(1.1, 1.1);
      }

      100% {
        transform: scale(1, 1);
      }
    }

    @-webkit-keyframes snap-pulse {
      0% {
        transform: scale(1, 1);
      }

      40% {
        transform: scale(1.1, 1.1);
      }

      100% {
        transform: scale(1, 1);
      }
    }

    @-webkit-keyframes snap-shadow-pulse {
      0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.25);
      }
      70% {
        -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
      }
      100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      }
    }
    @keyframes snap-shadow-pulse {
      0% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.25);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
      }
      70% {
        -moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
      }
      100% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      }
    }

    &__close-download-icon {
      width: 45px;
      height: 45px;
      position: absolute;
      top: 15px;
      left: 15px;
      background: rgba(85, 85, 85, 0.2);
      backdrop-filter: ${ ({ theme: { options } }) => `blur(${ options.blurRadius })` };
      border-radius: 100%;
      padding: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__snapshot-timer--block {
      &__snapshot-timer--icon {

      }

      &__snapshot-timer--time {

      }
    }

    &__snapshot-preview {
      transition: opacity .4s;
      background-color: rgba(0,0,0,0.5);
      backdrop-filter: blur(10px);
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      overflow: hidden;
      z-index: -9999;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      img, video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
      //img {
      //  object-fit: cover;
      //  object-position: center center;
      //}

      &.active {
        opacity: 1;
        z-index: 3;
        pointer-events: all;
        -webkit-user-select: auto !important; /* Safari */
        -webkit-touch-callout: default !important; /* iOS Safari */
        -khtml-user-select: auto !important; /* Konqueror HTML */
        -ms-user-select: auto !important; /* Internet Explorer/Edge */
        -moz-user-select: auto !important; /* Old versions of Firefox */
        user-select: auto !important;
      }
    }


    &__snapshot-flash {
      display: block;
      visibility: hidden;
      position: absolute;
      opacity: 0.7;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      pointer-events: none;
      z-index: 5;
      transition: opacity 0.3s linear;

      &.active {
        visibility: visible;
        opacity: 0;
        transition: opacity 0.3s linear;
      }
    }

    &__download-info {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      color: #fff;
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.35);
      transform: translate(-50%, -50%);
      font-size: 20px;
      white-space: nowrap;
      text-align: center;
      line-height: 26px;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;


      &__download-link {
        //display: none;
      }
    }
`;

export const VideoSwitcherLabel = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  color: ${ ({ theme: { colors } }) => colors.white };
  text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);

  & span:first-child {
    margin-right: 8px;
  }
`;

export const RecordingIndicatorWrapper = styled.div`
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  width: 55px;
  height: 55px;
  transform: translate(-50%, -50%);

  svg {
    transform: rotate(-90deg);
  }

  circle {
    fill: none;
    stroke: ${ ({ theme: { colors } }) => colors.white };
    stroke-width: 2px;
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
    stroke-linecap: round;
    animation: anim 20s linear infinite;
  }

  animation: appear .2s;
  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes anim {
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: -150;
    }
  }
`;

const sizeSpacer = '6px';
const bigSpacer = '70px';

export const CameraActionsContainer = styled.div<{ left: boolean, right: boolean, isBuilder: boolean, spacer: number }>`
  width: 125px;
  padding: 3px;
  border-radius: 40px;
  cursor: pointer;
  ${ ({ isBuilder }) => (isBuilder ? 'background: rgba(85, 85, 85, 0.1);' : 'background: rgba(85, 85, 85, 0.2);') }

  backdrop-filter: ${ ({ theme: { options } }) => `blur(${ options.blurRadius })` };
  display: flex;
  justify-content: space-around;

  &:before {
    //content: "";
    position: absolute;
    top: ${ sizeSpacer };
    left: ${ sizeSpacer };
    right: ${ bigSpacer };
    //right: 5px;
    //left: 70px;
    bottom: ${ sizeSpacer };
    border-radius: 40px;
    border: 2px solid transparent;

    @keyframes rightSizeAnim {
      0% {
        right: ${ bigSpacer };
      }
      50% {
        right: ${ sizeSpacer };
        left: ${ sizeSpacer };
      }
      100% {
        left: ${ bigSpacer };
        right: ${ sizeSpacer };
      }
    }

    @keyframes leftSizeAnim {
      0% {
        left: ${ bigSpacer };
        right: ${ sizeSpacer }
      }
      50% {
        left: ${ sizeSpacer };
        right: ${ sizeSpacer };
      }
      100% {
        right: ${ bigSpacer };
        left: ${ sizeSpacer };
      }
    }
    ${ ({ right }) => (right !== undefined && right ? 'animation: rightSizeAnim 1s forwards' : '') }
    ${ ({ left, spacer }) => (left !== undefined && left ? `left: 70px; right: ${ spacer };` : '') }
    ${ ({ left }) => (left !== undefined && left ? 'animation: leftSizeAnim 1s forwards' : '') }
  }
`;

export const CameraIconWrapper = styled.div<{ active?: boolean }>`
  position: relative;
  width: 57px;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity .4s;

  path {
    ${ ({ active }) => (!active && 'fill: #fff;') }
  }
`;

export const TimerText = styled.div`
  position: absolute;
  top: 50%;
  right: -35px;
  transform: translateY(-50%);
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-size: 10px;
  color: ${ ({ theme: { colors } }) => colors.white };
`;

export const DownloadIconWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  background: rgba(85, 85, 85, 0.2);
  backdrop-filter: ${ ({ theme: { options } }) => `blur(${ options.blurRadius })` };
  border-radius: 100%;
`;

export const PreviewModalRoot = styled.div`
  max-width: 270px;
  background: rgba(85, 85, 85, 0.2);
  backdrop-filter: ${ ({ theme: { options } }) => `blur(${ options.blurRadius })` };
  border-radius: 30px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: ${ ({ theme: { colors } }) => colors.white };
  padding: 30px 20px 20px 20px;
  text-align: center;
  line-height: 25px;
`;

export const ToggleAnimWrapper = styled.div<{ hide?: boolean }>`
  position: absolute;
  left: 0;
  top: -1px;
  opacity: ${ ({ hide }) => (hide ? '0' : '1') };
  svg {
    height: 65px !important;
  }
`;

export const CameraAnimContainer = styled.div<{ active?: boolean }>`
  z-index: -1;
  position: absolute;
  left: -0.5px;
  top: -1px;
  transition: opacity .4s;

  svg {
    height: 65px !important;
  }

  path {
    ${ ({ active }) => !active && 'stroke: #FFF;' };
  }
`;

export const VideoRecordingIndicatorContainer = styled.div`
  position: absolute;
  left: 0px;
  top: -1px;
  z-index: -1;

  svg {
    height: 65px !important;
  }
`;
