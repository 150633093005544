import { AtomModel, MoleculeModel } from '@geenee/shared';
import { Object3D }                 from 'three';

export const setObjectProperties = (object: Object3D, currentAsset?: AtomModel | MoleculeModel) => {
    if (!currentAsset?.options) return;

    if (currentAsset.options.scene_atom_position?.length === 3) {
        object.position.set(
            currentAsset.options.scene_atom_position[ 0 ],
            currentAsset.options.scene_atom_position[ 1 ],
            currentAsset.options.scene_atom_position[ 2 ]
        );
    }
    if (currentAsset.options.scene_atom_scale?.length === 3) {
        object.scale.set(
            currentAsset.options.scene_atom_scale[ 0 ],
            currentAsset.options.scene_atom_scale[ 1 ],
            currentAsset.options.scene_atom_scale[ 2 ]
        );
    }
    if (currentAsset.options.scene_atom_rotation?.length === 3) {
        object.rotation.set(
            currentAsset.options.scene_atom_rotation[ 0 ],
            currentAsset.options.scene_atom_rotation[ 1 ],
            currentAsset.options.scene_atom_rotation[ 2 ]
        );
    }
};
