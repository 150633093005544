import React                  from 'react';
import { Scrollbars }         from 'react-custom-scrollbars';
import { Divider, Wrapper }   from '@geenee/ui';
import { HeaderTitle }        from '../../components/HeaderTitle';
import { SupportRequestForm } from './components/SupportRequestForm';

export function ContactUs() {
    return (
        <Scrollbars
            autoHide
            autoHideTimeout={ 500 }
            autoHideDuration={ 200 }
        >
            <Wrapper
                fullHeight
                fullWidth
                padding="xxl"
                align="center"
            >
                <Wrapper maxWidth="736px">
                    <HeaderTitle title="Contact Us" subtitle="Submit your question, comment, or request here:" />
                    <Divider transparent margin="lg" />
                    <Wrapper maxWidth="736px" margin="lg">
                        <SupportRequestForm />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Scrollbars>
    );
}
