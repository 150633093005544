import { Analytics }                                         from '@geenee/analytics';
import { Emptable }                                          from '@geenee/shared/type/shared.type';
import { action, computed, makeAutoObservable, observable }  from 'mobx';
import { StripePaymentMethodStoreType }                      from '@geenee/builder/src/../type/model/stripe/stripe-payment-method.type';
import { stripePlanTitles, StripePlanType, StripePriceType } from '@geenee/builder/src/../type/model/stripe/stripe-plan.type';
import { StripeSubscriptionType }                            from '@geenee/builder/src/../type/model/stripe/stripe-subscription.type';
import { Stripe as StripeAPI }                               from '@geenee/builder/src/api';
import { StripePlanModel }                                   from '@geenee/builder/src/core/model/stripePlan.model';
import { StripePlanParamsModel }                             from '@geenee/builder/src/core/model/stripePlanParams.model';
import {
    CARD_CREATED, CARD_DELETED, DEFAULT_CARD_CHANGED,
    SUBSCRIPTION_BOUGHT, SUBSCRIPTION_CANCELED
} from '@geenee/builder/src/lib/constants';
import { container } from '@geenee/builder/src/magellan/di/di';

export class StripeState implements StripeSubscriptionType {
    @observable paymentMethods: Emptable<StripePaymentMethodStoreType> = {};
    @observable expiry_date: StripeSubscriptionType['expiry_date'] = '';
    @observable plan_params = new StripePlanParamsModel();
    @observable plan_price: StripeSubscriptionType['plan_price'] = 0;
    @observable plan_title: StripeSubscriptionType['plan_title'] = '';
    @observable status: StripeSubscriptionType['status'] = '';
    @observable plan_id: StripeSubscriptionType['plan_id'] = '';
    @observable plan_period!: StripeSubscriptionType['plan_period'];
    @observable plan_price_id: StripeSubscriptionType['plan_price_id'] = '';
    @observable plans: Emptable<StripePlanType> = {};
    @observable isPlansLoaded = false;
    @observable isPaymentMethodsLoaded = false;
    @observable daysLeft = -1;

    constructor() {
        makeAutoObservable(this);
    }

    @action
        updateState = (item: Partial<StripeState>) => {
            Object.keys(item).forEach((key) => {
                if (key === 'plan_params') {
                    this.plan_params.init(item[ key ] as Record<string, any>);
                } else {
                // @ts-ignore
                    this[ key ] = item[ key ];
                }
            });
        };

    @action
        fetchPlans = async () => {
            const res = await StripeAPI.fetchPlans();
            this.plans = res.reduce((acc, curr) => {
                const model = new StripePlanModel();
                model.init(curr);
                acc[ curr.id ] = model;
                return acc;
            }, {});
            this.isPlansLoaded = true;
        };

    @action
        fetchPaymentMethods = async () => {
            const res = await StripeAPI.fetchPaymentMethods();
            this.paymentMethods = res.reduce((acc, curr) => {
                acc[ curr.stripe_id ] = curr;
                return acc;
            }, {});
            this.isPaymentMethodsLoaded = true;
        };

    @action
        addPaymentMethod = async (stripe_id?: string) => {
            const analytics: Analytics = container.get('<Analytics>');
            if (stripe_id) {
                const res = await StripeAPI.attachPaymentMethod(stripe_id);
                analytics.track(CARD_CREATED, res);

                this.paymentMethods[ res.stripe_id ] = res;
            }
        };

    @action
        deletePaymentMethod = async (stripe_id: string) => {
            const analytics: Analytics = container.get('<Analytics>');

            if (stripe_id) {
                await StripeAPI.detachPaymentMethod(stripe_id);
                analytics.track(CARD_DELETED, this.paymentMethods[ stripe_id ]);
                delete this.paymentMethods[ stripe_id ];
                this.updateState(this);
            }
        };

    @action
        setDefaultPaymentMethod = async (stripe_id: string) => {
            const analytics: Analytics = container.get('<Analytics>');

            if (stripe_id) {
                await StripeAPI.setDefaultPaymentMethod(stripe_id);
                analytics.track(DEFAULT_CARD_CHANGED, this.paymentMethods[ stripe_id ]);

                Object.keys(this.paymentMethods).forEach((key) => {
                    this.paymentMethods[ key ].is_default = false;
                });
                this.paymentMethods[ stripe_id ].is_default = true;
            }
        };

    @computed
    get prices(): Record<string, StripePriceType> {
        return Object.values(this.plans).reduce((acc, curr) => {
            // @ts-ignore
            curr.prices.forEach((price) => {
                // @ts-ignore
                acc[ price.id ] = { name: curr.title, ...price, parent_plan_id: curr.id };
            });
            return acc;
        }, {}) as any;
    }

    @computed
    get isExpired() {
        return this.status === 'inactive' || this.expiry_date < new Date().toISOString();
    }
    @action
        buySubscription = async (price_id?: string) => {
            const analytics: Analytics = container.get('<Analytics>');
            const { prices } = this;

            if (price_id) {
                // @ts-ignore
                const newPriceObject = prices[ price_id ];

                return StripeAPI.purchaseSubscription({ price_id }).then(
                    (stripeResponse) => {
                        analytics.track(SUBSCRIPTION_BOUGHT, { ...newPriceObject, isUpgrade: newPriceObject.price > this.plan_price });
                        return stripeResponse;
                    }
                );
            }
            return null;
        };

    @action
        cancelSubscription = async () => {
            const analytics: Analytics = container.get('<Analytics>');

            const updated = await StripeAPI.cancelSubscription();
            const {
                plan_id,
                plan_period,
                plan_price,
                plan_price_id,
                plan_title
            } = this;

            analytics.track(SUBSCRIPTION_CANCELED, {
                plan_id,
                plan_period,
                plan_price,
                plan_price_id,
                plan_title
            });

            this.updateState({ ...updated, daysLeft: -1 });
        };

    @action
        fetchTransactions = async () => StripeAPI.fetchTransactions();

    @computed
    get defaultPaymentMethod() {
        const defaultMethodId = Object.keys(this.paymentMethods).find((key) => this.paymentMethods[ key ].is_default);
        // @ts-ignore
        return this.paymentMethods[ defaultMethodId ] || Object.values(this.paymentMethods)[ 0 ];
    }

    @computed
    get isHobbyist() {
        return this.plan_title.toLowerCase() === stripePlanTitles.hobbyist;
    }

    @computed
    get isPremium() {
        return this.plan_title.toLowerCase() === stripePlanTitles.premium;
    }

    @computed
    get isTeams() {
        return this.plan_title.toLowerCase() === stripePlanTitles.teams;
    }

    @computed
    get isEnterprise() {
        return this.plan_title.toLowerCase() === stripePlanTitles.enterprise;
    }

    @computed
    get isActiveTeamOrEnterprise() {
        return !this.isExpired && !this.isHobbyist && !this.isPremium;
    }
}

export const stripeState = new StripeState();
