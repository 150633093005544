import React, { FC, memo, MouseEventHandler, useEffect, useRef } from 'react';
import { Button, Icon }                                          from "../../..";
import { cn }                                                    from '../../util/bem';
import { FormSizesType, SizesUnionType, TextColorType }          from "../../util/global-props";
import { IconPropsType }                                         from "../icon/icon.component";
import './checkbox.component.scss';

export type CheckboxPropsType = {
    className?: string;
    label?: React.ReactNode;
    disabled?: boolean;
    checked?: boolean;
    defaultChecked?: boolean;

    autoFocus?: boolean;
    height?: FormSizesType;
    margin?: SizesUnionType;
    size?: 'sm' | 'md' | 'lg';
    iconColor?: IconPropsType["color"],
    color?: TextColorType;
    style?: React.CSSProperties;

    flex?: number;

    onChange?: (checked: boolean) => void;
    onClick?: MouseEventHandler<HTMLInputElement>;
    onMouseUp?: MouseEventHandler<HTMLInputElement>;

    tabIndex?: number;
}

const classNames = cn('checkbox');

export const Checkbox: FC<CheckboxPropsType> = memo((props) => {
    const node = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (node.current && props.autoFocus && !props.disabled) {
            node.current.focus();
        }
    }, [ props.autoFocus, props.disabled ]);

    const handleClick = () => {
        if (props.disabled || props.defaultChecked) {
            return;
        }
        if (props.onChange) {
            props.onChange(!props.checked);
        }
    };

    const isChecked = props.checked || props.defaultChecked;
    return (
        <div
            className={ classNames({
                checked:  isChecked,
                disabled: props.disabled,
                height:   props.height,
                margin:   props.margin,
                label:    !!props.label,
                size:     props.size
            }, null, props.className) }
            onClick={ handleClick }
            ref={ node }
            style={ props.style }
        >
            <input
                type="checkbox"
                aria-checked={ isChecked }
                tabIndex={ props.tabIndex }
                disabled={ props.disabled }

            />
            { props.label ? (
                <Button
                    margin="xxs"
                    fullWidth
                    viewType="transparent"
                    weight={ isChecked ? 'bold' : 'regular' }
                    size="md"
                    align="left"
                    radius="sm"
                    icon={ isChecked ? 'checkboxCheckedNew' : 'checkboxUncheckedNew' }
                    color={ props.color }
                    iconColor={ props.iconColor }
                    iconSize={ 24 }
                    iconMargin="xxs"
                >
                    { props.label }
                </Button>
            ) : (
                <Icon
                    size={ 24 }
                    name={ isChecked ? 'checkboxCheckedNew' : 'checkboxUncheckedNew' }
                    style={ props.label ? { marginRight: '4px', pointerEvents: 'none' } : { pointerEvents: 'none' } }
                />
            ) }
        </div>
    );
});

Checkbox.defaultProps = {
    defaultChecked: false,
    disabled:       false,
    autoFocus:      false,
    size:           'sm'
};
