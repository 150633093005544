import React, { forwardRef, memo, useState } from 'react';
import { InputPropsType }                    from '../input.component';

export type FileInputComponentProps = {
    // specific props if needed
    accept?: string;
    multiple?: boolean;
} & Partial<InputPropsType>;

export const FileInputComponent = memo(forwardRef((props: FileInputComponentProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
        name, id, accept, multiple, onChange, onBlur, value, ...rest
    } = props;
    const [ files, setFiles ] = useState<File[]>(value as unknown as File[] || ([] as File[]));

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files: newFiles } = event.target;

        if (newFiles) {
            setFiles(Array.from(newFiles));
            onChange && onChange(event);
        }
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (props.onBlur) {
            props.onBlur(event);
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
        if (props.onClick) {
            props.onClick(event);
        }
    };

    return (
        <input
            type='file'
            id={ id }
            name={ name }
            accept={ accept }
            multiple={ multiple }
            onChange={ handleChange }
            onBlur={ handleBlur }
            onClick={ handleClick }
            ref={ ref }
        />
    );
}));
