import { useContext, useState } from 'react';
import { useInject }            from '@geenee/shared';
import { useStripe }            from '@stripe/react-stripe-js';
import isEmpty                  from 'lodash-es/isEmpty';
import PaymentSuccessful        from '@geenee/builder/src/components/modals/PaymentSuccesful';
import { useBuilderInject }     from '@geenee/builder/src/core/hook/use-builder-inject';
import { stripeState }          from '@geenee/builder/src/core/state/stripe.state';
import { teamState }            from '@geenee/builder/src/core/state/team.state';
import { GRINGOTTS_AUTH }       from "@geenee/builder/src/lib/constants";
import dealLocalStorage         from "@geenee/builder/src/lib/dealLocalStorage";
import ModalUpdaterContext      from '@geenee/builder/src/lib/ModalUpdaterContext';

type RemoveAfterDowngradeProps = {
    deleteProjects?: string[];
    deleteMembers?: string[];
};

export const useSubscriptionHandlers = () => {
    const { AppState } = useInject();
    const { BuilderState } = useBuilderInject();
    const stripe = useStripe();
    const setModalOptions = useContext(ModalUpdaterContext);
    const [ waiting, setWaiting ] = useState(false);

    const deleteDataOnDowngrade = async (removeAfterDowngrade: RemoveAfterDowngradeProps) => {
        const { deleteProjects, deleteMembers } = removeAfterDowngrade;
        let deleteProjectsPromises: any[] = [];
        let deleteMembersPromises: any[] = [];
        if (deleteProjects) {
            // UPD: they will be unpublished instead of deleting
            deleteProjectsPromises = deleteProjects.map((id: string) => {
                const projectItem = AppState.projectsRegistry.get(id) as any;
                projectItem.published = false;
                return projectItem.saveData();
            });
        }

        if (deleteMembers) {
            deleteMembersPromises = deleteMembers.map((email: string) => teamState.deleteMemberOrInvite(email));
        }
        await Promise.all([ ...deleteProjectsPromises, ...deleteMembersPromises ]);
    };

    const makePurchase = async (
        paymentMethodId?: string,
        priceId?: string,
        paymentState?: any,
        showError?: (message: string) => void,
        removeAfterDowngrade?: RemoveAfterDowngradeProps,
        showSuccessWindow = true
    ) => {
        setWaiting(true);
        const doDowngradeDelete = !isEmpty(removeAfterDowngrade);
        try {
            const subscription = await stripeState.buySubscription(priceId);
            if (subscription && subscription.secret_key) {
                const res = await stripe?.confirmCardPayment(subscription.secret_key, { payment_method: paymentMethodId });
                if (res?.error) {
                    throw new Error(res.error.message);
                }
            }
            if (!dealLocalStorage('get', GRINGOTTS_AUTH)) {
                await BuilderState.downloadGringottsToken();
            }
            doDowngradeDelete && removeAfterDowngrade && await deleteDataOnDowngrade(removeAfterDowngrade);
            showError && showError('');

            const { profile, company } = await BuilderState.initializationService.checkSubAndFetchData();
            showSuccessWindow && setModalOptions({ component: PaymentSuccessful });
            await BuilderState.setCurrentUserProperties(company, profile);
        } catch (err: any) {
            console.error(err);
            showError && showError(err.message);
            setWaiting(false);
        }
        // setWaiting(false);
    };

    return { waiting, makePurchase };
};
