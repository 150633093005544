import React from 'react';

// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '18',
    height = '19',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            id={ id }
            onClick={ onClick }
            width={ width }
            height={ height }
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
            // eslint-disable-next-line max-len
                d="M3.54521 9C2.59124 9.31348 0.999756 9.36364 0.999756 9.36364C0.999756 9.36364 2.58245 9.71418 3.54521 10.0909C4.90675 10.6237 5.82998 10.8313 6.81794 11.9091C7.67622 12.8454 7.83712 13.625 8.27248 14.8182C8.67387 15.9183 8.99976 17.7273 8.99976 17.7273C8.99976 17.7273 9.32564 15.9183 9.72703 14.8182C10.1624 13.625 10.3173 12.8399 11.1816 11.9091C12.2619 10.7456 13.3394 10.6695 14.8179 10.0909C15.6543 9.76363 16.9998 9.36364 16.9998 9.36364C16.9998 9.36364 15.6449 9.24962 14.8179 9C13.2325 8.52143 12.2824 8.05545 11.1816 6.81818C10.4205 5.96282 10.1507 5.33637 9.72703 4.27273C9.24256 3.0564 8.99976 1 8.99976 1C8.99976 1 8.75695 3.0564 8.27248 4.27273C7.84883 5.33637 7.56989 5.95482 6.81794 6.81818C5.80908 7.9765 5.0045 8.52047 3.54521 9Z"
                stroke="black"
                strokeWidth="1.7"
                strokeLinejoin="round"
            />
        </svg>
    );
}
