import React, { useEffect } from 'react';
import { useHistory }       from "@geenee/common";
import { Divider, Wrapper } from '@geenee/ui';
import { observer }         from 'mobx-react';
import { HeaderTitle }      from "@geenee/builder/src/components/HeaderTitle";
import { useBuilderInject } from '@geenee/builder/src/core/hook/use-builder-inject';
import { MainContainer }    from '@geenee/builder/src/pages/MainContainer';
import { ProjectsList }     from './components/ProjectsList';
import { WalletSideBar }    from './components/WalletSideBar';

export const DashboardNFT = observer(() => {
    const { BuilderState } = useBuilderInject();
    const history = useHistory();

    useEffect(() => {
        if (!BuilderState.isNftAvailable) {
            history.push('/');
        }
    }, [ BuilderState.isNftAvailable ]);

    return (
        <MainContainer>
            <HeaderTitle title="NFT Dashboard" />
            <Divider transparent margin="md" />
            <Wrapper row fullWidth>
                <Wrapper fullWidth maxWidth='640px' style={ { flexShrink: 0 } }>
                    <ProjectsList />
                </Wrapper>
                <Wrapper style={ { paddingLeft: '78px' } }>
                    <WalletSideBar />
                </Wrapper>
            </Wrapper>
        </MainContainer>
    );
});
