import styled from 'styled-components';

export const Root = styled.div`
    padding: 32px 40px 40px;
`;

export const Wrapper = styled.div`
    display: inline-block;
    overflow-y: auto;
    max-height: 447px;
    width: 849px;
    margin-top: 23px;
    table {
        width: 100%;
        border-spacing: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border: 1px solid
            ${ ({ theme: { secondaryColors } }) => secondaryColors.grey20 };
    }
`;

export const TableHead = styled.thead`
    font-size: 12px;

    th {
        padding: 20px 0;
        background: #f5f5f5;
        text-align: left;
        position: sticky;
        top: 0;
        box-shadow: 0 1.5px 0 rgba(88, 88, 88, 0.25);

        &:first-child {
            border-top-left-radius: 4px;
            padding-left: 36px;
        }

        &:last-child {
            border-top-right-radius: 4px;
            text-align: right;
            padding-right: 36px;
        }
    }
`;

export const TableBody = styled.tbody`
    border-top: 0;
    font-size: 12px;

    td {
        padding: 20px 0;
        text-align: left;
        border-bottom: 1px solid
            ${ ({ theme: { secondaryColors } }) => secondaryColors.grey20 };

        &:first-child {
            padding-left: 36px;
        }

        &:last-child {
            text-align: right;
            padding-right: 36px;
        }
    }
`;
