import { CustomCodeAttachmentModel } from "@geenee/shared/src/magellan/atom/custom-module-atom/model/custom-code-attachment.model";
import { CustomModuleAtomModel }     from "@geenee/shared/src/magellan/atom/custom-module-atom/model/custom-module-atom.model";
import { AtomFactoryType }           from "@geenee/shared/src/magellan/atom/factory/atom.factory";
import type { MoleculeModel }        from "@geenee/shared/src/magellan/molecule/model/molecule.model";
import { FactoryAbstract }           from "@geenee/shared/src/util/factoryContainer";
import { injectable }                from "inversify";
import { runInAction }               from "mobx";

type ParentType = MoleculeModel

@injectable()
export class CustomModuleAtomFactory extends FactoryAbstract implements AtomFactoryType {
    create(atomSchema: CustomModuleAtomModel, $parent?: ParentType) {
        console.log("CustomModuleAtomFactory.create() called");
        console.log("atomSchema:", atomSchema);
        const atomModel: CustomModuleAtomModel = this.container.get("<CustomModuleAtomModel>");
        console.log("atomModel:", atomModel);
        const attachmentFactory = this.container.get("<AttachmentFactory>");

        Object.assign(atomModel, atomSchema);
        atomModel.atomSchema = atomSchema;
        atomSchema?.assets?.forEach((assetSchema: Partial<CustomCodeAttachmentModel>) => {
            runInAction(() => {
                const asset = attachmentFactory.create(assetSchema, atomModel) as CustomCodeAttachmentModel;
                if (process.env.ENV_GEENEE_APP !== "BUILDER") {
                    asset.loadAndEval();
                } else {
                    asset.load();
                }
                atomModel.assetsRegistry.set(asset.id, asset);
            });
        });
        if ($parent) {
            atomModel.$parent = $parent;
        }
        return atomModel;
    }
}
