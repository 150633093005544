import React, { FC, memo, MouseEventHandler, useEffect, useRef } from 'react';
import { cn }                                                    from '../../util/bem';
import { FormSizesType, SizesUnionType, TextColorType }          from '../../util/global-props';
import { Description }                                           from '../description/description.component';
import './switch.component.scss';

export type SwitchPropsType = {
    id?: boolean

    label?: React.ReactNode;
    disabled?: boolean;
    checked?: boolean;

    autoFocus?: boolean;
    size?: 'md' | 'lg';
    descriptionSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    height?: FormSizesType;
    margin?: SizesUnionType;
    color?: TextColorType;
    style?: React.CSSProperties;

    flex?: number;

    onChange?: (checked: boolean) => void;
    onClick?: MouseEventHandler<HTMLInputElement>;
    onMouseUp?: MouseEventHandler<HTMLInputElement>;

    tabIndex?: number;
}

const classNames = cn('switch');

export const Switch: FC<SwitchPropsType> = memo((props) => {
    const node = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (node.current && props.autoFocus && !props.disabled) {
            node.current.focus();
        }
    }, [ props.autoFocus, props.disabled ]);

    const handleClick = () => {
        if (props.disabled) {
            return;
        }
        if (props.onChange) {
            props.onChange(!props.checked);
        }
    };

    return (
        <div
            className={ classNames({
                checked:  props.checked,
                disabled: props.disabled,
                size:     props.size,
                height:   props.height,
                margin:   props.margin
            }) }
            onClick={ handleClick }
            ref={ node }
            style={ props.style }
        >
            <input
                id={ props.id }
                type="checkbox"
                role="switch"
                aria-checked={ props.checked }
                tabIndex={ props.tabIndex }
                disabled={ props.disabled }
                className={ classNames('element', { margin: !!props.label }) }
            />
            { props.label && (
                <Description style={ props.descriptionSize === 'xs' && props.size === 'lg' && { marginTop: 4 } || {} } size={ props.descriptionSize } color={ props.color }>
                    { props.label }
                </Description>
            ) }
        </div>
    );
});

Switch.defaultProps = {
    size:      'md',
    disabled:  false,
    autoFocus: false
};
