import * as React            from 'react';
import { CustomCodeWrapper } from "@geenee/builder/src/magellan/components/drawers/customCode/CustomCodeWrapper";
import {
    Object3DAssetsParser,
    Object3DAssetsParserPropsType
} from '@geenee/geeclient-kit/src/lib/component/scene/components/Object3DAssetsParser/Object3DAssetsParser';
import { GeeneeScene }                           from '@geenee/geeclient-kit/src/lib/component/scene/GeeneeScene.component';
import GLTF_TEMPLATE, { GLTFComponentPropsType } from '@geenee/shared/src/magellan/molecule/component/SceneMolecule/GLTF_TEMPLATE';

export type DIContextType = {
    VideoCanvasWrapper: React.ComponentType<any>
    VideoStreamWrapper: React.ComponentType<any>
    SlamWrapper: React.ComponentType<any>
    StampWrapper: React.ComponentType<any>
    BodytrackingWrapper: React.ComponentType<any>
    FacetrackingWrapper: React.ComponentType<any>
    IntroBox: React.ComponentType<any>
    ProgramViewWrapper: React.ComponentType<any>
    ExperienceContent: React.ComponentType<any>
    ContentWrapper: React.ComponentType<any>
    PageEmbedWrapper: React.ComponentType<any>
    ImageGalleryWrapper: React.ComponentType<any>
    EmptyGallerySlideWrapper: React.ComponentType<any>
    EmptyVideoWrapper: React.ComponentType<any>
    BasicDrawerWrapper: React.ComponentType<any>
    TriggersComponent: React.ComponentType<any>
    CampaignImageWrapper: React.ComponentType<any>
    BrandingBarWrapper: React.ComponentType<any>
    ExperienceWrapper: React.ComponentType<any>
    BasicDrawerContentWrapper: React.ComponentType<any>
    AssetSwitcherItemWrapper: React.ComponentType<any>
    CustomCodeTransparentButtonComponent: React.ComponentType<any>
    SlamAR: React.ComponentType<any>
    StampAR: React.ComponentType<any>
    NativeAR: React.ComponentType<any>
    GeeLTFSceneComponent: React.ComponentType<any>
    useSceneAssetsActions: () => any
    AssetsSourcePicker: React.ComponentType<any>
    PhoneContentWrapper: React.ComponentType<any>
    SceneBuildWrapper: React.ComponentType<any>
    SectionWrapperComponent: React.ComponentType<any>
    ExperienceWrapperComponent: React.ComponentType<any>
    ProjectWrapperComponent: React.ComponentType<any>
    ViewWrapperComponent: React.ComponentType<any>
    SceneMoleculeComponent: React.ComponentType<GLTFComponentPropsType>
    SceneAtomComponent: React.ComponentType<Object3DAssetsParserPropsType>
    SceneControls: React.ComponentType<any>
    SceneControls2d: React.ComponentType<any>
    useDeleteModal: (props: any) => any
    MoleculeWrapper: React.ComponentType<any>;
    MoleculeChildrenWrapper: React.ComponentType<any>;
    MoleculeChildWrapper: React.ComponentType<any>;
    enableLazyLoading: boolean;
    Viewer3D: React.ComponentType<any>;
    CustomCodeWrapper: React.ComponentType<any>;
}

export type DICustomContextType = {
    'SlamMotionPermissionWindow'?: React.ComponentType<{onRequestPermission: () => void}>
    'VideoPermissionWindow'?: React.ComponentType<{onRequestPermission: () => void}>
}

export const initDI: DIContextType = {
    VideoCanvasWrapper:                   (props) => <>{ props.children }</>,
    VideoStreamWrapper:                   (props) => <>{ props.children }</>,
    SlamWrapper:                          (props) => <>{ props.children }</>,
    StampWrapper:                         (props) => <>{ props.children }</>,
    BodytrackingWrapper:                  (props) => <>{ props.children }</>,
    FacetrackingWrapper:                  (props) => <>{ props.children }</>,
    IntroBox:                             (props) => <>{ props.children }</>,
    ProgramViewWrapper:                   (props) => <>{ props.children }</>,
    ExperienceContent:                    (props) => <>{ props.children }</>,
    ContentWrapper:                       (props) => <>{ props.children }</>,
    PageEmbedWrapper:                     (props) => <>{ props.children }</>,
    ImageGalleryWrapper:                  (props) => <>{ props.children }</>,
    EmptyGallerySlideWrapper:             (props) => <>{ props.children }</>,
    EmptyVideoWrapper:                    (props) => <>{ props.children }</>,
    BasicDrawerWrapper:                   (props) => <>{ props.children }</>,
    TriggersComponent:                    (props) => <>{ props.children }</>,
    CampaignImageWrapper:                 (props) => <>{ props.children }</>,
    BrandingBarWrapper:                   (props) => <>{ props.children }</>,
    ExperienceWrapper:                    (props) => <>{ props.children }</>,
    BasicDrawerContentWrapper:            (props) => <>{ props.children }</>,
    AssetSwitcherItemWrapper:             (props) => <>{ props.children }</>,
    CustomCodeTransparentButtonComponent: () => <></>,
    SlamAR:                               (props) => <GeeneeScene { ...props } type="slam" />,
    NativeAR:                             (props) => <GeeneeScene { ...props } type="slam" />,
    StampAR:                              (props) => <GeeneeScene { ...props } type="tracking" />,
    GeeLTFSceneComponent:                 (props) => <>{ props.children }</>,
    useSceneAssetsActions:                () => {},
    AssetsSourcePicker:                   (props) => <>{ props.children }</>,
    PhoneContentWrapper:                  (props) => <>{ props.children }</>,
    SceneBuildWrapper:                    (props) => <>{ props.children }</>,
    SectionWrapperComponent:              (props) => <>{ props.children }</>,
    ExperienceWrapperComponent:           (props) => <>{ props.children }</>,
    ProjectWrapperComponent:              (props) => <>{ props.children }</>,
    ViewWrapperComponent:                 (props) => <>{ props.children }</>,
    // SceneMoleculeComponent:               (props) => <GLTF_TEMPLATE { ...props } />,
    SceneMoleculeComponent:               (props) => <GLTF_TEMPLATE { ...props } />,
    SceneAtomComponent:                   (props: Object3DAssetsParserPropsType) => <Object3DAssetsParser { ...props } />,
    SceneControls:                        () => <></>,
    SceneControls2d:                      () => <></>,
    useDeleteModal:                       () => {},
    MoleculeWrapper:                      (props) => <>{ props.children }</>,
    MoleculeChildrenWrapper:              ({ children, className }: any) => (
        <div className={ className }>{ children }</div>
    ),
    MoleculeChildWrapper: ({ children }) => children,
    enableLazyLoading:    true,
    Viewer3D:             (props) => <>{ props.children }</>,
    CustomCodeWrapper:    (props) => <>{ props.children }</>
};

export const DI: React.Context<DIContextType> = React.createContext({ ...initDI });

export const createComponentContext = (
    updatedDi: DIContextType
): React.ContextType<any> => React.createContext({ ...updatedDi });
