import { action, makeAutoObservable, observable, set } from 'mobx';

export class NftWalletModel {
  @observable address = '';
  @observable balance = 0;
  @observable status = 0;
  constructor() {
      makeAutoObservable(this);
  }

  @action init(data: Partial<NftWalletModel>) {
      Object.keys(data).forEach((key) => {
          set(this, key, data[ key as keyof NftWalletModel ]);
      });
  }
}
