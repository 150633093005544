import { AssetsSourcePickerBabylon } from "@geenee/builder/src/components/AssetsSourcePickerBabylon";
import Modal                         from "@geenee/builder/src/components/Modal";
import WithModal                     from "@geenee/builder/src/components/WithModal";
import { BuilderState }              from "@geenee/builder/src/core/state/builder.state";
import { StripeState, stripeState }  from "@geenee/builder/src/core/state/stripe.state";
import { InitializationService }     from "@geenee/builder/src/lib/InitializationService";
import { SceneManagerFactory }       from "@geenee/builder/src/magellan/factory/scene-manager.factory";
import { AtomModel }                 from "@geenee/builder/src/magellan/model/atom.model";
import { CustomCodeAttachmentModel } from "@geenee/builder/src/magellan/model/custom-code-attachemnt.model";
import theme                         from "@geenee/builder/src/styles/themeV2";
import { DependencyModule }          from "@geenee/common";
import { CustomModuleAtomFactory }   from "@geenee/shared/src/magellan/atom/custom-module-atom/factory/custom-module-atom.factory";
import { CustomModuleAtomModel }     from "@geenee/shared/src/magellan/atom/custom-module-atom/model/custom-module-atom.model";
import { TeamState }                 from "~/core/state/team.state";
import { ProjectFactory }            from '../factory/project.factory';
import { AttachmentModel }           from "../model/attachment.model";
import { ExperienceModel }           from "../model/experience.model";
import { MoleculeModel }             from "../model/molecule.model";
import { ProjectModel }              from "../model/project.model";
import { SectionModel }              from "../model/section.model";
import { ViewModel }                 from "../model/view.model";
import { MagellanState }             from "../state/app.state";
import { DI as ComponentsDI }        from "./components-lib";

// store
window.GeespectorDI = { AssetsSourcePickerBabylon };

@DependencyModule({
    Factory: [ { symbol: "<SceneManagerFactory>", use: SceneManagerFactory },
        { symbol: "<CustomModuleAtomFactory>", use: CustomModuleAtomFactory },
        {
            symbol: "<ProjectFactory>",
            use:    ProjectFactory
        } ],
    State: [ {
        symbol: "<BuilderState>",
        use:    BuilderState
    }, { symbol: "<StripeState>", use: StripeState }, {
        symbol: "<AppState>",
        use:    MagellanState
    }, { symbol: "<TeamState>", use: TeamState } ],
    Model: [
        { symbol: "<AtomModel>", use: AtomModel },
        {
            symbol: "<AttachmentModel>",
            use:    AttachmentModel
        },
        { symbol: "<ExperienceModel>", use: ExperienceModel },
        { symbol: "<MoleculeModel>", use: MoleculeModel },
        {
            symbol: "<ProjectModel>",
            use:    ProjectModel
        },
        { symbol: "<SectionModel>", use: SectionModel },
        { symbol: "<ViewModel>", use: ViewModel },
        { symbol: "<CustomCodeAttachmentModel>", use: CustomCodeAttachmentModel },
        { symbol: "<CustomModuleAtomModel>", use: CustomModuleAtomModel },
        { symbol: "<InitializationService>", use: InitializationService }
    ],
    Component: [ { symbol: "<WithModal>", use: WithModal }, {
        symbol: "<Modal>",
        use:    Modal
    },
    {
        symbol: "<DIContextGetter>",
        use:    ComponentsDI
    }
    ],
    Constants: [ {
        symbol: "<StripeState>",
        use:    stripeState
    }, {
        symbol: "<Theme>",
        use:    theme
    } ]
})
class AppModule {}

export const appModule = new AppModule();

// eslint-disable-next-line
export const container = appModule.di.container;
