/* eslint-disable max-len */
import React from 'react';

export const Profile = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      d="M8 8C9.65685 8 11 6.433 11 4.5C11 2.567 9.65685 1.5 8 1.5C6.34315 1.5 5 2.567 5 4.5C5 6.433 6.34315 8 8 8Z"
      fill="#303036"
    />
    <path
      className="fill"
      d="M14 11.6671C14.0012 11.3481 13.9196 11.0356 13.7649 10.7669C13.6102 10.4982 13.3889 10.2848 13.1277 10.1524C11.5108 9.37076 9.76325 8.97833 8 9.00092C6.23675 8.97833 4.48918 9.37076 2.87226 10.1524C2.61108 10.2848 2.38985 10.4982 2.23513 10.7669C2.08041 11.0356 1.99877 11.3481 2.00001 11.6671V13C2.00001 13.5523 2.44773 14 3.00001 14H13C13.5523 14 14 13.5523 14 13V11.6671Z"
      fill="#303036"
    />
  </svg>
);
export const Card = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect className="fill" y="4" width="16" height="3" fill="#303036" />
    <path
      className="fill"
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 2C0.447715 2 0 2.44772 0 3V13C0 13.5523 0.447715 14 1 14H15C15.5523 14 16 13.5523 16 13V3C16 2.44772 15.5523 2 15 2H1ZM2 10C2 9.44772 2.44772 9 3 9H8C8.55228 9 9 9.44772 9 10C9 10.5523 8.55228 11 8 11H3C2.44772 11 2 10.5523 2 10ZM12 10C12 9.44772 12.4477 9 13 9C13.5523 9 14 9.44772 14 10C14 10.5523 13.5523 11 13 11C12.4477 11 12 10.5523 12 10Z"
      fill="#303036"
    />
  </svg>
);
export const Bell = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      d="M8 16C8.82875 16 9.5 15.2656 9.5 14.359H6.5C6.5 15.2656 7.17125 16 8 16ZM13.0238 11.2397C12.6868 10.8711 12.5 10.3897 12.5 9.89033V6.97436C12.5 4.45128 11.2738 2.34667 9.125 1.78872V1.23077C9.125 0.549744 8.6225 0 8 0C7.3775 0 6.875 0.549744 6.875 1.23077V1.78872C4.72625 2.34667 3.5 4.45128 3.5 6.97436V9.89033C3.5 10.3897 3.31316 10.8711 2.97622 11.2397L2.23219 12.0537C2.08283 12.2171 2 12.4305 2 12.6518C2 13.1415 2.39695 13.5385 2.88661 13.5385H13.1134C13.603 13.5385 14 13.1415 14 12.6518C14 12.4305 13.9172 12.2171 13.7678 12.0537L13.0238 11.2397Z"
      fill="#303036"
    />
  </svg>
);
export const Speaker = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2519 12.5725L7 9.57142V13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13L4 9.49999C2.89543 9.49999 2 8.60456 2 7.49999V6.42186C2 5.31729 2.89543 4.42186 4 4.42186H7V4.42856L12.2519 1.42746C12.5853 1.23698 13 1.47767 13 1.86158V12.1384C13 12.5223 12.5853 12.763 12.2519 12.5725ZM15.5 6.99999C15.5 7.99999 15 8.99999 14 8.99999V6.99999V4.99999C15 4.99999 15.5 5.99999 15.5 6.99999Z"
      fill="#303036"
    />
  </svg>
);
export const Price = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.76522 0.144819C8.50441 0.148121 8.25521 0.253196 8.07077 0.437632L0.455369 8.05303C0.0648444 8.44356 0.0648446 9.07672 0.455369 9.46725L6.5033 15.5152C6.89383 15.9057 7.52699 15.9057 7.91752 15.5152L15.5329 7.89978C15.7174 7.71534 15.8224 7.46614 15.8257 7.20533L15.9033 1.07986C15.9104 0.517707 15.4528 0.0601657 14.8907 0.0672816L8.76522 0.144819ZM11.6694 6.42252C12.2552 5.83674 12.2552 4.88699 11.6694 4.3012C11.0836 3.71542 10.1339 3.71542 9.54811 4.3012C8.96232 4.88699 8.96232 5.83674 9.54811 6.42252C10.1339 7.00831 11.0836 7.00831 11.6694 6.42252Z"
      fill="#303036"
    />
  </svg>
);
export const Notification = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      opacity="0.3"
      d="M13 16H1C0.447 16 0 15.552 0 15V3C0 2.448 0.447 2 1 2H7.5C7.77614 2 8 2.22386 8 2.5V3.5C8 3.77614 7.77614 4 7.5 4H2.5C2.22386 4 2 4.22386 2 4.5V13.5C2 13.7761 2.22386 14 2.5 14H11.5C11.7761 14 12 13.7761 12 13.5V8.5C12 8.22386 12.2239 8 12.5 8H13.5C13.7761 8 14 8.22386 14 8.5V15C14 15.552 13.553 16 13 16Z"
      fill="#303036"
    />
    <path
      className="fill"
      d="M13 6C14.6569 6 16 4.65685 16 3C16 1.34315 14.6569 0 13 0C11.3431 0 10 1.34315 10 3C10 4.65685 11.3431 6 13 6Z"
      fill="#303036"
    />
  </svg>
);
