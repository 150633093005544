import { action, makeAutoObservable, observable }                        from 'mobx';
import spotifyAPI, { getCredsByRefreshToken, getCredsByToken, getTrack } from '@geenee/builder/src/api/SpotifyAPI';
import { BuilderState }                                                  from '@geenee/builder/src/core/state/builder.state';
import { TOAST_ERROR }                                                   from '@geenee/builder/src/lib/constants';

export class SpotifyState {
    @observable isLoading = false;
    @observable foundTracksArr = [];
    @observable page = 0;
    @observable token = localStorage.getItem('sp-access-token') || '';
    @observable searchString = '';
    @observable builderState!: BuilderState;
    @observable choosedTrack: spotifyAPI.TrackObjectFull = {} as any;
    constructor() {
        makeAutoObservable(this);
    }

    init(builderState: BuilderState) {
        this.builderState = builderState;
    }

    @action
        addFoundTracks = (value: SpotifyApi.TrackObjectFull[]) => {
            if (!this.page) {
                this.foundTracksArr = value.filter((t) => t.preview_url) as any;
            } else {
                this.foundTracksArr = [ ...this.foundTracksArr, ...value.filter((t) => t.preview_url) ] as any;
            }
        };

    @action
    async search(value: {query: string; page: number; offset: number; limit: number}) {
        try {
            this.isLoading = true;
            this.searchString = value.query;
            if (!value.query) {
                this.foundTracksArr = [];
                this.page = value.page;
            } else {
                const data = await spotifyAPI.searchTracks(value.query, {
                    offset: value.offset,
                    limit:  value.limit
                });
                this.page = value.page;
                this.addFoundTracks(data.tracks.items);
                this.isLoading = false;
            }
        } catch (err: any) {
            console.log(err);
            // @ts-ignore
            if (err.status === 401) {
                const { data: { access_token, refresh_token } } = await getCredsByRefreshToken();
                access_token && localStorage.setItem('sp-access-token', access_token);
                refresh_token && localStorage.setItem('sp-refresh-token', refresh_token);
                this.token = access_token;
                spotifyAPI.setAccessToken(access_token);
                this.search(value);
            } else {
                this.builderState.toast =                   {
                    severity: TOAST_ERROR,
                    // @ts-ignore
                    detail:   err.message,
                    summary:  ''
                };
            }
        }
    }

    @action
    async getCredsByToken(value: {code: string; verifier: string}) {
        try {
            const { code, verifier } = value;
            this.isLoading = true;

            const { access_token, refresh_token } = await getCredsByToken(
                code,
                verifier
            );

            localStorage.setItem('sp-access-token', access_token);
            localStorage.setItem('sp-refresh-token', refresh_token);

            this.token = access_token;
            spotifyAPI.setAccessToken(access_token);
            this.isLoading = false;
        } catch (err: any) {
            // @ts-ignore
            console.log(err.message);
            localStorage.removeItem('sp-access-token');
            localStorage.removeItem('sp-refresh-token');
            this.builderState.toast = {
                severity: TOAST_ERROR,
                // @ts-ignore
                detail:   err.message,
                summary:  ''
            };
        }
    }

    @action
    async getTrack(value: string) {
        try {
            this.isLoading = true;
            const track = await getTrack(value);

            this.choosedTrack = track;
            // @ts-ignore
            this.foundTracksArr = [ track ];
            this.isLoading = false;
        } catch (err: any) {
            // @ts-ignore
            console.log(err.message);
            // @ts-ignore
            if (err.status === 401) {
                const { data: { access_token, refresh_token } } = await getCredsByRefreshToken();
                access_token && localStorage.setItem('sp-access-token', access_token);
                refresh_token && localStorage.setItem('sp-refresh-token', refresh_token);
                this.token = access_token;
                spotifyAPI.setAccessToken(access_token);
                this.getTrack(value);
            } else {
                this.builderState.toast =                   {
                    severity: TOAST_ERROR,
                    // @ts-ignore
                    detail:   err.message,
                    summary:  ''
                };
            }
        }
    }

    @action
    setChoosedTrack(value: spotifyAPI.TrackObjectFull) {
        this.choosedTrack = value;
    }

    @action
    clearData() {
        this.isLoading = false;
        this.token =          localStorage.getItem('sp-access-token') || '';
        this.foundTracksArr = [];
        this.choosedTrack =   {} as any;
        this.page =           0;
        this.searchString =   '';
    }
}

export const spotifyState = new SpotifyState();
