import styled from 'styled-components';

export const DragDropOverlay = styled.div<{ $border?: boolean }>`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px dashed black !important;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
    z-index: 2;
    color: ${ ({ theme }) => theme.colors.black }
`;
