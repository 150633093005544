import React                    from 'react';
import { useInject }            from '@geenee/shared';
import { Tooltip, Wrapper }     from '@geenee/ui';
import { observer }             from 'mobx-react';
import { LinkButton }           from '@geenee/builder/src/components/ProjectsLinkDropdown/components/HeaderButtons/LinkButton';
import { PreviewPublishButton } from '@geenee/builder/src/components/ProjectsLinkDropdown/components/HeaderButtons/PreviewPublishButton';
import { ReloadButton }         from '@geenee/builder/src/components/ProjectsLinkDropdown/components/HeaderButtons/ReloadButton';
import { useBuilderInject }     from "@geenee/builder/src/core/hook/use-builder-inject";

export const HeaderButtons = observer(({
    disabled, publish, isMobile, onPreviewPublishClick, onLinkButtonClick
}: {disabled: boolean, publish: boolean, isMobile: boolean, onPreviewPublishClick: (val?: boolean)=> void, onLinkButtonClick: ()=> void}) => {
    const { AppState } = useInject();
    const { BuilderState } = useBuilderInject();
    const { profile } = BuilderState.currentUser;

    const mainProgram = AppState.activeProject;

    const projectPublished = mainProgram?.published || false;
    const { dirty: isChangeDetected } = mainProgram?.options || { dirty: false };

    const disableActions = publish && profile?.role === 'editor';
    return (
        <Wrapper row>
            <Tooltip
                position="bottom"
                shadow
                menuWidth="200px"
                message="You have not enough permissions to publish this project."
                disabled={ !disableActions }
                delay={ 500 }
            >
                <Wrapper row>
                    { !disabled ? (
                        <PreviewPublishButton
                            disabled={ disableActions }
                            projectPublished={ projectPublished }
                            isChangeDetected={ isChangeDetected }
                            onClick={ () => onPreviewPublishClick() }
                            publish={ publish }
                        />
                    ) : <></> }
                    { publish && !disabled && isChangeDetected && projectPublished && (
                        <ReloadButton
                            disabled={ disableActions }
                            onClick={ () => onPreviewPublishClick(true) }
                        />
                    ) }
                </Wrapper>
            </Tooltip>
            { publish && projectPublished && (
                <LinkButton
                    isMobile={ isMobile }
                    onClick={ onLinkButtonClick }
                />
            ) }
        </Wrapper>
    );
});
