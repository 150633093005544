import React from 'react';

export const SceneBuild = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M10.6644 1.84732C11.495 1.38423 12.5049 1.38423 13.3355 1.84732L21.1675 6.21418C21.4744 6.38532 21.75 6.58866 21.75 6.98192V17.0023C21.7555 17.2755 21.6093 17.5438 21.3553 17.6811L13.3355 22.1527C12.505 22.6158 11.4951 22.6158 10.6645 22.1527L2.64568 17.6817C2.52048 17.6142 2.41659 17.5121 2.34672 17.3881C2.28285 17.2748 2.24753 17.1412 2.25 17.0039V6.98192C2.25 6.58879 2.5255 6.38529 2.83239 6.21418L10.6644 1.84732ZM3.75 16.5753L11.25 20.757V12.4387C11.2163 12.4227 11.1829 12.4055 11.1501 12.3871L3.75 8.26112V16.5753ZM12.75 20.757L20.25 16.5753V8.26105L12.8498 12.3871C12.817 12.4055 12.7837 12.4226 12.75 12.4387V20.757ZM12.607 3.1633C12.2295 2.9528 11.7704 2.9528 11.3929 3.1633L4.54428 6.98184L11.8785 11.0712C11.954 11.1133 12.0458 11.1133 12.1213 11.0712L19.4556 6.98184L12.607 3.1633Z" fill="none" />
    </svg>
);

export const WorldAr = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path className="stroke" d="M12 11V5L17 8V14L12 11Z" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M22 11V5L17 8V14L22 11Z" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M22 5L17 2L12 5L17 8L22 5Z" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M12 22C9.23858 22 7 17.5228 7 12C7 6.47715 9.23858 2 12 2" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M12 22C14.0503 22 15.8124 19.5318 16.584 16" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M12 14V22" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M2 12H10" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" />
        <path className="stroke" d="M21.8 14C20.8734 18.5645 16.8379 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C12.3375 2 12.6711 2.01672 13 2.04938" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const MarkerAr = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path className="stroke" d="M11 3L6 3C4.34315 3 3 4.34315 3 6L3 18C3 19.6569 4.34315 21 6 21L18 21C19.6569 21 21 19.6569 21 18L21 15" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M12 11V5L17 8V14L12 11Z" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M22 11V5L17 8V14L22 11Z" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M22 5L17 2L12 5L17 8L22 5Z" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M4.5 19.5L9.08579 14.9142C9.86683 14.1332 11.1332 14.1332 11.9142 14.9142L17.5 20.5" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <circle className="fill" cx="8" cy="8" r="2" fill="#3B344F" />
    </svg>
);
export const NativeAr = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path className="stroke" d="M3 3V21H21" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M19.5 19.5L21 21L19.5 22.5" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M1.5 4.5L3 3L4.5 4.5" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M12 11V5L17 8V14L12 11Z" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M22 11V5L17 8V14L22 11Z" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M22 5L17 2L12 5L17 8L22 5Z" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M6 12H9" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" />
        <path className="stroke" d="M6 15H12" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" />
        <path className="stroke" d="M6 18H16" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
);

export const ImageGallery = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="fill" d="M9 11C10.1046 11 11 10.1046 11 9C11 7.89543 10.1046 7 9 7C7.89543 7 7 7.89543 7 9C7 10.1046 7.89543 11 9 11Z" fill="none" />
        <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M21.75 6V18C21.75 20.0711 20.0711 21.75 18 21.75H6C3.92893 21.75 2.25 20.0711 2.25 18V6C2.25 3.92893 3.92893 2.25 6 2.25H18C20.0711 2.25 21.75 3.92893 21.75 6ZM3.96926 18.97C3.82871 18.6763 3.75 18.3473 3.75 18L3.75 6C3.75 4.75736 4.75736 3.75 6 3.75L18 3.75C19.2426 3.75 20.25 4.75736 20.25 6V14.1893L18.4445 12.3838C17.3706 11.3099 15.6294 11.3099 14.5555 12.3838L12.5 14.4393L12.4445 14.3838C11.3706 13.3099 9.6294 13.3099 8.55546 14.3838L3.96926 18.97ZM17.3839 13.4445L20.25 16.3106V18C20.25 19.1739 19.3511 20.1378 18.204 20.2409C18.1677 20.1419 18.1098 20.0491 18.0303 19.9696L13.5607 15.4999L15.6161 13.4445C16.1043 12.9563 16.8957 12.9563 17.3839 13.4445ZM5.0299 20.0307C5.32364 20.1713 5.65263 20.25 6 20.25H16.1894L11.3839 15.4445C10.8957 14.9563 10.1043 14.9563 9.61612 15.4445L5.0299 20.0307Z" fill="none" />
    </svg>
);

export const Iframe = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M5 7C5.55228 7 6 6.5523 6 6C6 5.44771 5.55228 5 5 5C4.44771 5 4 5.44771 4 6C4 6.5523 4.44771 7 5 7ZM8 7C8.55229 7 9 6.5523 9 6C9 5.44771 8.55229 5 8 5C7.44772 5 7 5.44771 7 6C7 6.5523 7.44772 7 8 7ZM12 6C12 6.5523 11.5523 7 11 7C10.4477 7 10 6.5523 10 6C10 5.44771 10.4477 5 11 5C11.5523 5 12 5.44771 12 6Z" fill="black" />
        <path className="stroke" d="M2 9H22" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
        <path className="stroke" d="M8 13H16" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
        <path className="stroke" d="M8 16H16" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
        <path className="stroke" d="M2 6C2 4.34315 3.34315 3 5 3H19C20.6569 3 22 4.34315 22 6V18C22 19.6569 20.6569 21 19 21H5C3.34315 21 2 19.6569 2 18V6Z" stroke="black" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
);

export const Video = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path className="stroke" d="M3 6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6Z" stroke="black" strokeWidth="1.5" strokeLinejoin="round" />
        <path className="stroke" d="M9.5 9.72318C9.5 8.95536 10.3295 8.47399 10.9961 8.85494L14.9806 11.1318C15.6524 11.5157 15.6524 12.4843 14.9806 12.8682L10.9961 15.1451C10.3295 15.526 9.5 15.0446 9.5 14.2768V9.72318Z" stroke="black" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
);

export const Overlay = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path className="stroke" d="M13 3L11 3.00001V2.5H13V3Z" stroke="#3B344F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M18 12V4C18 2.89543 17.1046 2 16 2H8C6.89543 2 6 2.89543 6 4V12M18 15V20C18 21.1046 17.1046 22 16 22H8C6.89543 22 6 21.1046 6 20V15H18Z" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" d="M15 18.5H9" stroke="#3B344F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export function BodyTrackingAvatar() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M12 2C9.23858 2 7 4.23858 7 7C7 8.88174 8.03951 10.5207 9.5756 11.3739C6.34252 12.4009 4 15.4269 4 19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19C20 15.4269 17.6575 12.4009 14.4244 11.3739C15.9605 10.5207 17 8.88174 17 7C17 4.23858 14.7614 2 12 2ZM9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7ZM6 19C6 15.6863 8.68629 13 12 13C15.3137 13 18 15.6863 18 19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19Z" fill="#3E3B46" />
        </svg>
    );
}

export function BodyTrackingTwin() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M5.66667 9.33333C5.66667 5.83553 8.5022 3 12 3C15.4978 3 18.3333 5.83553 18.3333 9.33333C18.3333 12.0556 16.6158 14.3767 14.2053 15.2722C18.1097 16.2556 21 19.7903 21 24V25.3333C21 27.3584 19.3584 29 17.3333 29H6.66667C4.64162 29 3 27.3584 3 25.3333V24C3 19.7903 5.89028 16.2556 9.79471 15.2722C7.38418 14.3767 5.66667 12.0556 5.66667 9.33333ZM12 5C9.60677 5 7.66667 6.9401 7.66667 9.33333C7.66667 11.7266 9.60677 13.6667 12 13.6667C14.3932 13.6667 16.3333 11.7266 16.3333 9.33333C16.3333 6.9401 14.3932 5 12 5ZM5 24C5 20.134 8.13401 17 12 17C15.866 17 19 20.134 19 24V25.3333C19 26.2538 18.2538 27 17.3333 27H6.66667C5.74619 27 5 26.2538 5 25.3333V24Z" fill="#615B6F" />
            <path className="fill" d="M19 5.33333C19 4.78105 19.4477 4.33333 20 4.33333C23.4978 4.33333 26.3333 7.16886 26.3333 10.6667C26.3333 12.7346 25.3427 14.5699 23.8106 15.7256C27.6318 17.2424 30.3333 20.9718 30.3333 25.3333C30.3333 27.3584 28.6917 29 26.6667 29H24C23.4477 29 23 28.5523 23 28C23 27.4477 23.4477 27 24 27H26.6667C27.5871 27 28.3333 26.2538 28.3333 25.3333C28.3333 21.136 25.2292 17.662 21.1917 17.0844C20.6992 17.014 20.3333 16.5921 20.3333 16.0945V15.832C20.3333 15.3757 20.6422 14.9773 21.0841 14.8635C22.9533 14.3824 24.3333 12.6845 24.3333 10.6667C24.3333 8.27343 22.3932 6.33333 20 6.33333C19.4477 6.33333 19 5.88562 19 5.33333Z" fill="#615B6F" />
        </svg>
    );
}

export function HeadTracking() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                className="fill"
                d="M12.3334 14.6665C12.3334 13.746 11.5872 12.9998 10.6667 12.9998C9.74627 12.9998 9.00008 13.746 9.00008 14.6665C9.00008 15.2188 8.55237 15.6665 8.00008 15.6665C7.4478 15.6665 7.00008 15.2188 7.00008 14.6665C7.00008 12.6415 8.6417 10.9998 10.6667 10.9998C12.6918 10.9998 14.3334 12.6415 14.3334 14.6665C14.3334 15.2188 13.8857 15.6665 13.3334 15.6665C12.7811 15.6665 12.3334 15.2188 12.3334 14.6665Z"
                fill="#615B6F"
            />
            <path
                className="fill"
                d="M21.3334 12.9998C22.2539 12.9998 23.0001 13.746 23.0001 14.6665C23.0001 15.2188 23.4478 15.6665 24.0001 15.6665C24.5524 15.6665 25.0001 15.2188 25.0001 14.6665C25.0001 12.6415 23.3585 10.9998 21.3334 10.9998C19.3084 10.9998 17.6667 12.6415 17.6667 14.6665C17.6667 15.2188 18.1145 15.6665 18.6667 15.6665C19.219 15.6665 19.6667 15.2188 19.6667 14.6665C19.6667 13.746 20.4129 12.9998 21.3334 12.9998Z"
                fill="#615B6F"
            />
            <path
                className="fill"
                d="M21.4212 21.421C21.8118 21.0305 21.8118 20.3973 21.4212 20.0068C21.0307 19.6162 20.3976 19.6162 20.007 20.0068C17.7941 22.2197 14.2061 22.2197 11.9932 20.0068C11.6026 19.6162 10.9695 19.6162 10.5789 20.0068C10.1884 20.3973 10.1884 21.0305 10.5789 21.421C13.573 24.415 18.4272 24.415 21.4212 21.421Z"
                fill="#615B6F"
            />
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0001 1.6665C8.084 1.6665 1.66675 8.08376 1.66675 15.9998C1.66675 23.9159 8.084 30.3332 16.0001 30.3332C23.9162 30.3332 30.3334 23.9159 30.3334 15.9998C30.3334 8.08376 23.9162 1.6665 16.0001 1.6665ZM3.66675 15.9998C3.66675 9.18833 9.18857 3.6665 16.0001 3.6665C22.8116 3.6665 28.3334 9.18833 28.3334 15.9998C28.3334 22.8113 22.8116 28.3332 16.0001 28.3332C9.18857 28.3332 3.66675 22.8113 3.66675 15.9998Z"
                fill="#615B6F"
            />
        </svg>
    );
}
