import React, { memo }                                 from 'react';
import { Description, Icon,  iconMap, Title, Wrapper } from '@geenee/ui';
import { IconPropsType }                               from '@geenee/ui/src/lib/component/icon/icon.component';

type Props = {
  icon: keyof typeof iconMap
  title: string
  description: string
  iconColor?: IconPropsType['color']
}

export const LibraryModalTitle = memo((props: Props) => (
    <Wrapper align="center" margin="md">
        <Wrapper row margin="xxs" align="center" valign="center">
            <Icon size={ 24 } color={ props.iconColor } margin="xs" name={ props.icon } />
            <Title styles={ { width: 'fit-content' } } weight="semi-bold" align="center" size="md">{ props.title }</Title>
        </Wrapper>
        <Description weight="medium" color="shade-4">{ props.description }</Description>
    </Wrapper>
));
