import * as React         from 'react';
import { SizesUnionType } from 'libs/ui/src/lib/util/global-props';
import { Wrapper }        from '../../../wrapper/wrapper.component';
import { tableClassName } from '../../utils';

const className = tableClassName('thead');

type PropsType = {
    children: React.ReactNode;

    margin?: SizesUnionType;
    padding?: SizesUnionType;
    style?: React.CSSProperties;

    stickyHeader?: boolean;
}

export const TableHeader = React.memo((props: PropsType) => {
    const stickyHeaderStyles: React.CSSProperties = props.stickyHeader ? {
        position: 'sticky',
        top:      0
    } : {};
    const style = { ...props.style, ...stickyHeaderStyles };

    return (
        <Wrapper
            className={ className }
            margin={ props.margin }
            padding={ props.padding }
            style={ style }
        >
            { props.children }
        </Wrapper>
    );
});
