import { getCameraScaledVector }          from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/GetCameraScaledVector';
import { transformObjectsToServerValues } from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/util/model-properties.helper';
import { Box3, Object3D, Vector3 }        from 'three';
import { GLTFLoader }                     from 'three/examples/jsm/loaders/GLTFLoader';

const getContainerSize = (object: Object3D) => {
    const boundingBox = new Box3().setFromObject(object);
    return boundingBox.getSize(new Vector3());
};

const generateSceneStats = (object: Object3D) => {
    const containerSize = getContainerSize(object);
    return [
        { type: 'Position', data: object.position },
        { type: 'Scale', data: object.scale },
        { type: 'Rotation', data: object.rotation },
        { type: 'Size', data: containerSize, hidden: true }
    ];
};

export const calculateInitialStats = async (file: File, wholeSceneScale?: [number, number, number]) => {
    const fileType = file.name.split('.').pop();
    const res: any = {};
    let object3D;
    let gltf;

    if (fileType === 'glb' || fileType === 'gltf') {
        gltf = await new GLTFLoader().loadAsync(URL.createObjectURL(file));
        object3D = gltf.scene;
    } else {
        object3D = new Object3D();
    }

    const sceneStats: any = generateSceneStats(object3D);
    res.options = {};
    res.properties = {};
    const cameraScaled = getCameraScaledVector(object3D, [ object3D.scale.x, object3D.scale.y, object3D.scale.z ]);
    const serverStats = transformObjectsToServerValues(sceneStats);
    res.properties.stats = serverStats;
    const positionStats = serverStats.find((el) => el.type === 'Position');
    const scaleStats = { data: cameraScaled };
    const rotationStats = serverStats.find((el) => el.type === 'Rotation');

    if (wholeSceneScale && wholeSceneScale.length === 3 && scaleStats.data) {
        scaleStats.data.x /= wholeSceneScale[ 0 ];
        scaleStats.data.y /= wholeSceneScale[ 1 ];
        scaleStats.data.z /= wholeSceneScale[ 2 ];
    }

    res.options.scene_atom_scale = [ scaleStats?.data?.x || 1, scaleStats?.data?.y || 1, scaleStats?.data?.z || 1 ];
    res.options.scene_atom_position = [ rotationStats?.data?.x || 0, rotationStats?.data?.y || 0, rotationStats?.data?.z || 0 ];
    res.options.scene_atom_position = [ positionStats?.data?.x || 0, positionStats?.data?.y || 0, positionStats?.data?.z || 0 ];
    return res;
};
