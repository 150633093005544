import React, { memo, useMemo }          from 'react';
import { Description, Divider, Wrapper } from '@geenee/ui';
import { RedirectToPageCard }            from './RedirectToPageCard';
import { useHomePageBottomMenu }         from './useHomePageBottomMenu';

export const DeveloperAccessAndSupport = memo(() => {
    const { menuSections } = useHomePageBottomMenu();
    const sections = useMemo(() => menuSections.map((menu) => <RedirectToPageCard key={ menu.id } { ...menu } />), [ menuSections ]);
    return (
        <Wrapper>
            <Divider transparent margin="xxl" />
            <Divider transparent margin="xl" />
            <Description margin="xl" weight="bold" size="xxl">Support & SDK Access</Description>
            <Wrapper row align="space-between">
                { sections }
            </Wrapper>
        </Wrapper>
    );
});
