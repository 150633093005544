import React                       from "react";
import { Description, Wrapper }    from "@geenee/ui";
import { FrameExtensionUnionType } from "@geenee/ui/src/lib/util/global-props";
import { observer }                from "mobx-react";

type TableHeaderComponentProps = {
    frame: FrameExtensionUnionType
    title: string
    commercial: string
    message: string
};

export const TableHeaderComponent = observer(({
    frame,
    title,
    commercial,
    message
}: TableHeaderComponentProps) => (
    <Wrapper key={ 1 } padding='xxs' align='center'>
        <Wrapper frame={ frame } margin='xs' align='center'>
            <Description size='xxl'>{ title }</Description>
        </Wrapper>
        <Description color='shade-2' margin='md'>{ commercial }</Description>
        <Description color='shade-4'>{ message }</Description>
    </Wrapper>
));
