export const tableCells = [
    {
        title:          'Cloud-Based Drag & Drop Builder',
        hobbyists:      true,
        premium:        true,
        teams:          true,
        enterprise:     true,
        tooltipTitle:   'Web-Based',
        tooltipMessage: 'Create, edit, deploy your experiences from a browser with easy-to-use tools hosted on our servers',
        tooltipIcon:    'web'
    },
    {
        title:          'World Tracking',
        hobbyists:      true,
        premium:        true,
        teams:          true,
        enterprise:     true,
        tooltipTitle:   'World',
        tooltipMessage: 'Build a carousel of scenes for the rear-facing “world view”',
        tooltipIcon:    'carouselAlt'
    },
    {
        title:          'Marker Tracking',
        hobbyists:      true,
        premium:        true,
        teams:          true,
        enterprise:     true,
        tooltipTitle:   'Marker',
        tooltipMessage: 'Attach AR assets to an image target to create interactive print and OOH',
        tooltipIcon:    'markerAR'
    },
    {
        title:          'Body Tracking',
        hobbyists:      true,
        premium:        true,
        teams:          true,
        enterprise:     true,
        tooltipTitle:   'Body',
        tooltipMessage: 'Create wearable avatars or digital garments for the body',
        tooltipIcon:    'body'
    },
    {
        title:          'Face Tracking',
        hobbyists:      true,
        premium:        true,
        teams:          true,
        enterprise:     true,
        tooltipTitle:   'Face',
        tooltipMessage: 'Attach accessories or replace the head with an avatar head',
        tooltipIcon:    'head'
    },
    {
        title:          'True-to-Size AR',
        hobbyists:      true,
        premium:        true,
        teams:          true,
        enterprise:     true,
        tooltipTitle:   'True-Size',
        tooltipMessage: 'Provide accurately sized products in your end-user’s environment',
        tooltipIcon:    'productViewer'
    },
    {
        title:          'Sections Library',
        hobbyists:      true,
        premium:        true,
        teams:          true,
        enterprise:     true,
        tooltipTitle:   'Ready-Made',
        tooltipMessage: 'Our section library makes it faster for you to build ',
        tooltipIcon:    'sections'
    },
    {
        title:          'Code Inject',
        hobbyists:      true,
        premium:        true,
        teams:          true,
        enterprise:     true,
        tooltipTitle:   'Inject Code',
        tooltipMessage: 'Use our library or write your own code to add interactivity and animation',
        tooltipIcon:    'code'
    },
    {
        title:          'SDK',
        hobbyists:      true,
        premium:        true,
        teams:          true,
        enterprise:     true,
        tooltipTitle:   'Build Freely',
        tooltipMessage: 'Create with 100% flexibility by utilizing our SDK',
        tooltipIcon:    'sdkAdd'
    },
    {
        title:          'Views / month',
        hobbyists:      null,
        premium:        null,
        teams:          null,
        enterprise:     null,
        tooltipTitle:   'Pings',
        tooltipMessage: 'Pay based on page views. If you are using our Builder, hosting is included',
        tooltipIcon:    'viewAlt'
    },
    {
        title:          'Seats',
        hobbyists:      null,
        premium:        null,
        teams:          null,
        enterprise:     null,
        tooltipTitle:   'Users',
        tooltipMessage: 'Team collaboration  based on project permissions and editor rights',
        tooltipIcon:    'teamWarm'
    },
    {
        title:          'No Watermark',
        hobbyists:      false,
        premium:        true,
        teams:          true,
        enterprise:     true,
        tooltipTitle:   'White Label',
        tooltipMessage: 'Upgrade from free to a paid plan to remove the Geenee watermark',
        tooltipIcon:    'whiteLabel'
    },
    {
        title:          'Loader Customization *',
        hobbyists:      false,
        premium:        false,
        teams:          true,
        enterprise:     true,
        tooltipTitle:   'Brand Animation',
        tooltipMessage: 'Add your own branded animated loader per project',
        tooltipIcon:    'loader'
    },
    {
        title:          'Team Library',
        hobbyists:      false,
        premium:        false,
        teams:          true,
        enterprise:     true,
        tooltipTitle:   'Shared Assets',
        tooltipMessage: 'Upload visual assets accessible by your team for easy, real-time collaboration',
        tooltipIcon:    'teamLib'
    },
    {
        title:          'Commerical License',
        hobbyists:      false,
        premium:        false,
        teams:          true,
        enterprise:     true,
        tooltipTitle:   'License Type',
        tooltipMessage: 'Get the commerical license to advertise a product or service.  Choose non-commerical if you’re an artist or agency displaying or prototyping',
        tooltipIcon:    'commercialLicense'
    },
    {
        title:          'Simple NFT Minting',
        hobbyists:      false,
        premium:        true,
        teams:          true,
        enterprise:     true,
        tooltipTitle:   'Create NFTs',
        tooltipMessage: 'Mint AR projects on the blockchain without gas fees',
        tooltipIcon:    'nft'
    },
    {
        title:          'Agency Program Inclusion',
        hobbyists:      false,
        premium:        false,
        teams:          true,
        enterprise:     true,
        tooltipTitle:   'Get Listed',
        tooltipMessage: 'Get included as a preferred design and/or dev shop',
        tooltipIcon:    'listed'
    },
    {
        title:          'Analytics',
        hobbyists:      false,
        premium:        false,
        teams:          true,
        enterprise:     true,
        tooltipTitle:   'Track It',
        tooltipMessage: 'Access popular integrated tools without leaving the platform',
        tooltipIcon:    'analyticsAlt'
    },
    {
        title:          'Programmatic NFT Minting',
        hobbyists:      false,
        premium:        false,
        teams:          false,
        enterprise:     true,
        tooltipTitle:   'NFT Collections',
        tooltipMessage: 'Create programmatic NFT collections on the chain fast & easily',
        tooltipIcon:    'nftCollection'
    },
    {
        title:          'Custom URL Domain Mapping *',
        hobbyists:      false,
        premium:        false,
        teams:          false,
        enterprise:     true,
        tooltipTitle:   'Your Domain',
        tooltipMessage: 'Instead of using our domain, re-route the experience to your site',
        tooltipIcon:    'domain'
    },
    {
        title:          'Dedicated Account Manager',
        hobbyists:      false,
        premium:        false,
        teams:          false,
        enterprise:     true,
        tooltipTitle:   'Support',
        tooltipMessage: 'We will provide you with a dedicated manager to assist in questions & needs',
        tooltipIcon:    'manager'
    },
    {
        title:          'Custom Design & Development',
        hobbyists:      false,
        premium:        false,
        teams:          false,
        enterprise:     true,
        tooltipTitle:   'At Your Service',
        tooltipMessage: 'We’ll design and/or code monthly or project-based',
        tooltipIcon:    'all'
    }
];
