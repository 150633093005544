import React, {
    FC, forwardRef, memo, useEffect, useRef, useState
} from 'react';
import lottie, { AnimationItem } from 'lottie-web';
import { cn }                    from '../../util/bem';
import './lottie.component.scss';

export type LottiePropsType = {
  className?: string;
  speed?: number;
  loop?: boolean;
  autoplay?: boolean;
  start?: boolean;
  playFrom?: number;
  src?: string;
  animationData?: { [key: string]: any };
  onLoad?: ()=> void
}

const className = cn('lottie');

export const Lottie: FC<LottiePropsType> = memo(forwardRef((props: LottiePropsType, ref: React.Ref<HTMLDivElement>) => {
    const [ cardAnimation, setCardAnimation ] = useState<AnimationItem | null>(null);
    const animationRef = useRef<HTMLDivElement | null>(null);

    const loadLottie = async (container: HTMLDivElement) => {
        const animation = await lottie.loadAnimation({
            container,
            renderer:         'svg',
            loop:             props.loop,
            path:             props.src,
            autoplay:         props.autoplay,
            animationData:    props.animationData,
            rendererSettings: { progressiveLoad: true }
        });
        animation.addEventListener(('loaded_images'), () => props.onLoad && props.onLoad());
        animation.setSpeed(props.speed || 1);
        setCardAnimation(animation);
    };

    useEffect(() => {
        if (!!animationRef.current && !cardAnimation) {
            loadLottie(animationRef.current);
        }
    }, [ animationRef.current, cardAnimation ]);

    useEffect(() => () => {
        cardAnimation?.removeEventListener(('loaded_images'), () => props.onLoad);
    }, [ cardAnimation ]);
    useEffect(() => {
        if (props.start) {
            // @ts-ignore
            cardAnimation?.goToAndPlay(props.playFrom || 0, true);
        } else {
            // @ts-ignore
            cardAnimation?.goToAndStop(props.playFrom || 0, true);
        }
    }, [ cardAnimation, props.start, props.playFrom ]);

    return (
        <div ref={ ref } className={ className('', null, props.className) }>
            <div ref={ animationRef } />
        </div>

    );
}));

Lottie.defaultProps = { autoplay: true, loop: true, speed: 1, start: true };

Lottie.displayName = 'Lottie';
