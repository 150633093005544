import React, { useContext, useEffect, useState }                      from 'react';
import CloseButton                                                     from '@geenee/builder/src/components/modals/common/CloseButton';
import ErrorMessage                                                    from '@geenee/builder/src/components/modals/common/ErrorMessage';
import { SketchFabModel }                                              from '@geenee/builder/src/core/model/SketchFabModel.model';
import ModalStateContext                                               from '@geenee/builder/src/lib/ModalStateContext';
import ModalUpdaterContext                                             from '@geenee/builder/src/lib/ModalUpdaterContext';
import { AnimatedWrapper, Modal as ModalComponent, useModal, Wrapper } from '@geenee/ui';
import isEmpty                                                         from 'lodash-es/isEmpty';
import { observer }                                                    from 'mobx-react';

type ContextProps = {
    path?: string;
    type?: string;
    location?: string;
    arScene?: boolean;
    onModelClick?: (model: SketchFabModel) => Promise<void>;
    nodeName?: string;
    cancelText?: string;
    continueText?: string;
    savingText?: string;
    title?: string;
    textInsert?: string;
    paymentMethodId?: string;
    isNewCard?: boolean;
    onTour?: boolean;
    onModalCloseCallback?: () => void;
    onAddingNewCardFinished?: () => void;
    confirmCallback?: () => void;
    cancelCallback?: () => void;
}

interface ElementProps extends ContextProps {
    close: () => void;
    showError: (message: string) => void;
    setModal: any;
}

type Props = {
    component?: (prop: ElementProps) => JSX.Element;
    props?: ContextProps;
    hideModal?: boolean | undefined;
}

const Modal = observer(() => {
    const modalProps = useContext<Props>(ModalStateContext);
    const setModalOptions = useContext(ModalUpdaterContext);
    // @ts-ignore
    // useModalUrlQuery(AppState);

    const ModalBody = modalProps.component ? modalProps.component : () => <></>;
    const [ showModal, hideModal ] = useModal((transformGroupProps: JSX.IntrinsicAttributes) => {
        const [ errorMessage, setErrorMessage ] = useState('');
        const showError = (message: string) => {
            setErrorMessage(message);
        };

        return (
            <ModalComponent
                className={ `global-modal ${ modalProps?.props?.onTour ? 'on-tour' : '' } global-modal-prior` }
                { ...transformGroupProps }
            >
                <AnimatedWrapper manual={ false } fullWidth>
                    <Wrapper>
                        <CloseButton
                            close={ async () => {
                            // onManualCloseCallback && await onManualCloseCallback();
                                modalProps?.props?.onModalCloseCallback && modalProps.props.onModalCloseCallback();
                                hideModal();
                                setErrorMessage('');
                            } }
                            isScene={ modalProps?.props?.arScene }
                        />
                        <ModalBody
                            { ...modalProps?.props }
                            showError={ showError }
                            close={ () => {
                                hideModal();
                                setErrorMessage('');
                            } }
                            setModal={ setModalOptions }
                        />
                    </Wrapper>
                </AnimatedWrapper>
                { !isEmpty(errorMessage) && <ErrorMessage message={ errorMessage } /> }
            </ModalComponent>
        );
    }, [ modalProps ]);

    useEffect(() => {
        if (!isEmpty(modalProps) && !(modalProps?.hideModal)) {
            showModal();
        } else if (modalProps.hideModal) {
            hideModal();
        }
    }, [ modalProps ]);

    return null;
});

// eslint-disable-next-line arca/no-default-export
export default Modal;
