import {
    CanvasTexture, Mapping, PixelFormat, TextureDataType, TextureFilter, Wrapping
} from 'three';

// eslint-disable-next-line arca/no-default-export
export default class GifTexture extends CanvasTexture {
    previousFrameInfo: any;
    constructor(image: HTMLImageElement | HTMLCanvasElement | HTMLVideoElement | ImageBitmap | undefined, mapping: Mapping | undefined, wrapS: Wrapping | undefined, wrapT: Wrapping | undefined, magFilter: TextureFilter | undefined, minFilter: TextureFilter | undefined, format: PixelFormat | undefined, type: TextureDataType | undefined, anisotropy: number | undefined) {
        super(image, mapping, wrapS, wrapT, magFilter, minFilter, format, type, anisotropy);

        this.needsUpdate = false;
    }

    setReader(reader: { width: any; height: any; }) {
        this.reader = reader;

        this.image = document.createElement('canvas');
        this.image.width = reader.width;
        this.image.height = reader.height;
        this.context = this.image.getContext('2d');

        this.frameNumber = 0;
        this.previousFrameInfo = null;
        this.draw();
    }

    draw(frameNumber: number | undefined) {
        if (!this.reader || !frameNumber) {
            return;
        }

        const { reader, image, context } = this;
        const { width, height } = image;

        // const frameNum = ++this.frameNumber % reader.numFrames();
        const frameNum = (1 + frameNumber) % reader.numFrames();
        if (frameNum === this.frameNumber) return;
        this.frameNumber = frameNum;

        const frameInfo = reader.frameInfo(frameNum);

        if (frameNum === 0) {
            // always clear canvas to start
            context.clearRect(0, 0, width, height);
        } else if (this.previousFrameInfo && this.previousFrameInfo.disposal === 2) {
            // disposal was "restore to background" which is essentially "restore to transparent"
            context.clearRect(
                this.previousFrameInfo.x,
                this.previousFrameInfo.y,
                this.previousFrameInfo.width,
                this.previousFrameInfo.height
            );
        }

        const imageData = context.getImageData(0, 0, width, height);
        reader.decodeAndBlitFrameRGBA(frameNum, imageData.data);
        context.putImageData(imageData, 0, 0);

        this.needsUpdate = true;

        this.previousFrameInfo = frameInfo;
        // setTimeout(this.draw.bind(this), frameInfo.delay * 10);
    }
}
