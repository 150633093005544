import React                      from 'react';
import { InputTableItem, Switch } from '@geenee/ui';
import { observer }               from 'mobx-react';
import { CampaignModel }          from '@geenee/builder/src/magellan/model/campaign.model';
import { ProjectModel }           from '@geenee/builder/src/magellan/model/project.model';

interface Props {
  activeProject: ProjectModel;
  activeCampaign: CampaignModel;
  disabled: boolean;
}

export const ShowIntroRow = observer(({ activeCampaign, activeProject, disabled }: Props) => {
    const onToggleOpenDirectly = () => {
        // eslint-disable-next-line no-param-reassign
        activeCampaign.options.hideSection = !activeCampaign?.options?.hideSection;
        activeCampaign.saveData();
    };
    if (activeProject?.campaignsRegistry.size !== 1) {
        return <></>;
    }
    return (
        <InputTableItem
            label="Show Intro"
        >
            <Switch disabled={ disabled } size="lg" checked={ !activeCampaign?.options?.hideSection } onChange={ onToggleOpenDirectly } />
        </InputTableItem>
    );
});
