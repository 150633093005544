import { useState } from 'react';

export const useModalState = (initialState = {}) => {
    const [ modalProps, setModalProps ] = useState(initialState);

    const setModalState = (modalPropsValue: any) => {
        setModalProps(modalPropsValue);
    };

    return [ modalProps, setModalState ];
};
