import React, { memo }      from "react";
import { cn, Wrapper }      from "@geenee/ui";
import { SizesUnionType }   from "../../../util/global-props";
import { WrapperPropsType } from "../../wrapper/wrapper.component";

type CollapseContentProps = {
    collapsed: boolean;
    children: React.ReactNode;
    contentWrapperProps?: WrapperPropsType;
    paddingLeft?: SizesUnionType | number
}

const className = cn('collapse-content');

export const CollapseContent = memo(({ collapsed, children, contentWrapperProps, paddingLeft = 'sm' }: CollapseContentProps) => (
    <Wrapper
        { ...contentWrapperProps }
        className={ `${ className({ collapsed, 'padding-left': paddingLeft }) } ${ contentWrapperProps?.className }` }
        style={ !Number.isNaN(paddingLeft) ? { paddingLeft } : {} }
    >
        { children }
    </Wrapper>
));
