import styled from 'styled-components';

export const GalleryPreviewContainer = styled.div<{ foreground: boolean }>`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: ${ ({ foreground }) => (foreground ? 4 : 0) };
    overflow: hidden;
    background-color: ${ ({ theme: { colors } }) => colors.white };
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;

    & > video,img {
        object-fit: fill;
        height: 100%;
        width: 100%;
    }
`;
