import React                                from 'react';
import { FormItem, InputTableItem, Switch } from '@geenee/ui';

interface Props {
  open_in_new: boolean;
  onUpdate: (value: boolean) => void;
  disabled?: boolean;
}

export function OpenInNewSwitcher({ open_in_new, onUpdate, disabled }: Props) {
    return (
        <InputTableItem label='Open In New Tab'>
            <FormItem>
                <Switch
                    color="white"
                    descriptionSize="xs"
                    size='lg'
                    checked={ open_in_new }
                    onChange={ onUpdate }
                    disabled={ disabled }
                />
            </FormItem>
        </InputTableItem>
    );
}
