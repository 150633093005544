import React, {
    createContext,
    useContext,
    useLayoutEffect,
    useRef,
    useState
} from 'react';
import { Router }               from 'react-router-dom';
import { createBrowserHistory } from 'history';

const HistoryContext = createContext({});

export const useHistory = () => useContext(HistoryContext);

export const history = createBrowserHistory({ window });
export const BrowserRouter = ({ children }) => {
    const { current: _history } = useRef(history);

    const [ { action, location }, setHistoryState ] = useState({
        action:   _history.action,
        location: _history.location
    });

    useLayoutEffect(() => _history.listen(setHistoryState), [ _history ]);

    return (
        <Router action={ action } location={ location } navigator={ _history }>
            <HistoryContext.Provider value={ history }>
                { children }
            </HistoryContext.Provider>
        </Router>
    );
};
