/* eslint-disable max-len */
import React from 'react';

export const TextListArrow = ({ children }:{children?: React.ReactNode}) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            className="fill"
            d="M13.625 8C13.625 7.71875 13.5313 7.53125 13.3438 7.34375L8 2L6.6875 3.3125L10.4375 7.0625L2.375 7.0625L2.375 8.9375L10.4375 8.9375L6.6875 12.6875L8 14L13.3438 8.65625C13.5313 8.46875 13.625 8.28125 13.625 8Z"
            fill="#303036"
        />
        { children }
    </svg>
);
export const TextListCheck = ({ children }:{children?: React.ReactNode}) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            className="fill"
            d="M13.625 8C13.625 7.71875 13.5313 7.53125 13.3438 7.34375L8 2L6.6875 3.3125L10.4375 7.0625L2.375 7.0625L2.375 8.9375L10.4375 8.9375L6.6875 12.6875L8 14L13.3438 8.65625C13.5313 8.46875 13.625 8.28125 13.625 8Z"
            fill="#303036"
        />
        { children }
    </svg>
);
export const TextListCircle = ({ children }:{children?: React.ReactNode}) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle
            className="fill"
            cx="8"
            cy="8"
            r="4"
            fill="#303036"
        />
        { children }
    </svg>

);
export const TextListCross = ({ children }:{children?: React.ReactNode}) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            className="fill"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.87841 7.64643C6.07368 7.84169 6.07368 8.15827 5.87841 8.35353L2.93819 11.2938C2.74293 11.489 2.74293 11.8056 2.93819 12.0009L3.99885 13.0615C4.19412 13.2568 4.5107 13.2568 4.70596 13.0615L7.64618 10.1213C7.84144 9.92604 8.15803 9.92604 8.35329 10.1213L11.2935 13.0615C11.4888 13.2568 11.8054 13.2568 12.0006 13.0615L13.0613 12.0009C13.2565 11.8056 13.2565 11.489 13.0613 11.2938L10.1211 8.35353C9.92579 8.15827 9.92579 7.84169 10.1211 7.64643L13.0613 4.70621C13.2565 4.51094 13.2565 4.19436 13.0613 3.9991L12.0006 2.93844C11.8054 2.74318 11.4888 2.74318 11.2935 2.93844L8.35329 5.87866C8.15803 6.07392 7.84144 6.07392 7.64618 5.87866L4.70596 2.93844C4.5107 2.74318 4.19412 2.74318 3.99885 2.93844L2.93819 3.9991C2.74293 4.19436 2.74293 4.51094 2.93819 4.70621L5.87841 7.64643Z"
            fill="#303036"
        />
        { children }
    </svg>

);
export const TextListSquare = ({ children }:{children?: React.ReactNode}) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
            className="fill"
            x="4"
            y="4"
            width="8"
            height="8"
            fill="#303036"
        />
        { children }
    </svg>

);
