import React              from 'react';
import { InputTableItem } from '@geenee/ui';
import { ColorPicker }    from '@geenee/builder/src/components/ColorPicker/ColorPicker';

type Props = {
  bgColor: string;
  onColorChange: (value: string) => void;
}

export function BackgroundColorRow({ bgColor, onColorChange }: Props) {
    return (
        <InputTableItem label="Background color:" viewType="secondary">
            <ColorPicker color={ bgColor } onColorChange={ onColorChange } />
        </InputTableItem>
    );
}
