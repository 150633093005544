import { Vector3 }                      from "@babylonjs/core/Maths/math.vector";
import { AbstractMesh }                 from "@babylonjs/core/Meshes/abstractMesh";
import { TransformNode }                from "@babylonjs/core/Meshes/transformNode";
import { BabylonRenderer }              from "@geenee/geespector/renderer/babylonjs.renderer";
import { AbstractCommand, CommandType } from '@geenee/shared/src/commander/types';

export class FitNodeToViewportCommand extends AbstractCommand<CommandType> {
    receiver: BabylonRenderer;
    entity: AbstractMesh | TransformNode;
    prevScaling = { x: 1, y: 1, z: 1 };

    constructor(receiver: BabylonRenderer, entity: AbstractMesh | TransformNode) {
        super();
        this.receiver = receiver;
        this.entity = entity;
    }

    execute = () => {
        this.prevScaling = { x: this.entity.scaling.x, y: this.entity.scaling.y, z: this.entity.scaling.z };
        this.receiver.fitNodeToViewport(this.entity);
    };

    revert = () => {
        this.receiver.setSceneObjectValues(
            this.entity,
            'scaling',
            new Vector3(this.prevScaling.x, this.prevScaling.y, this.prevScaling.z),
            true
        );
    };
}
