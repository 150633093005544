import { useCallback, useEffect, useRef, useState } from "react";
import GlobalState                                  from '@geenee/geespector/src/components/globalState';
import { useInject }                                from '@geenee/shared';
import { TOAST_ERROR }                              from "@geenee/builder/src/lib/constants";
import { getFileFromUrl }                           from "@geenee/builder/src/lib/getFileFromUrl";
import { SceneManager }                             from "@geenee/builder/src/magellan/scene-manager/scene-manager";

enum SectionName {
  'head-tracking'= 'Face Tracking',
  'body-tracking-overlay' = 'Body Tracking Overlay',
  'body-tracking-twin' = 'Body Tracking Twin'
}

const ACCESSIBLE_TYPES = '.glb';

export const useBabylonAssetsActions =  (
    renderer?: SceneManager['sceneRenderer'],
    onPanelClose?: (panelId?: 'materials' | 'textures' | 'layers') => void
) => {
    const { AppState } = useInject();
    const { firstAvailableSection } = AppState;
    const { activeMolecule, sceneManager } = firstAvailableSection || { activeMolecule: null };
    const internalChange = useRef({ isDirty: false, closePanel: false });
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        GlobalState.onEntityAddedObservable?.add(() => {
            if (internalChange) {
                setIsLoading(false);
                activeMolecule?.setLoading(false);
                if (internalChange.current.closePanel) {
                    onPanelClose && onPanelClose('layers');
                }
                internalChange.current.isDirty = false;
                internalChange.current.closePanel = false;
            }
        });
    }, [ activeMolecule ]);

    const getCustomErrorMessage = useCallback(
        () => `Only .glb files \nwill be viewable in ${ SectionName[ firstAvailableSection?.type || '' ] }`,
        [ firstAvailableSection?.type ]
    );

    const isCorrectFileType = (file: File) => {
        const fileType = file.name.split('.').pop();
        if (!ACCESSIBLE_TYPES.includes(fileType || '')) {
            GlobalState.showToast({
                severity: TOAST_ERROR,
                detail:   getCustomErrorMessage(),
                summary:  'Invalid file format'
            });
            return false;
        }
        return true;
    };

    const stopLoadingWithError = (errorToast?: {severity: '' | 'error' | 'warning' | 'success', summary: string, detail: string}) => {
        if (errorToast) {
            GlobalState.showToast(errorToast);
        }
        setIsLoading(false);
        activeMolecule?.setLoading(false);
    };
    const addSceneAsset = async ({
        file, url,
        // TODO: use fileName in the future
        fileName, closePanelAfterDownload = true
    } : {file?: File, url?: string, fileName?: string, closePanelAfterDownload?: boolean}) => {
        if (!renderer) {
            return;
        }

        activeMolecule?.setLoading(true);
        setIsLoading(true);
        const workingFile = file || (url ? await getFileFromUrl(url, url.split('/').pop()) : null);
        if (!workingFile) {
            return stopLoadingWithError({
                severity: TOAST_ERROR,
                detail:   'Error when fetching file',
                summary:  ''
            });
        }

        if (!workingFile.size) {
            return stopLoadingWithError({
                severity: TOAST_ERROR,
                summary:  'File download error',
                detail:   'Failed to download file because it is empty.'
            });
        }
        if (!isCorrectFileType(workingFile)) {
            return stopLoadingWithError();
        }

        sceneManager?.createModelFromFile(workingFile);
        internalChange.current.isDirty = true;
        internalChange.current.closePanel = closePanelAfterDownload;
    };

    return { addSceneAsset, isLoading, getCustomErrorMessage };
};
