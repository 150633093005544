/* eslint-disable max-len */
import React from 'react';

export const TextAlignLeft = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      d="M0.5 1H15.5C15.7761 1 16 1.22386 16 1.5V2.5C16 2.77614 15.7761 3 15.5 3H0.5C0.223858 3 0 2.77614 0 2.5V1.5C0 1.22386 0.223858 1 0.5 1ZM0.5 5H9.5C9.7761 5 10 5.22386 10 5.5V6.5C10 6.77614 9.7761 7 9.5 7H0.5C0.22386 7 0 6.77614 0 6.5V5.5C0 5.22386 0.22386 5 0.5 5ZM15.5 9H0.5C0.223858 9 0 9.22386 0 9.5V10.5C0 10.7761 0.223858 11 0.5 11H15.5C15.7761 11 16 10.7761 16 10.5V9.5C16 9.22386 15.7761 9 15.5 9ZM0.5 13H9.5C9.7761 13 10 13.2239 10 13.5V14.5C10 14.7761 9.7761 15 9.5 15H0.5C0.22386 15 0 14.7761 0 14.5V13.5C0 13.2239 0.22386 13 0.5 13Z"
      fill="#303036"
    />
  </svg>
);
export const TextAlignCenter = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 1H15.5C15.7761 1 16 1.22386 16 1.5V2.5C16 2.77614 15.7761 3 15.5 3H0.5C0.223858 3 0 2.77614 0 2.5V1.5C0 1.22386 0.223858 1 0.5 1ZM3.5 5H12.5C12.7761 5 13 5.22386 13 5.5V6.5C13 6.77614 12.7761 7 12.5 7H3.5C3.22386 7 3 6.77614 3 6.5V5.5C3 5.22386 3.22386 5 3.5 5ZM15.5 9H0.5C0.223858 9 0 9.22386 0 9.5V10.5C0 10.7761 0.223858 11 0.5 11H15.5C15.7761 11 16 10.7761 16 10.5V9.5C16 9.22386 15.7761 9 15.5 9ZM3.5 13H12.5C12.7761 13 13 13.2239 13 13.5V14.5C13 14.7761 12.7761 15 12.5 15H3.5C3.22386 15 3 14.7761 3 14.5V13.5C3 13.2239 3.22386 13 3.5 13Z"
      fill="#303036"
    />
  </svg>
);
export const TextAlignRight = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 1H15.5C15.7761 1 16 1.22386 16 1.5V2.5C16 2.77614 15.7761 3 15.5 3H0.5C0.223858 3 0 2.77614 0 2.5V1.5C0 1.22386 0.223858 1 0.5 1ZM6.5 5H15.5C15.7761 5 16 5.22386 16 5.5V6.5C16 6.77614 15.7761 7 15.5 7H6.5C6.22386 7 6 6.77614 6 6.5V5.5C6 5.22386 6.22386 5 6.5 5ZM15.5 9H0.5C0.223858 9 0 9.22386 0 9.5V10.5C0 10.7761 0.223858 11 0.5 11H15.5C15.7761 11 16 10.7761 16 10.5V9.5C16 9.22386 15.7761 9 15.5 9ZM6.5 13H15.5C15.7761 13 16 13.2239 16 13.5V14.5C16 14.7761 15.7761 15 15.5 15H6.5C6.22386 15 6 14.7761 6 14.5V13.5C6 13.2239 6.22386 13 6.5 13Z"
      fill="#303036"
    />
  </svg>
);
export const TextBold = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      d="M10.7822 8.17333C11.52 7.65778 12 6.89333 12 6.11111C12 4.39556 10.6044 3 8.88889 3H4V13.6667H9.55556C11.1467 13.6667 12.4444 12.3689 12.4444 10.7778C12.4444 9.62222 11.76 8.63556 10.7822 8.17333V8.17333ZM6.22222 4.77778H8.66667C9.40444 4.77778 10 5.37333 10 6.11111C10 6.84889 9.40444 7.44444 8.66667 7.44444H6.22222V4.77778ZM9.11111 11.8889H6.22222V9.22222H9.11111C9.84889 9.22222 10.4444 9.81778 10.4444 10.5556C10.4444 11.2933 9.84889 11.8889 9.11111 11.8889Z"
      fill="#303036"
    />
  </svg>
);
export const TextItalic = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      d="M6.00012 2V4H8.58012L4.92012 12H2.00012V14H10.0001V12H7.42012L11.0801 4H14.0001V2H6.00012Z"
      fill="#303036"
    />
  </svg>
);
export const TextFontSize = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="fill"
      d="M0 8H3V14H5V8H8V6H0V8ZM6 2V4H10V14H12V4H16V2H6Z"
      fill="#303036"
    />
  </svg>
);
export const TextColorPicker = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path className="fill color" d="M0 15H18V18H0V15Z" fill="#303036" />
    <path
      className="fill"
      d="M10 1H8L3.5 13H5.5L6.62 10H11.37L12.49 13H14.49L10 1ZM7.38 8L9 3.67L10.62 8H7.38Z"
      fill="#303036"
    />
  </svg>
);
