import axios                                       from 'axios';
import Spotify                                     from 'spotify-web-api-js';
import APIRequest                                  from '@geenee/builder/src/lib/APIRequest';
import { SPOTIFY_CLIENT_ID, SPOTIFY_REDIRECT_URL } from '@geenee/builder/src/lib/constants';
import envConfig                                   from '@geenee/builder/src/lib/envConfig';

const spotifyApi = new Spotify();
// eslint-disable-next-line arca/no-default-export
export default spotifyApi;

export const searchTracks = async (query: string, options: any) => spotifyApi.searchTracks(query, options);

export const getTrack = async (id: string) => spotifyApi.getTrack(id);

// @ts-ignore
export const getCredsByToken = async (code: string, verifier: string) => APIRequest.post(
    `${ envConfig.API_URL }/api/v0/spotify`,
    { code, verifier, redirect_url: SPOTIFY_REDIRECT_URL },
    { external: true }
);

export const getCredsByRefreshToken = () => axios.post(
    'https://accounts.spotify.com/api/token',
    new URLSearchParams({
        grant_type:    'refresh_token',
        refresh_token: localStorage.getItem('sp-refresh-token') || '',
        client_id:     SPOTIFY_CLIENT_ID
    }),
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
);
