import { useInject }        from "@geenee/shared/src/hook/use-inject.hook";
import { useSharedHistory } from "@geenee/shared/src/hook/use-shared-history";
import { SectionModel }     from "@geenee/shared/src/magellan/section/model/section.model";
import { runInAction }      from "mobx";

export const useProjectData = (redirectToSection: (url: string)=> Promise<void>) => {
    const history = useSharedHistory();
    const { AppState } = useInject();
    const { activeProject, options: { projectId, experienceId } } = AppState;

    const newCampaignClick = async () => {
    // @ts-ignore
        if (process.env.ENV_GEENEE_APP === 'BUILDER' && activeProject) {
            // @ts-ignore
            const experienceModel = await activeProject.createExperience();
            history.push(`/${ projectId }/${ experienceModel.id }`);
            return true;
        }
        return false;
    };

    const experiencesData = [];
    if (activeProject?.experiencesRegistry?.size) {
        const experiencesArray = Array.from(activeProject.experiencesRegistry.values()).sort((a, b) => a.options.order - b.options.order);
        experiencesData.push(...experiencesArray.map((experience) => ({
            ...experience,
            nft_published: experience.nft_published,
            onClick:       async () => {
                if (experience) {
                    if (process.env.ENV_GEENEE_APP !== 'BUILDER') {
                        const [ view ]: SectionModel[] = Array.from(experience.viewsRegistry.values())
                            .sort((a, b) => a.options.order - b.options.order);

                        await redirectToSection(`/${ projectId }/${ experience.id }/${ view ? view.id : '' }`);
                    } else {
                        history.push(`/${ projectId }/${ experience.id }`);
                    }
                }
            }
        })) as any);
    }
    // @ts-ignore
    if (process.env.ENV_GEENEE_APP === 'BUILDER' && !activeProject?.nft_published) {
        experiencesData.push({
            id:      'new_experience',
            title:   'create NEW experience',
            onClick: () => newCampaignClick(),
            options: { order: activeProject?.experiencesRegistry?.size || 1 }
        });
    }
    return {
        projectId,
        experiencesData,
        experienceId
    };
};
