import React, { useContext,  useMemo }                    from 'react';
import { useBuilderInject }                               from '@geenee/builder/src/core/hook/use-builder-inject';
import { stripeState }                                    from '@geenee/builder/src/core/state/stripe.state';
import { NOTION_SUPPORT_URL }                             from "@geenee/builder/src/lib/constants";
import ModalUpdaterContext                                from '@geenee/builder/src/lib/ModalUpdaterContext';
import { useTeamLibraryModal }                            from '@geenee/builder/src/module/team-library/modal/useTeamLibrary.modal';
import { useHandlers }                                    from '@geenee/builder/src/pages/AllPlansPage/useHandlers';
import { useHistory }                                     from "@geenee/common";
import { Button,  Description, Dropdown, Menu, MenuItem } from '@geenee/ui';
import findKey                                            from 'lodash-es/findKey';
import { observer }                                       from 'mobx-react';

interface Props {
    closeSideBar?: () => void;
    ownerItemsFilter?: (i: any) => boolean,
}

export enum SettingsButtonLabels {
    'team-library' = 'Team Library',
    'company-settings' = 'Company Settings',
    'google-analytics' = 'Google Analytics',
    'matomo-analytics' = 'Matomo Analytics',
    'nft-dashboard' = 'NFT Dashboard',
    'plan-and-billing' = 'Plan & Billing',
    'contact' = 'Contact Us',
    'sign-out' = 'Sign Out',
    'team-members' = 'Team Members',
    'your-profile' = 'Your Profile',
    'support' = 'Support Center',
    'sdk' = 'SDK Access',
}

export const SettingsButton = observer(({ ownerItemsFilter = (i) => i }: Props) => {
    const history = useHistory();
    const { pathname } = history.location;
    const { BuilderState } = useBuilderInject();
    const { role } = BuilderState.currentUser.profile || { role: '' };
    const [ showModal ] = useTeamLibraryModal();
    const { daysLeft } = stripeState;
    const isSubscriptionOver = !daysLeft || daysLeft <= 0;
    const setModalOptions = useContext(ModalUpdaterContext);
    const { showPaymentModal } = useHandlers('month', setModalOptions);
    const isOwner = (role || '').toLowerCase() === 'owner';

    const signOutItem = {
        label:   SettingsButtonLabels[ 'sign-out' ],
        onClick: () => {
            history.push('/login');
            BuilderState.logout();
            window.location.reload();
        }
    };

    const SharedItems = useMemo(
        () => [
            {
                label:   SettingsButtonLabels[ 'your-profile' ],
                onClick: () => {
                    history.push('/profile');
                }
            },
            signOutItem
        ],
        []
    );

    const checkSubscription = () => {
        if (isSubscriptionOver) {
            const properPlanForInterval = findKey(stripeState.prices, { name: 'Premium', period: 'month' });
            showPaymentModal(stripeState.plan_price_id || properPlanForInterval);
            return false;
        }
        return true;
    };

    const OwnerItems = useMemo(
        () => [
            {
                label:   'Home Page',
                path:    '/getting-started',
                onClick: () => {
                    history.push('/');
                }
            },
            {
                label:   SettingsButtonLabels[ 'company-settings' ],
                path:    '/company',
                onClick: () => {
                    history.push('/company');
                }
            },
            !stripeState.isPremium && !stripeState.isHobbyist ? {
                label:   SettingsButtonLabels[ 'team-members' ],
                path:    '/team',
                onClick: () => {
                    if (checkSubscription()) {
                        history.push('/team');
                    }
                }
            } : undefined,
            {
                label:   SettingsButtonLabels[ 'plan-and-billing' ],
                path:    '/your-plan',
                onClick: () => {
                    history.push('/your-plan');
                }
            },
            {
                label:   SettingsButtonLabels.sdk,
                path:    '/sdk',
                onClick: () => {
                    history.push('/sdk');
                }
            },
            !stripeState.isHobbyist && !stripeState.isPremium ? {
                label:   SettingsButtonLabels[ 'team-library' ],
                onClick: () => {
                    if (checkSubscription()) {
                        showModal();
                    }
                }
            } : undefined,
            BuilderState.isNftAvailable ? {
                label:   SettingsButtonLabels[ 'nft-dashboard' ],
                path:    '/nft-dashboard',
                onClick: () => {
                    if (checkSubscription()) {
                        history.push('/nft-dashboard');
                    }
                }
            } : undefined,
            stripeState.isEnterprise
            || stripeState.isTeams ? {
                    label:   SettingsButtonLabels[ 'google-analytics' ],
                    path:    '/analytics-google',
                    onClick: () => {
                        if (checkSubscription()) {
                            history.push('/analytics-google');
                        }
                    }
                } : undefined,
            stripeState.isEnterprise
            || stripeState.isTeams ? {
                    label:   SettingsButtonLabels[ 'matomo-analytics' ],
                    path:    '/analytics-matomo',
                    onClick: () => {
                        if (checkSubscription()) {
                            history.push('/analytics-matomo');
                        }
                    }
                } : undefined,
            {
                label:   SettingsButtonLabels.support,
                onClick: () => {
                    window.open(NOTION_SUPPORT_URL);
                }
            },
            {
                label:   SettingsButtonLabels.contact,
                path:    '/contact',
                onClick: () => {
                    history.push('/contact');
                }
            },
            {
                label:   SettingsButtonLabels[ 'your-profile' ],
                path:    '/profile',
                onClick: () => {
                    history.push('/profile');
                }
            },
            signOutItem
        ].filter((el) => el).filter(ownerItemsFilter),
        [ BuilderState.isNftAvailable, stripeState.plan_title, isSubscriptionOver ]
    );

    const activeItems = isOwner ? OwnerItems : SharedItems;

    return (
        <Dropdown
            menuWidth="fit-content"
            radius="xxxl"
            menu={ (closeDropdown) => (
                <Menu activeItemId={ pathname } viewType="dark" padding="xs" radius="xxxl">
                    { activeItems.map(({ path, label, onClick }, i) => (
                        <MenuItem
                            key={ i }
                            id={ path }
                            onSelect={ () => {
                                onClick();
                                closeDropdown();
                            } }
                        >
                            <Description noWrap>{ label }</Description>
                        </MenuItem>
                    )) }
                </Menu>
            ) }
            closeOnSelect
            position="top-left"
            viewType="secondary"
            margin="sm"
        >
            <Button rounded size="lg" icon="settings" iconSize={ 24 } />
        </Dropdown>
    );
});
