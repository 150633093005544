import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 40px 20px 40px 50px;
  background: rgba(202, 202, 202, 0.19);
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 10px;
`;
