import * as React                              from "react";
import type { StandardMaterial }               from "@babylonjs/core/Materials/standardMaterial";
import { Observable }                          from "@babylonjs/core/Misc/observable";
import { CheckBoxLineComponent }               from "@geenee/geespector-ui-components/src/lines/checkBoxLineComponent";
import { Color3LineComponent }                 from "@geenee/geespector-ui-components/src/lines/color3LineComponent";
import { LineContainerComponent }              from "@geenee/geespector-ui-components/src/lines/lineContainerComponent";
import { SliderLineComponent }                 from "@geenee/geespector-ui-components/src/lines/sliderLineComponent";
import type { LockObject }                     from "@geenee/geespector-ui-components/src/tabs/propertyGrids/lockObject";
import { Wrapper }                             from '@geenee/ui';
import type { GlobalState }                    from "../../../../globalState";
import type { PropertyChangedEvent }           from "../../../../propertyChangedEvent";
import { ActionTabSectionComponent }           from '../../../actionTabSectionComponent';
import { TextureLinkLineComponent }            from "../../../lines/textureLinkLineComponent";
import { ShowMoreSectionComponent }            from "../../../showMoreSectionComponent";
import { CommonMaterialPropertyGridComponent } from "./commonMaterialPropertyGridComponent";

interface IStandardMaterialPropertyGridComponentProps {
    globalState: GlobalState;
    material: StandardMaterial;
    lockObject: LockObject;
    onSelectionChangedObservable?: Observable<any>;
    onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
}

export class StandardMaterialPropertyGridComponent extends React.Component<IStandardMaterialPropertyGridComponentProps> {
    private _onDebugSelectionChangeObservable = new Observable<TextureLinkLineComponent>();

    constructor(props: IStandardMaterialPropertyGridComponentProps) {
        super(props);
    }

    renderTextures() {
        const { material } = this.props;

        const onDebugSelectionChangeObservable = this._onDebugSelectionChangeObservable;

        return (
            <ActionTabSectionComponent title="TEXTURES" collapsed>
                <TextureLinkLineComponent
                    label="Diffuse"
                    texture={ material.diffuseTexture }
                    propertyName="diffuseTexture"
                    material={ material }
                    onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                    onDebugSelectionChangeObservable={ onDebugSelectionChangeObservable }
                />
                <TextureLinkLineComponent
                    label="Specular"
                    texture={ material.specularTexture }
                    propertyName="specularTexture"
                    material={ material }
                    onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                    onDebugSelectionChangeObservable={ onDebugSelectionChangeObservable }
                />
                <TextureLinkLineComponent
                    label="Reflection"
                    texture={ material.reflectionTexture }
                    propertyName="reflectionTexture"
                    material={ material }
                    onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                    onDebugSelectionChangeObservable={ onDebugSelectionChangeObservable }
                />
                <TextureLinkLineComponent
                    label="Refraction"
                    texture={ material.refractionTexture }
                    propertyName="refractionTexture"
                    material={ material }
                    onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                    onDebugSelectionChangeObservable={ onDebugSelectionChangeObservable }
                />
                <TextureLinkLineComponent
                    label="Emissive"
                    texture={ material.emissiveTexture }
                    propertyName="emissiveTexture"
                    material={ material }
                    onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                    onDebugSelectionChangeObservable={ onDebugSelectionChangeObservable }
                />
                <TextureLinkLineComponent
                    label="Bump"
                    texture={ material.bumpTexture }
                    propertyName="bumpTexture"
                    material={ material }
                    onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                    onDebugSelectionChangeObservable={ onDebugSelectionChangeObservable }
                />
                <TextureLinkLineComponent
                    label="Opacity"
                    texture={ material.opacityTexture }
                    propertyName="opacityTexture"
                    material={ material }
                    onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                    onDebugSelectionChangeObservable={ onDebugSelectionChangeObservable }
                />
                <TextureLinkLineComponent
                    label="Ambient"
                    texture={ material.ambientTexture }
                    propertyName="ambientTexture"
                    material={ material }
                    onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                    onDebugSelectionChangeObservable={ onDebugSelectionChangeObservable }
                />
                <TextureLinkLineComponent
                    label="Lightmap"
                    texture={ material.lightmapTexture }
                    propertyName="lightmapTexture"
                    material={ material }
                    onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                    onDebugSelectionChangeObservable={ onDebugSelectionChangeObservable }
                />
                <TextureLinkLineComponent
                    label="Detailmap"
                    texture={ material.detailMap.texture }
                    material={ material }
                    onTextureCreated={ (texture) => (material.detailMap.texture = texture) }
                    onTextureRemoved={ () => (material.detailMap.texture = null) }
                    onSelectionChangedObservable={ this.props.onSelectionChangedObservable }
                    onDebugSelectionChangeObservable={ onDebugSelectionChangeObservable }
                />
                <CheckBoxLineComponent
                    label="Use lightmap as shadowmap"
                    target={ material }
                    propertyName="useLightmapAsShadowmap"
                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                />
                <CheckBoxLineComponent
                    label="Use detailmap"
                    target={ material.detailMap }
                    propertyName="isEnabled"
                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                />
            </ActionTabSectionComponent>
        );
    }

    render() {
        const { material } = this.props;

        return (
            <Wrapper className="pane">
                <CommonMaterialPropertyGridComponent
                    globalState={ this.props.globalState }
                    lockObject={ this.props.lockObject }
                    material={ material }
                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                />
                { this.renderTextures() }
                <ActionTabSectionComponent title="LIGHTING & COLORS" collapsed>
                    <Color3LineComponent label="Emissive" target={ material } propertyName="emissiveColor" onPropertyChangedObservable={ this.props.onPropertyChangedObservable } />
                    <ShowMoreSectionComponent>
                        <Color3LineComponent label="Diffuse" target={ material } propertyName="diffuseColor" onPropertyChangedObservable={ this.props.onPropertyChangedObservable } />
                        <Color3LineComponent label="Specular" target={ material } propertyName="specularColor" onPropertyChangedObservable={ this.props.onPropertyChangedObservable } />
                        <SliderLineComponent
                            label="Specular power"
                            target={ material }
                            propertyName="specularPower"
                            minimum={ 0 }
                            maximum={ 128 }
                            step={ 0.1 }
                            onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                        />

                        <Color3LineComponent label="Ambient" target={ material } propertyName="ambientColor" onPropertyChangedObservable={ this.props.onPropertyChangedObservable } />
                        <CheckBoxLineComponent
                            label="Use specular over alpha"
                            target={ material }
                            propertyName="useSpecularOverAlpha"
                            onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                        />
                    </ShowMoreSectionComponent>
                </ActionTabSectionComponent>
                <ActionTabSectionComponent title="LEVELS" collapsed>
                    { material.emissiveTexture && (
                        <SliderLineComponent
                            label="Emissive level"
                            target={ material.emissiveTexture }
                            propertyName="level"
                            minimum={ 0 }
                            maximum={ 2 }
                            step={ 0.01 }
                            onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                        />
                    ) }
                    <ShowMoreSectionComponent>
                        { material.diffuseTexture && (
                            <SliderLineComponent
                                label="Diffuse level"
                                target={ material.diffuseTexture }
                                propertyName="level"
                                minimum={ 0 }
                                maximum={ 2 }
                                step={ 0.01 }
                                onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                            />
                        ) }
                        { material.specularTexture && (
                            <SliderLineComponent
                                label="Specular level"
                                target={ material.specularTexture }
                                propertyName="level"
                                minimum={ 0 }
                                maximum={ 2 }
                                step={ 0.01 }
                                onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                            />
                        ) }
                        { material.reflectionTexture && (
                            <SliderLineComponent
                                label="Reflection level"
                                target={ material.reflectionTexture }
                                propertyName="level"
                                minimum={ 0 }
                                maximum={ 2 }
                                step={ 0.01 }
                                onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                            />
                        ) }
                        { material.refractionTexture && (
                            <SliderLineComponent
                                label="Refraction level"
                                target={ material.refractionTexture }
                                propertyName="level"
                                minimum={ 0 }
                                maximum={ 2 }
                                step={ 0.01 }
                                onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                            />
                        ) }
                        { material.bumpTexture && (
                            <SliderLineComponent
                                label="Bump level"
                                target={ material.bumpTexture }
                                propertyName="level"
                                minimum={ 0 }
                                maximum={ 2 }
                                step={ 0.01 }
                                onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                            />
                        ) }
                        { material.opacityTexture && (
                            <SliderLineComponent
                                label="Opacity level"
                                target={ material.opacityTexture }
                                propertyName="level"
                                minimum={ 0 }
                                maximum={ 2 }
                                step={ 0.01 }
                                onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                            />
                        ) }
                        { material.ambientTexture && (
                            <SliderLineComponent
                                label="Ambient level"
                                target={ material.ambientTexture }
                                propertyName="level"
                                minimum={ 0 }
                                maximum={ 2 }
                                step={ 0.01 }
                                onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                            />
                        ) }
                        { material.lightmapTexture && (
                            <SliderLineComponent
                                label="Lightmap level"
                                target={ material.lightmapTexture }
                                propertyName="level"
                                minimum={ 0 }
                                maximum={ 2 }
                                step={ 0.01 }
                                onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                            />
                        ) }
                        { material.detailMap.isEnabled && (
                            <>
                                <SliderLineComponent
                                    label="Detailmap diffuse"
                                    target={ material.detailMap }
                                    propertyName="diffuseBlendLevel"
                                    minimum={ 0 }
                                    maximum={ 1 }
                                    step={ 0.01 }
                                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                                />
                                <SliderLineComponent
                                    label="Detailmap bump"
                                    target={ material.detailMap }
                                    propertyName="bumpLevel"
                                    minimum={ 0 }
                                    maximum={ 1 }
                                    step={ 0.01 }
                                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                                />
                            </>
                        ) }
                    </ShowMoreSectionComponent>
                </ActionTabSectionComponent>
                <ActionTabSectionComponent title="NORMAL MAP" collapsed hasDivider={ false }>
                    <CheckBoxLineComponent
                        label="Invert X axis"
                        target={ material }
                        propertyName="invertNormalMapX"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                    <CheckBoxLineComponent
                        label="Invert Y axis"
                        target={ material }
                        propertyName="invertNormalMapY"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                </ActionTabSectionComponent>
            </Wrapper>
        );
    }
}
