import {
    AppState,
    AttachmentModel,     ExperienceFactory,
    ExperienceModel,
    ProgramType,
    ProjectType
} from '@geenee/shared';
import { inject, injectable }                        from 'inversify';
import { computed, observable, ObservableMap, toJS } from 'mobx';
import { BaseEntityModel }                           from './base-entity.model';

@injectable()
export class ProjectModel extends BaseEntityModel implements ProjectType {
    @observable title: ProjectType['title'] = '';
    @observable country: ProjectType['country'] = null;
    @observable description: ProjectType['description'] = null;
    @observable mapped_domain: ProjectType['mapped_domain'] = null;
    @observable nft_published: ProjectType['nft_published'] = false;
    @observable header_logo_id: ProjectType['header_logo_id'] = '';
    @observable header_logo: ProjectType['header_logo'] = { url: '' };
    @observable published: ProjectType['published'] = false;
    @observable powered_by_screen: ProjectType['powered_by_screen'] = false;
    @observable logoImage?: AttachmentModel = null;
    options: ProgramType['options'] = {
        branding_bar_color: '',
        background_color:   '',
        logo_image_url:     '',
        logo_image_id:      '',
        path:               ''
    };

    favicon_id: ProjectType['favicon_id'] = null;
    account_id = '';
    internal_domain: ProjectType['internal_domain'] = null;
    experiences = [];
    splash_screen: ProjectType['splash_screen'] = '';
    type = 'project';
    $parent!: AppState;

    @inject('<ExperienceFactory>')
        experienceFactory!: ExperienceFactory;

    @observable experiencesRegistry: ObservableMap<
        string,
        ExperienceModel
        > = new ObservableMap([]);

    @computed get activeExperience() {
        // @TODO: Double-check
        return this.experiencesRegistry.get(this.$parent.options.experienceId || '');
    }

    @computed get sortedChildren() {
        return Array.from(this.experiencesRegistry.values()).sort((a, b) => (a.options.order > b.options.order ? 1 : -1));
    }

    @computed get toJsonObject(): ProjectModel {
        const experiences = Array.from(this.experiencesRegistry.values()).map((el) => el.toJsonObject);
        return { ...toJS(this), $parent: undefined, experiences, experiencesRegistry: undefined };
    }

    @computed get children() {
        return this.experiencesRegistry;
    }
}

export type ProjectModelType = ProjectModel
