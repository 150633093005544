// import '@babylonjs/inspector';
// import '@babylonjs/core/Debug/debugLayer';
// import  '@geenee/geespector/dist/legacy/legacy';
import '@geenee/geespector/src/legacy/legacy';
import { HemisphericLight, Light, SceneLoader, SceneSerializer, Vector3 } from '@babylonjs/core';
import { ArcRotateCamera }                                                from "@babylonjs/core/Cameras/arcRotateCamera";
import { Camera }                                                         from "@babylonjs/core/Cameras/camera";
import { HighlightLayer }                                                 from '@babylonjs/core/Layers/highlightLayer';
import * as babylon                                                       from '@babylonjs/core/Legacy/legacy';
import { DirectionalLight }                                               from "@babylonjs/core/Lights/directionalLight";
import { PointLight }                                                     from "@babylonjs/core/Lights/pointLight";
import { SpotLight }                                                      from "@babylonjs/core/Lights/spotLight";
import { Material }                                                       from "@babylonjs/core/Materials/material";
import { BaseTexture }                                                    from "@babylonjs/core/Materials/Textures/baseTexture";
import { Texture }                                                        from '@babylonjs/core/Materials/Textures/texture';
import { Color3 }                                                         from '@babylonjs/core/Maths/math.color';
import { AbstractMesh }                                                   from "@babylonjs/core/Meshes/abstractMesh";
import { GroundMesh }                                                     from "@babylonjs/core/Meshes/groundMesh";
import { Mesh }                                                           from "@babylonjs/core/Meshes/mesh";
import { TransformNode }                                                  from "@babylonjs/core/Meshes/transformNode";
import { SceneRecorder }                                                  from "@babylonjs/core/Misc/sceneRecorder";
import { Node }                                                           from "@babylonjs/core/node";
import { Nullable }                                                       from "@babylonjs/core/types";
import { GridMaterial }                                                   from '@babylonjs/materials/grid/gridMaterial';
import { GLTF2Export, GLTFData }                                          from '@babylonjs/serializers/glTF/2.0/glTFSerializer';
import bodyModel                                                          from "@geenee/builder/src/asset/models/body_prefab.glb";
import headModel                                                          from "@geenee/builder/src/asset/models/head_prefab.glb";
import { getFileFromUrl }                                                 from "@geenee/builder/src/lib/getFileFromUrl";
import { OccluderMaterial }                                               from "@geenee/geespector/materials/occluder.material";
import { RecordPropertyChangeCommand }                                    from "@geenee/geespector/src/commands/RecordPropertyChangeCommand";
import GlobalState, { PropertyChangedEventType }                          from '@geenee/geespector/src/components/globalState';
import { PropertyChangedEvent }                                           from "@geenee/geespector/src/components/propertyChangedEvent";
import * as INSPECTOR                                                     from "@geenee/geespector/src/legacy/legacy";
import { msToTime }                                                       from "@geenee/geespector/src/lib/msToTime";
import { SceneAssetCacheService }                                         from "@geenee/geespector/src/lib/SceneAssetCacheService";
// import { BabylonRenderer as ParentRenderer } from '@geenee/bodyrenderers-babylon';
import { OmniRenderer }                                                   from "@geenee/omnirenderer";
import { AtomModel }                                                      from '@geenee/shared';
import {
    BABYLON_SECTION_TYPES,
    BODY_OCCLUDER_NODE_ID,
    BODY_TRACKING_OVERLAY_NODE_ID,
    BODY_TRACKING_TWIN_NODE_ID,
    HEAD_OCCLUDER_NODE_ID,
    TYPE_HEAD_TRACKING
} from "@geenee/shared/src/util/constants";
import FS                     from '@isomorphic-git/lightning-fs';
import { debounce }           from "lodash-es";
import { action, observable } from "mobx";
import { v4 }                 from "uuid";
import SceneWorker            from "../src/workers/scene.worker";

const globalObject = typeof global !== "undefined" ? global : typeof window !== "undefined" ? window : undefined;

if (typeof window !== "undefined") {
    window.BABYLON = window.BABYLON || babylon;
    window.BABYLON.Inspector = INSPECTOR.Inspector;
    window.INSPECTOR = INSPECTOR;
}

const CACHE_ALIVE_HRS = 3;

export interface BabylonRendererOptions {
  mode?: 'fit' | 'crop',
  mirror?: boolean,
  debugLayer?: boolean,
}

if (!window.fs) {
    window.fs = new FS('fs');
}
let timer: NodeJS.Timeout | undefined;

SceneLoader.ShowLoadingScreen = false;

export const SKIP_MESHES_SERIALIZE = [ "BackgroundHelper",
    "BackgroundPlane",
    "grid", "Camera", "Light Default", "default light" ];
const HIGHLIGHT_LAYER_NAME = 'HighlightLayer';
export const SKIP_MATERIALS_SERIALIZE = [ "BackgroundPlaneMaterial",
    "GridMaterial" ];

export enum ExportMode {
    file = 0,
    gltfData = 1
}

export type SceneNodeType = TransformNode | Mesh | Material | Texture | Light | BaseTexture | Node
type SceneNodeMapValue = 'rootNodes' | 'transformNodes' | 'materials' | 'meshes' | 'textures' | 'lights'
export class BabylonRenderer extends OmniRenderer {
    gridMesh: babylon.Mesh | undefined;
    @observable wasSceneChanged = false;
    sceneRecorder: SceneRecorder = new SceneRecorder();
    worker = SceneWorker;
    sceneSize = 0;
    rendererType: typeof BABYLON_SECTION_TYPES[number] = 'body-tracking-overlay';
    babylonSceneFile: File | undefined = undefined;
    // eslint-disable-next-line operator-linebreak
    offscreenCanvas: HTMLCanvasElement =
    // check the compatibility of offscreen canvas
        typeof OffscreenCanvas !== 'undefined'
            ? new OffscreenCanvas(1, 1) : document.createElement('canvas');
    serializedScene: any | undefined = undefined;
    highlightLevel!: HighlightLayer;
    prevHighlightNode: Nullable<Node> = null;
    prevOnPropertyChangeValue?: PropertyChangedEventType;
    sceneNodesMap = new Map<string, SceneNodeMapValue>();
    constructor(container: HTMLElement, options: BabylonRendererOptions =
    { debugLayer: true }, private moleculeId?: string, private type?: string, private commander?: any) {
        super(container, options.mode, options.mirror);

        if (this.scene) {
            this.scene.clearColor = new babylon.Color4(0.9, 0.88, 0.96, 1);
            this.scene.removeCamera(this.camera);

            const arcRotateCamera = new babylon.ArcRotateCamera('Camera', -(Math.PI / 2), Math.PI / 2, 10, new Vector3(0, 0, 0), this.scene);
            arcRotateCamera.setPosition(new Vector3(0, 1.2, 4.5));
            arcRotateCamera.attachControl(this.canvas.layers[ 1 ], false);
            this.camera = arcRotateCamera;

            // get from fs
            // window.fs.readFile('/gee-scene.babylon', {}, (err, data) => {
            //     console.log(data);
            //     this.importBabylonScene(data);
            // });

            this.renderer.runRenderLoop(() => {
                this.updateScene();
            });

            setTimeout(() => {
                const parent = this.canvas.layers[ 0 ].parentElement;
                if (parent) {
                    parent.style.zIndex = '0';
                }
            }, 500);
            container.style.overflow = 'visible';
            this.addHighlightHandler();
            if (options.debugLayer) {
                this.commander = commander;
                this.scene.debugLayer.show();
                this.sceneRecorder.track(this.scene);
            }
        }
    }

    setupCameraScale() {
        if (this.scene) {
            const ignoreWorldSizeNames = [ "grid", "BackgroundHelper", "BackgroundPlane" ];
            const worldExtends = this.scene.getWorldExtends((mesh) => !ignoreWorldSizeNames.includes(mesh.name));
            const worldSize = worldExtends.max.subtract(worldExtends.min);
            let radius = worldSize.length() * 1.5;
            // empty scene scenario!
            if (!Number.isFinite(radius)) {
                radius = 1;
            }
            const arcRotateCamera = this.camera as any as ArcRotateCamera;
            arcRotateCamera.radius = radius;
            arcRotateCamera.lowerRadiusLimit = radius * 0.4;
            arcRotateCamera.upperRadiusLimit = radius * 5;
            arcRotateCamera.wheelPrecision = 100 / radius;

            arcRotateCamera.minZ = radius * 0.01;
            arcRotateCamera.maxZ = radius * 1000;
            arcRotateCamera.speed = radius * 0.2;
        }
    }

    addHighlightHandler = () => {
        this.highlightLevel = new HighlightLayer(HIGHLIGHT_LAYER_NAME, this.scene);
        const backgroundMesh = this.scene?.getMeshByName('BackgroundHelper');
        if (backgroundMesh) {
            backgroundMesh.isPickable = false;
            this.highlightLevel.addExcludedMesh(backgroundMesh as Mesh);
        }
        const backgroundPlane = this.scene?.getMeshByName('BackgroundPlane');
        if (backgroundPlane) {
            backgroundPlane.isPickable = false;
            this.highlightLevel.addExcludedMesh(backgroundPlane as Mesh);
        }
        if (GlobalState.onSelectionChangedObservable) {
            GlobalState.onSelectionChangedObservable.add((entity) => {
                const isMesh = entity instanceof Mesh;
                const isTransformNode = entity instanceof TransformNode;

                if (entity && !isMesh && !isTransformNode) {
                    return;
                }

                if (this.prevHighlightNode && this.prevHighlightNode !== entity) {
                    this.prevHighlightNode.highlight = null;
                }
                if (isMesh) {
                    entity.highlight = true;
                    this.toggleNodeOutline(entity, entity.highlight, true);
                } else if (isTransformNode) {
                    this.highlightLevel.removeAllMeshes();

                    entity.getChildTransformNodes().forEach((node) => {
                        node.getChildMeshes().forEach((mesh) => {
                            this.toggleNodeOutline(mesh, true);
                        });
                    });
                    entity.getChildMeshes().forEach((mesh) => {
                        this.toggleNodeOutline(mesh, true);
                    });
                } else {
                    this.toggleNodeOutline(entity, false, true);
                }
                this.prevHighlightNode = entity;
            });
        }
    };
    createGrid = (gridMesh: GroundMesh) => {
        if (this.scene) {
            const width = 10;
            const depth = 10;

            this.gridMesh = gridMesh;

            if (!this.gridMesh.reservedDataStore) {
                this.gridMesh.reservedDataStore = {};
            }
            this.gridMesh.scaling.x = Math.max(width, depth);
            this.gridMesh.scaling.z = this.gridMesh.scaling.x;
            this.gridMesh.reservedDataStore.isInspectorGrid = true;
            this.gridMesh.isPickable = false;

            const groundMaterial = new GridMaterial('GridMaterial', this.scene);
            groundMaterial.majorUnitFrequency = 10;
            groundMaterial.minorUnitVisibility = 0.3;
            groundMaterial.gridRatio = 0.01;
            groundMaterial.backFaceCulling = false;
            groundMaterial.mainColor = new Color3(0.44, 0.47, 0.56);
            groundMaterial.lineColor = new Color3(0.44, 0.47, 0.56);
            groundMaterial.opacity = 0.8;
            groundMaterial.zOffset = 1.0;
            groundMaterial.opacityTexture = new Texture('https://assets.babylonjs.com/environments/backgroundGround.png', this.scene);

            this.gridMesh.material = groundMaterial;
            this.scene.addMaterial(new OccluderMaterial('Occluder Material', this.scene));
            this.highlightLevel.addExcludedMesh(this.gridMesh);
            if (this.rendererType === TYPE_HEAD_TRACKING) {
                this.gridMesh.position.y = -0.095;
            }
            return this.gridMesh;
        }
        return null;
    };

    checkIfSceneEmpty() {
        const skipNodesNames = [ 'BackgroundHelper', "BackgroundPlane", "Light Default", "default light" ];
        const filteredNodes = this.scene?.rootNodes?.filter((el) => (el.getClassName() === 'Mesh' || el.getClassName() === 'TransformNode')
            && !skipNodesNames.includes(el.name));
        const filteredMeshes = this.scene?.meshes.filter((el) => (el.getClassName() === 'Mesh' || el.getClassName() === 'TransformNode')
            && !skipNodesNames.includes(el.name));
        return !filteredNodes?.length && !filteredMeshes?.length;
    }

    removeGrid() {
        this.getNodeByName('grid')?.dispose(false, true);
    }

    async addReferenceHead(parent: Mesh | TransformNode) {
        const models = await this.importModelFile(`${ window.location.origin }${ headModel }`, undefined, parent);
        models.forEach((m) => {
            m.metadata = { gltf: { extras: { engeenee: { occluder: {} } } } };
        });
        this.setSceneChanged();
    }

    async addReferenceBody(parent: Mesh | TransformNode) {
        const models = await this.importModelFile(`${ window.location.origin }${ bodyModel }`, undefined, parent);
        models.forEach((m) => {
            m.metadata = { gltf: { extras: { engeenee: { occluder: {} } } } };
        });
        this.setSceneChanged();
    }

    removeMeshes() {
        if (this.scene) {
            this.scene.meshes.forEach((el) => el.dispose());
        }
    }

    removeReferenceHead() {
        this.getNodeByName(HEAD_OCCLUDER_NODE_ID)?.dispose(false, true);
    }

    removeReferenceBody() {
        this.getNodeByName(BODY_OCCLUDER_NODE_ID)?.dispose(false, true);
    }

    setSceneObjectValues(entity: any, field: string, value: any, skipCommandCreation = false) {
        const initialValue = entity[ field ];
        const e = new PropertyChangedEvent();
        e.object = entity;
        e.value = value;
        e.property = field;
        e.initialValue = initialValue;
        GlobalState.onPropertyChangedObservable.notifyObservers({ ...e, skipCommandCreation });
        entity[ field ] = value;
    }

    createRecordPropertyChangeCommand = debounce((e) => {
        const entity = {
            ...e,
            initialValue:
        this.prevOnPropertyChangeValue ? this.prevOnPropertyChangeValue.initialValue : e.initialValue
        };
        if (entity.value !== entity.initialValue) {
            const command = new RecordPropertyChangeCommand(this, entity);
            this.commander.executeCommand(command);
        }
        this.prevOnPropertyChangeValue = undefined;
    }, 500);

    startRecordPropertyChanges = (e: PropertyChangedEventType) => {
        if (e.skipCommandCreation) {
            return;
        }
        if (!this.prevOnPropertyChangeValue || e.property !== this.prevOnPropertyChangeValue?.property) {
            this.prevOnPropertyChangeValue = { ...e };
        }
        this.createRecordPropertyChangeCommand(e);
    };

    addNewNodesToNodesMap = (nodes: SceneNodeType[]) => nodes.forEach((node) => {
        let type: SceneNodeMapValue = 'transformNodes';
        if (node instanceof Mesh) {
            type = 'meshes';
        } else if (node instanceof Light) {
            type = 'lights';
        } else if (node instanceof TransformNode) {
            type = 'transformNodes';
        } else if (node instanceof Material) {
            type = 'materials';
        } else if (node instanceof Texture) {
            type = 'textures';
        }
        this.setNodeToMap(node, type);
    });
    addListeners = () => {
        if (this.scene) {
            GlobalState.onPropertyChangedObservable?.add((e: PropertyChangedEventType) => {
                this.setSceneChanged();
                this.scene && this.startRecordPropertyChanges(e);
            });
            GlobalState.onEntityAddedObservable?.add((nodes: Node[]) => {
                this.setSceneChanged();
                this.addNewNodesToNodesMap(nodes);
            });
        }
    };

    notifyEmptyScene() {
        GlobalState.onEmptySceneObserver.notifyObservers(this.checkIfSceneEmpty());
    }

    // check the compatibility of offscreen canvas

    postFillScene = () => {
        this.addListeners();
    };

    fillTheScene = async (sceneActorAtoms: AtomModel[]) => {
        try {
            const wasImportedFromCache = (await Promise.all(sceneActorAtoms.map((atom) => {
                let sizeSum = 0;
                const asset = SceneAssetCacheService.get(atom.id);
                if (asset) {
                    sizeSum += asset.file_size || 0;
                    this.importModelFile(URL.createObjectURL(asset), undefined, undefined, false);
                    this.sceneSize = sizeSum;
                }
                return !!asset;
            }))).every((el) => el);

            if (!wasImportedFromCache) {
                let sizeSum = 0;
                await Promise.all(sceneActorAtoms.map(async (atom) => {
                    if (atom.type !== 'scene-actor') return;
                    sizeSum += atom.firstAsset?.file_size || 0;
                    const file = await getFileFromUrl(atom.firstAsset.url);
                    SceneAssetCacheService.add(atom.id, file);
                    return this.importModelFile(URL.createObjectURL(file), undefined, undefined, false);
                }));
                this.sceneSize = sizeSum;
                if (!sceneActorAtoms.length) {
                    this.notifyEmptyScene();
                }
            }
            this.postFillScene();
        } catch (e) {
            console.error(e);
        }
    };

    skipHelpersSerialization = (serializedScene: Record<string, any>) => {
        serializedScene.meshes = serializedScene.meshes.filter((el) => !SKIP_MESHES_SERIALIZE.includes(el.name));
        serializedScene.materials = serializedScene.materials.filter((el) => !SKIP_MATERIALS_SERIALIZE.includes(el.name));
        serializedScene.cameras = [];
        serializedScene.effectLayers = serializedScene.effectLayers.filter((el) => el.name !== HIGHLIGHT_LAYER_NAME);
    };

    serializeAsBabylon = async () => {
        if (this.scene && this.moleculeId) {
            try {
                const FILE_NAME = `gee-scene-${ this.moleculeId }`;

                // serialize the scene in .babylon format as fast as we can using SceneSerializer
                const serializedScene = SceneSerializer.Serialize(this.scene);
                this.skipHelpersSerialization(serializedScene);
                if (this.prevHighlightNode?.highlight) {
                    this.scene.meshes.forEach((item) => {
                        item.highlight = null;
                    });
                    this.prevHighlightNode.highlight = null;
                }

                const blob = new Blob(
                    [ JSON.stringify(serializedScene) ],
                    { type: 'application/json' }
                );
                const file = new File(
                    [ blob ],
                    `${ FILE_NAME }.babylon`,
                    { type: 'application/json', lastModified: Date.now() }
                );
                this.sceneSize = file.size;

                window.fs.writeFile(`/${ FILE_NAME }.babylon`, file, { encoding: 'utf8' }, (err) => {
                    console.log("serialized!");
                    if (err) {
                        console.error(err);
                    }
                });
                return file;
            } catch (e) {
                console.error(e);
            }
        } else {
            console.error('Scene wasnt saved');
        }
        return null;
    };

    importLocalModelFile = async () => {
        let resolve: { (arg0: boolean): void; (value?: unknown): void; };
        const resultPromise = new Promise((res) => {
            resolve = res;
        });
        if (this.moleculeId) {
            window.fs.readFile(`/gee-scene-${ this.moleculeId }.babylon`, (err: any, data: File) => {
                if (data) {
                    const { hours } = msToTime(Date.now() - data.lastModified);
                    this.sceneSize = data.size;
                    if (hours < CACHE_ALIVE_HRS) {
                        data.text().then(async (text) => {
                            SceneLoader.LoadAssetContainerAsync(
                                '',
                                `data:${ text }`,
                                this.scene,
                                null,
                                '.babylon'
                            )
                                .then((container) => {
                                    container.addAllToScene();
                                    // this.setSceneNodesMap();
                                    resolve(true);
                                });

                            // notify observers
                            // resolve(true);/**/
                        });
                    } else {
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            });
        }
        return resultPromise;
    };

    debounceLeading(func, timeout = 300) {
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                timer = undefined;
                func.apply(this, args);
            }, timeout);
        };
    }

    @action
        setSceneChanged = () => {
            this.wasSceneChanged = true;
        };

    setLightParent = (light: Light, parent?: SceneNodeType) => {
        if (parent) {
            if (light.setParent) {
                light.setParent(parent);
            } else {
                light.parent = parent;
            }
        }
    };

    addPointLight(parent?: SceneNodeType) {
        if (this.scene) {
            const newPointLight = new PointLight(
                "Point light",
                Vector3.Zero(),
                this.scene
            );
            this.setLightParent(newPointLight, parent);
            GlobalState?.onSelectionChangedObservable?.notifyObservers(newPointLight);
            return newPointLight;
        }
        return null;
    }

    addDirectLight(parent?: SceneNodeType) {
        if (this.scene) {
            const newDirectionalLight = new DirectionalLight(
                "Directional light",
                new Vector3(-1, -1, -0.5),
                this.scene
            );
            this.setLightParent(newDirectionalLight, parent);
            GlobalState?.onSelectionChangedObservable?.notifyObservers(newDirectionalLight);
            return newDirectionalLight;
        }
        return null;
    }

    addAmbientLight(parent?: any) {
        if (this.scene) {
            // add sky highlight
            const ambientLightNode = new TransformNode('Ambient Light', this.scene);
            const newAmbientLight = new HemisphericLight(
                "Ambient light",
                new Vector3(0, 1, 0),
                this.scene
            );
            this.setLightParent(newAmbientLight, ambientLightNode);
            GlobalState?.onSelectionChangedObservable?.notifyObservers(newAmbientLight);
            return newAmbientLight;
            // const helper = this.scene.createDefaultEnvironment({ createSkybox: false });
            // GlobalState?.onSelectionChangedObservable?.notifyObservers(helper?.rootMesh);
            // return helper;
        }
        return null;
    }

    addSpotLight(parent?: SceneNodeType) {
        if (this.scene) {
            const newSpotLight = new SpotLight(
                "Spot Light",
                new Vector3(0, 1, 1),
                new Vector3(0, 0, -10),
                Math.PI / 2,
                10,
                this.scene
            );
            this.setLightParent(newSpotLight, parent);
            GlobalState?.onSelectionChangedObservable?.notifyObservers(newSpotLight);
            return newSpotLight;
        }
        return null;
    }

    prefabModel: Node[] = [];
    async addPrefab(prefab: string) {
        try {
            const parent = this.scene?.activeCamera?.parent;
            if (this.scene) {
                this.prefabModel = await this.importModelFile(`${ window.location.origin }${ prefab }`, undefined, parent, false);
                GlobalState?.onSelectionChangedObservable?.notifyObservers(this.prefabModel);
                this.postFillScene();
            }
        } catch (e) {
            console.error(e);
        }
    }

    removePrefab() {
        if (this.scene) {
            this.prefabModel.forEach(
                (node) => node.dispose()
            );
        }
    }

    getTrackingNode = () => this.scene?.rootNodes.find((item) => {
        if (item instanceof TransformNode) {
            return !!item.metadata?.gltf?.extras?.engeenee;
        }
        return false;
    });
    async addNodeWithModel(file: File) {
        const workingUrl = URL.createObjectURL(file);
        const parentNode = this.getTrackingNode();
        const nodes = await this.importModelFile(workingUrl, file?.name, parentNode, true);
        nodes.forEach((n) => GlobalState.onSelectionChangedObservable.notifyObservers(n));
        GlobalState?.onEntityAddedObservable?.notifyObservers(nodes);
        return nodes;
    }

    addMaterial(material: Material) {
        this.scene?.addMaterial(material);
        GlobalState.onSelectionChangedObservable?.notifyObservers(material);
        // GlobalState?.onEntityAddedObservable?.notifyObservers([ material ]);
    }

    removeMaterial(entity: Material) {
        this.scene?.removeMaterial(entity);
        entity.dispose();
    }

    addTransformNode(node: TransformNode) {
        this.scene?.addTransformNode(node);
        if (GlobalState.onPropertyChangedObservable) {
            const e = new PropertyChangedEvent();
            e.object = node;
            GlobalState.onPropertyChangedObservable.notifyObservers({ ...e, skipCommandCreation: true });
        }
    }

    // importBabylonScene = async (babylonFile: File) => {
    //     if (this.scene) {
    //         console.log('import');
    //         const scene = await SceneLoader.LoadAssetContainerAsync(
    //             '/gee-scene.babylon', babylonFile, this.scene);
    //         if (scene) {
    //             this.scene = scene.scene;
    //             this.scene.debugLayer.show();
    //             // this.addListeners();
    //         }
    //     }
    // };

    clearListeners() {
        GlobalState.onPropertyChangedObservable?.clear();
        GlobalState.onEntityAddedObservable?.clear();
        GlobalState.onSelectionChangedObservable?.clear();
        GlobalState.onEmptySceneObserver?.clear();
    }

    getSceneGlbInWorker = (mode = ExportMode.file): Promise<File|GLTFData> => new Promise((resolve) => {
        // const canvas = this.canvas.layers[ 1 ];
        // if (canvas.transferControlToOffscreen) {
        //     const offscreen = new OffscreenCanvas(canvas.clientWidth, canvas.clientHeight);
        //
        //     this.worker.postMessage({ canvas: offscreen, moleculeId: this.moleculeId }, [ offscreen ]);
        //     this.worker.addEventListener("message", (event) => {
        //         const { file } = event.data;
        //         resolve(file);
        //     });
        // } else {
        // Safari Workaround
        // @ts-ignore
        // getSceneGlb(window.fs, { Engine, Scene, SceneLoader, GLTF2Export },
        //     document.createElement('canvas'),).then((result) => {
        //         resolve(result);
        //     });
        // }
        const FILE_NAME = 'gee-scene';
        GLTF2Export.GLBAsync(
            this.scene,
            `${ FILE_NAME }.glb`,
            {
                exportUnusedUVs:                        true,
                includeCoordinateSystemConversionNodes: true,
                shouldExportNode:                       (node) => {
                    if (node.metadata && node.metadata._skinAuxilaryNode) {
                        return false;
                    }

                    if (node instanceof Camera || SKIP_MESHES_SERIALIZE.includes(node.name)) {
                        return false;
                    }
                    return true;
                }
                // includeCoordinateSystemConversionNodes: false
            }
        ).then((result: GLTFData) => {
            console.log(result);
            switch (mode) {
                case ExportMode.file: {
                    const blob = result.glTFFiles[ `${ FILE_NAME }.glb` ];
                    if (blob) {
                        const file = new File([ blob ], `${ FILE_NAME }.glb`);
                        resolve(file);
                    }
                    break;
                }
                case ExportMode.gltfData: {
                    resolve(result);
                    break;
                }
                default: console.error('getSceneGlbInWorker: Type not found error');
            }
        }).catch((error) => {
            console.error(error);
        });
    });
        // const canvas = this.canvas.layers[ 1 ];
        // if (canvas.transferControlToOffscreen) {
        //     const offscreen = new OffscreenCanvas(canvas.clientWidth, canvas.clientHeight);
        //     offscreen.toDataURL = () => {
        //         const blob = offscreen.convertToBlob;
        //         return new FileReaderSync().readAsDataURL(blob);
        //     };
        //
        //     this.worker.postMessage({ canvas: offscreen, moleculeId: this.moleculeId }, [ offscreen ]);
        //     this.worker.addEventListener("message", (event) => {
        //         const { file } = event.data;
        //         resolve(file);
        //     });
        // } else {
        //     // Safari Workaround
        //     // @ts-ignore
        //     getSceneGlb(window.fs, { ...babylon, GLTF2Export }, document.createElement('canvas'), this.moleculeId).then((result) => {
        //         resolve(result);
        //     });
        // }
        // TODO: unmock when glb is fine
        // .babylon file temporary
        // const FILE_NAME = `/gee-scene-${ this.moleculeId }.babylon`;
        // window.fs.readFile(FILE_NAME, (err, data) => {
        //     const file = new File([ data ], 'scene.babylon');
        //     resolve(file);
        // });
    // );

    fitNodeToViewport = (node: Mesh | TransformNode) => {
        const sizes = node.getHierarchyBoundingVectors();
        let DIVIDER = 2.3;
        if (this.type === TYPE_HEAD_TRACKING) {
            DIVIDER = 0.17;
        }
        const size = {
            x: sizes.max.x - sizes.min.x,
            y: sizes.max.y - sizes.min.y,
            z: sizes.max.z - sizes.min.z
        };
        const modelMax = Math.max(size.x, size.y, size.z);
        const ratio = DIVIDER / (modelMax || 1);
        node.scaling.x *= ratio;
        node.scaling.y *= ratio;
        node.scaling.z *= ratio;

        const e = new PropertyChangedEvent();
        e.object = node;
        e.property = "scaling";
        e.value = node.scaling;
        GlobalState.onPropertyChangedObservable.notifyObservers({ ...e, skipCommandCreation: true });
    };

    getValidatedParent = (parent: Node) => {
        let validatedParent: Node | null = parent;
        const isTrackableParent = [ BODY_TRACKING_OVERLAY_NODE_ID, BODY_TRACKING_TWIN_NODE_ID ].includes(parent?.id || '');
        if (isTrackableParent) {
            const hasAlreadySkeleton = parent.getChildMeshes().some((el) => el.skeleton);
            if (hasAlreadySkeleton) {
                validatedParent = null;
            }
        }
        return validatedParent;
    };

    getNodeByMetaData(metadata?: {[key: string]: any}) {
        const uuid = metadata?.gltf?.extras?.uuid;
        const type = this.sceneNodesMap.get(uuid);

        if (!uuid || !type || !this.scene) {
            return null;
        }
        return (this.scene[ type ] || []).find((el: SceneNodeType) => el.metadata?.gltf?.extras?.uuid === uuid);
    }

    // setSceneNodesMapByPointers() {
    //     this.sceneNodesMap.clear();
    //     const setNode = (item: SceneNodeType) => {
    //         if (!item?.metadata?.gltf?.pointers) {
    //             return;
    //         }
    //         item.metadata.gltf.pointers.forEach((pointer: string) => {
    //             let nodes = this.sceneNodesMap.get(pointer);
    //             if (nodes) {
    //                 nodes.push(item);
    //             } else {
    //                 nodes = [ item ];
    //             }
    //             this.sceneNodesMap.set(pointer, nodes);
    //         });
    //     };
    //     this.scene?.transformNodes.forEach(setNode);
    //     this.scene?.materials.forEach(setNode);
    //     this.scene?.meshes.forEach(setNode);
    //     this.scene?.textures.forEach(setNode);
    // }
    setNodeToMap(item: SceneNodeType, path: SceneNodeMapValue) {
        const uuid = v4();
        item.metadata = {
            ...item.metadata || {},
            gltf: {
                ...item.metadata?.gltf || {},
                extras: {
                    ...item.metadata?.gltf?.extras || {},
                    uuid
                }
            }
        };
        this.sceneNodesMap.set(uuid, path);
    }
    setSceneNodesMap() {
        this.sceneNodesMap.clear();
        this.scene?.rootNodes.forEach((item) => this.setNodeToMap(item, 'rootNodes'));
        this.scene?.transformNodes.forEach((item) => this.setNodeToMap(item, 'transformNodes'));
        this.scene?.materials.forEach((item) => this.setNodeToMap(item, 'materials'));
        this.scene?.meshes.forEach((item) => this.setNodeToMap(item, 'meshes'));
        this.scene?.textures.forEach((item) => this.setNodeToMap(item, 'textures'));
        this.scene?.lights.forEach((item) => this.setNodeToMap(item, 'lights'));
    }
    importModelFile = (
        url: string,
        fileName?: string,
        parent?: Nullable<Node>,
        setSceneDurty = true
    ): Promise<Node[]> => new Promise((resolve, reject) => {
        let ext = '.glb';
        if (url.split('.').length > 2) {
            const maybeExt =   url.split('.').pop();
            if (maybeExt === 'gltf' || maybeExt === 'glb' || maybeExt === 'babylon') {
                ext = `.${ maybeExt }`;
            }
        }

        SceneLoader.LoadAssetContainerAsync(
            '',
            url,
            this.scene,
            null,
            // get extension from url
            ext
        ).then((container) => {
            if (this.scene) {
                const rootsCount0 = this.scene.rootNodes.length;
                container.addToScene((item) => {
                    if (item instanceof Camera) {
                        const cameraParent = item.parent;
                        item.dispose(false, true);
                        if (cameraParent && !cameraParent.getChildren().length) {
                            cameraParent.dispose();
                        }
                        return false;
                    }
                    return true;
                });

                const rootNodes = this.scene.rootNodes.slice(rootsCount0);

                if (parent) {
                    const validatedParent = this.getValidatedParent(parent);
                    rootNodes.forEach(
                        (n) => {
                            const nodeHasSkeleton = (n as Mesh).skeleton || n.getChildMeshes().some((el) => el.skeleton);
                            n.parent = nodeHasSkeleton ? validatedParent : parent;
                        }
                    );
                }
                this.notifyEmptyScene();

                if (setSceneDurty) {
                    this.setSceneChanged();
                } else {
                    this.setupCameraScale();
                }
                this.scene.skeletons?.forEach((skeleton) => {
                    skeleton.bones.forEach((bone) => {
                        if (bone._linkedTransformNode && bone._linkedTransformNode.metadata) {
                            bone._linkedTransformNode.metadata.type = 'bone';
                        }
                    });
                });
                this.setSceneNodesMap();
                resolve(rootNodes);
            }
            throw new Error("Scene is not initialized when asset is imported");
        }).catch((error) => {
            reject(error);
        });
    });

    findNodeById = (uniqueId: number, type: 'mesh' | 'light' | 'transformNode') => {
        switch (type) {
            case 'mesh':
                return this.scene?.meshes.find((el) => el.uniqueId === uniqueId);
            case 'light':
                return this.scene?.lights.find((el) => el.uniqueId === uniqueId);
            case 'transformNode':
                return this.scene?.transformNodes.find((el) => el.uniqueId === uniqueId);
            default:
                return undefined;
        }
    };
    showHideMesh = (uniqueId: number, type: 'mesh' | 'light' | 'transformNode') => {
        if (this.scene) {
            const node = this.findNodeById(uniqueId, type);
            if (!node) {
                return;
            }
            if (node instanceof Light) {
                node.setEnabled(!node.isEnabled());
            } else {
                const isVisible = !node.isVisible;
                node.isVisible = isVisible;
                const e = new PropertyChangedEvent();
                e.object = node;
                e.property = "isVisible";
                e.value = isVisible;
                e.initialValue = !isVisible;
                GlobalState.onPropertyChangedObservable.notifyObservers({ ...e, skipCommandCreation: true });
            }
        }
    };

    removeLight(node: Light) {
        GlobalState.enableLightGizmo(node as Light, false);
        this.scene?.removeLight(node as Light);
    }

    removeNodeSkeleton(node: Node) {
        const skeleton = node.getChildMeshes(false).find(
            (child) => child.skeleton
        )?.skeleton || undefined;
        node.getChildren().find((child) => {
            if (child instanceof Light) {
                this.removeLight(child);
                return true;
            }
            return false;
        });
        if (skeleton) {
            skeleton.dispose();
        }
    }

    deleteMesh = (node: SceneNodeType) => {
        if (node && this.scene) {
            if (node instanceof Mesh) {
                this.scene?.removeMesh(node as AbstractMesh, true);
                this.removeNodeSkeleton(node);
            } else if (node instanceof Light) {
                this.scene?.reservedDataStore?.gizmoManager?.attachToMesh(null);
                this.removeLight(node as Light);
            } else if (node instanceof TransformNode) {
                this.scene?.removeTransformNode(node as TransformNode);
                this.removeNodeSkeleton(node);
            } else if (node instanceof Texture) {
                this.scene.removeTexture(node);
            } else if (node instanceof Material) {
                this.scene.removeMaterial(node);
            }
            node.dispose(false, true);
            const e = new PropertyChangedEvent();
            e.object = node;
            GlobalState.onPropertyChangedObservable.notifyObservers({ ...e, skipCommandCreation: true });
            GlobalState.onSelectionChangedObservable?.notifyObservers(null);
            this.notifyEmptyScene();
        }
    };

    renameMesh = (mesh: SceneNodeType, newName: string) => {
        const prevData = mesh.name;
        mesh.name = newName;
        GlobalState.onPropertyChangedObservable.notifyObservers({
            object:       mesh,
            property:     "name",
            value:        newName,
            initialValue: prevData
        });
    };

    addMesh = (mesh: SceneNodeType) => {
        if (this.scene) {
            if (mesh instanceof Mesh) {
                this.scene.addMesh(mesh);
            } else if (mesh instanceof Light) {
                this.scene.addLight(mesh);
            } else if (mesh instanceof TransformNode) {
                this.scene.addTransformNode(mesh);
            }
        }
    };

    getNodeByName = (name: string) => this.scene?.getNodeByName(name);

    clearScene = () => {
        this.scene?.dispose();
    };

    clearNodes = (disposeMaterialAndTextures = false) => {
        if (this.scene) {
            const { length } = this.scene.rootNodes;
            for (let i = 0; i < length;) {
                const node = this.scene.rootNodes[ i ];
                if (!node) {
                    return;
                }
                if (!SKIP_MESHES_SERIALIZE.includes(node.name)) {
                    node.dispose(false, disposeMaterialAndTextures);
                } else {
                    i++;
                }
            }
        }
    };

    toggleNodeOutline = (node: AbstractMesh, value: boolean, removePrevHighLights = false) => {
        if (removePrevHighLights) {
            this.highlightLevel.removeAllMeshes();
        }
        if (value) {
            this.highlightLevel.addMesh(node, Color3.FromHexString("#FF1D97"));
        }
    };
}
