import React                                               from 'react';
import { useParams }                                       from 'react-router';
import { RouteParams, SectionModel, useInject, ViewModel } from '@geenee/shared';
import { FormItem, Input, InputTableItem, Select }         from '@geenee/ui';
import { observer }                                        from 'mobx-react';
import { TYPE_DRAWER_OVERLAY }                             from '@geenee/builder/src/lib/constants';

interface Props {
  // eslint-disable-next-line react/require-default-props
  button_link_type?: string;
  // eslint-disable-next-line react/require-default-props
  button_url?: string;
  handleSetLinkPathInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSetLinkPath: (value: string) => void;
}

export const UrlField = observer(({ button_link_type, button_url, handleSetLinkPathInput, handleSetLinkPath }: Props) => {
    const { AppState } = useInject();
    const { activeProject } = AppState;

    const experiences = Array.from(activeProject?.experiencesRegistry?.values() || []).sort((a, b) => a.options.order - b.options.order);
    const views = experiences.reduce((resultViews: ViewModel[], experience) => {
        const currentViews = Array.from(experience.viewsRegistry?.values() || [])
            .sort((a, b) => a.options.order - b.options.order);
        return [ ...resultViews, ...currentViews ];
    }, [] as ViewModel[]);

    const allSections = views.reduce((resultSections: SectionModel[], view) => {
        const currentSections = Array.from(view.sectionsRegistry?.values() || [])
            .sort((a, b) => a.options.order - b.options.order).filter((item) => item.type !== TYPE_DRAWER_OVERLAY);
        return [ ...resultSections, ...currentSections ];
    }, [] as SectionModel[]);

    const { projectId } = useParams<RouteParams>();
    const options = allSections.map((section) => ({
        value: `/${ projectId }/${ section.$parent.$parent.id }/${ section.$parent.id }/${ section.id }`,
        label: section.options.section_menu_title
    }));

    if (button_link_type === 'web') {
        return (
            <InputTableItem label=''>
                <FormItem
                    autoFocusDetect
                >
                    <Input
                        size="md"
                        fullWidth
                        name="linkPath"
                        onChange={ handleSetLinkPathInput }
                        value={ button_url || '' }
                        placeholder="https://www.google.com"
                    />
                </FormItem>
            </InputTableItem>
        );
    }
    return (
        <InputTableItem label=''>
            <Select
                size='md'
                placeholder={ options.length === 0 ? 'None' : 'Choose Content' }
                value={ options.find((item) => item.value === button_url) }
                options={ options }
                onChange={ ({ value }) => handleSetLinkPath(value as string) }
            />
        </InputTableItem>
    );
});
