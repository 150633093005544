import React                                         from 'react';
import { Button, Description, Icon, Wrapper }        from '@geenee/ui';
import { DeletedSectionContent, DeletedSectionRoot } from './styles';

interface Props {
  onCloseDelete: () => void;
  onUndoClick: () => void;
}

export function DeletedSection({ onCloseDelete, onUndoClick }: Props) {
    return (
        <DeletedSectionRoot>
            <Wrapper row valign="center">
                <Description size="sm">Section Deleted</Description>
                { ' ' }
                <Button weight="medium" disableBorderRadius viewType="link" onClick={ onUndoClick }>undo</Button>
            </Wrapper>
            <Icon style={ { position: 'absolute', right: 16 } } name="close2" size={ 10 } onClick={ onCloseDelete } />

        </DeletedSectionRoot>
    );
}
