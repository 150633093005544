import React                  from 'react';
import { ActionsButtonsRoot } from '@geenee/geeclient-kit/src/lib/component/scene/widget/components/AtionsButtons/styles';
import { MuteButton }         from '@geenee/geeclient-kit/src/lib/component/scene/widget/components/MuteButton';
import { useInject }          from '@geenee/shared/index';
import { SwapCamera }         from '@geenee/shared/src/magellan/components/SwapCamera';
import { SceneModelType }     from '@geenee/shared/src/magellan/renderer/r3f-renderer/r3f.renderer';
import {
    TYPE_BODY_TRACKING_OVERLAY,
    TYPE_BODY_TRACKING_TWIN,
    TYPE_HEAD_TRACKING,
    TYPE_STAMP_AR
} from '@geenee/shared/src/util/constants';
import { observer } from 'mobx-react';

interface Props {
    sceneModel: SceneModelType;
}
const HAS_MUTE_BUTTON_TYPES = [ TYPE_STAMP_AR, TYPE_HEAD_TRACKING, TYPE_BODY_TRACKING_OVERLAY, TYPE_BODY_TRACKING_TWIN ];
export const ActionsButtons = observer(({ sceneModel }: Props) => {
    const { AppState } = useInject();
    const showSwipeCamera = HAS_MUTE_BUTTON_TYPES.includes(AppState.arSection?.type || '');

    return (
        <ActionsButtonsRoot>
            <MuteButton sceneModel={ sceneModel } />
            { showSwipeCamera ? <SwapCamera /> : <></> }
        </ActionsButtonsRoot>
    );
});
