import React from 'react';

// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '20',
    height = '22',
    onClick,
    id,
    fill = ''
}: SvgIconProps) {
    return (
        <svg
            id={ id }
            onClick={ onClick }
            width={ width }
            height={ height }
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M2 12L10 3V8.5L18 9L9.5 19.5V12.5L2 12Z" stroke={ fill ? '#57E7D4' : 'white' } strokeWidth="1.7" fill={ fill } />
        </svg>
    );
}
