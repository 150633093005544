import React                    from 'react';
import { DIContextType }        from '@geenee/geeclient-kit/src/lib/context/di.context';
import { AtomModel, useInject } from '@geenee/shared/index';
import { withDIContext }        from '@geenee/shared/src/magellan/hoc/withDIContext.hoc';
import { observer }             from 'mobx-react';
import { AudioRow }             from './AudioRow';
import { ObjectListItem }       from './object-list-item.component';

export const ObjectsList = withDIContext(observer(
    ({ diContext }: {diContext: DIContextType}) => {
        const { SceneState } = useInject();
        const { activeMolecule, selectedAtomId, disabled } = SceneState;
        const atomModels: AtomModel[] = activeMolecule?.sceneActorAtoms || [];

        const { useSceneAssetsActions } = diContext;
        const { replaceSceneAsset } = useSceneAssetsActions();

        return (
            <>
                { atomModels.map((el) => {
                    const attachment = Array.from(el.assetsRegistry.values())[ 0 ];
                    const loadedAsset = Array.from(el.objectsRegistry.values())[ 0 ];
                    return loadedAsset ? (
                        <ObjectListItem
                            atomModel={ el }
                            animationObjectId={ el.id }
                            key={ el.id }
                            active={ selectedAtomId === el.id }
                            fileName={
                                attachment?.title || attachment?.filename?.split('/')?.pop() || ''
                            }
                            onClick={ () => SceneState.setSelectedAtomId(el.id) }
                            disabled={ disabled }
                            title={ el.options.atom_title }
                            onReplaceAsset={ replaceSceneAsset }
                        />
                    ) : (
                        <div key={ el.id } />
                    );
                }) }
                <AudioRow
                    disabled={ disabled }
                />
            </>
        );
    }
));
