import React, { FC, Suspense, useEffect, useRef }  from 'react';
import { DndProvider }                             from 'react-dnd';
import { HTML5Backend }                            from 'react-dnd-html5-backend';
import { Processor }                               from "@geenee/armature";
import GeeneeARScene                               from '@geenee/geeclient-kit/src/lib/component/scene/components/GeeneeARScene/GeeneeARScene';
import { GeeneeSceneType }                         from '@geenee/geeclient-kit/src/lib/component/scene/GeeneeScene.component';
import LoadedAssets                                from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/loaded-assets.component3d';
import LoadingScene                                from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/loading-scene.component3d';
import EditAssetSceneState                         from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/state/edit-asset-scene.state';
import { TransformModes }                          from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/type';
import { DIContextType }                           from '@geenee/geeclient-kit/src/lib/context/di.context';
import { AtomModel, useInject }                    from '@geenee/shared/index';
import { BuilderThreeRenderer }                    from "@geenee/shared/src/magellan/hepler/builder-three.renderer";
import { WithoutVideoEngine }                      from "@geenee/shared/src/magellan/hepler/WithoutVideoEngine";
import { withDIContext }                           from '@geenee/shared/src/magellan/hoc/withDIContext.hoc';
import { fitCameraToObject }                       from '@geenee/shared/src/util/fitCameraToObject';
import { generatePlaneMesh }                       from '@geenee/shared/src/util/generatePlaneMesh';
import { Wrapper }                                 from "@geenee/ui";
import { useContextBridge }                        from '@react-three/drei';
import { MobXProviderContext, observer, Provider } from 'mobx-react';
// @ts-ignore
import { Vector3 }                                 from 'three';

type PropsType = {
  width: number
  height: number
  fov?: number
  onClick?: any
  diContext: DIContextType
  mode?: GeeneeSceneType
  blockingRender?: boolean
}

// eslint-disable-next-line no-shadow
enum KeyCodes {
  KeyE = 69,
  KeyR = 82,
  KeyT = 84
}
export const Viewer3dWrapper: FC<PropsType> = withDIContext<PropsType>(
    observer(({
        width,
        height,
        mode,
        diContext,
        blockingRender,
        ...props
    }) => {
        const { AppState, BuilderState } = useInject();
        const ContextBridge = useContextBridge(MobXProviderContext);
        const { firstAvailableSection, activeSceneModel } = AppState;
        const { $sceneState: SceneState } = BuilderState;
        const { activeMolecule } = firstAvailableSection;
        useEffect(() => {
            SceneState.init(mode);
        }, [ mode ]);

        const { selectedAtomId, disabled, cameraPosition } = SceneState;

        const atomModels = activeMolecule?.sceneActorAtoms || [] as AtomModel[];

        const { SceneControls, SceneControls2d } = diContext;

        const sceneGroupRef = useRef();

        const updateSelectedAtom = () => {
            const lastId = atomModels[ atomModels.length - 1 ].id;
            if (lastId !== selectedAtomId) {
                SceneState.setSelectedAtomId(lastId);
            }
        };
        const updateSelectedAtomAfterRemoving = () => {
            if (atomModels.find((atom) => atom.id === selectedAtomId)) {
                return;
            }
            if (atomModels.length) {
                updateSelectedAtom();
            } else {
                selectedAtomId && SceneState.setSelectedAtomId(null);
            }
        };

        useEffect(() => {
            updateSelectedAtomAfterRemoving();
        }, [ atomModels.length ]);
        // Reset Scene state on unmount
        useEffect(() => () => EditAssetSceneState.resetScene(), []);

        useEffect(() => {
            const handleKeyDown = (event: KeyboardEvent) => {
                if (disabled || event?.target?.className?.includes('input')) return;
                switch (event.keyCode) {
                    case KeyCodes.KeyE: {
                        const newTransformMode = TransformModes.scale;
                        SceneState.setTransformControlsMode(newTransformMode);
                        break;
                    }
                    case KeyCodes.KeyR: {
                        const newTransformMode = TransformModes.rotate;
                        SceneState.setTransformControlsMode(newTransformMode);
                        break;
                    }
                    case KeyCodes.KeyT: {
                        const newTransformMode = TransformModes.translate;
                        SceneState.setTransformControlsMode(newTransformMode);
                        break;
                    }
                    default: {
                        break;
                    }
                }
            };
            window.addEventListener('keydown', handleKeyDown);
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        }, [ ]);

        const handleLoadingCallback = (value: boolean) => {
            EditAssetSceneState.setLoaded(value);
            activeSceneModel.setLoaded(value);
            if (value) {
                updateSelectedAtom();
            }
        };

        const rescale = () => {
            if (activeSceneModel?.scene && activeSceneModel.camera) {
                activeSceneModel.renderer.domElement.style.width = 'auto';
                activeSceneModel.renderer.domElement.style.height = '100%';
                activeSceneModel.camera.aspect = 1;
                const plane = generatePlaneMesh();
                plane.visible = false;
                activeSceneModel.scene.add(plane);
                fitCameraToObject(activeSceneModel.camera, plane, false);
                const positionParams = mode === 'preview'
                    ? [ activeSceneModel.camera.position.x, 0.6, activeSceneModel.camera.position.z ]
                    : [ cameraPosition.x, cameraPosition.y, cameraPosition.z ];
                activeSceneModel.camera.position.set(...positionParams);
            }
        };

        useEffect(() => {
            rescale();
        }, [ activeSceneModel?.scene, sceneGroupRef.current ]);

        useEffect(() => {
            if (mode !== 'preview') {
                BuilderState.setToastPosition('center');
            }
            return () => {
                if (mode !== 'preview') {
                    BuilderState.setToastPosition('right');
                }
            };
        }, [ mode ]);

        const LightsComponent = activeSceneModel?._children.lights_component.component;

        const init = async (canvasContext: CanvasRenderingContext2D) => {
            const engine = new WithoutVideoEngine(Processor, {});
            const renderer = new BuilderThreeRenderer(
                canvasContext,
                activeSceneModel!
            );
            await Promise.all([
                engine.addRenderer(renderer),
                engine.init({ root: '/asset/wasm/' }) ]);

            activeSceneModel?.emitter.addListener('geenee-start-animation-loop', () => {
                engine.start();
            });
            activeSceneModel?.emitter.addListener('geenee-stop-animation-loop', () => {
                engine.pause();
            });
        };

        if (!activeMolecule) {
            return <></>;
        }

        return (
            <DndProvider backend={ HTML5Backend }>
                <Provider SceneState={ SceneState }>
                    <SceneControls2d
                        sceneGroupRef={ sceneGroupRef }
                    >
                        <Wrapper
                            style={ {
                                width:    '100%',
                                height:   '100%',
                                position: 'absolute',
                                zIndex:   -1
                            } }
                        >
                            <GeeneeARScene
                                width={ width }
                                height={ height }
                                activeSceneModel={ activeSceneModel }
                                mode={ mode }
                                blockingRender={ blockingRender }
                                onSceneInit={ init }
                            >
                                <Provider SceneState={ SceneState }>
                                    <Suspense fallback={ mode !== 'preview' ? <LoadingScene /> : undefined }>
                                        <LightsComponent />
                                        <group
                                            name="main-assets-group"
                                            ref={ sceneGroupRef }
                                            position={ activeMolecule.position }
                                            scale={ activeMolecule.scale }
                                            rotation={ activeMolecule.rotation }
                                        >
                                            { atomModels.map((atomModel: AtomModel) => (
                                                <LoadedAssets
                                                    key={ atomModel.id }
                                                    loadingCallback={ handleLoadingCallback }
                                                    atomModel={ atomModel }
                                                    loadedAsset={ atomModel.firstObject }
                                                    activeSceneModel={ activeSceneModel }
                                                    mode={ mode }
                                                    onModelClick={ () => SceneState.setSelectedAtomId(atomModel.id) }
                                                />
                                            )) }
                                        </group>
                                        <SceneControls
                                            sceneGroupRef={ sceneGroupRef }
                                        />
                                    </Suspense>
                                </Provider>
                            </GeeneeARScene>
                        </Wrapper>
                    </SceneControls2d>
                </Provider>
            </DndProvider>
        );
    })
);
// eslint-disable-next-line arca/no-default-export
export default Viewer3dWrapper;
