import React                               from 'react';
import { cn, Description, Title, Wrapper } from '@geenee/ui';
import './styles.scss';

interface Props {
  icon: JSX.Element
  title: string
  description: string
  onMouseEnter?: () => void
    onClick?: () => void
}

const className = cn('add-section-item');

export function AddSectionItem({
    icon, title, description, onMouseEnter, onClick
} : Props) {
    return (
        <Wrapper className={ className('root') } maxWidth="180px" onMouseEnter={ onMouseEnter } onClick={ onClick }>
            <Wrapper row valign="center" margin="xs" style={ { height: 30, marginBottom: 14 } }>
                { icon }
                <Title styles={ { marginLeft: 14, fontWeight: 500 } } weight="regular" size="xxs">{ title }</Title>
            </Wrapper>
            <Description className={ className('description') }>
                { description }
            </Description>
        </Wrapper>
    );
}
