import React from 'react';

// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '17',
    height = '17',
    fill = '#57E7D4',
    style
}: SvgIconProps) {
    return (
        <svg width={ width } height={ height } viewBox="0 0 17 17" fill="none" style={ style }>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M16.292 6.37186C16.2922 6.45937 16.2763 6.547 16.2441 6.62993L16.2406 6.63884C16.1356 6.89856 15.881 7.0818 15.5837 7.0818H11.3337C10.9425 7.0818 10.6253 6.76467 10.6253 6.37347C10.6253 5.98227 10.9425 5.66514 11.3337 5.66514H13.7954L11.8119 3.80133C10.4205 2.40972 8.43523 1.83547 6.53751 2.26485C4.63979 2.69424 3.09507 4.0672 2.446 5.90143C2.31549 6.27022 1.91073 6.46339 1.54194 6.33289C1.17315 6.20239 0.97998 5.79763 1.11048 5.42884C1.92182 3.13605 3.85273 1.41984 6.22487 0.883115C8.59702 0.346388 11.0787 1.0642 12.798 2.78439L14.8753 4.73607V2.12347C14.8753 1.73227 15.1925 1.41514 15.5837 1.41514C15.9749 1.41514 16.292 1.73227 16.292 2.12347V6.37186ZM0.000324249 10.6266C0.000110626 10.5391 0.0160141 10.4515 0.0481834 10.3685L0.051712 10.3596C0.156729 10.0999 0.411295 9.91668 0.708658 9.91668H4.95866C5.34986 9.91668 5.66699 10.2338 5.66699 10.625C5.66699 11.0162 5.34986 11.3333 4.95866 11.3333H2.49688L4.48038 13.1971C5.87178 14.5888 7.85709 15.163 9.7548 14.7336C11.6525 14.3042 13.1972 12.9313 13.8463 11.097C13.9768 10.7283 14.3816 10.5351 14.7504 10.6656C15.1192 10.7961 15.3123 11.2009 15.1818 11.5696C14.3705 13.8624 12.4396 15.5786 10.0674 16.1154C7.69529 16.6521 5.21366 15.9343 3.49433 14.2141L1.41699 12.2624V14.875C1.41699 15.2662 1.09986 15.5833 0.708658 15.5833C0.317456 15.5833 0.000324249 15.2662 0.000324249 14.875V10.6266Z"
                fill={ fill }
            />
            <mask id="mask0_22372_8606" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="17">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    // eslint-disable-next-line max-len
                    d="M16.292 6.37186C16.2922 6.45937 16.2763 6.547 16.2441 6.62993L16.2406 6.63884C16.1356 6.89856 15.881 7.0818 15.5837 7.0818H11.3337C10.9425 7.0818 10.6253 6.76467 10.6253 6.37347C10.6253 5.98227 10.9425 5.66514 11.3337 5.66514H13.7954L11.8119 3.80133C10.4205 2.40972 8.43523 1.83547 6.53751 2.26485C4.63979 2.69424 3.09507 4.0672 2.446 5.90143C2.31549 6.27022 1.91073 6.46339 1.54194 6.33289C1.17315 6.20239 0.97998 5.79763 1.11048 5.42884C1.92182 3.13605 3.85273 1.41984 6.22487 0.883115C8.59702 0.346388 11.0787 1.0642 12.798 2.78439L14.8753 4.73607V2.12347C14.8753 1.73227 15.1925 1.41514 15.5837 1.41514C15.9749 1.41514 16.292 1.73227 16.292 2.12347V6.37186ZM0.000324249 10.6266C0.000110626 10.5391 0.0160141 10.4515 0.0481834 10.3685L0.051712 10.3596C0.156729 10.0999 0.411295 9.91668 0.708658 9.91668H4.95866C5.34986 9.91668 5.66699 10.2338 5.66699 10.625C5.66699 11.0162 5.34986 11.3333 4.95866 11.3333H2.49688L4.48038 13.1971C5.87178 14.5888 7.85709 15.163 9.7548 14.7336C11.6525 14.3042 13.1972 12.9313 13.8463 11.097C13.9768 10.7283 14.3816 10.5351 14.7504 10.6656C15.1192 10.7961 15.3123 11.2009 15.1818 11.5696C14.3705 13.8624 12.4396 15.5786 10.0674 16.1154C7.69529 16.6521 5.21366 15.9343 3.49433 14.2141L1.41699 12.2624V14.875C1.41699 15.2662 1.09986 15.5833 0.708658 15.5833C0.317456 15.5833 0.000324249 15.2662 0.000324249 14.875V10.6266Z"
                    fill={ fill }
                />
            </mask>
        </svg>
    );
}
