import React, { FC } from 'react';
import { cn }        from '../../util/bem';
import LoaderBlue    from './resources/loader-blue.gif';
import LoaderWhite   from './resources/loader-white.gif';
import './loader.component.scss';

export const loaderSize = {
    xsm: '14',
    sm:  '16',
    md:  '18',
    lg:  '20',
    xl:  '24'
};

export type PropsType = {
    color: 'white' | 'blue';
    size?: typeof loaderSize[ keyof typeof loaderSize ];
};

const className = cn('loader');

export const Loader: FC<PropsType> = ({ color, size }) => {
    const getLoader = (): string => {
        switch (color) {
            case 'blue':
                return LoaderBlue;
            case 'white':
            default:
                return LoaderWhite;
        }
    };

    return (<img className={ className({ size }) } alt="loader" src={ getLoader() } />);
};

Loader.defaultProps = { color: 'white', size: '12' } as Partial<PropsType>;
