import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '40',
    height = '40',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
            onClick={ onClick }
        >
            <path
            // eslint-disable-next-line max-len
                d="M25 29.7297L16.5811 4.47312C16.0746 2.95362 13.9253 2.95362 13.4188 4.47312L8.79869 18.3335H3.33329C2.41282 18.3335 1.66663 19.0797 1.66663 20.0002C1.66663 20.9206 2.41282 21.6668 3.33329 21.6668H9.99996C10.7173 21.6668 11.3542 21.2078 11.5811 20.5272L15 10.2706L23.4188 35.5272C23.9253 37.0467 26.0746 37.0467 26.5811 35.5272L31.2012 21.6668H36.6666C37.5871 21.6668 38.3333 20.9206 38.3333 20.0002C38.3333 19.0797 37.5871 18.3335 36.6666 18.3335H30C29.2826 18.3335 28.6457 18.7925 28.4188 19.4731L25 29.7297Z"
                fill="black"
            />
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="3" width="38" height="34">
                <path
                // eslint-disable-next-line max-len
                    d="M25 29.7297L16.5811 4.47312C16.0746 2.95362 13.9253 2.95362 13.4188 4.47312L8.79869 18.3335H3.33329C2.41282 18.3335 1.66663 19.0797 1.66663 20.0002C1.66663 20.9206 2.41282 21.6668 3.33329 21.6668H9.99996C10.7173 21.6668 11.3542 21.2078 11.5811 20.5272L15 10.2706L23.4188 35.5272C23.9253 37.0467 26.0746 37.0467 26.5811 35.5272L31.2012 21.6668H36.6666C37.5871 21.6668 38.3333 20.9206 38.3333 20.0002C38.3333 19.0797 37.5871 18.3335 36.6666 18.3335H30C29.2826 18.3335 28.6457 18.7925 28.4188 19.4731L25 29.7297Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0)">
                <rect width="40" height="40" fill="black" />
            </g>
        </svg>
    );
}
