import { SketchFabImage } from '@geenee/builder/src/core/model/SketchFabModel.model';

export const selectNearestWidthImage = (
    images: SketchFabImage[],
    width: number
) => {
    const filteredImages = images
        .filter((el) => el.width <= width)
    // eslint-disable-next-line eqeqeq
        .sort((a, b) => (b.width == a.width ? 0 : +(b.width > a.width) || -1));
    return filteredImages[ 0 ];
};
