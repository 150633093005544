import React, { FC, memo, useMemo }          from "react";
import { Tooltip }                           from "../../..";
import { cn }                                from "../../util/bem";
import { WeightType }                        from "../../util/global-props";
import { Description, DescriptionPropsType } from "../description/description.component";
import { Wrapper, WrapperPropsType }         from "../wrapper/wrapper.component";
import "./input-table-item.component.scss";

export type InputTableItemPropsType = {
    className?: string;
    label?: string | React.ReactNode;
    style?: React.CSSProperties;
    before?: React.ReactNode;
    marginOff?: boolean;
    ratio?: number;
    after?: React.ReactNode;
    valign?: "start" | "center" | "end" | "base-line";
    size?: DescriptionPropsType["size"];
    weight?: WeightType
    onHoverAfter?: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    tooltipTitle?: string
    tooltipContent?: string
    tooltipMenuWidth?: string
    viewType?: "primary" | "secondary" | "shade-4";
    children?: React.ReactNode;
    padding?: WrapperPropsType["padding"]
    labelDescriptionProps?: Partial<DescriptionPropsType>
    labelWrapperProps?: Partial<WrapperPropsType>
    overflow?: WrapperPropsType["overflow"];
    contentWrapperProps?: WrapperPropsType
}

const contentSize = {
    xs:   "10px",
    sm:   "12px",
    md:   "14px",
    lg:   "16px",
    xl:   "18px",
    xxl:  "24px",
    xxxl: "30px",
    lx:   "48px"
};

const className = cn("input-table-item");

export const InputTableItem: FC<InputTableItemPropsType> = memo(((props) => {
    const flexSizes = useMemo(() => {
        if (!props.ratio) {
            return {
                label:   0.7,
                content: 1
            };
        }
        if (props.ratio > 1) {
            return {
                label:   1,
                content: 1 / props.ratio
            };
        }
        return {
            label:   props.ratio,
            content: 1
        };
    }, [ props.ratio ]);

    return (
        <Wrapper
            row
            className={ className({
                onHoverAfter: props.onHoverAfter,
                valign:       props.valign,
                ratio:        props.ratio,
                marginOff:    props.marginOff
            }, null, props.className) }
            style={ props.style }
            onMouseEnter={ props?.onMouseEnter }
            onMouseLeave={ props?.onMouseLeave }
            padding={ props.padding }
        >
            { props.label !== undefined && (
                <Wrapper
                    valign="center"
                    row
                    align="space-between"
                    className={ className("label", { viewType: props.viewType }) }
                    style={ { flex: flexSizes.label, ...props.labelWrapperProps?.style } }
                    { ...props.labelWrapperProps }
                >
                    <Tooltip
                        menuWidth={ props.tooltipMenuWidth }
                        minWidth={ props.tooltipMenuWidth }
                        title={ props.tooltipTitle }
                        message={ props.tooltipContent }
                        position="top"
                        shadow
                        messageAlign="center"
                        menuPadding="xxs"
                    >
                        <Description
                            { ...props.labelDescriptionProps }
                            weight={ props.weight }
                            style={ { minWidth: `calc(${ props.tooltipMenuWidth } / 2 + 50px )` } }
                            size={ props.size }
                        >
                            { props.label }
                        </Description>
                    </Tooltip>
                    { props.before && (
                        <div className={ `${ className("before") }` }>
                            { props.before }
                        </div>
                    ) }
                </Wrapper>
            ) }
            <Wrapper
                { ...props.contentWrapperProps }
                style={ {
                    ...props.contentWrapperProps?.style && { ...props.contentWrapperProps.style },
                    ...{
                        flex: flexSizes.content,
                        ...props.size && { fontSize: contentSize[ props.size ] }
                    }
                } }
                row
                overflow={ props.overflow }
                className={ className("content") }
            >
                {
                    React.Children?.map(props.children, (child) => (
                        React.isValidElement(child)
                            ? React.cloneElement(child, { ...child.props })
                            : null))
                }

                { props.after && (
                    <div className={ className("after") }>
                        { props.after }
                    </div>
                ) }
            </Wrapper>
        </Wrapper>
    );
}));

InputTableItem.defaultProps = {
    viewType:  "primary",
    marginOff: false,
    ratio:     0.7
};
