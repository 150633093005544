import queryString from 'querystring';
import { History } from 'history';

export const checkSketchfabToken = (history: History) => {
    const query = queryString.parse(window.location.search.slice(1));
    const { sketchfab_access_token, sketchfab_refresh_token } = query;
    if (sketchfab_access_token && sketchfab_refresh_token && !Array.isArray(sketchfab_access_token) && !Array.isArray(sketchfab_refresh_token)) {
        localStorage.setItem('sketchfab_access_token', sketchfab_access_token);
        localStorage.setItem('sketchfab_refresh_token', sketchfab_refresh_token);
        history.push(window.location.pathname);
    }
};
