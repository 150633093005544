import Vector3 from '../math/Vector3';

/**
 *
 */
export default class SensorSample {
  public sample: Vector3;
  public timestamp: number;

  constructor() {
    this.sample = new Vector3();
    this.timestamp = 0;
  }

  set(sample: Vector3, timestampS: number) {
    this.sample = sample;
    this.timestamp = timestampS;
  }

  copy(other: SensorSample) {
    this.set(other.sample, other.timestamp);
  }
}
