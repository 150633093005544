import { getFileFromUrl }                                   from "@geenee/builder/src/lib/getFileFromUrl";
import { container }                                        from "@geenee/builder/src/magellan/di/di";
import { MoleculeModel }                                    from "@geenee/builder/src/magellan/model/molecule.model";
import { GeeneeSceneType }                                  from "@geenee/geeclient-kit/src/lib/component/scene/GeeneeScene.component";
import { AppState, AtomModel }                              from "@geenee/shared";
import { injectable }                                       from "inversify";
import { action, computed, makeAutoObservable, observable } from "mobx";
import { Vector3 }                                          from "three";

@injectable()
export class SceneState {
  @observable selectedAtomId: string | null = null;

  @observable transformControlsMode: "translate" | "rotate" | "scale" | "lightning" | "" = "translate";
  @observable activeTab: "navigator" | "addAsset" = "addAsset";
  @observable parametersMatrixKey = 0;
  @observable isLoading = false;
  @observable selectedCodeModuleId = "";

  $appState: AppState;

  mode: GeeneeSceneType = "preview";
  cameraPosition = new Vector3(0, 1, 5.5);
  cameraTarget = new Vector3(0, 0, 0);

  constructor() {
      makeAutoObservable(this);
  }

  init(mode: GeeneeSceneType) {
      this.$appState = container.get("<AppState>");
      this.mode = mode;
      this.selectedCodeModuleId = "";
      this.selectedAtomId = null;
  }

  @computed get activeMolecule() {
      return this.$appState?.activeMolecule;
  }

  @computed get allModelsHidden() {
      return Array.from(this.activeMolecule?.sceneActorAtoms.values() || [])
          .every((el) => !el.sceneObjectVisible);
  }

  @computed get allAnimationsActive() {
      return Array.from(this.activeMolecule?.sceneActorAtoms.values() || [])
          .every((el) => {
              if (el.firstObject?.animations?.length) {
                  return el.options.scene_atom_selected_animation_index !== null;
              }
              return true;
          });
  }

  @computed get isAllSelected() {
      return this.selectedAtomId === null;
  }

  @computed get disabled() {
      return this.activeMolecule?.nft_published;
  }

  @computed get currentAsset() {
      return this.selectedAtomId ? this.activeMolecule?.atomsRegistry.get(this.selectedAtomId) : this.activeMolecule;
  }

  @computed get currentShadowValue() {
      const atomModels = this.activeMolecule?.sceneActorAtoms || [];
      if (this.currentAsset === this.activeMolecule) {
          const disableCastShadow = atomModels.every((el) => el.options.scene_atom_disable_cast_shadow);
          const disableReceiveShadow = atomModels.every((el) => el.options.scene_atom_disable_receive_shadow);
          return {
              disableCastShadow,
              disableReceiveShadow
          };
      }
      return {
          disableCastShadow:    this.currentAsset?.options.scene_atom_disable_cast_shadow,
          disableReceiveShadow: this.currentAsset?.options.scene_atom_disable_receive_shadow
      };
  }

  @action
  setCodeModuleId(id: string) {
      console.log({ id });
      if (this.selectedAtomId) {
          this.selectedAtomId = "";
      }
      this.selectedCodeModuleId = id;
  }

  @computed get activeCodeAtom() {
      return this.activeMolecule?.sceneCodeModuleAtoms.find(
          (item) => item.id === this.selectedCodeModuleId
      ) || this.activeMolecule?.sceneCodeModuleAtoms[ 0 ] || null;
  }

  @action
  async getCodeModuleJSON() {
      if (!this.activeCodeAtom?.firstAsset.url) {
          return null;
      }
      const result = await fetch(this.activeCodeAtom.firstAsset.url);
      const jsonData = await result.json();
      return jsonData;
  }

  @action
  setIsLoading(_isLoading: boolean) {
      this.isLoading = _isLoading;
  }

  @action
      onTabChange = (tab: "navigator" | "addAsset") => {
          this.activeTab = tab;
      };

  @action
  updateParametersMatrixKey() {
      this.parametersMatrixKey += 1;
  }

  @action setTransformControlsMode(mode: "translate" | "rotate" | "scale" | "lightning" | "") {
      this.transformControlsMode = mode;
  }

  @action setSelectedAtomId(id: string | null) {
      if (this.selectedCodeModuleId) {
          this.selectedCodeModuleId = "";
      }
      this.selectedAtomId = id;
  }

  @action
  changeOpenCustomCodeState(entity: AtomModel | MoleculeModel) {
      entity.custom_code_opened = !entity.custom_code_opened;
  }

  @action
  changeAudioPlaying(entity: AtomModel) {
      entity.is_audio_playing = !entity.is_audio_playing;
  }

  @action
  async deleteAtom(id: string) {
      await this.activeMolecule.deleteAtom(id);
  }

  @action
  async addAtomByUrl(entity: AtomModel) {
      const url = entity?.firstAsset?.audioUrl || entity?.firstAsset?.url;
      const fileName = entity.options.audio_name || entity?.firstAsset?.title || entity?.firstAsset?.filename?.split("/")
          ?.pop() || "";
      const isAudio = entity.type === "scene-audio";

      const file = await getFileFromUrl(url, fileName);
      if (isAudio) {
          this.activeMolecule.setSceneAudioFromFile?.(file);
      } else {
          await this.activeMolecule.addAtomWithAttachment(file, {
              type:    "scene-actor",
              options: file.name.endsWith(".glb")
                  ? { scene_atom_selected_animation_index: 0 } : undefined
          }, this.activeMolecule.$parent?.type !== "native-ar");
      }
  }

  @action
  renameModel(model: AtomModel, name: string) {
      if (model?.type === "scene-audio") {
          model.firstAsset.updateOptions({ audio_name: name });
      } else {
          model?.updateOptions({ atom_title: name });
      }
  }

  @action showHideAtoms(entity: AtomModel | MoleculeModel) {
      if (entity instanceof MoleculeModel) {
          const visible = this.allModelsHidden;
          Array.from(entity.sceneActorAtoms.values())
              .forEach((el: AtomModel) => {
                  el.setSceneObjectVisible(visible);
                  if (el.firstObject) {
                      const object = el.firstObject.scene || el.firstObject;
                      object.visible = visible;
                  }
              });
      } else {
          const object = entity?.firstObject?.scene || entity?.firstObject || entity;
          object.visible = !entity.sceneObjectVisible;
          entity.setSceneObjectVisible(!!object.visible);
      }
  }

  @action activateAnimation(entity: AtomModel | MoleculeModel, index: number | null) {
      if (entity instanceof MoleculeModel) {
          const val = this.allAnimationsActive ? null : 0;
          Array.from(entity.sceneActorAtoms.values())
              .forEach((el) => {
                  if (el.firstObject.animations?.length) {
                      el.updateOptions({ scene_atom_selected_animation_index: val });
                  }
              });
      } else {
          entity.updateOptions({ scene_atom_selected_animation_index: index });
      }
  }

  @action
  async moveToGround() {
      if (!this.currentAsset?.options?.scene_atom_position && !this.currentAsset?.options?.scene_molecule_position) return;
      const newPosition = Array.from(
          this.currentAsset.options.scene_atom_position || this.currentAsset.options.scene_molecule_position
      ) as [ number, number, number ] || [ 0, 0, 0 ];
      newPosition[ 1 ] = 0;
      await this.currentAsset.setSceneStats({ position: newPosition });
      this.updateParametersMatrixKey();
  }
}

export const sceneState = new SceneState();
