import React, { memo }                                              from 'react';
import { Scrollbars }                                               from 'react-custom-scrollbars';
import { Button, Description, Divider, InputTable, InputTableItem } from '@geenee/ui';
import { SketchFabModel }                                           from '@geenee/builder/src/core/model/SketchFabModel.model';
import { humanFileSize }                                            from '@geenee/builder/src/lib/humanFileSizes';

interface Props {
    model: SketchFabModel;
}

const ValueRow = memo(({
    title,
    value
}: {
  title: string;
  value: string | number;
}) => (
    <InputTableItem valign="start" label={ title } viewType="shade-4" size="sm" ratio={ 0.4 }>
        <Description size='sm' color='shade-2'>{ value }</Description>
    </InputTableItem>
));

export const ModelInfoCard = memo(({ model }: Props) => (
    <InputTable>
        <ValueRow title="Title" value={ model.name } />
        { model.archives.gltf.size && (
            <ValueRow title="Archive size" value={ humanFileSize(model.archives.gltf.size, true) } />
        ) }
        <ValueRow title="Vertices count" value={ model.vertexCount } />
        <ValueRow title="Face count" value={ model.faceCount } />
        <ValueRow
            title="Animated"
            value={ model.animationCount ? 'yes' : 'no' }
        />
        <Divider style={ { height: '1px' } } margin="xs" color="shade-6" />
        <InputTableItem marginOff label="Author" viewType="shade-4" size="sm" ratio={ 0.4 }>
            <Description size='sm' color='shade-2'>
                <Button
                    style={ { height: '16px' } }
                    size="sm"
                    viewType="link"
                    onClick={ () => window.open(model.user.profileUrl, '_blank') }
                >
                    { model.user.displayName || model.user.username }
                </Button>
            </Description>
        </InputTableItem>
        <ValueRow title="Views" value={ model.viewCount } />
        <ValueRow title="Likes" value={ model.likeCount } />
        { model.description && (
            <InputTableItem valign="start" label="About" viewType="shade-4" size="sm" ratio={ 0.4 }>
                <Scrollbars
                    autoHeight
                    autoHeightMin={ 0 }
                    autoHeightMax={ 64 }
                >
                    <Description style={ { wordBreak: 'break-word' } } size='sm' color='shade-2'>{ model.description }</Description>
                </Scrollbars>
            </InputTableItem>
        ) }
    </InputTable>
));
