import React                     from 'react';
import { Button, iconMap, Span } from '@geenee/ui';

interface IProps {
    label: string,
    icon: keyof typeof iconMap,
    onClick: () => void,
    className?: string
    description: string
}

export function SectionRowTemplate({
    label, className, icon, onClick, description
}: IProps) {
    return (
        <Button
            className={ className }
            viewType="transparent"
            radius="lg"
            iconColor="shade-2"
            iconSize={ 32 }
            onClick={ onClick }
            icon={ icon }
            weight="medium"
            align="left"
            variant="colorful"
        >
            <div style={ { display: 'flex' } }>
                <Span style={ { display: "unset" } } textAlign="left" weight="regular" color="shade-4">
                    <Span style={ { display: 'unset' } } color="shade-2" weight="medium">{ label }</Span>
                    { ' ' }
                    &#8212;
                    { ' ' }
                    { description }
                </Span>
            </div>
        </Button>
    );
}
