import 'swiper/css/bundle';
import React, { memo, useMemo }              from 'react';
import { Navigation, Pagination }            from 'swiper';
import { Swiper as SwiperRoot, SwiperSlide } from 'swiper/react';
import { SwiperProps as RootSwiperProps }    from 'swiper/react/swiper-react';
import { cn }                                from '../../util/bem';
import { SizesUnionType }                    from '../../util/global-props';
import './swiper.component.scss';

export interface SwiperProps {
  size?: 'xxs' | 'lg';
  padding?: 'xxs' | 'lg';
  children: React.ReactNode
  overflowX?: 'auto' | 'hidden' | 'visible' | 'scroll';
  overflowY?: 'auto' | 'hidden' | 'visible' | 'scroll';
  align?: 'center' | 'right' | 'left' | 'stretch' | 'space-between' | 'space-around';
  valign?: 'top'|'center'|'bottom';
  margin?: SizesUnionType;
  fullHeight?: boolean;
  fullWidth?: boolean;
  arrowsAlign?: 'top'|'center'
  showArrowsAlways?: boolean
}

const classNames = cn('swiper');

export const Swiper = memo(({
    children, overflowX, overflowY,
    padding, size,
    align, valign,
    fullHeight, fullWidth,
    arrowsAlign,
    margin,
    showArrowsAlways,
    ...props
}: SwiperProps & RootSwiperProps) => {
    const modules = useMemo(() => {
        const _modules = [];
        if (props.pagination) {
            _modules.push(Pagination);
        }
        return _modules;
    }, [ props.pagination ]);

    return (
        <SwiperRoot
            onLock={ (swiper) => {
                if (swiper.isLocked && showArrowsAlways) {
                    swiper.navigation.nextEl?.classList.remove('swiper-button-lock');
                    swiper.navigation.prevEl?.classList.remove('swiper-button-lock');
                    swiper.isLocked = false;
                }
            } }
            className={ classNames({
                padding,
                size,
                align,
                valign,
                fullHeight,
                fullWidth,
                margin,
                showArrowsAlways,
                'overflow-x':   overflowX,
                'overflow-y':   overflowY,
                'arrows-align': arrowsAlign
            }) }
            { ...props }
            modules={ modules }
        >
            { React.Children.map(children, (child, index) => (
                <SwiperSlide key={ index }>
                    { child }
                </SwiperSlide>
            )) }
        </SwiperRoot>
    );
});
