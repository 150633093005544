import React                from 'react';
import { Scrollbars }       from "react-custom-scrollbars";
import { HeaderTitle }      from "@geenee/builder/src/components/HeaderTitle";
import { ProfileForm }      from '@geenee/builder/src/pages/ProfilePage/ProfileForm';
import { Divider, Wrapper } from '@geenee/ui';

export function ProfilePage() {
    return (
        <Scrollbars
            autoHide
            autoHideTimeout={ 500 }
            autoHideDuration={ 200 }
        >
            <Wrapper
                fullHeight
                align="center"
                padding="xxl"
            >
                <Wrapper fullHeight maxWidth='800px'>
                    <HeaderTitle title="Your Profile" />

                    <Divider transparent margin="lg" />
                    <ProfileForm />
                </Wrapper>
            </Wrapper>
        </Scrollbars>
    );
}
