import React, { memo, useMemo } from 'react';
import { cn, Title, Wrapper }   from '@geenee/ui';
import UploadCloud              from '@geenee/builder/src/asset/icons/UploadCloud';
import './styles.scss';

interface Props {
  children?: React.ReactNode;
  className?: string
  content?: () => JSX.Element
}

const mainClassName = cn('drop-here');

export const DropHere = memo(({ children, className, content }: Props) => {
    const Content = useMemo(() => {
        if (content) {
            return content();
        }
        return null;
    }, [ content ]);

    return (
        <div className={ mainClassName('', null, className) }>
            { Content || (
                <Wrapper padding='xs' align='center'>
                    <Wrapper margin='xs' align='center'>
                        <UploadCloud />
                    </Wrapper>
                    <Wrapper margin='xxs' align='center'>
                        <Title weight='bold' color="white" size="xs">
                            { children }
                        </Title>
                    </Wrapper>
                </Wrapper>
            ) }
        </div>
    );
});
