import { PopoverSchema } from "@geenee/ui";
import { v4 }            from 'uuid';

const popoverId = v4();
export const SCENE_POPOVER_FULL: PopoverSchema = {
    id:    popoverId,
    type:  'SCENE_POPOVER_FULL',
    meta:  {},
    items: [
        {
            label: 'Add new item',
            meta:  { hasDivider: true, triggerForInside: 'add-new-item' },
            id:    v4()
        },
        {
            label: 'Rename',
            meta:  {},
            id:    v4()
        },
        {
            label: 'Duplicate',
            meta:  { hasDivider: true },
            id:    v4()
        },
        {
            label: 'Show / Hide',
            meta:  {},
            id:    v4()
        },
        {
            label: 'Delete',
            meta:  { color: 'error' },
            id:    v4()
        }
    ],
    insides: [ {
        id:       'add-new-item',
        parentId: popoverId,
        insides:  [],
        meta:     {},
        items:    [ {
            label: 'Point Light',
            id:    v4(),
            meta:  {}

        },
        {
            label: 'Direct Light',
            id:    v4(),
            meta:  {}

        }, {
            label: 'Spot Light',
            meta:  {},
            id:    v4()
        } ]
    } ]
};

export const SCENE_POPOVER_LIGHT: PopoverSchema = {
    id:    popoverId,
    meta:  {},
    type:  'SCENE_POPOVER_LIGHT',
    items: [
        {
            label: 'Rename',
            meta:  {},
            id:    v4()
        },
        {
            label: 'Duplicate',
            meta:  { hasDivider: true },
            id:    v4()
        },
        {
            label: 'Show / Hide',
            meta:  {},
            id:    v4()
        },
        {
            label: 'Delete',
            meta:  { color: 'error' },
            id:    v4()
        }
    ],
    insides: []
};

export const SCENE_POPOVER_TRANSFORM_NODE: PopoverSchema = {
    id:    popoverId,
    type:  'SCENE_POPOVER_TRANSFORM_NODE',
    meta:  {},
    items: [
        {
            label: 'Add new item',
            meta:  { hasDivider: true, triggerForInside: 'add-new-item' },
            id:    v4()

        },
        {
            label: 'Rename',
            meta:  {},
            id:    v4()
        },
        {
            label: 'Duplicate',
            meta:  { hasDivider: true },
            id:    v4()
        },
        {
            label: 'Delete',
            meta:  { color: 'error' },
            id:    v4()
        }
    ],
    insides: [ {
        id:       'add-new-item',
        parentId: popoverId,
        insides:  [],
        meta:     {},
        items:    [ {
            label: 'Point Light',
            id:    v4(),
            meta:  {}

        },
        {
            label: 'Direct Light',
            id:    v4(),
            meta:  {}

        }, {
            label: 'Spot Light',
            meta:  { },
            id:    v4()

        } ]
    } ]
};

export const ASSET_LIBRARY_POPOVER_SCHEMA: PopoverSchema = {
    id:    v4(),
    type:  'ASSET_LIBRARY_POPOVER_SCHEMA',
    meta:  {},
    items: [
        {
            label: 'Edit',
            meta:  { },
            id:    v4()
        },
        {
            label: 'Delete',
            meta:  { color: 'error' },
            id:    v4()
        }
    ],
    insides: []
};

export const TREE_JS_SCENE_POPOVER_SCHEMA: PopoverSchema = {
    id:    v4(),
    type:  'TREE_JS_SCENE_POPOVER_SCHEMA',
    meta:  {},
    items: [
        {
            label: 'Rename',
            meta:  { },
            id:    v4()
        },
        // {
        //     label: 'Replace',
        //     meta:  { },
        //     id:    v4()
        // },
        {
            label: 'Play Animation',
            meta:  {},
            id:    v4()
        },
        {
            label: 'Show/Hide',
            meta:  { hasDivider: true },
            id:    v4()
        },
        {
            label: 'Custom code',
            meta:  { hasDivider: true },
            id:    v4()
        },
        {
            label: 'Delete',
            meta:  { color: 'error' },
            id:    v4()
        }
    ],
    insides: []
};

export const TREE_JS_SCENE_POPOVER_SCHEMA_WITHOUT_ANIMATION: PopoverSchema = {
    id:    v4(),
    type:  'TREE_JS_SCENE_POPOVER_SCHEMA',
    meta:  {},
    items: [
        {
            label: 'Rename',
            meta:  { },
            id:    v4()
        },
        // {
        //     label: 'Replace',
        //     meta:  { },
        //     id:    v4()
        // },
        {
            label: 'Show/Hide',
            meta:  { hasDivider: true },
            id:    v4()
        },
        {
            label: 'Custom code',
            meta:  { hasDivider: true },
            id:    v4()
        },
        {
            label: 'Delete',
            meta:  { color: 'error' },
            id:    v4()
        }
    ],
    insides: []
};

export const TREE_JS_SCENE_POPOVER_SCHEMA_ROOT_WITHOUT_ANIMATION: PopoverSchema = {
    id:    v4(),
    type:  'TREE_JS_SCENE_POPOVER_SCHEMA_ROOT',
    meta:  {},
    items: [
        {
            label: 'Show/Hide',
            meta:  { hasDivider: true },
            id:    v4()
        },
        {
            label: 'Custom code',
            meta:  {},
            id:    v4()
        }
    ],
    insides: []
};

export const TREE_JS_SCENE_POPOVER_SCHEMA_ROOT: PopoverSchema = {
    id:    v4(),
    type:  'TREE_JS_SCENE_POPOVER_SCHEMA_ROOT',
    meta:  {},
    items: [
        {
            label: 'Play Animation',
            meta:  {},
            id:    v4()
        },
        {
            label: 'Show/Hide',
            meta:  { hasDivider: true },
            id:    v4()
        },
        {
            label: 'Custom code',
            meta:  {},
            id:    v4()
        }
    ],
    insides: []
};

export const TREE_JS_SCENE_POPOVER_SCHEMA_AUDIO: PopoverSchema = {
    id:    v4(),
    type:  'TREE_JS_SCENE_POPOVER_SCHEMA_AUDIO',
    meta:  {},
    items: [
        {
            label: 'Rename',
            meta:  { },
            id:    v4()
        },
        // {
        //     label: 'Replace',
        //     meta:  { },
        //     id:    v4()
        // },
        {
            label: 'Play/Stop',
            meta:  { hasDivider: true },
            id:    v4()
        },
        {
            label: 'Delete',
            meta:  { color: 'error' },
            id:    v4()
        }
    ],
    insides: []
};

export const TREE_JS_SCENE_POPOVER_SCHEMA_TRACKER_IMAGE: PopoverSchema = {
    id:    v4(),
    type:  'TREE_JS_SCENE_POPOVER_SCHEMA_TRACKER_IMAGE',
    meta:  {},
    items: [
        {
            label: 'Replace',
            meta:  { },
            id:    v4()
        },
        // {
        //     label: 'Replace',
        //     meta:  { },
        //     id:    v4()
        // },
        {
            label: 'Show/Hide',
            meta:  { },
            id:    v4()
        }
    ],
    insides: []
};
export const TREE_JS_SCENE_POPOVER_SCHEMA_SNIPPET: PopoverSchema = {
    id:    v4(),
    type:  'TREE_JS_SCENE_POPOVER_SCHEMA_SNIPPET',
    meta:  {},
    items: [
        {
            label: 'Delete',
            meta:  { color: 'error' },
            id:    v4()
        }
    ],
    insides: []
};
