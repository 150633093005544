import isEmpty from 'lodash-es/isEmpty';
import without from 'lodash-es/without';

export const useDowngradeRemoveData = (
    selectedProjects: string[],
    selectedMembers: string[],
    projects?: any[],
    members?: any[]
) => {
    const removeData = {} as {
      deleteProjects: string[];
      deleteMembers: string[];
  };
    if (!isEmpty(selectedProjects)) {
        const allProjectIds = projects?.map((p) => p.id);
        const willBeDeleted = without(allProjectIds, ...selectedProjects);
        if (!isEmpty(willBeDeleted)) {
            removeData.deleteProjects = willBeDeleted;
        }
    }
    if (!isEmpty(selectedMembers)) {
        const allMemberEmails = members?.map((m) => m.email);
        const willBeDeleted = without(allMemberEmails, ...selectedMembers);
        if (!isEmpty(willBeDeleted)) {
            removeData.deleteMembers = willBeDeleted;
        }
    }

    return removeData;
};
