import React                from 'react';
import { Scrollbars }       from "react-custom-scrollbars";
import { Divider, Wrapper } from '@geenee/ui';
import { observer }         from 'mobx-react';
import { HeaderTitle }      from "@geenee/builder/src/components/HeaderTitle";
import { YourPlanContent }  from "@geenee/builder/src/pages/YourPlanPage/YourPlanContent";

export const YourPlanPage = observer(() => (
    <Scrollbars
        autoHide
        autoHideTimeout={ 500 }
        autoHideDuration={ 200 }
    >
        <Wrapper
            fullHeight
            padding="xxl"
            align="center"
        >
            <Wrapper maxWidth="736px">
                <HeaderTitle title="Your Plan" />
                <Divider transparent margin="lg" />
                <Wrapper margin="lg">
                    <YourPlanContent />
                </Wrapper>
            </Wrapper>
        </Wrapper>
    </Scrollbars>

));
