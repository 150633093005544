import { AtomModel as AtomModelShared, HttpClient } from '@geenee/shared';
import { AtomSchemaType }                           from '@geenee/shared/type/atom.type';
import { Nullable }                                 from '@geenee/shared/type/shared.type';
import axios                                        from 'axios';
import { injectable }                               from 'inversify';
import { action, computed, makeObservable, toJS }   from 'mobx';
import envConfig                                    from '@geenee/builder/src/lib/envConfig';
import { container }                                from '@geenee/builder/src/magellan/di/di';
import { ProjectModel }                             from '@geenee/builder/src/magellan/model/project.model';

@injectable()
export class AtomModel extends AtomModelShared implements AtomSchemaType {
    httpClient: HttpClient = container.get('<HttpClient>');
    constructor() {
        super();
        makeObservable(this);
    }

    @computed
    get parentProjectEditable(): Nullable<ProjectModel> {
        return this.parentProject as Nullable<ProjectModel>;
    }

    @action
    async saveData() {
        this.updateState(this);
        this.parentProjectEditable?.saveData();
        const atom = toJS(this.toServerData());
        if (process.env.ENV_USE_MOCK_DATA) {
            return (await axios.put(`http://localhost:3000/atoms/${ this.id }`, atom)).data;
        }
        const { data } = await this.httpClient.put(
            `${ envConfig.API_URL }/api/v0/atoms/${ this.id }`,
            { atom }
        );
        return data.data;
    }

    toServerData() {
        return {
            ...this,
            $parent:               undefined,
            httpClient:            undefined,
            imageAsset:            undefined,
            assetsRegistry:        undefined,
            parentProject:         undefined,
            parentProjectEditable: undefined,
            objectsRegistry:       undefined,
            // @TODO: uncomment after integrating real API
            // assets:                undefined,
            attachmentFactory:     undefined,
            scaleLocked:           undefined
        };
    }
}
