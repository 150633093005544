import React, { useEffect, useRef }    from 'react';
import { useInject }                   from '@geenee/shared/index';
import { OrbitControls }               from '@react-three/drei';
import { useThree }                    from '@react-three/fiber';
import { observer }                    from "mobx-react";
import { Group, Object3D }             from 'three';
import { GLTF }                        from 'three/examples/jsm/loaders/GLTFLoader';
import { ModelTransformationControls } from './model-transformation-controls.component3d';

type ControlsProps = {
    targetAsset: Group | Object3D | GLTF | null;
    disabled: boolean;
};

const Scene3DControls = observer(({ targetAsset, disabled }: ControlsProps) => {
    const { SceneState } = useInject();
    const { cameraTarget, cameraPosition } = SceneState;
    const controls = useRef();
    const asset = targetAsset?.scene ? targetAsset.scene : targetAsset;
    const { camera } = useThree();

    useEffect(() => {
        camera.position.copy(cameraPosition);
        controls.current.target = cameraTarget;
    }, [ cameraPosition, cameraTarget ]);

    return (
        <>
            <OrbitControls ref={ controls } dampingFactor={ 0.25 } />
            <ModelTransformationControls
                disabled={ disabled }
                orbit={ controls.current }
                loadedAsset={ asset as Object3D | null }
            />
        </>
    );
});
// eslint-disable-next-line arca/no-default-export
export default Scene3DControls;
