import React, { useState }          from 'react';
import { observer }                 from 'mobx-react';
import { CreditCardForm }           from '@geenee/builder/src/components/modals/common/CreditCardForm';
import { usePaymentMethodHandlers } from '@geenee/builder/src/components/modals/common/handlers/usePaymentMethodHandlers';
import { stripeState }              from '@geenee/builder/src/core/state/stripe.state';
import { BoldCenter }               from '../common/styles';
import { StyledButton, Wrapper }    from './styles';

type CreditCardEditorProps = {
    paymentMethodId?: string;
    isNewCard?: boolean;
    onAddingNewCardFinished?: () => any;
  // eslint-disable-next-line no-empty-pattern
    setModal: ({ }) => void;
    showError: (message: string) => void;
};

export const CreditCardEditor = observer((props: CreditCardEditorProps) => {
    const card = stripeState.paymentMethods[ props.paymentMethodId || '' ] || {
        name:  '',
        last4: ''
    };
    const [ cardName, setCardName ] = useState<string>(card.name);
    const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        handleCardUpdateSubmit,
        addNewCardAndSetDefault,
        waiting
    } = usePaymentMethodHandlers();

    return (
        <Wrapper>
            <BoldCenter>update billing information</BoldCenter>
            <CreditCardForm
                cardName={ cardName }
                onCardNameChange={ (name) => setCardName(name) }
            />

            <StyledButton
                $waiting={ waiting }
                disabled={ waiting }
                onClick={ async () => {
                    if (props.isNewCard) {
                        try {
                            await addNewCardAndSetDefault(cardName, props.showError);
                            props.onAddingNewCardFinished
                                && props.onAddingNewCardFinished();
                        } catch (err: any) {
                            // @ts-ignore
                            props.showError(err.message);
                        }
                    } else if (props.paymentMethodId) {
                        // await handleCardUpdateSubmit(
                        //     card.name !== cardName,
                        //     props.paymentMethodId,
                        //     cardName,
                        //     props.setModal,
                        //     props.showError
                        // );
                    }
                } }
                label="UPDATE PAYMENT"
                $width={ 172 }
            />
        </Wrapper>
    );
});
