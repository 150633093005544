import React, { memo }              from "react";
import { CallbackFunctionVariadic } from "@geenee/geeclient-kit/src/lib/type/type";
import { Button, Description }      from "@geenee/ui";

export const EnterExperienceButton = memo((props: {onClick: CallbackFunctionVariadic, style?: React.CSSProperties}) => (
    <Button
        style={ { width: 180, marginTop: 'auto', ...props.style } }
        radius="lg"
        onClick={ props.onClick }
    >
        <Description weight="semi-bold" size="sm">ENTER EXPERIENCE</Description>
    </Button>
));
