import styled from 'styled-components';

export const IframeWrapper = styled.div`
  background: white;
  border-radius: 10px;
  padding: 10px;
  height: 70vh;
  margin-top: 5px;
`;

export const SelectWrapper = styled.div`
  width: 300px;
  margin-right: 24px;
`;
