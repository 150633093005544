import { updateProfile }                                         from '@geenee/builder/src/api/ProfileAPI';
import { updateMember }                                          from '@geenee/builder/src/api/TeamAPI';
import { action, computed, makeAutoObservable, observable, set } from 'mobx';

export type UserSchema = {
    first_name: string;
    last_name: string;
    email: string;
    acceptedFromEmail: boolean;
    isThirdParty: boolean;
    isSketchFabAuthorized: boolean;
    role?: string;
    roleId: number;
    activated?: boolean;
}

export class UserModel {
    @observable acceptedFromEmail = true;
    @observable is_active = true;
    @observable email: string | null = null;
    @observable first_name: string | null = null;
    @observable isSketchFabAuthorized = true;
    @observable last_name: string | null = null;
    @observable id = '';
    @observable title = 'owner';
    @observable roleId: number | null = null;
    @observable role: string | undefined;
    @observable has_restricted_projects = false;
    @observable restricted_projects_ids: string[] = [];
    @observable login_type = '';
    @observable options: {[key: string]: any} = {};
    @observable password: string | null = null;
    @observable password_confirmation: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    init(userData: Partial<UserModel>) {
        Object.keys(userData).forEach((key) => {
            set(this, key, userData[ key as keyof UserModel ]);
        });
    }

    @computed get canViewProjects() {
        return this.is_active;
    }

    @action
        update = async (data: Partial<UserModel>) => {
            this.init(data);
            await updateProfile(data);
        };

    @action
        updateTeamMember = async (data: Partial<UserModel>) => {
            this.init(data);
            await updateMember(this.id, data);
        };
}

export type UserModelType = UserModel;
