import React from 'react';

// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '22',
    height = '22',
    onClick,
    id,
    fill = 'black'
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            viewBox="0 0 22 22"
            xmlns="http://www.w3.org/2000/svg"
            onClick={ onClick }
        >
            <path
            // eslint-disable-next-line max-len
                d="M4.70711 8.70711C5.09763 8.31658 5.09763 7.68342 4.70711 7.29289C4.31658 6.90237 3.68342 6.90237 3.29289 7.29289L0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071L3.29289 14.7071C3.68342 15.0976 4.31658 15.0976 4.70711 14.7071C5.09763 14.3166 5.09763 13.6834 4.70711 13.2929L2.41421 11L4.70711 8.70711Z"
                fill={ fill }
            />
            <path
            // eslint-disable-next-line max-len
                d="M13.2929 4.70711C13.6834 5.09763 14.3166 5.09763 14.7071 4.70711C15.0976 4.31658 15.0976 3.68342 14.7071 3.29289L11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893L7.29289 3.29289C6.90237 3.68342 6.90237 4.31658 7.29289 4.70711C7.68342 5.09763 8.31658 5.09763 8.70711 4.70711L11 2.41421L13.2929 4.70711Z"
                fill={ fill }
            />
            <path
            // eslint-disable-next-line max-len
                d="M8.70711 17.2929C8.31658 16.9024 7.68342 16.9024 7.29289 17.2929C6.90237 17.6834 6.90237 18.3166 7.29289 18.7071L10.2929 21.7071C10.6834 22.0976 11.3166 22.0976 11.7071 21.7071L14.7071 18.7071C15.0976 18.3166 15.0976 17.6834 14.7071 17.2929C14.3166 16.9024 13.6834 16.9024 13.2929 17.2929L11 19.5858L8.70711 17.2929Z"
                fill={ fill }
            />
            <path
            // eslint-disable-next-line max-len
                d="M17.2929 13.2929C16.9024 13.6834 16.9024 14.3166 17.2929 14.7071C17.6834 15.0976 18.3166 15.0976 18.7071 14.7071L21.7071 11.7071C22.0976 11.3166 22.0976 10.6834 21.7071 10.2929L18.7071 7.29289C18.3166 6.90237 17.6834 6.90237 17.2929 7.29289C16.9024 7.68342 16.9024 8.31658 17.2929 8.70711L19.5858 11L17.2929 13.2929Z"
                fill={ fill }
            />
            <path
            // eslint-disable-next-line max-len
                d="M1 12H21C21.5523 12 22 11.5523 22 11C22 10.4477 21.5523 10 21 10H1C0.447715 10 0 10.4477 0 11C0 11.5523 0.447715 12 1 12Z"
                fill={ fill }
            />
            <path
            // eslint-disable-next-line max-len
                d="M10 1V21C10 21.5523 10.4477 22 11 22C11.5523 22 12 21.5523 12 21V1C12 0.447715 11.5523 0 11 0C10.4477 0 10 0.447715 10 1Z"
                fill={ fill }
            />

        </svg>
    );
}
