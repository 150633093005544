import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInject }                                                from '@geenee/shared/index';
import { Vector3SchemaType }                                        from '@geenee/shared/type/atom.type';
import { OrbitControls }                                            from '@react-three/drei';
import { useThree }                                                 from '@react-three/fiber';
import { observer }                                                 from "mobx-react";
import { Event, Object3D }                                          from 'three';
import { TransformControls }                                        from 'three/examples/jsm/controls/TransformControls';
import { TransformModes }                                           from '../type';
import { getSceneInputId, valueFormat }                             from '../util/scene-inputs.helper';

interface IProps {
    orbit?: OrbitControls;
    loadedAsset: Object3D | null;
    disabled?: boolean
}
const axis = [ 0, 1, 2 ];
const types = [ 'position', 'rotation', 'scale' ];

export const ModelTransformationControls = observer(({
    orbit,
    loadedAsset,
    disabled = false
}: IProps) => {
    const { SceneState } = useInject();
    const { transformControlsMode, currentAsset } = SceneState;
    const { camera, gl, scene } = useThree();
    const meshRef = useRef();
    const [ transformControl, setTControl ] = useState<
        TransformControls | undefined
    >();
    const [ lastloadedAsset, setLastloadedAsset ] = useState<any>();
    // Hack to break the closures and always  get the  fresh stats in every dependency callback
    // const statsRef = useRef(sceneStats);
    // statsRef.current = sceneStats;

    const isTransformMode = !(transformControlsMode === TransformModes.lightning || transformControlsMode === TransformModes.material);

    const orbitPreventionHandler = useCallback(
        (event: Event) => {
            if (orbit) {
                // eslint-disable-next-line no-param-reassign
                orbit.enabled = !event.value;
            }
        },
        [ orbit ]
    );

    const mouseUpHandler = useCallback(
        (e: Event) => {
            const targetObject = e.target.object;

            const scale = targetObject.scale.clone();
            const position = targetObject.position.clone();
            const rotation = targetObject.rotation.clone();

            currentAsset?.setSceneStats({
                scale:    [ scale.x, scale.y, scale.z ],
                position: [ position.x, position.y, position.z ],
                rotation: [ rotation.x, rotation.y, rotation.z ]
            });
            targetObject.position.set(position.x, position.y, position.z);
            targetObject.scale.set(scale.x, scale.y, scale.z);
            targetObject.rotation.set(rotation.x, rotation.y, rotation.z);
            SceneState.updateParametersMatrixKey();
        },
        [ currentAsset?.options ]
    );

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const sceneInputs = useSceneInputs(loadedAsset);

    const changeListener = useCallback(
        (e: Event) => {
            const targetObject = e.target.object;
            if (Object.keys(sceneInputs) && targetObject) {
                // eslint-disable-next-line no-use-before-define,@typescript-eslint/no-use-before-define
                setLockedScaleProperties(currentAsset?.scale, targetObject);
                types.forEach((type) => {
                    axis.forEach((axe) => {
                        if (sceneInputs[ type ][ axe ]) {
                            const input = sceneInputs[ type ] && sceneInputs[ type ][ axe ];
                            if (input) {
                                (input as HTMLInputElement).value = `${ valueFormat(
                                    type,
                                    targetObject[ type ][ axe ]
                                ) }`;
                            }
                        }
                    });
                });
            }
        },
        [ sceneInputs ]
    );

    const destruct = () => {
        if (transformControl) {
            scene.remove(transformControl);
            if (orbit) {
                transformControl.removeEventListener(
                    'dragging-changed',
                    orbitPreventionHandler
                );
            }
            transformControl.removeEventListener('mouseUp', mouseUpHandler);
            transformControl.removeEventListener('change', changeListener);
            transformControl.detach();
            transformControl.dispose();
            setTControl(undefined);
        }
    };

    const subscribeToTransform = () => {
        if (loadedAsset && isTransformMode) {
            destruct();
            const transformControls = new TransformControls(camera, gl.domElement);
            if (orbit) {
                transformControls.addEventListener(
                    'dragging-changed',
                    orbitPreventionHandler
                );
            }
            transformControls.addEventListener('mouseUp', mouseUpHandler);
            transformControls.addEventListener('change', changeListener);
            transformControls.setMode(transformControlsMode);
            transformControls.attach(loadedAsset);
            scene.add(transformControls);
            setTControl(transformControls);
            setLastloadedAsset(loadedAsset);
        }
    };

    useEffect(() => {
        if (!loadedAsset) {
            destruct();
            setLastloadedAsset(null);
        } else if (orbit && (loadedAsset !== lastloadedAsset || !transformControl)) {
            subscribeToTransform();
        }
    }, [ loadedAsset, lastloadedAsset, orbit, transformControlsMode, disabled ]);

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    useTransformationModes(transformControl, transformControlsMode);

    if ((disabled || !isTransformMode) && transformControl) {
        transformControl.detach();
        transformControl.dispose();
        setTControl(undefined);
    }

    return <mesh ref={ meshRef } />;
});

const useTransformationModes = (
    transformControl?: TransformControls,
    transformControlsMode?: TransformModes
) => {
    useEffect(() => {
        if (
            transformControl
            && transformControlsMode
            && transformControl.getMode() !== transformControlsMode
        ) {
            transformControl.setMode(transformControlsMode);
        }
    }, [ transformControlsMode, transformControl ]);
};

const useSceneInputs = (loadedAsset: Object3D | null) => useMemo(() => {
    if (loadedAsset) {
        const inputs: {
                [key: string]: { [key: string]: HTMLElement | null };
            } = {};
        types.forEach((type) => axis.forEach((axe) => {
            if (!inputs[ type ]) {
                inputs[ type ] = {};
            }
            inputs[ type ][ axe ] = document.getElementById(
                getSceneInputId(type, axe)
            );
        }));
        return inputs;
    }
    return {};
}, [ loadedAsset ]);

const setLockedScaleProperties = (scaleStats: Vector3SchemaType, targetObject: Object3D) => {
    if (!targetObject.geeneeScaleUnlocked && scaleStats?.length === 3) {
        if (scaleStats[ 0 ] !== targetObject.scale.x) {
            const delta = targetObject.scale.x - scaleStats[ 0 ];
            // eslint-disable-next-line no-param-reassign
            targetObject.scale.y += delta;
            // eslint-disable-next-line no-param-reassign
            targetObject.scale.z += delta;
        } else if (scaleStats[ 1 ] !== targetObject.scale.y) {
            const delta = targetObject.scale.y - scaleStats[ 1 ];
            // eslint-disable-next-line no-param-reassign
            targetObject.scale.x += delta;
            // eslint-disable-next-line no-param-reassign
            targetObject.scale.z += delta;
        } else if (scaleStats[ 2 ] !== targetObject.scale.z) {
            const delta = targetObject.scale.z - scaleStats[ 2 ];
            // eslint-disable-next-line no-param-reassign
            targetObject.scale.x += delta;
            // eslint-disable-next-line no-param-reassign
            targetObject.scale.y += delta;
        }
    }
};
