import React, { memo, useEffect, useMemo, useState } from "react";
import { Bone }                                      from "@babylonjs/core/Bones/bone";
import { Camera }                                    from "@babylonjs/core/Cameras/camera";
import { Light }                                     from "@babylonjs/core/Lights/light.js";
import { Mesh }                                      from "@babylonjs/core/Meshes/mesh";
import { TransformNode }                             from "@babylonjs/core/Meshes/transformNode.js";
import type { Observable }                           from "@babylonjs/core/Misc/observable";
import { Node }                                      from "@babylonjs/core/node";
import { SKIP_MESHES_SERIALIZE }                     from "@geenee/geespector/renderer/babylonjs.renderer";
import { SetNodeParentCommand }                      from "@geenee/geespector/src/commands/SetNodeParent";
import { getTreeIcon }                               from "@geenee/geespector/src/components/sceneExplorer/getTreeIcon";
import { OptionsLineComponent }                      from "@geenee/geespector-ui-components/src/lines/optionsLineComponent";
import { TextLineComponent }                         from "@geenee/geespector-ui-components/src/lines/textLineComponent";
import type { LockObject }                           from "@geenee/geespector-ui-components/src/tabs/propertyGrids/lockObject";
import { useInject }                                 from "@geenee/shared/src/hook/use-inject.hook";
import type { GlobalStateClass }                     from "../../../globalState";
import GlobalState                                   from "../../../globalState";
import { PropertyChangedEvent }                      from "../../../propertyChangedEvent";

interface IParentPropertyGridComponentProps {
    globalState: GlobalStateClass;
    lockObject: LockObject;
    node: Node;
    onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
    border?: boolean
}

enum SortOrderByType {
    'TrackingNode' = 0,
    'TransformNode' = 1,
    'Mesh' = 2,
    'Bone' = 3
}

const getNodeOrder = (node: Node) => {
    if (node instanceof Mesh) {
        return SortOrderByType.Mesh;
    }
    if (node instanceof TransformNode) {
        if (node.metadata.type === 'bone') {
            return SortOrderByType.Bone;
        }
        if (node.metadata?.gltf?.extras?.engeenee) {
            return SortOrderByType.TrackingNode;
        }
        return SortOrderByType.TransformNode;
    }
    return -1;
};
const checkTrackingNodeToSkeleton = (node?: Node) => {
    if (node) {
        const hasAlreadySkeleton = node.getChildMeshes()
            .some((mesh) => mesh.skeleton);
        if (hasAlreadySkeleton) {
            return true;
        }
    }
    return false;
};
const isAvailableNode = (n: Node) => (!(n instanceof Camera || n instanceof Bone || n instanceof Light)
    && !n.skeleton && !n.metadata?._skinAuxilaryNode && !SKIP_MESHES_SERIALIZE.includes(n.name));

export const ParentPropertyGridComponent = memo((props: IParentPropertyGridComponentProps) => {
    const { node } = props;
    const { AppState } = useInject();
    const { activeSection : sectionModel } = AppState;
    const { sceneManager } = sectionModel || { sceneManager: undefined };
    const [ scene ] = useState(node.getScene());
    const [ children, setChildren ] = useState(new Set());
    const setChildNodes = () => {
        const newSet = new Set();
        node.getChildren(undefined, false).forEach((child) => newSet.add(child));
        setChildren(newSet);
    };

    useEffect(() => {
        setChildNodes();
    }, [ node ]);

    useEffect(() => {
        GlobalState.onEntityAddedObservable.add(() => {
            setChildNodes();
        });
    }, []);
    const nodeHasSkeleton = (node as Mesh).skeleton || node.getChildMeshes().some((el) => el.skeleton);

    const trackingNode = scene?.rootNodes.find((item) => {
        if (item instanceof TransformNode) {
            return !!item.metadata?.gltf?.extras?.engeenee;
        }
        return false;
    });
    const isTrackingNodeHasSkeleton = nodeHasSkeleton ? checkTrackingNodeToSkeleton(trackingNode) : null;

    const isCurrentNodeAvailableToChangeParent = useMemo(() => isAvailableNode(node), []);

    const sortedNodes = useMemo(() => scene
        .getNodes()
        .filter((n) => n !== node
              && !children.has(n)
              && isAvailableNode(n)
              && (!nodeHasSkeleton || !isTrackingNodeHasSkeleton))
        .sort((a, b) => {
            const compareRes = getNodeOrder(a) - getNodeOrder(b);
            if (compareRes === 0) {
                return (a.name || "no name").localeCompare(b.name || "no name");
            }
            return compareRes;
        }), [ nodeHasSkeleton, isTrackingNodeHasSkeleton, children ]);

    const nodeOptions = useMemo(() => [ { label: "None", value: -1 }, ...sortedNodes.map((m, i) => ({
        label:      m.name || "no name",
        iconConfig: getTreeIcon(m),
        value:      i
    })) ], [ sortedNodes ]);

    return (
        <>
            { node.parent && (
                <TextLineComponent
                    label="Link to parent"
                    value={ node.parent.name }
                    onLink={ () => props.globalState.onSelectionChangedObservable.notifyObservers(node.parent) }
                />
            ) }
            { isCurrentNodeAvailableToChangeParent && (
                <OptionsLineComponent
                    key={ nodeOptions.length }
                    label="Parent"
                    options={ nodeOptions }
                    target={ node }
                    propertyName="parent"
                    noDirectUpdate
                    onSelect={ (value: number) => {
                        const newParent = sortedNodes[ value ];
                        const changeParentCommand = new SetNodeParentCommand(sceneManager?.sceneRenderer, node, newParent);
                        sceneManager?.commander.executeCommand(changeParentCommand);
                    } }
                    extractValue={ () => (node.parent ? sortedNodes.indexOf(node.parent) : -1) }
                    border={ props.border }
                />
            ) }
        </>
    );
});
