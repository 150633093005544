import React, { ForwardedRef, forwardRef, useCallback } from 'react';
import { cn, Wrapper }                                  from '@geenee/ui';
import { observer }                                     from 'mobx-react';
import { useBuilderInject }                             from '@geenee/builder/src/core/hook/use-builder-inject';
import { DropHere }                                     from '../../../../components/DropHere';
import { DropWrapper }                                  from '../../../../components/DropWrapper';
import { SUPPORT_FORM_ATTACHMENT_FORMATS, TOAST_ERROR } from '../../../../lib/constants';
import { SupportFile }                                  from '../useSupportRequestForm';
import { DropContent }                                  from './DropContent';
import './styles.scss';

type Props = {
  filesList: SupportFile[]
  onDrop: (files: File[])=> Promise<void>
  onRemoveFile: (id?: number)=> void
}

export const MAX_FILES_COUNT = 5;
const className = cn('drop-attachments');

const DropType = [ ...SUPPORT_FORM_ATTACHMENT_FORMATS ];
export const DropAttachments = observer(({ onDrop, filesList, onRemoveFile }: Props): JSX.Element => {
    const { BuilderState } = useBuilderInject();

    const onFilesUpload = useCallback(
        async (files: File[]) => {
            const validCount = (files.length + filesList.length) <= MAX_FILES_COUNT;
            const loadedFiles = validCount ? files : files.slice(0, MAX_FILES_COUNT - filesList.length);
            if (!validCount) {
                BuilderState.toast = {
                    severity: TOAST_ERROR,
                    detail:   `You could not upload more then ${ MAX_FILES_COUNT } files`,
                    summary:  'File limit violated'
                };
            }
            await onDrop(loadedFiles);
        },
        [ filesList.length, onDrop ]
    );

    const DropHereContent = useCallback(() => <DropContent onRemoveFile={ onRemoveFile } filesList={ filesList } />, [ filesList ]);
    const RootContainer = forwardRef((props: { children: JSX.Element }, ref: ForwardedRef<HTMLDivElement>) => (
        <Wrapper
            { ...props }
            innerRef={ ref }
        />
    ));

    return (
        <DropWrapper
            multiple
            disabled={ filesList.length > MAX_FILES_COUNT }
            onDrop={ onFilesUpload }
            fullWidth
            fileFormats={ DropType }
            rootContainer={ RootContainer }
            clickable
        >
            <DropHere
                className={ className('drop-here') }
                content={ DropHereContent }
            />
        </DropWrapper>
    );
});
