import React, { useCallback, useState }   from 'react';
import { Scrollbars }                     from 'react-custom-scrollbars';
import { DndProvider }                    from 'react-dnd';
import { HTML5Backend }                   from 'react-dnd-html5-backend';
import { LoadingSpinnerCentered }         from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { cn, Description, Icon, Wrapper } from "@geenee/ui";
import { observer }                       from 'mobx-react';
import { DropHere }                       from '~/components/DropHere';
import { DropWrapper }                    from '~/components/DropWrapper';
import { AssetCard }                      from "~/components/LibraryModalTemplates/components/AssetCard";
import { EmptySearchPage }                from "~/components/LibraryModalTemplates/components/EmptySearchPage";
import { DIComponentType }                from '~/core/context/di.context';
import { useBuilderInject }               from "~/core/hook/use-builder-inject";
import { AVAILABLE_DROP_FORMATS }         from '~/lib/constants';
import { humanFileSize }                  from '~/lib/humanFileSizes';
import { DeleteCheckbox }                 from "~/module/team-library/components/Actions/DeleteCheckbox";
import { MoreActions }                    from "~/module/team-library/components/Actions/MoreActions";
import { EditAsset }                      from "~/module/team-library/components/EditAsset";
import { TeamLibraryDialogHeader }        from '~/module/team-library/components/TeamLibraryDialogHeader';
import { useHandlers }                    from '~/module/team-library/hooks/useHandlers';
import { useReorder }                     from '~/module/team-library/hooks/useReorder';
import { TeamLibraryItemModel }           from '~/module/team-library/state/team-library-item.model';
import TeamLibraryState                   from './state/team-library.state';
import './styles.scss';

interface Props {
    onImport?: (asset: TeamLibraryItemModel) => void;
    onClose: () => void;
    hideAudio?: boolean;
    experienceSize?: number;
}

const className = cn('asset-library');

export const TeamLibrary = observer(({ onImport, onClose, hideAudio }: Props & DIComponentType) => {
    const { BuilderState } = useBuilderInject();
    const [ editingAsset, setEditingAsset ] = useState<TeamLibraryItemModel | null>(null);
    TeamLibraryState.mode = BuilderState.teamLibraryMode;
    const isEditMode = TeamLibraryState.editMode;
    const { filteredItems, storage } = TeamLibraryState;
    const { listItems, moveItem, updateListItemsOrder } = useReorder(filteredItems);
    const [ checkedItems, setCheckedItems ] = useState<Map<string, TeamLibraryItemModel>>(new Map());

    const { uploadModels, isLoading } = useHandlers();

    const onDeleteCheckedItems = () => {
        Array.from(checkedItems.values()).forEach((el) => {
            el.delete();
            checkedItems.delete(el.id);
        });
        setCheckedItems(new Map(checkedItems));
    };

    const DropHereContent = useCallback((_isLoading) => (
        <Wrapper style={ { paddingTop: '32px', paddingBottom: '32px' } } padding='xs' align='center'>
            <Icon color={ _isLoading ? "gradient-warm-1" : "shade-5" } size={ 48 } margin="sm" name="uploadCloud" />
            <Wrapper margin='xxs' align='center'>
                <Description color="shade-2" weight="medium">Team Library is Empty yet</Description>
                <Description color="shade-4" align="center">
                    Drag & drop your assets or press the “Add” button above.
                    Supported file formats: png, jpg, jpeg, gif, mp4, glb, gltf
                </Description>
            </Wrapper>
        </Wrapper>
    ), [ ]);

    const onDelete = useCallback((el: TeamLibraryItemModel) => () => {
        el.delete();
        checkedItems.delete(el.id);
        setCheckedItems(new Map(checkedItems));
    }, [ checkedItems ]);

    const onEdit = useCallback((el: TeamLibraryItemModel) => {
        setEditingAsset(el);
    }, []);

    const overlayComponent = useCallback(
        (_isLoading: boolean) => (
            <div className={ className('drop-here-container') }>
                <DropHere
                    className={ className('drop-here', { isLoading: _isLoading }) }
                    content={ () => DropHereContent(_isLoading) }
                />
            </div>
        ),
        []
    );
    return (
        <DndProvider backend={ HTML5Backend }>
            { editingAsset ? (
                <EditAsset
                    asset={ editingAsset }
                    onBackClicked={ () => {
                        setEditingAsset(null);
                    } }
                />
            ) : (
                <Wrapper style={ { minWidth: '928px' } }>
                    <TeamLibraryDialogHeader
                        onClose={ onClose }
                        onUploadModel={ async (files) => {
                            await uploadModels(files);
                        } }
                        checkedList={ checkedItems }
                        onDelete={ () => onDeleteCheckedItems() }
                        onClearSelection={ () => setCheckedItems(new Map()) }
                        onSelectAll={ () => {
                            listItems.forEach((el) => {
                                if (el.options?.readonly) return;
                                checkedItems.set(el.id, el);
                            });
                            setCheckedItems(new Map(checkedItems));
                        } }
                    />

                    <DropWrapper
                        disabled={ isLoading || !isEditMode }
                        multiple
                        onDrop={ uploadModels }
                        fileFormats={ [ ...AVAILABLE_DROP_FORMATS, 'mp3', 'json' ] }
                        overlayComponent={ !storage.length ? overlayComponent(true) : null }
                    >
                        <Wrapper>
                            <Scrollbars
                                style={ { height: '504px' } }
                                autoHide
                                autoHideTimeout={ 500 }
                                autoHideDuration={ 200 }
                            >
                                <Wrapper
                                    padding="lg"
                                    style={ { paddingBottom: '8px', paddingTop: 0 } }
                                    row
                                    fullHeight
                                    wrap
                                    className={ className('content') }
                                >
                                    { listItems.map((el, index) => (
                                        <AssetCard
                                            key={ el.id }
                                            index={ index }
                                            id={ el.id }
                                            moveItem={ moveItem }
                                            mediaClassName={ className('image-card') }
                                            updateListItemsOrder={ updateListItemsOrder }
                                            order={ el.attachment.options?.order || 0 }
                                            descriptionData={ {
                                                Title:         el.title,
                                                Size:          humanFileSize(el.attachment.file_size, true),
                                                'Posted by':   el.posted_by || 'Username',
                                                'Upload date': new Date(el.attachment.inserted_at || '11/11/20').toLocaleDateString()
                                            } }
                                            onClick={ () => {
                                                if (hideAudio && el.attachment.url.toLowerCase().endsWith('.mp3')) {
                                                    return;
                                                }
                                                onImport && onImport(el);
                                            } }
                                            url={ el.thumbnail?.url || '' }
                                            actions={ !el.options?.readonly ? (
                                                <>
                                                    <DeleteCheckbox
                                                        checked={ checkedItems.has(el.id) }
                                                        onClick={ () => {
                                                            if (!checkedItems.has(el.id)) {
                                                                checkedItems.set(el.id, el);
                                                            } else {
                                                                checkedItems.delete(el.id);
                                                            }
                                                            setCheckedItems(new Map(checkedItems));
                                                        } }
                                                    />
                                                    <MoreActions
                                                        onEdit={ onEdit }
                                                        onDelete={ onDelete }
                                                        asset={ el }
                                                    />

                                                </>
                                            ) : <></> }
                                        />
                                    )) }
                                </Wrapper>
                                { isLoading ? (
                                    <div className={ className('loader-overlay-wrapper') }>
                                        <LoadingSpinnerCentered />
                                    </div>
                                ) : null }
                                { (!storage.length && !isLoading) && (
                                    overlayComponent(false)
                                ) }
                                { !!storage.length && !filteredItems.length && (
                                    <EmptySearchPage />
                                ) }
                            </Scrollbars>
                        </Wrapper>
                    </DropWrapper>
                </Wrapper>
            ) }
        </DndProvider>
    );
});
