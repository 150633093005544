import React, { useEffect }                 from 'react';
import { Analytics }                        from '@geenee/analytics';
import { ExperienceModel, ProjectModel }    from '@geenee/shared';
import { AnimatedWrapper, Modal, useModal } from '@geenee/ui';
import { PROGRAM_CUSTOM_CODE_UPDATED }      from '~/lib/constants';
import { container }                        from '~/magellan/di/di';
import { CodeEditor }                       from '~/module/custom-code/component/editor.component';

export const useCustomCodeModal = (entityModel: ProjectModel | ExperienceModel | undefined) => {
    if (!entityModel) {
        return null;
    }

    const analytics: Analytics = container.get('<Analytics>');

    const [ layoutShowModal, layoutHideModal ] = useModal((transformGroupProps: JSX.IntrinsicAttributes) => (

        <Modal
            className='global-modal'
            onHideModal={ () => {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                hideModal();
            } }

            // eslint-disable-next-line react/jsx-props-no-multi-spaces
            { ...transformGroupProps }
        >
            <AnimatedWrapper manual={ false }>

                <CodeEditor
                    value={ entityModel?.custom_code?.code || '' }
                    onUpdate={ (val) => {
                        if (entityModel) {
                            // @ts-ignore
                            entityModel.updateCustomCode({ custom_code: { code: val } });
                            // @ts-ignore
                            entityModel?.saveData();
                            const { id } = entityModel;
                            analytics.track(PROGRAM_CUSTOM_CODE_UPDATED, { id });
                        }
                    } }
                />

            </AnimatedWrapper>
        </Modal>
    ), [ entityModel.custom_code ]);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                layoutHideModal();
            }
        };
        window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close);
    }, []);

    const showModal = () => {
        layoutShowModal();
    };

    const hideModal = () => {
        layoutHideModal();
    };

    return [ showModal, hideModal ];
};
