// // @flow
// import * as React from 'react';
// import { SiteSectionSchemaType } from '@onepage-legacy/shared/lib/type/model/site-section';
// import { createObservableContext } from '@onepage-legacy/shared/lib/util/context/_helpers/create-observable-context';
// import { SiteModalSchemaType } from '@onepage-legacy/shared/lib/type/model/site-modal';
//
// type SectionContextObsContext = SiteSectionSchemaType | SiteModalSchemaType;
// export const SectionContext: React.Context<SectionContextObsContext> =
//   createObservableContext({} as SectionContextObsContext);

import * as React      from 'react';
import { SectionType } from "@geenee/shared/type/section.type";

export const SectionContext: React.Context<SectionType> = React.createContext({} as SectionType);
