import React           from 'react';
import { useTheme }    from 'styled-components';
import CheckboxChecked from '@geenee/builder/src/asset/icons/CheckboxChecked';
import HintNoDesigner  from '@geenee/builder/src/asset/icons/HintNoDesigner';
import Hint            from '@geenee/builder/src/components/common/Hint';
import {
    UnderlinedTableRow,
    WithHintWrapper
} from '@geenee/builder/src/pages/AllPlansPage/styles';

export function ContentTemplates() {
    const { secondaryColors } = useTheme();
    return (
        <UnderlinedTableRow>
            <td>
                <WithHintWrapper>
                    <span>Content Templates</span>
                    <Hint
                        title="No Designer Needed"
                        text="Just upload media to any of our AR and mobile web page templates."
                        iconElement={ <HintNoDesigner /> }
                    />
                </WithHintWrapper>
            </td>
            <td className="basic-plan">Limited</td>
            <td className="plan-filler" />
            <td className="studio-plan">
                { ' ' }
                <CheckboxChecked beIcon fill={ secondaryColors.green70 } />
            </td>
            <td className="plan-filler" />
            <td className="pro-plan">
                { ' ' }
                <CheckboxChecked beIcon fill={ secondaryColors.green70 } />
            </td>
        </UnderlinedTableRow>
    );
}
