import React             from "react";
import { ViewComponent } from "@geenee/geeclient-kit/src/lib/component/view/view.component";
import { observer }      from "mobx-react";
import { ContentNav }    from "./content-nav.component";

export const ExperienceViewComponent = observer(() => (
    <>
        <ContentNav />
        <ViewComponent />
    </>
));
