import styled from 'styled-components';

export const ToggleContainer = styled.div<{ selected: boolean }>`
    display: flex;
    justify-content: center;

    span {
        display: inline-flex;
        align-items: center;
        margin: 0 16px;

        &:first-child {
            font-weight: ${ ({ selected }) => (selected ? 'normal' : '700') };
        }

        &:last-child {
            font-weight: ${ ({ selected }) => (selected ? '700' : 'normal') };
        }
    }
`;

export const ToggleOn = 'ToggleOn';
export const ToggleOff = 'ToggleOff';
export const CurrentOption = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: rgb(91, 233, 213);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);

    @keyframes ${ ToggleOn } {
        from {
            margin-left: 0;
        }
        to {
            margin-left: 16px;
        }
    }

    @keyframes ${ ToggleOff } {
        from {
            margin-left: 16px;
        }
        to {
            margin-left: 0;
        }
    }

    &[toggled='true'] {
        margin-left: 16px;
        animation-name: ${ ToggleOn };
        animation-timing-function: linear;
        animation-duration: 0.1s;
    }

    &[toggled='false'] {
        margin-left: 0;
        animation-name: ${ ToggleOff };
        animation-timing-function: linear;
        animation-duration: 0.1s;
    }
`;

export const OptionRail = styled.div`
    display: inline-flex;
    background: rgba(193, 193, 193, 0.78);
    width: 36px;
    padding: 2px;
    border-radius: 18px;
    cursor: pointer;
`;
