import React, { useEffect }      from 'react';
import { ErrorOption }           from 'react-hook-form';
import { Analytics }             from '@geenee/analytics';
import GeeneeLogo                from '@geenee/assets/lib/icons/resources/GeeneeLogoGradient';
import backgroundSrc             from '@geenee/assets/lib/images/bg-frame4.png';
import { DropImageForm }         from '@geenee/builder/src/components/DropImageForm';
import { useBuilderInject }      from '@geenee/builder/src/core/hook/use-builder-inject';
import { ACCOUNT_SETUPED }       from '@geenee/builder/src/lib/constants';
import { companyUrlConstructor } from '@geenee/builder/src/pages/CompanyPage/components/CompanyPageContent';
import { useHistory }            from "@geenee/common";
import { useMobxForm }           from '@geenee/shared/src/magellan/hooks/use-mobx-form';
import {
    Button,
    Description,
    Divider,
    Form,
    FormError,
    FormItem,
    Icon,
    Input,
    InputTable,
    InputTableItem,
    Link,
    Span,
    Title,
    Wrapper
} from '@geenee/ui';
import { observer } from 'mobx-react';

const POSTFIX = '.geenee.ar';

export const SignUpCompanyInfo = observer(() => {
    const { BuilderState, container } = useBuilderInject();

    const formModel = useMobxForm('<CompanySettings>');
    const { form: { formState: { errors } } } = formModel;

    const history = useHistory();
    const isAuth = BuilderState.isAuthenticated;

    useEffect(() => {
        if (!isAuth) {
            history.push("/sign-up");
        }
    }, [ isAuth ]);

    const submitHandler = async (event: React.SyntheticEvent) => {
        try {
            const analytics: Analytics = container.get('<Analytics>');
            analytics.track(ACCOUNT_SETUPED, {
                publishingDomain: formModel.values.name,
                fileSize:         formModel.values?.logo?.size,
                fileType:         formModel.values?.logo?.type
            });

            await formModel.submit(event);

            history.push('/');
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Wrapper
            fullHeight
            align="center"
            valign="center"
            backgroundImage={ backgroundSrc }
        >
            <Wrapper align="center" margin="md">
                <GeeneeLogo width="192" height="32" />
            </Wrapper>
            <Wrapper
                maxWidth="544px"
                frame="solid-opacity-white"
                padding="xl"
                radius="xxxl"
                margin="md"
                shadow="translucency-md"
            >
                <Wrapper valign="center">
                    <Title
                        align="center"
                        size="sm"
                        weight="medium"
                        margin="md"
                        styles={ { lineHeight: 1.5 } }
                    >
                        You are in! Here are a few things to set up
                    </Title>
                    <Form
                        onSubmit={ async (e) => {
                            await submitHandler(e);
                        } }
                    >
                        <Wrapper>
                            <Wrapper>
                                <InputTable margin="xs">
                                    <InputTableItem
                                        valign="start"
                                        labelWrapperProps={ {
                                            style: {
                                                padding: '8px 0',
                                                flex:    '0.55 1 0%',
                                                border:  0
                                            }
                                        } }
                                        viewType="secondary"
                                        size="md"
                                        label="Company name"
                                    >
                                        <FormItem
                                            autoFocusDetect
                                            margin="off"
                                            errorComponent={
                                                FormError as React.FC
                                            }
                                            error={
                                                !!errors.name
                                                && errors.name.message
                                            }
                                            hasTooltipMessage={ false }
                                        >
                                            <>
                                                <Input
                                                    size="md"
                                                    fullWidth
                                                    after={ (
                                                        <Description size="md" color={ errors.name ? 'error' : 'shade-3' }>
                                                            { POSTFIX }
                                                        </Description>
                                                    ) }
                                                    { ...formModel.bind(
                                                        'name',
                                                        formModel.get('name').rules
                                                    ) }
                                                    onChange={ (e) => {
                                                      formModel.setValue(
                                                          'name',
                                                          companyUrlConstructor(e.target.value)
                                                      );
                                                    } }
                                                    style={ { paddingRight: '10px' } }
                                                    shorten
                                                    placeholder={
                                                        formModel.get('name')
                                                            .placeholder
                                                    }
                                                    error={ !!errors.name }
                                                    disabled={ formModel.isLoading }
                                                />
                                                <Span
                                                    color="shade-4"
                                                    style={ {
                                                        fontSize:   '10px',
                                                        lineHeight: '1.6'
                                                    } }
                                                    weight="medium"
                                                >
                                                    <Icon
                                                        name="info"
                                                        color="shade-4"
                                                        margin="xxs"
                                                        stroke="fat"
                                                    />
                                                    Will be a part of your domain name.
                                                    Secure it now, but you can always
                                                    change it later.
                                                </Span>
                                            </>
                                        </FormItem>
                                    </InputTableItem>
                                    <Divider margin="xs" transparent />
                                    <InputTableItem
                                        valign="start"
                                        labelWrapperProps={ { style: { padding: '8px 0' } } }
                                        ratio={ 0.55 }
                                        viewType="secondary"
                                        size="md"
                                        label="Company logo"
                                    >
                                        <Wrapper>
                                            <DropImageForm
                                                url={
                                                    formModel.values.logo
                                                    && URL.createObjectURL(
                                                        formModel.values.logo
                                                    )
                                                }
                                                onDrop={ (file) => formModel.setValue(
                                                    'logo',
                                                    file
                                                ) }
                                                onDropError={ (
                                                    error: ErrorOption
                                                ) => {
                                                    formModel.setError(
                                                        'logo',
                                                        error
                                                    );
                                                } }
                                                maxImageSize={ 2097152 } // 2MB
                                                error={
                                                    !!errors.logo
                                                    && errors.logo.message
                                                }
                                                bind={ formModel.bind(
                                                    'logo',
                                                    formModel.get('logo').rules
                                                ) }
                                                acceptedFormats={ [
                                                    'gif',
                                                    'jpg',
                                                    'png'
                                                ] }
                                                description={ (
                                                    <Description margin="xs" size="xs" weight="medium" lineHeight="md" color="shade-4">
                                                        <Span
                                                            style={ { lineHeight: '1.6' } }
                                                        >
                                                            <Icon
                                                                size={ 16 }
                                                                name="info"
                                                                color="shade-4"
                                                                margin="xxs"
                                                                stroke="fat"
                                                            />
                                                            File formats:
                                                            { ' ' }
                                                            { [
                                                                'gif',
                                                                'jpg',
                                                                'png'
                                                            ].join(', ') }
                                                            .
                                                            { ' ' }
                                                            Max size: 2MB
                                                        </Span>
                                                    </Description>
                                                ) }
                                                isColumn
                                                dropRootProps={ {
                                                    styles: {
                                                        height:   '120px',
                                                        minWidth: '296px',
                                                        maxWidth: '100%'
                                                    }
                                                } }
                                            />
                                            { errors && errors.form && (
                                                <FormError
                                                    error={ errors.form.message }
                                                />
                                            ) }
                                        </Wrapper>
                                    </InputTableItem>
                                </InputTable>
                            </Wrapper>

                            <Divider color="grey-3" margin="sm" />
                            <Wrapper row align="right">
                                <Button
                                    onClick={ submitHandler }
                                    type="submit"
                                    radius="xxl"
                                    size="lg"
                                    loading={ formModel.isLoading }
                                >
                                    START MY FIRST PROJECT
                                </Button>
                            </Wrapper>
                        </Wrapper>
                    </Form>
                </Wrapper>
            </Wrapper>
            <Description lineHeight="md">
                <Link
                    onClick={ () => history.push('/') }
                    fontSize="14px"
                    weight="medium"
                    style={ { margin: 0 } }
                >
                    Skip
                </Link>
                { ' ' }
                to add later
            </Description>
        </Wrapper>
    );
});
