/* eslint-disable max-len */
import React from 'react';

export function Help({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                d="M8.878 10.144V10.036C8.878 9.298 9.22 8.74 9.85 8.29L10.588 7.768C11.65 7.012 12.37 6.004 12.37 4.582C12.37 2.764 10.912 1 8.122 1C5.458 1 4 2.872 4 4.816C4 5.086 4.018 5.374 4.09 5.626L6.358 5.716C6.304 5.554 6.286 5.356 6.286 5.14C6.286 4.132 6.844 3.106 8.122 3.106C9.328 3.106 9.922 3.898 9.922 4.726C9.922 5.266 9.688 5.752 9.148 6.148L8.284 6.796C7.258 7.57 6.862 8.452 6.862 9.406C6.862 9.676 6.898 9.91 6.934 10.144H8.878ZM6.52 12.754C6.52 13.546 7.15 14.176 7.942 14.176C8.734 14.176 9.382 13.546 9.382 12.754C9.382 11.962 8.734 11.314 7.942 11.314C7.15 11.314 6.52 11.962 6.52 12.754Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
export function Chat({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                opacity="0.2"
                d="M9.375 0C5.86875 0 3 2.47031 3 5.57812C3 8.68594 5.86875 11.1562 9.375 11.1562C9.69375 11.1562 10.0125 11.1562 10.2516 11.0766L14.1562 12.75V9.24375C15.1125 8.2875 15.75 7.0125 15.75 5.57812C15.75 2.47031 12.8813 0 9.375 0Z"
                fill="#222225"
            />
            <path
                className="fill"
                d="M4.5 6C6.975 6 9 7.74375 9 9.9375C9 12.1313 6.975 13.875 4.5 13.875C4.275 13.875 4.05 13.875 3.88125 13.8187L1.125 15V12.525C0.45 11.85 0 10.95 0 9.9375C0 7.74375 2.025 6 4.5 6Z"
                fill="#222225"
            />
            { children }
        </svg>
    );
}
export function FaqBig({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                opacity="0.2"
                d="M8 0C3.58867 0 0 3.58867 0 8C0 12.4113 3.58867 16 8 16C12.4113 16 16 12.4113 16 8C16 3.58867 12.4113 0 8 0Z"
                fill="#222225"
            />
            <path
                className="fill"
                d="M8.49677 9.7459V9.67213C8.49677 9.16803 8.74194 8.78689 9.19355 8.47951L9.72258 8.12295C10.4839 7.60656 11 6.91803 11 5.94672C11 4.70492 9.95484 3.5 7.95484 3.5C6.04516 3.5 5 4.77869 5 6.10656C5 6.29098 5.0129 6.4877 5.06452 6.65984L6.69032 6.72131C6.65161 6.61066 6.63871 6.47541 6.63871 6.32787C6.63871 5.63934 7.03871 4.93852 7.95484 4.93852C8.81935 4.93852 9.24516 5.47951 9.24516 6.04508C9.24516 6.41393 9.07742 6.7459 8.69032 7.01639L8.07097 7.45902C7.33548 7.9877 7.05161 8.59016 7.05161 9.2418C7.05161 9.42623 7.07742 9.58607 7.10323 9.7459H8.49677ZM6.80645 11.5287C6.80645 12.0697 7.25806 12.5 7.82581 12.5C8.39355 12.5 8.85806 12.0697 8.85806 11.5287C8.85806 10.9877 8.39355 10.5451 7.82581 10.5451C7.25806 10.5451 6.80645 10.9877 6.80645 11.5287Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
export function Tutorials({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                opacity="0.2"
                d="M8 0C3.58867 0 0 3.58867 0 8C0 12.4113 3.58867 16 8 16C12.4113 16 16 12.4113 16 8C16 3.58867 12.4113 0 8 0Z"
                fill="#222225"
            />
            <path
                className="fill"
                d="M11.25 7.56699C11.5833 7.75944 11.5833 8.24056 11.25 8.43301L6.75 11.0311C6.41667 11.2235 6 10.983 6 10.5981L6 5.40192C6 5.01702 6.41667 4.77646 6.75 4.96891L11.25 7.56699Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
export function NewFeatures({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
                className="fill"
                opacity="0.2"
                y="3"
                width="14"
                height="14"
                rx="2"
                fill="#303036"
            />
            <circle
                className="fill"
                cx="13.7106"
                cy="3.78947"
                r="3.78947"
                fill="#303036"
            />
            { children }
        </svg>
    );
}

export function Support({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
            <path className="fill" fill="#222225" fillRule="evenodd" clipRule="evenodd" d="M17 0.875C14.7218 0.875 12.875 2.72183 12.875 5C12.875 7.27817 14.7218 9.125 17 9.125C19.2782 9.125 21.125 7.27817 21.125 5C21.125 2.72183 19.2782 0.875 17 0.875ZM15.125 5C15.125 3.96447 15.9645 3.125 17 3.125C18.0355 3.125 18.875 3.96447 18.875 5C18.875 6.03553 18.0355 6.875 17 6.875C15.9645 6.875 15.125 6.03553 15.125 5Z" />
            <path d="M27.5 19.625L21.5 19.625C20.8787 19.625 20.375 19.1213 20.375 18.5C20.375 17.8787 20.8787 17.375 21.5 17.375L27.5 17.375C28.1213 17.375 28.625 17.8787 28.625 18.5C28.625 19.1213 28.1213 19.625 27.5 19.625Z" className="fill" fill="#222225" />
            <path d="M27.5 25.625L21.5 25.625C20.8787 25.625 20.375 25.1213 20.375 24.5C20.375 23.8787 20.8787 23.375 21.5 23.375L27.5 23.375C28.1213 23.375 28.625 23.8787 28.625 24.5C28.625 25.1213 28.1213 25.625 27.5 25.625Z" className="fill" fill="#222225" />
            <path d="M6.5 19.625L12.5 19.625C13.1213 19.625 13.625 19.1213 13.625 18.5C13.625 17.8787 13.1213 17.375 12.5 17.375L6.5 17.375C5.87868 17.375 5.375 17.8787 5.375 18.5C5.375 19.1213 5.87868 19.625 6.5 19.625Z" className="fill" fill="#222225" />
            <path d="M6.5 25.625L12.5 25.625C13.1213 25.625 13.625 25.1213 13.625 24.5C13.625 23.8787 13.1213 23.375 12.5 23.375L6.5 23.375C5.87868 23.375 5.375 23.8787 5.375 24.5C5.375 25.1213 5.87868 25.625 6.5 25.625Z" className="fill" fill="#222225" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.50312 5.49377L14.1864 9.83541C15.3579 10.4212 16.32 11.3038 17 12.3656C17.68 11.3038 18.6421 10.4212 19.8136 9.83541L28.4969 5.49377C29.2316 5.12642 30.125 5.67857 30.125 6.5V11.375H32C32.6213 11.375 33.125 11.8787 33.125 12.5V30.5C33.125 31.1213 32.6213 31.625 32 31.625H20.364C19.4689 31.625 18.6104 31.9806 17.9775 32.6135C17.7024 32.8886 17.4149 33.1251 17 33.125C16.5838 33.1249 16.2984 32.8894 16.0225 32.6135C15.3896 31.9806 14.5311 31.625 13.636 31.625H2C1.37868 31.625 0.875 31.1213 0.875 30.5V12.5C0.875 11.8787 1.37868 11.375 2 11.375H3.875V6.5C3.875 5.67857 4.76841 5.12642 5.50312 5.49377ZM30.875 13.625V29.375H20.364C19.5862 29.375 18.825 29.5361 18.125 29.8398V16.2082C18.125 15.5879 18.2427 14.9855 18.4617 14.4277C18.8006 13.9424 19.3632 13.625 20 13.625H30.875ZM27.875 11.375H21.7656L27.875 8.32029V11.375ZM15.875 29.8398C15.175 29.5361 14.4138 29.375 13.636 29.375H3.125V13.625H14C14.6368 13.625 15.1994 13.9424 15.5383 14.4277C15.7573 14.9855 15.875 15.5879 15.875 16.2082V29.8398ZM6.125 8.32029L12.2344 11.375H6.125V8.32029Z" className="fill" fill="#222225" />
            { children }
        </svg>
    );
}

export function SDK({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="34" viewBox="0 0 28 34" fill="none">
            <path className="fill" d="M14 0.875C14.6213 0.875 15.125 1.37868 15.125 2V5.375L18.5 5.375C19.1213 5.375 19.625 5.87868 19.625 6.5C19.625 7.12132 19.1213 7.625 18.5 7.625L15.125 7.625V11C15.125 11.6213 14.6213 12.125 14 12.125C13.3787 12.125 12.875 11.6213 12.875 11V7.625L9.5 7.625C8.87868 7.625 8.375 7.12132 8.375 6.5C8.375 5.87868 8.87868 5.375 9.5 5.375L12.875 5.375V2C12.875 1.37868 13.3787 0.875 14 0.875Z" fill="#222225" />
            <path className="fill" d="M19.0195 10.0733C18.7149 10.6148 18.9069 11.3007 19.4485 11.6054L23.7053 13.9998L14.1839 19.3557C14.0697 19.4199 13.9303 19.4199 13.8162 19.3557L4.2947 13.9998L8.55155 11.6054C9.09307 11.3007 9.28513 10.6148 8.98053 10.0733C8.67592 9.53176 7.98999 9.3397 7.44846 9.64431L1.44846 13.0193C1.09422 13.2186 0.875002 13.5934 0.875002 13.9998C0.875002 14.4063 1.09422 14.7811 1.44846 14.9804L12.7131 21.3167C13.5122 21.7662 14.4879 21.7662 15.2869 21.3167L26.5515 14.9804C26.9058 14.7811 27.125 14.4063 27.125 13.9998C27.125 13.5934 26.9058 13.2186 26.5515 13.0193L20.5515 9.64431C20.01 9.3397 19.3241 9.53176 19.0195 10.0733Z" fill="#222225" />
            <path className="fill" d="M26.9805 19.4483C26.6759 18.9068 25.99 18.7147 25.4484 19.0193L15.6546 24.5283C14.6272 25.1062 13.3728 25.1062 12.3454 24.5283L2.55153 19.0193C2.01 18.7147 1.32407 18.9068 1.01946 19.4483C0.714854 19.9898 0.906914 20.6757 1.44844 20.9804L11.2423 26.4894C12.9546 27.4526 15.0454 27.4526 16.7577 26.4894L26.5515 20.9804C27.0931 20.6757 27.2851 19.9898 26.9805 19.4483Z" fill="#222225" />
            <path className="fill" d="M25.4484 25.0193C25.99 24.7147 26.6759 24.9068 26.9805 25.4483C27.2851 25.9898 27.0931 26.6757 26.5515 26.9804L16.7577 32.4894C15.0454 33.4526 12.9546 33.4526 11.2423 32.4894L1.44844 26.9804C0.906914 26.6757 0.714854 25.9898 1.01946 25.4483C1.32407 24.9068 2.01 24.7147 2.55153 25.0193L12.3454 30.5283C13.3728 31.1062 14.6272 31.1062 15.6546 30.5283L25.4484 25.0193Z" fill="#222225" />
            { children }
        </svg>
    );
}

export function Stats({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="40" viewBox="0 0 44 40" fill="none">
            <path
                className="fill"
                fill="#222225"
                d="M0.5 6C0.5 2.96243 2.96243 0.5 6 0.5H38C41.0376 0.5 43.5 2.96243 43.5 6V34C43.5 37.0376 41.0376 39.5 38 39.5H10C9.17157 39.5 8.5 38.8284 8.5 38C8.5 37.1716 9.17157 36.5 10 36.5H38C39.3807 36.5 40.5 35.3807 40.5 34V6C40.5 4.61929 39.3807 3.5 38 3.5H6C4.61929 3.5 3.5 4.61929 3.5 6V30C3.5 30.8284 2.82843 31.5 2 31.5C1.17157 31.5 0.5 30.8284 0.5 30V6Z"
            />
            <path
                className="fill"
                fill="#222225"
                d="M10 12.5C10.8284 12.5 11.5 13.1716 11.5 14V16C11.5 16.8284 10.8284 17.5 10 17.5C9.17157 17.5 8.5 16.8284 8.5 16V14C8.5 13.1716 9.17157 12.5 10 12.5Z"
            />
            <path
                className="fill"
                fill="#222225"
                d="M19.5 10C19.5 9.17157 18.8284 8.5 18 8.5C17.1716 8.5 16.5 9.17157 16.5 10V16C16.5 16.8284 17.1716 17.5 18 17.5C18.8284 17.5 19.5 16.8284 19.5 16V10Z"
            />
            <path
                className="fill"
                fill="#222225"
                d="M26 14.5C26.8284 14.5 27.5 15.1716 27.5 16V30C27.5 30.8284 26.8284 31.5 26 31.5C25.1716 31.5 24.5 30.8284 24.5 30V16C24.5 15.1716 25.1716 14.5 26 14.5Z"
            />
            <path
                className="fill"
                fill="#222225"
                d="M35.5 10C35.5 9.17157 34.8284 8.5 34 8.5C33.1716 8.5 32.5 9.17157 32.5 10V30C32.5 30.8284 33.1716 31.5 34 31.5C34.8284 31.5 35.5 30.8284 35.5 30V10Z"
            />
            <path
                className="fill"
                fill="#222225"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5 26C6.5 21.8579 9.85786 18.5 14 18.5C18.1421 18.5 21.5 21.8579 21.5 26C21.5 30.1421 18.1421 33.5 14 33.5C12.4714 33.5 11.0496 33.0427 9.86391 32.2574L5.06066 37.0607C4.47487 37.6464 3.52513 37.6464 2.93934 37.0607C2.35355 36.4749 2.35355 35.5251 2.93934 34.9393L7.7426 30.1361C6.95732 28.9504 6.5 27.5286 6.5 26ZM14 21.5C11.5147 21.5 9.5 23.5147 9.5 26C9.5 28.4853 11.5147 30.5 14 30.5C16.4853 30.5 18.5 28.4853 18.5 26C18.5 23.5147 16.4853 21.5 14 21.5Z"
            />
            { children }
        </svg>
    );
}

export function Team({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="40" viewBox="0 0 42 40" fill="none">
            <path
                className="fill"
                fill="#222225"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 0.5C8.75329 0.5 4.5 4.75329 4.5 10C4.5 14.0834 7.07627 17.565 10.6921 18.9082C4.83543 20.3834 0.5 25.6854 0.5 32V34C0.5 37.0376 2.96243 39.5 6 39.5H20C20.8284 39.5 21.5 38.8284 21.5 38C21.5 37.1716 20.8284 36.5 20 36.5H6C4.61929 36.5 3.5 35.3807 3.5 34V32C3.5 26.201 8.20101 21.5 14 21.5C17.1096 21.5 19.902 22.8499 21.8267 25.0004C22.3792 25.6177 23.3275 25.6702 23.9448 25.1177C24.5621 24.5652 24.6146 23.6169 24.0621 22.9996C22.2971 21.0276 19.9581 19.5762 17.3075 18.9084C20.9235 17.5653 23.5 14.0835 23.5 10C23.5 4.75329 19.2467 0.5 14 0.5ZM7.5 10C7.5 6.41015 10.4101 3.5 14 3.5C17.5899 3.5 20.5 6.41015 20.5 10C20.5 13.5899 17.5899 16.5 14 16.5C10.4101 16.5 7.5 13.5899 7.5 10Z"
            />
            <path
                className="fill"
                fill="#222225"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32 10.5C27.8579 10.5 24.5 13.8579 24.5 18C24.5 21.1308 26.4183 23.8135 29.1436 24.9369C25.2927 26.1494 22.5 29.7486 22.5 34C22.5 37.0376 24.9624 39.5 28 39.5H36C39.0376 39.5 41.5 37.0376 41.5 34C41.5 29.7486 38.7073 26.1494 34.8563 24.9369C37.5817 23.8135 39.5 21.1308 39.5 18C39.5 13.8579 36.1421 10.5 32 10.5ZM27.5 18C27.5 15.5147 29.5147 13.5 32 13.5C34.4853 13.5 36.5 15.5147 36.5 18C36.5 20.4853 34.4853 22.5 32 22.5C29.5147 22.5 27.5 20.4853 27.5 18ZM25.5 34C25.5 30.4101 28.4101 27.5 32 27.5C35.5899 27.5 38.5 30.4101 38.5 34C38.5 35.3807 37.3807 36.5 36 36.5H28C26.6193 36.5 25.5 35.3807 25.5 34Z"
            />
            { children }
        </svg>
    );
}
