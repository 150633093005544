import * as React                                 from "react";
import type { SpotLight }                         from "@babylonjs/core/Lights/spotLight";
import type { Observable }                        from "@babylonjs/core/Misc/observable";
import { Color3LineComponent }                    from "@geenee/geespector-ui-components/src/lines/color3LineComponent";
import { FloatLineComponent }                     from "@geenee/geespector-ui-components/src/lines/floatLineComponent";
import { LineContainerComponent }                 from "@geenee/geespector-ui-components/src/lines/lineContainerComponent";
import { Vector3LineComponent }                   from "@geenee/geespector-ui-components/src/lines/vector3LineComponent";
import type { LockObject }                        from "@geenee/geespector-ui-components/src/tabs/propertyGrids/lockObject";
import { Wrapper }                                from '@geenee/ui';
import type { GlobalState }                       from "../../../../globalState";
import type { PropertyChangedEvent }              from "../../../../propertyChangedEvent";
import { ActionTabSectionComponent }              from '../../../actionTabSectionComponent';
import { CommonLightPropertyGridComponent }       from "./commonLightPropertyGridComponent";
import { CommonShadowLightPropertyGridComponent } from "./commonShadowLightPropertyGridComponent";

interface ISpotLightPropertyGridComponentProps {
    globalState: GlobalState;
    light: SpotLight;
    lockObject: LockObject;
    onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
}

export class SpotLightPropertyGridComponent extends React.Component<ISpotLightPropertyGridComponentProps> {
    constructor(props: ISpotLightPropertyGridComponentProps) {
        super(props);
    }

    render() {
        const { light } = this.props;

        return (
            <Wrapper className="pane">
                <CommonLightPropertyGridComponent
                    globalState={ this.props.globalState }
                    lockObject={ this.props.lockObject }
                    light={ light }
                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                />
                <ActionTabSectionComponent title="SETUP" hasDivider={ false }>
                    <Color3LineComponent label="Diffuse" target={ light } propertyName="diffuse" onPropertyChangedObservable={ this.props.onPropertyChangedObservable } />
                    <Color3LineComponent label="Specular" target={ light } propertyName="specular" onPropertyChangedObservable={ this.props.onPropertyChangedObservable } />
                    <FloatLineComponent
                        lockObject={ this.props.lockObject }
                        useEuler={ this.props.globalState.onlyUseEulers }
                        label="Angle"
                        target={ light }
                        propertyName="angle"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                    <FloatLineComponent
                        lockObject={ this.props.lockObject }
                        useEuler={ this.props.globalState.onlyUseEulers }
                        label="Inner angle"
                        target={ light }
                        propertyName="innerAngle"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                    <FloatLineComponent
                        lockObject={ this.props.lockObject }
                        label="Exponent"
                        target={ light }
                        propertyName="exponent"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                    <Vector3LineComponent label="Position" target={ light } propertyName="position" onPropertyChangedObservable={ this.props.onPropertyChangedObservable } />
                    <Vector3LineComponent label="Direction" target={ light } propertyName="direction" onPropertyChangedObservable={ this.props.onPropertyChangedObservable } />
                </ActionTabSectionComponent>
                <CommonShadowLightPropertyGridComponent
                    globalState={ this.props.globalState }
                    lockObject={ this.props.lockObject }
                    light={ light }
                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                />
            </Wrapper>
        );
    }
}
