import { useContext }           from 'react';
import { Analytics }            from '@geenee/analytics';
import { useInject }            from '@geenee/shared';
import { findKey }              from 'lodash-es';
import { runInAction }          from 'mobx';
import { useGenerateUrlHelper } from '@geenee/builder/src/components/ProjectsLinkDropdown/hooks/useGenerateUrlHelper';
import { useBuilderInject }     from '@geenee/builder/src/core/hook/use-builder-inject';
import { stripeState }          from '@geenee/builder/src/core/state/stripe.state';
// import TourState                                                             from '@geenee/builder/src/core/state/tour.state';
import {
    PROGRAM_PUBLISHED, PROGRAM_REPUBLISHED, PROGRAM_UNPUBLISHED, PROJECT_PUBLISH_SUCCESS,
    PROJECT_UNPUBLISH_SUCCESS, TOAST_ERROR, TOAST_SUCCESS
} from '@geenee/builder/src/lib/constants';
import { getDIComponent }   from '@geenee/builder/src/core/context/di.get-component';
import ModalUpdaterContext from '@geenee/builder/src/lib/ModalUpdaterContext';
import { useHandlers }     from '@geenee/builder/src/pages/AllPlansPage/useHandlers';

export const usePublishProject = ({ dropdownActive, updateFullUrl }: {dropdownActive, updateFullUrl: (val: string)=> void}) => {
    const { AppState } = useInject();
    const { BuilderState, container } = useBuilderInject();
    const useGenerateUrlHelper = getDIComponent('UseGenerateUrlHelper');

    const analytics: Analytics = container.get('<Analytics>');
    const { getPublishLink } = useGenerateUrlHelper();
    const mainProgram = AppState.activeProject;
    const setModalOptions = useContext(ModalUpdaterContext);

    const { role } = BuilderState.currentUser.profile || { role: '' };
    // const maxProjectsReached = isMaxProjectsReached(AppState.projectsRegistry, stripeState.plan_params);
    const isPublishingRestricted = role?.toLowerCase() === 'editor' || (stripeState.status !== 'active');

    const { showPaymentModal } = useHandlers('month', setModalOptions);

    const getAnalyticEventName = (prevValue, newValue) => {
        if (!prevValue && newValue) {
            return PROGRAM_PUBLISHED;
        }
        if (prevValue && newValue) {
            return PROGRAM_REPUBLISHED;
        }
        if (!prevValue && !newValue) {
            return PROGRAM_UNPUBLISHED;
        }

        return undefined;
    };

    const togglePublishProject = async (published?: boolean) => {
        // Disable Editor role from publishing action
        if (isPublishingRestricted) {
            const properPlanForInterval = findKey(stripeState.prices, { name: 'Premium', period: 'month' });
            showPaymentModal(stripeState.plan_price_id || properPlanForInterval);
            return;
        }

        try {
            const link = await getPublishLink(published);

            const { id, title, nft_published } = mainProgram || {};

            analytics.track(getAnalyticEventName(published, mainProgram?.published), { id, title, nft_published });

            runInAction(() => {
                BuilderState.toast = {
                    severity: TOAST_SUCCESS,
                    detail:   mainProgram?.published
                        ? PROJECT_PUBLISH_SUCCESS
                        : PROJECT_UNPUBLISH_SUCCESS,
                    summary: ''
                };
            });

            if (!dropdownActive) {
                updateFullUrl(link);
            }
        } catch (e: Error | any) {
            let message = e?.message;
            if (e?.cause?.status === 422) {
                message = 'Two programs have same name. Please change program name to proceed with publishing.';
            }

            runInAction(() => {
                BuilderState.toast = {
                    severity: TOAST_ERROR,
                    detail:   message,
                    summary:  ''
                };
            });
        }
    };

    return { togglePublishProject };
};
