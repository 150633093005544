import React                    from 'react';
import { ProjectViewComponent } from "@geenee/geeclient-kit/src/lib/component/project/project-view.component";
import { DIContextType }        from "@geenee/geeclient-kit/src/lib/context/di.context";
import { withDIContext }        from "@geenee/geeclient-kit/src/lib/hoc/with-di-context";
import { withProjectContext }   from "@geenee/geeclient-kit/src/lib/hoc/withProjectContext";
import { observer }             from "mobx-react";

type PropsType = {
  diContext: DIContextType
}

export const ProjectComponent =  withProjectContext(withDIContext<PropsType>(observer((props) => {
    const { ProjectWrapperComponent } = props.diContext;

    return (
        <ProjectWrapperComponent { ...props }>
            <ProjectViewComponent { ...props } />
        </ProjectWrapperComponent>
    );
})));
