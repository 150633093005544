import { HttpClient } from '@geenee/shared';
import {
    FlowCodeParams,
    generateParameters
} from '@geenee/builder/src/components/ProjectsLinkDropdown/components/FlowCode/components/FlowCodeHelper';
import { container } from '@geenee/builder/src/magellan/di/di';

export const generateQRCodeApi = async (params: FlowCodeParams, mainColor: string) => {
    const httpClient: HttpClient = container.get('<HttpClient>');
    const data = generateParameters(params, mainColor);
    return httpClient.get(
        'https://generator.flowcode.com/v1/flowcode',
        {
            headers: {
                accept:
                // eslint-disable-next-line max-len
                    'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
            },
            params: { ...data }
        }
    );
};
