import React, { useEffect, useMemo, useRef } from 'react';
import {
    Navigate,
    Route,
    Routes
} from "react-router";
import { BugReportComponent } from '@geenee/builder/src/components/BugReportComponent';
import Modal                  from '@geenee/builder/src/components/Modal';
import { useBuilderInject }   from '@geenee/builder/src/core/hook/use-builder-inject';
import { stripeState }        from '@geenee/builder/src/core/state/stripe.state';
import { cmsDI }              from '@geenee/builder/src/magellan/di/components-lib';
import { getRoutes }          from '@geenee/builder/src/pages/routes';
import GlobalStyles           from '@geenee/builder/src/styles/globalStyles';
//
import { DI }                 from '@geenee/geeclient-kit/src/lib/context/di.context';
import { SUBSCRIPTION_ERROR } from '@geenee/shared/src/util/errorCodes';
import { ToastComponent }     from '@geenee/ui';
import { observer }           from 'mobx-react';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
// const GeespectorScene = React.lazy(() => import('geespector-scene/Module'));

const App = observer(() => {
    const {
        BuilderState,
        getDIComponent
    } = useBuilderInject();

    const { daysLeft } = stripeState;

    const isInitialized = BuilderState.isInitialized && !!BuilderState.currentUser.profile;
    const isAuth = BuilderState.isAuthenticated;

    const builderInit = async () => {
        try {
            if (isAuth && !isInitialized) {
                await BuilderState.init();
            }
        } catch (e) {
            // @ts-ignore
            if (e.code === SUBSCRIPTION_ERROR) {
                console.error("Subscription error");
            }
        }
    };

    useEffect(() => {
        builderInit();
    }, [ isAuth ]);

    const { toast } = BuilderState;
    const toastRef = useRef<any>();

    useEffect(() => {
        if (toast.severity.length) {
            toastRef?.current?.show(toast);
        }
    }, [ toast ]);

    const AppRoutes = useMemo(
        () => getRoutes(getDIComponent, daysLeft <= 0),
        [ daysLeft ]
    );

    return (
        <DI.Provider value={ cmsDI }>
            <GlobalStyles />
            <div className="main-app">
                { /* <ModalProvider rootComponent={ TransitionGroup }> */ }
                <Modal />

                { /* todo: are we need this?  */ }
                <BugReportComponent />

                { /* <Tour /> */ }
                <Routes>
                    <Route
                        path="/"
                        element={ <Navigate to="/getting-started" /> }
                    />

                    { AppRoutes.map(
                        (
                            { path, routes, render: Component },
                            index
                        ) => (
                            <Route
                                key={ index }
                                path={ path }
                                element={ <Component { ...routes ? { routes } : null } /> }
                            />
                        )
                    ) }
                </Routes>
                <ToastComponent
                    position={ BuilderState.toastPosition }
                    toast={ BuilderState.toast }
                />
                { /* </ModalProvider> */ }
            </div>
        </DI.Provider>
    );
});

// export default hot(App);
// eslint-disable-next-line arca/no-default-export
export default App;
