import React, { useCallback, useRef, useState } from 'react';
import { ChromePicker }                         from 'react-color';
import { cn, Wrapper  }                         from '@geenee/ui';
import isEmpty                                  from 'lodash-es/isEmpty';
import { useTheme }                             from 'styled-components';
import { rgbaObjectToHexA }                     from '@geenee/builder/src/lib/colorConverter';
import useOnClickOutside                        from '@geenee/builder/src/lib/useOnClickOutside';
import './styles.scss';

type Color = import('react-color').Color;
type ColorResult = import('react-color').ColorResult;

type ColorPickerProps = {
    // hexa
  color: string;
  onColorChange: (color: string) => void;
  disableAlpha?: boolean;
  disabled?:boolean
    buttonMargin?: 'sm' | 'md' | 'lg' | 'off' | 'xxs' | 'xs' | 'xl' | 'xxl',
    children?: JSX.Element | JSX.Element[],
};

type colorPickerDataParams = {
    color: Color;
    initiator: EventTarget;
};

const className = cn('color-picker');

export function ColorPicker(props: ColorPickerProps) {
    const { colors } = useTheme();
    const [
        colorPickerData,
        setColorPickerData
    ] = useState<colorPickerDataParams | null>(null);
    const {
        color = '#000',
        onColorChange,
        disableAlpha = false
    } = props;
    const [ currentColor, setCurrentColor ] = useState<Color>(color);
    const pickerRef = useRef(null);

    // eslint-disable-next-line no-shadow
    const changeColor = (color: ColorResult) => {
        setCurrentColor(color.rgb);
        onColorChange(rgbaObjectToHexA(color.rgb));
    };

    const ColorPickerComponent = (
        <div className={ className('container') } ref={ pickerRef }>
            <ChromePicker
                color={ currentColor }
                onChangeComplete={ changeColor }
                disableAlpha={ disableAlpha }
            />
        </div>
    );

    const toggleColorPicker = (
        e: React.TouchEvent | React.MouseEvent
    ) => {
        if (!isEmpty(colorPickerData)) {
            setColorPickerData(null);
            return;
        }
        const colorPickerSettings = {
            color,
            initiator: e.target
        };
        setColorPickerData(colorPickerSettings);
    };

    const closeColorPicker = useCallback((e: Event) => {
        if (e.target === colorPickerData!.initiator) return;
        setColorPickerData(null);
    }, [ colorPickerData ]);

    useOnClickOutside(pickerRef, (e: Event) => closeColorPicker(e));

    return (
        <>
            { props.children ? <span onClick={ toggleColorPicker }>{ props.children }</span> : (
                <Wrapper
                    style={ {
                        backgroundColor: colors.white,
                        borderRadius:    '50%',
                        cursor:          'pointer'
                    } }
                    maxWidth="fit-content"
                    margin={ props.buttonMargin }
                >
                    <button
                        className={ className('selector-button') }
                        style={ { backgroundColor: color } }
                        onClick={ (e) => !props.disabled && toggleColorPicker(e) }
                    />
                    { /* <ColorSelectorButton
                        color={ color }
                    /> */ }
                </Wrapper>
            ) }
            { colorPickerData && (ColorPickerComponent) }
        </>
    );
}
