import { action, makeAutoObservable, observable, ObservableMap }                 from 'mobx';
import { downloadSketchFabModel, getDownloadModelUrl, getSketchFabModelByQuery } from '@geenee/builder/src/api/SketchFabApi';
import { FiltersSet }                                                            from '@geenee/builder/src/components/SketchFabImporterModal/FilterBtn';
import { SketchFabModel }                                                        from '@geenee/builder/src/core/model/SketchFabModel.model';
import { BuilderState }                                                          from '@geenee/builder/src/core/state/builder.state';
import { TOAST_ERROR }                                                           from '@geenee/builder/src/lib/constants';
import { convertGltfToGlb }                                                      from '@geenee/builder/src/lib/convertGltfToGlb';
import { extractFilesFromZip }                                                   from '@geenee/builder/src/lib/extractFilesFromZip';

export const getSketchfabStoreKey = (
    query = '',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    page = 0,
    sort = '',
    filters: FiltersSet = {}
) => JSON.stringify({ query: query.toLowerCase(), sort, filters, page });

class SketchfabState {
    @observable fetchedQueryModels = new ObservableMap<string, SketchFabModel[]>();
    @observable hasNextStore = new ObservableMap<string, boolean>();
    @observable isNextPageAvailable = false;
    @observable isLoading = false;
    @observable lastQuery: {query?: string; page?: number; sort?: string; filters?: any} = {};
    @observable mode: 'geenee' | 'default' = 'default';

    constructor() {
        makeAutoObservable(this);
    }

    @action updateMode(mode: 'geenee' | 'default') {
        this.mode = mode;
    }
    openAuthoriseUrl = async () => {
        const currentUrl = window.location.origin;
        window.open(
            // eslint-disable-next-line max-len
            `https://sketchfab.com/oauth2/authorize/?response_type=token&client_id=rOz8md2y5HTMYNco3JCPs8LSdZk1aRjtEroXQNGd&redirect_uri=${ currentUrl }/auth/sketchfab/callback&state=${ window.location.href }`,
            '_self'
        );
    };

    setAccessToken = (token: string) => {
        localStorage.setItem('sketchfab_access_token', token);
    };

    @action
        getModelsByQuery = async (value: {query: string; page: number; sort: string; filters: any}, builderState?: BuilderState) => {
            const { query, page, sort, filters } = value;
            this.isLoading = true;
            const prevQueryKey = getSketchfabStoreKey(this.lastQuery.query, this.lastQuery.page, this.lastQuery.sort, this.lastQuery.filters);
            const currentQueryKey = getSketchfabStoreKey(query, page, sort, filters);
            if (prevQueryKey === currentQueryKey) {
                this.isLoading = false;
                return;
            }

            try {
                const data = await getSketchFabModelByQuery(query, page, sort, filters);
                if (data) {
                    if (this.fetchedQueryModels.get(prevQueryKey)
                      && this.lastQuery.filters === filters && this.lastQuery.sort === sort && this.lastQuery.query === query) {
                        // @ts-ignore
                        this.fetchedQueryModels.set(currentQueryKey, [ ...this.fetchedQueryModels.get(prevQueryKey), ...data.results ]);
                    } else {
                        this.fetchedQueryModels.set(currentQueryKey, data.results);
                    }
                    const pageAvailable = !!data.next;
                    this.hasNextStore.set(currentQueryKey, pageAvailable);
                    this.isNextPageAvailable = pageAvailable;
                }
                this.lastQuery = value;
                this.isLoading = false;
                return true;
            } catch (e: any) {
                if (builderState) {
                    // @ts-ignore
                    BuilderState.update('toast', {
                        severity: TOAST_ERROR,
                        // @ts-ignore
                        detail:   e.message,
                        summary:  ''
                    });
                }
                return false;
            }
        };

    @action
        clearData = async () => {
            this.fetchedQueryModels.clear();
            this.hasNextStore.clear();
            this.isNextPageAvailable = false;
            this.isLoading = false;
            this.lastQuery = {};
        };

    @action
        setLastQuery = (value: Record<string, any>) => {
            this.lastQuery = value;
        };

    @action
        setLoading = (value: boolean) => {
            this.isLoading = value;
        };

    downloadModel = async (model: SketchFabModel) => {
        try {
            const url = await getDownloadModelUrl(model.uid, this.mode === 'geenee' ? 'source' : 'gltf');
            const zippedModel = await downloadSketchFabModel(url);
            const unzippedFiles = extractFilesFromZip(zippedModel);
            let finalBuffer: any[]  = [];
            if (this.mode === 'geenee') {
                const glbFile = unzippedFiles.find((item) => item?.ext === 'glb') || { file: [] };
                finalBuffer = glbFile.file || [];
            } else {
                finalBuffer = await convertGltfToGlb(unzippedFiles);
            }
            // @ts-ignore
            return new File([ finalBuffer ], `${ model.name }.glb`);
        } catch (e: any) {
            // @ts-ignore
            if (e?.message.includes('401')) {
                localStorage.removeItem('sketchfab_access_token');
                this.openAuthoriseUrl();
            }
            console.error(e);
            return undefined;
        }
    };
}

export const sketchfabState = new SketchfabState();
