import React    from 'react';
import { Icon } from '@geenee/ui';
import {
    ModalConfirmationContent,
    ModalProps
} from "@geenee/builder/src/components/ModalConfirmationContent";
import { useModalDialogWrapper } from "@geenee/builder/src/components/ModalDialogWrapper";

export const useSDKModalDisplay = (props: ModalProps) => {
    const [ showModal, hideModal ] = useModalDialogWrapper(
        <ModalConfirmationContent { ...props } />,
        { closeTriggerElement: <Icon color="shade-4" name="closeRound" size={ 24 } /> },
        '448px'
    );

    return [ showModal, hideModal ];
};
