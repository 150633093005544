import type { TargetedAnimation } from "@babylonjs/core/Animations/animationGroup";
import type { IExplorerExtensibilityGroup } from "@babylonjs/core/Debug/debugLayer";
import { ExtensionsComponent } from "../extensionsComponent";
import * as React from "react";

interface ITargetedAnimationItemComponentProps {
    targetedAnimation: TargetedAnimation;
    extensibilityGroups?: IExplorerExtensibilityGroup[];
    onClick: () => void;
}

export class TargetedAnimationItemComponent extends React.Component<ITargetedAnimationItemComponentProps> {
    constructor(props: ITargetedAnimationItemComponentProps) {
        super(props);
    }

    render() {
        const targetedAnimation = this.props.targetedAnimation;
        return (
            <div className="targetedAnimationTools">
                {/*<TreeItemLabelComponent label={targetedAnimation.animation.name} onClick={() => this.props.onClick()} icon={faFilm} color="cornflowerblue" />*/}
                {<ExtensionsComponent target={targetedAnimation} extensibilityGroups={this.props.extensibilityGroups} />}
            </div>
        );
    }
}
