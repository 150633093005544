import React, { FC, memo } from 'react';
import { cn }              from '../../util/bem';
import { Wrapper }         from "../wrapper/wrapper.component";

export type TabsPanelPropsType = {
    children: React.ReactNode;
    tabPanelId: string;
};

const className = cn('tabs-panel');

export const TabsPanel: FC<TabsPanelPropsType> = memo((props) => (
    <Wrapper
        fullHeight
        className={ className() }
        role="tabpanel"
        id={ `tabpanel-${ props.tabPanelId }` }
        aria-labelledby={ `tab-${ props.tabPanelId }` }
        { ...props }
    >
        { props.children }
    </Wrapper>
));
