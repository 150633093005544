import React              from 'react';
import { Table, Wrapper } from '@geenee/ui';
import { observer }       from 'mobx-react';
import { ChangePlanInfo } from "./ChangePlanInfo";
import { EmptyPage }      from './EmptyPage';
import { useTableData }   from './useTableData';

export const TeamPageContent = observer(() => {
    const [ data, columns ] = useTableData();

    return (
        <Wrapper>
            <Wrapper maxWidth='fit-content' margin="xl">
                <Table
                    minWidth={ 736 }
                    noFooter
                    bodyFrame={ data.length ? 'solid-default' : undefined }
                    paddingBody="sm"
                    paddingHeader="sm"
                    data={ data }
                    columns={ columns }
                />
                { !data.length ? (
                    <EmptyPage />) : <></> }
            </Wrapper>
            { data.length ? <ChangePlanInfo />
                : <></> }
        </Wrapper>
    );
});
