import React from 'react';

// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '39',
    height = '65',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 39 65"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.75" y="0.75" width="37.5" height="63.5" rx="2.25" fill="white" stroke="black" strokeWidth="1.5" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M19.5001 32.8889C15.6648 32.8889 12.5556 29.7798 12.5556 25.9444C12.5556 22.1091 15.6648 19 19.5001 19C23.3354 19 26.4445 22.1091 26.4445 25.9444C26.4445 29.7798 23.3354 32.8889 19.5001 32.8889ZM32 45.3889V42.6111C32 38.7758 28.8909 35.6667 25.0556 35.6667H13.9444C10.1091 35.6667 7 38.7758 7 42.6111V45.3889C7 46.156 7.62183 46.7778 8.38889 46.7778C9.15595 46.7778 9.77778 46.156 9.77778 45.3889V42.6111C9.77778 40.3099 11.6433 38.4444 13.9444 38.4444H25.0556C27.3567 38.4444 29.2222 40.3099 29.2222 42.6111V45.3889C29.2222 46.156 29.844 46.7778 30.6111 46.7778C31.3782 46.7778 32 46.156 32 45.3889ZM23.6667 25.9446C23.6667 28.2458 21.8012 30.1112 19.5 30.1112C17.1988 30.1112 15.3333 28.2458 15.3333 25.9446C15.3333 23.6434 17.1988 21.7779 19.5 21.7779C21.8012 21.7779 23.6667 23.6434 23.6667 25.9446Z"
                fill="black"
            />
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="7" y="19" width="25" height="28">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    // eslint-disable-next-line max-len
                    d="M19.5001 32.8889C15.6648 32.8889 12.5556 29.7798 12.5556 25.9444C12.5556 22.1091 15.6648 19 19.5001 19C23.3354 19 26.4445 22.1091 26.4445 25.9444C26.4445 29.7798 23.3354 32.8889 19.5001 32.8889ZM32 45.3889V42.6111C32 38.7758 28.8909 35.6667 25.0556 35.6667H13.9444C10.1091 35.6667 7 38.7758 7 42.6111V45.3889C7 46.156 7.62183 46.7778 8.38889 46.7778C9.15595 46.7778 9.77778 46.156 9.77778 45.3889V42.6111C9.77778 40.3099 11.6433 38.4444 13.9444 38.4444H25.0556C27.3567 38.4444 29.2222 40.3099 29.2222 42.6111V45.3889C29.2222 46.156 29.844 46.7778 30.6111 46.7778C31.3782 46.7778 32 46.156 32 45.3889ZM23.6667 25.9446C23.6667 28.2458 21.8012 30.1112 19.5 30.1112C17.1988 30.1112 15.3333 28.2458 15.3333 25.9446C15.3333 23.6434 17.1988 21.7779 19.5 21.7779C21.8012 21.7779 23.6667 23.6434 23.6667 25.9446Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0)">
                <rect x="2.8335" y="16.2227" width="33.3333" height="33.3333" fill="black" />
            </g>
        </svg>
    );
}
