import React, { FC, memo }           from 'react';
import { cn }                        from '../../util/bem';
import { SizesUnionType }            from '../../util/global-props';
import { Button, ButtonPropsType }   from '../button/button.component';
import { Checkbox }                  from '../checkbox/checkbox.component';
import { IconPropsType }             from '../icon/icon.component';
import { Radio }                     from '../radio/radio.component';
import { Wrapper, WrapperPropsType } from '../wrapper/wrapper.component';
import './select-list.component.scss';

type SelectListItemPropsType = {
    value?: string;
    label?: React.ReactNode;
    checked?: boolean;
    onClick?: (value?: string) => void;
    icon?: IconPropsType['name'];
    disabled?: boolean;
    margin?: SizesUnionType;
    flex?: string | number;
}

export type SelectListPropsType = {
    items?: SelectListItemPropsType[];
    className?: string;
    value?: string | string[];

    viewType?: 'check' | 'radio' | 'button' | 'block' | 'transparent';

    margin?: SizesUnionType;
    size?: ButtonPropsType['size'];
    flex?: number | string;
    style?: React.CSSProperties;

    onChange?: (value?: string) => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onMouseItemEnter?: (value?: string) => void;
    onMouseItemLeave?: () => void;

    disabled?: boolean;
}

const className = cn('select-list');

export const SelectList: FC<SelectListPropsType> = memo((props) => {
    if (!props.items) return null;

    const onClickHandler = (item: SelectListItemPropsType) => {
        if (item.onClick) {
            if (item.value) {
                return item.onClick(item.value);
            }
            item.onClick('');
        }
        if (props.onChange) {
            if (item.value) {
                return props.onChange(item.value);
            }
            return props.onChange('');
        }
    };
    // @ts-ignore
    const isChecked = (item: SelectListItemPropsType) => {
        if (props.value) {
            if (typeof props.value === 'string') {
                if (props.value === item.value) return true;
            } else if (item.value && props.value.includes(item.value)) return true;
        } else if (item.checked) return true;
    };

    const itemComponent = (item: SelectListItemPropsType, index: number) => {
        const globalProps = {
            key:          index,
            className:    className('item', { active: isChecked(item) }),
            disable:      item.disabled,
            onMouseEnter: () => props.onMouseItemEnter && props.onMouseItemEnter(item.value ? item.value : undefined)
        };
        const listProps = {
            checked:  isChecked(item),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onChange: (val: any) => onClickHandler(item),
            label:    item.label,
            margin:   item.margin,
            ...globalProps
        };
        const buttonProps = {
            onClick: () => onClickHandler(item),

            flex: item.flex || 1,
            size: props.size || (props.viewType === 'block' && 'md') || 'sm',
            icon: item.icon,
            ...globalProps
        };
        switch (props.viewType) {
            case 'radio':
                return <Radio { ...listProps } />;
            case 'button':
                return (
                    <Button
                        viewType={ isChecked(item) ? 'primary' : 'secondary' }
                        { ...buttonProps }
                    >
                        { item.label }
                    </Button>
                );
            case 'block':
                return (
                    <Button
                        viewType="secondary"
                        margin="xxs"
                        { ...buttonProps }
                    >
                        { item.label }
                    </Button>
                );
            case 'transparent':
                return (
                    <Button
                        viewType={ isChecked(item) ? 'secondary' : 'transparent' }
                        margin="xxs"
                        { ...buttonProps }
                    >
                        { item.label }
                    </Button>
                );
            case 'check':
            default:
                return <Checkbox { ...listProps } />;
        }
    };

    const wrapperStyles = (): WrapperPropsType => {
        switch (props.viewType) {
            case 'button':
                return {
                    row:      true,
                    overflow: 'hidden',
                    radius:   'md'
                };
            case 'block':
            case 'transparent':
                return { row: true };
            default: return {};
        }
    };

    return (
        <Wrapper
            className={ className({ type: props.viewType }) }
            { ...wrapperStyles() }
            { ...props }
        >
            { props.items.map((item, index) => itemComponent(item, index)) }
        </Wrapper>
    );
});
