import React, { useEffect, useState } from 'react';
import { CollapsedTreeItem }          from "@geenee/geeclient-kit/src/lib/component/scene/components/CollapsedTreeItem";
import { ActionsList }                from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/LayersChevronPanel/Actions";
import { AssetTitle }                 from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/LayersChevronPanel/AssetTitle";
import { useInject }                  from "@geenee/shared/src/hook/use-inject.hook";
import { Icon }                       from '@geenee/ui';
import { observer }                   from "mobx-react";

interface Props {
    disabled?: boolean
}

export const AudioRow = observer(({ disabled = false }: Props) => {
    const [ audioObject, setAudioObject ] = useState<HTMLAudioElement | null>(null);
    const { AppState } = useInject();
    const { activeMolecule } = AppState;
    const audioAtom = activeMolecule?.audioAtom;

    useEffect(() => {
        if (audioAtom?.firstAsset?.audioUrl) {
            setAudioObject(new Audio(audioAtom?.firstAsset?.audioUrl));
        } else {
            setAudioObject(null);
        }
    }, [ audioAtom?.firstAsset?.audioUrl ]);

    useEffect(() => {
        if (audioAtom?.is_audio_playing) {
            audioObject?.play();
        } else {
            audioObject?.pause();
        }
    }, [ audioAtom?.is_audio_playing ]);

    if (!audioObject) {
        return <></>;
    }
    return (
        <CollapsedTreeItem
            isSelected={ false }
            icon={ <Icon margin="xxs" stroke="fat" color="shade-4" name="media" /> }
            content={ (
                <AssetTitle
                    disabled={ disabled }
                    value={ audioAtom?.firstAsset?.options.audio_name || '' }
                    currentModel={ audioAtom }
                    after={ (
                        <ActionsList
                            currentModel={ audioAtom }
                            popoverSchema="audio"
                            enablePlayAudio
                        />
                    ) }
                />
            ) }
        />
    );
});
