import React                                          from 'react';
import { useHistory }                                 from "@geenee/common";
import { useParams }                                  from "react-router-dom";
import { MoleculeComponent }                          from '@geenee/geeclient-kit/src';
import { defaultOptions, NavigationMoleculeOptType  } from '@geenee/geeclient-kit/src/lib/default-options/molecule/Navigation';
import { CombinationMoleculeSchemaType }              from '@geenee/geeclient-kit/src/lib/type/section-molecule';
import { AtomModel }                                  from "@geenee/shared/src/magellan/atom/model/atom.model";
import { RouteParams }                                from "@geenee/shared/type/routeParams.type";
import { observer }                                   from 'mobx-react';
import { getMoleculeSettings, useContentNavHelper }   from './use-content-nav-helper';

export const ContentNav = observer(() => {
    const history = useHistory();
    const {
        projectId,
        experienceId
    } = useParams<RouteParams>();

    const {
        atoms,
        showContentNav,
        moleculeSchemaType
    } = useContentNavHelper();

    const moleculeSchema: CombinationMoleculeSchemaType<NavigationMoleculeOptType> = {
        atoms,
        // @ts-ignore
        options: {
            ...defaultOptions,
            ...getMoleculeSettings(moleculeSchemaType),
            onItemClick: (item: AtomModel) => {
                if (item.type === "overlay") {
                    history.push(
                        // TODO: GOSH... MOVE THIS OPTION TO SECTION!!!
                        // TODO: WTF!
                        `/${ projectId }/${ experienceId }/${ item.id }`
                    );
                } else {
                    history.push(
                        `/${ projectId }/${ item.experience_id }/${ item.view_id }/${ item.id }`
                    );
                }
            }
        }
    };

    return showContentNav ? (
        <section
            id="content-nav"
            data-color-root="true"
            data-color-bg="black"
            style={ {
                display:  "flex",
                position: "absolute",
                top:      0,
                right:    0,
                bottom:   0,
                height:   "fit-content",
                zIndex:   3
            } }
        >
            <MoleculeComponent
                kitMolecule="Navigation"
                moleculeSchema={ moleculeSchema }
            />
        </section>
    )
        : null;
});
