import React from "react";
import { cn } from "../../util/bem";
import { TextColorType, WeightType } from "../../util/global-props";
import "./link.styles.scss";

interface IProps {
    id?: string;
    onClick?: () => void;
    children?: JSX.Element | JSX.Element[] | string;
    fontSize?: string;
    style?: React.CSSProperties;
    ellipsis?: boolean;
    color?: TextColorType;
    weight?: WeightType;
}

const className = cn("link");

export function Link({
    onClick,
    children,
    fontSize,
    style,
    id = "",
    ellipsis,
    color,
    weight
}: IProps) {
    return (
        <a
            className={ className("root", {
                ellipsis,
                color,
                weight
            }) }
            id={ id }
            onClick={ onClick }
            style={ style ? {
                ...style,
                fontSize
            } : { fontSize } }
        >
            <p
                className="link-component"
            >
                { children }
            </p>
        </a>
    );
}
