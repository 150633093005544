import React                                                  from 'react';
import { useHistory }                                         from "@geenee/common";
import useComposerLocationParser                              from '@geenee/shared/src/util/useComposerLocationParser';
import classnames                                             from 'classnames';
import { useTheme }                                           from 'styled-components';
import Dashboard                                              from '@geenee/builder/src/asset/icons/Dashboard';
import { getDIComponent }                                     from "@geenee/builder/src/core/context/di.get-component";
import { DashboardWrapper, NodeControl, NodeLine, NodeTitle } from './styles';

export type HomeNodePropsType = {};

export function HomeNode(props: HomeNodePropsType) {
    const { currentUrl } = useComposerLocationParser();
    const history = useHistory();
    const { secondaryColors } = useTheme();
    const AddFolder = getDIComponent('AddFolder');

    return (
        <DashboardWrapper>
            <NodeLine>
                <NodeControl>
                    <Dashboard
                        width="14"
                        height="14"
                        fill={
                            currentUrl === "/getting-started"
                                ? secondaryColors.pink60
                                : "none"
                        }
                    />
                </NodeControl>
                <NodeTitle
                    className={ classnames({ matchPath: currentUrl === "/getting-started" }) }
                    onClick={ () => history.push("/getting-started") }
                >
                    Home
                </NodeTitle>
                <AddFolder />
            </NodeLine>
        </DashboardWrapper>
    );
}
