import React, { useMemo, useState }            from 'react';
import { Wrapper }                             from '@geenee/ui';
import { observer, useLocalObservable }        from "mobx-react";
import { ActionType, ButtonWithPortalTooltip } from "@geenee/builder/src/pages/SDKAccessPage/components/ButtonWithPortalTooltip";

export const SDKTableActions = observer(({
    onUpdate,
    onRemove,
    onCopy,
    onShow
}: {
  onUpdate: ()=> void,
  onRemove: ()=> void,
  onCopy: ()=> void,
  onShow: (val: boolean)=> void,
}) => {
    const [ eyeOn, setEyeOn ] = useState(false);

    const tooltipTexts = useLocalObservable(() => ({
        copy:   'Copy',
        update: 'Update key',
        show:   eyeOn ? 'Hide' : 'Show',
        onChange(val: string, field: 'copy' | 'show' | 'update') {
            this[ field ] = val;
        }
    }));

    const actions: ActionType[] = useMemo(() => [ {
        tooltipText: tooltipTexts.show,
        icon:        eyeOn ? 'eyeOn' : 'eyeOff',
        onClick:     () => {
            const visible = !eyeOn;
            onShow(visible);
            tooltipTexts.onChange(visible ? 'Hide' : 'Show', 'show');
            setEyeOn(visible);
        }
    }, {
        tooltipText: tooltipTexts.copy,
        icon:        'copy',
        onClick:     () => {
            onCopy();
            tooltipTexts.onChange('Copied', 'copy');

            setTimeout(() => {
                tooltipTexts.onChange('Copy', 'copy');
            }, 2000);
        }
    }, {
        tooltipText: tooltipTexts.update,
        icon:        'edit2',
        onClick:     () => {
            onUpdate();
        }
    }, {
        icon:    'remove',
        onClick: () => {
            onRemove();
        },
        iconColor: 'error'
    } ], [ tooltipTexts.copy, tooltipTexts.show, eyeOn ]);

    return (
        <Wrapper style={ { height: 56 } } row align='right' valign='center'>
            { actions.map((action, index) => (
                <ButtonWithPortalTooltip
                    key={ `npm-button-${ index }` }
                    action={ action }
                />
            )) }
        </Wrapper>
    );
});
