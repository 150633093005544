import styled      from 'styled-components';
import { Wrapper } from '../wrapper/wrapper.component';

export const ChevronIconContainer = styled.div<{collapsed?: boolean}>`
    position: absolute;
    top: 22px;
    right: 15px;
    cursor: pointer;
    pointer-events: all;
    z-index: 2;

    svg {
        transition: transform .3s;
    }

    ${ ({ collapsed }) => (collapsed ? `svg {
    transform: rotate(-180deg);
    }` : '') }
`;

export const PanelContent = styled(Wrapper)<{ $height: string; $collapsed: boolean }>`
    transition: max-height 0.2s ease, height .2s;
    overflow: ${ ({ $collapsed }) => ($collapsed ? 'hidden' : 'visible') };
    //backdrop-filter: blur(20px);
    border-radius: 0px 0px 10px 10px;
    height: fit-content;
    padding: 5px;
    max-height: 100%;
    border: 1px solid #E6E6E6;
    z-index: 0;
    ${ ({ $collapsed, $height }) => ($collapsed ? 'max-height: 0;' : `max-height: ${ $height };`) }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        backdrop-filter: blur(7px);
        background: rgba(234, 234, 234, 0.5);
    }
`;

export const ContentWrapper = styled.div<{ padding?: string}>`
    padding: ${ ({ padding }) => padding || '16px' };
    width: 100%;
    height: 100%;
`;

export const PanelContainer = styled.div`
    margin-bottom: 1.2em;

`;
