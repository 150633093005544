import React                                from 'react';
import { CountryItem }                      from '@geenee/builder/src/components/CountriesSelector/index';
import { FlagWrapper, SelectedItemWrapper } from './styles';

export function CountryItemTemplate(item: CountryItem) {
    return (
        <SelectedItemWrapper>
            <FlagWrapper>{ item.flag }</FlagWrapper>
            <span>{ item.name }</span>
        </SelectedItemWrapper>
    );
}
