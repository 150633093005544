import React                        from "react";
import { FormLineWrapperComponent } from "@geenee/geespector/src/components/formLineWrapper/formLineWrapperComponent";
import { InputMatrix }              from "../../input-matrix/input-matrix.component";
import { camelToText }              from "./getTitleFromCamelCase";

function ArrayFieldTemplate(props) {
    const rows = [ { rowGetter: 'value' } ];
    return (
        <FormLineWrapperComponent title={ camelToText(props.title) } border>
            <InputMatrix
                rows={ rows }
                columns={ Array.from({ length: props.schema.maxItems }, (v, k) => `${ k }`) }
                target={ { value: props.formData[ props.title ] || props.schema.default.slice() } }
                getOnChange={ (row, column) => (value) => {
                    const arrayValue =  props.formData[ props.title ] || props.schema.default.slice();
                    arrayValue[ parseInt(column, 10) ] = value;
                    const newFormData = { ...props.formData };
                    newFormData[ props.title ] = arrayValue;
                    props.onChange(newFormData);
                } }
                inputSize="sm"
                labelWeight="medium"
                labelSize="sm"
            />
        </FormLineWrapperComponent>
    );
}

export default ArrayFieldTemplate;
