import React, { useState }          from 'react';
import ChevronUp                    from '@geenee/shared/asset/icons/ChevronUp';
import { cn, Description, Wrapper } from '@geenee/ui';
import { observer }                 from 'mobx-react';
import { useTheme }                 from 'styled-components';
import './styles.scss';

interface IProps {
  title: string
  description: JSX.Element
  children: JSX.Element | JSX.Element[]
  width?: number
  withoutContent?: boolean
}

const className = cn('wizard-form');

export const WizardForm = observer(({
    title, description, children, width, withoutContent
}: IProps) => {
    const { colors } = useTheme();
    const [ descExpanded, setDescExpanded ] =  useState(true);
    return (
        <>
            <Wrapper
                id="wizard_wrapper"
                radius="xl"
                padding="md"
                // style={ { maxHeight: '80%', minHeight: '200px', minWidth: '515px' } }
                style={ { minWidth: '300px', cursor: 'pointer' } }
                margin="md"
                frame="solid-dark"
                onClick={ () => {
                    setDescExpanded(!descExpanded);
                } }
            >
                <Description size="xxxl" color="light-gradient">
                    { title }
                </Description>
                <Description
                    size="md"
                    color="white"
                    className={ className('desc', { expanded: descExpanded }) }
                >
                    { description }
                </Description>
                <div
                    className={ className('chevron-wrapper', { expanded: descExpanded }) }
                >
                    <ChevronUp fill={ colors.white } />
                </div>
            </Wrapper>
            { !withoutContent ? (
                <Wrapper
                    id="wizard_content"
                    radius="xl"
                    maxWidth={ width ? `${ width }px` : '80%' }
                    padding="md"
                    // style={ { maxHeight: '80%', minHeight: '200px', minWidth: '515px' } }
                    style={ { minWidth: width ? '0px' : '344px', maxHeight: '60%' } }
                    overflow="visible"
                    frame="solid-dark"
                >
                    { children }
                </Wrapper>
            ) : <></> }
        </>
    );
});
