import * as React                              from "react";
import type { Material }                       from "@babylonjs/core/Materials/material";
import type { Observable }                     from "@babylonjs/core/Misc/observable";
import type { LockObject }                     from "@geenee/geespector-ui-components/src/tabs/propertyGrids/lockObject";
import { Wrapper }                             from '@geenee/ui';
import type { GlobalState }                    from "../../../../globalState";
import type { PropertyChangedEvent }           from "../../../../propertyChangedEvent";
import { CommonMaterialPropertyGridComponent } from "./commonMaterialPropertyGridComponent";

interface IMaterialPropertyGridComponentProps {
    globalState: GlobalState;
    material: Material;
    lockObject: LockObject;
    onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
}

export class MaterialPropertyGridComponent extends React.Component<IMaterialPropertyGridComponentProps> {
    constructor(props: IMaterialPropertyGridComponentProps) {
        super(props);
    }

    render() {
        const { material } = this.props;

        return (
            <Wrapper className="pane">
                <CommonMaterialPropertyGridComponent
                    globalState={ this.props.globalState }
                    lockObject={ this.props.lockObject }
                    material={ material }
                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                />
            </Wrapper>
        );
    }
}
