import React, { useMemo }  from 'react';
import { format, getYear } from 'date-fns';
import { Button }          from '../button/button.component';
import { Title }           from '../title/title.component';

export type DatePickerHeaderProps = {
    date: Date;
    decreaseMonth?: () => any;
    increaseMonth?: () => any;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
}

export const useDatePickerHeader = (classname: string, disable = false) => useMemo(() => {
    if (disable) {
        return function () {
            return null;
        };
    }

    return function (props: DatePickerHeaderProps) {
        return (
            <div className={ classname }>
                <Button
                    icon="arrowLeft"
                    viewType="transparent"
                    size="sm"
                    onClick={ props.decreaseMonth }
                    disabled={ props.prevMonthButtonDisabled }
                />
                <Title align="center" weight="regular" size="xs">
                    { format(props.date, 'MMMM') }
                    { ' ' }
                    { getYear(props.date) }
                </Title>
                <Button
                    icon="arrowRight"
                    viewType="transparent"
                    size="sm"
                    onClick={ props.increaseMonth }
                    disabled={ props.nextMonthButtonDisabled }
                />
            </div>
        );
    };
}, [ disable, classname ]);
