import { useContext }                                 from 'react';
import type { Container }                             from 'inversify';
import { MobXProviderContext }                        from 'mobx-react';
import type { AppState, SceneFactory, SceneRenderer } from '../..';

export interface InjectedMap {
    container: Container
    AppState: AppState
    SceneFactory: SceneFactory
    SceneModel: SceneRenderer
}

export const useInject = (): InjectedMap => useContext(MobXProviderContext) as InjectedMap;
