import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { observer, useLocalObservable }                        from "mobx-react";
import {
    FormSizesType,
    FrameExtensionUnionType,
    RadiusSizesType, ShadowExtensionUnionType,
    SizesUnionType,
    TextColorType
} from "../../util/global-props";
import { Popover }                        from "./popover.component";
import { PopoverItemType, PopoverSchema } from "./util";

export interface PopoverStyleProps {
  margin?: SizesUnionType;
  padding?: SizesUnionType;
  radius?: RadiusSizesType;
  align?: 'center' | 'right' | 'left' | 'stretch' | 'space-between' | 'space-around';
  valign?: 'top'|'center'|'bottom' | 'stretch';
  frame?: FrameExtensionUnionType;
  shadow?: ShadowExtensionUnionType;
  minWidth?: string;
  menuWidth?: string;
  color?: TextColorType
  size?: FormSizesType;
  style?: React.CSSProperties;
}

interface PopoverContainerPropsType extends PopoverStyleProps {
  popoverSchema?: PopoverSchema;
  children: React.ReactNode;
  onClose: () => void;
  onSelected?: (item: PopoverItemType, e: any) => void
}

export const PopoverContainer: FC<PopoverContainerPropsType> = observer((props: PopoverContainerPropsType) => {
    const [ opened, setOpened ] = useState(0);
    const childRef = useRef<HTMLElement>(null);

    const insidesState = useLocalObservable(() => ({
        insides: props.popoverSchema?.insides || [],
        getInsideCoordinates(containerId: string, parentId: string) {
            const inside = this.insides.find((item) => item.id === containerId);
            if (inside?.meta.coordinates) {
                return inside.meta.coordinates;
            }
            return this.setInsideCoordinates(containerId, parentId);
        },
        setInsideCoordinates(id: string, parentId: string) {
            const elem = document.getElementById(`main-popover-${ parentId }`);
            const elemClientRect = elem?.getBoundingClientRect();
            const newCoordinates = { left: 0, top: 0 };
            if (elemClientRect) {
                newCoordinates.left = elemClientRect.right;
                newCoordinates.top = elemClientRect.top;
            }
            this.updateCoordinate(id, newCoordinates);
            return newCoordinates;
        },
        removeInsidesCoordinates() {
            this.insides = this.insides.map((item) => ({ ...item, meta: { ...item.meta, coordinates: undefined } }));
        },
        deleteCoordinates(id: string) {
            this.updateCoordinate(id, undefined);
        },
        updateCoordinate(id: string, newCoordinate?: { left: number, top: number }) {
            this.insides = this.insides.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        meta: {
                            ...item.meta,
                            coordinates: newCoordinate
                        }
                    };
                }
                return item;
            }) || [];
        },
        clear() {
            this.removeInsidesCoordinates();
        }
    }));

    useEffect(() => {
        if (childRef.current && props.popoverSchema) {
            const rootCoordinates = childRef.current.getBoundingClientRect();
            props.popoverSchema.meta = { ...props.popoverSchema.meta, coordinates: { left: rootCoordinates.right, top: rootCoordinates.top } };
            insidesState.insides = props.popoverSchema.insides;
            setOpened((prev) => prev + 1);
        }
    }, [ childRef.current, props.popoverSchema ]);

    const removeInsides = (parentId: string) => {
        if (parentId === 'root') {
            insidesState.removeInsidesCoordinates();
        } else {
            insidesState.insides.forEach((inside) => {
                if (inside.parentId === parentId && inside.meta.coordinates) {
                    insidesState.deleteCoordinates(inside.id);
                }
            });
        }
    };

    const makeInsideActive = (id: string, parentId: string) => {
        if (!props.popoverSchema) {
            return;
        }

        removeInsides(parentId);
        if (id) {
            if (parentId === props.popoverSchema.id || insidesState.insides.find((inside) => inside.id === parentId)) {
                insidesState.setInsideCoordinates(id, parentId);
            }
        }
    };

    const popoverProps = { ...props, children: undefined };

    const onClose = useCallback((e: EventTarget) => {
        insidesState.clear();
        if (!childRef.current?.contains(e.srcElement)) {
            props.onClose();
        }
    }, [ childRef.current ]);

    return (
        <span ref={ childRef } style={ { width: 'fit-content', height: 'fit-content' } }>
            { props.children }
            { !!props.popoverSchema?.meta.coordinates && (
                <>
                    <Popover
                        { ...popoverProps }
                        key={ JSON.stringify(props.popoverSchema.meta.coordinates) }
                        showAndHideInside={ (id, parentId) => makeInsideActive(id, parentId) }
                        coordinates={ props.popoverSchema.meta.coordinates || { left: 0, top: 0 } }
                        closePopover={ onClose }
                    />
                    { insidesState.insides.filter((item) => item.meta.coordinates).map((inside) => {
                        const modifiedParentId = inside.parentId;
                        return (
                            <Popover
                                key={ `inside-${ inside.id }` }
                                { ...popoverProps }
                                margin="off"
                                popoverSchema={ inside }
                                showAndHideInside={ makeInsideActive }
                                coordinates={ insidesState.getInsideCoordinates(inside.id, modifiedParentId) }
                                closePopover={ onClose }
                            />
                        );
                    }) }
                </>
            ) }
        </span>
    );
});

PopoverContainer.defaultProps = {
    frame:     'solid-white',
    shadow:    'md',
    radius:    'lg',
    menuWidth: '150px',
    padding:   'xxs'
};
