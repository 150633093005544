import React                from 'react';
import { Wrapper }          from '@geenee/ui';
import { AnimatedCard }     from '../AnimatedCard';
import { useAnimatedCards } from './useAnimatedCards';

export function CardList() {
    const { cards } = useAnimatedCards();

    return (
        <Wrapper style={ { height: 'fit-content' } } row align="space-between">
            { cards.map((card, index) => <AnimatedCard key={ `home-page-card-${ index }` } { ...card } />) }
        </Wrapper>
    );
}
