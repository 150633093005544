import React                        from 'react';
import { ExperiencesSwiperWrapper } from '@geenee/geeclient-kit/src/lib/component/project/ExperiencesCarousel/ExperiencesSwiperWrapper';
import { SwiperSlide }              from 'swiper/react';
import { SwiperWrapper }            from './styles';

interface Props {
  items: string[];
}

export function PreviewCarousel(props: Props) {
    const items = props.items.map((src) => (
        <SwiperSlide key={ src }>
            { /* eslint-disable-next-line jsx-a11y/alt-text */ }
            <img src={ src } width="380px" height="204px" />
        </SwiperSlide>
    ));

    return (
        <SwiperWrapper>
            <ExperiencesSwiperWrapper>
                { items }
            </ExperiencesSwiperWrapper>
        </SwiperWrapper>
    );
}
