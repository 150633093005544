import React, { ForwardedRef, forwardRef, useCallback } from "react";
import { ErrorOption }                                  from "react-hook-form";
import { AttachmentFormatType }                         from "@geenee/builder/src/lib/constants";
import { cn, FormError, Wrapper }                       from "@geenee/ui";
import { WrapperPropsType }                             from "@geenee/ui/src/lib/component/wrapper/wrapper.component";
import { observer }                                     from "mobx-react";
import { DropWrapper }                                  from "../DropWrapper";
import { DropContent }                                  from "./DropContent";
import "./styles.scss";

type Props = {
  onDrop: (file: File) => void;
  onDropError?: (error: ErrorOption) => void;
  acceptedFormats?: AttachmentFormatType<"gif" | "png" | "jpg">[];
  maxImageSize?: number;
  url?: string;
  dropRootProps?: WrapperPropsType;
  action?: React.ReactNode;
  description?: React.ReactNode;
  bind?: any;
  clickable?: boolean;
  isColumn?: boolean;
  mediaSize?: "xl" | "xxl";
  error?: string;
};

const className = cn("drop-image-form");
export const DropImageForm = observer(
    ({
        onDrop,
        onDropError,
        acceptedFormats = [ "gif", "jpg", "png" ],
        maxImageSize,
        url,
        dropRootProps = {},
        description,
        bind,
        clickable,
        isColumn,
        mediaSize = "xl",
        action,
        error
    }: Props): JSX.Element => {
        const onFilesUpload = useCallback(
            async (file: File) => {
                await onDrop(file);
            },
            [ onDrop ]
        );

        const RootContainer = forwardRef(
            (
                props: { children: JSX.Element },
                ref: ForwardedRef<HTMLDivElement>
            ) => (
                <Wrapper
                    { ...props }
                    { ...dropRootProps }
                    radius="xxl"
                    className={ className(
                        { hasBorder: true, error: !!error },
                        null,
                        dropRootProps.className || ""
                    ) }
                    style={ {
                        ...dropRootProps.style,
                        ...(url && { outline: "none" })
                    } }
                    innerRef={ ref }
                    margin="xs"
                />
            )
        );

        return (
            <Wrapper { ...(isColumn ? { column: true } : { row: true }) }>
                <DropWrapper
                    onDrop={ (files: File[]) => files.length && onFilesUpload(files[ 0 ]) }
                    onDropError={ onDropError }
                    fullWidth
                    fileFormats={ acceptedFormats }
                    maxFileSize={ maxImageSize }
                    rootContainer={ RootContainer }
                >
                    <DropContent
                        acceptedFormats={ acceptedFormats }
                        url={ url }
                        bind={ bind }
                        mediaSize={ mediaSize }
                    />
                </DropWrapper>
                <Wrapper>
                    { description }
                    { error && <FormError error={ error } /> }
                    { action }
                </Wrapper>
            </Wrapper>
        );
    }
);
