import { HttpClient } from "@geenee/shared";
import envConfig      from "~/lib/envConfig";
import { container }  from "~/magellan/di/di";

export const createTaskApi = async (params: {
    subject: string,
    description: string,
    attachments?: { blob: Blob, fileName: string }[],
    assignee_email: string | false
}) => {
    const httpClient: HttpClient = container.get("<HttpClient>");
    const formData = new FormData();
    formData.append("subject", params.subject);
    formData.append("description", params.description);
    params.attachments?.forEach((attachment) => {
        console.log(attachment);
        formData.append(
            "attachments[]",
            attachment.blob,
            attachment.fileName
        );
    });
    params.assignee_email && formData.append("assignee_email", params.assignee_email);

    return httpClient.post(
        `${ envConfig.API_URL }/api/v0/ticket`,
        formData,
        {
            headers: {
                "Content-Type": "multipart/json; charset=utf-8; boundary=__X_PAW_BOUNDARY__",
                Accept:         "*/*"
            }
        }
    );
};
