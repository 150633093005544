import { Light }                        from "@babylonjs/core/Lights/light";
import { AbstractMesh }                 from "@babylonjs/core/Meshes/abstractMesh";
import { TransformNode }                from "@babylonjs/core/Meshes/transformNode";
import { container }                    from "@geenee/builder/src/magellan/di/di";
import { AtomModel }                    from "@geenee/builder/src/magellan/model/atom.model";
import { SectionModel }                 from "@geenee/builder/src/magellan/model/section.model";
import { BabylonRenderer }              from "@geenee/geespector/renderer/babylonjs.renderer";
import { bypassHistory }                from "@geenee/shared/src/commander/decorators";
import { AbstractCommand, CommandType } from '@geenee/shared/src/commander/types';

@bypassHistory
export class FillSceneCommand extends AbstractCommand<CommandType> {
    receiver: BabylonRenderer;
    entity!: AbstractMesh | TransformNode | Light;
    atoms: AtomModel[];
    type?: string;

    constructor(receiver: BabylonRenderer, atoms: AtomModel[], type: string) {
        super();
        this.receiver = receiver;
        this.type = type;
        this.atoms = atoms;
    }

    execute = () => {
        const AppState = container.get('<AppState>');
        const { activeSection } = AppState as {activeSection: SectionModel};
        const { activeMolecule } = activeSection || {} as SectionModel;
        activeMolecule.setLoading(true);
        this.receiver.fillTheScene(this.atoms).then(() => {
            activeMolecule.setLoading(false);
        });
    };

    revert = () => {
        this.receiver.removeReferenceHead();
        this.receiver.removeReferenceBody();

        this.receiver.removeMeshes();
    };
}
