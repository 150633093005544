const parseVideoServiceUrl = (url: string) => {
    const match = url.match(
        // eslint-disable-next-line max-len
        /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/
    );

    if (match === null) {
        return {
            type:         undefined,
            id:           undefined,
            thumbnailSrc: undefined
        };
    }

    let type;
    let thumbnailSrc;
    if (match[ 3 ].indexOf('youtu') > -1) {
        type = 'youtube';
        thumbnailSrc = `https://img.youtube.com/vi/${ match[ 6 ] }/maxresdefault.jpg`;
    } else if (match[ 3 ].indexOf('vimeo') > -1) {
        type = 'vimeo';
    }

    return {
        type,
        id: match[ 6 ],
        thumbnailSrc
    };
};

// eslint-disable-next-line arca/no-default-export
export default parseVideoServiceUrl;
