import React, { useState }        from 'react';
import { LoadingSpinnerCentered } from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { observer }               from 'mobx-react';
import { useNFTmodal }            from '@geenee/builder/src/components/modals/PostPublishingNFT/useNFTmodal';
import { FlowCode }               from '@geenee/builder/src/components/ProjectsLinkDropdown/components/FlowCode';
import { Header }                 from '@geenee/builder/src/components/ProjectsLinkDropdown/components/ProjectLinkPopup/Header';
import { ShortUrlRow }            from '@geenee/builder/src/components/ProjectsLinkDropdown/components/ProjectLinkPopup/ShortUrlRow';
import {
    CardDropdownContainer,
    DropdownWrapper
} from '@geenee/builder/src/components/ProjectsLinkDropdown/styles';
import { useBuilderInject } from '@geenee/builder/src/core/hook/use-builder-inject';

type Props = {
    dropdownActive: boolean
    fullUrl: string
    publish?: boolean
    disabled?: boolean
    publishLink: string
}
export const ProjectLinkPopup = observer(({
    dropdownActive, fullUrl, publish, disabled, publishLink
}: Props) => {
    const { BuilderState, getDIComponent } = useBuilderInject();
    const [ shortUrl, setShortUrl ] = useState('');
    const [ showNFTModal ] = useNFTmodal();
    const MintYourProject = getDIComponent('MintYourProject');

    if (!dropdownActive) {
        return <></>;
    }
    return (
        <CardDropdownContainer
            publish={ publish }
        >
            <DropdownWrapper publish={ publish }>
                { !shortUrl ? <LoadingSpinnerCentered /> : <></> }

                <div style={ { display: shortUrl ? 'block' : 'none' } }>
                    <Header publish={ publish } />
                    <ShortUrlRow shortUrl={ shortUrl } publish={ publish } />
                    { fullUrl ? (
                        <FlowCode
                            key={ fullUrl }
                            publish={ publish }
                            url={ fullUrl }
                            onShortUrlUpdate={ (url: string) => {
                                setShortUrl(url);
                            } }
                        />
                    ) : <></> }
                    { /* <CodeBlock */ }
                    { /*    idName={ idName } */ }
                    { /*    publish={ publish } */ }
                    { /*    shortUrl={ shortUrl } */ }
                    { /* /> */ }
                    {
                        BuilderState.isNftAvailable && publish ? (
                            <MintYourProject
                                publish={ publish }
                                clickHandler={ () => {
                                    showNFTModal(publishLink);
                                } }
                                disabled={ disabled }
                            />

                        ) : <></>
                    }
                </div>
            </DropdownWrapper>
        </CardDropdownContainer>
    );
});
