import React                                   from 'react';
import { useTheme }                            from 'styled-components';
import CheckboxChecked                         from '@geenee/builder/src/asset/icons/CheckboxChecked';
import CodeInject                              from '@geenee/builder/src/asset/icons/CodeInject';
import Hint                                    from '@geenee/builder/src/components/common/Hint';
import { UnderlinedTableRow, WithHintWrapper } from '@geenee/builder/src/pages/AllPlansPage/styles';

export function CodeInjection() {
    const { secondaryColors } = useTheme();
    return (
        <UnderlinedTableRow>
            <td>
                <WithHintWrapper>
                    <span>Code Injection</span>
                    { ' ' }
                    <Hint
                        title='Customize the Details'
                        text="Add code to modify the css and enhance the scenes."
                        iconElement={ <CodeInject /> }
                    />
                </WithHintWrapper>

            </td>
            <td className='basic-plan'>
                { ' ' }
                <CheckboxChecked
                    beIcon
                    fill={ secondaryColors.green70 }
                />
            </td>
            <td className='plan-filler' />
            <td className='studio-plan'>
                { ' ' }
                <CheckboxChecked
                    beIcon
                    fill={ secondaryColors.green70 }
                />
            </td>
            <td className='plan-filler' />
            <td className='pro-plan'>
                { ' ' }
                <CheckboxChecked
                    beIcon
                    fill={ secondaryColors.green70 }
                />
            </td>
        </UnderlinedTableRow>
    );
}
