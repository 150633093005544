import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '12',
    height = '12',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="6" cy="6" r="5.5" fill="white" />
            <circle cx="6" cy="6" r="5.5" fill="#D8D8D8" />
            <circle cx="6" cy="6" r="5.5" stroke="#BBBBBB" />
            <path
            // eslint-disable-next-line max-len
                d="M8.33333 3H3.66667C3.29848 3 3 3.29848 3 3.66667V8.33333C3 8.70152 3.29848 9 3.66667 9H8.33333C8.70152 9 9 8.70152 9 8.33333V3.66667C9 3.29848 8.70152 3 8.33333 3Z"
                stroke="black"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
            // eslint-disable-next-line max-len
                d="M4.83325 5.33331C5.10939 5.33331 5.33325 5.10945 5.33325 4.83331C5.33325 4.55717 5.10939 4.33331 4.83325 4.33331C4.55711 4.33331 4.33325 4.55717 4.33325 4.83331C4.33325 5.10945 4.55711 5.33331 4.83325 5.33331Z"
                stroke="black"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.99996 6.99998L7.33329 5.33331L3.66663 8.99998"
                stroke="black"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
