import Quaternion from '../math/Quaternion';
import BasePoseSensor from './BasePoseSensor';
import IPoseSensor from './IPoseSensor';

/**
 *
 */
export default class NoIMUPoseSensor extends BasePoseSensor implements IPoseSensor {

  /**
   *
   * {RelativeOrientationSensor} sensor
   */
  constructor() {
    super();
  }

  public start(): Promise<void> {
    return new Promise(resolve => {
      if (this.started) {
        resolve();
        return;
      }

      this.started = true;
      resolve();
    });
  }

  public stop() {
    this.started = false;
  }

  public getOrientation() {
    return new Quaternion();
  }
}
