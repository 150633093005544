import * as React                           from "react";
import type { IExplorerExtensibilityGroup } from "@babylonjs/core/Debug/debugLayer";
import type { TransformNode }               from "@babylonjs/core/Meshes/transformNode";
import { MoreAction }                       from "@geenee/geespector/src/components/sceneExplorer/components/moreAction";
import { ExtensionsComponent }              from "../extensionsComponent";

interface ITransformNodeItemComponentProps {
    transformNode: TransformNode;
    extensibilityGroups?: IExplorerExtensibilityGroup[];
    onClick: () => void;
}

// eslint-disable-next-line react/prefer-stateless-function
export class TransformNodeItemComponent extends React.Component<ITransformNodeItemComponentProps> {
    constructor(props: ITransformNodeItemComponentProps) {
        super(props);
    }

    override render() {
        const { transformNode } = this.props;

        return (
            <div className="transformNodeTools">
                <MoreAction
                    type="transformNode"
                    popoverSchemaType="transform-node-popover"
                    item={ this.props.transformNode }
                    disableDelete={ transformNode.metadata?.type === 'bone' }
                />
                <ExtensionsComponent target={ transformNode } extensibilityGroups={ this.props.extensibilityGroups } />
            </div>
        );
    }
}
