import { PBRMaterial }       from "@babylonjs/core/Materials/PBR/pbrMaterial";
import { StandardMaterial }  from "@babylonjs/core/Materials/standardMaterial";
import type { BaseTexture }  from "@babylonjs/core/Materials/Textures/baseTexture.js";
import type { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";

export const getTexturesForMesh = (mesh: AbstractMesh): BaseTexture[] => {
    const textures: (BaseTexture | null)[] = [];
    const material = mesh?.material;
    if (!material) {
        return [];
    }
    if (material instanceof PBRMaterial) {
        textures.push(material.albedoTexture);
        textures.push(material.bumpTexture);
        textures.push(material.ambientTexture);
        textures.push(material.opacityTexture);
        textures.push(material.emissiveTexture);
        textures.push(material.metallicTexture);
        textures.push(material.reflectionTexture);
        textures.push(material.refractionTexture);
        textures.push(material.microSurfaceTexture);
        textures.push(material.reflectivityTexture);
        textures.push(material.ambientTexture);
        textures.push(material.lightmapTexture);
    } else if (material instanceof StandardMaterial) {
        textures.push(material.diffuseTexture);
        textures.push(material.bumpTexture);
        textures.push(material.ambientTexture);
        textures.push(material.opacityTexture);
        textures.push(material.emissiveTexture);
        textures.push(material.reflectionTexture);
        textures.push(material.refractionTexture);
        textures.push(material.ambientTexture);
        textures.push(material.lightmapTexture);
    }
    return textures.filter((el) => el) as BaseTexture[];
};
