import React, { useMemo, useState }               from "react";
import { Column, DataType, Description, Wrapper } from "@geenee/ui";
import { useBuilderInject }                       from "@geenee/builder/src/core/hook/use-builder-inject";
import { NpmTableActions }                        from "@geenee/builder/src/pages/SDKAccessPage/components/NpmTable/NpmTableActions";
import { encodeKey }                              from "@geenee/builder/src/pages/SDKAccessPage/components/useTableData";

export const useNpmTable = () => {
    const { BuilderState } = useBuilderInject();
    const { npm_key } = BuilderState.$accountState;
    const [ openEye, setOpenEye ] = useState(false);
    const data = useMemo(() => {
        const cells: Array<{ [key: string]: React.ReactNode }> = [];
        cells.push({
            origin: (
                <Wrapper valign='center' style={ { height: '56px' } }>
                    <Description style={ { height: '20px' } } shorten>NPM token</Description>
                </Wrapper>
            ),
            key: (
                <Wrapper valign='center' style={ { height: '56px' } }>
                    <Description weight="bold">{ openEye ? npm_key : encodeKey(npm_key) }</Description>
                </Wrapper>
            ),
            actions: (
                <NpmTableActions
                    onCopy={ () => navigator.clipboard.writeText(npm_key).catch((e) => console.log(e)) }
                    onShow={ () => setOpenEye(!openEye) }
                />
            )
        });

        return cells;
    }, [ openEye ]);

    const columns: Column<DataType>[] = useMemo(() => [
        {
            accessor: 'origin',
            minWidth: 240,
            Cell:     ({ cell: { value } }) => <Wrapper key={ value }>{ value }</Wrapper>
        },
        {
            accessor: 'key',
            minWidth: 344,
            Cell:     ({ cell: { value } }) => <Wrapper key={ value }>{ value }</Wrapper>
        },
        {
            accessor: 'actions',
            minWidth: 216,
            Cell:     ({ cell: { value } }) => <Wrapper key={ value }>{ value }</Wrapper>
        }
    ], []);
    return [ data, columns ];
};
