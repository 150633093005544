import React                        from "react";
import { FormLineWrapperComponent } from "@geenee/geespector/src/components/formLineWrapper/formLineWrapperComponent";
import { Input }                    from '@geenee/ui';
import { FormItem }                 from "../../form-item/form-item.component";
import { camelToText }              from "./getTitleFromCamelCase";
import { getError }                 from "./utils";

function BaseInput(props) {
    const {
        id, required, schema, value, onChange, rawErrors, label
    } = props;
    return (
        <FormLineWrapperComponent title={ camelToText(label) } border>
            <FormItem margin="off" autoFocusDetect error={ !!(rawErrors && rawErrors.length) && getError(rawErrors) }>
                <Input
                    id={ id }
                    error={ !!(rawErrors && rawErrors.length) }
                    disabled={ schema.disabled }
                    placeholder={ schema.title }
                    required={ required }
                    value={ value || "" }
                    onChange={ (e) => onChange(e.target.value || null) }
                    prefix={ schema.prefix }
                    suffix={ schema.suffix }
                    fullWidth
                    size='md'
                    type={ schema.type }
                    min={ schema.minimum }
                    max={ schema.maximum }
                    defaultValue={ schema.default }
                />
            </FormItem>
        </FormLineWrapperComponent>
    );
}

export default BaseInput;
