import React           from 'react';
import { useTheme }    from 'styled-components';
import CheckboxChecked from '@geenee/builder/src/asset/icons/CheckboxChecked';
import HintTapToPlace  from '@geenee/builder/src/asset/icons/HintTapToPlace';
import Hint            from '@geenee/builder/src/components/common/Hint';
import {
    UnderlinedTableRow,
    WithHintWrapper
} from '@geenee/builder/src/pages/AllPlansPage/styles';

export function WorldAr() {
    const { secondaryColors } = useTheme();
    return (
        <UnderlinedTableRow>
            <td>
                <WithHintWrapper>
                    <span>World AR</span>

                    <Hint
                        title="AR Everywhere"
                        text="Build AR scenes from .glb .gif .jpg .png or .mp4
                        files that can be placed on any surface in your end-users' environment."
                        iconElement={ <HintTapToPlace /> }
                    />
                </WithHintWrapper>
            </td>
            <td className="basic-plan">
                <CheckboxChecked beIcon fill={ secondaryColors.green70 } />
            </td>
            <td className="plan-filler" />
            <td className="studio-plan">
                <CheckboxChecked beIcon fill={ secondaryColors.green70 } />
            </td>
            <td className="plan-filler" />
            <td className="pro-plan">
                { ' ' }
                <CheckboxChecked beIcon fill={ secondaryColors.green70 } />
            </td>
        </UnderlinedTableRow>
    );
}
