import styled            from 'styled-components';
import { MainContainer } from '@geenee/builder/src/pages/MainContainer';

export const AnalyticsRoot = styled(MainContainer)`
  .one-ui-tooltip {
    width: 50%;
    padding: 15px;
  }
  .gapi-view-selector-container {
    width: fit-content;
    margin-top: 15px;
    margin-bottom: 20px;
    font-family: Gordita, "Open Sans", "Helvetica Neue", sans-serif !important;
    display: flex;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    background: rgba(202, 202, 202, 0.19);
    padding: 10px;
    table {
      width: 240px !important;
      &:last-child {
        td:first-child {
          width: 40px !important;
        }
        td:last-child {
          width: 140px !important;
        }
      }
      td:first-child {
        font-weight: normal !important;
        width: 40px !important;
      }
      td:last-child {
        width: 110px !important;
        &>div:first-child {
          width: 138px;
          height: 40px;
          display: flex;
          align-items: center;
          .shr-a-shr-fc-shr-fb-shr-cb-shr-qe {
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  .analytics-auth-widgets {
    /* Custom styles */
  }
  .shr-a-shr-nc-shr-oc,.shr-a-shr-fc-shr-fb-shr-cb {
    // @ts-ignore
    background: ${ ({ theme: { colors } }) => colors.white } !important;
    border-radius: 8px;
    font-weight: normal !important;
    .shr-a-shr-nc-shr-oc,.shr-a-shr-fc-shr-fb-shr-cb-shr-pe {
      //padding: 8px !important;
    }
  }
  .graphs-root {
    display:  flex;
    flex-wrap: wrap;
    background: rgba(202, 202, 202, 0.19);
    border-radius: 10px;
    max-height: 63vh;
    overflow: auto;
  }
  .one-ui-tooltip__message-wrapper {
    font-size: 12px;
    // @ts-ignore
    background: ${ ({ theme: { colors } }) => colors.white } !important;
    // @ts-ignore
    color: ${ ({ theme: { colors } }) => colors.black } !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
`;

export const InputRowWrapper = styled.div<{isMatomo?: boolean}>`
  // @ts-ignore
  ${ ({ isMatomo }) => (isMatomo && 'min-width: 1000px;') }
  width: 100%;
  background: rgba(202, 202, 202, 0.19);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .matomo_auth_input {
    margin-left: 24px;
  }
  button {
    margin-left: 24px;
    flex-shrink: 0;
  }
`;

export const AnalyticsDescription = styled.div`
    font-size: 13px;
    margin: 20px 24px 32px 24px;
`;
