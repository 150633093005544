import React                        from 'react';
import { Scrollbars }               from 'react-custom-scrollbars';
import { Swiper }                   from '@geenee/ui';
import { Swiper as SwiperInstance } from 'swiper/react';

type Props = {
    // @ts-ignore
    onSlideChange?: (swiper: SwiperInstance) => void
    simulateTouch?: boolean
    children: JSX.Element | JSX.Element[]
}
export function ExperiencesSwiperWrapper({ onSlideChange, children, simulateTouch = true } : Props) {
    return (
        <Scrollbars
            autoHide
            autoHideTimeout={ 500 }
            autoHideDuration={ 200 }
        >
            <Swiper
                size="lg"
                fullHeight
                fullWidth
                align="center"
                arrowsAlign='top'
                overflowY="auto"
                direction="horizontal"
                loop={ false }
                navigation
                centeredSlides
                slidesPerView={ 2 }
                spaceBetween={ 70 }
                onSlideChange={ onSlideChange }
                simulateTouch={ simulateTouch }
            >
                { children }
            </Swiper>
        </Scrollbars>
    );
}
