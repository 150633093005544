import React                         from 'react';
import { NavigationMoleculeOptType } from '../default-options/molecule/Navigation';
import { MoleculeType }              from '../type/molecule';
import { SiteMoleculeSchemaType }    from '../type/section-molecule';
import { MoleculeContextBuilder }    from './molecule-context-builder';

type PropsType<T extends NavigationMoleculeOptType = NavigationMoleculeOptType> = {
    moleculeSchema: SiteMoleculeSchemaType<T>;
    kitMolecule: MoleculeType;
} & { [key: string]: unknown };

export function MoleculeComponent<T extends NavigationMoleculeOptType>(props: PropsType<T>) {
    return <MoleculeContextBuilder { ...props } molecule={ props.moleculeSchema } kitMolecule={ props.kitMolecule } />;
}
