import React, { useCallback } from 'react';
import {
    Button, Divider,
    Input,
    InputTable, InputTableItem,
    TextArea, Wrapper
} from '@geenee/ui';
import { DropdownDisplayValue }              from "@geenee/builder/src/components/DropdownDisplayValue";
import { DropAttachments }                   from './DropAttachments';
import { categories, useSupportRequestForm } from './useSupportRequestForm';

export function SupportRequestForm() {
    const {
        loading,
        state, isMainFieldsFilled, onRemoveFile,
        onChange, onSubmit, filesList, getBinaryImage
    } = useSupportRequestForm();

    const getImage = useCallback(async (files: File[]) => {
        files.forEach((file) => getBinaryImage(file));
    }, []);

    const InputTableItemStyled = useCallback(({ children, label }: { children: React.ReactNode, label: string }) => (
        <InputTableItem
            ratio={ 0.65 }
            size="lg"
            label={ label }
            viewType="secondary"
            valign="base-line"
            weight="medium"
        >
            { children }
        </InputTableItem>
    ), []);
    return (
        <Wrapper frame="solid-default" maxWidth="775">
            <Wrapper padding="md" align="center" valign="center" loading={ loading }>
                <InputTable margin="sm">
                    <InputTableItemStyled label="Your name *">
                        <Input
                            size="md"
                            fullWidth
                            onChange={ (e) => onChange(e.target.value, 'name') }
                            value={ state.name }
                            placeholder="Enter your name"
                        />
                    </InputTableItemStyled>
                    <InputTableItemStyled label="Category *">
                        <DropdownDisplayValue
                            items={ categories }
                            value={ state.category.value }
                            onSelect={ (_val, itemId) => {
                                onChange(categories[ itemId ], 'category');
                            } }
                            defaultLabel="Select category"
                        />
                    </InputTableItemStyled>

                    <InputTableItemStyled label="Message *">
                        <TextArea
                            size="md"
                            value={ state.content }
                            placeholder="Type in your request"
                            onChange={ (e) => onChange(e.target.value, 'content') }
                        />
                    </InputTableItemStyled>

                    <InputTableItemStyled label="Attach files">
                        <DropAttachments
                            onRemoveFile={ onRemoveFile }
                            filesList={ filesList }
                            onDrop={ getImage }
                        />
                    </InputTableItemStyled>
                </InputTable>
                <Divider color="grey-3" margin="sm" />

                <Wrapper row align="right">
                    <Button
                        uppercase
                        disabled={ !isMainFieldsFilled }
                        onClick={ onSubmit }
                    >
                        Send Request
                    </Button>
                </Wrapper>
            </Wrapper>
        </Wrapper>
    );
}
