import React, { useEffect, useState } from 'react';
import { CollapseWrapper }            from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/ParametersTabTreejs/CollapseWrapper";
import { useInject }                  from '@geenee/shared/index';
import {
    Button, cn, Divider,
    InputTableItem, Popover, RGBColorPicker,
    Tooltip, Wrapper
} from '@geenee/ui';
import { observer }             from 'mobx-react';
import { RangeSliderComponent } from './RangeSliderComponent';
import './styles.scss';

const COLOR_PICKER_CONST = {
    width:            230,
    marginTop:        12,
    heightWithMargin: 296
};

const className = cn('material-panel');

export const MaterialPanel = observer(() => {
    const { SceneState } = useInject();
    const { currentAsset } = SceneState;
    const [ copyText, setCopyText ] = useState('Copy');
    const [ popoverCoordinates, setPopoverCoordinates ] = useState<{top?: number, bottom?: number, left: number}>();

    useEffect(() => {
        if (copyText === 'Copied') {
            setTimeout(() => setCopyText('Copy'), 2000);
        }
    }, [ copyText ]);

    const [ rgbValue, setRgbValue ] = useState(currentAsset?.options?.atom_text3d_material_options?.color ? {
        r: parseInt(currentAsset.options.atom_text3d_material_options.color.substring(1, 3), 16),
        g: parseInt(currentAsset.options.atom_text3d_material_options.color.substring(3, 5), 16),
        b: parseInt(currentAsset.options.atom_text3d_material_options.color.substring(5, 7), 16)
    } : { r: 0, g: 0, b: 0 });

    const onUpdate = (field: string, value: string) => {
        const materialOptions = { ...currentAsset?.options.atom_text3d_material_options, [ field ]: value };
        currentAsset?.updateOption('atom_text3d_material_options', materialOptions);
    };

    const switchExpandState = (e: React.MouseEvent) => {
        const boundingClientRect = e.currentTarget.getBoundingClientRect();
        if ((window.innerHeight - boundingClientRect.bottom) > COLOR_PICKER_CONST.heightWithMargin) {
            setPopoverCoordinates({
                top:  boundingClientRect.bottom + COLOR_PICKER_CONST.marginTop,
                left: boundingClientRect.right - COLOR_PICKER_CONST.width
            });
        } else {
            setPopoverCoordinates({
                top:  boundingClientRect.top - COLOR_PICKER_CONST.heightWithMargin,
                left: boundingClientRect.right - COLOR_PICKER_CONST.width
            });
        }
    };

    return (
        <CollapseWrapper
            collapsed={ false }
            title="TEXT SETTINGS"
            contentWrapperProps={ { padding: "xs" } }
            paddingLeft="xs"
        >
            <InputTableItem marginOff viewType="secondary" size="sm" weight="medium" label="Color">
                <Wrapper
                    className={ className('color-preview') }
                    margin="xs"
                    border="shade-5"
                    style={ { background: currentAsset?.options.atom_text3d_material_options.color } }
                    radius="lg"
                    onClick={ (e) => switchExpandState(e) }
                >
                    <Button
                        radius="lg"
                        size="xs"
                        viewType="white"
                        className={ className('picker-icon') }
                        icon="colorPicker"
                    />
                </Wrapper>
                <Tooltip
                    position="top"
                    menuWidth="80px"
                    minWidth="80px"
                    shadow
                    message={ copyText }
                >
                    <Wrapper frame='solid-white' radius='lg' maxWidth="fit-content">
                        <Button
                            onClick={ () => {
                                navigator.clipboard.writeText(currentAsset?.options?.atom_text3d_material_options.color);
                                setCopyText('Copied');
                            } }
                            size="sm"
                            icon="copy"
                            viewType="secondary"
                            radius="lg"
                        />
                    </Wrapper>
                </Tooltip>

                { popoverCoordinates && (
                    <Popover
                        closePopover={ () => {
                            setPopoverCoordinates(undefined);
                        } }
                        coordinates={ popoverCoordinates }
                    >
                        <Wrapper frame="solid-white" padding="xs" radius="xxxl">
                            <RGBColorPicker
                                onChange={ (rgbColor, hexColor) => {
                                    onUpdate('color', `#${ hexColor.slice(0, 7) }`);
                                    setRgbValue(rgbColor);
                                } }
                                rgbColor={ rgbValue }
                            />
                        </Wrapper>
                    </Popover>
                ) }
            </InputTableItem>
            <Divider style={ { height: 1, marginTop: 12, marginBottom: 12 } } color="white" />
            <RangeSliderComponent
                value={ currentAsset.options.atom_text3d_material_options.opacity }
                description="Opacity"
                onChange={ (value) => {
                    onUpdate('opacity', value);
                } }
            />
            <Divider style={ { height: 1, marginTop: 12, marginBottom: 12 } } color="white" />
            <RangeSliderComponent
                value={ currentAsset.options.atom_text3d_material_options.roughness }
                description="Roughness"
                onChange={ (value) => {
                    onUpdate('roughness', value);
                } }
            />
            <Divider style={ { height: 1, marginTop: 12, marginBottom: 12 } } color="white" />
            <RangeSliderComponent
                value={ currentAsset.options.atom_text3d_material_options.metalness }
                description="Metallic"
                onChange={ (value) => {
                    onUpdate('metalness', value);
                } }
            />
        </CollapseWrapper>
    );
});
