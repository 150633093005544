import React, { FC, useEffect, useMemo, useState } from 'react';
import { useGesture }                              from 'react-use-gesture';
import { ActionsButtons }                          from '@geenee/geeclient-kit/src/lib/component/scene/widget/components/AtionsButtons';
import { SceneLotties }                            from "@geenee/geeclient-kit/src/lib/component/scene/widget/scene-lotties";
import { useInject }                               from '@geenee/shared/index';
import { FallbackPlaceholder }                     from '@geenee/shared/src/magellan/components/FallbackPlaceholder';
import { JoyStick }                                from '@geenee/shared/src/magellan/components/JoyStick';
import { SnapShotComponent }                       from '@geenee/shared/src/magellan/components/SnapShotComponent';
import { SceneModelType }                          from '@geenee/shared/src/magellan/renderer/r3f-renderer/r3f.renderer';
import { Wrapper }                                 from '@geenee/ui';
import { observer, useLocalObservable }            from 'mobx-react';

type PropsType = {
  sceneModel: SceneModelType;
  children?: React.ReactNode;
};

interface StateType {
  pinch: { visible: boolean; shown: boolean };
  tap: { visible: boolean; shown: boolean };
  permissions: { visible: boolean; shown: boolean };
  lookAround: { visible: boolean; shown: boolean };

  set(prop: any, keyOrVal: keyof any, val: any): void;
}

export const SceneUIElements: FC<PropsType> = (observer((props) => {
    const { AppState } = useInject();

    const state: StateType = useLocalObservable(() => (
    {
        pinch: {
            visible: false,
            shown:   false
        },
        tap: {
            visible: false,
            shown:   false
        },
        permissions: {
            visible: false,
            shown:   false
        },
        lookAround: { visible: false },
        set<T extends keyof StateType>(prop: T, keyOrVal: keyof StateType[T], val: any = null) {
            try {
                if (Object.keys(this).includes(prop)) {
                    this[ prop ][ keyOrVal ] = val;
                } else {
                    // @ts-ignore
                    this[ prop ] = keyOrVal;
                }
            } catch (e) {
                // console.log(e)
            }
        }
    } as StateType
    ));

    const [ lottiesLoaded, setLottiesLoaded ] = useState(false);
    const trackingImageUrl = AppState.activeSection?.type === 'overlay'
        ? AppState.arSection?.sortedChildren[ 0 ]?.sceneTriggerAtom?.assetUrl
        : AppState.activeMolecule?.sceneTriggerAtom?.assetUrl;

    useGesture({
        onPinch: () => {
            if (state.pinch.visible) {
                state.set('pinch', 'visible', false);
            }
        }
    }, { domTarget: window });
    useEffect(() => {
        AppState.emitter.addListener('geenee-model-placed', () => {
            // state.set('pinch', 'shown', 'true');
            state.set('pinch', 'visible', true);

            setTimeout(() => {
                state.set('pinch', 'visible', false);
            }, 2000);
        });
    }, []);

    useEffect(() => {
        if (props.sceneModel.scene_experience_type === 'slam-ar' && props.sceneModel.options.motionAccessGranted) {
            state.set('tap', 'visible', true);
            props.sceneModel.$parent.emit('geenee-slam-permission-granted');
            state.set('permissions', 'visible', false);
            state.set('lookAround', 'visible', true);
        }
    }, [ props.sceneModel.options.motionAccessGranted ]);

    const [ isLoaderVisible, setLoaderVisibility ] = useState(false);

    useEffect(() => {
        const loaded = props.sceneModel.isLoaded;
        const loadingVisible = (!loaded && props.sceneModel.geeneeUI.lookAroundLoader && lottiesLoaded);

        if (loadingVisible && !isLoaderVisible) {
            setLoaderVisibility(true);
        }

        if (isLoaderVisible && !loadingVisible) {
            setLoaderVisibility(false);
        }
    }, [ props.sceneModel.isLoaded,
        state.permissions.visible,
        props.sceneModel.geeneeUI.lookAroundLoader,
        lottiesLoaded ]);

    useEffect(() => {
        if (props.sceneModel && !props.sceneModel.scene3DSettings.defaultCanvasClick) {
            props.sceneModel.$parent.emitter.addListener('geenee-model-placed', () => {
                state.set('tap', 'visible', false);
                state.set('pinch', 'visible', true);
            });
        }
    }, [ props.sceneModel, props.sceneModel.scene3DSettings.defaultCanvasClick ]);

    return (
        <Wrapper
            // TODO: should be removed
            style={ { position: 'absolute', top: 0 } }
            className="gnee-scene-ui"
            id="geenee-ui-global--wrapper"
            onTouchStart={ () => {
                state.set('pinch', 'visible', false);
            } }
            onClick={ () => {
                if (!props.sceneModel.options.modelShown && props.sceneModel.isLoaded) {
                    if (state.tap.visible) {
                        state.set('tap', 'visible', false);
                    }

                    if (state.pinch.visible) {
                        state.set('pinch', 'visible', false);
                    }
                }
            } }
        >

            { props.children }

            <ActionsButtons sceneModel={ props.sceneModel } />

            { props.sceneModel.geeneeUI.instructions && (
                <Wrapper
                    padding="md"
                    className="slam-instructions-canvas"
                    // @ts-ignore
                    id="geenee-ui-slam-instruction--wrapper"
                    style={ {
                        width:         '100%',
                        height:        '100%',
                        position:      'fixed',
                        top:           0,
                        right:         0,
                        bottom:        0,
                        left:          0,
                        zIndex:        3,
                        pointerEvents: 'none'

                    } }
                    align="center"
                    valign="center"
                >
                    <SceneLotties
                        type={ props.sceneModel.scene_experience_type }
                        lottiesLoaded={ lottiesLoaded }
                        onLoad={ () => setLottiesLoaded(true) }
                        worldArLottiesState={ state }
                        setLookAroundVisible={ (val: boolean) => state.set('lookAround', 'visible', val) }
                        isLoaderVisible={ isLoaderVisible }
                        markerArImageUrl={ trackingImageUrl }
                        sceneModel={ props.sceneModel }
                    />
                </Wrapper>
            ) }

            { props.sceneModel.geeneeUI.snapshot && (
                <SnapShotComponent
                    activeSceneModel={ props.sceneModel }
                />
            ) }

            {
                props.sceneModel.fallbackPlaceholder?.enabled && (
                    <FallbackPlaceholder activeSceneModel={ props.sceneModel } />
                )
            }

            <JoyStick sceneModel={ props.sceneModel } />

        </Wrapper>
    );
}));
