import React                        from 'react';
import { InviteModel }              from '@geenee/builder/src/core/model/invite.model';
import { UserModel }                from '@geenee/builder/src/core/model/user.model';
import { DeleteMemberModalContent } from "@geenee/builder/src/pages/MemberPage/components/DeleteMemberModalContent";
import { Icon }                     from '@geenee/ui';
import { useModalDialogWrapper }    from '../../../components/ModalDialogWrapper';

export const useDeleteMemberModal = (member: InviteModel | UserModel) => {
    const [ showModal, hideModal ] = useModalDialogWrapper(
        <DeleteMemberModalContent member={ member } />,
        { closeTriggerElement: <Icon color="shade-4" name="closeRound" size={ 24 } /> },
        '448px'
    );

    return { showModal, hideModal };
};
