import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '66',
    height = '66',
    onClick,
    id,
    fill = 'black'
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
            onClick={ onClick }
        >
            <path
            // eslint-disable-next-line max-len
                d="M46.0213 31.2058H34.7943V19.9787C34.7943 18.9922 33.9865 18.1843 32.9999 18.1843C32.0133 18.1843 31.2055 18.9921 31.2055 19.9787V31.2058H19.9784C18.9919 31.2058 18.184 32.0136 18.184 33.0002C18.184 33.9868 18.9918 34.7946 19.9784 34.7946H31.2055V46.0181C31.2055 47.0047 32.0133 47.8125 32.9999 47.8125C33.9865 47.8125 34.7943 47.0047 34.7943 46.0181V34.7946H46.0178C47.0044 34.7946 47.8122 33.9868 47.8122 33.0002C47.8157 32.0136 47.0078 31.2058 46.0212 31.2058H46.0213Z"
                fill={ fill }
            />
        </svg>
    );
}
