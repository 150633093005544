import React                from 'react';
import { LinkIcon }         from '@geenee/builder/src/components/ProjectsLinkDropdown/components/HeaderButtons/LinkIcon';
import { StyledLinkButton } from '@geenee/builder/src/components/ProjectsLinkDropdown/components/HeaderButtons/styles';

export function LinkButton({ isMobile, onClick }: {isMobile: boolean, onClick: () => void}) {
    return (
        <StyledLinkButton
            isMobile={ isMobile }
            id="link-button"
            // @ts-ignore
            onClick={ (e) => {
                e.stopPropagation();
                e.preventDefault();
                onClick();
            } }
            iconElement={ <LinkIcon /> }
        />
    );
}
