import React, { SyntheticEvent, useState } from 'react';
import { InputSearch }                     from "@geenee/builder/src/components/LibraryModalTemplates/components/FilterRow/InputSearch";
import { Wrapper }                         from '@geenee/ui';

interface Props {
  handleQueryChange: (value: string) => void;
  query: string;
}

export function UserInputRow({ handleQueryChange, query }: Props) {
    const [ inputValue, setInputValue ] = useState(query);
    const handleKeyDown = (
        e: SyntheticEvent<HTMLInputElement, KeyboardEvent>
    ) => {
        if (e.nativeEvent.key === 'Enter') {
            handleQueryChange((e.target as HTMLInputElement).value);
        }
    };
    return (
        <Wrapper
            radius="xxl"
            padding="xs"
            frame="solid-bg"
            row
            valign="center"
            align="space-between"
            style={ { fontSize: '12px' } }
        >
            <InputSearch
                value={ inputValue }
                onKeyDown={ handleKeyDown }
                onChange={ (val) => setInputValue(val) }
            />
        </Wrapper>
    );
}
