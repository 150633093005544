import React                    from 'react';
import { Description, Wrapper } from '@geenee/ui';
import StyledButton             from '@geenee/builder/src/components/common/Button';
import { useWithdrawModal }     from '@geenee/builder/src/components/modals/Withdraw/useWithdrawModal';
import { HeaderContainer }      from './styles';

interface IProps {
    wallet_id: string;
    balance?: number;
}

export function Header({ wallet_id, balance }: IProps) {
    const [ showModal ] = useWithdrawModal(wallet_id);
    return (
        <HeaderContainer>
            <Wrapper row fullWidth align='stretch' valign='center' style={ { minHeight: 55 } }>
                <Description size='sm' weight='bold'>Wallet</Description>
                <Wrapper align='right'>
                    <StyledButton
                        $borderRadius='5px'
                        $padding='0 15px'
                        $justify='center'
                        $width="132px"
                        $height="30px"
                        onClick={ () => { balance && showModal(); } }
                        label='WITHDRAWAL'
                    />
                </Wrapper>
            </Wrapper>
        </HeaderContainer>
    );
}
