import React                       from 'react';
import { Divider, Table, Wrapper } from '@geenee/ui';
import { observer }                from "mobx-react";
import { HeaderTitle }             from "@geenee/builder/src/components/HeaderTitle";
import { useBuilderInject }        from "@geenee/builder/src/core/hook/use-builder-inject";
import { useNpmTable }             from "@geenee/builder/src/pages/SDKAccessPage/components/NpmTable/useNpmTable";

export const NpmTable = observer(() => {
    const { BuilderState } = useBuilderInject();
    const { npm_key } = BuilderState.$accountState;
    const [ data, columns ] = useNpmTable();
    if (!npm_key) {
        return <></>;
    }
    return (
        <Wrapper style={ { paddingLeft: 0, paddingRight: 0 } } padding="sm">
            <Divider color="grey-3" margin="xxxl" />
            <HeaderTitle
                title="NPM Token"
                subtitle="Get the NPM token to download the SDK packages and start developing your own custom experience."
            />
            <Divider transparent margin="md" />
            <Wrapper maxWidth='fit-content' margin="xl">
                <Table
                    noFooter
                    bodyFrame='solid-default'
                    paddingBody="sm"
                    paddingHeader="sm"
                    noHeader
                    data={ data }
                    columns={ columns }
                />
            </Wrapper>
        </Wrapper>
    );
});
