import React                                from 'react';
import { AnimatedWrapper, Modal, useModal } from '@geenee/ui';
import { DeleteAlert, DeleteAlertProps }    from '@geenee/builder/src/components/modals/DeleteAlert';

export const useDeleteModal = (props: DeleteAlertProps) => {
    const [ showModal, hideModal ] = useModal((transformGroupProps: JSX.IntrinsicAttributes) => (

        <Modal
            className='global-modal'
            onHideModal={ () => {
                hideModal();
            } }
            { ...transformGroupProps }
        >
            <AnimatedWrapper manual={ false }>
                <DeleteAlert
                    { ...props }
                    confirmCallback={ () => {
                        props.confirmCallback && props.confirmCallback();
                        hideModal();
                    } }
                />
            </AnimatedWrapper>
        </Modal>
    ), [ props ]);

    return { showModal, hideModal };
};
