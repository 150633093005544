import { Engine } from "@geenee/armature";

export class WithoutVideoEngine extends Engine<any, any, any> {
    constructor(...args) {
        super(...args);
    }

    protected iterate = async () => {
        const { streamCanvas } = this;
        // Process and render
        const result = [];
        result && await Promise.all(this.renderers.map(
            (r) => r.update(result, streamCanvas)
        ));
        this.enqueue();
    };
}
