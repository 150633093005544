import * as React                    from "react";
import type { Material }             from "@babylonjs/core/Materials/material";
import { StandardMaterial }          from "@babylonjs/core/Materials/standardMaterial";
import type { BaseTexture }          from "@babylonjs/core/Materials/Textures/baseTexture";
import { Texture }                   from "@babylonjs/core/Materials/Textures/texture";
import type { Observable, Observer } from "@babylonjs/core/Misc/observable";
import { Tools }                     from "@babylonjs/core/Misc/tools";
import type { Nullable }             from "@babylonjs/core/types";
import GlobalState                   from '@geenee/geespector/src/components/globalState';
import { PropertyChangedEvent }      from "@geenee/geespector/src/components/propertyChangedEvent";
import { FileButtonLineComponent }   from "@geenee/geespector-ui-components/src/lines/fileButtonLineComponent";
import { TextLineComponent }         from "@geenee/geespector-ui-components/src/lines/textLineComponent";
import { Button, Wrapper }           from '@geenee/ui';

export interface ITextureLinkLineComponentProps {
    label: string;
    texture: Nullable<BaseTexture>;
    material?: Material;
    onSelectionChangedObservable?: Observable<any>;
    onDebugSelectionChangeObservable?: Observable<TextureLinkLineComponent>;
    propertyName?: string;
    onTextureCreated?: (texture: BaseTexture) => void;
    customDebugAction?: (state: boolean) => void;
    onTextureRemoved?: () => void;
    border?: boolean
    ratio?: number
}

export class TextureLinkLineComponent extends React.Component<ITextureLinkLineComponentProps, { isDebugSelected: boolean, error: string }> {
    private _onDebugSelectionChangeObserver: Nullable<Observer<TextureLinkLineComponent>> = null;
    constructor(props: ITextureLinkLineComponentProps) {
        super(props);

        const { material } = this.props;
        const { texture } = this.props;

        this.state = { isDebugSelected: material?.reservedDataStore?.debugTexture === texture, error: '' };
    }

    componentDidMount() {
        if (!this.props.onDebugSelectionChangeObservable) {
            return;
        }
        this._onDebugSelectionChangeObserver = this.props.onDebugSelectionChangeObservable.add((line) => {
            if (line !== this) {
                this.setState({ isDebugSelected: false });
            }
        });
    }

    componentWillUnmount() {
        if (this.props.onDebugSelectionChangeObservable && this._onDebugSelectionChangeObserver) {
            this.props.onDebugSelectionChangeObservable.remove(this._onDebugSelectionChangeObserver);
        }
    }

    debugTexture() {
        if (this.props.customDebugAction) {
            const newState = !this.state.isDebugSelected;
            this.props.customDebugAction(newState);
            this.setState({ isDebugSelected: newState });

            if (this.props.onDebugSelectionChangeObservable) {
                this.props.onDebugSelectionChangeObservable.notifyObservers(this);
            }

            return;
        }

        const { texture } = this.props;

        const { material } = this.props;

        if (!material || !texture) {
            return;
        }
        const scene = material.getScene();

        if (material.reservedDataStore && material.reservedDataStore.debugTexture === texture) {
            const { debugMaterial } = material.reservedDataStore;
            texture.level = material.reservedDataStore.level;
            scene.meshes.forEach((mesh) => {
                if (mesh.material === debugMaterial) {
                    mesh.material = material;
                }
            });

            debugMaterial.dispose();
            material.reservedDataStore.debugTexture = null;
            material.reservedDataStore.debugMaterial = null;

            this.setState({ isDebugSelected: false });
            return;
        }

        let checkMaterial = material;
        let needToDisposeCheckMaterial = false;
        if (material.reservedDataStore && material.reservedDataStore.debugTexture) {
            checkMaterial = material.reservedDataStore.debugMaterial;
            needToDisposeCheckMaterial = true;
        }

        const debugMaterial = new StandardMaterial("debugMaterial", scene);
        debugMaterial.disableLighting = true;
        debugMaterial.sideOrientation = material.sideOrientation;
        debugMaterial.emissiveTexture = texture;
        debugMaterial.forceDepthWrite = true;
        debugMaterial.reservedDataStore = { hidden: true };

        scene.meshes.forEach((mesh) => {
            if (mesh.material === checkMaterial) {
                mesh.material = debugMaterial;
            }
        });

        if (!material.reservedDataStore) {
            material.reservedDataStore = {};
        }

        material.reservedDataStore.debugTexture = texture;
        material.reservedDataStore.debugMaterial = debugMaterial;
        material.reservedDataStore.level = texture.level;
        texture.level = 1.0;

        if (this.props.onDebugSelectionChangeObservable) {
            this.props.onDebugSelectionChangeObservable.notifyObservers(this);
        }

        if (needToDisposeCheckMaterial) {
            checkMaterial.dispose();
        }

        this.setState({ isDebugSelected: true });
    }

    onLink() {
        if (!this.props.onSelectionChangedObservable) {
            return;
        }
        const { texture } = this.props;
        this.props.onSelectionChangedObservable.notifyObservers(texture!);
        GlobalState.onNodeTabsChangedObservable.notifyObservers('textures');
    }

    updateTexture(file: File) {
        const material = this.props.material!;
        Tools.ReadFile(
            file,
            (data) => {
                const blob = new Blob([ data ], { type: "octet/stream" });
                const url = URL.createObjectURL(blob);
                const prevTexture = this.props.propertyName ? material[ this.props.propertyName ] : undefined;
                const texture = new Texture(url, material.getScene(), false, false);

                if (this.props.propertyName) {
                    (material as any)[ this.props.propertyName! ] = texture;
                } else if (this.props.onTextureCreated) {
                    this.props.onTextureCreated(texture);
                }

                const e = new PropertyChangedEvent();
                e.object = material;
                e.property = this.props.propertyName || '';
                e.value = texture;
                e.initialValue =  prevTexture;
                GlobalState.onPropertyChangedObservable.notifyObservers(e);

                this.forceUpdate();
            },
            undefined,
            true
        );
    }

    removeTexture() {
        const material = this.props.material!;
        const prevTexture = material[ this.props.propertyName ];
        if (this.props.propertyName) {
            (material as any)[ this.props.propertyName! ] = null;
        } else if (this.props.onTextureRemoved) {
            this.props.onTextureRemoved();
        }

        this.forceUpdate();
        const e = new PropertyChangedEvent();
        e.object = material;
        e.property = this.props.propertyName || '';
        e.value = null;
        e.initialValue =  prevTexture;
        GlobalState.onPropertyChangedObservable.notifyObservers(e);
    }

    render() {
        const { texture } = this.props;
        if (!texture && !this.state.error) {
            if (this.props.propertyName || this.props.onTextureCreated) {
                return (
                    <FileButtonLineComponent
                        icon="add"
                        label={ this.props.label }
                        error={ this.state.error }
                        onClick={ (file) => this.updateTexture(file) }
                        accept=".jpg, .png, .tga, .dds, .env"
                        onError={ (error) => this.setState({ error }) }
                    />
                );
            }
            return null;
        }
        return (
            <Wrapper fullWidth className="textureLinkLine">
                <TextLineComponent
                    ratio={ this.props.ratio }
                    error={ this.state.error }
                    label={ this.props.label }
                    value={ texture?.name || '' }
                    onLink={ () => this.onLink() }
                    border={ this.props.border ?? true }
                    actions={
                        !texture?.isCube && this.props.material && (
                            <>
                                <Wrapper
                                    frame="solid-white"
                                    radius='lg'
                                    maxWidth="fit-content"
                                    margin="xs"
                                    style={ { marginLeft: 8 } }
                                >
                                    <Button
                                        onClick={ () => this.debugTexture() }
                                        size="sm"
                                        disabled={ !!this.state.error }
                                        icon={ this.state.isDebugSelected ? 'eyeOn' : 'eyeOff' }
                                        viewType="transparent"
                                        radius="lg"
                                    />
                                </Wrapper>
                                { this.state.error ? (
                                    <FileButtonLineComponent
                                        icon="add"
                                        onlyButton
                                        error={ this.state.error }
                                        label={ this.props.label }
                                        onClick={ (file) => this.updateTexture(file) }
                                        accept=".jpg, .png, .tga, .dds, .env"
                                        onError={ (error) => this.setState({ error }) }
                                    />
                                ) : (
                                    <Wrapper frame='solid-white' radius='lg' maxWidth="fit-content">
                                        <Button
                                            onClick={ () => this.removeTexture() }
                                            size="sm"
                                            icon="remove"
                                            viewType="transparent"
                                            radius="lg"
                                        />
                                    </Wrapper>
                                ) }
                            </>
                        )
                    }
                />
            </Wrapper>
        );
    }
}
