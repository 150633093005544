import React                             from 'react';
import { Button, Description }           from '@geenee/ui';
import { TERMS_CONDITION_LINK }          from '~/lib/constants';
import { ContentWrapper }                from '../CancelSubscription/styles';
import { CancelSubscriptionDescription } from './styles';

function CancelConfirmation() {
    return (
        <ContentWrapper align="center" fullWidth maxWidth="460px">
            <Description margin="md" weight="bold" align="center">CANCELATION CONFIRMED</Description>
            <CancelSubscriptionDescription size='sm' align="center">
                Your account subscription has been canceled. There is no credit or refund for usage during partial months. Please go to
                { ' ' }
                <Button
                    style={ { display: 'unset' } }
                    onClick={ () => {
                        window.open(
                            TERMS_CONDITION_LINK,
                            '__blank'
                        );
                    } }
                    viewType='link'
                >
                    Cancelation Policy

                </Button>
                { ' ' }
                for more details
                <br />
                <br />
                While you are no longer a paying subscriber, you can always login and access your non-commercial projects.
            </CancelSubscriptionDescription>
        </ContentWrapper>
    );
}

// eslint-disable-next-line arca/no-default-export
export default CancelConfirmation;
