import React, { useEffect }     from 'react';
import { Analytics }            from '@geenee/analytics';
import { useInject }            from '@geenee/shared';
import { InputTable }           from '@geenee/ui';
import { observer }             from 'mobx-react';
import { ContentBasicButton }   from '@geenee/builder/src/components/WizardForm/FormType/ContentBasicButton';
import { ContentVisibilityRow } from '@geenee/builder/src/components/WizardForm/FormType/ContentMainForm/components/ContentVisibilityRow';
import { IFrameForm }           from '@geenee/builder/src/components/WizardForm/FormType/ContentMainForm/components/IFrameForm';
import { OverlayForm }          from '@geenee/builder/src/components/WizardForm/FormType/ContentMainForm/components/OverlayForm';
import { SceneCarouselRow }     from '@geenee/builder/src/components/WizardForm/FormType/ContentMainForm/components/SceneCarouselRow';
import { StampForm }            from '@geenee/builder/src/components/WizardForm/FormType/ContentMainForm/components/StampForm';
import { VideoEmbedForm }       from '@geenee/builder/src/components/WizardForm/FormType/ContentMainForm/components/VideoEmbedForm';
import { useHandlers }          from '@geenee/builder/src/components/WizardForm/FormType/ContentMainForm/hooks/useHandlers';
import { useBuilderInject }     from "@geenee/builder/src/core/hook/use-builder-inject";
import {
    SECTION_VISIBLE_IN_MENU_TOGGLED,
    TYPE_DRAWER_OVERLAY
}   from '@geenee/builder/src/lib/constants';
import { MoleculeModel } from '@geenee/builder/src/magellan/model/molecule.model';
import { SectionModel }  from '@geenee/builder/src/magellan/model/section.model';
import { MagellanState } from '@geenee/builder/src/magellan/state/app.state';

export const ContentMainForm = observer(({ disabled }: {disabled: boolean}) => {
    const { AppState, container } = useInject();
    const { activeSection, isBasicButtonFormOpened } = AppState as unknown as MagellanState;
    const { activeMolecule } = activeSection || {};
    const analytics: Analytics = container.get('<Analytics>');

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const updateDynamicContent = (value: any, name: string) => updateField('options', {
        ...activeSection?.options,
        dynamic_content: {
            ...activeSection?.options?.dynamic_content,
            [ name ]: value
        }
    } as any);

    useEffect(() => {
        if (activeSection?.options?.dynamic_content && activeSection?.options?.dynamic_content?.enabled) {
            // Verify that important inital values for dynamic content are set
            if (!activeSection?.options?.dynamic_content?.default) {
                // childAssets[ 0 ] && updateDynamicContent(childAssets[ 0 ].id, 'default');
            }
            if (!activeSection?.options?.dynamic_content?.timeZone) {
                updateDynamicContent(Intl.DateTimeFormat().resolvedOptions().timeZone, 'timeZone');
            }
            if (!activeSection?.options?.dynamic_content?.type) {
                updateDynamicContent('date', 'type');
            }
        }
    }, [ activeSection?.options?.dynamic_content?.enabled ]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { updateField } = useHandlers(activeSection as SectionModel);

    if (!activeSection) {
        return <></>;
    }

    if (activeSection.type === TYPE_DRAWER_OVERLAY && isBasicButtonFormOpened) {
        return <ContentBasicButton disabled={ disabled } />;
    }

    const associatedTriggerImgUrl = activeMolecule?.sceneTriggerAtom?.assetUrl;

    const visibilityUpdateHandler = (value) => {
        updateField('section_is_visible_in_menu', value, true);
        analytics.track(SECTION_VISIBLE_IN_MENU_TOGGLED, { value });
    };

    return (
        <InputTable>
            <ContentVisibilityRow
                menu_visibility={ activeSection.options.section_is_visible_in_menu }
                onUpdate={ visibilityUpdateHandler }
                disabled={ disabled }
            />
            { activeSection.type !== 'native-ar' && (
                <SceneCarouselRow
                    section_scene_carousel_visibility={ activeSection.options.section_scene_carousel_visibility }
                    type={ activeSection.type }
                    disabled={ disabled }
                    onUpdate={ (value) => updateField('section_scene_carousel_visibility', value, true) }
                />
            ) }
            { /* <QuickViewRowComponent */ }
            { /*    sectionModel={ activeSection } */ }
            { /*    onUpdate={ (value) => { */ }
            { /*        updateField('section_scene_use_native_ar', value, true); */ }
            { /*        if (value) { */ }
            { /*            updateField('section_scene_carousel_visibility', false, true); */ }
            { /*        } */ }

            { /*    } } */ }
            { /*    disabled={ disabled } */ }
            { /* /> */ }
            { /* <ContentDynamicSceneRow
                            dynamicСontent={ activeSection?.options?.dynamic_content?.enabled }
                            onUpdate={ (val, name) => updateDynamicContent(val, name) }
                        /> */ }
            <IFrameForm
                type={ activeSection.type }
                disabled={ disabled }
                iframeMolecule={ activeMolecule as MoleculeModel }
            />
            <VideoEmbedForm
                type={ activeSection.type }
                disabled={ disabled }
                videoMolecule={ activeMolecule as MoleculeModel }
            />
            <StampForm
                type={ activeSection.type }
                sectionModel={ activeSection as SectionModel }
                // @ts-ignore
                associatedTriggerImgUrl={ associatedTriggerImgUrl }
                handleUpload={ (file) => activeMolecule.setSceneTrigger(file) }
                disabled={ disabled }
            />
            <OverlayForm overlayMolecule={ activeMolecule as MoleculeModel } disabled={ disabled } />
        </InputTable>
    );
});
