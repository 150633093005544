// @ts-nocheck
import React                                          from 'react';
import GeeneeARScene                                  from "@geenee/geeclient-kit/src/lib/component/scene/components/GeeneeARScene/GeeneeARScene";
import { Lights }                                     from "@geenee/geeclient-kit/src/lib/component/scene/components/Lights/Lights";
import VideoOverlay                                   from "@geenee/geeclient-kit/src/lib/component/scene/components/VideoOverlay/VideoOverlay";
import * as Geetracker                                from '@geenee/geetracker';
import { AtomModel }                                  from '@geenee/shared';
import { AnimationController }                        from '@geenee/shared/src/magellan/renderer/AnimationController';
import { EffectsLibrary }                             from '@geenee/shared/src/magellan/renderer/EffectsLibrary';
import { MultiplayerController }                      from '@geenee/shared/src/magellan/renderer/MultiplayerController';
import { SceneSectionTypeUnionType }                  from "@geenee/shared/type/section.type";
import { Camera, CanvasContext, SharedCanvasContext } from '@react-three/fiber';
import EventEmitter                                   from 'eventemitter3';
import { injectable }                                 from 'inversify';
import {
    action,
    makeAutoObservable, observable, ObservableMap, runInAction, set, toJS
} from 'mobx';
import { Matrix4, Texture } from 'three';
import { Queue }            from 'typescript-collections';
import { BROWSER_MODE }     from '../../../util/constants';
// import { MeshEngine }                                         from '@geenee/bodytracking';
import GLTF_TEMPLATE        from '../../molecule/component/SceneMolecule/GLTF_TEMPLATE';
import { AppState }         from '../../state/app.state';

const { slam } = Geetracker;

type attachmentType = {
    url: string
    id: string
}

type AnnotationType = {
    type: 'ar-model' | 'video-overlay' | 'iframe'
    url: string
    model?: {
        rotation: number[]
        scale: number[]
        translation: number[]
        url: string
    }
    offset?: number[]
    size?: number[]
}

export type SceneExperienceUnionType = SceneSectionTypeUnionType | 'custom' | 'scene-build'

export interface SceneModelInterface
    extends Omit<SceneSchemaType, 'attachments'> {
    attachments: ObservableMap<string, attachmentType>
    options: {
        isCapturing: boolean
        focal: number
        overlayMatrix: Matrix4 | null
        motionAccessGranted: boolean
        modelShown: boolean
    }
}

export interface xyzNum {
    x: number,
    y: number;
    z: number
}

export interface ArModelType {
    id: string,
    url: string;
    body: {
        model: {
            texture?: string;
            url: string,
            scale?: xyzNum,
            rotation?: xyzNum,
            translation?: xyzNum,
            text3D?: {
                body?: string,
                fontType?: Record<string, unknown>,
                imgTexture?: Record<string, unknown>
                shaderTexture?: Record<string, unknown>
                shaderMaterialEdits?: void
            },
            holoStream?: {
                url: string
            },
            shadows?: boolean
        }
    }
}

function timeout(ms) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export interface SceneSchemaType {
    id: string
    projectId?: string
    attachments?: attachmentType[]
    scene_experience_type: SceneExperienceUnionType
    arModel?: ArModelType;
    targetImageUrl?: string;
    targetImages?: string[];
    options?: {
        snapshotMediaType?: 'image' | 'video'
    },
    'onSceneInit'?: (gl: SharedCanvasContext['gl']) => any,
    'onFrame'?: (gl: SharedCanvasContext['gl']) => any,
    'onReady'?: (gl: SharedCanvasContext['gl']) => any,
    children?: {
        lights_component?: {
            component?: React.ReactNode
            hooks?: CallableFunction[]
        }
        effectComposer_component?: {
            component?: React.ReactNode
            hooks?: CallableFunction[]
        },
        scene_component?: {
            component?: React.ReactNode
            hooks?: []
        }
        gltf_component?: {
            component?: React.ReactNode
            hooks?: []
        }
        video_overlay_component?: {
            component?: React.ReactNode
            hooks?: []
        }
        audio_track_component?: {
            component?: React.ReactNode
            hooks?: []
        },
        audio_tracks?: any
    },
    shadows?: boolean,
    geeneeUI?: {
        instructions?: boolean,
        motionPermission?: boolean,
        lookAroundLoader?: boolean,
        snapshot?: boolean,
        snapTimer?: boolean,
        videoPermission?: boolean,
        swapCamera?: boolean,
    },
    fallbackPlaceholder?: {
        enabled?: boolean,
        ifDesktop?: boolean,
        unsupportedBrowser?: boolean,
        goPortrait?: boolean,
        geeneeLogo?: boolean
    },
    scene3DSettings?: {
        environmentPreset?: 'sunset' | 'dawn' | 'night' | 'warehouse' | 'forest' | 'apartment' | 'studio' | 'city' | 'park' | 'lobby' | undefined,
        defaultCanvasClick?: boolean,
    }
    gestureControl?: {
        gestureOn?: boolean,
        scaleOn?: boolean,
        rotateOn?: boolean,
        dragOn?: boolean,
        minScaleLimit?: number,
        onPinchListener?: boolean
    }
    surfacePlaceholder?: {
        enabled?: boolean,
        url?: string,
        screenPositionY?: number,
        rotationY?: number,
        scale?: number
    },
    redirectUrl?: string,
    buttonLabelHtml?: string
    disableDefaultCanvasClick?: boolean
}

@injectable()
export class SceneRenderer implements SceneModelInterface {
    @observable id!: string;
    @observable projectId!: string;
    @observable annotations: AnnotationType[] = [];
    @observable wasmLoaded = false;
    @observable attachments: ObservableMap<string,
        attachmentType> = new ObservableMap([]);
    @observable scene_experience_type: SceneExperienceUnionType = 'stamp-ar';
    @observable canvasContext: CanvasContext | null = null;
    @observable isLoaded = false;
    @observable takingSnapshot = false;
    @observable experienceStarted = false;
    @observable redirectUrl = '';
    @observable buttonLabelHtml = '';
    @observable joystick = null;
    animationController = new AnimationController();
    @observable shouldRender = false;
    effectsLibrary = new EffectsLibrary();
    multiplayerController = new MultiplayerController(this.animationController, this.effectsLibrary);

    userCallbacks = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
        onSceneInit: (gl: SharedCanvasContext['gl']) => {

        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
        onRender: (gl: SharedCanvasContext['gl']) => {

        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
        onReady: (gl: SharedCanvasContext['gl']) => {

        }
    };

    @observable sceneSchema: SceneSchemaType | null = null;
    @observable audio: HTMLAudioElement | null = null;
    @observable audioMuted = false;
    @observable audioLooped = true;
    @observable targetImages: string[] = [];
    @observable targetImageUrl: string | null = null;

    @observable options = {
        isCapturing:           false,
        overlayMatrix:         new Matrix4(),
        mesh:                  [],
        captureMediaType:      'image',
        width:                 window.innerWidth,
        height:                window.innerHeight,
        videoScale:            1.0,
        focal:                 320,
        onModelClickFired:     false,
        offModelClickFired:    false,
        timeFrameFired:        {},
        animationLoopFired:    {},
        animationNthLoopFired: false,
        modelShown:            false,
        arAssetId:             null,
        audioUrl:              null,
        loopCount:             0,
        markerInOutEvent:      true,
        motionAccessGranted:   false
    };

    @observable arModel: ArModelType | null = null;
    @observable trackingImageUrl: string | null = null;

    $parent!: AppState;

    emitter: EventEmitter = new EventEmitter();

    @observable videoFrameOptions = {
        sx:     0,
        sy:     0,
        sw:     0,
        sh:     0,
        dx:     0,
        dy:     0,
        width:  0,
        height: 0
    };

    @observable scene: SharedCanvasContext['scene'] | null = null;
    @observable camera: Camera | null = null;
    @observable hasSlamVideo = false;
    @observable videoStreamTexture = new Texture();

    @observable videoElement: HTMLVideoElement | null = null;
    @observable videoCanvas: HTMLCanvasElement = document.createElement('canvas', {
        desynchronized:        true,
        preserveDrawingBuffer: true,
        alpha:                 true
    });
    @observable videoCanvasContext: CanvasContext | null = this.videoCanvas.getContext('2d', {
        desynchronized:        true,
        preserveDrawingBuffer: true,
        alpha:                 true
    });
    @observable imageData: ImageData | null = null;
    @observable ready = false;
    @observable previousImageData: ImageData | null = null;
    @observable slamData = new Queue<{ imageData: ImageData, roto: number[], focal?: number }>();
    @observable faceMeshData = new Queue();

    @observable renderer: SharedCanvasContext['gl'] | null = null;
    @observable overlayMatrix: Matrix4 | null = null;

    @observable _children = {
        lights_component: {
            component: Lights,
            hooks:     []
        },
        effectComposer_component: {
            component: <></>,
            hooks:     []
        },
        scene_component: {
            component: GeeneeARScene,
            hooks:     []
        },
        gltf_component: {
            component: GLTF_TEMPLATE,
            hooks:     []
        },
        gltf_component_stamp: {
            component: GLTF_TEMPLATE,
            hooks:     []
        },
        video_overlay_component: {
            component: VideoOverlay,
            hooks:     []
        },
        audio_track: {
            component: null,
            hooks:     []
        }
    };

    @observable audioTracks = {};
    @observable readyToRender = true;
    @observable capturer: any = null;

    @observable geeneeUI = {
        instructions:     true,
        motionPermission: true,
        lookAroundLoader: true,
        snapshot:         true,
        snapTimer:        true,
        videoPermission:  true,
        swapCamera:       true
    };

    @observable fallbackPlaceholder = {
        enabled:            true,
        ifDesktop:          true,
        unsupportedBrowser: true,
        goPortrait:         true,
        geeneeLogo:         false
    };

    @observable scene3DSettings = {
        environmentPreset:  'city',
        defaultCanvasClick: true
    };

    @observable gestureControl = {
        gestureOn:       true,
        scaleOn:         true,
        rotateOn:        true,
        dragOn:          true,
        minScaleLimit:   0.05,
        onPinchListener: false
    };

    @observable disableDefaultCanvasClick = false;
    @observable shadows = true;
    @observable surfacePlaceholder = {
        enabled:         false,
        url:             '',
        screenPositionY: 0.7,
        rotationY:       0,
        scale:           1
    };

    constructor() {
        makeAutoObservable(this);

        this.emitter.on('on-model-click', () => {
            runInAction(() => {
                set(this.options, 'onModelClickFired', true);
            });
        });

        this.emitter.on('timeframe', (assetId) => {
            runInAction(() => {
                set(this.options, 'timeFrameFired', {
                    ...this.options.timeFrameFired,
                    [ assetId ]: true
                });
            });
        });

        this.emitter.on('after-animation-loop', (loopCount) => {
            runInAction(() => {
                set(this.options, 'loopCount', loopCount);
            });
        });
    }

      @action
          onResize = () => {
              this.readyToRender = false;
              // this.stopExp();
              if (BROWSER_MODE) {
                  this.updateOption('width', window.innerWidth);
                  this.updateOption('height', window.innerHeight);
                  setTimeout(() => {
                      runInAction(() => {
                          if (this.renderer) {
                              this.camera.aspect = window.innerWidth / window.innerHeight;

                              // this.scene?.resize(window.innerWidth, window.innerHeight);
                              this.renderer.setSize(window.innerWidth, window.innerHeight);
                              this.camera.updateProjectionMatrix();

                              setTimeout(() => {
                                  runInAction(() => {
                                      this.readyToRender = true;
                                  });
                              });
                          }
                      });
                  });
              }
          };

      onRender() {
          this.userCallbacks.onRender(toJS(this.canvasContext));
          this.animationController.update();
      }

  @action
      setup = (
          canvasContext: CanvasContext,
          scene: SharedCanvasContext['scene'],
          camera: Camera
      ) => {
          this.canvasContext = canvasContext;
          this.renderer = canvasContext.gl;
          this.scene = scene;
          this.camera = camera;
          this.videoCanvas.width = window.innerWidth;
          this.videoCanvas.height = window.innerHeight;
          this.videoCanvas.style.position = 'fixed';
          this.videoCanvas.style.zIndex = -1;
          this.videoCanvas.style.top = 0;
          this.videoCanvas.style.right = 0;

          if (process.env.ENV_GEENEE_APP !== 'BUILDER') {
              window.addEventListener('resize', this.onResize);
          }

          this.userCallbacks.onSceneInit(canvasContext);
      };

  @action setWasmLoaded(wasmLoaded: boolean) {
      this.wasmLoaded = wasmLoaded;
  }

    @action
  updateOption<T extends keyof SceneModelInterface['options']>(key: T, value: SceneModelInterface['options'][T]) {
      set(this.options, key, value);
  }

    @action
    setScene3DSettingsOption(option: string, enabled: boolean) {
        this.scene3DSettings[ option ] = enabled;
    }

    @action
    setGestureOption(option: string, enabled: boolean) {
        this.gestureControl[ option ] = enabled;
    }

    @action
    setEnvPreset(preset: string) {
        this.scene3DSettings.environmentPreset = preset;
    }

    @action
    setDefaultCanvasClick(enabled: boolean) {
        this.scene3DSettings.defaultCanvasClick = enabled;
    }

    @action
    setGeeneeUIOption(option: string, enabled: boolean) {
        this.geeneeUI[ option ] = enabled;
    }

    @action
    setFallbackPlaceholderOption(option: string, enabled: boolean) {
        this.fallbackPlaceholder[ option ] = enabled;
    }

    @action
    setSurfacePlaceholderOption(option: string, enabled: boolean) {
        this.surfacePlaceholder[ option ] = enabled;
    }

    @action
    getObjectsListByName(name: string) {
        const objectList = [];
        this.scene.traverse((child) => {
            if (child.name === name) {
                objectList.push[ child ];
            }
        });
        return objectList;
    }

    @action
    getObjectByName(name: string) {
        return this.scene.getObjectByName(name);
    }

    @action
    setCapturer(capturer) {
        if (capturer) {
            this.capturer = capturer;
        }
    }

    @action
    placeModelOnSurfaceAt(clientX: number, clientY: number) {
        // Put the model on the surface only after first click.
        // If Surface Placeholder enabled we put the model in the circle center
        // Or follow the user click position if placeholder is disabled
        const x = this.surfacePlaceholder.enabled
            ? window.innerWidth / 2
            : clientX;
        const y = this.surfacePlaceholder.enabled
            ? window.innerHeight * this.surfacePlaceholder.screenPositionY
            : clientY;

        // Check if clientX and clientY are numbers
        const posX = x || (window.innerWidth / 2);
        const posY = y || (window.innerHeight * 0.75);

        this.emitter.emit('on-position-reset', [ posX, posY ]);
    }

    @action startSlamPreposition(x, y) {
        return;
        this.updateOption('lastX', x);
        this.updateOption('lastY', y);
        slam.start(x, y, this.options.width, this.options.height);
    }

    startAudio() {
        setTimeout(() => {
            Object.keys(this.audioTracks)
                .forEach((k) => {
                    this.audioTracks[ k ].play();
                    this.audioTracks[ k ].pause();
                });
            this.playAudio();
        });
    }

    @action
        startExp = () => {
            this.experienceStarted = true;
        };

    @action
        toggleAudioMuted = () => {
            this.audioMuted = !this.audioMuted;
            if (this.audioMuted) {
                this.audio?.pause();
            } else {
                this.audio?.play();
            }
        };

    @action
        stopAudio = () => {
            this.audio?.pause();
        };

    @action
        setAudioLoopOn = () => {
            if (this.audio) this.audio.loop = true;
        };

    @action
        setAudioLoopOff = () => {
            if (this.audio) this.audio.loop = false;
        };

    @action
        playAudio = () => {
            if (this.audio?.ended && !this.audioMuted) {
                this.audio.play();
            }
            if (this.audio && !this.audioMuted) {
                if (this.audio.paused || this.audio.ended) {
                    this.audio.play();
                }
            }
        };

  @action audioInit = (audioAtom: AtomModel) => {
      if (!audioAtom) {
          this.setAudio(audioAtom);
          return;
      }
      this.prepareAudio(audioAtom);
      this.setAudio(audioAtom);
      if (this.audioLooped) {
          this.setAudioLoopOn();
      }
  };

  @action
  prepareAudio(audioAtom: AtomModel) {
      if (audioAtom.firstAsset.url) {
          const audioUrl = audioAtom.firstAsset?.options?.audio_spotify_url || audioAtom.firstAsset.url;
          const track = new Audio(audioUrl);
          track.crossOrigin = 'anonymous';
          set(this.audioTracks, audioAtom.id, track);
      }
  }

  @action
      setAudio = (audioAtom: AtomModel) => {
          if (this.audio) {
              this.audio.pause();
          }
          if (audioAtom) {
              this.audio = this.audioTracks[ audioAtom.id ];
          } else {
              this.audio = null;
          }
      };

  startAudio() {
      setTimeout(() => {
          Object.keys(this.audioTracks)
              .forEach((k) => {
                  this.audioTracks[ k ].play();
                  this.audioTracks[ k ].pause();
              });
          this.playAudio();
      });
  }

  @action
  stopExp() {
      // slam.stop();
      this.experienceStarted = false;
  }

  loadedFired = false;
  @action.bind(this)
  async setLoaded(value: boolean) {
      if (this.loadedFired) {
          return;
      }
      this.loadedFired = true;
      await timeout(10);
      if (!window.Geenee.activeSceneModel) {
          window.Geenee.activeSceneModel = this;
      }
      this.userCallbacks.onReady(toJS(this.canvasContext));
      runInAction(async () => {
          this.updateOption('assetsLoaded', true);
          this.isLoaded = true;
          await timeout(1000);
          this.emitter.emit('geenee-assets-loaded');
      });
  }
}

export type SceneModelType = SceneRenderer
