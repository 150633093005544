import React, { useContext }                    from 'react';
import { Description, InputTableItem, Wrapper } from '@geenee/ui';
import { observer }                             from 'mobx-react';
import { ViewTransactions }                     from '@geenee/builder/src/components/modals/ViewTransactions';
import { stripeState }                          from '@geenee/builder/src/core/state/stripe.state';
import ModalUpdaterContext                      from '@geenee/builder/src/lib/ModalUpdaterContext';
import { ButtonWithPortalTooltip }              from "@geenee/builder/src/pages/SDKAccessPage/components/ButtonWithPortalTooltip";

const usd = new Intl.NumberFormat('en-US', {
    style:    'currency',
    currency: 'USD'
});
export const dateEn = new Intl.DateTimeFormat('en');

export const NextPayment = observer(() => {
    const setModalOptions = useContext(ModalUpdaterContext);
    const now = new Date();
    const endDate = dateEn.format(now.setDate(now.getDate() + stripeState.daysLeft));
    return (
        <InputTableItem
            size="lg"
            label="Next Payment"
            viewType="secondary"
            weight="medium"
        >
            <Wrapper row valign='center'>
                <Wrapper valign="center" frame="solid-white" radius="xl" margin="xs" style={ { height: '40px' } }>
                    <Description style={ { paddingLeft: 13 } }>
                        { usd.format(stripeState.plan_price) }
                        { ' at ' }
                        { endDate }
                    </Description>
                </Wrapper>
                <ButtonWithPortalTooltip
                    action={ {
                        icon:        "stats",
                        onClick:     () => setModalOptions({ component: ViewTransactions }),
                        tooltipText: 'View transactions',
                        iconSize:    22
                    } }
                    menuWidth="140px"
                />
            </Wrapper>
        </InputTableItem>
    );
});
