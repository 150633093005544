import React, { memo, useEffect }             from 'react';
import { Button, Description, Icon, Wrapper } from '@geenee/ui';
import { SketchFabModel }                     from '@geenee/builder/src/core/model/SketchFabModel.model';

const CREATIVE_COMMON_PRACTICES_URL =    'https://wiki.creativecommons.org/wiki/best_practices_for_attribution';

interface Props {
    model: SketchFabModel;
    onClose?: () => void;
}

export const ImportedModal = memo(({ model, onClose }: Props) => {
    useEffect(() => () => {
        onClose && onClose();
    }, []);
    return (
        <Wrapper padding="lg" fullWidth align='center'>
            <Icon name="checkSmall" color="gradient-cold-3" size={ 48 } />
            <Wrapper margin='xxs' align='center'>
                <Description color="shade-2" weight="medium">Import Successful</Description>
                <Wrapper
                    fullWidth
                    row
                    align="center"
                    valign="center"
                    margin='md'
                    color="shade-4"
                    style={ { flexWrap: 'wrap' } }
                >
                    <Button weight="medium" margin="xxs" viewType="link" onClick={ () => window.open(model.viewerUrl, '_blank') }>
                        { model.name }
                    </Button>
                    by
                    <Button
                        weight="medium"
                        viewType="link"
                        style={ { marginLeft: '4px' } }
                        margin="xxs"
                        onClick={ () => window.open(model.user.profileUrl, '_blank') }
                    >
                        { model.user.displayName || model.user.username }
                    </Button>
                    is now in your scene
                </Wrapper>
                <Description color="shade-4">Please give appropriate credit.</Description>
                <Wrapper
                    fullWidth
                    row
                    align="center"
                    valign="center"
                    color="shade-4"
                    style={ { flexWrap: 'wrap' } }
                >
                    { ' ' }
                    Check out
                    { ' ' }
                    <Button
                        weight="medium"
                        viewType="link"
                        style={ { marginLeft: '4px' } }
                        margin="xxs"
                        onClick={ () => window.open(CREATIVE_COMMON_PRACTICES_URL, '_blank') }
                    >
                        Creative Common&apos;s best practices
                    </Button>
                    { ' ' }
                    for attribution.
                </Wrapper>
            </Wrapper>
        </Wrapper>
    );
});
