import React, { useEffect, useState } from 'react';
import {
    ActiveUsersChart,
    AnalyticsDashboard,
    BounceRateChart,
    OrganicSearchesChart,
    PagesPerSessionChart,
    PageViewsPerPathChart,
    SessionDurationChart,
    SessionsByDateChart,
    SessionsByHourChart,
    SessionsBySourceChart,
    SessionsGeoChart
}                                              from 'react-analytics-charts';
import { useHistory }                          from "@geenee/common";
import { useAnalyticsApi }                     from 'react-use-analytics-api';
import { Divider, Tooltip, Wrapper }           from '@geenee/ui';
import { observer }                            from 'mobx-react';
import StyledButton                            from '@geenee/builder/src/components/common/Button';
import { HeaderTitle }                         from "@geenee/builder/src/components/HeaderTitle";
import { Link }                                from '@geenee/builder/src/components/Link';
import { accountState }                        from '@geenee/builder/src/core/state/account.state';
import { stripeState }                         from '@geenee/builder/src/core/state/stripe.state';
import { NOTION_SUPPORT_URL }                  from "@geenee/builder/src/lib/constants";
import { AnalyticsInputRow }                   from '@geenee/builder/src/pages/Analytics/AnalyticsInputRow';
import { tooltips }                            from '@geenee/builder/src/pages/Analytics/tooltips';
import { AnalyticsDescription, AnalyticsRoot } from './styles';

export const AnalyticsPage = observer(() => {
    const { authorized } = useAnalyticsApi();
    const [ isSaving, setIsSaving ] = useState(false);
    const { ga_client_id } = accountState;
    const history = useHistory();

    useEffect(() => {
        if (!stripeState.isEnterprise && !stripeState.isTeams) {
            history.replace('/');
        }
    }, []);

    return (
        <AnalyticsRoot>
            <HeaderTitle
                title="Google Analytics"
                subtitle={ (
                    !ga_client_id ? (
                        <>
                            You can connect your Google Analytics account to 1 or more
                            projects. For help, please check out the
                            { ' ' }
                            { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
                            <Link onClick={ () => window.open(NOTION_SUPPORT_URL) }>
                                FAQ’s.
                            </Link>
                        </>
                    ) : null
                ) }
            />
            <Divider transparent margin="md" />
            { ga_client_id ? (
                <Wrapper row style={ { alignItems: 'center' } }>
                    <AnalyticsDescription>
                        You connected your Google Analytics.
                    </AnalyticsDescription>
                    <StyledButton
                        disabled={ isSaving }
                        label="REMOVE CODE"
                        $width="152px"
                        onClick={ async () => {
                            setIsSaving(true);
                            await accountState.update({ ga_client_id: '' });
                            setIsSaving(false);
                        } }
                    />
                </Wrapper>
            ) : (
                <AnalyticsInputRow />
            ) }
            { ga_client_id ? (
                <AnalyticsDashboard
                    hideAuth={ authorized }
                    authOptions={ { clientId: ga_client_id } }
                    renderCharts={ (gapi, viewId) => {
                        const chartStyles = {
                            height: 300,
                            width:  '100%'
                        };
                        return (
                            <div
                                className="graphs-root"
                            >
                                <Tooltip message={ tooltips.activeUsersChart } position="top">
                                    <ActiveUsersChart
                                        viewId={ viewId }
                                        gapi={ gapi }
                                        style={ chartStyles }
                                        days={ 28 }
                                        // @ts-ignore
                                        activeUserDays={ 7 }
                                        options={ {
                                            backgroundColor: 'transparent',
                                            colors:          [ 'cc46cf' ]
                                        } }
                                    />
                                </Tooltip>
                                <Tooltip message={ tooltips.bounceRateChart } position="top">
                                    <BounceRateChart
                                        gapi={ gapi }
                                        viewId={ viewId }
                                        days={ 28 }
                                        style={ chartStyles }
                                        options={ {
                                            backgroundColor: 'transparent',
                                            colors:          [ 'cc46cf' ]
                                        } }
                                    />
                                </Tooltip>
                                <Tooltip
                                    message={ tooltips.organicSearchesChart }
                                    position="top"
                                >
                                    <OrganicSearchesChart
                                        gapi={ gapi }
                                        viewId={ viewId }
                                        days={ 28 }
                                        style={ chartStyles }
                                        options={ {
                                            backgroundColor: 'transparent',
                                            colors:          [ 'cc46cf' ]
                                        } }
                                    />
                                </Tooltip>
                                <Tooltip
                                    message={ tooltips.pagesPerSessionChart }
                                    position="top"
                                >
                                    <PagesPerSessionChart
                                        gapi={ gapi }
                                        viewId={ viewId }
                                        days={ 28 }
                                        style={ chartStyles }
                                        options={ {
                                            backgroundColor: 'transparent',
                                            colors:          [ 'cc46cf' ]
                                        } }
                                    />
                                </Tooltip>
                                <Tooltip
                                    message={ tooltips.sessionDurationChart }
                                    position="top"
                                >
                                    <SessionDurationChart
                                        gapi={ gapi }
                                        viewId={ viewId }
                                        days={ 28 }
                                        style={ chartStyles }
                                        options={ {
                                            backgroundColor: 'transparent',
                                            colors:          [ 'cc46cf' ]
                                        } }
                                    />
                                </Tooltip>
                                <Tooltip
                                    message={ tooltips.sessionsByDateChart }
                                    position="top"
                                >
                                    <SessionsByDateChart
                                        gapi={ gapi }
                                        viewId={ viewId }
                                        style={ chartStyles }
                                        showPageViews
                                        showUsers
                                        options={ {
                                            backgroundColor: 'transparent',
                                            colors:          [ 'cc46cf', 'ED87C3' ]
                                        } }
                                    />
                                </Tooltip>
                                <Tooltip message={ tooltips.sessionsGeoChart } position="top">
                                    <SessionsGeoChart
                                        gapi={ gapi }
                                        viewId={ viewId }
                                        style={ chartStyles }
                                        showPageViews
                                        options={ {
                                            width:           '100%',
                                            backgroundColor: 'transparent',
                                            colorAxis:       { colors: [ '#ecb4d6', '#cc46cf' ] }
                                        } }
                                    />
                                </Tooltip>
                                <Tooltip
                                    message={ tooltips.sessionsBySourceChart }
                                    position="top"
                                >
                                    <SessionsBySourceChart
                                        gapi={ gapi }
                                        viewId={ viewId }
                                        style={ chartStyles }
                                        options={ {
                                            backgroundColor: 'transparent',
                                            colors:          [
                                                '#ecb4d6',
                                                '#cc46cf',
                                                '#5CEBD6',
                                                '#FF008A',
                                                '#9F9E9B',
                                                '#F0BA30',
                                                '#61bcff',
                                                '#1ED760',
                                                '#2A2A2A'
                                            ]
                                        } }
                                    />
                                </Tooltip>
                                <Tooltip
                                    message={ tooltips.sessionsByHourChart }
                                    position="top"
                                >
                                    <SessionsByHourChart
                                        gapi={ gapi }
                                        viewId={ viewId }
                                        style={ chartStyles }
                                        options={ {
                                            width:           '100%',
                                            backgroundColor: 'transparent',
                                            colors:          [ 'cc46cf' ]
                                        } }
                                    />
                                </Tooltip>
                                <Tooltip
                                    message={ tooltips.pageViewPerPathChart }
                                    position="top"
                                >
                                    <PageViewsPerPathChart
                                        gapi={ gapi }
                                        viewId={ viewId }
                                        style={ chartStyles }
                                    />
                                </Tooltip>
                            </div>
                        );
                    } }
                />
            ) : (
                <></>
            ) }
        </AnalyticsRoot>
    );
});
