import styled from 'styled-components';

export const HintPlaceholder = styled.div`
    position: relative;
    visibility: hidden;

    &:hover {
        visibility: visible;
    }
`;

export const HintWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 230px;

    &:hover ${ HintPlaceholder } {
        visibility: visible;
    }

    .hint-content {
        width: 200px;
        height: 100%;
        margin-left: 30px;
        padding: 10px 10px 10px 4px;
        display: flex;

        background: linear-gradient(
                90deg,
                ${ ({ theme: { secondaryColors } }) => secondaryColors.pink50 } 0%,
                rgba(250, 200, 227, 0.5) 100%
            ),
            #ffffff;
        border: 1px solid rgb(237, 135, 195);
        border-radius: 5px;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            border-top: 10px solid transparent;
            border-right: 20px solid rgb(237, 135, 195);
            border-bottom: 10px solid transparent;
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 12px;
            transform: translateY(-50%);

            border-top: 10px solid transparent;
            border-right: 20px solid
                ${ ({ theme: { secondaryColors } }) => secondaryColors.pink50 };
            border-bottom: 10px solid transparent;
        }
    }

    .img-container {
        flex: 1 0 56px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .text-container {
        margin-left: 5px;
        flex: 1 1 auto;
        line-height: 15px;
        font-family: 'Mulish', sans-serif;
    }
`;
