import {
    CAMERA_PLANE_HEIGHT,
    CAMERA_PLANE_WIDTH, HEIGHT_POSITION_OFFSET
} from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/camera-viewport.component3d';
import { Mesh, PlaneGeometry } from 'three';

export const generatePlaneMesh = () => {
    const plane = new PlaneGeometry(
        CAMERA_PLANE_WIDTH,
        CAMERA_PLANE_HEIGHT
    );
    const planeMesh = new Mesh(plane);
    planeMesh.position.set(0, HEIGHT_POSITION_OFFSET, 0);
    return planeMesh;
};
