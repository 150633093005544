import { AttachmentModel }  from '@geenee/shared';
import { AR_CONTENT_TYPES } from '@geenee/builder/src/lib/constants';
import generateModelPreview from '@geenee/builder/src/lib/generateModelPreview';
import { AtomModel }        from '@geenee/builder/src/magellan/model/atom.model';
import { ExperienceModel }  from '@geenee/builder/src/magellan/model/experience.model';
import { MoleculeModel }    from '@geenee/builder/src/magellan/model/molecule.model';
import { ProjectModel }     from '@geenee/builder/src/magellan/model/project.model';
import { SectionModel }     from '@geenee/builder/src/magellan/model/section.model';

export const generateProjectContentsPreview = async (activeProject?: ProjectModel) => {
    if (!activeProject) {
        return [];
    }
    const experiences = Array.from(activeProject.experiencesRegistry.values()).filter((el) => el) as ExperienceModel[];
    const arContents: SectionModel[] = [];
    experiences.forEach((exp) => {
        Array.from(exp.viewsRegistry.values())
            .forEach((view) => {
                const filteredContents = Array.from(view.sectionsRegistry.values())
                    .filter((content) => AR_CONTENT_TYPES.includes(content.type))
                    .sort((a: SectionModel, b: SectionModel) => a.options.order - b.options.order);
                if (filteredContents.length) {
                    arContents.push(...filteredContents);
                }
            });
    });

    const arMolecules: MoleculeModel[] = arContents.reduce((acc, curr) => {
        const molecules = Array.from(curr.moleculesRegistry.values());
        if (molecules.length) {
            // @ts-ignore
            acc.push(...molecules);
        }
        return acc;
    }, []);

    const arAtoms: AtomModel[] = arMolecules.reduce((acc, curr) => {
        const atoms = Array.from(curr.atomsRegistry.values());
        if (atoms.length) {
            // @ts-ignore
            acc.push(...atoms);
        }
        return acc;
    }, []);
    const trackingImages = new Map<string, string>();

    const resultAtoms = arAtoms.filter((item) => {
        const attachments: AttachmentModel[] = Array.from(item.assetsRegistry.values());
        if (item.type === 'scene-trigger') {
            trackingImages.set(item.$parent?.id || '', attachments[ 0 ].url);
            return false;
        }
        return true;
    });

    const images: string[] = [];
    const generatedMolecules = new Set<string>();

    // eslint-disable-next-line no-restricted-syntax
    for (const item of resultAtoms) {
        const item_molecule_id = item.$parent?.id || '';
        if (generatedMolecules.has((item_molecule_id))) {
            // eslint-disable-next-line no-continue
            continue;
        }
        generatedMolecules.add(item_molecule_id);
        const trackingImageUrl = trackingImages.get(item_molecule_id);
        // eslint-disable-next-line no-await-in-loop
        await generateModelPreview({
            trackerUrl:   trackingImageUrl || '',
            rendererSize: { width: 2000, height: 1000 },
            done:         async (imageUrl) => {
                images.push(imageUrl);
            },
            customDistance:     1.3,
            modelsToBeRendered: resultAtoms.filter((atom) => atom.$parent?.id === item_molecule_id)
        });
    }

    return images;
};
