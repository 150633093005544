import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '50',
    height = '50',
    onClick,
    id,
    fill = '#fff'
}: SvgIconProps) {
    return (
        <svg
            id={ id }
            onClick={ onClick }
            width={ width }
            height={ height }
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="24.7694" cy="24.7694" r="23.7694" stroke={ fill } strokeWidth="2" />
            <rect x="17.623" y="14.5578" width="5.3634" height="20.6874" rx="2" fill={ fill } />
            <rect x="26.0508" y="14.5578" width="5.3634" height="20.6874" rx="2" fill={ fill } />
        </svg>
    );
}
