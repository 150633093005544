import React, { FC, memo, useRef }       from 'react';
import { cn }                            from '../../util/bem';
import { FormSizesType, SizesUnionType } from '../../util/global-props';
import { Description }                   from '../description/description.component';
import { Wrapper }                       from '../wrapper/wrapper.component';
import './radio-group.component.scss';

export type RadioGroupPropsType = {
    className?: string;
    id?: string;
    label?: React.ReactNode;
    disabled?: boolean;
    value: string;
    autoFocus?: boolean;
    height?: FormSizesType;
    margin?: SizesUnionType;
    size?: 'sm' | 'md' | 'lg';
    style?: React.CSSProperties;
    viewType: 'block' | 'radio';
    theme?: 'primary' | 'primary-green' | 'primary-white' | 'secondary' | 'dark' | 'transparent' | 'alert';

    flex?: number;

    onChange?: (val: string) => void;

    tabIndex?: number;
    children?: React.ReactNode;
}

const classNames = cn('radio-group');

export const RadioGroup: FC<RadioGroupPropsType> = memo((props) => {
    const node = useRef<HTMLInputElement>(null);

    const handleClick = (val: string) => {
        if (props.onChange) {
            props.onChange(val);
        }
    };

    return (
        <div
            className={ classNames({
                height:   props.height,
                margin:   props.margin,
                label:    !!props.label,
                size:     props.size,
                viewType: props.viewType
            }, null, props.className) }
            id={ props.id }
            ref={ node }
            style={ props.style }
        >
            <Wrapper>
                <Description margin="xxs">{ props.label }</Description>
            </Wrapper>
            <Wrapper align="stretch" row>
                {
                    React.Children.map(props.children, (child) => (
                        React.isValidElement(child)
                            ? React.cloneElement(child, {
                                ...child.props,
                                viewType: props.viewType,
                                checked:  props.value === child.props.value,
                                theme:    props.theme,
                                onChange: (val: string) => {
                                    handleClick(val);
                                },
                                onClick: (val: string) => handleClick(val)
                            })
                            : null))
                }
            </Wrapper>
        </div>
    );
});

RadioGroup.defaultProps = {
    disabled:  false,
    autoFocus: false,
    size:      'sm'
};
