import React, { useCallback, useEffect }             from 'react';
import { AnimatedWrapper, Modal, useModal, Wrapper } from '@geenee/ui';

const IFRAME_ORIGIN = 'https://geenee.readyplayer.me';

export const useReadyPlayerMeModal = (onImportModel: (url: string) => void) => {
    const [ showModal, hideModal, isOpened ] = useModal((transformGroupProps: JSX.IntrinsicAttributes) => (
        <Modal
            className='global-modal'
            style={ {
                width: '80%', height: '80%', background: 'transparent', padding: 10, maxWidth: 1280, maxHeight: 800
            } }
            onHideModal={ () => {
                hideModal();
            } }
            { ...transformGroupProps }
        >
            <AnimatedWrapper manual={ false }>
                <Wrapper fullHeight fullWidth radius="md" overflow="hidden">
                    <iframe
                        title="rpm"
                        width="100%"
                        height="100%"
                        src={ IFRAME_ORIGIN }
                        frameBorder="0"
                        allow="camera *; microphone *"
                    />
                </Wrapper>
            </AnimatedWrapper>
        </Modal>
    ), []);

    const receiveMessage = useCallback(async (e: MessageEvent<string>) => {
        if (e.origin === IFRAME_ORIGIN && isOpened) {
            const url = e.data;
            if (url) {
                onImportModel(url);
                hideModal();
            }
        }
    }, [ isOpened ]);

    useEffect(() => {
        if (isOpened) {
            window.addEventListener('message', receiveMessage, false);
            return () => {
                window.removeEventListener('message', receiveMessage);
            };
        }
    }, [ isOpened ]);

    return { showModal, hideModal };
};
