import { injectable }       from 'inversify';
import { runInAction, set } from  'mobx';
import { FactoryAbstract }  from '../../util/factoryContainer';
import { SceneSchemaType }  from '../renderer/r3f-renderer/r3f.renderer';
import { AppStateType }     from '../state/app.state';

@injectable()
export class SceneFactory extends FactoryAbstract {
    create(sceneSchema: SceneSchemaType, $parent?: AppStateType) {
        const sceneModel = this.container.get('<SceneRenderer>');
        if (sceneSchema.options) {
            Object.keys(sceneSchema.options).forEach((optionKey) => {
                runInAction(() => {
                    // @ts-ignore
                    set(sceneModel.options, optionKey, sceneSchema.options[ optionKey ]);
                });
            });
        }
        sceneModel.projectId = sceneSchema.projectId;
        sceneModel.scene_experience_type = sceneSchema.scene_experience_type;
        sceneModel.id = sceneSchema.id;
        sceneModel.sceneSchema = sceneSchema;
        (sceneSchema?.attachments || []).forEach((a) => {
            sceneModel.attachments.set(a.id, a);
        });
        sceneModel.arModel = sceneSchema.arModel;

        sceneModel.targetImageUrl = sceneSchema.targetImageUrl;
        sceneModel.targetImages = sceneSchema.targetImages || [];
        if ($parent) {
            sceneModel.$parent = $parent;
        }
        if (sceneSchema.children) {
            Object.keys(sceneSchema?.children).forEach((componentName) => {
                // @ts-ignore
                sceneModel._children[ componentName ] =                    sceneSchema.children[ componentName ];
            });
        }
        if (sceneSchema.geeneeUI) {
            sceneModel.geeneeUI = { ...sceneModel.geeneeUI, ...sceneSchema.geeneeUI };
        }
        if (sceneSchema.surfacePlaceholder) {
            sceneModel.surfacePlaceholder = { ...sceneModel.surfacePlaceholder, ...sceneSchema.surfacePlaceholder };
        }
        if (sceneSchema.shadows) {
            sceneModel.shadows = sceneSchema.shadows;
        }
        if (sceneSchema.scene3DSettings) {
            sceneModel.scene3DSettings = { ...sceneModel.scene3DSettings, ...sceneSchema.scene3DSettings };
        }
        if (sceneSchema.fallbackPlaceholder) {
            sceneModel.fallbackPlaceholder = { ...sceneModel.fallbackPlaceholder, ...sceneSchema.fallbackPlaceholder };
        }

        if (sceneSchema.gestureControl) {
            sceneModel.gestureControl = { ...sceneModel.gestureControl, ...sceneSchema.gestureControl };
        }

        sceneModel.disableDefaultCanvasClick = !!sceneSchema.disableDefaultCanvasClick;

        const callbacks: ['onSceneInit', 'onRender', 'onReady'] = [ 'onSceneInit', 'onRender', 'onReady' ];

        // @ts-ignore
        if (Object.keys(sceneSchema).some((item) => callbacks.includes(item))) {
            callbacks.forEach((c) => {
                // @ts-ignore
                if (sceneSchema[ c ]) {
                    // @ts-ignore
                    sceneModel.userCallbacks[ c ] = sceneSchema[ c ];
                }
            });
        }

        sceneModel.redirectUrl = sceneSchema.redirectUrl;
        sceneModel.buttonLabelHtml = sceneSchema.buttonLabelHtml;

        return sceneModel;
    }
}

export type SceneFactoryType = SceneFactory
