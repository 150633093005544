// @flow
import * as React from 'react';

/**
 * Abstract class. React Component
 */
export class InjectedComponent<
  P extends {},
  I extends {},
  S extends {} = {}
> extends React.Component<P, S> {
  get injected() {
    return this.props as P & I;
  }
  // props: P & I = this.props;
}

export default InjectedComponent;
