// extend Attachment Model
import { SandpackFile }                                    from "@codesandbox/sandpack-react";
import { AttachmentModel }                                 from "@geenee/shared/src/magellan/model/attachment.model";
import { injectable, postConstruct }                       from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class CustomCodeAttachmentModel extends AttachmentModel {
    @observable json: Record<string, SandpackFile> = {};
    // temp

    @observable module = { exports: {} };
    @observable initialOptions = {};

    @postConstruct()
    init() {
        makeObservable(this);
    }

    @action
    async load() {
        const json = await fetch(this.url)
            .then((r) => r.json());
        this.json = json;
        const configModule = json[ "/src/config.json" ];
        if (configModule) {
            this.initialOptions = { ...JSON.parse(json[ "/src/config.json" ].code), ...this.initialOptions };

            this.options = {
                ...this.initialOptions,
                ...this.options,
                properties: {
                    ...this.initialOptions.properties,
                    ...this.options.properties
                }
            };
        }
    }

    @action
        loadAndEval = async () => {
            const json = await fetch(this.url)
                .then((r) => r.json());
            if (!json || !json[ "/src/index.ts" ] || !json[ "/src/config.json" ]) {
                return;
            }
            runInAction(() => {
                console.log(json);
                this.initialOptions = { ...JSON.parse(json[ "/src/config.json" ].code) };
                this.options = {
                    ...this.initialOptions,
                    ...this.options,
                    ...this.$parent.options
                };
            });

            this.json = json;

            const worker = new Worker(new URL("../service/gigger-worker/gigger.worker.ts", import.meta.url), { type: "module" });

            const workerResult = await new Promise((resolve) => {
                worker.onmessage = async (message) => {
                    console.log({ message });
                    const {
                        type,
                        data
                    } = message.data;
                    if (type === "export") {
                        const moduleFunction = new Function("module", "exports", data);
                        runInAction(async () => {
                            await moduleFunction(this.module, this.module.exports);
                            resolve(this.module.exports);
                        });
                    }
                    if (type === "error") {
                        console.error(data);
                    }
                };
                worker.postMessage({ entry: "/src/index.ts", modules: json });
            }).catch((e) => {
                console.error(e);
            });

            return workerResult;
        };
}
