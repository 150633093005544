import { AUTHORIZATION } from "@geenee/shared/src/util/constants";
import axios, {
    AxiosInstance,
    AxiosInterceptorManager,
    AxiosRequestConfig,
    AxiosResponse
} from "axios";
import { injectable } from "inversify";

export const USER_SERVER_URL = "https://zlppglhwdd.execute-api.eu-central-1.amazonaws.com/dev";

type AxiosClient = Pick<AxiosInstance, keyof AxiosInstance>
const defaultConfig = {} as AxiosRequestConfig;
export type HttpClientConfig = Readonly<typeof defaultConfig>

@injectable()
export class HttpClient implements AxiosClient {
    defaults: AxiosRequestConfig;
    interceptors: {
        request: AxiosInterceptorManager<AxiosRequestConfig>
        response: AxiosInterceptorManager<AxiosResponse<any>>
    };
    rejectUnauthorized = false;
    private _provider: AxiosInstance;

    constructor() // @Optional()
    // @Inject(HttpClientConfig)
    //     config: HttpClientConfig
    // eslint-disable-next-line brace-style
    {
        // this._provider = axios.create({ ...config, ...defaultConfig });
        this._provider = axios.create({ ...defaultConfig });

        this._provider.interceptors.request.use((config) => {
            const token = localStorage.getItem(AUTHORIZATION);
            config.headers.Authorization = token ? `Bearer ${ token }` : "";
            return config;
        });
        // this._provider.post(
        //     `${ USER_SERVER_URL }/login`,
        //     {
        //         email:    'test@xr-redbull.com',
        //         password: 'test@xr-redbull.com'
        //     }
        // ).then((res) => res.data).then((data) => {
        //     this._authKey = `Bearer ${ data.token }`;
        //     localStorage.setItem('authorization', data.token);
        // });

        this._provider.interceptors.response.use((response) => response, async (error) => {
            const status = error?.response?.status || 0;
            const quietCatch: boolean = error?.config?.params?.quietCatch;

            const detail = {
                message: error.response?.data?.detail || error.message,
                ...(quietCatch && { quietCatch })
            };

            if (status === 401) {
                window.dispatchEvent(new Event("unauth"));
            } else if (status === 422) {
                window.dispatchEvent(new CustomEvent("httpError", { detail }));
            } else if (status > 200 && status !== 301 && status !== 302) {
                window.dispatchEvent(new CustomEvent("httpError", { detail }));
            } else {
                window.dispatchEvent(new CustomEvent("httpError", { detail }));
            }
            throw error;
        });
        // Promise.reject(error));
        this.interceptors = this._provider.interceptors;
        this.defaults = this._provider.defaults;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getUri(config?: AxiosRequestConfig | undefined): string {
        throw new Error("Method not implemented.");
    }

    options<T = any, R = AxiosResponse<T>>(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        url: string,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        config?: AxiosRequestConfig | undefined
    ): Promise<R> {
        throw new Error("Method not implemented.");
    }

    request<T = any>(config: AxiosRequestConfig): Promise<T> {
        return this._provider.request(config);
    }

    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
        return this._provider.get(url, config);
    }

    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
        return this._provider.delete(url, config);
    }

    head(url: string, config?: AxiosRequestConfig): Promise<any> {
        return this._provider.head(url, config);
    }

    post<T = any>(
        url: string,
        data?: any,
        config?: AxiosRequestConfig
    ): Promise<T> {
        return this._provider.post(url, data, config);
    }

    put<T = any>(
        url: string,
        data?: any,
        config?: AxiosRequestConfig
    ): Promise<T> {
        return this._provider.put(url, data, config);
    }

    patch<T = any>(
        url: string,
        data?: any,
        config?: AxiosRequestConfig
    ): Promise<T> {
        return this._provider.patch(url, data, config);
    }
}
