import { PopoverModel, PopoverModelType } from "@geenee/shared/src/magellan/model/popover.model";
import { injectable }                     from 'inversify';
import { FactoryAbstract }                from "../../util/factoryContainer";

@injectable()
export class PopoverFactory extends FactoryAbstract {
    create(
        popoverSchema: Partial<PopoverModelType>
    ) {
        const popoverModel: PopoverModel = this.container.get('<PopoverModel>');
        Object.assign(popoverModel, popoverSchema);

        popoverModel.insides = popoverSchema.insides?.map((inside) => {
            const _popoverModel = this.container.get('<PopoverModel>');
            const insides: PopoverModel[] = [];
            inside.insides?.forEach((_inside) => {
                insides.push(this.create(_inside));
            });
            _popoverModel.insides = insides;
            _popoverModel.isOpen = false;
            Object.assign(_popoverModel, inside);
            return _popoverModel;
        }) || [];

        return popoverModel;
    }
}

export type PopoverFactoryType = PopoverFactory
