import React                            from 'react';
import { Analytics }                    from '@geenee/analytics';
import { ProjectModel }                 from '@geenee/shared';
import { InputTableItem }               from '@geenee/ui';
import { ColorPicker }                  from '@geenee/builder/src/components/ColorPicker/ColorPicker';
import { useBuilderInject }             from '@geenee/builder/src/core/hook/use-builder-inject';
import { PROGRAM_HEADER_COLOR_CHANGED } from '@geenee/builder/src/lib/constants';
import theme                            from '@geenee/builder/src/styles/theme';

interface Props {
    brandingBarColor: string;
    disabled?: boolean;
    activeProject: ProjectModel;
}

export function ProgramBrandingBarRow({
    activeProject,
    brandingBarColor,
    disabled
}: Props) {
    const { container } = useBuilderInject();

    const analytics: Analytics = container.get('<Analytics>');

    const colorChangeHandler = async (color) => {
        try {
            activeProject.options.branding_bar_color = color;

            await activeProject.saveData();
            const { id } = activeProject;
            analytics.track(PROGRAM_HEADER_COLOR_CHANGED, { id, color });
        } catch (e) {
            console.error('Error while updating branding bar color');
        }
    };

    return (
        <InputTableItem label="Branding Bar">
            <ColorPicker
                disabled={ disabled }
                color={ brandingBarColor || theme.colors.brandingBarBackground }
                onColorChange={ colorChangeHandler }
            />
        </InputTableItem>
    );
}
