import React                           from 'react';
import { Wrapper }                     from '@geenee/ui';
import { PreviewModalRoot }            from '../styles';
import { CancelButton, ConfirmButton } from './styles';

interface Props {
    onConfirm: () => void,
    onCancel: () => void
}

export function CloseConfirmation({ onConfirm, onCancel }: Props) {
    return (
        <PreviewModalRoot>
            Closing will erase the media. Are you sure?
            <Wrapper row style={ { marginTop: 20 } }>
                <CancelButton onClick={ onCancel }>
                    No
                </CancelButton>
                <ConfirmButton onClick={ onConfirm }>
                    Yes
                </ConfirmButton>
            </Wrapper>
        </PreviewModalRoot>
    );
}
