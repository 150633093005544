import { useEffect, useState } from 'react';

type UseDropdownType = [ string ];

export function useAutoPosition(p: {
    dropdownEl: HTMLElement | null;
    triggerEl: HTMLElement | null;
    openState: boolean;
    position?: string;
}): UseDropdownType {
    const [ autoPosition, setAutoPosition ] = useState('');

    const setVerticalPosition = () => {
        const positionInfo = p.position?.split('-') || [];

        if (p.dropdownEl && p.triggerEl && p.position) {
            const triggerHeight = p.triggerEl.getBoundingClientRect().height;
            const triggerTop = p.triggerEl.getBoundingClientRect().top;
            const dropHeight = p.dropdownEl.getBoundingClientRect().height;
            const windowHeight = window.innerHeight;

            if ((windowHeight - triggerTop - triggerHeight - dropHeight) > 8) {
                setAutoPosition(positionInfo.length > 2 ? `bottom-${ positionInfo[ 2 ] }` : 'bottom');
            } else {
                setAutoPosition(positionInfo.length > 2 ? `top-${ positionInfo[ 2 ] }` : 'top');
            }
        }
    };

    const setHorizontalPosition = () => {
        if (p.dropdownEl && p.triggerEl && p.position) {
            const triggerWidth = p.triggerEl.getBoundingClientRect().width;
            const triggerLeft = p.triggerEl.getBoundingClientRect().left;
            const dropWidth = p.dropdownEl.getBoundingClientRect().width;
            const windowWidth = window.innerWidth;

            if ((windowWidth - triggerLeft - triggerWidth - dropWidth) > 8) {
                setAutoPosition('right');
            } else {
                setAutoPosition('left');
            }
        }
    };
    useEffect(() => {
        const positionInfo = p.position?.split('-') || [];

        if (positionInfo[ 0 ] === 'auto') {
            if (positionInfo[ 1 ] === 'vertical') {
                setVerticalPosition();
            } else {
                setHorizontalPosition();
            }
        }
    }, [ p.dropdownEl, p.triggerEl?.offsetHeight, p.position, p.openState ]);

    return [ autoPosition ];
}
