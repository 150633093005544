import styled from 'styled-components';

export const IconWrapper = styled.div`
    margin-bottom: 12px;
`;

export const TextWrapper = styled.div`
    font-size: 17px;
    width: 245px;
    line-height: 25px;
`;
