import React, { FC, Suspense, useEffect, useRef }                              from "react";
import { useWatermark }                                                        from "@geenee/geeclient-kit/src/lib/component/scene/components/GeeneeARScene/hooks/useWatermark";
import { GeeneeSceneType }                                                     from "@geenee/geeclient-kit/src/lib/component/scene/GeeneeScene.component";
import hdrFile                                                                 from "@geenee/shared/asset/city.hdr";
import { MoleculeModel }                                                       from "@geenee/shared/index";
import { SceneRenderer }                                                       from "@geenee/shared/src/magellan/renderer/r3f-renderer/r3f.renderer";
import { AdaptiveDpr, AdaptiveEvents, Environment, Preload, useContextBridge } from "@react-three/drei";
import { Canvas }                                                              from "@react-three/fiber";
import { MobXProviderContext, observer }                                       from "mobx-react";
import { LinearToneMapping, SRGBColorSpace }                                     from "three";

export interface PreviewOptions {
  trackerUrl?: string;
  rendererSize?: { width: number; height: number };
  lookAtObjectCenter?: boolean;
  customDistance?: number;
  moleculeWithSceneStats?: MoleculeModel;
}

type PropsType = {
  width: number;
  height: number;
  isReady?: boolean;
  activeSceneModel?: SceneRenderer;
  blockingRender?: boolean;
  previewOptions?: PreviewOptions;
  mode?: GeeneeSceneType;
  onSceneInit?: (context: CanvasRenderingContext2D) => Promise<void>,
  children: JSX.Element | JSX.Element[]
};

export const GeeneeARScene: FC<PropsType> = observer(
    ({
        activeSceneModel,
        children,
        width,
        height,
        mode,
        blockingRender = false,
        onSceneInit
    }: // eslint-disable-next-line @typescript-eslint/no-unused-vars
        PropsType & { diContext?: any }) => {
        if (!activeSceneModel) {
            return null;
        }
        const ContextBridge = useContextBridge(MobXProviderContext);
        const bgCanvasRef = useRef(null);

        const setupScene = async (context) => {
            if (mode !== "preview") {
                context.camera.position.set(0, 0, 0);
                context.camera.near = 0.01;
                context.camera.far = 10000;
                context.camera.fov = activeSceneModel.scene_experience_type === "bodytracking" ? 10 : 60;
                context.camera.aspect = width / height;
                context.camera.updateProjectionMatrix();
            }
            context.scene.fog = false;

            const renderer = context.gl;
            renderer.toneMapping = LinearToneMapping;
            renderer.outputColorSpace = SRGBColorSpace;
            renderer.useLegacyLights = true;
            activeSceneModel.setup(
                context,
                context.scene,
                context.camera
            );
            onSceneInit && await onSceneInit(context);
            if (mode !== "preview") {
                activeSceneModel?.emitter.emit("geenee-start-animation-loop");
            }
        };

        useEffect(
            () => () => {
                activeSceneModel?.emitter.emit("geenee-stop-animation-loop");
            },
            [ activeSceneModel ]
        );

        useWatermark();

        return (
            <div
                className={
                    mode === "cms" && process.env.ENV_GEENEE_APP !== "BUILDER"
                        ? "absolute-pos"
                        : mode === "preview"
                            ? "preview-pos"
                            : ""
                }
            >
                <Suspense fallback={ null }>
                    <Canvas
                        className={
                            process.env.ENV_GEENEE_APP === "BUILDER" ? "absolute-pos" : ""
                        }
                        style={ {
                            width:
                mode === "preview" || mode === "cms"
                    ? "100%"
                    : (width || window.innerWidth) + 2,
                            height:
                mode === "preview" || mode === "cms"
                    ? "100%"
                    : (height || window.innerHeight) + 2,
                            background:     "transparent",
                            position:       mode !== "preview" ? "fixed" : undefined,
                            zIndex:         2,
                            top:            0,
                            right:          0,
                            bottom:         0,
                            left:           0,
                            // TODO: Remove absolute flow after brandingBar logic updates
                            // marginTop:      process.env.ENV_GEENEE_APP !== 'BUILDER' ? 49 : 0,
                            display:        'flex',
                            justifyContent: 'center'
                        } }
                        frameloop="demand"
                        onCreated={ (context) => {
                            if (activeSceneModel) {
                                setupScene(context);
                            }
                        } }
                        shadows
                        // performance={{ max: 0.6 }}
                        // colorManagement
                        // sRGB
                        flat
                        linear
                        dpr={ [ 1, 2 ] }
                        gl={ {
                            useLegacyLights: true,
                            toneMapping:             LinearToneMapping,
                            outputColorSpace:          SRGBColorSpace,
                            antialias:               true,
                            // alpha: process.env.ENV_GEENEE_APP === 'BUILDER',
                            preserveDrawingBuffer:   true
                            // powerPreference:       'low-power'
                        } }
                        /* gl={ {
// canvas,
// antialias:             true,
alpha:                 process.env.ENV_GEENEE_APP === 'BUILDER',
preserveDrawingBuffer: true
// powerPreference:       'low-power'
} } */
                    >
                        <ContextBridge>
                            <Suspense fallback={ null }>
                                <scene
                                    name="geenee-3d-main-scene"
                                    // @ts-ignore
                                    width={ width }
                                    height={ height }
                                >
                                    { process.env.NODE_ENV === "production" && (
                                        <Environment
                                            files={
                                                // @ts-ignore
                                                process.env.ENV_GEENEE_APP === "BUILDER" ? hdrFile : window.env.APP_DIST_URL + hdrFile
                                            }
                                        />
                                    ) }
                                    { children }
                                </scene>

                                { /* { window?.Geenee?.EffectComposer && ( */ }
                                { /*    window.Geenee.EffectComposer */ }
                                { /* ) } */ }

                                { /* { EffectComposerComponent } */ }
                            </Suspense>

                            <Preload all />
                            <AdaptiveEvents />
                            <AdaptiveDpr pixelated />
                        </ContextBridge>
                    </Canvas>
                </Suspense>
            </div>
        );
    }
);
// eslint-disable-next-line arca/no-default-export
export default GeeneeARScene;
