import styled              from 'styled-components';
import { HintPlaceholder } from '@geenee/builder/src/components/common/Hint/styles';

export const UpperMessage = styled.div`
    p {
        display: inline-block;
    }
`;

export const Link = styled.a`
    text-decoration: underline;
    color: #595959;
    font-style: italic;

    &:hover {
        color: inherit;
    }
`;

export const UnderlinedTableRow = styled.tr`
    border-bottom: 1px solid #ffffff;
`;

export const WithHintWrapper = styled.div`
    display: flex;

    & > span {
        cursor: pointer;

        &:hover {
            font-weight: bold;
        }

        &:hover + ${ HintPlaceholder } {
            visibility: visible;
        }
    }
`;

export const AllPlansContainer = styled.div`
    max-width: 970px;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
`;

export const TipContainer = styled.div`
    position: absolute;
    top: -50px;
    right: 25px;
`;

export const UpperBarArea = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ToggleContainer = styled.div`
    padding-left: 16px;
    width: 210px;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-radius: 10px 10px 0px 0px;

    span {
        font-size: 12px;
    }
`;

export const PlansTableArea = styled.div`
    height: 90%;
    width: 100%;
    padding: 15px 18px;
    background: ${ ({ theme: { colors } }) => colors.panelBackground };
    border-radius: 10px 0px 10px 10px;
`;

export const GrowAnimation = 'GrowAnimation';

export const PricingCell = styled.td`
    padding: 28px 8px 33px;
`;

export const PricingRow = styled.tr`
    font-size: 12px;
    font-weight: inherit;

    @keyframes ${ GrowAnimation } {
        from {
            transform: scaleY(1.6);
        }
        to {
            transform: scaleY(1);
        }
    }

    ${ PricingCell } {
        padding: 32px 0;
        font-size: inherit;
        font-weight: inherit;
    }

    &[${ GrowAnimation }] {
        animation-name: ${ GrowAnimation };
        animation-duration: 0.15s;
        animation-timing-function: linear;
    }
`;

export const YourPlan = styled.td`
    color: #249f8e;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    line-height: 13px;
`;

export const PlansTable = styled.table<{ currentPlan: string }>`
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;

    ${ ({ currentPlan }) => {
        switch (currentPlan) {
            case 'Basic':
                return `
                .basic-plan {
                    background-color: #F7F7F7;
                }
                `;
            case 'Premium':
                return `
                .studio-plan,
                .premium-plan {
                    background-color: #F7F7F7;
                }
                `;
            case 'Enterprise':
                return `
                .pro-plan,
                .professional-plan,
                .enterprise-plan {
                    background-color: #F7F7F7;
                }
                `;
            default:
        }
    } }

    .basic-plan, .studio-plan, .pro-plan, .professional-plan {
        width: 204px;
        padding: 8px;
    }

    tr {
        text-align: center;
    }

    th {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        text-transform: uppercase;
    }

    td {
        font-size: 12px;
        line-height: 20px;
    }

    .first-plan-cell {
        padding-top: 14px;
        border-radius: 10px 10px 0 0;
    }

    .last-plan-cell {
        padding-bottom: 17px;
        border-radius: 0 0 10px 10px;
    }

    ${ YourPlan } {
        padding: 0;
        padding-bottom: 12px;
    }

    td:first-child {
        padding-left: 15px;
        text-align: left;
    }

    .plan-filler {
        width: 31px;
    }

    .buttons-row button {
        margin: 0 auto;
    }
`;

export const ContactBoxArea = styled.div`
    margin-top: 27px;
    padding-top: 26px;
    padding-bottom: 31px;
    background: #042248;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    text-align: center;
`;

export const LimitedOfferLabel = styled.span`
    color: ${ ({ theme: { secondaryColors } }) => secondaryColors.pink40 };
    font-weight: bold;
`;
