export interface CommandType {
    receiver?: object
    entity?: object | string
    bypassHistory?: boolean;
    execute: () => void
    revert: () => void
}

export abstract class AbstractCommand <T extends CommandType> {
    protected bypassHistory = false;
}
