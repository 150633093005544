export const calculateNewDimensions = (
    imgWidth: number,
    imgHeight: number,
    dimensionWidth: number,
    dimensionHeight: number
) => {
    let height = imgHeight;
    let width = imgWidth;
    if (imgWidth > dimensionWidth) {
        if (imgHeight <= dimensionHeight || imgWidth >= imgHeight) {
            const coef = imgWidth / imgHeight;
            width = dimensionWidth;
            height = Math.floor(width / coef);
        } else {
            const coef = imgHeight / imgWidth;
            height = dimensionHeight;
            width = Math.floor(height / coef);
        }
    } else if (imgHeight > dimensionHeight) {
        const coef = imgHeight / imgWidth;
        height = dimensionHeight;
        width = Math.floor(height / coef);
    }
    return { width, height };
};

export const compress = (
    target: { files: File[] | FileList | null },
    dimensionWidth: number,
    dimensionHeight: number
): Promise<File> => {
    let resolve: (file: File) => void = () => target.files![ 0 ];
    let reject: (err: Event | string) => void;
    const resultPromise = new Promise<File>((res, rej) => {
        resolve = res;
        reject = rej;
    });
    const fileName = target.files && target.files[ 0 ].name;
    const fileType = target.files && target.files[ 0 ].type;
    const isGif = fileType === 'image/gif';
    const reader = new FileReader();
    reader.onload = (event) => {
        const img = new Image();
        if (event.target) {
            img.src = (event.target.result as string) || '';
            img.onload = () => {
                const { width, height } = calculateNewDimensions(
                    img.naturalWidth,
                    img.naturalHeight,
                    dimensionWidth,
                    dimensionHeight
                );
                const elem = document.createElement('canvas');
                elem.width = width;
                elem.height = height;
                const ctx = elem.getContext('2d');
                if (ctx) {
                    ctx.drawImage(img, 0, 0, width, height);
                    ctx.canvas.toBlob(
                        (blob) => {
                            if (blob) {
                                // eslint-disable-next-line no-new
                                const file = new File([ blob ], fileName || '', {
                                    type:         'image/png',
                                    lastModified: Date.now()
                                });
                                resolve(file);
                            }
                        },
                        'image/png',
                        1
                    );
                }
            };
            img.onerror = (error) => {
                console.log(error);
                reject(error);
            };
        }
        reader.onerror = (error) => {
            console.log(error);
            reject(error);
        };
    };

    if (target.files && isGif) {
        const file = target.files[ 0 ] as File;
        resolve(file);
    } else if (target.files && !isGif) {
        reader.readAsDataURL(target.files[ 0 ]);
    }

    return resultPromise;
};
