import React, { ChangeEvent, useRef } from 'react';
import { ArAssetUploadBox }           from '@geenee/geeclient-kit/src/lib/component/scene/components/Uploader/styles';
import Trash                          from '@geenee/shared/asset/icons/Trash';
import { Wrapper }                    from '@geenee/ui';
import { useTheme }                   from 'styled-components';
import Upload                         from '@geenee/builder/src/asset/icons/Upload';
import { QrRowWrapper }               from '../styles';

type Props = {
  qrImageUrl: undefined | string;
  withoutLabel?: boolean
  onFileSelect: (e: ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void
}

export function ImageRow({ qrImageUrl, onFileSelect, withoutLabel, onDelete }: Props) {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    // @ts-ignore
    const { secondaryColors } = useTheme();

    return (
        <QrRowWrapper>
            { withoutLabel ? <></> : <>Logo Icon</> }
            <input
                type="file"
                name='image'
                accept='.png,.jpg,.jpeg'
                onChange={ onFileSelect }
                hidden
                ref={ fileInputRef }
            />
            <ArAssetUploadBox
                onClick={ () => fileInputRef.current?.click() }
            >
                { qrImageUrl ? <img src={ qrImageUrl } />
                    : <Upload fill={ secondaryColors.grey30 } /> }
            </ArAssetUploadBox>
            { qrImageUrl ? (
                <Wrapper
                    frame="solid-white"
                    align='right'
                    onClick={ onDelete }
                >
                    <Trash />
                </Wrapper>
            ) : <></> }
        </QrRowWrapper>
    );
}
