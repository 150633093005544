import { useContext } from 'react';
import { Container } from 'inversify';
import { MobXProviderContext } from 'mobx-react';
import { CustomColorState } from '../model/state/custom-color-state';
import { ModalsStateStore } from '../model/state/modals-state.store';
import SiteCreateFromTemplateState from '../model/state/site-create-from-template-state';
import SiteEditState from '../model/state/site-edit-state';
import BuilderStore from '../model/store/builder-store';
import { ColorStore } from '../model/store/color-store';
import CountryStore from '../model/store/country-store';
import { CrmState } from '../model/state/crm-state';
import FileStore from '../model/store/file-store';
import FontStore from '../model/store/font-store';
import FormStore from '../model/store/form-store';
import LanguageStore from '../model/store/language-store';
import LayoutStore from '../model/store/layout-store';
import LeadStore from '../model/store/lead-store';
import PrebuiltCategoryStore from '../model/store/prebuilt-category-store';
import PrebuiltStore from '../model/store/prebuilt-store';
import RouterStore from '../model/store/router-store';
import RouterStoreCrm from '../model/store/router-store-crm';
import SiteStore from '../model/store/site-store';
import TemplateCategoryStore from '../model/store/template-category-store';
import TemplateStore from '../model/store/template-store';
import UnsplashStore from '../model/store/unsplash-store';
import UserStore from '../model/store/user-store';

interface InjectedMap {
  container: Container;
  RouterStore: RouterStore;
  RouterStoreCRM: RouterStoreCrm;
  SiteStore: SiteStore;
  TemplateStore: TemplateStore;
  TemplateCategoryStore: TemplateCategoryStore;
  BuilderStore: BuilderStore;
  LayoutStore: LayoutStore;
  PrebuiltStore: PrebuiltStore;
  PrebuiltCategoryStore: PrebuiltCategoryStore;
  UserStore: UserStore;
  FormStore: FormStore;
  LeadStore: LeadStore;
  CRMState: CrmState;
  ColorStore: ColorStore;
  FontStore: FontStore;
  FileStore: FileStore;
  UnsplashStore: UnsplashStore;
  CountryStore: CountryStore;
  LanguageStore: LanguageStore;
  SiteEditState: SiteEditState;
  CustomColorState: CustomColorState;
  SiteCreateFromTemplate: SiteCreateFromTemplateState;
  ModalsState: ModalsStateStore;
}

export const useInject = (): InjectedMap => useContext(MobXProviderContext);
