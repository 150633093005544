import { ScenePlugin, Size, VideoPlugin } from "@geenee/armature";
import { SceneRenderer }                  from "@geenee/shared/src/magellan/renderer/r3f-renderer/r3f.renderer";
import * as three                         from "three";
import { Renderer }                       from '../renderer';

/**
 * Generic three.js renderer
 *
 * Extends [[SceneRenderer]] for three.js rendering engines
 * ThreeRenderer does the basic initialization of three.js
 * engine, scene, and camera. It's generic class that should
 * be parameterized by type of processing results to build
 * application using particular [[Processor]] implementation.
 *
 * @template ResultT - Type of processing results
 */

// fiber renderer
// try to extend from ThreeRenderer and SceneRenderer<>
export class ThreeRenderer<ResultT extends {} = {}, SceneT = three.Scene>
    extends Renderer {
    /** Camera's vertical angle of view */
    protected cameraAngle = 10 / 180 * Math.PI;
    protected plugins: (ScenePlugin<ResultT, SceneT> | VideoPlugin<ResultT>)[] = [];
    protected videoCanvas: HTMLCanvasElement = document.createElement('canvas');
    protected videoCtx = this.videoCanvas.getContext('2d', {
        desynchronized:        true,
        preserveDrawingBuffer: true,
        alpha:                 true
    }) as CanvasRenderingContext2D | null;
    protected aspectRatio = 1;
    protected mode: 'fit' | 'crop' = 'crop';
    /** Rendering engine */
    protected renderer: three.WebGLRenderer;
    /** Camera instance */
    protected camera: three.Camera;
    protected scene: three.Scene;
    protected mirror = false;

    assignVideoCanvasStyles() {
        this.videoCanvas.style.position = 'absolute';
        this.videoCanvas.style.top = '0';
        this.videoCanvas.style.left = '0';
        this.videoCanvas.style.zIndex = '-1';
    }

    protected updateSizes = (containerRatio: number) => {
    // Preserve aspect ratio
        let width = 1;
        let height = 1;
        if (containerRatio > this.aspectRatio) {
            if (this.mode === 'fit') width = this.aspectRatio / containerRatio;
            else height = containerRatio / this.aspectRatio;
        } else if (this.mode === 'fit') height = containerRatio / this.aspectRatio;
        else width = this.aspectRatio / containerRatio;

        // Set style
        const widthStr = `${ width * 100 }%`;
        const heightStr = `${ height * 100 }%`;
        const leftStr = `${ (1 - width) / 2 * 100 }%`;
        const topStr = `${ (1 - height) / 2 * 100 }%`;
        // for (const layer of layers) {
        const layer = this.videoCanvas;
        layer.style.width = widthStr;
        layer.style.height = heightStr;
        layer.style.left = leftStr;
        layer.style.top = topStr;
        // }

        this.renderer.setSize(
            this.canvas.clientWidth,
            this.canvas.clientHeight
        );
    };

    protected handleResize = (entries: ResizeObserverEntry[]) => {
        if (entries.length < 1) return;
        // @ts-ignore
        const size = entries[ 0 ].contentBoxSize[ 0 ];
        this.updateSizes(size.inlineSize / size.blockSize);
    };

    // constructor(protected canvas: HTMLCanvasElement, protected renderer: three.WebGLRenderer, protected scene: three.Scene, protected camera: three.Camera) {
    constructor(protected canvas: HTMLCanvasElement, protected canvasContext: CanvasRenderingContext2D, renderer: three.WebGLRenderer, scene: three.Scene, camera: three.Camera, protected activeSceneModel: SceneRenderer) {
        super();

        this.renderer = renderer;
        this.scene = scene;
        this.camera = camera;

        this.assignVideoCanvasStyles();
        document.body.appendChild(this.videoCanvas);

        // @ts-ignore
        const observer = new ResizeObserver(this.handleResize);
        observer.observe(canvas.parentElement);
    }

    async load() {
    // Initialize attached plugins
        if (this.loaded) return;
        await Promise.all(this.plugins.map(
            (p) => p.load(this.scene as unknown as SceneT)
        ));
        await super.load();
    }

    unload() {
    // Unload attached plugins
        this.plugins.forEach(
            (p) => p.unload()
        );
        super.unload();
    }

    /**
   * Update and render the scene
   *
   * Virtual method updating and rendering 3D scene.
   * Basic implementation for three.js engine calls
   * `this.renderer.render(this.scene, this.camera)`.
   *
   * @override
   */
    protected updateScene() {
    // Render the scene
        if (this.scene) this.renderer.render(this.scene, this.camera);
    }

    /**
   * Set camera parameters
   *
   * Setups [[ThreeRenderer.camera | camera]] instance
   * according to parameters provided by [[Processor]].
   *
   * @param size - Resolution of input video
   * @param ratio - Aspect ration of input video
   * @override
   */
    setupCamera(ratio: number, angle: number) {
        super.setupCamera(ratio, angle);
        if (this.camera instanceof three.PerspectiveCamera) {
            this.camera.aspect = this.cameraRatio;
            this.camera.fov = this.cameraAngle / Math.PI * 180;
            this.camera.updateProjectionMatrix();
        }
    }

    protected updateVideo(stream: HTMLCanvasElement) {
        const { videoCtx } = this;
        if (!videoCtx) return;
        const { width, height } = this.videoSize;
        videoCtx.clearRect(0, 0, width, height);
        videoCtx.drawImage(stream, 0, 0);
    }

    async addPlugin(plugin: ScenePlugin<ResultT, SceneT> | VideoPlugin<ResultT>) {
        if (this.loaded && !plugin.loaded) await plugin.load(this.scene as unknown as SceneT);
        this.plugins.push(plugin);
    }

    removePlugin(plugin: any) {
        const { plugins } = this;
        const i = plugins.indexOf(plugin);
        if (i < 0) return;
        plugins[ i ].dispose();
        plugins.splice(i, 1);
    }

    async update(result: any, stream: HTMLCanvasElement) {
    // Update the video layer
        this.updateVideo(stream);
        // Updated attached scene and video plugins
        await Promise.all(this.plugins.map((p) => {
            if (p instanceof ScenePlugin) return p.update(result, stream);
            return p.update(result, stream, this.videoCtx);
        }));
        // Render the scene
        this.updateScene();
        // Call all the callbacks
        this.activeSceneModel.onRender();
        return super.update(result, stream);
    }

    /**
   * Set camera parameters
   *
   * If [[ThreeRenderer.camera | camera]] is OrthographicCamera
   * sets orthographic projection according to video resolution.
   *
   * @param size - Resolution of input video
   * @param ratio - Aspect ratio of input video
   * @override
   */
    setupVideo(size: Size, ratio?: number) {
        super.setupVideo(size, ratio);
        this.videoCanvas.width = this.videoSize.width;
        this.videoCanvas.height = this.videoSize.height;
        this.setAspectRatio(this.videoRatio);
    }

    setAspectRatio = (ratio: number) => {
        this.aspectRatio = ratio;
        this.updateSizes(window.innerWidth
      / window.innerHeight);
    };

    setMirror(mirror?: boolean) {
        this.mirror = mirror;
        [ this.canvas, this.videoCanvas ].forEach((canvas) => {
            canvas.style.transform = mirror ? "scaleX(-1)" : "";
        });
    }
}
