import React, { useEffect }     from 'react';
import { Description, Wrapper } from '@geenee/ui';
import isEmpty                  from 'lodash-es/isEmpty';
import { observer }             from 'mobx-react';
import SpotifyLogo              from '@geenee/builder/src/asset/icons/SpotifyLogo';
import { spotifyState }         from '@geenee/builder/src/core/state/spotify.state';
import { SPOTIFY_PAGE_LIMIT }   from '@geenee/builder/src/lib/constants';
import { MoleculeModel }        from '@geenee/builder/src/magellan/model/molecule.model';
import FoundTracks              from './FoundTracks';
import SearchBar                from './SearchBar';
import {
    HeaderTitle,
    SpotifyWrapper
}                                                           from './styles';

const SpotifyContainer = observer(({ activeMolecule }: {activeMolecule: MoleculeModel}) => {
    const { foundTracksArr, choosedTrack } = spotifyState;

    const searchInputOnChangeHandler = (searchStringValue: string) => {
        spotifyState.search({
            query:  searchStringValue,
            offset: 0,
            limit:  SPOTIFY_PAGE_LIMIT,
            page:   0
        });
    };

    useEffect(() => {
        const spotifyId = activeMolecule.audioAtom?.firstAsset?.options?.audio_spotify_id;
        if (!choosedTrack.preview_url && activeMolecule && spotifyId) {
            spotifyState.getTrack(spotifyId);
        }
    }, []);

    return (
        <SpotifyWrapper>
            <Wrapper fullWidth>
                <Wrapper row fullWidth align="center" valign="center" margin='sm'>
                    <SpotifyLogo />
                    <HeaderTitle>Spotify</HeaderTitle>
                </Wrapper>
                <Wrapper margin='md' align='center'>
                    <Description size='sm'>Add 30 second sound clips right from Spotify.</Description>
                </Wrapper>
                <SearchBar
                    searchString={
                        // eslint-disable-next-line max-len
                        `${ activeMolecule.audioAtom?.firstAsset?.options?.audio_artists || '' } ${ activeMolecule.audioAtom?.firstAsset?.options?.audio_name || '' }`.trim()
                    }
                    handleQueryChange={ searchInputOnChangeHandler }
                />
            </Wrapper>
            { !isEmpty(foundTracksArr) && <FoundTracks collection={ foundTracksArr } choosedTrack={ choosedTrack } /> }
        </SpotifyWrapper>
    );
});

// eslint-disable-next-line arca/no-default-export
export default SpotifyContainer;
