import React, { useEffect, useState } from 'react';
import { useMediaQuery }              from 'react-responsive';
import { useLocation }                from 'react-router';
import {  useInject }                 from '@geenee/shared';
import { cn, Wrapper }                from '@geenee/ui';
import { observer }                   from 'mobx-react';
import { MobileWrapper }              from '@geenee/builder/src/components/MobileWrapper/MobileWrapper';
import { PanelToggler }               from '@geenee/builder/src/components/Tree/PanelToggler';
import { WizardFormSwitcher }         from '@geenee/builder/src/components/WizardForm/WizardFormSwitcher';
import { MagellanState }              from '@geenee/builder/src/magellan/state/app.state';
import { HeaderButtons }              from './HeaderButtons';
import './styles.scss';

const className = cn('composer');

const useUrlChange = (AppState: MagellanState) => {
    const location = useLocation();
    useEffect(() => {
        if (AppState) {
            AppState.onRouteChange(location as any);
        }
    }, [ location.pathname ]);
};

export const Composer = observer(() => {
    const isMobile = useMediaQuery({ query: '(max-width: 910px)' });
    const [ panelOpened, setPanelOpened ] = useState(!isMobile);
    const { AppState } = useInject();
    useUrlChange(AppState as unknown as MagellanState);

    useEffect(() => {
        if (isMobile) {
            setPanelOpened(!isMobile);
        }
    }, [ isMobile ]);

    return (
        <>
            <MobileWrapper />
            <Wrapper
                padding="xxl"
                fullHeight
                className={ className('right-section', { closed: !panelOpened }) }
            >
                <Wrapper fullHeight fullWidth maxWidth='695px'>
                    <HeaderButtons />
                    <WizardFormSwitcher />
                </Wrapper>
                <PanelToggler
                    mode="left"
                    onTogglePanel={ () => setPanelOpened(!panelOpened) }
                    toggle={ panelOpened }
                />
            </Wrapper>
        </>
    );
});
