import * as React                   from "react";
import { memo, useRef, useState }   from "react";
import { FormLineWrapperComponent } from "@geenee/geespector/src/components/formLineWrapper/formLineWrapperComponent";
// @ts-ignore
import { Button, Wrapper }          from "@geenee/ui";
import { ButtonPropsType }          from "@geenee/ui/src/lib/component/button/button.component";
import { v4 }                       from "uuid";

interface IFileButtonLineComponentProps {
    label: string | React.ReactNode;
    onClick: (file: File) => void;
    accept: string;
    icon?: ButtonPropsType['icon'];
    iconLabel?: string;
    border?: boolean;
    onError?: (error: string) => void;
    error?: string
    onlyButton?: boolean
    ratio?: number
}

type UploadButtonProps = {
  accept: string
  icon?: ButtonPropsType['icon'];
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string | React.ReactNode
}
export class FileButtonLineComponent extends React.Component<IFileButtonLineComponentProps> {
    constructor(props: IFileButtonLineComponentProps) {
        super(props);
    }

    onChange(evt: any) {
        const { files } = evt.target;
        if (files && files.length) {
            const splitFileName = files[ 0 ].name.split('.');
            const fileType = splitFileName[ splitFileName.length - 1 ];

            if (this.props.accept.includes(`.${ fileType.toLowerCase() }`)) {
                if (this.props.error && this.props.onError) {
                    this.props.onError('');
                }
                this.props.onClick(files[ 0 ]);
            } else if (this.props.onError) {
                this.props.onError('Wrong file format');
            }
        }

        evt.target.value = "";
    }

    render() {
        if (this.props.onlyButton) {
            return (
                <UploadButton
                    onChange={ (evt) => this.onChange(evt) }
                    accept={ this.props.accept }
                    icon={ this.props.icon }
                    label={ this.props.label }
                />
            );
        }
        if (this.props.icon) {
            return (
                <FormLineWrapperComponent
                    title={ this.props.label }
                    ratio={ this.props.ratio }
                    wrapperProps={ { align: "right" } }
                    border={ this.props.border || true }
                >
                    <UploadButton
                        onChange={ (evt) => this.onChange(evt) }
                        accept={ this.props.accept }
                        icon={ this.props.icon }
                    />
                </FormLineWrapperComponent>
            );
        }

        return (
            <FormLineWrapperComponent
                ratio={ this.props.ratio }
                border
                title=""
                className="buttonLine"
            >
                <UploadButton
                    onChange={ (evt) => this.onChange(evt) }
                    accept={ this.props.accept }
                    label={ this.props.label }
                />
            </FormLineWrapperComponent>
        );
    }
}

const UploadButton = memo((props: UploadButtonProps) => {
    const [ id ] = useState(v4());
    const uploadInputRef = useRef<HTMLInputElement | null>(null);
    return (
        <>
            { props.icon ? (
                <Wrapper frame='solid-white' maxWidth="fit-content" radius='lg'>
                    <Button
                        size="sm"
                        icon={ props.icon }
                        viewType="secondary"
                        radius='lg'
                        iconSize={ 13 }
                        onClick={ () => uploadInputRef.current?.click() }
                    />
                </Wrapper>

            ) : (
                <Button
                    size="sm"
                    viewType="secondary"
                    radius="md"
                    fullWidth
                    onClick={ () => uploadInputRef.current?.click() }
                >
                    { props.label }
                </Button>
            ) }
            <input
                hidden
                ref={ uploadInputRef }
                id={ `file-upload${ id }` }
                type="file"
                accept={ props.accept }
                onChange={ (evt) => props.onChange(evt) }
            />
        </>
    );
});
