import { Size } from "@geenee/armature";

let captureTime = 0;

export const captureFrame = (video: HTMLVideoElement, context: CanvasRenderingContext2D) => {
  if (video.currentTime > captureTime) {
    captureTime = video.currentTime;
    const { videoWidth, videoHeight } = video;
    context.canvas.width = videoWidth;
    context.canvas.height = videoHeight;
    context.clearRect(0, 0, videoWidth, videoHeight);
    context.drawImage(video, 0, 0);
  }
}

export const getImageFromCanvas = (context: CanvasRenderingContext2D, rszContext: CanvasRenderingContext2D, width: number, height: number): ImageData => {
  const videoWidth = context.canvas.width;
  const videoHeight = context.canvas.height;

  if (videoWidth === width && videoHeight === height) {
    return context.getImageData(0, 0, width, height);
  }

  const sceneAspect = parseFloat((width / height).toFixed(4));
  const videoAspect = parseFloat((videoWidth / videoHeight).toFixed(4));

  let scale = 1.0;
  if (sceneAspect > videoAspect) {
    scale = width / videoWidth;
  } else {
    scale = height / videoHeight;
  }

  const sw = width / scale;
  const sh = height / scale;
  const sx = (videoWidth - sw) / 2;
  const sy = (videoHeight - sh) / 2;
  rszContext.canvas.width = width;
  rszContext.canvas.height = height;
  rszContext.clearRect(0, 0, width, height);
  rszContext.drawImage(context.canvas, sx, sy, sw, sh, 0, 0, width, height);
  return rszContext.getImageData(0, 0, width, height);
}

export const scaleFromSize = (size: Size, sceneWidth: number, sceneHeight: number): number => {
  const sceneAspect = sceneWidth / sceneHeight;
  const { width: videoWidth, height: videoHeight } = size;
  const videoAspect = videoWidth / videoHeight;

  let scale = 1.0;
  if (sceneAspect > videoAspect) {
    scale = sceneWidth / videoWidth;
  } else {
    scale = sceneHeight / videoHeight;
  }

  return scale;
}
