import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '20',
    height = '20',
    onClick,
    id,
    activated = false,
    fill = 'black',
    beIcon = false
}: SvgIconProps) {
    // eslint-disable-next-line no-shadow
    const setActivationStatusColors = (activated: boolean, beIcon: boolean) => {
        if (beIcon) {
            return {
                circle:  fill,
                outline: fill,
                tick:    'white'
            };
        }
        if (activated) {
            return {
                circle:  'black',
                outline: 'black',
                tick:    'white'
            };
        }
        return {
            circle:  'white',
            outline: 'black',
            tick:    'black'
        };
    };
    const checkboxColors = setActivationStatusColors(activated, beIcon);

    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            onClick={ onClick }
        >
            <circle cx="10" cy="10" r="10" fill={ checkboxColors.outline } />
            <circle cx="10" cy="10" r="9" fill={ checkboxColors.circle } />
            <path
                d="M5 11L7.92929 13.9293C7.96834 13.9683 8.03166 13.9683 8.07071 13.9293L15 7"
                stroke={ checkboxColors.tick }
                strokeWidth="1.7"
                strokeLinecap="round"
            />
        </svg>
    );
}
