import React          from 'react';
import { useHistory } from "@geenee/common";
import {
    Button, Card, Description, Divider, Icon, Span, Wrapper
} from "@geenee/ui";
import { observer }    from "mobx-react";
import { stripeState } from "@geenee/builder/src/core/state/stripe.state";
import { teamState }   from "@geenee/builder/src/core/state/team.state";

export const ChangePlanInfo = observer(() => {
    const maxMembersPerPlan = stripeState.plan_params.seats - 1;
    const team = Array.from(teamState.userStore.values());
    const { invites } = teamState;
    const history = useHistory();

    if (stripeState.isEnterprise) {
        return <></>;
    }
    return (
        <Card
            styles={ { height: "fit-content" } }
            row
            fullHeight
            maxWidth="40%"
            mediaBefore
            media={ (
                <Icon size={ 64 } rounded={ false } name="stats" frame="default" radius="lg" color="gradient-warm-3" />
            ) }
        >
            <Divider transparent margin="sm" />
            <Wrapper padding="xs">
                <Description margin="xs" weight="bold" lineHeight="lg" size="lg">Team Members count status</Description>
                <Wrapper style={ { width: "400px" } }>
                    <Description noWrap>
                        <Span weight="bold">
                            You can add up to
                            { " " }
                            { (maxMembersPerPlan - (team.length + invites.length)) }
                            { " " }
                            more people to your team.
                        </Span>
                    </Description>
                    <Description>
                        <Span color="grey-9">Need more team members? See the</Span>
                        { " " }
                        <Span>
                            <Button
                                disableBorderRadius
                                viewType="link"
                                size="lg"
                                onClick={ () => {
                                    history.push("/all-plans");
                                } }
                            >
                                upgrade plan
                            </Button>
                        </Span>
                        { " " }
                        <Span color="grey-9">options.</Span>

                    </Description>
                </Wrapper>
            </Wrapper>
        </Card>
    );
});
