import React, { useEffect, useRef, useState } from 'react';
import { BabylonRenderer, ExportMode }        from "@geenee/geespector/renderer/babylonjs.renderer";
import GlobalState                            from '@geenee/geespector/src/components/globalState';
import { useInject }                          from '@geenee/shared';
import { ToastProps }                         from "@geenee/ui";
import { observer }                           from 'mobx-react';
import { TYPE_HEAD_TRACKING }                 from "~/lib/constants";
import { AtomModel }                          from '~/magellan/model/atom.model';
import { SceneDropPlaceholder }               from "~/module/edit-asset-3d-scene/modal/SceneDropPlaceholder";
import { SceneHeader }                        from "~/module/edit-asset-3d-scene/modal/SceneHeader";
import { SceneDropWrapperComponent }          from '~/module/geeltf-inspector/component/scene-drop-wrapper.component';
import { InspectorRoot }                      from '~/module/geeltf-inspector/component/styles';
import { useLoadedModels }                    from '~/module/geeltf-inspector/hooks/use-loaded-models.hook';

interface Props {
  onHideModal: () => void
}

export const GltfInspectorSceneComponent = observer(({ onHideModal }: Props) => {
    const { AppState, BuilderState } = useInject();
    const { activeSection : sectionModel, activeMolecule } = AppState;
    const wrapperRef = useRef<HTMLDivElement>(null);
    const { sceneManager } = sectionModel || { sceneManager: undefined };
    const [ renderer, setRenderer ] = useState<BabylonRenderer>();

    if (!sceneManager) return <></>;

    useEffect(() => {
        GlobalState.showToast = (value: ToastProps['toast']) => {
            BuilderState.setToast(value);
        };
        BuilderState.setToastPosition('center');
        return () => {
            BuilderState.setToastPosition('right');
        };
    }, []);
    useEffect(() => {
        sceneManager.initialise(wrapperRef.current);
        const sceneRenderer = sceneManager.sceneRenderer as any as BabylonRenderer;
        setRenderer(sceneRenderer);
        if (sectionModel?.type === TYPE_HEAD_TRACKING) {
            if (sceneRenderer.gridMesh) {
                sceneRenderer.gridMesh.position.y = -0.1;
            }
        }
        let isKeyPressed = false;
        let lastKeyPressTime;

        window.addEventListener('keydown', async (event: KeyboardEvent) => {
            // @ts-ignore
            if (event.target.className.includes("one-ui-input")) {
                return;
            }
            if (event.ctrlKey || event.metaKey) {
                isKeyPressed = true;
                lastKeyPressTime = Date.now();
            }
            if (isKeyPressed && event.which === 83 && Date.now() - lastKeyPressTime < 100) {
                event.preventDefault();

                try {
                    const glb = await sceneRenderer.getSceneGlbInWorker(ExportMode.gltfData);
                    glb.downloadFiles();
                } catch (e) {
                    console.log('Save scene error: ', e);
                }

                isKeyPressed = false;
            }
        });
    }, []);

    useLoadedModels((activeMolecule?.sceneActorAtoms || []) as AtomModel[], renderer, sectionModel?.type);

    return (
        <InspectorRoot>
            <SceneDropWrapperComponent
                // @ts-ignore
                scene={ renderer?.scene }
                renderer={ renderer }
            >
                <SceneHeader onHideModal={ onHideModal } renderer={ renderer } />
                <div ref={ wrapperRef } id="canvas-wrapper" style={ { width: '100%', height: '100%', padding: '0 16px 24px 16px' } } />
            </SceneDropWrapperComponent>
        </InspectorRoot>
    );
});
