import React, { memo }       from 'react';
import { DI, DIContextType } from '@geenee/geeclient-kit/src/lib/context/di.context';
import { observer }          from 'mobx-react';
import { $Diff }             from 'utility-types';

type DiffPropsType = {
    diContext: DIContextType;
}

export function withDIContext<T extends DiffPropsType>(Component: React.FC<T>) {
    return observer((props: $Diff<T, DiffPropsType>) => (
        <DI.Consumer>
            { (context) => <Component { ...props as T } diContext={ context } /> }
        </DI.Consumer>
    ));
}
