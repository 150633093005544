import React               from 'react';
import { useTheme }        from 'styled-components';
import AnalyticsIcon       from '@geenee/builder/src/asset/icons/AnalyticsIcon';
import CheckboxChecked     from '@geenee/builder/src/asset/icons/CheckboxChecked';
import Hint                from '@geenee/builder/src/components/common/Hint';
import { WithHintWrapper } from '@geenee/builder/src/pages/AllPlansPage/styles';

export function AnalyticsDashboard() {
    const { secondaryColors } = useTheme();
    return (
        <tr>
            <td>
                <WithHintWrapper>
                    <span>Analytics Dashboard</span>
                    { ' ' }
                    <Hint
                        title='All campaign data in one place'
                        text="Access a full suite of measurement tools without leaving the platform via our Google Analytics integration."
                        iconElement={ <AnalyticsIcon /> }
                    />
                </WithHintWrapper>

            </td>
            <td className='basic-plan' />
            <td className='plan-filler' />
            <td className='studio-plan' />
            <td className='plan-filler' />
            <td className='pro-plan'>
                { ' ' }
                <CheckboxChecked
                    beIcon
                    fill={ secondaryColors.green70 }
                />
            </td>
        </tr>
    );
}
