import React, { FC, ForwardedRef, forwardRef, memo } from 'react';
import { cn }                                        from '../../util/bem';
import { SizesUnionType, TextColorType }             from "../../util/global-props";
import './description.component.scss';

export type DescriptionPropsType = {
    className?: string;
    children: React.ReactNode;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'lx';
    lineHeight?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    align?: 'left' | 'center' | 'right';
    color?: TextColorType;
    weight?: 'bold' | 'normal' | 'medium' | 'regular' | 'semi-bold';
    margin?: SizesUnionType;
    padding?: SizesUnionType;
    capitalize?: boolean;
    uppercase?: boolean;
    shorten?: boolean;
    fullWidth?: boolean;
    flex?: number | string;
    noWrap?: boolean;
    ref?: React.Ref<HTMLDivElement>;
    style?: React.CSSProperties;
    onClick?: React.EventHandler<React.MouseEvent<HTMLDivElement, PointerEvent>>;
}

const className = cn('description');

export const Description: FC<DescriptionPropsType> = memo(forwardRef((props: DescriptionPropsType, ref: ForwardedRef<HTMLDivElement>) => (
    <div
        ref={ ref }
        className={ className({
            size:       props.size,
            align:      props.align,
            weight:     props.weight,
            color:      props.color,
            uppercase:  props.uppercase,
            padding:    props.padding,
            margin:     props.margin,
            noWrap:     props.noWrap,
            flex:       props.flex,
            lineHeight: props.lineHeight,
            capitalize: props.capitalize,
            shorten:    props.shorten,
            fullWidth:  props.fullWidth
        }, null, props.className) }
        style={ {
            cursor: props.onClick && 'pointer',
            ...props.style
        } }
        onClick={ props.onClick }
    >
        { props.children }
    </div>
)));

Description.defaultProps = { lineHeight: 'md' };
