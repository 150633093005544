import { AttachmentModel, ProjectModel }               from '@geenee/shared';
import { AtomModel }                                   from '@geenee/shared/src/magellan/atom/model/atom.model';
import { Vector3SchemaType }                           from '@geenee/shared/type/atom.type';
import { Nullable }                                    from '@geenee/shared/type/shared.type';
import { inject, injectable }                          from 'inversify';
import { action, computed, observable, ObservableMap } from 'mobx';
import { MoleculeSchemaType }                          from '../../../../type/molecule.type';
import { AtomFactory }                                 from '../../atom/factory/atom.factory';
import { BaseEntityModel }                             from '../../model/base-entity.model';
import { SectionModel }                                from '../../section/model/section.model';

@injectable()
export class MoleculeModel extends BaseEntityModel implements MoleculeSchemaType {
    @observable type: MoleculeSchemaType['type'] = 'scene-actor';
    @observable moleculeSchema!: MoleculeSchemaType;
    @observable section_id: MoleculeSchemaType['section_id'] = '';
    options: MoleculeSchemaType['options'] = {
        scene_molecule_order:        0,
        section_is_visible_in_menu:  false,
        scene_molecule_scale:        [ 1, 1, 1 ],
        scene_molecule_bounding_box: [ 1, 1, 1 ],
        scene_molecule_position:     [ 0, 0, 0 ],
        scene_molecule_rotation:     [ 0, 0, 0 ]
    };
    @observable atoms: MoleculeSchemaType['atoms'] = [];
    @observable custom_code_opened = false;
    @observable atomsRegistry: ObservableMap<string, AtomModel> = new ObservableMap<string, AtomModel>();
    $parent!: SectionModel;
    @inject('<AtomFactory>')
        atomFactory!: AtomFactory;
    @observable thumbnail!: AttachmentModel | null = null;
    @observable thumbnail_id = '';

    @action
        updateState = (item: Partial<MoleculeModel>) => {
            Object.keys(item).forEach((key) => {
            // @ts-ignore
                this[ key ] = item[ key ];
            });
        };

    getAtomByType(type: string) : AtomModel | null {
        return Array.from(this.atomsRegistry.values()).find((el) => el.type === type) || null;
    }

    @computed get nft_published() {
        return this.$parent?.nft_published;
    }

    @action
    async update(value: Partial<MoleculeSchemaType>) {
        Object.assign(value);
        // put request
        // @ts-ignore
        this.saveData();
    }

    @action
    async updateOptions(options: Partial<MoleculeSchemaType['options']>) {
        Object.assign(this.options, options);
        await this.update(this);
    }

    @action
    async updateOption<T = string>(key: string, value: T) {
        this.options[ key ] = value;
        await this.update(this);
    }

  @action
    async getCodeModuleJSON(codeModule: AtomModel) {
        if (!codeModule?.firstAsset.url) {
            return null;
        }
        const result = await fetch(codeModule.firstAsset.url);
        const jsonData = await result.json();
        return jsonData;
    }

  get parent_id() {
      return this.section_id;
  }

    @computed
  get parentProject(): Nullable<ProjectModel> {
      return this.$parent?.parentProject || null;
  }

    @computed
    get sceneActorAtoms(): AtomModel[] {
        return Array.from(this.atomsRegistry.values())
            .filter((el) => el.type === 'scene-actor').sort((a, b) => (a.options.order || 0) - (b.options.order || 0));
    }

    @computed
    get sceneCodeModuleAtoms(): AtomModel[] {
        return Array.from(this.atomsRegistry.values())
            .filter((el) => el.type === 'scene-code-module').sort((a, b) => (a.options.order || 0) - (b.options.order || 0));
    }

    @computed
    get audioAtom() {
        return Array.from(this.atomsRegistry.values()).find((el) => el.type === 'scene-audio');
    }

    @computed
    get sceneTriggerAtom() {
        return Array.from(this.atomsRegistry.values()).find((el) => el.type === 'scene-trigger');
    }

    @computed
    get title(): string | undefined {
        return this.options.molecule_menu_title;
    }

    set title(value: string | undefined) {
        this.options.molecule_menu_title = value;
    }

    @computed
    get position() {
        return this.options.scene_molecule_position || [ 0, 0, 0 ];
    }

    @computed
    get rotation() {
        return this.options.scene_molecule_rotation || [ 0, 0, 0 ];
    }

    @computed
    get scale() {
        return this.options.scene_molecule_scale || [ 1, 1, 1 ];
    }

    @action
        setSceneStats = async (value: {position?: Vector3SchemaType, scale?: Vector3SchemaType, rotation?: Vector3SchemaType}) => {
            if (value.position?.length === 3) {
                this.options.scene_molecule_position = [ ...value.position ];
            }
            if (value.scale?.length === 3) {
                this.options.scene_molecule_scale = [ ...value.scale ];
            }
            if (value.rotation?.length === 3) {
                this.options.scene_molecule_rotation = [ ...value.rotation ];
            }
            await this.update(this);
        };

    @computed
    get assetsLoaded() {
        return this.sceneActorAtoms.every((el) => el.objectsRegistry.size);
    }
}
