import React        from 'react';
import { DropHere } from '@geenee/builder/src/components/DropHere';
import { Root }     from './styles';

export function EmptyGallerySlideWrapper() {
    return (
        <Root>
            <DropHere>
                DRAG & DROP
                <br />
                YOUR IMAGE HERE
                <br />
                (.jpg, .jpeg, .gif)
            </DropHere>
        </Root>
    );
}
