import styled from 'styled-components';

export const Bold = styled.p`
    font-weight: 700;
    font-size: 13px;
`;

export const Wrapper = styled.div`
    width: 600px;
    height: 170px;
    margin: 32px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
`;
