import { AbstractMesh }                 from "@babylonjs/core/Meshes/abstractMesh";
import { TransformNode }                from "@babylonjs/core/Meshes/transformNode";
import { Node }                         from "@babylonjs/core/node";
import { BabylonRenderer }              from "@geenee/geespector/renderer/babylonjs.renderer";
import { PropertyChangedEvent }         from "@geenee/geespector/src/components/propertyChangedEvent";
import { AbstractCommand, CommandType } from '@geenee/shared/src/commander/types';
import GlobalState                      from "../components/globalState";

export class SetNodeParentCommand extends AbstractCommand<CommandType> {
    private prevParent: AbstractMesh['parent'] | null = null;
    private serialize: any;
    receiver: BabylonRenderer;
    metadata: object;
    parent: Node | null;
    constructor(receiver: BabylonRenderer, node: Node, parent: Node | null) {
        super();
        this.receiver = receiver;
        this.metadata = node.metadata;
        this.parent = parent;
    }

    notify(node: Node) {
        const e = new PropertyChangedEvent();
        e.object = node;
        e.property = 'parent';
        e.value = node.parent;
        GlobalState.onPropertyChangedObservable.notifyObservers({ ...e, skipCommandCreation: true });
        GlobalState.onSelectionRenamedObservable.notifyObservers();
    }

    updateParent() {
        const node: TransformNode = this.receiver.getNodeByMetaData(this.metadata);
        node.metadata = this.metadata;
        this.prevParent = node.parent;
        if (!this.parent) {
            if (node.setParent) {
                node.setParent(null);
            } else {
                node.parent = null;
            }
        } else if (node.setParent) {
            node.setParent(this.parent);
        } else {
            node.parent = this.parent;
        }
        this.notify(node);
    }
    execute = () => {
        /* const bodyTypes = [ 'twin', 'avatar' ];
        if (bodyTypes.includes(this.parent?.metadata?.gltf?.extras?.engeenee?.type)) {
            if (!this.checkIfNodeSuitableForBody()) {
                GlobalState.showToast({
                    detail:   'The node is not suitable for body tracking',
                    severity: TOAST_ERROR,
                    summary:  ''
                });
                return;
            }
        } */
        if (!this.serialize) {
            this.receiver.serializeAsBabylon().then((res) => {
                this.serialize = res;
                this.updateParent();
            });
        } else {
            this.updateParent();
        }
    };

    revert = () => {
        this.receiver.clearNodes(true);
        this.receiver.importLocalModelFile();
    };
}
