import styled from 'styled-components';

export const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  background: linear-gradient(180deg,
  ${ ({ theme: { colors } }) => colors.white }  0%,
  ${ ({ theme: { secondaryColors } }) => secondaryColors.pink50 } 100%);
`;
