import React, { FC, forwardRef, memo, useMemo } from 'react';
import { cn }                                   from '../../util/bem';
import { MediaPropsType }                       from '../../util/externalClasses';
import {
    FrameExtensionUnionType,
    RadiusSizesType, ShadowExtensionUnionType, SizesUnionType, TextColorType
} from '../../util/global-props';
import { Wrapper } from '../wrapper/wrapper.component';
import './card.component.scss';

export type CardPropsType = {
  children?: React.ReactNode | string;
  className?: string;
  id?: string;
  row?: boolean;

  align?: 'center' | 'right' | 'left' | 'stretch' | 'space-between' | 'space-around';
  valign?: 'top'|'center'|'bottom' | 'stretch';
  frame?: FrameExtensionUnionType;
  shadow?: ShadowExtensionUnionType;

  color?: TextColorType;
  padding?: SizesUnionType;
  margin?: SizesUnionType;
  fullWidth?: boolean;
  fullHeight?: boolean;
  maxWidth?: string;
  radius?: RadiusSizesType;
  overflow?: 'hidden' | 'scroll' | 'visible';
  flex?: number | string;
  hoverShadow?: 'child' | 'self' | 'none';

  media?: React.ReactNode;
  mediaBefore?: boolean;

  styles?: React.CSSProperties;
  innerRef?: React.Ref<HTMLDivElement>;
  onClick?: React.EventHandler<React.MouseEvent<HTMLDivElement, PointerEvent>>;
  onMouseDown?: React.EventHandler<React.MouseEvent<HTMLDivElement, PointerEvent>>;
  onMouseLeave?: React.EventHandler<React.MouseEvent<HTMLDivElement>>;
  onMouseEnter?: React.EventHandler<React.MouseEvent<HTMLDivElement>>;

  hide?: MediaPropsType;
  xsm?: CardPropsType;
  sm?: CardPropsType;
  md?: CardPropsType;
  lg?: CardPropsType;

}

const className = cn('card');

export const Card: FC<CardPropsType> = memo(forwardRef((props: CardPropsType, ref: React.Ref<HTMLDivElement>) => {
    const MediaContent = useMemo(() => {
        if (!props.media) {
            return null;
        }
        return props.media;
    }, [ props.media ]);
    return (
        <Wrapper
            className={ className({ hoverShadow: props.hoverShadow }, null, props.className) }
            styles={ props.styles }
            row={ props.row }
            shadow={ props.shadow }
            innerRef={ props.innerRef }
            onClick={ props.onClick || (() => {}) }
            onMouseEnter={ props.onMouseEnter }
            onMouseLeave={ props.onMouseLeave }
            onMouseDown={ props.onMouseDown }
            fullWidth={ props.fullWidth }
            fullHeight={ props.fullHeight }
            id={ props.id }
            color={ props.color }
            overflow={ props.overflow }
            maxWidth={ props.maxWidth }
            margin={ props.margin }
            flex={ props.flex }
            align={ props.align }
            valign={ props.valign }
            padding={ props.padding }
            radius={ props.radius }
            frame={ props.frame }
        >
            { props.mediaBefore ? MediaContent : null }
            { props.children }
            { !props.mediaBefore ? MediaContent : null }
        </Wrapper>
    );
}));

Card.defaultProps = { hoverShadow: 'none' };

Card.displayName = 'Card';
