import React                     from 'react';
import { Icon }                  from '@geenee/ui';
import { useModalDialogWrapper } from "~/components/ModalDialogWrapper";
import { TeamLibrary }           from '~/module/team-library';
import { TeamLibraryItemModel }  from '~/module/team-library/state/team-library-item.model';

export const useTeamLibraryModal = (
    onImport?: (asset: TeamLibraryItemModel) => void,
    experienceSize?: number,
    hideAudio?: boolean
) => {
    const [ showModal, hideModal ] = useModalDialogWrapper(
        <TeamLibrary
            experienceSize={ experienceSize }
            hideAudio={ hideAudio }
            onImport={ (el) => {
                if (onImport) {
                    onImport(el);
                    hideModal();
                }
            } }
        />,
        { closeTriggerElement: <Icon color="shade-4" name="closeRound" size={ 24 } /> },
        undefined,
        'off'
    );
    return [ showModal, hideModal ];
};
