import React                              from 'react';
import { AttachmentModel, MoleculeModel } from '@geenee/shared';
import { GLTFComponentPropsType }         from '@geenee/shared/src/magellan/molecule/component/SceneMolecule/GLTF_TEMPLATE';
import { computed }                       from 'mobx';

// @TODO: double-check $parent sequence maybe there is a need to add one '$parent'
// to the chain because of adding the view level
export const sceneMoleculeToGltfTemplatePropsBridge = (sceneMoleculeModel: MoleculeModel): GLTFComponentPropsType => ({
    activeSceneModel: sceneMoleculeModel.$parent.sceneRenderer,
    setIsModelLoaded: (value) => value,
    appState:         sceneMoleculeModel.$parent.$parent.$parent.$parent,
    arModel:          {},
    onLoad:           () => null,
    appRef:           React.createRef(),
    attachmentModel:  new AttachmentModel(),
    audioAtom:        sceneMoleculeModel?.audioAtom
});
