import React, { useState } from 'react';
import {
    Button,
    cn,
    Icon,    InputTableItem,
    Wrapper
} from '@geenee/ui';
import { useTheme }    from 'styled-components';
import { ColorPicker } from '@geenee/builder/src/components/ColorPicker/ColorPicker';

interface Props {
    onChange: (val: string[]) => void
    updateMainColor: (val: string) => void
    initColorsList: string[]
}

const className = cn('fg-colors-picker');

export function FgColorsPicker({ onChange, updateMainColor, initColorsList }: Props) {
    const { colors } = useTheme();
    const [ colorsList, setColorsList ] = useState<string[]>(initColorsList || [ colors.black ]);

    const updateColorsList = (color: string, index: number) => {
        const newColorsList = [ ...colorsList ];
        newColorsList.splice(index, 1, color);
        onChange(newColorsList);
        setColorsList(newColorsList);
    };

    const removeColor = (index: number) => {
        const newColorsList = [ ...colorsList ];
        newColorsList.splice(index, 1);
        setColorsList(newColorsList);
    };

    return (
        <>
            <InputTableItem label="Main color:" viewType="secondary">
                <ColorPicker
                    color={ colorsList[ 0 ] }
                    onColorChange={ (val: string) => {
                        updateColorsList(val, 0);
                        updateMainColor(val);
                    } }
                />
            </InputTableItem>
            <InputTableItem label="Additional colors:" viewType="secondary">
                <Wrapper row className={ className('container') } fullWidth valign="center">
                    { colorsList.length > 1 ? colorsList.slice(1).map((item, index) => (
                        <Wrapper
                            key={ index }
                            className={ className('color-icon') }
                            maxWidth="fit-content"
                        >
                            <Icon
                                className={ className('delete-icon') }
                                size={ 8 }
                                name="delete"
                                onClick={ () => removeColor(index + 1) }
                            />
                            <ColorPicker
                                color={ item }
                                onColorChange={ (val: string) => updateColorsList(val, index + 1) }
                            />
                        </Wrapper>
                    )) : <></> }
                    <Button
                        onClick={ () => setColorsList([ ...colorsList, colors.black ]) }
                        viewType="transparent"
                        rounded
                        style={ { marginTop: '12px' } }
                        size="sm"
                        icon="add"
                        iconSize={ 12 }
                    />
                </Wrapper>
            </InputTableItem>
            { /* </Wrapper> */ }

        </>
    );
}
