import React                                 from 'react';
import { BuilderState }                      from '@geenee/builder/src/core/state/builder.state';
import { teamState }                         from '@geenee/builder/src/core/state/team.state';
import { VALID_EMAIL_REGEXP }                from '@geenee/builder/src/lib/constants';
import { handleError }                       from '@geenee/shared/src/util/errorHandler';
import { inject, injectable, postConstruct } from "inversify";
import { action, makeObservable }            from "mobx";
import { FieldModel, MobxForm }              from './mobx-form';

const fields: FieldModel[] = [
    {
        name:        'first_name',
        type:        'text',
        placeholder: 'First name',
        rules:       { required: 'Please, fill first name' }
    },
    {
        name:        'last_name',
        type:        'text',
        placeholder: 'Last name',
        rules:       { required: 'Please, fill last name' }
    },
    {
        name:        'has_restricted_projects',
        type:        'text',
        placeholder: 'First name'
    },
    {
        name:        'restricted_projects_ids',
        type:        'text',
        placeholder: 'Last name'
    },
    {
        name:        'role',
        type:        'text',
        placeholder: 'First name',
        rules:       { required: 'Please, select the role' }
    },
    {
        name:        'email',
        type:        'text',
        placeholder: 'Email',
        rules:       {
            required: 'This field is required',
            pattern:  {
                value:   VALID_EMAIL_REGEXP,
                message: "Invalid email address"
            }
        }
    }
];

export interface SubmitData {
  first_name: string;
  last_name: string;
  email: string;
  role: 'owner' | 'admin' | 'editor';
  has_restricted_projects: boolean;
  restricted_projects_ids: string[];
  is_active: boolean;
  login_type: string;
  id: string;
}

@injectable()
export class MemberSettings extends MobxForm {
  @inject('<BuilderState>') builderState: BuilderState;
  @postConstruct()
  initialize() {
      makeObservable(this);
  }

  setup(form) {
      this.form = form;
      this.fields = fields;

      this.hooks.onSubmit = this.onSubmit.bind(this);
  }

  @action
  async addNewUser(data: SubmitData) {
      await teamState.inviteUser({ ...data, has_restricted_projects: data.has_restricted_projects || false });
  }

  @action
  async updateMember(data: SubmitData) {
      if (data.role === 'owner') {
          await teamState.updateTeamMember({ role: 'owner', ...data, has_restricted_projects: false });
          await this.builderState.currentUser.profile.updateTeamMember({ role: 'admin' });
      } else {
          await teamState.updateTeamMember(data);
      }
  }
  @action
  async onSubmit(data: SubmitData, _e: React.SyntheticEvent) {
      this.setIsLoading(true);

      try {
          if (data.id) {
              await this.updateMember(data);
          } else {
              await this.addNewUser(data);
          }
      } catch (error) {
          this.setIsLoading(false);
          handleError(error, this.setError);
          this.errorHandler(error);
      }

      this.setIsLoading(false);
  }
}
