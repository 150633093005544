import { Light }                        from "@babylonjs/core/Lights/light";
import { Material }                     from "@babylonjs/core/Materials/material";
import { Texture }                      from "@babylonjs/core/Materials/Textures/texture";
import { Mesh }                         from "@babylonjs/core/Meshes/mesh";
import { TransformNode }                from "@babylonjs/core/Meshes/transformNode";
import { BabylonRenderer }              from "@geenee/geespector/renderer/babylonjs.renderer";
import { AbstractCommand, CommandType } from '@geenee/shared/src/commander/types';

export class DeleteCommand extends AbstractCommand<CommandType> {
    receiver: BabylonRenderer;
    metadata: { [key: string]: any };
    serialize: any;
    constructor(receiver: BabylonRenderer, entity: Texture | Material | TransformNode | Mesh | Light) {
        super();
        this.receiver = receiver;
        this.metadata = entity.metadata;
    }

    execute = () => {
        if (!this.serialize) {
            this.receiver.serializeAsBabylon().then((res) => {
                this.serialize = res;
            });
        }
        const node =  this.receiver.getNodeByMetaData(this.metadata);
        if (node) {
            this.receiver.deleteMesh(node);
        }
    };

    revert = () => {
        this.receiver.clearNodes(true);
        this.receiver.importLocalModelFile().then(() => {
            this.receiver.notifyEmptyScene();
        });
    };
}
