import React, { FC, memo }   from 'react';
import { Description, Icon } from "../../..";
import { cn }                from '../../util/bem';
import { SizesUnionType }    from '../../util/global-props';
import './tag.component.scss';

export type TagPropsType = {
    className?: string;
    children?: React.ReactNode;
    before?: React.ReactNode;
    after?: React.ReactNode;
    dot?: boolean;
    arrow?: boolean;

    color?: 'key' | 'grey' | 'green' | 'violet' | 'blue' | 'yellow' | 'red';
    size?: 'xs' | 'sm' | 'md' | 'lg';
    align?: 'left'|'center'|'right';
    fullWidth?: boolean;
    transparent?: boolean;
    margin?: SizesUnionType;
    flex?: number | string;
    style?: React.CSSProperties;
    bold?: boolean;

    ref?: React.Ref<HTMLDivElement>;
    onClick?: React.EventHandler<React.MouseEvent<HTMLDivElement, PointerEvent>>;
    onFocus?: React.EventHandler<React.FocusEvent<HTMLDivElement>>;
    onMouseEnter?: React.EventHandler<React.MouseEvent<HTMLDivElement, PointerEvent>>;
    onMouseLeave?: React.EventHandler<React.MouseEvent<HTMLDivElement, PointerEvent>>;
}

const className = cn('tag');

export const Tag: FC<TagPropsType> = memo((props) => (
    <div
        className={ className({
            size:        props.size,
            frame:       props.color,
            margin:      props.margin,
            flex:        props.flex,
            align:       props.align,
            fullWidth:   props.fullWidth,
            transparent: props.transparent,
            bold:        props.bold
        }, null, props.className) }
        ref={ props.ref }
        onClick={ props.onClick }
        onFocus={ props.onFocus }
        onMouseEnter={ props.onMouseEnter }
        onMouseLeave={ props.onMouseLeave }
        style={ props.style }
    >
        { props.dot && <div className={ className('dot') } /> }
        <Description className={ className('text') }>{ props.children }</Description>
        { props.arrow && <Icon name="arrowDown" className={ className('arrow') } /> }
    </div>
));

Tag.defaultProps = { color: 'grey', size: 'xs' };
