import React, { useState }                       from 'react';
import { observer }                              from 'mobx-react';
import { stripeState }                           from '@geenee/builder/src/core/state/stripe.state';
import { CardList }                              from '../common/CardList';
import { Bold, BoldCenter, Button, Link }        from '../common/styles';
import { CreditCardEditor }                      from '../CreditCardEditor';
import { ManageCards }                           from '../ManageCards';
import { InfoText, ManageCardsWrapper, Wrapper } from './styles';

type ManageBillingProps = {
    close: () => void;
    // eslint-disable-next-line no-empty-pattern
    setModal: ({ }) => void;
    showError: (message: string) => void;
};

export const ManageBilling = observer((props: ManageBillingProps) => {
    const [ waiting, setWaiting ] = useState<boolean>(false);
    const [ defaultCardId, setDefaultCardId ] = useState(
        stripeState.defaultPaymentMethod?.stripe_id
    );

    const changeDefaultPaymentMethod = async () => {
        try {
            await stripeState.setDefaultPaymentMethod(defaultCardId);
            setWaiting(false);
            props.close();
        } catch (e: any) {
            console.error(e);
        }
        setWaiting(false);
    };

    const handleUpdate = async (event: Event) => {
        event.preventDefault();
        setWaiting(true);
        changeDefaultPaymentMethod();
    };

    const RenderManageBilling = (
        <>
            <BoldCenter>manage billing</BoldCenter>
            <Bold>choose a different card</Bold>
            <CardList currentId={ defaultCardId } onChange={ (id) => setDefaultCardId(id) } setModal={ props.setModal } />
            <ManageCardsWrapper>
                <Link onClick={ () => props.setModal({ component: ManageCards }) }>
                    Manage Cards
                </Link>
            </ManageCardsWrapper>
            <Button
                onClick={ handleUpdate }
                $waiting={ waiting }
                disabled={ waiting }
                label="UPDATE PAYMENT"
                $width={ 172 }
                $margin="114px 0 20px 0"
            />
            <InfoText>
                By clicking the above, you agree to our
                { ' ' }
                <Link href='https://geenee.ar/terms-and-conditions/' target='_blank'> Terms of Service </Link>
                { ' ' }
                and
                confirm that you have read and understood our
                { ' ' }
                <Link href='https://geenee.ar/privacy-policy/' target='_blank'> Privacy Policy.</Link>
            </InfoText>
        </>
    );

    const cardsLen = Object.keys(stripeState.paymentMethods).length;
    const paymentMethodId =  Object.keys(stripeState.paymentMethods)[ 0 ];
    return (
        <>
            { cardsLen === 0 ? (
                <CreditCardEditor
                    isNewCard
                    paymentMethodId={ paymentMethodId }
                    onAddingNewCardFinished={ () => props.setModal({ component: ManageCards }) }
                    setModal={ props.setModal }
                    showError={ props.showError }
                />
            ) : (
                <Wrapper>{ RenderManageBilling }</Wrapper>
            ) }
        </>
    );
});
