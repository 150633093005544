import React            from 'react';
import { SvgIconProps } from '../../src/@custom_types/interfaces';

export default ({
    width = '22',
    height = '20',
    onClick,
    id,
    fill = 'white',
    className = '',
    style
}: SvgIconProps & {className: string}) => (
    <svg
        className={ className }
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 20"
        fill="none"
        id={ id }
        width={ width }
        height={ height }
        onClick={ onClick }
        style={ style }
    >
        <path d="M11 6.25C10.5858 6.25 10.25 6.58579 10.25 7C10.25 7.41421 10.5858 7.75 11 7.75C12.7949 7.75 14.25 9.20507 14.25 11V11.1893L13.5303 10.4697C13.2374 10.1768 12.7626 10.1768 12.4697 10.4697C12.1768 10.7626 12.1768 11.2374 12.4697 11.5303L14.4697 13.5303C14.7626 13.8232 15.2374 13.8232 15.5303 13.5303L17.5303 11.5303C17.8232 11.2374 17.8232 10.7626 17.5303 10.4697C17.2374 10.1768 16.7626 10.1768 16.4697 10.4697L15.75 11.1893V11C15.75 8.37665 13.6234 6.25 11 6.25Z" fill={ fill } />
        <path d="M7.53033 8.46967C7.23744 8.17678 6.76256 8.17678 6.46967 8.46967L4.46967 10.4697C4.17678 10.7626 4.17678 11.2374 4.46967 11.5303C4.76256 11.8232 5.23744 11.8232 5.53033 11.5303L6.25 10.8107V11C6.25 13.6234 8.37665 15.75 11 15.75C11.4142 15.75 11.75 15.4142 11.75 15C11.75 14.5858 11.4142 14.25 11 14.25C9.20507 14.25 7.75 12.7949 7.75 11V10.8107L8.46967 11.5303C8.76256 11.8232 9.23744 11.8232 9.53033 11.5303C9.82322 11.2374 9.82322 10.7626 9.53033 10.4697L7.53033 8.46967Z" fill={ fill } />
        <path fillRule="evenodd" clipRule="evenodd" d="M9.07037 0.25C8.1509 0.25 7.29226 0.709528 6.78223 1.47457L5.9697 2.69338C5.73786 3.04112 5.34757 3.25 4.92963 3.25H4C1.92893 3.25 0.25 4.92893 0.25 7V16C0.25 18.0711 1.92893 19.75 4 19.75H18C20.0711 19.75 21.75 18.0711 21.75 16V7C21.75 4.92893 20.0711 3.25 18 3.25H17.0704C16.6524 3.25 16.2621 3.04112 16.0303 2.69338L15.2178 1.47457C14.7077 0.709528 13.8491 0.25 12.9296 0.25H9.07037ZM8.0303 2.30662C8.26214 1.95888 8.65243 1.75 9.07037 1.75H12.9296C13.3476 1.75 13.7379 1.95888 13.9697 2.30662L14.7822 3.52543C15.2923 4.29047 16.1509 4.75 17.0704 4.75H18C19.2426 4.75 20.25 5.75736 20.25 7V16C20.25 17.2426 19.2426 18.25 18 18.25H4C2.75736 18.25 1.75 17.2426 1.75 16V7C1.75 5.75736 2.75736 4.75 4 4.75H4.92963C5.8491 4.75 6.70774 4.29047 7.21777 3.52543L8.0303 2.30662Z" fill={ fill } />
    </svg>
);
