import * as React                from 'react';
import { TableFooterGroupProps } from 'react-table';
import { tableClassName }        from '../../utils';

const className = tableClassName('f__tr');

type PropsType = {
    children?: React.ReactNode;
    footerRowProps: TableFooterGroupProps;
} ;

export const TableFooterRow = React.memo((props: PropsType) => (
    <div { ...props.footerRowProps } className={ className }>
        { props.children }
    </div>
));
