import { AnimationController }                         from '@geenee/shared/src/magellan/renderer/AnimationController';
import { EffectsLibrary, SpriteTextOptions }           from '@geenee/shared/src/magellan/renderer/EffectsLibrary';
import * as Colyseus                                   from 'colyseus.js';
// @ts-ignore
import { Clock, Euler, Object3D, Quaternion, Vector3 } from 'three';

const DEFAULT_ROOM_NAME = 'global-room';

export interface GameOptions {
  disableNicknames: boolean
  nicknameTextOptions: SpriteTextOptions
}

const ROOM_MAX_PLAYERS = 10;
const LERP_ALPHA = 4;

export class MultiplayerController {
    client: Colyseus.Client | undefined;
    room: Colyseus.Room | undefined;
    onJoinedCallback: ((v: any, playerData: Record<string, any>) => void) | undefined;
    onPlayerLeftCallback: ((clientId: string) => void) | undefined;
    onHostGameInit: ((v: any) => void) | undefined;
    onServerStateChange: ((v: any) => void) | undefined;
    remotePlayers = new Map<string, Object3D>();
    sessionId: string | undefined;
    gameOptions: GameOptions = { disableNicknames: false, nicknameTextOptions: {} };
    clock = new Clock();
    // Delta between frames
    delta = 0;
    changes = new Map();
    position = { x: 0, y: 0, z: 0 };
    serverPlayersCoordinates = new Map<string, any>();

    constructor(public animationController: AnimationController, public effectsLibrary: EffectsLibrary) {}

    playerState = {
        xPos:     0,
        yPos:     0,
        zPos:     0,
        xRot:     0,
        yRot:     0,
        zRot:     0,
        xScale:   1,
        yScale:   1,
        zScale:   1,
        anim:     '',
        nickname: '',
        skin:     ''
    };

    setObjectField = (object: Object3D, field: string, value: any) => {
        switch (field) {
            case 'xScale':
                object.scale.x = value;
                break;
            case 'yScale':
                object.scale.y = value;
                break;
            case 'zScale':
                object.scale.z = value;
                break;
            case 'anim':
                // @ts-ignore
                if (object.playingAnim !== value) {
                // @ts-ignore
                    if (object.playingAnim) {
                        // @ts-ignore
                        this.animationController.crossFade(object.playingAnim, value, 0.2, object);
                    } else {
                        this.animationController.stopAllObjectAnims(object);
                        this.animationController.playClip(value, object);
                    }
                    // @ts-ignore
                    object.playingAnim = value;
                }
                break;
            default:
                break;
        }
    };

    async init(serverUrl = 'wss://hpftqe.colyseus.dev:443', options: GameOptions) {
        try {
            // this.client = new Colyseus.Client('ws://localhost:2567');
            this.client = new Colyseus.Client(serverUrl);
            if (options) { this.gameOptions = options; }
        } catch (e) {
            console.log('JOIN ERROR', e);
        }
    }

    async createRoom(roomName = DEFAULT_ROOM_NAME, options: Record<string, any> = {}) {
        try {
            if (this.client) {
                this.room = await this.client.create(roomName, options);
                this.sessionId = this.room.sessionId;
                this.setListeners();
            } else {
                console.error('client not initialized');
            }
        } catch (e) {
            console.error('Error while create room', e.message);
        }
    }

    // returns true if joined successfully
    async joinRoomById(roomId: string, playerInitialData: Record<string, any> = {}) {
        try {
            if (this.client) {
                this.room = await this.client.joinById(roomId, { playerInitialData });
                this.sessionId = this.room.sessionId;
                this.setListeners();
                return true;
            }
            console.error('client not initialized');
        } catch (e) {
            console.error('Room join error', e.code, e.message);
            // if (e.code === 4212) {} // Room not found
        }
        return false;
    }

    async joinOrCreateRoom(roomName = DEFAULT_ROOM_NAME, roomOptions: Record<string, any> = {}, playerInitialData: Record<string, any> = {}) {
        try {
            if (this.client) {
                this.room = await this.client.joinOrCreate(roomName, { ...roomOptions, playerInitialData });
                this.sessionId = this.room.sessionId;
                this.setListeners();
            } else {
                console.error('client not initialized');
            }
        } catch (e) {
            console.error('Error while create room', e.message);
        }
    }

    // Game name is used to split players in public rooms between different games
    async joinOrCreatePublicGameRoom(
        roomName = DEFAULT_ROOM_NAME,
        gameName = '',
        roomOptions: Record<string, any> = {},
        playerInitialData: Record<string, any> = {}
    ) {
        try {
            if (this.client) {
                const rooms = await this.client.getAvailableRooms(roomName);
                const existingGameRoom = rooms.find((room) => room.metadata.gameName === gameName
                  && room.metadata.public && room.clients < ROOM_MAX_PLAYERS);
                if (existingGameRoom) {
                    await this.joinRoomById(existingGameRoom.roomId, playerInitialData);
                } else {
                    console.log('Creating new game room');
                    this.room = await this.client.create(roomName, { gameName, public: true, ...roomOptions, playerInitialData });
                    this.sessionId = this.room.sessionId;
                    this.setListeners();
                }
            } else {
                console.error('client not initialized');
            }
        } catch (e) {
            console.error('Error while create room', e.message);
        }
    }

    addRemotePlayerObject = (clientId: string, object: Object3D) => {
        console.log('add player', { clientId, object });
        this.remotePlayers.set(clientId, object);
    };

    customServerMethod = (methodName: string, param: Record<string, any>) => {
        this.send('customMethod', { method: methodName, param });
    };

    setListeners() {
        if (this.room) {
            this.room.onStateChange((state) => {
                this.onServerStateChange && this.onServerStateChange(state);
            });

            this.room.state.players.onAdd = (player: Record<string, any>, key: string) => {
                if (key === this.sessionId) { return; }
                this.onJoinedCallback && this.onJoinedCallback(key, player);
                player.onChange = (changes: Record<string, any>[]) => {
                    const serverData = this.serverPlayersCoordinates.get(key) || {};
                    serverData.position = new Vector3(player.xPos, player.yPos, player.zPos);

                    const euler = new Euler(player.xRot, player.yRot, player.zRot);
                    serverData.rotation = new Quaternion().setFromEuler(euler);

                    this.serverPlayersCoordinates.set(key, serverData);

                    const object = this.remotePlayers.get(key);
                    if (object) {
                        changes.forEach((change) => {
                            this.setObjectField(object, change.field, change.value);
                            if (!this.gameOptions.disableNicknames && change.field === 'nickname') {
                                this.effectsLibrary.addNicknameToObject(change.value, object, this.gameOptions.nicknameTextOptions);
                            }
                        });
                    }
                };
                player.onRemove = () => {
                    this.onPlayerLeftCallback && this.onPlayerLeftCallback(key);
                    const remotePlayer = this.remotePlayers.get(key);
                    if (remotePlayer) {
                        remotePlayer.removeFromParent();
                    }
                    this.remotePlayers.delete(key);
                };
            };

            this.room.onLeave((code) => {
                console.log('leave', code);
            });

            this.room.onError((code, message) => {
                console.log('oops, error ocurred:');
                console.log(message);
            });
        }
    }

    compare(prevState: Record<string, any>, newState: Record<string, any>) {
        const changes: Record<string, any> = {};
        Object.keys(newState).forEach((key) => {
            if (key === 'options') {
                if (JSON.stringify(prevState.options) !== JSON.stringify(newState.options)) {
                    changes.options = newState.options;
                }
            } else if (prevState[ key ] !== newState[ key ]) {
                changes[ key ] = newState[ key ];
            }
        });
        return changes;
    }

    syncRemotePlayers = () => {
        this.delta = this.clock.getDelta();
        Array.from(this.remotePlayers.keys()).forEach((remoteKey) => {
            const remoteObject = this.remotePlayers.get(remoteKey);
            const remoteServerData = this.serverPlayersCoordinates.get(remoteKey);
            if (remoteServerData) {
                remoteObject.position.lerp(remoteServerData.position, this.delta * LERP_ALPHA);
                remoteObject.quaternion.slerp(remoteServerData.rotation, this.delta * LERP_ALPHA);
            }
        });
    };

    // Take Serializes changes in the tranform and pass those changes to the server
    updatePlayerState(object: Object3D, playingAnim = '') {
        this.syncRemotePlayers();
        const previousState = JSON.parse(JSON.stringify(this.playerState));
        // Copy Transform to State (round position to fix floating point issues with state compare)
        this.playerState.xPos = parseFloat(object.position.x.toFixed(4));
        this.playerState.yPos = parseFloat(object.position.y.toFixed(4));
        this.playerState.zPos = parseFloat(object.position.z.toFixed(4));

        this.playerState.xRot = parseFloat(object.rotation.x.toFixed(4));
        this.playerState.yRot = parseFloat(object.rotation.y.toFixed(4));
        this.playerState.zRot = parseFloat(object.rotation.z.toFixed(4));

        this.playerState.xScale = parseFloat(object.scale.x.toFixed(4));
        this.playerState.yScale = parseFloat(object.scale.y.toFixed(4));
        this.playerState.zScale = parseFloat(object.scale.z.toFixed(4));

        this.playerState.anim = playingAnim;
        // @ts-ignore
        this.playerState.nickname = object.nickname;
        // @ts-ignore
        this.playerState.skin = object.skin;

        const changes = this.compare(previousState, this.playerState);
        // Transform has been update locally, push changes
        if (Object.keys(changes).length) {
            this.room?.send('entityUpdate', changes);
        }
    }

    send(type: string, value: any) {
        if (this.room) {
            this.room.send(type, value);
        }
    }

    broadcast = (type: string, data: any) => {
        this.send('broadcast', { type, data });
    };

    onMessage = (type: string, callback: any) => {
        if (this.room) {
            this.room.onMessage(type, callback);
        }
    };

    leave() {
        if (this.room) {
            this.room.leave();
        }
    }
}
