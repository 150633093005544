import { action, makeAutoObservable, observable, set } from 'mobx';

export class NftItemModel {
  @observable description = '';
  @observable image = '';
  @observable listing_id = '';
  @observable name = '';
  @observable preview_images = [];
  @observable price: number | null = null;
  @observable signature = '';
  @observable starting_price: number | null = null;
  @observable status = '';
  @observable subtitle = '';
  @observable title = '';
  @observable type = 'fixed_price';
  @observable url = '';
  updated_at = '';
  inserted_at = '';

  constructor() {
      makeAutoObservable(this);
  }

  @action init(data: Partial<NftItemModel>) {
      Object.keys(data).forEach((key) => {
          set(this, key, data[ key as keyof NftItemModel ]);
      });
  }
}
