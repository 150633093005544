import React                                   from 'react';
import HintYourTeam                            from '@geenee/builder/src/asset/icons/HintYourTeam';
import Hint                                    from '@geenee/builder/src/components/common/Hint';
import { UnderlinedTableRow, WithHintWrapper } from '@geenee/builder/src/pages/AllPlansPage/styles';

export function Seats() {
    return (
        <UnderlinedTableRow>
            <td>
                <WithHintWrapper>
                    <span>Seats</span>
                    <Hint
                        title='Collaborate With Your Team'
                        text='Add colleagues to create, preview and publish. Additional seats can be purchased based on plan.'
                        iconElement={ <HintYourTeam /> }
                    />
                </WithHintWrapper>
            </td>
            <td className='basic-plan'>2 Users</td>
            <td className='plan-filler' />
            <td className='studio-plan'>5 Users</td>
            <td className='plan-filler' />
            <td className='pro-plan'>15 Users</td>
        </UnderlinedTableRow>
    );
}
