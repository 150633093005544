import * as React            from 'react';
import { useInject }         from '@geenee/shared';
import isEmpty               from 'lodash-es/isEmpty';
import { observer }          from 'mobx-react';
import { hexAToRGBA }        from '@geenee/builder/src/lib/colorConverter';
import { MagellanCMS }       from '@geenee/builder/src/magellan/app';
import { withCustomCode }    from '@geenee/builder/src/module/custom-code/hoc/withCustomCode.hoc';
import { PhoneScreenStyled } from '@geenee/builder/src/styles';

export const PhoneScreen = withCustomCode(
    observer((props) => {
        const { AppState } = useInject();

        const colorObj = AppState?.activeProject?.options.background_color;
        const rgbaColor = isEmpty(colorObj) ? undefined : hexAToRGBA(colorObj!);
        return (
            <PhoneScreenStyled
                color={ rgbaColor }
                id="phone_screen"
            >
                <MagellanCMS
                    // @ts-ignore
                    customCodeControls={ { useAssetCustomCodeModal: props.useAssetCustomCodeModal, useCustomCodeModal: props.useCustomCodeModal } }
                />
            </PhoneScreenStyled>
        );
    })
);
