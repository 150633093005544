import React, { useEffect, useRef, useState }         from 'react';
import { CurrentOption, OptionRail, ToggleContainer } from './styles';

type ToggleProps = {
    options: string[];
    onToggle: (selected: string) => void;
    /*
     * false = first option is selected
     * true = second is selected
     */
    preToggle?: boolean;
    blocked?: boolean;
};

function Toggle(props: ToggleProps) {
    const { blocked = false } = props;
    const [ toggled, setToggled ] = useState(!!props.preToggle);
    const optionRef = useRef();

    const handleToggle = () => {
        const newStatus = !toggled;

        if (!blocked) {
            setToggled(newStatus);
            // @ts-ignore
            optionRef.current.setAttribute('toggled', newStatus);
        }
        const newOption = newStatus ? props.options[ 1 ] : props.options[ 0 ];
        props.onToggle(newOption);
    };

    useEffect(() => {
        if (toggled) {
            // @ts-ignore
            optionRef.current.setAttribute('toggled', true);
        }
    }, []);

    return (
        <ToggleContainer selected={ toggled }>
            <span>{ props.options[ 0 ] }</span>
            <OptionRail onClick={ handleToggle }>
                <CurrentOption
                // @ts-ignore
                    ref={ optionRef }
                />
            </OptionRail>
            <span>{ props.options[ 1 ] }</span>
        </ToggleContainer>
    );
}

// eslint-disable-next-line arca/no-default-export
export default Toggle;
