import React                                from "react";
import Uploader                             from "@geenee/geeclient-kit/src/lib/component/scene/components/Uploader";
import { Button, Divider }                  from "@geenee/ui";
import { observer }                         from "mobx-react";
import { InputSearch }                      from "~/components/LibraryModalTemplates/components/FilterRow/InputSearch";
import { LibraryFilter }                    from "~/components/LibraryModalTemplates/components/FilterRow/LibraryFilter";
import { dateList, fileTypeList, sortList } from "~/module/team-library/components/listOfValues";
import TeamLibraryState                     from "~/module/team-library/state/team-library.state";

type Props = {
    onUploadModel: (files: File[]) => void;
}

export const FiltersRow = observer((props: Props) => {
    const isSnippetMode = TeamLibraryState.mode === 'snippet';

    return (
        <>
            <InputSearch
                value={ TeamLibraryState.query.searchQuery }
                onChange={ (value: string) => {
                    TeamLibraryState.query = {
                        ...TeamLibraryState.query,
                        searchQuery: value
                    };
                } }
                margin="sm"
            />
            <Divider
                style={ {
                    height:    "32px",
                    alignSelf: "unset"
                } }
                margin="sm"
                color="shade-6"
            />
            <LibraryFilter
                filterData={ sortList }
                label="Sort by"
                onSelect={ (value) => {
                    TeamLibraryState.query = {
                        ...TeamLibraryState.query,
                        sort: value as any
                    };
                } }
                value={ TeamLibraryState.query.sort }
            />
            <Divider transparent margin="sm" />

            <LibraryFilter
                filterData={ dateList }
                label="Date"
                onSelect={ (value) => {
                    TeamLibraryState.query = {
                        ...TeamLibraryState.query,
                        dateFilter: value as any
                    };
                } }
                value={ TeamLibraryState.query.dateFilter }
            />
            { !isSnippetMode ? (
                <>
                    <Divider transparent margin="sm" />

                    <LibraryFilter
                        filterData={ fileTypeList }
                        label="File type"
                        onSelect={ (value) => {
                            TeamLibraryState.query = {
                                ...TeamLibraryState.query,
                                fileType: value as any
                            };
                        } }
                        value={ TeamLibraryState.query.fileType }
                    />
                </>
            ) : null }
            <Divider transparent margin="sm" />
            { TeamLibraryState.editMode ? (
                <Button
                    size="sm"
                    icon="addAsset"
                    iconSize={ 14 }
                    align="center"
                    iconMargin="sm"
                    onClick={ () => {
                        const uploader = document.getElementById("assets-library");
                        if (uploader) {
                            uploader.click();
                        }
                    } }
                    radius="lg"
                />
            ) : <></> }
            <Uploader
                multiple
                hideUploadText
                accept=".gltf,.glb,.png,.jpg,.jpeg,.gif,.mp4,.mp3,.json"
                handleUpload={ (files) => {
                    props.onUploadModel(Array.isArray(files) ? files : [ files ]);
                } }
                name="assets-library"
                size={ 20 }
            />
        </>
    );
});
