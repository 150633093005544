import React                from 'react';
import { cn, Wrapper }      from '@geenee/ui';
import { observer }         from 'mobx-react';
import { useBuilderInject } from '@geenee/builder/src/core/hook/use-builder-inject';
import './nftOptIn.styles.scss';

export const NFTOptIn = observer(() => {
    const { BuilderState } = useBuilderInject();
    const { profile } = BuilderState.currentUser;
    const email = profile?.email || '';
    const sendMail = () => {
        if (email) {
            window.open(
                `mailto:pavel@geenee.me?cc=operations@geenee.me,developer@geenee.me&subject=Request NFT&body=Hi Geenee Team!
                Could you please enable the NFT feature for my account? Here’s my login email: ${ email }.`
            );
        }
    };
    if (BuilderState.isNftAvailable || BuilderState.isAuthenticating) {
        return <></>;
    }
    const className = cn('nft-opt-in');
    return (
        <Wrapper className={ className('root') }>
            <div className={ className('first-row') }>NEW FEATURE:</div>
            <div className={ className('second-row') }>TURN YOUR PROJECT INTO A NFT</div>
            <div className={ className('third-row') } onClick={ sendMail }>
                find out how &gt;
            </div>
        </Wrapper>
    );
});
