// @ts-nocheck
import React           from 'react';
import { cn }          from '../../util/bem';
import { Description } from '../description/description.component';
import { Wrapper }     from '../wrapper/wrapper.component';
import {
    ContentWrapper,
    PanelContent
}             from './styles';
import './panel.styles.scss';

type PanelProps = {
    height: string
    width: string
    header: string
    collapsed: boolean
    children: JSX.Element | JSX.Element[]
    contentPadding?: string
}

const className = cn('viewer3d-panel');

export function Panel(props: PanelProps) {
    return (
        <Wrapper
        // @ts-ignore
            radius="xl"
            overflow="visible"
            fullHeight
            fullWidth
            style={ { padding: '3px', width: props.width } }
        >
            <Wrapper
                className={ className('title') }
                align="left"
                valign="center"
                padding="sm"
                maxHeight="45px"
                // @ts-ignore
                shadow="thin-bottom"
            >
                <Description size="sm" weight="bold">
                    { props.header }
                </Description>
            </Wrapper>
            <PanelContent
                align="stretch"
                valign="stretch"
                $height={ props.height }
                $collapsed={ props.collapsed }
            >
                <ContentWrapper padding={ props.contentPadding }>{ props.children }</ContentWrapper>
            </PanelContent>
        </Wrapper>
    );
}

// eslint-disable-next-line arca/no-default-export
export default Panel;
