import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '16',
    height = '16',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
                fill="white"
                stroke="#BBBBBB"
            />
            <rect x="8.57129" y="4" width="3.42857" height="3.42857" rx="0.5" stroke="black" strokeWidth="0.5" strokeLinejoin="round" />
            <rect x="4" y="4" width="3.42857" height="3.42857" rx="0.5" stroke="black" strokeWidth="0.5" strokeLinejoin="round" />
            <rect x="8.57129" y="8.57153" width="3.42857" height="3.42857" rx="0.5" stroke="black" strokeWidth="0.5" strokeLinejoin="round" />
            <rect x="4" y="8.57153" width="3.42857" height="3.42857" rx="0.5" stroke="black" strokeWidth="0.5" strokeLinejoin="round" />
        </svg>
    );
}
