import React, { FC }     from 'react';
import { SceneRenderer } from '@geenee/shared/src/magellan/renderer/r3f-renderer/r3f.renderer';
import { softShadows }   from '@react-three/drei';

type ShadowReceiverPlaneType = {
    activeSceneModel: SceneRenderer;
};

const ShadowReceiverPlane: FC<ShadowReceiverPlaneType> = ({ activeSceneModel }) => {
    const experienceType = activeSceneModel.scene_experience_type;
    const receiverRotationX = experienceType === 'stamp-ar' ? 0 : (-Math.PI / 2);
    return (
        <group name="geenee-shadow-receiver-group">
            <mesh name="geenee-shadow-receiver-mesh" receiveShadow rotation={ [ receiverRotationX, 0, 0 ] } position={ [ 0, 0, 0 ] }>
                <planeGeometry attach="geometry" args={ [ 150, 150 ] } />
                <shadowMaterial attach="material" color="#000000" transparent depthWrite={ false } opacity={ 0.3 } />
            </mesh>
        </group>
    );
};
// eslint-disable-next-line arca/no-default-export
export default ShadowReceiverPlane;
