import React, { memo, useState }        from 'react';
import { PopoverModel }                 from '@geenee/shared/src/magellan/model/popover.model';
import { ASSET_LIBRARY_POPOVER_SCHEMA } from "@geenee/shared/src/util/popover-templates";
import { Button, cn, PopoverContainer } from '@geenee/ui';
import { useBuilderInject }             from "~/core/hook/use-builder-inject";
import { useDeleteModal }               from "~/module/team-library/hooks/useDeleteModal";
import { TeamLibraryItemModel }         from "~/module/team-library/state/team-library-item.model";

type Props = {
  onEdit: (el: TeamLibraryItemModel) => void;
  onDelete: (el: TeamLibraryItemModel) => () => void;
  asset: TeamLibraryItemModel
}

const className = cn('asset-library');

export const MoreActions = memo((props: Props) => {
    const { BuilderState } = useBuilderInject();
    const [ showModal ] = useDeleteModal({ onDelete: props.onDelete(props.asset) });

    const [ popover, setPopover ] = useState<PopoverModel | null>(null);

    return (
        <div className={ className('action', { position: 'right' }) }>
            <PopoverContainer
                margin="xs"
                popoverSchema={ popover?.toJSON() }
                onClose={ () => {
                    setPopover(BuilderState.closePopover());
                } }
                onSelected={ (item, e: any) => {
                    e.stopPropagation();
                    e.preventDefault();

                    if (item.label === 'Delete') {
                        showModal();
                    } else {
                        props.onEdit(props.asset);
                    }
                } }
            >
                <Button
                    viewType="action"
                    size="xs"
                    radius="sm"
                    icon="more"
                    onClick={ (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (popover) {
                            setPopover(BuilderState.closePopover());
                        } else {
                            setPopover(BuilderState.createPopover(ASSET_LIBRARY_POPOVER_SCHEMA));
                        }
                    } }
                />
            </PopoverContainer>
        </div>
    );
});
