import { useCallback, useEffect } from "react";

type Props = {
  position: string,
  triggerEl: HTMLDivElement | null,
  portalTarget: HTMLDivElement | null,
  triggerCalculate: boolean,
}
export const usePortalCalculate = ({ position, triggerEl, portalTarget, triggerCalculate }: Props) => {
    const calculateWidthPosition = (triggerPosition: number, triggerWidth: number) => {
        if (position.startsWith('left')) {
            return triggerPosition;
        } if (position.startsWith('right')) {
            return triggerPosition + triggerWidth;
        }
        return triggerPosition + triggerWidth / 2;
    };

    const calculateHeightPosition = (triggerPosition: number, triggerHeight: number) => {
        if (position.startsWith('top')) {
            return triggerPosition;
        } if (position.startsWith('bottom')) {
            return triggerPosition + triggerHeight;
        }
        return triggerPosition + triggerHeight * 0.5;
    };

    useEffect(() => {
        if (!triggerEl || !portalTarget) {
            return;
        }
        const triggerElPositionY = calculateHeightPosition(
            triggerEl?.getBoundingClientRect().y,
            triggerEl?.getBoundingClientRect().height
        );

        const triggerElPositionX = calculateWidthPosition(
            triggerEl?.getBoundingClientRect().x,
            triggerEl?.getBoundingClientRect().width
        );
        if (portalTarget.style.top !== `${ triggerElPositionY }px` && portalTarget.style.top !== `${ triggerElPositionY }px`) {
            portalTarget.style.top = `${ triggerElPositionY }px`;
            portalTarget.style.left = `${ triggerElPositionX }px`;
        }
    }, [ portalTarget, position, triggerCalculate ]);
};
