import React                                                 from 'react';
import { TimeframeOptionsList }                              from '@geenee/shared/src/util/TimeframeOptionsList';
import { FormItem, InputTableItem, Select, Switch, Wrapper } from '@geenee/ui';
import { observer }                                          from 'mobx-react';
import { ColorPicker }                                       from '@geenee/builder/src/components/ColorPicker/ColorPicker';
import { TYPE_DRAWER_OVERLAY }                               from '@geenee/builder/src/lib/constants';
import { AtomModel }                                         from '@geenee/builder/src/magellan/model/atom.model';
import { MoleculeModel }                                     from '@geenee/builder/src/magellan/model/molecule.model';

interface Props {
    overlayMolecule?: MoleculeModel
    disabled: boolean
}

export const OverlayForm = observer(({ overlayMolecule, disabled }: Props) => {
    const textAtom = overlayMolecule?.getAtomByType('text');
    const buttonAtom = overlayMolecule?.getAtomByType('button') as AtomModel | null;
    const { molecule_overlay_background_color, molecule_overlay_open_at } = overlayMolecule?.options || {};
    if (overlayMolecule?.type !== TYPE_DRAWER_OVERLAY || !overlayMolecule || !textAtom || !buttonAtom) {
        return <></>;
    }
    const { atom_button_visible } = buttonAtom.options || {};
    // const {atom_text_content} = textAtom?.options?.atom_text_content || ;
    return (
        <>
            <InputTableItem label='Background Color'>
                <FormItem>
                    <ColorPicker
                        disabled={ disabled }
                        color={ molecule_overlay_background_color || '#FFF' }
                        onColorChange={ (value) => {
                            overlayMolecule.options.molecule_overlay_background_color = value;
                            overlayMolecule.saveData();
                        } }
                    />
                </FormItem>
            </InputTableItem>
            <InputTableItem label="Button Visibility">
                <Switch
                    descriptionSize="xs"
                    color="white"
                    size='lg'
                    checked={ atom_button_visible }
                    onChange={ (value) => {
                        buttonAtom.options.atom_button_visible = value;
                        buttonAtom.saveData();
                    } }
                    disabled={ disabled }
                />
            </InputTableItem>
            <InputTableItem
                label="Open At"
                tooltipTitle="Time Delay"
                tooltipContent="Open at x seconds of the Experience "
                tooltipMenuWidth="140px"
            >
                <Wrapper maxWidth="95px">
                    <Select
                        isDisabled={ disabled }
                        size="md"
                        options={ TimeframeOptionsList }
                        value={
                            TimeframeOptionsList.find(
                                (item) => item.value
                                === molecule_overlay_open_at
                            ) || TimeframeOptionsList[ 0 ]
                        }
                        onChange={ ({ value }) => {
                            // eslint-disable-next-line no-param-reassign
                            // @ts-ignore

                            overlayMolecule.options.molecule_overlay_open_at = value;
                            overlayMolecule.saveData();
                        } }
                    />
                </Wrapper>
            </InputTableItem>
        </>
    );
});
