import React                  from 'react';
import { Scrollbars }         from 'react-custom-scrollbars';
import { Divider, Wrapper }   from '@geenee/ui';
import { CompanyPageContent } from "@geenee/builder/src/pages/CompanyPage/components/CompanyPageContent";
import { HeaderTitle }        from '../../components/HeaderTitle';

export function CompanyPage() {
    return (
        <Scrollbars
            autoHide
            autoHideTimeout={ 500 }
            autoHideDuration={ 200 }
        >
            <Wrapper
                fullHeight
                padding="xxl"
                align="center"
            >
                <Wrapper maxWidth="612px">
                    <HeaderTitle title="Company Settings" />
                    <Divider transparent margin="lg" />
                    <Wrapper maxWidth="736px" margin="lg">
                        <CompanyPageContent />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Scrollbars>
    );
}
