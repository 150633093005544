import { CubeTextureLoader } from 'three';
import envMapImgWhite        from '../asset/images/envWhite.jpg';

export const setEnvMap = (scene, isFiber) => {
    let resolve; let
        reject;
    const promise = new Promise((res, rej) => {
        resolve = res;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        reject = rej;
    });
    const loader = new CubeTextureLoader();
    const envMapTexture = loader.load(
        [
            envMapImgWhite,
            envMapImgWhite,
            envMapImgWhite,
            envMapImgWhite,
            envMapImgWhite,
            envMapImgWhite
        ],
        () => {
            if (isFiber) {
                // eslint-disable-next-line no-restricted-syntax
                for (const mat in scene.materials) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (scene.materials.hasOwnProperty(mat)) {
                        // eslint-disable-next-line no-param-reassign
                        scene.materials[ mat ].envMap = envMapTexture;
                    }
                }
            } else {
                // use THREE.Scene traverse to access the materials
                scene.traverse((child) => {
                    if (child.isMesh) {
                        // eslint-disable-next-line no-param-reassign
                        child.material.envMap = envMapTexture;
                    }
                });
            }
            resolve();
        }
    );

    // use react-three-fiber structure to access all materials
    return promise;
};
