import styled from 'styled-components';

export const ActionsWrapper = styled.div<{depth: number}>.attrs({className: 'node-actions'})`
  display: none;
  span:first-child {
    position: absolute;
    left: calc(-15px + ${ ({ depth }) => depth * -15 }px);
    top: 8px;
    cursor: move;
  }
  
  span:last-child {
    position: absolute;
    right: 10px;
    top: 6px;
    cursor: pointer;
  }
`;
