import React                     from 'react';
import { useHistory }            from "@geenee/common";
import useComposerLocationParser from '@geenee/shared/src/util/useComposerLocationParser';
import classnames                from 'classnames';
import isEmpty                   from 'lodash-es/isEmpty';
import isEqual                   from 'lodash-es/isEqual';
import { ObservableMap }         from 'mobx';
import { ChildrenWrapper }       from '@geenee/builder/src/components/Tree/styles';
import { ElementType, TreeNode } from '@geenee/builder/src/components/Tree/TreeNode';
import {
    CONTENT_AR_PROPERTY_NAME,
    PROJECT_TYPES,
    TREE_NODE_TYPES,
    TYPE_SLAM_AR,
    TYPE_STAMP_AR
}                                from '@geenee/builder/src/lib/constants';
import stringIncludesNew from '@geenee/builder/src/lib/stringIncludesNew';

export const useNodeChildren = (
    node: ElementType,
    openedNodes: Set<string>,
    childMove: (dragIndex: number, hoverIndex: number) => void,
    onCollapsedChange: (id: string, value: boolean, type: string) => Promise<void>,
    triggerTreeUpdate: () => void,
    depth: number,
    updateProgramsOrder: () => void,
    onAddWillBeDeletedItem: (id: string) => void,
    willBeDeletedItems: Set<string>,
    onDeleteItem: (id: string) => void,
    onTitleChange: (id: string, value: string) => void,
    treeChildren?: ObservableMap<string, any>
) => {
    const { type, id, path } = node;
    const collapsed = !openedNodes.has(id);
    const { currentUrl } = useComposerLocationParser();
    const history = useHistory();
    const nodeChildren = () => {
        if (!!node.children || !node.children) {
            // Check if node has children and init output array
            const children = node.children ? [ ...node.children ] : [];
            if (
                [ ...PROJECT_TYPES, TYPE_SLAM_AR, TYPE_STAMP_AR ].includes(
                    type!
                )
                && !stringIncludesNew(id)
            ) {
                const childOfProject = [
                    ...PROJECT_TYPES,
                    TYPE_SLAM_AR,
                    TYPE_STAMP_AR
                ].findIndex((el) => el === type);
                // Only types defined in PROJECT_TYPES can have chidren
                // if (childOfProject === -1) return;

                // NOTE - check if currently processed node should have a "new child" (only if it has it
                // in the current URL as a "new_" type name
                const urlToArray = currentUrl.split('/').slice(1, -1);
                const pathToArray = path && path.split('/').slice(1);
                const urlLastNode = currentUrl.split('/').slice(-1);
                if (
                    isEqual(urlToArray, pathToArray)
                    && stringIncludesNew(String(urlLastNode))
                ) {
                    // Get child type from the list of possible node types
                    const index = childOfProject + 1;
                    const childType = [
                        ...TREE_NODE_TYPES,
                        CONTENT_AR_PROPERTY_NAME,
                        CONTENT_AR_PROPERTY_NAME
                    ][ index ];
                    const newChildType = `new_${ childType }`;

                    // Create node for non-existing new child
                    const newChildNode = {
                        id:      newChildType,
                        type:    childType,
                        options: { order: children.length + 1 },
                        title:   `${
                            childType === CONTENT_AR_PROPERTY_NAME
                                ? 'Asset'
                                : childType.charAt(0).toUpperCase()
                                  + childType.slice(1)
                        } Set Up`,
                        path: `/${ urlToArray.join('/') }/${
                            childType === CONTENT_AR_PROPERTY_NAME
                                ? 'new_asset'
                                : newChildType
                        }`
                    };
                    if (history.location.pathname.endsWith('new_asset')) {
                        children.push(newChildNode);
                    } else {
                        children.splice(0, 0, newChildNode);
                    }
                }
            }

            if (isEmpty(children)) return null;

            return (
                <ChildrenWrapper
                    className={ classnames(
                        `${ type }-children-wrapper collapsable`,
                        { collapsed }
                    ) }
                >
                    { children.map((c, i) => {
                        const treeChildrenProp = treeChildren && treeChildren.get(c.id);
                        return (
                            <TreeNode
                                index={ i }
                                moveItem={ childMove }
                                node={ c }
                                key={ c.id }
                                // lastNode={ i === children.filter((el) => !willBeDeletedItems.has(el.id)).length - 1 }
                                lastNode={ i === children.length - 1 }
                                openedNodes={ openedNodes }
                                onCollapsedChange={ onCollapsedChange }
                                triggerTreeUpdate={ triggerTreeUpdate }
                                depth={ depth + 1 }
                                updateProgramsOrder={ updateProgramsOrder }
                                treeChildren={ treeChildrenProp ? treeChildrenProp.children : undefined }
                                onAddWillBeDeletedItem={ onAddWillBeDeletedItem }
                                willBeDeletedItems={ willBeDeletedItems }
                                onDeleteItem={ onDeleteItem }
                                onTitleChange={ onTitleChange }
                                hideMoveIcon={ children.length < 2 }
                            />
                        );
                    }) }
                </ChildrenWrapper>
            );
        }
        return <></>;
    };

    return { nodeChildren };
};
