const PROJECT = {
    mapped_domain:   '',
    favicon_id:      null,
    header_logo_id:  null,
    title:           'Project',
    internal_domain: '',
    type:            'project',
    description:     '',
    published:       false,
    options:         {
        branding_bar_color: '#a7a7a7ff',
        background_color:   '#ffffffff',
        path:               '/'
    }
};

const EXPERIENCE = {
    title:                 'Experience',
    type:                  'experience',
    thumbnail_image_id:    null,
    description:           '',
    cover_image_id:        null,
    splash_screen_message:
    // eslint-disable-next-line max-len
      '<p class="ql-align-center"><strong class="ql-size-3_5vw">ADD YOUR TITLE&nbsp;</strong></p><p class="ql-align-center"><br></p><p class="ql-align-center"><span class="ql-size-3_5vw">Click here to edit your welcome message. Give a quick intro on what to expect with a strong call to action.&nbsp;</span></p>',
    options: { hidden_in_tree: false }
};

// Content types below
const CONTENT = {
    title: null,
    type:  'content'
};

const OVERLAY_VIEW = { title: 'Overlay View' };

// Content type blocks
const OVERLAY = {
    type:    'overlay',
    options: {
        section_is_visible_in_menu: true,
        section_menu_title:         'Overlay'
    }
};

const OVERLAY_ATOMS = [
    {
        type:    'text',
        // eslint-disable-next-line max-len
        options: { atom_text_content: "<p class='ql-align-center'><strong class='ql-size-3_5vw'>FIND OUT MORE</strong></p><p class='ql-align-center'><span class='ql-size-3_5vw'>Click here to change the text within this drawer.</span></p>" }
    },
    {
        type:    'button',
        options: {
            atom_button_align:       'center',
            atom_button_visible:     true,
            atom_button_link_type:   'web',
            atom_button_url:         '',
            atom_button_border_type: 'rounded',
            // eslint-disable-next-line max-len
            atom_button_title:       '<p class="ql-align-center"><strong class="ql-size-3_5vw" style="color: rgb(255, 255, 255);">BUTTON TEXT</strong></p>'
        }
    }
];

const FULLSCREEN_VIDEO_VIEW = { title: 'Video Embed Build' };

const FULLSCREEN_VIDEO = {
    type:    'fullscreen-video',
    options: {
        section_is_visible_in_menu: true,
        section_menu_title:         'Video Embed'
    }
};

const FULLSCREEN_VIDEO_ATOMS = [ {
    type:    'video',
    options: {
        atom_video_url:                  'https://',
        atom_video_allow_landscape_mode: true
    }
} ];

const SCENE_BUILD_VIEW = { title: 'AR Build' };

const SCENE_BUILD = {
    type:                     'scene-build',
    ar_attachment_ids:        [],
    thumbnail_attachment_ids: [],
    options:                  {
        section_is_visible_in_menu:        false,
        section_scene_carousel_visibility: true,
        section_menu_title:                'AR Build'
    }
};

const SLAM_AR_VIEW = { title: 'World AR Build' };

const SLAM_AR = {
    type:                     'slam-ar',
    menu_title:               'World AR',
    ar_attachment_ids:        [],
    thumbnail_attachment_ids: [],
    options:                  {
        section_is_visible_in_menu:        true,
        section_scene_carousel_visibility: true,
        section_menu_title:                'World AR'
    }
};

const STAMP_AR_VIEW = { title: 'Marker AR Build' };

const STAMP_AR = {
    type:                     'stamp-ar',
    menu_title:               'Marker AR',
    tracking_image_id:        null,
    ar_attachment_ids:        [],
    thumbnail_attachment_ids: [],
    options:                  {
        section_is_visible_in_menu:        true,
        section_scene_carousel_visibility: true,
        section_menu_title:                'Marker AR'
    }
};

const NATIVE_AR_VIEW = { title: 'True Size AR Build' };

const NATIVE_AR = {
    type:                     'native-ar',
    menu_title:               'True Size AR',
    ar_attachment_ids:        [],
    thumbnail_attachment_ids: [],
    options:                  {
        section_is_visible_in_menu:        true,
        section_scene_carousel_visibility: true,
        section_menu_title:                'True Size AR'
    }
};

const BODY_OVERLAY_AR_VIEW = { title: 'Body Overlay Build' };
const BODY_OVERLAY_AR = { options: { section_menu_title: 'Body Overlay AR' } };

const BODY_TWIN_AR_VIEW = { title: 'Body Twin Build' };
const BODY_TWIN_AR = { options: { section_menu_title: 'Body Twin AR' } };

const FACE_AR_VIEW = { title: 'Face Tracking Build' };
const FACE_AR = { options: { section_menu_title: 'Face Tracking AR' } };

const IFRAME_VIEW = { title: 'Full Page Build' };

const IFRAME = {
    type:    'iframe',
    // remote_url:  'https://',
    // open_in_new: false,
    options: {
        section_menu_title:         'Full Page',
        section_is_visible_in_menu: true
    }
};

const IFRAME_ATOMS = [ {
    type:    'iframe',
    options: {
        atom_iframe_remote_url:  'https://',
        atom_iframe_open_in_new: false
    }
} ];

const FULLSCREEN_GALLERY_VIEW = { title: 'Gallery Build' };

const FULLSCREEN_GALLERY = {
    type:    'fullscreen-gallery',
    options: {
        section_is_visible_in_menu: true,
        section_menu_title:         'Image Gallery'
    }
};

const CUSTOM_CODE_VIEW = { title: 'Custom Code View' };

const CUSTOM_CODE = {
    type:    'custom-code',
    options: {
        section_is_visible_in_menu: true,
        section_menu_title:         'Custom Code'
    }
};
const CUSTOM_CODE_ATOMS = [ {
    type:    'custom-code',
    options: { atom_custom_code_value: 'console.log("Here we go")' }
} ];

// eslint-disable-next-line arca/no-default-export
export default {
    PROJECT,
    EXPERIENCE,
    CONTENT,
    OVERLAY,
    OVERLAY_VIEW,
    OVERLAY_ATOMS,
    IFRAME,
    IFRAME_VIEW,
    IFRAME_ATOMS,
    FULLSCREEN_VIDEO,
    FULLSCREEN_VIDEO_VIEW,
    FULLSCREEN_VIDEO_ATOMS,
    FULLSCREEN_GALLERY,
    FULLSCREEN_GALLERY_VIEW,
    SLAM_AR,
    STAMP_AR,
    NATIVE_AR,
    CUSTOM_CODE_ATOMS,
    CUSTOM_CODE,
    CUSTOM_CODE_VIEW,
    SCENE_BUILD,
    SCENE_BUILD_VIEW,
    SLAM_AR_VIEW,
    STAMP_AR_VIEW,
    NATIVE_AR_VIEW,
    BODY_OVERLAY_AR_VIEW,
    BODY_TWIN_AR_VIEW,
    BODY_OVERLAY_AR,
    BODY_TWIN_AR,
    FACE_AR_VIEW,
    FACE_AR
};
