import React from 'react';

export function Web() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path d="M9 8.99988C9 9.82833 8.32843 10.4999 7.5 10.4999C6.67157 10.4999 6 9.82833 6 8.99988C6 8.17145 6.67157 7.49988 7.5 7.49988C8.32843 7.49988 9 8.17145 9 8.99988Z" fill="url(#paint0_linear_2336_547)" />
            <path d="M13.5 8.99988C13.5 9.82833 12.8284 10.4999 12 10.4999C11.1716 10.4999 10.5 9.82833 10.5 8.99988C10.5 8.17145 11.1716 7.49988 12 7.49988C12.8284 7.49988 13.5 8.17145 13.5 8.99988Z" fill="url(#paint1_linear_2336_547)" />
            <path d="M16.5 10.4999C17.3284 10.4999 18 9.82833 18 8.99988C18 8.17145 17.3284 7.49988 16.5 7.49988C15.6716 7.49988 15 8.17145 15 8.99988C15 9.82833 15.6716 10.4999 16.5 10.4999Z" fill="url(#paint2_linear_2336_547)" />
            <path d="M9 18.3749C8.37868 18.3749 7.875 18.8786 7.875 19.4999C7.875 20.1212 8.37868 20.6249 9 20.6249H15C15.6213 20.6249 16.125 20.1212 16.125 19.4999C16.125 18.8786 15.6213 18.3749 15 18.3749H9Z" fill="url(#paint3_linear_2336_547)" />
            <path d="M9 24.3749C8.37868 24.3749 7.875 24.8786 7.875 25.4999C7.875 26.1212 8.37868 26.6249 9 26.6249H18C18.6213 26.6249 19.125 26.1212 19.125 25.4999C19.125 24.8786 18.6213 24.3749 18 24.3749H9Z" fill="url(#paint4_linear_2336_547)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M21.4178 18.4554C21 18.2882 20.5228 18.3862 20.2045 18.7044C19.8863 19.0226 19.7884 19.4999 19.9555 19.9177L22.9555 27.4177C23.0959 27.7688 23.4034 28.0259 23.7738 28.1019C24.1443 28.178 24.5281 28.0628 24.7955 27.7954L26.25 26.3409L27.7045 27.7954C28.1438 28.2347 28.8562 28.2347 29.2955 27.7954C29.7348 27.356 29.7348 26.6437 29.2955 26.2044L27.841 24.7499L29.2955 23.2954C29.5629 23.028 29.6781 22.6441 29.6021 22.2737C29.526 21.9033 29.269 21.5958 28.9178 21.4554L21.4178 18.4554ZM24.4109 24.998L23.0195 21.5193L26.4981 22.9108L24.4109 24.998Z" fill="url(#paint5_linear_2336_547)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.875 8.99988V26.9999C1.875 30.1065 4.3934 32.6249 7.5 32.6249H28.5C31.6066 32.6249 34.125 30.1065 34.125 26.9999V8.99988C34.125 5.89328 31.6066 3.37488 28.5 3.37488H7.5C4.3934 3.37488 1.875 5.89328 1.875 8.99988ZM7.5 5.62488C5.63604 5.62488 4.125 7.13592 4.125 8.99988V12.3749H31.875V8.99988C31.875 7.13592 30.364 5.62488 28.5 5.62488H7.5ZM31.875 26.9999V14.6249H4.125V26.9999C4.125 28.8638 5.63604 30.3749 7.5 30.3749H28.5C30.364 30.3749 31.875 28.8638 31.875 26.9999Z" fill="url(#paint6_linear_2336_547)" />
            <defs>
                <linearGradient id="paint0_linear_2336_547" x1="34.125" y1="3.37488" x2="5.01387" y2="35.4718" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2336_547" x1="34.125" y1="3.37488" x2="5.01387" y2="35.4718" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint2_linear_2336_547" x1="34.125" y1="3.37488" x2="5.01387" y2="35.4718" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint3_linear_2336_547" x1="34.125" y1="3.37488" x2="5.01387" y2="35.4718" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint4_linear_2336_547" x1="34.125" y1="3.37488" x2="5.01387" y2="35.4718" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint5_linear_2336_547" x1="34.125" y1="3.37488" x2="5.01387" y2="35.4718" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint6_linear_2336_547" x1="34.125" y1="3.37488" x2="5.01387" y2="35.4718" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function CarouselAlt() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path d="M12 1.875C9.72183 1.875 7.875 3.72183 7.875 6V18C7.875 18.6213 8.37868 19.125 9 19.125C9.62132 19.125 10.125 18.6213 10.125 18V6C10.125 4.96447 10.9645 4.125 12 4.125H15V4.50001C15 4.89784 15.158 5.27937 15.4393 5.56067C15.7206 5.84198 16.1022 6.00001 16.5 6.00001L19.5 6C20.3284 6 21 5.32842 21 4.5V4.125H24C25.0355 4.125 25.875 4.96447 25.875 6V18C25.875 18.6213 26.3787 19.125 27 19.125C27.6213 19.125 28.125 18.6213 28.125 18V6C28.125 3.72183 26.2782 1.875 24 1.875H12Z" fill="url(#paint0_linear_2336_655)" />
            <path d="M10.125 30C10.125 29.3787 9.62132 28.875 9 28.875C8.37868 28.875 7.875 29.3787 7.875 30C7.875 32.2782 9.72183 34.125 12 34.125H24C26.2782 34.125 28.125 32.2782 28.125 30C28.125 29.3787 27.6213 28.875 27 28.875C26.3787 28.875 25.875 29.3787 25.875 30C25.875 31.0355 25.0355 31.875 24 31.875H12C10.9645 31.875 10.125 31.0355 10.125 30Z" fill="url(#paint1_linear_2336_655)" />
            <path d="M25.5 27C27.1569 27 28.5 25.6569 28.5 24C28.5 22.3431 27.1569 21 25.5 21C23.8431 21 22.5 22.3431 22.5 24C22.5 25.6569 23.8431 27 25.5 27Z" fill="url(#paint2_linear_2336_655)" />
            <path d="M10.5 27C12.1569 27 13.5 25.6569 13.5 24C13.5 22.3431 12.1569 21 10.5 21C8.84315 21 7.5 22.3431 7.5 24C7.5 25.6569 8.84315 27 10.5 27Z" fill="url(#paint3_linear_2336_655)" />
            <path d="M31.5455 27.0455C31.1062 27.4848 30.3938 27.4848 29.9545 27.0455C29.5152 26.6062 29.5152 25.8938 29.9545 25.4545L31.409 24L29.9545 22.5455C29.5152 22.1062 29.5152 21.3938 29.9545 20.9545C30.3938 20.5152 31.1062 20.5152 31.5455 20.9545L33.7955 23.2045C34.2348 23.6438 34.2348 24.3562 33.7955 24.7955L31.5455 27.0455Z" fill="url(#paint4_linear_2336_655)" />
            <path d="M6.0455 27.0455C5.60616 27.4848 4.89385 27.4848 4.45451 27.0455L2.2045 24.7955C1.76517 24.3562 1.76517 23.6438 2.2045 23.2045L4.45451 20.9545C4.89385 20.5152 5.60616 20.5152 6.0455 20.9545C6.48484 21.3938 6.48484 22.1062 6.0455 22.5455L4.59099 24L6.0455 25.4545C6.48484 25.8938 6.48484 26.6062 6.0455 27.0455Z" fill="url(#paint5_linear_2336_655)" />
            <path d="M18 27C19.6569 27 21 25.6569 21 24C21 22.3431 19.6569 21 18 21C16.3431 21 15 22.3431 15 24C15 25.6569 16.3431 27 18 27Z" fill="url(#paint6_linear_2336_655)" />
            <defs>
                <linearGradient id="paint0_linear_2336_655" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2336_655" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint2_linear_2336_655" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint3_linear_2336_655" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint4_linear_2336_655" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint5_linear_2336_655" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint6_linear_2336_655" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function MarkerAR({ defs }: { defs?: React.ReactNode }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 36 36" fill="none">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.0788 2.03532C25.7225 1.82156 25.2775 1.82156 24.9212 2.03532L17.4389 6.52472C17.0993 6.72049 16.875 7.08404 16.875 7.5V16.5C16.875 16.8952 17.0823 17.2614 17.4212 17.4647L24.9012 21.9527C25.2509 22.1721 25.704 22.1896 26.0788 21.9647L33.5788 17.4647C33.9177 17.2614 34.125 16.8952 34.125 16.5V7.5C34.125 7.08303 33.8982 6.71905 33.5611 6.52472L26.0788 2.03532ZM19.125 15.863V9.48696L24.375 12.637V19.013L19.125 15.863ZM26.625 19.013V12.637L31.875 9.48696V15.863L26.625 19.013ZM30.8134 7.5L25.5 10.688L20.1866 7.5L25.5 4.31196L30.8134 7.5Z"
                fill="url(#gradient-warm-3)"
            />
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.95389 28.455C5.74306 28.0145 5.625 27.521 5.625 27L5.625 9C5.625 7.13604 7.13604 5.625 9 5.625L16.5 5.625C17.1213 5.625 17.625 5.12132 17.625 4.5C17.625 3.87868 17.1213 3.375 16.5 3.375H9C5.8934 3.375 3.375 5.8934 3.375 9V27C3.375 30.1066 5.8934 32.625 9 32.625H27C30.1066 32.625 32.625 30.1066 32.625 27V22.5C32.625 21.8787 32.1213 21.375 31.5 21.375C30.8787 21.375 30.375 21.8787 30.375 22.5V27C30.375 28.7608 29.0266 30.2067 27.306 30.3613C27.2515 30.2129 27.1647 30.0736 27.0455 29.9544L18.6668 21.5757C17.0559 19.9648 14.4441 19.9648 12.8332 21.5757L5.95389 28.455ZM7.54485 30.0461C7.98545 30.2569 8.47894 30.375 9 30.375H24.2841L17.0758 23.1667C16.3436 22.4345 15.1564 22.4345 14.4242 23.1667L7.54485 30.0461Z"
                fill="url(#gradient-warm-3)"
            />
            <path
                className="fill"
                d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                fill="url(#gradient-warm-3)"
            />
            { defs }
        </svg>
    );
}

export function Body() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.875 10.5C10.875 6.56497 14.065 3.375 18 3.375C21.935 3.375 25.125 6.56497 25.125 10.5C25.125 13.5625 23.1928 16.1738 20.4809 17.1812C22.4837 17.6856 24.2493 18.7868 25.5776 20.2845C28.4983 18.0193 30.375 14.4786 30.375 10.5C30.375 9.87868 30.8787 9.375 31.5 9.375C32.1213 9.375 32.625 9.87868 32.625 10.5C32.625 15.2404 30.3688 19.4535 26.876 22.1244C27.6721 23.5707 28.125 25.2325 28.125 27V28.5C28.125 30.7782 26.2782 32.625 24 32.625H12C9.72183 32.625 7.875 30.7782 7.875 28.5V27C7.875 25.2325 8.32792 23.5707 9.12404 22.1244C5.63116 19.4535 3.375 15.2404 3.375 10.5C3.375 9.87868 3.87868 9.375 4.5 9.375C5.12132 9.375 5.625 9.87868 5.625 10.5C5.625 14.4786 7.50175 18.0193 10.4224 20.2845C11.7507 18.7868 13.5163 17.6856 15.519 17.1812C12.8072 16.1738 10.875 13.5625 10.875 10.5ZM18 5.625C15.3076 5.625 13.125 7.80761 13.125 10.5C13.125 13.1924 15.3076 15.375 18 15.375C20.6924 15.375 22.875 13.1924 22.875 10.5C22.875 7.80761 20.6924 5.625 18 5.625ZM10.125 27C10.125 22.6508 13.6508 19.125 18 19.125C22.3492 19.125 25.875 22.6508 25.875 27V28.5C25.875 29.5355 25.0355 30.375 24 30.375H12C10.9645 30.375 10.125 29.5355 10.125 28.5V27Z" fill="url(#paint0_linear_2336_541)" />
            <defs>
                <linearGradient id="paint0_linear_2336_541" x1="32.625" y1="3.375" x2="3.375" y2="32.625" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function Head() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path className="fill" d="M13.875 16.5C13.875 15.4645 13.0355 14.625 12 14.625C10.9645 14.625 10.125 15.4645 10.125 16.5C10.125 17.1213 9.62132 17.625 9 17.625C8.37868 17.625 7.875 17.1213 7.875 16.5C7.875 14.2218 9.72183 12.375 12 12.375C14.2782 12.375 16.125 14.2218 16.125 16.5C16.125 17.1213 15.6213 17.625 15 17.625C14.3787 17.625 13.875 17.1213 13.875 16.5Z" fill="url(#paint0_linear_2336_649)" />
            <path className="fill" d="M24 14.625C25.0355 14.625 25.875 15.4645 25.875 16.5C25.875 17.1213 26.3787 17.625 27 17.625C27.6213 17.625 28.125 17.1213 28.125 16.5C28.125 14.2218 26.2782 12.375 24 12.375C21.7218 12.375 19.875 14.2218 19.875 16.5C19.875 17.1213 20.3787 17.625 21 17.625C21.6213 17.625 22.125 17.1213 22.125 16.5C22.125 15.4645 22.9645 14.625 24 14.625Z" fill="url(#paint1_linear_2336_649)" />
            <path className="fill" d="M24.0988 24.0988C24.5382 23.6594 24.5382 22.9471 24.0988 22.5078C23.6595 22.0684 22.9472 22.0684 22.5078 22.5078C20.0182 24.9974 15.9818 24.9974 13.4922 22.5078C13.0529 22.0684 12.3406 22.0684 11.9012 22.5078C11.4619 22.9471 11.4619 23.6594 11.9012 24.0988C15.2695 27.4671 20.7305 27.4671 24.0988 24.0988Z" fill="url(#paint2_linear_2336_649)" />
            <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M18 1.875C9.09441 1.875 1.875 9.09441 1.875 18C1.875 26.9056 9.09441 34.125 18 34.125C26.9056 34.125 34.125 26.9056 34.125 18C34.125 9.09441 26.9056 1.875 18 1.875ZM4.125 18C4.125 10.337 10.337 4.125 18 4.125C25.663 4.125 31.875 10.337 31.875 18C31.875 25.663 25.663 31.875 18 31.875C10.337 31.875 4.125 25.663 4.125 18Z" fill="url(#paint3_linear_2336_649)" />
            <defs>
                <linearGradient id="paint0_linear_2336_649" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2336_649" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint2_linear_2336_649" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint3_linear_2336_649" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function ProductViewer() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M26.0788 2.03532C25.7225 1.82156 25.2775 1.82156 24.9212 2.03532L17.4389 6.52472C17.0993 6.72049 16.875 7.08404 16.875 7.5V16.5C16.875 16.8952 17.0823 17.2614 17.4212 17.4647L24.9012 21.9527C25.2509 22.1721 25.704 22.1896 26.0788 21.9647L33.5788 17.4647C33.9177 17.2614 34.125 16.8952 34.125 16.5V7.5C34.125 7.08303 33.8982 6.71905 33.5611 6.52472L26.0788 2.03532ZM19.125 15.863V9.48697L24.375 12.637V19.013L19.125 15.863ZM26.625 19.013V12.637L31.875 9.48697V15.863L26.625 19.013ZM30.8134 7.5L25.5 10.688L20.1866 7.5L25.5 4.31196L30.8134 7.5Z" fill="url(#paint0_linear_2336_661)" />
            <path d="M7.5455 5.95451C7.98484 6.39385 7.98484 7.10616 7.5455 7.5455C7.10616 7.98484 6.39384 7.98484 5.9545 7.5455L5.625 7.21599V30.375H28.784L28.4545 30.0455C28.0152 29.6062 28.0152 28.8939 28.4545 28.4545C28.8938 28.0152 29.6062 28.0152 30.0455 28.4545L32.2955 30.7045C32.7348 31.1439 32.7348 31.8562 32.2955 32.2955L30.0455 34.5455C29.6062 34.9848 28.8938 34.9848 28.4545 34.5455C28.0152 34.1062 28.0152 33.3939 28.4545 32.9545L28.784 32.625H4.5C3.87868 32.625 3.375 32.1213 3.375 31.5V7.21599L3.0455 7.5455C2.60616 7.98484 1.89384 7.98484 1.4545 7.5455C1.01517 7.10616 1.01517 6.39385 1.4545 5.95451L3.7045 3.70451C4.14384 3.26517 4.85616 3.26517 5.2955 3.70451L7.5455 5.95451Z" fill="url(#paint1_linear_2336_661)" />
            <path d="M7.875 18C7.875 17.3787 8.37868 16.875 9 16.875H13.5C14.1213 16.875 14.625 17.3787 14.625 18C14.625 18.6213 14.1213 19.125 13.5 19.125H9C8.37868 19.125 7.875 18.6213 7.875 18Z" fill="url(#paint2_linear_2336_661)" />
            <path d="M9 21.375C8.37868 21.375 7.875 21.8787 7.875 22.5C7.875 23.1213 8.37868 23.625 9 23.625H18C18.6213 23.625 19.125 23.1213 19.125 22.5C19.125 21.8787 18.6213 21.375 18 21.375H9Z" fill="url(#paint3_linear_2336_661)" />
            <path d="M7.875 27C7.875 26.3787 8.37868 25.875 9 25.875H24C24.6213 25.875 25.125 26.3787 25.125 27C25.125 27.6213 24.6213 28.125 24 28.125H9C8.37868 28.125 7.875 27.6213 7.875 27Z" fill="url(#paint4_linear_2336_661)" />
            <defs>
                <linearGradient id="paint0_linear_2336_661" x1="34.125" y1="1.875" x2="1.12499" y2="34.875" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2336_661" x1="34.125" y1="1.875" x2="1.12499" y2="34.875" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint2_linear_2336_661" x1="34.125" y1="1.875" x2="1.12499" y2="34.875" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint3_linear_2336_661" x1="34.125" y1="1.875" x2="1.12499" y2="34.875" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint4_linear_2336_661" x1="34.125" y1="1.875" x2="1.12499" y2="34.875" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function Sections() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5 9.375C12.8787 9.375 12.375 9.87868 12.375 10.5V19.5C12.375 20.1213 12.8787 20.625 13.5 20.625H22.5C23.1213 20.625 23.625 20.1213 23.625 19.5V10.5C23.625 9.87868 23.1213 9.375 22.5 9.375H13.5ZM14.625 18.375V11.625H21.375V18.375H14.625Z" fill="url(#paint0_linear_2336_667)" />
            <path d="M12.375 28.5C12.375 27.8787 12.8787 27.375 13.5 27.375H22.5C23.1213 27.375 23.625 27.8787 23.625 28.5C23.625 29.1213 23.1213 29.625 22.5 29.625H13.5C12.8787 29.625 12.375 29.1213 12.375 28.5Z" fill="url(#paint1_linear_2336_667)" />
            <path d="M13.5 22.875C12.8787 22.875 12.375 23.3787 12.375 24C12.375 24.6213 12.8787 25.125 13.5 25.125H22.5C23.1213 25.125 23.625 24.6213 23.625 24C23.625 23.3787 23.1213 22.875 22.5 22.875H13.5Z" fill="url(#paint2_linear_2336_667)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.875 6C7.875 3.72183 9.72183 1.875 12 1.875H24C26.2782 1.875 28.125 3.72183 28.125 6V30C28.125 32.2782 26.2782 34.125 24 34.125H12C9.72183 34.125 7.875 32.2782 7.875 30V6ZM12 4.125C10.9645 4.125 10.125 4.96447 10.125 6V30C10.125 31.0355 10.9645 31.875 12 31.875H24C25.0355 31.875 25.875 31.0355 25.875 30V6C25.875 4.96447 25.0355 4.125 24 4.125H21V4.5C21 5.32842 20.3284 6 19.5 6L16.5 6.00001C16.1022 6.00001 15.7206 5.84198 15.4393 5.56067C15.158 5.27937 15 4.89784 15 4.50001V4.125H12Z" fill="url(#paint3_linear_2336_667)" />
            <defs>
                <linearGradient id="paint0_linear_2336_667" x1="28.125" y1="1.875" x2="-0.922517" y2="20.1141" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2336_667" x1="28.125" y1="1.875" x2="-0.922517" y2="20.1141" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint2_linear_2336_667" x1="28.125" y1="1.875" x2="-0.922517" y2="20.1141" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint3_linear_2336_667" x1="28.125" y1="1.875" x2="-0.922517" y2="20.1141" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function Code() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path d="M20.645 10.9466C21.2267 11.1647 21.5215 11.8132 21.3033 12.395L16.8033 24.395C16.5851 24.9767 15.9367 25.2715 15.3549 25.0533C14.7732 24.8351 14.4784 24.1867 14.6966 23.6049L19.1966 11.6049C19.4147 11.0232 20.0632 10.7284 20.645 10.9466Z" fill="url(#paint0_linear_2336_583)" />
            <path d="M11.2045 14.2044C11.6438 13.7651 12.3562 13.7651 12.7955 14.2044C13.2348 14.6438 13.2348 15.3561 12.7955 15.7954L10.591 17.9999L12.7955 20.2044C13.2348 20.6438 13.2348 21.3561 12.7955 21.7954C12.3562 22.2347 11.6438 22.2347 11.2045 21.7954L8.2045 18.7954C7.76516 18.3561 7.76516 17.6438 8.2045 17.2044L11.2045 14.2044Z" fill="url(#paint1_linear_2336_583)" />
            <path d="M23.2045 14.2044C23.6438 13.7651 24.3562 13.7651 24.7955 14.2044L27.7955 17.2044C28.2348 17.6438 28.2348 18.3561 27.7955 18.7954L24.7955 21.7954C24.3562 22.2347 23.6438 22.2347 23.2045 21.7954C22.7652 21.3561 22.7652 20.6438 23.2045 20.2044L25.409 17.9999L23.2045 15.7954C22.7652 15.3561 22.7652 14.6438 23.2045 14.2044Z" fill="url(#paint2_linear_2336_583)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.5 3.375C4.3934 3.375 1.875 5.8934 1.875 9V27C1.875 30.1066 4.3934 32.625 7.5 32.625H28.5C31.6066 32.625 34.125 30.1066 34.125 27V9C34.125 5.8934 31.6066 3.375 28.5 3.375H7.5ZM4.125 9C4.125 7.13604 5.63604 5.625 7.5 5.625H28.5C30.364 5.625 31.875 7.13604 31.875 9V27C31.875 28.864 30.364 30.375 28.5 30.375H7.5C5.63604 30.375 4.125 28.864 4.125 27V9Z" fill="url(#paint3_linear_2336_583)" />
            <defs>
                <linearGradient id="paint0_linear_2336_583" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2336_583" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint2_linear_2336_583" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint3_linear_2336_583" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function SDKAdd() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path d="M18 1.875C18.6213 1.875 19.125 2.37868 19.125 3V6.375L22.5 6.375C23.1213 6.375 23.625 6.87868 23.625 7.5C23.625 8.12132 23.1213 8.625 22.5 8.625L19.125 8.625V12C19.125 12.6213 18.6213 13.125 18 13.125C17.3787 13.125 16.875 12.6213 16.875 12V8.625L13.5 8.625C12.8786 8.625 12.375 8.12132 12.375 7.5C12.375 6.87868 12.8786 6.375 13.5 6.375L16.875 6.375V3C16.875 2.37868 17.3787 1.875 18 1.875Z" fill="url(#paint0_linear_2336_565)" />
            <path d="M23.0194 11.0733C22.7148 11.6148 22.9069 12.3007 23.4484 12.6054L27.7053 14.9998L18.1838 20.3557C18.0697 20.4199 17.9303 20.4199 17.8161 20.3557L8.29467 14.9998L12.5515 12.6054C13.093 12.3007 13.2851 11.6148 12.9805 11.0733C12.6759 10.5318 11.99 10.3397 11.4484 10.6443L5.44843 14.0193C5.09419 14.2186 4.87497 14.5934 4.87497 14.9998C4.87497 15.4063 5.09419 15.7811 5.44843 15.9804L16.713 22.3167C17.5121 22.7662 18.4878 22.7662 19.2869 22.3167L30.5515 15.9804C30.9058 15.7811 31.125 15.4063 31.125 14.9998C31.125 14.5934 30.9058 14.2186 30.5515 14.0193L24.5515 10.6443C24.01 10.3397 23.3241 10.5318 23.0194 11.0733Z" fill="url(#paint1_linear_2336_565)" />
            <path d="M30.9805 20.4483C30.6759 19.9068 29.9899 19.7147 29.4484 20.0193L19.6546 25.5283C18.6272 26.1062 17.3727 26.1062 16.3453 25.5283L6.5515 20.0193C6.00997 19.7147 5.32404 19.9068 5.01943 20.4483C4.71482 20.9898 4.90688 21.6757 5.44841 21.9804L15.2422 27.4894C16.9546 28.4526 19.0453 28.4526 20.7577 27.4894L30.5515 21.9804C31.093 21.6757 31.2851 20.9898 30.9805 20.4483Z" fill="url(#paint2_linear_2336_565)" />
            <path d="M29.4484 26.0193C29.9899 25.7147 30.6759 25.9068 30.9805 26.4483C31.2851 26.9898 31.093 27.6757 30.5515 27.9804L20.7577 33.4894C19.0453 34.4526 16.9546 34.4526 15.2422 33.4894L5.44841 27.9804C4.90688 27.6757 4.71482 26.9898 5.01943 26.4483C5.32404 25.9068 6.00997 25.7147 6.5515 26.0193L16.3453 31.5283C17.3727 32.1062 18.6272 32.1062 19.6546 31.5283L29.4484 26.0193Z" fill="url(#paint3_linear_2336_565)" />
            <defs>
                <linearGradient id="paint0_linear_2336_565" x1="31.1252" y1="1.875" x2="-0.521096" y2="27.5648" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2336_565" x1="31.1252" y1="1.875" x2="-0.521096" y2="27.5648" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint2_linear_2336_565" x1="31.1252" y1="1.875" x2="-0.521096" y2="27.5648" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint3_linear_2336_565" x1="31.1252" y1="1.875" x2="-0.521096" y2="27.5648" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function ViewAlt() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path d="M20.9169 19.3258C20.4776 18.8865 19.7653 18.8865 19.3259 19.3258C18.5937 20.0581 17.4065 20.0581 16.6743 19.3258C15.9421 18.5936 15.9421 17.4064 16.6743 16.6742C17.4065 15.9419 18.5937 15.9419 19.3259 16.6742C19.7653 17.1135 20.4776 17.1135 20.9169 16.6742C21.3563 16.2348 21.3563 15.5225 20.9169 15.0832C20.4005 14.5668 19.7813 14.2159 19.1251 14.0306V13.5C19.1251 12.8787 18.6214 12.375 18.0001 12.375C17.3788 12.375 16.8751 12.8787 16.8751 13.5V14.0306C16.219 14.2159 15.5997 14.5668 15.0833 15.0832C13.4724 16.6941 13.4724 19.3059 15.0833 20.9168C15.5997 21.4332 16.219 21.7841 16.8751 21.9694V22.5C16.8751 23.1213 17.3788 23.625 18.0001 23.625C18.6214 23.625 19.1251 23.1213 19.1251 22.5V21.9694C19.7813 21.7841 20.4005 21.4332 20.9169 20.9168C21.3563 20.4775 21.3563 19.7652 20.9169 19.3258Z" fill="url(#paint0_linear_2336_535)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.37512 18C9.37512 13.2365 13.2367 9.375 18.0001 9.375C22.7636 9.375 26.6251 13.2365 26.6251 18C26.6251 22.7635 22.7636 26.625 18.0001 26.625C13.2367 26.625 9.37512 22.7635 9.37512 18ZM18.0001 11.625C14.4793 11.625 11.6251 14.4792 11.6251 18C11.6251 21.5208 14.4793 24.375 18.0001 24.375C21.5209 24.375 24.3751 21.5208 24.3751 18C24.3751 14.4792 21.5209 11.625 18.0001 11.625Z" fill="url(#paint1_linear_2336_535)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.22465 9.99752C9.81475 7.34764 13.4473 4.875 18.0001 4.875C22.5529 4.875 26.1854 7.34764 28.7755 9.99752C31.3729 12.6549 33.0464 15.6136 33.7003 16.8781C34.0669 17.587 34.0669 18.413 33.7003 19.1219C33.0464 20.3865 31.3729 23.3451 28.7755 26.0025C26.1854 28.6524 22.5529 31.125 18.0001 31.125C13.4473 31.125 9.81475 28.6524 7.22465 26.0025C4.62723 23.3451 2.95379 20.3865 2.29986 19.1219C1.93325 18.413 1.93325 17.587 2.29986 16.8781C2.95379 15.6136 4.62723 12.6549 7.22465 9.99752ZM8.83369 11.5703C6.4575 14.0013 4.90429 16.74 4.29844 17.9116C4.26706 17.9723 4.26706 18.0277 4.29844 18.0884C4.90429 19.26 6.4575 21.9987 8.83369 24.4297C11.2172 26.8683 14.3052 28.875 18.0001 28.875C21.695 28.875 24.783 26.8683 27.1665 24.4297C29.5427 21.9987 31.0959 19.26 31.7017 18.0884C31.7331 18.0277 31.7331 17.9723 31.7017 17.9116C31.0959 16.74 29.5427 14.0013 27.1665 11.5703C24.783 9.13173 21.695 7.125 18.0001 7.125C14.3052 7.125 11.2172 9.13173 8.83369 11.5703Z" fill="url(#paint2_linear_2336_535)" />
            <defs>
                <linearGradient id="paint0_linear_2336_535" x1="33.9753" y1="4.875" x2="8.22412" y2="36.2182" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2336_535" x1="33.9753" y1="4.875" x2="8.22412" y2="36.2182" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint2_linear_2336_535" x1="33.9753" y1="4.875" x2="8.22412" y2="36.2182" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function WhiteLabel() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M18 7.875C12.4081 7.875 7.875 12.4081 7.875 18C7.875 23.5919 12.4081 28.125 18 28.125C23.5919 28.125 28.125 23.5919 28.125 18C28.125 12.4081 23.5919 7.875 18 7.875ZM10.125 18C10.125 13.6508 13.6508 10.125 18 10.125C22.3492 10.125 25.875 13.6508 25.875 18C25.875 22.3492 22.3492 25.875 18 25.875C13.6508 25.875 10.125 22.3492 10.125 18Z" fill="url(#paint0_linear_2336_607)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M3.375 27C3.375 30.1066 5.8934 32.625 9 32.625H27C30.1066 32.625 32.625 30.1066 32.625 27V9C32.625 5.8934 30.1066 3.375 27 3.375H9C5.8934 3.375 3.375 5.8934 3.375 9V27ZM9 30.375C7.13604 30.375 5.625 28.864 5.625 27L5.625 9C5.625 7.13604 7.13604 5.625 9 5.625L27 5.625C28.864 5.625 30.375 7.13604 30.375 9V27C30.375 28.864 28.864 30.375 27 30.375H9Z" fill="url(#paint1_linear_2336_607)" />
            <defs>
                <linearGradient id="paint0_linear_2336_607" x1="32.625" y1="3.375" x2="3.375" y2="32.625" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2336_607" x1="32.625" y1="3.375" x2="3.375" y2="32.625" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function Loader() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path d="M19.125 3C19.125 2.37868 18.6213 1.875 18 1.875C17.3787 1.875 16.875 2.37868 16.875 3V9C16.875 9.62132 17.3787 10.125 18 10.125C18.6213 10.125 19.125 9.62132 19.125 9V3Z" fill="url(#paint0_linear_2336_637)" />
            <path d="M33 19.125C33.6213 19.125 34.125 18.6213 34.125 18C34.125 17.3787 33.6213 16.875 33 16.875H27C26.3787 16.875 25.875 17.3787 25.875 18C25.875 18.6213 26.3787 19.125 27 19.125H33Z" fill="url(#paint1_linear_2336_637)" />
            <path d="M18 25.875C18.6213 25.875 19.125 26.3787 19.125 27V33C19.125 33.6213 18.6213 34.125 18 34.125C17.3787 34.125 16.875 33.6213 16.875 33V27C16.875 26.3787 17.3787 25.875 18 25.875Z" fill="url(#paint2_linear_2336_637)" />
            <path d="M26.4743 5.57206C26.7849 5.03398 26.6006 4.34594 26.0625 4.03528C25.5244 3.72462 24.8364 3.90898 24.5257 4.44706L22.2757 8.34417C21.965 8.88225 22.1494 9.57029 22.6875 9.88095C23.2256 10.1916 23.9136 10.0072 24.2243 9.46917L26.4743 5.57206Z" fill="url(#paint3_linear_2336_637)" />
            <path d="M13.3125 26.1189C13.8506 26.4296 14.0349 27.1176 13.7243 27.6557L11.4743 31.5528C11.1636 32.0909 10.4756 32.2752 9.93747 31.9646C9.39939 31.6539 9.21504 30.9659 9.5257 30.4278L11.7757 26.5307C12.0864 25.9926 12.7744 25.8082 13.3125 26.1189Z" fill="url(#paint4_linear_2336_637)" />
            <path d="M24.5257 31.5529C24.8364 32.091 25.5244 32.2754 26.0625 31.9647C26.6006 31.6541 26.785 30.966 26.4743 30.4279L24.2243 26.5308C23.9136 25.9927 23.2256 25.8084 22.6875 26.1191C22.1494 26.4297 21.9651 27.1177 22.2757 27.6558L24.5257 31.5529Z" fill="url(#paint5_linear_2336_637)" />
            <path d="M13.3125 9.8811C12.7744 10.1918 12.0864 10.0074 11.7757 9.46933L9.52575 5.57221C9.21509 5.03413 9.39945 4.34609 9.93752 4.03543C10.4756 3.72477 11.1636 3.90913 11.4743 4.44721L13.7243 8.34433C14.035 8.8824 13.8506 9.57044 13.3125 9.8811Z" fill="url(#paint6_linear_2336_637)" />
            <path d="M30.4279 26.4744C30.966 26.7851 31.6541 26.6007 31.9647 26.0627C32.2754 25.5246 32.091 24.8365 31.5529 24.5259L27.6558 22.2759C27.1177 21.9652 26.4297 22.1496 26.1191 22.6877C25.8084 23.2257 25.9927 23.9138 26.5308 24.2244L30.4279 26.4744Z" fill="url(#paint7_linear_2336_637)" />
            <path d="M9.88092 13.3127C9.57026 13.8507 8.88222 14.0351 8.34414 13.7244L4.44703 11.4744C3.90895 11.1638 3.72459 10.4757 4.03525 9.93766C4.34591 9.39958 5.03395 9.21522 5.57203 9.52588L9.46914 11.7759C10.0072 12.0865 10.1916 12.7746 9.88092 13.3127Z" fill="url(#paint8_linear_2336_637)" />
            <path d="M31.5529 11.4744C32.091 11.1638 32.2754 10.4757 31.9647 9.93766C31.6541 9.39958 30.966 9.21522 30.4279 9.52588L26.5308 11.7759C25.9927 12.0865 25.8084 12.7746 26.1191 13.3127C26.4297 13.8507 27.1177 14.0351 27.6558 13.7244L31.5529 11.4744Z" fill="url(#paint9_linear_2336_637)" />
            <path d="M9.88092 22.6877C10.1916 23.2257 10.0072 23.9138 9.46914 24.2244L5.57203 26.4744C5.03395 26.7851 4.34591 26.6007 4.03525 26.0627C3.72459 25.5246 3.90895 24.8365 4.44703 24.5259L8.34414 22.2759C8.88222 21.9652 9.57026 22.1496 9.88092 22.6877Z" fill="url(#paint10_linear_2336_637)" />
            <path d="M9 19.125C9.62132 19.125 10.125 18.6213 10.125 18C10.125 17.3787 9.62132 16.875 9 16.875H3C2.37868 16.875 1.875 17.3787 1.875 18C1.875 18.6213 2.37868 19.125 3 19.125H9Z" fill="url(#paint11_linear_2336_637)" />
            <defs>
                <linearGradient id="paint0_linear_2336_637" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2336_637" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint2_linear_2336_637" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint3_linear_2336_637" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint4_linear_2336_637" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint5_linear_2336_637" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint6_linear_2336_637" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint7_linear_2336_637" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint8_linear_2336_637" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint9_linear_2336_637" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint10_linear_2336_637" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint11_linear_2336_637" x1="34.125" y1="1.875" x2="1.875" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function TeamLib() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M18 1.875C15.7218 1.875 13.875 3.72183 13.875 6C13.875 8.27817 15.7218 10.125 18 10.125C20.2782 10.125 22.125 8.27817 22.125 6C22.125 3.72183 20.2782 1.875 18 1.875ZM16.125 6C16.125 4.96447 16.9645 4.125 18 4.125C19.0355 4.125 19.875 4.96447 19.875 6C19.875 7.03553 19.0355 7.875 18 7.875C16.9645 7.875 16.125 7.03553 16.125 6Z" fill="url(#paint0_linear_2336_571)" />
            <path d="M28.5 20.625L22.5 20.625C21.8787 20.625 21.375 20.1213 21.375 19.5C21.375 18.8787 21.8787 18.375 22.5 18.375L28.5 18.375C29.1213 18.375 29.625 18.8787 29.625 19.5C29.625 20.1213 29.1213 20.625 28.5 20.625Z" fill="url(#paint1_linear_2336_571)" />
            <path d="M28.5 26.625L22.5 26.625C21.8787 26.625 21.375 26.1213 21.375 25.5C21.375 24.8787 21.8787 24.375 22.5 24.375L28.5 24.375C29.1213 24.375 29.625 24.8787 29.625 25.5C29.625 26.1213 29.1213 26.625 28.5 26.625Z" fill="url(#paint2_linear_2336_571)" />
            <path d="M7.5 20.625L13.5 20.625C14.1213 20.625 14.625 20.1213 14.625 19.5C14.625 18.8787 14.1213 18.375 13.5 18.375L7.5 18.375C6.87868 18.375 6.375 18.8787 6.375 19.5C6.375 20.1213 6.87868 20.625 7.5 20.625Z" fill="url(#paint3_linear_2336_571)" />
            <path d="M7.5 26.625L13.5 26.625C14.1213 26.625 14.625 26.1213 14.625 25.5C14.625 24.8787 14.1213 24.375 13.5 24.375L7.5 24.375C6.87868 24.375 6.375 24.8787 6.375 25.5C6.375 26.1213 6.87868 26.625 7.5 26.625Z" fill="url(#paint4_linear_2336_571)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.50312 6.49377L15.1864 10.8354C16.3579 11.4212 17.32 12.3038 18 13.3656C18.68 12.3038 19.6421 11.4212 20.8136 10.8354L29.4969 6.49377C30.2316 6.12642 31.125 6.67857 31.125 7.5V12.375H33C33.6213 12.375 34.125 12.8787 34.125 13.5V31.5C34.125 32.1213 33.6213 32.625 33 32.625H21.364C20.4689 32.625 19.6104 32.9806 18.9775 33.6135C18.7024 33.8886 18.4149 34.1251 18 34.125C17.5838 34.1249 17.2984 33.8894 17.0225 33.6135C16.3896 32.9806 15.5311 32.625 14.636 32.625H3C2.37868 32.625 1.875 32.1213 1.875 31.5V13.5C1.875 12.8787 2.37868 12.375 3 12.375H4.875V7.5C4.875 6.67857 5.76841 6.12642 6.50312 6.49377ZM31.875 14.625V30.375H21.364C20.5862 30.375 19.825 30.5361 19.125 30.8398V17.2082C19.125 16.5879 19.2427 15.9855 19.4617 15.4277C19.8006 14.9424 20.3632 14.625 21 14.625H31.875ZM28.875 12.375H22.7656L28.875 9.32029V12.375ZM16.875 30.8398C16.175 30.5361 15.4138 30.375 14.636 30.375H4.125V14.625H15C15.6368 14.625 16.1994 14.9424 16.5383 15.4277C16.7573 15.9855 16.875 16.5879 16.875 17.2082V30.8398ZM7.125 9.32029L13.2344 12.375H7.125V9.32029Z" fill="url(#paint5_linear_2336_571)" />
            <defs>
                <linearGradient id="paint0_linear_2336_571" x1="34.125" y1="1.875" x2="1.87497" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2336_571" x1="34.125" y1="1.875" x2="1.87497" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint2_linear_2336_571" x1="34.125" y1="1.875" x2="1.87497" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint3_linear_2336_571" x1="34.125" y1="1.875" x2="1.87497" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint4_linear_2336_571" x1="34.125" y1="1.875" x2="1.87497" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint5_linear_2336_571" x1="34.125" y1="1.875" x2="1.87497" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function CommercialLicense() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M9 1.875C6.72183 1.875 4.875 3.72183 4.875 6V12C4.875 12.6213 5.37868 13.125 6 13.125C6.62132 13.125 7.125 12.6213 7.125 12V6C7.125 4.96447 7.96447 4.125 9 4.125H21.2574C21.3173 4.125 21.3769 4.12787 21.436 4.13353C21.3965 4.2484 21.375 4.3717 21.375 4.5V10.5C21.375 11.1213 21.8787 11.625 22.5 11.625H28.5C28.6283 11.625 28.7516 11.6035 28.8665 11.564C28.8721 11.6231 28.875 11.6827 28.875 11.7426V30C28.875 31.0355 28.0355 31.875 27 31.875H22.5C21.8787 31.875 21.375 32.3787 21.375 33C21.375 33.6213 21.8787 34.125 22.5 34.125H27C29.2782 34.125 31.125 32.2782 31.125 30V11.7426C31.125 10.6486 30.6904 9.59941 29.9168 8.82583L24.1742 3.08318C23.4006 2.3096 22.3514 1.875 21.2574 1.875H9ZM27.284 9.375L23.625 5.71599V9.375H27.284Z" fill="url(#paint0_linear_2336_595)" />
            <path d="M13.3259 25.3258C12.5937 26.0581 11.4065 26.0581 10.6742 25.3258C9.94202 24.5936 9.94202 23.4064 10.6742 22.6742C11.4065 21.9419 12.5937 21.9419 13.3259 22.6742C13.7652 23.1135 14.4775 23.1135 14.9169 22.6742C15.3562 22.2348 15.3562 21.5225 14.9169 21.0832C13.306 19.4723 10.6942 19.4723 9.08326 21.0832C7.47235 22.6941 7.47235 25.3059 9.08326 26.9168C10.6942 28.5277 13.306 28.5277 14.9169 26.9168C15.3562 26.4775 15.3562 25.7652 14.9169 25.3258C14.4775 24.8865 13.7652 24.8865 13.3259 25.3258Z" fill="url(#paint1_linear_2336_595)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.875 24C1.875 18.4081 6.40812 13.875 12 13.875C17.5919 13.875 22.125 18.4081 22.125 24C22.125 29.5919 17.5919 34.125 12 34.125C6.40812 34.125 1.875 29.5919 1.875 24ZM12 16.125C7.65076 16.125 4.125 19.6508 4.125 24C4.125 28.3492 7.65076 31.875 12 31.875C16.3492 31.875 19.875 28.3492 19.875 24C19.875 19.6508 16.3492 16.125 12 16.125Z" fill="url(#paint2_linear_2336_595)" />
            <defs>
                <linearGradient id="paint0_linear_2336_595" x1="31.125" y1="1.875" x2="-0.971884" y2="30.9861" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2336_595" x1="31.125" y1="1.875" x2="-0.971884" y2="30.9861" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint2_linear_2336_595" x1="31.125" y1="1.875" x2="-0.971884" y2="30.9861" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function NFT() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.6895 2.18743C17.0012 1.71987 17.5006 1.5 18 1.5C18.4994 1.5 18.9988 1.71987 19.3105 2.18743L23.4361 8.3758C23.688 8.75368 23.688 9.24599 23.4361 9.62387L19.3105 15.8122C18.9988 16.2798 18.4994 16.5 18 16.5C17.5006 16.5 17.0012 16.2798 16.6895 15.8122L12.5639 9.62387C12.312 9.24599 12.312 8.75368 12.5639 8.3758L16.6895 2.18743ZM18 13.7217L16.255 11.1043L17.6442 11.5673C17.8751 11.6443 18.1248 11.6443 18.3557 11.5673L19.745 11.1042L18 13.7217ZM18 4.27796L20.747 8.39851L17.9999 9.31421L15.2529 8.39854L18 4.27796Z" fill="url(#paint0_linear_2336_559)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.4805 10.417C11.1759 9.87551 10.49 9.68345 9.94846 9.98806L5.44845 12.5193C5.09422 12.7186 4.875 13.0934 4.875 13.4998V26.9999C4.875 27.4063 5.09422 27.7811 5.44845 27.9804L15.2423 33.4894C16.9546 34.4526 19.0454 34.4526 20.7577 33.4894L30.5515 27.9804C30.9058 27.7811 31.125 27.4063 31.125 26.9999V13.5C31.125 13.0936 30.9058 12.7186 30.5515 12.5193L26.0515 9.98806C25.51 9.68345 24.8241 9.87551 24.5195 10.417C24.2149 10.9586 24.4069 11.6445 24.9485 11.9491L27.7053 13.4998L18.1838 18.8557C18.0697 18.9199 17.9303 18.9199 17.8162 18.8557L8.29469 13.4998L11.0515 11.9491C11.5931 11.6445 11.7851 10.9586 11.4805 10.417ZM7.125 26.3419V15.4234L16.7131 20.8167C16.7676 20.8474 16.8231 20.876 16.8792 20.9025C16.8764 20.9346 16.875 20.9672 16.875 21V31.7688C16.6936 31.7047 16.5163 31.6245 16.3454 31.5284L7.125 26.3419ZM19.125 21C19.125 20.9672 19.1236 20.9346 19.1208 20.9025C19.1769 20.876 19.2324 20.8474 19.2869 20.8167L28.875 15.4234V26.3419L19.6546 31.5284C19.4837 31.6245 19.3064 31.7047 19.125 31.7688V21Z" fill="url(#paint1_linear_2336_559)" />
            <defs>
                <linearGradient id="paint0_linear_2336_559" x1="31.125" y1="1.5" x2="-0.810338" y2="27.1269" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2336_559" x1="31.125" y1="1.5" x2="-0.810338" y2="27.1269" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function Listed() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M30 3.375C27.7218 3.375 25.875 5.22183 25.875 7.5C25.875 9.77817 27.7218 11.625 30 11.625C32.2782 11.625 34.125 9.77817 34.125 7.5C34.125 5.22183 32.2782 3.375 30 3.375ZM28.125 7.5C28.125 6.46447 28.9645 5.625 30 5.625C31.0355 5.625 31.875 6.46447 31.875 7.5C31.875 8.53553 31.0355 9.375 30 9.375C28.9645 9.375 28.125 8.53553 28.125 7.5Z" fill="url(#paint0_linear_2336_613)" />
            <path d="M5.625 10.5C5.625 8.63604 7.13604 7.125 9 7.125H23.25C23.8713 7.125 24.375 6.62132 24.375 6C24.375 5.37868 23.8713 4.875 23.25 4.875H9C5.8934 4.875 3.375 7.3934 3.375 10.5V25.5C3.375 28.6066 5.8934 31.125 9 31.125H13.136C14.0311 31.125 14.8896 31.4806 15.5225 32.1135L17.2045 33.7955C17.4155 34.0065 17.7016 34.125 18 34.125C18.2984 34.125 18.5845 34.0065 18.7955 33.7955L20.4775 32.1135C21.1104 31.4806 21.9689 31.125 22.864 31.125H27C30.1066 31.125 32.625 28.6066 32.625 25.5V14.25C32.625 13.6287 32.1213 13.125 31.5 13.125C30.8787 13.125 30.375 13.6287 30.375 14.25V25.5C30.375 27.364 28.864 28.875 27 28.875H22.864C21.3721 28.875 19.9414 29.4676 18.8865 30.5225L18 31.409L17.1135 30.5225C16.0586 29.4676 14.6279 28.875 13.136 28.875H9C7.13604 28.875 5.625 27.364 5.625 25.5V10.5Z" fill="url(#paint1_linear_2336_613)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.375 15C12.375 11.8934 14.8934 9.375 18 9.375C21.1066 9.375 23.625 11.8934 23.625 15C23.625 17.348 22.1863 19.3601 20.1424 20.2026C22.7577 21.0261 24.7216 23.3172 25.07 26.1108C25.1468 26.7273 24.7094 27.2895 24.0928 27.3664C23.4763 27.4432 22.9141 27.0058 22.8372 26.3892C22.5375 23.9856 20.4852 22.125 18 22.125C15.5149 22.125 13.4625 23.9856 13.1628 26.3892C13.0859 27.0058 12.5238 27.4432 11.9072 27.3664C11.2907 27.2895 10.8532 26.7273 10.9301 26.1108C11.2784 23.3172 13.2423 21.0261 15.8577 20.2027C13.8137 19.3601 12.375 17.3481 12.375 15ZM18 11.625C16.136 11.625 14.625 13.136 14.625 15C14.625 16.864 16.136 18.375 18 18.375C19.864 18.375 21.375 16.864 21.375 15C21.375 13.136 19.864 11.625 18 11.625Z" fill="url(#paint2_linear_2336_613)" />
            <defs>
                <linearGradient id="paint0_linear_2336_613" x1="34.125" y1="3.375" x2="3.375" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2336_613" x1="34.125" y1="3.375" x2="3.375" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint2_linear_2336_613" x1="34.125" y1="3.375" x2="3.375" y2="34.125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function AnalyticsAlt() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path d="M1.875 7.5C1.875 5.22183 3.72183 3.375 6 3.375H30C32.2782 3.375 34.125 5.22183 34.125 7.5V28.5C34.125 30.7782 32.2782 32.625 30 32.625H9C8.37868 32.625 7.875 32.1213 7.875 31.5C7.875 30.8787 8.37868 30.375 9 30.375H30C31.0355 30.375 31.875 29.5355 31.875 28.5V7.5C31.875 6.46447 31.0355 5.625 30 5.625H6C4.96447 5.625 4.125 6.46447 4.125 7.5V25.5C4.125 26.1213 3.62132 26.625 3 26.625C2.37868 26.625 1.875 26.1213 1.875 25.5V7.5Z" fill="url(#paint0_linear_2336_631)" />
            <path d="M9 12.375C9.62132 12.375 10.125 12.8787 10.125 13.5V15C10.125 15.6213 9.62132 16.125 9 16.125C8.37868 16.125 7.875 15.6213 7.875 15V13.5C7.875 12.8787 8.37868 12.375 9 12.375Z" fill="url(#paint1_linear_2336_631)" />
            <path d="M16.125 10.5C16.125 9.87868 15.6213 9.375 15 9.375C14.3787 9.375 13.875 9.87868 13.875 10.5V15C13.875 15.6213 14.3787 16.125 15 16.125C15.6213 16.125 16.125 15.6213 16.125 15V10.5Z" fill="url(#paint2_linear_2336_631)" />
            <path d="M21 13.875C21.6213 13.875 22.125 14.3787 22.125 15V25.5C22.125 26.1213 21.6213 26.625 21 26.625C20.3787 26.625 19.875 26.1213 19.875 25.5V15C19.875 14.3787 20.3787 13.875 21 13.875Z" fill="url(#paint3_linear_2336_631)" />
            <path d="M28.125 10.5C28.125 9.87868 27.6213 9.375 27 9.375C26.3787 9.375 25.875 9.87868 25.875 10.5V25.5C25.875 26.1213 26.3787 26.625 27 26.625C27.6213 26.625 28.125 26.1213 28.125 25.5V10.5Z" fill="url(#paint4_linear_2336_631)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.375 22.5C6.375 19.3934 8.8934 16.875 12 16.875C15.1066 16.875 17.625 19.3934 17.625 22.5C17.625 25.6066 15.1066 28.125 12 28.125C10.8535 28.125 9.78717 27.782 8.89794 27.1931L5.2955 30.7955C4.85616 31.2348 4.14384 31.2348 3.7045 30.7955C3.26517 30.3562 3.26517 29.6438 3.7045 29.2045L7.30695 25.6021C6.71799 24.7128 6.375 23.6465 6.375 22.5ZM12 19.125C10.136 19.125 8.625 20.636 8.625 22.5C8.625 24.364 10.136 25.875 12 25.875C13.864 25.875 15.375 24.364 15.375 22.5C15.375 20.636 13.864 19.125 12 19.125Z" fill="url(#paint5_linear_2336_631)" />
            <defs>
                <linearGradient id="paint0_linear_2336_631" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2336_631" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint2_linear_2336_631" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint3_linear_2336_631" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint4_linear_2336_631" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint5_linear_2336_631" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function NFTCollection() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.9999 1.5C17.5006 1.5 17.0012 1.71987 16.6895 2.18743L12.5639 8.3758C12.312 8.75368 12.312 9.24599 12.5639 9.62387L16.6895 15.8122C17.0012 16.2798 17.5006 16.5 17.9999 16.5C18.4993 16.5 18.9987 16.2798 19.3104 15.8122L23.436 9.62387C23.6879 9.24599 23.6879 8.75368 23.436 8.3758L19.3104 2.18743C18.9987 1.71987 18.4993 1.5 17.9999 1.5ZM16.255 11.1043L17.9999 13.7217L19.7449 11.1042L18.3556 11.5673C18.1247 11.6443 17.875 11.6443 17.6441 11.5673L16.255 11.1043ZM20.747 8.39851L17.9999 4.27796L15.2529 8.39854L17.9999 9.31421L20.747 8.39851Z" fill="url(#paint0_linear_2336_619)" />
            <path d="M9.94843 9.98806C10.49 9.68345 11.1759 9.87551 11.4805 10.417C11.7851 10.9586 11.593 11.6445 11.0515 11.9491L8.29467 13.4998L17.8161 18.8557C17.9303 18.9199 18.0697 18.9199 18.1838 18.8557L27.7053 13.4998L24.9484 11.9491C24.4069 11.6445 24.2148 10.9586 24.5194 10.417C24.8241 9.87551 25.51 9.68345 26.0515 9.98806L30.5515 12.5193C30.9058 12.7186 31.125 13.0934 31.125 13.4998C31.125 13.9063 30.9058 14.2811 30.5515 14.4804L19.2869 20.8167C18.4878 21.2662 17.5121 21.2662 16.713 20.8167L5.44843 14.4804C5.09419 14.2811 4.87497 13.9063 4.87497 13.4998C4.87497 13.0934 5.09419 12.7186 5.44843 12.5193L9.94843 9.98806Z" fill="url(#paint1_linear_2336_619)" />
            <path d="M30.9805 19.6983C30.6759 19.1568 29.9899 18.9647 29.4484 19.2693L19.6546 24.7783C18.6272 25.3562 17.3727 25.3562 16.3453 24.7783L6.5515 19.2693C6.00997 18.9647 5.32404 19.1568 5.01943 19.6983C4.71482 20.2398 4.90688 20.9257 5.44841 21.2304L15.2422 26.7394C16.9546 27.7026 19.0453 27.7026 20.7577 26.7394L30.5515 21.2304C31.093 20.9257 31.2851 20.2398 30.9805 19.6983Z" fill="url(#paint2_linear_2336_619)" />
            <path d="M29.4484 26.0193C29.9899 25.7147 30.6759 25.9068 30.9805 26.4483C31.2851 26.9898 31.093 27.6757 30.5515 27.9804L20.7577 33.4894C19.0453 34.4526 16.9546 34.4526 15.2422 33.4894L5.44841 27.9804C4.90688 27.6757 4.71482 26.9898 5.01943 26.4483C5.32404 25.9068 6.00997 25.7147 6.5515 26.0193L16.3453 31.5283C17.3727 32.1062 18.6272 32.1062 19.6546 31.5283L29.4484 26.0193Z" fill="url(#paint3_linear_2336_619)" />
            <defs>
                <linearGradient id="paint0_linear_2336_619" x1="31.1252" y1="1.5" x2="-0.810273" y2="27.1274" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2336_619" x1="31.1252" y1="1.5" x2="-0.810273" y2="27.1274" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint2_linear_2336_619" x1="31.1252" y1="1.5" x2="-0.810273" y2="27.1274" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint3_linear_2336_619" x1="31.1252" y1="1.5" x2="-0.810273" y2="27.1274" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function Domain() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path d="M9 9C9 9.82845 8.32843 10.5 7.5 10.5C6.67157 10.5 6 9.82845 6 9C6 8.17157 6.67157 7.5 7.5 7.5C8.32843 7.5 9 8.17157 9 9Z" fill="url(#paint0_linear_2336_553)" />
            <path d="M13.5 9C13.5 9.82845 12.8284 10.5 12 10.5C11.1716 10.5 10.5 9.82845 10.5 9C10.5 8.17157 11.1716 7.5 12 7.5C12.8284 7.5 13.5 8.17157 13.5 9Z" fill="url(#paint1_linear_2336_553)" />
            <path d="M16.5 10.5C17.3284 10.5 18 9.82845 18 9C18 8.17157 17.3284 7.5 16.5 7.5C15.6716 7.5 15 8.17157 15 9C15 9.82845 15.6716 10.5 16.5 10.5Z" fill="url(#paint2_linear_2336_553)" />
            <path d="M7.06721 19.8942C6.87073 19.3047 6.23362 18.9862 5.64418 19.1827C5.05475 19.3791 4.73619 20.0163 4.93267 20.6057L6.43267 25.1057C6.58196 25.5536 6.995 25.8606 7.46689 25.8745C7.93879 25.8883 8.36914 25.6061 8.54448 25.1678L8.99994 24.0291L9.4554 25.1678C9.63074 25.6061 10.0611 25.8883 10.533 25.8745C11.0049 25.8606 11.4179 25.5536 11.5672 25.1057L13.0672 20.6057C13.2637 20.0163 12.9451 19.3791 12.3557 19.1827C11.7663 18.9862 11.1291 19.3047 10.9327 19.8942L10.4039 21.4806L10.0445 20.5821C9.87363 20.155 9.45996 19.8749 8.99994 19.8749C8.53992 19.8749 8.12625 20.155 7.9554 20.5821L7.59601 21.4806L7.06721 19.8942Z" fill="url(#paint3_linear_2336_553)" />
            <path d="M14.6442 19.1827C15.2336 18.9862 15.8707 19.3047 16.0672 19.8942L16.596 21.4806L16.9554 20.5821C17.1262 20.155 17.5399 19.8749 17.9999 19.8749C18.46 19.8749 18.8736 20.155 19.0445 20.5821L19.4039 21.4806L19.9327 19.8942C20.1291 19.3047 20.7663 18.9862 21.3557 19.1827C21.9451 19.3791 22.2637 20.0163 22.0672 20.6057L20.5672 25.1057C20.4179 25.5536 20.0049 25.8606 19.533 25.8745C19.0611 25.8883 18.6307 25.6061 18.4554 25.1678L17.9999 24.0291L17.5445 25.1678C17.3691 25.6061 16.9388 25.8883 16.4669 25.8745C15.995 25.8606 15.582 25.5536 15.4327 25.1057L13.9327 20.6057C13.7362 20.0163 14.0547 19.3791 14.6442 19.1827Z" fill="url(#paint4_linear_2336_553)" />
            <path d="M25.0672 19.8942C24.8707 19.3047 24.2336 18.9862 23.6442 19.1827C23.0547 19.3791 22.7362 20.0163 22.9327 20.6057L24.4327 25.1057C24.582 25.5536 24.995 25.8606 25.4669 25.8745C25.9388 25.8883 26.3691 25.6061 26.5445 25.1678L26.9999 24.0291L27.4554 25.1678C27.6307 25.6061 28.0611 25.8883 28.533 25.8745C29.0049 25.8606 29.4179 25.5536 29.5672 25.1057L31.0672 20.6057C31.2637 20.0163 30.9451 19.3791 30.3557 19.1827C29.7663 18.9862 29.1292 19.3047 28.9327 19.8942L28.4039 21.4806L28.0445 20.5821C27.8736 20.155 27.46 19.8749 26.9999 19.8749C26.5399 19.8749 26.1262 20.155 25.9554 20.5821L25.596 21.4806L25.0672 19.8942Z" fill="url(#paint5_linear_2336_553)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.875 9V27C1.875 30.1066 4.3934 32.625 7.5 32.625H28.5C31.6066 32.625 34.125 30.1066 34.125 27V9C34.125 5.8934 31.6066 3.375 28.5 3.375H7.5C4.3934 3.375 1.875 5.8934 1.875 9ZM7.5 5.625C5.63604 5.625 4.125 7.13604 4.125 9V12.375H31.875V9C31.875 7.13604 30.364 5.625 28.5 5.625H7.5ZM31.875 27V14.625H4.125V27C4.125 28.864 5.63604 30.375 7.5 30.375H28.5C30.364 30.375 31.875 28.864 31.875 27Z" fill="url(#paint6_linear_2336_553)" />
            <defs>
                <linearGradient id="paint0_linear_2336_553" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2336_553" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint2_linear_2336_553" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint3_linear_2336_553" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint4_linear_2336_553" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint5_linear_2336_553" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint6_linear_2336_553" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function Manager() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.875 10.5C4.875 6.56497 8.06497 3.375 12 3.375C15.935 3.375 19.125 6.56497 19.125 10.5C19.125 13.5625 17.1928 16.1738 14.4809 17.1812C18.8734 18.2876 22.125 22.2641 22.125 27V28.5C22.125 30.7782 20.2782 32.625 18 32.625H6C3.72183 32.625 1.875 30.7782 1.875 28.5V27C1.875 22.2641 5.12657 18.2876 9.51905 17.1812C6.8072 16.1738 4.875 13.5625 4.875 10.5ZM12 5.625C9.30761 5.625 7.125 7.80761 7.125 10.5C7.125 13.1924 9.30761 15.375 12 15.375C14.6924 15.375 16.875 13.1924 16.875 10.5C16.875 7.80761 14.6924 5.625 12 5.625ZM12 19.125C7.65076 19.125 4.125 22.6508 4.125 27V28.5C4.125 29.5355 4.96447 30.375 6 30.375H18C19.0355 30.375 19.875 29.5355 19.875 28.5V27C19.875 22.6508 16.3492 19.125 12 19.125Z" fill="url(#paint0_linear_2336_643)" />
            <path d="M22.5 7.875C21.8787 7.875 21.375 8.37868 21.375 9C21.375 9.62132 21.8787 10.125 22.5 10.125H31.5C32.1213 10.125 32.625 9.62132 32.625 9C32.625 8.37868 32.1213 7.875 31.5 7.875H22.5Z" fill="url(#paint1_linear_2336_643)" />
            <path d="M22.875 18C22.875 17.3787 23.3787 16.875 24 16.875H31.5C32.1213 16.875 32.625 17.3787 32.625 18C32.625 18.6213 32.1213 19.125 31.5 19.125H24C23.3787 19.125 22.875 18.6213 22.875 18Z" fill="url(#paint2_linear_2336_643)" />
            <path d="M27 25.875C26.3787 25.875 25.875 26.3787 25.875 27C25.875 27.6213 26.3787 28.125 27 28.125H31.5C32.1213 28.125 32.625 27.6213 32.625 27C32.625 26.3787 32.1213 25.875 31.5 25.875H27Z" fill="url(#paint3_linear_2336_643)" />
            <defs>
                <linearGradient id="paint0_linear_2336_643" x1="32.625" y1="3.375" x2="3.41154" y2="34.0866" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2336_643" x1="32.625" y1="3.375" x2="3.41154" y2="34.0866" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint2_linear_2336_643" x1="32.625" y1="3.375" x2="3.41154" y2="34.0866" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint3_linear_2336_643" x1="32.625" y1="3.375" x2="3.41154" y2="34.0866" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function All() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path d="M7.5 10.5C8.32843 10.5 9 9.82845 9 9C9 8.17157 8.32843 7.5 7.5 7.5C6.67157 7.5 6 8.17157 6 9C6 9.82845 6.67157 10.5 7.5 10.5Z" fill="url(#paint0_linear_2336_577)" />
            <path d="M12 10.5C12.8284 10.5 13.5 9.82845 13.5 9C13.5 8.17157 12.8284 7.5 12 7.5C11.1716 7.5 10.5 8.17157 10.5 9C10.5 9.82845 11.1716 10.5 12 10.5Z" fill="url(#paint1_linear_2336_577)" />
            <path d="M18 9C18 9.82845 17.3284 10.5 16.5 10.5C15.6716 10.5 15 9.82845 15 9C15 8.17157 15.6716 7.5 16.5 7.5C17.3284 7.5 18 8.17157 18 9Z" fill="url(#paint2_linear_2336_577)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M31.875 14.625V27C31.875 28.864 30.364 30.375 28.5 30.375C27.8379 30.375 27.203 30.112 26.7348 29.6438L24.841 27.75L26.2955 26.2955C26.5629 26.0281 26.6781 25.6443 26.6021 25.2738C26.526 24.9034 26.269 24.5959 25.9178 24.4555L18.4178 21.4555C18 21.2884 17.5228 21.3863 17.2045 21.7045C16.8863 22.0228 16.7884 22.5 16.9555 22.9178L19.9555 30.4178C20.0959 30.769 20.4034 31.026 20.7738 31.1021C21.1443 31.1781 21.5281 31.0629 21.7955 30.7955L23.25 29.341L25.1438 31.2348C26.034 32.1249 27.2412 32.625 28.5 32.625C31.6066 32.625 34.125 30.1066 34.125 27V9C34.125 5.8934 31.6066 3.375 28.5 3.375H7.5C4.3934 3.375 1.875 5.8934 1.875 9V27C1.875 30.1066 4.3934 32.625 7.5 32.625H18C18.6213 32.625 19.125 32.1213 19.125 31.5C19.125 30.8787 18.6213 30.375 18 30.375H7.5C5.63604 30.375 4.125 28.864 4.125 27V14.625H31.875ZM7.5 5.625C5.63604 5.625 4.125 7.13604 4.125 9V12.375H31.875V9C31.875 7.13604 30.364 5.625 28.5 5.625H7.5ZM20.0195 24.5195L21.4109 27.9981L23.4981 25.9109L20.0195 24.5195Z" fill="url(#paint3_linear_2336_577)" />
            <path d="M19.125 17.25C19.125 16.6287 18.6213 16.125 18 16.125C17.3787 16.125 16.875 16.6287 16.875 17.25V18.75C16.875 19.3713 17.3787 19.875 18 19.875C18.6213 19.875 19.125 19.3713 19.125 18.75V17.25Z" fill="url(#paint4_linear_2336_577)" />
            <path d="M11.625 22.5C11.625 21.8787 12.1287 21.375 12.75 21.375H14.25C14.8713 21.375 15.375 21.8787 15.375 22.5C15.375 23.1213 14.8713 23.625 14.25 23.625H12.75C12.1287 23.625 11.625 23.1213 11.625 22.5Z" fill="url(#paint5_linear_2336_577)" />
            <path d="M15.0832 17.9922C14.6439 17.5529 13.9316 17.5529 13.4922 17.9922C13.0529 18.4316 13.0529 19.1439 13.4922 19.5832L14.5529 20.6439C14.9922 21.0832 15.7045 21.0832 16.1439 20.6439C16.5832 20.2045 16.5832 19.4922 16.1439 19.0529L15.0832 17.9922Z" fill="url(#paint6_linear_2336_577)" />
            <path d="M22.5078 17.9922C22.9471 18.4316 22.9471 19.1439 22.5078 19.5832L21.4471 20.6439C21.0078 21.0832 20.2955 21.0832 19.8561 20.6439C19.4168 20.2045 19.4168 19.4922 19.8561 19.0529L20.9168 17.9922C21.3561 17.5529 22.0684 17.5529 22.5078 17.9922Z" fill="url(#paint7_linear_2336_577)" />
            <path d="M16.1438 25.9472C16.5831 25.5079 16.5831 24.7956 16.1438 24.3562C15.7044 23.9169 14.9921 23.9169 14.5528 24.3562L13.4921 25.4169C13.0528 25.8562 13.0528 26.5685 13.4921 27.0079C13.9315 27.4472 14.6438 27.4472 15.0831 27.0079L16.1438 25.9472Z" fill="url(#paint8_linear_2336_577)" />
            <defs>
                <linearGradient id="paint0_linear_2336_577" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2336_577" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint2_linear_2336_577" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint3_linear_2336_577" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint4_linear_2336_577" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint5_linear_2336_577" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint6_linear_2336_577" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint7_linear_2336_577" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint8_linear_2336_577" x1="34.125" y1="3.375" x2="5.01387" y2="35.4719" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export function TeamWarm() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 3.375C8.06497 3.375 4.875 6.56497 4.875 10.5C4.875 13.5625 6.8072 16.1738 9.51905 17.1812C5.12657 18.2876 1.875 22.2641 1.875 27V28.5C1.875 30.7782 3.72183 32.625 6 32.625H16.5C17.1213 32.625 17.625 32.1213 17.625 31.5C17.625 30.8787 17.1213 30.375 16.5 30.375H6C4.96447 30.375 4.125 29.5355 4.125 28.5V27C4.125 22.6508 7.65076 19.125 12 19.125C14.3322 19.125 16.4265 20.1374 17.87 21.7503C18.2844 22.2132 18.9956 22.2526 19.4586 21.8383C19.9216 21.4239 19.961 20.7127 19.5466 20.2497C18.2229 18.7707 16.4685 17.6821 14.4806 17.1813C17.1927 16.174 19.125 13.5626 19.125 10.5C19.125 6.56497 15.935 3.375 12 3.375ZM7.125 10.5C7.125 7.80761 9.30761 5.625 12 5.625C14.6924 5.625 16.875 7.80761 16.875 10.5C16.875 13.1924 14.6924 15.375 12 15.375C9.30761 15.375 7.125 13.1924 7.125 10.5Z" fill="url(#paint0_linear_2583_601)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M25.5 10.875C22.3934 10.875 19.875 13.3934 19.875 16.5C19.875 18.8481 21.3137 20.8601 23.3577 21.7027C20.4695 22.6121 18.375 25.3114 18.375 28.5C18.375 30.7782 20.2218 32.625 22.5 32.625H28.5C30.7782 32.625 32.625 30.7782 32.625 28.5C32.625 25.3114 30.5305 22.6121 27.6423 21.7027C29.6863 20.8601 31.125 18.8481 31.125 16.5C31.125 13.3934 28.6066 10.875 25.5 10.875ZM22.125 16.5C22.125 14.636 23.636 13.125 25.5 13.125C27.364 13.125 28.875 14.636 28.875 16.5C28.875 18.364 27.364 19.875 25.5 19.875C23.636 19.875 22.125 18.364 22.125 16.5ZM20.625 28.5C20.625 25.8076 22.8076 23.625 25.5 23.625C28.1924 23.625 30.375 25.8076 30.375 28.5C30.375 29.5355 29.5355 30.375 28.5 30.375H22.5C21.4645 30.375 20.625 29.5355 20.625 28.5Z" fill="url(#paint1_linear_2583_601)" />
            <defs>
                <linearGradient id="paint0_linear_2583_601" x1="32.625" y1="3.375" x2="3.41154" y2="34.0866" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
                <linearGradient id="paint1_linear_2583_601" x1="32.625" y1="3.375" x2="3.41154" y2="34.0866" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFB444" />
                    <stop offset="1" stopColor="#FF1D97" />
                </linearGradient>
            </defs>
        </svg>
    );
}
