import React         from 'react';
import { Container } from './styles';

export function RteContent({ htmlContent }) {
    return (
        <Container builder={ process.env.ENV_GEENEE_APP === 'BUILDER' }>
            <div className="ql-container">
                <div
                    className="ql-editor"
                    dangerouslySetInnerHTML={ { __html: htmlContent || '' } }
                />
            </div>
        </Container>
    );
}
