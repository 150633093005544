import { ArSceneProcessorType, GeetrackerSlamEngine, GeetrackerStampEngine } from '../processor';
// import {slam, loadWasm} from '@geenee/geetraker';

type ProcessReturnType = [typeof ImageData, number[]]
export interface SlamProcessor extends ArSceneProcessorType<ProcessReturnType> {
    engine: GeetrackerSlamEngine;
    process(width: any, height: any): ProcessReturnType
}

export class GeetrackerSlamProcessor implements SlamProcessor {
    // engine = slam;
    engine!: GeetrackerSlamEngine;

    process(width:number, height:number) {
        const [ imageData, roto ] = this.engine.process(width, height) as ProcessReturnType;

        return [ imageData, roto ];
    }

    async init(video: HTMLVideoElement, fov: number) {
        await this.engine.loadWasm();
        this.engine.initialize(video, fov);
    }
}
