import { getAttachmentPreSignedUrl } from '@geenee/builder/src/api/AttachmentsAPI';
import fileNameToSafeString          from '@geenee/builder/src/lib/fileNameToSafeString';
import { AttachmentType }            from '@geenee/shared/type/attachment.type';
import pako                          from 'pako';

export const createUploadFileChannel = async (presignedUrl: string, file: File, onProgressChange?: (progress: number) => void) => {
    let resolvePromise; let
        rejectPromise;
    const promise = new Promise((resolve, reject) => {
        resolvePromise = resolve;
        rejectPromise = reject;
    });
    const uploadFile = file;
    const xhr = new XMLHttpRequest();
    const onProgress = (e: ProgressEvent) => {
        if (e.lengthComputable) {
            const progress = e.loaded / e.total;
            onProgressChange && onProgressChange(progress * 100);
        }
    };
    const onFailure = (e: ProgressEvent | null) => {
        console.error(e);
        rejectPromise(new Error('Upload failed'));
    };
    xhr.upload.addEventListener('error', onFailure);
    xhr.upload.addEventListener('abort', onFailure);
    xhr.upload.addEventListener('progress', onProgress);
    xhr.onreadystatechange = () => {
        const { readyState, status } = xhr;
        if (readyState === 4) {
            if (status === 200) {
                xhr.upload.removeEventListener('error', onFailure);
                xhr.upload.removeEventListener('abort', onFailure);
                xhr.upload.removeEventListener('progress', onProgress);
                xhr.onreadystatechange = null;
                xhr.abort();
                resolvePromise();
            } else {
                onFailure(null);
            }
        }
    };
    xhr.open('PUT', presignedUrl, true);

    // gzip compression make sense only for glb assets
    // The file type is empty when uploading glb files
    // if (uploadFile.name.includes('.glb')) {
    //     xhr.setRequestHeader('Content-Encoding', 'gzip');
    //     xhr.setRequestHeader('Content-Type', 'application/x-gzip');
    //     const buffer = await uploadFile.arrayBuffer();
    //     uploadFile = pako.gzip(new Uint8Array(buffer));
    // }

    xhr.send(uploadFile);
    return promise;
};

export const uploadAttachment = async (
    attachment: Partial<AttachmentType> & {file?: File},
    onProgressChange?: (progress: number) => void
) => {
    // @ts-ignore
    const {
        file, parent_id, order, properties, options
    } = attachment;
    const fileName = file?.name ? fileNameToSafeString(file.name) : 'spotify.mp3';
    const fileParams = {
        attachment: {
            filename:  fileName,
            // file_name: fileName,
            parent_id,
            properties,
            file_size: file?.size || 0,
            file_type: fileName.split('.').pop(), // TODO: doesn't make sense without right backend responce
            options
        }
    };
    if (order !== undefined) {
        // @ts-ignore
        fileParams.attachment.order = order;
    }

    try {
    // Get pre-signed URL
        // eslint-disable-next-line no-shadow
        const { attachment, presignedUrl } = await getAttachmentPreSignedUrl(fileParams);
        // TODO: delete old attachment
        if (file) {
            await createUploadFileChannel(presignedUrl, file, onProgressChange);
        }
        return { attachment };
    } catch (e: any) {
        console.log('error on a single Attachment saving ', e);
        return { attachment: null };
    }
};
