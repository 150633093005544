const TOKEN = 'Bearer 5b40f76bddc74a8b24323f829a9f1f98a008312b';

class _UrlShortener {
    cache = new Map<string, string>();

    async apiCall(long_url: string) {
        return fetch('https://api-ssl.bitly.com/v4/shorten', {
            method:  'post',
            headers: {
                authorization:
                TOKEN,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ long_url })
        }).then((res) => res.json());
    }

    async getShortUrl(url: string) {
        const cachedValue = this.cache.get(url);
        if (cachedValue) return cachedValue;
        const { link } = await this.apiCall(url);
        this.cache.set(url, link);
        return link;
    }
}

export const UrlShortener = new _UrlShortener();
