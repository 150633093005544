import axios     from 'axios';
import envConfig from '../lib/envConfig';

const { NFT_API_URL } = envConfig;

export const createWalletApi = (
    password: string,
    password_confirmation: string,
    token: string
) => axios.post(`${ NFT_API_URL }/api/v1/account/wallet`, {
    password,
    password_confirmation
}, { headers: { authorization: `Bearer ${ token }` } }).then((res) => res.data.data);

export const deleteWalletApi = (
    id: string,
    token: string
) => axios.delete(`${ NFT_API_URL }/api/v1/account/wallet/${ id }`, { headers: { authorization: `Bearer ${ token }` } });

export const getWalletsApi = (token: string) => axios.get(
    `${ NFT_API_URL }/api/v1/account/wallets`,
    { headers: { authorization: `Bearer ${ token }` } }
).then((res) => res.data.data);

export const confirmWalletApi = (id: string, token: string) => axios.patch(
    `${ NFT_API_URL }/api/v1/account/wallet/${ id }`,
    {},
    { headers: { authorization: `Bearer ${ token }` } }
).then((res) => res.data.data);

export const mintProjectApi = (projectData: any, token: string) => axios.post(
    `${ NFT_API_URL }/api/v1/mint`,
    projectData,
    { headers: { authorization: `Bearer ${ token }` } }
).then((res) => res.data.data);

export const getNftsListApi = (token: string) => axios.get(
    `${ NFT_API_URL }/api/v1/mint`,
    { headers: { authorization: `Bearer ${ token }` } }
).then((res) => res.data.data);

export const viewWalletApi = (id: string, token: string) => axios.get(
    `${ NFT_API_URL }/api/v1/account/wallet/${ id }`,
    { headers: { authorization: `Bearer ${ token }` } }
).then((res) => res.data.data);

export const withdrawApi = (
    from_wallet: string,
    to_wallet: string,
    password: string,
    amount: number,
    token: string
) => axios.post(
    `${ NFT_API_URL }/api/v1/account/wallet/${ from_wallet }`,
    { to_wallet, password, amount },
    { headers: { authorization: `Bearer ${ token }` } }
).then((res) => res.data.data);

// address: "0x7F410EE49AB73A0B18CD33BC9283EAC59B9B24C4"
// eslint-disable-next-line max-len
// mnemonic: "liar skull gap section mouse stand cruel letter auto sing ankle top cry urge beauty october resist swamp again horror cruise ticket globe apart"
// private_key: "80D9597D61590BA90D24040F992824F27353DEC4ECC8B77B6412B6D34BC358D0"
// status: "inactive"
