import React                      from 'react';
import { LoadingSpinnerCentered } from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { QrImageLoaderWrapper }   from '@geenee/builder/src/components/ProjectsLinkDropdown/styles';

interface Props {
    imageCode: string
    loading?: boolean
}

export function QRCodeImage({ imageCode, loading }: Props) {
    return loading
        ? (
            <QrImageLoaderWrapper>
                <LoadingSpinnerCentered />
            </QrImageLoaderWrapper>
        )
        : <img id='qr-code-svg' width="180px" height="180px" src={ `data:image/svg+xml;base64,${ imageCode }` } alt="" />;
}
