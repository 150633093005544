import React                                                 from 'react';
import { Description, InputTableItem, RangeSlider, Wrapper } from '@geenee/ui';
import { observer }                                          from 'mobx-react';

type RangeSliderComponentProps = {
    value: number,
    description: string,
    onChange: (value: string) => void,
}

export const RangeSliderComponent = observer(({ value, description, onChange } : RangeSliderComponentProps) => (
    <InputTableItem marginOff weight="medium" label={ description } size="sm" viewType="secondary">
        <RangeSlider
            values={ [ value * 100 ] }
            max={ 100 }
            toFixed={ 0 }
            tooltipAfter="%"
            onChange={ (value) => onChange((value[ 0 ] / 100).toFixed(2)) }
        />
    </InputTableItem>
));
