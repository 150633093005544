import React, { useMemo }    from "react";
import { observer }          from "mobx-react";
import { LibraryModalTitle } from "~/components/LibraryModalTemplates/components/LibraryModalTitle";
import { useBuilderInject }  from "~/core/hook/use-builder-inject";

export const LibraryTitle = observer(() => {
    const { BuilderState } = useBuilderInject();
    const isSnippetMode = BuilderState.teamLibraryMode === 'snippet';

    const textCopies = useMemo(() => {
        if (isSnippetMode) {
            return {
                title:       'Snippet Library',
                description: 'Add new snippets or use from your previously used files',
                icon:        'customCode'
            };
        }
        return {
            title:       'Team Library',
            description: 'Add new assets or use from your previously used files',
            icon:        'library'
        };
    }, [ isSnippetMode ]);
    return (
        <LibraryModalTitle
            title={ textCopies.title }
            description={ textCopies.description }
            icon={ textCopies.icon }
            iconColor="gradient-warm-1"
        />
    );
});
