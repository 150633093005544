import * as React                           from "react";
import type { IExplorerExtensibilityGroup } from "@babylonjs/core/Debug/debugLayer";
import type { Texture }                     from "@babylonjs/core/Materials/Textures/texture";
import { ExtensionsComponent }              from "../extensionsComponent";

interface ITextureTreeItemComponentProps {
    texture: Texture;
    extensibilityGroups?: IExplorerExtensibilityGroup[];
    onClick: () => void;
}

export const TextureTreeItemComponent = (props: ITextureTreeItemComponentProps) => (
    <div className="textureTools">
        { /* <TreeItemLabelComponent label={props.texture.name} onClick={() => props.onClick()} icon={faImage} color="mediumpurple" /> */ }
        <ExtensionsComponent target={ props.texture } extensibilityGroups={ props.extensibilityGroups } />
    </div>
);
