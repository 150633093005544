import * as React                           from "react";
import type { IExplorerExtensibilityGroup } from "@babylonjs/core/Debug/debugLayer";
import type { Control }                     from "@babylonjs/gui/2D/controls/control";
import { faEye, faEyeSlash, faHighlighter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon }                  from "@fortawesome/react-fontawesome";
import { Icon }                             from "@geenee/ui";
import { ExtensionsComponent }              from "../../extensionsComponent";

interface IControlTreeItemComponentProps {
    control: Control;
    extensibilityGroups?: IExplorerExtensibilityGroup[];
    onClick: () => void;
}

export class ControlTreeItemComponent extends React.Component<IControlTreeItemComponentProps, { isActive: boolean; isVisible: boolean }> {
    constructor(props: IControlTreeItemComponentProps) {
        super(props);

        const { control } = this.props;
        this.state = { isActive: control.isHighlighted, isVisible: control.isVisible };
    }

    highlight() {
        const { control } = this.props;
        control.isHighlighted = !control.isHighlighted;

        this.setState({ isActive: control.isHighlighted });
    }

    switchVisibility(): void {
        const newState = !this.state.isVisible;
        this.setState({ isVisible: newState });
        this.props.control.isVisible = newState;
    }

    render() {
        const { control } = this.props;
        // @ts-ignore
        const name = `${ control.name || "No name" } [${ control.getClassName() }]`;
        const isActiveElement = this.state.isActive ? <FontAwesomeIcon icon={ faHighlighter } /> : <FontAwesomeIcon icon={ faHighlighter } className="isNotActive" />;
        const visibilityElement = this.state.isVisible ? <Icon name="eyeOn" /> : <Icon name="eyeOff" />;

        return (
            <div className="controlTools">
                { /* <TreeItemLabelComponent label={name} onClick={() => this.props.onClick()} icon={faObjectGroup} color="greenyellow" /> */ }
                <div className="highlight icon" onClick={ () => this.highlight() } title="Highlight this control">
                    { isActiveElement }
                </div>
                <div className="visibility icon" onClick={ () => this.switchVisibility() } title="Show/Hide control">
                    { visibilityElement }
                </div>
                <ExtensionsComponent target={ control } extensibilityGroups={ this.props.extensibilityGroups } />
            </div>
        );
    }
}
