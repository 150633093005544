import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 26px 30px;
  background: rgba(202, 202, 202, 0.19);
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 10px;
`;

export const Link = styled.a`
    font-size: 14px;
    color: #000;
    text-decoration: underline;
    &:hover,
    &:visited {
        color: #000;
    }
`;
