/* eslint-disable max-len */
import React from 'react';

export function NavBurger({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 1H1C0.4 1 0 1.4 0 2C0 2.6 0.4 3 1 3H15C15.6 3 16 2.6 16 2C16 1.4 15.6 1 15 1ZM15 7H1C0.4 7 0 7.4 0 8C0 8.6 0.4 9 1 9H15C15.6 9 16 8.6 16 8C16 7.4 15.6 7 15 7ZM1 13H15C15.6 13 16 13.4 16 14C16 14.6 15.6 15 15 15H1C0.4 15 0 14.6 0 14C0 13.4 0.4 13 1 13Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
export function NavBurger2({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 3C1 2.44772 1.44772 2 2 2H14C14.5523 2 15 2.44772 15 3C15 3.55228 14.5523 4 14 4H2C1.44772 4 1 3.55228 1 3ZM1 8C1 7.44772 1.44772 7 2 7H14C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9H2C1.44772 9 1 8.55228 1 8ZM2 12C1.44772 12 1 12.4477 1 13C1 13.5523 1.44772 14 2 14H14C14.5523 14 15 13.5523 15 13C15 12.4477 14.5523 12 14 12H2Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}

export function NavDots({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.72144 8C1.72144 7.17157 2.40793 6.5 3.23636 6.5C4.06479 6.5 4.75129 7.17157 4.75129 8C4.75129 8.82843 4.06479 9.5 3.23636 9.5C2.40793 9.5 1.72144 8.82843 1.72144 8ZM7.27616 8C7.27616 7.17157 7.96266 6.5 8.79109 6.5C9.61951 6.5 10.306 7.17157 10.306 8C10.306 8.82843 9.61951 9.5 8.79109 9.5C7.96266 9.5 7.27616 8.82843 7.27616 8ZM14.3458 6.5C13.5174 6.5 12.8309 7.17157 12.8309 8C12.8309 8.82843 13.5174 9.5 14.3458 9.5C15.1742 9.5 15.8607 8.82843 15.8607 8C15.8607 7.17157 15.1742 6.5 14.3458 6.5Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
export function NavDotsVertical({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12C9 12.5523 8.55228 13 8 13ZM8 9C7.44772 9 7 8.55228 7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8C9 8.55228 8.55228 9 8 9ZM7 4C7 4.55229 7.44772 5 8 5C8.55228 5 9 4.55229 9 4C9 3.44771 8.55228 3 8 3C7.44772 3 7 3.44771 7 4Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}
export function Home({ children }: {children?: React.ReactNode}) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className="fill"
                d="M12.2857 14C12.7143 14 13 13.7005 13 13.2512V6.5117C13 6.28705 12.9286 6.0624 12.7143 5.91264L8.42857 2.16849C8.14286 1.94384 7.78571 1.94384 7.5 2.16849L3.21429 5.91264C3.07143 6.0624 3 6.28705 3 6.5117V13.2512C3 13.7005 3.28571 14 3.71429 14H6.57143V10.2559H9.42857V14H12.2857Z"
                fill="#303036"
            />
            { children }
        </svg>
    );
}

export function More({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                className="fill"
                d="M6.00001 8C6.00001 8.5523 5.5523 9 5.00001 9C4.44773 9 4.00001 8.5523 4.00001 8C4.00001 7.44771 4.44773 7 5.00001 7C5.5523 7 6.00001 7.44771 6.00001 8Z"
                fill="#615B6F"
            />
            <path
                className="fill"
                d="M9.00001 8C9.00001 8.5523 8.5523 9 8.00001 9C7.44773 9 7.00001 8.5523 7.00001 8C7.00001 7.44771 7.44773 7 8.00001 7C8.5523 7 9.00001 7.44771 9.00001 8Z"
                fill="#615B6F"
            />
            <path
                className="fill"
                d="M12 8C12 8.5523 11.5523 9 11 9C10.4477 9 10 8.5523 10 8C10 7.44771 10.4477 7 11 7C11.5523 7 12 7.44771 12 8Z"
                fill="#615B6F"
            />
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00001 1.33333C4.31811 1.33333 1.33334 4.3181 1.33334 8C1.33334 11.6819 4.31811 14.6667 8.00001 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8C14.6667 4.3181 11.6819 1.33333 8.00001 1.33333ZM2.66668 8C2.66668 5.05448 5.05449 2.66667 8.00001 2.66667C10.9455 2.66667 13.3333 5.05448 13.3333 8C13.3333 10.9455 10.9455 13.3333 8.00001 13.3333C5.05449 13.3333 2.66668 10.9455 2.66668 8Z"
                fill="#615B6F"
            />
            { children }
        </svg>
    );
}

export function Cursor({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                className="fill"
                d="M3.33325 12.6667C3.33325 13.0349 3.63173 13.3334 3.99992 13.3334C4.36811 13.3334 4.66659 13.0349 4.66659 12.6667L4.66659 3.33341C4.66659 2.96523 4.36811 2.66675 3.99992 2.66675C3.63173 2.66675 3.33325 2.96523 3.33325 3.33341V12.6667Z"
                fill="#615B6F"
            />
            <path
                className="fill"
                d="M7.99992 13.3334C7.63173 13.3334 7.33325 13.0349 7.33325 12.6667L7.33325 3.33341C7.33325 2.96523 7.63173 2.66675 7.99992 2.66675C8.36811 2.66675 8.66659 2.96523 8.66659 3.33341L8.66659 12.6667C8.66659 13.0349 8.36811 13.3334 7.99992 13.3334Z"
                fill="#615B6F"
            />
            <path
                className="fill"
                d="M11.9999 13.3334C11.6317 13.3334 11.3333 13.0349 11.3333 12.6667L11.3333 3.33341C11.3333 2.96523 11.6317 2.66675 11.9999 2.66675C12.3681 2.66675 12.6666 2.96523 12.6666 3.33341V12.6667C12.6666 13.0349 12.3681 13.3334 11.9999 13.3334Z"
                fill="#615B6F"
            />
            { children }
        </svg>
    );
}
