import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({ width = '35', height = '26', onClick, id }: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 35 26"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M34.6737 17.3339C34.6737 12.5477 30.7938 8.66774 26.0076 8.66774H25.2562C23.1725 2.75703 17.1023 -0.884335 10.8172 0.186151C4.15654 1.3206 -0.524541 7.36851 0.0472173 14.1008C0.618975 20.8332 6.25293 26.005 13.0095 26L26.0076 26C30.7938 26 34.6737 22.12 34.6737 17.3339ZM24.1876 11.5568H26.0075C29.1983 11.5568 31.7849 14.1434 31.7849 17.3342C31.7849 20.525 29.1983 23.1116 26.0075 23.1116H13.0083C7.7521 23.1156 3.37014 19.093 2.92544 13.8567C2.48073 8.62048 6.12158 3.91655 11.3021 3.0342C16.4826 2.15185 21.4753 5.3853 22.7891 10.4735C22.9537 11.1112 23.529 11.5568 24.1876 11.5568Z"
                fill="black"
            />
            <mask
                id="mask0"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="35"
                height="26"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    // eslint-disable-next-line max-len
                    d="M34.6737 17.3339C34.6737 12.5477 30.7938 8.66774 26.0076 8.66774H25.2562C23.1725 2.75703 17.1023 -0.884335 10.8172 0.186151C4.15654 1.3206 -0.524541 7.36851 0.0472173 14.1008C0.618975 20.8332 6.25293 26.005 13.0095 26L26.0076 26C30.7938 26 34.6737 22.12 34.6737 17.3339ZM24.1876 11.5568H26.0075C29.1983 11.5568 31.7849 14.1434 31.7849 17.3342C31.7849 20.525 29.1983 23.1116 26.0075 23.1116H13.0083C7.7521 23.1156 3.37014 19.093 2.92544 13.8567C2.48073 8.62048 6.12158 3.91655 11.3021 3.0342C16.4826 2.15185 21.4753 5.3853 22.7891 10.4735C22.9537 11.1112 23.529 11.5568 24.1876 11.5568Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0)">
                <rect y="-4.33398" width="34.6645" height="34.6645" fill="black" />
            </g>
        </svg>
    );
}
