
import React, { FC, useMemo } from 'react';
import {
    NavigationListAtomComponent,
    NavigationListAtomComponentPropsType
} from '@geenee/geeclient-kit/src/lib/component/atom/navigation-list/navigation-list.atom.component';
import { NavigationMoleculeOptType }     from '@geenee/geeclient-kit/src/lib/default-options/molecule/Navigation';
import { withMoleculeContext }           from '@geenee/geeclient-kit/src/lib/hoc/withMoleculeContext';
import { cnKitMolecule }                 from '@geenee/geeclient-kit/src/lib/util/bem';
import { compose }                       from '@geenee/geeclient-kit/src/lib/util/compose';
import { observer }                      from 'mobx-react';
import { CombinationMoleculeSchemaType } from '../../type/section-molecule';
import { CallbackFunctionVariadic }      from '../../type/type';
import '../../style/ui-kit/default/molecule/Navigation.scss';

export type NavigationMoleculePropsType = {
  moleculeContext: CombinationMoleculeSchemaType<
    NavigationMoleculeOptType,
    NavigationListAtomComponentPropsType
    >;
  onItemClick: CallbackFunctionVariadic;
};

const className = cnKitMolecule('molecule-navigation');
/**
 * This is a TypeScript React component for a navigation molecule, which includes a navigation list
 * atom component and various style options.
 * @property moleculeContext - `moleculeContext` is an object that contains the options and data for
 * the NavigationMolecule component. It is passed down as a prop to the component and includes
 * properties such as `options`, `id`, and `onItemClick`. The `options` property is an object that
 * contains various styling and
 * @property {CallbackFunctionVariadic} onItemClick - `onItemClick` is a callback function that is
 * passed as a prop to the `NavigationMoleculeComponent`. It is triggered when a navigation item is
 * clicked and takes in a variable number of arguments.
 */



// eslint-disable-next-line react/function-component-definition
export const NavigationMoleculeView: FC<NavigationMoleculePropsType> = ({ moleculeContext }) => {
    const styles = useMemo(() => ({
        composition_type:            moleculeContext.options.composition_type,
        molecule_blur_radius_custom: moleculeContext.options.molecule_blur_radius_custom,
        molecule_blur_radius:        moleculeContext.options.molecule_blur_radius,
        style_type:                  moleculeContext.options.style_type,
        style_color_text:            moleculeContext.options.style_color_text,
        style_color_text_alpha:      moleculeContext.options.style_color_text_alpha,
        style_color_text_type:       moleculeContext.options.style_color_text_type,
        style_color_text_variation:
    moleculeContext.options.style_color_text_variation,
        style_color_text_custom:      moleculeContext.options.style_color_text_custom,
        style_color_bg:               moleculeContext.options.style_color_bg,
        style_color_bg_alpha:         moleculeContext.options.style_color_bg_alpha,
        style_color_bg_type:          moleculeContext.options.style_color_bg_type,
        style_color_bg_variation:     moleculeContext.options.style_color_bg_variation,
        style_color_bg_custom:        moleculeContext.options.style_color_bg_custom,
        style_color_border:           moleculeContext.options.style_color_border,
        style_color_border_alpha:     moleculeContext.options.style_color_border_alpha,
        style_color_border_type:      moleculeContext.options.style_color_border_type,
        style_color_border_variation:
    moleculeContext.options.style_color_border_variation,
        style_color_border_custom:
    moleculeContext.options.style_color_border_custom,
        style_corner_radius_custom:
    moleculeContext.options.style_corner_radius_custom,
        style_corner_radius:
    moleculeContext.options.style_corner_radius,
        style_size: moleculeContext.options.style_size
    }), [ moleculeContext.options ]);

    // eslint-disable-next-line max-len
    const getBorderRadius = () => (styles.style_corner_radius === 'custom' && styles.style_corner_radius_custom ? { '--molecule-navigation__border-radius': `${ styles.style_corner_radius_custom }px` } : {});
    // eslint-disable-next-line max-len
    const getBlurRadius = () => (styles.molecule_blur_radius === 'custom' && styles.molecule_blur_radius_custom ? { '--blur-radius': `${ styles.molecule_blur_radius_custom }px` } : {});

    return (
        <div className="main-root" data-font-kit="euclid-circular">
            <div
                className={ className('', {
                    type:                              styles.style_type,
                    [ `type-${ styles.style_type }` ]: moleculeContext.options.molecule_blur_radius,
                    composition:                       styles.composition_type
                }) }
                data-color-util={
                    styles.style_type === 'line' ? styles.style_color_border : undefined
                }
                data-color-util-variation={
                    styles.style_type === 'line' ? 'harmonic' : undefined
                }
                data-color-util-alpha={ styles.style_color_border_alpha }
                data-color-text={ styles.style_color_text }
                data-color-text-alpha={ styles.style_color_text_alpha }
                data-color-text-variation={ styles.style_color_text_variation }
                data-color-text-type={ styles.style_color_text_type }
                data-color-bg={
                    styles.style_type === 'solid' || styles.style_type === 'blur' ? styles.style_color_bg : undefined
                }
                data-color-bg-alpha={ styles.style_color_bg_alpha }
                data-color-bg-variation={
                    styles.style_type === 'solid' || styles.style_type === 'blur'
                        ? styles.style_color_bg_variation
                        : undefined
                }
                data-color-bg-type={
                    styles.style_type === 'solid' || styles.style_type === 'blur' ? styles.style_color_bg_type : undefined
                }
                data-molecule-id={ moleculeContext.id }
                style={ { ...getBlurRadius(), ...getBorderRadius() } as React.CSSProperties }
            >
                <NavigationListAtomComponent
                    size={ styles.style_size }
                    activeItemId={ moleculeContext.options.activeId }
                    onItemClick={ moleculeContext.options.onItemClick }
                    moleculeContext={ moleculeContext }
                />
            </div>
        </div>
    );
};

const enhance = compose(withMoleculeContext);

export const NavigationMoleculeComponent = enhance(
    observer(NavigationMoleculeView)
);

export default NavigationMoleculeComponent;
