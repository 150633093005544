// @ts-ignore
import bodyModel                        from "@geenee/builder/src/asset/models/body_prefab.glb";
// @ts-ignore
import headModel                        from "@geenee/builder/src/asset/models/head_prefab.glb";
// @ts-ignore
import twinModel                        from "@geenee/builder/src/asset/models/twin_prefab.glb";
import { container }                    from "@geenee/builder/src/magellan/di/di";
import { SectionModel }                 from "@geenee/builder/src/magellan/model/section.model";
import { BabylonRenderer }              from "@geenee/geespector/renderer/babylonjs.renderer";
import { bypassHistory }                from "@geenee/shared/src/commander/decorators";
import { AbstractCommand, CommandType } from "@geenee/shared/src/commander/types";
import {
    TYPE_BODY_TRACKING_OVERLAY, TYPE_BODY_TRACKING_TWIN,
    TYPE_HEAD_TRACKING
} from "@geenee/shared/src/util/constants";

@bypassHistory
export class PrefabCommand extends AbstractCommand<CommandType> {
    receiver: BabylonRenderer;
    prefab = '';
    type?: string;

    constructor(receiver: BabylonRenderer, type: string) {
        super();
        this.receiver = receiver;

        this.type = type;
        if (type === TYPE_HEAD_TRACKING) {
            this.prefab = headModel;
        }
        if (type === TYPE_BODY_TRACKING_OVERLAY) {
            this.prefab = bodyModel;
        }
        if (type === TYPE_BODY_TRACKING_TWIN) {
            this.prefab = twinModel;
        }
    }

    execute = () => {
        const AppState = container.get('<AppState>');
        const { activeSection } = AppState as {activeSection: SectionModel};
        const { activeMolecule } = activeSection || {} as SectionModel;
        activeMolecule.setLoading(true);
        this.receiver.addPrefab(this.prefab).then(() => {
            activeMolecule.setLoading(false);
        });
    };

    revert = () => {
        this.receiver.removePrefab();
        this.receiver.removeReferenceHead();
        this.receiver.removeReferenceBody();
    };
}
