import React, { useEffect, useState }   from 'react';
import { AssetsSourcePicker }           from "@geenee/builder/src/components/AssetsSourcePicker";
import { SceneCodeModulesTree }         from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/scene-code-modules-tree.component";
import { TrackerImageSectionComponent } from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/tracker-image-section.component";
import { TOOLTIP_DELAY }                from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/transformation-modes-switcher";
import { ScenePanelComponent }          from "@geenee/geespector/src/components/scenePanel/scenePanelComponent";
import { AtomModel, useInject }         from '@geenee/shared/index';
import { Button, Tabs, Wrapper }        from '@geenee/ui';
import { TabsItem }                     from "@geenee/ui/src/lib/component/tabs/tabs-item.component";
import { TabsPanel }                    from "@geenee/ui/src/lib/component/tabs/tabs-panel.component";
import { TabsPanelContainer }           from "@geenee/ui/src/lib/component/tabs/tabs-panel-container.component";
import { observer }                     from 'mobx-react';
import { LayersChevronPanel }           from './LayersChevronPanel';
import { ObjectsList }                  from './objects-list.component';
import { ResizeWrapper }                from "./ResizeWrapper";

export type AnimationClip = {
  duration: number
  name: string
  uuid: string
}

export interface AudioData {
  url: string
  artists: string
  name: string
}

export const ContentTabTreejs = observer(() => {
    const { SceneState } = useInject();
    const { activeMolecule, selectedAtomId, disabled, activeTab } = SceneState;
    const atomModels = activeMolecule?.sceneActorAtoms || [] as AtomModel[];

    const [ scenePanelCollapsed, setScenePanelCollapsed ] = useState(false);

    const noAllAnimations = activeMolecule.sceneActorAtoms.find((item) => item.firstObject?.animations?.length);

    useEffect(() => {
        if (atomModels.length) {
            SceneState.onTabChange('navigator');
        }
    }, [ ]);

    const onTabChange = (e: any, tabId: 'navigator' | 'addAsset') => {
        if (tabId === 'addAsset' && activeTab === 'addAsset') {
            SceneState.onTabChange('navigator');
        } else {
            SceneState.onTabChange(tabId);
        }
    };

    return (
        <Wrapper
            style={ {
                left:     '0px',
                position: 'absolute',
                width:    'fit-content',
                height:   scenePanelCollapsed ? 'fit-content' : '100%'
            } }
            maxWidth="600px"
        >
            <Wrapper style={ { zIndex: 1 } }>
                <Tabs
                    activeTab={ activeTab }
                    orientation="horizontal"
                    tabsContainerStyle={ { marginBottom: 8 } }
                >
                    {
                        [
                            <TabsItem
                                key="addAsset"
                                onChange={ onTabChange }
                                tabId="addAsset"
                                tooltip={ { message: "Add new item", position: 'right', delay: TOOLTIP_DELAY } }
                                style={ { marginRight: 8 } }
                            >
                                <Wrapper
                                    frame="solid-opacity-white"
                                    shadow="md"
                                    blur
                                    radius="xxl"
                                    padding="xxs"
                                >
                                    <Button
                                        viewType="transparent"
                                        align="center"
                                        size="md"
                                        radius="lg"
                                        iconSize={ 17.5 }
                                        icon="add"
                                        id="addAsset"
                                        iconColor={ (activeTab === 'addAsset') ? 'white' : 'shade-1' }
                                        active={ activeTab === 'addAsset' }
                                    />
                                </Wrapper>
                            </TabsItem>,
                            <TabsItem
                                margin="xs"
                                key="navigator"
                                onChange={ onTabChange }
                                tabId="navigator"
                                tooltip={ { message: "Navigator", minWidth: '60px', delay: TOOLTIP_DELAY } }
                            >
                                <Wrapper
                                    frame="solid-opacity-white"
                                    shadow="md"
                                    blur
                                    radius="xxl"
                                    padding="xxs"
                                >
                                    <Button
                                        viewType="transparent"
                                        align="center"
                                        size="md"
                                        radius="lg"
                                        iconSize={ 24 }
                                        icon="layers"
                                        iconColor={ (activeTab === 'navigator') ? 'white' : 'shade-1' }
                                        active={ activeTab === 'navigator' }

                                    />
                                </Wrapper>
                            </TabsItem>
                        ]
                    }
                </Tabs>
            </Wrapper>
            <ResizeWrapper enable={ { right: true } }>
                <TabsPanelContainer activeTabId={ activeTab } wrapperProps={ { style: { height: 'calc(100% - 80px)' } } }>
                    <TabsPanel tabPanelId="addAsset">
                        <ScenePanelComponent
                            clickMode="none"
                            title="Add new item"
                            hideCollapseIcon
                            changeTitleBackgroundOnHover={ false }
                        >
                            <AssetsSourcePicker />
                        </ScenePanelComponent>
                    </TabsPanel>
                    <TabsPanel tabPanelId="navigator">
                        <ScenePanelComponent
                            title="Navigator"
                            clickMode="icon"
                            onCollapseChange={ setScenePanelCollapsed }
                            changeTitleBackgroundOnHover={ false }
                            stickyToHeaderComponent={ (
                                <TrackerImageSectionComponent />
                            ) }
                        >
                            <Wrapper>
                                <LayersChevronPanel
                                    title={ activeMolecule?.options?.molecule_menu_title || 'Scene' }
                                    initialExpanded
                                    noAnimations={ !noAllAnimations }
                                    onTitleClick={ () => {
                                        if (selectedAtomId) {
                                            SceneState.setSelectedAtomId(null);
                                        } else {
                                            SceneState.setSelectedAtomId(atomModels[ 0 ]?.id);
                                        }
                                    } }
                                    active={ selectedAtomId === null }
                                    currentModel={ activeMolecule }
                                    disabled={ disabled }
                                    rootNode
                                >
                                    <ObjectsList />
                                </LayersChevronPanel>
                                <SceneCodeModulesTree />
                            </Wrapper>
                        </ScenePanelComponent>
                    </TabsPanel>
                </TabsPanelContainer>
            </ResizeWrapper>
        </Wrapper>
    );
});
