import React                       from 'react';
import { useParams }               from 'react-router';
import { useHistory }              from "@geenee/common";
import { RouteParams, useInject  } from '@geenee/shared';
import { observer }                from 'mobx-react';
import { ClickOverlay }            from '@geenee/builder/src/styles';

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const ExperienceWrapper = observer(({ children }: Props) => {
    const { AppState } = useInject();
    const { options: { sectionId } } = AppState;
    const {
        projectId,
        experienceId
    } = useParams<RouteParams>();
    const history = useHistory();
    return (
        <ClickOverlay
            onClick={ () => history.push(
                `/${ projectId }/${ experienceId }/${ sectionId }`
            ) }
        >
            { children }
        </ClickOverlay>
    );
});
