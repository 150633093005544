import { HttpClient }      from '@geenee/shared';
import { getMockProjects } from '@geenee/builder/src/api/ProjectsAPI';
import { UserModel }       from '@geenee/builder/src/core/model/user.model';
import { accountState }    from '@geenee/builder/src/core/state/account.state';
import { stripeState }     from '@geenee/builder/src/core/state/stripe.state';
import { teamState }       from '@geenee/builder/src/core/state/team.state';
import envConfig           from '@geenee/builder/src/lib/envConfig';
import { container }       from '@geenee/builder/src/magellan/di/di';

const { API_URL } = envConfig;

export class InitializationService {
    programUrl = '/program';

    httpClient: HttpClient = container.get('<HttpClient>');

    async loadUserDataComposerApi() {
        const { data } = await this.httpClient.get(`${ API_URL }/api/v0/account`);
        return data.data;
    }

    async projectsFetch() {
        if (process.env.ENV_USE_MOCK_DATA) {
            return getMockProjects();
        }
        const { data } = await this.httpClient.get(`${ API_URL }/api/v0/programs`);
        return data.data;
    }

    // returns null if subscription is not active
    async initProfile() {
        const {
            profile,
            company
        }   = await this.loadUserDataComposer();
        return { profile, company };
    }

    loadUserContentData = async () => Promise.all([
        this.projectsFetch()
    ]);

    async loadProfileInfo(account, subscription, user) {
        Object.keys(account).forEach((key) => {
            if (account[ key ] === 'null') {
                account[ key ] = '';
            }
        });

        if (!subscription) {
            stripeState.fetchPlans();
            stripeState.fetchPaymentMethods();
            const userModel = new UserModel();
            userModel.init(user);
            accountState.updateState(account);
            stripeState.updateState({ daysLeft: -1 });

            return { daysLeft: -1, profile: userModel, company: account };
        }
        // @ts-ignore
        const diffTime = new Date(subscription.expiry_date) - new Date();
        const diffDays = subscription.status !== 'active' ? -1 : Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        stripeState.updateState({ ...subscription, daysLeft: diffDays });

        stripeState.fetchPlans();
        stripeState.fetchPaymentMethods();
        if (user.role === 'owner') {
            teamState.fetchTeamMembers(user.email);
            teamState.fetchInvites();
        }
        const userModel = new UserModel();
        userModel.init(user);
        accountState.updateState(account);

        return { profile: userModel, company: account };
    }

    async loadUserDataComposer() {
        const { account, subscription, user } = await this.loadUserDataComposerApi();
        return this.loadProfileInfo(account, subscription, user);
    }

    checkSubAndFetchData = async () => {
        let resolve;
        let reject;
        const promise = new Promise((res, rej) => {
            resolve = res;
            reject  = rej;
        });
        const { account, subscription, user } = await this.fetchSubStatus(0, promise, resolve, reject);
        return this.loadProfileInfo(account, subscription, user);
    };

    fetchSubStatus = async (i = 0, promise: any, resolve: any, reject: any) => {
        try {
            const { account, subscription, user } = await this.loadUserDataComposerApi();
            if (subscription.status === 'active') {
                resolve({ account, subscription, user });
            } else if (i < 3) {
                setTimeout(() => {
                    this.fetchSubStatus(i + 1, promise, resolve, reject);
                }, 5000);
            } else {
                reject('Payment is not accepted yet');
            }
        } catch (e: any) {
            reject(e);
        }
        return promise;
    };
}
