import { signUpWithInviteApi }                                                 from '@geenee/builder/src/api/AuthAPI';
import { deleteInviteApi, deleteMember, getInvitesApi, getTeam, inviteMember } from '@geenee/builder/src/api/TeamAPI';
import { InviteModel }                                                         from '@geenee/builder/src/core/model/invite.model';
import { UserModel }                                                           from '@geenee/builder/src/core/model/user.model';
import { injectable }                                                          from 'inversify';
import { action, makeAutoObservable, observable, ObservableMap }               from 'mobx';

@injectable()
export class TeamState {
  @observable userStore: ObservableMap<string, UserModel> = new ObservableMap<string, UserModel>();
  @observable invitesLoaded = false;
  @observable invites: InviteModel[] = [];
  @observable usersLoaded = false;
  constructor() {
      makeAutoObservable(this);
  }

  @action
  getMemberByEmail(email: string) {
      return Array.from(this.userStore.values()).find((item) => item.email === email);
  }
    @action
        updateTeamMember = async (data: Partial<UserModel>) => {
            const member = this.userStore.get(data.email || '');
            if (member) {
                member.updateTeamMember(data);
            }
        };
  @action
      fetchTeamMembers = async (currentUserEmail = '') => {
          this.userStore.clear();
          const teamMembers = await getTeam();
          teamMembers.forEach((member) => {
              if (member.email !== currentUserEmail) {
                  const model = new UserModel();
                  model.init(member);
                  this.userStore.set(member.email, model);
              }
          });
          this.usersLoaded = true;
      };

  @action inviteUser = async (userData: Partial<UserModel>) => {
      const invite = await inviteMember(userData);
      const model = new InviteModel();
      model.init(invite);
      this.invites.push(invite);
  };

  @action acceptInvite = async (password: string, code: string) => {
      await signUpWithInviteApi(password, code);
  };

  @action deleteMember = async (email: string) => {
      const member = this.userStore.get(email);
      if (member && member.id) {
          this.userStore.delete(email);
          await deleteMember(member.id);
      }
  };

  @action fetchInvites = async () => {
      const invites = await getInvitesApi();
      this.invites = [];
      invites.forEach((item) => {
          const model = new InviteModel();
          model.init(item);
          this.invites.push(model);
      });
      this.invitesLoaded = true;
  };

  @action
  async deleteInvite(id: string) {
      deleteInviteApi(id);
      const index = this.invites.findIndex((el) => el.id === id);
      if (index !== -1) {
          this.invites.splice(index, 1);
      }
  }

  @action deleteMemberOrInvite(email: string) {
      const member = this.userStore.get(email);
      if (member) {
          this.deleteMember(email);
      } else {
          const invite = this.invites.find((el) => el.email === email);
          if (invite) {
              this.deleteInvite(invite.id);
          }
      }
  }
}

export const teamState = new TeamState();
