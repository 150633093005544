import React, { useCallback, useMemo, useRef } from "react";
import { InviteModel }                         from "@geenee/builder/src/core/model/invite.model";
import { UserModel }                           from "@geenee/builder/src/core/model/user.model";
import { teamState }                           from "@geenee/builder/src/core/state/team.state";
import { useDeleteMemberModal }                from "@geenee/builder/src/pages/MemberPage/components/useDeleteMemberModal";
import { useHistory }                          from "@geenee/common";
import {
    Button, Column, DataType, Description, Divider, Wrapper
} from "@geenee/ui";

export const useTableData = () => {
    const userForDelete = useRef<InviteModel | UserModel>(null);
    const { showModal } = useDeleteMemberModal(userForDelete.current || {} as UserModel);
    const team = Array.from(teamState.userStore.values());
    const { invites } = teamState;
    const history = useHistory();

    const editMember = useCallback((email: string) => {
        history.push(`/team/${ encodeURIComponent(email).replace(/[.]/mg, '*') }`);
    }, []);

    const dividerRow = useMemo(() => ({
        name:    <Divider color='grey-2' />,
        email:   <Divider color='grey-2' />,
        rights:  <Divider color='grey-2' />,
        status:  <Divider color='grey-2' />,
        actions: <Divider color='grey-2' />
    }), []);

    const data = useMemo(() => {
        const cells: Array<{ [key: string]: React.ReactNode }> = [];
        [ ...team, ...invites ].forEach((member, index, array) => {
            cells.push({
                name: (
                    <Wrapper valign="center" style={ { height: 56 } }>
                        <Description style={ { width: 'calc(100% - 16px)', height: '20px' } } color="shade-2" shorten className='member-names'>
                            { `${ member.first_name || 'Empty' } ${ member.last_name || 'Name' }` }
                        </Description>
                    </Wrapper>
                ),
                email: (
                    <Wrapper valign="center" style={ { height: 56 } }>
                        <Description style={ { width: 'calc(100% - 16px)', height: '20px' } } shorten color="shade-2">
                            { ((member || { email: '' }).email || '').toLowerCase() }
                        </Description>
                    </Wrapper>
                ),
                rights: (
                    <Wrapper valign="center" style={ { height: 56 } }>
                        <Description weight="bold" color="shade-2" capitalize className='make-capitalised'>
                            { (member.role === 'editor' && 'editor') || (member.role === 'owner' && 'owner') || 'admin' }
                        </Description>
                    </Wrapper>
                ),
                status: (
                    <Wrapper valign="center" style={ { height: 56 } }>
                        <Description weight="bold" color={ (member as any).is_active ? 'green' : 'shade-4' }>
                            { (member as any).is_active ? 'Active' : 'Inactive' }
                        </Description>
                    </Wrapper>
                ),
                actions: (
                    <Wrapper row valign="center" align="right" style={ { height: 56 } }>
                        { !(member instanceof InviteModel) ? (
                            <Button
                                size='sm'
                                margin="xs"
                                radius="sm"
                                viewType='action'
                                icon="edit2"
                                iconSize={ 20 }
                                onClick={ () => {
                                    editMember(member.email || '');
                                } }
                            />
                        ) : <></> }
                        <Button
                            size='sm'
                            radius="sm"
                            viewType='action'
                            iconColor="error"
                            icon="remove"
                            iconSize={ 20 }
                            onClick={ () => {
                                userForDelete.current = member;
                                showModal();
                            } }
                        />
                    </Wrapper>
                )
            });
            if (index + 1 !== array.length) cells.push(dividerRow);
        });

        return cells;
    }, [ team, invites ]);

    const columns: Column<DataType>[] = useMemo(() => [
        {
            accessor: 'name',
            minWidth: 208,
            Header:   () => <Description uppercase color="shade-4" weight='bold'>Name</Description>,
            Cell:     ({ cell: { value } }) => <Wrapper key={ value }>{ value }</Wrapper>
        },
        {
            accessor: 'email',
            minWidth: 216,
            Header:   () => <Description uppercase color="shade-4" weight='bold'>Email</Description>,
            Cell:     ({ cell: { value } }) => <Wrapper key={ value }>{ value }</Wrapper>
        },
        {
            accessor: 'rights',
            minWidth: 96,
            Header:   () => <Description uppercase color="shade-4" weight='bold'>Rights</Description>,
            Cell:     ({ cell: { value } }) => <Wrapper key={ value }>{ value }</Wrapper>
        },
        {
            accessor: 'status',
            minWidth: 96,
            Header:   () => <Description uppercase color="shade-4" weight='bold'>Status</Description>,
            Cell:     ({ cell: { value } }) => <Wrapper key={ value }>{ value }</Wrapper>
        },
        {
            accessor: 'actions',
            minWidth: 88,
            Header:   () => <Description style={ { width: '100%' } } uppercase color="shade-4" align="right" weight='bold'>Actions</Description>,
            Cell:     ({ cell: { value } }) => <Wrapper key={ value }>{ value }</Wrapper>
        }
    ], []);
    return [ data, columns ];
};
