import { Light }                          from "@babylonjs/core/Lights/light";
import { BabylonRenderer, SceneNodeType } from "@geenee/geespector/renderer/babylonjs.renderer";
import { CommandType }                    from "@geenee/shared/src/commander/types";

export type LightType = 'light-spot' | 'light-point' | 'light-direct';

// @addUuid
export class LightStrategy implements CommandType {
    receiver: BabylonRenderer;
    entity: LightType;
    parent?: SceneNodeType;
    light?: Light | null;
    metadata : object | null = null;

    constructor(receiver: BabylonRenderer, entity: LightType, parent?: SceneNodeType) {
        this.receiver = receiver;
        this.parent = parent;
        this.entity = entity;
    }

    execute = () => {
        if (this.entity === 'light-spot') {
            this.light = this.receiver.addSpotLight(this.parent);
        } else if (this.entity === 'light-point') {
            this.light = this.receiver.addPointLight(this.parent);
        } else if (this.entity === 'light-direct') {
            this.light =  this.receiver.addDirectLight(this.parent);
        }
        if (this.light && this.metadata) {
            this.light.metadata = this.metadata;
        }
        if (!this.metadata && this.light) {
            this.receiver.addNewNodesToNodesMap([ this.light ]);
            this.metadata = { ...this.light.metadata || {} };
        }
    };

    revert = () => {
        if (this.light) {
            this.receiver.deleteMesh(this.light);
        }
    };
}
