import { initProps as collapseProps }  from '@geenee/geeclient-kit/src/lib/component/atom/collapse/util';
import { initProps as iconProps }      from '@geenee/geeclient-kit/src/lib/component/atom/icon/util';
import { initProps as linkProps }      from '@geenee/geeclient-kit/src/lib/component/atom/link/util';
import { initProps as plainTextProps } from '@geenee/geeclient-kit/src/lib/component/atom/plain-text/util';
import {
    BlurRadiusCustomExtensionOptType,
    BlurRadiusExtensionOptType,
    CompositionExtensionOptType,
    StyleExtensionOptType
} from '@geenee/geeclient-kit/src/lib/type/_extension-type';
import {
    CollapseAtomOptType,
    IconAtomOptType,
    LinkAtomOptType,
    PlainTextAtomOptType, SizeExtensionType
} from '../../type/_atom-option-type';

export type NavigationMoleculeOptType = {
  [key: string]: any;
  style_size?: SizeExtensionType<'s'| 'm' | 'l' | 'xl'>
} & IconAtomOptType
  & PlainTextAtomOptType
  & LinkAtomOptType
  & CollapseAtomOptType
  & StyleExtensionOptType<'transparent' | 'line' | 'solid' | 'blur'>
  & CompositionExtensionOptType<'topLeft' | 'topRight' | 'left' | 'right'>
  & BlurRadiusExtensionOptType<'s' | 'm' | 'l' | 'xl' | 'custom'>
  & BlurRadiusCustomExtensionOptType;

export const defaultOptions: NavigationMoleculeOptType = {
    ...iconProps,
    ...plainTextProps,
    ...linkProps,
    ...collapseProps,
    molecule_blur_radius_custom:  0,
    molecule_blur_radius:         's',
    composition_type:             'topRight',
    style_type:                   'transparent',
    style_color_text:             'inherit',
    style_color_text_alpha:       1,
    style_color_text_type:        'auto',
    style_color_text_variation:   'text',
    style_color_text_custom:      '',
    style_color_bg:               'inherit',
    style_color_bg_alpha:         1,
    style_color_bg_type:          'auto',
    style_color_bg_variation:     'contrast',
    style_color_bg_custom:        '',
    style_color_border:           'inherit',
    style_color_border_alpha:     1,
    style_color_border_type:      'auto',
    style_color_border_variation: 'contrast',
    style_color_border_custom:    '',
    style_corner_radius:          '0',
    style_corner_radius_custom:   0,
    style_size:                   's',
    link_icon_after:              true,
    link_align:                   'right',
    link_valign:                  'center',
    link_icon_size:               's',
    link_color_text:              'white',
    collapse_icon_size:           's',
    collapse_scroll_height:       's'
};
