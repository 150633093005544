import React, { useEffect }       from 'react';
import { LoadingSpinnerCentered } from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { observer }               from 'mobx-react';
import { nftState }               from '@geenee/builder/src/core/state/nft.state';
import { Activity }               from './components/Activity';
import { Header }                 from './components/Header';
import { SidebarWrapper }         from './styles';

export const WalletSideBar = observer(() => {
    const { activeWallet } = nftState;
    useEffect(() => {
        nftState.viewWallet();
    }, []);

    if (!activeWallet && !nftState.isWalletsLoaded) {
        return <LoadingSpinnerCentered />;
    } if (!activeWallet) {
        return <></>;
    }

    return (
        <SidebarWrapper fullWidth maxWidth='319px'>
            <Header wallet_id={ activeWallet.address } balance={ activeWallet.balance } />
            <Activity balance={ activeWallet.balance } />
        </SidebarWrapper>
    );
});
