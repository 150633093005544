import React, { useEffect, useState }          from 'react';
import GlobalState                             from '@geenee/geespector/src/components/globalState';
import { Icon }                                from '@geenee/ui';
import { useModalDialogWrapper }               from "@geenee/builder/src/components/ModalDialogWrapper";
import { SketchFabImporterModal }              from '@geenee/builder/src/components/SketchFabImporterModal';
import { SketchFabModel }                      from '@geenee/builder/src/core/model/SketchFabModel.model';
import { sketchfabState }                      from '@geenee/builder/src/core/state/sketchfab.state';
import { EXPERIENCE_MAX_SIZE_MB, TOAST_ERROR } from "@geenee/builder/src/lib/constants";

export const useHandlers = (
    handleUpload: (value: { model?: File }) => Promise<void>,
    setModalOptions: ({}) => void,
    onValidateSize?: (fileSize: number) => boolean,
    onClose?: () => void
) => {
    const [ error, setError ] = useState('');

    const sketchFabAuthorise = () => {
        sketchfabState.openAuthoriseUrl();
    };

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError('');
            }, 7000);
        }
    }, [ error ]);

    const onModelClick = async (model: SketchFabModel): Promise<boolean> => {
        sketchfabState.setLoading(true);
        const archiveSize = model.archives.gltf.size;
        if (onValidateSize && !onValidateSize(archiveSize)) {
            sketchfabState.setLoading(false);
            GlobalState?.showToast({
                severity: TOAST_ERROR,
                summary:  'File size exceeded',
                detail:   `This asset exceeds the size limit of the ${ EXPERIENCE_MAX_SIZE_MB } MB. Please upload a smaller size asset!`
            });
            return false;
        }

        const fetchedModel = await sketchfabState.downloadModel(model);
        await handleUpload({ model: fetchedModel });

        sketchfabState.setLoading(false);
        return true;
    };

    /* const getImageFile = async (model: SketchFabModel) => {
        const thumbnailImageObject = selectNearestWidthImage(
            model.thumbnails.images,
            THUMBNAIL_IMAGE_WIDTH
        );
        if (!thumbnailImageObject.url) {
            return null;
        }
        // const loadImageAction = AppActions.loadImageWithCors(dispatch);
        // return loadImageAction({ url: encodeURI(thumbnailImageObject.url) });
    }; */
    const [ showModal ] = useModalDialogWrapper(
        <SketchFabImporterModal
            onCloseSuccessModal={ () => {
                sketchfabState.clearData();
                onClose && onClose();
            } }
            onModelClick={ (value) => onModelClick(value) }
        />,
        { closeTriggerElement: <Icon color="shade-4" onClick={ onClose } name="closeRound" size={ 24 } /> },
        undefined,
        'off',
        () => {
            sketchfabState.clearData();
        }
    );

    const onModelImport = async () => {
        try {
            if (!localStorage.getItem('sketchfab_access_token')) {
                sketchFabAuthorise();
            } else {
                showModal();
            }
        } catch (e: any) {
            console.error(e);
        }
    };

    return { isSketchFabAuthorized: !!localStorage.getItem('sketchfab_access_token'), onModelImport, error };
};
