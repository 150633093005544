import styled       from 'styled-components';
import StyledButton from '@geenee/builder/src/components/common/Button';

/* Wrappers */
export const AllPlansButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-right: auto;

    svg {
        margin-right: 10px;
    }
`;

export const ButtonsWrapper = styled.div`
    width: 100%;
    padding: 44px 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    & > * {
        margin: 28px 15px;
    }
`;
/* - Wrappers */

export const CardInformation = styled.div`
    border: 1px solid
        ${ ({ theme: { secondaryColors } }) => secondaryColors.grey70 };
    border-radius: 8px;
    padding: 12px;
    width: 100%;
`;

export const DropDownArrow = styled.div`
    position: absolute;
    width: 6.97px;
    height: 3.95px;
    right: 16.03px;
    top: calc(50% - 3.95px / 2 - 0.02px);

    /* grayscale / gray */

    background: #9fa2b4;
`;

export const DefaultCard = styled.div`
    background: #ffffff;
    border: 1px solid #f0f1f7;
    border-radius: 8px;
    padding: 13px;
    font-weight: 400;
    font-size: 12px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    & > p {
        width: 100%;
        text-transform: capitalize;
    }

    cursor: pointer;
`;

export const Bold = styled.p`
    font-weight: 700;
    font-size: 13px;
    width: 100%;
    text-align: left;
    vertical-align: middle;
    text-transform: uppercase;
`;

export const BoldCenter = styled(Bold)`
    text-align: center;
`;

export const Link = styled.a`
    text-decoration: underline;
    color: #595959;

    &:hover {
        color: inherit;
    }
`;

export const Card = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;

    > div {
        background: #ccc;
        border-radius: 50%;
        height: 8px;
        width: 8px;
        margin-right: 5px;
    }
`;
export const AddNewCard = styled.div``;
export const CardListWrapper = styled.div`
    background: #f5f5f5;

    box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.12);
    border-radius: 8px;
    padding: 4px;
    width: 100%;
    font-size: 12px;

    > div {
        border-radius: 8px;
        padding: 8px;
        cursor: pointer;
    }

    > ${ AddNewCard } {
        color: #52575c;
        font-weight: 500;
    }

    > ${ Card } {
        text-transform: capitalize;

        &:hover {
            background: white;
        }
    }
`;

/* By adding waiting to props you get a loading gif to the right side */
export const Button = styled(StyledButton)<{ $waiting?: boolean }>`
    ${ (props) => props.$waiting
        && `
        &:after {
            content: url('/asset/images/svg/pulse.svg');
            pointer-events: none;
            cursor: default;
            position: absolute;
            display: block;
            top: 50%;
            left: calc(100% + 16px);
            transform: translateY(-50%);
        }

        &:active:after {
            top: calc(50% - 1px);
        }
    ` }
`;

export const CloseElement = styled.div`
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 10px;
    z-index: 1;
`;

export const ModalError = styled.div`
    position: absolute;
    background: #ffddd6;
    border: 1px solid #fbd9d2;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    padding: 10px;
    top: 50%;
    transform: translateY(-50%);
    right: -170px;
    width: 150px;
    color: #513b3b;
    font-style: italic;

    @keyframes FadeIn {
        from {
            opacity: 0.01;
        }
        to {
            opacity: 1;
        }
    }

    animation-name: FadeIn;
    animation-duration: 0.2s;
    animation-timing-function: ease-in;

    b {
        display: block;
        width: 100%;
    }
`;
