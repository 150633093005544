import getEmitter from '../emitter/getEmitter';
import CVStamp from './CVStamp';
import { getImageFromCanvas, captureFrame, scaleFromSize } from '../image/ImageUtil';
import { createCanvasRenderingContext2D } from '../utils';
import { Processor, Size } from '@geenee/armature'
import {Matrix4, StampResult} from "~/index";


export default class Stamp extends Processor<StampResult> {
  // @ts-ignore
  public on: Function;
  // @ts-ignore
  public off: Function;
  // @ts-ignore
  private emit: Function;
  private stamp?: CVStamp;
  private frameContext: CanvasRenderingContext2D;
  private videoContext: CanvasRenderingContext2D;
  private stampContext: CanvasRenderingContext2D;
  private intrinsic: Float64Array
  private translation: Float64Array;
  private sceneSize: Size = {width: 1920, height: 1080};

  constructor() {
    super();
    // Emitter
    const emitter = getEmitter(this) as any;
    this.emit = emitter.emit;
    this.on = emitter.on;
    this.off = emitter.off;

    this.frameContext = createCanvasRenderingContext2D();
    this.videoContext = createCanvasRenderingContext2D();
    this.stampContext = createCanvasRenderingContext2D();

    this.intrinsic = new Float64Array(3 * 4);
    this.translation = new Float64Array(4 * 4);
  }

  public async init(params?: any, size?: Size, ratio?: number) {
    this.params = params || {};
    if (!ratio && size)
      ratio = size?.width / size?.height;

    this.setupVideo(
        size || this.videoSize,
        ratio || this.videoRatio);

    // @ts-ignore
    this.stamp = new CVStamp(window.cv);
    return true
  }

  public addTarget(image: ImageData) {
    this.stamp?.addTarget(image);
  }

  public train() {
    this.stamp?.train();
  }

  public setSceneSize (width: number, height: number) {
    this.sceneSize = { width, height };
  }

  public async process(input: HTMLCanvasElement) : Promise<StampResult> {
    const context = input.getContext("2d", { alpha: false });
    if(!context) {
      return []
    }
    const stampFrame = getImageFromCanvas(context, this.stampContext, this.videoSize.width, this.videoSize.height);
    const nFoundTarget = this.stamp?.recognize(stampFrame, this.intrinsic, this.translation);

    // flip matrix
    this.translation[1] *= -1;
    this.translation[2] *= -1;
    this.translation[4] *= -1;
    this.translation[7] *= -1;
    this.translation[8] *= -1;
    this.translation[11] *= -1;

    // scale matrix
    const matScale = scaleFromSize(this.videoSize, this.sceneSize.width, this.sceneSize.height);
    const scaleX = matScale;
    const scaleY = matScale;
    this.translation[0] *= scaleX;
    this.translation[1] *= scaleX;
    this.translation[2] *= scaleX;
    this.translation[3] *= scaleX;
    this.translation[4] *= scaleY;
    this.translation[5] *= scaleY;
    this.translation[6] *= scaleY;
    this.translation[7] *= scaleY;

    return [nFoundTarget, this.translation as unknown as Matrix4, this.intrinsic[5]]
  }
}
