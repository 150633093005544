import { Wrapper }  from '@geenee/ui';
import styled       from 'styled-components';
import StyledButton from '@geenee/builder/src/components/common/Button';

export const GradientWrapper = styled(Wrapper)`
    padding: 26px 55px;
    background: rgba(202, 202, 202, 0.19);
    border-radius: 10px;
`;

export const BackButton = styled(StyledButton)`
    &&& {
        background: rgba(196, 196, 196, 0.19);
        border: 1px solid
            ${ ({ theme: { secondaryColors } }) => secondaryColors.grey200 };
        box-shadow: none;
        color: black;

        &:hover {
            background: ${ ({ theme: { colors } }) => colors.white };
            box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
            color: black;
        }
    }
`;

export const SelectWrapper = styled.div`
    padding: 0 60px;
    .one-ui-description {
        font-family: Mulish;
        font-size: 12px;
    }

    .one-ui-checkbox {
        align-items: center;
    }

    .one-ui-checkbox__element {
        position: relative;
        width: 20px;
        height: 20px;
        flex: 0 0 auto;
        background-color: transparent;
        border: 1px solid #52575C;
        border-radius: 50%;

        &--checked {
            border: none;
        }

        &:before {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #000;
        }
    }

`;

export const SwiperWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  height: 204px;
  position: relative;
  img {
    object-fit: contain;
  }
  .swiper-button-next,.swiper-button-prev {
    top: 50%
  }
  margin-bottom: 25px;
`;
