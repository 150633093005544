import React, { useState }                            from 'react';
import { Checkbox, Description, Title, Wrapper }      from '@geenee/ui';
import { observer }                                   from 'mobx-react';
import NextArrow                                      from '@geenee/builder/src/asset/icons/NextArrow';
import nftPlaceholder                                 from '@geenee/builder/src/asset/images/NFT_placeholder.png';
import RaisedButton                                   from '@geenee/builder/src/components/common/Button/WithIcon';
import { PreviewCarousel }                            from '@geenee/builder/src/components/modals/DoubleCheckNFT/PreviewCarousel';
import envConfig                                      from '@geenee/builder/src/lib/envConfig';
import { AddNFTDetails }                              from '../AddNFTDetails';
import { ContentWrapper }                             from '../PostPublishingNFT/styles';
import { ReenterNFTWalletPassword }                   from '../ReenterNFTWalletPassword';
import { BackButton, GradientWrapper, SelectWrapper } from './styles';

type Props = {
  close: () => void;
  // eslint-disable-next-line no-empty-pattern
  setModal: ({ }) => void;
  descriptionData: {
    title: string | undefined;
    subtitle: string | undefined;
    category: number | undefined;
    description: string | undefined;
    price: string | undefined;
    preview_images: string[];
  };
  hasWallet: boolean;
  mintProjectId: string;
  projectUrl: string;
};

export const DoubleCheckNFT = observer((props: Props) => {
    const { title, category, description, price } = props.descriptionData;
    const [ agree, setAgree ] = useState(false);

    const onClickConfirm = () => {
        props.setModal({
            component: ReenterNFTWalletPassword,
            props:     {
                descriptionData: props.descriptionData,
                mintProjectId:   props.mintProjectId,
                previewUrl:      envConfig.MAGELLAN_PREVIEW_URL,
                projectUrl:      props.projectUrl
            }
        });
    };

    const onGoBack = () => {
        props.setModal({
            component: AddNFTDetails,
            props:     { descriptionData: props.descriptionData, mintProjectId: props.mintProjectId, projectUrl: props.projectUrl }
        });
    };

    return (
        <ContentWrapper>
            <Wrapper align='center'>
                <Title size='xxl' align='center' margin='md'>
                    Double check.
                </Title>
                <Wrapper maxWidth='413px' align='center'>
                    <Description size='sm' align='center' margin='md'>
                        FYI, you will not be able to edit the project or information once you publish it as an NFT. Make sure it’s what you want!
                    </Description>
                </Wrapper>
                <Wrapper maxWidth='490px' align='center'>
                    <GradientWrapper align='center' margin='lg'>
                        <PreviewCarousel items={ props.descriptionData.preview_images.length
                            ? props.descriptionData.preview_images : [ nftPlaceholder ] }
                        />
                        { /* eslint-disable-next-line max-len */ }
                        { /* <Wrapper margin='md'><ImgWrapper><img src={ props.descriptionData.preview_images[ 0 ] || nftPlaceholder } alt="some image" width='489px' height='262px' style={ { objectFit: 'cover' } } /></ImgWrapper></Wrapper> */ }
                        { /* <Wrapper margin='md'><img src={ nftPlaceholder } alt="some image" width='380px' height='204px' /></Wrapper> */ }
                        <Wrapper align='center'>
                            <Description size='sm' margin='sm'>
                                { title }
                                { /*                              { ' ' }
                                by
                                { ' ' }
                                { sellerName } */ }
                            </Description>
                            <Description size='sm' margin='sm'>
                                Category:
                                { ' ' }
                                { category }
                            </Description>
                            <Description size='sm' margin='sm'>
                                { description }
                            </Description>
                            <Description size='sm'>
                                Price: ETH
                                { ' ' }
                                { price }
                            </Description>
                        </Wrapper>
                    </GradientWrapper>
                    <Wrapper align='center' margin='xl'>
                        <SelectWrapper>
                            <Checkbox
                                checked={ agree }
                                onChange={ (checked) => setAgree(checked) }
                                label='By checking this box, you agree to our terms
                                and conditions as well as acknowledge that the art is your own IP.'
                            />
                        </SelectWrapper>
                    </Wrapper>

                    <Wrapper row fullWidth style={ { justifyContent: 'space-between' } }>
                        <BackButton
                            $padding="0 23px"
                            $justify="center"
                            $width="141px"
                            $height="61px"
                            onClick={ () => onGoBack() }
                            label="BACK"
                        />
                        <Wrapper align="right">
                            <RaisedButton
                                $gradient
                                $padding="0 23px"
                                $justify="space-between"
                                $width="141px"
                                $height="61px"
                                onClick={ () => onClickConfirm() }
                                text="CONFIRM"
                                iconElement={ (
                                    <NextArrow width="30" height="30" />
                                ) }
                                disabled={ !agree }
                            />
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </ContentWrapper>
    );
});
