import React, { useState }         from "react";
import { SceneModuleControls }     from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/custom-module-config-form/custom-module-config-form.component";
import { LightningPanel }          from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/LightningPanel";
import { MaterialPanel }           from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/MaterialPanel";
import { PropertiesPanel }         from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/PropertiesPanel";
import { ResizeWrapper }           from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/ResizeWrapper";
import { TransformModes }          from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/type";
import { saveShadowOption }        from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/util/model-properties.helper";
import { EmptyTab }                from "@geenee/geespector/src/components/sceneExplorer/components/EmptyTab";
import { ScenePanelComponent }     from "@geenee/geespector/src/components/scenePanel/scenePanelComponent";
import { useInject }               from "@geenee/shared/src/hook/use-inject.hook";
import { AtomModel }               from "@geenee/shared/src/magellan/atom/model/atom.model";
import { TYPE_NATIVE_AR }          from "@geenee/shared/src/util/constants";
import { cn, Divider, Wrapper }    from "@geenee/ui";
import { observer }                from "mobx-react";
import { Object3D }                from "three";
import { NativeArBannerComponent } from "../NativeArBanner/native-ar-banner.component";
import './parameters-tab-treejs.scss';

type Props = {
  sceneWrapperObject: Object3D
}
const className = cn('parameters-tab');

export const ParametersTabTreejs = observer(({ sceneWrapperObject }: Props) => {
    const { SceneState } = useInject();
    const {
        activeMolecule, transformControlsMode, currentAsset, currentShadowValue, selectedCodeModuleId
    } = SceneState;

    const snippetMode = !!selectedCodeModuleId;

    const atomModels = activeMolecule?.sceneActorAtoms || [] as AtomModel[];
    const [ scenePanelCollapsed, setScenePanelCollapsed ] = useState(false);

    const saveShadows = (value: boolean, type: 'receive' | 'cast') => {
        if (currentAsset === activeMolecule) {
            atomModels.forEach((el) => saveShadowOption(value, type, el));
        } else {
            saveShadowOption(value, type, currentAsset);
        }
    };

    const Content = () => {
        if (transformControlsMode === TransformModes.lightning) {
            return (
                <LightningPanel
                    disableReceiveShadow={ currentShadowValue.disableReceiveShadow }
                    disableCastShadow={ currentShadowValue.disableCastShadow }
                    onDisableShadowChanged={ saveShadows }
                />
            );
        }

        return (
            <PropertiesPanel
                sceneWrapperObject={ sceneWrapperObject }
            />
        );
    };

    return (
        <Wrapper
            fullHeight
            className={ className({ collapsed: scenePanelCollapsed }) }
            maxWidth="fit-content"
        >
            <ResizeWrapper
                enable={ { left: true } }
                minWidth={ 312 }
            >
                <ScenePanelComponent
                    key={ currentAsset?.id }
                    title="Parameters"
                    clickMode="icon"
                    changeTitleBackgroundOnHover={ false }
                    onCollapseChange={ setScenePanelCollapsed }
                >
                    { snippetMode ? <SceneModuleControls /> : null }
                    { !snippetMode
                        ? atomModels.length ? Content() : (
                            <EmptyTab
                                customLabel="No selected nodes"
                                customDescription="Select any node to display their parameters"
                            />
                        ) : null }
                    { !snippetMode && currentAsset?.isText3D ? (
                        <>
                            <Divider style={ { height: 1, marginTop: 12, marginBottom: 12 } } color="white" />
                            <MaterialPanel />
                        </>
                    ) : null }
                    { activeMolecule.$parent.type === TYPE_NATIVE_AR
                        ? (
                            <>
                                <Divider style={ { height: 1, marginTop: 12, marginBottom: 12 } } color="white" />
                                <NativeArBannerComponent />
                            </>
                        ) : <></> }
                </ScenePanelComponent>
            </ResizeWrapper>
        </Wrapper>
    );
});
