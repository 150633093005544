import { AttachmentModel }                                              from "@geenee/builder/src/magellan/model/attachment.model";
import { CustomCodeAttachmentModel as CustomCodeAttachmentModelShared } from "@geenee/shared/src/magellan/atom/custom-module-atom/model/custom-code-attachment.model";
import { inject, injectable, postConstruct }                            from "inversify";
import { action, makeObservable }                                       from "mobx";

@injectable()
export class CustomCodeAttachmentModel extends CustomCodeAttachmentModelShared {
  @inject("<AttachmentModel>")
      attachmentModel!: AttachmentModel;

  constructor() {
      super();
      makeObservable(this);
  }

  @postConstruct()
  init() {
  }

  @action
  toServerData() {
      return this.attachmentModel.toServerData.bind(this)();
  }

  @action
  saveData() {
      return this.attachmentModel.saveData.bind(this)();
  }
}
