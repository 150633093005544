import React, { useMemo, useState }              from 'react';
import { CallbackFunctionVariadic }              from "@geenee/geeclient-kit/src/lib/type/type";
import { Description, FormItem, Input, Wrapper } from '@geenee/ui';
import { checkUrl, UrlInputBefore }              from "@geenee/builder/src/pages/SDKAccessPage/components/CreateToken";
import { encodeKey }                             from "@geenee/builder/src/pages/SDKAccessPage/components/useTableData";

export function EditModalDescription({ onChange, token }:{ onChange: CallbackFunctionVariadic, token: string}) {
    const [ hostUrl, setHostUrl ] = useState<string | null>(null);

    const isUrl = useMemo(() => checkUrl(hostUrl), [ hostUrl ]);

    const transformIsUrlResponseToFormItemFormat = (isUrlResponse) => {
        if (isUrlResponse === null || isUrlResponse === true) return false;
        return 'Invalid URL';
    };

    return (
        <Wrapper align="center" color="dark-5" margin="xs">
            <Description color="shade-4">
                Please confirm update of the Host URL for
            </Description>
            <Description weight="bold" margin="md">{ encodeKey(token) }</Description>
            <FormItem autoFocusDetect margin="off" error={ transformIsUrlResponseToFormItemFormat(isUrl) }>
                <Input
                    before={ <UrlInputBefore /> }
                    size="md"
                    fullWidth
                    onChange={ (e) => {
                        const url = e.target.value.replace(/http(s)*:\/\//, '');
                        setHostUrl(url);
                        onChange(url);
                    } }
                    value={ hostUrl || '' }
                    placeholder="Enter host URL"
                />
            </FormItem>
        </Wrapper>
    );
}
