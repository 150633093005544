import React from 'react';

// to update state variable in useCallback
/**
 * https://github.com/facebook/react/issues/14099#issuecomment-440013892
 *
 * @param {function} fn
 */
export function useEventCallback(fn: any) {
    const ref = React.useRef(fn);
    React.useLayoutEffect(() => {
        ref.current = fn;
    });
    return React.useCallback((...args: any) => (ref.current)(...args), []);
}
