import React, { FC, memo, useEffect } from "react";
import { cn }                         from "../../util/bem";
import { useDynamicHeight }           from "./animated-wrapper.hook";
import { AnimatedWrapperTransition }  from "./animated-wrapper-transition.component";
import "./animated-wrapper.component.scss";

const className = cn("animated-wrapper");

export type AnimatedWrapperPropsType = {
    className?: string;

    children: React.ReactNode;

    transitionFade?: boolean;
    transitionHeight?: boolean;

    manual?: boolean;

    initialHeight?: string;
    fullWidth?: boolean;
    style?: React.CSSProperties;
}

export const AnimatedWrapper: FC<AnimatedWrapperPropsType> = memo((props) => {
    const [ ref, height ] = useDynamicHeight(props.initialHeight, props.transitionHeight);

    return (
        <div
            className={ className(
                {
                    fullWidth: props.fullWidth,
                    fade:      props.transitionFade,
                    height:    props.transitionHeight
                },
                null,
                props.className
            ) }
            style={ {
                // height transition. is it ok?
                height: props.transitionHeight ? height : undefined,
                ...props.style
            } }
        >
            <div
                ref={ ref }
                style={ { width: "100%" } }
            >
                <AnimatedWrapperTransition
                    manual={ props.manual }
                    className={ className("container") }
                >
                    { props.children }
                </AnimatedWrapperTransition>
            </div>
        </div>
    );
});

AnimatedWrapper.defaultProps = { initialHeight: "auto" };
