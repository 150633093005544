import React, { ChangeEvent }   from "react";
import { Icon, Input, Wrapper } from "@geenee/ui";

interface ISceneExplorerFilterComponentProps {
    value: string
    onFilter: (filter: string) => void;
    disabled?: boolean
}

export function SceneExplorerFilterComponent({ value, onFilter, disabled } : ISceneExplorerFilterComponentProps) {
    return (
        <Wrapper>
            <Input
                disabled={ disabled }
                transparent
                size="sm"
                type="text"
                placeholder="Search"
                value={ value }
                icon="search"
                after={ value ? <Icon name="closeRound" onClick={ () => onFilter('') } /> : undefined }
                iconSize={ 18 }
                iconColor="grey-9"
                onChange={ (evt: ChangeEvent<HTMLInputElement>) => {
                    onFilter(evt.target.value);
                } }
                margin="xs"
            />
        </Wrapper>
    );
}
