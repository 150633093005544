import type { ProjectType } from '@geenee/shared/index';
import FS                   from '@isomorphic-git/lightning-fs';
import { injectable }       from 'inversify';
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
    toJS
} from 'mobx';
// browserFS setup

@injectable()
export class FoldersController {
    @observable fs: FS;

    @observable isInitialised = false;
    @observable folders: { id: string; title: string; children: string[] }[] = [];

    constructor() {
        // @ts-ignore
        this.fs = new FS('builder-fs', { wipe: true });

        makeObservable(this);
        this.isInitialised = true;
    }

    private getFolders = async (): Promise<string[]> => {
        const tree = await this.fs.promises.readdir('/');
        return tree.filter((key) => key.includes('_'));
    };

    @computed get foldersTree() {
        return toJS(this.folders);
    }

    @action
    async init() {
        const folders = await this.getFolders();

        const promises = folders.map(async (path) => {
            const children = await this.fs.promises.readdir(`/${ path }`);

            return {
                id:    path,
                title: path.split('_')[ 0 ],
                children
            };
        });

        const foldersAwaited = await Promise.all(promises);
        runInAction(() => {
            this.folders = foldersAwaited;
        });
    }

    @action
    async handleFolder(schema: ProjectType) {
        const { path = '/' } = schema.options;
        const folderId = path.split('/')[ 1 ];

        const folders = await this.readFolder();
        const existFolder = folders.find((key) => key === folderId);

        if (existFolder) {
            const subFiles = await this.readFolder(path);

            const isFileExist = subFiles.find((file) => file === schema.id);

            if (!isFileExist) {
                await this.createFile(
                    `/${ folderId }/${ schema.id }`,
                    JSON.stringify(schema)
                );
                this.folders
                    .find((folder) => folder.id === folderId)
                    ?.children.push(schema.id);
            }
        } else {
            path !== '/' && await this.createFolder(folderId);

            await this.createFile(
                path === '/' ? `/${ schema.id }` : `/${ folderId }/${ schema.id }`,
                JSON.stringify(schema)
            );
        }
    }

    @action
    async readFile(name: string) {
        try {
            return await this.fs.promises.readFile(name);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    @action
    async createFile(name: string, data: any) {
        try {
            return await this.fs.promises.writeFile(name, data);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    @action
    async moveObjectToFolder(
        object: any,
        folderId: string
    ) {
        const { path = '' } = object.options;

        try {
            const oldFilePath = path.includes('_') ? `${ path }/${ object.id }` : `/${ object.id }`;
            const newFilePath = folderId ? `/${ folderId }/${ object.id }` : `/${ object.id }`;

            await this.fs.promises.rename(oldFilePath, newFilePath);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    @action
    async readFolder(path = '/'): Promise<string[]> {
        try {
            return await this.fs.promises.readdir(path);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    @action
    async removeFolder(path: string) {
        try {
            await this.fs.promises.rmdir(`/${ path }`);

            runInAction(() => {
                this.folders = this.folders.filter(
                    (folder) => folder.id !== path
                );
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    @action
    async removeFile(path: string) {
        try {
            await this.fs.promises.unlink(path);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    @action
    async createFolder(path: string) {
        try {
            const id = path;
            const title = id.split('_')[ 0 ];

            await this.fs.promises.mkdir(`/${ path }`);

            this.folders = [
                {
                    id,
                    title,
                    children: []
                },
                ...this.folders
            ];
        } catch (error) {
            console.error(error);
        }
    }

    @action async changeFolderTitle(id: string, title: string) {
        const folderIndex = this.folders.findIndex((f) => f.id === id);
        const postId = id.split('_')[ 1 ];

        try {
            if (folderIndex > -1) {
                this.folders[ folderIndex ].title = title;
                this.folders[ folderIndex ].id = `${ title }_${ postId }`;

                await this.fs.promises.rename(`/${ id }`, `/${ title }_${ postId }`);

                return `${ title }_${ postId }`;
            }

            return '';
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}
