import React, { useRef }    from 'react';
import { useDrag, useDrop } from 'react-dnd';
import LoadingSpinner       from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { REORDER_ITEM }     from '@geenee/builder/src/lib/constants';
import { Media }            from './styles';

type SingleItemProps = {
  setFocus: (id: string) => void;
  id: string;
  index: number;
  isFocused: boolean;
  canMove: boolean;
  url: string;
  order: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  updateListItemsOrder: (index: number, id: string) => void;
  mediaContainer?: any;
};

type DropItem = {
  type: string;
  id: string;
  index: number;
}

function SingleItem({
    setFocus, id, index, isFocused, canMove, url, order, moveItem, updateListItemsOrder, mediaContainer
}: SingleItemProps) {
    const imageRef = useRef(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ { isDragging }, drag ] = useDrag({
        item: {
            type: REORDER_ITEM,
            id,
            order,
            index
        },
        canDrag: canMove,
        collect: (monitor) => ({ isDragging: monitor.isDragging() })
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ { isOver }, drop ] = useDrop({
        accept: [ REORDER_ITEM ],
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        drop:   (item: DropItem) => {
            updateListItemsOrder(index, id);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        hover: (item, monitor) => {
            if (!imageRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves

            if (dragIndex === hoverIndex) {
                return;
            }

            moveItem(dragIndex, hoverIndex);
            // NOTE - we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            // eslint-disable-next-line no-param-reassign
            item.index = hoverIndex;
        },
        collect: (monitor) => ({ isOver: monitor.isOver() })
    });

    drag(drop(imageRef));

    const MediaContainer = mediaContainer || Media;

    return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <MediaContainer
            ref={ imageRef }
            onClick={ () => setFocus(id) }
            focused={ isFocused }
            onMouseOver={ () => setFocus(id) }
            canMove={ canMove }
        >
            { url ? <img src={ url } alt={ id } /> : <LoadingSpinner /> }
        </MediaContainer>
    );
}
// eslint-disable-next-line arca/no-default-export
export default SingleItem;
