export const tooltips = {
    activeUsersChart: 'This LineChart charts the number of users who visited within the last 1, 7, 14, 28, or 30 days,'
                             + ' for each date, until today. Useful for visualizing your userbase growth over time.',
    bounceRateChart: 'This LineChart charts the bounce rate percentage for each date, until today. '
                             + 'A "bounce" occurs when a visitor arrives at your site and promptly leaves without'
                             + ' interacting any further. The bounce rate is the percentage of users who bounce. '
                             + 'A lower bounce rate indicates higher initial engagement. Useful for seeing how well '
                             + 'a site grabs people\'s attention and "hooks" them into staying.',
    organicSearchesChart: 'This LineChart charts the number of organic searches for each date, until today. '
                             + 'This chart is useful for visualizing how much traffic is coming from organic searches alone.',
    pagesPerSessionChart: 'This ColumnChart charts the number of pageviews per session for each date, until today.'
                             + ' This chart is useful for showing how engaged users are. '
                             + 'More pageviews per session generally means your content is compelling users to browse around.',
    sessionDurationChart: 'This ColumnChart charts how many seconds are spent on average per session, for each date, until today. '
                             + 'This is useful for visualizing, on average, how long users stay engaged on your site before leaving.',
    sessionsByDateChart: 'This LineChart charts the number of sessions, and optionally pageviews and/or users, for each date, until today. '
                             + 'This is useful for seeing how a site\'s traffic changes and grows over time.',
    sessionsGeoChart: 'This GeoChart shows on a map of the world the number of sessions, and optionally pageviews, '
                             + 'for each country, in the date range specified (number of days ago, until today). '
                             + 'Useful for quickly visualizing where in the world your visitors are coming from.',
    sessionsBySourceChart: 'This PieChart charts the number of sessions for each source (such as (direct), google, '
                             + 'or a domain name), in the date range specified (number of days ago, until today).'
                             + ' Useful for knowing what sites your traffic is coming from.',
    sessionsByHourChart: 'This ColumnChart charts the number of sessions for each hour of the day (0 for midnight, '
                            + '12 for noon, 18 for 6pm, etc), in the date range specified (number of days ago, until today).'
                            + ' This shows what time of day users are most active and can be helpful for knowing when to post.',
    pageViewPerPathChart: 'This TableChart shows the number of pageviews for each page path in the date range specified (number '
                            + 'of days ago, until today). This chart is useful for seeing which pages and posts are the most popular.'
};
