import { ExperienceModel } from '@geenee/shared';
import { SectionFactory }  from '@geenee/shared/src/magellan/section/factory/section.factory';
import { ViewType }        from '@geenee/shared/type/view.type';
import { injectable }      from 'inversify';
import { FactoryAbstract } from '../../util/factoryContainer';

@injectable()
export class ViewFactory extends FactoryAbstract {
    create(viewSchema: Partial<ViewType>, $parent: ExperienceModel) {
        const viewModel = this.container.get('<ViewModel>');
        const sectionFactory: SectionFactory = this.container.get('<SectionFactory>');
        Object.assign(viewModel, viewSchema);
        viewSchema?.sections?.forEach((el) => {
            const sectionModel = sectionFactory.create(el, viewModel);
            viewModel.sectionsRegistry.set(sectionModel.id, sectionModel);
        });
        if ($parent) {
            viewModel.$parent = $parent;
        }
        viewModel.initCode();
        return viewModel;
    }
}

export type ViewFactoryType = ViewFactory
