import React, { useEffect, useMemo, useRef }                                     from 'react';
import cameraModelGLB                                                            from '@geenee/shared/asset/models/camera.glb';
import { useLoader }                                                             from '@react-three/fiber';
import { BufferGeometry, DoubleSide, MeshBasicMaterial, PlaneGeometry, Vector3 } from 'three';
import { GLTFLoader }                                                            from 'three/examples/jsm/loaders/GLTFLoader';
import { setEnvMap }                                                             from '../../utils/sdk/SceneObjectsUtils';

export const CAMERA_PLANE_WIDTH = 1.3;
export const CAMERA_PLANE_HEIGHT = 2;
export const HEIGHT_POSITION_OFFSET = 0.3;
const DISTANCE = 4;

export function CameraViewPort() {
    const cameraModel = useLoader(GLTFLoader, cameraModelGLB);
    const geometry = useMemo(() => {
        const pts = [
            new Vector3(
                CAMERA_PLANE_WIDTH / 2,
                DISTANCE,
                CAMERA_PLANE_HEIGHT / 2
            ),
            new Vector3(0, 0, 0),
            new Vector3(0, 0, CAMERA_PLANE_HEIGHT),
            new Vector3(CAMERA_PLANE_WIDTH, 0, CAMERA_PLANE_HEIGHT),
            new Vector3(CAMERA_PLANE_WIDTH, 0, 0)
        ];

        const geom = new BufferGeometry().setFromPoints(pts);
        geom.setIndex([ 0, 1, 2, 0, 2, 3, 0, 3, 4, 0, 4, 1, 1, 3, 2, 1, 4, 3 ]);
        geom.computeVertexNormals();
        return geom;
    }, []);

    useEffect(() => {
        // const loader = new GLTFLoader();
        setEnvMap(cameraModel.scene);

        // loader.loadAsync(cameraModel).then(async (gltf) => {
        //     await setEnvMap(gltf.scene);
        //     setIphoneAsset(gltf.scene);
        // });
    }, [ cameraModel ]);

    return (
        <>
            <mesh
                geometry={
                    new PlaneGeometry(
                        CAMERA_PLANE_WIDTH,
                        CAMERA_PLANE_HEIGHT
                    )
                }
                position={ [ 0, HEIGHT_POSITION_OFFSET, 0 ] }
                material={
                    new MeshBasicMaterial({
                        color:       0xcc46cf,
                        side:        DoubleSide,
                        depthWrite:  false,
                        opacity:     0.2,
                        transparent: true
                    })
                }
            />
            <lineSegments
                rotation={ [ Math.PI / 2, 0, 0 ] }
                position={ [
                    -CAMERA_PLANE_WIDTH / 2,
                    CAMERA_PLANE_HEIGHT / 2 + HEIGHT_POSITION_OFFSET,
                    0
                ] }
            >
                <edgesGeometry attach="geometry" args={ [ geometry ] } />
                <lineBasicMaterial color="#CC46CF" attach="material" />
            </lineSegments>
            { cameraModel?.scene && (
                <>
                    { /* <directionalLight position={[0.05, 0, -5]} intensity={2} /> */ }
                    <primitive
                        object={ cameraModel?.scene }
                        dispose={ null }
                        scale={ [ 0.01, 0.01, 0.01 ] }
                        rotation={ [ 0, Math.PI / 2, 0 ] }
                        position={ [ 0, 0.23, DISTANCE + 0.07 ] }
                    />
                </>
            ) }
        </>
    );
}
