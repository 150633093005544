import { runInAction }                         from 'mobx';
import { useBuilderInject }                    from '@geenee/builder/src/core/hook/use-builder-inject';
import { EXPERIENCE_MAX_SIZE_MB, TOAST_ERROR } from '@geenee/builder/src/lib/constants';

const experienceSizeErrorTemplate =  (
    sizeDifference: string
) => `This asset exceeds the size limit of the ${ EXPERIENCE_MAX_SIZE_MB } MB Experience by ${ sizeDifference } MB.\nPlease upload a smaller asset.`;

export const useShowErrorMessage = (messageTemplate: (message: string) => string = experienceSizeErrorTemplate) => {
    const { BuilderState } = useBuilderInject();

    const showErrorNotification = ({ message, summary = '' }: {message?: string | number, summary?: string}) => {
        runInAction(() => {
            BuilderState.toast = { severity: TOAST_ERROR, detail: messageTemplate(`${ message }`), summary };
        });
    };
    return { showErrorNotification };
};
