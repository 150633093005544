import React, { useEffect, useState }                   from 'react';
import { Description, FormItem, Input, InputTableItem } from '@geenee/ui';
import parseVideoServiceUrl                             from '@geenee/builder/src/lib/parseVideoServiceUrl';

const validate = (value: string) => {
    if (!value) return 'required';
    if (parseVideoServiceUrl(value)?.type === undefined) return 'notValid';
    return '';
};

interface Props {
    url: string;
    onUpdate: (value: string) => void;
    disabled?: boolean;
}

export function VideoUrlForm({ url, onUpdate, disabled }: Props) {
    const [ value, setValue ] = useState(url || '');
    const [ error, setError ] = useState('');
    useEffect(() => {
        setValue(url);
    }, [ url ]);

    return (
        <InputTableItem label="Video URL">
            <FormItem
                autoFocusDetect
                error={
                    (error === 'required' && <Description noWrap color="shade-2">This field is required</Description>)
                  || (error === 'notValid' && <Description noWrap color="shade-2">Please add a valid video URL</Description>)
                }
            >
                <Input
                    size="md"
                    fullWidth
                    value={ value }
                    disabled={ disabled }
                    onChange={ (e) => {
                        setValue(e.target.value);
                        const validatedError = validate(e.target.value);
                        if (!validatedError) {
                            onUpdate(e.target.value);
                        }
                        setError(validatedError);
                    } }
                />
            </FormItem>
        </InputTableItem>
    );
}
