import React                    from 'react';
import { useInject }            from '@geenee/shared';
import { Wrapper }              from '@geenee/ui';
import { observer }             from 'mobx-react';
import { AddSection }           from '@geenee/builder/src/components/Composer/HeaderButtons/AddSection';
import { ProjectLinksDropdown } from '@geenee/builder/src/components/ProjectsLinkDropdown';
import { MagellanState }        from '@geenee/builder/src/magellan/state/app.state';

export const HeaderButtons = observer(() => {
    const { AppState } = useInject();
    const { activeWorkingItem } = AppState as unknown as MagellanState;
    const disabled = activeWorkingItem && activeWorkingItem.nft_published;
    return (
        <Wrapper
            row
            fullWidth
            margin="sm"
        >
            <Wrapper wrap row flex='1' align="space-between" valign="center">
                <AddSection />
                <Wrapper row maxWidth="fit-content">
                    <Wrapper align="right" id="preview-container" margin="xs">
                        <ProjectLinksDropdown disabled={ disabled } />
                    </Wrapper>
                    <Wrapper
                        id="publishing-container"
                        align="right"
                    >
                        <ProjectLinksDropdown publish disabled={ disabled } />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
    );
});
