import { Analytics }                                         from '@geenee/analytics';
import { AppState, HttpClient, MoleculeModel, type ProjectType
} from '@geenee/shared';
import axios                                                 from 'axios';
import { inject, injectable }                                from 'inversify';
import { action, makeObservable, observable, ObservableMap } from 'mobx';
import { BuilderState }                                      from "@geenee/builder/src/core/state/builder.state";
import { PROGRAM_CREATED, PROGRAM_DELETED }                       from '@geenee/builder/src/lib/constants';
import envConfig                                             from '@geenee/builder/src/lib/envConfig';
import getAlphanumericFromString                             from '@geenee/builder/src/lib/getAlphanumericFromString';
import Templates                                             from '@geenee/builder/src/lib/nodeTemplates';
import { container }                                         from '@geenee/builder/src/magellan/di/di';
import { AtomModel }                                         from '@geenee/builder/src/magellan/model/atom.model';

export const getPreviewQuery = (
    children: AtomModel[] = [],
    trackerUrl = '',
    moleculeWithSceneStats?: MoleculeModel
) => JSON.stringify({
    children:        children.map((el) => el.toServerData()),
    trackerUrl,
    wholeSceneStats: [
        moleculeWithSceneStats?.position,
        moleculeWithSceneStats?.rotation,
        moleculeWithSceneStats?.scale
    ]
});

@injectable()
export class MagellanState extends AppState {
    @observable isBasicButtonFormOpened = false;
    @observable isSplashScreenEditing = false;
    @observable $parent!: BuilderState;
    @observable isInitialised = false;
    // key is a query of json dependencies
    @observable modelPreview = new ObservableMap<
        string,
        { img?: string; isLoading: boolean }
    >();

    // @observable reservedPrograms: ObservableMap<
    //     string,
    //     ProgramModel
    // > = new ObservableMap<string, ProgramModel>();

    @inject('<HttpClient>')
        httpClient!: HttpClient;

    @observable lastUpdate: number = Date.now();

    @action setLastUpdate() {
        this.lastUpdate = Date.now();
    }

    constructor() {
        super();
        // this.magellanType = 'full';

        makeObservable(this);
    }

    setNewProgramTitle(schema: Partial<ProjectType>) {
        const existingTitle = schema.title;
        if (existingTitle) {
            let counter = 1;
            while (1) {
                schema.title = `${ existingTitle } ${ counter }`;
                if (
                    Array.from(this.projectsRegistry.values()).find(
                        (el) => el.title === schema.title
                    )
                ) {
                    counter += 1;
                } else {
                    schema.mapped_domain = getAlphanumericFromString(
                        schema.title
                    );
                    break;
                }
            }
        }
    }

    @action
    async createProject(projectSchema: Partial<ProjectType> = {}) {
        try {
            const programs = Array.from(this.projectsRegistry.values()).map(
                (el) => el.options.order || 0
            );
            const order = programs.length ? Math.max(...programs) : 0;
            const composedSchema = {
                ...Templates.PROJECT,
                ...projectSchema,
                options: {
                    ...Templates.PROJECT.options,
                    ...projectSchema.options,
                    order: order + 1
                }
            };
            this.setNewProgramTitle(composedSchema);

            const analytics: Analytics = container.get('<Analytics>');

            const { data: { data } } = await this.httpClient.post(
                `${ envConfig.API_URL }/api/v0/programs`,
                { program: composedSchema }
            );

            const { title, id } = data;

            analytics.track(PROGRAM_CREATED, { id, title, order });

            composedSchema.id = id;

            const projectModel = await this.projectFactory.create(
                composedSchema,
                this
            );
            this.projectsRegistry.set(projectModel.id, projectModel);
            return projectModel;
        } catch (e) {
            console.error('Create Program error: ', e);
            return new Error('Create Program error');
        }
    }

    @action
    async deleteChild(childId: string) {
        try {
            const analytics: Analytics = container.get('<Analytics>');

            await this.httpClient.delete(
                `${ envConfig.API_URL }/api/v0/programs/${ childId }`
            );

            analytics.track(PROGRAM_DELETED, { id: childId });

            this.projectsRegistry.delete(childId);
            this.setLastUpdate();
        } catch (e) {
            console.error('Delete Program error: ', e);
        }
    }
}
