import { createGlobalStyle } from 'styled-components';
import { normalize }         from 'styled-normalize';
import fontFaceDeclarations  from './fontFaceDeclarations';
import { DefaultThemeV2 }    from './themeV2';

const GlobalStyles = createGlobalStyle<{ theme: DefaultThemeV2 }>`
  ${ normalize }

  ${ fontFaceDeclarations }

  :root {
    --one-ui-dropdown-z-index: 1000;
  }

  html {
    box-sizing: border-box;
    font-family: 'Gordita', sans-serif !important;
    min-width: fit-content;
    height: 100%;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    background: ${ ({ theme: { colors } }) => colors.bodyBackground };
    background-attachment: fixed;
    font-family: 'Gordita', sans-serif;
    height: 100%;

    .p-component {
      font-family: 'Gordita', "Open Sans", "Helvetica Neue", sans-serif;
    }
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  canvas {
    outline: none;
  }

  .activeTourRoot {
    visibility: visible;
    box-shadow: 0 0 0px 2px ${ ({ theme: { secondaryColors } }) => secondaryColors.green20 } !important;

    &#wizard_wrapper {
      border-radius: 10px;
    }

    &#phone_container {
      border-radius: 40px;
    }

    &#nav_tree {
      border-radius: 5px;
    }

    &#preview-container {
      position: relative;
      box-shadow: none !important;

      &::after {
          content: '';
          position: absolute;
          top: -5px;
          left: -166px;
          width: 304px;
          height: 452px;
          border-radius: 10px;
          z-index: 1;
          box-shadow: 0 0 0px 2px
              ${ ({ theme: { secondaryColors } }) => secondaryColors.green20 } !important;
      }
    }

    &#publishing-container {
      position: relative;
      box-shadow: none !important;

      &::after {
          content: '';
          position: absolute;
          top: -5px;
          left: -136px;
          width: 304px;
          height: 452px;
          border-radius: 10px;
          z-index: 1;
          box-shadow: 0 0 0px 2px
              ${ ({ theme: { secondaryColors } }) => secondaryColors.green20 } !important;
      }
    }
  }

  .p-menu {
    &&& {
      &.p-menu-overlay {
        margin-top: 19px;
        border-radius: 8px;
        padding: 9px 0;

        &.main-header-top-menu {
          left: unset !important;
          top: 0px !important;
          position: absolute;
          right: 0 !important;
        }
      }

      .p-menuitem-link {
        padding: 7px 14px;
      }


      .p-menuitem {
      font-family: 'Gordita', Arial, Helvetica, sans-serif;

      .p-menuitem-text {
        font-size: 12px;
      }

      &:hover {
        .p-menuitem-text {
          font-weight: bold;
        }
      }
    }
    }
  }

  .capitalize {
    text-transform: capitalize;
  }

  .underline {
    text-decoration: underline;
  }

  .global-modal {
    z-index: 5;
    align-items: center;

    &-prior {
      z-index: 5;
    }

    &.on-tour {
      pointer-events: none;
    }

    .one-ui-modal__content {
      overflow: unset;
      box-shadow: none;
      border-radius: 16px;
    }
  }

  .p-menuitem-icon.custom-icon {
    width: 20px;
    display: inline-flex;
    justify-content: center;

    &.progress-icon::before {
      content: url('/asset/images/svg/progress.svg');
    }
    &.delete-icon::before {
      content: url('/asset/images/svg/trashCan.svg');
    }
  }

  .main-app {
    height: 100%;
    width: 100%;
    position: relative
    //min-height: 850px;
  }

  .preview-pos {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-bottom: 40px;
  }
`;

// eslint-disable-next-line arca/no-default-export
export default GlobalStyles;
