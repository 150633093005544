export const sortList = [
    {
        value: '',
        label: 'Relevance'
    },
    {
        value: '+size',
        label: 'Small To Large'
    },
    {
        value: '-size',
        label: 'Large To Small'
    }
];

export const dateList = [
    { value: '', label: 'All Time' },
    { value: 'week', label: 'This Week' },
    { value: 'month', label: 'This Month' },
    { value: 'year', label: 'This Year' }
];

export const fileTypeList = [
    { value: '', label: 'All' },
    { value: '.glb', label: '.glb' },
    { value: '.jpg', label: '.jpg' },
    { value: '.png', label: '.png' },
    { value: '.mp3', label: '.mp3' },
    { value: '.mp4', label: '.mp4' }
];
