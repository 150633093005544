import React                          from 'react';
import { useInject }                  from '@geenee/shared';
import { InputTable, InputTableItem } from '@geenee/ui';
import { observer }                   from 'mobx-react';
import { AnalyticsRow }               from '@geenee/builder/src/components/WizardForm/FormType/ProgramDesignForm/components/AnalyticsRow';
import { ProgramBackgroundColorRow }  from '@geenee/builder/src/components/WizardForm/FormType/ProgramDesignForm/components/ProgramBackgroundColorRow';
import { ProgramBrandingBarRow }      from '@geenee/builder/src/components/WizardForm/FormType/ProgramDesignForm/components/ProgramBrandingBarRow';
import { useBuilderInject }           from '@geenee/builder/src/core/hook/use-builder-inject';
import { stripeState }                from '@geenee/builder/src/core/state/stripe.state';
import { ProjectModel }               from '@geenee/builder/src/magellan/model/project.model';
import { CustomCodeComponent }        from '@geenee/builder/src/module/custom-code';

type Color = import('react-color').Color;

export type colorPickerDataParams = {
    position: {
        x: string;
        y: string;
    };
    color: Color;
    initiator: EventTarget;
};

export const ProgramDesignForm =    observer(() => {
    const { AppState } = useInject();
    const { activeProject } = (AppState as unknown) as { activeProject: ProjectModel; };
    // @TODO: uncomment after fixing the URL parsing
    // const disabled = activeProject.nft_published;
    const disabled = false;
    const { getDIComponent } = useBuilderInject();
    const CountriesRow = getDIComponent('CountriesRow');

    if (!activeProject) return <></>;

    return (
        <>
            <InputTable>
                <ProgramBrandingBarRow
                    brandingBarColor={ activeProject.options.branding_bar_color }
                    disabled={ disabled }
                    activeProject={ activeProject }
                />
                <ProgramBackgroundColorRow
                    background_color={ activeProject.options.background_color }
                    disabled={ disabled }
                    activeProject={ activeProject }
                />
                { stripeState.isEnterprise || stripeState.isTeams ? <AnalyticsRow model={ activeProject } disabled={ disabled } /> : <></> }
                <InputTableItem label='Custom Code'>
                    <CustomCodeComponent
                        model={ activeProject }
                        // @ts-ignore
                        plan_title={ stripeState.plan_title }
                        disabled={ disabled }
                    />
                </InputTableItem>
                <CountriesRow
                    value={ activeProject.country }
                    disabled={ disabled }
                    activeProject={ activeProject }
                />
            </InputTable>
        </>
    );
});
