import React                                 from 'react';
import Plus                                  from '@geenee/builder/src/asset/icons/Plus';
import { AddButtonLabel, AdderButtonStyled } from './styles';

interface Props {
  onBtnClick: () => void;
  disabled?: boolean
}

export function AdderBtn({ onBtnClick, disabled }: Props) {
    return (
        <AdderButtonStyled
            disabled={ disabled }
            onClick={ () => onBtnClick() }
            id='add-new-element'
            $hideBtnText
            $width="42px"
            $height="42px"
            $borderRadius="8px 0px 0px 8px"
        >
            <Plus id="tree-plus-icon" fill="white" width="40px" height="40px" />
            <AddButtonLabel>NEW PROJECT</AddButtonLabel>
        </AdderButtonStyled>
    );
}
