import { Analytics } from '@geenee/analytics';
import {
    DEFAULT_BEVEL_OPTIONS,     DEFAULT_MATERIAL_OPTIONS,
    DEFAULT_TEXT_SIZE,
    TEXT3D_TYPE
}                                                                from '@geenee/geeclient-kit/src/lib/component/scene/components/Text3D/constants';
import { HttpClient, MoleculeModel as MoleculeModelShared } from '@geenee/shared';
import { AtomSchemaType }                                   from '@geenee/shared/type/atom.type';
import { MoleculeSchemaType }                               from '@geenee/shared/type/molecule.type';
import { Nullable }                                         from '@geenee/shared/type/shared.type';
import { iconMap }                                          from '@geenee/ui';
import axios                                                from 'axios';
import { injectable }                                       from 'inversify';
import {
    action, computed, makeObservable, ObservableMap, runInAction, toJS
} from 'mobx';
// eslint-disable-next-line
import { calculateInitialStats }                                 from '@geenee/builder/src/lib/calculateInitialStats';
import { ATOM_CREATED, ATOM_DELETED } from '@geenee/builder/src/lib/constants';
import envConfig                      from '@geenee/builder/src/lib/envConfig';
import nodeTemplates                  from '@geenee/builder/src/lib/nodeTemplates';
import { uploadAttachment }           from '@geenee/builder/src/lib/uploadAttachment';
import { container }                  from '@geenee/builder/src/magellan/di/di';
import { ProjectModel }               from '@geenee/builder/src/magellan/model/project.model';

@injectable()
export class MoleculeModel extends MoleculeModelShared implements MoleculeSchemaType {
    httpClient: HttpClient = container.get('<HttpClient>');
    analytics: Analytics = container.get('<Analytics>');

    constructor() {
        super();
        makeObservable(this);
    }

    @computed
    get parentProjectEditable(): Nullable<ProjectModel> {
        return this.parentProject as Nullable<ProjectModel>;
    }

    @action async createAtom(atomSchema: Partial<AtomSchemaType>) {
        try {
            const atoms = Array.from(this.atomsRegistry.values()).map((el) => el.options.order || 0);
            const order = atoms.length ? Math.max(...atoms) : 0;
            const composedSchema = {
                ...atomSchema,
                options: {
                    order: order + 1,
                    ...atomSchema.options
                },
                molecule_id: this.id,
                moleculeId:  this.id,
                assets:      undefined
            };

            const { data: { data } } = await this.httpClient.post(`${ envConfig.API_URL }/api/v0/atoms`, { atom: composedSchema });
            const { id, asset_ids, molecule_id, type } = data;

            this.analytics.track(ATOM_CREATED, { id, asset_ids, molecule_id, type });

            composedSchema.id = id;
            const atomModel = this.atomFactory.create({ ...composedSchema, assets: atomSchema.assets }, this);
            runInAction(() => {
                this.atomsRegistry.set(atomModel.id, atomModel);
            });
            this.parentProjectEditable?.saveData();
            return atomModel;
        } catch (e) {
            console.error('Error while creating atom: ', e);

            return new Error('Error while creating atom');
        }
    }

    @action async createAtomsBySchema(atomsSchemas: Record<string, any>[]) {
        const requests = atomsSchemas.map((atomSchema) => this.createAtom(atomSchema));
        await Promise.all(requests);
    }

    @action async createAtomsByMoleculeType() {
        switch (this.type) {
            case 'iframe': {
                this.createAtomsBySchema(nodeTemplates.IFRAME_ATOMS);
                break;
            }
            case 'video': {
                this.createAtomsBySchema(nodeTemplates.FULLSCREEN_VIDEO_ATOMS);
                break;
            }
            case 'overlay': {
                this.createAtomsBySchema(nodeTemplates.OVERLAY_ATOMS);
                break;
            }
            case 'custom-code': {
                this.createAtomsBySchema(nodeTemplates.CUSTOM_CODE_ATOMS);
                break;
            }
            // eslint-disable-next-line
            default: {}
        }
    }

    uploadModelAttachment = async (file: File, scaleToCamera = true) => {
        const stats = scaleToCamera ? await calculateInitialStats(file, this.options.scene_molecule_scale) : {};
        // const attachment = await attachmentModel.createWithPreview({
        const attachmentModel = await this.createAttachmentModel({
            file,
            ...stats
        }, uploadAttachment);
        return { attachmentModel, stats };
    };

    @action
    async replaceAtomWithFile(file: File, oldAtomId: string, atomSchema: Partial<AtomSchemaType>, scaleToCamera = true) {
        const oldAtom = this.atomsRegistry.get(oldAtomId);
        if (oldAtom) {
            const { order } = oldAtom.options;
            const newAtom = await this.addAtomWithAttachment(file, { ...atomSchema, options: { ...atomSchema.options, order } }, scaleToCamera);
            this.atomsRegistry.delete(oldAtomId);
            await this.deleteAtom(oldAtomId);
            return newAtom;
        }
    }

    @action
    addText3DAsset() {
        const atomSchema: Partial<AtomSchemaType> = {
            type:    'scene-actor',
            options: {
                type:                         TEXT3D_TYPE,
                atom_title:                   '3D Text',
                atom_text3d_body:             'Lorem ipsum',
                atom_text3d_font_size:        DEFAULT_TEXT_SIZE,
                atom_text3d_font_type:        '',
                atom_text3d_bevel_options:    DEFAULT_BEVEL_OPTIONS,
                atom_text3d_material_options: DEFAULT_MATERIAL_OPTIONS,
                atom_text3d_shader_texture:   '',
                scene_atom_source_icon:       'text3d'
            }
        };
        this.createAtom(atomSchema);
    }

    @action
    async addAtomWithAttachment(file: File, atomSchema: Partial<AtomSchemaType> = {}, scaleToCamera = true) {
        const { attachmentModel, stats } = await this.uploadModelAttachment(file, scaleToCamera);
        return this.createAtom({
            ...atomSchema,
            // @ts-ignore
            assets:    [ attachmentModel ],
            // @ts-ignore
            asset_ids: [ attachmentModel.id ],
            options:   { ...atomSchema.options, ...stats.options }
        });
    }

    @action
        setSceneAudioFromFile = async (file: File, sourceIcon?: keyof typeof iconMap) => {
            const attachmentModel = await this.createAttachmentModel({
                file,
                options: {
                    audio_name:        file.name,
                    audio_artists:     '',
                    audio_spotify_url: undefined
                }
            }, uploadAttachment);
            if (this.audioAtom) {
                this.audioAtom.assets = [ attachmentModel ];
                this.audioAtom.asset_ids = [ attachmentModel.id ];
                const newMap = new ObservableMap();
                newMap.set(attachmentModel.id, attachmentModel);
                this.audioAtom.assetsRegistry = newMap;
                this.audioAtom.saveData();
            } else {
                return this.createAtom({
                    type:      'scene-audio',
                    assets:    [ attachmentModel ],
                    asset_ids: [ attachmentModel.id ],
                    options:   { scene_atom_source_icon: sourceIcon }
                });
            }
        };

    @action
        setSceneAudioOptions = async ({ url, name, artists = '', id }: {url: string, name: string, artists?: string, id: string}) => {
            if (this.audioAtom && this.audioAtom.firstAsset) {
                return this.audioAtom.firstAsset.updateOptions({
                    audio_name:        name,
                    audio_artists:     artists,
                    audio_spotify_id:  id,
                    audio_spotify_url: url
                });
            }
            const attachmentModel = await this.createAttachmentModel({
                options: {
                    audio_name:        name,
                    audio_artists:     artists,
                    audio_spotify_id:  id,
                    audio_spotify_url: url
                }
            }, uploadAttachment);
            return this.createAtom({
                type:      'scene-audio',
                assets:    [ attachmentModel ],
                asset_ids: [ attachmentModel.id ],
                options:   { scene_atom_source_icon: 'spotify' }

            });
        };

    @action
        setSceneTrigger = async (file: File) => {
            const attachmentModel = await this.createAttachmentModel({ file }, uploadAttachment);
            if (this.sceneTriggerAtom) {
                this.sceneTriggerAtom.assets = [ attachmentModel ];
                this.sceneTriggerAtom.asset_ids = [ attachmentModel.id ];
                const newRegistry = new ObservableMap();
                newRegistry.set(attachmentModel.id, attachmentModel);
                this.sceneTriggerAtom.assetsRegistry = newRegistry;
                await this.sceneTriggerAtom.saveData();
                return this.sceneTriggerAtom;
            }
            return this.createAtom({
                type:      'scene-trigger',
                assets:    [ attachmentModel ],
                asset_ids: [ attachmentModel.id ]
            });
        };

    @action
        deleteAtom = async (atomId: string) => {
            try {
                await this.deleteChild(atomId);
                this.analytics.track(ATOM_DELETED, { id: atomId });
            } catch (e) {
                console.error('Error while deleting atom: ', e);
            }
        };

    @action
    async saveData(analyticData?: { eventName: string, properties: object }) {
        try {
            this.updateState(this);
            this.parentProjectEditable?.saveData();
            const molecule = toJS(this.toServerData());

            const { data } = await this.httpClient.put(
                `${ envConfig.API_URL }/api/v0/molecules/${ this.id }`,
                { molecule }
            );

            if (analyticData?.eventName && analyticData?.properties) {
                this.analytics.track(analyticData?.eventName, analyticData?.properties);
            }

            return data.data;
        } catch (e) {
            console.error('Error while updating molecule: ', e);

            return new Error('Error while updating molecule');
        }
    }

    toServerData() {
        return {
            ...this,
            $parent:               undefined,
            httpClient:            undefined,
            atoms:                 undefined,
            atomsRegistry:         undefined,
            parentProject:         undefined,
            parentProjectEditable: undefined,
            attachmentFactory:     undefined,
            atomFactory:           undefined,
            thumbnail:             undefined
        };
    }

    @action deleteChild(childId: string) {
        this.atomsRegistry.delete(childId);
        if (process.env.ENV_USE_MOCK_DATA) {
            axios.delete(`http://localhost:3000/atoms/${ childId }`);
        } else {
            this.httpClient.delete(`${ envConfig.API_URL }/api/v0/atoms/${ childId }`);
        }
        this.parentProjectEditable?.saveData();
    }

    @action
    async setThumbnailImage(file: File) {
        const newAttachment = await this.createAttachmentModel({ file }, uploadAttachment);
        if (newAttachment.id) {
            this.thumbnail = newAttachment;
            this.thumbnail_id = newAttachment.id;
            await this.saveData();
        }
    }
}
