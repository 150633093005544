import React, { useEffect,  useState } from 'react';
import { positionValues, Scrollbars }  from 'react-custom-scrollbars';
import { DndProvider }                 from 'react-dnd';
import { HTML5Backend }                from "react-dnd-html5-backend";
import { AssetCard }                   from "@geenee/builder/src/components/LibraryModalTemplates/components/AssetCard";
import { EmptySearchPage }             from "@geenee/builder/src/components/LibraryModalTemplates/components/EmptySearchPage";
import { LibraryModalTitle }           from "@geenee/builder/src/components/LibraryModalTemplates/components/LibraryModalTitle";
import { useBuilderInject }            from '@geenee/builder/src/core/hook/use-builder-inject';
import { TOAST_ERROR }                 from '@geenee/builder/src/lib/constants';
import { getFileFromUrl }              from '@geenee/builder/src/lib/getFileFromUrl';
import { humanFileSize }               from '@geenee/builder/src/lib/humanFileSizes';
import { GifLibraryApi }               from '@geenee/builder/src/module/gif-library/api';
import { UserInputRow }                from '@geenee/builder/src/module/gif-library/components/UserInputRow';
import { GifLibraryItemModel }         from '@geenee/builder/src/module/gif-library/models/GifLibraryItem.model';
import { LoadingSpinnerCentered }      from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { Wrapper }                     from '@geenee/ui';
import { observer }                    from 'mobx-react';

interface Props {
  onClose: () => void;
  onImport?: (value: File) => void;
}

const DEFAULT_QUERY = '';
const MAX_SCROLL_HEIGHT = 504;

export const GifLibrary = observer(({ onImport }: Props) => {
    const [ cache, setCache ] = useState<Record<string, GifLibraryItemModel[]>>({ [ DEFAULT_QUERY ]: [] });
    const [ isNextPageAvailable, setIsNextPageAvailable ] = useState(false);
    const [ query, setQuery ] = useState(DEFAULT_QUERY);
    const { BuilderState } = useBuilderInject();
    const [ nextPos, setNextPos ] = useState('');
    const [ scrollLoading, setScrollLoading ] = useState(false);

    const fetchItems = async (pos: string) => {
        try {
            const { next, results } = query ? await GifLibraryApi.getByQuery(query, pos) : await GifLibraryApi.getTrending(pos);
            setNextPos(next);
            setIsNextPageAvailable(!!next);
            setCache({ ...cache, [ query ]: [ ...cache[ query ], ...results ] });
            setScrollLoading(false);
        } catch (e: any) {
            console.error(e);
            BuilderState.toast = { severity: TOAST_ERROR, detail: 'Something went wrong', summary: e.message };
        }
    };

    const scrolled = (myDiv: positionValues) => {
        if (!myDiv) return;
        if (
            myDiv.scrollTop + MAX_SCROLL_HEIGHT >= (myDiv.scrollHeight - 10)
      && !scrollLoading
      && isNextPageAvailable
        ) {
            setScrollLoading(true);
            fetchItems(nextPos);
        }
    };

    useEffect(() => {
        fetchItems('');
    }, [ query ]);

    return (
        <DndProvider backend={ HTML5Backend }>
            <Wrapper maxWidth="928px" style={ { minWidth: '928px' } }>
                <Wrapper padding="md">
                    <LibraryModalTitle
                        title="Tenor GIF Library "
                        description="Add GIF assets right from Tenor library"
                        iconColor="shade-2"
                        icon="tenor"
                    />
                    <UserInputRow
                        query={ query }
                        handleQueryChange={ (value) => {
                            setCache({ ...cache, [ value ]: [] });
                            setQuery(value);
                        } }
                    />
                </Wrapper>
                <Wrapper
                    fullHeight
                >
                    <Scrollbars
                        autoHide
                        autoHideTimeout={ 500 }
                        autoHideDuration={ 200 }
                        style={ { height: `${ MAX_SCROLL_HEIGHT }px` } }
                        onUpdate={ scrolled }
                    >
                        <Wrapper
                            row
                            fullHeight
                            wrap
                            padding="lg"
                            style={ {
                                paddingTop: 0, paddingBottom: '8px', columnGap: 32, rowGap: 32, height: 'fit-content'
                            } }
                        >
                            { cache[ query ].map((el, index) => (
                                <AssetCard
                                    key={ index }
                                    index={ index }
                                    id={ el.id }
                                    descriptionData={ {
                                        Title: el.media[ 0 ].gif_transparent.url.split('/')?.pop()?.split('.').shift() || '',
                                        Size:  humanFileSize(el.media[ 0 ].gif_transparent.size, true)
                                    } }
                                    onClick={ async () => {
                                        const item = el.media[ 0 ].gif_transparent;
                                        if (item && onImport) {
                                            const file = await getFileFromUrl(item.url, item.url.split('/').pop());
                                            onImport(file);
                                        }
                                    } }
                                    url={ el.media[ 0 ].gif_transparent.url }
                                />
                            )) }
                            { !scrollLoading && !cache[ query ].length ? <EmptySearchPage /> : null }
                            { scrollLoading && (
                                <Wrapper style={ { height: '120px' } } fullWidth align='center' valign='center'>
                                    <LoadingSpinnerCentered />
                                </Wrapper>
                            ) }
                        </Wrapper>
                    </Scrollbars>
                </Wrapper>
            </Wrapper>
        </DndProvider>
    );
});
