import React, { FC, memo }                           from 'react';
import { cn }                                        from '../../util/bem';
import { MediaPropsType }                            from '../../util/externalClasses';
import { SizesUnionType, TextColorType, WeightType } from '../../util/global-props';
import { withMedia }                                 from '../../util/media-merge';
import './title.component.scss';

export type TitlePropsType = {
    children?: React.ReactNode | string;
    size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    sizeSm?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    align?: 'left' | 'center' | 'right';
    weight?: WeightType;
    color?: TextColorType;
    margin?: SizesUnionType;
    padding?: SizesUnionType;
    flex?: number | string;
    styles?: React.CSSProperties;
    className?: string
    hide?: MediaPropsType;
    lg?: TitlePropsType;
    md?: TitlePropsType;
    sm?: TitlePropsType;
    xsm?: TitlePropsType;
}

const className = cn('title');

export const Title: FC<TitlePropsType> = withMedia(memo((props) => (
    <div
        className={ className({
            size:    props.size,
            sizeSm:  props.sizeSm,
            align:   props.align,
            margin:  props.margin,
            padding: props.padding,
            color:   props.color,
            weight:  props.weight,
            flex:    props.flex
        }, null, props.className) }
        style={ { ...props.styles } }
    >
        { props.children }
    </div>
)));
