import React, { useState } from 'react';
import CopyIcon            from '@geenee/builder/src/asset/icons/CopyIcon';
import {
    ButtonStyled,
    CopyActionWrapper, DoneLabel, ShortUrlRowRoot
} from '@geenee/builder/src/components/ProjectsLinkDropdown/styles';
import { Wrapper } from '@geenee/ui';

export function ShortUrlRow({ shortUrl, publish }:{shortUrl: string, publish?: boolean}) {
    const [ copySuccess, setCopySuccess ] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shortUrl);
        setCopySuccess(true);

        setTimeout(() => {
            setCopySuccess(false);
        }, 3000);
    };
    return (
        <ShortUrlRowRoot>
            <CopyActionWrapper
                active={ copySuccess }
                publish={ publish }
            >
                <Wrapper row fullWidth valign="center">
                    <Wrapper>
                        <span style={ {
                            overflow:     'hidden',
                            whiteSpace:   'nowrap',
                            textOverflow: 'ellipsis',
                            width:        200
                        } }
                        >
                            { shortUrl.replace('https://', '') }

                        </span>
                    </Wrapper>
                    <Wrapper
                        align="right"
                        className="button"
                        valign="center"
                    >
                        { !copySuccess ? (
                            <ButtonStyled>
                                <CopyIcon
                                    onClick={ copyToClipboard }
                                    fill="black"
                                />
                            </ButtonStyled>
                        ) : <DoneLabel withoutMargin>copied!</DoneLabel> }
                    </Wrapper>
                </Wrapper>
            </CopyActionWrapper>
        </ShortUrlRowRoot>
    );
}
