import React from 'react';

// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '67',
    height = '67',
    onClick,
    id,
    fill = 'black'
}: SvgIconProps) {
    return (
        <svg
            fill='none'
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 67 67"
            xmlns="http://www.w3.org/2000/svg"
            shapeRendering='geometricPrecision'
        >
            <g filter="url(#filter0_d_next)">
                <circle cx="33.7998" cy="32.2608" r="31.2002" transform="rotate(90 33.7998 32.2608)" stroke={ fill } strokeWidth="2" />
                <path
                // eslint-disable-next-line max-len
                    d="M37.9503 38.3256C37.2737 39.0022 37.2737 40.0992 37.9503 40.7758C38.6269 41.4524 39.7239 41.4524 40.4005 40.7758L47.3306 33.8456C48.0072 33.169 48.0072 32.072 47.3306 31.3954L40.4005 24.4653C39.7239 23.7887 38.6269 23.7887 37.9503 24.4653C37.2737 25.1419 37.2737 26.2389 37.9503 26.9155L43.6554 32.6205L37.9503 38.3256Z"
                    fill={ fill }
                />
                <path
                // eslint-disable-next-line max-len
                    d="M21.8502 34.3516L46.1057 34.3516C47.0626 34.3516 47.8383 33.5759 47.8383 32.619C47.8383 31.6622 47.0626 30.8865 46.1057 30.8865L21.8502 30.8865C20.8934 30.8865 20.1177 31.6622 20.1177 32.619C20.1177 33.5759 20.8934 34.3516 21.8502 34.3516Z"
                    fill={ fill }
                />
            </g>
            <defs>
                <filter id="filter0_d_next" x="0" y="0.0605469" width="67" height="67" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.516667 0 0 0 0 0.516667 0 0 0 0 0.516667 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <clipPath id="clip0_mext">
                    <rect width="65" height="65" fill="white" transform="translate(66 0.0605469) rotate(90)" />
                </clipPath>
            </defs>
        </svg>
    );
}
