import React                          from "react";
import { Radio, RadioGroup, Wrapper } from '@geenee/ui';
import { getError }                   from "./utils";

function RadioWidget(props) {
    const {
        id, required, schema, value, onChange, rawErrors, options
    } = props;
    return (
        <Wrapper>
            <RadioGroup
                margin="xs"
                disabled={ schema.disabled }
                label={ schema.title }
                value={ value }
                onChange={ onChange }
                space={ false }
            >
                { options.enumOptions.map((item, key) => (
                    <Radio margin="md" key={ key } value={ item.value } label={ item.label } />
                )) }
            </RadioGroup>
            { /* { getError(rawErrors) } */ }
        </Wrapper>
    );
}

export default RadioWidget;
