import { CommandType } from '@geenee/shared/src/commander/types';
import { AtomModel }   from "@geenee/shared/src/magellan/atom/model/atom.model";

export class RenameCommand implements CommandType {
    receiver: any;
    entity: AtomModel;
    name: string;
    prevName?: string;

    constructor(receiver: any, entity: AtomModel | any, name: string) {
        this.receiver = receiver;
        this.entity = entity;
        this.name = name;
    }

    execute = () => {
        this.prevName = this.entity.options.audio_name || this.entity.options.atom_title;
        this.receiver.renameModel(this.entity, this.name);
    };

    revert = () => {
        if (this.prevName) {
            this.receiver.renameModel(this.entity, this.prevName);
        }
    };
}
