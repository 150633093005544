import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal }                                     from 'react-dom';
import ReactQuill                                           from 'react-quill';
import Quill                                                from 'quill/dist/quill.js';
import { ColorPicker }                                      from '@geenee/builder/src/components/ColorPicker/ColorPicker';
import { Container }                                        from './styles';
import 'react-quill/dist/quill.snow.css';

const letterKeys = [ 'color', 'bold', 'italic', 'underline' ];

type RichTextEditorProps = {
    value: string | undefined;
    activeEditor: boolean;
    onEditorChange?: (content: string) => void;
    id: string;
    focus?: boolean;
    blur?: boolean;
    onBlur?: (
      // @ts-ignore
      previousRange: Quill.RangeStatic,
      // @ts-ignore
      source: Quill.Sources,
      editor: any
    ) => void;
    hiddenKeys?: ('link'| 'bullet' | 'ord' | 'underline' | 'italic' | 'bold' | 'color')[]
    disabled?: boolean;
};

const RichTextEditor = (props: RichTextEditorProps) => {
    const {
        value, activeEditor, onEditorChange, id = 'noid', focus
    } = props;
    const [ portalTarget, setPortalTarget ] = useState<null | Element>(
        null
    );
    const hiddenKeysSet = new Set(props.hiddenKeys || []);

    const hiddenLettersKeys = props.hiddenKeys ? props.hiddenKeys.filter((item) => letterKeys.includes((item))) : [];
    const showFirstSeparator = hiddenLettersKeys.length !== letterKeys.length;

    const [ quillRef, setRef ] = useState<ReactQuill | null>(null);

    // NOTE - Get DOM element to pass it down to the RTE component
    useEffect(() => {
        const selector = '#toolbar-wrapper';
        const node = document.querySelector(selector);
        // Wait until DOM will be built and element will be selected
        if (portalTarget !== node) {
            setPortalTarget(node);
        }
    }, [ activeEditor ]);

    useEffect(() => {
        const Link = Quill.import('formats/link');
        const builtInFunc = Link.sanitize;
        Link.sanitize = function customSanitizeLinkInput(linkValueInput) {
            let val = linkValueInput;

            // do nothing, since this implies user's already using a custom protocol
            if (!/^\w+:/.test(val)) {
                val = `https://${ val }`;
            }
            // else if (!/^https?:/.test(val)) val = `http:${ val }`;

            return builtInFunc.call(this, val); // retain the built-in logic
        };
    }, []);

    // Add fonts to whitelist
    const Font = Quill.import('formats/font');
    Font.whitelist = [ 'gordita', 'euclid-circular-a', 'open-sans', 'arvo', 'lato', 'karla', 'montserrat' ];
    Quill.register(Font, true);

    // Add font sizes to whitelist
    const Size = Quill.import('formats/size');
    Size.whitelist = [ '1vw', '1_5vw', '2vw', '2_5vw', '3vw', '3_5vw', '4vw', '4_5vw', '5vw', '5_5vw', '6vw' ];
    Quill.register(Size, true);

    const onColorChange = (color: string) => {
        const editor = quillRef?.getEditor();
        editor!.format('color', color);
    };
    const formats = [
        'font',
        'size',
        'color',
        'bold',
        'italic',
        'underline',
        'align',
        'list',
        'bullet',
        'link'
    ];

    // Toolbar functionality breaks if code below is imported from
    // outside of this component
    function EditorToolbar() {
        if (!portalTarget) return <div id={ `rte-toolbar-${ id }` } style={ { display: 'none' } } />;
        const toolbar = (
            <div id={ `rte-toolbar-${ id }` }>
                <div className="ql-first-row">
                    <div className="ql-formats">
                        <select className="ql-font" defaultValue="gordita">
                            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                            <option value="gordita" />
                            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                            <option value="euclid-circular-a" />
                            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                            <option value="open-sans" />
                            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                            <option value="arvo" />
                            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                            <option value="lato" />
                            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                            <option value="karla" />
                            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                            <option value="montserrat" />
                        </select>
                    </div>
                    <div className="ql-formats">
                        <select className="ql-size" defaultValue="13">
                            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                            <option value="1vw" />
                            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                            <option value="1_5vw" />
                            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                            <option value="2vw" />
                            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                            <option value="2_5vw" />
                            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                            <option value="3vw" />
                            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                            <option value="3_5vw" />
                            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                            <option value="4vw" />
                            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                            <option value="4_5vw" />
                            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                            <option value="5vw" />
                            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                            <option value="5_5vw" />
                            { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                            <option value="6vw" />
                        </select>
                    </div>
                </div>
                <div className="ql-second-row">

                    <>
                        { !hiddenKeysSet.has('color') && (
                            <div className="ql-formats" id="toolbar-color-picker-button">
                                <ColorPicker
                                    color="white"
                                    onColorChange={ onColorChange }
                                >
                                    { /* eslint-disable-next-line react/button-has-type */ }
                                    <button className="ql-color" aria-label="Color" />
                                </ColorPicker>
                            </div>
                        ) }
                        { !hiddenKeysSet.has('bold') && (
                            <div className="ql-formats">
                                { /* eslint-disable-next-line react/button-has-type */ }
                                <button className="ql-bold" aria-label="Bold" />
                            </div>
                        ) }
                        { !hiddenKeysSet.has('italic') && (
                            <div className="ql-formats">
                                { /* eslint-disable-next-line react/button-has-type */ }
                                <button className="ql-italic" aria-label="Italic" />
                            </div>
                        ) }
                        { !hiddenKeysSet.has('underline') && (
                            <div className="ql-formats">
                                { /* eslint-disable-next-line react/button-has-type */ }
                                <button className="ql-underline" aria-label="Underline" />
                            </div>
                        ) }
                    </>
                    { showFirstSeparator ? <div className="ql-separator" /> : <></> }
                    <div className="ql-formats">
                        { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                        <select className="ql-align" />
                    </div>
                    { (!hiddenKeysSet.has('ord') || !hiddenKeysSet.has('bullet')) && (
                        <>
                            <span className="ql-list" defaultValue="bullet">
                                { /* eslint-disable-next-line react/button-has-type */ }
                                { !hiddenKeysSet.has('ord') && <button className="ql-list" value="ordered" aria-label="Ord" /> }
                                { /* eslint-disable-next-line react/button-has-type */ }
                                { !hiddenKeysSet.has('bullet') && <button className="ql-list" value="bullet" aria-label="Bullet" /> }
                            </span>
                        </>
                    ) }

                    { !hiddenKeysSet.has('link') ? (
                        <>
                            <div className="ql-separator" />
                            <div className="ql-formats">
                                { /* eslint-disable-next-line react/button-has-type */ }
                                <button className="ql-link" aria-label="Link" />
                            </div>
                        </>
                    ) : <></> }
                </div>
            </div>
        );

        return createPortal(toolbar, portalTarget!);
    }

    const getPropperModules = () => {
        if (activeEditor) {
            return {
                toolbar: {
                    container: `#rte-toolbar-${ id }`,
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    handlers:  { color: () => {} }
                }
            };
        }
        return { toolbar: false };
    };

    // Save ref to state to register when it's available
    const onRefChange = useCallback((node) => {
        // ref value changed to node
        setRef(node); // e.g. change ref state to trigger re-render
        if (node === null) {
            // node is null, if DOM node of ref had been unmounted before
        } else {
            // ref value exists
        }
    }, []);

    // Set focus on RTE component if focus prop is set to true
    useEffect(() => {
        if (quillRef !== null) {
            if (focus && activeEditor) {
                quillRef?.focus();
            }
        }
    }, [ quillRef, activeEditor ]);

    // Turns off focus from RTE component, used to deactivate it during introduction Tour
    useEffect(() => {
        if (quillRef !== null) {
            // @ts-ignore
            // eslint-disable-next-line no-restricted-globals
            if (blur && activeEditor) {
                quillRef?.blur();
            }
        }
        // eslint-disable-next-line no-restricted-globals
    }, [ blur, quillRef ]);

    // NOTE - Quill RTE constantly emmits errors when its parent is updated
    // so this is a fix that prevents that behaviour
    const TextEditor = useMemo(
        () => (
            <Container>
                { activeEditor && <EditorToolbar /> }
                <ReactQuill
                    ref={ onRefChange }
                    theme="snow"
                    defaultValue={ value }
                    onChange={ onEditorChange }
                    modules={ getPropperModules() }
                    formats={ formats }
                    readOnly={ props.disabled }
                    onBlur={ props.onBlur }
                />
            </Container>
        ),
        [ activeEditor, portalTarget, quillRef ]
    );

    return TextEditor;
};
// eslint-disable-next-line arca/no-default-export
export default RichTextEditor;
