// @ts-nocheck
import React, { FC, useMemo }                            from 'react';
import { LinkAtomComponent }                             from "@geenee/geeclient-kit/src/lib/component/atom/link/link.view";
import { getPropsFromOwner }                             from "@geenee/geeclient-kit/src/lib/component/atom/navigation-item/util";
import { withAtomContext }                               from "@geenee/geeclient-kit/src/lib/hoc/withAtomContext";
import { withMoleculeContext }                           from "@geenee/geeclient-kit/src/lib/hoc/withMoleculeContext";
import { LinkAtomOptType }                               from '@geenee/geeclient-kit/src/lib/type/_atom-option-type';
import { StoryBookMoleculeSchemaType }                   from "@geenee/geeclient-kit/src/lib/type/section-molecule";
import { compose }                                       from "@geenee/geeclient-kit/src/lib/util/compose";
import { withProps }                                     from "@geenee/geeclient-kit/src/lib/util/withProps";
import { AtomSchemaType, NavigationListAtomOptionsType } from "@geenee/shared/type/atom.type";
import { observer }                                      from "mobx-react";
import { CallbackFunctionVariadic }                      from '../../../type/type';
import './navigation-item.atom.component.scss';

type NavigationItemAtomComponentPropsType = {
  isActive: boolean;
  onClick: CallbackFunctionVariadic;
  shouldScaleDown: boolean
  atomContext: AtomSchemaType;
};

const AtomPropsMap: Record<keyof NavigationListAtomOptionsType, keyof LinkAtomOptType> = {
    navigation_icon_geenee:           'link_icon_geenee',
    navigation_atom_color_bg:         'link_color_bg',
    navigation_atom_text:             'link_text',
    navigation_atom_text_font_weight: 'link_text_font_weight',
    navigation_atom_text_font_type:   'link_text_font_type',
    navigation_text_switch:           'link_text_switch',
    navigation_icon_switch:           'link_icon_switch'
};

export const NavigationItemView: FC<NavigationItemAtomComponentPropsType> = (props) => {
    const atomProps = useMemo(() => {
        const { options } = props.atomContext;
        const resultProps: Partial<LinkAtomOptType> = {};
        if (options) {
            Object.keys(options)
                .forEach((key) => {
                    resultProps[ AtomPropsMap[ key ] ] = props.atomContext.options[ key ];
                });
        }
        return resultProps;
    }, [ props.atomContext.options ]);

    return (
        <LinkAtomComponent
            color_auto_variant="harmonic"
            onClick={ props.onClick }
            link_padding="auto"
            { ...atomProps }
        />
    );
};

const enhance = compose(
    withAtomContext,
    withMoleculeContext,
    withProps(({
        atomContext,
        moleculeContext,
        ...extendProps
    }: {
      atomContext: AtomSchemaType<NavigationListAtomOptionsType>;
      moleculeContext: StoryBookMoleculeSchemaType<NavigationListAtomOptionsType>;
    }) => getPropsFromOwner({ ...atomContext.options, ...moleculeContext.options }, extendProps))
);

export const NavigationItemAtomComponent = enhance(observer(NavigationItemView));
