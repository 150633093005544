import { Ref }              from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ObservableMap }    from 'mobx';
import { ElementType }      from '@geenee/builder/src/components/Tree/TreeNode';
import { NODE_TYPE_FOLDER, REORDER_ITEM }     from '@geenee/builder/src/lib/constants';

export const useTreeNodeDrag = (
    node: ElementType,
    index: number,
    canMove: boolean,
    updateProgramsOrder: () => void,
    treeChildren?: ObservableMap<string, any>,
    // @ts-ignore
    containerRef: Ref<any>,
    onCollapsedChange: (id: string, value: boolean, type: string) => void,
    moveItem: (dragIndex: number, hoverIndex: number) => void,
    triggerTreeUpdate: () => void
) => {
    const {
        id, options, $parent, type, path
    } = node;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ { isDragging }, drag ] = useDrag({
        item: {
            type:      REORDER_ITEM,
            id,
            options,
            path,
            index,
            parent_id: node.type === NODE_TYPE_FOLDER ? undefined : node.$parent.id,
            $parent
        },
        canDrag: canMove,
        collect: (monitor) => ({ isDragging: monitor.isDragging() })
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ { isOver }, drop ] = useDrop({
        accept: [ REORDER_ITEM ],
        drop:   (item: any) => {
            if (!item.parent_id && node.type === 'project') {
                updateProgramsOrder();
                return;
            }
            if (!treeChildren) {
                return;
            }
            const isParentContainsElement = !!node?.children?.find((el) => el.id === item.id);
            if (isParentContainsElement) {
                // @ts-ignore
                const newListItemsArray = node.children.map(
                    // eslint-disable-next-line no-shadow
                    (item: any, i) => ({
                        id:       item.id,
                        order:    i,
                        oldOrder: item.order
                    })
                );
                newListItemsArray.forEach((el) => {
                    // eslint-disable-next-line no-shadow
                    const item = treeChildren.get(el.id);
                    if (item) {
                        item.options.order = el.order;
                        item.saveData();
                    }
                });
            }
        },
        hover: (item) => {
            // @ts-ignore
            if (!containerRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves

            if (
                dragIndex === hoverIndex
                || (node && node.parent_id) !== (item && item.parent_id)
            ) {
                return;
            }

            onCollapsedChange(node.id, true, type || '');
            moveItem(dragIndex, hoverIndex);
            // NOTE - we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            // eslint-disable-next-line no-param-reassign
            item.index = hoverIndex;
        },
        collect: (monitor) => ({ isOver: monitor.isOver() })
    });

    // @ts-ignore
    drag(drop(containerRef));

    const childMove = (dragIndex: number, hoverIndex: number) => {
        // @ts-ignore
        const dragCard = node.children[ dragIndex ];
        // const modifiedItemsSet = cloneDeep(node.children);
        const modifiedItemsSet = node.children;
        // @ts-ignore
        modifiedItemsSet.splice(dragIndex, 1);
        // @ts-ignore
        modifiedItemsSet.splice(hoverIndex, 0, dragCard);
        triggerTreeUpdate();
    };

    return { childMove };
};
