import { SceneSchemaType }                                 from '@geenee/shared/src/magellan/renderer/r3f-renderer/r3f.renderer';
import { SceneSectionTypeUnionType, SectionTypeUnionType } from '@geenee/shared/type/section.type';
import { v4 as uuid }                                      from 'uuid';

export const generateEmptySceneRenderer = (type: SectionTypeUnionType) => ({
    id:                    uuid(),
    scene_experience_type: type,
    projectId:             uuid(),
    attachments:           []
} as unknown as SceneSchemaType);
