import { BROWSER_MODE } from '../constants';
export const MIN_TIMESTEP = 0.001;
export const MAX_TIMESTEP = 1;

export const isIOS = () => {
  if(typeof navigator === "object") {
    const iOSiPadOS = /^iP/.test(navigator.platform)
        || /^Mac/.test(navigator.platform) && navigator.maxTouchPoints > 4;
    const isIOS     = BROWSER_MODE ? /iPad|iPhone|iPod/.test(navigator.platform) || iOSiPadOS : false;
    return isIOS;
  }
  return false
};

export const isAndroid = () => {
  return BROWSER_MODE ? (typeof navigator === "object") && navigator.userAgent.indexOf('Android') !== -1 : false;
};

export const isR7 = () => {
  const isR7 = BROWSER_MODE ? (typeof navigator === "object") && navigator.userAgent.indexOf('R7 Build') !== -1 : false;
  return isR7;
};

export const isLandscapeMode = () => {
  let result = false;

  if (BROWSER_MODE) {
    const rtn = window.orientation === 90 || window.orientation === -90;
    result = isR7() ? !rtn : rtn;
  }

  return result;
};

export const isTimestampDeltaValid = (timestampDeltaS) => {
  if (isNaN(timestampDeltaS)) {
    return false;
  }
  if (timestampDeltaS <= MIN_TIMESTEP) {
    return false;
  }
  if (timestampDeltaS > MAX_TIMESTEP) {
    return false;
  }
  return true;
};

export const isFirefoxAndroid = () => {
  const isFirefoxAndroid = BROWSER_MODE ? (typeof navigator === "object") && navigator.userAgent.indexOf('Firefox') !== -1 && navigator.userAgent.indexOf('Android') !== -1 : false;
  return isFirefoxAndroid;
};

export const getChromeVersion = () => {
  const match = BROWSER_MODE ? (typeof navigator === "object") && navigator.userAgent.match(/.*Chrome\/([0-9]+)/) : null;
  const value = match ? parseInt(match[1], 10) : null;
  return value;
};

const slicedToArray = (arr, i) => {
  let _arr = [];
  let _n = true;
  let _d = false;
  let _e = undefined;
  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);
      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i['return']) _i['return']();
    } finally {
      if (_d) throw _e;
    }
  }
  return _arr;
};

export const isChromeWithoutDeviceMotion = () => {
  let value = false;
  if (getChromeVersion() === 65) {
    const match = BROWSER_MODE ? (typeof navigator === "object") && navigator.userAgent.match(/.*Chrome\/([0-9\.]*)/) : null;

    if (match) {
      let _match$1$split = match[1].split('.'),
        _match$1$split2 = slicedToArray(_match$1$split, 4),
        major = _match$1$split2[0],
        minor = _match$1$split2[1],
        branch = _match$1$split2[2],
        build = _match$1$split2[3];
      value = parseInt(branch, 10) === 3325 && parseInt(build, 10) < 148;
    }
  }
  return value;
};

const getOriginFromUrl = (url) => {
  let domainIdx;
  let protoSepIdx = url.indexOf('://');
  if (protoSepIdx !== -1) {
    domainIdx = protoSepIdx + 3;
  } else {
    domainIdx = 0;
  }
  let domainEndIdx = url.indexOf('/', domainIdx);
  if (domainEndIdx === -1) {
    domainEndIdx = url.length;
  }
  return url.substring(0, domainEndIdx);
};

export const isInsideCrossOriginIFrame = () => {
  let result = false;

  if (BROWSER_MODE) {
    const isFramed = window.self !== window.top;
    const refOrigin = getOriginFromUrl(document.referrer);
    const thisOrigin = getOriginFromUrl(window.location.href);
    result = isFramed && refOrigin !== thisOrigin;
  }

  return result;
};
