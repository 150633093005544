import React, { FC, memo, ReactText }    from 'react';
import RSelect                           from 'react-select';
import { cn }                            from '../../util/bem';
import { FormSizesType, SizesUnionType } from '../../util/global-props';
import { CustomSelectContainer }         from './select-container.component';
import { CustomSingleValue }             from './select-single-value.component';
import { CustomValueContainer }          from './select-value-container.component';
import './select.component.scss';

export type OptionType = {
    label: string;
    value: ReactText | string;
}

export type SelectPropsType = {
    className?: string;
    id?: string;
    options: OptionType[];
    value?: OptionType | undefined;
    defaultValue?: OptionType;
    placeholder?: string;

    size?: FormSizesType;
    margin?: SizesUnionType;

    isSearchable?: boolean;
    isDisabled?: boolean;
    transparent?: boolean;
    menuIsOpen?: boolean;
    title?: string;
    onChange?: (item: OptionType) => void;
}

const className = cn('select');

export const Select: FC<SelectPropsType> = memo((props) => (
    <div
        className={
            className({
                margin:      props.margin,
                transparent: props.transparent
            }, null, props.className)
        }
    >
        { props.title && (
            <div className={ className('title') }>{ props.title }</div>
        ) }
        <div className={ className('wrapper', { size: props.size }) }>
            <RSelect
                id={ props.id }
                // @ts-ignore
                options={ props.options }
                value={ props.value }
                // TODO: WTF
                // @ts-ignore
                onChange={ props.onChange }
                defaultValue={ props.defaultValue }
                isSearchable={ props.isSearchable }
                isDisabled={ props.isDisabled }
                classNamePrefix={ className() }
                menuPortalTarget={ document.body }
                components={ {
                    ValueContainer:  CustomValueContainer,
                    SingleValue:     CustomSingleValue,
                    SelectContainer: CustomSelectContainer
                } }
                placeholder={ props.placeholder }
                styles={ {
                    menuPortal: (provided: any) => ({
                        ...provided,
                        zIndex: 9999
                    })
                } }
                { ...props }
            />
        </div>
    </div>
));

Select.defaultProps = {
    size:         'lg',
    isSearchable: false
};
