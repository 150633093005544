import React, {
    ChangeEvent,
    forwardRef,
    useEffect,
    useRef,
    useState
} from 'react';
import { compress }                                  from '@geenee/shared/src/util/ImageService';
import { Button, Description, Icon, Input, Wrapper } from '@geenee/ui';
import {
    ArAssetUploadBox,
    DescriptionText,
    ErrorRoot,
    UploadBox
} from './styles';

type UploaderProps = {
  handleUpload: (file: File | File[]) => void;
  description?: string;
  currentFile?: string | null | undefined;
  showImage?: boolean;
  accept?: string;

  onChange?: (event: any) => void;
  bind?: any;

  width?: string;
  height?: string;
  dimension?: [number, number];
  size?: number;
  name: string;
  hideUploadText?: boolean;
  nodeType?: string;
  fillImage?: boolean; // fill full space of the parent container in image preview
  multiple?: boolean;
  errorCallback?: (type: 'type' | 'size', fileSize?: number) => void,
  label?: string
  disabled?: boolean
  rootStyle?: React.CSSProperties,
  viewType?: 'primary' | 'secondary'
  skipTypeValidation?: boolean
};

const Uploader = forwardRef(({
    handleUpload,
    currentFile,
    accept,
    showImage = false,
    width,
    height,
    description,
    dimension,
    size, // in megabytes
    name,
    hideUploadText,
    nodeType,
    fillImage,
    multiple,
    errorCallback,
    label,
    disabled,
    rootStyle,
    viewType,
    skipTypeValidation,
    onChange
}: UploaderProps, _ref: React.ForwardedRef<HTMLInputElement>) => {
    const ref = useRef(_ref || null);

    const [ error, setError ] = useState<string | null>(null);

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError('');
            }, 7000);
        }
    }, [ error ]);

    const openFileDialog = () => {
        if (!disabled) {
            const elem = ref.current;

            elem?.click();
        }
    };

    const validateSize = (file: File): boolean => {
        const actualSize = file.size;
        if (actualSize > size! * 1048576) {
            errorCallback ? errorCallback('size', actualSize) : setError(`The file size should be no larger than ${ size }mb`);
            return false;
        }
        setError(null);
        return true;
    };

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const files = multiple ? event?.target?.files : [ event?.target?.files![ 0 ] ];

        if (!files?.length) return;

        // eslint-disable-next-line no-restricted-syntax
        for (let file of files) {
            const fileType = `.${ file.name.split('.').slice(-1)[ 0 ] }`;

            const rightType = skipTypeValidation || accept!.split(',')
                .map((e) => e.trim())
                .includes(fileType.toLowerCase());

            if (accept && !rightType) {
                errorCallback ? errorCallback('type') : setError('Wrong file type selected.');
                return;
            }

            if (file) {
                if (dimension) {
                    try {
                        // eslint-disable-next-line no-await-in-loop
                        const compressedFile = await compress(event.target, ...dimension);
                        if (compressedFile) {
                            file = compressedFile;
                        } else {
                            throw new Error('empty file');
                        }
                    } catch (e: any) {
                        setError('Error while file compression.');

                        return;
                    }
                }
                if (size) {
                    if (!validateSize(file)) {
                        return;
                    }
                }
                if (error) {
                    setError('');
                }
                if (!multiple) {
                    file && handleUpload(file);

                    if (onChange) {
                        onChange({
                            ...event,
                            target: { files: [ file ] }
                        });
                    }
                }
            }
        }

        if (multiple) {
            handleUpload(Array.from(files));

            if (onChange) {
                onChange({
                    ...event,
                    target: { files }
                });
            }
        }
    };
    const ActiveBox = nodeType === 'ar_asset' ? ArAssetUploadBox : UploadBox;

    return (
        <Wrapper
            row
            valign="center"
            fullWidth
            styles={ hideUploadText ? { width: 0 } : {} }
            style={ rootStyle }
        >
            { showImage && (
                <Wrapper
                    row
                    styles={ {
                        flex:  '0 1 auto',
                        width: 'initial'
                    } }
                >
                    <ActiveBox
                        className="upload-icon-wrapper"
                        onClick={ openFileDialog }
                        width={ width }
                        height={ height }
                        imgSrc={ (fillImage && currentFile) || '' }
                    >
                        { currentFile && !fillImage && <img src={ currentFile } alt="" /> }
                        { !currentFile && <Icon size={ 12 } color="shade-2" name="add" /> }
                    </ActiveBox>
                </Wrapper>
            ) }
            <Input
                multiple={ multiple }
                name={ name }
                ref={ ref }
                accept={ accept }
                type="file"
                id={ name }
                style={ { display: 'none' } }
                onChange={ handleChange }
                onClick={ (event) => {
                    // @ts-ignore
                    // eslint-disable-next-line no-param-reassign
                    event.target.value = null;
                } }
            />
            { !hideUploadText && (
                <Wrapper
                    fullWidth
                    styles={ {
                        flex:    '1 1 auto',
                        padding: showImage ? '0px 0px 0px 17px' : ''
                    } }
                >
                    { viewType === 'primary' ? (
                        <Description
                            style={ { textDecoration: 'underline' } }
                            color={ viewType === 'primary' ? 'white' : 'shade-3' }
                            onClick={ openFileDialog }
                        >
                            { label
                || (currentFile
                    ? multiple
                        ? `Replace ${ currentFile.length } files`
                        : 'Replace'
                    : multiple
                        ? 'Bulk Upload'
                        : 'Upload') }
                        </Description>
                    ) : (
                        <Button bold={ false } viewType="link" onClick={ openFileDialog }>
                            { label
                || (currentFile
                    ? multiple
                        ? `Replace ${ currentFile.length } files`
                        : 'Replace'
                    : multiple
                        ? 'Bulk Upload'
                        : 'Upload') }
                        </Button>
                    ) }
                    { description && (
                        <DescriptionText viewType={ viewType }>{ description }</DescriptionText>
                    ) }
                </Wrapper>
            ) }
            { error && (
                <Wrapper style={ { flex: '1 1 auto' } }>
                    <ErrorRoot>
                        <Wrapper fullWidth>
                            <Description
                                size="xs"
                                style={ { fontStyle: 'italic' } }
                                weight="bold"
                            >
                                Invalid file
                            </Description>
                            <Description size="xs" style={ { fontStyle: 'italic' } }>
                                { error }
                            </Description>
                        </Wrapper>
                    </ErrorRoot>
                </Wrapper>
            ) }
        </Wrapper>
    );
});

Uploader.defaultProps = { viewType: 'primary' };

// eslint-disable-next-line arca/no-default-export
export default Uploader;
