import React                      from 'react';
import { InputTableItem, Switch } from '@geenee/ui';
import { CAROUSEL_CONTENT_TYPES } from '@geenee/builder/src/lib/constants';

interface Props {
    section_scene_carousel_visibility: boolean;
    onUpdate: (value: boolean) => void;
    disabled?: boolean;
    type: string
}

export function SceneCarouselRow({ section_scene_carousel_visibility, onUpdate, disabled, type }: Props) {
    return CAROUSEL_CONTENT_TYPES.includes(type) ? (
        <InputTableItem label='Scene Carousel'>
            <Switch
                descriptionSize="xs"
                color="white"
                size='lg'
                checked={ section_scene_carousel_visibility }
                onChange={ onUpdate }
                disabled={ disabled }
            />
        </InputTableItem>
    ) : <></>;
}
