import React, { ComponentType, FC, useEffect, useState } from 'react';
import Viewer3dWrapper                                   from '@geenee/geeclient-kit/src/lib/component/scene/widget/viewer-3d-wrapper.widget';
import { useInject }                                     from '@geenee/shared/src/hook/use-inject.hook';
import { observer, Provider as MobxProvider }            from 'mobx-react';
import { v4 as uuid }                                    from 'uuid';
import { WasmWrapperWidget }                             from '../section/scene/wasm-wrapper.widget';
import mobxMap                                           from './mobx/map';

export type GeeneeSceneType = 'cms' | 'lib' | 'standAloneApp' | 'preview'

type PropsType = {
    type?: 'stamp-ar' | 'slam-ar'| 'viewer';
    mode: GeeneeSceneType
    blockingRender?: boolean
} & { [key: string]: any };

export const withMobx = (Component: ComponentType) => function () {
    return (
        <MobxProvider { ...mobxMap }>
            <Component />
        </MobxProvider>
    );
};

export const GeeneeScene: FC<PropsType> = observer((props) => {
    const { AppState } = useInject();
    const { activeSceneModel } = AppState;
    const { activeSection, firstAvailableSection } =  AppState;
    const sectionModel = activeSection || firstAvailableSection;
    const [ screenSizes, setScreenSizes ] = useState({ width: window.innerWidth, height: window.innerHeight });

    useEffect(() => {
        const scenes = [
            {
                id:                    uuid(),
                scene_experience_type: props.type === 'viewer' ? 'slam' : props.type,
                projectId:             uuid(),
                attachments:           []
            }
        ];
        AppState.init(scenes, props.mode !== 'lib');
    }, [ ]);

    useEffect(() => {
        const listener = () => {
            setScreenSizes({ width: window.innerWidth, height: window.innerHeight });
        };
        window.addEventListener('resize', listener);
        return () => window.removeEventListener('resize', listener);
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const preventScaling = () => {
        // prevent scaling on mobile devices
        document.addEventListener(
            'touchmove',
            (event) => {
                // @ts-ignore
                if (event.scale !== 1) {
                    event.preventDefault();
                }
            },
            { passive: false }
        );
    };

    const appStarted = () => {
        // preventScaling();
    };

    const EVENTS = { 'geenee-app-started': appStarted };

    useEffect(() => {
        // Listen to events
        // eslint-disable-next-line no-restricted-syntax
        for (const [ key, value ] of Object.entries(EVENTS)) {
            AppState.emitter.addListener(key, value);
        }

        return () => {
            // eslint-disable-next-line no-restricted-syntax
            for (const [ key, value ] of Object.entries(EVENTS)) {
                AppState.emitter.removeListener(key, value);
            }
        };
    }, []);

    if (activeSceneModel) {
        if (props.type === 'viewer') {
            return (
                <Viewer3dWrapper
                    key={ sectionModel?.activeMolecule?.id }
                    width={ screenSizes.width }
                    height={ screenSizes.height }
                    props={ props }
                    mode={ props.mode }
                    blockingRender={ props.blockingRender }
                >
                    { props.children }
                </Viewer3dWrapper>

            );
        }
        return (
            <>
                <WasmWrapperWidget
                    activeSceneModel={ activeSceneModel }
                    onReady={ () => {
                        appStarted();
                    } }
                    contentModel={ props.contentModel }
                    height={ screenSizes.height }
                    width={ screenSizes.width }
                    mode={ props.mode }
                />
            </>
        );
    }

    return null;
});

GeeneeScene.defaultProps = {
    type: 'slam',
    mode: 'lib'
};
