import React                                     from 'react';
import { Description, Wrapper }                  from '@geenee/ui';
import { ActivityContainer, HighlightContainer } from './styles';

interface Props {
    balance: number;
}

export function Activity({ balance }: Props) {
    return (
        <ActivityContainer>
            <Wrapper>
                { /* <Wrapper row valign='center' style={ { justifyContent: 'space-between' } } margin='lg'> */ }
                <Wrapper row valign='center' style={ { justifyContent: 'space-between' } }>
                    <Description size='sm' weight='bold' margin='sm'>
                        Balance
                    </Description>
                    <Wrapper style={ { width: '154px' } } align='center'>
                        <HighlightContainer>
                            <Description align='center' size='sm' weight='bold'>
                                { balance }
                                { ' ' }
                                ETH
                                { /* <br />
                                aprox $400 */ }
                            </Description>
                        </HighlightContainer>
                    </Wrapper>
                </Wrapper>
                { /* <Wrapper margin='xxl'>
                    <Description size='sm' weight='bold' margin='sm'>
                        Activity
                    </Description>
                    <ActivityWrapper>
                        <Wrapper>
                            { DUMMY_LOG.map((e, i) => (
                                <Wrapper row fullWidth key={ i } margin='sm'>
                                    <Description size='sm'>{ `${ (new Date(e.time)).getMonth() }/${ (new Date(e.time)).
                                    getDate() }/${ (new Date(e.time)).getFullYear().toString().slice(-2) }` }</Description>
                                    <Description size='sm'>{ e.action }</Description>
                                </Wrapper>
                            )) }
                        </Wrapper>
                    </ActivityWrapper>
                </Wrapper>
                <Wrapper>
                    <StyledButton
                        $dark
                        $padding="0"
                        $justify="center"
                        $width="180px"
                        $height="40px"
                        onClick={ () => { history.push('/getting-started/13'); } }
                        label="FAQ’S"
                    />
                </Wrapper> */ }
            </Wrapper>
        </ActivityContainer>
    );
}
