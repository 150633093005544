import { AttachmentModel }                        from '@geenee/shared/src/magellan/model/attachment.model';
import { injectable }                             from 'inversify';
import { action, makeAutoObservable, observable } from 'mobx';

@injectable()
export class EditAssetSceneState {
    @observable assetsLoaded = false;
    @observable sceneAsset: AttachmentModel | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setLoaded(value: boolean) {
        this.assetsLoaded = value;
    }

    @action setSceneAssets(value: AttachmentModel, trackerUrl?: string) {
        this.sceneAsset = Object.assign(value, { trackerUrl });
    }

    @action resetScene() {
        this.sceneAsset = null;
        this.assetsLoaded = false;
    }
}
// eslint-disable-next-line arca/no-default-export
export default new EditAssetSceneState();

export type EditAssetSceneStateType = EditAssetSceneState;
