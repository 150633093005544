import React                                    from 'react';
import { useInject }                            from '@geenee/shared';
import useComposerLocationParser                from '@geenee/shared/src/util/useComposerLocationParser';
import { useAssetSourceConfig }                 from '@geenee/builder/src/components/AssetsSourcePicker/hooks/useAssetSourceConfig';
import { useAssetSourceConfigBabylon }          from "@geenee/builder/src/components/AssetsSourcePickerBabylon/hooks/useAssetSourceConfigBabylon";
import { NFTOptIn }                             from '@geenee/builder/src/components/NFTOptIn';
import { MintYourProject }                      from '@geenee/builder/src/components/ProjectsLinkDropdown/components/MintYourProject';
import { useGenerateUrlHelper }                 from '@geenee/builder/src/components/ProjectsLinkDropdown/hooks/useGenerateUrlHelper';
import { SettingsButton, SettingsButtonLabels } from '@geenee/builder/src/components/SettingsButton';
import { AddFolder }                            from "@geenee/builder/src/components/Tree/AddFolder";
import { ShowIntroRow }                         from '@geenee/builder/src/components/WizardForm/FormType/CampaignDesignForm/components/ShowIntroRow';
import { CountriesRow }                         from '@geenee/builder/src/components/WizardForm/FormType/ProgramDesignForm/components/CountriesRow';
import envConfig, { EnvironmentHostnames }      from '@geenee/builder/src/lib/envConfig';
import { SectionWrapperComponent }              from '@geenee/builder/src/magellan/section/section-wrapper.component';
import { LoginPage }                            from '@geenee/builder/src/pages';
import { NFTConnect }                           from '@geenee/builder/src/pages/AllPlansPage/rows/NFTConnect';
import { SignUpPage }                           from '@geenee/builder/src/pages/NonAuth/SignUpPage/SignUpPage';

type DIComponents = {
    [name: string]: DIComponentsList
};

type DIComponentsList = {
    [key in DIComponentNames]: Function
}

export enum DIComponentNames {
    "CountriesRow" = "CountriesRow",
    "NftOptIn" = "NftOptIn",
    "ShowIntroRow" = "ShowIntroRow",
    "NFTConnect" = "NFTConnect",
    "MintYourProject" = "MintYourProject",
    "SectionWrapperComponent" = "SectionWrapperComponent",
    "useAssetSourceConfig" = "useAssetSourceConfig",
    "useAssetSourceConfigBabylon" = "useAssetSourceConfigBabylon",
    "LoginPage" = "LoginPage",
    "SignUpPage" = "SignUpPage",
    "SettingsButtonCreator" = "SettingsButtonCreator",
    "UseGenerateUrlHelper" = "UseGenerateUrlHelper",
    "AddFolder" = "AddFolder",
}

// 'CountriesRow' | 'NftOptIn' | ... |
type DIComponentsListOptions = keyof typeof DIComponentNames;

export type GetDIComponentFunc = (componentName: DIComponentsListOptions) => Function

const RedBullDIComponents = {
    [ DIComponentNames.CountriesRow ]:            CountriesRow,
    [ DIComponentNames.NftOptIn ]:                () => <></>,
    [ DIComponentNames.ShowIntroRow ]:            ShowIntroRow,
    [ DIComponentNames.AddFolder ]:               AddFolder,
    [ DIComponentNames.NFTConnect ]:              () => <></>,
    [ DIComponentNames.MintYourProject ]:         () => <></>,
    [ DIComponentNames.SectionWrapperComponent ]: SectionWrapperComponent,
    // ids were taked from useAssetSourceConfig
    [ DIComponentNames.useAssetSourceConfig ]:    (props) => useAssetSourceConfig(
        { ...props, sourcesFilter: (s) => [ "uploadCloud", "library", "snippet" ].includes(s.id) }
    ),
    [ DIComponentNames.useAssetSourceConfigBabylon ]: useAssetSourceConfigBabylon,
    [ DIComponentNames.LoginPage ]:                   () => <LoginPage hasSocialAuth={ false } />,
    [ DIComponentNames.SignUpPage ]:                  () => <SignUpPage hasSocialSignUp={ false } />,
    [ DIComponentNames.SettingsButtonCreator ]:       (closeSideBar) => (
        <SettingsButton
            closeSideBar={ closeSideBar }
            ownerItemsFilter={ (i) => i.label !== SettingsButtonLabels[ "matomo-analytics" ]
                && i.label !== SettingsButtonLabels[ "nft-dashboard" ]
                && i.label !== SettingsButtonLabels[ "google-analytics" ] }
        />
    ),
    // Ugly hack with hostname to be able use custom domain on prod and our domain on the sandbox
    // @TODO: Remove hostname check after finalizing the custom domains solution
    [ DIComponentNames.UseGenerateUrlHelper ]: window.location.hostname === EnvironmentHostnames.RedBull ? () => {
        const { AppState } = useInject();
        const mainProgram = AppState.activeProject;
        const { experienceId } = useComposerLocationParser();
        const getPublishLink = async (published?: boolean) => {
            // @ts-ignore
            const { published_name } = await mainProgram?.publish(published === undefined ? !mainProgram?.published : published);

            return `https://${ envConfig.PUBLISHING_DOMAIN_URL }/${ experienceId ? window.location.pathname.slice(1) : published_name }`;
        };

        const generatePublishLink = async (slug: string) => `https://${ envConfig.PUBLISHING_DOMAIN_URL }/${
            experienceId ? window.location.pathname.slice(1) : slug }`;

        return { generatePublishLink, getPublishLink };
    } : useGenerateUrlHelper
};
const BuildersDIComponents: DIComponents = {
    default: {
        [ DIComponentNames.CountriesRow ]:                () => <></>,
        [ DIComponentNames.NftOptIn ]:                    NFTOptIn,
        [ DIComponentNames.ShowIntroRow ]:                () => <></>,
        [ DIComponentNames.AddFolder ]:                   () => <></>,
        [ DIComponentNames.NFTConnect ]:                  NFTConnect,
        [ DIComponentNames.MintYourProject ]:             MintYourProject,
        [ DIComponentNames.SectionWrapperComponent ]:     SectionWrapperComponent,
        [ DIComponentNames.useAssetSourceConfig ]:        useAssetSourceConfig,
        [ DIComponentNames.useAssetSourceConfigBabylon ]: useAssetSourceConfigBabylon,
        [ DIComponentNames.LoginPage ]:                   () => <LoginPage />,
        [ DIComponentNames.SignUpPage ]:                  () => <SignUpPage />,
        [ DIComponentNames.SettingsButtonCreator ]:       (closeSideBar) => <SettingsButton closeSideBar={ closeSideBar } />,
        [ DIComponentNames.UseGenerateUrlHelper ]:        useGenerateUrlHelper
    },
    [ EnvironmentHostnames.RedBull ]:        RedBullDIComponents,
    [ EnvironmentHostnames.RedBullSandbox ]: RedBullDIComponents
};

const BuildersDIComponentsProxy = new Proxy(
    BuildersDIComponents[ window.location.hostname in BuildersDIComponents
        ? window.location.hostname
        : "default" ],
    {
        get: (obj: DIComponentsList, prop: DIComponentsListOptions) => {
            if (prop in obj) {
                return obj[ prop ];
            }
            throw new Error(`${ String(prop) } Component was not found in the DI Components list. Please check usage of getDIComponent function.`);
        }
    }
);

export const getDIComponent: GetDIComponentFunc = (componentName: DIComponentsListOptions) => BuildersDIComponentsProxy[ componentName ];
