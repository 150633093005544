import { useEffect, useRef, useState } from 'react';
import { Recorder }                    from "@geenee/armature";
import CameraRecordingLottie           from '@geenee/assets/lib/lottie/camera-recording-indicator.json';
import { useInject }                   from "@geenee/shared/src/hook/use-inject.hook";
import { SceneModelType }              from '@geenee/shared/src/magellan/renderer/r3f-renderer/r3f.renderer';
import { BABYLON_SECTION_TYPES }       from "@geenee/shared/src/util/constants";
import { addEffect }                   from '@react-three/fiber';

const isIOS = () => {
    if (typeof navigator === "object") {
        const iOSiPadOS = /^iP/.test(navigator.platform)
      // eslint-disable-next-line no-mixed-operators
      || /^Mac/.test(navigator.platform) && navigator.maxTouchPoints > 4;
        return /iPad|iPhone|iPod/.test(navigator.platform) || iOSiPadOS;
    }
    return false;
};

type Props = {
    takeSnapshot: () => void
    onSnapshotTaken: () => void
    activeSceneModel: SceneModelType
    snapShotTaken: boolean | null
}

const audioContextMap = new Map();
const AudioContext = window.AudioContext // Default
    // @ts-ignore
    || window.webkitAudioContext; // Safari and old versions of Chrome

export const useVideoController = (props: Props) => {
    const snapShotPreviewVideoRef = useRef<HTMLVideoElement>(null);
    const videoRecordingIndicatorContainer = useRef(null);

    const [ isVideoMode, setIsVideoMode ] = useState<boolean | null>(null);
    const recorder = useRef(null);
    const [ videoBlob, setVideoBlob ] = useState<Blob | null>();
    const [ isVideoRecordStarted, setIsVideoRecordStarted ] = useState(false);
    const [ videoRecordingAnimation, setVideoRecordingAnimation ] = useState<any>();

    const [ showVideoLoading, setShowVideoLoading ] = useState(false);
    const { AppState } = useInject();
    const { arSection } = AppState;
    const { sceneManager } = arSection;
    const renderer = sceneManager?.sceneRenderer;
    const captureFrame = () => {
        // @ts-ignore
        if (window.isVideoRecordStated) {
            // @ts-ignore
            const [ track ] = window.recordingVideoStream.getVideoTracks();
            track.requestFrame();
            props.activeSceneModel.videoCanvasContext.width = props.activeSceneModel.options.width;
            props.activeSceneModel.videoCanvasContext.height = props.activeSceneModel.options.height;
            const widthDiff = sceneManager.sceneRenderer.videoCanvas.clientWidth - sceneManager.sceneRenderer.canvas.clientWidth;
            const heightDiff = sceneManager.sceneRenderer.videoCanvas.clientHeight - sceneManager.sceneRenderer.canvas.clientHeight;
            props.activeSceneModel.videoCanvasContext.drawImage(
                sceneManager.sceneRenderer.videoCanvas,
                -widthDiff / 2,
                -heightDiff / 2,
                sceneManager.sceneRenderer.videoCanvas.clientWidth,
                sceneManager.sceneRenderer.videoCanvas.clientHeight
            );
            props.activeSceneModel.videoCanvasContext.drawImage(
                sceneManager.sceneRenderer.canvas,
                0,
                0,
                sceneManager.sceneRenderer.canvas.clientWidth,
                sceneManager.sceneRenderer.canvas.clientHeight
            );
        }
    };

    const loadLottie = async () => {
        // @ts-ignore
        setVideoRecordingAnimation(await lottie.loadAnimation({
            container:     videoRecordingIndicatorContainer.current,
            renderer:      'svg',
            loop:          true,
            autoplay:      false,
            animationData: CameraRecordingLottie
        }));
    };
    useEffect(() => {
        loadLottie();
    }, []);

    useEffect(() => {
        if (!BABYLON_SECTION_TYPES.includes(arSection.type)) {
            addEffect(captureFrame);
        }
    }, [ arSection.type ]);

    useEffect(() => {
        if (props.snapShotTaken) {
            if (props.activeSceneModel.audio) {
                props.activeSceneModel.audio.pause();
            }
        } else if (props.snapShotTaken === false) {
            if (props.activeSceneModel.audio) {
                if (snapShotPreviewVideoRef.current) {
                    snapShotPreviewVideoRef.current.src = '';
                }
                if (!props.activeSceneModel.audioMuted) {
                    props.activeSceneModel.audio.play();
                }
            }
        }
    }, [ props.snapShotTaken, props.activeSceneModel.audio ]);

    const setVideoStarted = (value: boolean) => {
        setIsVideoRecordStarted(value);
        if (value) {
            videoRecordingAnimation?.play();
        } else {
            videoRecordingAnimation?.stop();
        }
    };

    const startVideoRecordingTreeJs = () => {
        setVideoStarted(true);
        // @ts-ignore
        window.isVideoRecordStated = true;
        const { videoCanvas } = props.activeSceneModel;
        const stream = videoCanvas.captureStream(0);
        // @ts-ignore
        window.recordingVideoStream = stream;

        if (props.activeSceneModel.audio) {
            const videoOrAudioElement = props.activeSceneModel.audio;
            videoOrAudioElement.crossOrigin = 'anonymous';
            const audio = audioContextMap.get(videoOrAudioElement.src);
            if (audio) {
                stream.addTrack(audio);
            } else {
                // @ts-ignore
                const ctx = new AudioContext();
                const dest = ctx.createMediaStreamDestination();
                const sourceNode = ctx.createMediaElementSource(videoOrAudioElement);
                sourceNode.connect(dest);
                sourceNode.connect(ctx.destination);
                const audioTrack = dest.stream.getAudioTracks()[ 0 ];
                stream.addTrack(audioTrack);
                audioContextMap.set(videoOrAudioElement.src, audioTrack);
            }
        }

        const mediaRecorder = new MediaRecorder(stream);
        let chunks: BlobPart[] = [];
        mediaRecorder.ondataavailable = (e) => {
            chunks.push(e.data);
        };
        mediaRecorder.start();
        recorder.current = mediaRecorder;

        mediaRecorder.onstop = async () => {
            const blob = new Blob(chunks, { type: 'video/mp4' });
            setVideoBlob(blob);
            const videoURL = URL.createObjectURL(blob);
            if (snapShotPreviewVideoRef && snapShotPreviewVideoRef.current) {
                snapShotPreviewVideoRef.current.src = videoURL;
            }
            chunks = [];

            setShowVideoLoading(true);
            props.onSnapshotTaken();
            await snapShotPreviewVideoRef.current?.play();
            setShowVideoLoading(false);
            setVideoStarted(false);
        };
    };

    const startVideoRecordingBabylon = () => {
        recorder.current = new Recorder(renderer, `video/${ isIOS() ? "mp4" : "webm" }`, true, 'max', undefined, 10000000);
          // 8000000
          //the best quality
          // 8000000
        // );
        setVideoStarted(true);
        /* if (!window.rear) {
        countdownAnimation.play();
    } */

        // const timeout = setTimeout(() => {
        // setIsVideoTimeoutReleased(true);
        /* if (!window.rear) {
        pulseAnimation.play();
    } else {
        setTimeout(() => {
            pulseAnimation.play();
        }, 500);
    } */

        // setRecordingStartTimeout(null);
        // @ts-ignore
        window.isVideoRecordStated = true;
        videoRecordingAnimation?.play();
        recorder.current.start();
        // }, (VIDEO_RECORDING_DELAY * (+!window.rear)));

    // setRecordingStartTimeout(timeout);
    };

    const startVideoRecording = () => {
        if (BABYLON_SECTION_TYPES.includes(arSection.type)) {
            return startVideoRecordingBabylon();
        }
        return startVideoRecordingTreeJs();
    };

    const stopVideoRecordingTreeJs = () => {
        // @ts-ignore
        window.isVideoRecordStated = false;
        recorder.current.stop();
    };

    const stopVideoRecordingBabylon = async () => {
        setVideoStarted(false);
        // @ts-ignore
        window.isVideoRecordStated = false;
        // cameraAnimation?.setDirection(-1);
        // cameraAnimation?.play();

        // pulseAnimation.stop();

        // if (recordingStartTimeout) {
        //     clearTimeout(recordingStartTimeout);
        //     setRecordingStartTimeout(null);
        // } else {
        snapShotPreviewVideoRef.current.muted = false;
        setShowVideoLoading(true);
        props.onSnapshotTaken();
        const blob = await recorder.current.stop();
        if (!blob) return;
        setVideoBlob(blob);
        const videoURL = URL.createObjectURL(blob);
        snapShotPreviewVideoRef.current.src = videoURL;
        // setSnapShotTaken(true);
        // window.audio?.pause();
        await snapShotPreviewVideoRef.current?.play();
        setShowVideoLoading(false);
        // window.freeze = true;
        // }

        videoRecordingAnimation.stop();
        // countdownAnimation.stop();
        // setIsVideoTimeoutReleased(false);
        // clearTimeout(recordingStopTimeout);
        // setRecordingStopTimeout(null);
    };

    const stopVideoRecording = () => {
        if (BABYLON_SECTION_TYPES.includes(arSection.type)) {
            return stopVideoRecordingBabylon();
        }
        return stopVideoRecordingTreeJs();
    };
    const onCaptureClick = () => {
        if (isVideoMode) {
            if (!isVideoRecordStarted) {
                startVideoRecording();
            } else {
                // @ts-ignore
                stopVideoRecording();
            }
        } else {
            props.takeSnapshot();
        }
    };

    const onPhotoCaptureClick = () => {
        if (isVideoRecordStarted) {
            return;
        }
        if (!isVideoMode) {
            onCaptureClick();
        } else {
            setIsVideoMode(false);
        }
    };

    const onVideoCaptureClick = () => {
        if (isVideoMode) {
            onCaptureClick();
        } else {
            setIsVideoMode(true);
        }
    };

    return {
        isVideoMode,
        videoBlob,
        showVideoLoading,
        onPhotoCaptureClick,
        onVideoCaptureClick,
        snapShotPreviewVideoRef,
        videoRecordingIndicatorContainer,
        isVideoRecordStarted
    };
};
