import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Scrollbars }                                      from 'react-custom-scrollbars';
import cloneDeep                                           from 'lodash-es/cloneDeep';
import { observer }                                        from 'mobx-react';
import Move                                                from '@geenee/builder/src/asset/icons/Move';
import Trash                                               from '@geenee/builder/src/asset/icons/Trash';
import { DropWrapper, ListItemProps }                      from '@geenee/builder/src/components/DropWrapper';
import { IMG_FORMATS }                                     from "@geenee/builder/src/lib/constants";
import { AtomModel }                                       from '@geenee/builder/src/magellan/model/atom.model';
import { SectionModel }                                    from '@geenee/builder/src/magellan/model/section.model';
import SingleItem                                          from './SingleItem';
import {
    Container,
    MediaActions,
    MediaContainer,
    MediaWrapper
}                          from './styles';

type IdentifierParam = string;

type ImageGalleryProps = {
    activeSection: SectionModel;
    images: AtomModel[];
    handleDelete: (id: IdentifierParam) => void;
    dragAndDrop?: boolean;
    onDrop: (files: File[], monitor: any) => void;
};

export const ImageGallery = observer(({
    images,
    handleDelete,
    dragAndDrop,
    onDrop,
    activeSection
}: ImageGalleryProps) => {
    const [ focusedMedium, setFocusedMedium ] = useState<IdentifierParam | null>(
        null
    );
    const [ readyToMoveImage, setReadyToMoveImage ] = useState<string | null>(null);
    const [ listItems, setListItems ] = useState(images);
    const mediaRef = useRef<HTMLDivElement>(null);
    const actionsRef = useRef<HTMLDivElement>(null);
    const { activeMolecule } = activeSection;

    useEffect(
        () => {
            setListItems(images);
        },
        [ images ]
    );

    const checkClickOutside = (event: any) => {
        try {
            if (mediaRef?.current?.contains(event.target) || actionsRef?.current?.contains(event.target)) {
                return;
            }
            setFocusedMedium(null);
        } catch (e: any) {
            // @ts-ignore
            console.log(e.message);
        }
    };

    const handleFocus = (identifier: IdentifierParam) => {
        setFocusedMedium(identifier);
    };

    const handleImageDelete = (uniqeIdentifier: string) => {
        setReadyToMoveImage(null);
        handleDelete(uniqeIdentifier);
    };

    const handleMoveSelect = (id: string | null) => {
        if (readyToMoveImage === id) {
            setReadyToMoveImage(null);
        } else {
            setReadyToMoveImage(id);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', checkClickOutside, false);
        return () => document.removeEventListener('mousedown', checkClickOutside, false);
    });

    const handleReorder = (newListitemsArray: ListItemProps[]) => {
        newListitemsArray.forEach(({ id, order }) => {
            if (id) {
                const imageItem = activeMolecule.atomsRegistry.get(id);
                if (imageItem) {
                    imageItem.options.order = order;
                    (imageItem as AtomModel).saveData();
                }
            }
        });
    };

    const moveItem = useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = listItems[ dragIndex ];
            const modifiedItemsSet = cloneDeep(listItems);
            modifiedItemsSet.splice(dragIndex, 1);
            modifiedItemsSet.splice(hoverIndex, 0, dragCard);
            setListItems([ ...modifiedItemsSet ]);
        },
        [ listItems ]
    );

    const updateListItemsOrder = useCallback((newOrder, id) => {
        const newListItemsArray = cloneDeep(listItems).map(
            (item, i) => {
                if (!item.id) {
                    return {
                        order:    i,
                        oldOrder: item.options.order
                    };
                }
                return { id: item.id, order: i, oldOrder: item.options.order };
            }
        );
        // Update selected item references if needed
        let selectedId = id;
        if (id.includes('+')) {
            const strArray = id.split('+');
            strArray[ strArray.length - 1 ] = newOrder;
            selectedId = strArray.join('+');
        }
        setReadyToMoveImage(selectedId);
        setFocusedMedium(selectedId);

        handleReorder(newListItemsArray as any);
    }, [ listItems ]);

    return (
        <DropWrapper
            multiple
            clearMessageKey={ activeMolecule.id }
            style={ { height: '100%' } }
            onDrop={ onDrop }
            disabled={ !dragAndDrop }
            rootContainer={ Container }
            fileFormats={ IMG_FORMATS }
        >
            <Scrollbars
                autoHide
                autoHideTimeout={ 500 }
                autoHideDuration={ 200 }
                autoHeight
                autoHeightMax="100%"
            >
                <div style={ { padding: 1 } }>
                    <MediaContainer>
                        { listItems.map(
                            ((atomModel, idx) => {
                                const { options: { order }, id } = atomModel;

                                const uniqeIdentifier = id || `${ order }`;
                                const isFocused = focusedMedium === uniqeIdentifier;
                                const canMove = readyToMoveImage === uniqeIdentifier;

                                return (
                                    <MediaWrapper key={ uniqeIdentifier } ref={ mediaRef }>
                                        { (isFocused || canMove) && handleDelete && (
                                            <MediaActions ref={ actionsRef } moveSelected={ canMove }>
                                                <button
                                                    className='move-btn'
                                                    onClick={ () => {
                                                        handleMoveSelect(uniqeIdentifier);
                                                    } }
                                                >
                                                    <Move width="10" height="10" />
                                                </button>
                                                { // @ts-ignore
                                                    handleDelete && (
                                                        <button onClick={ () => handleImageDelete(id) }>
                                                            <Trash width="10" height="10" />
                                                        </button>
                                                    )
                                                }
                                            </MediaActions>
                                        ) }
                                        <SingleItem
                                            setFocus={ handleFocus }
                                            id={ uniqeIdentifier }
                                            index={ idx }
                                            isFocused={ isFocused }
                                            canMove={ canMove }
                                            url={ atomModel.assetUrl }
                                            order={ order || 0 }
                                            moveItem={ moveItem }
                                            updateListItemsOrder={ updateListItemsOrder }
                                        />
                                    </MediaWrapper>
                                );
                            })
                        ) }
                    </MediaContainer>
                </div>
            </Scrollbars>
        </DropWrapper>
    );
});
