import React from "react";

export const FaceSad = ({ children }: { children?: React.ReactNode }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.25 10C9.25 10.6904 8.69036 11.25 8 11.25C7.30964 11.25 6.75 10.6904 6.75 10C6.75 9.58579 6.41421 9.25 6 9.25C5.58579 9.25 5.25 9.58579 5.25 10C5.25 11.5188 6.48122 12.75 8 12.75C9.51878 12.75 10.75 11.5188 10.75 10C10.75 9.58579 10.4142 9.25 10 9.25C9.58579 9.25 9.25 9.58579 9.25 10Z"
            fill="#3E3B46"
            className="fill"
        />
        <path
            d="M16 11.25C16.6904 11.25 17.25 10.6904 17.25 10C17.25 9.58579 17.5858 9.25 18 9.25C18.4142 9.25 18.75 9.58579 18.75 10C18.75 11.5188 17.5188 12.75 16 12.75C14.4812 12.75 13.25 11.5188 13.25 10C13.25 9.58579 13.5858 9.25 14 9.25C14.4142 9.25 14.75 9.58579 14.75 10C14.75 10.6904 15.3096 11.25 16 11.25Z"
            fill="#3E3B46"
            className="fill"
        />
        <path
            d="M16.0658 16.4694C16.3586 16.7623 16.3586 17.2372 16.0658 17.5301C15.7729 17.823 15.298 17.823 15.0051 17.5301C13.3454 15.8704 10.6544 15.8704 8.99469 17.5301C8.70179 17.823 8.22692 17.823 7.93403 17.5301C7.64113 17.2372 7.64113 16.7623 7.93403 16.4694C10.1795 14.2239 13.8202 14.2239 16.0658 16.4694Z"
            fill="#3E3B46"
            className="fill"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z"
            fill="#3E3B46"
            className="fill"
        />
        { children }
    </svg>
);
