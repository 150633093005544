import { SegmentProvider }          from "@geenee/analytics";
import { DI as DIContext }          from "@geenee/geeclient-kit/src/lib/context/di.context";
import { CallbackFunctionVariadic } from "@geenee/geeclient-kit/src/lib/type/type";
import {
    AppState,
    ExperienceFactory,
    ExperienceModel,
    ProgramModel,
    ProjectFactory,
    ProjectModel,
    SceneFactory,
    SceneRenderer,
    ViewFactory,
    ViewModel
} from "@geenee/shared";
import { RootAnalytics }                                                from "@geenee/shared/src/analytics/analytics";
import { SceneCommander }                                               from "@geenee/shared/src/commander/scene-commander";
import { PopoverFactory }                                               from "@geenee/shared/src/general/factory/popover.factory";
import { AtomFactory }                                                  from "@geenee/shared/src/magellan/atom/factory/atom.factory";
import { AtomModel }                                                    from "@geenee/shared/src/magellan/atom/model/atom.model";
import { AssetFactory }                                                 from "@geenee/shared/src/magellan/factory/asset.factory";
import { AttachmentFactory }                                            from "@geenee/shared/src/magellan/factory/attachment.factory";
import { CompanySettings }                                              from "@geenee/shared/src/magellan/form/company-settings";
import { ForgotPassword }                                               from "@geenee/shared/src/magellan/form/forgot-password";
import { InviteUser }                                                   from "@geenee/shared/src/magellan/form/invite-user";
import { Login }                                                        from "@geenee/shared/src/magellan/form/login";
import { MemberSettings }                                               from "@geenee/shared/src/magellan/form/member-settings";
import { ProfileSettings }                                              from "@geenee/shared/src/magellan/form/profile-settings";
import { ResetPassword }                                                from "@geenee/shared/src/magellan/form/reset-password";
import { SignUp }                                                       from "@geenee/shared/src/magellan/form/sign-up";
import { AssetModel }                                                   from "@geenee/shared/src/magellan/model/asset.model";
import { AttachmentModel }                                              from "@geenee/shared/src/magellan/model/attachment.model";
import { PopoverModel }                                                 from "@geenee/shared/src/magellan/model/popover.model";
import { MoleculeFactory }                                              from "@geenee/shared/src/magellan/molecule/factory/molecule.factory";
import { MoleculeModel }                                                from "@geenee/shared/src/magellan/molecule/model/molecule.model";
import { SceneManager }                                                 from "@geenee/shared/src/magellan/renderer/babylon-renderer/client-scene-manager";
import { SectionFactory }                                               from "@geenee/shared/src/magellan/section/factory/section.factory";
import { SectionModel }                                                 from "@geenee/shared/src/magellan/section/model/section.model";
import { HttpClient }                                                   from "@geenee/shared/src/service/http.service";
import { factoryContainer }                                             from "@geenee/shared/src/util/factoryContainer";
import { Container, ContainerModule, decorate, injectable, interfaces } from "inversify";
import { action, computed, observable }                                 from "mobx";

export type DependencyEntityType = {
    [key: PropertyKey]: any
}

export type Dependency = {
    symbol: PropertyKey;
    use: DependencyEntityType;
} | DependencyEntityType

export type Dependencies = MetaModule<Dependency>

export const Symbols = { AppState: Symbol.for("<AppState>") };

decorate(injectable(), SegmentProvider);

export class DI {
    private _container: Container = new Container({ skipBaseClassChecks: true });
    @observable private _baseModule = new ContainerModule((bind) => {
        // root configuration
        bind<AppState>("<AppState>").to(AppState).inSingletonScope();
        bind<RootAnalytics>("<Analytics>").to(RootAnalytics).inSingletonScope();
        bind<SegmentProvider>("<AnalyticsProvider>").to(SegmentProvider).inSingletonScope();
        bind<SceneCommander>("<SceneCommander>").to(SceneCommander).inSingletonScope();

        // forms
        bind<Login>("<Login>").to(Login);
        bind<SignUp>("<SignUp>").to(SignUp);
        bind<CompanySettings>("<CompanySettings>").to(CompanySettings);
        bind<ForgotPassword>("<ForgotPassword>").to(ForgotPassword);
        bind<InviteUser>("<InviteUser>").to(InviteUser);
        bind<ResetPassword>("<ResetPassword>").to(ResetPassword);
        bind<ProfileSettings>("<ProfileSettings>").to(ProfileSettings);
        bind<MemberSettings>("<MemberSettings>").to(MemberSettings);
        // factory
        /* @ts-ignore */
        bind<SceneFactory>("<SceneFactory>").toFactory(factoryContainer(SceneFactory));
        bind<SectionFactory>("<SectionFactory>").toFactory(factoryContainer(SectionFactory));
        bind<MoleculeFactory>("<MoleculeFactory>").toFactory(factoryContainer(MoleculeFactory));
        bind<AtomFactory>("<AtomFactory>").toFactory(factoryContainer(AtomFactory));
        bind<ProjectFactory>("<ProjectFactory>").toFactory(factoryContainer(ProjectFactory));
        bind<ExperienceFactory>("<ExperienceFactory>").toFactory(factoryContainer(ExperienceFactory));
        bind<AttachmentFactory>("<AttachmentFactory>").toFactory(factoryContainer(AttachmentFactory));
        bind<ViewFactory>("<ViewFactory>").toFactory(factoryContainer(ViewFactory));
        bind<PopoverFactory>("<PopoverFactory>").toFactory(factoryContainer(PopoverFactory));
        bind<AssetFactory>("<AssetFactory>").toFactory(factoryContainer(AssetFactory));

        // model
        bind<ProgramModel>("<ProgramModel>").to(ProgramModel);
        bind<ProjectModel>("<ProjectModel>").to(ProjectModel);
        bind<ViewModel>("<ViewModel>").to(ViewModel);
        bind<ExperienceModel>("<ExperienceModel>").to(ExperienceModel);
        bind<SceneRenderer>("<SceneRenderer>").to(SceneRenderer);
        bind<AttachmentModel>("<AttachmentModel>").to(AttachmentModel);
        bind<SectionModel>("<SectionModel>").to(SectionModel);
        bind<MoleculeModel>("<MoleculeModel>").to(MoleculeModel);
        bind<AtomModel>("<AtomModel>").to(AtomModel);
        bind<HttpClient>("<HttpClient>").to(HttpClient);
        bind<SceneManager>("<SceneManager>").to(SceneManager);
        bind<AssetModel>("<AssetModel>").to(AssetModel);
        bind<PopoverModel>("<PopoverModel>").to(PopoverModel);

        bind("<DIContextGetter>").toDynamicValue(() => DIContext);
    });
    @observable private _applicationModule: ContainerModule | undefined = undefined;

    constructor() {
        this._container.load(this._baseModule);
    }

    @computed
    get container() {
        return this._container;
    }

    @computed
    get<T>(serviceIdentifier: interfaces.ServiceIdentifier<T>) {
        return this._container.get(serviceIdentifier);
    }

    @action
    unbind<T>(serviceIdentifier: interfaces.ServiceIdentifier<T>) {
        this._container.unbind(serviceIdentifier);
    }

    @action
    addApplicationDependencies(dependencies: Dependencies): void {
        this._bindDependency(dependencies);
    }

    private _createContainerModule(bindingFunction: CallbackFunctionVariadic): ContainerModule {
        return new ContainerModule(bindingFunction);
    }

    @action
    private _bindDependency(metaModule: MetaModule<Dependency>) {
        const {
            Factory = [],
            Model = [],
            Service = [],
            Component = [],
            State = [],
            Constants = []
        } = metaModule;
        const unbindIfBinded = (dep: Dependency) => {
            this._container.isBound(grabOverrideOrClassName(dep)) && this._container.unbind(grabOverrideOrClassName(dep));
        };
        const getKey = (n: string): string => `<${ n }>`;
        const grabOverrideOrClassName = (dep: Dependency) => {
            if (dep.use) {
                return dep.symbol;
            }

            // @ts-ignore
            return getKey(dep.name) || dep;
        };
        const grabOverrideOrClassInstance = (dep: Dependency) => {
            if (dep.use) {
                return dep.use;
            }
            return dep;
        };
        const bind = (dep: Dependency, _bind: CallbackFunctionVariadic) => {
            unbindIfBinded(dep);

            return _bind(grabOverrideOrClassName(dep));
        };

        this._applicationModule = this._createContainerModule((_bind) => {
            [ ...Factory ].forEach((dependency) => {
                bind(dependency, _bind)
                    .toFactory(factoryContainer(grabOverrideOrClassInstance(dependency)));
            });
            [ ...State ].forEach((dependency) => {
                bind(dependency, _bind)
                    .to(grabOverrideOrClassInstance(dependency)).inSingletonScope();
            });
            [ ...Model,
                ...Service ].forEach((dependency) => {
                bind(dependency, _bind)
                    .to(grabOverrideOrClassInstance(dependency));
            });
            [ ...Component ].forEach((dependency) => {
                bind(dependency, _bind)
                    .toDynamicValue(() => grabOverrideOrClassInstance(dependency));
            });
            [ ...Constants ].forEach((dependency) => {
                bind(dependency, _bind)
                    .toConstantValue(grabOverrideOrClassInstance(dependency));
            });
        });
    }

    @action
    loadApplicationModule() {
        if (this._applicationModule) {
            this.container.load(this._applicationModule);
        }
    }
}

export const rootDI = new DI();
