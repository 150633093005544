import React, { FC, memo } from 'react';
import { cn }              from '../../util/bem';
import './form-validation-error.component.scss';

type FormValidationErrorPropsType = {
    error?: string | React.ReactNode;
}

const className = cn('form-validation-error');

export const FormValidationError: FC<FormValidationErrorPropsType> = memo((props) => (
    <div className={ className('root') }>
        <div className={ className() }>
            { props.error }
        </div>
    </div>
));
