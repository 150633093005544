import React, { useEffect, useMemo, useState } from 'react';
import { positionValues, Scrollbars }          from "react-custom-scrollbars";
import { DndProvider }                         from 'react-dnd';
import { HTML5Backend }                        from "react-dnd-html5-backend";
import { LoadingSpinnerCentered }              from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { Button, cn, Divider, Wrapper }        from '@geenee/ui';
import { observer }                            from "mobx-react";
import { AssetCard }                           from "@geenee/builder/src/components/LibraryModalTemplates/components/AssetCard";
import { EmptySearchPage }                     from "@geenee/builder/src/components/LibraryModalTemplates/components/EmptySearchPage";
import { LibraryModalTitle }                   from "@geenee/builder/src/components/LibraryModalTemplates/components/LibraryModalTitle";
import { FiltersRow }                          from "@geenee/builder/src/components/SketchFabImporterModal/FiltersRow";
import { ImportedModal }                       from '@geenee/builder/src/components/SketchFabImporterModal/ImportedModal';
import { ModelInfoCard }                       from "@geenee/builder/src/components/SketchFabImporterModal/ModelInfoCard";
import { useBuilderInject }                    from "@geenee/builder/src/core/hook/use-builder-inject";
import { SketchFabModel }                      from '@geenee/builder/src/core/model/SketchFabModel.model';
import { sketchfabState }                      from '@geenee/builder/src/core/state/sketchfab.state';
import { humanFileSize }                       from "@geenee/builder/src/lib/humanFileSizes";
import { selectNearestWidthImage }             from "@geenee/builder/src/lib/selectNearestWidthImage";
import './styles.scss';

interface Props {
    onModelClick: (model: SketchFabModel) => Promise<boolean>;
    onClose: () => void
    onCloseSuccessModal: () => void;
}

const IMAGE_WIDTH = 256;
const MAX_SCROLL_HEIGHT = 504;

const className = cn('sketchfab-model');

export const SketchFabImporterModal = observer(({ onModelClick, onClose, onCloseSuccessModal }: Props) => {
    const { BuilderState } = useBuilderInject();
    const isGeeneeMode = BuilderState.sketchfabModelMode === 'geenee';

    useEffect(() => {
        sketchfabState.updateMode(BuilderState.sketchfabModelMode);
    }, [ BuilderState.sketchfabModelMode ]);

    const textCopies = useMemo(() => {
        if (isGeeneeMode) {
            return {
                title:       'Geenee Library',
                description: 'Add free 3D assets right from Geenee library',
                icon:        'geeneeLibrary'
            };
        }
        return {
            title:       'Sketchfab Library',
            description: 'Add free 3D assets right from Sketchfab library',
            icon:        'sketchfab'
        };
    }, [ isGeeneeMode ]);
    const [ selectedModel, setSelectedModel ] = useState<SketchFabModel | null>();
    const [ isModelImported, setIsModelImported ] = useState(false);
    const [ query, setQuery ] = useState(
        sketchfabState.lastQuery.query || (isGeeneeMode ? 'Geenee' : 'Low Poly')
    );
    const [ page, setPage ] = useState(0);
    const [ key, setKey ] = useState('');
    const sketchFabModels =        sketchfabState.fetchedQueryModels;
    const { isNextPageAvailable } = sketchfabState;
    const [ scrollLoading, setScrollLoading ] = useState(false);

    const activeData = useMemo(() => sketchFabModels.get(key), [ key ]);

    const scrolled = (myDiv: positionValues) => {
        if (!myDiv) return;
        if (
            myDiv.scrollTop + MAX_SCROLL_HEIGHT >= (myDiv.scrollHeight - 10)
      && !scrollLoading
      && isNextPageAvailable
        ) {
            setScrollLoading(true);
            setPage(page + 1);
        }
    };

    useEffect(() => {
        if (activeData) {
            setScrollLoading(false);
        } else {
            setScrollLoading(true);
        }
    }, [ JSON.stringify(activeData) ]);

    if (isModelImported && selectedModel) {
        return <ImportedModal onClose={ onCloseSuccessModal } model={ selectedModel } />;
    }

    return (
        <DndProvider backend={ HTML5Backend }>
            <Wrapper maxWidth="928px" style={ { minWidth: '928px' } }>
                <Wrapper padding="md">
                    <LibraryModalTitle
                        title={ textCopies.title }
                        description={ textCopies.description }
                        iconColor="shade-2"
                        icon={ textCopies.icon }
                    />
                    { selectedModel ? (
                        <Wrapper
                            radius="xxl"
                            padding="xs"
                            frame="solid-bg"
                            row
                            valign="center"
                            style={ { fontSize: '12px' } }
                        >
                            <Wrapper row align="space-between">
                                <Button
                                    size='qsm'
                                    viewType="white"
                                    iconMargin="xxs"
                                    align='left'
                                    radius="lg"
                                    uppercase
                                    onClick={ () => {
                                        setSelectedModel(null);
                                    } }
                                    icon="arrowLeft"
                                >
                                    Back to Library
                                </Button>
                                <Button
                                    size='sm'
                                    align='center'
                                    radius="lg"
                                    uppercase
                                    loading={ sketchfabState.isLoading }
                                    onClick={ async () => {
                                        const isImportSuccess = await onModelClick(selectedModel);
                                        if (isImportSuccess) {
                                            setIsModelImported(true);
                                        } else {
                                            onClose();
                                        }
                                    } }
                                >
                                    Import
                                </Button>
                            </Wrapper>
                        </Wrapper>
                    ) : (
                        <FiltersRow
                            query={ query }
                            modelsLoading={ scrollLoading }
                            handleQueryChange={ setQuery }
                            page={ page }
                            onKeyChanged={ setKey }
                            disableInput={ isGeeneeMode }
                        />
                    ) }
                </Wrapper>
                { !selectedModel ? (
                    <Wrapper
                        fullHeight
                    >
                        <Scrollbars
                            autoHide
                            autoHideTimeout={ 500 }
                            autoHideDuration={ 200 }
                            style={ { height: `${ MAX_SCROLL_HEIGHT }px` } }
                            onUpdate={ scrolled }
                        >
                            <Wrapper
                                row
                                fullHeight
                                wrap
                                padding="lg"
                                style={ { paddingTop: 0, paddingBottom: '8px' } }
                                className={ className('content') }
                            >
                                { activeData?.map((el, index) => (
                                    <AssetCard
                                        key={ index }
                                        index={ index }
                                        id={ el.uid }
                                        descriptionData={ {
                                            Title:         el.name,
                                            Size:          humanFileSize(el.archives.gltf.size, true),
                                            'Posted by':   el.user.username,
                                            'Upload date': new Date(el.publishedAt).toLocaleDateString()
                                        } }
                                        onClick={ () => setSelectedModel(el) }
                                        url={ selectNearestWidthImage(
                                            el.thumbnails.images,
                                            IMAGE_WIDTH
                                        ).url }
                                    />
                                )) }
                                { !scrollLoading && !activeData?.length ? <EmptySearchPage /> : null }
                                { scrollLoading && (
                                    <Wrapper style={ { height: '120px' } } fullWidth align='center' valign='center'>
                                        <LoadingSpinnerCentered />
                                    </Wrapper>
                                ) }
                            </Wrapper>
                        </Scrollbars>

                    </Wrapper>
                ) : (
                    <Wrapper row padding="md">
                        <iframe
                            className={ className('iframe') }
                            title={ selectedModel.name }
                            src={ selectedModel.embedUrl }
                            frameBorder="0"
                        />
                        <Divider transparent margin="lg" />
                        <ModelInfoCard model={ selectedModel } />
                    </Wrapper>
                ) }
            </Wrapper>
        </DndProvider>
    );
});
