import styled   from 'styled-components';
import WithIcon from '@geenee/builder/src/components/common/Button/WithIcon';

export const StyledLinkButton = styled(WithIcon)<({isMobile: boolean})>`
  padding:                16px !important;
  height:                 48px !important;
  width:                  48px !important;
  margin-left:            ${ ({ isMobile }) => (isMobile ? 'auto' : '10px') }
`;

export const GetIconElementWrapper = styled.div`
  margin-left: 16px;
`;
