import styled from 'styled-components';

export const AutocompleteWrapper = styled.div`
  width: 100%;
  
  .p-autocomplete-multiple-container {
    width: 100%;
    border-radius: 8px;
    min-height: 38px;
    border: 1px solid rgba(227, 227, 232, 0.5);
    padding-top: 8px !important;
    
    &.p-focus {
      border-color: #212121 !important;
      box-shadow: none !important;
    }
    .p-autocomplete-token {
      background: transparent !important;
      color: ${ ({ theme: { colors } }) => colors.black } !important;
      border: 1px solid rgba(227, 227, 232, 1);
      margin-bottom: 5px !important;
    }
  }

  .p-autocomplete-token-label {
    margin: 0 !important;
    padding-right: 16px !important;
  }
  
  .p-autocomplete-token-icon {
    width: 15px;
    height: 15px;
  }
`;

export const FlagWrapper = styled.span`
  margin-right: 8px;
  padding-top: 3px;
`;

export const SelectedItemWrapper = styled.span`
  position: relative;
  display: flex;
  align-items: baseline;
`;

export const CloseIconWrapper = styled.div`
  position: absolute;
  right: -19px;
  top: 1px;
  pointer-events: none;
`;
