import { CommandType }   from '@geenee/shared/src/commander/types';
import { AtomModel }     from "@geenee/shared/src/magellan/atom/model/atom.model";
import { MoleculeModel } from "@geenee/shared/src/magellan/molecule/model/molecule.model";

export class ShowHideCommand implements CommandType {
    receiver: any;
    entity: AtomModel | MoleculeModel;

    constructor(receiver: any, entity: AtomModel | MoleculeModel) {
        this.receiver = receiver;
        this.entity = entity;
    }

    execute = () => {
        this.receiver.showHideAtoms(this.entity);
    };

    revert = () => {
        this.receiver.showHideAtoms(this.entity);
    };
}
