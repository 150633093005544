import React              from "react";
import { Select }         from "../../select/select.component";
import { Wrapper }        from "../../wrapper/wrapper.component";
import AutocompleteWidget from "./AutocompleteWidget";
import { getError }       from "./utils";

// TODO: We can even add start and end adornments in the second phase
function SelectWidget(props) {
    const {
        id, required, schema, value, onChange, rawErrors, options
    } = props;

    if (schema.type === "array") {
    // return <MultiSelectWidget {...props} />;
        return <AutocompleteWidget { ...props } />;
    }

    // TODO: require, error
    return (
        <Wrapper>
            <Select
                id={ id }
                isDisabled={ schema.disabled }
                title={ schema.title }
                size='md'
                value={ options.enumOptions.find((item) => item.value === value) || "" }
                options={ options.enumOptions }
                margin="xs"
                // eslint-disable-next-line no-shadow
                onChange={ ({ value }) => onChange(value) }
            />
            { /* { getError(rawErrors) } */ }
        </Wrapper>
    );
}

export default SelectWidget;
