import { FontTypeExtensionType } from "@geenee/geeclient-kit/src/lib/type/_atom-option-type";
import {
    ColorExtensionType,
    ColorTypeExtensionType,
    ColorVariationExtensionType,
    FontLineHeightExtensionType, FontSizeExtensionType,
    FontUppercaseExtensionType,
    FontWeightExtensionType
} from "@geenee/geeclient-kit/src/lib/type/_extension-type";

export type HeaderAtomOptType = {
  header_text: string;
  header_text_font_size: FontSizeExtensionType;
  header_text_font_type?: FontTypeExtensionType<
    'xl' | 'l' | 'm' | 's' | 'custom'
    >;
  header_text_font_weight: FontWeightExtensionType;
  header_text_font_line_height: FontLineHeightExtensionType;
  header_text_font_uppercase: FontUppercaseExtensionType;
  header_text_color: ColorExtensionType;
  header_text_color_type: ColorTypeExtensionType;
  header_text_color_custom: string;
  header_text_color_alpha: number;
  header_text_color_variation: ColorVariationExtensionType;
  header_text_switch: boolean;
}

export const initProps: HeaderAtomOptType = {
    header_text:                  '',
    header_text_font_size:        30,
    header_text_font_weight:      400,
    header_text_font_line_height: 1.5,
    header_text_font_type:        'm',
    header_text_font_uppercase:   false,
    header_text_color:            'inherit',
    header_text_color_alpha:      1,
    header_text_color_type:       'auto',
    header_text_color_variation:  'text',
    header_text_color_custom:     '',
    header_text_switch:           true
};

export const getPropsFromOwner = (options: HeaderAtomOptType, extendProps: Object = {}) => ({
    header_text_switch:           options.header_text_switch,
    header_text_font_size:        options.header_text_font_size,
    header_text_font_weight:      options.header_text_font_weight,
    header_text_font_line_height: options.header_text_font_line_height,
    header_text_font_uppercase:   options.header_text_font_uppercase,
    header_text_font_type:        options.header_text_font_type,
    header_text_color:            options.header_text_color,
    header_text_color_custom:     options.header_text_color_custom,
    header_text_color_variation:  options.header_text_color_variation,
    header_text_color_type:       options.header_text_color_type,
    header_text_color_alpha:      options.header_text_color_alpha,
    header_text:                  options.header_text,
    ...extendProps
});
