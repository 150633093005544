import React                         from "react";
import type { Observable }           from "@babylonjs/core/Misc/observable";
import { Scene }                     from "@babylonjs/core/scene";
import { Color3LineComponent }       from "@geenee/geespector-ui-components/src/lines/color3LineComponent";
import { FloatLineComponent }        from "@geenee/geespector-ui-components/src/lines/floatLineComponent";
import { OptionsLineComponent }      from "@geenee/geespector-ui-components/src/lines/optionsLineComponent";
import type { LockObject }           from "@geenee/geespector-ui-components/src/tabs/propertyGrids/lockObject";
import type { PropertyChangedEvent } from "../../../propertyChangedEvent";

interface IFogPropertyGridComponentProps {
    scene: Scene;
    lockObject: LockObject;
    onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
}

const fogModeOptions = [
    { label: "None", value: Scene.FOGMODE_NONE },
    { label: "Linear", value: Scene.FOGMODE_LINEAR },
    { label: "Exp", value: Scene.FOGMODE_EXP },
    { label: "Exp2", value: Scene.FOGMODE_EXP2 }
];

export const FogPropertyGridComponent = (props: IFogPropertyGridComponentProps) => {
    const { scene } = props;
    const { fogMode: mode } = scene;

    return (
        <>
            <OptionsLineComponent
                label="Fog mode"
                options={ fogModeOptions }
                target={ scene }
                propertyName="fogMode"
                onPropertyChangedObservable={ props.onPropertyChangedObservable }
                // onSelect={ (value) => setState({ mode: value }) }
                border={ mode !== Scene.FOGMODE_NONE }
            />
            { mode !== Scene.FOGMODE_NONE && (
                <Color3LineComponent
                    label="Fog color"
                    target={ scene }
                    propertyName="fogColor"
                    onPropertyChangedObservable={ props.onPropertyChangedObservable }
                />
            ) }
            { (mode === Scene.FOGMODE_EXP || mode === Scene.FOGMODE_EXP2) && (
                <FloatLineComponent
                    lockObject={ props.lockObject }
                    label="Fog density"
                    target={ scene }
                    propertyName="fogDensity"
                    onPropertyChangedObservable={ props.onPropertyChangedObservable }
                />
            ) }
            { mode === Scene.FOGMODE_LINEAR && (
                <FloatLineComponent
                    lockObject={ props.lockObject }
                    label="Fog start"
                    target={ scene }
                    propertyName="fogStart"
                    onPropertyChangedObservable={ props.onPropertyChangedObservable }
                />
            ) }
            { mode === Scene.FOGMODE_LINEAR && (
                <FloatLineComponent
                    lockObject={ props.lockObject }
                    label="Fog end"
                    target={ scene }
                    propertyName="fogEnd"
                    onPropertyChangedObservable={ props.onPropertyChangedObservable }
                />
            ) }
        </>
    );
};
