import styled       from 'styled-components';
import StyledButton from '@geenee/builder/src/components/common/Button';

export const AddButtonLabel = styled.span`
    content: 'NEW PROJECT';
    font-size: 12px;
    white-space: nowrap;
    font-family: 'Euclid Circular A', sans-serif;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50px;
`;

export const AdderButtonStyled = styled(StyledButton)`
    background: linear-gradient(0deg, #ff008a, #ff008a), #f4f4f8 !important;
    margin: 8px 0 20px auto !important;
    z-index: 1 !important;
    position: relative;
    transition: width .3s !important;
    &:hover {
        width: 160px !important;
        align-items: flex-end !important;

        ${ AddButtonLabel } {
            left: 15px;
        }
    }
        overflow: hidden;
`;
