import React from 'react';

export default ({
    width = '24',
    height = '24',
    onClick,
    id,
    fill = 'black'
}: SvgIconProps) => (
    <svg
        fill="none"
        id={ id }
        width={ width }
        height={ height }
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        onClick={ onClick }
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M11.9997 9.41448L6.70711 14.707C6.31658 15.0976 5.68342 15.0976 5.29289 14.707C4.90237 14.3165 4.90237 13.6833 5.29289 13.2928L10.5854 8.00026C11.3661 7.21966 12.6327 7.21843 13.4142 7.99992L18.7071 13.2928C19.0976 13.6833 19.0976 14.3165 18.7071 14.707C18.3166 15.0976 17.6834 15.0976 17.2929 14.707L11.9997 9.41448Z" fill={ fill } />
    </svg>
);
