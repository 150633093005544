import React, { useEffect, useMemo }      from 'react';
import { CollapseWrapper }                from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/ParametersTabTreejs/CollapseWrapper";
import { useInject }                      from '@geenee/shared/index';
import { Divider, Input, InputTableItem } from '@geenee/ui';
import { observer }                       from 'mobx-react';

type BannerSettings = 'section_native_ar_banner_text' | 'section_native_ar_banner_link'
| 'section_native_ar_banner_description' | 'section_native_ar_banner_button_label'
export const NativeArBannerComponent = observer(() => {
    const { SceneState } = useInject();
    const { activeMolecule } = SceneState;

    const isDisable = useMemo(() => !(activeMolecule?.$parent.options.section_native_ar_banner_text && activeMolecule?.$parent.options.section_native_ar_banner_link), [
        activeMolecule?.$parent.options.section_native_ar_banner_text, activeMolecule?.$parent.options.section_native_ar_banner_link
    ]);

    useEffect(() => {
        if (!activeMolecule?.$parent.options.section_native_ar_banner_button_label) {
            activeMolecule?.$parent.updateOption('section_native_ar_banner_button_label', 'Visit');
        }
    }, []);
    const onChange = (field: BannerSettings) => (event: React.ChangeEvent<HTMLInputElement>) => {
        activeMolecule?.$parent.updateOption(field, event.target.value);
    };

    return (
        <CollapseWrapper
            collapsed={ false }
            title="BANNER SETTINGS"
            contentWrapperProps={ { padding: "xs" } }
            paddingLeft="xs"
        >
            <InputTableItem marginOff weight="medium" viewType="secondary" label="Title" size="sm">
                <Input
                    size="sm"
                    fullWidth
                    placeholder="Enter title"
                    value={ activeMolecule?.$parent.options.section_native_ar_banner_text || '' }
                    onChange={ onChange('section_native_ar_banner_text') }
                />
            </InputTableItem>
            <Divider style={ { height: 1, marginTop: 12, marginBottom: 12 } } color="white" />
            <InputTableItem marginOff weight="medium" viewType="secondary" label="Link" size="sm">
                <Input
                    size="sm"
                    fullWidth
                    placeholder="Enter URL"
                    value={ activeMolecule?.$parent.options.section_native_ar_banner_link || '' }
                    onChange={ onChange('section_native_ar_banner_link') }
                />
            </InputTableItem>
            <Divider style={ { height: 1, marginTop: 12, marginBottom: 12 } } color="white" />
            <InputTableItem
                tooltipContent="Only for iOS"
                tooltipMenuWidth="100px"
                marginOff
                weight="medium"
                viewType="secondary"
                label="Description"
                size="sm"
            >
                <Input
                    size="sm"
                    disabled={ isDisable }
                    fullWidth
                    placeholder="Enter description"
                    value={ activeMolecule?.$parent.options.section_native_ar_banner_description || '' }
                    onChange={ onChange('section_native_ar_banner_description') }
                />
            </InputTableItem>
            <Divider style={ { height: 1, marginTop: 12, marginBottom: 12 } } color="white" />
            <InputTableItem
                tooltipContent="Only for iOS"
                tooltipMenuWidth="100px"
                marginOff
                weight="medium"
                viewType="secondary"
                label="Button Label"
                size="sm"
            >
                <Input
                    size="sm"
                    disabled={ isDisable }
                    fullWidth
                    placeholder="Enter button label"
                    value={ activeMolecule?.$parent.options.section_native_ar_banner_button_label || '' }
                    onChange={ onChange('section_native_ar_banner_button_label') }
                />
            </InputTableItem>

        </CollapseWrapper>
    );
});
