import { CommandType } from '@geenee/shared/src/commander/types';
import { AtomModel }   from "@geenee/shared/src/magellan/atom/model/atom.model";

export class DeleteCommand implements CommandType {
    receiver: any;
    entity: AtomModel;

    constructor(receiver: any, entity: AtomModel) {
        this.receiver = receiver;
        this.entity = entity;
    }

    execute = () => {
        this.receiver.deleteAtom(this.entity.id);
    };

    revert = () => {
        this.receiver.addAtomByUrl(
            this.entity
        );
    };
}
