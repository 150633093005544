import Quaternion from '../math/Quaternion';
import Vector3 from '../math/Vector3';
import BasePoseSensor from './BasePoseSensor';
import IPoseSensor from './IPoseSensor';
import { BROWSER_MODE } from '../constants';

const X_AXIS = new Vector3(1, 0, 0);
const Z_AXIS = new Vector3(0, 0, 1);

/**
 *
 */
export default class ROSPoseSensor extends BasePoseSensor implements IPoseSensor {
  private sensor: RelativeOrientationSensor;
  private sensor_to_world: Quaternion;
  private outQ: Quaternion;
  private sensorQ: Quaternion;

  constructor(sensor: RelativeOrientationSensor) {
    super();
    this.sensor = sensor;

    this.sensor_to_world = new Quaternion();
    this.sensor_to_world.setFromAxisAngle(X_AXIS, -Math.PI / 2);
    this.sensor_to_world.multiply(new Quaternion().setFromAxisAngle(Z_AXIS, Math.PI / 2));

    this.outQ = new Quaternion();
    this.sensorQ = new Quaternion();

    this.sensor.addEventListener('reading', this.onSensorRead);
    this.sensor.start();
  }

  /**
   *
   */
  onSensorRead = () => {
    // -- empty function
  }

  /**
   *
   */
  start(): Promise<void> {
    return new Promise(resolve => {
      if (this.isStarted()) {
        resolve();
        return;
      }
      if (BROWSER_MODE) {
        window.addEventListener('devicemotion', (e) => this.onDeviceMotion(e));
      }
      this.started = true;
      resolve();
    });
  }


  /**
   *
   */
  stop() {
    this.sensor.removeEventListener('reading', this.onSensorRead);
    if (BROWSER_MODE) {
      window.removeEventListener('devicemotion', this.onDeviceMotion);
    }

    this.started = false;
  }

  /**
   *
   * @return {Quaternion}
   */
  getOrientation() {
    const { sensor_to_world, outQ, sensorQ } = this;
    if (!this.started) {
      return new Quaternion();
    }

    const q = this.sensor.quaternion;
    if (!q) {
      return new Quaternion();
    }
    sensorQ.set(q[0], q[1], q[2], q[3]);
    outQ.copy(sensor_to_world);
    outQ.multiply(sensorQ);

    return outQ.clone();
  }
}
