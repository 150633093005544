import styled from 'styled-components';

export const TapForFullScreenLabel = styled.span`
    display: none;
    background: rgba(0, 0, 0, 0.19);
    border: 1px solid
        ${ ({ theme: { secondaryColors } }) => secondaryColors.pink10 };
    border-radius: 15px;
    font-family: 'Euclid Circular A', sans-serif;
    font-weight: 700;
    font-size: 12px;
    padding: 20px 40px;
    position: absolute;
    width: fit-content;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    cursor: pointer;
    line-height: 15px;
    color: ${ ({ theme: { colors } }) => colors.white };
`;

export const SceneDropTarget = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 2;
    &:hover {
        ${ TapForFullScreenLabel } {
            display: block;
        }
    }
`;

export const ModelDropContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;
