import React, { useMemo }    from "react";
import { CollapsedTreeItem } from "@geenee/geeclient-kit/src/lib/component/scene/components/CollapsedTreeItem";
import { ActionsList }       from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/LayersChevronPanel/Actions";
import { AssetTitle }        from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/LayersChevronPanel/AssetTitle";
import { useInject }         from "@geenee/shared/src/hook/use-inject.hook";
import { AtomModel }         from "@geenee/shared/src/magellan/atom/model/atom.model";
import { Description, Icon } from "@geenee/ui";
import { observer }          from "mobx-react";

export const SceneCodeModulesTree = observer(() => {
    const { SceneState } = useInject();
    const { activeMolecule, selectedCodeModuleId } = SceneState;
    const icon = useMemo(() => <Icon margin="xxs" stroke="fat" color="shade-4" name="customCode" />, []);
    const moduleAtoms = activeMolecule?.sceneCodeModuleAtoms;
    if (!moduleAtoms?.length) {
        return <></>;
    }
    return (
        <CollapsedTreeItem
            isSelected={ false }
            icon={ icon }
            content={ (
                <Description uppercase color="shade-2" size="sm">Snippets</Description>
            ) }
            offset={ 1 }
        >
            { moduleAtoms.map((item: AtomModel) => (
                <CollapsedTreeItem
                    key={ item.id }
                    isSelected={ selectedCodeModuleId === item.id }
                    icon={ icon }
                    content={ (
                        <AssetTitle
                            value={ item.firstAsset.filename || '' }
                            onClick={ () => SceneState.setCodeModuleId(item.id) }
                            after={ (
                                <ActionsList
                                    currentModel={ item }
                                    popoverSchema="snippet"
                                />
                            ) }
                        />
                    ) }
                    offset={ 2 }
                />
            )) }
        </CollapsedTreeItem>
    );
});
