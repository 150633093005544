import * as React         from 'react';
import { SizesUnionType } from 'libs/ui/src/lib/util/global-props';
import { Wrapper }        from '../../../wrapper/wrapper.component';
import { tableClassName } from '../../utils';

const className = tableClassName('tfoot');

type PropsType = {
    children: React.ReactNode;

    margin?: SizesUnionType;
    padding?: SizesUnionType;

    style?: React.CSSProperties;
};

export const TableFooter = React.memo((props: PropsType) => (
    <Wrapper
        className={ className }
        margin={ props.margin }
        padding={ props.padding }
        style={ props.style }
    >
        { props.children }
    </Wrapper>
));
