import React                                      from 'react';
import { usePasswordModal }                       from "@geenee/builder/src/pages/MemberPage/components/usePasswordModal";
import { CallbackFunctionVariadic }               from "@geenee/geeclient-kit/src/lib/type/type";
import { Button, Input, InputTableItem, Wrapper } from '@geenee/ui';
import { observer }                               from "mobx-react";

export const PasswordRow = observer(({
    onEdit,
    canEdit = true,
    label = 'Password'
}: { onEdit: CallbackFunctionVariadic, canEdit?: boolean, label?: string }) => {
    const { showModal } = usePasswordModal({ onChange: onEdit });
    return (
        <InputTableItem
            ratio={ 0.65 }
            size="lg"
            label={ label }
            viewType="secondary"
            weight="medium"
        >
            <Wrapper row valign='center'>
                <Input
                    margin="xs"
                    size="md"
                    fullWidth
                    disabled
                    type="password"
                    name="password"
                    value="password"
                    placeholder="Provide new password"
                />
                { canEdit && <Button icon="edit2" viewType="action" radius="sm" iconSize={ 20 } onClick={ () => showModal() } /> }
            </Wrapper>
        </InputTableItem>
    );
});
