import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '12',
    height = '12',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="6" cy="6" r="5.5" fill="white" stroke="#BBBBBB" />
            <path
            // eslint-disable-next-line max-len
                d="M2.58337 8V4C2.58337 3.58579 2.91916 3.25 3.33337 3.25H8.66671C9.08092 3.25 9.41671 3.58579 9.41671 4V8C9.41671 8.41421 9.08092 8.75 8.66671 8.75H3.33337C2.91916 8.75 2.58337 8.41421 2.58337 8Z"
                stroke="black"
                strokeWidth="0.5"
            />
            <path
            // eslint-disable-next-line max-len
                d="M6.00007 5.92644L2.85789 3.72691C2.70707 3.62134 2.49923 3.65802 2.39366 3.80883C2.28809 3.95965 2.32477 4.16749 2.47558 4.27307L5.80891 6.6064C5.92369 6.68674 6.07645 6.68674 6.19122 6.6064L9.52456 4.27307C9.67537 4.16749 9.71205 3.95965 9.60648 3.80883C9.50091 3.65802 9.29306 3.62134 9.14225 3.72691L6.00007 5.92644Z"
                fill="black"
            />
        </svg>
    );
}
