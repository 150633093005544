import React, { useEffect, useMemo, useState } from "react";
import { useInject }                           from "@geenee/shared/src/hook/use-inject.hook";
import JsonSchemaForm                          from "@geenee/ui/src/lib/component/json-schema-form/json-schema-form.component";
import { observer }                            from "mobx-react";

const _defaultOptions = {
    start_button_text:         "ÚNETE AL JALEO",
    packshot_image_url:        "https://lab.geen.ee/celebremos-el-momento/static/media/Packshot_no_Background.5657ce148ebe283c63ca889e74645ac6.png",
    packshot_header_text:      "¡CELEBREMOS\nEL MOMENTO!",
    packshot_description_text: "RED BULL TE DA AAALAS",
    packshot_button_text:      "SEGUIR EXPLORANDO",
    packshot_button_url:       ".",
    packshot_button_target:    "_self"
};
export const SceneModuleControls = observer(() => {
    const { SceneState } = useInject();

    const [ defaultOptions, setDefaultOptions ] = useState<{[key: string]: any} | null>(null);
    const { activeCodeAtom } = SceneState;

    useEffect(() => {
        if (activeCodeAtom?.options) {
            // setDefaultOptions({
            //     ..._defaultOptions,
            //     ...activeCodeAtom.options
            // });
        }
    }, [ activeCodeAtom?.options, activeCodeAtom?.id ]);

    // const fetch = async () => {
    //     const res = await activeMolecule.getCodeModuleJSON(activeCodeAtom);
    //     const config = JSON.parse(res[ '/src/config.json' ].code);
    //     setSchema(config);
    //     console.log(schema);
    //     // localStorage.setItem("transpiledModules", JSON.stringify(res));
    //     setTranspiledModules(res);
    // };

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (defaultOptions) {
                Object.keys(activeCodeAtom.customCodeAttachment.options.properties).forEach((key) => {
                    const property = activeCodeAtom.customCodeAttachment.options.properties[ key ];
                    if (property.visibility === 'visible') {
                        property.default = defaultOptions[ key ];
                    }
                });
                activeCodeAtom.customCodeAttachment.saveData();
            }
        }, 500);
        return () => clearTimeout(timeout);
    }, [ defaultOptions ]);

    const schema = useMemo(() => {
        const config = { ...(activeCodeAtom?.customCodeAttachment?.options || {}) };
        const visibleProperties: {[key: string]: any} = {};
        if (Object.keys(config?.properties || {}).length) {
            Object.entries(config.properties).forEach(([ key, value ]: [key: string, value: any]) => {
                if (value.visibility === 'visible') {
                    visibleProperties[ key ] = value;
                }
            });
        }
        return { ...config, properties: visibleProperties };
    }, [ activeCodeAtom?.id, activeCodeAtom?.customCodeAttachment, activeCodeAtom?.customCodeAttachment?.options?.properties ]);
    return (
        <>
            { schema && (
                <JsonSchemaForm
                    schema={ schema }
                    onChange={ (e) => {
                        console.log(e);
                        setDefaultOptions(e);
                    } }
                    value={ schema || {} }
                />
            ) }
        </>
    );
});
