// @flow

import { LinkAtomOptType } from '../../../type/_atom-option-type';

export const initProps: LinkAtomOptType = {
    link_switch:                 false,
    link_size:                   's',
    link_type:                   'transparent',
    link_icon_after:             false,
    link_href:                   '',
    link_mail_to:                '',
    link_tel:                    '',
    link_text:                   '',
    link_subtitle:               '',
    link_blank:                  false,
    link_action_type:            'link',
    link_scroll:                 0,
    link_animate_scroll:         true,
    link_modal:                  0,
    link_color_text:             'inherit',
    link_color_text_alpha:       1,
    link_color_text_type:        'auto',
    link_color_text_variation:   'harmonic',
    link_color_text_custom:      '',
    link_color_bg:               'inherit',
    link_color_bg_alpha:         1,
    link_color_bg_type:          'auto',
    link_color_bg_variation:     'harmonic',
    link_color_bg_custom:        '',
    link_color_border:           'inherit',
    link_color_border_alpha:     1,
    link_color_border_type:      'auto',
    link_color_border_variation: 'harmonic',
    link_color_border_custom:    '',
    link_icon_switch:            false,
    link_text_switch:            true,
    link_text_font_weight:       400,
    link_text_font_type:         'm',
    link_hover:                  false,

    link_text_font_uppercase: true,

    link_icon_color_text:             'inherit',
    link_icon_color_text_alpha:       1,
    link_icon_color_text_type:        'auto',
    link_icon_color_text_variation:   'harmonic',
    link_icon_color_text_custom:      '',
    link_icon_color_bg:               'inherit',
    link_icon_color_bg_alpha:         1,
    link_icon_color_bg_type:          'auto',
    link_icon_color_bg_variation:     'harmonic',
    link_icon_color_bg_custom:        '',
    link_icon_color_border:           'inherit',
    link_icon_color_border_alpha:     1,
    link_icon_color_border_type:      'auto',
    link_icon_color_border_variation: 'harmonic',
    link_icon_color_border_custom:    '',
    link_icon_blur_radius:            's',
    link_icon_blur_radius_custom:     0,
    link_icon_corner_radius:          '100',
    link_icon_corner_radius_custom:   100,

    link_icon_type:            'geenee',
    link_icon_size:            'm',
    link_icon_style:           'transparent',
    link_icon_nucleo:          '',
    link_icon_geenee:          '',
    link_corner_radius:        '100',
    link_corner_radius_custom: 100,

    link_blur_radius:        's',
    link_blur_radius_custom: 0,
    link_padding:            'off'
};

export const getPropsFromOwner = (
    options: LinkAtomOptType,
    extendProps: Object = {}
) => ({
    link_switch:     options.link_switch,
    link_size:       options.link_size,
    link_type:       options.link_type,
    link_align:      options.link_align,
    link_valign:     options.link_valign,
    link_icon_after: options.link_icon_after,
    link_href:       options.link_href,
    link_mail_to:    options.link_mail_to,
    link_tel:        options.link_tel,

    link_text:                options.link_text,
    link_text_font_uppercase: options.link_text_font_uppercase,
    link_text_font_weight:    options.link_text_font_weight,
    link_text_font_type:      options.link_text_font_type,
    link_text_switch:         options.link_text_switch,
    link_subtitle:            options.link_subtitle,

    link_blank:                  options.link_blank,
    link_action_type:            options.link_action_type,
    link_scroll:                 options.link_scroll,
    link_modal:                  options.link_modal,
    link_animate_scroll:         options.link_animate_scroll,
    link_color_text:             options.link_color_text,
    link_color_text_alpha:       options.link_color_text_alpha,
    link_color_text_type:        options.link_color_text_type,
    link_color_text_variation:   options.link_color_text_variation,
    link_color_text_custom:      options.link_color_text_custom,
    link_color_bg:               options.link_color_bg,
    link_color_bg_alpha:         options.link_color_bg_alpha,
    link_color_bg_type:          options.link_color_bg_type,
    link_color_bg_variation:     options.link_color_bg_variation,
    link_color_bg_custom:        options.link_color_bg_custom,
    link_color_border:           options.link_color_border,
    link_color_border_alpha:     options.link_color_border_alpha,
    link_color_border_type:      options.link_color_border_type,
    link_color_border_variation: options.link_color_border_variation,
    link_color_border_custom:    options.link_color_border_custom,

    link_icon_color_text:             options.link_icon_color_text,
    link_icon_color_text_alpha:       options.link_icon_color_text_alpha,
    link_icon_color_text_type:        options.link_icon_color_text_type,
    link_icon_color_text_variation:   options.link_icon_color_text_variation,
    link_icon_color_text_custom:      options.link_icon_color_text_custom,
    link_icon_color_bg:               options.link_icon_color_bg,
    link_icon_color_bg_alpha:         options.link_icon_color_bg_alpha,
    link_icon_color_bg_type:          options.link_icon_color_bg_type,
    link_icon_color_bg_variation:     options.link_icon_color_bg_variation,
    link_icon_color_bg_custom:        options.link_icon_color_bg_custom,
    link_icon_color_border:           options.link_icon_color_border,
    link_icon_color_border_alpha:     options.link_icon_color_border_alpha,
    link_icon_color_border_type:      options.link_icon_color_border_type,
    link_icon_color_border_variation: options.link_icon_color_border_variation,
    link_icon_color_border_custom:    options.link_icon_color_border_custom,
    link_icon_blur_radius:            options.link_icon_blur_radius,
    link_icon_blur_radius_custom:     options.link_icon_blur_radius_custom,
    link_icon_corner_radius:          options.link_icon_corner_radius,
    link_icon_corner_radius_custom:   options.link_icon_corner_radius_custom,

    link_icon_indent:          options.link_icon_indent,
    link_icon_switch:          options.link_icon_switch,
    link_icon_type:            options.link_icon_type,
    link_icon_size:            options.link_icon_size,
    link_icon_style:           options.link_icon_style,
    link_icon_nucleo:          options.link_icon_nucleo,
    link_corner_radius:        options.link_corner_radius,
    link_corner_radius_custom: options.link_corner_radius_custom,
    link_padding:              options.link_padding,
    link_hover:                options.link_hover,
    ...extendProps
});
