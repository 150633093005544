import { useContext, useMemo }                                                     from "react";
import { useBabylonAssetsActions }                                                 from "@geenee/builder/src/components/AssetsSourcePickerBabylon/hooks/useBabylonAssetsActions";
import { useReadyPlayerMeModal }                                                   from "@geenee/builder/src/components/modals/ReadyPlayerMeModal/useReadyPlayerMeModal";
import { useHandlers as useSketchfabHandler }                                      from "@geenee/builder/src/components/SketchFabImporter/useHandlers";
import { useBuilderInject }                                                        from "@geenee/builder/src/core/hook/use-builder-inject";
import { TYPE_BODY_TRACKING_OVERLAY, TYPE_BODY_TRACKING_TWIN, TYPE_HEAD_TRACKING } from "@geenee/builder/src/lib/constants";
import ModalUpdaterContext                                                         from "@geenee/builder/src/lib/ModalUpdaterContext";
import { useTeamLibraryModal }                                                     from "@geenee/builder/src/module/team-library/modal/useTeamLibrary.modal";
import { BabylonRenderer }                                                         from "@geenee/geespector/renderer/babylonjs.renderer";
import { useInject }                                                               from '@geenee/shared';
import { SceneManager }                                                            from "@geenee/shared/src/manager/scene.manager";
import { SceneManagerCommander }                                                   from "~/magellan/factory/scene-manager.commander";

interface Props {
  mode?: 'add' | 'replace',
  sourcesFilter: (p: any) => boolean,
  renderer?: SceneManager['sceneRenderer']
  onPanelClose: (panelId?: 'materials' | 'textures' | 'layers') => void
}

export const useAssetSourceConfigBabylon  = (
    sourcesFilter: Props["sourcesFilter"],
    onPanelClose: Props["onPanelClose"],
    showLibrary = false,
    mode: Props["mode"] = 'add'
) => {
    const uploaderId =  `add-assets-children-babylon-${ mode }`;
    const setModalOptions = useContext(ModalUpdaterContext);
    const { BuilderState } = useBuilderInject();
    const { AppState } = useInject();
    const { activeSection } = AppState;
    const { sceneManager } = (activeSection || { sceneManager: undefined }) as { sceneManager?: SceneManagerCommander };
    const renderer = sceneManager?.sceneRenderer as BabylonRenderer | undefined;
    const { isLoading, addSceneAsset } = useBabylonAssetsActions(renderer, onPanelClose);

    const { onModelImport } = useSketchfabHandler(
        (value) => addSceneAsset({ file: value.model, closePanelAfterDownload: false }),
        setModalOptions,
        undefined,
        () => onPanelClose('layers')
    );

    const [ showAssetModal ] = useTeamLibraryModal(
        (asset) => addSceneAsset({ url: asset.attachment.url, fileName: asset.attachment.filename })
    );

    const { showModal: showRpmModal } = useReadyPlayerMeModal((url) => addSceneAsset({ url }));
    const isBodyTracking = activeSection?.type === TYPE_BODY_TRACKING_OVERLAY || activeSection?.type === TYPE_BODY_TRACKING_TWIN;
    const isHeadTracking = activeSection?.type === TYPE_HEAD_TRACKING;

    // @ts-ignore
    const itemsConfig: {id: string, iconLabel?: string, label?: string, onClick?: () => void}[] = useMemo(
        () => [
            isBodyTracking ? {
                iconLabel: 'avatar',
                label:     'Body tracking avatar',
                id:        'bodytrackingAvatar',
                onClick:   () => {
                    sceneManager?.createTrackingNode('avatar');
                    onPanelClose('layers');
                }
            } : undefined,
            isBodyTracking ? {
                iconLabel: 'twin',
                label:     'Body tracking twin',
                id:        'bodytrackingTwin',
                onClick:   () => {
                    sceneManager?.createTrackingNode('twin');
                    onPanelClose('layers');
                }
            } : undefined,
            isHeadTracking ? {
                iconLabel: 'head',
                label:     'Head tracking',
                id:        'headtracking',
                onClick:   () => {
                    sceneManager?.createTrackingNode('head');
                    onPanelClose('layers');
                }
            } : undefined,
            {
                iconLabel: 'emptyNode',
                label:     'Empty node',
                id:        'emptyNode',
                onClick:   () => {
                    sceneManager?.createEmptyNode();

                    onPanelClose('layers');
                }
            },
            { id: 'separator' },
            {
                iconLabel: 'uploadCloud',
                label:     'Upload new',
                id:        'uploadCloud',
                onClick:   () => {
                    document.getElementById(uploaderId)?.click();
                }
            },
            showLibrary
                ? {
                    iconLabel: 'library',
                    label:     'Team library',
                    id:        'library',
                    onClick:   () => {
                        BuilderState.changeTeamLibraryMode('default');
                        showAssetModal();
                    }
                }
                : undefined,
            {
                iconLabel: 'geeneeLibrary',
                label:     'Geenee library',
                id:        'geeneeLibrary',
                onClick:   async () => {
                    BuilderState.changeSketchfabMode('geenee');
                    await onModelImport();
                }
            },
            {
                iconLabel: 'readyPlayerMe',
                label:     'Ready Player Me Avatar',
                id:        'readyPlayerMe',
                onClick:   showRpmModal
            },
            {
                iconLabel: 'sketchfab',
                label:     'Sketchfab',
                id:        'sketchfab',
                onClick:   async () => {
                    BuilderState.changeSketchfabMode('default');
                    await onModelImport();
                }
            },
            { id: 'separator' },
            {
                iconLabel: 'pointLight',
                label:     'Point light',
                id:        'pointLight',
                onClick:   () => {
                    sceneManager?.createSceneLight('light-point');
                    onPanelClose('layers');
                }
            },
            {
                iconLabel: 'directLight',
                label:     'Direct light',
                id:        'directLight',
                onClick:   () => {
                    sceneManager?.createSceneLight('light-direct');
                    onPanelClose('layers');
                }
            },
            {
                iconLabel: 'spotLight',
                label:     'Spot light',
                id:        'sportLight',
                onClick:   () => {
                    sceneManager?.createSceneLight('light-spot');
                    onPanelClose('layers');
                }
            },
            { id: 'separator' },
            {
                iconLabel: 'materials',
                label:     'Standard material',
                id:        'standardMaterial',
                onClick:   () => {
                    sceneManager?.createSceneMaterial('standard');
                    onPanelClose('materials');
                }
            },
            {
                iconLabel: 'pbrMaterial',
                label:     'PBR material',
                id:        'pbrMaterial',
                onClick:   () => {
                    sceneManager?.createSceneMaterial('pbr');
                    onPanelClose('materials');
                }
            }
        ].filter((el) => el).filter(sourcesFilter),
        // @ts-ignore
        [ showRpmModal, renderer?.scene, onModelImport, setModalOptions, showAssetModal, mode ]
    );

    return { itemsConfig, uploaderId, uploadHandler: async (file: File) => addSceneAsset({ file }), isLoading };
};
