import React                      from 'react';
import { InputTableItem, Select } from '@geenee/ui';
import { observer }               from 'mobx-react';
import { borderValues }           from '@geenee/builder/src/components/WizardForm/FormType/ContentBasicButton/components/values';
import { AtomModel }              from '@geenee/builder/src/magellan/model/atom.model';

interface Props {
    disabled: boolean
    buttonAtom: AtomModel
}

export const ButtonBorderType = observer(({ disabled, buttonAtom }: Props) => (
    <InputTableItem label='Button Style'>
        <Select
            isDisabled={ disabled }
            size="md"
            options={ borderValues }
            value={
                borderValues.find(
                    (item) => item.value
                    === buttonAtom.options.atom_button_border_type
                ) || borderValues[ 0 ]
            }
            onChange={ ({ value }) => {
                buttonAtom.options.atom_button_border_type = `${ value }`;
                buttonAtom.saveData();
            } }
        />
    </InputTableItem>
));
