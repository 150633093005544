import { BROWSER_MODE } from './constants';
import { load } from './sdk';

export type Matrix4 =  [
    number, number, number, number,
    number, number, number, number,
    number, number, number, number,
    number, number, number, number,
]

export type SlamResult = [] | Matrix4

import Slam from "./slam/Slam";
export const slam = Slam

export type StampResult = [] | [
    // nFoundTarget
    number | undefined,
    // translation matrix
    Matrix4,
    // rotation
    number,
]

import Stamp from './stamp/Stamp'
export const stamp = Stamp

export const loadWasm = load;

const globalThis: any = BROWSER_MODE ? window : {};

if (!globalThis.Geenee || globalThis.Geenee === undefined) globalThis.Geenee = {};
globalThis.Geenee.slam = slam;
globalThis.Geenee.stamp = stamp;
globalThis.Geenee.loadWasm = loadWasm;

export default globalThis.Geenee;
