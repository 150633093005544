import { GroundMesh }      from "@babylonjs/core/Meshes/groundMesh";
import { BabylonRenderer } from "@geenee/geespector/renderer/babylonjs.renderer";
import { CommandType }     from '@geenee/shared/src/commander/types';

export class GridStrategy implements CommandType {
    receiver: BabylonRenderer;
    entity: GroundMesh;

    constructor(receiver: BabylonRenderer, entity: GroundMesh) {
        this.entity = entity;
        this.receiver = receiver;
    }

    execute = () => {
        this.receiver.createGrid(this.entity);
    };

    revert = () => {
        this.receiver.removeGrid();
    };
}
