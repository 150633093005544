import React, { memo }                             from 'react';
import { DescriptionPropsType }                    from "../description/description.component";
import { InputTableItem, InputTableItemPropsType } from "../input-table/input-table-item.component";
import { Wrapper }                                 from "../wrapper/wrapper.component";

type Props = {
  children: React.ReactNode
  ratio?: number
  marginOff?: boolean
  viewType?: InputTableItemPropsType['viewType']
  label?: string
  before?: React.ReactNode
  size?: DescriptionPropsType['size']
  weight?: InputTableItemPropsType['weight']
}
export const InputMatrixRow = memo((props: Props) => (
    <InputTableItem
        ratio={ props.ratio }
        marginOff={ props.marginOff }
        viewType={ props.viewType }
        label={ props.label }
        before={ props.before }
        size={ props.size }
        weight={ props.weight }
    >
        <Wrapper row fullWidth style={ { fontSize: 'inherit' } }>
            { props.children }
        </Wrapper>
    </InputTableItem>
));
