import React from 'react';

// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '10',
    height = '8',
    fill = 'none'
}: SvgIconProps) {
    return (
        <svg
            width={ width }
            height={ height }
            viewBox="0 0 10 8"
            fill={ fill }
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
            // eslint-disable-next-line max-len
                d="M6.17722 6.47842C5.58496 7.23082 4.44678 7.23981 3.83817 6.49689L0.75485 2.73311C-0.0442156 1.7577 0.638676 0.294746 1.89769 0.284804L7.98146 0.236762C9.24047 0.22682 9.95525 1.67874 9.17767 2.6666L6.17722 6.47842Z"
                fill="url(#paint0_linear_hire)"
                fillOpacity="0.9"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_hire"
                    x1="5.02362"
                    y1="7.94396"
                    x2="4.94273"
                    y2="-2.30052"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A1B5C5" />
                    <stop offset="1" stopColor="#D0CDAC" />
                </linearGradient>
            </defs>
        </svg>
    );
}
