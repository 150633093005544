import styled from 'styled-components';

export const BodyStyles = () => `
  width: 460px;
  height: auto;
  max-height: 100vh;
  padding: 33px 25px 40px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;

export const DefaultWrapper = styled.div`
    ${ BodyStyles };
`;

export const Title = styled.span`
    font-weight: bold;
    color: inherit;
    font-size: 13px;
    margin-bottom: 23px;
`;

export const DescriptionContainer = styled.div`
    width: 320px;
    margin-bottom: 23px;

    p {
        /* margin: 0; */
        font-size: 13px;
        text-align: center;
    }
`;

export const SelectWrapper = styled.div<{disableSelect: boolean}>`
    margin-bottom: 16px;

    .one-ui-select__placeholder {
        font-family: Mulish;
    }

    .one-ui-dropdown__menu-wrapper {
        padding: 0;
    }

    .one-ui-select-list {
        background-color: #F5F5F5;

        &__item {
            ${ ({ disableSelect }) => disableSelect && 'cursor: not-allowed' };

            &:hover {
                background-color: #fff;
            }

            &.one-ui-checkbox--checked {
                ${ ({ disableSelect }) => disableSelect && 'cursor: pointer' };
            }
        }
    }

    .one-ui-checkbox__element {
        position: relative;
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: 1px solid #52575C;
        border-radius: 50%;

        &--checked {
            border: none;
        }

        &:before {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #000;
        }
    }

`;
