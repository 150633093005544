import React                              from "react";
import { cn, Description, Icon, Wrapper } from "@geenee/ui";
import "./styles.scss";

const className = cn("scene-drop-placeholder");

export const SceneDropPlaceholder = ({
    active,
    formats = []
}: { active?: boolean, formats: string[] }) => (
    <Wrapper
        className={ className("root") }
        padding="sm"
        maxWidth="fit-content"
        radius="xxxl"
    >
        <Wrapper
            className={ className("content", { active }) }
            radius="lg"
            frame="solid-white"
            fullHeight
            align="center"
            valign="center"
        >
            <Icon
                size={ 43 }
                color={ active ? "gradient-warm-1" : "shade-4" }
                name="uploadCloud"
                margin="sm"
            />
            <Description size="lg" weight="medium" margin="xs">
                Drag & drop your assets
            </Description>
            <Description color="shade-4" className={ className("formats") }>
                Supported file formats:
                <br />
                { formats.map((item) => `${ item }`)
                    .join(", ") }
            </Description>
        </Wrapper>
    </Wrapper>
);
