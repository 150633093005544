import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '12',
    height = '12',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="8" cy="8" r="7.5" fill="white" stroke="#A9A9A9" />
            <path
            // eslint-disable-next-line max-len
                d="M8.59334 2.8058L12.1489 4.58357C12.602 4.80873 12.8886 5.27094 12.8889 5.77714V10.0129C12.8886 10.5189 12.602 10.9811 12.1499 11.2058L8.59445 12.9835C8.21887 13.1714 7.77669 13.1714 7.40124 12.9836L3.84446 11.2052C3.39179 10.9771 3.1076 10.512 3.11112 10.0083L3.11112 5.77691C3.11138 5.27094 3.39801 4.80873 3.85013 4.58406L7.40667 2.8058C7.78043 2.62007 8.21958 2.62007 8.59334 2.8058Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M12.1489 4.58357L8.59334 2.8058C8.21958 2.62007 7.78043 2.62007 7.40667 2.8058L3.85013 4.58406C3.39801 4.80873 3.11138 5.27094 3.11112 5.77691L3.11112 10.0083C3.1076 10.512 3.39179 10.9771 3.84446 11.2052L7.40124 12.9836C7.77669 13.1714 8.21887 13.1714 8.59445 12.9835L12.1499 11.2058C12.602 10.9811 12.8886 10.5189 12.8889 10.0129V5.77714C12.8886 5.27094 12.602 4.80873 12.1489 4.58357ZM8.00003 6.95136L4.55161 5.22715L7.80323 3.60133C7.9267 3.53998 8.0729 3.53992 8.1974 3.60162L11.4484 5.22715L8.00003 6.95136ZM8.44446 7.72295L12 5.94517L12 10.0127C11.9999 10.1811 11.9044 10.3352 11.7534 10.4102L8.44446 12.0647V7.72295ZM7.55557 7.72294L4.00002 5.94516L4.00001 10.0113C3.99884 10.1803 4.09357 10.3353 4.24323 10.4107L7.55557 12.0669V7.72294Z"
                fill="black"
            />

        </svg>
    );
}
