import APIRequest               from '~/lib/APIRequest';
import { TeamLibraryItemModel } from '~/module/team-library/state/team-library-item.model';

const create = (item: TeamLibraryItemModel) => APIRequest.request(
    'post',
    '/api/v0/attachment_library',
    { attachment_library: item }
).then((res) => res.data);
// TODO: Ask Vlad about the array
const read = () => APIRequest.request('GET', '/api/v0/attachment_library').then((res) => res.data);
const update = (item: TeamLibraryItemModel) => APIRequest.request(
    'put',
    `/api/v0/attachment_library/${ item.id }`,
    { attachment_library: item }
).then((res) => res.data);
const deleteLibraryItem = (id: string) => APIRequest.request('DELETE', `/api/v0/attachment_library/${ id }`);
const show = (id: string) => APIRequest.request('GET', `/api/v0/attachment_library/${ id }`, { auth: true }).then((res) => res.data);

export const AssetLibraryApi = {
    create, read, update, deleteLibraryItem, show
};
