import React, { useEffect }                                          from 'react';
import { CallbackFunctionVariadic }                                  from "@geenee/geeclient-kit/src/lib/type/type";
import { AnimatedWrapper, Modal, ModalPropsType, useModal, Wrapper } from '@geenee/ui';
import { SizeType }                                                  from '@geenee/ui/src/lib/util/global-props';

export const useModalDialogWrapper = (c: React.ReactNode | React.ComponentType<any>, modalProps?: ModalPropsType, maxWidth?: string, padding: SizeType = 'md', _onClose?: CallbackFunctionVariadic) => {
    const [ showModal, hideModal ] = useModal((transformGroupProps) => (
        <Modal
            className='global-modal'
            onHideModal={ () => {
                _onClose && _onClose();
                hideModal();
            } }
            { ...{ ...(modalProps || {}), ...transformGroupProps } }
        >
            <AnimatedWrapper manual={ false }>
                <Wrapper
                    maxWidth={ maxWidth || '100%' }
                    padding={ padding }
                >
                    { React.cloneElement(c, {
                        ...c.props,
                        onClose: () => {
                            _onClose && _onClose();
                            hideModal();
                        }
                    }) }
                </Wrapper>
            </AnimatedWrapper>
        </Modal>
    ), [ c.props ]);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                hideModal();
            }
        };
        window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close);
    }, []);

    return [ showModal, hideModal ];
};
