import React                         from 'react';
import { Wrapper }                   from '@geenee/ui';
import { CardList }                  from '../CardList';
import { DeveloperAccessAndSupport } from '../DeveloperAndAccessSupport';

export function HomePageContent() {
    return (
        <Wrapper
            style={ { paddingLeft: 0, paddingRight: 0 } }
            padding="xxl"
        >
            <CardList />
            <DeveloperAccessAndSupport />
        </Wrapper>
    );
}
