import React, { useEffect, useRef, useState } from 'react';
import { AutoComplete }                       from 'primereact/autocomplete';
import { CountryItemTemplate }                from '@geenee/builder/src/components/CountriesSelector/CountryItemTemplate';
import { SelectedTemplate }                   from '@geenee/builder/src/components/CountriesSelector/SelectedTemplate';
// @ts-ignore
import countries                              from './countries.json';
import { AutocompleteWrapper }                from './styles';

export interface CountryItem {
  id: string;
  name: string;
  flag: string;
  alpha2: string;
  alpha3: string;
  ioc: string;
}

interface Props {
  value: string | null;
  onChange: (value: string) => void;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
}

export function CountriesSelector({ value, onChange, disabled }: Props) {
    const [ selectedCountries, setSelectedCountries ] = useState<CountryItem[]>([]);
    const [ filteredCountries, setFilteredCountries ] = useState<CountryItem[]>([]);
    const autoCompleteRef = useRef<AutoComplete | null>(null);

    useEffect(() => {
        // @ts-ignore
        const countriesIds = (value || '').split(',');
        const newSelectedCountries: CountryItem[] = [];
        countriesIds.forEach((countryId) => {
            const country = countries.find((el) => el.id === countryId);
            if (country) {
                newSelectedCountries.push(country);
            }
        });
        setSelectedCountries(newSelectedCountries);
    }, [ value ]);

    const searchCountry = (event: {originalEvent: Event; query: string}) => {
        setTimeout(() => {
            let newFilteredCountries;
            if (!event.query.trim().length) {
                newFilteredCountries = [ ...countries ];
            } else {
                newFilteredCountries = countries.filter((country) => country.name.toLowerCase().startsWith(event.query.toLowerCase()));
            }
            setFilteredCountries(newFilteredCountries);
        }, 250);
    };

    return (
        <AutocompleteWrapper>
            <AutoComplete
                placeholder={ selectedCountries.length ? '' : 'None' }
                // @ts-ignore
                onClick={ () => { // @ts-ignore
                    !disabled && autoCompleteRef.current?.onDropdownClick();
                } }
                ref={ (ref) => { autoCompleteRef.current = ref; } }
                style={ { width: '100%' } }
                value={ selectedCountries }
                suggestions={ filteredCountries }
                completeMethod={ searchCountry }
                field="name"
                multiple
                onChange={ (e) => {
                    setSelectedCountries(e.value);
                    onChange(e.value.map((el: CountryItem) => el.id).join(','));
                } }
                itemTemplate={ CountryItemTemplate }
                disabled={ disabled }
                // @ts-ignore
                selectedItemTemplate={ SelectedTemplate }
            />
        </AutocompleteWrapper>
    );
}
