import { ArSceneProcessorType, GeetrackerSlamEngine, GeetrackerStampEngine } from '../processor';
// import {stamp, loadWasm} from '@geenee/geetraker';

export interface StampProcessor extends ArSceneProcessorType {
    engine: GeetrackerStampEngine;
    process(width:number, height:number): [ImageData, number[]];
    init(video: HTMLVideoElement, fov: number): Promise<void>;
}

export class GeetrackerStampProcessor implements StampProcessor {
    // engine = stamp;

    process(width:number, height:number) {
        const [ imageData, roto ] = this.engine.process(width, height);

        return [ imageData, roto ] as [ImageData, number[]];
    }

    async init(video: HTMLVideoElement, fov: number) {
        await loadWasm();
        this.engine.initialize(video, fov);
    }
}
