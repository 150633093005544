import { Analytics }                                            from '@geenee/analytics';
import { ExperienceModel as ExperienceModelShared, HttpClient } from '@geenee/shared';
import { Nullable }                                             from '@geenee/shared/type/shared.type';
import { ViewType }                                             from '@geenee/shared/type/view.type';
import { action, computed, makeObservable, toJS }               from 'mobx';
import { VIEW_CREATED, VIEW_DELETED }                           from '@geenee/builder/src/lib/constants';
import envConfig                                                from '@geenee/builder/src/lib/envConfig';
import { uploadAttachment }                                     from '@geenee/builder/src/lib/uploadAttachment';
import { container }                                            from '@geenee/builder/src/magellan/di/di';
import { ProjectModel }                                         from '@geenee/builder/src/magellan/model/project.model';

export class ExperienceModel extends ExperienceModelShared implements ViewType {
    httpClient: HttpClient = container.get('<HttpClient>');
    analytics: Analytics = container.get('<Analytics>');

    constructor() {
        super();
        makeObservable(this);
    }

    @computed
    get parentProjectEditable(): Nullable<ProjectModel> {
        return this.parentProject as Nullable<ProjectModel>;
    }

    @action
        updateState = (item: Partial<ExperienceModel>) => {
            Object.keys(item).forEach((key) => {
            // @ts-ignore
                this[ key ] = item[ key ];
            });
        };

    @action
    async updateOptions(options: Partial<ViewType['options']>) {
        Object.assign(this.options, options);
        await this.saveData();
    }

    async saveData(analyticData?: { eventName: string, properties: object }) {
        try {
            this.updateState(this);
            this.parentProjectEditable?.saveData();

            await this.httpClient.put(
                `${ envConfig.API_URL }/api/v0/experiences/${ this.id }`,
                { experience: toJS(this.toServerData()) }
            );

            if (analyticData?.eventName && analyticData?.properties) {
                this.analytics.track(analyticData?.eventName, analyticData?.properties);
            }
        } catch (e) {
            console.error('Error while updating experience: ', e);
        }
    }

    toServerData() {
        return {
            ...this,
            $parent:               undefined,
            sectionsRegistry:      undefined,
            httpClient:            undefined,
            sections:              undefined,
            parentProject:         undefined,
            parentProjectEditable: undefined,
            attachmentFactory:     undefined,
            viewFactory:           undefined,
            viewsRegistry:         undefined,
            views:                 undefined
        };
    }

    @action
    async setCoverImage(file: File) {
        const newAttachment = await this.createAttachmentModel({
            id: this.cover_image_id || '',
            file
        }, uploadAttachment);
        if (newAttachment.id) {
            this.cover_image_id = newAttachment.id;
            this.cover_image = newAttachment;
            await this.saveData();
        }
    }

    @action
    async createView(viewSchema: Partial<ViewType>) {
        try {
            const views = Array.from(this.viewsRegistry.values()).map((el) => el.options.order || 0);
            const order = views.length ? Math.max(...views) : 0;
            const composedSchema = {
                ...viewSchema,
                options: {
                    ...viewSchema.options,
                    order: order + 1
                },
                sections:      [],
                experience_id: this.id,
                experienceId:  this.id,
                type:          'view'
            };

            const { data: { data } } = await this.httpClient.post(`${ envConfig.API_URL }/api/v0/views`, { view: composedSchema });
            const { id, experience_id } = data;

            this.analytics.track(VIEW_CREATED, { id, experience_id, order });

            composedSchema.id = id;
            const viewModel = this.viewFactory.create(composedSchema, this);
            this.viewsRegistry.set(viewModel.id, viewModel);
            (this.parentProject as ProjectModel).saveData();
            return viewModel;
        } catch (e) {
            console.error('Error while creating a view: ', e);

            return new Error('Error while creating a view');
        }
    }

    @action
    async deleteChild(childId: string) {
        try {
            await this.httpClient.delete(`${ envConfig.API_URL }/api/v0/views/${ childId }`);
            this.viewsRegistry.delete(childId);

            this.parentProjectEditable?.saveData();
            this.analytics.track(VIEW_DELETED, { id: childId });
        } catch (e) {
            console.error('Error while deleting a view: ', e);
        }
    }
}
