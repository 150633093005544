import React, { useState }                      from 'react';
import { Description, Wrapper }                 from '@geenee/ui';
import findKey                                  from 'lodash-es/findKey';
import { observer }                             from 'mobx-react';
import { createTaskApi }                        from '@geenee/builder/src/api/ZendeskApi';
import { Payment as PaymentModal }              from '@geenee/builder/src/components/modals/Payment';
import { useBuilderInject }                     from '@geenee/builder/src/core/hook/use-builder-inject';
import { stripeState }                          from '@geenee/builder/src/core/state/stripe.state';
import CancelConfirmation                       from '../CancelConfirmation';
import { useCloseAndRedirect }                  from '../common/handlers/useCloseAndRedirect';
import { Button, Link }                         from '../common/styles';
import { ContentWrapper, CustomButtonsWrapper } from './styles';

type Props = {
    close: () => void;
    setModal: (v?: any) => void;
    showError: (message: string) => void;
}

export const CancelSubscription = observer(({ close, setModal, showError }: Props) => {
    const { goToPlans } = useCloseAndRedirect(close);
    const [ waiting, setWaiting ] = useState<boolean>(false);
    const { BuilderState } = useBuilderInject();
    const { profile } = BuilderState.currentUser;
    const email = profile?.email || '';

    const showNewCycleModal = () => {
        let properId = stripeState.plan_price_id;

        // NOTE - in case if current plan is Free Trial choose Basic per month as upgrade
        const freePlan = stripeState.prices[ properId ].price === 0;
        if (freePlan) {
            const allPlans = Object.keys(stripeState.prices).map(
                (itemName) => ({
                    planName: itemName,
                    props:    stripeState.prices[ itemName ]
                })
            );
            const basicMonth = allPlans.find(
                ({ props: { name, period } }) => name === 'Premium' && period === 'month'
            );
            properId = basicMonth!.planName;
        } else {
            const currentInterval = stripeState.prices[ properId ]?.period;
            const currentPlanName = stripeState.prices[ properId ]?.name;
            const newInterval = currentInterval === 'month' ? 'year' : 'month';
            const newId = findKey(stripeState.prices, { name: currentPlanName, period: newInterval });
            if (newId) {
                properId = newId;
            }
        }

        setModal({
            component: PaymentModal,
            props:     {
                priceId: properId,
                type:    'PAYMENT'
            }
        });
    };

    const handleClick = async () => {
        setWaiting(true);
        try {
            setTimeout(() => {
                setModal({ component: CancelConfirmation });
                showError('');
            }, 0);
            const currentPlan = stripeState.prices[ stripeState.plan_price_id ];
            if (stripeState.daysLeft) {
                await createTaskApi({
                    subject:     'Refund',
                    description: `Please make a refund for the ${ currentPlan.name } basic plan, ${ stripeState.daysLeft } days, email: ${ email }.`
                });
            }
            await stripeState.cancelSubscription();
        } catch (err: any) {
            showError(err.message);
        }
        setWaiting(false);
    };

    return (
        <ContentWrapper align="center" fullWidth maxWidth="460px">
            <Description align="center" weight="bold">CANCEL SUBSCRIPTION</Description>
            <Wrapper align="center" padding="md">
                <Description size="sm" margin="sm" align="center" weight="medium"> Are you sure you want to cancel?</Description>
                <Description size="sm">
                    Please note once you cancel, your account will be for non-commercial use and all projects will include a Geenee watermark.
                </Description>
            </Wrapper>

            <CustomButtonsWrapper>
                <Button
                    $light
                    $width="172px"
                    $height="40px"
                    label="NEVERMIND"
                    onClick={ () => close() }
                />
                <Button
                    $width="172px"
                    $height="40px"
                    $waiting={ waiting }
                    disabled={ waiting }
                    onClick={ handleClick }
                    label="PROCEED"
                />
            </CustomButtonsWrapper>
            <Description size="sm" margin="sm">Need help with anything?</Description>
            <Link onClick={ () => goToPlans() }>Change plans</Link>
            <Link onClick={ showNewCycleModal }>Change billing cycle</Link>

        </ContentWrapper>
    );
});
