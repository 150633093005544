import React                                   from 'react';
import { useTheme }                            from 'styled-components';
import CheckboxChecked                         from '@geenee/builder/src/asset/icons/CheckboxChecked';
import HintCommerical                          from '@geenee/builder/src/asset/icons/HintCommerical';
import Hint                                    from '@geenee/builder/src/components/common/Hint';
import { UnderlinedTableRow, WithHintWrapper } from '@geenee/builder/src/pages/AllPlansPage/styles';

export function CommericalLicense() {
    const { secondaryColors } = useTheme();
    return (
        <UnderlinedTableRow>
            <td>
                <WithHintWrapper>
                    <span>Commerical License</span>
                    <Hint
                        title='Personal vs Business'
                        text='Get the commerical license if you plan to use it for any business or promo use case.'
                        iconElement={ <HintCommerical /> }
                    />
                </WithHintWrapper>
            </td>
            <td className='basic-plan' />
            <td className='plan-filler' />
            <td className='studio-plan'>
                { ' ' }
                <CheckboxChecked
                    beIcon
                    fill={ secondaryColors.green70 }
                />
            </td>
            <td className='plan-filler' />
            <td className='pro-plan'>
                { ' ' }
                <CheckboxChecked
                    beIcon
                    fill={ secondaryColors.green70 }
                />
            </td>
        </UnderlinedTableRow>
    );
}
