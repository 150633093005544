import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '40',
    height = '40',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
            onClick={ onClick }
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M3.33329 29.9998C1.11107 29.9998 1.11107 26.6665 3.33329 26.6665C5.17424 26.6665 6.66663 25.1741 6.66663 23.3332V14.9998C6.66663 7.63604 12.6362 1.6665 20 1.6665C27.3638 1.6665 33.3333 7.63604 33.3333 14.9998V23.3332C33.3333 25.1741 34.8257 26.6665 36.6666 26.6665C38.8888 26.6665 38.8888 29.9998 36.6666 29.9998H3.33329ZM29.9999 23.3332V14.9998C29.9999 9.47699 25.5228 4.99984 19.9999 4.99984C14.4771 4.99984 9.99994 9.47699 9.99994 14.9998V23.3332C9.99994 24.5475 9.67529 25.6859 9.10806 26.6665H30.8918C30.3246 25.6859 29.9999 24.5475 29.9999 23.3332ZM20 38.3273C21.7827 38.3273 23.4305 37.3781 24.325 35.8361C24.9695 34.725 24.1678 33.3332 22.8833 33.3332H17.1167C15.8321 33.3332 15.0305 34.725 15.675 35.8361C16.5695 37.3781 18.2173 38.3273 20 38.3273Z"
                fill="black"
            />
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="1" width="38" height="38">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    // eslint-disable-next-line max-len
                    d="M3.33329 29.9998C1.11107 29.9998 1.11107 26.6665 3.33329 26.6665C5.17424 26.6665 6.66663 25.1741 6.66663 23.3332V14.9998C6.66663 7.63604 12.6362 1.6665 20 1.6665C27.3638 1.6665 33.3333 7.63604 33.3333 14.9998V23.3332C33.3333 25.1741 34.8257 26.6665 36.6666 26.6665C38.8888 26.6665 38.8888 29.9998 36.6666 29.9998H3.33329ZM29.9999 23.3332V14.9998C29.9999 9.47699 25.5228 4.99984 19.9999 4.99984C14.4771 4.99984 9.99994 9.47699 9.99994 14.9998V23.3332C9.99994 24.5475 9.67529 25.6859 9.10806 26.6665H30.8918C30.3246 25.6859 29.9999 24.5475 29.9999 23.3332ZM20 38.3273C21.7827 38.3273 23.4305 37.3781 24.325 35.8361C24.9695 34.725 24.1678 33.3332 22.8833 33.3332H17.1167C15.8321 33.3332 15.0305 34.725 15.675 35.8361C16.5695 37.3781 18.2173 38.3273 20 38.3273Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0)">
                <rect width="40" height="40" fill="black" />
            </g>
        </svg>
    );
}
