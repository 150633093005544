import React, { memo, useCallback } from 'react';
import { Button, iconMap, Tooltip } from '@geenee/ui';
import { IconPropsType }            from '@geenee/ui/src/lib/component/icon/icon.component';

export type ActionType = {
  tooltipText?: string,
  icon: keyof typeof iconMap,
  onClick: () => void,
  iconColor?: IconPropsType['color'],
  iconSize?: number
}

type Props = {
  action: ActionType
  menuWidth?: string
}

export const ButtonWithPortalTooltip = memo(({ action, menuWidth = '106px' }: Props) => {
    const IconButton = useCallback((item : ActionType) => (
        <Button
            size='sm'
            radius="lg"
            viewType='action'
            iconColor={ item.iconColor }
            icon={ item.icon }
            iconSize={ item.iconSize || 20 }
            onClick={ item.onClick }
        />
    ), []);
    return (
        action.tooltipText ? (
            <Tooltip
                margin="xs"
                message={ action.tooltipText }
                position="top"
                messageAlign="center"
                menuWidth={ menuWidth }
                minWidth={ menuWidth }
                shadow
            >
                { IconButton(action) }
            </Tooltip>
        )
            : IconButton(action)
    );
});
