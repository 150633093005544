import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '68',
    height = '68',
    onClick,
    id,
    fill = 'white'
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 68 68"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_add)">
                <g filter="url(#filter1_i)">
                    <circle cx="33.75" cy="32.75" r="31.75" fill="none" id='hover-rollover' />
                </g>
                <circle cx="34" cy="33" r="32" stroke={ fill } strokeWidth="2" />
                <path
                // eslint-disable-next-line max-len
                    d="M46.9297 31.264H35.8165V20.1507C35.8165 19.1742 35.0168 18.3745 34.0402 18.3745C33.0636 18.3745 32.264 19.1741 32.264 20.1507V31.264H21.1507C20.1742 31.264 19.3745 32.0636 19.3745 33.0402C19.3745 34.0168 20.1741 34.8165 21.1507 34.8165H32.264V45.9263C32.264 46.9028 33.0636 47.7025 34.0402 47.7025C35.0168 47.7025 35.8165 46.9028 35.8165 45.9263V34.8165H46.9262C47.9028 34.8165 48.7025 34.0168 48.7025 33.0402C48.7059 32.0637 47.9062 31.264 46.9296 31.264H46.9297Z"
                    fill={ fill }
                />
            </g>
            <defs>
                <filter
                    id="filter0_add"
                    x="0"
                    y="0"
                    width="68"
                    height="68"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.516667 0 0 0 0 0.516667 0 0 0 0 0.516667 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <filter
                    id="filter1_i"
                    x="2"
                    y="1"
                    width="63.5"
                    height="65.5"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
                </filter>
            </defs>
        </svg>
    );
}
