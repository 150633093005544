export const getSceneGlb = (fs, { Engine, Scene, SceneLoader, GLTF2Export }, canvas, moleculeId) => {
    const engine = new Engine(canvas, true);
    const offscreenScene = new Scene(engine);
    return new Promise((resolve, reject) => {
        fs.readFile(`/gee-scene-${ moleculeId }.babylon`, (err, data) => {
            try {
                if (data) {
                    data.text().then((scene) => {
                        SceneLoader.LoadAssetContainerAsync('', `data:${ scene }`, offscreenScene, null, '.babylon').then((container) => {
                            container.addAllToScene();
                            const FILE_NAME = 'gee-scene';
                            GLTF2Export.GLBAsync(
                                offscreenScene,
                                FILE_NAME,
                                {

                                    shouldExportNode: (node) => {
                                        if (node.metadata && node.metadata._skinAuxilaryNode) {
                                            return false;
                                        }
                                        if (node.name === 'camera' || node.name === "Camera") {
                                            return false;
                                        }
                                        return true;
                                    }
                                    // includeCoordinateSystemConversionNodes: false
                                }
                            ).then((result) => {
                                console.log(result);
                                const blob = result.glTFFiles[ `${ FILE_NAME }.glb` ];
                                if (blob) {
                                    const file = new File([ blob ], `${ FILE_NAME }.glb`);
                                    resolve(file);
                                }
                            });
                        });
                    });
                }
            } catch (e) {
                console.error(e);
                reject(e);
            }
        });
    });
};
export const getSceneBabylon = (fs, BABYLON, canvas, moleculeId) => {
    const engine = new BABYLON.Engine(canvas, true);
    const offscreenScene = new BABYLON.Scene(engine);
    return new Promise((resolve, reject) => {
        fs.readFile(`/gee-scene-${ moleculeId }.babylon`, (err, data) => {
            try {
                if (data) {
                    data.text().then((scene) => {
                        BABYLON.SceneLoader.AppendAsync('', `data:${ scene }`, offscreenScene, null).then(() => {
                            const FILE_NAME = 'gee-scene';
                            BABYLON.GLTF2Export.GLBAsync(
                                offscreenScene,
                                FILE_NAME
                            ).then((result) => {
                                const blob = result.glTFFiles[ `${ FILE_NAME }.glb` ];
                                if (blob) {
                                    const file = new File([ blob ], `${ FILE_NAME }.glb`);
                                    resolve(file);
                                }
                            });
                        });
                    });
                }
            } catch (e) {
                console.error(e);
                reject(e);
            }
        });
    });
};

/* eslint-disable */
const sceneWorker = () => {
    importScripts(`${self.location.origin}/babylonjs/babylon.js`);
    importScripts(`${self.location.origin}/babylonjs-serializers/babylonjs.serializers.js`);
  importScripts(`${self.location.origin}/babylonjs-loaders/babylonjs.loaders.js`);
    importScripts(`https://unpkg.com/@isomorphic-git/lightning-fs`);
    const document = self.document = {
        createElement(tagName, options) {
            if (tagName === 'canvas') {
                const canvas = new OffscreenCanvas(100, 100);
                canvas.toDataURL = () => canvas.convertToBlob().then((blob) => new FileReaderSync().readAsDataURL(blob));
                return canvas;
            }
        }
    };
    self.onmessage = (message) => {
        const { canvas, moleculeId } = message.data;
        const fs = new LightningFS('fs');
        INSERT_GET_SCENE_GLB_DEFINITION(fs, BABYLON, canvas, moleculeId).then((file) => {
          postMessage({file})
        })
    };
};

const buildWorker = (worker) => {
    let code = worker.toString();
    code = code.replace("INSERT_GET_SCENE_GLB_DEFINITION", `(${getSceneGlb.toString()})`)
    const blob = new Blob([ `(${ code })()` ]);
    return new Worker(URL.createObjectURL(blob));
};

export default buildWorker(sceneWorker);
