import styled from 'styled-components';

export const InfoBlock = styled.div`
  .one-ui-description {
    font-size: 13px;
  }
`;

export const HighlightContainer = styled.div`
  color: #FF008A;
`;

export const InfoContainer = styled.div`
  width: 100%;
  padding: 12px 14px;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  .one-ui-description {
    font-size: 13px;
    line-height: 30px;
  }
`;
