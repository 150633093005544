import { BuilderState }                      from '@geenee/builder/src/core/state/builder.state';
import { inject, injectable, postConstruct } from "inversify";
import { action, makeObservable }            from 'mobx';
import { MobxForm }                          from './mobx-form';

@injectable()
export class ResetPassword extends MobxForm {
  @inject('<BuilderState>') builderState: BuilderState;

  @postConstruct()
  initialize() {
      makeObservable(this);
  }

  setup(form) {
      this.form = form;

      this.fields = [
          {
              name:        'password',
              type:        'password',
              placeholder: 'Your Password',
              rules:       {
                  required:  'This field is required',
                  minLength: {
                      value:   8,
                      message: 'Password must have at least 8 characters'
                  }
              }
          }
      ];

      this.hooks.onSubmit = this.onSubmit.bind(this);
  }

  @action
  async onSubmit(data: any, _e: React.SyntheticEvent, options) {
      this.setIsLoading(true);

      try {
          await this.builderState.resetPass(options.code, data.password);
      } catch (error) {
          this.setIsLoading(false);

          this.setError('form', {
              type:    'custom',
              message: 'The credentials you’ve entered are incorrect.'
          });

          this.errorHandler(error);
      }

      this.setIsLoading(false);
  }
}
