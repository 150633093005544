// eslint-disable-next-line simple-import-sort/sort
type ProcessedCodeType = {
    script: HTMLScriptElement;
};

export const processCode = (code: string, assignDataAttribute: boolean | string = false): ProcessedCodeType => {
    const script = document.createElement('script');
    script.innerHTML = code;

    if (assignDataAttribute) {
        // eslint-disable-next-line no-param-reassign

        // @ts-ignore
        elem.dataset[ assignDataAttribute ] = '1';
    }
    // @ts-ignore
    return script;
};
