import React                     from 'react';
import { isArSceneType }         from '@geenee/geeclient-kit/src/lib/component/scene/util';
import LayoutSectionComponent    from '@geenee/geeclient-kit/src/lib/component/section/layout/layout-section.component';
import { SceneSectionComponent } from '@geenee/geeclient-kit/src/lib/component/section/scene/scene-section.component';
import { DIContextType }         from '@geenee/geeclient-kit/src/lib/context/di.context';
import { SectionModel }          from '@geenee/shared/index';
import { observer }              from 'mobx-react';

type PropsType = {
  diContext: DIContextType;
  SectionWrapperComponent: React.ComponentType<any>;
  shouldNotRenderInteractiveScene?: boolean;
  sectionModel: SectionModel
};

export const SectionViewComponent  = observer((props: PropsType) => (
    <>

        {
            props.sectionModel && (
                <>
                    { process.env.ENV_GEENEE_APP !== 'BUILDER' ? (

                        <>
                            { isArSceneType(props.sectionModel.type) && (<SceneSectionComponent { ...props } />) }
                            { !isArSceneType(props.sectionModel.type) && (<LayoutSectionComponent { ...props } />) }
                        </>
                    ) : (
                        <LayoutSectionComponent { ...props } />
                    ) }
                </>
            )
        }
    </>
));
