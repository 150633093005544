import React                                          from 'react';
import { TableInfo }                                  from '@geenee/builder/src/pages/AllPlansPage';
import { LimitedOfferLabel, PricingCell, PricingRow } from '@geenee/builder/src/pages/AllPlansPage/styles';

interface Props {
  planNames: string[];
  currentInterval: 'year' | 'month';
  tableInfo: TableInfo;
}

export function Pricing({ planNames, currentInterval, tableInfo }: Props) {
    const getPricing = (index: number) => {
        const planName = planNames[ index ];
        const { price } = tableInfo[ planName ][ currentInterval ];
        const priceComponents = [
            <p key={ planName }>
                { price }
                { ' ' }
                /
                { currentInterval }
                <br />
                { planName === 'Basic' ? (
                    <LimitedOfferLabel>
                        Limited Time Offer
                    </LimitedOfferLabel>
                ) : <></> }
            </p>
        ];

        return priceComponents;
    };
    return (
        <PricingRow>
            <PricingCell />
            <PricingCell className="basic-plan">{ getPricing(0) }</PricingCell>
            <td className="plan-filler" />
            <PricingCell className="studio-plan">{ getPricing(1) }</PricingCell>
            <td className="plan-filler" />
            <PricingCell className="pro-plan" />
        </PricingRow>
    );
}
