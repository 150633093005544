import React              from 'react';
import { Table, Wrapper } from '@geenee/ui';
import { observer }       from 'mobx-react';
import { ChangePlanInfo } from './ChangePlanInfo';
import { EmptyPage }      from './EmptyPage';
import { useTableData }   from './useTableData';

export const SDKAccessTable = observer(() => {
    const [ data, columns, isLoaded ] = useTableData();

    return (
        <Wrapper margin="xl">
            <Wrapper loading={ !isLoaded } maxWidth='fit-content' margin="xl">
                <Table
                    noFooter
                    bodyFrame='solid-default'
                    paddingBody="sm"
                    paddingHeader="sm"
                    data={ data }
                    columns={ columns }
                />
                { !data?.length ? <EmptyPage /> : <></> }
            </Wrapper>
            { data.length ? <ChangePlanInfo /> : <></> }
        </Wrapper>
    );
});
