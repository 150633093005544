import { action, makeAutoObservable, observable } from 'mobx';
import { StripePlanType }                         from '@geenee/builder/src/../type/model/stripe/stripe-plan.type';
import { StripePlanParamsModel }                  from './stripePlanParams.model';

export class StripePlanModel implements StripePlanType {
  @observable description: StripePlanType['description'] = '';
  @observable id: StripePlanType['id'] = '';
  @observable params: StripePlanParamsModel = new StripePlanParamsModel();
  @observable prices: StripePlanType['prices'] = [];
  @observable title: StripePlanType['title'] = '';

  constructor() {
      makeAutoObservable(this);
  }

  @action init = (item: Partial<StripePlanModel>) => {
      Object.keys(item).forEach((key) => {
          if (key === 'params') {
              this.params.init(item[ key ] as Record<string, any>);
          } else {
          // @ts-ignore
              this[ key ] = item[ key ];
          }
      });
  };
}
