import { action, makeAutoObservable, observable, set } from 'mobx';

export class SDKTokenModel {
  @observable key = '';
  @observable origin = '';
  @observable id = '';

  constructor() {
      makeAutoObservable(this);
  }

  @action init(data: Partial<SDKTokenModel>) {
      Object.keys(data).forEach((key) => {
          set(this, key, data[ key as keyof SDKTokenModel ]);
      });
  }
}
