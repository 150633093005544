import { BuilderState }                      from "@geenee/builder/src/core/state/builder.state";
import { VALID_EMAIL_REGEXP }                from "@geenee/builder/src/lib/constants";
import { inject, injectable, postConstruct } from "inversify";
import { action, makeObservable }            from "mobx";
import { MobxForm }                          from './mobx-form';

@injectable()
export class ForgotPassword extends MobxForm {
  @inject("<BuilderState>")
      builderState!: BuilderState;

  @postConstruct()
  initialize() {
      makeObservable(this);
  }

  setup(form) {
      this.form = form;

      this.fields = [
          {
              name:        'email',
              type:        'email',
              placeholder: 'Your Email Address',
              rules:       {
                  required: 'This field is required',
                  pattern:  {
                      value:   VALID_EMAIL_REGEXP,
                      message: "Invalid email address"
                  }
              }
          }
      ];

      this.hooks.onSubmit = this.onSubmit.bind(this);
  }

    @action
  async onSubmit(data: any, _e: React.SyntheticEvent) {
      this.setIsLoading(true);

      try {
          await this.builderState.forgotPass(data.email);
      } catch (error) {
          this.setIsLoading(false);

          this.setError('form', { type: 'custom', message: 'The credentials you’ve entered are incorrect.' });

          this.errorHandler(error);
      }

      this.setIsLoading(false);
  }
}
