import React, { useEffect }   from 'react';
import ChevronUp              from '../../../../asset/icons/ChevronUp';
import { SceneModelType }     from '../../model/scene.model';
import { JoystickController } from './JoyStickController';
import {
    ChevronDownWrapper, ChevronLeftWrapper,
    ChevronRightWrapper, ChevronUpWrapper, JoyStickRoot, StickBackground, StickBorder, StickRoot
} from './styles';

interface Props {
    sceneModel: SceneModelType
}

export function JoyStick({ sceneModel }: Props) {
    useEffect(() => {
        const myStick = new JoystickController('stick', 35, 8);
        sceneModel.joystick = myStick;
    }, []);

    return (
        <JoyStickRoot
            id="geenee-joystick"
        >
            <JoyStickChevronUp />
            <JoyStickChevronDown />
            <JoyStickChevronLeft />
            <JoyStickChevronRight />
            <StickRoot id="stick">
                <StickBorder />
            </StickRoot>
        </JoyStickRoot>
    );
}

function JoyStickChevronUp() {
    return (
        <ChevronUpWrapper>
            <ChevronUp />
        </ChevronUpWrapper>
    );
}

function JoyStickChevronDown() {
    return (
        <ChevronDownWrapper>
            <ChevronUp />
        </ChevronDownWrapper>
    );
}

function JoyStickChevronLeft() {
    return (
        <ChevronLeftWrapper>
            <ChevronUp />
        </ChevronLeftWrapper>
    );
}

function JoyStickChevronRight() {
    return (
        <ChevronRightWrapper>
            <ChevronUp />
        </ChevronRightWrapper>
    );
}
