/* eslint-disable max-len */
import React from 'react';

export const ArrowDown = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="stroke"
      d="M4 6L8 10L12 6"
      stroke="#303036"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const ArrowDown2 = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="stroke"
      d="M12 7L8 11L4 7"
      stroke="#111111"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="stroke"
      d="M8 10.5V1.5"
      stroke="#111111"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ArrowTop = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="stroke"
      d="M12 10L8 6L4 10"
      stroke="#303036"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ArrowLeft = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="stroke"
      d="M10 12L6 8L10 4"
      stroke="#303036"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const ArrowRight = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="stroke"
      d="M6 12L10 8L6 4"
      stroke="#303036"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ArrowCircle = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_22143:847)" filter="url(#filter0_d_22143:847)">
      <path
        d="M16.1384 28.7233C8.48277 28.7233 2.2767 22.5172 2.2767 14.8616C2.2767 7.20607 8.48277 0.999999 16.1384 0.999999C23.7939 1 30 7.20607 30 14.8616C30 22.5172 23.7939 28.7233 16.1384 28.7233Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M18.054 17.6607C17.7418 17.973 17.7418 18.4793 18.054 18.7916C18.3663 19.1038 18.8726 19.1038 19.1849 18.7916L22.3834 15.593C22.6957 15.2807 22.6957 14.7744 22.3834 14.4622L19.1849 11.2636C18.8726 10.9514 18.3663 10.9514 18.054 11.2636C17.7418 11.5759 17.7418 12.0822 18.054 12.3945L20.6871 15.0276L18.054 17.6607Z"
        fill="white"
      />
      <path
        d="M10.6231 15.8273L21.818 15.8273C22.2596 15.8273 22.6176 15.4693 22.6176 15.0276C22.6176 14.586 22.2596 14.228 21.818 14.228L10.6231 14.228C10.1815 14.228 9.82349 14.586 9.82349 15.0276C9.82349 15.4693 10.1815 15.8273 10.6231 15.8273Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_22143:847"
        x="0"
        y="0"
        width="32"
        height="32"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.516667 0 0 0 0 0.516667 0 0 0 0 0.516667 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_22143:847"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_22143:847"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_22143:847">
        <rect
          width="30"
          height="30"
          fill="white"
          transform="translate(31) rotate(90)"
        />
      </clipPath>
    </defs>
  </svg>
);
