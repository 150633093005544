import React, { FC, memo } from 'react';
import { cn }              from '../../util/bem';
import { Button }          from '../button/button.component';
import { Icon }            from '../icon/icon.component';
import './tabs-scroll-button.component.scss';

type TabsScrollButtonPropTypes = {
    direction: 'left' | 'right' | 'top' | 'bottom';
    visible: boolean;
    onClick: () => void;
};
export type Ref = HTMLButtonElement;

const getArrowIcon = (direction: TabsScrollButtonPropTypes['direction']) => {
    switch (direction) {
        case 'right':
            return 'arrowRight';
        case 'bottom':
            return 'arrowDown';
        case 'top':
            return 'arrowTop';
        case 'left':
        default:
            return 'arrowLeft';
    }
};

const className = cn('tabs-scroll-button');

export const TabsScrollButton: FC<TabsScrollButtonPropTypes> = memo(React.forwardRef<Ref, TabsScrollButtonPropTypes>((props, ref) => {
    if (!props.visible) {
        return null;
    }

    return (
        <Button
            className={ className() }
            ref={ ref }
            onClick={ props.onClick }
        >

            <Icon size={ 18 } style={ { padding: "8px 0" } } name={ getArrowIcon(props.direction) } />
        </Button>
    );
}));
