import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Divider, Wrapper }                                    from '@geenee/ui';
import { observer }                                            from 'mobx-react';
import { InputSearch }                                         from "@geenee/builder/src/components/LibraryModalTemplates/components/FilterRow/InputSearch";
import { LibraryFilter }                                       from "@geenee/builder/src/components/LibraryModalTemplates/components/FilterRow/LibraryFilter";
import { animValues }                                          from '@geenee/builder/src/components/SketchFabImporterModal/FiltersRow/SketchFabAnimValues';
import { sortList }                                            from '@geenee/builder/src/components/SketchFabImporterModal/FiltersRow/SketchFabSortValues';
import { useBuilderInject }                                    from '@geenee/builder/src/core/hook/use-builder-inject';
import { getSketchfabStoreKey, sketchfabState }                from '@geenee/builder/src/core/state/sketchfab.state';

interface Props {
    query: string;
    modelsLoading: boolean;
    handleQueryChange: (value: string) => void;
    page: number;
    onKeyChanged: (value: string) => void;
    disableInput?: boolean;
}

export interface FiltersSet {
  animated?: boolean;
}

export const FiltersRow = observer(({
    query,
    modelsLoading,
    handleQueryChange,
    page,
    onKeyChanged,
    disableInput
}: Props) => {
    const { BuilderState } = useBuilderInject();
    const [ inputValue, setInputValue ] = useState(query);
    const [ sort, setSort ] = useState(sketchfabState.lastQuery.sort || '');
    const [ filters, setFilters ] = useState<FiltersSet | null>(
        sketchfabState.lastQuery.filters || null
    );

    const handleKeyDown = (
        e: SyntheticEvent<HTMLInputElement, KeyboardEvent>
    ) => {
        if (e.nativeEvent.key === 'Enter') {
            handleQueryChange((e.target as HTMLInputElement).value);
        }
    };

    const filterValue = useMemo(() => {
        if (filters?.animated !== undefined) {
            return filters.animated ? '+' : '-';
        }
        return '';
    }, [ filters?.animated ]);

    useEffect(() => {
        // @ts-ignore
        const key = getSketchfabStoreKey(query, page, sort, filters);
        // if (!sketchfabState.fetchedQueryModels.get(key)) {
        sketchfabState.getModelsByQuery({ query, page, sort, filters }, BuilderState).then(() => {
            onKeyChanged(key);
        });
        // }
    }, [ query, sort, JSON.stringify(filters), page ]);

    return (
        <Wrapper
            radius="xxl"
            padding="xs"
            frame="solid-bg"
            row
            valign="center"
            align="space-between"
            style={ { fontSize: '12px' } }
        >
            <Wrapper row>
                <InputSearch
                    value={ inputValue }
                    onKeyDown={ handleKeyDown }
                    onChange={ (val) => setInputValue(val) }
                    disabled={ disableInput || modelsLoading }
                    margin="sm"
                />
                <Divider style={ { height: '32px', alignSelf: 'unset' } } color="shade-6" margin="sm" />
            </Wrapper>
            <Wrapper row maxWidth="fit-content">
                <LibraryFilter
                    filterData={ sortList }
                    label="Sort by"
                    onSelect={ (value) => !modelsLoading && setSort(value as string) }
                    value={ sort }
                />
                <Divider transparent margin="sm" />
                <LibraryFilter
                    filterData={ animValues }
                    label="Filter by"
                    onSelect={ (value) => !modelsLoading && setFilters(
                        value ? { animated: value === '+' } : null
                    ) }
                    value={ filterValue }
                />
            </Wrapper>
        </Wrapper>
    );
});
