import React from "react";
import {
    ErrorOption, FieldError, FieldErrorsImpl, FieldValues, RegisterOptions, useForm
} from "react-hook-form";
import { injectable }                                        from "inversify";
import { action, computed, makeObservable, observable, set } from "mobx";

export type FormType = Partial<ReturnType<typeof useForm>>;

export interface FieldModel {
  name: string;
  placeholder?: string;
  rules?: RegisterOptions;
  type?: "text" | "email" | "password" | "file" | "number" | "select";
}

@injectable()
export abstract class MobxForm {
  @observable isLoading = false;
  // loading = false;
  fields: FieldModel[] = [];

  form: FormType;

  @observable values: { [key: string]: any } = {};
  @observable hooks: {
    onSubmit: (
      data: FieldValues,
      e: React.SyntheticEvent,
      options: any
    ) => Promise<void>;
    onError?: (
      errors: Partial<FieldErrorsImpl<FieldError>>,
      e?: React.SyntheticEvent
    ) => void;
  } = {
          onSubmit: async (data: FieldValues, _e, _options) => {
              console.log("submit", data);
          },
          onError: async (errors, _e) => {
              await this.form.trigger();

              Object.keys(errors)
                  .forEach((key) => {
                      this.setError(key, {
                          type:    "custom",
                          message: errors[ key ].message
                      });

                      throw new Error(errors[ key ].message);
                  });
          }
      };

  constructor(form: FormType) {
      makeObservable(this);

      // observe(this, (change) => {
      //     console.log('MOBX Form changed', change);
      // });
  }

  @computed
  get errors() {
      return this.form.formState.errors;
  }

  @action
  bind(field: string, options: RegisterOptions = {}) {
      this.form.watch(field);

      return this.form.register(field, {
          ...options,
          onChange: async (e: React.ChangeEvent<HTMLInputElement>) => {
              await this.onChangeHandler(e, field);
          }
      });
  }

  get(key: string): FieldModel {
      return this.fields.find((field) => field.name === key);
  }

  @action
  setIsLoading(state: boolean) {
      this.isLoading = state;
  }

  @action
  setError(name: string, error: ErrorOption) {
      if (!error.message) {
          this.form.clearErrors(name);
      } else {
          this.form.setError(name, error);
      }
  }

  @action
  setValue(
      name: string,
      value: unknown,
      config?: { shouldValidate?: boolean; shouldDirty?: boolean }
  ) {
      this.form.setValue(name, value, config);
      set(this.values, name, value);
  }

  @action
  async onChangeHandler(
      event: React.ChangeEvent<HTMLInputElement>,
      field: string
  ) {
      if (this.errors) {
          this.form.clearErrors();
      }

      switch (event.target.type) {
          case "file":
              this.setValue(field, [ ...event.target.files ]);
              break;
          default:
              this.setValue(field, event.target.value);
              break;
      }
  }

  setup(form: FormType) {
      this.form = form;
  }

  @action
  errorHandler(error: string | Error) {
      if (error instanceof Error) {
          throw error;
      } else {
          throw new Error(error);
      }
  }

  @action submitHandler = async (e?: React.BaseSyntheticEvent, options = {}) => {
      this.errors.form && this.form.clearErrors("form");

      await this.form.handleSubmit(
          (data) => this.hooks.onSubmit(data, e, options),
          this.hooks.onError
      )(e);
  };

  @action
  async submit(e, options = {}) {
      try {
          await this.submitHandler(e, options);
      } catch (error) {
          this.errorHandler(error);
      }
  }
}
