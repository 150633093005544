import { useEffect, useState } from 'react';
import { useBuilderInject }    from '~/core/hook/use-builder-inject';
import AssetLibraryState       from '~/module/team-library/state/team-library.state';

export const useHandlers = () => {
    const [ isLoading, setIsLoading ] = useState(false);
    const { BuilderState } = useBuilderInject();

    const uploadModels = async (files) => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        // eslint-disable-next-line no-restricted-syntax
        for (const file of files) {
            const {
                first_name,
                last_name,
                email
            }        = BuilderState.currentUser.profile || {
                first_name: '',
                last_name:  '',
                email:      ''
            };
            const userName = first_name || last_name ? `${ first_name } ${ last_name }`.trim() : email;
            // eslint-disable-next-line no-await-in-loop
            await AssetLibraryState.createLibraryAsset(file, file.name, '', userName || 'Username');
        }
        setIsLoading(false);
    };

    useEffect(() => () => {
        AssetLibraryState.clearCache();
    }, []);

    return { uploadModels, isLoading };
};
