import React                from 'react';
import { TYPE_CUSTOM_CODE } from '@geenee/shared/src/magellan/components/contentView/constants';
import { Icon }             from '@geenee/ui';
import { useTheme }         from 'styled-components';
import ContentArAttachment  from '@geenee/builder/src/asset/icons/ContentArAttachment';
import ContentBasic         from '@geenee/builder/src/asset/icons/ContentBasic';
import ContentCustom        from '@geenee/builder/src/asset/icons/ContentCustom';
import ContentFandango      from '@geenee/builder/src/asset/icons/ContentFandango';
import ContentGallery       from '@geenee/builder/src/asset/icons/ContentGallery';
import ContentIframe        from '@geenee/builder/src/asset/icons/ContentIframe';
import ContentNew           from '@geenee/builder/src/asset/icons/ContentNew';
import ContentSceneBuild    from '@geenee/builder/src/asset/icons/ContentSceneBuild';
import ContentShare         from '@geenee/builder/src/asset/icons/ContentShare';
import ContentSignup        from '@geenee/builder/src/asset/icons/ContentSignup';
import ContentSlam          from '@geenee/builder/src/asset/icons/ContentSlam';
import ContentSpotify       from '@geenee/builder/src/asset/icons/ContentSpotify';
import ContentStamp         from '@geenee/builder/src/asset/icons/ContentStamp';
import ContentVideo         from '@geenee/builder/src/asset/icons/ContentVideo';
import Folder               from '@geenee/builder/src/asset/icons/Folder';
import ProductViewer        from '@geenee/builder/src/asset/icons/ProductViewer';
import TreeCircle           from '@geenee/builder/src/asset/icons/TreeCircle';
import Triggers             from '@geenee/builder/src/asset/icons/Triggers';
import ViewTree             from '@geenee/builder/src/asset/icons/ViewTree';
import { ElementType }      from '@geenee/builder/src/components/Tree/TreeNode';
import {
    CONTENT_AR_PROPERTY_NAME,
    FLAT_CONTENT_TYPES,
    NODE_TYPE_EXPERIENCE,
    NODE_TYPE_FOLDER,
    NODE_TYPE_PROGRAM,
    NODE_TYPE_PROJECT,
    NODE_TYPE_TRIGGERS,
    NODE_TYPE_VIEW,
    PROJECT_TYPES,
    TYPE_BODY_TRACKING_OVERLAY,
    TYPE_BODY_TRACKING_TWIN,
    TYPE_DRAWER_FANDANGO,
    TYPE_DRAWER_OVERLAY,
    TYPE_DRAWER_SHARE,
    TYPE_DRAWER_SIGNUP,
    TYPE_DRAWER_SPOTIFY,
    TYPE_FULLSCREEN_GALLERY,
    TYPE_FULLSCREEN_VIDEO,
    TYPE_HEAD_TRACKING,
    TYPE_IFRAME,
    TYPE_NATIVE_AR,
    TYPE_SCENE_BUILD,
    TYPE_SLAM_AR,
    TYPE_STAMP_AR, TYPE_WYSIWYG
} from '@geenee/builder/src/lib/constants';

export const useTreeNodeUtils = () => {
    const { secondaryColors } = useTheme();
    const setTypeColor = (type: string) => {
        const color = {
            fill:   'transparent',
            stroke: 'gray'
        };
        switch (type) {
            case NODE_TYPE_PROJECT:
            case NODE_TYPE_PROGRAM:
                color.fill = secondaryColors.blue50;
                color.stroke = secondaryColors.blue40;
                break;
            case NODE_TYPE_EXPERIENCE:
                color.fill = secondaryColors.green50;
                color.stroke = secondaryColors.green40;
                break;
            default:
                break;
        }

        return color;
    };

    const findNearestChildType = (children?: ElementType[] = []) => {
        const child = children.find((el) => FLAT_CONTENT_TYPES.includes(el.type || ''));
        return child?.type || 'view';
    };

    const getIcon = (
        type = '',
        collapsed: boolean,
        children?: ElementType[] = [],
        size = '16'
    ) => {
        if (PROJECT_TYPES.includes(type!)) {
            return (
                <TreeCircle
                    width={ size }
                    height={ size }
                    fill={ collapsed ? setTypeColor(type!).fill : 'transparent' }
                    stroke={ setTypeColor(type!).stroke }
                />
            );
        }
        let customIcon;
        const overridedType =            type === NODE_TYPE_VIEW ? findNearestChildType(children) : type;
        switch (overridedType) {
            case NODE_TYPE_TRIGGERS:
                customIcon = <Triggers width={ size } height={ size } />;
                break;
            case TYPE_CUSTOM_CODE:
            case TYPE_DRAWER_OVERLAY:
                customIcon = <ContentBasic width={ size } height={ size } />;
                break;
            case TYPE_DRAWER_SHARE:
                customIcon = <ContentShare width={ size } height={ size } />;
                break;
            case TYPE_DRAWER_SIGNUP:
                customIcon = <ContentSignup width={ size } height={ size } />;
                break;
            case TYPE_DRAWER_SPOTIFY:
                customIcon = <ContentSpotify width={ size } height={ size } />;
                break;
            case TYPE_DRAWER_FANDANGO:
                customIcon = <ContentFandango width={ size } height={ size } />;
                break;
            case TYPE_FULLSCREEN_GALLERY:
                customIcon = <ContentGallery width={ size } height={ size } />;
                break;
            case NODE_TYPE_FOLDER:
                customIcon = (
                    <Folder
                        width={ size }
                        height={ size }
                        stroke={
                            collapsed
                                ? secondaryColors.pink40
                                : secondaryColors.blue40
                        }
                    />
                );
                break;
            case TYPE_FULLSCREEN_VIDEO:
                customIcon = <ContentVideo width={ size } height={ size } />;
                break;
            case TYPE_IFRAME:
                customIcon = <ContentIframe width={ size } height={ size } />;
                break;
            case TYPE_SCENE_BUILD:
                customIcon = <ContentSceneBuild width={ size } height={ size } />;
                break;
            case TYPE_NATIVE_AR:
                customIcon = <Icon name="productViewerCircle" />;
                break;
            case TYPE_SLAM_AR:
                customIcon = <Icon name="worldArCircle" />;
                break;
            case TYPE_BODY_TRACKING_OVERLAY:
            case TYPE_BODY_TRACKING_TWIN:
                customIcon = <Icon name="bodytrackingCircle" />;
                break;
            case TYPE_HEAD_TRACKING:
                customIcon = <Icon name="facetrackingCircle" />;
                break;
            case CONTENT_AR_PROPERTY_NAME:
                customIcon = <ContentArAttachment width={ size } height={ size } />;
                break;
            case TYPE_STAMP_AR:
                customIcon = <Icon name="markerArCircle" />;
                break;
            case TYPE_WYSIWYG:
                customIcon = <ContentCustom width={ size } height={ size } />;
                break;
            case NODE_TYPE_VIEW:
                customIcon = <ViewTree width={ size } height={ size } />;
                break;
            default:
                customIcon = <ContentNew width={ size } height={ size } />;
                break;
        }
        return customIcon;
    };

    return { setTypeColor, getIcon };
};
