/* eslint-disable max-len */
import React from 'react';

export const Folder = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 3.75C3.75736 3.75 2.75 4.75736 2.75 6V18C2.75 19.2426 3.75736 20.25 5 20.25H19C20.2426 20.25 21.25 19.2426 21.25 18V10C21.25 8.75736 20.2426 7.75 19 7.75H14.8284C14.0991 7.75 13.3996 7.46027 12.8839 6.94454L10.0555 4.11612C9.82104 3.8817 9.50309 3.75 9.17157 3.75H5ZM1.25 6C1.25 3.92893 2.92893 2.25 5 2.25H9.17157C9.90092 2.25 10.6004 2.53973 11.1161 3.05546L13.9445 5.88388C14.179 6.1183 14.4969 6.25 14.8284 6.25H19C21.0711 6.25 22.75 7.92893 22.75 10V18C22.75 20.0711 21.0711 21.75 19 21.75H5C2.92893 21.75 1.25 20.0711 1.25 18V6Z"
            fill="#303036"
            className="fill"
        />
    </svg>
);

export const AddFolder = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 10.25C12.4142 10.25 12.75 10.5858 12.75 11V13.25H15C15.4142 13.25 15.75 13.5858 15.75 14C15.75 14.4142 15.4142 14.75 15 14.75H12.75V17C12.75 17.4142 12.4142 17.75 12 17.75C11.5858 17.75 11.25 17.4142 11.25 17V14.75H9C8.58579 14.75 8.25 14.4142 8.25 14C8.25 13.5858 8.58579 13.25 9 13.25H11.25V11C11.25 10.5858 11.5858 10.25 12 10.25Z"
            fill="#303036"
            className="fill"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 2.25C2.92893 2.25 1.25 3.92893 1.25 6V18C1.25 20.0711 2.92893 21.75 5 21.75H19C21.0711 21.75 22.75 20.0711 22.75 18V10C22.75 7.92893 21.0711 6.25 19 6.25H14.8284C14.4969 6.25 14.179 6.1183 13.9445 5.88388L11.1161 3.05546C10.6004 2.53973 9.90092 2.25 9.17157 2.25H5ZM2.75 6C2.75 4.75736 3.75736 3.75 5 3.75H9.17157C9.50309 3.75 9.82104 3.8817 10.0555 4.11612L12.8839 6.94454C13.3996 7.46027 14.0991 7.75 14.8284 7.75H19C20.2426 7.75 21.25 8.75736 21.25 10V18C21.25 19.2426 20.2426 20.25 19 20.25H5C3.75736 20.25 2.75 19.2426 2.75 18V6Z"
            fill="#303036"
            className="fill"
        />
    </svg>
);
