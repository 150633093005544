import { useContext, useEffect, useMemo }     from 'react';
import { useSpotifyHandlers }                 from '@geenee/builder/src/components/AssetsSourcePicker/hooks/useSpotifyHandlers';
import { useReadyPlayerMeModal }              from '@geenee/builder/src/components/modals/ReadyPlayerMeModal/useReadyPlayerMeModal';
import { useHandlers as useSketchfabHandler } from '@geenee/builder/src/components/SketchFabImporter/useHandlers';
import { useBuilderInject }                   from "@geenee/builder/src/core/hook/use-builder-inject";
import { spotifyState }                       from '@geenee/builder/src/core/state/spotify.state';
import { stripeState }                        from '@geenee/builder/src/core/state/stripe.state';
import { TYPE_NATIVE_AR }                     from '@geenee/builder/src/lib/constants';
import ModalUpdaterContext                    from '@geenee/builder/src/lib/ModalUpdaterContext';
import { SourceTypes, useSceneAssetsActions } from '@geenee/builder/src/magellan/hooks/useSceneAssetsActions';
import { SectionModel }                       from '@geenee/builder/src/magellan/model/section.model';
import { useGifLibraryModal }                 from '@geenee/builder/src/module/gif-library/modal/useGifLibraryModal';
import { useTeamLibraryModal }                from '@geenee/builder/src/module/team-library/modal/useTeamLibrary.modal';
import { useInject }                          from '@geenee/shared';

interface Props {
    mode?: 'add' | 'replace',
    atomId?: string,
    sourcesFilter?: (p: any) => boolean,
}
export const useAssetSourceConfig = ({ atomId = '', mode = 'add', sourcesFilter = (s) => s }: Props) => {
    const uploaderId =  `add-assets-children-${ atomId }-${ mode }`;
    const { BuilderState } = useBuilderInject();
    const { AppState } = useInject();
    const { activeSection } = AppState;
    const setModalOptions = useContext(ModalUpdaterContext);
    const { activeMolecule } = activeSection || {} as SectionModel;
    const { choosedTrack } = spotifyState;
    const isNativeAR = activeSection?.type === TYPE_NATIVE_AR;

    useEffect(() => {
        BuilderState.changeSketchfabMode('default');
        BuilderState.changeTeamLibraryMode('default');
    }, []);
    const {
        calculateExperienceSize, replaceSceneAsset, onImportFromLibrary, isLoading, addSceneAsset, addText3DAsset,
        isValidOverallAssetsSize, accessibleTypes
    } = useSceneAssetsActions(undefined, true);

    // TODO: double-check how to pass scene-actor parameter and make a nicer check
    const currentFileHandler = (v: {file?: File, url?: string, source: SourceTypes}) => (mode === 'add'
        ? addSceneAsset({ ...v })
        : replaceSceneAsset({ atomId: atomId || '', ...v }));

    const [ showAssetModal ] = useTeamLibraryModal(
        (assets) => onImportFromLibrary(assets, mode, atomId),
        calculateExperienceSize()
    );

    const uploadHandler = (file) => currentFileHandler({ file, source: 'scene' });

    const { onModelImport } = useSketchfabHandler(
        // @ts-ignore
        (value) => currentFileHandler({ file: value.model, source: 'sketchfab' }),
        setModalOptions,
        isValidOverallAssetsSize
    );

    const { showModal: showGifModal } = useGifLibraryModal((file) => currentFileHandler({ file, source: 'tenor' }));
    const { showModal: showRpmModal } = useReadyPlayerMeModal((url) => currentFileHandler({ url, source: 'readyPlayerMe' }));
    const { onSpotifyButtonClick } = useSpotifyHandlers(activeMolecule, choosedTrack);

    const itemsConfig = useMemo(
        () => [
            {
                iconLabel: 'uploadCloud',
                label:     'Upload',
                id:        'uploadCloud',
                onClick:   () => {
                    const uploader = document.getElementById(uploaderId);
                    if (uploader) {
                        setTimeout(() => {
                            uploader.click();
                        }, 0);
                    }
                }
            },
            {
                iconLabel: 'library',
                label:     'From Team Library',
                id:        'library',
                onClick:   () => {
                    BuilderState.changeTeamLibraryMode('default');
                    showAssetModal();
                },
                hidden: !stripeState.isActiveTeamOrEnterprise
            },
            { id: 'separator' },
            {
                iconLabel: 'text3d',
                label:     'From 3D Text Creator',
                id:        'text3d',
                onClick:   () => {
                    addText3DAsset();
                }
            },
            {
                iconLabel: 'sketchfab',
                label:     'From Sketchfab',
                id:        'sketchfab',
                onClick:   async () => {
                    await onModelImport();
                }
            },
            {
                iconLabel: 'tenor',
                label:     'From GIFs by Tenor',
                id:        'tenor',
                onClick:   showGifModal,
                hidden:    isNativeAR
            },
            {
                iconLabel: 'readyPlayerMe',
                label:     'From RPM Avatar Library',
                id:        'readyPlayerMe',
                onClick:   showRpmModal
            },
            {
                iconLabel: 'spotify',
                label:     'From Spotify Embed',
                id:        'spotify',
                onClick:   onSpotifyButtonClick,
                hidden:    isNativeAR
            },
            {
                iconLabel: 'customCode',
                label:     'From Snippet',
                id:        'snippet',
                onClick:   () => {
                    BuilderState.changeTeamLibraryMode('snippet');
                    showAssetModal();
                },
                hidden: isNativeAR
            }
        ].filter((el) => !el.hidden).filter(sourcesFilter),
        [ onModelImport, showGifModal, showRpmModal, onSpotifyButtonClick, mode ]
    );

    return {
        itemsConfig, uploadHandler, uploaderId, isLoading, uploadTypes: accessibleTypes
    };
};
