import React                                 from "react";
import { BuilderState }                      from "@geenee/builder/src/core/state/builder.state";
import { VALID_EMAIL_REGEXP }                from "@geenee/builder/src/lib/constants";
import { inject, injectable, postConstruct } from "inversify";
import { action, makeObservable }            from "mobx";
import { MobxForm }                          from "./mobx-form";

export interface SubmitData {
  email: string;
  password: string;
}

@injectable()
export class Login extends MobxForm {
  @inject("<BuilderState>")
      builderState!: BuilderState;

  @postConstruct()
  initialize() {
      makeObservable(this);
  }

  setup(form) {
      this.form = form;
      this.fields = [
          {
              name:        "email",
              type:        "email",
              placeholder: "Your Email Address",
              rules:       {
                  required: "This field is required",
                  pattern:  {
                      value:   VALID_EMAIL_REGEXP,
                      message: "Invalid email address"
                  }
              }
          },
          {
              name:        "password",
              type:        "password",
              placeholder: "Your Password",
              rules:       { required: "This field is required" }
          }
      ];
      this.hooks.onSubmit = this.onSubmit.bind(this);
  }

  @action
  async onSubmit(data: SubmitData, _e: React.SyntheticEvent, options: { nextPath: string }) {
      this.setIsLoading(true);

      try {
          await this.builderState.login(
              data.email.toLowerCase(),
              data.password,
              options.nextPath
          );
      } catch (error) {
          this.setIsLoading(false);

          this.setError("form", {
              type:    "custom",
              message: "The credentials you’ve entered are incorrect."
          });

          this.errorHandler(error);
      }

      this.setIsLoading(false);
  }
}
