import React, { useMemo, useState } from 'react';
import { Wrapper }                  from '@geenee/ui';
import isEmpty                      from 'lodash-es/isEmpty';
import { DowngradeDataProps }       from '@geenee/builder/src/@custom_types/types';
import StyledButton                 from '@geenee/builder/src/components/common/Button';
import { Payment as PaymentModal }  from '../Payment';
import { Header }                   from './components/Header';
import { MembersSelectorRow }       from './components/MembersSelectorRow';
import { ProjectsSelectorRow }      from './components/ProjectsSelectorRow';
import { useDowngradeRemoveData }   from './hooks/useDowngradeRemoveData';
import { DefaultWrapper, Title }    from './styles';

type Props = {
    priceId: string;
    // eslint-disable-next-line react/no-unused-prop-types
    close: () => void;
    // eslint-disable-next-line no-empty-pattern
    setModal: ({ }) => void;
    preDowngradeData: DowngradeDataProps;
}

const isSubmitDisabled = (
    bothProjectAndMembers: boolean,
    projects: any[] | undefined,
    members: any[] | undefined,
    selectedProjects: string[],
    selectedMembers: string[]
) => {
    if (bothProjectAndMembers) {
        return (isEmpty(selectedProjects) || isEmpty(selectedMembers));
    }
    if (!isEmpty(projects) && isEmpty(selectedProjects)) {
        return true;
    }
    if (!isEmpty(members) && isEmpty(selectedMembers)) {
        return true;
    }
    return false;
};

export function PreDowngrade({ priceId, setModal, preDowngradeData }: Props) {
    const [ selectedProjects, setSelectedProjects ] = useState<string[]>([]);
    const [ selectedMembers, setSelectedMembers ] = useState<string[]>([]);

    const {
        planName, maxMembers, members, maxProjects, projects
    } = preDowngradeData;

    const disabledProjectSelection = maxProjects === selectedProjects.length;
    const disabledMemberSelection = maxMembers === selectedMembers.length;

    const removeData = useDowngradeRemoveData(
        selectedProjects,
        selectedMembers,
        projects,
        members
    );

    const onSelectProjects = (value: any) => {
        if (selectedProjects.includes(value)) {
            setSelectedProjects([ ...selectedProjects.filter((o) => o !== value) ]);
        } else {
            if (disabledProjectSelection) return;
            setSelectedProjects([ ...selectedProjects, value ]);
        }
    };

    const onSelectMembers = (value: any) => {
        if (selectedMembers.includes(value)) {
            setSelectedMembers([ ...selectedMembers.filter((o) => o !== value) ]);
        } else {
            if (disabledMemberSelection) return;
            setSelectedMembers([ ...selectedMembers, value ]);
        }
    };

    const onClickDowngrade = () => {
        setModal({
            component: PaymentModal,
            props:     {
                priceId,
                type:                 'DOWNGRADE',
                removeAfterDowngrade: removeData
            }
        });
    };

    const bothProjectAndMembers = (maxMembers !== undefined && maxProjects !== undefined);
    const projectsList = useMemo(() => projects?.map((project) => ({ value: project.id, label: project.title })), [ projects ]);
    const membersList = useMemo(() => members?.map((member) => ({
        value: member.email,
        label:
          `${ member.first_name } ${ member.last_name }`.trim() || member.email
    })), [ members ]);

    const btnDisabled = isSubmitDisabled(bothProjectAndMembers, projects, members, selectedProjects, selectedMembers);

    return (
        <DefaultWrapper>
            <Wrapper align="center">
                <Title>DOWNGRADE</Title>
                <Header
                    maxMembers={ maxMembers }
                    maxProjects={ maxProjects }
                    planName={ planName }
                    bothProjectAndMembers={ bothProjectAndMembers }
                />
                { /* { maxProjects && (
                    <ProjectsSelectorRow
                        disabledProjectSelection={ disabledProjectSelection }
                        projectsList={ projectsList }
                        selectedProjects={ selectedProjects }
                        onSelectProjects={ onSelectProjects }
                    />
                ) } */ }
                { members && (
                    <MembersSelectorRow
                        disabledMemberSelection={ disabledMemberSelection }
                        membersList={ membersList }
                        selectedMembers={ selectedMembers }
                        onSelectMembers={ onSelectMembers }
                    />
                ) }
                <Wrapper align='center' style={ { marginTop: 38 } }>
                    <StyledButton
                        label="DOWNGRADE NOW"
                        $width='223px'
                        onClick={ () => onClickDowngrade() }
                        disabled={ btnDisabled }
                    />
                </Wrapper>
            </Wrapper>
        </DefaultWrapper>
    );
}
