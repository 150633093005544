import APIRequest              from '~/lib/APIRequest';
import envConfig               from '~/lib/envConfig';
import { GifLibraryItemModel } from '~/module/gif-library/models/GifLibraryItem.model';

const BASE_URL = `https://g.tenor.com/v1/search?searchfilter=sticker&key=${ envConfig.TENOR_KEY }`;
const TRENDING_URL = `https://g.tenor.com/v1/trending?searchfilter=sticker&key=${ envConfig.TENOR_KEY }`;
const getByQuery = (
    query: string,
    pos: string
): Promise<{ next: string; results: GifLibraryItemModel[] }> => APIRequest.request(
    'get',
    `${ BASE_URL }&q=${ query }${ pos ? `&pos=${ pos }` : '' }`,
    { external: true }
);
const getTrending = (
    pos: string
    // eslint-disable-next-line max-len
): Promise<{ next: string; results: GifLibraryItemModel[] }> => APIRequest.request('get', `${ TRENDING_URL }${ pos ? `&pos=${ pos }` : '' }`, { external: true });

export const GifLibraryApi = { getByQuery, getTrending };
