import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Scrollbars }                                      from 'react-custom-scrollbars';
import { DndProvider }                                     from 'react-dnd';
import { HTML5Backend }                                    from 'react-dnd-html5-backend';
import cloneDeep                                           from 'lodash-es/cloneDeep';
import Move                                                from '@geenee/builder/src/asset/icons/Move';
import Trash                                               from '@geenee/builder/src/asset/icons/Trash';
import { DropWrapper, ListItemProps }                      from '@geenee/builder/src/components/DropWrapper';
import SingleItem                                          from '@geenee/builder/src/components/ImageGallery/SingleItem';
import { MediaActions, MediaContainer }                    from '@geenee/builder/src/components/ImageGallery/styles';
import { MediaItemContainer }                              from '@geenee/builder/src/components/modals/AddNFTDetails/styles';
import { MediaWrapper }                                    from './styles';

interface Props {
  images: any[];
  handleDelete: (id: string, name: string | undefined, size: number | undefined) => void;
  onDrop: (files: File[], monitor: any) => void;
  handleReorder: (items: ListItemProps[]) => void;
}

export function MediaFiles({ images, handleDelete, onDrop, handleReorder }: Props) {
    const [ focusedMedium, setFocusedMedium ] = useState<string | null>(
        null
    );
    const [ readyToMoveImage, setReadyToMoveImage ] = useState<string | null>(null);
    const [ listItems, setListItems ] = useState(images);
    const mediaRef = useRef<HTMLDivElement>(null);
    const actionsRef = useRef<HTMLDivElement>(null);

    const checkClickOutside = (event: any) => {
        try {
            // @ts-ignore
            if (mediaRef.current.contains(event.target) || actionsRef.current.contains(event.target)) {
                return;
            }
            setFocusedMedium(null);
        } catch (e: any) {
            // @ts-ignore
            console.log(e.message);
        }
    };

    const handleFocus = (identifier: string) => {
        setFocusedMedium(identifier);
    };

    const handleImageDelete = (uniqeIdentifier: string, name: string, size: number) => {
        setReadyToMoveImage(null);
        handleDelete(uniqeIdentifier, name, size);
    };

    const handleMoveSelect = (id: string | null) => {
        if (readyToMoveImage === id) {
            setReadyToMoveImage(null);
        } else {
            setReadyToMoveImage(id);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', checkClickOutside, false);
        return () => document.removeEventListener('mousedown', checkClickOutside, false);
    });

    useEffect(
        () => {
            setListItems(images);
        },
        [ images ]
    );

    /* const handleReorder = (newListitemsArray: ListItemProps[]) => {
        /!* newListitemsArray.forEach(({ id, order }) => {
            if (id) {
                const imageItem = activeSection.image_attachments.find((el) => el.id === id);
                if (imageItem) {
                    imageItem.order = order;
                    (imageItem as AttachmentModel).saveData();
                }
            }
        }); *!/
    }; */

    const moveItem = useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = listItems[ dragIndex ];
            const modifiedItemsSet = cloneDeep(listItems);
            modifiedItemsSet.splice(dragIndex, 1);
            modifiedItemsSet.splice(hoverIndex, 0, dragCard);
            setListItems([ ...modifiedItemsSet ]);
        },
        [ listItems ]
    );

    const updateListItemsOrder = useCallback((newOrder, id) => {
        const newListitemsArray = cloneDeep(listItems).map(
            (item: ListItemProps, i) => {
                if (!item.id) {
                    return {
                        order:    i,
                        oldOrder: item.order
                    };
                }
                return { id: item.id, order: i, oldOrder: item.order };
            }
        );
        // Update selected item references if needed
        let selectedId = id;
        if (id.includes('+')) {
            const strArray = id.split('+');
            strArray[ strArray.length - 1 ] = newOrder;
            selectedId = strArray.join('+');
        }
        setReadyToMoveImage(selectedId);
        setFocusedMedium(selectedId);

        // Return reorder result
        handleReorder(newListitemsArray as any);
    }, [ listItems ]);

    return (
        <DndProvider backend={ HTML5Backend }>
            <DropWrapper
                multiple
                onDrop={ onDrop }
                // rootContainer={ Container }
            >
                <Scrollbars
                    style={ { width: '100%', marginTop: '22px' } }
                    autoHeight
                    autoHeightMin={ 0 }
                    autoHeightMax={ 300 }
                >
                    <MediaContainer>
                        { listItems.map(
                            ({
                                url, id, order, name, size
                            }, idx) => {
                                const uniqeIdentifier = id || `${ idx }+${ order }`;
                                const isFocused = focusedMedium === uniqeIdentifier;
                                const canMove = readyToMoveImage === uniqeIdentifier;

                                return (
                                    <MediaWrapper key={ uniqeIdentifier } ref={ mediaRef }>
                                        { (isFocused || canMove) && handleDelete && (
                                            <MediaActions ref={ actionsRef } moveSelected={ canMove } left>
                                                { /* eslint-disable-next-line react/button-has-type */ }
                                                <button className='move-btn' onClick={ () => handleMoveSelect(uniqeIdentifier) }>
                                                    <Move width="10" height="10" />
                                                </button>
                                                { // @ts-ignore
                                                    handleDelete && (
                                                    // eslint-disable-next-line react/button-has-type
                                                        <button onClick={ () => handleImageDelete(uniqeIdentifier, name, size) }>
                                                            <Trash width="10" height="10" />
                                                        </button>
                                                    )
                                                }
                                            </MediaActions>
                                        ) }
                                        <SingleItem
                                            mediaContainer={ MediaItemContainer }
                                            setFocus={ handleFocus }
                                            id={ uniqeIdentifier }
                                            index={ idx }
                                            isFocused={ isFocused }
                                            canMove={ canMove }
                                            url={ url }
                                            order={ order }
                                            moveItem={ moveItem }
                                            updateListItemsOrder={ updateListItemsOrder }
                                        />
                                    </MediaWrapper>
                                );
                            }
                        ) }
                    </MediaContainer>
                </Scrollbars>
            </DropWrapper>
        </DndProvider>
    );
}
