// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-redeclare
/* global fetch, Headers, AUTH_URL */

import {
    API_URL,
    APIREQUEST_AUTH_SUFFIX
} from '@geenee/builder/src/lib/constants';
import joinPath from '@geenee/builder/src/lib/joinPath';

/**
 * A generic API request module using the fetch API.
 *
 * This module is used for communicating with external APIs in server and
 * browser environments.
 *
 * In a server environment, a polyfill for the fetch() API is required.
 *
 * In case of failures, it expects a JSON payload containing an object with an
 * "error" and optional "message" key. When "error" is an object, it is being
 * serialized as JSON (see handleResponse).
 *
 * @module apiRequest
 */

/**
 * Constructs a human-readable string from a JSON Object that follows the application/problem+json specification.
 * @method formatProblem
 * @param {Object} problem
 * @returns {string}
 */
export const formatProblem = (status, problem) => {
    let message = status === 401 ? `${ status }` : '';

    if (typeof problem === 'string' && problem.length > 0) {
        message += `${ problem }`;
    } else {
        if (typeof problem.title === 'string' && problem.title.length > 0) {
            message += `${ problem.title }`;
        }
        if (typeof problem.detail === 'string' && problem.detail.length > 0) {
            message += `${ problem.detail }`;
        }
        if (problem.errors && problem.errors.length) {
            problem.errors.forEach((err, index) => {
                message
                    += index === problem.errors.length - 1
                        ? err.msg
                        : `${ err.msg } \n`;
            });
        }
    }

    return message;
};
export const HTTP_METHODS = 'post get put patch del'.split(' ');

export const RGX_HTTP_OK = /^2\d+/; // Regular expression to check for HTTP 2xx response codes
const RGX_JSON_MIME = /^application\/(problem\+)?json/;
export const HTTP_METHODS_WITH_BODY = [ 'post', 'put', 'delete' ];
const DEFAULT_MIME_TYPE = 'application/json';

// /**
//  * @class APIRequest
//  * @example
//  * Create a bound call to this.request for HTTP_METHODS:
//  *
//  * apiRequest.post(url, body, options).then(..., ...);
//  * apiRequest.get(url, options).then(..., ...);
//  * apiRequest.patch(url, body, options).then(..., ...);
//  * apiRequest.put(url, body, options).then(..., ...);
//  * apiRequest.del(url, options).then(..., ...);
//  */

class APIRequest {
    /**
     * @constructor
     */
    constructor() {
        /**
         * Authentication headers.
         * @type {string|null}
         */
        this.authHeaders = [];

        /**
         * Reference to a callback method for 401 responses.
         * @method function
         */
        this._onUnauthorized = null;

        /**
         * Whether to log all requests.
         * @type {boolean}
         */
        this.logging = false;

        HTTP_METHODS.forEach((method) => {
            this[ method ] = (url, ...args) => this.request(
                method === 'del' ? 'delete' : method,
                url,
                ...args
            );
        });

        this.tenantSlug = () => {
            const url = window.location.hostname;

            /**
             * mocked. because no exported constant APIREQUEST_HOSTNAME_MATCH_SLUG
             *  in '~/lib/constants' ┌( ಠ_ಠ)┘
             */
            const APIREQUEST_HOSTNAME_MATCH_SLUG = '';

            const slugIdx = url.indexOf(APIREQUEST_HOSTNAME_MATCH_SLUG);
            const tenant = {
                authUrl: '',
                apiUrl:  API_URL
            };
            /**
             * Check if app is opened from the valid url, othervise
             * consider it's running on local machine.
             */
            if (slugIdx === -1) {
                tenant.authUrl = process.env.ENV_AUTH_URL;
            } else {
                const name = url.slice(0, slugIdx);
                const domain = url.slice(
                    slugIdx + APIREQUEST_HOSTNAME_MATCH_SLUG.length
                );

                tenant.authUrl = `https://${ name }${ APIREQUEST_AUTH_SUFFIX }.${ domain }`;
            }
            return tenant;
        };
    }

    /**
     * @method setAuthHeaders
     * @param {Object[]} authHeaders
     */
    setAuthHeaders(authHeaders) {
        this.authHeaders = authHeaders || [];
    }

    /**
     * @method setOnUnauthorized
     * @param {function|null} fn
     */
    setOnUnauthorized = (fn = null) => {
        this._onUnauthorized = fn;
    };

    /**
     * Returns an options object for fetch. Depending on whether the provided method
     * is included in HTTP_METHODS_WITH_BODY, this method takes two or three arguments.
     *
     * @method getOptions
     * @param {string} method
     * @param {Array} args
     */
    getOptions(method, args) {
        // eslint-disable-next-line no-void
        let body = void 0;

        // When method is contained in HTTP_METHODS_WITH_BODY, "body" is the next
        // argument after url.

        // eslint-disable-next-line no-bitwise
        if (~HTTP_METHODS_WITH_BODY.indexOf(method.toLowerCase())) {
            body = args.shift();
        }

        // Options are always the last argument, set to empty map if undefined.
        const options = args.shift() || {};

        let headers = options.headers || {};

        if (!(headers instanceof Headers)) {
            headers = new Headers(headers);
        }

        // Do not modify given body and options parameters if options.raw is set to
        // true. This is necessary for performing requests to 3rd party APIs

        if (options.raw !== true) {
            // eslint-disable-next-line no-bitwise
            if (~HTTP_METHODS_WITH_BODY.indexOf(method)) {
                if (headers.get('Content-Type') === 'multipart/form-data') {
                    // https://stackoverflow.com/a/39281156
                    // Browsers use their own random boundary when building a request that uses FormData
                    // You need to pass the boundary in the header, but you can't when it's random
                    headers.delete('Content-Type');
                } else if (!headers.get('Content-Type')) {
                    // Add "application/json" Content-type header when nothing else was defined.
                    headers.append('Content-Type', DEFAULT_MIME_TYPE);
                }
            }

            // Append all authentication headers in case they has been set through `setAuthHeaders`
            // and `options.auth` is not false.
            const { authHeaders } = this;
            if (
                options.auth !== false
                && Array.isArray(authHeaders)
                && authHeaders.length
            ) {
                // eslint-disable-next-line no-plusplus
                for (let i = 0, l = authHeaders.length; i < l; ++i) {
                    headers.append(...authHeaders[ i ]);
                }
            }
            // we don't want to stringify the body when the type is 'application/x-www-form-urlencoded'
            // eslint-disable-next-line max-len
            const isBodyUrlEncoded = options.headers && options.headers[ 'Content-Type' ] && options.headers[ 'Content-Type' ].indexOf('application/x-www-form-urlencoded') !== -1;

            // In case "body" is an object or array, serialize it beforehand
            if (!isBodyUrlEncoded && options.json !== false && typeof body === 'object') {
                body = JSON.stringify(body);
            }
        }

        return {
            method: method.toUpperCase(),
            ...options,
            headers,
            body
        };
    }

    /**
     * @method onFailure
     * @param {string} method
     * @param {string} url
     * @param {string} message
     * @returns {Promise}
     */
    onFailure = (method, url, message, cause) => {
        const debugErrorText = `Error while ${ method.toUpperCase() } ${ url }: ${ message }`;
        if (process.env.ENV_NODE_ENV !== 'test') {
            console.error(debugErrorText);
        }
        return Promise.reject(new Error(message || 'Error while request url', { cause }));
    };

    /**
     * @method onUnauthorized
     * @param {string} method
     * @param {string} path
     */
    onUnauthorized = (method, path) => {
        if (typeof this._onUnauthorized === 'function') {
            this._onUnauthorized(method, path);
        }
    };

    /**
     * Validates the returned promise from the fetch() call.
     * In case the server returned an HTTP 2xx code, the returned payload will be
     * decoded, otherwise, the error is being handled.
     *
     * @method handleResponse
     * @param {Object} response
     * @return {Object|Promise}
     * @see https://github.com/github/fetch#handling-http-error-statuses
     */
    handleResponse = (method, url, response, options) => {
        const statusCode = response.status;
        const { headers } = response;
        const contentType = headers.get('Content-Type');

        const usedAuthHeader = options.headers.get('authorization');
        const currentAuthHeader = (this.authHeaders.find((pair) => /(A|a)uthorization/.test(pair[ 0 ])) || [ null, null ])[ 1 ];

        // GNEE-814 Do not trigger onUnauthorized if meanwhile the token changed
        if (statusCode === 401 && usedAuthHeader === currentAuthHeader) {
            this.onUnauthorized(method, url);
        }

        /**
         * Decode the returned payload.
         */
        let defer = null;
        if (options && options.responseType === 'arraybuffer') {
            defer = response.arrayBuffer();
        } else if (options.json || RGX_JSON_MIME.test(contentType)) {
            defer = response.json();
        } else {
            defer = response.text();
        }

        /**
         * Constructs a meaningful error message from an error response.
         * This module expects a JSON payload containing an object with an "error" and optional "message" key.
         * When "error" is an object, it is being serialized as JSON.
         *
         * @method formatErrorMessage
         * @param {string} statusText
         * @param {Object} body
         * @private
         * @return {string}
         */
        const formatErrorMessage = (statusText, body) => {
            const msg = `Response: ${ statusText }\n${ body }`;
            return msg;
        };
        return defer.then(
            (body) => {
                if (RGX_HTTP_OK.test(statusCode)) {
                    return body;
                }
                const decodedMessage = this.decodeBufferMessage(body);
                return this.onFailure(
                    method,
                    url,
                    formatProblem(statusCode, decodedMessage || body),
                    { status: body?.status, ...body?.invalid_params && { invalid_params: body.invalid_params } }
                );
            },
            (error) => {
                if (error instanceof Error) {
                    return this.onFailure(
                        method,
                        url,
                        error.message || error.stack
                    );
                }
                // The response couldn't be decoded properly
                return this.onFailure(
                    method,
                    url,
                    formatErrorMessage(response.statusText, error)
                );
            }
        );
    };

    decodeBufferMessage = (body) => {
        if (body instanceof ArrayBuffer) {
            const encoder = new TextDecoder('utf-8');
            return encoder.decode(body);
        }
        return '';
    };

    /**
     * Returns a promise, which will throw an error in case of non 2xx responses
     * (and also in other error cases).
     *
     * Always assumes a JSON response (which is parsed in case the request was
     * successful).
     *
     * @method request
     * @param {string} method
     * @param {string} url
     * @param {Array} args
     * @returns {Promise}
     */
    request(method, requestUrl, ...args) {
        const options = this.getOptions(method, args);
        const url = options.external ? requestUrl : this.rewrite(requestUrl);
        if (this.logging) {
            if (
                typeof console !== 'undefined'
                && typeof console.log === 'function'
            ) {
                console.log(`${ method.toUpperCase() } ${ url }`);
            }
        }

        return fetch(url, options) // Handle network errors immediately
            .catch((err) => this.onFailure(method, url, err.message || err))
            .then((response) => this.handleResponse(method, url, response, options));
    }

    /**
     * @method rewrite
     * @param  {String} path
     * @return {String}
     */
    rewrite(path) {
        /**
         * Use the authentication service's url directly, bypassing
         * the API gateway.
         */
        const tenant = this.tenantSlug();
        const { authUrl } = tenant;

        if (path === '/api/v0/token') {
            return joinPath(authUrl, path);
        }
        return joinPath(API_URL, path);
    }
}

/**
 * APIRequest is exported as singleton.
 */
// eslint-disable-next-line arca/no-default-export
export default new APIRequest();
