import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Tools }                                       from "@babylonjs/core/Misc/tools";
import { Button, cn, InputMatrix }                     from "@geenee/ui";
// eslint-disable-next-line import/no-named-as-default
import GlobalState                                     from "../../../globalState";
import './transformationPropertyGridComponent.scss';

interface Props {
  source: Record<string, any>
  border?: boolean
}

const className = cn('transformation-property-grid');

export function TransformationPropertyGridComponent({ source, border }: Props) {
    const [ scaleLocked, setScaleLocked ] = useState(true);
    const [ key, setKey ] = useState('');
    const internalChange = useRef(false);

    useEffect(() => {
        GlobalState.onPropertyChangedObservable.add((e) => {
            if (internalChange.current) {
                internalChange.current = false;
                return;
            }
            if (e.property === 'scaling'
              || e.property === 'position'
              || e.property === 'rotation'
              || e.property === 'rotationQuaternion') {
                setKey(Tools.RandomId());
            }
        });
    }, []);

    const getOnChange = (field: string, axis: string | number) => (inputValue: number) => {
        const value = field.includes('rotation') ? Tools.ToRadians(+inputValue) : +inputValue;
        const prevData = source[ field ].clone();
        if (field === 'scaling' && scaleLocked) {
            source[ field ].x = value;
            source[ field ].y = value;
            source[ field ].z = value;
        } else if (source.rotationQuaternion && field.includes('rotation')) {
            const euler = source.rotationQuaternion.toEulerAngles();
            euler[ axis ] = value;
            const quat = euler.toQuaternion();
            source[ field ].x  = quat.x;
            source[ field ].y  = quat.y;
            source[ field ].z  = quat.z;
            source[ field ].w  = quat.w;
        } else {
            source[ field ][ axis ] = value;
        }
        internalChange.current = true;
        GlobalState.onPropertyChangedObservable.notifyObservers({
            object:       source,
            property:     field,
            value:        source[ field ],
            initialValue: prevData
        });
    };

    const transformMatrix = useMemo(() => ({
        rows: [ {
            label:     "Position",
            rowGetter: 'position'
        },
        {
            label:       'Rotation',
            rowGetter:   source.rotationQuaternion ? 'rotationQuaternion' : 'rotation',
            valueGetter: (values: Record<string, any>, column: string) => {
                if (source.rotationQuaternion && GlobalState.onlyUseEulers) {
                    const eulerDegrees = source.rotationQuaternion.toEulerAngles();
                    return Tools.ToDegrees(eulerDegrees[ column ]);
                }
                if (!GlobalState.onlyUseEulers) {
                    return values.rotation[ column ];
                }
                return Tools.ToDegrees(values.rotation[ column ]);
            },
            step: 1,
            min:  -360,
            max:  360
        },
        {
            label:     'Scale',
            rowGetter: 'scaling',
            before:    <Button
                margin="xs"
                icon={ scaleLocked ? "lockClose" : "lockOpen" }
                iconColor="shade-4"
                radius="sm"
                iconStroke="fat"
                size='sm'
                viewType="action"
                onClick={ () => {
                    setScaleLocked((v) => !v);
                } }
            />
        } ],
        columns:
          [ 'x', 'y', 'z' ]
    }), [ source, JSON.stringify(source.rotation),
        JSON.stringify(source.rotationQuaternion),
        JSON.stringify(source.position),
        JSON.stringify(source.scaling), scaleLocked ]);

    return (
        <InputMatrix
            key={ source.id + key }
            rows={ transformMatrix.rows }
            columns={ transformMatrix.columns }
            getOnChange={ getOnChange }
            target={ source }
            inputSize="sm"
            labelsTitle=""
            hasColumnLabels
            labelWeight="medium"
            labelSize="sm"
            lockedRows={ scaleLocked ? [ 'scaling' ] : [] }
            className={ className({ border }) }
        />

    );
}
