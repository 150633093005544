import { Engine, EngineParams }           from '@geenee/armature';
import { SceneCommanderType }             from "@geenee/shared/src/commander/scene-commander";
import { AvatarRenderer as RendererType } from '@geenee/shared/src/magellan/renderer/babylon-renderer/avatar-renderer';
import { CombinationSceneManagerModel }   from '@geenee/shared/src/magellan/scene-manager/type';
import { inject }                         from "inversify";
import { ArSceneProcessorType }           from '../../processor/processor';

interface EngineParams {
  /** Maximum processing size (resizing). If not defined
   * engine uses image size preferred by processor. */
  max?: number;
  /** Preserve original resolution, default - true. */
  orig?: boolean;
}

const modelMap: {
  [key: string]: {
    file: string, avatar: boolean,
    outfit?: any
  }
} = {
    onesie: {
        file:   'onesie.glb',
        avatar: false,
        outfit: {
            occluders: [ /Head$/, /Body/ ],
            hidden:    [ /Eye/, /Teeth/, /Footwear/ ]
        }
    },
    jacket: {
        file:   'jacket.glb',
        avatar: false,
        outfit: {
            occluders: [ /Head$/, /Body/ ],
            hidden:    [ /Eye/, /Teeth/, /Bottom/, /Footwear/, /Glasses/ ]
        }
    }
};

// BuilderFaceTrackingSceneManagerFactory
// SceneManagerFaceClientAppFactory
// SceneManagerBodytrackingBuilderFactory

// TODO: Decompose create method to separate common functions (ex. initRenderer)

// TODO: move AvatarRenderer to DI
// temp no type
export class SceneManager extends Engine<any, any, any> implements CombinationSceneManagerModel {
    sceneRenderer!: RendererType;
    @inject('<SceneCommander>') commander: SceneCommanderType;
    processor!: ArSceneProcessorType<any>;
    iteration!: () => Promise<any>;
    iterate!: () => Promise<any>;
    initialise!: (...args: any[]) => Promise<void>;

    constructor(Processor: new (p: any, r?: string | undefined) => any, procParams: any, engineParams?: EngineParams | undefined) {
        super(Processor, procParams, engineParams);
    }
}
