import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({ width = '20', height = '36', onClick, id }: SvgIconProps) {
    return (
        <svg
            id={ id }
            onClick={ onClick }
            width={ width }
            height={ height }
            viewBox="0 0 20 36"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M17 1.64056H3C1.89543 1.64056 1 2.51743 1 3.59909V17.3088C1 18.3904 1.89543 19.2673 3 19.2673H17C18.1046 19.2673 19 18.3904 19 17.3088V3.59909C19 2.51743 18.1046 1.64056 17 1.64056Z" fill="white" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.49988 8.49547C7.32831 8.49547 7.99988 7.83782 7.99988 7.02657C7.99988 6.21532 7.32831 5.55768 6.49988 5.55768C5.67145 5.55768 4.99988 6.21532 4.99988 7.02657C4.99988 7.83782 5.67145 8.49547 6.49988 8.49547Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19 13.3918L14 8.49545L3 19.2673" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 28.2661L6.03033 24.3787C5.73744 24.0919 5.26256 24.0919 4.96967 24.3787C4.67678 24.6655 4.67678 25.1306 4.96967 25.4174L9.46967 29.8241C9.76257 30.1109 10.2374 30.1109 10.5303 29.8241L15.0303 25.4174C15.3232 25.1306 15.3232 24.6655 15.0303 24.3787C14.7374 24.0919 14.2626 24.0919 13.9697 24.3787L10 28.2661Z" fill="black" />
        </svg>
    );
}
