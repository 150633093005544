import styled from 'styled-components';

const activeButton = (color: string) => `
    background-color: ${ color };
    border: 0.5px solid #ebeaea;
    box-shadow: inset 0px 1px 1px rgba(181, 175, 175, 0.25);
`;

export const MediaActions = styled.div<{ moveSelected: boolean; left?: boolean }>`
    position: absolute;
    top: 0px;
    ${ ({ left }) => (left ? 'left: 4px;' : 'right: 4px;') }
    min-width: 24px;
    height: 21.1px;
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 10px 10px;
    align-self: center;
    display: flex;
    justify-content: space-around;
    padding: 0px 7px;
    background: ${ ({ theme: { colors } }) => colors.white };

    & button:not(:last-child) {
        margin-right: 1px;
    }

    button {
        outline: none;
        cursor: pointer;
        background-color: transparent;
        width: 18px;
        height: 16px;
        padding: 0;
        margin-top: 3px;
        border: 0.5px solid transparent;
        border-radius: 5px;
    }

    button:hover {
        ${ activeButton(
        // @ts-ignore
        ({ theme: { secondaryColors } }) => secondaryColors.grey20
    ) }
    }

    ${ ({ moveSelected, theme: { secondaryColors } }) => moveSelected
        && `
    .move-btn {
        ${ activeButton(secondaryColors.grey20) }
    }
    ` }
`;

export const Media = styled.div<{
    focused: boolean;
    canMove: boolean;
}>`
    background: ${ ({ theme: { secondaryColors } }) => secondaryColors.grey30 };
    width: 100%;
    height: 120px;
    cursor: ${ ({ canMove }) => (canMove ? 'grab' : 'unset') };

    img,
    video {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    /* Disable image selection to prevent activation of DropWrapper component on drag  */
    img {
        pointer-events: none;
    }

    ${ ({ focused, theme: { secondaryColors } }) => focused
        && `
    outline: 1.2px solid ${ secondaryColors.pink10 };
  ` }
`;

export const MediaWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
  
    svg {
        margin-left: auto;
        cursor: pointer;
    }
`;

export const Container = styled.div`
    width: 100%;
    position: relative;
    overflow-y: auto;
`;

export const MediaContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  position: relative;
`;
