import * as React   from 'react';
import { observer } from 'mobx-react';
import { $Diff }    from 'utility-types';

type DiffPropsType = {
  extendOptions: Record<string, any>;
};

export function withProps(mapper: ({ ...args }: any) => any) {
    return <T extends DiffPropsType>(Component: React.ComponentType<T>) => observer((props: $Diff<T, DiffPropsType>) => (
        <Component { ...mapper(props as T) } { ...props } />
    ));
}
