import SnippetIcon        from '@geenee/assets/lib/images/snippet-logo.png';
import { AttachmentType } from '@geenee/shared/type/attachment.type';
import { injectable }     from 'inversify';
import {
    action, computed,
    makeAutoObservable, observable
} from 'mobx';
import { v4 }                              from 'uuid';
import { calculateInitialStats }           from '~/lib/calculateInitialStats';
import { SHARED_AUDIO_ICON_URL }           from '~/lib/constants';
import { uploadAttachment }                from '~/lib/uploadAttachment';
import { container }                       from '~/magellan/di/di';
import { AttachmentModel }                 from '~/magellan/model/attachment.model';
import { AssetLibraryApi }                 from '~/module/team-library/api';
import { generateTeamLibraryModelPreview } from '~/module/team-library/lib/generateTeamLibraryModelPreview';
import { getWeekDates }                    from '~/module/team-library/lib/getWeekDates';
import { TeamLibraryItemModel }            from '~/module/team-library/state/team-library-item.model';

@injectable()
export class TeamLibraryState {
    @observable storage: TeamLibraryItemModel[] = [];
    @observable query: {
        searchQuery: string;
        sort: string;
        dateFilter: string,
        fileType: string
    } = { searchQuery: '', sort: '', dateFilter: '', fileType: '' };
    @observable mode: 'snippet' | 'default' = 'default';
    @observable editMode = true;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    clearCache() {
        Object.keys(this.query).forEach((key) => {
            // @ts-ignore
            this.query[ key ] = '';
        });
    }
    @action
    async fetchItems() {
        this.editMode = true;
        const items = await AssetLibraryApi.read();
        // TODO: Double-check such behaviour
        // @ts-ignore
        this.storage = [ ...items, ...DEFAULT_ITEMS ].sort((a, b) => a.attachment.options.order - b.attachment.options.order).map((el) => {
            const item = new TeamLibraryItemModel();
            // @ts-ignore
            item.init({ ...el, $parent: this });
            return item;
        });
    }

    @action
    async genarateDefaultItems() {
        this.editMode = false;
        this.storage = DEFAULT_ITEMS.sort((a, b) => a.attachment.options.order - b.attachment.options.order).map((el) => {
            const item = new TeamLibraryItemModel();

            // @ts-ignore
            item.init({ ...el, $parent: this });
            return item;
        });
    }

    @computed
    get filteredItems() {
        let filtered = this.storage;
        filtered = filtered.filter((item) => {
            const fileType = item.attachment.filename.split('.').pop();
            if (this.mode === 'default') {
                return fileType !== 'json';
            }
            return fileType === 'json';
        });
        if (this.query.searchQuery) {
            filtered = filtered.filter((el) => el.title.toLowerCase().includes(this.query.searchQuery.toLowerCase()));
        }
        if (this.query.fileType) {
            filtered = filtered.filter((el) => {
                const fileType = el.attachment.filename.split('.');
                return `.${ fileType[ fileType.length - 1 ] }` === this.query.fileType;
            });
        }
        if (this.query.dateFilter) {
            if (this.query.dateFilter === 'week') {
                const [ startWeek, endWeek ] = getWeekDates();
                filtered = filtered.filter((el) => {
                    const date = new Date(el.inserted_at);
                    return +date >= +startWeek && +date < +endWeek;
                });
            } else if (this.query.dateFilter === 'month') {
                const currentMonth = new Date().getMonth();
                filtered = filtered.filter((el) => new Date(el.inserted_at).getMonth() === currentMonth);
            } else if (this.query.dateFilter === 'year') {
                const currentYear = new Date().getFullYear();
                filtered = filtered.filter((el) => new Date(el.inserted_at).getFullYear() === currentYear);
            }
        }
        if (this.query.sort === '+size') {
            return filtered.slice().sort((a, b) => a.attachment.file_size - b.attachment.file_size);
        }
        if (this.query.sort === '-size') {
            return filtered.slice().sort((a, b) => b.attachment.file_size - a.attachment.file_size);
        }
        return filtered.slice().sort((a, b) => (a.attachment.options.order || 0) - (b.attachment.options.order || 0));
    }

    @computed
    get childrenMaxOrder() {
        return this.storage.length
            ? Math.max(
                ...this.storage.map(
                    (el) => el?.attachment?.options?.order || 0
                )
            )
            : 0;
    }

    async createAttachment(file: File, order?: number) {
        const stats = await calculateInitialStats(file);
        return this.createAttachmentModel({
            file,
            ...stats,
            options: { ...stats.order, order: order !== undefined ? order : this.childrenMaxOrder + 1 }
        });
    }

    async createThumbnail(blob: Blob, order?: number) {
        const thumbnail = new File([ blob ], 'Preview', { type: 'image/png' });
        return this.createAttachmentModel({
            file:    thumbnail,
            options: { order: order !== undefined ? order : this.childrenMaxOrder + 1 }
        });
    }

    async createModelAndThumbnailAttachments(file: File, order?: number):
      Promise<{fileAttachment: AttachmentModel; thumbnailAttachment: AttachmentModel}> {
        let resolve: any;
        let reject: any;
        const resultPromise = new Promise<{fileAttachment: AttachmentModel; thumbnailAttachment: AttachmentModel}>((res, rej) => {
            resolve = res;
            reject = rej;
        });
        try {
            const fileAttachment = await this.createAttachment(file, order);
            if (file.name.endsWith('.mp3')) {
                const shared_audio_icon = await fetch(SHARED_AUDIO_ICON_URL).then((res) => res.blob());
                const thumbnailAttachment = await this.createThumbnail(shared_audio_icon, order);
                resolve({ fileAttachment, thumbnailAttachment });
            } else if (file.name.endsWith('.json')) {
                const shared_audio_icon = await fetch(SnippetIcon).then((res) => res.blob());
                const thumbnailAttachment = await this.createThumbnail(shared_audio_icon, order);
                resolve({ fileAttachment, thumbnailAttachment });
            } else {
                const blob = await generateTeamLibraryModelPreview(file);
                const thumbnailAttachment = await this.createThumbnail(blob, order);
                resolve({ fileAttachment, thumbnailAttachment });
            }
        } catch (e) { reject(e); }
        return resultPromise;
    }

    @action
    async createLibraryAsset(file: File, title: string, description = '', posted_by = '', thumbnailFile?: File) {
        if (!file || !title) {
            throw new Error('Missing required file and title');
        }
        let fileAttachment: AttachmentModel;
        let thumbnailAttachment: AttachmentModel;
        if (thumbnailFile) {
            thumbnailAttachment = await this.createThumbnail(thumbnailFile);
            fileAttachment = await this.createAttachment(file);
        } else {
            const files         = await this.createModelAndThumbnailAttachments(file);
            fileAttachment      = files.fileAttachment;
            thumbnailAttachment = files.thumbnailAttachment;
        }
        const item         = new TeamLibraryItemModel();
        item.posted_by = posted_by;
        item.attachment_id = fileAttachment.id;
        item.title         = title;
        item.description = description;
        item.thumbnail_id  = thumbnailAttachment?.id;
        item.attachment    = fileAttachment;
        item.thumbnail     = thumbnailAttachment;
        this.storage.push(item);
        const res = await AssetLibraryApi.create(item);
        item.$parent  = this;
        item.id   = res.id;
        item.inserted_at = res.inserted_at;
    }

    createAttachmentModel = async (attachmentSchema: Partial<AttachmentType> & {file?: File}) => {
        const { attachment: uploadedAttachmentSchema } = await uploadAttachment(attachmentSchema);
        return container?.get('<AttachmentFactory>')?.create(uploadedAttachmentSchema);
    };
}

// eslint-disable-next-line arca/no-default-export
export default new TeamLibraryState();

const DEFAULT_ITEMS: TeamLibraryItemModel[] = [
    {
        attachment: {
            custom_code: {},
            filename:    "Inworld_Geenee_Snippet.json",
            file_size:   6300000,
            id:          v4(),
            inserted_at: new Date().toISOString(),
            options:     { order: -1 },
            order:       -1,
            title:       'Inworld_Geenee_Snippet',
            updated_at:  new Date().toISOString(),
            url:         "https://raw.githubusercontent.com/Geenee/public-code-snippets/main/Inworld_Geenee_Snippet_with_Animations_live.json"
        },
        custom_code: {},
        description: '',
        id:          v4(),
        inserted_at: new Date().toISOString(),
        options:     { readonly: true },
        posted_by:   "Geenee",
        thumbnail:   {
            custom_code: {},
            file_size:   27650,
            filename:    "preview",
            id:          v4(),
            inserted_at: new Date().toISOString(),
            options:     { order: -1 },
            order:       -1,
            properties:  {},
            title:       null,
            updated_at:  new Date().toISOString(),
            url:         "https://gist.github.com/eltonorudzhov/82e74751526176bc1456e12ae41de235/raw/snippetThumbnail.png"
        },
        title:      "Inworld_Geenee_Snippet",
        updated_at: new Date().toISOString()
    }
];
