import React, { useState }                                               from 'react';
import Uploader                                                          from '@geenee/geeclient-kit/src/lib/component/scene/components/Uploader';
import LoadingSpinner                                                    from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { Description, FormItem, InputTableItem, Wrapper }                from '@geenee/ui';
import { observer }                                                      from 'mobx-react';
import { useBuilderInject }                                              from "@geenee/builder/src/core/hook/use-builder-inject";
import { TOAST_ERROR, TYPE_STAMP_AR, UPLOADER_ALLOWED_IMAGE_EXTENSIONS } from '@geenee/builder/src/lib/constants';
import { SectionModel }                                                  from '@geenee/builder/src/magellan/model/section.model';

interface Props {
  type: string;
  sectionModel: SectionModel;
  associatedTriggerImgUrl?: string;
  handleUpload: (file: File) => void;
  disabled?: boolean;
}

export const StampForm = observer(({
    type, sectionModel, associatedTriggerImgUrl, handleUpload, disabled
}: Props) => {
    const { BuilderState } = useBuilderInject();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ mouseIsEntered, setMouseIsEntered ] = useState(false);
    const isStampContent = type === TYPE_STAMP_AR;
    if (!isStampContent) return <></>;
    const missingTrigger = !associatedTriggerImgUrl;

    const onMouseEnter = () => {
        setMouseIsEntered(true);
    };

    const onMouseLeave = () => {
        sectionModel.setShowMissingContentError(false);
        setMouseIsEntered(false);
    };

    return (
        <InputTableItem
            label="Tracker Image *"
            onMouseEnter={ onMouseEnter }
            onMouseLeave={ onMouseLeave }
            style={ { marginBottom: 20 } }
        >
            <FormItem
                focused={ missingTrigger }
                error={
                    missingTrigger && (sectionModel.showMissingContentError || mouseIsEntered) && (
                        <Wrapper>
                            <Description color='shade-2' weight="bold">Add Tracker</Description>
                            <Description size="sm" noWrap color="shade-2">Please add the image to proceed.</Description>
                        </Wrapper>
                    )
                }
            >
                { isLoading ? <LoadingSpinner size={ 30 } /> : (
                    <Uploader
                        disabled={ disabled }
                        name="trackerImage"
                        currentFile={ associatedTriggerImgUrl }
                        description="This asset can be a jpg or png up to 2mbs"
                        handleUpload={ async (value) => {
                            setIsLoading(true);
                            await handleUpload(Array.isArray(value) ? value[ 0 ] : value);
                            setIsLoading(false);
                        } }
                        accept={
                            UPLOADER_ALLOWED_IMAGE_EXTENSIONS
                        }
                        errorCallback={ (_type) => {
                            BuilderState.toast = {
                                severity: TOAST_ERROR,
                                detail:   _type === 'size' ? `File size should be 2MB` : 'Only png, jpg, jpeg, gif files can be uploaded',
                                summary:  _type === 'size' ? 'Invalid file size' : 'Invalid file format'
                            };
                        } }
                        size={ 2 }
                    />
                ) }
            </FormItem>
        </InputTableItem>
    );
});
