import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '12',
    height = '12',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="6" cy="6" r="5.5" fill="white" stroke="#BBBBBB" />
            <g clipPath="url(#clip_share0)">
                <path
                // eslint-disable-next-line max-len
                    d="M7.23072 3C6.68116 3 6.23074 3.45042 6.23074 3.99999C6.23074 4.10635 6.24757 4.21122 6.27882 4.30767L4.62738 5.22597C4.4552 5.08625 4.23736 5.00001 3.99999 5.00001C3.45042 5.00001 3 5.45043 3 6C3 6.54957 3.45042 6.99998 3.99999 6.99998C4.23736 6.99998 4.45521 6.91615 4.62738 6.77643L6.2812 7.69473C6.24965 7.79148 6.23072 7.89334 6.23072 8.00001C6.23072 8.54958 6.68114 9 7.23071 9C7.78027 9 8.23069 8.54958 8.23069 8.00001C8.23069 7.45044 7.78027 7.00003 7.23071 7.00003C6.95457 7.00003 6.70307 7.11541 6.52158 7.2981L4.91345 6.40388C4.96843 6.28038 4.99998 6.14307 4.99998 6.00004C4.99998 5.85611 4.96903 5.7203 4.91345 5.5962L6.51919 4.70198C6.70098 4.88617 6.95338 5.00006 7.23072 5.00006C7.78029 5.00006 8.23071 4.54964 8.23071 4.00007C8.23071 3.4505 7.78029 3.00008 7.23072 3.00008L7.23072 3ZM7.23072 3.46153C7.5309 3.46153 7.76918 3.69981 7.76918 3.99999C7.76918 4.30016 7.5309 4.53844 7.23072 4.53844C6.93055 4.53844 6.69227 4.30016 6.69227 3.99999C6.69227 3.69981 6.93055 3.46153 7.23072 3.46153ZM4 5.4615C4.30017 5.4615 4.53846 5.69978 4.53846 5.99996C4.53846 6.30013 4.30017 6.53841 4 6.53841C3.69983 6.53841 3.46155 6.30013 3.46155 5.99996C3.46155 5.69978 3.69983 5.4615 4 5.4615ZM7.23072 7.46148C7.5309 7.46148 7.76918 7.69976 7.76918 7.99993C7.76918 8.3001 7.5309 8.53838 7.23072 8.53838C6.93055 8.53838 6.69227 8.3001 6.69227 7.99993C6.69227 7.69976 6.93055 7.46148 7.23072 7.46148Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip_share0">
                    <rect
                        width="5.25005"
                        height="6"
                        fill="white"
                        transform="translate(3 3)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
