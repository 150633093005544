import styled     from 'styled-components';
import { Button } from '../common/styles';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 40px 40px;
    width: 600px;
`;

export const CardWrapper = styled.div`
    position: relative;
    overflow: hidden;
    margin-top: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Card = styled.div`
    padding: 0 10px;
    height: 57px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    width: 100%;

    p {
        margin-bottom: 0;
        font-size: 12px;

        &:first-child {
            margin-top: 0;
        }
    }
`;

export const CardUndo = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 10px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
    border: 1px solid #fbf8cc;
    border-radius: 5px;
    width: 100%;
    background: #fffdd6;
    p {
        margin: 0;
    }

    @keyframes SlideRight {
        0% {
            left: 0;
        }
        100% {
            left: 100%;
        }
    }

    @keyframes SlideLeft {
        0% {
            left: 100%;
        }
        100% {
            left: 0;
        }
    }

    &[SlideRight] {
        animation: SlideRight 0.2s;
    }

    animation: SlideLeft 0.2s;
`;

export const CardBorder = styled.div`
    margin-top: 10px;
    height: 1px;
    background: #ccc;
    width: calc(100% - 20px);
`;

export const StyledButton = styled(Button)`
    margin: 90px 0 !important;
`;

export const ButtonsWrapper = styled.div`
    user-select: none;

    svg {
        margin-right: 30px;
        cursor: pointer;
    }

    svg:hover {
        opacity: 0.6;
    }

    svg:active {
        position: relative;
        top: 1px;
    }
`;
