import * as React                              from "react";
import type { Mesh }                           from "@babylonjs/core/Meshes/mesh";
import type { TransformNode }                  from "@babylonjs/core/Meshes/transformNode";
import type { Observable }                     from "@babylonjs/core/Misc/observable";
import InjectedComponent                       from "@geenee/geeclient-kit/src/packages/shared/lib/util/abstract/injected-component";
import { FitNodeToViewportCommand }            from "@geenee/geespector/src/commands/FitNodeToViewportCommand";
import { CheckBoxLineComponent }               from "@geenee/geespector-ui-components/src/lines/checkBoxLineComponent";
import { TextInputLineComponent }              from "@geenee/geespector-ui-components/src/lines/textInputLineComponent";
import { TextLineComponent }                   from "@geenee/geespector-ui-components/src/lines/textLineComponent";
import type { LockObject }                     from "@geenee/geespector-ui-components/src/tabs/propertyGrids/lockObject";
import MaterialsShema                          from '@geenee/omnirenderer/dist/schema/materials.json';
import NodesSchema                             from '@geenee/omnirenderer/dist/schema/nodes.json';
import PoseSchema                              from '@geenee/omnirenderer/dist/schema/posetune.json';
import { SceneCommanderType }                  from "@geenee/shared/src/commander/scene-commander";
import { Button, InputTableItem, Wrapper }     from '@geenee/ui';
import JsonSchemaForm                          from "@geenee/ui/src/lib/component/json-schema-form/json-schema-form.component";
import { Container }                           from "inversify";
import { inject }                              from "mobx-react";
import { v4 }                                  from "uuid";
import GlobalState                             from "../../../../globalState";
import { PropertyChangedEvent }                from "../../../../propertyChangedEvent";
import { ActionTabSectionComponent }           from "../../../actionTabSectionComponent";
import { ShowMoreSectionComponent }            from "../../../showMoreSectionComponent";
import { AnimationGridComponent }              from "../animations/animationPropertyGridComponent";
import { CommonPropertyGridComponent }         from "../commonPropertyGridComponent";
import { CustomPropertyGridComponent }         from "../customPropertyGridComponent";
import { ParentPropertyGridComponent }         from "../parentPropertyGridComponent";
import { TransformationPropertyGridComponent } from "../transformationPropertyGridComponent";
import { VariantsPropertyGridComponent }       from "../variantsPropertyGridComponent";

interface ITransformNodePropertyGridComponentProps {
    globalState: GlobalState;
    transformNode: TransformNode;
    lockObject: LockObject;
    onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
}

type InjectPropsType = {
  container: Container
};

const schema = {
    ...NodesSchema,
    definitions: {
        // "#/definitions/engeenee.com/posetune.json":  PoseSchema,
        // "#/definitions/engeenee.com/materials.json": MaterialsShema
        "engeenee.com/posetune.json":  PoseSchema,
        "engeenee.com/materials.json": MaterialsShema
    }
};

@inject('container')
export class TransformNodePropertyGridComponent extends InjectedComponent<ITransformNodePropertyGridComponentProps, InjectPropsType, {schema: Record<string, any>, metakey: string, jsonSchemaKey: string}> {
    AppState = this.injected.container.get('<AppState>');
    SceneCommander = this.injected.container.get('<SceneCommander>') as SceneCommanderType;
    // @ts-ignore
    renderer = this.AppState?.activeSection?.sceneManager?.sceneRenderer;
    internalChanges = false;
    constructor(props: ITransformNodePropertyGridComponentProps) {
        super(props);
        this.state = { schema: {}, metakey: '', jsonSchemaKey: v4(), internalChange: false };
        GlobalState.onPropertyChangedObservable.add((e) => {
            if (e.property === 'metadata') {
                if (this.internalChanges) {
                    this.internalChanges = false;
                    return;
                }
                this.setState({ jsonSchemaKey: v4() });
            }
        });
    }

    override componentDidMount() {
        this.assignSchema();
    }

    assignSchema = () => {
        const { transformNode } = this.props;
        let metakey = '';
        switch (transformNode?.metadata?.gltf?.extras?.engeenee?.type) {
            case 'avatar': {
                metakey = 'poseAlign';
                break;
            }
            case 'twin': {
                metakey = 'poseTwin';
                break;
            }
            case 'head': {
                metakey = 'headTrack';
                break;
            }
            default: {
                this.setState({ schema: {} });
                return;
            }
        }
        this.setState({ schema: { ...schema.properties[ metakey ], definitions: schema.definitions }, metakey });
    };

    override componentDidUpdate(prevProps: Readonly<ITransformNodePropertyGridComponentProps>) {
        if (prevProps.transformNode !== this.props.transformNode) {
            this.assignSchema();
        }
    }
    onMetaPropertyChanged = (value: typeof this.state.schema) => {
        this.internalChanges = true;
        const { transformNode } = this.props;
        const prevValue = JSON.parse(JSON.stringify(transformNode.metadata));
        transformNode.metadata.gltf.extras.engeenee[ this.state.metakey ] = { ...value };
        const e = new PropertyChangedEvent();
        e.object = transformNode;
        e.property = "metadata";
        e.value = JSON.parse(JSON.stringify(transformNode.metadata));
        e.initialValue = prevValue;
        GlobalState.onPropertyChangedObservable.notifyObservers(e);
    };

    render() {
        const { transformNode } = this.props;
        return (
            <Wrapper>
                <CustomPropertyGridComponent
                    globalState={ this.props.globalState }
                    target={ transformNode }
                    lockObject={ this.props.lockObject }
                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                />
                { /* <LineContainerComponent title="GENERAL" selection={ this.props.globalState }> */ }
                <ActionTabSectionComponent title="GENERAL">
                    { /* <TextLineComponent label="ID" value={ transformNode.id } /> */ }
                    <TextInputLineComponent
                        lockObject={ this.props.lockObject }
                        label="Name"
                        target={ transformNode }
                        propertyName="name"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                    { /* <TextLineComponent label="Unique ID" value={ transformNode.uniqueId.toString() } /> */ }
                    <CheckBoxLineComponent
                        label="Enabled"
                        isSelected={ () => transformNode.isEnabled() }
                        onSelect={ (value) => transformNode.setEnabled(value) }
                    />
                    <ParentPropertyGridComponent
                        globalState={ this.props.globalState }
                        node={ transformNode }
                        lockObject={ this.props.lockObject }
                    />
                    <ShowMoreSectionComponent>
                        <TextLineComponent label="Class" value={ transformNode.getClassName() } />
                    </ShowMoreSectionComponent>
                    { /* <ButtonLineComponent
                        label="Dispose"
                        onClick={ () => {
                            transformNode.dispose();
                            this.props.globalState.onSelectionChangedObservable.notifyObservers(null);
                        } }
                    /> */ }
                </ActionTabSectionComponent>
                { !!Object.keys(this.state.schema).length && (
                    <ActionTabSectionComponent title="ADVANCED">
                        <JsonSchemaForm
                            key={ this.state.jsonSchemaKey }
                            schema={ this.state.schema }
                            onChange={ this.onMetaPropertyChanged }
                            value={ transformNode?.metadata?.gltf?.extras?.engeenee[ this.state.metakey ] || {} }
                        />
                    </ActionTabSectionComponent>
                ) }
                <CommonPropertyGridComponent host={ transformNode } lockObject={ this.props.lockObject } globalState={ this.props.globalState } />
                <VariantsPropertyGridComponent host={ transformNode as Mesh } lockObject={ this.props.lockObject } globalState={ this.props.globalState } />
                <ActionTabSectionComponent title="TRANSFORMATION" contentWrapperProps={ { padding: "xs" } } paddingLeft="xs" collapsed hasDivider={ false }>
                    <TransformationPropertyGridComponent source={ transformNode } border />
                    <InputTableItem
                        size="sm"
                        marginOff
                        weight="medium"
                        viewType="secondary"
                        label="Fit to viewport"
                    >
                        <Button
                            size="qsm"
                            fullWidth
                            viewType="secondary"
                            radius="lg"
                            onClick={ () => {
                                const fitToViewportCommand = new FitNodeToViewportCommand(this.renderer, this.props.transformNode);
                                this.SceneCommander.executeCommand(fitToViewportCommand);
                            } }
                        >
                            Fit
                        </Button>
                    </InputTableItem>
                </ActionTabSectionComponent>
                <AnimationGridComponent globalState={ this.props.globalState } animatable={ transformNode } scene={ transformNode.getScene() } lockObject={ this.props.lockObject } />
            </Wrapper>
        );
    }
}
