import styled     from 'styled-components';
import { Button } from '@geenee/builder/src/components/modals/common/styles';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    padding: 32px 40px 40px;
`;

export const StyledButton = styled(Button)`
    margin-top: 24px !important;
    padding-bottom: 40px;
`;
