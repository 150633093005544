import { useEffect }  from 'react';
import { useHistory } from "@geenee/common";
import { useInject }  from './use-inject.hook';

export const useSharedHistory = () => {
    const history = useHistory();
    const { AppState } = useInject();

    useEffect(() => {
        if (AppState.publishedName && !history.pushModified) {
            const originalPush = history.push;
            // eslint-disable-next-line no-inner-declarations
            function customPush(...params: any) {
                let [ path ] = params;
                path           = `/${ AppState.publishedName }${ path }`;
                originalPush(path, ...params.slice(1));
            }
            history.push = customPush;
            history.pushModified = true;
        }
    }, [ AppState.publishedName ]);

    return history;
};
