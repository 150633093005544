import React, { useContext }                             from 'react';
import { Description, InputTable, Wrapper }              from '@geenee/ui';
import { observer }                                      from "mobx-react";
import { CancelSubscription as CancelSubscriptionModal } from "@geenee/builder/src/components/modals/CancelSubscription";
import { stripeState }                                   from "@geenee/builder/src/core/state/stripe.state";
import ModalUpdaterContext                               from "@geenee/builder/src/lib/ModalUpdaterContext";
import { BillingCycle }                                  from "@geenee/builder/src/pages/YourPlanPage/rows/BillingCycle";
import { CardsOnFile }                                   from "@geenee/builder/src/pages/YourPlanPage/rows/CardsOnFile";
import { CurrentPlan }                                   from "@geenee/builder/src/pages/YourPlanPage/rows/CurrentPlan";
import { NextPayment }                                   from "@geenee/builder/src/pages/YourPlanPage/rows/NextPayment";

export const YourPlanContent = observer(() => {
    const setModalOptions = useContext(ModalUpdaterContext);
    const price = stripeState.prices[ stripeState.plan_price_id ];

    const hideCancelButton = !price || !price.price || stripeState.status !== 'active';
    return (
        <Wrapper frame="solid-default" maxWidth="775" padding="md" valign="center">
            <InputTable margin="sm">
                <CurrentPlan />
                <BillingCycle />
                <NextPayment />
                <CardsOnFile />
                { !hideCancelButton ? (
                    <Wrapper
                        style={ { height: '40px' } }
                        valign="center"
                        margin="sm"
                    >
                        <Description
                            weight="medium"
                            onClick={ () => setModalOptions({ component: CancelSubscriptionModal }) }
                            align="left"
                            color="error"
                        >
                            Cancel Subscription
                        </Description>
                    </Wrapper>
                ) : <></> }
            </InputTable>
        </Wrapper>
    );
});
