import React, { ReactElement } from 'react';
import { Description  }        from '@geenee/ui';
import { ButtonProps }         from 'primereact/button';
import { ButtonWithIcon }      from './styles';

type RaisedButtonProps = {
    onClick?: () => void;
    iconElement: ReactElement;
    iconPos?: string;
    text?: string;
    width?: string;
    height?: string;
    $dark?: boolean;
    disabled?: boolean;
    pulseAnimation?: boolean;
};

function WithIcon({
    onClick,
    iconElement = <div />,
    iconPos = 'right',
    $justify = 'center',
    $padding = 'unset',
    $dark = false,
    text,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    as,
    disabled,
    ...rest
}: ButtonProps & RaisedButtonProps) {
    return (
        <ButtonWithIcon
            { ...rest }
            $dark={ $dark }
            $justify={ $justify }
            $padding={ $padding }
            $direction={ iconPos === 'left' ? 'row-reverse' : 'row' }
            onClick={ (e) => !disabled && onClick && onClick(e) }
        >
            { text && <Description weight="bold" size='sm'>{ text }</Description> }
            { iconElement }
        </ButtonWithIcon>
    );
}

// eslint-disable-next-line arca/no-default-export
export default WithIcon;
