import React                 from 'react';
import { InputTableItem }    from '@geenee/ui';
import { CountriesSelector } from '@geenee/builder/src/components/CountriesSelector';
import { ProjectModel }      from '@geenee/builder/src/magellan/model/project.model';

interface Props {
  // eslint-disable-next-line react/require-default-props
  value: string | null;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  activeProject: ProjectModel;
}

export function CountriesRow({ value, disabled, activeProject }: Props) {
    return (
        <InputTableItem label="Country Restrictions">
            <CountriesSelector
                value={ value }
                onChange={ (value) => {
                    activeProject.country = value;
                    activeProject.saveData();
                } }
                disabled={ disabled }
            />
        </InputTableItem>
    );
}
