import React                                   from 'react';
import ProjectsIcon                            from '@geenee/builder/src/asset/icons/ProjectsIcon';
import Hint                                    from '@geenee/builder/src/components/common/Hint';
import { UnderlinedTableRow, WithHintWrapper } from '@geenee/builder/src/pages/AllPlansPage/styles';

export function LiveProjects() {
    return (
        <UnderlinedTableRow>
            <td>
                <WithHintWrapper>
                    <span>Projects</span>
                    { ' ' }
                    <Hint
                        title='Publish & Share'
                        text="Each subscription tier allows for a certain number of AR projects to be concurrently published live."
                        iconElement={ <ProjectsIcon /> }
                    />
                </WithHintWrapper>

            </td>
            <td className='basic-plan'>
                1
            </td>
            <td className='plan-filler' />
            <td className='studio-plan'>
                3
            </td>
            <td className='plan-filler' />
            <td className='pro-plan'>
                Unlimited
            </td>
        </UnderlinedTableRow>
    );
}
