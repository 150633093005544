import React from 'react';

// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '22',
    height = '22',
    onClick,
    id,
    fill = 'black'
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 22 22"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
            // eslint-disable-next-line max-len
                d="M13 0C14.6569 0 16 1.34315 16 3V4H20C20.5523 4 21 4.44772 21 5C21 5.51284 20.614 5.93551 20.1166 5.99327L20 6H19V19C19 20.5977 17.7511 21.9037 16.1763 21.9949L16 22H6C4.40232 22 3.09634 20.7511 3.00509 19.1763L3 19V6H2C1.48716 6 1.06449 5.61396 1.00673 5.11662L1 5C1 4.48716 1.38604 4.06449 1.88338 4.00673L2 4H6V3C6 1.40232 7.24892 0.0963392 8.82373 0.00509274L9 0H13ZM17 6H5V19C5 19.5128 5.38604 19.9355 5.88338 19.9933L6 20H16C16.5128 20 16.9355 19.614 16.9933 19.1166L17 19V6ZM13 2H9C8.44772 2 8 2.44772 8 3V4H14V3C14 2.48716 13.614 2.06449 13.1166 2.00673L13 2Z"
                fill={ fill }
            />
        </svg>
    );
}
