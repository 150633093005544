import React, { FC, useEffect, useRef, useState } from 'react';
import { DoubleSide, Euler, Vector3 }             from 'three';
import { DefaultLoadingManager }                  from "three/src/loaders/LoadingManager";

type VideoPlanePropsType = {
    width: number,
    height: number,
    url: string,
    position: Vector3 | [ number, number, number ] | undefined;
    rotation: Vector3 | Euler | [ number, number, number ] | undefined;
    scale: Vector3 | [ number, number, number ] | undefined;
    loadedCallback?: any
    visible: boolean
};

const VideoPlane: FC<VideoPlanePropsType> = ({ width, height, url, ...props }) => {
    const videoMeshRef = useRef();
    const [ visible, setVisible ] = useState(false);
    const [ videoRatio, setVideoRatio ] = useState(1);
    const [ videoEl ] = useState(() => {
        const vid = document.createElement('video');
        vid.src = url;
        vid.loop = true;
        vid.muted = true;
        // vid.autoplay = true;
        vid.playsinline = true;
        vid.crossOrigin = 'Anonymous';

        vid.setAttribute('loop', 'true');
        // vid.setAttribute('type', 'video/mp4; codecs="hvc1"');
        vid.setAttribute('muted', 'true');
        vid.setAttribute('autoplay', 'false');
        vid.setAttribute('playsinline', 'true');
        return vid;
    });

    useEffect(() => {
        videoEl.play();
        videoEl.addEventListener('canplaythrough', () => {
            setVideoRatio(videoEl.videoHeight / videoEl.videoWidth);
            setVisible(true);
            props.loadedCallback(true);
            DefaultLoadingManager.onLoad && DefaultLoadingManager.onLoad();
        });
    }, [ videoEl ]);

    return (
        <mesh
            ref={ videoMeshRef }
            position={ props.position }
            rotation={ props.rotation }
            scale={ props.scale }
            dispose={ null }
            castShadow={ false }
            visible={ props.visible && visible }
            name="geenee-3d-video-plane-mesh"
        >
            <planeGeometry attach="geometry" args={ [ width || 1, (height || 1) * videoRatio ] } />
            <meshBasicMaterial attach="material" color="#ffffff" depthWrite side={ DoubleSide }>
                <videoTexture attach="map" args={ [ videoEl ] } />
            </meshBasicMaterial>
        </mesh>
    );
};
// eslint-disable-next-line arca/no-default-export
export default VideoPlane;
