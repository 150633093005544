const dealLocalStorage = (action: string, key: string, value?: string) => {
    let response;
    switch (action) {
        case 'set':
            localStorage.setItem(key, value || '');
            return true;
        case 'get':
            response = localStorage.getItem(key);
            return response === 'null' ? false : response;
        case 'remove':
            localStorage.removeItem(key);
            return true;
        default:
            throw new Error(`Invalid action call to localStorage: ${ action } `);
    }
};
// eslint-disable-next-line arca/no-default-export
export default dealLocalStorage;
