import React, { FC, memo, useEffect, useMemo, useRef } from 'react';
import { Scrollbars }                                  from "react-custom-scrollbars";
import { CallbackFunctionVariadic }                    from '@geenee/geeclient-kit/src/lib/type/type';
import { useOnClickOutside, Wrapper }                  from '../../..';
import { cn }                                          from '../../util/bem';
import { RadiusSizesType, SizesUnionType }             from '../../util/global-props';
import { useDropdown }                                 from './dropdown.hook';
import { DropdownItem }                                from './dropdown-item.component';
import './dropdown.component.scss';

export type DropdownPropsType = {
    className?: string;
    menu?: ((callBack: CallbackFunctionVariadic) => React.ReactNode) | React.ReactNode;
    items?: React.ReactNode;
    children: ((dropdownOpen: boolean) => React.ReactNode) | React.ReactNode ;
    disabled?: boolean;
    viewType?: 'primary' | 'secondary';
    fullWidth?: boolean;

    prepend?: React.ReactNode
    prependSticky?: boolean

    maxCount?: number
    isChangeOutside?: boolean;
    isChanged?: (open: boolean) => void;
    isOpen?: boolean;
    radius?: RadiusSizesType;

    clickOutside?: boolean;
    margin?: SizesUnionType;
    flex?: number | string;
    menuWidth?: string;
    position?:
        'auto-vertical-left'
        | 'auto-vertical-center'
        | 'auto-vertical-right'
        | 'bottom-left'
        | 'bottom-center'
        | 'bottom-right'
        | 'top-left'
        | 'top-center'
        | 'top-right';
    onSelect?: (e: React.SyntheticEvent, id: string) => void;
    closeOnSelect?: boolean;
    style?: React.CSSProperties;

}

const className = cn('dropdown');

export const Dropdown: FC<DropdownPropsType> = memo((props: DropdownPropsType) => {
    const triggerEl  = useRef(null);
    const dropdownEl = useRef(null);
    const mainRef = useRef(null);
    const [ dropdownOpen, toggleDropdownOpen, autoPosition ] = useDropdown(
        dropdownEl.current,
        triggerEl.current,
        props.closeOnSelect,
        props.isOpen,
        props.isChangeOutside,
        props.position
    );
    const onClick = () => {
        toggleDropdownOpen();
    };

    useEffect(() => {
        if (props.isChanged) {
            props.isChanged(dropdownOpen);
        }
    }, [ dropdownOpen, dropdownOpen ]);

    const renderMenuContent: React.ReactNode = useMemo(() => {
        if (!props.menu) {
            return null;
        }
        return (
            typeof props.menu === 'function' ? props.menu(toggleDropdownOpen) : props.menu
        );
    }, [ props.menu ]);

    const menuWidth = props.menuWidth ? { width: props.menuWidth } : { width: '200px' };

    useOnClickOutside(mainRef, () => {
        if (dropdownOpen && props.clickOutside) {
            toggleDropdownOpen();
        }
    });

    return (
        <div
            ref={ mainRef }
            className={
                className({
                    disabled:    props.disabled,
                    'menu-mode': !!props.menu,
                    margin:      props.margin,
                    flex:        props.flex,
                    fullWidth:   props.fullWidth
                }, null, props.className)
            }
            style={ props.style }
        >
            <div
                className={ className('trigger') }
                ref={ triggerEl }
                onClick={ onClick }
            >
                { typeof props.children === 'function' ? props.children && props.children(dropdownOpen) : props.children }
            </div>

            <div
                className={ className(
                    'menu',
                    {
                        position: autoPosition || props.position,
                        open:     dropdownOpen,
                        type:     props.viewType,
                        radius:   props.radius
                    }
                ) }
                ref={ dropdownEl }
                style={ dropdownOpen ? menuWidth : { width: '0px' } }
                onClick={ (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                } }
            >
                <Wrapper
                    fullWidth
                >
                    { renderMenuContent
                        ? React.cloneElement(renderMenuContent, {
                            // @ts-ignore
                            ...renderMenuContent.props,
                            prepend:       props.prepend,
                            prependSticky: props.prependSticky
                        }) : (
                            <>
                                { props.prepend && props.prependSticky ? props.prepend : null }

                                <Scrollbars
                                    autoHide
                                    autoHideTimeout={ 500 }
                                    autoHideDuration={ 200 }
                                    autoHeight
                                    // each item 40px + padding 8px
                                    autoHeightMax={ props.maxCount ? `${ 40 * props.maxCount + 8 }px` : '100%' }
                                >
                                    <Wrapper padding="xxs" className={ className('menu_items') }>
                                        { props.prepend && !props.prependSticky ? props.prepend : null }
                                        { React.Children.map(props.items, (child, i) => (
                                            React.isValidElement(child)
                                                ? (
                                                    <DropdownItem
                                                        id={ i }
                                                        onSelect={ (e, id) => {
                                                            if (!props.disabled) {
                                                                props.onSelect && props.onSelect(e, id);
                                                            }
                                                            if (props.closeOnSelect) {
                                                                toggleDropdownOpen();
                                                            }
                                                        } }
                                                    >
                                                        { React.cloneElement(child) }
                                                    </DropdownItem>
                                                ) : null)) }
                                    </Wrapper>
                                </Scrollbars>
                            </>
                        ) }

                </Wrapper>
            </div>

        </div>
    );
});

Dropdown.defaultProps = {
    disabled:      false,
    closeOnSelect: false,
    position:      'bottom-left',
    viewType:      'primary'
};
