import React from 'react';
import {
    Button,
    Card, Description, Divider, Icon,
    Wrapper
} from '@geenee/ui';
import { RedirectCardType } from './useHomePageBottomMenu';

export function RedirectToPageCard(props: RedirectCardType) {
    return (
        <Card
            row
            maxWidth="40%"
            mediaBefore
            media={ (
                <Icon name={ props.iconName } size={ 56 } frame="default" rounded={ false } radius="lg" color="gradient-warm-3" />
            ) }
        >
            <Divider transparent margin="sm" />
            <Wrapper padding="xs">
                <Wrapper row>
                    <Description margin="xs" lineHeight="lg" size="lg" weight="bold">{ props.title }</Description>
                </Wrapper>
                <Divider transparent margin="xs" />
                <Description color='dark-5'>
                    { props.description }
                    { props.onClickSDKDocs && (
                        <>
                            { ' ' }
                            Find our
                            { ' ' }
                            <a onClick={ props.onClickSDKDocs } style={ { color: 'black', textDecoration: 'underline' } }>
                                SDK documentation here
                            </a>
                            .
                        </>
                    ) }
                </Description>
                { !props.disabled ? (
                    <Button
                        viewType="link"
                        disableBorderRadius
                        onClick={ props.onClick }
                        size="lg"
                    >
                        { props.buttonName }
                    </Button>
                ) : null }
            </Wrapper>
        </Card>
    );
}
