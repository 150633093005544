import { Quaternion } from "@babylonjs/core/Maths/math.vector.js";
import { SphericalHarmonics } from "@babylonjs/core/Maths/sphericalPolynomial";
import { _Exporter } from "../glTFExporter";
import type { IGLTFExporterExtensionV2 } from "../glTFExporterExtension";
import { Constants, DumpTools, RGBDTextureTools, Scalar, Scene, Tools } from "@babylonjs/core";

const NAME = "EXT_lights_image_based";
/**
 * [Specification](https://github.com/KhronosGroup/glTF/blob/main/extensions/2.0/Vendor/EXT_lights_image_based/README.md)
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class EXT_lights_image_based implements IGLTFExporterExtensionV2 {
    /**
     * The name of this extension.
     */
    public readonly name = NAME;

    /**
     * Defines whether this extension is enabled.
     */
    public enabled = true;

    /** Defines whether this extension is required */
    public required = false;

    private _exporter: _Exporter;

    /**
     * @internal
     */
    constructor(exporter: _Exporter) {
        this._exporter = exporter;
    }

    /** @internal */
    public dispose() {}

    /** @internal */
    public get wasUsed() {
        return !!this._exporter._babylonScene.environmentTexture;
    }

    _exportImage(name, mimeType, data) {
        const imageData = this._exporter._imageData;
        const baseName = name.replace(/\.\/|\/|\.\\|\\/g, "_");
        const extension = '.png';
        let fileName = baseName + extension;
        if (fileName in imageData) {
            fileName = `${baseName}_${Tools.RandomId()}${extension}`;
        }
        imageData[fileName] = {
            data: data,
            mimeType: mimeType,
        };
        const images = this._exporter._images;
        images.push({
            name: name,
            uri: fileName,
        });
        return images.length - 1;
    }

    async getImageData(imageTypeProp?: string) {
        const texture = this._exporter._babylonScene.environmentTexture;
        const internalTexture = texture.getInternalTexture();
        const engine = internalTexture.getEngine();
        const hostingScene = new Scene(engine);
        const specularTextures = [];

        // As we are going to readPixels the faces of the cube, make sure the drawing/update commands for the cube texture are fully sent to the GPU in case it is drawn for the first time in this very frame!
        engine.flushFramebuffer();

        let _a;
        const imageType = (_a = imageTypeProp) !== null && _a !== void 0 ? _a : "image/png";

        if (texture.textureType !== Constants.TEXTURETYPE_HALF_FLOAT &&
            texture.textureType !== Constants.TEXTURETYPE_FLOAT &&
            texture.textureType !== Constants.TEXTURETYPE_UNSIGNED_BYTE &&
            texture.textureType !== Constants.TEXTURETYPE_UNSIGNED_INT &&
            texture.textureType !== Constants.TEXTURETYPE_UNSIGNED_INTEGER &&
            texture.textureType !== -1) {
            return Promise.reject(new Error("The cube texture should allow HDR (Full Float or Half Float)."));
        }

        let textureType = Constants.TEXTURETYPE_FLOAT;
        if (!engine.getCaps().textureFloatRender) {
            textureType = Constants.TEXTURETYPE_HALF_FLOAT;
            if (!engine.getCaps().textureHalfFloatRender) {
                return Promise.reject(new Error("Env texture can only be created when the browser supports half float or full float rendering."));
            }
        }

        // Read and collect all mipmaps data from the cube.
        const mipmapsCount = Scalar.ILog2(internalTexture.width);
        for (let i = 0; i <= mipmapsCount; i++) {
            const faceWidth = Math.pow(2, mipmapsCount - i);
            // All faces of the cube.
            specularTextures[ i ] = [];
            for (let face = 0; face < 6; face++) {
                let faceData = await texture.readPixels(face, i, undefined, false);
                for (let i = 0; i < faceData.byteLength; i++) {
                    // Gamma scale
                    faceData[i] = Math.pow(faceData[i], 0.45);
                }
                const tempTexture = engine.createRawTexture(faceData, faceWidth, faceWidth, Constants.TEXTUREFORMAT_RGBA, false, true, Constants.TEXTURE_NEAREST_SAMPLINGMODE, null, textureType);
                await RGBDTextureTools.EncodeTextureToRGBD(tempTexture, hostingScene, textureType);
                const rgbdEncodedData = await engine._readTexturePixels(tempTexture, faceWidth, faceWidth);
                const imageEncodedData = await DumpTools.DumpDataAsync(faceWidth, faceWidth, rgbdEncodedData, imageType, undefined, false, true);
                specularTextures[i][face] = imageEncodedData;
                tempTexture.dispose();
            }
        }
        return specularTextures;
    }

    async exportSceneAsync(context: string, scene: Record<string, any>) {
        const texture = this._exporter._babylonScene.environmentTexture;
        const imageData = texture._texture._bufferViewArrayArray || await this.getImageData();
        const specularImages = [];
        for (let mipmap = 0; mipmap < imageData.length; mipmap++) {
            const faces = imageData[ mipmap ];
            specularImages.push(new Array(faces.length));
            for (let face = 0; face < faces.length; face++) {
                const index = this._exportImage(`env_tex_${mipmap}_${face}`, 'image/png', imageData[ mipmap ][ face ]);
                specularImages[ mipmap ][ face ] = index;
            }
        }
        const specularImageSize = texture._texture.width;
        const { name } = texture;
        const intensity = texture.level;
        const { sphericalPolynomial } = texture;
        const sphericalHarmonics = SphericalHarmonics.FromPolynomial(sphericalPolynomial);
        sphericalHarmonics.scaleInPlace(1/intensity);
        // Convert lambertian radiance to irradiance
        sphericalHarmonics.scaleInPlace(Math.PI);

        const irradianceCoefficients = [
            sphericalHarmonics.l00.asArray(),
            sphericalHarmonics.l1_1.asArray(),
            sphericalHarmonics.l10.asArray(),
            sphericalHarmonics.l11.asArray(),
            sphericalHarmonics.l2_2.asArray(),
            sphericalHarmonics.l2_1.asArray(),
            sphericalHarmonics.l20.asArray(),
            sphericalHarmonics.l21.asArray(),
            sphericalHarmonics.l22.asArray()
        ];

        const rotationQuaternion = new Quaternion().fromRotationMatrix(texture.getReflectionTextureMatrix());
        const rotation = rotationQuaternion.asArray();
        if (!this._exporter._glTF.extensions) {
            this._exporter._glTF.extensions = {};
        }
        this._exporter._glTF.extensions[this.name] = {
            lights: [
                {
                    intensity,
                    irradianceCoefficients,
                    name,
                    rotation,
                    specularImageSize,
                    specularImages
                }
            ]
        };

        if (!scene.extensions) {
            scene.extensions = {};
        }
        scene.extensions[this.name] = { light: 0 };
    }
}

_Exporter.RegisterExtension(NAME, (exporter) => new EXT_lights_image_based(exporter));
