import { Material }        from "@babylonjs/core/Materials/material";
import { BabylonRenderer } from "@geenee/geespector/renderer/babylonjs.renderer";
import { addUuid }         from "@geenee/geespector/src/straregies/decorators";
import { CommandType }     from "@geenee/shared/src/commander/types";

@addUuid
export class MaterialStrategy implements CommandType {
    receiver: BabylonRenderer;
    entity: Material;

    constructor(receiver: BabylonRenderer, entity: Material) {
        this.receiver = receiver;
        this.entity = entity;
    }
    execute() {
        this.receiver.addMaterial(this.entity);
    }
    revert() {
        this.receiver.removeMaterial(this.entity);
    }
}
