import React                       from 'react';
import isEmpty                     from 'lodash-es/isEmpty';
import { DowngradeDataProps }      from '@geenee/builder/src/@custom_types/types';
import { useSubscriptionHandlers } from '@geenee/builder/src/components/modals/common/handlers/useSubscriprionHandlers';
import { stripeState }             from '@geenee/builder/src/core/state/stripe.state';
import { NOTION_SUPPORT_URL }      from '@geenee/builder/src/lib/constants';
import { useCloseAndRedirect }     from '../common/handlers/useCloseAndRedirect';
import {
    Bold,
    Button,
    ButtonsWrapper,
    Link
}                                            from '../common/styles';
import { Payment as PaymentModal }           from '../Payment';
import { PreDowngrade as PreDowngradeModal } from '../PreDowngrade';
import { DowngradeWrapper }                  from './styles';

type DowngradeProps = {
    priceId: string;
    mainText: string;
    mainTextFooter: string;
    isHobbyist: boolean;
    close: () => void;
    // eslint-disable-next-line no-empty-pattern
    setModal: ({ }) => void;
    preDowngradeData: DowngradeDataProps;
};

const Downgrade = function (props: DowngradeProps) {
    const defaultMainText = `You will no longer be able to
    access the full scope of templates, or have access
    to our premium hosting for large augmented reality files and
    high traffic.`;

    const downgradePlan = stripeState.prices[ props.priceId ];
    const { goToContact } = useCloseAndRedirect(props.close);

    const { waiting, makePurchase } = useSubscriptionHandlers();

    const onDowngradeClick = async () => {
        if (!isEmpty(props.preDowngradeData)) {
            props.setModal({
                component: PreDowngradeModal,
                props:     {
                    priceId:          props.priceId,
                    preDowngradeData: { ...props.preDowngradeData, planName: downgradePlan.name }
                }
            });
            return;
        }
        if (props.isHobbyist) {
            await makePurchase(undefined, props.priceId, undefined, undefined, undefined, false);
            props.close();
        } else {
            props.setModal({
                component: PaymentModal,
                props:     {
                    priceId: props.priceId,
                    type:    'DOWNGRADE'
                }
            });
        }
    };

    return (
        <DowngradeWrapper>
            <Bold style={ { textTransform: 'uppercase' } }>
                { downgradePlan.name }
            </Bold>
            <p>
                Are you sure you want to downgrade to the
                { ' ' }
                { downgradePlan.name }
                { ' ' }
                Plan?
            </p>
            <p>
                { props.mainText || defaultMainText }
            </p>
            { props.mainTextFooter
                && (
                    <p>
                        { props.mainTextFooter }
                    </p>
                ) }
            <ButtonsWrapper>
                <Button
                    $light
                    $width="172px"
                    $height="40px"
                    label='NEVERMIND'
                    onClick={ () => props.close() }
                />
                <Button
                    onClick={ async () => {
                        await onDowngradeClick();
                    } }
                    $width="172px"
                    $height="40px"
                    label="DOWNGRADE"
                    $waiting={ waiting }
                />
            </ButtonsWrapper>
            <p style={ { marginBottom: '15px' } }>Need help with anything?</p>
            <p>
                Read our
                { ' ' }
                <Link onClick={ () => window.open(NOTION_SUPPORT_URL) }>FAQ’s</Link>
            </p>
            <p>
                <Link onClick={ () => goToContact() }>Submit a question</Link>
            </p>
        </DowngradeWrapper>
    );
};

// eslint-disable-next-line arca/no-default-export
export default Downgrade;
