import React, {
    FC, ForwardedRef, forwardRef, memo, useEffect, useMemo, useState
} from "react";
import { cn }                                                       from "../../util/bem";
import { externalClasses, MediaPropsType }                          from "../../util/externalClasses";
import { FormSizesType, RadiusSizesType, SizeTypes, TextColorType } from "../../util/global-props";
import { Icon, IconPropsType }                                      from "../icon/icon.component";
import { iconMap }                                                  from "../icon/icon.library";
import { Loader, loaderSize }                                       from "../loader/loader.component";
import "./button.component.scss";

export type ButtonPropsType = {
    className?: string;
    id?: string
    children?: React.ReactNode;
    before?: React.ReactNode;
    after?: React.ReactNode;
    type?: "button" | "submit" | "reset";
    weight?: "bold" | "medium" | "regular" | "semi-bold";
    icon?: keyof typeof iconMap;
    iconAfter?: boolean;
    iconFloat?: "left" | "right";
    iconColor?: IconPropsType["color"];
    iconMargin?: SizeTypes;
    iconStroke?: "normal" | "fat";
    iconSize?: number;

    descriptionMargin?: SizeTypes
    border?: TextColorType;
    shadow?: "default" | "none" | "hover" | "xs"

    fullWidthContent?: boolean;
    variant?: "default" | "colorful";
    align?: "left" | "center" | "right";
    viewType?: "primary"
        | "secondary"
        | "green"
        | "link"
        | "action"
        | "white"
        | "transparent"
        | "alert"
        | "3d-scene-primary"
        | "error"
    size?: FormSizesType;
    color?: TextColorType;
    square?: boolean;
    uppercase?: boolean;
    active?: boolean;

    loading?: boolean;
    fullWidth?: boolean;
    rounded?: boolean;
    disabled?: boolean;
    flex?: number | string;
    margin?: SizeType;
    style?: React.CSSProperties;
    hide?: MediaPropsType;
    disableBorderRadius?: boolean;
    radius?: RadiusSizesType;

    data?: Record<string, string>;
    ref?: React.Ref<HTMLButtonElement>;
    onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement, PointerEvent>>;
    onFocus?: React.EventHandler<React.FocusEvent<HTMLButtonElement>>;
    onMouseLeave?: React.EventHandler<React.MouseEvent<HTMLButtonElement, PointerEvent>>;
    onMouseEnter?: React.EventHandler<React.MouseEvent<HTMLButtonElement, PointerEvent>>;
}

const className = cn("button");

export const Button: FC<ButtonPropsType> = memo(forwardRef((props: ButtonPropsType, ref: ForwardedRef<HTMLButtonElement>) => {
    const [ loading, setLoading ] = useState(false);
    const getIconColor = () => {
        if (props.iconColor) {
            return props.iconColor;
        }
        if (props.viewType === "primary" || props.viewType === "alert") {
            return "white";
        }
        return "dark";
    };

    const iconFloatStyle = useMemo(
        () => props.iconFloat && { [ `margin${ (() => (props.iconFloat === "left" ? "Right" : "Left"))() }` ]: "auto" },
        [ props.iconFloat ]
    );
    const getContent = () => (
        <span className={ className("content", { fullWidth: props.fullWidthContent }) }>
            { props.before }
            { props.icon && !props.iconAfter && (
                <Icon
                    style={ iconFloatStyle }
                    margin={ props.iconMargin }
                    stroke={ props.iconStroke }
                    name={ props.icon }
                    size={ props.iconSize || undefined }
                    onMouseEnter={ () => {
                        console.log("enter");
                    } }
                    color={ getIconColor() }
                />
            ) }
            <div className={ className("button-description", { margin: props.descriptionMargin }) }>
                { props.children }
            </div>
            { props.icon && props.iconAfter && (
                <Icon
                    margin={ props.iconMargin }
                    style={ iconFloatStyle }
                    stroke={ props.iconStroke }
                    name={ props.icon }
                    size={ props.iconSize || undefined }
                    color={ getIconColor() }
                />
            ) }
            { props.after }
        </span>
    );
    const getLoader = () => (
        <div className={ className("loader") }>
            <Loader
                color={ (props.viewType === "primary" || props.viewType === "alert") ? "white" : "blue" }
                size={ loaderSize[ props.size as keyof typeof loaderSize ] }
            />
        </div>
    );
    useEffect(() => {
        if (props.loading) {
            setTimeout(() => setLoading(true), 100);
        }
        if (!props.loading) {
            setTimeout(() => setLoading(false), 100);
        }
    }, [ props.loading ]);

    return (
        // eslint-disable-next-line react/button-has-type
        <button
            { ...props.data }
            id={ props.id }
            type={ props.type }
            className={ className({
                size:                props.size,
                align:               props.align,
                type:                props.viewType,
                fullWidth:           props.fullWidth,
                rounded:             props.rounded,
                disabled:            props.disabled,
                margin:              props.margin,
                flex:                props.flex,
                noContent:           !props.children,
                color:               props.color,
                uppercase:           props.uppercase,
                square:              !props.rounded && props.square,
                disableBorderRadius: props.disableBorderRadius,
                active:              props.active,
                radius:              props.radius,
                variant:             props.variant,
                shadow:              props.shadow,
                border:              props.border,
                loading
            }, null, props.className, externalClasses({ media: props.hide })) }
            onClick={ props.onClick }
            onFocus={ props.onFocus }
            onMouseLeave={ props.onMouseLeave }
            onMouseEnter={ props.onMouseEnter }
            style={ props.style }
            ref={ ref }
        >
            { getContent() }
            { props.loading && getLoader() }
        </button>
    );
}));

Button.defaultProps = {
    type:       "button",
    size:       "md",
    viewType:   "primary",
    iconMargin: "xs",
    iconAfter:  false,
    weight:     'bold',
    radius:     'lg',
    shadow:     'default'
};
