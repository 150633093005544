import { ComponentType, FC } from 'react';

export type ComponentHOC<ExternalProps = {}> = <T>(
  Component: ComponentType<T>
) => // @ts-ignore
FC<Partial<T> & ExternalProps>;

type FunctionHOC = (...any: any) => any;

export const compose = (...funcs: FunctionHOC[]): ComponentHOC => funcs.reduce(
    (a, b) => (...args: any) => a(b(...args)),
    (arg: any) => arg
);
