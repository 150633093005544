import React from 'react';
import {
    MARKER_AR_INSTRUCTIONS,
    TYPE_BODY_TRACKING_OVERLAY, TYPE_BODY_TRACKING_TWIN,
    TYPE_DRAWER_OVERLAY,     TYPE_FULLSCREEN_GALLERY, TYPE_FULLSCREEN_VIDEO,
    TYPE_HEAD_TRACKING,
    TYPE_IFRAME,
    TYPE_NATIVE_AR,
    TYPE_SCENE_BUILD,
    TYPE_SLAM_AR,
    TYPE_STAMP_AR,
    TYPE_WYSIWYG
} from '@geenee/builder/src/lib/constants';
import { openInSmallWindow } from '@geenee/builder/src/utils/openInSmallWindow';
import { TYPE_CUSTOM_CODE }  from '@geenee/shared/src/magellan/components/contentView/constants';
import { Button }            from '@geenee/ui';

export const getRightTitle = (type: string) => {
    switch (type) {
        case TYPE_SLAM_AR:
            return 'World AR';
        case TYPE_NATIVE_AR:
            return 'True Size AR';
        case TYPE_SCENE_BUILD:
            return 'AR Build';
        case TYPE_STAMP_AR:
            return 'Marker AR';
        case TYPE_DRAWER_OVERLAY:
            return 'Overlay';
        case TYPE_IFRAME:
            return 'Page Embed';
        case TYPE_FULLSCREEN_VIDEO:
            return 'Video Embed';
        case TYPE_FULLSCREEN_GALLERY:
            return 'Gallery';
        case TYPE_WYSIWYG:
            return 'WYSIWYG';
        case TYPE_CUSTOM_CODE:
            return 'Custom Code';
        case TYPE_BODY_TRACKING_OVERLAY:
            return 'Body Tracking Overlay Build';
        case TYPE_BODY_TRACKING_TWIN:
            return 'Body Tracking Twin Build';
        case TYPE_HEAD_TRACKING:
            return 'Face Tracking Build';
        default:
            return 'Undefined Content type';
    }
};

export const getRightPlaceHolder = (type: string) => {
    switch (type) {
        case TYPE_SLAM_AR:
            return 'Untitled World AR';
        case TYPE_NATIVE_AR:
            return 'Untitled Native AR';
        case TYPE_STAMP_AR:
            return 'Untitled Marker AR';
        case TYPE_DRAWER_OVERLAY:
            return 'Untitled Drawer';
        case TYPE_IFRAME:
            return 'Untitled IFrame';
        case TYPE_FULLSCREEN_VIDEO:
            return 'Untitled Video';
        case TYPE_FULLSCREEN_GALLERY:
            return 'Untitled Image Gallery';
        case TYPE_WYSIWYG:
            return 'Untitled WYSIWYG';
        default:
            return 'Undefined Content type';
    }
};

export const getRightHint = (type: string) => {
    switch (type) {
        case TYPE_DRAWER_OVERLAY:
            return (
                <>
                    Tap the overlay’s UI to edit the text and button.
                    <br />
                    <br />
                    You can also change the overlay’s background color, turn off the button,
                    and/or time the overlay to open after a designated time period within your Experience.
                </>
            );
        case TYPE_SCENE_BUILD:
            return (
                <>
                    Choose your AR Type, then build out your scene(s).
                </>
            );
        case TYPE_SLAM_AR:
            return (
                <>
                    Add a new scene via the carousel. Each scene can be a group of 1 or more assets.
                    Optionally you can turn off the carousel as well as the right rail end-user navigational menu.
                    <br />
                    <br />
                    Go full screen to add assets, sound, & interactivity.
                </>
            );
        case TYPE_STAMP_AR:
            return (
                <>
                    Add your tracker image per scene.
                    { ' ' }
                    <Button
                        style={ { display: 'unset' } }
                        viewType="link"
                        onClick={ () => {
                            openInSmallWindow(MARKER_AR_INSTRUCTIONS, 1200, 800);
                        } }
                    >
                        Click here for best practices.

                    </Button>
                    <br />
                    <br />
                    Add a new scene via the carousel. Each scene can be a group of 1 or more assets.
                    Optionally you can turn off the carousel as well as the right rail end-user navigational menu.
                    <br />
                    <br />
                    Go full screen to add assets, sound, & interactivity.
                </>
            );
        case TYPE_IFRAME:
            return (
                <>Embed a webpage by adding the link below. If your page can not be embedded, it will open in a new tab. </>
            );
        case TYPE_FULLSCREEN_VIDEO:
            return (
                <>Embed a video into your Experience to go deeper into your product, brand or story.</>
            );
        case TYPE_FULLSCREEN_GALLERY:
            return (
                <>
                    Drop images to create a full screen gallery. Users will swipe up and down to view.
                </>
            );
        case TYPE_WYSIWYG:
            return (
                <>
                    This is the WYSIWYG template. Click or drag & drop a
                    widget from the top bar and then drop in your media
                    assets.
                </>
            );
        case TYPE_CUSTOM_CODE:
            return (
                <>Add your own code to create a unique html/css page.</>
            );
        case TYPE_NATIVE_AR:
            return (
                <>
                    Go full screen to add 3D assets.
                </>
            );
        case TYPE_BODY_TRACKING_OVERLAY:
        case TYPE_BODY_TRACKING_TWIN:
            return (
                <>
                    Create virtual try-on or avatar mirroring experiences
                    by attaching one more more assets to the body occluder. Go full screen to create.
                </>
            );
        case TYPE_HEAD_TRACKING:
            return (
                <>
                    Create virtual try-on experiences by attaching one more more assets to the head occluder. Go full screen to create.
                </>
            );
        default:
            return <></>;
    }
};
