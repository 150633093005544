import 'swiper/css/bundle';
import React                                                   from 'react';
import { useParams }                                           from "react-router-dom";
import { useHistory }                                          from "@geenee/common";
import { MoleculeComponent }                                   from "@geenee/geeclient-kit/src";
import { defaultOptions, ScenesListOptType }                   from "@geenee/geeclient-kit/src/lib/default-options/molecule/ScenesList";
import { CombinationMoleculeSchemaType }                       from "@geenee/geeclient-kit/src/lib/type/section-molecule";
import { MoleculeModel, RouteParams, SectionModel, useInject } from '@geenee/shared';
import { observer }                                            from 'mobx-react';

interface Props {
  sectionModel: SectionModel,
}

export const ScenesListComponent = observer(({ sectionModel }: Props) => {
    const history = useHistory();
    const { AppState } = useInject();
    const { activeExperience, activeView, activeMolecule } = AppState;
    const {
        projectId,
        moleculeId
    } = useParams<RouteParams>();
    const iterableItems = sectionModel.sortedChildren as MoleculeModel[];

    // @ts-ignore
    const isBuilder = process.env.ENV_GEENEE_APP === 'BUILDER';
    const hidePanel = !isBuilder && (iterableItems.length === 1);

    const openCurrentScene = (sceneMolecule: MoleculeModel) => {
        if (activeExperience?.id && sceneMolecule?.id && sceneMolecule?.id !== moleculeId) {
            AppState.updateOption('moleculeId', sceneMolecule.id as any);
            if (!isBuilder) {
                sectionModel.sceneRenderer.audioInit(sceneMolecule?.audioAtom);
                if (sceneMolecule.audioAtom) {
                    sectionModel.sceneRenderer.playAudio();
                }
            }
            history.push(
                `/${ projectId }/${ activeExperience.id }/${ activeView?.id }/${ sectionModel.id }/${ sceneMolecule.id }`
            );
        }
    };

    const createNewScene = async () => {
    // @ts-ignore
        const newMolecule = await (sectionModel as SectionModel).createMolecule({ type: 'scene-build' });
        openCurrentScene(newMolecule);
    };

    const moleculeSchema: CombinationMoleculeSchemaType<ScenesListOptType> = {
        atoms:   [ ...iterableItems, ...isBuilder ? [ { id: 'new' } ] : [] ],
        // @ts-ignore
        options: {
            ...defaultOptions,
            activeIndex:   iterableItems.findIndex((el) => el.id === AppState.options.moleculeId),
            activeId:      activeMolecule?.id,
            onSlideChange: (index: number) => { openCurrentScene(iterableItems[ index ]); },
            onItemClick:   (item: MoleculeModel) => {
                if (item.id === 'new') {
                    createNewScene();
                } else {
                    openCurrentScene(item);
                }
            }
        }
    };

    if (hidePanel) {
        return <></>;
    }

    return (
        <section
            id="scenes-list"
            data-color-root="true"
            data-color-bg="black"
        >
            <MoleculeComponent
                kitMolecule="ScenesList"
                moleculeSchema={ moleculeSchema }
            />
        </section>
    );
});
