import styled from 'styled-components';

export const EditableBtn = styled.button`
  width: 100%;
  background: rgb(0, 0, 0);
  border: 1px solid rgb(0, 0, 0);
  border-radius: 5px;
  .ql-editor {
    padding-top: 8px;
    padding-bottom: 8px;
    color: rgb(255, 255, 255);
  }
`;
