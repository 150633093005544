import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '12',
    height = '12',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="6" cy="6" r="5.5" fill="white" stroke="#BBBBBB" />
            <path
            // eslint-disable-next-line max-len
                d="M8.64286 3H3.92857C3.41574 3 3 3.41574 3 3.92857V8.07143C3 8.58426 3.41574 9 3.92857 9H8.64286C9.15569 9 9.57143 8.58426 9.57143 8.07143V3.92857C9.57143 3.41574 9.15569 3 8.64286 3ZM3.92857 3.42857H8.64286C8.91909 3.42857 9.14286 3.65234 9.14286 3.92857V4.85714H3.42857V3.92857C3.42857 3.65234 3.65234 3.42857 3.92857 3.42857ZM3.42857 8.07143V5.28571H4.85714V8.57143H3.92857C3.65234 8.57143 3.42857 8.34766 3.42857 8.07143ZM8.64286 8.57143H5.28571V5.28571H9.14286V8.07143C9.14286 8.20396 9.09012 8.33119 8.99637 8.42494C8.90262 8.51869 8.77539 8.57143 8.64286 8.57143Z"
                fill="black"
            />
        </svg>
    );
}
