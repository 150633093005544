import React               from "react";
import { Scrollbars }      from "react-custom-scrollbars";
import { Wrapper }         from "@geenee/ui";
import { observer }        from "mobx-react";
import { HeaderTitle }     from "../HeaderTitle";
import { HomePageContent } from "./components/HomePageContent";

export const HomePage = observer(() => (
    <Scrollbars
        autoHide
        autoHideTimeout={ 500 }
        autoHideDuration={ 200 }
    >
        <Wrapper
            fullHeight
            align="center"
            padding="xl"
        >
            <Wrapper
                style={ {
                    minWidth: "928px",
                    flex:     "1 0 auto"
                } }
                maxWidth="1100px"
            >
                <HeaderTitle
                    weight="medium"
                    gradient
                    title="Getting Started"
                    subtitle="Start from scratch or go straight to the AR 3D Editor:"
                />
                <HomePageContent />
            </Wrapper>
        </Wrapper>
    </Scrollbars>
));
