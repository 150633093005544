import * as React                                  from 'react';
import { TableBodyProps }                          from 'react-table';
import { FrameExtensionUnionType, SizesUnionType } from '../../../util/global-props';
import { Wrapper }                                 from '../../wrapper/wrapper.component';
import { tableClassName }                          from '../utils';

const className = tableClassName('tbody');

type PropsType = {
    children?: React.ReactNode;
    tBodyProps?: TableBodyProps;
    frame?: FrameExtensionUnionType;

    margin?: SizesUnionType;
    padding?: SizesUnionType;

    style?: React.CSSProperties;
}

export const TableBody = React.memo((props: PropsType) => (
    <Wrapper
        frame={ props.frame }
        className={ className }
        margin={ props.margin }
        padding={ props.padding }
        style={ props.style }
        { ...props.tBodyProps }
    >
        { props.children }
    </Wrapper>
));
