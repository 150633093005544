export class SceneAssetCacheService {
    private static cache = new Map();

    static get = (key: string) => this.cache.get(key);

    static add = (key: string, model: File) => {
        this.cache.set(key, model);
    };

    static remove = (key: string) => {
        this.cache.delete(key);
    };
}
