import React, { memo, useState }        from 'react';
import { Description, Switch, Wrapper } from '../../../../index';
import { SizesUnionType }               from '../../../util/global-props';

type PlanSwitcherProps = {
    row?: boolean;
    align?: 'center' | 'right' | 'left' | 'stretch' | 'space-between' | 'space-around';
    valign?: 'top'|'center'|'bottom' | 'stretch';
    padding?: SizesUnionType;
    margin?: SizesUnionType;
    style?: React.CSSProperties;
}

export const PlanSwitcher = memo((props: PlanSwitcherProps) => {
    const [ checked, setChecked ] = useState(false);

    return (
        <Wrapper
            row={ props.row }
            align={ props.align }
            valign={ props.valign }
            padding={ props.padding }
            margin={ props.margin }
            style={ props.style }
        >
            <Description color={ !checked ? 'dark' : 'grey-6' } margin='xs'>Monthly</Description>
            { ' ' }
            <Switch
                size='lg'
                margin='off'
                checked={ checked }
                onChange={ () => setChecked(!checked) }
            />
            { ' ' }
            <Description color={ checked ? 'dark' : 'grey-6' }>Yearly</Description>
        </Wrapper>
    );
});
