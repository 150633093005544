import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default ({
    width = '20',
    height = '22',
    onClick,
    id,
    fill = 'black'
}: SvgIconProps) => (
    <svg
        fill="none"
        id={ id }
        width={ width }
        height={ height }
        viewBox="0 0 20 22"
        onClick={ onClick }
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M9 12.5858V1C9 0.447715 9.44771 0 10 0C10.5523 0 11 0.447715 11 1V12.5858L13.2929 10.2929C13.6834 9.90237 14.3166 9.90237 14.7071 10.2929C15.0976 10.6834 15.0976 11.3166 14.7071 11.7071L10.7078 15.7064C10.7054 15.7088 10.703 15.7112 10.7005 15.7136C10.6062 15.8063 10.498 15.8764 10.3828 15.9241C10.2657 15.9727 10.1375 15.9996 10.003 16C10.002 16 10.001 16 10 16C9.999 16 9.998 16 9.997 16C9.72537 15.9992 9.47921 15.8901 9.29945 15.7136C9.29702 15.7112 9.29459 15.7088 9.29218 15.7064L5.29289 11.7071C4.90237 11.3166 4.90237 10.6834 5.29289 10.2929C5.68342 9.90237 6.31658 9.90237 6.70711 10.2929L9 12.5858ZM0 16V19C0 20.6569 1.34315 22 3 22H17C18.6569 22 20 20.6569 20 19V16C20 15.4477 19.5523 15 19 15C18.4477 15 18 15.4477 18 16V19C18 19.5523 17.5523 20 17 20H3C2.44772 20 2 19.5523 2 19V16C2 15.4477 1.55228 15 1 15C0.447715 15 0 15.4477 0 16Z"
            fill={ fill }
        />
    </svg>
);
