import React, { FC }                    from 'react';
import { iconMap }                      from "@geenee/geeclient-kit/src/lib/component/atom/icon/icon.library";
import { SiteMoleculeSchemaType }       from '@geenee/geeclient-kit/src/lib/type/section-molecule';
import { observer }                     from 'mobx-react';
import { withMoleculeContext }          from '../../../hoc/withMoleculeContext';
import { IconAtomOptType }              from '../../../type/_atom-option-type';
import { cnKitAtom }                    from '../../../util/bem';
import { compose }                      from '../../../util/compose';
import { withProps }                    from '../../../util/withProps';
import { getPropsFromOwner, initProps } from './util';
import './icon.atom.component.scss';

const className = cnKitAtom('atom-icon');
export type IconAtomComponentType = {
  color_auto_variant: string
} & IconAtomOptType
// eslint-disable-next-line react/function-component-definition
const IconView: FC<IconAtomComponentType> = (props) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const icon: JSX.Element = iconMap[ props.icon_geenee ];

    const getColorVariation = () => {
        if (props.icon_color_bg_type === 'auto') {
            return props.color_auto_variant;
        }
        return props.icon_color_bg_variation;
    };

    const getIconRadius = () => {
        const radius = (props.icon_corner_radius === 'custom')
            ? `${ props.icon_corner_radius_custom }px`
            : `${ props.icon_corner_radius }px`;
        return { '--atom-icon--icon_corner_radius': radius };
    };

    const getColor = () => ({
        '--color-bg': props.icon_color_bg_type === 'custom'
            ? props.icon_color_bg_custom
            : undefined,
        '--color-text': props.icon_color_text_type === 'custom'
            ? props.icon_color_text_custom
            : undefined,
        '--color-util': props.icon_color_border_type === 'custom'
            ? props.icon_color_border_custom
            : undefined
    });

    const getCustomBlurRadius = () => (props.icon_blur_radius === 'custom'
    && props.icon_blur_radius_custom ? { '--blur-radius': `${ props.icon_blur_radius_custom }px` } : {});

    if (!props.icon_switch) {
        return null;
    }

    return (
        <div
            data-atom={ `icon-${ props.icon_type }` }
            className={ className('', {
                type:                             props.icon_style,
                size:                             props.icon_size,
                indent:                           props.icon_indent,
                [ `type-${ props.icon_style }` ]: props.icon_blur_radius
            }) }
            data-color-util={
                props.icon_style === 'line' || props.icon_style === 'blur' ? props.icon_color_border : undefined
            }
            data-color-util-alpha={
                props.icon_style === 'line' || props.icon_style === 'blur' ? props.icon_color_border_alpha : undefined
            }
            data-color-util-variation={
                props.icon_style === 'line' || props.icon_style === 'blur'
                    ? props.icon_color_border_variation
                    : undefined
            }
            data-color-text={ props.icon_color_text }
            data-color-text-alpha={ props.icon_color_text_alpha }
            data-color-text-type={ props.icon_color_text_type }
            data-color-text-variation={ props.icon_color_text_variation }
            data-color-bg={
                props.icon_style === 'solid' || props.icon_style === 'blur' ? props.icon_color_bg : undefined
            }
            data-color-bg-alpha={ props.icon_color_bg_alpha }
            data-color-bg-type={
                props.icon_style === 'solid' || props.icon_style === 'blur' ? props.icon_color_bg_type : undefined
            }
            data-color-bg-variation={
                props.icon_style === 'solid' || props.icon_style === 'blur' ? getColorVariation() : undefined
            }
            style={ { ...getColor(), ...getIconRadius(), ...getCustomBlurRadius() } as React.CSSProperties }

        >
            { icon }
        </div>
    );
};

IconView.defaultProps = { ...initProps } as Partial<IconAtomOptType>;

const enhance = compose(
    withMoleculeContext,
    withProps(
        ({
            moleculeContext,
            ...extendProps
        }: {
      moleculeContext: SiteMoleculeSchemaType<IconAtomOptType>;
    }) => getPropsFromOwner(moleculeContext.options, extendProps)
    ),
    observer
);

export const IconAtomComponent = enhance(IconView);
