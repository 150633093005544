import React, { useMemo } from "react";
import { useHistory }     from "@geenee/common";
import { Analytics }      from "@geenee/analytics";
import {
    Column, DataType, Description, Divider, Icon,
    Tooltip, Wrapper
} from "@geenee/ui";
import { DowngradeDataProps }                 from "@geenee/builder/src/@custom_types/types";
import DowngradeModal                         from '@geenee/builder/src/components/modals/Downgrade';
import { useBuilderInject }                   from "@geenee/builder/src/core/hook/use-builder-inject";
import { teamState }                          from "@geenee/builder/src/core/state/team.state";
import { CONTACT_US_FROM_PLANS_PAGE_CLICKED } from "@geenee/builder/src/lib/constants";
import { TableFooterComponent }               from "@geenee/builder/src/pages/AllPlansPage/components/plan-footer.component";
import { useTableInfo }                       from "@geenee/builder/src/pages/AllPlansPage/useTableInfo";
import { TableHeaderComponent }               from "./components/plan-header.component";
import { SubscriptionPeriodSwitcher }         from "./components/subscription-period-switcher.component";
import { tableCells }                         from "./constants";

export const useTableData = (
    stripeState,
    yearCycle: boolean,
    currentInterval: string,
    onCycleChange: (val: boolean) => void,
    showModal: (planId: string) => void,
    setModalOptions: (v: any) => void
) => {
    const planTitle = stripeState.plan_title;
    const planPeriod = stripeState.plan_period;
    const planIsActive = stripeState.status === 'active';

    const history = useHistory();
    const getDivider = () => ({
        features:   <Divider margin='xxs' />,
        hobbyists:  <Divider />,
        premium:    <Divider />,
        teams:      <Divider />,
        enterprise: <Divider />
    });
    const { tableInfo } = useTableInfo();
    const { container } = useBuilderInject();
    const analytics: Analytics = container.get('<Analytics>');

    const onClickHobbyist = () => {
        const tablePlan = tableInfo.Hobbyist.year;
        setModalOptions({
            component: DowngradeModal,
            props:     {
                priceId:  tablePlan.id,
                mainText: `Please note you will be moving to a non-commercial
                    license with no access to custom features, team seats, library, analytics,
                    and the ability to mint. In addition, you will have an account cap of 500 views per month
                    and all projects will have a Geenee watermark.`,
                mainTextFooter: 'If this sounds right, please continue.',
                isHobbyist:     true
            }
        });
    };

    const onClickPremium = () => {
        const tablePlan = tableInfo[ 'Premium2.0' ][ yearCycle ? 'year' : 'month' ];
        if (planTitle === 'Enterprise' || planTitle === 'Teams') {
            setModalOptions({
                component: DowngradeModal,
                props:     {
                    priceId:  tablePlan.id,
                    mainText: `Please note you will be moving to a non-commercial license and your projects will no longer
                        have access to your custom features, team seats, library, and analytics. In addition,
                        you will have an account cap of 1000 views per month.`,
                    mainTextFooter: 'If this sounds right, please continue.'
                }
            });
        } else {
            showModal(tablePlan.id);
        }
    };

    const onClickTeams = () => {
        const team = Array.from(teamState.userStore.values());
        const { invites } = teamState;

        const tablePlan = tableInfo.Teams[ yearCycle ? 'year' : 'month' ];
        const parentPlan = stripeState.plans[ tablePlan.parent_plan_id || '' ];
        if (!parentPlan) {
            console.error('Plan is not found');
            return;
        }
        // -1 for the owner itself
        const maxMembersPerPlan = parentPlan.params.seats - 1;
        const maxMembersReached = team.length + invites.length > maxMembersPerPlan;

        const preDowngradeData = {} as DowngradeDataProps;
        if (maxMembersReached) {
            preDowngradeData.members = [ ...team, ...invites ];
            preDowngradeData.maxMembers = maxMembersPerPlan;
        }

        if (planTitle === 'Enterprise') {
            setModalOptions({
                component: DowngradeModal,
                props:     {
                    priceId:  tablePlan.id,
                    mainText: `Please note your projects will no longer have access to your custom features.
                        In addition, you will have a total of 5 seats and an account cap at 10,000 views per month.`,
                    mainTextFooter: 'If this sounds right, please continue.',
                    preDowngradeData
                }
            });
        } else {
            showModal(tablePlan.id);
        }
    };
    const contactUsClickHandler = () => {
        const {
            plan_id,
            plan_period,
            plan_price,
            plan_price_id,
            plan_title
        } = stripeState;

        analytics.track(CONTACT_US_FROM_PLANS_PAGE_CLICKED, {
            plan_id,
            plan_period,
            plan_price,
            plan_price_id,
            plan_title
        });
        history.push('/contact', { category: 'sales' });
    };

    const getTableCells = () => {
        const cells: Array<object> = [ getDivider() ];
        tableCells.forEach((feature) => {
            if (feature.title === 'Views / month') {
                cells.push({
                    features: <Wrapper row margin='xxs' color='shade-1' valign='center' style={ { height: '24px' } }>
                        <Wrapper margin='xs' maxWidth='fit-content'><Description color='shade-1'>Views / month</Description></Wrapper>
                        <Tooltip
                            shadow
                            position='right'
                            menuPadding='xs'
                            minWidth='224px'
                            valign="top"
                            //
                            mediaRow
                            mediaBefore
                            media={ <Icon margin="sm" size={ 36 } color="gradient-warm-3" name={ feature.tooltipIcon } /> }
                            //
                            title={ feature.tooltipTitle }
                            titleColor='shade-2'
                            message={ feature.tooltipMessage }
                            messageColor='shade-4'
                            messageAlign='left'
                        >
                            <Icon size={ 16 } color='shade-2' name='info' />
                        </Tooltip>
                              </Wrapper>,
                    hobbyists: <Wrapper
                        color='shade-1'
                        align='center'
                        valign='center'
                        style={ { height: '24px' } }
                    >
                        <Description>500 views</Description>
                               </Wrapper>,
                    premium: <Wrapper
                        color='shade-1'
                        align='center'
                        valign='center'
                        style={ { height: '24px' } }
                    >
                        <Description>1K views</Description>
                             </Wrapper>,
                    teams: <Wrapper
                        color='shade-1'
                        align='center'
                        valign='center'
                        style={ { height: '24px' } }
                    >
                        <Description>10K views</Description>
                           </Wrapper>,
                    enterprise: <Wrapper
                        color='shade-1'
                        align='center'
                        valign='center'
                        style={ { height: '24px' } }
                    >
                        <Description>Custom</Description>
                                </Wrapper>
                });
            } else if (feature.title === 'Seats') {
                cells.push({
                    features: <Wrapper row margin='xxs' valign='center' style={ { height: '24px' } }>
                        <Wrapper margin='xs' maxWidth='fit-content'><Description color='shade-1'>Seats</Description></Wrapper>
                        <Tooltip
                            frame="solid-white"
                            shadow
                            valign="top"
                            position='right'
                            menuPadding='sm'
                            minWidth='224px'
                            //
                            mediaRow
                            mediaBefore
                            media={ <Icon margin="sm" size={ 36 } color="gradient-warm-3" name={ feature.tooltipIcon } /> }
                            //
                            title={ feature.tooltipTitle }
                            titleColor='shade-2'
                            message={ feature.tooltipMessage }
                            messageColor='shade-4'
                            messageAlign='left'
                        >
                            <Icon size={ 16 } color='shade-2' name='info' />
                        </Tooltip>
                              </Wrapper>,
                    hobbyists: <Wrapper
                        color='shade-1'
                        align='center'
                        valign='center'
                        style={ { height: '24px' } }
                    >
                        <Description>1</Description>
                               </Wrapper>,
                    premium: <Wrapper
                        color='shade-1'
                        align='center'
                        valign='center'
                        style={ { height: '24px' } }
                    >
                        <Description>1</Description>
                             </Wrapper>,
                    teams: <Wrapper
                        color='shade-1'
                        align='center'
                        valign='center'
                        style={ { height: '24px' } }
                    >
                        <Description>5</Description>
                           </Wrapper>,
                    enterprise: <Wrapper
                        color='shade-1'
                        align='center'
                        valign='center'
                        style={ { height: '24px' } }
                    >
                        <Description>Custom</Description>
                                </Wrapper>
                });
            } else {
                cells.push({
                    features: <Wrapper row margin='xxs' valign='center' style={ { height: '24px' } }>
                        <Wrapper margin='xs' maxWidth='fit-content'><Description color='shade-1'>{ feature.title }</Description></Wrapper>
                        <Tooltip
                            shadow
                            valign="top"
                            position='right'
                            menuPadding='xs'
                            minWidth='224px'
                            //
                            mediaRow
                            mediaBefore
                            media={ <Icon margin="xs" size={ 36 } color="gradient-warm-3" name={ feature.tooltipIcon } /> }
                            //
                            title={ feature.tooltipTitle }
                            titleColor='shade-2'
                            message={ feature.tooltipMessage }
                            messageColor='shade-4'
                            messageAlign='left'
                        >
                            <Icon size={ 16 } color='shade-2' name='info' />
                        </Tooltip>
                              </Wrapper>,
                    hobbyists: feature.hobbyists ? <Wrapper align='center'><Icon color='shade-2' size={ 24 } name='checkSmall' /></Wrapper>
                        : <Wrapper color='shade-1' align='center'>–</Wrapper>,
                    premium: feature.premium ? <Wrapper align='center'><Icon color='shade-2' size={ 24 } name='checkSmall' /></Wrapper>
                        : <Wrapper color='shade-1' align='center'>–</Wrapper>,
                    teams: feature.teams ? <Wrapper align='center'><Icon color='shade-2' size={ 24 } name='checkSmall' /></Wrapper>
                        : <Wrapper color='shade-1' align='center'>–</Wrapper>,
                    enterprise: feature.enterprise ? <Wrapper align='center'><Icon color='shade-2' size={ 24 } name='checkSmall' /></Wrapper>
                        : <Wrapper color='shade-1' align='center'>–</Wrapper>
                });
            }
            cells.push(getDivider());
        });

        return cells;
    };

    const columns: Column<DataType>[] = useMemo(() => [
        {
            accessor: 'features',
            width:    270,
            Header:   <SubscriptionPeriodSwitcher
                key={ 0 }
                yearlyCycle={ yearCycle }
                onChange={ onCycleChange }
            />,
            Cell: ({ cell: { value } }) => <Wrapper key={ value }>{ value }</Wrapper>
        },
        {
            accessor: 'hobbyists',
            width:    180,
            Header:   <TableHeaderComponent
                frame="gradient-warm-4"
                title="HOBBYISTS"
                commercial="Non-Commercial Use"
                message="Try out our products for free with or without code, fast & easily"
            />,
            Footer: <TableFooterComponent
                description={
                    (
                        <Description weight='bold' color='shade-1' margin='sm'>
                            { '\n' }
                            Free
                        </Description>
                    )
                }
                currentPlanIsActive={ planIsActive }
                isCurrentPlan={ planTitle === 'Hobbyist' }
                buttonText="Downgrade"
                onClick={ onClickHobbyist }
                key={ 2 }
            />,
            Cell: ({ cell: { value } }) => <Wrapper key={ value }>{ value }</Wrapper>
        },
        {
            accessor: 'premium',
            width:    180,
            Header:   <TableHeaderComponent
                frame="gradient-cold-2"
                title="PREMIUM"
                commercial="Non-Commercial Use"
                message="Enhance the brand, engage the audience & sell through your work"
            />,
            Footer: <TableFooterComponent
                description={
                    (
                        <Description weight='bold' color='shade-1' margin='sm'>
                            { '\n' }
                            { currentInterval === 'month' ? '$99 / month' : '$1K / year' }
                        </Description>
                    )
                }
                isCurrentPlan={ planTitle === 'Premium2.0' && planPeriod === currentInterval }
                currentPlanIsActive={ planIsActive }
                buttonText={ (planTitle === 'Premium2.0' && planPeriod === 'year')
                    || planTitle === 'Enterprise' || planTitle === 'Teams' ? 'Downgrade' : 'Upgrade' }
                onClick={ onClickPremium }
                key={ 3 }
            />,
            Cell: ({ cell: { value } }) => <Wrapper key={ value }>{ value }</Wrapper>
        },
        {
            accessor: 'teams',
            width:    180,
            Header:   <TableHeaderComponent
                frame="gradient-warm-3"
                title="TEAMS"
                commercial="Commercial Use"
                message="Empower teams of designers, engineers, marketers to create & manage projects"
            />,
            Footer: <TableFooterComponent
                description={ (
                    <>
                        <Description weight='bold' color='pink'>
                            Limited time offer
                        </Description>
                        <Description weight='bold' color='shade-1' margin='sm'>
                            { currentInterval === 'month' ? '$3K / month' : '$32,4K / year' }
                        </Description>
                    </>
                ) }
                currentPlanIsActive={ planIsActive }
                isCurrentPlan={ planTitle === 'Teams' && planPeriod === currentInterval }
                buttonText={ (planTitle === 'Teams' && planPeriod === 'year')
                    || planTitle === 'Enterprise' ? 'Downgrade' : 'Upgrade' }
                onClick={ onClickTeams }
                key={ 4 }
            />,
            Cell: ({ cell: { value } }) => <Wrapper key={ value }>{ value }</Wrapper>
        },
        {
            accessor: 'enterprise',
            width:    180,
            Header:   <TableHeaderComponent
                frame="gradient-warm-5"
                title="ENTERPRISE"
                commercial="Commercial Use"
                message="Use our fully loaded, NFT-enabled, customized services to create & manage your projects"
            />,
            Footer: <TableFooterComponent
                description={
                    (
                        <Description weight='bold' color='shade-1' margin='sm'>
                            { '\n' }
                            Loaded + Customized
                        </Description>
                    )
                }
                currentPlanIsActive={ planIsActive }
                isCurrentPlan={ planTitle === 'Enterprise' }
                buttonText="Contact Us"
                onClick={ contactUsClickHandler }
                key={ 5 }
            />,
            Cell: ({ cell: { value } }) => <Wrapper key={ value }>{ value }</Wrapper>
        }
    ], [ currentInterval, stripeState.plan_id ]);

    const data = useMemo(() => getTableCells(), []);

    return [ data, columns ];
};
