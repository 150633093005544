import { ExperienceModel, ProjectModel, SectionModel } from "@geenee/shared";
import { BaseEntityModel }                             from "@geenee/shared/src/magellan/model/base-entity.model";
import { injectable }                                  from "inversify";
import { AttachmentType }                              from "../../../type/attachment.type";
import { FactoryAbstract }                             from "../../util/factoryContainer";

type ParentType = ProjectModel | ExperienceModel | SectionModel | BaseEntityModel

@injectable()
export class AttachmentFactory extends FactoryAbstract {
    create(attachmentSchema: Partial<AttachmentType> = {}, $parent?: ParentType) {
        let attachmentModel;
        if (attachmentSchema?.filename?.includes("json")
          || attachmentSchema.file?.name?.includes("json")
          || attachmentSchema.file?.type?.includes("json")
          || attachmentSchema?.url?.includes("json")
        ) {
            attachmentModel = this.container.get("<CustomCodeAttachmentModel>");
        } else {
            attachmentModel = this.container.get("<AttachmentModel>");
        }

        Object.assign(attachmentModel, attachmentSchema);
        if ($parent) {
            attachmentModel.$parent = $parent;
        }
        return attachmentModel;
    }
}

export type AttachmentFactoryType = AttachmentFactory
