import styled from 'styled-components';

export const InspectorRoot = styled.div`
  width: 100vw;
  height: 100vh;
  #scene-explorer-host {
    z-index: 3;
    position: absolute !important;
    top: 16px;
    left: 16px;
    bottom: 24px;
  }
`;
