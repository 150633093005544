import React                                       from 'react';
import { useInject }                               from '@geenee/shared';
import { LoadingSpinnerCentered }                  from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { observer }                                from 'mobx-react';
import { useOpenSceneModal }                       from '@geenee/builder/src/module/edit-asset-3d-scene/modal/useOpenSceneModal';
import {  SceneDropTarget, TapForFullScreenLabel } from './styles';

export const SceneOpenTrigger = observer(() => {
    const { AppState } = useInject();
    const { activeSceneModel, activeMolecule } = AppState;
    const [ showModal ] = useOpenSceneModal();

    if (!activeMolecule?.assetsLoaded) {
        return <LoadingSpinnerCentered />;
    }

    return (
        <SceneDropTarget
            onMouseEnter={ () => activeSceneModel?.emitter.emit('geenee-start-animation-loop') }
            onMouseLeave={ () => activeSceneModel?.emitter.emit('geenee-stop-animation-loop') }
        >
            <TapForFullScreenLabel onClick={ () => showModal() }>
                GO FULL SCREEN TO
                <br />
                DESIGN YOUR SCENE
            </TapForFullScreenLabel>
        </SceneDropTarget>
    );
});
