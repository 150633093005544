import React from 'react';

// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '52',
    height = '52',
    onClick,
    id,
    fill = '#5CEBD6'
}: SvgIconProps) {
    return (
        <svg
            id={ id }
            onClick={ onClick }
            width={ width }
            height={ height }
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_green)">
                <circle cx="26" cy="25" r="25" fill={ fill } />
                <path
                // eslint-disable-next-line max-len
                    d="M15 26.1429L22.131 32.9343C22.1697 32.9711 22.2303 32.9711 22.269 32.9343L39 17"
                    stroke="white"
                    strokeWidth="1.7"
                    strokeLinecap="round"
                />
            </g>
            <defs>
                <filter id="filter0_green" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.516667 0 0 0 0 0.516667 0 0 0 0 0.516667 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}
