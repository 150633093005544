import React           from 'react';
import { LinkWrapper } from '@geenee/builder/src/components/Link/styles';

interface IProps {
    id?: string;
    onClick?: () => void;
    children?: JSX.Element | JSX.Element[] | string;
    fontSize?: string;
    style?: React.CSSProperties;
    paragraphStyle?: React.CSSProperties
}

export function Link({
    onClick, children, fontSize, style, id = '', paragraphStyle
}: IProps) {
    return (
        <LinkWrapper id={ id } onClick={ onClick } $fontSize={ fontSize || '12px' } style={ style || {} }>
            <p
                className="link-component"
                style={ paragraphStyle }
            >
                { children }
            </p>
        </LinkWrapper>
    );
}
