import React                                  from 'react';
import { Button, Description, Icon, Wrapper } from '@geenee/ui';

export function ErrorWindow({ onClose }: {onClose: () => void}) {
    return (
        <Wrapper align="center">
            <Icon size={ 48 } name="error" color="gradient-warm-4" margin="md" />
            <Description weight='bold' margin="xs" lineHeight="lg" size="lg">
                Ooops, something went wrong!
            </Description>
            <Description color="dark-5" margin="md">
                Please try to send your support request one more time
            </Description>
            <Button uppercase onClick={ onClose } margin="xs">Try Again</Button>
        </Wrapper>
    );
}
