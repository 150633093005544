import * as React from 'react';
import { cn }     from '../../util/bem';
import './form.component.scss';

export type FormPropsType = {
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void | Promise<void>;
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

const className = cn('form');

export const Form = ({ children, onSubmit, ...rest }: FormPropsType) => (
    <form onSubmit={ onSubmit } className={ className() } { ...rest }>
        { children }
    </form>
);
