import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '68',
    height = '68',
    onClick,
    id,
    fill = 'none'
}: SvgIconProps) {
    return (
        <svg
            id={ id }
            onClick={ onClick }
            width={ width }
            height={ height }
            viewBox="0 0 68 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_play_v)">
                <g filter="url(#filter1_i)">
                    <circle cx="33.75" cy="32.75" r="31.75" fill={ fill } id='hover-rollover' />
                </g>
                <circle cx="34" cy="33" r="32" stroke="white" strokeWidth="2" />
                <path d="M43.8735 32.6656L27.7959 43.2741L27.7959 22.0572L43.8735 32.6656Z" fill="white" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    // eslint-disable-next-line max-len
                    d="M46.651 31.8633L27.5961 19.6137C26.6903 19.0314 25.499 19.6817 25.499 20.7586V45.2578C25.499 46.3346 26.6903 46.985 27.5961 46.4027L46.651 34.1531C47.4844 33.6173 47.4844 32.399 46.651 31.8633ZM43.3981 33.0082L28.2212 42.7647V23.2516L43.3981 33.0082Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_play_v"
                    x="0"
                    y="0"
                    width="68"
                    height="68"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.516667 0 0 0 0 0.516667 0 0 0 0 0.516667 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <filter
                    id="filter1_i"
                    x="2"
                    y="1"
                    width="63.5"
                    height="65.5"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
                </filter>
            </defs>
        </svg>
    );
}
