import React from 'react';

export default ({
    width = '24',
    height = '24',
    onClick,
    id,
    fill = 'white'
}: SvgIconProps) => (
    <svg
        fill="none"
        id={ id }
        width={ width }
        height={ height }
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        onClick={ onClick }
    >
        <path d="M13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V14.5858L9.70711 13.2929C9.31658 12.9024 8.68342 12.9024 8.29289 13.2929C7.90237 13.6834 7.90237 14.3166 8.29289 14.7071L11.2929 17.7071C11.6834 18.0976 12.3166 18.0976 12.7071 17.7071L15.7071 14.7071C16.0976 14.3166 16.0976 13.6834 15.7071 13.2929C15.3166 12.9024 14.6834 12.9024 14.2929 13.2929L13 14.5858V7Z" fill={ fill } />
        <path fillRule="evenodd" clipRule="evenodd" d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z" fill={ fill } />
    </svg>
);
