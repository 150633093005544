import React                                from 'react';
import { FormItem, InputTableItem, Switch } from '@geenee/ui';

interface Props {
  allow_landscape_mode: boolean;
  onUpdate: (value: boolean) => void;
  disabled?: boolean;
}

export function LandscapeSwitcher({ allow_landscape_mode, onUpdate, disabled }: Props) {
    return (
        <InputTableItem label="Landscape Option">
            <FormItem>
                <Switch
                    color="white"
                    descriptionSize="xs"
                    size="lg"
                    checked={ allow_landscape_mode }
                    onChange={ onUpdate }
                    disabled={ disabled }
                />
            </FormItem>
        </InputTableItem>
    );
}
