import React, { useCallback, useState } from 'react';
import { CallbackFunctionVariadic }     from "@geenee/geeclient-kit/src/lib/type/type";
import { TOAST_SUCCESS }                from '@geenee/shared/src/util/constants';
import { handleError }                  from '@geenee/shared/src/util/errorHandler';
import {
    Button, Description, FormItem, Icon, Input, Wrapper
} from '@geenee/ui';
import { observer }                                                                          from "mobx-react";
import { useBuilderInject }                                                                  from '~/core/hook/use-builder-inject';
import { ERROR_PASSWORD_LENGTH, ERROR_PASSWORD_MATCH, PASSWORD_UPDATE_SUCCESS, TOAST_ERROR } from '~/lib/constants';

const PASSWORD_LENGTH = 8;
export const PasswordModalContent = observer((props: {onChange: CallbackFunctionVariadic, onClose: CallbackFunctionVariadic}) => {
    const [ passwordState, setPasswordState ] = useState<{password: string | null, password_confirmation: string | null}>({
        password:              null,
        password_confirmation: null
    });
    const [ showPassword, setShowPassword ] = useState<Set<'password' | 'password_confirmation'>>(new Set());
    const { BuilderState } = useBuilderInject();

    const onSave = async () => {
        try {
            await BuilderState.currentUser?.profile?.update(passwordState);
            BuilderState.update('toast', {
                severity: TOAST_SUCCESS,
                detail:   PASSWORD_UPDATE_SUCCESS,
                summary:  ''
            });
        } catch (e) {
            handleError(e, (key, value) => {
                BuilderState.update('toast', {
                    severity: TOAST_ERROR,
                    detail:   value.message,
                    summary:  ''
                });
            });
        }
    };

    const getIcon = useCallback((field: keyof typeof passwordState) => (
        <Icon
            size={ 24 }
            name={ showPassword.has(field) ? 'eyeOn' : 'eyeOff' }
            onClick={ () => {
                if (showPassword.has(field)) {
                    showPassword.delete(field);
                } else {
                    showPassword.add(field);
                }
                setShowPassword(new Set(showPassword));
            } }
        />
    ), [ showPassword ]);

    const getError = useCallback((field: keyof typeof passwordState) => {
        if (passwordState[ field ]) {
            if (passwordState[ field ]?.length < PASSWORD_LENGTH) {
                return ERROR_PASSWORD_LENGTH;
            } if (passwordState.password !== passwordState.password_confirmation) {
                return ERROR_PASSWORD_MATCH;
            }
        }
        return false;
    }, [ passwordState ]);
    return (
        <Wrapper align="center" style={ { width: 400 } }>
            <Icon size={ 48 } name="lock" color="gradient-warm-5" margin="md" />
            <Description weight='bold' margin="xs" size="lg" lineHeight="lg">
                Change password
            </Description>
            <Description align="center" color="shade-4" margin="sm">
                Your new password should consist at least of 8 characters.
            </Description>
            <Wrapper padding="xs">
                <FormItem
                    autoFocusDetect
                    error={ getError('password') }
                >
                    <Input
                        margin="xs"
                        size="md"
                        fullWidth
                        type={ showPassword.has('password') ? 'text' : "password" }
                        name="password"
                        value={ passwordState.password || '' }
                        placeholder="New password"
                        onChange={ (e) => setPasswordState({ ...passwordState, password: e.target.value }) }
                        after={ getIcon('password') }
                    />
                </FormItem>

                <FormItem
                    margin="xs"
                    autoFocusDetect
                    error={ getError('password_confirmation') }
                >
                    <Input
                        size="md"
                        fullWidth
                        type={ showPassword.has('password_confirmation') ? 'text' : "password" }
                        name="password_confirmation"
                        value={ passwordState.password_confirmation || '' }
                        placeholder="Confirm password"
                        onChange={ (e) => setPasswordState({ ...passwordState, password_confirmation: e.target.value }) }
                        after={ getIcon('password_confirmation') }

                    />
                </FormItem>
            </Wrapper>
            <Wrapper maxWidth="210px" style={ { paddingBottom: 0, paddingTop: 0 } } padding="sm" row margin="xs" align="center">
                <Button
                    fullWidth
                    uppercase
                    disabled={ passwordState.password === null || !!getError('password') }
                    onClick={ async () => {
                        await onSave();
                        props.onClose && props.onClose();
                    } }
                    margin="xs"
                >
                    Change password
                </Button>
            </Wrapper>
        </Wrapper>
    );
});
