import React, { useEffect, useMemo } from "react";
import { useHistory }                from "@geenee/common";
import {  useInject }                from '@geenee/shared';
import useComposerLocationParser     from '@geenee/shared/src/util/useComposerLocationParser';
import {
    cn, Description, Icon, Span, Swiper, Tooltip, Wrapper
} from '@geenee/ui';
import { observer }               from "mobx-react";
import { EXPERIENCE_MAX_SIZE_MB } from "~/lib/constants";
import { useSceneAssetsActions }  from "~/magellan/hooks/useSceneAssetsActions";
import './styles.scss';

const className = cn('scene-bottom-bar');

export const SceneBottomBar = observer(() => {
    const { AppState } = useInject();
    const [ swiper, setSwiper ] = React.useState(null);

    const { projectId, experienceId, viewId, sectionId } = useComposerLocationParser();
    const history = useHistory();
    const { activeMolecule } = AppState;
    const scenes = AppState.activeSection?.sortedChildren || [];
    const { calculateExperienceSize, atomModels } = useSceneAssetsActions();
    const experienceSize: number = useMemo(() => +(calculateExperienceSize() / 1048576).toFixed(2), [ atomModels ]);

    useEffect(() => {
        if (swiper) {
            const index = scenes.findIndex((scene) => scene.id === activeMolecule?.id);
            swiper.slideTo(index);
        }
    }, [ swiper ]);

    return (
        <Wrapper
            className={ className() }
            radius="lg"
            maxWidth="216px"
            frame="solid-default"
        >
            <Swiper
                size="xxs"
                fullHeight
                fullWidth
                align="center"
                arrowsAlign='center'
                overflowY="hidden"
                direction="horizontal"
                showArrowsAlways
                navigation
                pagination={ { clickable: true } }
                centeredSlides
                spaceBetween={ 80 }
                onSwiper={ setSwiper }
                onSlideChange={ (item) => {
                    history.push(`/${ projectId }/${ experienceId }/${ viewId }/${ sectionId }/${ scenes[ item.activeIndex ]?.id }`);
                } }
            >
                { scenes.map((item, index) => (
                    <Wrapper
                        className={ className('slide-content', { center: scenes.length === 1 }) }
                        key={ index }
                        align="center"
                        maxWidth="152px"
                        row
                    >
                        <Description style={ { width: 92 } } align="center" noWrap margin="xs" shorten size="xs" color="shade-2">
                            { AppState.activeMolecule?.options?.molecule_menu_title || 'Scene' }
                        </Description>
                        <Tooltip
                            position="top"
                            messageAlign="center"
                            menuWidth="132px"
                            minWidth="132px"
                            message={ (
                                <Description size="xs" color="shade-3">
                                    Scene size:
                                    <Span style={ { marginLeft: 2 } } color="shade-1">
                                        { Math.round((100 * experienceSize) / EXPERIENCE_MAX_SIZE_MB) }
                                        % full
                                    </Span>
                                </Description>
                            ) }
                            delay={ 500 }
                            shadow
                        >
                            <Wrapper
                                row
                                maxWidth="fit-content"
                                valign="center"
                            >
                                <Icon style={ { marginRight: 2 } } name="info" size={ 12 } />
                                <Description
                                    noWrap
                                    size="xs"
                                    color="shade-2"
                                >
                                    { `${ experienceSize }/${ EXPERIENCE_MAX_SIZE_MB } Mb` }
                                </Description>
                            </Wrapper>
                        </Tooltip>
                    </Wrapper>
                )) }
            </Swiper>
        </Wrapper>
    );
});
