import React from 'react';

// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '25',
    height = '24',
    onClick,
    id,
    fill = 'white'
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 25 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M10.0211 2.05835C6.3196 1.63572 2.74033 3.53737 1.01872 6.84128C-0.702893 10.1452 -0.210909 14.1683 2.25613 16.96C2.62185 17.3738 3.25381 17.4128 3.66765 17.0471C4.0815 16.6814 4.12051 16.0494 3.75479 15.6356C1.83599 13.4643 1.45333 10.3352 2.79236 7.76549C4.13139 5.19579 6.91527 3.71672 9.79422 4.04544C12.6732 4.37415 15.052 6.44269 15.7773 9.2481C15.8914 9.68947 16.2896 9.99779 16.7455 9.99779H18.0055C19.8329 9.999 21.4276 11.2395 21.8786 13.0111C22.3295 14.7828 21.5219 16.6347 19.9168 17.5098C19.4319 17.7741 19.2531 18.3815 19.5175 18.8665C19.7818 19.3514 20.3892 19.5301 20.8741 19.2658C23.2818 17.9532 24.4932 15.1753 23.8168 12.5178C23.1404 9.86034 20.7483 7.9996 18.0061 7.99779H17.4847C16.3458 4.77598 13.4612 2.45113 10.0211 2.05835ZM16.7126 16.7049C16.322 17.0954 15.6889 17.0954 15.2984 16.7049L13.0055 14.412V20.9978C13.0055 21.5501 12.5577 21.9978 12.0055 21.9978C11.4532 21.9978 11.0055 21.5501 11.0055 20.9978V14.412L8.71257 16.7049C8.32204 17.0954 7.68888 17.0954 7.29835 16.7049C6.90783 16.3144 6.90783 15.6812 7.29835 15.2907L11.2976 11.2914L11.2984 11.2907C11.3003 11.2887 11.3023 11.2868 11.3043 11.2848L11.3049 11.2842C11.3953 11.1954 11.5026 11.1237 11.6214 11.0742C11.5063 11.122 11.3984 11.1922 11.3043 11.2848M12.7126 11.2907L12.706 11.2842C12.7082 11.2863 12.7104 11.2885 12.7126 11.2907Z"
                fill={ fill }
            />
            <mask id="mask0-cloud" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="2" width="25" height="20">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    // eslint-disable-next-line max-len
                    d="M10.0211 2.05835C6.3196 1.63572 2.74033 3.53737 1.01872 6.84128C-0.702893 10.1452 -0.210909 14.1683 2.25613 16.96C2.62185 17.3738 3.25381 17.4128 3.66765 17.0471C4.0815 16.6814 4.12051 16.0494 3.75479 15.6356C1.83599 13.4643 1.45333 10.3352 2.79236 7.76549C4.13139 5.19579 6.91527 3.71672 9.79422 4.04544C12.6732 4.37415 15.052 6.44269 15.7773 9.2481C15.8914 9.68947 16.2896 9.99779 16.7455 9.99779H18.0055C19.8329 9.999 21.4276 11.2395 21.8786 13.0111C22.3295 14.7828 21.5219 16.6347 19.9168 17.5098C19.4319 17.7741 19.2531 18.3815 19.5175 18.8665C19.7818 19.3514 20.3892 19.5301 20.8741 19.2658C23.2818 17.9532 24.4932 15.1753 23.8168 12.5178C23.1404 9.86034 20.7483 7.9996 18.0061 7.99779H17.4847C16.3458 4.77598 13.4612 2.45113 10.0211 2.05835ZM16.7126 16.7049C16.322 17.0954 15.6889 17.0954 15.2984 16.7049L13.0055 14.412V20.9978C13.0055 21.5501 12.5577 21.9978 12.0055 21.9978C11.4532 21.9978 11.0055 21.5501 11.0055 20.9978V14.412L8.71257 16.7049C8.32204 17.0954 7.68888 17.0954 7.29835 16.7049C6.90783 16.3144 6.90783 15.6812 7.29835 15.2907L11.2976 11.2914L11.2984 11.2907C11.3003 11.2887 11.3023 11.2868 11.3043 11.2848L11.3049 11.2842C11.3953 11.1954 11.5026 11.1237 11.6214 11.0742C11.5063 11.122 11.3984 11.1922 11.3043 11.2848M12.7126 11.2907L12.706 11.2842C12.7082 11.2863 12.7104 11.2885 12.7126 11.2907Z"
                    fill={ fill }
                />
            </mask>
            <g mask="url(#mask0-coud)">
                <rect width="24" height="24" fill="transparent" />
            </g>
        </svg>
    );
}
