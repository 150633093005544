import React                      from 'react';
import { useInject }              from '@geenee/shared';
import { LoadingSpinnerCentered } from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { cn }                     from "@geenee/ui";
import { observer }               from "mobx-react";
import './loading-status.scss';

const className = cn('loading-status');

interface Props {
  id: string
}

export const LoadingStatus = observer(({ id }: Props) => {
    const { AppState } = useInject();
    const { activeSection } = AppState;
    const { activeMolecule } = activeSection || { activeMolecule: { loading: false } };
    const { loading } = activeMolecule;
    if (!loading) {
        return <></>;
    }
    return <div id={ id } className={ className() }><LoadingSpinnerCentered size={ 60 } /></div>;
});
