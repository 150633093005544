import * as React                                 from "react";
import type { PointLight }                        from "@babylonjs/core/Lights/pointLight";
import type { Observable }                        from "@babylonjs/core/Misc/observable";
import { Color3LineComponent }                    from "@geenee/geespector-ui-components/src/lines/color3LineComponent";
import { Vector3LineComponent }                   from "@geenee/geespector-ui-components/src/lines/vector3LineComponent";
import type { LockObject }                        from "@geenee/geespector-ui-components/src/tabs/propertyGrids/lockObject";
import { Wrapper }                                from '@geenee/ui';
import type { GlobalState }                       from "../../../../globalState";
import type { PropertyChangedEvent }              from "../../../../propertyChangedEvent";
import { ActionTabSectionComponent }              from '../../../actionTabSectionComponent';
import { CommonLightPropertyGridComponent }       from "./commonLightPropertyGridComponent";
import { CommonShadowLightPropertyGridComponent } from "./commonShadowLightPropertyGridComponent";

interface IPointLightPropertyGridComponentProps {
    globalState: GlobalState;
    light: PointLight;
    lockObject: LockObject;
    onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
}

export class PointLightPropertyGridComponent extends React.Component<IPointLightPropertyGridComponentProps> {
    constructor(props: IPointLightPropertyGridComponentProps) {
        super(props);
    }

    render() {
        const { light } = this.props;

        return (
            <Wrapper className="pane">
                <CommonLightPropertyGridComponent
                    globalState={ this.props.globalState }
                    lockObject={ this.props.lockObject }
                    light={ light }
                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                />
                <ActionTabSectionComponent title="SETUP" hasDivider={ false }>
                    <Color3LineComponent
                        label="Diffuse"
                        target={ light }
                        propertyName="diffuse"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                    <Color3LineComponent
                        label="Specular"
                        target={ light }
                        propertyName="specular"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                    <Vector3LineComponent
                        label="Position"
                        target={ light }
                        propertyName="position"
                        onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                    />
                </ActionTabSectionComponent>
                <CommonShadowLightPropertyGridComponent
                    globalState={ this.props.globalState }
                    lockObject={ this.props.lockObject }
                    light={ light }
                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                />
            </Wrapper>
        );
    }
}
