import React                    from 'react';
import { useInject }            from '@geenee/shared';
import { InputTable }           from '@geenee/ui';
import { observer }             from 'mobx-react';
import { ContentVisibilityRow } from '@geenee/builder/src/components/WizardForm/FormType/ViewDesignForm/components/ContentVisibilityRow';
import { ViewModel }            from '@geenee/builder/src/magellan/model/view.model';
import { MagellanState }        from '@geenee/builder/src/magellan/state/app.state';

export const ViewDesignForm = observer(({ disabled }: {disabled: boolean}) => {
    const { AppState } = useInject();
    const { activeView } = AppState as unknown as MagellanState;

    if (!activeView) {
        return <></>;
    }

    return <></>;

    return (
        <InputTable>
            <ContentVisibilityRow
                menu_visibility={ activeView.options.section_is_visible_in_menu }
                onUpdate={ (value) => (activeView as ViewModel).updateOptions({ section_is_visible_in_menu: value }) }
                disabled={ disabled }
            />
        </InputTable>
    );
});
