import styled from 'styled-components';

export const TracksContainer = styled.div`
    width: 100%;
    height: 532px;
    overflow: auto;

    background: rgba(202, 202, 202, 0.19);
    border: 1px solid #e6e6e6;
    border-radius: 10px 10px 0px 0px;
`;
