import * as React                           from "react";
import { useMemo }                          from "react";
import type { IExplorerExtensibilityGroup } from "@babylonjs/core/Debug/debugLayer";
import type { Material }                    from "@babylonjs/core/Materials/material";
import type { NodeMaterial }                from "@babylonjs/core/Materials/Node/nodeMaterial";
import { AddLightCommand }                  from "@geenee/geespector/src/commands/AddLightCommand";
import { DeleteCommand }                    from "@geenee/geespector/src/commands/DeleteCommand";
import { DuplicateCommand }                 from "@geenee/geespector/src/commands/DuplicateMeshCommand";
import { HideShowCommand }                  from "@geenee/geespector/src/commands/hideShowCommand";
import { useInject }                        from "@geenee/shared/src/hook/use-inject.hook";
import { Button, Icon }                     from "@geenee/ui";
import { ExtensionsComponent }              from "../extensionsComponent";

interface IMaterialTreeItemComponentProps {
    material: Material | NodeMaterial;
    extensibilityGroups?: IExplorerExtensibilityGroup[];
    onClick: () => void;
}

export const MaterialTreeItemComponent = (props: IMaterialTreeItemComponentProps) => {
    const { AppState } = useInject();
    const { activeSection : sectionModel } = AppState;
    const { sceneManager } = sectionModel || { sceneManager: undefined };
    const renderer = sceneManager?.sceneRenderer;
    const commands = useMemo(() => ({ delete: new DeleteCommand(renderer, props.material) }), [ props.material ]);

    return (
        <div className="materialTools">
            { /* <TreeItemLabelComponent label={props.material.name} onClick={() => props.onClick()} icon={faBrush} color="orange" /> */ }
            <ExtensionsComponent target={ props.material } extensibilityGroups={ props.extensibilityGroups } />
            <Button
                size='xs'
                radius="sm"
                viewType='action'
                iconColor="error"
                icon="remove"
                iconSize={ 12 }
                onClick={ (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    sceneManager?.commander.executeCommand(commands.delete);
                } }
            />
        </div>
    );
};
