import React, { useEffect }                 from 'react';
import  EditAssetSceneState                 from '@geenee/geeclient-kit/src/lib/component/scene/viewer3d/state/edit-asset-scene.state';
import { AnimatedWrapper, Modal, useModal } from '@geenee/ui';
import { useBuilderInject }                 from '~/core/hook/use-builder-inject';
import ArScene                              from './ArScene';

export const useOpenSceneModal = () => {
    const { BuilderState } = useBuilderInject();
    const [ layoutShowModal, layoutHideModal ] = useModal((transformGroupProps: JSX.IntrinsicAttributes) => (

        <Modal
            className='global-modal'
            onHideModal={ () => {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                hideModal();
            } }
            // eslint-disable-next-line react/jsx-props-no-multi-spaces
            { ...transformGroupProps }
        >
            <AnimatedWrapper manual={ false }>
                <ArScene onHideModal={ async () => {
                    // TODO: generate preview image only onModalHide, not onStatsChanged
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    await hideModal();
                } }
                />
            </AnimatedWrapper>
        </Modal>
    ), []);

    const showModal = () => {
        // const asset = assetFile.url ? assetFile : allArAssets[ 0 ];
        // @ts-ignore
        // EditAssetSceneState?.setSceneAssets(asset, trackerImg?.url);

        BuilderState.onArModalEnter();
        layoutShowModal();
    };

    const hideModal = async () => {
        EditAssetSceneState.resetScene();
        await BuilderState.onArModalLeave(layoutHideModal);
    };

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                hideModal();
            }
        };
        window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close);
    }, []);

    return [ showModal, hideModal ];
};
