import React from 'react';

// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '40',
    height = '40',
    fill = 'black'
}: SvgIconProps) {
    return (
        <svg width={ width } height={ height } viewBox="0 0 40 40" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M30.6047 9.5115C36.4604 15.369 36.4604 24.864 30.6047 30.7215C29.9539 31.3725 29.9541 32.4277 30.605 33.0785C31.256 33.7293 32.3113 33.7291 32.962 33.0782C40.119 25.919 40.119 14.314 32.962 7.15484C32.3113 6.50387 31.256 6.50371 30.605 7.15448C29.9541 7.80526 29.9539 8.86053 30.6047 9.5115ZM17.2922 7.14855L9.41538 13.45H3.33335C2.41288 13.45 1.66669 14.1962 1.66669 15.1167V25.1167C1.66669 26.0371 2.41288 26.7833 3.33335 26.7833H9.41538L17.2922 33.0848C18.3835 33.9578 20 33.1808 20 31.7833V8.45C20 7.05249 18.3835 6.27553 17.2922 7.14855ZM11.0412 16.4181L16.6667 11.9177V28.3156L11.0412 23.8152C10.7457 23.5788 10.3785 23.45 10 23.45H5.00002V16.7833H10C10.3785 16.7833 10.7457 16.6545 11.0412 16.4181ZM24.7213 24.8214C27.3239 22.218 27.3239 17.998 24.7213 15.3947C24.0705 14.7437 24.0707 13.6884 24.7217 13.0377C25.3726 12.3869 26.4279 12.3871 27.0787 13.038C30.9825 16.943 30.9825 23.273 27.0787 27.178C26.4279 27.829 25.3726 27.8292 24.7217 27.1784C24.0707 26.5276 24.0705 25.4723 24.7213 24.8214Z"
                fill="black"
            />
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="6" width="38" height="28">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    // eslint-disable-next-line max-len
                    d="M30.6047 9.5115C36.4604 15.369 36.4604 24.864 30.6047 30.7215C29.9539 31.3725 29.9541 32.4277 30.605 33.0785C31.256 33.7293 32.3113 33.7291 32.962 33.0782C40.119 25.919 40.119 14.314 32.962 7.15484C32.3113 6.50387 31.256 6.50371 30.605 7.15448C29.9541 7.80526 29.9539 8.86053 30.6047 9.5115ZM17.2922 7.14855L9.41538 13.45H3.33335C2.41288 13.45 1.66669 14.1962 1.66669 15.1167V25.1167C1.66669 26.0371 2.41288 26.7833 3.33335 26.7833H9.41538L17.2922 33.0848C18.3835 33.9578 20 33.1808 20 31.7833V8.45C20 7.05249 18.3835 6.27553 17.2922 7.14855ZM11.0412 16.4181L16.6667 11.9177V28.3156L11.0412 23.8152C10.7457 23.5788 10.3785 23.45 10 23.45H5.00002V16.7833H10C10.3785 16.7833 10.7457 16.6545 11.0412 16.4181ZM24.7213 24.8214C27.3239 22.218 27.3239 17.998 24.7213 15.3947C24.0705 14.7437 24.0707 13.6884 24.7217 13.0377C25.3726 12.3869 26.4279 12.3871 27.0787 13.038C30.9825 16.943 30.9825 23.273 27.0787 27.178C26.4279 27.829 25.3726 27.8292 24.7217 27.1784C24.0707 26.5276 24.0705 25.4723 24.7213 24.8214Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0)">
                <rect width="40" height="40" fill={ fill } />
            </g>
        </svg>
    );
}
