import React, { useState }          from 'react';
import { Wrapper }                  from '@geenee/ui';
import { useStripe }                from '@stripe/react-stripe-js';
import { findKey }                  from 'lodash-es';
import { CreditCardForm }           from '@geenee/builder/src/components/modals/common/CreditCardForm';
import { usePaymentMethodHandlers } from '@geenee/builder/src/components/modals/common/handlers/usePaymentMethodHandlers';
import { useSubscriptionHandlers }  from '@geenee/builder/src/components/modals/common/handlers/useSubscriprionHandlers';
import { DIComponentType }          from '@geenee/builder/src/core/context/di.context';
import { paymentModalState }        from '@geenee/builder/src/core/state/payment-modal.state';
import { stripeState }              from '@geenee/builder/src/core/state/stripe.state';
import { dateEn }                   from '@geenee/builder/src/pages/YourPlanPage/rows/NextPayment';
import { CardList }                 from '../common/CardList';
import { Button, Link }             from '../common/styles';
import {
    Disclaimer,
    PaymentWrapper,
    PricingCost,
    PricingDescription,
    PricingDetails,
    Title
}                          from './styles';

type PaymentProps = {
    priceId: string;
    type: string;
    removeAfterDowngrade: any;
    // eslint-disable-next-line no-empty-pattern
    setModalProps: ({ }) => void;
    showError: (message: string) => void;
};

const formatInterval = (interval: string) => `${ interval.charAt(0).toUpperCase() + interval.slice(1) }ly`;

export function Payment(props: PaymentProps & DIComponentType) {
    const stripe = useStripe();
    const defaultPlan = findKey(stripeState.prices, { name: 'Premium', period: 'month' });
    const planToBuy = stripeState.prices[ props.priceId ] || stripeState.prices[ defaultPlan ];
    const [ newCardName, setNewCardName ] = useState<string>('');
    const [ cardId, setCardId ] = useState(Object.keys(stripeState.paymentMethods)[ 0 ]);
    const [ addNewCardMode, setAddNewCardMode ] = useState(false);
    const emptyPaymentMethod = !Object.keys(stripeState.paymentMethods).length;

    if (!planToBuy) {
        return <></>;
    }

    const now = new Date();
    const endDate = dateEn.format(planToBuy.period === 'month' ? now.setMonth(now.getMonth() + 1) : now.setFullYear(now.getFullYear() + 1));

    const {
        addNewCardAndSetDefault,
        waiting: paymentMethodWaiting
    } = usePaymentMethodHandlers();
    const {
        makePurchase,
        waiting: subscriptionWaiting
    } = useSubscriptionHandlers();
    const waiting = paymentMethodWaiting || subscriptionWaiting;

    const handleSubmit = async () => {
        try {
            if (!newCardName) {
                // console.log('buy', cardId, props.priceId);
                // return;
                await makePurchase(cardId, props.priceId, paymentModalState, props.showError, props.removeAfterDowngrade);
                return;
            }

            const paymentMethod = await addNewCardAndSetDefault(newCardName, props.showError);
            if (paymentMethod) {
                await makePurchase(paymentMethod.id, props.priceId, paymentModalState, props.showError, props.removeAfterDowngrade);
            }
        } catch (err: any) {
            console.log({ err });
            // @ts-ignore
            props.showError(err.message);
        }
    };

    return (
        // <Provider paymentModalState={ { paymentModalState } }>
        <PaymentWrapper>
            <Wrapper fullWidth align='center'>
                <Title>payment details</Title>
                { /* eslint-disable-next-line @typescript-eslint/no-use-before-define */ }
                <PlanToBuy planToBuy={ planToBuy } endDate={ endDate } />
                <Wrapper margin='xxl'>
                    { emptyPaymentMethod || addNewCardMode ? (
                        <CreditCardForm
                            cardName={ newCardName }
                            onCardNameChange={ (name) => setNewCardName(name) }
                        />
                    ) : (
                        <CardList
                            // @ts-ignore
                            setModalProps={ props.setModalProps }
                            currentId={ cardId }
                            onChange={ (id) => setCardId(id) }
                            onAddNewCardOverrideHandler={ () => {
                                setAddNewCardMode(true);
                            } }
                        />
                    ) }
                </Wrapper>
                <Wrapper fullWidth align="center">
                    <Button
                        $width="172px"
                        $height="40px"
                        onClick={ handleSubmit }
                        disabled={ !stripe || waiting }
                        $waiting={ waiting }
                        label={ `CONFIRM ${ props.type }` }
                    />
                </Wrapper>
                { /* eslint-disable-next-line @typescript-eslint/no-use-before-define */ }
                <DisclaimerComponent />
            </Wrapper>
        </PaymentWrapper>
        // </Provider>
    );
}

function PlanToBuy({
    planToBuy,
    endDate
}: {
    // @ts-ignore
    planToBuy: Price;
    endDate: string;
}) {
    return (
        <PricingDetails>
            <PricingDescription>
                <span>
                    { planToBuy.name }
                    { ' ' }
                    Plan
                    { ' ' }
                    { formatInterval(planToBuy.period || 'month') }
                    { ' ' }
                    Subscription
                </span>
                <span>
                    Billing Cycle begins on
                    { ' ' }
                    { endDate }
                </span>
            </PricingDescription>

            <PricingCost>
                <span>
                    $
                    { planToBuy.price }
                </span>
                <span>
                    /
                    { planToBuy.period || 'month' }
                </span>
            </PricingCost>
        </PricingDetails>
    );
}

function DisclaimerComponent() {
    return (
        <Disclaimer>
            <span>Change or cancel your subscription at anytime.</span>
            <br />
            <span>
                By clicking the above, you agree to our
                { ' ' }
                <Link href='https://geenee.ar/terms-and-conditions/' target='_blank'>Terms of Service</Link>
                { ' ' }
                and confirm that you have read and understood our
                { ' ' }
                <Link href='https://geenee.ar/privacy-policy/' target='_blank'>Privacy Policy</Link>
                { ' ' }
                . Cancel within 14 days for a full refund.
            </span>
        </Disclaimer>
    );
}
