import React, { memo }                        from 'react';
import { Button, Description, Icon, Wrapper } from '@geenee/ui';
import { MAX_FILES_COUNT }                    from '../DropAttachments';
import { SupportFile }                        from '../useSupportRequestForm';

export const FilesUploadBar = memo(({ filesList, onRemoveFile }: { filesList: SupportFile[], onRemoveFile: (id?: number)=> void }): JSX.Element => {
    if (!filesList.length) {
        return <></>;
    }

    return (
        <Wrapper>
            <Wrapper row valign="center" align="space-between">
                <Description color="dark-5">{ `${ filesList.length } / ${ MAX_FILES_COUNT } uploaded` }</Description>
                <Button
                    style={ { padding: 0 } }
                    viewType="transparent"
                    color="error"
                    align="right"
                    onClick={ (e) => {
                        e.stopPropagation();
                        onRemoveFile();
                    } }
                >
                    Remove all
                </Button>
            </Wrapper>
            { filesList.map((file, index) => (
                <Wrapper
                    margin={ index !== (filesList.length - 1) ? 'xs' : 'off' }
                    style={ { columnGap: '10px' } }
                    key={ `${ file.fileName }__${ index }` }
                    row
                    valign="center"
                >
                    <Wrapper row valign="center">
                        <Wrapper frame="solid-grey" radius="sm" padding="xxs" margin="xxs">
                            <Description
                                lineHeight="lg"
                                size="xs"
                            >
                                { file.fileName }
                            </Description>
                        </Wrapper>
                        <Button
                            viewType="transparent"
                            size="xs"
                            color="error"
                            icon="remove"
                            onClick={ (e) => {
                                e.stopPropagation();
                                onRemoveFile(index);
                            } }
                            radius="sm"
                        />
                    </Wrapper>
                </Wrapper>
            )) }
        </Wrapper>
    );
});
