const getEmitter = (scope: any) => {
  /**
   * Holds all currently registered callbacks.
   * `all` holds callback for the special `all` event.
   * @type {Object}
   */
  const callbacks = Object.create({
    all: [],
  });

  /**
   * Binds the specified callback to the given event type.
   */
  const on = (type: string, callback: Function) => {
    const cbs = (callbacks[type] || []);
    const index = cbs.indexOf(callback);
    if (index === -1) {
      cbs.push(callback);
    }
    callbacks[type] = cbs;
  };

  /**
   * Unbinds the specified callback from the given event type.
   */
  const off = (type: string, callback: Function) => {
    const cbs = callbacks[type] || [];
    if (typeof callback === 'function') {
      const index = cbs.indexOf(callback);
      if (index > -1) {
        cbs.splice(index, 1);
      }
    } else {
      cbs.length = 0;
    }
    if (type !== 'all' && cbs.length === 0) {
      delete callbacks[type];
    }
  };

  /**
   * Emits an event.
   */
  const emit = (type: string, ...args: any) => {
    (callbacks[type] || []).map((callback: Function) => callback.apply(scope, args));
    if (callbacks.all.length > 0) {
      const allArgs = [type, ...args];
      callbacks.all.map((callback: Function) => callback.apply(scope, allArgs));
    }
  };

  /**
   * Retuns the amount of bound listeners. Used mostly for testing purposes.
   */
  const getLength = (type: string): number => (callbacks[type] || []).length;

  return {
    on,
    off,
    emit,
    getLength,
  };
};

export default getEmitter;
