import React, { useEffect, useRef, useState } from 'react';
import { useHistory }                         from "@geenee/common";
import { useInject }                          from '@geenee/shared';
import { observer }                           from 'mobx-react';
import { AdderBtn }                           from '@geenee/builder/src/components/Tree/Adder/components/AdderBtn';
import TourState                              from '@geenee/builder/src/core/state/tour.state';
import useOnClickOutside                      from '@geenee/builder/src/lib/useOnClickOutside';
import { MagellanState }                      from '@geenee/builder/src/magellan/state/app.state';
import { AddNewWrapper }                      from '../styles';

interface Props {
    onAdd: () => void;
}

export const Adder = observer(({ onAdd }: Props) => {
    const { AppState } = useInject();
    const history = useHistory();
    const menuRef = useRef();
    const [ dropdownActive, setDropdownActive ] = useState(false);
    const [ isSaving, setIsSaving ] = useState(false);
    const { isStarted, step } = TourState;

    useEffect(() => {
        if (isStarted) {
            if (step === 1) {
                setDropdownActive(true);
            } else if (dropdownActive) {
                setDropdownActive(false);
            }
        }
    }, [ isStarted, step ]);

    const runHierarchy = async () => {
        try {
            setIsSaving(true);
            const program = await (AppState as MagellanState).createProject();
            const { id: experienceId } = await program.createExperience();
            setIsSaving(false);
            history.push(`/${ program.id }/${ experienceId }`);
            setDropdownActive(false);
        } catch (e) {
            console.error('runHierarchy error: ', e);
        }
    };

    // @ts-ignore
    useOnClickOutside(menuRef, (e) => {
        // @ts-ignore
        if (isStarted || e.target.classList.contains('one-ui-select__option')) {
            return;
        }
        setDropdownActive(false);
    });

    const clickHandler = async () => {
        await runHierarchy();
        onAdd();
    };

    return (
        <AddNewWrapper active={ dropdownActive }>
            <AdderBtn
                disabled={ isSaving }
                onBtnClick={ clickHandler }
            />
        </AddNewWrapper>
    );
});
