import * as React                                 from "react";
import { DirectionalLightFrustumViewer }          from "@babylonjs/core/Debug/directionalLightFrustumViewer";
import type { DirectionalLight }                  from "@babylonjs/core/Lights/directionalLight";
import { CascadedShadowGenerator }                from "@babylonjs/core/Lights/Shadows/cascadedShadowGenerator";
import type { ShadowGenerator }                   from "@babylonjs/core/Lights/Shadows/shadowGenerator";
import type { Observable }                        from "@babylonjs/core/Misc/observable";
import { CheckBoxLineComponent }                  from "@geenee/geespector-ui-components/src/lines/checkBoxLineComponent";
import { Color3LineComponent }                    from "@geenee/geespector-ui-components/src/lines/color3LineComponent";
import { LineContainerComponent }                 from "@geenee/geespector-ui-components/src/lines/lineContainerComponent";
import { Vector3LineComponent }                   from "@geenee/geespector-ui-components/src/lines/vector3LineComponent";
import type { LockObject }                        from "@geenee/geespector-ui-components/src/tabs/propertyGrids/lockObject";
import { Wrapper }                                from '@geenee/ui';
import type { GlobalState }                       from "../../../../globalState";
import type { PropertyChangedEvent }              from "../../../../propertyChangedEvent";
import { ActionTabSectionComponent }              from '../../../actionTabSectionComponent';
import { CommonLightPropertyGridComponent }       from "./commonLightPropertyGridComponent";
import { CommonShadowLightPropertyGridComponent } from "./commonShadowLightPropertyGridComponent";

interface IDirectionalLightPropertyGridComponentProps {
    globalState: GlobalState;
    light: DirectionalLight;
    lockObject: LockObject;
    onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
}

export class DirectionalLightPropertyGridComponent extends React.Component<IDirectionalLightPropertyGridComponentProps> {
    constructor(props: IDirectionalLightPropertyGridComponentProps) {
        super(props);
    }

    displayFrustum() {
        const { light } = this.props;
        const camera = light.getScene().activeCamera;

        const displayFrustum = ((light as any)._displayFrustum = !(light as any)._displayFrustum);

        if ((light as any)._displayFrustumObservable) {
            light.getScene().onAfterRenderObservable.remove((light as any)._displayFrustumObservable);
            (light as any)._displayFrustumDLH.dispose();
        }

        if (displayFrustum && camera) {
            const dlh = ((light as any)._displayFrustumDLH = new DirectionalLightFrustumViewer(light, camera));
            (light as any)._displayFrustumObservable = light.getScene().onAfterRenderObservable.add(() => {
                dlh.update();
            });
        }
    }

    render() {
        const { light } = this.props;

        const generator = (light.getShadowGenerator() as ShadowGenerator | CascadedShadowGenerator) || null;

        const hideAutoCalcShadowZBounds = generator instanceof CascadedShadowGenerator;
        const displayFrustum = (light as any)._displayFrustum ?? false;

        return (
            <Wrapper className="pane">
                <CommonLightPropertyGridComponent
                    globalState={ this.props.globalState }
                    lockObject={ this.props.lockObject }
                    light={ light }
                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                />
                <ActionTabSectionComponent title="SETUP">
                    <Color3LineComponent label="Diffuse" target={ light } propertyName="diffuse" onPropertyChangedObservable={ this.props.onPropertyChangedObservable } />
                    <Color3LineComponent label="Specular" target={ light } propertyName="specular" onPropertyChangedObservable={ this.props.onPropertyChangedObservable } />
                    { this.props.globalState.isDevMode && (
                        <>
                            <Vector3LineComponent label="Position" target={ light } propertyName="position" onPropertyChangedObservable={ this.props.onPropertyChangedObservable } />
                            <Vector3LineComponent label="Direction" target={ light } propertyName="direction" onPropertyChangedObservable={ this.props.onPropertyChangedObservable } />
                        </>
                    ) }
                    { !hideAutoCalcShadowZBounds && (
                        <CheckBoxLineComponent
                            label="Auto Calc Shadow ZBounds"
                            target={ light }
                            propertyName="autoCalcShadowZBounds"
                            onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                        />
                    ) }
                </ActionTabSectionComponent>
                <CommonShadowLightPropertyGridComponent
                    globalState={ this.props.globalState }
                    lockObject={ this.props.lockObject }
                    light={ light }
                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                />
                <ActionTabSectionComponent title="DEBUG" collapsed hasDivider={ false }>
                    <CheckBoxLineComponent label="Display frustum" isSelected={ () => displayFrustum } onSelect={ () => this.displayFrustum() } />
                </ActionTabSectionComponent>
            </Wrapper>
        );
    }
}
