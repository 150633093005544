import React                        from 'react';
import Download                     from '../../../../../asset/icons/Download';
import { PreviewModalRoot }         from '../styles';
import { IconWrapper, TextWrapper } from './styles';

export function DownloadInstructions() {
    return (
        <PreviewModalRoot>
            <IconWrapper>
                <Download width="41" height="41" />
            </IconWrapper>
            <TextWrapper>
                Tap download icon in your browser to share downloaded media
            </TextWrapper>
        </PreviewModalRoot>
    );
}
