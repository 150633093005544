import React, { useEffect, useRef, useState }     from 'react';
import { BabylonRenderer }                        from "@geenee/geespector/renderer/babylonjs.renderer";
import { useInject }                              from '@geenee/shared';
import { SnapshotToggle }                         from '@geenee/shared/src/magellan/components/SnapShotComponent/SnapshotToggle';
import { SnapShotWrapper }                        from '@geenee/shared/src/magellan/components/SnapShotComponent/SnapShotWrapper';
import { Wrapper }                                from '@geenee/ui';
import { observer }                               from 'mobx-react';
import { LoadingStatus }                          from "~/components/LoadingStatus/loading-status.component";
import { SceneDropTarget, TapForFullScreenLabel } from '~/components/ScreenElements/ContentTypeContainer/ContentTypes/SceneDrawer/SceneOpenTrigger/styles';
import { SnapshotScreenWrapper }                  from '~/components/ScreenElements/ContentTypeContainer/ContentTypes/styles';
import { TYPE_HEAD_TRACKING }                     from '~/lib/constants';
import { AtomModel }                              from '~/magellan/model/atom.model';
import { useLoadedModels }                        from '~/module/geeltf-inspector/hooks/use-loaded-models.hook';
import { useGLTFInspectorModal }                  from '~/module/geeltf-inspector/modal/useGLTFInspectorModal';

export const GeeLTFSceneComponent = observer(() => {
    const { AppState } = useInject();
    const { activeSection : sectionModel, activeMolecule } = AppState;
    const { sceneManager } = sectionModel || { sceneManager: undefined };
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const [ renderer, setRenderer ] = useState<BabylonRenderer>();
    const [ isVideoMode, setIsVideoMode ] = useState(false);

    const initScene = () => {
        if (sceneManager && wrapperRef.current) {
            Array.from(wrapperRef.current.children || []).forEach((child) => {
                wrapperRef.current?.removeChild(child);
            });
            sceneManager.initialise(wrapperRef.current, { debugLayer: false });
            const sceneRenderer = sceneManager.sceneRenderer as any as BabylonRenderer;
            setRenderer(sceneRenderer);
            if (sectionModel?.type === TYPE_HEAD_TRACKING) {
                if (sceneRenderer.gridMesh) {
                    sceneRenderer.gridMesh.position.y = -0.1;
                }
            }
        }
    };
    // @ts-ignore
    const [ showModal ] = useGLTFInspectorModal(sceneManager, initScene);

    useEffect(() => {
        initScene();
    }, [ activeMolecule?.id ]);

    useLoadedModels((activeMolecule?.sceneActorAtoms || []) as AtomModel[], renderer, sectionModel?.type);

    return (
        <Wrapper style={ { width: '100%', height: '100%', position: 'absolute', zIndex: 2 } }>
            <div
                ref={ wrapperRef }
                id="canvas-wrapper"
                style={ {
                    width: '100%', height: 'calc(100% + 48px)', position: 'absolute', top: -48, left: 0
                } }
            />
            <SceneDropTarget>
                <TapForFullScreenLabel onClick={ () => showModal() }>
                    GO FULL SCREEN TO
                    <br />
                    DESIGN YOUR SCENE
                </TapForFullScreenLabel>
            </SceneDropTarget>
            <SnapshotScreenWrapper>
                <SnapShotWrapper>
                    <SnapshotToggle
                        isVideoMode={ isVideoMode }
                        videoRecordingIndicatorContainer={ null }
                        onPhotoCaptureClick={ () => setIsVideoMode(false) }
                        onVideoCaptureClick={ () => setIsVideoMode(true) }
                    />
                </SnapShotWrapper>
            </SnapshotScreenWrapper>
            <LoadingStatus id="preview-loader" />
        </Wrapper>
    );
});
