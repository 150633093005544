import React, { useEffect }                             from 'react';
import { useHistory }                                   from "@geenee/common";
import { useInject }                                    from '@geenee/shared';
import { LoadingSpinnerCentered }                       from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { Description, Title, Wrapper }                  from '@geenee/ui';
import { observer }                                     from 'mobx-react';
import NextArrow                                        from '@geenee/builder/src/asset/icons/NextArrow';
// @ts-ignore
import nftPlaceholder                                   from '@geenee/builder/src/asset/images/NFT_placeholder.png';
import RaisedButton                                     from '@geenee/builder/src/components/common/Button/WithIcon';
import { AddNFTDetails }                                from '@geenee/builder/src/components/modals/AddNFTDetails';
import { DIComponentType }                              from '@geenee/builder/src/core/context/di.context';
import { nftState }                                     from '@geenee/builder/src/core/state/nft.state';
import TourState                                        from '@geenee/builder/src/core/state/tour.state';
import { NOTION_SUPPORT_URL }                           from "@geenee/builder/src/lib/constants";
import { useFirstAssetPreview }                         from '@geenee/builder/src/lib/useFirstAssetPreview';
import { CreateNFTWallet }                              from '../CreateNFTWallet';
import { BackButton, ContentWrapper, ImgWrapper, Link } from './styles';

type Props = {
  close: () => void;
  // eslint-disable-next-line no-empty-pattern
  setModal: ({ }) => void;
  hasWallet: boolean;
  projectUrl: string;
};

export const PostPublishingNFT = observer((props: Props & DIComponentType) => {
    const { AppState } = useInject();
    const { firstAvailableSection } = AppState;
    const history = useHistory();

    useEffect(() => {
        nftState.getWallets();
    }, []);

    const onClickWallet = () => {
        // const nextModal = nftState.walletStore.length ? SelectNFTToMint : CreateNFTWallet;
        const nextModal = nftState.walletStore.length ? AddNFTDetails : CreateNFTWallet;
        // @ts-ignore
        if (AppState?.activeProject.id) {
            props.setModal({ component: nextModal, props: { mintProjectId: AppState?.activeProject.id, projectUrl: props.projectUrl } });
        }
    };

    // TODO: double-check this ts-error when NFT will be working
    // @ts-ignore
    const { isLoading, previewUrl } = useFirstAssetPreview(firstAvailableSection || undefined);

    return (
        <ContentWrapper>
            <Wrapper align='center'>
                { !nftState.isWalletsLoaded ? <LoadingSpinnerCentered /> : (
                    <>
                        <Title
                            size='xxl'
                            align='center'
                            margin='md'
                            // @ts-ignore
                            style={ { width: '690px' } }
                        >
                            Would you like to turn your WebAR Project into an NFT?
                        </Title>
                        <Wrapper maxWidth='490px' align='center'>
                            <Wrapper align='center' margin='md'>
                                { isLoading ? <LoadingSpinnerCentered />
                                    : (
                                        <ImgWrapper>
                                            { /* eslint-disable-next-line jsx-a11y/img-redundant-alt */ }
                                            <img
                                                src={ previewUrl || nftPlaceholder }
                                                alt="some image"
                                                width='489px'
                                                height='262px'
                                                style={ { objectFit: 'contain' } }
                                            />
                                        </ImgWrapper>
                                    ) }
                            </Wrapper>
                            <Wrapper margin='sm'>
                                { !props.hasWallet && (
                                    <Wrapper align='center'>
                                        <Description size='sm' align='center' margin='sm' style={ { width: '492px' } }>
                                            It’s quick and easy to mint your Project and sell it as an AR NFT.
                                            We do not charge gas (set up) fees. Upon the sale, you will be charged a one-time 2.5%
                                            fee via the Mintable marketplace and .5% Geenee fee.
                                        </Description>
                                        <Link
                                            onClick={ () => {
                                                props.setModal({ hideModal: true });
                                                window.open(NOTION_SUPPORT_URL);
                                            } }
                                            target="_blank"
                                        >
                                            Hold on, what are NFTs?
                                        </Link>
                                    </Wrapper>
                                ) }
                            </Wrapper>
                            <Wrapper row fullWidth style={ { justifyContent: 'space-between' } }>
                                <BackButton
                                    $padding="0 23px"
                                    $justify="center"
                                    $width="141px"
                                    $height="61px"
                                    onClick={ () => { props.close(); } }
                                    label="NO THX"
                                />
                                <Wrapper align="right">
                                    <RaisedButton
                                        $gradient
                                        $padding="0 23px"
                                        $justify="space-between"
                                        $width="141px"
                                        $height="61px"
                                        onClick={ () => onClickWallet() }
                                        text="YES"
                                        iconElement={ (
                                            <NextArrow width="30" height="30" />
                                        ) }
                                    />
                                </Wrapper>
                            </Wrapper>
                            { /* <Link href='#' target="_blank" className="do-not-show">
                                Please do not show this message again
                            </Link> */ }
                        </Wrapper>
                    </>
                ) }
            </Wrapper>
        </ContentWrapper>
    );
});
