import '@abraham/reflection';
import * as React                        from 'react';
import { useState }                      from 'react';
import { DndProvider }                   from 'react-dnd';
import { HTML5Backend }                  from 'react-dnd-html5-backend';
import { TransitionGroup }               from 'react-transition-group';
import { Analytics }                     from '@geenee/analytics';
import WithModal                         from '@geenee/builder/src/components/WithModal';
import { BuilderDI, DIContextType }      from '@geenee/builder/src/core/context/di.context';
import { getDIComponent }                from "@geenee/builder/src/core/context/di.get-component";
import envConfig                         from '@geenee/builder/src/lib/envConfig';
import PagesConfig                       from '@geenee/builder/src/lib/pagesConfig';
import { container as builderContainer } from '@geenee/builder/src/magellan/di/di';
import theme                             from '@geenee/builder/src/styles/themeV2';
import { BrowserRouter }                 from "@geenee/common";
import { EditAssetSceneState }           from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/state/edit-asset-scene.state";
import { BROWSER_MODE }                  from '@geenee/shared/src/util/constants';
import { ModalProvider }                 from '@geenee/ui';
import * as Sentry                       from '@sentry/react';
import { Integrations }                  from '@sentry/tracing';
import { Elements }                      from '@stripe/react-stripe-js';
import { loadStripe }                    from '@stripe/stripe-js';
import { createBrowserHistory, History } from 'history';
import { Container }                     from "inversify";
import { Provider }                      from 'mobx-react';
import { ThemeProvider }                 from 'styled-components';
import App                               from './App';
// require('react-hot-loader/patch');

const stripePromise = loadStripe(envConfig.STRIPE_KEY);
const sentryDsn =    process.env.NODE_ENV === 'development' ? undefined : envConfig.SENTRY_DSN;
const UUIDRegexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
const analytics: Analytics = builderContainer.get('<Analytics>');

export const history: History|object = BROWSER_MODE ? createBrowserHistory() : {};

// @ts-ignore
history?.listen((routeObj) => {
    if (!UUIDRegexExp.test(routeObj.pathname?.split('/')[ 1 ]) || '') {
        const { pageName } = Object.keys(PagesConfig).reduce(
            (acc: { path?: string; pageName: string }, key: string) => (PagesConfig[ key ].path === routeObj.pathname
                ? { pageName: PagesConfig[ key ].name }
                : acc),
            { pageName: '' }
        );

        if (pageName) {
            analytics.page('main', pageName);
        } else {
            console.error('Analytics Error: unknown page. ', routeObj);
        }
    } else {
        analytics.page('editPrograms', 'Edit Program');
    }
});

if (BROWSER_MODE) {
    Sentry.init({
        dsn:         sentryDsn,
        environment:
      process.env.ENV_NODE_ENV === 'development'
          ? 'development'
          : 'production',
        denyUrls: [
            // Comment lines below to capture errors while in local development
            /localhost:1234\//i, // local server
            /127\.0\.0\.1:1234\//i // local server
        ],
        integrations:     [ new Integrations.BrowserTracing() ],
        tracesSampleRate: 1.0
    });

    analytics.init(envConfig.SEGMENT_KEY);
}
export const Builder = React.memo((props: { container: Container }) => {
    const [ appDi ] = useState<DIContextType>({});
    const { container } = props;

    const mobxMap = {
        container,
        AppState:     container.get("<AppState>"),
        StripeState:  container.get("<StripeState>"),
        BuilderState: container.get("<BuilderState>")
    };
    const builderMobxMap = {
        EditAssetSceneState: new EditAssetSceneState(),
        getDIComponent
    };
    return (
        <BrowserRouter>
            <Provider { ...{ ...mobxMap, ...builderMobxMap } }>
                <ThemeProvider theme={ theme }>
                    <Elements stripe={ stripePromise }>
                        <BuilderDI.Provider
                            value={ { ...appDi } }
                        >
                            <WithModal>
                                <ModalProvider
                                    rootComponent={ TransitionGroup }
                                >
                                    <DndProvider backend={ HTML5Backend }>
                                        { /* <AppContainer> */ }
                                        <App />
                                        { /* </AppContainer> */ }
                                    </DndProvider>
                                </ModalProvider>
                            </WithModal>
                        </BuilderDI.Provider>
                    </Elements>
                </ThemeProvider>
            </Provider>
        </BrowserRouter>
    );
});
