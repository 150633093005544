const globalAny: any = window || global || {};
let cv: any;

export function load() {
    return loadOpenCV();
}

function loadOpenCV(): Promise<void> {
    return new Promise((resolve) => {
        globalAny.Module = { onRuntimeInitialized: resolve };
        cv = require('./opencv/opencv');
        // @ts-ignore
        window.cv = cv;
    });
}
