import { ButtonProps } from 'primereact/button';
import styled          from 'styled-components';

export const buttonStyle = ({
    theme: { colors, secondaryColors },
    $fontSize,
    $width,
    $color,
    $height,
    $padding,

    $gradient,
    $light,

    $textColor,
    $textWeight,
    disabled,
    $hideBtnText,
    $borderRadius
}: ButtonProps) => `
  &&& {
    cursor: ${ disabled ? 'not-allowed !important' : 'pointer' };
    padding: ${ $padding || '0' };
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border: none !important;
    width: ${ $width || '100%' };
    height: ${ $height || '40px' };
    font-size: ${ $fontSize || '12px' };
    font-weight: ${ $textWeight || 'bold' };

    color: ${
    $textColor
    // eslint-disable-next-line no-nested-ternary
        || ($gradient ? colors.black : $light ? colors.black : colors.white)
};
    background-color: ${
    $color
    // eslint-disable-next-line no-nested-ternary
        || ($gradient
            ? secondaryColors.green80
            : $light
                ? colors.white
                : colors.black)
};

    border-radius: ${ $borderRadius || '40px' };
    text-transform: uppercase;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    filter: ${ disabled ? 'grayscale(1)' : 'none' };

    div,
    h3,
    .p-button-text {
      font-family: 'Euclid Circular A', sans-serif;
    }

    &:hover {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), inset 0px 2px 2px rgba(0, 0, 0, 0.25);
      background: ${
    // eslint-disable-next-line no-nested-ternary
    $gradient
        ? 'linear-gradient(90deg, #F5F5F5 4.35%, #57E7D4 100%)'
        : $light
            ? '#F5F5F5'
            : '#5A5A5A'
};
      color: ${
    // eslint-disable-next-line no-nested-ternary
    $textColor || $gradient
        ? colors.black
        : $light
            ? colors.black
            : colors.white
};
    }

    ${
    $hideBtnText
        && `
      .p-button-text { display: none; }
    `
}
  }
`;

export const ButtonWithIcon = styled.div<ButtonProps>`
    ${ buttonStyle };

    @keyframes opacity-pulse {
        0% {
            opacity: 1;
        }

        50% {
            opacity: 0.4;
        }
        
        100% {
            opacity: 1;
        }
    }
    
    svg {
        ${ ({ pulseAnimation }) => (pulseAnimation ? 'animation: opacity-pulse 3s infinite' : '') };
    }

    &&& {
        cursor: pointer;
        flex-direction: ${ ({ $direction }) => $direction };
        display: flex;
        align-items: center;
        position: relative;
        justify-content: ${ ({ $justify }) => $justify };

        & > *:first-child {
            ${ ({ $direction }) => ($direction === 'row'
        ? 'margin-right: 7px !important;'
        : 'margin-left: 7px !important;') }
        }

        ${ ({ $dark }) => $dark && 'h3 {color: #fff}' }
    }
`;
