import React, { useState }                     from 'react';
import { useInject }                           from '@geenee/shared';
import { LoadingSpinnerCentered }              from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { observer }                            from 'mobx-react';
import { DropWrapper }                         from '@geenee/builder/src/components/DropWrapper';
import { CameraFeedContainer }                 from '@geenee/builder/src/components/ScreenElements/CameraFeedPlaceholder/styles';
// eslint-disable-next-line max-len
import { SceneDrawer }                         from '@geenee/builder/src/components/ScreenElements/ContentTypeContainer/ContentTypes/SceneDrawer';
import { DIComponentType }                     from '@geenee/builder/src/core/context/di.context';
import { AVAILABLE_DROP_FORMATS, IMG_FORMATS } from '@geenee/builder/src/lib/constants';
import { LoadingWrapper }                      from '@geenee/builder/src/magellan/components/programView/styles';
import { MoleculeModel }                       from '@geenee/builder/src/magellan/model/molecule.model';
import { SectionModel }                        from '@geenee/builder/src/magellan/model/section.model';

export function VideoCanvasWrapper() {
    return <></>;
}
export function VideoStreamWrapper() {
    return <></>;
}
export function SlamWrapper() {
    return <SceneDrawer />;
}
export function StampWrapper() {
    return <SceneDrawer />;
}
export const SceneBuildWrapper = SceneDrawer;

export const ContentWrapper = observer(
    ({ children }: { children: JSX.Element[] }) => (
        <>
            <CameraFeedContainer />
            { children }
        </>
    )
);

export const ImageGalleryWrapper = observer(
    ({ children }: {
            children: JSX.Element | JSX.Element[];
        }) => {
        const [ loading, setLoading ] = useState(false);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [ uploadingWindowOpened, setUploadingWindowOpened ] = useState(
            false
        );
        const { AppState } = useInject();
        const { firstAvailableSection } = AppState;
        const { activeMolecule } =  firstAvailableSection as SectionModel & {activeMolecule: MoleculeModel};
        const disabled = firstAvailableSection?.nft_published;
        return (
            <>
                { loading ? (
                    <LoadingWrapper>
                        <LoadingSpinnerCentered />
                    </LoadingWrapper>
                ) : (
                    <DropWrapper
                        clearMessageKey={ activeMolecule?.id }
                        disabled={ disabled }
                        multiple
                        clickable
                        fileFormats={ IMG_FORMATS }
                        fullHeight
                        onDrop={ async (files) => {
                            setLoading(true);
                            await Promise.all(files.map((file) => activeMolecule?.addAtomWithAttachment(file, { type: 'gallery-image' })));
                            setUploadingWindowOpened(true);
                            setLoading(false);
                        } }
                    >
                        { children }
                    </DropWrapper>
                ) }
                { /* { uploadingWindowOpened && (
                        <Drawer
                            uploadingAttachments={
                                activeSection.image_attachments.filter(
                                    (el) => !el.id
                                ) as AttachmentModel[]
                            }
                            style={ {
                                position: 'fixed',
                                bottom:   0,
                                right:    '25px',
                                zIndex:   1
                            } }
                            component={ DrawerUploadContent }
                            onCloseUploader={ () => {
                                setUploadingWindowOpened(false);
                            } }
                        />
                    ) } */ }
            </>
        );
    }
);

export const AssetSwitcherItemWrapper = observer(({ children, moleculeModel }: {
    children: JSX.Element | JSX.Element[];
    moleculeModel: MoleculeModel;
} & DIComponentType) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const { AppState } = useInject();
    const { activeSection } = (AppState as unknown) as {
    activeSection?: SectionModel;
  };
    const disabled = moleculeModel.id === 'new' || activeSection?.nft_published;
    if (isLoading) {
        return <LoadingSpinnerCentered size={ 74 } />;
    }
    return (
        <DropWrapper
            disabled={ disabled }
            onDrop={ async ([ file ]) => {
                setIsLoading(true);
                await moleculeModel.setThumbnailImage(file);
                setIsLoading(false);
            } }
            fileFormats={ AVAILABLE_DROP_FORMATS }
            style={ { borderRadius: '100%', textAlign: 'center', width: '65px', height: '65px' } }
        >
            { children }
        </DropWrapper>
    );
});
