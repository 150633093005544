import React                                   from 'react';
import { useTheme }                            from 'styled-components';
import CheckboxChecked                         from '@geenee/builder/src/asset/icons/CheckboxChecked';
import HintCloudLightning                      from '@geenee/builder/src/asset/icons/HintCloudLightning';
import Hint                                    from '@geenee/builder/src/components/common/Hint';
import { UnderlinedTableRow, WithHintWrapper } from '@geenee/builder/src/pages/AllPlansPage/styles';

export function SdkAccess() {
    const { secondaryColors } = useTheme();
    return (
        <UnderlinedTableRow>
            <td>
                <WithHintWrapper>
                    <span>SDK Access</span>
                    <Hint
                        title='100% Custom'
                        text='Full access to the Geenee SDK to create robust, interactive WebAR powered by our patented tech stack.'
                        iconElement={ <HintCloudLightning /> }
                    />
                </WithHintWrapper>
            </td>
            <td className='basic-plan' />
            <td className='plan-filler' />
            <td className='studio-plan'>
                { ' ' }
                <CheckboxChecked
                    beIcon
                    fill={ secondaryColors.green70 }
                />
            </td>
            <td className='plan-filler' />
            <td className='pro-plan'>
                { ' ' }
                <CheckboxChecked
                    beIcon
                    fill={ secondaryColors.green70 }
                />
            </td>
        </UnderlinedTableRow>
    );
}
