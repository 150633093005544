import { Vector3SchemaType }       from '@geenee/shared/type/atom.type';
import { Box3, Object3D, Vector3 } from 'three';

export const isContainsSkinnedMesh = (object3D: Object3D) => {
    let hasSkinnedMesh = false;
    object3D.traverse((obj3D) => {
        if (obj3D.type === "SkinnedMesh") {
            hasSkinnedMesh = true;
        }
    });
    return hasSkinnedMesh;
};

export const getCompoundBoundingBox = (object3D: Object3D) : Box3 => {
    let box = null;
    object3D.traverse((obj3D) => {
        // @ts-ignore
        const { geometry } = obj3D;
        if (geometry === undefined) return;
        geometry.computeBoundingBox();
        if (box === null) {
            box = geometry.boundingBox;
        } else {
            box.union(geometry.boundingBox);
        }
    });
    return box;
};

export const getCameraScaledVector = (object: Object3D | null, scaleStats: Vector3SchemaType) => {
    if (object) {
        const boundingBox    = isContainsSkinnedMesh(object) ? getCompoundBoundingBox(object) : new Box3().setFromObject(object);
        const containerSize  = boundingBox.getSize(new Vector3());
        const modelMax = Math.max(containerSize.x, containerSize.y, containerSize.z);
        const ratio = 1 / (modelMax || 1);

        if (ratio.toFixed(3) !== '1.000') {
            return new Vector3(scaleStats[ 0 ] * ratio, scaleStats[ 1 ] * ratio, scaleStats[ 2 ] * ratio);
        }
        return null;
    }
    return null;
};
