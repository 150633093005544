import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '19',
    height = '19',
    onClick,
    id,
    fill = 'black'
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            viewBox="0 0 19 19"
            xmlns="http://www.w3.org/2000/svg"
            onClick={ onClick }
        >
            <path
            // eslint-disable-next-line max-len
                d="M13.0469 11.5833H12.255L11.9743 11.3021C12.9567 10.1146 13.5481 8.57292 13.5481 6.89583C13.5481 3.15625 10.6312 0.125 7.03259 0.125C3.43403 0.125 0.51709 3.15625 0.51709 6.89583C0.51709 10.6354 3.43403 13.6667 7.03259 13.6667C8.64643 13.6667 10.13 13.0521 11.2727 12.0312L11.5433 12.3229V13.1458L16.5552 18.3438L18.0488 16.7917L13.0469 11.5833ZM7.03259 11.5833C4.53665 11.5833 2.52186 9.48958 2.52186 6.89583C2.52186 4.30208 4.53665 2.20833 7.03259 2.20833C9.52853 2.20833 11.5433 4.30208 11.5433 6.89583C11.5433 9.48958 9.52853 11.5833 7.03259 11.5833Z"
                fill={ fill }
            />
        </svg>
    );
}
