import React                              from 'react';
import { Collapse, Description, Divider } from "@geenee/ui";
import { WrapperPropsType }               from "@geenee/ui/src/lib/component/wrapper/wrapper.component";
import { SizeType }                       from "@geenee/ui/src/lib/util/global-props";

interface Props {
  children: JSX.Element | JSX.Element[]
  title: string
  collapsed?: boolean
  contentWrapperProps?: WrapperPropsType
  paddingLeft?: SizeType
  hasDivider?: boolean
}

export function ActionTabSectionComponent({
    children, title, collapsed = false, contentWrapperProps, paddingLeft = 'off', hasDivider = true
} : Props) {
    return (
        <>
            <Collapse
                collapsed={ collapsed }
                panelContent={ <Description lineHeight="lg" weight="medium">{ title }</Description> }
                iconCollapsedAfter
                iconCollapsedProps={ { name: 'arrowDown' } }
                iconCollapsedRotationAngle="180deg"
                paddingLeft={ paddingLeft }
                panelWrapperProps={ { padding: "xs", radius: 'lg' } }
                collapseWrapperProps={ { fullWidth: true } }
                contentWrapperProps={ contentWrapperProps }
            >
                { children }
            </Collapse>
            { hasDivider && <Divider style={ { height: 1, marginTop: 8 } } margin="xs" color="white" /> }
        </>
    );
}
