import * as React                              from "react";
import type { Material }                       from "@babylonjs/core/Materials/material";
import type { MultiMaterial }                  from "@babylonjs/core/Materials/multiMaterial";
import type { Observable }                     from "@babylonjs/core/Misc/observable";
import { LineContainerComponent }              from "@geenee/geespector-ui-components/src/lines/lineContainerComponent";
import { TextLineComponent }                   from "@geenee/geespector-ui-components/src/lines/textLineComponent";
import type { LockObject }                     from "@geenee/geespector-ui-components/src/tabs/propertyGrids/lockObject";
import { Wrapper }                             from "@geenee/ui";
import type { GlobalState }                    from "../../../../globalState";
import type { PropertyChangedEvent }           from "../../../../propertyChangedEvent";
import { CommonMaterialPropertyGridComponent } from "./commonMaterialPropertyGridComponent";

interface IMultiMaterialPropertyGridComponentProps {
    globalState: GlobalState;
    material: MultiMaterial;
    lockObject: LockObject;
    onSelectionChangedObservable?: Observable<any>;
    onPropertyChangedObservable?: Observable<PropertyChangedEvent>;
}

export class MultiMaterialPropertyGridComponent extends React.Component<IMultiMaterialPropertyGridComponentProps> {
    constructor(props: IMultiMaterialPropertyGridComponentProps) {
        super(props);
    }

    onMaterialLink(mat: Material) {
        if (!this.props.onSelectionChangedObservable) {
            return;
        }

        this.props.onSelectionChangedObservable.notifyObservers(mat);
    }

    renderChildMaterial() {
        const { material } = this.props;

        return (
            <LineContainerComponent title="CHILDREN" selection={ this.props.globalState }>
                { material.subMaterials.map((mat, i) => {
                    if (mat) {
                        return <TextLineComponent key={ `Material #${ i }` } label={ `Material #${ i }` } value={ mat.name } onLink={ () => this.onMaterialLink(mat) } />;
                    }
                    return null;
                }) }
            </LineContainerComponent>
        );
    }

    render() {
        const { material } = this.props;

        return (
            <Wrapper className="pane">
                <CommonMaterialPropertyGridComponent
                    globalState={ this.props.globalState }
                    lockObject={ this.props.lockObject }
                    material={ material }
                    onPropertyChangedObservable={ this.props.onPropertyChangedObservable }
                />
                { this.renderChildMaterial() }
            </Wrapper>
        );
    }
}
