import { AtomModel, MoleculeModel } from "@geenee/shared";
import { AttachmentType }           from "@geenee/shared/type/attachment.type";
import { injectable }               from "inversify";
import { AtomSchemaType }           from "../../../../type/atom.type";
import { FactoryAbstract }          from "../../../util/factoryContainer";

type ParentType = MoleculeModel

@injectable()
export class AtomFactory extends FactoryAbstract {
    create(
        atomSchema: AtomSchemaType,
        $parent?: ParentType
    ) {
        const customModuleAtomFactory = this.container.get("<CustomModuleAtomFactory>");
        if (atomSchema.type === "scene-code-module") {
            return customModuleAtomFactory.create(atomSchema, $parent);
        }
        console.log("AtomFactory.create() called");
        console.log("atomSchema:", atomSchema);

        const atomModel: AtomModel = this.container.get("<AtomModel>");
        const attachmentFactory = this.container.get("<AttachmentFactory>");
        Object.assign(atomModel, atomSchema);
        atomModel.atomSchema = atomSchema;

        atomSchema?.assets?.forEach((assetSchema: Partial<AttachmentType>) => {
            const asset = attachmentFactory.create(assetSchema, $parent);
            atomModel.assetsRegistry.set(asset.id, asset);
        });
        // if (atomSchema?.options?.atom_image_url) {
        //     const image = assetFactory.create({ url: atomSchema.options.atom_image_url }, atomModel);
        //     atomModel.imageAsset = image;
        //     atomModel.assetsRegistry.set(image.id, image);
        // }
        if ($parent) {
            atomModel.$parent = $parent;
        }
        return atomModel;
    }
}

export type AtomFactoryType = AtomFactory
