import React, { useEffect, useRef } from 'react';
import { useInject }                from '@geenee/shared';
import { runInAction }              from 'mobx';
import { observer }                 from 'mobx-react';
import RichTextEditor               from '@geenee/builder/src/components/RichTextEditor/RichTextEditor';
import TourState                    from '@geenee/builder/src/core/state/tour.state';
import useOnClickOutside            from '@geenee/builder/src/lib/useOnClickOutside';
import { MagellanState }            from '@geenee/builder/src/magellan/state/app.state';
import { IntroBoxStyled }           from '@geenee/builder/src/styles';

export const IntroBox = observer(() => {
    const { AppState } = useInject();
    const wizardRef = useRef<HTMLElement | null>(null);
    const { activeExperience } = AppState as MagellanState;
    const { isStarted, step } = TourState;
    const [ activeEditor, setActiveEditor ] = React.useState(false);
    const disabled = activeExperience?.nft_published;

    useEffect(() => {
        wizardRef.current = document.getElementById('wizard_content');
    }, [ activeEditor ]);

    useOnClickOutside(wizardRef, (event) => {
        // @ts-ignore
        const targetClassName = event?.target?.className;
        const canClose = typeof targetClassName === 'string' ? !targetClassName.startsWith('ql-') : true;
        canClose && activeEditor && setActiveEditor(false);
        runInAction(() => {
            if (!isStarted && canClose) {
                (AppState as unknown as MagellanState).isSplashScreenEditing = false;
            }
        });
    });

    useEffect(() => {
        // Toolbar should be visible while Tour is active on step 4
        if (isStarted && step === 4) {
            setActiveEditor(true);
        } else {
            setActiveEditor(false);
        }
        return () => {
            if (!isStarted) {
                runInAction(() => (AppState as unknown as MagellanState).isSplashScreenEditing = false);
            }
        };
    }, [ step, isStarted ]);

    if (!activeExperience) return <></>;

    const { id, splash_screen_message } = activeExperience;

    const activateComponent = () => {
        if (!disabled) {
            runInAction(() => {
                (AppState as unknown as MagellanState).isSplashScreenEditing = true;
            });
            setActiveEditor(true);
        }
    };

    return (
        <IntroBoxStyled onClick={ activateComponent } hoverEffect={ isStarted && step === 4 }>
            { activeExperience.splash_screen_message && (
                <RichTextEditor
                    value={ splash_screen_message }
                    onEditorChange={ (content) => {
                        if (content !== splash_screen_message) {
                            activeExperience.splash_screen_message = content;
                            activeExperience.saveData();
                        }
                    } }
                    activeEditor={ activeEditor }
                    key={ `${ id } ${ step } ${ activeEditor }` }
                    id={ id }
                    blur={ isStarted }
                />
            ) }
        </IntroBoxStyled>
    );
});
