import { AppState, ProjectType } from "@geenee/shared";
import { FactoryAbstract }       from "@geenee/shared/src/util/factoryContainer";
import { injectable }            from "inversify";

@injectable()
export class ProjectFactory extends FactoryAbstract {
    async create(projectSchema: ProjectType, $parent?: AppState) {
        const projectModel = this.container.get("<ProjectModel>");
        const experienceFactory = this.container.get("<ExperienceFactory>");

        projectSchema?.experiences?.forEach((experience) => {
            const experienceModel = experienceFactory.create(experience, projectModel);
            projectModel.experiencesRegistry.set(experienceModel.id, experienceModel);
        });

        if (projectSchema.options && $parent?.$parent?.foldersController) {
            // create folders
            await $parent?.$parent.foldersController.handleFolder(projectSchema);
        }

        Object.assign(projectModel, { ...projectSchema });
        if ($parent) {
            projectModel.$parent = $parent;
        }
        return projectModel;
    }
}

export type ProjectFactoryType = ProjectFactory;
