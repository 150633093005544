import { getFileFromUrl } from "@geenee/builder/src/lib/getFileFromUrl";
import { CommandType }    from '@geenee/shared/src/commander/types';
import { MoleculeModel }  from "@geenee/shared/src/magellan/molecule/model/molecule.model";

export class AddTrackingImage implements CommandType {
    receiver: MoleculeModel;
    file: File;
    prevFile?: File;

    constructor(receiver: MoleculeModel, file: File) {
        this.receiver = receiver;
        this.file = file;
    }

    execute = () => {
        getFileFromUrl(this.receiver.sceneTriggerAtom?.assetUrl || '').then((file) => {
            this.prevFile = file;
        });
        this.receiver.setSceneTrigger(this.file);
    };

    revert = () => {
        if (this.prevFile) {
            this.receiver.setSceneTrigger(this.prevFile);
        }
    };
}
