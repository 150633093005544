import React, { memo }                         from 'react';
import { CollapsedTreeItem }                   from "@geenee/geeclient-kit/src/lib/component/scene/components/CollapsedTreeItem";
import { AtomModel, MoleculeModel, useInject } from '@geenee/shared/index';
import {
    Icon, iconMap,
    Wrapper
} from '@geenee/ui';
import { observer }    from "mobx-react";
import { ActionsList } from "./Actions";
import { AssetTitle }  from "./AssetTitle";

interface Props {
    title: string
    children: React.ReactNode
    active?: boolean
    disabled?: boolean
    currentModel?: AtomModel | MoleculeModel
    onTitleClick?: () => void
    actions?: JSX.Element | JSX.Element[],
    rowClassName?: string
    noAnimations?: boolean
    initialExpanded?: boolean
    rootNode?: boolean
    offset?: number
}

const ModelIcon = memo(({ icon }: {icon: keyof typeof iconMap}) => <Icon margin="xxs" stroke="fat" color="shade-4" name={ icon } />);

export const LayersChevronPanel = observer(({
    children,
    title = '',
    active = false,
    disabled,
    currentModel,
    onTitleClick,
    noAnimations,
    initialExpanded = true,
    rootNode = false,
    offset = 1
}: Props) => {
    const { AppState } = useInject();

    const Content = () => (
        <AssetTitle
            onClick={ onTitleClick }
            value={ title }
            currentModel={ currentModel }
            disabled={ disabled || rootNode }
            after={ (
                <ActionsList
                    popoverSchema={ rootNode ? 'rootNode' : 'full' }
                    index={ 0 }
                    currentModel={ currentModel }
                    enableCustomCode={ !disabled }
                    enableVisibleEye={ !!AppState.activeMolecule?.sceneActorAtoms?.length }
                    enablePlayAnimation={ !noAnimations }
                />
            ) }
        />
    );

    return (
        <Wrapper fullWidth>
            <CollapsedTreeItem
                content={ <Content /> }
                isSelected={ active }
                icon={ rootNode ? null : <ModelIcon icon={ currentModel?.options?.scene_atom_source_icon || 'mesh' } /> }
                id={ `node-item_${ currentModel?.id }` }
                offset={ offset }
                isExpanded={ initialExpanded }
            >
                { children }
            </CollapsedTreeItem>
        </Wrapper>
    );
});
