import { SectionModel } from '@geenee/builder/src/magellan/model/section.model';

export const useHandlers = (activeSection?: SectionModel) => {
    const updateField = (name: string, value: string | number | boolean, fromOptions = false) => {
        if (activeSection) {
            if (fromOptions) {
                activeSection.options[ name ] = value;
            } else {
                // @ts-ignore
                // eslint-disable-next-line no-param-reassign
                activeSection[ name ] = value;
            }
            activeSection.saveData();
        }
    };

    return { updateField };
};
