import React, { useEffect }      from "react";
import { useModalDialogWrapper } from "@geenee/builder/src/components/ModalDialogWrapper";
import envConfig                 from "@geenee/builder/src/lib/envConfig";
import { useInject }             from "@geenee/shared/src/hook/use-inject.hook";
import { Icon }                  from "@geenee/ui";
import { observer }              from "mobx-react";

const Iframe = observer(({ url }: { url: string }) => {
    const { BuilderState } = useInject();
    const {
        activeCodeAtom,
        activeMolecule
    } = BuilderState.$sceneState;

    // get the transpiled modules from the AppState

    const iframeRef = React.useRef<HTMLIFrameElement>(null);
    useEffect(() => {
        window.addEventListener("message", (event) => {
            if (event.data.type === "custom-code-editor:transpiled") {
                const { data } = event.data;
                // if (Object.keys(data).length) {
                // localStorage.setItem("transpiledModules", JSON.stringify(data));
                // }
            }
            if (event.data === "close") {
                window.parent.postMessage("close", "*");
            }
        });
    }, [ activeCodeAtom?.id ]);
    const setupMessaging = async () => {
        const json = await activeMolecule?.getCodeModuleJSON(activeCodeAtom)
            .then((res) => res);
        window.addEventListener("message", (event) => {
            if (event.data.type === "child:ready") {
                iframeRef.current?.contentWindow?.postMessage({
                    type: "parent:transpiledModules",
                    data: json
                }, envConfig.CUSTOM_CODE_EDITOR_URL);
                iframeRef.current?.contentWindow?.postMessage({
                    type: "parent:snippet",
                    data: json
                }, envConfig.CUSTOM_CODE_EDITOR_URL);
            }
        });
    };
    useEffect(() => {
        if (iframeRef.current) {
            setupMessaging();
        }
    }, [ iframeRef.current ]);

    return (
        <iframe
            style={ {
                width:        "95vw",
                height:       "95vh",
                border:       "none",
                borderRadius: "16px"
            } }
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen; microphone; camera: user; camera: environment;"
            sameOrigin
            ref={ iframeRef }
            same-origin="true"
            frameBorder="0"
            // add all popular attrs

            src={ url }
        />

    );
});
export const useModalWithIframe = ({
    url,
    onClose
}: { url: string, onClose?: () => void }) => {
    const [ showModal, hideModal ] = useModalDialogWrapper(
        <Iframe url={ url } />,
        { closeTriggerElement: <Icon color="shade-4" name="closeRound" size={ 24 } /> },
        undefined,
        "off",
        onClose
    );
    return [ showModal, hideModal ];
};
