import React, { memo }                    from "react";
import { cn, Description, Icon, Wrapper } from "@geenee/ui";
import './styles.scss';

const className = cn('empty-search-page');
export const EmptySearchPage = memo(() => (
    <Wrapper
        className={ className() }
        maxWidth="352px"
        align="center"
        padding="md"
    >
        <Icon size={ 48 } name="searchQuestion" margin="sm" color="shade-4" />
        <Description size="lg" margin="xs">
            No results found for your search
        </Description>
        <Description size="lg" color="shade-4">
            Please try another search for your request or upload a new asset
        </Description>
    </Wrapper>
));
