import React                                from 'react';
import { AnimatedWrapper, Modal, useModal } from '@geenee/ui';
import { GifLibrary }                       from '~/module/gif-library';

export const useGifLibraryModal = (onImport?: (asset: File) => void) => {
    const [ showModal, hideModal ] = useModal((transformGroupProps: JSX.IntrinsicAttributes) => (
        <Modal
            className='global-modal'
            onHideModal={ () => {
                hideModal();
            } }
            { ...transformGroupProps }
        >
            <AnimatedWrapper manual={ false }>
                <GifLibrary
                    onClose={ hideModal }
                    onImport={ (v) => {
                        if (onImport) {
                            onImport(v);
                        }
                        hideModal();
                    } }
                />
            </AnimatedWrapper>
        </Modal>
    ), [ onImport ]);
    return { showModal, hideModal };
};
