/**
 * This is a TypeScript React component for rendering a collapsible navigation list with clickable
 * items, using atoms and molecules from the GeeClient Kit library.
 * @property {string} activeItemId - `activeItemId` is a string representing the ID of the currently
 * active item in the navigation list.
 * @property onItemClick - `onItemClick` is a function that takes an `ExperienceModel` object and an
 * optional `sectionId` string as arguments and is called when a navigation item is clicked. It is used
 * to handle the click event and perform some action, such as updating the active item or navigating to
 * a different page
 * @property {CombinationMoleculeSchemaType} moleculeContext - `moleculeContext` is an object that
 * contains information about the current molecule that the `NavigationListAtomComponent` is a part of.
 * It includes the `atoms` array, which is an array of `AtomSchemaType` objects that represent the
 * individual atoms within the molecule. It also includes any
 * @property {AtomSchemaType} atoms - `atoms` is a property of type `AtomSchemaType` that is passed to
 * the `NavigationListAtomComponent`. It is an array of objects that represent individual atoms (UI
 * components) that make up the navigation list. Each object has properties such as `id`, `label`,
 * `icon`,
 * @property size - The `size` property is a type of `SizeExtensionType` which can have one of the
 * following values: `'s'`, `'m'`, `'l'`, or `'xl'`. It is used to determine the size of the
 * `NavigationListAtomComponent`.
 */
// eslint-disable @nrwl/nx/enforce-module-boundaries
import React, { FC, useMemo, useState }                          from 'react';
import { NavigationItemAtomComponent }                           from '@geenee/geeclient-kit/src/lib/component/atom/navigation-item/navitaion-item.atom.component';
import { AtomContext }                                           from "@geenee/geeclient-kit/src/lib/context/atom-context";
import { withMoleculeContext }                                   from "@geenee/geeclient-kit/src/lib/hoc/withMoleculeContext";
import { SizeExtensionType }                                     from "@geenee/geeclient-kit/src/lib/type/_atom-option-type";
import { compose }                                               from "@geenee/geeclient-kit/src/lib/util/compose";
import { withProps }                                             from "@geenee/geeclient-kit/src/lib/util/withProps";
import { ExperienceModel }                                       from '@geenee/shared';
import { AtomSchemaType, NavigationListAtomOptionsType }         from "@geenee/shared/type/atom.type";
import { CombinationMoleculeSchemaType, SiteMoleculeSchemaType } from '../../../type/section-molecule';
import { CollapseAtomComponent }                                 from '../collapse/collapse.view';
import { getPropsFromOwner }                                     from "./util";

/**
 * This is a TypeScript React component props type for a navigation list atom, which includes the
 * active item ID, item click handler, molecule context, atom schema, and size extension.
 * @property {string} activeItemId - This property represents the ID of the currently active item in
 * the navigation list.
 * @property onItemClick - This is a function that takes in two parameters: an item of type
 * ExperienceModel and an optional sectionId of type string. It is called when an item in the
 * navigation list is clicked and is used to handle the click event.
 * @property {CombinationMoleculeSchemaType} moleculeContext - It is a property of type
 * `CombinationMoleculeSchemaType` which is likely a data structure that contains information about the
 * context or environment in which the `NavigationListAtomComponent` is being used. This could include
 * things like the current user, device type, or other relevant contextual information.
 * @property {AtomSchemaType} atoms - The `atoms` property is of type `AtomSchemaType` and is a set of
 * predefined styles and properties that can be applied to the navigation list component. It allows for
 * consistent styling across the application.
 * @property size - The `size` property is a string literal type that specifies the size of the
 * component. It can have one of the following values: `'s'`, `'m'`, `'l'`, or `'xl'`. This property is
 * used to control the size of the component and its child elements.
 */
export type NavigationListAtomComponentPropsType = {
  activeItemId: string,
  onItemClick: (item: ExperienceModel, sectionId?: string) => void;
  moleculeContext: CombinationMoleculeSchemaType,
  atoms: AtomSchemaType
  size: SizeExtensionType<'s'| 'm' | 'l' | 'xl'>
};

/**
 * This is a TypeScript React component that renders a collapsible navigation list view with clickable
 * items.
 *
 * @param  - `NavigationListView`: the name of the functional component being exported
 */
export const NavigationListView: FC<NavigationListAtomComponentPropsType> = ({
    moleculeContext,
    activeItemId,
    onItemClick,
    size
}) => (
    <CollapseAtomComponent size={ size }>
        {
            moleculeContext.atoms.map((el, index) => (
                <AtomContext.Provider key={ index } value={ el }>
                    <NavigationItemAtomComponent
                        key={ `nav-item-atom-comp_${ index }` }
                        isActive={ el.id === activeItemId }
                        onClick={ () => onItemClick(el) }
                        shouldScaleDown={ false }
                    />
                </AtomContext.Provider>
            ))
        }
    </CollapseAtomComponent>
);
/* `const enhance` is a higher-order component (HOC) that is composed of two other HOCs:
`withMoleculeContext` and `withProps`. */
const enhance = compose(
    withMoleculeContext,
    withProps(
        ({ moleculeContext, ...extendProps }: {
      moleculeContext: SiteMoleculeSchemaType<NavigationListAtomOptionsType>;
    }) => getPropsFromOwner(moleculeContext.atoms, extendProps)
    )
);

export const NavigationListAtomComponent = enhance(NavigationListView);
