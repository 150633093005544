import * as React         from 'react';
import { FC }             from 'react';
import { AtomContext }    from "@geenee/geeclient-kit/src/lib/context/atom-context";
import { AtomSchemaType } from "@geenee/shared/type/atom.type";
import { observer }       from 'mobx-react';
import { $Diff }          from 'utility-types';

type DiffPropsType = {
  atomContext: AtomSchemaType;
};

export function withAtomContext<T extends DiffPropsType>(Component: FC<T>) {
    return React.memo(
        observer((props: $Diff<T, DiffPropsType>) => {
            const Elem: FC<T> = Component;
            return (
                <AtomContext.Consumer>
                    { (context) => <Elem { ...(props as T) } atomContext={ context } /> }
                </AtomContext.Consumer>
            );
        })
    );
}
