import React, { Fragment, useContext } from 'react';
import { useHistory }                  from "@geenee/common";
import { useInject }                   from '@geenee/shared';
import { observer }                    from 'mobx-react';
import { DowngradeDataProps }          from '@geenee/builder/src/@custom_types/types';
import StyledButton                    from '@geenee/builder/src/components/common/Button';
import DowngradeModal                  from '@geenee/builder/src/components/modals/Downgrade';
import { stripeState }                 from '@geenee/builder/src/core/state/stripe.state';
import { teamState }                   from '@geenee/builder/src/core/state/team.state';
import ModalUpdaterContext             from '@geenee/builder/src/lib/ModalUpdaterContext';
import { TableInfo }                   from '@geenee/builder/src/pages/AllPlansPage';

interface Props {
  planNames: string[];
  tableInfo: TableInfo;
  yearlyCycle: boolean;
  showPaymentModal: (priceId: string) => void;
}

export const ButtonsRow = observer(({
    planNames,
    tableInfo,
    yearlyCycle,
    showPaymentModal
}: Props) => {
    const { AppState } = useInject();
    const team = Array.from(teamState.userStore.values());
    const { invites } = teamState;
    const history = useHistory();
    const setModalOptions = useContext(ModalUpdaterContext);
    // const maxProjectsReached = isMaxProjectsReached(AppState.projectsRegistry, parentPlan.params);

    const onClickDowngrade = (planName: string) => {
        const tablePlan = tableInfo[ planName ] && tableInfo[ planName ][ yearlyCycle ? "year" : "month" ];
        const planId = tablePlan.id;
        const parentPlan = stripeState.plans[ tablePlan.parent_plan_id || "" ];
        if (!parentPlan) {
            console.error("Plan is not found");
            return;
        }
        // -1 for the owner itself
        const maxMembersPerPlan = parentPlan.params.seats - 1;
        const maxMembersReached = team.length + invites.length > maxMembersPerPlan;

        const preDowngradeData = {} as DowngradeDataProps;
        if (maxMembersReached) {
            preDowngradeData.members = [ ...team, ...invites ];
            preDowngradeData.maxMembers = maxMembersPerPlan;
        }

        setModalOptions({
            component: DowngradeModal,
            props:     {
                priceId: planId,
                preDowngradeData
            }
        });
    };

    return (
        <tr className="buttons-row">
            <td />
            { planNames.map((planName, index) => {
                const tablePlan = tableInfo[ planName ] && tableInfo[ planName ][ yearlyCycle ? "year" : "month" ];

                const filler = <td className="plan-filler" />;
                if (!tablePlan) {
                    return (
                        <Fragment key={ planName }>
                            <td className={ `${ planName }-plan last-plan-cell` }>
                                <StyledButton
                                    $gradient
                                    $width="180px"
                                    $height="40px"
                                    label="Contact Us"
                                    onClick={ () => history.push("/contact", { category: "sales" }) }
                                />
                            </td>
                        </Fragment>
                    );
                }

                /* No matter what, we always display the upgrade button on better plans */
                if (index > planNames.indexOf(stripeState.plan_title)) {
                    return (
                        <Fragment key={ planName }>
                            <td className={ `${ planName }-plan last-plan-cell` }>
                                <StyledButton
                                    $width="180px"
                                    $height="40px"
                                    label="SELECT"
                                    onClick={ () => showPaymentModal(tablePlan.id) }
                                />
                            </td>
                            { index < 2 && filler }
                        </Fragment>
                    );
                }

                if (stripeState.plan_price_id === tablePlan.id) {
                    return (
                        <Fragment key={ planName }>
                            <td className={ `${ planName }-plan last-plan-cell` }>
                                <StyledButton
                                    $gradient
                                    $width="180px"
                                    $height="40px"
                                    label="MANAGE"
                                    onClick={ () => history.push("/your-plan") }
                                />
                            </td>
                            { index < 2 && filler }
                        </Fragment>
                    );
                }

                if (index === planNames.indexOf(stripeState.plan_title)) {
                    return (
                        <Fragment key={ planName }>
                            <td className={ `${ planName }-plan last-plan-cell` }>
                                <StyledButton
                                    $width="180px"
                                    $height="40px"
                                    label="SELECT"
                                    onClick={ () => showPaymentModal(tablePlan.id) }
                                />
                            </td>
                            { index < 2 && filler }
                        </Fragment>
                    );
                }

                return (
                    <Fragment key={ tablePlan.id }>
                        <td className={ `${ planName }-plan last-plan-cell` }>
                            <StyledButton
                                $width="180px"
                                $height="40px"
                                label="SELECT"
                                onClick={ () => onClickDowngrade(planName) }
                            />
                        </td>
                        { index < 2 && filler }
                    </Fragment>
                );
            }) }
        </tr>
    );
});
