import React, { ChangeEvent, useState } from 'react';
import { useHistory }                   from "@geenee/common";
import { LoadingSpinnerCentered }       from '@geenee/shared/src/magellan/components/LoadingSpinner';
import {
    Description, FormItem, Input, InputTable, InputTableItem,
    Title, Wrapper
}                          from '@geenee/ui';
import forIn                from 'lodash-es/forIn';
import isEmpty              from 'lodash-es/isEmpty';
import omit                 from 'lodash-es/omit';
import { runInAction }      from 'mobx';
import { observer }         from 'mobx-react';
import NextArrow            from '@geenee/builder/src/asset/icons/NextArrow';
import RaisedButton         from '@geenee/builder/src/components/common/Button/WithIcon';
import { Link }             from '@geenee/builder/src/components/Link';
import { ContentWrapper }   from '@geenee/builder/src/components/modals/PostPublishingNFT/styles';
import { GradientWrapper }  from '@geenee/builder/src/components/modals/ReenterNFTWalletPassword/styles';
import { WithdrawSuccess }  from '@geenee/builder/src/components/modals/Withdraw/screens/WithdrawSuccess';
import { useBuilderInject } from '@geenee/builder/src/core/hook/use-builder-inject';
import { nftState }         from '@geenee/builder/src/core/state/nft.state';
import { TOAST_ERROR }      from '@geenee/builder/src/lib/constants';

interface Props {
  from_wallet: string;
  close: () => void;
  // eslint-disable-next-line no-empty-pattern
  setModal: ({}) => void;
}

type ErrorsProps = {
  password: any;
  title?: any;
  subtitle?: any;
  category?: any;
  description?: any;
  price?: any;
}

export const GetYourFunds = observer(({
    close,
    setModal,
    from_wallet
}: Props) => {
    const history = useHistory();
    const { BuilderState } = useBuilderInject();
    const [ formData, setFormData ] = useState();
    // @ts-ignore
    const [ errors, setErrors ] = useState<ErrorsProps>({});
    const [ isLoading, setIsLoading ] = useState(false);

    const validate = (inputValue: string | number, name: string, validation: { required?: boolean; minLength?: number }) => {
        let error: any = {};
        forIn(validation, (value, key) => {
            if (typeof inputValue !== "number" && key === "required" && isEmpty(inputValue)) {
                error = { [ name ]: { type: "required" } };
                return;
            }
            if (typeof inputValue !== "number" && key === "minLength" && inputValue.length < value!) {
                error = { [ name ]: { type: "minLength" } };
            }
        });

        if (!isEmpty(error)) {
            setErrors({ ...errors, ...error });
        } else if (errors[ name ]) {
            // @ts-ignore
            const prunedErrors = omit(errors, [ [ name ] ]);
            // @ts-ignore
            setErrors(prunedErrors);
        }
    };

    const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {
            value,
            name
        } = e.currentTarget;
        // @ts-ignore
        setFormData({
            ...formData,
            [ name ]: value
        });

        const validation: any = {};
        switch (name) {
            case "address":
                validation.required = true;
                break;
            case "password":
                validation.required = true;
                validation.minLength = 6;
                // eslint-disable-next-line no-fallthrough
            default:
                validation.required = true;
                break;
        }
        validate(value, name, validation);
    };

    const onClickEnter = async () => {
        if (formData && Object.keys(formData).length === 3 && !Object.keys(errors).length) {
            setIsLoading(true);
            try {
                // @ts-ignore
                await nftState.withdraw(from_wallet, formData.address, formData.password, formData.price);
                setModal({ component: WithdrawSuccess });
            } catch (e: any) {
                runInAction(() => {
                    BuilderState.toast = {
                        severity: TOAST_ERROR,
                        // @ts-ignore
                        detail:   e.message,
                        summary:  ""
                    };
                });
            }
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <ContentWrapper>
                <LoadingSpinnerCentered />
            </ContentWrapper>
        );
    }
    return (
        <ContentWrapper>
            <Wrapper align="center">
                <Title size="xxl" align="center" margin="lg">
                    Get your funds
                </Title>
                <Wrapper maxWidth="490px" align="center">
                    <Description size="sm" align="center" margin="md">
                        Transfer your funds to your crypto wallet.
                        { " " }
                        <Link onClick={ () => {
                            close();
                            history.push("/getting-started/14");
                        } }
                        >
                            Need help?
                        </Link>
                    </Description>
                </Wrapper>
                <GradientWrapper maxWidth="489px" align="center" margin="xl">
                    <InputTable>
                        <InputTableItem label="Wallet Address">
                            <FormItem
                                autoFocusDetect
                                error={
                                    // @ts-ignore
                                    (errors?.address?.type === "required" && (
                                        "This field is required"
                                    ))
                                }
                            >
                                <Input
                                    size="md"
                                    fullWidth
                                    name="address"
                                    // @ts-ignore
                                    value={ formData?.address }
                                    placeholder="Wallet Address"
                                    onChange={ handleTextChange }
                                />
                            </FormItem>
                        </InputTableItem>
                        <InputTableItem label="Wallet Password">
                            <FormItem
                                autoFocusDetect
                                error={
                                    (errors?.password?.type === "required" && (
                                        "This field is required"
                                    )) || (
                                        errors?.password?.type === "minLength" && (
                                            "Password should be at least 6 characters long"
                                        )
                                    )
                                }
                            >
                                <Input
                                    size="md"
                                    fullWidth
                                    name="password"
                                    type="password"
                                    // @ts-ignore
                                    value={ formData?.password }
                                    placeholder="Wallet password"
                                    onChange={ handleTextChange }
                                />
                            </FormItem>
                        </InputTableItem>
                        <InputTableItem label="Withdrawal Price">
                            <FormItem
                                autoFocusDetect
                                error={
                                    (errors?.price?.type === "required" && (
                                        "This field is required"
                                    ))
                                }
                            >
                                <Input
                                    size="md"
                                    fullWidth
                                    name="price"
                                    // @ts-ignore
                                    value={ formData?.price }
                                    placeholder="1"
                                    before="eth"
                                    onChange={ handleTextChange }
                                />
                            </FormItem>
                        </InputTableItem>
                    </InputTable>
                </GradientWrapper>
                <Wrapper row fullWidth align="center">
                    <RaisedButton
                        $gradient
                        $padding="0 23px"
                        $justify="space-between"
                        $width="141px"
                        $height="61px"
                        onClick={ () => onClickEnter() }
                        text="NEXT"
                        iconElement={ (
                            <NextArrow width="30" height="30" />
                        ) }
                    />
                </Wrapper>
            </Wrapper>
        </ContentWrapper>
    );
});
