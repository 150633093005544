import * as React                                                from "react";
import { ContextMenu, ContextMenuTrigger, MenuItem }             from "react-contextmenu";
import type { IExplorerExtensibilityGroup }                      from "@babylonjs/core/Debug/debugLayer";
import type { Nullable }                                         from "@babylonjs/core/types";
import { faBan, faCompress, faExpandArrowsAlt, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon }                                       from "@fortawesome/react-fontawesome";
import { Collapse, Description, Icon  }                          from '@geenee/ui';
import { Tools }                                                 from "../../tools";
import type { GlobalState }                                      from "../globalState";
import { TreeItemSelectableComponent }                           from "./treeItemSelectableComponent";

interface ITreeItemExpandableHeaderComponentProps {
    isExpanded: boolean;
    label: string;
    onClick: () => void;
    onExpandAll: (expand: boolean) => void;
}

// @ts-ignore
class TreeItemExpandableHeaderComponent extends React.Component<ITreeItemExpandableHeaderComponentProps> {
    constructor(props: ITreeItemExpandableHeaderComponentProps) {
        super(props);
    }

    expandAll() {
        this.props.onExpandAll(!this.props.isExpanded);
    }

    render() {
        const chevron = this.props.isExpanded ? <FontAwesomeIcon icon={ faMinus } color="black" /> : <FontAwesomeIcon icon={ faPlus } color="black" />;
        const expandAll = this.props.isExpanded ? <FontAwesomeIcon icon={ faCompress } color="black" /> : <FontAwesomeIcon icon={ faExpandArrowsAlt } color="black" />;

        return (
            <div className="expandableHeader">
                <div className="text">
                    <div className="arrow icon" onClick={ () => this.props.onClick() }>
                        { chevron }
                    </div>
                    <Description size="sm" color="pink" weight="bold">
                        { this.props.label }
                    </Description>
                </div>
                <div className="expandAll icon" onClick={ () => this.expandAll() } title={ this.props.isExpanded ? "Collapse all" : "Expand all" }>
                    { expandAll }
                </div>
            </div>
        );
    }
}

interface ITreeItemRootHeaderComponentProps {
    label: string;
}

class TreeItemRootHeaderComponent extends React.Component<ITreeItemRootHeaderComponentProps> {
    constructor(props: ITreeItemRootHeaderComponentProps) {
        super(props);
    }

    render() {
        return (
            <div className="expandableHeader">
                <div className="text">
                    <div className="arrow icon">
                        <FontAwesomeIcon icon={ faBan } color="black" />
                    </div>
                    <div className="text-value">{ this.props.label }</div>
                </div>
            </div>
        );
    }
}

export interface ITreeItemComponentProps {
    items?: Nullable<any[]>;
    label: string;
    offset: number;
    filter: Nullable<string>;
    forceSubitems?: boolean;
    globalState: GlobalState;
    entity?: any;
    selectedEntity: any;
    extensibilityGroups?: IExplorerExtensibilityGroup[];
    contextMenuItems?: { label: string; action: () => void }[];
}

export class TreeItemComponent extends React.Component<ITreeItemComponentProps, { isExpanded: boolean; mustExpand: boolean }> {
    static _ContextMenuUniqueIdGenerator = 0;

    constructor(props: ITreeItemComponentProps) {
        super(props);

        this.state = { isExpanded: false, mustExpand: false };
    }

    switchExpandedState(): void {
        this.setState({ isExpanded: !this.state.isExpanded, mustExpand: false });
    }

    shouldComponentUpdate(nextProps: ITreeItemComponentProps, nextState: { isExpanded: boolean }) {
        if (!nextState.isExpanded && this.state.isExpanded) {
            return true;
        }

        const { items } = nextProps;

        if (items && items.length) {
            if (nextProps.selectedEntity) {
                for (const item of items) {
                    if (Tools.LookForItem(item, nextProps.selectedEntity)) {
                        nextState.isExpanded = true;
                        return true;
                    }
                }
            }
        }

        return true;
    }

    expandAll(expand: boolean) {
        this.setState({ isExpanded: expand, mustExpand: expand });
    }

    renderContextMenu() {
        if (!this.props.contextMenuItems) {
            TreeItemComponent._ContextMenuUniqueIdGenerator++;
            return null;
        }

        return (
        // @ts-ignore
            <ContextMenu id={ `contextmenu#${ TreeItemComponent._ContextMenuUniqueIdGenerator++ }` } className="context-menu">
                { this.props.contextMenuItems.map((c) => (
                    // @ts-ignore
                    <MenuItem onClick={ () => c.action() } key={ c.label }>
                        { c.label }
                    </MenuItem>
                )) }
            </ContextMenu>
        );
    }

    render() {
        let { items } = this.props;

        const marginStyle = { paddingLeft: `${ 10 * (this.props.offset + 0.5) }px` };

        if (!items) {
            if (this.props.forceSubitems) {
                items = [];
            } else {
                return (
                    <div className="groupContainer" style={ marginStyle }>
                        <div>{ this.props.label }</div>
                    </div>
                );
            }
        }

        if (!items.length) {
        // @ts-ignore
            return (
                <ContextMenuTrigger id={ `contextmenu#${ TreeItemComponent._ContextMenuUniqueIdGenerator }` }>
                    { this.renderContextMenu() }
                </ContextMenuTrigger>
            );
            return (
                <div className="groupContainer" style={ marginStyle }>
                    { /* @ts-ignore */ }
                    <ContextMenuTrigger id={ `contextmenu#${ TreeItemComponent._ContextMenuUniqueIdGenerator }` }>
                        { this.renderContextMenu() }
                        <TreeItemRootHeaderComponent label={ this.props.label } />
                    </ContextMenuTrigger>
                </div>
            );
        }

        const sortedItems = Tools.SortAndFilter(null, items);

        // @ts-ignore
        return (
            <ContextMenuTrigger id={ `contextmenu#${ TreeItemComponent._ContextMenuUniqueIdGenerator }` }>
                { this.renderContextMenu() }
                <Collapse
                    icon={ (
                        <Icon margin="xxs" color="shade-4" name="sdk" />
                    ) }
                    iconCollapsedProps={ {
                        name:   'arrowRightSmall',
                        color:  'shade-4',
                        margin: 'xxs',
                        size:   16
                    } }
                    panelContent={ (
                        <Description color="shade-1" size='sm'>{ this.props.label }</Description>
                    ) }
                    panelWrapperProps={ {
                        valign:  'center',
                        padding: 'xs'
                    } }
                >
                    { sortedItems.map((item) => (
                        <TreeItemSelectableComponent
                            mustExpand={ this.state.mustExpand }
                            extensibilityGroups={ this.props.extensibilityGroups }
                            key={ item.uniqueId !== undefined && item.uniqueId !== null ? item.uniqueId : item.name }
                            offset={ this.props.offset + 1 }
                            selectedEntity={ this.props.selectedEntity }
                            entity={ item }
                            globalState={ this.props.globalState }
                            filter={ this.props.filter }
                        />
                    )) }
                </Collapse>
            </ContextMenuTrigger>
        );
    }
}
