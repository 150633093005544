import { initProps as iconProps } from '@geenee/geeclient-kit/src/lib/component/atom/icon/util';
import { initProps as linkProps } from '@geenee/geeclient-kit/src/lib/component/atom/link/util';
import { CollapseAtomOptType }    from '@geenee/geeclient-kit/src/lib/type/_atom-option-type';

export const initProps: CollapseAtomOptType = {
    ...iconProps,
    ...linkProps,
    collapse_items_space_between:         'default',
    collapse_icon_position:               'bottom',
    collapse_title_text:                  '',
    collapse_title_revert:                true,
    collapse_items_default_expanded:      false,
    collapse_items_default_visible_count: 3,
    collapse_items_align:                 'right',
    collapse_color_text:                  'inherit',
    collapse_color_text_alpha:            1,
    collapse_color_text_type:             'auto',
    collapse_color_text_variation:        'harmonic',
    collapse_color_text_custom:           '',
    collapse_color_bg:                    'inherit',
    collapse_color_bg_alpha:              1,
    collapse_color_bg_type:               'auto',
    collapse_color_bg_variation:          'harmonic',
    collapse_color_bg_custom:             '',
    collapse_color_border:                'inherit',
    collapse_color_border_alpha:          1,
    collapse_color_border_type:           'auto',
    collapse_color_border_variation:      'harmonic',
    collapse_color_border_custom:         '',

    collapse_icon_color_text:             'inherit',
    collapse_icon_color_text_alpha:       1,
    collapse_icon_color_text_type:        'auto',
    collapse_icon_color_text_variation:   'harmonic',
    collapse_icon_color_text_custom:      '',
    collapse_icon_color_bg:               'inherit',
    collapse_icon_color_bg_alpha:         1,
    collapse_icon_color_bg_type:          'auto',
    collapse_icon_color_bg_variation:     'harmonic',
    collapse_icon_color_bg_custom:        '',
    collapse_icon_color_border:           'inherit',
    collapse_icon_color_border_alpha:     1,
    collapse_icon_color_border_type:      'auto',
    collapse_icon_color_border_variation: 'harmonic',
    collapse_icon_color_border_custom:    '',
    collapse_icon_blur_radius:            's',
    collapse_icon_blur_radius_custom:     0,
    collapse_icon_corner_radius:          '100',
    collapse_icon_corner_radius_custom:   100,

    collapse_icon_type:             'geenee',
    collapse_icon_switch:           true,
    collapse_icon_size:             'm',
    collapse_icon_style:            'blur',
    collapse_icon_nucleo:           '',
    collapse_icon_geenee_expand:    'arrowTop',
    collapse_icon_geenee_collapsed: 'arrowDown',

    collapse_scroll_height: 'off'
};

export const getPropsFromOwner = (
    options: CollapseAtomOptType,
    extendProps: Object = {}
) => ({
    collapse_items_space_between:         options.collapse_items_space_between,
    collapse_icon_position:               options.collapse_icon_position,
    collapse_title_text:                  options.collapse_title_text,
    collapse_title_revert:                options.collapse_title_revert,
    collapse_items_default_expanded:      options.collapse_items_default_expanded,
    collapse_items_default_visible_count: options.collapse_items_default_visible_count,
    collapse_items_align:                 options.collapse_items_align,
    collapse_color_text:                  options.collapse_color_text,
    collapse_color_text_alpha:            options.collapse_color_text_alpha,
    collapse_color_text_type:             options.collapse_color_text_type,
    collapse_color_text_variation:        options.collapse_color_text_variation,
    collapse_color_text_custom:           options.collapse_color_text_custom,
    collapse_color_bg:                    options.collapse_color_bg,
    collapse_color_bg_alpha:              options.collapse_color_bg_alpha,
    collapse_color_bg_type:               options.collapse_color_bg_type,
    collapse_color_bg_variation:          options.collapse_color_bg_variation,
    collapse_color_bg_custom:             options.collapse_color_bg_custom,
    collapse_color_border:                options.collapse_color_border,
    collapse_color_border_alpha:          options.collapse_color_border_alpha,
    collapse_color_border_type:           options.collapse_color_border_type,
    collapse_color_border_variation:      options.collapse_color_border_variation,
    collapse_color_border_custom:         options.collapse_color_border_custom,

    collapse_icon_color_text:             options.collapse_icon_color_text,
    collapse_icon_color_text_alpha:       options.collapse_icon_color_text_alpha,
    collapse_icon_color_text_type:        options.collapse_icon_color_text_type,
    collapse_icon_color_text_variation:   options.collapse_icon_color_text_variation,
    collapse_icon_color_text_custom:      options.collapse_icon_color_text_custom,
    collapse_icon_color_bg:               options.collapse_icon_color_bg,
    collapse_icon_color_bg_alpha:         options.collapse_icon_color_bg_alpha,
    collapse_icon_color_bg_type:          options.collapse_icon_color_bg_type,
    collapse_icon_color_bg_variation:     options.collapse_icon_color_bg_variation,
    collapse_icon_color_bg_custom:        options.collapse_icon_color_bg_custom,
    collapse_icon_color_border:           options.collapse_icon_color_border,
    collapse_icon_color_border_alpha:     options.collapse_icon_color_border_alpha,
    collapse_icon_color_border_type:      options.collapse_icon_color_border_type,
    collapse_icon_color_border_variation: options.collapse_icon_color_border_variation,
    collapse_icon_color_border_custom:    options.collapse_icon_color_border_custom,
    collapse_icon_blur_radius:            options.collapse_icon_blur_radius,
    collapse_icon_blur_radius_custom:     options.collapse_icon_blur_radius_custom,
    collapse_icon_corner_radius:          options.collapse_icon_corner_radius,
    collapse_icon_corner_radius_custom:   options.collapse_icon_corner_radius_custom,

    collapse_icon_type:             options.collapse_icon_type,
    collapse_icon_switch:           options.collapse_icon_switch,
    collapse_icon_size:             options.collapse_icon_size,
    collapse_icon_style:            options.collapse_icon_style,
    collapse_icon_nucleo:           options.collapse_icon_nucleo,
    collapse_icon_geenee_expand:    options.collapse_icon_geenee_expand,
    collapse_icon_geenee_collapsed: options.collapse_icon_geenee_collapsed,
    collapse_scroll_height:         options.collapse_scroll_height,
    ...extendProps
});
