import styled from 'styled-components';

export const Wrapper = styled.span`
@keyframes spin{
  0%{
    transform:scale(1) rotate(0deg);
  }
  50%{
    transform:scale(0.8) rotate(360deg);
  }
  100%{
    transform:scale(1) rotate(720deg);
  }
}

  svg {
    animation:2s cubic-bezier(0.63,0.045,0.34,1) 0s normal none infinite spin;
    transform-origin: center;
  }
`;
