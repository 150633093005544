import styled       from 'styled-components';
import StyledButton from '@geenee/builder/src/components/common/Button';

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 0 25px;
  background: #FFFFFF;
  border-radius: 10px 10px 0px 0px;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));

  ${ StyledButton } {
        background-color: ${ ({ theme: { secondaryColors } }) => secondaryColors.green60 } !important;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25) !important;
        color: #000 !important;

        &:hover {
            background-color: ${ ({ theme: { secondaryColors } }) => secondaryColors.green90 } !important;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25) inset 0px 1px 1px
                rgba(0, 0, 0, 0.25) !important;
        }
    }
`;

export const ActivityContainer = styled.div`
  width: 100%;
  padding: 24px 25px 40px;
  background: rgba(202, 202, 202, 0.19);
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 0px 0px 10px 10px;
`;

export const ActivityWrapper = styled.div`
  width: 100%;

  .one-ui-wrapper--direction-row {
    & > .one-ui-description:first-child {
      flex: 0 0 95px;
    }
  }
`;

export const HighlightContainer = styled.div`
  color: #FF008A;
  font-family: 'Euclid Circular A';
`;
