import { AttachmentFactory }               from "@geenee/shared/src/magellan/factory/attachment.factory";
import { AttachmentType }                  from "@geenee/shared/type/attachment.type";
import { inject, injectable }              from "inversify";
import { action, observable }              from "mobx";
import { BasicSchemaType, CustomCodeType } from "../../../type/shared.type";
import { BROWSER_MODE }                    from "../../util/constants";
import { processCode }                     from "../../util/processCode";

@injectable()
export class BaseEntityModel implements BasicSchemaType {
  @observable id!: BasicSchemaType["id"] = "";
  @observable inserted_at!: BasicSchemaType["inserted_at"] = "";
  @observable updated_at!: BasicSchemaType["updated_at"] = "";
  @observable custom_code: BasicSchemaType["custom_code"] = {
      code:    "",
      options: {}
  };
  @observable loading: BasicSchemaType["loading"] = false;

  @inject("<AttachmentFactory>")
      attachmentFactory!: AttachmentFactory;

  @observable options: BasicSchemaType["options"] = { order: 0 };

  @action
  initCode() {
      // @ts-ignore
      if (this.custom_code && this.custom_code.code && process.env.ENV_GEENEE_APP !== "BUILDER" && BROWSER_MODE) {
          const script = processCode(this.custom_code.code);
          document.body.appendChild(script as unknown as HTMLScriptElement);
      }

      this.initAnalytics();
  }

  @action
  initAnalytics() {
      if (this.custom_code && this.custom_code.options
      // @ts-ignore
      && this.custom_code.options.analytics && process.env.ENV_GEENEE_APP !== "BUILDER" && BROWSER_MODE) {
          const script = processCode(this.custom_code.options.analytics);
          document.body.appendChild(script as unknown as HTMLScriptElement);
      }
  }

  @action
      updateCustomCode = (object: { [key: string]: any, custom_code: CustomCodeType }) => {
          this.custom_code = {
              ...this.custom_code,
              code:    object?.custom_code?.code,
              options: {
                  ...this.custom_code?.options || {},
                  ...(object?.custom_code?.options || {})
              }
          };
      };

  createAttachmentModel = async (
      attachmentSchema: Partial<AttachmentType> & { file?: File },
      uploadAttachment: (
      attachment: Partial<AttachmentType>,
      onProgressChange?: (progress: number) => void
    ) => Promise<{ attachment: AttachmentType }>
  ) => {
      const { attachment: uploadedAttachmentSchema } = await uploadAttachment(attachmentSchema);
      const attachmentModel = this.attachmentFactory.create(uploadedAttachmentSchema);
      attachmentModel.parentProjectEditable?.saveData();
      return attachmentModel;
  };

    @action
    setLoading = (value: boolean) => {
      this.loading = value;
  };
}
