import React                                   from 'react';
import HintWebArHosting                        from '@geenee/builder/src/asset/icons/HintWebArHosting';
import Hint                                    from '@geenee/builder/src/components/common/Hint';
import { UnderlinedTableRow, WithHintWrapper } from '@geenee/builder/src/pages/AllPlansPage/styles';

export function Hosting() {
    return (
        <UnderlinedTableRow>
            <td>
                <WithHintWrapper>
                    <span>Hosting per plan</span>
                    <Hint
                        title='WebAR Hosting'
                        text='AR optimized for the web at the best quality and speed. Purchase additional views on demand.'
                        iconElement={ <HintWebArHosting /> }
                    />
                </WithHintWrapper>
            </td>
            <td className='basic-plan'>1K Views / Month</td>
            <td className='plan-filler' />
            <td className='studio-plan'>500K Views / Month</td>
            <td className='plan-filler' />
            <td className='pro-plan'>1M Views / Month</td>
        </UnderlinedTableRow>
    );
}
