import React, { useCallback, useEffect }    from 'react';
import { useDrop }                          from 'react-dnd';
import { NativeTypes }                      from 'react-dnd-html5-backend';
import { ErrorOption }                      from 'react-hook-form';
import { Analytics }                        from '@geenee/analytics';
import { DropRootContainer }                from '@geenee/builder/src/components/DropWrapper/root-container';
import { hasExtension }                     from '@geenee/builder/src/components/DropWrapper/util';
import { useBuilderInject }                 from '@geenee/builder/src/core/hook/use-builder-inject';
import { INVALID_FILE_FORMAT, TOAST_ERROR } from '@geenee/builder/src/lib/constants';
import { DragAndDropOverlay }               from '@geenee/geeclient-kit/src/lib/component/scene/components/GeeneeARScene/components/DragAndDropOverlay';
import { compress }                         from '@geenee/shared/src/util/ImageService';
import { cn,  Wrapper }                     from '@geenee/ui';
import { isArray }                          from "lodash-es";
import { runInAction }                      from 'mobx';
import { observer }                         from 'mobx-react';
import { StyledComponent }                  from 'styled-components';
import { AttachmentFormatTypeUnion }        from '../../lib/constants';
import './styles.scss';

export type ListItemProps = {
    title?: string;
    id?: string;
    order?: number;
    type?: string;
    oldOrder?: number;
};

interface IProps {
    onDrop: (files: File[], monitor: any) => void;
    onDropError?: (error: ErrorOption) => void;
    children: React.ReactNode;
    disabled?: boolean;
    rootContainer?: StyledComponent<any, any> | React.ComponentType<any>;
    dimension?: [number, number];
    fileFormats?: AttachmentFormatTypeUnion[];
    maxFileSize?: number;
    skipFileSize?: boolean
    fullWidth?: boolean;
    fullHeight?: boolean;
    multiple?: boolean;
    style?: any;
    clearMessageKey?: string;
    clickable?: boolean;
    customErrorMessage?: string;
    overlayComponent?: React.ReactNode;
}

const className = cn('drop-wrapper');

export const DropWrapper = observer((props: IProps) => {
    const { BuilderState, container } = useBuilderInject();
    const analytics: Analytics = container.get('<Analytics>');

    const clearError = () => {
        if (BuilderState.toast.severity) {
            runInAction(() => {
                BuilderState.toast = { severity: '', detail: '', summary: '' };
            });
        }
    };
    useEffect(() => {
        clearError();
        return () => clearError();
    }, [ props.clearMessageKey ]);

    const onUpload = async (_item, monitor) => {
        let item = _item;
        if (_item instanceof File) {
            item = { files: [ _item ] };
        }
        const formats = props.fileFormats || [];

        if (props.onDropError) {
            props.onDropError({ message: '' });
        }

        if (formats.length) {
            const acceptedFiles: File[] = [];

            const accepted = item.files.filter((file) => hasExtension(file.name, formats));
            acceptedFiles.push(...accepted);
            if (!acceptedFiles.length) {
                runInAction(() => {
                    // temp solution. need to be refactored (mobx form)
                    if (props.onDropError) {
                        props.onDropError({
                            message: `Only ${ formats.join(
                                ', '
                            ) } files can be uploaded`
                        });
                    } else {
                        BuilderState.toast = {
                            severity: TOAST_ERROR,
                            detail:   props.customErrorMessage
                                ? props.customErrorMessage
                                : `Only ${ formats.join(
                                    ', '
                                ) } files \ncan be uploaded`,
                            summary: 'Invalid file format.'
                        };
                    }
                });

                const files = item.files.reduce((acc, curr) => {
                    const { name = '', type = '', size = '' } = curr;
                    acc.push({ name, type, size });
                    return acc;
                }, []);

                analytics.track(INVALID_FILE_FORMAT, { files });

                return;
            }

            if (!props.skipFileSize && props.maxFileSize && (acceptedFiles[ 0 ].size > props.maxFileSize)) {
                if (props.onDropError) {
                    props.onDropError({ message: `File size should be less than ${ (props.maxFileSize / (1024 * 1024)).toFixed() }MB` });
                }

                return;
            }

            !props.disabled && props.onDrop(acceptedFiles, monitor);
            return;
        }
        // Accept only image types for MVP, but allow video as a recognition trigger
        let acceptedFiles = [];
        // @ts-ignore
        // eslint-disable-next-line no-shadow
        acceptedFiles = item.files.filter((item) => item.type.includes('image'));
        if (!props.disabled) {
            if (acceptedFiles.length) {
                try {
                    let result = props.multiple
                        ? acceptedFiles
                        : [ acceptedFiles[ 0 ] ];
                    if (props.dimension) {
                        if (props.multiple) {
                            const promises = acceptedFiles.map(async (file) => {
                                const compressedFile = await compress(
                                    { files: [ file ] },
                                    props.dimension![ 0 ],
                                    props.dimension![ 1 ]
                                );
                                if (compressedFile) {
                                    return compressedFile;
                                }
                                throw new Error('empty file');
                            });
                            // @ts-ignore
                            result = await Promise.all(promises);
                        } else {
                            const compressedFile = await compress(
                                item,
                                props.dimension![ 0 ],
                                props.dimension![ 1 ]
                            );
                            if (compressedFile) {
                                // @ts-ignore
                                result = [ compressedFile ];
                            } else {
                                throw new Error('empty file');
                            }
                        }
                    }
                    props.onDrop(result, monitor);
                } catch (e: any) {
                    runInAction(() => {
                        // temp solution. need to be refactored (mobx form)
                        if (props.onDropError) {
                            props.onDropError({ message: `Error while file compression.` });
                        } else {
                            BuilderState.toast = {
                                severity: TOAST_ERROR,
                                detail:   'Error while file compression.',
                                summary:  ''
                            };
                        }
                    });
                }
            } else {
                runInAction(() => {
                    // temp solution. need to be refactored (mobx form)
                    if (props.onDropError) {
                        props.onDropError({ message: `Invalid file format.` });
                    } else {
                        BuilderState.toast = {
                            severity: TOAST_ERROR,
                            detail:   '',
                            summary:  'Invalid file format.'
                        };
                    }
                });
            }
        }
    };
    const [ { canDrop, isOver }, drop ] = useDrop({
        accept: NativeTypes.FILE,
        async drop(item: any, monitor) {
            onUpload(item, monitor);
        },
        collect: (monitor) => ({
            isOver:  monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });

    const isDropActive = canDrop && isOver;

    const onOpenInput = useCallback(async () => {
        if (!props.clickable) {
            return;
        }
        const input = document.createElement('input');
        input.type = 'file';
        input.onchange = (e) => {
            e.preventDefault();
            const { files } = e.target as HTMLInputElement;
            if (files) {
                onUpload(
                    { files: Object.values(files).map((file) => file) },
                    null
                );
            }
        };
        input.click();
    }, [ props.clickable ]);

    return (
        // @ts-ignore
        <DropRootContainer
            ref={ drop }
            onClick={ onOpenInput }
            rootContainer={ props.rootContainer }
            fullHeight={ props.fullHeight }
        >
            <Wrapper
                className={ className('select-container') }
                fullWidth={ props.fullWidth }
                fullHeight
            >
                { !isArray(props.children)
                    ? props.children
                        ? React.cloneElement(props.children, { onFilesUpload: onUpload })
                        : <></>
                    : React.Children.map(props.children, (child) => (child ? React.cloneElement(child, { onFilesUpload: onUpload }) : <></>)) }
                { isDropActive
                    && !props.disabled
                    && (props.overlayComponent || (
                        <DragAndDropOverlay style={ props.style } />
                    )) }
            </Wrapper>
        </DropRootContainer>
    );
});
