// eslint-disable-next-line arca/no-default-export
export default (roleId: string) => {
    switch (roleId) {
        case 'admin':
            return 'An Admin has access to your Projects with the ability to edit, delete and publish.';
        case 'owner':
            return 'The Owner has complete access to manage the Company Account from its users to billing to all Projects.';
        case 'editor':
            return 'An Editor has access to your Projects with the ability to edit, delete.';
        default:
            return '';
    }
};
