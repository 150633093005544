import React, { useState } from "react";
import {
    Button, Description, Icon, Input, Title, Wrapper
} from "@geenee/ui";
import { DropdownDisplayValue }         from "~/components/DropdownDisplayValue";
import { useBuilderInject }             from "~/core/hook/use-builder-inject";
import { ACCOUNT_DELETION_REASON_SENT } from "~/lib/constants";

export function SuccessWindow({ onClose }: {onClose: () => void}) {
    const { BuilderState: builderState } = useBuilderInject();
    const [ value, setValue ] = useState("");
    const [ commentValue, setCommentValue ] = useState("");

    const options = [
        { label: "The builder is too expensive", value: "The builder is too expensive" },
        { label: "I am not using the builder", value: "I am not using the builder" },
        { label: "I am having too many technical difficulties", value: "I am having too many technical difficulties" },
        { label: "The builder works not as I expected", value: "The builder works not as I expected" },
        { label: "I have another profile on Geenee builder", value: "I have another profile on Geenee builder" },
        { label: "Other (please specify in comment)", value: "other" }
    ];

    const onSelect = (v: string) => {
        setValue(v);
    };

    const onSubmit = async () => {
        try {
            builderState.analytics.track(ACCOUNT_DELETION_REASON_SENT, {
                account_id: builderState.currentUser.profile?.id || '*user_id*',
                reason:     value,
                comment:    commentValue
            });

            onClose();
        } catch (e) {
            console.error(e);
            onClose();
        }
    };

    return (
        <Wrapper align="center" fullWidth maxWidth="400px">
            <Icon color="gradient-cold-3" size={ 48 } margin="sm" name="success" />
            <Title align="center" size="lg" weight="medium" margin="xs">Account deletion confirmed</Title>
            <Description align="center" size="md" lineHeight="lg" margin="md">
                Your account will be deleted within 24 hours. You will get a confirmation email.
                <br />
                <br />
                We are sorry to see you go. We would appreciate if you share a reason why do you want to delete.
            </Description>
            <Wrapper padding="xs">
                <DropdownDisplayValue
                    items={ options }
                    value={ value }
                    onSelect={ onSelect }
                    maxCount={ 8 }
                    defaultLabel="Choose an option from the list below"
                    margin="xs"
                />
                <Input
                    margin="xs"
                    size="md"
                    placeholder="Comment (optional)"
                    value={ commentValue }
                    onChange={ (e) => setCommentValue(e.target.value) }
                />
                <Button
                    fullWidth
                    viewType="primary"
                    disabled={ value !== "other" && !value }
                    uppercase
                    onClick={ onSubmit }
                >
                    Submit
                </Button>
            </Wrapper>
        </Wrapper>
    );
}
