import React, { useContext }                    from 'react';
import { Description, InputTableItem, Wrapper } from '@geenee/ui';
import { observer }                             from 'mobx-react';
import { ManageBilling }                        from '@geenee/builder/src/components/modals/ManageBilling';
import { stripeState }                          from '@geenee/builder/src/core/state/stripe.state';
import ModalUpdaterContext                      from '@geenee/builder/src/lib/ModalUpdaterContext';
import { ButtonWithPortalTooltip }              from "@geenee/builder/src/pages/SDKAccessPage/components/ButtonWithPortalTooltip";

export const CardsOnFile = observer(() => {
    const cardOnFile = stripeState.defaultPaymentMethod;
    const setModalOptions = useContext(ModalUpdaterContext);

    return (
        <InputTableItem
            size="lg"
            label="Credit card on file"
            viewType="secondary"
            weight="medium"
        >
            <Wrapper row valign='center'>
                <Wrapper valign="center" frame="solid-white" radius="xl" margin="xs" style={ { height: '40px' } }>
                    <Description style={ { paddingLeft: 13 } }>
                        { cardOnFile ? (
                            <>
                                <div>
                                    { '•••• •••• •••• ' }
                                    { cardOnFile.last4 }
                                    , expires
                                    { ' ' }
                                    { cardOnFile.exp_month }
                                    /
                                    { cardOnFile.exp_year }
                                </div>
                            </>
                        ) : <></> }
                    </Description>
                </Wrapper>
                <ButtonWithPortalTooltip
                    action={ {
                        icon:        'edit2',
                        onClick:     () => setModalOptions({ component: ManageBilling }),
                        tooltipText: cardOnFile ? 'Change card' : 'Add credit card'
                    } }
                    menuWidth="120px"
                />
            </Wrapper>
        </InputTableItem>
    );
});
