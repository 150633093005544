import React, { FC }                                      from "react";
import { withMoleculeContext }                            from "@geenee/geeclient-kit/src";
import { getPropsFromOwner, ImageAtomOptType, initProps } from "@geenee/geeclient-kit/src/lib/component/atom/image/util";
import { SiteMoleculeSchemaType }                         from "@geenee/geeclient-kit/src/lib/type/section-molecule";
import { cnKitAtom }                                      from "@geenee/geeclient-kit/src/lib/util/bem";
import { compose }                                        from "@geenee/geeclient-kit/src/lib/util/compose";
import { withProps }                                      from "@geenee/geeclient-kit/src/lib/util/withProps";
import { observer }                                       from "mobx-react";
import './image.atom.component.scss';

const className = cnKitAtom('atom-image');
// eslint-disable-next-line react/function-component-definition
const ImageView: FC<ImageAtomOptType> = (props) => (props.image_src ? (
    <div
        className={ className('') }
        style={ {
            '--atom-image-width':          props.image_width,
            '--atom-image-height':         props.image_height,
            '--atom-image--border_radius': props.image_border_radius
        } as React.CSSProperties }
    >
        { /* eslint-disable-next-line jsx-a11y/alt-text */ }
        <img
            src={ props.image_src }
            style={ { '--atom-image--object_fit': props.image_object_fit } as React.CSSProperties }
        />
    </div>
) : <></>);

ImageView.defaultProps = { ...initProps } as Partial<ImageAtomOptType>;

const enhance = compose(
    withMoleculeContext,
    withProps(
        ({
            moleculeContext,
            ...extendProps
        }: {
      moleculeContext: SiteMoleculeSchemaType<ImageAtomOptType>;
    }) => getPropsFromOwner(moleculeContext.options, extendProps)
    ),
    observer
);

export const ImageAtomComponent = enhance(ImageView);
