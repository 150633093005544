import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '12',
    height = '20',
    onClick,
    id,
    fill = 'black',
    style
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            viewBox="0 0 12 20"
            xmlns="http://www.w3.org/2000/svg"
            onClick={ onClick }
            style={ style }
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M7.97969 10L0.418419 17.5613C-0.139473 18.1192 -0.139473 19.0237 0.418419 19.5816C0.976311 20.1395 1.88083 20.1395 2.43872 19.5816L11.0102 11.0102C11.568 10.4523 11.568 9.54774 11.0102 8.98985L2.43872 0.418419C1.88083 -0.139473 0.976311 -0.139473 0.418419 0.418419C-0.139473 0.976311 -0.139473 1.88083 0.418419 2.43872L7.97969 10Z"
                fill={ fill }
            />
        </svg>
    );
}
