import React, { useState }                               from 'react';
import { useInject }                                     from '@geenee/shared';
import { LoadingSpinnerCentered }                        from '@geenee/shared/src/magellan/components/LoadingSpinner';
import { COVER_IMAGE_ID }                                from '@geenee/shared/src/util/constants';
import {  cn }                                           from '@geenee/ui';
import { observer }                                      from 'mobx-react';
import { DropWrapper }                                   from '@geenee/builder/src/components/DropWrapper';
import { DIComponentType }                               from '@geenee/builder/src/core/context/di.context';
import { ImageUpdatePencil }                             from '@geenee/builder/src/magellan/components/programView/ImageUpdatePencil';
import { ExperienceModel }                               from '@geenee/builder/src/magellan/model/experience.model';
import { CampaignImageWrapperContainer, LoadingWrapper } from './styles';
import './CampaignImageWrapper.styles.scss';

interface Props {
    children: JSX.Element | JSX.Element[];
}

const className = cn('campaign-image-wrapper');

export const CampaignImageWrapper = observer(({ children }: Props & DIComponentType) => {
    const { AppState } = useInject();
    const { activeExperience } = AppState;
    const [ isLoading, setLoading ] = useState(false);
    const disabled = activeExperience?.nft_published;

    const handleUpload = async (file: File) => {
        if (activeExperience) {
            setLoading(true);
            await (activeExperience as ExperienceModel).setCoverImage(file);
            setLoading(false);
        }
    };

    return (
        <DropWrapper
            clearMessageKey={ activeExperience?.id }
            disabled={ disabled }
            rootContainer={ CampaignImageWrapperContainer }
            dimension={ [ 1000, 1000 ] }
            onDrop={ async ([ file ]) => {
                await handleUpload(file);
            } }
        >
            { isLoading ? (
                <LoadingWrapper>
                    <LoadingSpinnerCentered />
                </LoadingWrapper>
            ) : (
                <></>
            ) }
            <div
                className={ className('root') }
            >
                <ImageUpdatePencil
                    uploadContainerId={ COVER_IMAGE_ID }
                    className={ className('pencil-container') }
                    disabled={ disabled }
                    handleUpload={ handleUpload }
                    hide={ !activeExperience?.cover_image?.url }
                />
                { children }
            </div>
        </DropWrapper>
    );
});
