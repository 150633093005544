import React, { useMemo, useState } from 'react';
import { CollapseWrapper }          from "@geenee/geeclient-kit/src/lib/component/scene/viewer3d/component/scene-control/ParametersTabTreejs/CollapseWrapper";
import { AtomModel, useInject }     from '@geenee/shared/index';
import { Button, InputMatrix }      from '@geenee/ui';
import { observer }                 from 'mobx-react';
import { MathUtils, Object3D }      from 'three';
import { ROUND_DELIMITER }          from '../../../util/scene-inputs.helper';
import { Text3DPanel }              from '../Text3DPanel';
import { TransformToolsPanel }      from '../TransformToolsPanel';

const { degToRad, radToDeg } = MathUtils;

interface Props {
    sceneWrapperObject: Object3D;
}

export const PropertiesPanel = observer(({ sceneWrapperObject } : Props) => {
    const { SceneState } = useInject();
    const {
        activeMolecule, disabled, currentAsset, selectedAtomId, parametersMatrixKey
    } = SceneState;

    const atomModels = activeMolecule?.sceneActorAtoms || [] as AtomModel[];

    const [ isScaleUnlocked, setIsScaleUnlocked ] = useState(false);

    const transformMatrix = useMemo(() => ({
        rows: [ {
            label:     "Position",
            rowGetter: 'position'
        },
        {
            label:       'Rotation',
            rowGetter:   'rotation',
            valueGetter: (values: Record<string, any>, column: string) => {
                const value = values.rotation[ column ];
                return Math.round(
                    radToDeg(value) * ROUND_DELIMITER
                ) / ROUND_DELIMITER;
            },
            step: 1,
            min:  -360,
            max:  360
        },
        {
            label:     'Scale',
            rowGetter: 'scale',
            step:      0.01,
            before:    <Button
                margin="xs"
                icon={ isScaleUnlocked ? "lockOpen" : "lockClose" }
                iconColor="shade-4"
                radius="sm"
                size='sm'
                iconStroke="fat"
                viewType="action"
                onClick={ () => {
                    if (selectedAtomId === null) {
                        sceneWrapperObject.geeneeScaleUnlocked = !isScaleUnlocked;
                    } else {
                        const child = atomModels.find((el) => el.id === selectedAtomId);
                        if (child) {
                            child.firstObject.geeneeScaleUnlocked = !isScaleUnlocked;
                        }
                    }
                    setIsScaleUnlocked((prev) => !prev);
                } }
            />
        } ],
        columns:        [ '0', '1', '2' ],
        displayColumns: [ 'x', 'y', 'z' ]
    }), [
        atomModels, selectedAtomId,
        currentAsset, JSON.stringify(currentAsset?.rotation),
        JSON.stringify(currentAsset?.position),
        JSON.stringify(currentAsset?.scale), isScaleUnlocked
    ]);

    const getOnChange = (field: 'position' | 'rotation' | 'scale', axis: string) => (inputValue: number) => {
        if (!currentAsset) {
            return;
        }
        const value = Math.round(+inputValue * ROUND_DELIMITER) / ROUND_DELIMITER;
        const newState = Array.from(currentAsset[ field ] || []);
        if (field === 'scale' && !isScaleUnlocked) {
            newState[ 0 ] = value;
            newState[ 1 ] = value;
            newState[ 2 ] = value;
        } else if (field === 'rotation') {
            newState[ +axis ] = degToRad(value);
        } else {
            newState[ +axis ] = value;
        }
        currentAsset?.setSceneStats({ [ field ]: newState });
    };

    if (!currentAsset) {
        return <></>;
    }

    return (
        <CollapseWrapper
            collapsed={ false }
            title="TRANSFORMATION"
            contentWrapperProps={ { padding: "xs" } }
            paddingLeft="xs"
        >
            <InputMatrix
                key={ parametersMatrixKey }
                rows={ transformMatrix.rows }
                columns={ transformMatrix.columns }
                displayColumns={ transformMatrix.displayColumns }
                getOnChange={ getOnChange }
                target={ currentAsset }
                inputSize="sm"
                labelsTitle=""
                hasColumnLabels
                labelWeight="medium"
                labelSize="sm"
                lockedRows={ !isScaleUnlocked ? [ 'scale' ] : [] }
            />
            { currentAsset.isText3D && (
                <Text3DPanel />
            ) }
            { !currentAsset.isText3D && (
                <TransformToolsPanel />
            ) }
        </CollapseWrapper>
    );
});
