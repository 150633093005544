import React                                    from 'react';
import { useHistory }                           from "@geenee/common";
import { Analytics }                            from '@geenee/analytics';
import { Description, InputTableItem, Wrapper } from '@geenee/ui';
import { observer }                             from 'mobx-react';
import { useBuilderInject }                     from '@geenee/builder/src/core/hook/use-builder-inject';
import { stripeState }                          from '@geenee/builder/src/core/state/stripe.state';
import { VIEW_PLAN_PAGE_CLICKED }               from '@geenee/builder/src/lib/constants';
import { ButtonWithPortalTooltip }              from "@geenee/builder/src/pages/SDKAccessPage/components/ButtonWithPortalTooltip";

export const CurrentPlan = observer(() => {
    const { container } = useBuilderInject();
    const history = useHistory();

    const analytics: Analytics = container.get("<Analytics>");

    return (
        <InputTableItem
            size="lg"
            label="Current plan"
            viewType="secondary"
            weight="medium"
        >
            <Wrapper row valign="center">
                <Wrapper valign="center" frame="solid-white" radius="xl" margin="xs" style={ { height: "40px" } }>
                    <Description style={ { paddingLeft: 13 } } capitalize>
                        { stripeState.plan_title ? `${ stripeState.plan_title } ` : "" }
                        { stripeState.status !== "active" ? (
                            <>
                                Inactive
                            </>
                        ) : (
                            <></>
                        ) }
                    </Description>
                </Wrapper>
                <ButtonWithPortalTooltip
                    action={ {
                        icon:    "edit2",
                        onClick: () => {
                            const {
                                plan_id,
                                plan_period,
                                plan_price,
                                plan_price_id,
                                plan_title
                            } = stripeState;

                            analytics.track(VIEW_PLAN_PAGE_CLICKED, {
                                plan_id,
                                plan_period,
                                plan_price,
                                plan_price_id,
                                plan_title
                            });

                            history.push("/all-plans");
                        },
                        tooltipText: "Change plan"
                    } }
                    menuWidth="120px"
                />
            </Wrapper>
        </InputTableItem>
    );
});
