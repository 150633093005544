import React, { FC, useEffect }                              from "react";
import { AtomModel, SceneRenderer }                          from "@geenee/shared/index";
import { AdaptiveDpr }                                       from "@react-three/drei";
import { useThree }                                          from "@react-three/fiber";
import { observer }                                          from "mobx-react";
import { DefaultLoadingManager }                             from "three/src/loaders/LoadingManager";
import { DRACOLoader, GLTFLoader }                           from "three-stdlib";
import { createAsset }                                       from "use-asset";
import { setCastShadow, setFrustumCulled, setRecieveShadow } from "../../utils/sdk/SceneObjectsUtils";

const gltfLoader = new GLTFLoader(DefaultLoadingManager);
const dracoloader = new DRACOLoader();
dracoloader.setDecoderPath("https://www.gstatic.com/draco/versioned/decoders/1.4.3/");
gltfLoader.setDRACOLoader(dracoloader);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const modelsCache = new Map();

const gltfAsset = createAsset(async (path) => {
    const {
        gl,
        camera
    } = useThree();
    return new Promise((resolve) => {
        if (modelsCache.has(path)) {
            setTimeout(() => {
                resolve(modelsCache.get(path));
            });
        } else {
            gltfLoader.load(path, (gltf: any) => {
                modelsCache.set(path, gltf);
                gl.compile(gltf.scene, camera);
                resolve(gltf);
            });
        }
    });
});

type Simple3DModelPropsType = {
  url?: string
  atomModel: AtomModel
  selectedAnimation?: number | null
  onAssetLoad?: any
  disableCastShadow?: boolean
  disableReceiveShadow?: boolean
  position: number[]
  rotation: number[]
  scale: number[],
  activeSceneModel: SceneRenderer
}

const SimpleGLTF: FC<Simple3DModelPropsType> = observer(({
    selectedAnimation,
    atomModel,
    disableCastShadow,
    disableReceiveShadow,
    position,
    rotation,
    scale,
    activeSceneModel,
    ...props
}) => {
    const url = atomModel.assetUrl;
    const gltf: any = atomModel.firstObject ? {
        scene:      atomModel.firstObject,
        animations: atomModel.firstObject.animations
    } : gltfAsset.read(url);
    gltf.scene.animations = gltf.animations;

    useEffect(() => {
        if (gltf) {
            gltf.animations.forEach((anim) => activeSceneModel.animationController.add(anim, gltf.scene));
            props.onAssetLoad(true);
        }
    }, []);

    useEffect(() => {
        if (gltf) {
            setCastShadow(gltf?.scene, !disableCastShadow);
            setRecieveShadow(gltf?.scene, !disableReceiveShadow);
            setFrustumCulled(gltf?.scene, false);
        }
    }, [ gltf, disableCastShadow, disableReceiveShadow ]);

    useEffect(() => {
        if (selectedAnimation !== null && selectedAnimation !== undefined) {
            const animation = gltf?.animations[ selectedAnimation ];
            if (animation) {
                activeSceneModel.animationController.playClip(animation.name, gltf.scene);
            }
        } else {
            activeSceneModel.animationController.stopAllObjectAnims(gltf.scene);
        }
    }, [ selectedAnimation ]);

    return (
        <>
            { /* <Environment preset={ props.activeSceneModel.scene3DSettings.environmentPreset } /> */ }
            <primitive
                name="geenee-3d-gltf-mesh"
                position={ position }
                rotation={ rotation }
                scale={ scale }
                object={ gltf.scene }
                visible={ atomModel.sceneObjectVisible }
            />
            { /* <BakeShadows key={JSON.stringify(props.attachmentModel.properties)} /> */ }
            <AdaptiveDpr pixelated />
        </>
    );
});
// eslint-disable-next-line arca/no-default-export
export default SimpleGLTF;
