import { ProjectModel }                           from '@geenee/shared';
import { action, makeAutoObservable, observable } from 'mobx';
import { v4 as uuidv4 }                           from 'uuid';
import { getCompanyLogoPreSignedUrl }             from '@geenee/builder/src/api/CompanyApi';
import {
    confirmWalletApi,
    createWalletApi,
    deleteWalletApi,
    getNftsListApi,
    getWalletsApi,
    mintProjectApi, viewWalletApi, withdrawApi
}                                         from '@geenee/builder/src/api/NftApi';
import { NftItemModel }                   from '@geenee/builder/src/core/model/nftItem.model';
import { NftWalletModel }                 from '@geenee/builder/src/core/model/nftWallet.model';
import { generateProjectContentsPreview } from '@geenee/builder/src/lib/generateProjectContentsPreview';
import { createUploadFileChannel }        from '@geenee/builder/src/lib/uploadAttachment';

class NftState {
    @observable token = '';
    @observable walletStore: NftWalletModel[] = [];
    @observable isWalletsLoaded = false;
    @observable nftItems: NftItemModel[] = [];
    @observable isNftItemsLoaded = false;
    @observable activeWallet?: NftWalletModel;
    constructor() {
        makeAutoObservable(this);
    }

    @action
        setToken = (token: string) => {
            this.token = token;
        };

    @action
    async createWallet(password: string, password_confirmation: string) {
        return createWalletApi(password, password_confirmation, this.token);
    }

    @action deleteWallet(id: string) {
        return deleteWalletApi(id, this.token);
    }

    @action async getWallets() {
        if (!this.isWalletsLoaded) {
            this.walletStore = await getWalletsApi(this.token);
            this.isWalletsLoaded = true;
        }
        return this.walletStore;
    }

    @action async confirmWallet(id: string) {
        const wallet = await confirmWalletApi(id, this.token);
        this.walletStore.push(wallet);
    }

    @action async mintProject(projectData: any) {
        const minted = await mintProjectApi(projectData, this.token);
        const item = new NftItemModel();
        item.init(minted);
        this.nftItems.unshift(minted);
        return minted;
    }

    @action async getNftsList(): Promise<NftItemModel[]> {
        if (!this.isNftItemsLoaded) {
            this.nftItems = [];
            const res = await getNftsListApi(this.token);
            res.forEach((el) => {
                const model = new NftItemModel();
                model.init(el);
                this.nftItems.push(model);
            });
        }
        return this.nftItems;
    }

    @action async viewWallet() {
        const wallets = await this.getWallets();
        if (wallets[ 0 ]) {
            const info = await viewWalletApi(wallets[ 0 ].address, this.token);
            const wallet = new NftWalletModel();
            wallet.init(info);
            this.activeWallet = wallet;
        }
    }

    async uploadPreviewImages(files: File[]) {
        const uploadings = files.map(async (file) => {
            const { url, s3_url } = await getCompanyLogoPreSignedUrl(file.name);
            await createUploadFileChannel(url, file);
            return s3_url;
        });
        return Promise.all(uploadings);
    }

    async generateAndUploadPreviewImages(rootProgram: ProjectModel) {
        const previews = await generateProjectContentsPreview(rootProgram);
        const filesPromises = previews.map((imageUrl) => fetch(imageUrl)
            .then((response) => response.blob()).then((blob) => new File([ blob ], `${ uuidv4() }.png`, { type: 'image/png' })));
        const files = await Promise.all(filesPromises);
        return this.uploadPreviewImages(files);
    }

    async uploadQrCode(file: File) {
        const [ url ] = await this.uploadPreviewImages([ file ]);
        return url;
    }

    @action
    async withdraw(from_wallet: string, to_wallet: string, password: string, amount: string) {
        await withdrawApi(from_wallet, to_wallet, password, parseFloat(amount), this.token);
        await this.viewWallet();
    }
}

export const nftState = new NftState();
