import React                           from 'react';
import { Description, Title, Wrapper } from '@geenee/ui';
import { ContentWrapper }              from '@geenee/builder/src/components/modals/PostPublishingNFT/styles';

export function WithdrawSuccess() {
    return (
        <ContentWrapper>
            <Wrapper align='center'>
                <Title size='xxl' align='center' margin='lg'>
                    All good.
                </Title>
                <Wrapper maxWidth='413px' align='center'>
                    <Description size='sm' align='center' margin='md'>
                        Nice work.
                        <br />
                        Your funds will be directly deposited into your crypto wallet soon.
                    </Description>
                </Wrapper>
            </Wrapper>
        </ContentWrapper>
    );
}
