import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '12',
    height = '12',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="6" cy="6" r="5.5" fill="white" stroke="#BBBBBB" />
            <path
            // eslint-disable-next-line max-len
                d="M5.42457 4.53767L3.32456 6.00579L5.43251 7.47392C5.57257 7.56892 5.59597 7.75151 5.49479 7.88303C5.43251 7.96348 5.33923 8 5.24596 8C5.18368 8 5.1138 7.97803 5.05942 7.94152L2.62457 6.24691C2.54679 6.18843 2.5 6.10084 2.5 6.01326V5.99871V5.98416C2.5 5.89658 2.54679 5.809 2.62457 5.75051L5.05117 4.0562C5.19124 3.96119 5.38568 3.99058 5.48686 4.11468C5.59562 4.26046 5.56433 4.44305 5.42457 4.53777V4.53767ZM9.5 6.00579V5.99124C9.5 5.90366 9.45321 5.81608 9.37543 5.75759L6.9412 4.07036C6.80114 3.97536 6.60669 4.00475 6.50552 4.12885C6.40435 4.26037 6.43564 4.44295 6.5678 4.53796L8.67544 6.00579L6.57543 7.47392C6.43536 7.56892 6.41197 7.75151 6.51314 7.88303C6.57543 7.96348 6.6687 8 6.76197 8C6.82425 8 6.89413 7.97803 6.94851 7.94151L9.37512 6.2472C9.4529 6.18871 9.49969 6.10113 9.49969 6.01355C9.49999 6.01326 9.49999 6.01326 9.49999 6.00585L9.5 6.00579Z"
                fill="black"
            />
        </svg>
    );
}
