import styled from 'styled-components';

const sharedButtonStyles = `
  width: 115px;
  height: 45px;
 font-family: 'Euclid Circular A', Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ConfirmButton = styled.div`
  color: ${ ({ theme: { colors } }) => colors.black };
  background: ${ ({ theme: { colors } }) => colors.white };
  ${ sharedButtonStyles }
`;

export const CancelButton = styled.div`
  margin-right: 15px;
  color: ${ ({ theme: { colors } }) => colors.white };
  border: 2px solid ${ ({ theme: { colors } }) => colors.white };
  ${ sharedButtonStyles }
`;
