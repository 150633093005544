import { useMemo }                                     from 'react';
import { useHistory }                                  from "@geenee/common";
import MarkerLottie                                    from "@geenee/assets/lib/lottie/marker-ar.json";
import DefaultMarkerArCard                             from "@geenee/assets/lib/lottie/marker-ar-images/MarkerAR050.png";
import ProjectLottie                                   from "@geenee/assets/lib/lottie/project.json";
import DefaultProjectCard                              from "@geenee/assets/lib/lottie/project-images/Start_from_Scratch024.png";
import WorldLottie                                     from "@geenee/assets/lib/lottie/world-ar.json";
import DefaultWorldArCard                              from "@geenee/assets/lib/lottie/world-ar-images/img_0.png";
import { useInject }                                   from '@geenee/shared';
import { createHierarchy, CreateHierarchyContentType } from '../../../Tree/Adder/createHierarchy';

export type AnimatedCardProps = {
  src?: string,
  animationData?: { [key: string]: any },
  title: string,
  description: string,
  defaultImage: string,
  onClick: () => Promise<void>,
  playFrom?: number
  hideImageWithoutTransparent?: boolean
}

export const useAnimatedCards = () => {
    const { AppState } = useInject();
    const history = useHistory();

    const startHierarchy = async (contentType: CreateHierarchyContentType) => {
        const path = await createHierarchy(
            // @ts-ignore
            AppState,
            contentType
        );
        path && history.push(path as string);
    };

    const cards: AnimatedCardProps[] = useMemo(() => [ {
        animationData: ProjectLottie,
        defaultImage:  DefaultProjectCard,
        title:         'Start from Scratch',
        description:   'Build AR section by section. Begin by adding your interface elements',
        onClick:       async () => {
            await startHierarchy('program');
        }
    }, {
        animationData: WorldLottie,
        defaultImage:  DefaultWorldArCard,
        title:         'World AR',
        description:   'AR that tracks to any planar surface. Start by building your AR scene',
        onClick:       async () => {
            await startHierarchy('slam-ar');
        },
        hideImageWithoutTransparent: true
    }, {
        animationData: MarkerLottie,
        defaultImage:  DefaultMarkerArCard,
        title:         'Marker AR',
        description:   'AR that launches from 2D image. Add a tracker image & build your scene',
        onClick:       async () => {
            await startHierarchy('stamp-ar');
        },
        playFrom: 40
    } ], []);

    return { cards };
};
