import styled    from 'styled-components';
import ChevronUp from '../../../../asset/icons/ChevronUp';

export const JoyStickRoot = styled.div`
  width: 115px;
  height: 115px;
  position:  fixed;
  left:      50%;
  transform: translateX(-50%);
  bottom:    105px;
  display:   none;
  border-radius: 55px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: ${ ({ theme: { options } }) => `blur(${ options.blurRadius })` };
  user-select: none;
`;

export const StickBackground = styled.div`
  width: 37.5px;
  height: 37.5px;
  border-radius: 100%;
    position: absolute;
    background: ${ ({ theme: { colors } }) => colors.white };
  top: 0;
  left: 0;
`;

export const StickRoot = styled.div`
  position: absolute;
  top: 35px;
  left: 35px;
  background: ${ ({ theme: { colors } }) => colors.white };
  border-radius: 100%;
  box-shadow: 0px 4px 4px rgba(103, 86, 103, 0.5);
`;

export const StickBorder = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 100%;
  border: 2px solid transparent;
  background: linear-gradient(180deg, #FFE8F4 0%, #FDADFF 50%, #FDADFF 50%, #FDADFF 50.01%, #8DDBFF 100%) border-box; /*3*/
  -webkit-mask:
          linear-gradient(#fff 0 0) padding-box,
          linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
`;

const chevronStyle = `
    display: flex;
    flex-grow: 0;
    position:absolute;
    path {
        fill: white;
    }
    opacity: 0.6;
`;
const CHEVRON_SPACER = '8px';
export const ChevronUpWrapper = styled.div`
    top: ${ CHEVRON_SPACER };
    left:50%;
    transform: translateX(-50%);
    ${ chevronStyle }
`;

export const ChevronDownWrapper = styled.div`
    left:50%;
    transform: translateX(-50%) rotate(180deg);
    bottom: ${ CHEVRON_SPACER };
    ${ chevronStyle }
`;

export const ChevronLeftWrapper = styled.div`
  left: ${ CHEVRON_SPACER };
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  ${ chevronStyle }
`;

export const ChevronRightWrapper = styled.div`
  right: ${ CHEVRON_SPACER };
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  ${ chevronStyle }
`;
