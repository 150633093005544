import React                                             from "react";
import { BodyTracking, FaceTracking, NativeAr, WorldAr } from "./resource/ARIcons";
import {
    BodyTrackingAvatar,
    BodyTrackingTwin,
    DirectLight, EmptyNode, GeeneeLibraryIcon,
    LibraryIcon, PBRMaterial, PointLight,
    SpotLight,
    StandardMaterial,
    UploadCloud
} from "./resource/assetLibraryIcons";
import {
    Facebook, Google, ReadyPlayerMeIcon, SketchfabIcon, SpotifyLogo, TenorIcon, Text3DIcon
} from './resource/brand-icons';
import { CheckboxChecked, CheckboxCheckedNew, CheckboxUnchecked, CheckboxUncheckedNew } from "./resource/checkbox";
import {
    BodyTrackingCircle,
    FaceTrackingCircle,
    MarkerArCircle, ProductViewerCircle,
    WorldArCircle
} from "./resource/experienceType";
import { Success } from './resource/info';
import {
    All,
    AnalyticsAlt,
    Body,
    CarouselAlt,
    Code,
    CommercialLicense,
    Domain,
    Head,
    Listed,
    Loader,
    Manager,
    MarkerAR,
    NFT,
    NFTCollection,
    ProductViewer,
    SDKAdd,
    Sections,
    TeamLib,
    TeamWarm,
    ViewAlt,
    Web,
    WhiteLabel
} from "./resource/sdkTableIcons";
import { FaceSad }               from "./resource/users";
import { Warning1, Warning1Fat } from "./resource/warnings";
import {
    Add,
    AddAsset, AddFolder, AddImage,
    AgainstArrows,
    AlignBottom,
    AlignCenter,
    AlignLeft,
    AlignMiddle,
    AlignRight,
    AlignTop,
    ArrowCircle,
    ArrowDown,
    ArrowLeft,
    ArrowRight,
    ArrowRightSmall,
    ArrowTop,
    AutoStar,
    AutoStars,
    Bell, Bone, BoundingBox,
    Button,
    Calendar, Cancel,
    Card,
    CardComponent,
    Chat,
    Check, CheckBig,
    CheckSmall,
    Clock,
    Close,
    Close2, CloseRound, Collapsed, ColorPicker, ContactProfile,
    Copy, Cursor, CustomCodeIcon,
    Delete,
    DeviceAllM,
    DeviceAllS,
    DeviceDesktopL,
    DeviceDesktopM,
    DeviceDesktopS,
    DeviceMobileL,
    DeviceMobileM,
    DeviceMobileS,
    DeviceTabletL,
    DeviceTabletM,
    DeviceTabletS,
    Drag,
    Edit,
    Edit2, Error,
    Exit,
    Export,
    ExportLeads,
    Extend,
    Extended, ExtendIcon,
    EyeOff,
    EyeOffFat,
    EyeOn,
    EyeOnFat,
    Faq,
    FaqBig,
    Filter,
    Folder,
    Form,
    GoogleDrive,
    GoogleSheets,
    Grid,
    Help,
    HideAll,
    Home,
    Image,
    Info,
    Instagram,
    Integrations,
    Key,
    KlickTipp,
    Layer,
    Layers,
    LinkIcon, LoaderCircle, Lock,
    LockClose,
    LockOpen,
    LogoApple,
    LogoMessenger,
    LogoTelegram,
    Mail,
    Map,
    MapComponent,
    Materials,
    Measure, MediaIcon, More,
    Move,
    MoveTo,
    NavBurger,
    NavBurger2,
    NavDots,
    NavDotsVertical,
    NewFeatures,
    None,
    Notification,
    Options, Pause,
    PcBug,
    Phone,
    Pick,
    Play, PlayCircle, PoweredByGeeneeHorizontal,
    Price,
    Profile, RedoIcon, Refresh,
    Restore,
    Rotate, Save,
    Scale, SDK,
    Search, SearchQuestion,
    Settings,
    ShowAll,
    SkinnedMesh,
    Speaker,
    Star, StartFinish, Stats,
    Status, Stop, Styles,
    Submit,
    SubmitAction, Support, Team,
    Text,
    TextAlignCenter,
    TextAlignLeft,
    TextAlignRight,
    TextBold,
    TextColorPicker,
    TextFontSize,
    TextItalic,
    TextListArrow,
    TextListCheck,
    TextListCircle,
    TextListCross,
    TextListSquare,
    Textures,
    ToGround, TransformNode, Trash,
    Tutorials,
    Twitter, UndoIcon,
    Unsplash, Upload,
    ViewInYourSpace,
    Vimeo, Warning,
    WhatsUp,
    YouTube,
    Zapier
} from "./resource";

export const iconLibrary = {
    System: {
        checkboxChecked:      <CheckboxChecked activated />,
        checkboxUnchecked:    <CheckboxUnchecked />,
        checkboxCheckedNew:   <CheckboxCheckedNew />,
        checkboxUncheckedNew: <CheckboxUncheckedNew />,
        check:                <Check />,
        checkSmall:           <CheckSmall />,
        checkBig:             <CheckBig />,
        lockClose:            <LockClose />,
        lockOpen:             <LockOpen />,
        key:                  <Key />,
        eyeOn:                <EyeOn />,
        eyeOff:               <EyeOff />,
        eyeOnFat:             <EyeOnFat />,
        eyeOffFat:            <EyeOffFat />,
        play:                 <Play />,
        playCircle:           <PlayCircle />,
        pause:                <Pause />,
        stop:                 <Stop />,
        grid:                 <Grid />,
        settings:             <Settings />,
        options:              <Options />,
        submit:               <Submit />,
        link:                 <LinkIcon />,
        styles:               <Styles />,
        upload:               <Upload />,
        drag:                 <Drag />,
        faq:                  <Faq />,
        autoStar:             <AutoStar />,
        autoStars:            <AutoStars />,
        search:               <Search />,
        searchQuestion:       <SearchQuestion />,
        pcBug:                <PcBug />,
        loaderCircle:         <LoaderCircle />,
        customCode:           <CustomCodeIcon />,
        media:                <MediaIcon />
    },
    // TODO: useless icon. Check usage, if not used, remove it
    Settings:   { none: <None /> },
    Navigation: {
        home:         <Home />,
        burger:       <NavBurger />,
        burger2:      <NavBurger2 />,
        dots:         <NavDots />,
        dotsVertical: <NavDotsVertical />,
        more:         <More />,
        cursor:       <Cursor />
    },
    Users:       { faceSad: <FaceSad /> },
    Development: { folder: <Folder />, addFolder: <AddFolder /> },
    Warnings:    {
        warning1:    <Warning1 />,
        warning1Fat: <Warning1Fat />
    },
    Actions: {
        close:           <Close />,
        close2:          <Close2 />,
        closeRound:      <CloseRound />,
        add:             <Add />,
        copy:            <Copy />,
        delete:          <Delete />,
        remove:          <Trash />,
        edit2:           <Edit2 />,
        edit:            <Edit />,
        restore:         <Restore />,
        measure:         <Measure />,
        moveTo:          <MoveTo />,
        toGround:        <ToGround />,
        export:          <Export />,
        star:            <Star />,
        move:            <Move />,
        rotate:          <Rotate />,
        scale:           <Scale />,
        pick:            <Pick />,
        boundingBox:     <BoundingBox />,
        againstArrows:   <AgainstArrows />,
        cancel:          <Cancel />,
        refresh:         <Refresh />,
        info:            <Info />,
        warning:         <Warning />,
        error:           <Error />,
        viewInYourSpace: <ViewInYourSpace />,
        lock:            <Lock />,
        addImage:        <AddImage />,
        colorPicker:     <ColorPicker />
    },
    Arrows: {
        arrowDown:       <ArrowDown />,
        arrowLeft:       <ArrowLeft />,
        arrowRight:      <ArrowRight />,
        arrowRightSmall: <ArrowRightSmall />,
        arrowTop:        <ArrowTop />,
        arrowCircle:     <ArrowCircle />,
        extendIcon:      <ExtendIcon />,
        undo:            <UndoIcon />,
        redo:            <RedoIcon />
    },
    Align: {
        alignTop:    <AlignTop />,
        alignMiddle: <AlignMiddle />,
        alignBottom: <AlignBottom />,
        alignLeft:   <AlignLeft />,
        alignCenter: <AlignCenter />,
        alignRight:  <AlignRight />
    },
    Content: {
        image:         <Image />,
        clock:         <Clock />,
        form:          <Form />,
        mail:          <Mail />,
        phone:         <Phone />,
        text:          <Text />,
        calendar:      <Calendar />,
        submitAction:  <SubmitAction />,
        map:           <Map />,
        mapComponent:  <MapComponent />,
        button:        <Button />,
        cardComponent: <CardComponent />,
        start:         <StartFinish />
    },
    Profile: {
        profile:        <Profile />,
        card:           <Card />,
        bell:           <Bell />,
        speaker:        <Speaker />,
        price:          <Price />,
        notification:   <Notification />,
        contactProfile: <ContactProfile />
    },
    Crm: {
        status:       <Status />,
        exportLeads:  <ExportLeads />,
        integrations: <Integrations />,
        filter:       <Filter />
    },
    TextEdit: {
        textAlignCenter: <TextAlignCenter />,
        textAlignLeft:   <TextAlignLeft />,
        textAlignRight:  <TextAlignRight />,
        textBold:        <TextBold />,
        textColorPicker: <TextColorPicker />,
        textFontSize:    <TextFontSize />,
        textItalic:      <TextItalic />

    },
    TextList: {
        textListArrow:  <TextListArrow />,
        textListCheck:  <TextListCheck />,
        textListCircle: <TextListCircle />,
        textListCross:  <TextListCross />,
        textListSquare: <TextListSquare />
    },
    Info:    { success: <Success /> },
    Devices: {
        deviceMobile: {
            16: <DeviceMobileS />,
            24: <DeviceMobileM />,
            56: <DeviceMobileL />
        },
        deviceTablet: {
            16: <DeviceTabletS />,
            24: <DeviceTabletM />,
            56: <DeviceTabletL />
        },
        deviceDesktop: {
            16: <DeviceDesktopS />,
            24: <DeviceDesktopM />,
            56: <DeviceDesktopL />
        },
        deviceAll: {
            16: <DeviceAllS />,
            24: <DeviceAllM />
        }
    },
    Help: {
        help:        <Help />,
        chat:        <Chat />,
        faqBig:      <FaqBig />,
        tutorials:   <Tutorials />,
        newFeatures: <NewFeatures />,
        support:     <Support />,
        sdk:         <SDK />,
        stats:       <Stats />,
        team:        <Team />
    },
    Logos: {
        poweredByGeeneeHorizontal: <PoweredByGeeneeHorizontal />,
        apple:                     <LogoApple />,
        messenger:                 <LogoMessenger />,
        telegram:                  <LogoTelegram />,
        klickTipp:                 <KlickTipp />,
        googleSheets:              <GoogleSheets />,
        unsplash:                  <Unsplash />,
        youtube:                   <YouTube />,
        vimeo:                     <Vimeo />,
        instagram:                 <Instagram />,
        googleDrive:               <GoogleDrive />,
        whatsUp:                   <WhatsUp />,
        twitter:                   <Twitter />,
        zapier:                    <Zapier />
    },
    BrandIcons: {
        google:        <Google />,
        facebook:      <Facebook />,
        text3d:        <Text3DIcon />,
        sketchfab:     <SketchfabIcon />,
        geeneeLibrary: <GeeneeLibraryIcon />,
        tenor:         <TenorIcon />,
        spotify:       <SpotifyLogo />,
        readyPlayerMe: <ReadyPlayerMeIcon />
    },
    AssetLibraryIcons: {
        uploadCloud:      <UploadCloud />,
        library:          <LibraryIcon />,
        pointLight:       <PointLight />,
        directLight:      <DirectLight />,
        spotLight:        <SpotLight />,
        standardMaterial: <StandardMaterial />,
        pbrMaterial:      <PBRMaterial />,
        emptyNode:        <EmptyNode />,
        avatar:           <BodyTrackingAvatar />,
        twin:             <BodyTrackingTwin />
    },
    SDKTableIcons: {
        web:               <Web />,
        carouselAlt:       <CarouselAlt />,
        body:              <Body />,
        head:              <Head />,
        productViewer:     <ProductViewer />,
        sections:          <Sections />,
        code:              <Code />,
        sdkAdd:            <SDKAdd />,
        viewAlt:           <ViewAlt />,
        whiteLabel:        <WhiteLabel />,
        loader:            <Loader />,
        teamWarm:          <TeamWarm />,
        teamLib:           <TeamLib />,
        commercialLicense: <CommercialLicense />,
        nft:               <NFT />,
        listed:            <Listed />,
        analyticsAlt:      <AnalyticsAlt />,
        nftCollection:     <NFTCollection />,
        domain:            <Domain />,
        manager:           <Manager />,
        all:               <All />
    },
    Menu3D: {
        addAsset:      <AddAsset />,
        layers:        <Layers />,
        mesh:          <Layer />,
        materials:     <Materials />,
        textures:      <Textures />,
        extend:        <Extend />,
        transformNode: <TransformNode />,
        bone:          <Bone />,
        skinnedMesh:   <SkinnedMesh />,
        exit:          <Exit />,
        save:          <Save />

    },
    ExperienceTypes: {
        bodytrackingCircle:  <BodyTrackingCircle />,
        facetrackingCircle:  <FaceTrackingCircle />,
        worldArCircle:       <WorldArCircle />,
        markerArCircle:      <MarkerArCircle />,
        productViewerCircle: <ProductViewerCircle />
    },
    ExtendColapse: {
        showAll:   <ShowAll />,
        hideAll:   <HideAll />,
        extended:  <Extended />,
        collapsed: <Collapsed />
    },
    ARIcons: {
        bodyTrackingAR: <BodyTracking />,
        faceTrackingAR: <FaceTracking />,
        worldAR:        <WorldAr />,
        nativeAR:       <NativeAr />,
        markerAR:       <MarkerAR />
    }
};

export const iconMap = {
    ...iconLibrary.Info,
    ...iconLibrary.Warnings,
    ...iconLibrary.Development,
    ...iconLibrary.System,
    ...iconLibrary.Users,
    ...iconLibrary.Settings,
    ...iconLibrary.Navigation,
    ...iconLibrary.Actions,
    ...iconLibrary.Arrows,
    ...iconLibrary.Align,
    ...iconLibrary.Content,
    ...iconLibrary.Profile,
    ...iconLibrary.Crm,
    ...iconLibrary.Devices,
    ...iconLibrary.TextEdit,
    ...iconLibrary.TextList,
    ...iconLibrary.Help,
    ...iconLibrary.Logos,
    ...iconLibrary.BrandIcons,
    ...iconLibrary.AssetLibraryIcons,
    ...iconLibrary.SDKTableIcons,
    ...iconLibrary.Menu3D,
    ...iconLibrary.ExtendColapse,
    ...iconLibrary.ExperienceTypes,
    ...iconLibrary.ARIcons
};
