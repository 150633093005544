import { action, makeAutoObservable, observable } from 'mobx';
import { StripePlanParamsType }                   from '@geenee/builder/src/../type/model/stripe/stripe-plan.type';

export class StripePlanParamsModel implements StripePlanParamsType {
    @observable ar_assets_per_experience: StripePlanParamsType['ar_assets_per_experience'] = 0;
    @observable asset_size_limit: StripePlanParamsType['asset_size_limit'] = 0;
    @observable commercial_license: StripePlanParamsType['commercial_license'] = false;
    @observable content_templates: StripePlanParamsType['content_templates'] = 0;
    @observable custom_domains: StripePlanParamsType['custom_domains'] = 0;
    @observable experience_size_limit: StripePlanParamsType['experience_size_limit'] = 0;
    @observable experiences: StripePlanParamsType['experiences'] = 0;
    @observable multi_ar_assets_per_scene: StripePlanParamsType['multi_ar_assets_per_scene'] = 0;
    @observable nfts: StripePlanParamsType['nfts'] = 0;
    @observable projects: StripePlanParamsType['projects'] = 0;
    @observable sdk: StripePlanParamsType['sdk'] = false;
    @observable seats: StripePlanParamsType['seats'] = 0;
    @observable views: StripePlanParamsType['views'] = 0;
    constructor() {
        makeAutoObservable(this);
    }
    @action init = (item: Partial<StripePlanParamsModel>) => {
        Object.keys(item).forEach((key) => {
            // @ts-ignore
            this[ key ] = item[ key ];
        });
    };
}
