import React, { memo, useCallback, useMemo, useState } from "react";
import Uploader                                        from "@geenee/geeclient-kit/src/lib/component/scene/components/Uploader";
import { LoadingSpinnerCentered }                      from "@geenee/shared/src/magellan/components/LoadingSpinner";
import {
    Button, FormItem, Icon, Input, InputTable, InputTableItem, MediaContent, Wrapper
} from "@geenee/ui";
import { DropImageForm }                   from "~/components/DropImageForm";
import { useBuilderInject }                from "~/core/hook/use-builder-inject";
import { getFileFromUrl }                  from "~/lib/getFileFromUrl";
import { LibraryTitle }                    from "~/module/team-library/components/LibraryTitle";
import { generateTeamLibraryModelPreview } from "~/module/team-library/lib/generateTeamLibraryModelPreview";
import AssetLibraryState                   from "~/module/team-library/state/team-library.state";
import { TeamLibraryItemModel }            from "~/module/team-library/state/team-library-item.model";

interface Props {
    onBackClicked: () => void;
    asset?: TeamLibraryItemModel;
}
const validate = (value: string) => {
    if (!value) return 'required';
    return '';
};

export const EditAsset = memo(({ onBackClicked, asset }: Props) => {
    const { BuilderState } = useBuilderInject();

    const isCustomAsset = !!asset?.attachment.filename.split(".").pop()?.includes("json");

    const [ file, setFile ] = useState<File>();
    const [ thumbnailFile, setThumbnail ] = useState<File>();
    const [ title, setTitle ] = useState(asset?.title || "");
    const [ error, setError ] = useState(title ? '' : 'required');
    const [ description ] = useState(asset?.description || "");
    const [ isLoading, setIsLoading ] = useState(false);

    const { first_name, last_name, email } = BuilderState.currentUser.profile || {
        first_name: "",
        last_name:  "",
        email:      ""
    };
    const userName = first_name || last_name ? `${ first_name } ${ last_name }`.trim() : email;

    const isImage = useMemo(() => {
        if (file) {
            return file.type.includes("image");
        }
        if (asset?.attachment.url) {
            return [ "png", "jpeg", "jpg" ].includes(asset.attachment.url.split(".").pop() || "");
        }
        return false;
    }, [ file, asset?.attachment.url ]);
    const thumbnailUrl = useMemo(() => (thumbnailFile ? URL.createObjectURL(thumbnailFile)
        : asset?.thumbnail?.url), [ thumbnailFile, asset?.thumbnail?.url ]);

    const onTitleUpdate = useCallback((val: string) => {
        setTitle(val);
    }, []);

    const updateCustomAssetPreview = async (src: string) => {
        const json = await fetch(asset?.attachment.url).then((r) => r.json());
        const configPath = "/src/config.json";
        const newJson = { ...json };

        const configModule = newJson[ configPath ];
        if (configModule) {
            const configCode = JSON.parse(configModule.code);
            configCode.previewSrc = src;
            configModule.code = JSON.stringify(configCode);
        }
        return new File([ JSON.stringify(newJson) ], title, { type: "application/json" });
    };

    const onUpdate = async () => {
        setIsLoading(true);

        const payload = {
            title,
            description,
            file,
            thumbnailFile,
            posted_by: userName || "*username*"
        };

        if (asset && title) {
            await asset.update(payload);
        } else if (title && file) {
            await AssetLibraryState.createLibraryAsset(file, title, description, payload.posted_by);
        }

        setIsLoading(false);
        onBackClicked();
    };
    return (
        <Wrapper style={ { minWidth: "928px" } }>
            <Wrapper padding="md">
                <LibraryTitle />
                <Wrapper
                    radius="xxl"
                    padding="xs"
                    frame="solid-grey"
                    row
                    valign="center"
                    style={ { fontSize: "12px" } }
                >
                    <Wrapper row align="space-between">
                        <Button
                            size="qsm"
                            viewType="white"
                            iconMargin="xxs"
                            align="left"
                            radius="lg"
                            uppercase
                            onClick={ onBackClicked }
                            icon="arrowLeft"
                        >
                            Back to Library
                        </Button>
                        <Button
                            size="sm"
                            align="center"
                            radius="lg"
                            uppercase
                            disabled={ !!error || isLoading || (!file && !asset) }
                            onClick={ onUpdate }
                        >
                            Update
                        </Button>
                    </Wrapper>
                </Wrapper>
            </Wrapper>

            <Wrapper row padding="lg" style={ { paddingTop: "8px" } }>
                <Wrapper
                    margin="lg"
                    style={ { height: "200px" } }
                    maxWidth="400px"
                    radius="xxl"
                    align="center"
                    valign="center"
                    frame="solid-grey"
                >
                    { isImage ? (
                        <MediaContent
                            fallback={ <LoadingSpinnerCentered /> }
                            url={ file ? URL.createObjectURL(file) : asset?.attachment?.url || "" }
                            size="xxxl"
                            fit="cover"
                            radius="xxl"
                            type="image"
                            ratio="ratio-2-1"
                        />
                    ) : <Icon size={ 48 } name="addImage" color="shade-5" /> }
                </Wrapper>
                <InputTable>
                    <InputTableItem ratio={ 0.3 } label="Title" viewType="secondary" weight="medium" size="sm">
                        <FormItem
                            margin="off"
                            autoFocusDetect
                            error={
                                (error === 'required' && (
                                    'This field is required'
                                ))
                            }
                        >
                            <Input
                                size="md"
                                fullWidth
                                value={ title }
                                placeholder="Untitled Asset"
                                onChange={ (e) => {
                                    const validatedError = validate(e.target.value);
                                    if (!validatedError) {
                                        error && setError('');
                                    } else if (error !== validatedError) {
                                        setError(validatedError);
                                    }
                                    onTitleUpdate(e.target.value);
                                } }
                            />
                        </FormItem>
                    </InputTableItem>
                    <InputTableItem
                        ratio={ 0.3 }
                        weight="medium"
                        valign="start"
                        label="Thumbnail"
                        viewType="secondary"
                        size="sm"
                    >
                        <DropImageForm
                            dropRootProps={ { styles: { height: "96px", maxWidth: "192px", minWidth: "192px" } } }
                            onDrop={ async (_file) => {
                                setThumbnail(_file);
                            } }
                            url={ thumbnailUrl }
                            description="Upload or generate thumbnail"
                            action={ !asset?.attachment?.url?.toLowerCase()?.endsWith(".mp3")
                                ? (
                                    <Button
                                        disabled={ !file && !asset?.attachment?.url }
                                        uppercase
                                        viewType="secondary"
                                        radius="lg"
                                        size="sm"
                                        onClick={ async () => {
                                            const modelFile = file
                                                // @ts-ignore
                                                || await getFileFromUrl(asset?.attachment?.url, asset?.attachment?.url.split("/").pop());
                                            const modelBlob = await generateTeamLibraryModelPreview(modelFile);
                                            const thumbnail = new File([ modelBlob ], "Preview", { type: "image/png" });
                                            setThumbnail(thumbnail);
                                        } }
                                    >
                                        Generate
                                    </Button>
                                ) : null }
                        />
                    </InputTableItem>
                    <InputTableItem
                        ratio={ 0.3 }
                        weight="medium"
                        valign="center"
                        label="File"
                        viewType="secondary"
                        size="sm"
                    >
                        <Uploader
                            label="REPLACE FILE"
                            viewType="secondary"
                            skipTypeValidation
                            handleUpload={ (fileValue) => {
                                setFile(fileValue as any);
                            } }
                            name="assets-library"
                        />
                    </InputTableItem>
                    <InputTableItem
                        ratio={ 0.3 }
                        weight="medium"
                        valign="center"
                        label="File URL"
                        viewType="secondary"
                        size="sm"
                    >
                        <Wrapper row valign="center" style={ { fontSize: "inherit" } }>
                            <Input
                                margin="xs"
                                size="sm"
                                fullWidth
                                disabled
                                value={ asset?.attachment?.url || "" }
                            />
                            <Button
                                icon="copy"
                                viewType="secondary"
                                radius="sm"
                                size="sm"
                                onClick={ () => navigator.clipboard.writeText(asset?.attachment?.url || "") }
                            />
                        </Wrapper>
                    </InputTableItem>
                </InputTable>
            </Wrapper>
        </Wrapper>
    );
});
