import React from 'react';

// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '27',
    height = '18',
    fill = '#57E7D4',
    style
}: SvgIconProps) {
    return (
        <svg width={ width } height={ height } viewBox="0 0 27 18" fill="none" style={ style }>
            <path
            // eslint-disable-next-line max-len
                d="M8.44107 4.07351L16.7799 1.56183V1H18.5439V13.8228H16.7799V13.1288L8.28071 10.551H4.17543L3.08496 9.09689V5.23021L4.17543 4.07351H8.44107Z"
                stroke={ fill }
                strokeWidth="1.2"
                strokeLinejoin="round"
            />
            <path
            // eslint-disable-next-line max-len
                d="M8.12012 4.17267V10.4849"
                stroke={ fill }
                strokeWidth="1.2"
            />
            <path
            // eslint-disable-next-line max-len
                d="M3.02074 5.62681C3.02074 5.62681 2.18529 5.39457 1.73784 5.62681C1.11202 5.95163 1.00524 6.59024 1.00017 7.31228C0.995025 8.04611 1.10107 8.70218 1.73784 9.03081C2.1858 9.26199 3.02074 9.03081 3.02074 9.03081"
                stroke={ fill }
                strokeWidth="1.2"
            />
            <path
            // eslint-disable-next-line max-len
                d="M4.84896 10.6832C4.84896 10.6832 2.73217 14.9465 2.7001 15.3761C2.66804 15.8058 2.63157 16.1445 2.89254 16.4998C3.13933 16.8358 3.41491 16.9631 3.82264 16.9955C4.36998 17.039 4.75274 16.7642 5.07347 16.3345C5.39419 15.9049 7.86378 10.6832 7.86378 10.6832"
                stroke={ fill }
                strokeWidth="1.2"
                strokeLinecap="round"
            />
            <path
            // eslint-disable-next-line max-len
                d="M18.5439 5.26324C18.5439 5.26324 19.7064 5.80171 19.9872 6.45299C20.1724 6.88262 20.1512 7.4775 19.9872 8.03932C19.7472 8.86155 18.5439 9.39431 18.5439 9.39431"
                stroke={ fill }
                strokeWidth="1.2"
                strokeLinecap="round"
            />
            <path
            // eslint-disable-next-line max-len
                d="M25.1032 1.76813L21.9932 4.31287"
                stroke={ fill }
                strokeWidth="1.2"
                strokeLinecap="round"
            />
            <path d="M22.3604 7.31226H25.4704" stroke={ fill } strokeWidth="1.2" strokeLinecap="round" />
            <path d="M21.9932 10.5811L25.1032 13.0598" stroke={ fill } strokeWidth="1.2" strokeLinecap="round" />
        </svg>
    );
}
