import React           from 'react';
import { Description } from '@geenee/ui';
import { PillRoot }    from './styles';

// eslint-disable-next-line no-shadow
export enum PillType {
  LIVE = 'LIVE',
  NFT_LIVE = 'NFT_LIVE',
  DRAFT = 'DRAFT'
}

interface Props {
  type: PillType;
}

export function Pill({ type }: Props) {
    return (
        <PillRoot type={ type }>
            <Description size='sm'>
                { type.replace('_', ' ') }
            </Description>
        </PillRoot>
    );
}
