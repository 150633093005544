import React                    from 'react';
import { Description, Wrapper } from '@geenee/ui';
import StyledButton             from '@geenee/builder/src/components/common/Button';
import { HighlightContainer }   from './styles';

interface Props {
  url: string;
}

export function StatisticsColumn({ url }: Props) {
    return (
        <Wrapper fullWidth maxWidth='154px'>
            <Wrapper valign='center' align='center' margin='xxs' style={ { height: 40 } }>
                <HighlightContainer>
                    <Description size='sm' weight='bold' style={ { fontFamily: 'Euclid Circular A' } }>
                        THIS IS LIVE
                    </Description>
                </HighlightContainer>
            </Wrapper>
            <Wrapper margin='md'>
                <StyledButton
                    $gradient
                    $padding="0"
                    $justify="center"
                    $width="100%"
                    $height="40px"
                    onClick={ () => {
                        window.open(url);
                    } }
                    label="VIEW ON MINTABLE"
                />
            </Wrapper>
            { /* <InfoContainer>
            <Wrapper fullWidth>
                <Description size='sm'>Views: 1000 </Description>
                <Description size='sm'>This Month: 1000</Description>
                <Description size='sm'>Bids: 100</Description>
                <Description size='sm'>End Date: 1/21</Description>
            </Wrapper>
        </InfoContainer> */ }
        </Wrapper>
    );
}
