import { useInject }             from '@geenee/shared';
import useComposerLocationParser from '@geenee/shared/src/util/useComposerLocationParser';
import { useBuilderInject }      from '@geenee/builder/src/core/hook/use-builder-inject';
import { accountState }          from '@geenee/builder/src/core/state/account.state';
import { PUBLISHING_DOMAIN_URL } from '@geenee/builder/src/lib/constants';
import { companyUrlConstructor } from '@geenee/builder/src/pages/CompanyPage/components/CompanyPageContent';

export const useGenerateUrlHelper = () => {
    const { AppState } = useInject();
    const companyUrl = accountState.slug;
    const { publishing_domain } = accountState;
    const mainProgram = AppState.activeProject;
    const { experienceId } = useComposerLocationParser();
    const { BuilderState } = useBuilderInject();
    const { profile }               = BuilderState.currentUser;

    const getPublishLink = async (published?: boolean) => {
        // @ts-ignore
        const { published_name } = await mainProgram?.publish(published === undefined ? !mainProgram?.published : published);
        const publishing_domain_new = publishing_domain
            || companyUrlConstructor(accountState.name || '')
            // @ts-ignore
            || companyUrlConstructor(profile.email || '');

        if (mainProgram?.published) {
            // fill publishing domain in case it wasn't set by the user yet
            if (publishing_domain !== publishing_domain_new) {
                const updObj: any = { publishing_domain: publishing_domain_new };
                if (!accountState.name) {
                    updObj.name = publishing_domain_new;
                }
                await accountState.update(updObj);
            }
        }
        return publishing_domain_new && published_name
            // eslint-disable-next-line max-len
            ? `https://${ publishing_domain_new }${ PUBLISHING_DOMAIN_URL }/${ experienceId ? window.location.pathname.slice(1) : published_name }`
            : 'https://project-company.geenee.ar';
    };

    const generatePublishLink = async (slug: string) => {
        if (companyUrl && !publishing_domain) {
            // @ts-ignore
            return getPublishLink();
        }

        return publishing_domain && slug
            // eslint-disable-next-line max-len
            ? `https://${ publishing_domain }${ PUBLISHING_DOMAIN_URL }/${ experienceId ? window.location.pathname.slice(1) : slug }`
            : 'https://project-company.geenee.ar';
    };

    return { generatePublishLink, getPublishLink };
};
