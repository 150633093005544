import React, { FC }                   from 'react';
import { IconAtomComponent }           from '@geenee/geeclient-kit/src/lib/component/atom/icon/icon.atom.component';
import { ImageAtomComponent }          from "@geenee/geeclient-kit/src/lib/component/atom/image/image.atom.component";
import { ImageAtomOptType }            from "@geenee/geeclient-kit/src/lib/component/atom/image/util";
import { getPropsFromOwner }           from '@geenee/geeclient-kit/src/lib/component/atom/link/util';
import { Link }                        from '@geenee/geeclient-kit/src/lib/component/routing/link';
import { withMoleculeContext }         from '@geenee/geeclient-kit/src/lib/hoc/withMoleculeContext';
import { ColorVariationExtensionType } from '@geenee/geeclient-kit/src/lib/type/_extension-type';
import { cnKitAtom }                   from '@geenee/geeclient-kit/src/lib/util/bem';
import { compose }                     from '@geenee/geeclient-kit/src/lib/util/compose';
import { withProps }                   from '@geenee/geeclient-kit/src/lib/util/withProps';
import { LinkAtomOptType }             from '../../../type/_atom-option-type';
import { SiteMoleculeSchemaType }      from '../../../type/section-molecule';
import { CallbackFunctionVariadic }    from '../../../type/type';
import { PlainTextView }               from '../plain-text/plain-text.atom.component';
import { initProps as plaintextProps } from '../plain-text/util';
import { initProps }                   from './util';
import './link.scss';

export type LinkAtomPropsType = {
  to?: string;
  children?: React.ReactNode;
  blank?: '_blank';
  onClick: CallbackFunctionVariadic;
  color_auto_variant: ColorVariationExtensionType
  imageProps?: ImageAtomOptType;
} & LinkAtomOptType;

const className = cnKitAtom('atom-link');

export const LinkView: FC<LinkAtomPropsType> = (props: LinkAtomPropsType) => {
    const getColorDBVariant = () => {
        if (props.link_color_bg_type === 'auto') {
            return props.color_auto_variant;
        }
        return props.link_color_bg_variation;
    };

    const getColorBorderVariant = () => {
        if (props.link_color_border_type === 'auto') {
            return props.color_auto_variant;
        }
        return props.link_color_border_variation;
    };

    const getLinkRadius = () => {
        const radius = (props.link_corner_radius === 'custom')
            ? `${ props.link_corner_radius_custom }px`
            : `${ props.link_corner_radius }px`;
        return { '--atom-link__border-radius': radius };
    };

    const getCustomBlurRadius = () => (props.link_text_switch
    && props.link_blur_radius === 'custom' && props.link_blur_radius_custom ? { '--blur-radius': `${ props.link_blur_radius_custom }px` } : {});

    const getStyle = () => {
        const colorObj =  {
            '--color-bg': props.link_color_bg_type === 'custom'
                ? props.link_color_bg_custom
                : undefined,
            '--color-text': props.link_color_text_type === 'custom'
                ? props.link_color_text_custom
                : undefined,
            '--color-util': props.link_color_border_type === 'custom'
                ? props.link_color_border_custom
                : undefined
        };
        return Object.assign(
            colorObj,
            getLinkRadius(),
            getCustomBlurRadius(),
            Object.keys(props.imageProps || {}).length ? { padding: '0', margin: '0' } : {}
        );
    };

    const renderIcon = () => props.link_icon_geenee && (
        <IconAtomComponent
            color_auto_variant="harmonic"
            icon_switch={ props.link_icon_switch }
            icon_geenee={ props.link_icon_geenee }
            icon_size={ props.link_icon_size }
            icon_style={ props.link_icon_style }
            icon_color_text={ props.link_icon_color_text }
            icon_color_text_alpha={ props.link_icon_color_text_alpha }
            icon_color_text_custom={ props.link_icon_color_text_custom }
            icon_color_text_type={ props.link_icon_color_text_type }
            icon_color_text_variation={ props.link_icon_color_text_variation }
            icon_color_bg={ props.link_icon_color_bg }
            icon_color_bg_alpha={ props.link_icon_color_bg_alpha }
            icon_color_bg_custom={ props.link_icon_color_bg_custom }
            icon_color_bg_type={ props.link_icon_color_bg_type }
            icon_color_bg_variation={ props.link_icon_color_bg_variation }
            icon_color_border={ props.link_icon_color_border }
            icon_color_border_alpha={ props.link_icon_color_border_alpha }
            icon_color_border_custom={ props.link_icon_color_border_custom }
            icon_color_border_type={ props.link_icon_color_border_type }
            icon_color_border_variation={ props.link_icon_color_border_variation }
            icon_blur_radius={ props.link_icon_blur_radius }
            icon_blur_radius_custom={ props.link_icon_blur_radius_custom }
            icon_corner_radius={ props.link_icon_corner_radius }
            icon_corner_radius_custom={ props.link_icon_corner_radius_custom }
            icon_indent={ props.link_icon_indent }
        />
    );

    const renderText = () => !!props.link_text?.length && (
        <span id="link-text">
            <PlainTextView
                { ...plaintextProps }
                plain_text_font_uppercase={ props.link_text_font_uppercase }
                plain_text_switch={ !!props.link_text.length && props.link_text_switch }
                plain_text={ props.link_text }
                plain_text_cursor="pointer"
                plain_text_font_weight={ props.link_text_font_weight }
                plain_text_font_type={ props.link_text_font_type }
            />
            <PlainTextView
                { ...plaintextProps }
                plain_text_font_uppercase={ props.link_text_font_uppercase }
                plain_text_switch={ !!props.link_subtitle.length && props.link_text_switch }
                plain_text_font_size={ 12 }
                plain_text={ props.link_subtitle }
                plain_text_cursor="pointer"
            />
        </span>
    );

    const renderImage = () => !!Object.keys(props.imageProps || {}).length && props.imageProps?.image_src && (
        <ImageAtomComponent
            { ...props.imageProps }
        />
    );

    return (
        <Link
            className={ className(
                '',
                {
                    type:                            props.link_type,
                    [ `type-${ props.link_type }` ]: props.link_text_switch ? props.link_blur_radius : undefined,
                    size:                            props.link_size,
                    align:                           props.link_align,
                    valign:                          props.link_valign,
                    icon:                            props.link_icon_switch,
                    title:                           !!props.link_text.length,
                    subtitle:                        !!props.link_subtitle.length,
                    rounded:                         props.link_corner_radius === '100',
                    padding:                         props.link_padding,
                    hover:                           props.link_hover
                }
            ) }
            data-atom="link"
            data-color-util={ props.link_color_border }
            data-color-util-alpha={ props.link_color_border_alpha }
            data-color-util-type={ props.link_color_border_type }
            data-color-util-variation={ getColorBorderVariant() }
            data-color-text={ props.link_color_text }
            data-color-text-alpha={ props.link_color_text_alpha }
            data-color-text-type={ props.link_color_text_type }
            data-color-text-variation={ props.link_color_text_variation }
            data-color-bg={
                props.link_type === 'solid' || props.link_type === 'blur' ? props.link_color_bg : undefined
            }
            data-color-bg-alpha={ props.link_color_bg_alpha }
            data-color-bg-type={
                props.link_type === 'solid' || props.link_type === 'blur' ? props.link_color_bg_type : undefined
            }
            data-color-bg-variation={ getColorDBVariant() }
            style={ getStyle() }
            onClick={ props.onClick }
            to={ props.link_href }
            tel={ props.link_tel }
            mailTo={ props.link_mail_to }
            type={ props.link_action_type }
            scrollSectionId={ props.link_scroll }
            modalSectionId={ props.link_modal }
            animateScroll={ props.link_animate_scroll }
            blank={ props.link_blank }
        >
            { props.link_icon_after ? (
                <>
                    { renderText() }
                    { renderIcon() }
                    { renderImage() }
                </>
            )
                : (
                    <>
                        { renderIcon() }
                        { renderText() }
                        { renderImage() }
                    </>
                ) }
        </Link>
    );
};

LinkView.defaultProps = { ...initProps } as Partial<LinkAtomPropsType>;

const enhance = compose(
    withMoleculeContext,
    withProps(
        ({
            moleculeContext,
            ...extendProps
        }: {
      moleculeContext: SiteMoleculeSchemaType<LinkAtomOptType>;
    }) => getPropsFromOwner(moleculeContext.options, extendProps)
    )
);

export const LinkAtomComponent = enhance(LinkView);
