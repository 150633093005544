import React, { useEffect, useRef, useState } from 'react';
import { AtomModel }                          from "@geenee/shared/src/magellan/atom/model/atom.model";
import { observer }                           from "mobx-react";
import {
    DoubleSide, Mesh, MeshBasicMaterial,     Object3D, PlaneGeometry,
    Texture,
    TextureLoader
} from 'three';
// import { GLTF }                               from 'three/examples/jsm/loaders/GLTFLoader';

interface Props {
    currentModel?: AtomModel | null
}

const texturesCache = new Map();
// We need to convert image pixels size to the Canvas scene units
// For those reasons I use this formula units = pixels / DELIMETER
// DELIMETER is a  special constant that was saved after some of the experiments
// with images (width = BASIC_WIDTH). With this constant the image has right scale on the scene.
export const DELIMETER = 350;
export const BASIC_WIDTH = 699;
export const TrackerImagePlane = observer(({ currentModel }: Props) => {
    const url = currentModel?.assetUrl;
    const [ visible, setVisible ] = useState(false);
    const meshRef = useRef<Mesh>();

    useEffect(() => {
        if (currentModel?.sceneObjectVisible) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [ currentModel?.sceneObjectVisible ]);
    const createMeshFromTheTexture = (tex: Texture) => {
        const mesh = meshRef.current;
        if (!mesh) return;
        mesh.material = new MeshBasicMaterial({
            map:  tex,
            side: DoubleSide
        });
        // As the DELIMETER saved for only BASIC_WIDTH image, we need to calculate the new value,
        // but for the new image sizes
        const newDelimeter = (DELIMETER * tex.image.width) / BASIC_WIDTH;
        const planeHeight = tex.image.height / newDelimeter;
        const planeWidth = tex.image.width / newDelimeter;
        mesh.scale.set(planeWidth, planeHeight, 1);
        setVisible(true);
    };

    useEffect(() => {
        if (url) {
            if (texturesCache.get(url)) {
                createMeshFromTheTexture(texturesCache.get(url));
                return;
            }
            const loader = new TextureLoader();
            loader.loadAsync(url).then((tex) => {
                createMeshFromTheTexture(tex);
                texturesCache.set(url, tex);
            });
        }
    }, [ url ]);

    if (!url) return <></>;

    return (
        <mesh
            visible={ visible }
            ref={ meshRef }
            geometry={ new PlaneGeometry() }
        />
    );
});
