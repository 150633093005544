import React              from 'react';
import { useTheme }       from 'styled-components';
import ChevronLeft        from '@geenee/builder/src/asset/icons/ChevronLeft';
import ChevronRight       from '@geenee/builder/src/asset/icons/ChevronRight';
import { ChevronWrapper } from '@geenee/builder/src/components/Tree/styles';

interface Props {
  onTogglePanel?: () => void;
  toggle?: boolean;
  mode?: 'left'| 'right'
}

export function PanelToggler({ onTogglePanel, toggle, mode = 'right' }: Props) {
    const { colors } = useTheme();
    return ((mode === 'right' ? !toggle : toggle) ? (
        <ChevronWrapper className="form-panel-toggler" right mode={ mode } onClick={ onTogglePanel }>
            <ChevronRight width="8px" height="14px" fill={ colors.black } />
        </ChevronWrapper>
    ) : (
        <ChevronWrapper className="form-panel-toggler" mode={ mode } onClick={ onTogglePanel }>
            <ChevronLeft width="8px" height="14px" fill={ colors.black } />
        </ChevronWrapper>
    ));
}
