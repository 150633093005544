import React from 'react';

export type CheckboxCheckedPropsType = {
    width?: string;
    height?: string;
    onClick?: () => void;
    id?: string;
    activated? :boolean;
    fill?: string;
    beIcon?: boolean;
    children?: React.ReactNode;
}

export function CheckboxChecked({
    width = '20',
    height = '20',
    onClick,
    id,
    activated = false,
    fill = 'black',
    beIcon = false,
    children
}: CheckboxCheckedPropsType) {
    const setActivationStatusColors = (activated: boolean, beIcon: boolean) => {
        if (beIcon) {
            return {
                circle:  fill,
                outline: fill,
                tick:    'white'
            };
        }
        if (activated) {
            return {
                circle:  'black',
                outline: 'black',
                tick:    'white'
            };
        }
        return {
            circle:  'white',
            outline: 'black',
            tick:    'black'
        };
    };
    const checkboxColors = setActivationStatusColors(activated, beIcon);

    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            onClick={ onClick }

        >
            <circle cx="10" cy="10" r="10" className='fill' fill={ checkboxColors.outline } />
            <circle cx="10" cy="10" r="9" className='fill' fill={ checkboxColors.circle } />
            <path
                d="M5 11L7.92929 13.9293C7.96834 13.9683 8.03166 13.9683 8.07071 13.9293L15 7"
                stroke={ checkboxColors.tick }
                strokeWidth="1.7"
                strokeLinecap="round"
            />
            { children }
        </svg>
    );
}

export type CheckboxUncheckedPropsType = {
    width?: string;
    height?: string;
    onClick?: () => void;
    id?: string;
    fill?: string;
    children?: React.ReactNode;
}

export function CheckboxUnchecked() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path className="fill" fillRule="evenodd" clipRule="evenodd" d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z" fill="#3E3B46" />
        </svg>
    );
}

export function CheckboxUncheckedNew({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7ZM7 5C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7Z"
                fill="#222225"
            />
            { children }
        </svg>
    );
}

export function CheckboxCheckedNew({ children }: {children?: React.ReactNode}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                className="fill"
                d="M9 8C8.44772 8 8 8.44772 8 9V15C8 15.5523 8.44772 16 9 16H15C15.5523 16 16 15.5523 16 15V9C16 8.44772 15.5523 8 15 8H9Z"
                fill="#222225"
            />
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 3C4.79086 3 3 4.79086 3 7V17C3 19.2091 4.79086 21 7 21H17C19.2091 21 21 19.2091 21 17V7C21 4.79086 19.2091 3 17 3H7ZM5 7C5 5.89543 5.89543 5 7 5H17C18.1046 5 19 5.89543 19 7V17C19 18.1046 18.1046 19 17 19H7C5.89543 19 5 18.1046 5 17V7Z"
                fill="#222225"
            />
            { children }
        </svg>
    );
}
