import React                      from 'react';
import { LoadingSpinnerCentered } from "@geenee/shared/src/magellan/components/LoadingSpinner";
import { Html }                   from '@react-three/drei';

function LoadingScene() {
    return (
        <>
            <Html position={ [ -0.33, 0.33, 0 ] }>
                <LoadingSpinnerCentered />
            </Html>
        </>
    );
}

// eslint-disable-next-line arca/no-default-export
export default LoadingScene;
