export const sortList = [
    {
        value: '',
        label: 'Relevance'
    },
    {
        value: 'likeCount',
        label: '+ Like Count'
    },
    {
        value: '-likeCount',
        label: '- Like Count'
    },
    {
        value: 'viewCount',
        label: '+ View Count'
    },
    {
        value: '-viewCount',
        label: '- View Count'
    },
    {
        value: 'publishedAt',
        label: '+ Published At'
    },
    {
        value: '-publishedAt',
        label: '- Published At'
    },
    {
        value: 'processedAt',
        label: '+ Processed At'
    },
    {
        value: '-processedAt',
        label: '- Processed At'
    }
];
