import React from 'react';
// eslint-disable-next-line arca/no-default-export
export default function ({
    width = '12',
    height = '12',
    onClick,
    id
}: SvgIconProps) {
    return (
        <svg
            fill="none"
            id={ id }
            width={ width }
            height={ height }
            onClick={ onClick }
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="6" cy="6" r="5.5" fill="white" stroke="#BBBBBB" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M9.01363 5.72014L4.34696 2.72014C4.12512 2.57753 3.83337 2.73681 3.83337 3.00054V9.00054C3.83337 9.26426 4.12512 9.42354 4.34696 9.28093L9.01363 6.28093C9.21773 6.14972 9.21773 5.85135 9.01363 5.72014ZM8.21696 6.00053L4.50004 8.38997V3.61108L8.21696 6.00053Z"
                fill="black"
            />
        </svg>
    );
}
