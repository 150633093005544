import React, { useMemo }       from 'react';
import { Description }          from '@geenee/ui';
import { useLocalObservable }   from "mobx-react";
import { ModalProps }           from "@geenee/builder/src/components/ModalConfirmationContent";
import { SDKTokenModel }        from "@geenee/builder/src/core/model/sdktoken.model";
import { sdkTokenState }        from "@geenee/builder/src/core/state/sdkToken.state";
import { URL_REGEX }            from "@geenee/builder/src/pages/SDKAccessPage/components/CreateToken";
import { EditModalDescription } from "@geenee/builder/src/pages/SDKAccessPage/components/EditModalDescription";
import { useSDKModalDisplay }   from './useSDKModalDisplay';

export type SDKModalProps = {
  item: SDKTokenModel
  mode: 'remove' | 'update'
}

export const useSDKModal = ({ item, mode }: SDKModalProps) => {
    const hostUrl = useLocalObservable(() => ({
        url: item.origin || null,
        onChange(val: string) {
            this.url = val;
        }
    }));

    const options: ModalProps = useMemo(() => {
        if (mode === 'remove') {
            return {
                iconName:          'remove',
                confirmButtonName: 'Delete',
                description:       (
                    <Description
                        color="shade-4"
                        margin="xs"
                        align="center"
                    >
                        This action cannot be undone, but you can create a new SDK token. Make sure you are doing it on purpose.
                    </Description>
                ),
                onConfirm: async () => {
                    await sdkTokenState.deleteToken(item.id);
                    return true;
                },
                title: 'Are you sure you want to delete token?'
            };
        }

        return {
            iconName:          'edit2',
            confirmButtonName: 'Update',
            description:       (
                <EditModalDescription onChange={ hostUrl.onChange } token={ item.key } />
            ),
            onConfirm: async () => {
                if (!hostUrl.url) return false;

                // Note: on the db side we are saving URL hostname and pathname so there is a need
                // to reassemble the URL on FE side
                let hostname = hostUrl.url;
                let pathname = '';

                const strIdx = hostUrl.url.indexOf('/');

                if (strIdx !== -1) {
                    hostname = hostUrl.url.slice(0, strIdx);
                    pathname = hostUrl.url.slice(strIdx);
                }

                const checkUrlString = `http://${ hostname }${ pathname }`;

                if (!URL_REGEX.test(checkUrlString)) {
                    return false;
                }
                let checkUrl: null | URL = null;

                try {
                    checkUrl = new URL(checkUrlString);
                } catch (parseError) {
                    return false;
                }

                if (checkUrl) {
                    let saveUrlString = checkUrl.hostname + checkUrl.pathname;
                    // Note: on WA side there is a normalization and there is a need to do it on the FE too
                    if (saveUrlString.endsWith('/')) {
                        saveUrlString = saveUrlString.slice(0, -1);
                    }
                    await sdkTokenState.updateToken(item, saveUrlString);
                    return true;
                }

                return false;
            },
            title: 'Update SDK token'
        };
    }, [ item, mode ]);
    const [ showModal ] = useSDKModalDisplay(options);

    return [ showModal ];
};
