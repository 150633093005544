import React, { FC, useEffect, useMemo, useRef  } from 'react';
import { extend, useFrame }                       from '@react-three/fiber';
import { observer }                               from 'mobx-react';
import {
    Clock, MeshStandardMaterial,     RepeatWrapping, TextureLoader,
    Vector3
}                       from 'three';
import { TextGeometry }                                from 'three/examples/jsm/geometries/TextGeometry';
import { FontLoader }                                  from 'three/examples/jsm/loaders/FontLoader';
import { DefaultLoadingManager }                       from "three/src/loaders/LoadingManager";
import defaultJSONFont                                 from '../../assets/fonts3d/Gordita_Regular.json';
import { setCastShadow, setEnvMap, setRecieveShadow  } from '../../utils/sdk/SceneObjectsUtils';
import ShaderRuntime                                   from '../../utils/sdk/shaderFrog';
import {
    DEFAULT_BEVEL_OPTIONS,     DEFAULT_MATERIAL_OPTIONS,
    DEFAULT_TEXT_SIZE,
    DEFAULT_TEXT3D_BODY,
    DEFAULT_TEXT3D_CURVE_SEGMENTS,
    TEXT3D_HEIGHT_MULTIPLIER,
    TEXT3D_Z_INDEX_GAP
}                       from './constants';
import { Text3DComponentPropsType } from './types';

export const Text3D: FC<Text3DComponentPropsType> = observer(({
    atomModel,
    position,
    scale,
    visible,
    rotation,
    loadedCallback
}) => {
    const extended = useMemo(() => extend({ TextGeometry }), []);
    const clock = useMemo(() => new Clock(), []);
    const textRef = useRef(null);
    const body = atomModel.options.atom_text3d_body || DEFAULT_TEXT3D_BODY;
    const textSize = atomModel.options.atom_text3d_font_size || DEFAULT_TEXT_SIZE;
    const fontType = useMemo(
        () => (
            new FontLoader().parse(atomModel.options.atom_text3d_font_type || defaultJSONFont)),
        [ atomModel.options.atom_text3d_font_type ]
    );
    const bevelOptions = useMemo(
        () => (
            atomModel.options.atom_text3d_bevel_options || DEFAULT_BEVEL_OPTIONS),
        [ atomModel.options.atom_text3d_bevel_options ]
    );
    const materialOptions = useMemo(
        () => (
            atomModel.options.atom_text3d_material_options || DEFAULT_MATERIAL_OPTIONS),
        [ atomModel.options.atom_text3d_material_options ]
    );
    const shaderTexture = atomModel.options.atom_text3d_shader_texture;
    const shaderLoader = atomModel.options.atom_text3d_shader_texture ? new ShaderRuntime() : false;
    const disableCastShadow = atomModel.options.scene_atom_disable_cast_shadow;
    const disableReceiveShadow = atomModel.options.scene_atom_disable_receive_shadow;

    const fontConfig = useMemo(() => ({
        font:           fontType,
        size:           textSize,
        height:         textSize * TEXT3D_HEIGHT_MULTIPLIER,
        curveSegments:  DEFAULT_TEXT3D_CURVE_SEGMENTS,
        bevelEnabled:   bevelOptions.enabled,
        bevelThickness: bevelOptions.thickness,
        bevelSize:      bevelOptions.size,
        bevelOffset:    bevelOptions.offset,
        bevelSegments:  bevelOptions.segments
    }), [ fontType, textSize, bevelOptions ]);

    const loadTexture = () => {
        if (!materialOptions.map) return null;
        new TextureLoader().load(materialOptions.map, (texture) => {
            const newTexture = texture;
            newTexture.wrapS = RepeatWrapping;
            newTexture.wrapT = RepeatWrapping;
            newTexture.repeat.set(3, 3);
            return newTexture;
        });
    };

    const loadShaderTexture = () => {
        shaderLoader.load([ shaderTexture ], (shaders: Array<any>) => {
            textRef.current.material = shaderLoader.get(shaders[ 0 ].name);
            textRef.current.material.transparent = true;
            // TODO: investigate shader
            // Callback to give access for the shader material customisations
            // if (shaderMaterialEdits) {
            //     shaderMaterialEdits(textRef.current.material);
            // }
        });
    };

    const createMaterial = () => {
        textRef.current.material = new MeshStandardMaterial({
            color:       materialOptions.color,
            map:         loadTexture(),
            transparent: true,
            opacity:     materialOptions.opacity,
            roughness:   materialOptions.roughness,
            metalness:   materialOptions.metalness
        });
    };

    useEffect(() => {
        shaderTexture ? loadShaderTexture() : createMaterial();

        if (loadedCallback) {
            loadedCallback(true);
            DefaultLoadingManager.onLoad && DefaultLoadingManager.onLoad();
        }
    }, [ ]);

    const updateTextPosition = () => {
        if (!textRef?.current?.material?.transparent || !position) return;
        textRef.current.renderOrder = position[ 2 ].toFixed(TEXT3D_Z_INDEX_GAP) * 10 ** TEXT3D_Z_INDEX_GAP;
    };

    useEffect(() => {
        updateTextPosition();
    }, [ position ]);

    const updateTextMaterial = () => {
        if (!textRef?.current?.material) return;

        textRef.current.material.color.set(materialOptions.color);
        textRef.current.material.opacity = +materialOptions.opacity;
        textRef.current.material.roughness = +materialOptions.roughness;
        textRef.current.material.metalness = +materialOptions.metalness;
    };
    useEffect(() => {
        updateTextMaterial();
    }, [ materialOptions.color, materialOptions.roughness, materialOptions.metalness, materialOptions.opacity ]);

    const updateCameraPosition = () => {
        if (!textRef.current) return;
        const self = textRef.current;
        const { geometry } = self;
        geometry.computeBoundingBox();
        const centerOffset = new Vector3(
            -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x),
            0,
            -0.5 * (geometry.boundingBox.max.z - geometry.boundingBox.min.z)
        );
        self.position.copy(centerOffset);
        setEnvMap(self);
    };
    useEffect(() => {
        updateCameraPosition();
    }, [ textRef.current, position, rotation, scale, body ]);

    useEffect(() => {
        if (!textRef.current) return;
        setCastShadow(textRef.current, !disableCastShadow);
        setRecieveShadow(textRef.current, !disableReceiveShadow);
    }, [ textRef.current, disableCastShadow, disableReceiveShadow ]);

    useFrame(() => {
        if (shaderLoader) {
            const time = clock.getElapsedTime();
            shaderLoader.updateShaders(time);
        }
    });

    return (
        <group
            position={ position }
            rotation={ rotation }
            scale={ scale }
        >
            <mesh
                ref={ textRef }
                dispose={ null }
                // visible={ visible }
                name="geenee-3d-text3d-mesh"
                castShadow
            >
                <textGeometry attach="geometry" args={ [ body, fontConfig ] } />
            </mesh>
        </group>
    );
});
