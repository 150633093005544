import React, { FC, memo, useMemo } from 'react';
import { cn }                       from '../../util/bem';
import { Tooltip }                  from "../tooltip/tooltip.component";
import { MenuPropsType }            from "./menu.component";
import './menu-item.component.scss';

export type MenuItemPropsType = {
    id: string;
    label?: React.ReactNode;
    iconBefore?: React.ReactNode;
    iconAfter?: React.ReactNode;
    disabled?: boolean;
    selected?: boolean;
    onSelect?: (e: React.MouseEvent, id: string) => void;
    /** - `option` or `menuitem` default is `menuitem` */
    role?: string;
    hasTooltip?: boolean;
    weight?: 'normal' | 'bold';
    children: React.ReactNode;
    size?: MenuPropsType['size'];
}

type MenuItemAttrsType = {
    role?: string;
    'aria-disabled'?: boolean;
    'aria-selected'?: boolean;
}

const className = cn('menu-item');

export const MenuItem: FC<MenuItemPropsType> = memo((props) => {
    const handleOnSelect = (e: React.MouseEvent) => {
        if (!props.disabled) {
            if (props.onSelect) {
                props.onSelect(e, props.id);
            }
        }
    };
    const attrs: MenuItemAttrsType = useMemo(() => {
        let _attrs: MenuItemAttrsType = {
            role:            props.role,
            'aria-disabled': props.disabled
        };
        if (props.role === 'option') {
            _attrs = {
                ..._attrs,
                role:            'option',
                'aria-selected': props.selected
            };
        } else if (props.role === null || props.role === 'none') {
            attrs.role = 'none';
        }
        return _attrs;
    }, [ props.role, props.disabled, props.selected ]);

    return (
        <Tooltip
            disabled={ !props.hasTooltip }
            delay={ 400 }
            margin="xs"
            message={ props.children }
            position="top"
            messageAlign="center"
            minWidth="120px"
            radius="lg"
            shadow
        >
            <li
                className={ className({
                    disabled: props.disabled,
                    selected: props.selected,
                    weight:   props.weight,
                    shorten:  props.hasTooltip,
                    size:     props.size
                }) }
                onClick={ handleOnSelect }
                { ...attrs }
            >
                { props.iconBefore && props.iconBefore }
                { props.children }
                { props.iconAfter && props.iconAfter }
            </li>
        </Tooltip>

    );
});

MenuItem.defaultProps = {
    role:     'menuitem',
    disabled: false,
    weight:   'normal'
};
