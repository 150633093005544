import { AccountState }   from '@geenee/builder/src/core/state/account.state';
import APIRequest         from '@geenee/builder/src/lib/APIRequest';
import { querySerialize } from '@geenee/builder/src/lib/querySerialize';

// @ts-ignore
const updateAccount = (data: Partial<AccountState>) => APIRequest.put(
    `/api/v0/account?${ querySerialize(data) }`
);

export const AccountAPI = { updateAccount };
